import React, { useMemo, useRef, useState } from 'react';
import { WhereStyled } from '../AutoActionEditorWhere.styled';
import { formatNumberShorthand, isNil } from 'common/utils/helpFunctions';
import { IPreviewMainFieldInfo } from './PreviewBox';
import { PreviewItemsBox } from './PreviewItemsBox';
import { Typography } from 'common/design-system/components-v2';
import Popover from 'common/design-system/components-v2/Popover';
import { IValueCount } from 'common/interface/general';

export type PreviewCardVariant = 'h1' | 'h2';
export const PreviewCounterCard: React.FC<{
    title: string,
    count: number,
    variant?: PreviewCardVariant,
    counters?: IValueCount[],
    fieldInfo?: IPreviewMainFieldInfo,
    isStale?: boolean,
}> = ({ title, count, variant = 'h2', counters, fieldInfo, isStale }) => {
    const countStr = useMemo(() => formatNumberShorthand(count, true), [count]);
    const [showItemsPopup, setShowItemsPopup] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const onClick = useMemo(() => fieldInfo ? () => setShowItemsPopup(true) : undefined, [fieldInfo]);

    return (
        <WhereStyled.PreviewCounterCardTopDiv>
            <WhereStyled.PreviewCounterCardDiv
                onClick={onClick}
                isClickable={!isNil(onClick)}
                variant={variant}
                ref={contentRef}>
                <Typography variant={variant === 'h1' ? 'body' : 'bodyXs'} color={isStale ? 'alert' : 'normal'}>{title}</Typography>
                <Typography variant={variant} color={isStale ? 'alert' : 'normal'}>{countStr}</Typography>
            </WhereStyled.PreviewCounterCardDiv>
            { counters && (counters.length > 0) && fieldInfo &&
                <Popover open={showItemsPopup} anchorEl={contentRef.current} placement={'top'} onClose={() => setShowItemsPopup(false)} >
                    <PreviewItemsBox counters={counters} fieldInfo={fieldInfo} />
                </Popover>
            }
        </WhereStyled.PreviewCounterCardTopDiv>
    );
};
