import styled from 'styled-components';


const PagerDutyNameWrapper = styled.div`
  width: 50%;
  margin: 24px ;
`;

const PagerDutyWebhookUrlWrapper = styled.div`
  margin: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
`;


export {
    PagerDutyNameWrapper, PagerDutyWebhookUrlWrapper
};