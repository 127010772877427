import { getHttpService } from 'common/interface/services';
import { Rule } from '../components/BusinessPriorityRules/BusinessPriority.interface';

const BASE_URL = 'erm/businessPriority';

export interface IBusinessPriorityService {
    removeRule: (rule: string) => Promise<number>;
    getRules: () => Promise<Rule[]>;
    updateRule: (rule: Rule) => Promise<Rule>;
    addRule: (rule: Rule) => Promise<Rule>;
}

export class BusinessPriorityService implements IBusinessPriorityService {
    removeRule = async (ruleId: string) =>
        await getHttpService().delete<number>(`${BASE_URL}/delete-rule?id=${ruleId}`);

    getRules = async () => await getHttpService().get<Rule[]>(`${BASE_URL}/get-rules`);

    updateRule = async (rule: Rule) => await getHttpService().put<Rule>(`${BASE_URL}/update-rule`, { data: rule });

    addRule = async (rule: Rule) => await getHttpService().post<Rule>(`${BASE_URL}/add-rule`, { data: rule });
}
