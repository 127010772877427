import React, { useEffect, useState } from 'react';
import {
    AzureDefenderForCloudStyled,
    AzureDefenderForCloudNameWrapper,
    CloudAccountSelectionWrapper
} from './AzureDefenderForCloud.styled';
import { Typography, Dropdown, Input, List, Stack } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations, SUBMIT_STATUS_RESPONSE } from 'common/module_interface/settings/integrations/consts';
import { getIntegrationsService } from 'common/interface/services';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';


interface IGenericConfigurationProps extends IConfigurationContainerProps{
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration{
    configurationObj: IAzureDefenderForCloudConfigurationObj
}

export interface IAzureDefenderForCloudConfigurationObj {
    Subscription: string
}

const AzureDefenderForCloudComponent : React.FC<IGenericConfigurationProps> = ({ onConfigurationChangeCallBack ,configuration, onConfigurationSaved, viewMode }) => {
    const { t } = useTranslation( I18nIntegrations );

    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [cloudAccountInputConfig, SetCloudAccountInputConfig] = useState<string>(t('GENERAL.SELECT'));
    const [cloudAccountList, setCloudAccountList] = useState<any[]>([]);
    const [selectedAccountSubscriptionId, setSelectedAccountSubscriptionId] = useState<string | undefined>('');
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [getAccountsMessage, setGetAccountsMessage] = useState<string>('');
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);

    const viewOnly = Boolean(viewMode);
    const discardChangesButtonHidden = Boolean(idConfiguration);

    useEffect(() => {
        checkChangesMade();
    }, [nameInputConfig,
        cloudAccountInputConfig,
        idConfiguration]);

    useEffect(() => {
        getAzureCloudAccounts();
    }, []);

    const checkMissingRequiredInput = () => {
        const variableNotChanged = (
            nameInputConfig === '' ||
            cloudAccountInputConfig === t('GENERAL.SELECT')
        );
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected = (
            nameInputConfig !== configuration?.name ||
            cloudAccountInputConfig !== configuration?.configurationObj.Subscription
        );
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const setCloudAccountInitialValue = (accounts: { name: string, subscriptionId:string }[]) => {
        if (accounts.length > 0 && configuration?.configurationObj.Subscription) {
            const configurationAccount = accounts.find((account: any) => account.subscriptionId === configuration?.configurationObj.Subscription);
            setSelectedAccountSubscriptionId(configurationAccount?.subscriptionId);
            configurationAccount && SetCloudAccountInputConfig(`${configurationAccount.name} (${configurationAccount.subscriptionId})`);
        }
    };

    const getAzureCloudAccounts = async () => {
        try {
            const cloudAccountResponse = await getIntegrationsService().getAzureCloudAccounts(false);
            setCloudAccountList(cloudAccountResponse.map((account: { name: string, subscriptionId:string }) => {
                return { label: `${account.name} (${account.subscriptionId})`, value: `${account.name} (${account.subscriptionId})`, onClick: () => {handleCloudAccountChange(account);} };
            }));
            setCloudAccountInitialValue(cloudAccountResponse);
        } catch (error:any) {
            setGetAccountsMessage(error.message || t('GENERAL.NETWORK_ERROR'));
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setNameInputConfig(textInput);
    };

    const closeDropbox = () => {
        setIsDropdownOpen(false);
    };

    const handleCloudAccountChange = (typeSelected: { name: string, subscriptionId:string }) => {
        setSelectedAccountSubscriptionId(typeSelected.subscriptionId);
        SetCloudAccountInputConfig(`${typeSelected.name} (${typeSelected.subscriptionId})`);
        closeDropbox();
    };

    const handleDiscardChanges = () => {
        setNameInputConfig(configuration?.name || '');
        SetCloudAccountInputConfig(configuration?.configurationObj.Subscription || '');
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        try {
            const resp = onConfigurationSaved && await onConfigurationSaved(nameInputConfig,
                { Subscription: selectedAccountSubscriptionId },
                idConfiguration);
            resp?.id && setIdConfiguration(resp.id);
            setResponseMessageSuccess(true);
            onConfigurationChangeCallBack && onConfigurationChangeCallBack();
            setIsButtonDisabled(true);
            return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
        } catch (error:any) {
            setResponseMessageSuccess(false);
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
        }
    };

    return (
        <AzureDefenderForCloudStyled data-aid={'azure-defender-for-cloud-component'}>
            <AzureDefenderForCloudNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input type="text" data-aid='nameInput' value={nameInputConfig} placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange} autoFocus={true} disabled={viewOnly}/>
            </AzureDefenderForCloudNameWrapper>
            <CloudAccountSelectionWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.CLOUD_ACCOUNT')}</Typography>
                <Dropdown
                    label={cloudAccountInputConfig}
                    open={isDropdownOpen}
                    onStateChange={setIsDropdownOpen}
                    buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { maxWidth: 'none', display: 'flex' } }}
                    placement="bottom-start"
                >
                    <List
                        options={cloudAccountList}
                        disabled={viewOnly}
                    />
                </Dropdown>
                <Stack>
                    <Typography elementType='h5'>{getAccountsMessage}</Typography>
                </Stack>
            </CloudAccountSelectionWrapper>
            { GenericCancelSubmitButtonWrapper({ handleDiscardChanges ,discardChangesButtonHidden, isButtonDisabled, submitForm, viewOnly, responseMessageSuccess }) }

        </AzureDefenderForCloudStyled>
    );
};

export default AzureDefenderForCloudComponent;