
export enum ActionType {
    Remove = 'remove',
    Rename = 'rename',
    AddActionTrail = 'add-action-trail',
    EditCredentials = 'edit-credentials',
    SyncNow = 'sync-now',
}

export enum ModalType {
    Remove = 'remove',
    RemoveWithPolicies = 'remove-with-policies',
    Rename = 'rename',
    EditCredentials = 'edit-credentials',
}