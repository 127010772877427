import React from 'react';
import { Stack, Spinner, Typography } from 'common/design-system/components-v2';

interface LoaderProps {
    text?: string;
}

const SpinnerWithText: React.FC<LoaderProps> = ({ text }) => {
    return (
        <Stack alignItems='center' justifyContent='center' fullWidth fullHeight>
            <Spinner size={40}/>
            <Stack padding={[8, 0]}>
                {text ? <Typography>{text}</Typography> : null}
            </Stack>
        </Stack>
    );
};

export default SpinnerWithText;