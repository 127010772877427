import { Stack, Typography } from 'common/design-system/components-v2';
import styled, { css } from 'styled-components/macro';


export const AzureOnboardingWrapper = styled(Stack)`
  height: 100%;
`;


export const AzureOnboardingStep = styled(Stack)`
  width: 100%;
  height: 408px;

  li {
    margin-left: ${props => props.theme.spacing(5)}${props => props.theme.units};
    list-style: disc;
    line-height: 28px;
  }
`;

export const AzureOnboardingStepTitle = styled(Typography).attrs({ variant: 'xl', type: 'key' })``;

export const StorageTable = styled(Stack)`
  .remove-cell {
      display: none;
  }

  .ag-row:hover .remove-cell {
      display: flex;
      cursor: pointer;
  }
`;

export const AzureConfigurationPanel = styled(Stack)`
  ${props => props.theme && css`
    border: 1px solid ${props.theme.palette.border.light};
  `};
`;


export const SwitchButtonContainer = styled(Stack)<{ isAutoOnboarded: boolean, isSelected: boolean }>`
  span {
    cursor: ${props => !props.isSelected || props.isAutoOnboarded ? 'not-allowed' : 'pointer'};
  }
`;

export const SummaryWrapper = styled(Stack)<{ status: boolean, showBackground: boolean }>`
  background-color: ${ props => props.showBackground ? props.status ? props.theme.palette.surface.successLight : props.theme.palette.surface.alertLight : 'transparent'};
  color: ${ props => props.status ? props.theme.palette.surface.success : props.theme.palette.surface.alert};
  border-radius: ${props => `${props.theme.spacing(6)}${props.theme.units}`};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const IconWrapper = styled(Stack)<{ status: boolean }>`
  background-color: ${ props => props.status ? props.theme.palette.riskLevel.lowBg : props.theme.palette.riskLevel.criticalBg};
  border-radius: 50%;
`;

export const SearchInputWrapper = styled(Stack)`
 width: 184px;
`;