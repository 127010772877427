import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { SecurityEventsTabledDiv } from './SecurityEventsTable.styled';
import i18n from 'i18next';
import { I18nRiskNamespace } from '../../../consts';
import { IFindingItem } from '../SecurityEvents.interface';
import EventsTableWidget from 'common/erm-components/custom/EventsTableWidget/EventsTableWidget';
import { getColumnsOverrideDefs } from './SecurityEventsTable.const';

const CustomLoadingCellRenderer = (props: ICellRendererParams & { itemCount: number }) => {
    const searchingEventsText = i18n.t('EXPOSURE_DASHBOARD.SECTIONS.REAL_TIME.WIDGETS.SEARCHING_EVENTS', { ns: I18nRiskNamespace });
    const searchingMoreEventsText = i18n.t('EXPOSURE_DASHBOARD.SECTIONS.REAL_TIME.WIDGETS.SEARCHING_MORE_EVENTS', { ns: I18nRiskNamespace });
    return (
        <div className="ag-loading">
            <span className='ag-loading-icon'><span className="ag-icon ag-icon-loading"></span></span>
            <span className='ag-loading-text'>{props.itemCount ? searchingMoreEventsText : searchingEventsText}</span>
        </div>
    );
};

const SecurityEventsTable: React.FC<{ items: IFindingItem[], componentName?: string, isSearching?: boolean }> =
    ({ items, componentName, isSearching = false }) => {

        const loadingCellRenderer = useMemo(() => {
            return CustomLoadingCellRenderer;
        }, []);

        const columnOverride = useMemo(() => {
            return getColumnsOverrideDefs();
        }, [componentName]);


        return (
            <SecurityEventsTabledDiv>
                <EventsTableWidget items={items} colOverride={columnOverride} isLoading={isSearching}
                    loadingCellRenderer={loadingCellRenderer} />
            </SecurityEventsTabledDiv>
        );
    };

export default SecurityEventsTable;
