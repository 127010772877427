import React from 'react';
import { ToolBarProps } from './Toolbar.types';
import { ActionType } from '../../GcpEnvironment.types';
import { useTranslation } from 'react-i18next';
import { changeUrl } from 'common/utils/http';
import { getGcpEnvNamespace } from '../../initialize.i18n';
import { GcpEnvironmentAction } from 'common/module_interface/assets/GcpEnvironment';
import { GcpEnvironmentAddinRegistry } from 'common/helpers/gcpEnvironment';
import { TFunction } from 'i18next';
import { Dropdown, List } from 'common/design-system/components-v2';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { getNotificationsService, getUserService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import GcpCloudAccountsService from '../../services/cloudAccounts/gcpCloudAccounts.service';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';

interface Action {
    type: ActionType;
    label: string;
    icon?: AllIconsName;
}
interface Actions {
    singleAction?: Action;
    multiActions?: {
        label: string;
        icon?: AllIconsName;
        items: Array<Action>;
    }
}

const getActions = (t: TFunction): Array<Actions> => [
    { singleAction: { type: ActionType.Remove, label: t('gcpPage.toolbar.remove'), icon: 'delete' } },
    { singleAction: { type: ActionType.Rename, label: t('gcpPage.toolbar.rename'), icon: 'edit' } },
    { singleAction: { type: ActionType.EditCredentials, label: t('gcpPage.toolbar.editCredentials'), icon: 'edit' } },
    { singleAction: { type: ActionType.SyncNow, label: t('gcpPage.toolbar.syncNow'), icon: 'refresh' } },
    { singleAction: { type: ActionType.AddGSuite, label: t('gcpPage.toolbar.addGSuite'), icon: 'edit' } },
    { singleAction: { type: ActionType.RemoveIntelligence, label: t('gcpPage.toolbar.removeIntelligence'), icon: 'delete' } },
    { multiActions: {
        label: t('gcpPage.toolbar.addIntelligence'),
        icon: 'plus',
        items: [ 
            { type: ActionType.AddFlowLogs, label: t('gcpPage.toolbar.addFlowLogs') },
            { type: ActionType.AddActivityLogs, label: t('gcpPage.toolbar.addActivityLogs') }
        ] }
    },
];

const Toolbar: React.FunctionComponent<ToolBarProps> = ({
    actionClick,
    gcpAccount,
}) => {
    const { t } = useTranslation(getGcpEnvNamespace('gcp'));

    const [actionsFromAddins, setActionsFromAddins] = React.useState<Array<GcpEnvironmentAction>>([]);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
    const { getByAccountId, isLoading } = useAllCloudAccounts();

    const relevantCloudAccount = React.useMemo(() => {
        if (isLoading) return undefined;
        return getByAccountId(gcpAccount.account.id);
    }, [getByAccountId, isLoading, gcpAccount]);

    React.useEffect(() => {
        if (!gcpAccount) return;

        const getAndSetActionsFromAddins = () => {
            const newActions: Array<any> = [];
            const actionsFromAddin = GcpEnvironmentAddinRegistry.getActions();
            const actionsPromises = actionsFromAddin.map(action => {
                return action.isRelevant ? action.isRelevant(gcpAccount) : true;
            });
            Promise.all(actionsPromises).then(results => {
                actionsFromAddin.forEach((action, index) => {
                    if (results[index]) {
                        newActions.push(action.getValue(gcpAccount));
                    }
                });
                setActionsFromAddins(newActions);
            });
        };

        getAndSetActionsFromAddins();
    }, [gcpAccount]);

    const handleOnSyncNowSuccess = React.useCallback(() => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('gcpPage.syncNow.onSubmit.success'),
            text: '',
        });
    }, [t]);

    const handleOnSyncNowError = React.useCallback((err: string) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('gcpPage.syncNow.onSubmit.error'),
            text: err,
        });
    }, [t]);

    const syncNow = React.useCallback(async (accountId: string) => {
        try{
            await GcpCloudAccountsService.cloudAccountsSyncNow(accountId);
            handleOnSyncNowSuccess();
        }
        catch(err: any){
            handleOnSyncNowError(err.message);
        }
    }, [handleOnSyncNowSuccess, handleOnSyncNowError]);

    const handleOnActionClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: ActionType) => {
        setIsDropdownOpen(false);
        switch (type) {
            case ActionType.EditCredentials: {
                const { altKey, shiftKey } = event;
                if (altKey && shiftKey) {
                    handleOnActionClick(event, ActionType.SyncNow);
                    break;
                }
                actionClick(type);
                break;
            }
            case ActionType.SyncNow: {
                syncNow(gcpAccount.account.id);
                break;
            }
            case ActionType.AddFlowLogs:
            case ActionType.AddActivityLogs:{
                let urlType = 'flowlogs';
                if(type === ActionType.AddActivityLogs){
                    urlType = 'cloudtrail';
                }
                changeUrl(`/magellan/onboarding-gcp?type=${urlType}&account=${gcpAccount.account.projectId}&id=${gcpAccount.account.id}&accountName=${gcpAccount.account.name}`);
                break;
            }
            case ActionType.RemoveIntelligence:{
                changeUrl(`/magellan/offboarding-gcp?account=${gcpAccount.account.projectId}`);
                break;
            }
            default:
                actionClick(type);
        }
    }, [actionClick, syncNow, gcpAccount]);

    const isActionTypeActive = React.useCallback((type: ActionType) => {
        switch (type) {
            case ActionType.Remove:
            case ActionType.Rename:
            case ActionType.AddFlowLogs:
            case ActionType.AddActivityLogs:
            case ActionType.AddGSuite:
            case ActionType.EditCredentials: {
                return true;
            }
            case ActionType.SyncNow: {
                return getUserService().hasPermission(['cloud-account-sync-now']);
            }
            case ActionType.RemoveIntelligence: {
                return !!relevantCloudAccount?.isLogicEnabled;
            }
            default:
                return false;
        }
    }, [relevantCloudAccount]);

    const getIsLoadingState = React.useCallback((type: ActionType) => {
        switch (type) {
            default:
                return false;
        }
    }, []);

    const filteredActions = React.useMemo(() => {
        return getActions(t).
            filter(action => {
                if (action.singleAction) {
                    return isActionTypeActive(action.singleAction.type);
                } else if (action.multiActions) {
                    return action.multiActions.items.some(act => isActionTypeActive(act.type));
                }
                return false;
            });
    }, [t, isActionTypeActive]);

    const dropdownListOptions = React.useMemo<IListItemProps[]>(() => {
        const listItems: IListItemProps[] = [];
        filteredActions.forEach((action, index) => {
            if (action.singleAction) {
                const { label, type } = action.singleAction;
                listItems.push({
                    label,
                    onClick: (e) => handleOnActionClick(e as any, type),
                    disabled: getIsLoadingState(type),
                    value: `${label}-${index}`
                });
            } else if (action.multiActions) {
                const { label, items } = action.multiActions;
                listItems.push({
                    label,
                    value: `${label}-${index}`,
                    itemType: 'seperator',
                });
                items.forEach(act => {
                    listItems.push({
                        label: act.label,
                        onClick: (e) => handleOnActionClick(e as any, act.type),
                        disabled: getIsLoadingState(act.type),
                        value: `${act.label}-${index}`
                    });
                });
            }
        });
        actionsFromAddins.forEach((action, index) => {
            listItems.push({
                label: action.label,
                icon: action.icon,
                onClick: () => action.callback(gcpAccount),
                disabled: action.disabled && action.disabled(gcpAccount),
                value: `${action.label}-${index}`
            });
        });
        return listItems;
    }, [filteredActions, actionsFromAddins, getIsLoadingState, handleOnActionClick, gcpAccount]);

    
    return (
        <Dropdown
            open={isDropdownOpen}
            onStateChange={(state) => setIsDropdownOpen(state)}
            buttonProps={{
                iconButton: true,
                iconProps: { name: 'more' },
            }}
            maxHeight={500}
        >
            <List
                options={dropdownListOptions}
            />
        </Dropdown>
    );
};


export default Toolbar;
