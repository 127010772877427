import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CloudBotsInitialState, ICloudBotsState, ICloudBotState } from './cloudBots.interface';
import { IRemediationResponse } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { BotStatus } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';

const CloudBotsSlice = createSlice({
    name: 'cloud_bots',
    initialState: CloudBotsInitialState,
    reducers: {
        setCloudBotStatus: (state: ICloudBotsState, action: PayloadAction<{ customizationKey: string; cloudBotStatus: BotStatus; }>) => {
            const { customizationKey, cloudBotStatus } = action.payload;
            if (state.cloudBots[customizationKey]) {
                state.cloudBots[customizationKey].cloudBotStatus = cloudBotStatus;
            }
        },
        addCloudBot: (state: ICloudBotsState, action: PayloadAction<{ customizationKey: string }>) => {
            const { customizationKey } = action.payload;
            state.cloudBots[customizationKey] = {
                ...state.cloudBots[customizationKey],
                cloudBotStatus: BotStatus.NOT_STARTED,
            } as ICloudBotState;
        },
        deleteCloudBot: (state: ICloudBotsState, action: PayloadAction<{ customizationKey: string }>) => {
            const { customizationKey } = action.payload;
            if(state.cloudBots[customizationKey]) {
                delete state.cloudBots[customizationKey];
            }
        },
        updateRemediationDetails: (state: ICloudBotsState, action: PayloadAction<{ customizationKey: string, remediationData?: IRemediationResponse }>) => {
            const { customizationKey, remediationData } = action.payload;
            if (state.cloudBots[customizationKey]) {
                state.cloudBots[customizationKey].cloudBotRemediationData = remediationData;
            }
        },
    }
});

export default CloudBotsSlice;
