import { CpIcon, CpMenu, CpMenuDivider, CpMenuItem } from '@dome9/components/react/components';
import { logout, revertToRoot, switchRole } from 'platform/user/User.actions';
import { getImageUrl, getRoles, getRootUsername, getUsername, setRoles, userHasRole } from 'platform/user/User.reducer';
import React, { Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserRolesModalIsOpen, setUserRolesModalIsOpen } from '../../user/SwitchRoleComponent.reducer';
import { changeLanguage, getTopMenuOpen, setTopMenuOpen } from '../Menus/Menus.reducer';
import SwitchRole from '../SwitchRole/SwitchRole';
import './TopNavigationBar.scss';
import { ILanguageName, resources } from 'common/services/translations/translations';
import { getStoreService } from 'common/interface/services';
import { getUserAccounts } from '../SwitchRole/Roles/Roles.reducer';
import cpLogo from '../../../assets/images/check-point-logo-new-white.svg';
import { useTranslation } from 'react-i18next';
import { changeUrl } from 'common/utils/http';
import { getRolesFromServer } from '../../roles/Roles.service';
import { Button } from '@dome9/berries/react-components';
import { setAppLoaded } from '../../../App.reducer';

enum IconsProduct {
    CloudGuard = 'cloudGuard',
    InfinityPortal = 'infinityPortalNewLogo',
}

enum ProductName {
    CloudGuard = 'CloudGuard',
}

function hideTopBarCpMenu() {
    getStoreService().dispatch(setTopMenuOpen(false));
}

function openSwitchRoleModal() {
    getStoreService().dispatch(setUserRolesModalIsOpen(true));
}

function extractLanguageCodes() : ILanguageName[]{
    return Object.keys(resources) as ILanguageName[];
}

export const TopNavigationBar: React.FC = () => {
    const dispatch = getStoreService().dispatch;
    const topMenuOpen = useSelector(getTopMenuOpen);
    const roles = useSelector(getRoles);
    const username = useSelector(getUsername);
    const isUserRolesModalOpen = useSelector(getUserRolesModalIsOpen);
    const imageUrl = useSelector(getImageUrl);
    const hasRole = useSelector(userHasRole);
    const rootUser = useSelector(getRootUsername);
    const { t, i18n } = useTranslation();
    const languages = extractLanguageCodes();
    const userAccountAndRoles = useSelector(getUserAccounts);

    const openMenu = useCallback(
        (ev: any) => {
            const parentClassName = ev.target.closest('.menu-placeholder');
            const menuOpen =
                parentClassName && parentClassName.className.indexOf('menu-placeholder') > -1 ? !topMenuOpen : false;
            if (menuOpen !== topMenuOpen) {
                dispatch(setTopMenuOpen(menuOpen));
            }
        },
        [dispatch, topMenuOpen],
    );

    useEffect(()=>{
        getRolesFromServer().then((roles) => setRoles(roles));
    },[]);

    useEffect(() => {
        window.addEventListener('blur', hideTopBarCpMenu);
        document.addEventListener('click', openMenu);
        return () => {
            window.removeEventListener('blur', hideTopBarCpMenu);
            document.removeEventListener('click', openMenu);
        };
    }, [openMenu]);

    function showPageLoader() {
        getStoreService().dispatch(setAppLoaded(false));
    }

    return (
        <header className='TopNavigationBar flex-center-space-between' data-aid='top-navigation-bar'>
            <div
                onClick={() => {
                    changeUrl('/');
                }}
                className='left-panel flex-center pointer'>
                <CpIcon className='cloud-guard-logo title-extra-large flex-center' icon={IconsProduct.CloudGuard} />
                <span className='cloud-guard-title'>{ProductName.CloudGuard}</span>
            </div>
            <div className='right-panel flex-center'>
                <div id="beamerButton" className='mr-2 username-text' >
                    <Button variant='integrated' labelClassName="text-white">{t('WHATS_NEW')}</Button>
                </div>
                <div className={'divider'} />
                {(topMenuOpen) && (
                    <div className='top-menu-wrapper' data-aid='top-right-dropdown-menu'>
                        <CpMenu
                            onCpClose={(ev: any) => {
                                openMenu(ev);
                            }}>
                            <CpMenuDivider>
                                <span>{t('TOP_BAR_MENU.SWITCH_ROLE')}</span>
                            </CpMenuDivider>
                            {hasRole && (
                                <CpMenuItem onClick={revertToRoot}>
                                    <CpIcon icon='backNavigationArrow' />
                                    <span className={'back-to text-body'}>{t('TOP_BAR_MENU.BACK_TO')}</span>
                                    <span className={'back-to-user text-body'}>{rootUser}</span>
                                </CpMenuItem>
                            )}

                            <Fragment>
                                {roles.map((role) => (
                                    <CpMenuItem
                                        onClick={() => {
                                            showPageLoader();
                                            switchRole(role);
                                        }}
                                        key={`${role.accountName}_${role.accountId}_${role.role}`}>
                                        <span className={'text-body'}>
                                            {role.role} @ {role.accountName}
                                        </span>
                                    </CpMenuItem>
                                ))}
                                {userAccountAndRoles && userAccountAndRoles.length > 0 && (
                                    <CpMenuItem
                                        onClick={() => {
                                            dispatch(setTopMenuOpen(false));
                                            openSwitchRoleModal();
                                        }}>
                                        <span className={'text-body'}>{t('TOP_BAR_MENU.MORE')}...</span>
                                    </CpMenuItem>
                                )}
                            </Fragment>
                            <CpMenuDivider key={'language-menu-divider'}>
                                {t('TOP_BAR_MENU.SELECT_LANGUAGE')}
                            </CpMenuDivider>
                            {languages.map((language) => {
                                return (
                                    <CpMenuItem
                                        onclick={() => {
                                            changeLanguage(language);
                                        }}
                                        key={`change-language-to-${language}`}>
                                        {language === i18n.language && (
                                            <CpIcon icon='check' className='selected-language-icon' />
                                        )}
                                        <span>{t(`LANGUAGES_NAMES.${language}`)}</span>
                                    </CpMenuItem>
                                );
                            })}
                            <CpMenuDivider />
                            <CpMenuItem
                                onClick={() => {
                                    logout();
                                }}>
                                <CpIcon icon='logout' />
                                <span className={'text-body'} data-aid='logout-button'>{t('TOP_BAR_MENU.LOGOUT')}</span>
                            </CpMenuItem>
                        </CpMenu>
                    </div>
                )}
                {imageUrl && <img src={imageUrl} className={'vendor-image'} />}
                <span className={`menu-placeholder pointer ${hasRole ? 'has-role' : ''} flex items-center`}>
                    <div className={'text-body username-text'} data-aid='top-right-username'>{username}</div>
                    {!hasRole && <span className='mb-5'><CpIcon icon='user' className='title-large user-logo' /></span>}
                    <CpIcon icon='searchArrowDown' className='text-small user-logo arrow-logo'
                        data-aid='top-right-dropdown-button' />
                    {hasRole && <div>{rootUser}</div>}
                </span>

                <div className={'divider'} />
                <img className={'infinity-portal-logo'} height='19px' src={cpLogo} alt={t('GENERAL.LOGO_ALT')} />
                {isUserRolesModalOpen && <SwitchRole />}
            </div>
        </header>
    );
};

export default TopNavigationBar;
