import { Addin } from 'common/extensibility/AddinRegistry';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { AzureEnvironmentAddinRegistry, AzureEnvironmentAddingBase } from 'common/helpers/azureEnvironment';
import { AzureEnvironmentData, AzureEnvironmentTab } from 'common/module_interface/assets/AzureEnvironment';
import { EnvironmentChip , StatusCellRenderer_Status } from 'common/module_interface/assets/Environments';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { getAzureEnvNamespace } from './initialize.i18n';
import Assets from './tabs/Assets';
import { changeUrl } from 'common/utils/http';
import { getCloudAccountsService } from 'common/interface/data_services';
import { Vendors } from 'common/consts/vendors';
import { getUserService } from 'common/interface/services';


export const initialAzureEnvironmentData = () => {

    const tabs: Addin<AzureEnvironmentAddingBase<AzureEnvironmentTab>>[] = [
        {
            id: 'readiness',
            position: 10,
            content: {
                id: 'readiness',
                getValue: () => ({
                    name: 'readiness',
                    label: i18next.t('azurePage.tabs.readiness', { ns: getAzureEnvNamespace('azure') }),
                    isReactTab: false,
                })
            }
        },
        {
            id: 'network',
            position: 20,
            content: {
                id: 'network',
                getValue: () => ({
                    name: 'Network',
                    label: i18next.t('azurePage.tabs.network', { ns: getAzureEnvNamespace('azure') }),
                    isReactTab: false,
                })
            }
        },
        {
            id: 'protected assets new',
            position: 30,
            content: {
                id: 'protected assets new',
                getValue: (azureData: AzureEnvironmentData) => ({
                    name: 'protected assets new',
                    label: i18next.t('azurePage.tabs.protectedAssets', { ns: getAzureEnvNamespace('azure') }),
                    isReactTab: true,
                    content: <Assets cloudAccountId={azureData.account.id} />
                })
            }
        },
        {
            id: 'compliance policies',
            position: 40,
            content: {
                id: 'compliance policies',
                getValue: () => ({
                    name: 'compliance policies',
                    label: i18next.t('azurePage.tabs.compliancePolicies', { ns: getAzureEnvNamespace('azure') }),
                    isReactTab: false,
                })
            }
        },
        {
            id: 'assessment history',
            position: 50,
            content: {
                id: 'assessment history',
                getValue: () => ({
                    name: 'assessment history',
                    label: i18next.t('azurePage.tabs.assessmentHistory', { ns: getAzureEnvNamespace('azure') }),
                    isReactTab: false,
                })
            }
        },
        {
            id: 'threat and security events',
            position: 60,
            content: {
                id: 'threat and security events',
                getValue: () => ({
                    name: 'threat & security events',
                    label: i18next.t('azurePage.tabs.threatAndSecurityEvents', { ns: getAzureEnvNamespace('azure') }),
                    isReactTab: false,
                })
            }
        },
        {
            id: 'serverless',
            position: 70,
            content: {
                id: 'serverless',
                getValue: () => ({
                    name: 'serverless',
                    label: i18next.t('azurePage.tabs.serverless', { ns: getAzureEnvNamespace('azure') }),
                    isReactTab: false,
                }),
                isRelevant: (azureData: AzureEnvironmentData)=> azureData.account.isServerlessActive && getUserService().hasPermission(['serverless_runtime_azure'])
            }
        }
    ];
    AzureEnvironmentAddinRegistry.addTabs(tabs);

    const detailsItems: Addin<AzureEnvironmentAddingBase<BlockInfoProps>>[] = [
        {
            id: 'organizationalUnit',
            position: 10,
            content: {
                id: 'organizationalUnit',
                getValue: (azureData) => ({
                    title: i18next.t('azurePage.info.organizationalUnit', { ns: getAzureEnvNamespace('azure') }),
                    info: azureData.account.organizationalUnitName, 
                    copyable: true,
                })
            }
        },
        {
            id: 'subscriprionId',
            position: 20,
            content: {
                id: 'subscriprionId',
                getValue: (azureData) => ({
                    title: i18next.t('azurePage.info.subscriprionId', { ns: getAzureEnvNamespace('azure') }),
                    info: azureData.account.subscriptionId,
                    copyable: true,
                })
            }
        },
        {
            id: 'tenantId',
            position: 20,
            content: {
                id: 'tenantId',
                getValue: (azureData) => ({
                    title: i18next.t('azurePage.info.tenantId', { ns: getAzureEnvNamespace('azure') }),
                    info: azureData.account.tenantId,
                    copyable: true,
                })
            }
        },
        {
            id: 'cloudGuardId',
            position: 30,
            content: {
                id: 'cloudGuardId',
                getValue: (azureData) => ({
                    title: i18next.t('azurePage.info.cloudGuardId', { ns: getAzureEnvNamespace('azure') }),
                    info: azureData.account.id,
                    copyable: true,
                })
            }
        },
        {
            id: 'onboardingTime',
            position: 40,
            content: {
                id: 'onboardingTime',
                getValue: (azureData) => ({
                    title: i18next.t('azurePage.info.onboardingTime', { ns: getAzureEnvNamespace('azure') }),
                    info: dayjs(azureData.account.creationDate).format('MMM D, YYYY h:mm A Z'),
                })
            }
        },
        {
            id: 'totalAssets',
            position: 60,
            content: {
                id: 'totalAssets',
                getValue: (azureData) => ({
                    title: i18next.t('azurePage.info.totalAssets', { ns: getAzureEnvNamespace('azure') }),
                    info: i18next.t('COMMON.PRETTY_NUMBER', { value: azureData.account.totalAssets }),
                })
            }
        }
    ];
    AzureEnvironmentAddinRegistry.addDetailsPanelItems(detailsItems);

    const chips: Addin<AzureEnvironmentAddingBase<EnvironmentChip>>[] = [
        {
            id: 'environmentStatusChip',
            position: 20,
            content: {
                id: 'environmentStatusChip',
                customLoadingLabel: i18next.t('azurePage.chips.status.loading', { ns: getAzureEnvNamespace('azure') }),
                getValue: async (azureData) => {
                    const syncStatusList = await getCloudAccountsService().getEnvironmentSyncStatus(true, err => { throw err; });
                    const status = syncStatusList.find(s => s.platform === Vendors.AZURE && s.id === azureData.account.id);
                    const syncStatus = status?.hasIssues ? StatusCellRenderer_Status.warning : StatusCellRenderer_Status.success;
                    const tooltipI18Key = syncStatus === StatusCellRenderer_Status.warning ? 'azurePage.chips.MISSING_PERMISSIONS' : 'azurePage.chips.VALID_PERMISSIONS';
                    const chipProps: EnvironmentChip = {
                        label: i18next.t(`azurePage.chips.status.${syncStatus}`, { ns: getAzureEnvNamespace('azure') }),
                        leadingIconProps: { name: syncStatus === StatusCellRenderer_Status.warning ? 'warning' : 'checkCircle' },
                        color: syncStatus === StatusCellRenderer_Status.warning ? 'warning' : 'success',
                        disableInteraction: true,
                        tooltip: i18next.t(tooltipI18Key, { ns: getAzureEnvNamespace('azure') })
                    };

                    if (syncStatus === StatusCellRenderer_Status.warning) {
                        chipProps.disableInteraction = false;
                        chipProps.onClick = () => {
                            changeUrl(`/cloud-account/azure/${azureData.account.id}?tabName=readiness&tabOnly=true`);
                        };
                    }
                    return chipProps;
                }
            }
        },
    ];
    AzureEnvironmentAddinRegistry.addChips(chips);
};