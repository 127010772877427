import { getVendor } from 'common/consts/vendors';
import {
    IAggregationDataItem,
    IOrganizationalUnit,
    ICloudAccount,
    getCloudAccountsService,
    getOrganizationalUnitService
} from 'common/interface/data_services';
import { IDashboardWidget, IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import { IAccountFilters, PlatformCountType } from '../Helpers/types';
import { changeUrl } from 'common/utils/http';

const platformNamesMap = {
    get: (name : string) => ( name === 'gcp' ? 'google': name)
};
export const getEnvironmentData = (aggregationDataItem: IAggregationDataItem) : IGenericWidgetDataItem=> {
    const vendorData = getVendor(aggregationDataItem.value);
    return {
        key: vendorData?.displayName || aggregationDataItem.value,
        value: aggregationDataItem.count,
        icon: vendorData?.icon,
        onclick: () => changeUrl(`/cloud-account/index?query=%7B"filter":%7B"freeTextPhrase":"","filterFields":%5B%7B"name":"resource-type","value":"${aggregationDataItem.value}"%7D%5D%7D%7D`)
    };
};
const initializePlatformCounts = () :PlatformCountType => ({
    alibaba: 0, aws: 0, azure: 0, containerregistry: 0, google: 0, kubernetes: 0, shiftleft: 0
});
const flattenChildren = (unit: IOrganizationalUnit): string[] =>
    [unit.id, ...(unit.children?.flatMap(child => flattenChildren(child)) ?? [])];

const findAllDescendantIds = (unit: IOrganizationalUnit, unitId: string): string[] => {
    if (unit.id === unitId) {
        return flattenChildren(unit);
    }
    return unit.children?.flatMap(child => findAllDescendantIds(child, unitId)) ?? [];
};
const updatePlatformsCount = (cloudAccounts: ICloudAccount[], filters: IAccountFilters): PlatformCountType => {
    return cloudAccounts.filter(account => {
        if (filters.organizationalUnitId) return filters.organizationalUnitId.includes(account.organizationalUnitId);
        if (filters.platform) return filters.platform.includes(account.platform);
        return true;
    }).reduce((count: PlatformCountType, account) => {
        const platform = account.platform as string;
        count[platform as keyof PlatformCountType] = (count[platform as keyof PlatformCountType] || 0) + 1;
        return count;
    }, initializePlatformCounts());
};
const getFilterFields = (widget: IDashboardWidget) => widget.filterState && widget.filterState.filter(
    // clean freeText && empty values
    (filter: any) => filter.name !== 'free-text' && filter.value !== ''
);
const buildFilters = (filterState: { name:string, value:string|number }[], organizationalUnitsView: IOrganizationalUnit): IAccountFilters => {
    return filterState.reduce((filters: IAccountFilters, filter) => {
        const value = filter?.value?.toString();
        if (!value) return filters;
        switch (filter.name) {
            case 'organizationalUnitId': {
                const organizationalUnitIds = findAllDescendantIds(organizationalUnitsView, value);
                filters.organizationalUnitId = [...(filters.organizationalUnitId || []), ...organizationalUnitIds];
                break;
            }
            case 'platform': {
                const platformName = platformNamesMap.get(value);
                filters.platform = [...(filters.platform || []), platformName];
                break;
            }
        }
        return filters;
    }, {});
};
const createVendorItems = (platformsCount: PlatformCountType, filters: IAccountFilters) => {
    const filterPanelStr = JSON.stringify(filters);
    const widgetLink = `/cloud-account/index?filterPanel=${filterPanelStr}`;
    return Object.entries(platformsCount).map(([key, value]) => {
        const vendorData = getVendor(key);
        return {
            key: vendorData?.displayName || key,
            value,
            icon: vendorData?.icon,
            onclick: () => changeUrl(widgetLink)
        };
    });
};
export const monitoredEnvironmentsDataGetter = async (widget: IDashboardWidget) => {
    const organizationalUnitsView: IOrganizationalUnit = await getOrganizationalUnitService().getOrganizationalUnitsView();
    const cloudAccounts: ICloudAccount[] = await getCloudAccountsService().getAllCloudAccounts();
    const filterFields = getFilterFields(widget);
    const filters = buildFilters(filterFields, organizationalUnitsView);
    const platformsCount = updatePlatformsCount(cloudAccounts, filters);
    const vendorItems = createVendorItems(platformsCount, filters);
    return { items: vendorItems };
};

export const unmonitoredEnvironmentsDataGetter = async () => {
    const response = await getCloudAccountsService().getUnmonitoredCloudAccountsCount();
    const vendorItems = Object.keys(response)?.map((key) => {
        const vendorData = getVendor(key);
        return {
            key: vendorData?.displayName || key,
            value: response[key],
            icon: vendorData?.icon,
            onclick: () => changeUrl(`/cloud-account/index?query=%7B"filter":%7B"freeTextPhrase":"","filterFields":%5B%7B"name":"resource-type","value":"${key}"%7D%5D%7D%7D`)
        };
    });

    return {
        items: vendorItems || [],
    };
};