import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const BodyDiv = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
`;

export const PageStyled = {
    TopDiv,
    BodyDiv,
};
