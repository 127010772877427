import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { CVE_IGNORE_LIST_SERVICE_ID } from './CveIgnoreList.consts';
import { CveIgnoreListService } from './CveIgnoreListService';

const initializeCveIgnoreListService = () => {
    globalAddinContainer.addService(CVE_IGNORE_LIST_SERVICE_ID, new CveIgnoreListService());
};

export default function initializeCveIgnoreList() {
    initializeCveIgnoreListService();
}
