import { useTranslation } from 'react-i18next';
import WelcomePage from 'common/components/WelcomePage/WelcomePage';
import { IWelcomePageSection } from 'common/components/WelcomePage/WelcomePage.interface';

const Welcome: React.FC = () => {
    const { t } = useTranslation();
    const sections: IWelcomePageSection[] = [
        {
            title: t('INSIGHT.WELCOME.SECTIONS.WELCOME.TITLE'),
            subtitle: t('INSIGHT.WELCOME.SECTIONS.WELCOME.SUB_TITLE'),
            items: [
                {
                    title: t('INSIGHT.WELCOME.SECTIONS.ENTITIES_GRAPH_EXPLORER.ITEMS.0.TITLE'),
                    subtitle: t('INSIGHT.WELCOME.SECTIONS.ENTITIES_GRAPH_EXPLORER.ITEMS.0.SUB_TITLE'),
                    icon: 'assets',
                    linkTo: '/insight/entities-graph-explorer',
                },
            ],
        },
    ];
    return (
        <WelcomePage
            title={t('INSIGHT.WELCOME.TITLE')}
            subtitle={t('INSIGHT.WELCOME.SUB_TITLE')}
            sections={sections}
        />
    );
};

export default Welcome;