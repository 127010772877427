import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams, WizardStep } from 'common/components/Wizard/Wizard';
import { getNotificationsService } from 'common/interface/services';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Done } from 'common/components/policy/Done/Done';
import { useLocation } from 'react-router-dom';
import { NotificationType } from 'common/interface/notifications';
import { getIntelligenceService, INTELLIGENCE } from 'common/module_interface/intelligence/intelligence';
import { IIntelligencePolicy, IUpdatePolicyDto } from 'common/module_interface/intelligence/Intelligence.interface';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { finishFlow } from 'common/components/policy/utils';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

export const EditPolicyPage: React.FC = () => {
    const { t } = useTranslation();
    const [policy, setPolicy] = useState<IIntelligencePolicy>();
    const [selectedNotifications, setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusOK, setFlowStatusOK] = useState<boolean>();
    const [flowStatusMessage, setFlowStatusMessage] = useState<string>();
    useBreadcrumbsLastChildren([INTELLIGENCE, t('POLICY.EDIT_POLICY')]);
    const returnUrl = useReturnUrl();
    const location = useLocation();
    const saveChanges = useCallback(async () => {
        if (policy && selectedNotifications?.length) {
            setFlowStatusMessage(t('GENERAL.PROCESSING'));
            policy.notificationIds = selectedNotifications.map((notification: ListItem) => notification.id);
            const policyDto: IUpdatePolicyDto = {
                policy: {
                    bundleId: policy.bundleId,
                    notifications: selectedNotifications.map((notification: ListItem) => notification.id),
                },
                cloudAccount: policy.cloudAccountId,
                logType: '',

            };
            try {
                await getIntelligenceService().updatePolicy(policyDto);
                setFlowStatusOK(true);
                setFlowStatusMessage(t('POLICY.POLICY_ADDED_MESSAGE'));
            } catch {
                setFlowStatusOK(false);
                setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
            }
        }
    }, [policy, selectedNotifications, t]);


    const steps: WizardStep[] = useMemo(() => [
        {
            id: 'notificationSelection',
            name: t('POLICY.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams: RenderViewParams) => {
                return (
                    <>
                        {selectedNotifications && <NotificationsSelectionComponent
                            preSelectedItems={selectedNotifications} {...renderViewParams}
                            selectedNotificationsChanged={selectedNotificationsChanged} />}
                    </>
                );
            },
            onNext: async () => {
                saveChanges();
            },
        }, {
            id: 'done',
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams: RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async () => {
                finishFlow(returnUrl);
            },
        },
    ], [flowStatusMessage, flowStatusOK, saveChanges, selectedNotifications, t]);

    const raisePolicyNotFoundErrorMessage = () => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('GENERAL.ERROR_OCCURRED'),
            text: t('POLICY.POLICY_NOT_FOUND'),
        });
    };

    useEffect(() => {
        const handlePolicyLoad = async (policyId: string) => {
            const allPolicies = await getIntelligenceService().getPolicies(false);
            const _policy = allPolicies.find(policy => policy.id === policyId);
            if (_policy) {
                setPolicy(_policy);
                setSelectedNotifications(_policy.notificationIds.map((notificationId: string) => ({ id: notificationId })) as ListItem[]);
            } else {
                raisePolicyNotFoundErrorMessage();
            }
        };
        if (!policy) {
            const querystring = new URLSearchParams(location.search);
            const policyId = querystring.get('policyId');
            if (!policyId) {
                raisePolicyNotFoundErrorMessage();
                return;
            } else {
                handlePolicyLoad(policyId);
            }
        }
    }, [location, steps.length, policy]);

    const selectedNotificationsChanged = (selectedItems: ListItem[]) => {
        setSelectedNotifications(selectedItems);
    };

    return (
        <div>
            {steps.length ? <Wizard
                steps={steps}
                title={t('POLICY.EDIT_POLICY')}
                doneButtonText={t('GENERAL.DONE')}
                onClose={() => finishFlow(returnUrl)}
                ShowBackButtonInLastStep={false}
            />
                : null}
        </div>
    );
};