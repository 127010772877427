import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, ClientId } from 'App.interface';
import { ALIASES } from 'platform/auth/auth.const';
import { RootState } from 'common/services/store/store';
import { getIsCloudInfra, RuntimeEnvironmentType } from 'common/utils/RuntimeEnvironment';


const initialState: AppState = {
    isAWSLegacyView: false,
    clientId: 'webapp',
    expiredCookieOptions: {},
    environmentType: RuntimeEnvironmentType.UNKNOWN,
    appLoaded: false,
    isAngularLoaded: false,
    isReact: false,
    magellanUrl: '',
    hideAngularUntilNavigated: false,
    isReactPageWithAngular: false,
    isAngularFrameOnRemote: false,
    isMainNavigationBarOpen: false,
    isUserHideCGSystemAlertBar: false
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setApiUrl: (state: AppState, action: PayloadAction<string>) => {
            state.apiUrl = action.payload;
        },
        setAuthServerUrl: (state: AppState, action: PayloadAction<string>) => {
            state.authServerUrl = action.payload;
        },
        setCloudInfraAuthenticateUrl: (state: AppState, action: PayloadAction<string>) => {
            state.cloudInfraAuthenticateUrl = action.payload;
        },
        setCloudInfraUrl: (state: AppState, action: PayloadAction<string>) => {
            state.cloudInfraUrl = action.payload;
        },
        setMagellanUrl: (state: AppState, action: PayloadAction<string>) => {
            state.magellanUrl = action.payload;
        },
        setAppLoaded: (state: AppState, action: PayloadAction<boolean>) => {
            state.appLoaded = action.payload;
        },
        setEnvironmentType: (state: AppState, action: PayloadAction<RuntimeEnvironmentType>) => {
            state.environmentType = action.payload;
        },
        setIsAngularLoaded: (state: AppState, action: PayloadAction<boolean>) => {
            state.isAngularLoaded = action.payload;
        },
        setIsReact: (state: AppState, action: PayloadAction<boolean>) => {
            state.isReact = action.payload;
        },
        setHideAngularUntilNavigated: (state: AppState, action: PayloadAction<boolean>) => {
            state.hideAngularUntilNavigated = action.payload;
        },
        setIsAWSLegacyView: (state: AppState, action: PayloadAction<boolean>) => {
            state.isAWSLegacyView = action.payload;
        },
        setIsReactPageWithAngular: (state: AppState, action: PayloadAction<boolean>) => {
            state.isReactPageWithAngular = action.payload;
        },
        setIsAngularFrameOnRemote: (state: AppState, action: PayloadAction<boolean>) => {
            state.isAngularFrameOnRemote = action.payload;
        },
        setIsMainNavigationBarOpen: (state: AppState, action: PayloadAction<boolean>) => {
            state.isMainNavigationBarOpen = action.payload;
        },
        setIsUserHideCGSystemAlertBar: (state: AppState, action: PayloadAction<boolean>) => {
            state.isUserHideCGSystemAlertBar = action.payload;
        },

    },
});

export const {
    setApiUrl,
    setAuthServerUrl,
    setCloudInfraAuthenticateUrl,
    setCloudInfraUrl,
    setMagellanUrl,
    setAppLoaded,
    setEnvironmentType,
    setIsAngularLoaded,
    setIsReact,
    setHideAngularUntilNavigated,
    setIsAWSLegacyView,
    setIsReactPageWithAngular,
    setIsAngularFrameOnRemote,
    setIsMainNavigationBarOpen,
    setIsUserHideCGSystemAlertBar
} = appSlice.actions;

export const getIsAWSLegacyView = (state: RootState): boolean => state.app.isAWSLegacyView;
export const getIsReactPageWithAngular = (state: RootState): boolean => state.app.isReactPageWithAngular;
export const getIsAngularFrameOnRemote = (state: RootState): boolean => state.app.isAngularFrameOnRemote;
export const getHideAngularUntilNavigated = (state: RootState): boolean => state.app.hideAngularUntilNavigated;
export const getAppLoaded = (state: RootState): boolean => {
    return state.app.appLoaded;
};

export const getIsReact = (state: RootState): boolean => state.app.isReact;
export const getClientId = (): ClientId => {
    if (getIsCloudInfra()) {
        return ALIASES.CLIENT_ID_CLOUD_INFRA as ClientId;
    }
    const searchParams = new URLSearchParams(document.location.search);
    if (decodeURIComponent(searchParams.get('returnUrl') || '')?.includes('msp.')) {
        return ALIASES.CLIENT_ID_MSP as ClientId;
    }
    return ALIASES.CLIENT_ID_WEBAPP as ClientId;
};
export const getIsMainNavigationBarOpen = (state: RootState): boolean => state.app.isMainNavigationBarOpen;
export const getIsUserHideCGSystemAlertBar = (state: RootState): boolean => state.app.isUserHideCGSystemAlertBar;


export default appSlice.reducer;
