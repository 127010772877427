import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CpLoadingDots } from '@dome9/components/react/components';
import { Button, Stack, Wizard } from 'common/design-system/components-v2';
import { getStoreService } from 'common/interface/services';
import Welcome from './Components/Welcome/Welcome';
import StoragesTable from './Components/StoragesTable/StoragesTable';
import NSGStoragesTable from './Components/StoragesTable/NSGStoragesTable';
import ARMTemplate from './Components/ARMTemplate/ARMTemplate';
import Subscriptions from './Components/Subscriptions/Subscriptions';
import AzureNetworkFirewall from './Components/AzureNetworkFirewall/AzureNetworkFirewall';
import Summary from './Components/Summary/Summary';
import { AzureOnboardingWrapper } from './AzureIntelligenceOnboarding.styled';
import { IWizardBody, RenderedStep, WizardStep } from './AzureIntelligenceOnboarding.interface';
import { AssetType, AzureIntelligenceOnboardingType } from './AzureIntelligenceOnboarding.const';
import {
    canGoBack,
    canGoForward,
    getStep,
    getIsLoading,
    getAzureOnboardingNextButtonDisabled,
    getAzureOnboardingFinishButtonDisabled,
    clearAzureOnboardingState,
    setSubscriptionId,
    setCloudAccountId, setAzureIntelligenceType, getAzureOnboardingType
} from './AzureIntelligenceOnboarding.reducer';
import { goToStep } from './AzureIntelligenceOnboarding.actions';
import { exitOnboardingWizard } from './Services/AzureIntelligenceOnboardingService';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';

const AzureIntelligenceOnboarding: FunctionComponent = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });
    const bodyRef = React.useRef<IWizardBody>();
    const dispatch = getStoreService().dispatch;

    const currentStep = useSelector(getStep);
    const canGoStepForward = useSelector(canGoForward);
    const canGoStepBack = useSelector(canGoBack);
    const isLoading = useSelector(getIsLoading);
    const nextButtonDisabled = useSelector(getAzureOnboardingNextButtonDisabled);
    const finishButtonDisabled = useSelector(getAzureOnboardingFinishButtonDisabled);
    const isCentralized = useSelector(getAzureOnboardingType) === AzureIntelligenceOnboardingType.centralized;

    const { subscription, type, accountName, cloudAccountId } = useParams<{
        subscription: string,
        type: AssetType,
        accountName: string,
        cloudAccountId: string
    }>();

    const intelligenceType = type === AssetType.flowLogs ? t('TYPE.NETWORK_TRAFFIC') : t('TYPE.ACCOUNT_ACTIVITY');

    const rerenderBody = async () => {
        const wizardBody = bodyRef.current;
        if (wizardBody) await wizardBody.updateSteps();
    };

    const defaultSteps: RenderedStep[] = [
        {
            name: t('STEPS.WELCOME'),
            component: <Welcome onChangeType={rerenderBody}/>
        },
        {
            name: t('STEPS.ARM_TEMPLATE'),
            component: <ARMTemplate/>
        },
        {
            name: t('STEPS.FIREWALL'),
            component: <AzureNetworkFirewall/>
        },
        {
            name: t('STEPS.SUMMARY'),
            component: <Summary/>
        }
    ];

    const centralizedSteps = (): RenderedStep[] => {
        const steps = [...defaultSteps];
        steps.splice(1, 0, {
            name: t('STEPS.STORAGE'),
            component: <StoragesTable/>
        });
        steps.splice(4, 0, {
            name: t('STEPS.SUBSCRIPTIONS'),
            component: <Subscriptions/>
        });
        return steps;
    };

    const nsgSteps = () => {
        const steps = defaultSteps.map(steps => ({ ...steps }));
        steps.splice(1, 0, {
            name: t('STEPS.NETWORK_SECURITY_GROUP'),
            component: <NSGStoragesTable/>
        });
        return steps;
    };

    useEffect(() => {
        rerenderBody();
    }, [currentStep, dispatch]);

    useEffect(() => {
        return (() => {
            //clear state on unmounting component
            dispatch(clearAzureOnboardingState());
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch(clearAzureOnboardingState());
        dispatch(setSubscriptionId(subscription));
        dispatch(setCloudAccountId(cloudAccountId));
        dispatch(setAzureIntelligenceType(type));
    }, [dispatch, subscription, cloudAccountId, type]);

    const handleExitOnboardingWizard = () => {
        exitOnboardingWizard();
    };

    const getRenderedSteps: () => RenderedStep[] = isCentralized ? centralizedSteps : nsgSteps;

    const title = `${t('AZURE')} ${intelligenceType} - ${t('ONBOARDING')} - ${accountName} (${subscription})`;

    const footerActions = (
        <Stack direction='row' spacing={1}>
            {canGoStepBack && <Button variant='text' onClick={() => goToStep(false)}>{t('BUTTONS.BACK')}</Button>}
            {canGoStepForward ?
                <Button 
                    variant='contained' 
                    color='brandPrimary' 
                    onClick={() => goToStep(true)} 
                    disabled={nextButtonDisabled}
                >
                    {t('BUTTONS.NEXT')}
                </Button> :
                <Button 
                    variant='contained' 
                    color='brandPrimary' 
                    onClick={() => handleExitOnboardingWizard()} 
                    disabled={finishButtonDisabled}
                >
                    {t('BUTTONS.DONE')}
                </Button>
            }
        </Stack>
    );

    const steps: WizardStep[] = useMemo(() => {
        return getRenderedSteps().map((step, idx) => ({
            title: step.name,
            number: idx + 1
        }));
    }, [getRenderedSteps]);

    const content: JSX.Element = useMemo(() => {
        const renderedSteps: RenderedStep[] = getRenderedSteps();
        const currentRendedStep: JSX.Element = renderedSteps[currentStep].component;
        return currentRendedStep;
    }, [currentStep, getRenderedSteps]);

    return (
        <AzureOnboardingWrapper>
            <Wizard
                title={title} 
                stepperProps={{ 
                    steps, 
                    currentStep 
                }}
                onClose={handleExitOnboardingWizard}
                content={content}
                footerActions={footerActions}
            />
            {isLoading ? <CpLoadingDots></CpLoadingDots> : null}
        </AzureOnboardingWrapper>
    );
};
export default AzureIntelligenceOnboarding;
