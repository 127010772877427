import React, { useEffect, useState } from 'react';
import { CpCommonButton, CpTextBox, CpPopover, CpSpinner } from '@dome9/components/react/components';
import { createUseStyles } from 'react-jss';
import { useHistory, useLocation } from 'react-router-dom';
import { FormFieldModel } from '../../../common/interface/formFieldModel';
import FormValidations from '../helpers/FormValidations';
import PasswordValidationHelper from '../helpers/passwordValidationHelper';
import { useTranslation } from 'react-i18next';
import { getHttpService } from '../../../common/interface/services';
import { logoutAndRedirectToLogin } from '../../user/User.actions';

interface ResetPasswordRequestModel {
    mfaCode?: string | null | undefined;
    requestId: string;
    password: string;
    verificationToken: string;
}

export const ResetPasswordFrom: React.FC<{}> = () => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [verificationToken, setVerificationToken] = useState<string>('');
    const [requestId, setRequestId] = useState<string>('');
    const [isMfaRequired, setIsMfaRequired] = useState<boolean>(false);
    const [password, setPassword] = useState<FormFieldModel>({ value: '' });
    const [confirmPassword, setConfirmPassword] = useState<FormFieldModel>({ value: '' });
    const [mfaCode, setMfaCode] = useState<FormFieldModel>({ value: '' });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const history = useHistory();
    const searchString = useLocation().search;

    const createAnchorEl = (event: React.MouseEvent<HTMLElement> | null) => {
        if (event) {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const useStyles = createUseStyles({
        resetPasswordForm: {
            flex: 2,
            padding: '3.2rem',
            position: 'relative',
            '& .title': {
                'margin-bottom': '1rem',
                'text-transform': 'uppercase',
                color: '#777',
                'font-weight': 100,
                'font-size': '2em',
            },
            '& form': {
                height: '100%',
                display: 'flex',
                'flex-direction': 'column',

                '& .footer': {
                    'font-size': '12px',
                    'align-self': 'end',
                },
                '& .registerButton': {
                    'align-self': 'flex-end',
                    'margin-top': '10px',
                },
            },
            '& .submit-button': {
                'align-self': 'flex-end',
            },
            '& .error-message': {
                color: 'red',
            },
        },
    });


    const isFormValid = () => {
        const passwordValid = FormValidations.validatePassword(password.value, setPassword);
        const confirmPasswordValid = FormValidations.validateConfirmPassword(
            confirmPassword.value,
            password.value,
            setConfirmPassword
        );
        const isMfaOk = isMfaRequired ? !!mfaCode.value : true;
        if (!isMfaOk) {
            mfaCode.state = 'error';
            mfaCode.errorMessage = 'MFA is required';
        } else {
            mfaCode.state = 'normal';
        }
        return passwordValid && confirmPasswordValid && isMfaOk;
    };

    const classes = useStyles();
    useEffect(() => {
        const verifyRequestId = async (requestId: string) => {
            try {
                const httpService = getHttpService();
                const response = await httpService.request<{ mfa: boolean; verificationToken: string }>(
                    `auth/reset-password/${requestId}`,
                    { method: 'GET' },
                    { publicMode: true }
                );
                setVerificationToken(response.verificationToken);
                setIsMfaRequired(response.mfa);
            } catch (error) {
                logoutAndRedirectToLogin();
            }
        };

        const queryParams = new URLSearchParams(searchString);
        const requestId = queryParams.get('requestId');

        if (requestId) {
            setRequestId(requestId);
            verifyRequestId(requestId);
        }
    }, [searchString,history]);

    const handleSubmit = async () => {
        if (isFormValid()) {
            const data: ResetPasswordRequestModel = {
                mfaCode: isMfaRequired ? mfaCode.value : null,
                requestId: requestId,
                password: password.value,
                verificationToken: verificationToken,
            };
            try {
                const httpService = getHttpService();
                await httpService.post('auth/reset-password', { data }, { publicMode: true });
                logoutAndRedirectToLogin();
            } catch (error: any) {
                setErrorMessage((error as Error).message);
            }
        }
    };
    const { t } = useTranslation();
    return (
        <>
            {verificationToken ?
                <div className={classes.resetPasswordForm}>
                    <h2 className='title'>{t('FORMS.RESET_PASSWORD_FORM.TITLE')}</h2>
                    <form>
                        <CpTextBox
                            onCpChange={(ev: any) => setPassword({ value: ev.target.value })}
                            autofocus
                            value={password.value}
                            helperText={password.errorMessage}
                            state={password.state}
                            type={'password'}
                            label={'Password'}
                            onFocus={(ev: any) => {
                                createAnchorEl(ev);
                            }}
                            onBlur={() => {
                                createAnchorEl(null);
                            }}
                        />
                        <CpPopover anchor={anchorEl} bottom={true} start={true} open={Boolean(anchorEl)}>
                            <PasswordValidationHelper passwordString={password.value} />
                        </CpPopover>
                        <CpTextBox
                            onCpChange={(ev: any) => setConfirmPassword({ value: ev.target.value })}
                            value={confirmPassword.value}
                            helperText={confirmPassword.errorMessage}
                            state={confirmPassword.state}
                            type={'password'}
                            label={t('FORMS.RESET_PASSWORD_FORM.CONFIRM_PASSWORD')}
                        />
                        {isMfaRequired && (
                            <CpTextBox
                                onCpChange={(ev: any) => setMfaCode({ value: ev.target.value })}
                                value={mfaCode.value}
                                helperText={mfaCode.errorMessage}
                                state={mfaCode.state}
                                type={'text'}
                                label={t('FORMS.RESET_PASSWORD_FORM.MFA')}
                            />
                        )}

                        <CpCommonButton className='submit-button' onClick={() => handleSubmit()}>
                            {t('FORMS.RESET_PASSWORD_FORM.SUBMIT_BUTTON')}
                        </CpCommonButton>
                        <p className='error-message'>{errorMessage}</p>
                    </form>
                </div> :
                <div className={classes.resetPasswordForm}>
                    <CpSpinner/>
                    <span>Loading</span>
                </div>}
        </>
    );
};
