import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';

export interface ScopeAction {
    action: string;
    policy: string;
    scope: string;
    score: number;
}

export enum ACTIONS_FIELD_NAMES {
    ACTION = 'action',
    POLICY = 'policy',
    SCOPE = 'scope',
    SCORE = 'score',
}

export interface RoleScopeActions {
    [key: string]: ScopeAction[]
}

export interface IamSensitivity {
    roleScopeActions: RoleScopeActions;
    entityType: string;
}

export interface IamSensitivityTableInfo {
    actions: ScopeAction[],
    roleAsset: IProtectedAssetViewModel,
}

export interface IamSensitivityRoleAndTableInfo {
    id: string,
    actions: ScopeAction[],
    roleAsset: IProtectedAssetViewModel,
}