/* eslint-disable react/prop-types */
import { Select, Tooltip } from '@dome9/berries/react-components';
import { ISelectOption } from 'common/interface/general';
import { DashboardWidgetTypes, IGenericWidgetSettingsProps } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../initialize.i18n';
import { EVENT_ACTIVITY_AWS, SourceOptionsByDataSourceName, __ALL_ACCOUNTS__ } from './Consts';
import { getFilteredCloudAccountsBySource } from './Utils';
import GSLBuilderModal from './GSLBuilderModal';
import { SingleValue } from 'react-select';


const IntelligenceWidgetsSettings: React.FC<IGenericWidgetSettingsProps> = ({ widget, updateWidgetCallback }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const widgetDataSourceName = widget.dataSourceName as keyof typeof SourceOptionsByDataSourceName;
    const [cloudAccountsOptions, setCloudAccountsOptions] = useState([] as ISelectOption[]);
    const [limit, setLimit] = useState(widget.limit);
    const [isGSLBuilderOpen, setIsGSLBuilderOpen] = useState(false);

    if(!widget.timeFrame) {
        widget.timeFrame = 24;
    }
    if(!widget.cloudAccountId){
        widget.cloudAccountId = __ALL_ACCOUNTS__;
    }
    if(!widget.options?.binSize){
        widget.options.binSize = '10m';
    }
    const [widgetClone, setWidgetClone] = useState(deepCloneObject(widget));

    const timeFrameOptions = [
        {
            label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.1_HOUR'),
            value: 1
        },
        {
            label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.3_HOURS'),
            value: 3
        },
        {
            label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.1_DAY'),
            value: 24
        }
    ];

    const binSizeOptions = [
        { label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.1_MINUTE'), value: '1m' },
        { label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.5_MINUTES'), value: '5m' },
        { label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.10_MINUTES'), value: '10m' },
        { label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.15_MINUTES'), value: '15m' },
        { label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.30_MINUTES'), value: '30m' },
        { label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.1_HOUR'), value: '1h' },
        { label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.3_HOURS'), value: '3h' },
        { label: t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.1_DAY'), value: '24h' },
    ];

    const handleChangeGSL = (rule: string) => {
        const _widgetClone = deepCloneObject(widgetClone);
        _widgetClone.gslFilter = rule;
        setWidgetClone(_widgetClone);
        updateWidgetCallback(_widgetClone);
        setIsGSLBuilderOpen(false);
    };

    const handleChangeCloudAccount = (option: SingleValue<ISelectOption>) => {
        const _widgetClone = deepCloneObject(widgetClone);
        _widgetClone.cloudAccountId = option?.value;
        setWidgetClone(_widgetClone);
        updateWidgetCallback(_widgetClone);
    };

    const handleChangeTimeFrame = (option: SingleValue<ISelectOption>) => {
        const _widgetClone = deepCloneObject(widgetClone);
        _widgetClone.timeFrame = option?.value;
        setWidgetClone(_widgetClone);
        updateWidgetCallback(_widgetClone);
    };

    const handleChangeLimit = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.valueAsNumber;
        const _widgetClone = deepCloneObject(widgetClone);
        _widgetClone.limit = value;
        setWidgetClone(_widgetClone);
        updateWidgetCallback(_widgetClone);
        setLimit(value);
    };

    const handleChangeBinSize = (option: SingleValue<ISelectOption>) => {
        const _widgetClone = deepCloneObject(widgetClone);
        _widgetClone.options.binSize = option?.value;
        setWidgetClone(_widgetClone);
        updateWidgetCallback(_widgetClone);
    };

    const getCloudAccountsOptions = useCallback(async () => {
        const filteredCloudAccounts = await getFilteredCloudAccountsBySource(widgetDataSourceName);
        const _cloudAccountsOptions = filteredCloudAccounts.map(cloudAccount => {
            return {
                label: cloudAccount.name,
                value: cloudAccount.externalId,
            };
        });
        _cloudAccountsOptions.unshift({
            label: t('WIDGETS.WIDGET_SETTINGS.ALL_CLOUD_ACCOUNTS'),
            value: __ALL_ACCOUNTS__,
        });
        setCloudAccountsOptions(_cloudAccountsOptions);

    }, [t, widgetDataSourceName]);


    useEffect(() => {
        getCloudAccountsOptions();
    }, [getCloudAccountsOptions, widgetClone, updateWidgetCallback]);


    function openGSLBuilderModal() {
        setIsGSLBuilderOpen(true);
    }

    function onGSLBuilderModalClosed() {
        setIsGSLBuilderOpen(false);
    }

    return (
        <div className="flex">
            <div className="flex-1 space-y-5">
                {( widgetClone.type !== DashboardWidgetTypes.Trend && widgetClone.type !== DashboardWidgetTypes.Map ) &&
                    <div className="font-semibold mb-2">
                        {t('WIDGETS.WIDGET_SETTINGS.ITEMS_COUNT')}:
                        <div className='mt-2 flex items-center'>
                            <div className='mr-4'>
                                <input onChange={handleChangeLimit} type="range" step={5} min={5} max={50} value={limit} />
                            </div>
                            <div>
                                ({limit})
                            </div>
                        </div>
                    </div>
                }
                { widgetClone.dataSourceName === EVENT_ACTIVITY_AWS && widgetClone.type === DashboardWidgetTypes.Trend &&
                    <div className="font-semibold mb-2">
                        {t('WIDGETS.WIDGET_SETTINGS.BIN_SIZE')}:
                        <Select
                            required={false}
                            value={binSizeOptions?.filter(option => option.value === widgetClone.options?.binSize)}
                            onChange={(option: SingleValue<ISelectOption>) => handleChangeBinSize(option)}
                            closeMenuOnSelect={true}
                            placeholder={t('WIDGETS.WIDGET_SETTINGS.BIN_SIZE_PLACEHOLDER')}
                            isSearchable={true}
                            options={binSizeOptions}
                        />
                    </div>
                }
                <div className="font-semibold mb-2">
                    {t('WIDGETS.WIDGET_SETTINGS.CLOUD_ACCOUNT_TITLE')}:
                    <Select
                        required={false}
                        value={cloudAccountsOptions?.filter(option => option.value === widgetClone.cloudAccountId)}
                        onChange={(option: SingleValue<ISelectOption>) => handleChangeCloudAccount(option)}
                        closeMenuOnSelect={true}
                        placeholder={t('WIDGETS.WIDGET_SETTINGS.CLOUD_ACCOUNT_PLACEHOLDER')}
                        isSearchable={true}
                        options={cloudAccountsOptions}
                    />
                </div>
                <div className="font-semibold mb-2">
                    {t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.TITLE')}:
                    <Select
                        required={false}
                        value={timeFrameOptions?.filter(option => option.value === widgetClone.timeFrame) as any} // TODO: fix this
                        onChange={(option: SingleValue<ISelectOption>) => handleChangeTimeFrame(option)}
                        closeMenuOnSelect={true}
                        placeholder={t('WIDGETS.WIDGET_SETTINGS.TIME_FRAMES.PLACEHOLDER')}
                        isSearchable={true}
                        options={timeFrameOptions}
                    />
                </div>
                <div className="font-semibold mb-2">
                    {t('WIDGETS.GSL_BUILDER.GSL_FILTER')}:
                    <div className='h-[30px] border border-black justify-end flex flex-center  pr-[10px]'>
                        <div className='flex w-full px-4'>
                            <Tooltip placement='top' content={widgetClone?.gslFilter} >
                                <span className='grow truncate px-2' >{widgetClone?.gslFilter}</span>
                            </Tooltip>
                            <a className='shrink-0 whitespace-nowrap w-[100px] cursor-pointer text-info' onClick={openGSLBuilderModal}>{t('WIDGETS.GSL_BUILDER.OPEN_GSL_BUILDER')}</a>
                        </div>
                        <GSLBuilderModal predefinedRuleText={widgetClone?.gslFilter} onConfirm={handleChangeGSL} onCancel={onGSLBuilderModalClosed} isOpen={isGSLBuilderOpen} assetType={widgetDataSourceName}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntelligenceWidgetsSettings;

