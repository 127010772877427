import { FC } from 'react';
import { Stack, IconButton, Card, Chip } from 'common/design-system/components-v2';

interface CloudBotsListProps {
    savedCloudBots: string[];
    editCloudBot: (bot: string) => void;
    removeCloudBot: (bot: string) => void;
    maxCloudBots: number;
}
const CloudBotsList: FC<CloudBotsListProps> = ({ savedCloudBots, editCloudBot, removeCloudBot, maxCloudBots }) => {
    return (
        <>
            <Stack spacing={2} direction={'column'}>
                { savedCloudBots?.map((bot, index) => (
                    <Card
                        key={`${bot}-${index}`}
                        title={bot.includes(' ') ? bot.split(' ')[0] : bot}
                        iconProps={{ name: 'robot' }}
                        rightContent={
                            <Stack direction="row" spacing={1}>
                                { bot.includes(' ') && <IconButton iconProps={{ name: 'edit', size: 16 }} variant="text" onClick={() => editCloudBot(bot)}></IconButton> }
                                <IconButton iconProps={{ name: 'delete', size: 16 }} variant="text" onClick={() => removeCloudBot(bot)}></IconButton>
                            </Stack>}/>
                ))}
            </Stack>
            <Stack spacing={2} fullWidth direction={'column'} alignItems={'flex-end'}>
                <Chip label={`${savedCloudBots.length}/${maxCloudBots}`} color={'normal'}/>
            </Stack>
        </>
    );
};

export default CloudBotsList;