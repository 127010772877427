import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { Chip, Stack } from 'common/design-system/components-v2';

const NSGStorageNameCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });

    return (
        <Stack alignItems='center' direction='row' spacing={2}>
            {params.data.storageName}
            {!params.data.storageExist && 
                <Chip 
                    label={t('NSG.NEW')} 
                    context='medium' 
                    leadingIconProps={{ name: 'info' }}
                    size='sm'
                    withBorder={false}
                />
            }
        </Stack>
    );
};

export default NSGStorageNameCellRenderer;
