import { AxiosResponse } from 'axios';
import { getStoreService, ICacheEntry, ICacheLookupResult, ICachingConfig } from 'common/interface/services';
import ApiCaching, { getRequestSignature, isMethodHasCache, markEntryAsResolved } from 'common/utils/apiCaching';

export const runAlternativePromise = <T,P>(
    apiCaching: ApiCaching,
    origEntry: ICacheEntry,
    method: string,
    alternativePromise: Promise<AxiosResponse<T>>,
    tags?: string[]): Promise<AxiosResponse<T>> => {

    const alternativeEntry: ICacheEntry<T> = apiCaching.createAlternativeEntry<T,P>(origEntry, method, alternativePromise, tags);
    return alternativePromise.then((response: AxiosResponse<T>) => {
        markEntryAsResolved<T>(alternativeEntry);
        const currEntry: ICacheEntry<T> | undefined = apiCaching.getCacheEntry<T>(alternativeEntry.uniqueKey, method);
        if (currEntry !== alternativeEntry) {
            apiCaching.insertAlternativeEntryToCache<T>(alternativeEntry, method);
        }
        return response;
    });
};

export const runRequest = async <T,P=any>(
    apiCaching: ApiCaching,
    route: string,
    method: string,
    promiseCreator: () => Promise<AxiosResponse<T>>,
    payload?: P,
    cachingConfig?: ICachingConfig,
): Promise<AxiosResponse<T>> => {
    const hasMethodRelatedCache = isMethodHasCache(method);
    const shouldLookup = hasMethodRelatedCache && cachingConfig?.useCache;
    const entryKey: string = getRequestSignature<P>(route, payload, cachingConfig);
    
    // LOOKUP FOR CACHED PROMISE
    const lookupResult: ICacheLookupResult<T> | undefined = shouldLookup ? apiCaching.lookupCacheEntry<T>(method, entryKey, cachingConfig.dataAgeLimit) : undefined;
    let response: AxiosResponse<T> | undefined;
    if (lookupResult) {
        if (lookupResult.isValid) {
            if (apiCaching.isAllowedRefreshAhead<T>(lookupResult.entry)) {
                const alternativePromise = promiseCreator();
                void runAlternativePromise<T,P>(apiCaching, lookupResult.entry, method, alternativePromise, cachingConfig?.tags);
            }
            response = await lookupResult.entry.promise;
        } else {
            if (lookupResult.entry.alternativeEntry) {
                response = await lookupResult.entry.alternativeEntry.promise;
            } else {
                const promise = promiseCreator();
                response = await runAlternativePromise<T,P>(apiCaching, lookupResult.entry, method, promise, cachingConfig?.tags);
            }
        }
    
        // RETURN CACHED RESPONSE
        return response;
    }
    
    // LOOKUP DID NOT FIND DATA => STORE NEW PROMISE
    const promise: Promise<AxiosResponse<T>> = promiseCreator();
    let savedEntry: ICacheEntry<T> | undefined;
    if (hasMethodRelatedCache) {
        savedEntry = apiCaching.savePromiseToCache<T,P>(entryKey, route, method, promise, payload, cachingConfig?.tags);
    }
    
    // RESOLVE NEW PROMISE
    response = await promise;

    if (savedEntry) {
        markEntryAsResolved<T>(savedEntry);
    }
    
    // RETURN RESPONSE OF NEW PROMISE
    return response;
};

export const getRouteFromPath = (path: string, apiPrefix: string, baseUrl?: string): string => {
    const state = getStoreService().state;
    const finalBaseUrl = baseUrl ? '' : state.app.apiUrl;
    return path.startsWith('http') ? path : `${finalBaseUrl}/${apiPrefix}/${path}`;
};