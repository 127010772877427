import {
    FINDING_SERVICE_ID,
    getGslService,
    IFindingService,
} from 'common/module_interface/intelligence/intelligence';
import { IGslRunResponse } from 'common/module_interface/intelligence/Intelligence.interface';
import {
    IServerValueToUiValueConvertors,
    mapGslResponseToType,
} from 'common/module_interface/intelligence/Intelligence.utils';
import { convertStringToNumber, formatComments } from '../Findings/Findings.utils';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IGslCount, IGslFilter, IGslSort } from 'common/erm-components/services/gsl/GslService.interface';
import { IFindingGslFilter } from 'common/module_interface/intelligence/Gsl/FindingGslService.interface';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { INTELLIGENCE_SOURCE } from 'common/module_interface/intelligence/Gsl/GslService.const';
import { isBoolean } from 'common/erm-components/utils/types';

export class FindingService implements IFindingService {

    public async getFindings(filter: IFindingGslFilter, limit?: number, sort?: IGslSort[]): Promise<IFinding[]> {
        const gslFilter: IGslFilter = {
            source: INTELLIGENCE_SOURCE.alerts,
            ...filter
        };
        const response: IGslRunResponse = await getGslService().getItems(gslFilter, limit, sort);

        const valueConvertors: IServerValueToUiValueConvertors = {
            accountId: convertStringToNumber,
            assessmentCloudAccountType: convertStringToNumber,
            originId: convertStringToNumber,
            rulesetId: convertStringToNumber,
            severityLevel: convertStringToNumber,
            statusId: convertStringToNumber,
            isAcknowledged: isBoolean,
            isExcluded: isBoolean,
            comments: formatComments,
        };
        return mapGslResponseToType<IFinding>(FindingFields, response, valueConvertors);
    }

    public async getFindingFacets(filter: IFindingGslFilter, aggregations: string[]): Promise<Aggregations> {
        const gslFilter: IGslFilter = {
            source: INTELLIGENCE_SOURCE.alerts,
            ...filter,
        };
        return await getGslService().getAggregations(gslFilter, aggregations);
    }

    public async getFindingCount(filter: IFindingGslFilter, count: IGslCount): Promise<number> {
        const gslFilter: IGslFilter = {
            source: INTELLIGENCE_SOURCE.alerts,
            ...filter,
        };
        return await getGslService().getCount(gslFilter, count);
    }
}

export default function initializeFindingGslService() {
    globalAddinContainer.addService(FINDING_SERVICE_ID, new FindingService());
}
