import React, { useEffect, useState } from 'react';
import { EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import { EdgeData } from '../../Common/Models/GraphModels';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import AccountActivityEdgePopoverComponent from './AccountActivityEdgePopoverComponent';
import OccurrencesTableComponent from '../../Common/Components/OccurencesTableComponent';
import { Tooltip } from 'common/design-system/components-v2';

const AccountActivityEdge: React.FC<EdgeProps> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    style,
    markerEnd,
}) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const edgeData = data as EdgeData;
    const [label, setLabel] = useState<string | null>(null);
    useEffect(() => {
        if (edgeData?.additionalData?.events) setLabel(edgeData.additionalData.events[0]);
    }, [edgeData?.additionalData?.events, data]);

    return <g style={style}>
        <defs>
            <mask id={id}>
                <rect
                    x="0"
                    y="0"
                    fill="white"
                    height="10000"
                    width="10000"
                    transform="translate(-1000,-1000)"
                />
                <polygon
                    className={'react-flow__edge-fat-arrow'}
                    height="10"
                    width="10"
                    transform="translate(-8,-8)"
                    points="12,14 0,14 4,8 0,2 12,2 16,8 "
                    fill="black"
                    style={{
                        offsetPath: `path('${edgePath}')`,
                    }}
                />
            </mask>
            <linearGradient id={`gradient-${id}`} x1='0%' y1='0%' x2='100%' y2='0%'>
                <stop className="edge-stop--start" offset="10%"></stop>
                <stop className="edge-stop--end" offset="60%"></stop>
            </linearGradient>
            <marker
                className="edge-stop--arrow"
                id={`marker-end-${id}`}
                viewBox="0 0 40 40"
                markerHeight={8}
                markerWidth={8}
                refX={11}
                refY={8}>
                <path d="M2.1,15c-1,0.5-2.1-0.2-2.1-1.3V8V2.3C0,1.3,1.1,0.6,2.1,1l11.3,5.7c1.1,0.5,1.1,2.1,0,2.6L2.1,15z" />
            </marker>
        </defs>

        <path
            id={id}
            className="react-flow__custom-edge-path"
            stroke={`url(#gradient-${id}) ${edgeData.targetColor}`}
            d={edgePath}
            markerEnd={markerEnd}
        />
        {label && (
            <EdgeLabelRenderer>
                <Tooltip content={AccountActivityEdgePopoverComponent(edgeData, t)} interactive={true} appendTo={() => document.body} delay={200} maxWidth={400}>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            background: `linear-gradient(45deg, ${edgeData.sourceColor} 10%, ${edgeData.targetColor} 60%)`,
                            padding: '0 5px',
                            borderRadius: 5,
                            fontSize: 11,
                            color: 'white',
                            fontWeight: 600,
                            maxWidth: 150,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                        { label }
                    </div>
                </Tooltip>
            </EdgeLabelRenderer>
        )}
        <OccurrencesTableComponent edgeData={edgeData} labelX={labelX} labelY={labelY} />
    </g>;
};
export default AccountActivityEdge;
