import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    CloudAccountGet,
    CloudAccountNameUpdate,
    CloudAccountsCredentialsUpdate,
    CloudAccountDelete,
    CloudAccountDeleteForce
} from './azureCloudAccounts.interface';


const cloudAccountGet: CloudAccountGet.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountGet.Response>>(
        `AzureCloudAccount/${id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountNameUpdate: CloudAccountNameUpdate.Function = async (request: CloudAccountNameUpdate.Request) => {
    return await getHttpService().request<AxiosResponse<CloudAccountNameUpdate.Response>>(
        `AzureCloudAccount/${request.cloudAccountId}/AccountName`,
        { method: 'PUT', data: { name: request.name } },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountsCredentialsUpdate: CloudAccountsCredentialsUpdate.Function = async (request: CloudAccountsCredentialsUpdate.Request) => {
    return await getHttpService().request<AxiosResponse<CloudAccountsCredentialsUpdate.Response>>(
        `AzureCloudAccount/${request.cloudAccountId}/credentials`,
        { method: 'PUT', data: request.data },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountDelete: CloudAccountDelete.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountDelete.Response>>(
        `AzureCloudAccount/${id}`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountDeleteForce: CloudAccountDeleteForce.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountDeleteForce.Response>>(
        `AzureCloudAccount/${id}/DeleteForce`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountsSyncNow: any = async (id: string) => {
    return await getHttpService().request<AxiosResponse<any>>(
        `AzureCloudAccount/${id}/SyncNow`,
        { method: 'POST', data: { id } },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const AzureCloudAccountsService = {
    cloudAccountGet,
    cloudAccountNameUpdate,
    cloudAccountsCredentialsUpdate,
    cloudAccountDelete,
    cloudAccountDeleteForce,
    cloudAccountsSyncNow
};

export default AzureCloudAccountsService;
