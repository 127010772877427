import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setAzureOnboardingOperationMode, getAzureOnboardingOperationMode } from '../helpers/azure.reducer';
import { AzureOnboardingOperationMode, IInstructionTypeCardContent } from '../helpers/azure.interface';
import { azureSingleOperationsCards } from '../helpers/azure.consts';
import { getStoreService } from 'common/interface/services';
import { Stack } from 'common/design-system/components-v2';
import InstructionCard from './InstructionCard';

const AzureSingleOperation = () => {
    const { i18n } = useTranslation();
    const { dispatch } = getStoreService().getReduxTools();
    const azureOperationMode = useSelector(getAzureOnboardingOperationMode);
    const [azureCards, setAzureCards] = useState(azureSingleOperationsCards());
    useEffect(() => {
        // to change translation in const's
        setAzureCards(azureSingleOperationsCards());
    }, [i18n.language]);
    
    const handleSelectedCard = (value: AzureOnboardingOperationMode) => {
        dispatch(setAzureOnboardingOperationMode(value));
    };

    return (
        <Stack data-aid="azure-operation" direction="row" justifyContent='flex-start' alignItems='flex-start' spacing={2}>
            {azureCards.map((card: IInstructionTypeCardContent) => <Stack key={card.title} direction="row" style={{ width: '50%', minWidth: '200px', flexGrow: 1 }}>
                <InstructionCard config={card} isSelected={azureOperationMode === card.value} onCardSelected={handleSelectedCard}/>
            </Stack>
            )}
        </Stack>
    );
};

export default AzureSingleOperation;
