export const TitleMap: Record<string, string> = {
    AwsIamGroup: 'AWS IAM Group',
    AwsIamUser: 'AWS IAM User',
    AwsSecurityGroup: 'AWS Security Group',
    AwsVpc: 'AWS VPC',
    AwsSubnet: 'AWS Subnet',
    AwsAppLoadBalancerBase: 'AWS ALB',
    AwsAppLoadBalancerTargetGroup: 'Target Group',
    AwsElasticLoadBalancer: 'AWS ELB',
    AwsNetworkLoadBalancer: 'AWS NLB',
    AwsNacl: 'Network Access Control List',
    AwsNatGateway: 'AWS NAT Gateway',
    AwsNetworkInterface: 'Network Interface',
    AwsEC2Instance: 'AWS EC2 Instance',
    AwsApiGateway: 'AWS API Gateway',
    AwsAutoScalingGroup: 'AWS Auto Scaling Group',
    AwsCloudAccount: 'Account',
    AwsEcsCluster: 'AWS ECS Cluster',
    AwsEcsService: 'AWS ECS Service',
    AwsEksCluster: 'AWS EKS Cluster',
    AwsEcsTask: 'AWS ECS Task',
    AwsEcsTaskDefinition: 'AWS ECS Task Definition',
    AwsIamPolicy: 'AWS IAM Policy',
    AwsIamRole: 'AWS IAM Role',
    AwsLambdaFunction: 'AWS Lambda',
    AwsOrganizationAccount: 'AWS Organization Account',
    AwsOrganizationUnit: 'AWS Organization Unit',
    AwsRouteTable: 'AWS Route Table',
    AwsOrganization: 'Organization',
    AwsRedshiftCluster: 'AWS Redshift',
    AwsS3Bucket: 'AWS S3 Bucket',
    AwsWafCoverage: 'CG WAF',
};