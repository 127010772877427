import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataSecurityStyled } from './DataSecurityDashboard.styled';
import SecurityIssuesAndEvents from './SecurityIssuesAndEvents/SecurityIssuesAndEvents';
import DataInventory from './DataInventory/DataInventory';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { DataSecuritySummaryStatus } from './SummaryStatus/DataSecuritySummaryStatus';
import { DataSecurityFilterPanel } from './DataSecurityFilterPanel/DataSecurityFilterPanel';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import FullSizeError from 'common/erm-components/custom/FullSize/FullSizeError';
import { EmptyState } from './EmptyState/EmptyState';
import { Stack } from 'common/design-system/components-v2';
import {
    fetchEmptyEnvironmentsStateInfo,
    fetchIsFullEmptyState,
} from './DataSecurityDashboard.datasource';
import { IEnvironmentsEmptyStateInfo } from '../../../RiskManagement.interface';

enum DashboardState {
    IS_LOADING,
    EMPTY_STATE,
    DATA_LOADED,
    LOADING_FAILED,
}

const DataSecurityDashboard: React.FC = () => {
    const [filterValues, setFilterValues] = useState<IFiltersValues>();
    const [dashboardState, setDashboardState] = useState<DashboardState>(DashboardState.IS_LOADING);
    const isFullEmptyStateRef = useRef<boolean>();
    const [emptyEnvStateInfo, setEmptyEnvStateInfo] = useState<IEnvironmentsEmptyStateInfo>();

    const onFilterChanged = useCallback((filterVals: IFiltersValues) => {
        setFilterValues(filterVals || []);
    }, []);

    const loadData = useCallback(async () => {
        try {
            if (isFullEmptyStateRef.current) {
                return;
            }
            if (isFullEmptyStateRef.current === undefined) {
                isFullEmptyStateRef.current = await fetchIsFullEmptyState();
                if (isFullEmptyStateRef.current) {
                    setDashboardState(DashboardState.EMPTY_STATE);
                    return;
                }
            }
            const emptyEnvInfo: IEnvironmentsEmptyStateInfo = await fetchEmptyEnvironmentsStateInfo(filterValues);
            setEmptyEnvStateInfo(emptyEnvInfo);
            setDashboardState(DashboardState.DATA_LOADED);
        } catch (error) {
            console.error(error);
            setDashboardState(DashboardState.LOADING_FAILED);
        }
    }, [filterValues]);

    useEffect(() => {
        void loadData();
    }, [loadData, filterValues]);

    return (
        <DataSecurityStyled.TopDiv>
            {dashboardState === DashboardState.IS_LOADING && <FullSizeSpinner /> }
            {dashboardState === DashboardState.LOADING_FAILED && <FullSizeError /> }
            {dashboardState === DashboardState.EMPTY_STATE && <EmptyState /> }
            {dashboardState === DashboardState.DATA_LOADED &&
                <Stack spacing={5}>
                    <DataSecuritySummaryStatus />
                    <DataSecurityFilterPanel onFilterChanged={onFilterChanged} />
                    <DataInventory filterValues={filterValues} emptyEnvStateInfo={emptyEnvStateInfo}/>
                    <SecurityIssuesAndEvents filterValues={filterValues} />
                </Stack>
            }
        </DataSecurityStyled.TopDiv>
    );
};

export default DataSecurityDashboard;