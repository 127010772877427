import React from 'react';
import { IEvent } from 'common/module_interface/events/Events';
import ComplianceFindingApi from 'modules/events/services/complianceFindingApi/complianceFindingApi.service';
import { ComplianceFindingSeverity } from 'modules/events/services/complianceFindingApi/complianceFindingApi.interface';

export interface UseMutateEventSeverityRequest {
    onSuccess?: (response: ComplianceFindingSeverity.Response) => void;
    onError?: (error: string) => void;
}

export interface UseMutateEventSeverityPayload {
    isLoading: boolean;
    isError: boolean;
    results?: ComplianceFindingSeverity.Response;

    changeSeverity: (events: IEvent[], details: ComplianceFindingSeverity.Request['details']) => Promise<void>;
    reset?: () => void;
}

export type UseMutateEventSeverityReset = (req?: UseMutateEventSeverityRequest) => UseMutateEventSeverityPayload;

export const useMutateEventSeverity: UseMutateEventSeverityReset = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<ComplianceFindingSeverity.Response>();

    const changeSeverity = React.useCallback(async (events: IEvent[], details: ComplianceFindingSeverity.Request['details']) => {
        setIsLoading(true);
        try {
            const response = await ComplianceFindingApi.changeSeverity({
                ids: events.map(event => event.id),
                details
            });
            setResults(response.data);
            req?.onSuccess?.(response.data);
        } catch (error: any) {
            setIsError(true);
            req?.onError?.(error?.message || 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    }, [req]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setResults(undefined);
    }, []);

    return ({
        isLoading,
        isError,
        results,

        changeSeverity,
        reset
    });
};