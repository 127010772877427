import React from 'react';
import { Location } from 'common/utils/history';
import {
    IInternalLinkMenuItem,
    IGroupMainMenuItem,
    IInternalMenuBase,
    IInternalUrlMenuItem,
    INavigableMenuItem,
} from './Interface';
import { IMainMenuItem, IMenuSection, IMenuSectionItem } from 'common/interface/menu';
import GroupMenuItemRenderer from './CellRenderers/GroupMenuItemRenderer';
import LinkMenuItemRenderer from './CellRenderers/LinkMenuItemRenderer';
import UrlMenuItemRenderer from './CellRenderers/UrlMenuItemRenderer';

export class InternalMainMenuItem implements IGroupMainMenuItem {
    id: string;
    path: string[];
    descriptionKeys: string[];
    icon: string;
    iconColor: string;
    isPreview: boolean;
    mainMenuChipTitle: string;
    label: string;
    state: string;
    urlAliases: (string | RegExp)[];
    isNotSelectable: boolean;
    groupState: string;
    component: React.FC<{ menuItem: IInternalMenuBase }>;

    constructor(mainMenuItem: IMainMenuItem, groupState: string) {
        this.path = [mainMenuItem.id];
        this.id = this.path.join('.');
        this.descriptionKeys = mainMenuItem.descriptionKeys ?? [];
        this.icon = mainMenuItem.icon;
        this.iconColor = mainMenuItem.iconColor ?? ''; //TODO:set some default here
        this.isPreview = mainMenuItem.isPreview ?? false;
        this.mainMenuChipTitle = mainMenuItem.mainMenuChipTitle ?? '';
        this.label = mainMenuItem.label;
        this.state = mainMenuItem.state ?? '';
        this.urlAliases = mainMenuItem.urlAliases ?? [];
        this.isNotSelectable = !this.state;
        this.groupState = groupState ?? '/';
        this.component = GroupMenuItemRenderer;
    }
}

export class InternalSectionMenuItem implements IGroupMainMenuItem {
    id: string;
    path: string[];
    descriptionKeys: string[];
    icon: string;
    iconColor: string;
    isPreview: boolean;
    mainMenuChipTitle: string;
    label: string;
    state: string;
    urlAliases: (string | RegExp)[];
    isNotSelectable: boolean;
    groupState: string;
    component: React.FC<{ menuItem: IInternalMenuBase }>;

    constructor(section: IMenuSection, parentPath: string[], groupState: string) {
        this.path = parentPath.concat([section.id]);
        this.id = this.path.join('.');
        this.label = section.title ?? '';
        this.groupState = groupState ?? '/';
        this.component = GroupMenuItemRenderer;

        this.state = '';
        this.isNotSelectable = !this.state;
        this.urlAliases = [];
        this.icon = '';
        this.iconColor = '';
        this.descriptionKeys = [];
        this.isPreview = false;
        this.mainMenuChipTitle = '';
    }
}

export class InternalLinkMenuItem implements IInternalLinkMenuItem {
    id: string;
    loadingItem?: boolean;
    path: string[];
    chip?: { label: string };
    icon?: string;
    isBackground: boolean;
    isReact: boolean;
    label: string;
    state: string;
    urlAliases: (string | RegExp)[];
    component: React.FC<{ menuItem: IInternalMenuBase }>;

    constructor(sectionMenuItem: IMenuSectionItem, parentPath: string[]) {
        this.path = parentPath.concat([sectionMenuItem.id]);
        this.id = this.path.join('.');
        this.chip = sectionMenuItem.chip ? { ...sectionMenuItem.chip } : undefined;
        this.icon = sectionMenuItem.icon;
        this.isBackground = sectionMenuItem.isBackground ?? false;
        this.isReact = sectionMenuItem.isReact ?? false;
        this.label = sectionMenuItem.label;
        this.state = sectionMenuItem.state ?? '';
        this.urlAliases = sectionMenuItem.urlAliases ?? [];
        this.loadingItem = sectionMenuItem.loadingItem;
        this.component = LinkMenuItemRenderer;
    }
}

export class InternalUrlMenuItem implements IInternalUrlMenuItem {
    id: string;
    path: string[];
    label: string;
    url: string;
    component: React.FC<{ menuItem: IInternalMenuBase }>;

    constructor(sectionMenuItem: IMenuSectionItem, parentPath: string[]) {
        this.path = parentPath.concat([sectionMenuItem.id]);
        this.id = this.path.join('.');
        this.label = sectionMenuItem.label;
        this.url = sectionMenuItem.url!;
        this.component = UrlMenuItemRenderer;
    }
}

function getMainMenuInternalState(mainMenuItem: IMainMenuItem): string {
    if (mainMenuItem.state) {
        return mainMenuItem.state;
    }
    if (mainMenuItem.sections?.length) {
        for (const section of mainMenuItem.sections) {
            const internalState = getMenuSectionInternalState(section);
            if (internalState) {
                return internalState;
            }
        }
    }
    return '';
}

function getMenuSectionInternalState(sectionMenuItem: IMenuSection): string {
    const navigableItem = sectionMenuItem.items?.find(item => !item.url && item.state);
    if (navigableItem) {
        return navigableItem.state!;
    }
    return '';
}

export function buildInternalMenu(mainMenuItems: IMainMenuItem[]): IInternalMenuBase[] {
    const internalMenu: IInternalMenuBase[] = [];

    for (const menuItem of mainMenuItems) {
        let internalGroupState = getMainMenuInternalState(menuItem);
        const internalMenuItem = new InternalMainMenuItem(menuItem, internalGroupState);
        internalMenu.push(internalMenuItem);
        menuItem.sections?.forEach(section => {
            let path = internalMenuItem.path;
            if (section.title && section.items && section.items.length > 0) {
                internalGroupState = getMenuSectionInternalState(section);
                const internalSection = new InternalSectionMenuItem(section, internalMenuItem.path, internalGroupState);
                internalMenu.push(internalSection);
                path = internalSection.path;
            }
            section.items?.forEach( sectionItem => {
                if (sectionItem.url) {
                    internalMenu.push(new InternalUrlMenuItem(sectionItem, path));
                } else {
                    internalMenu.push(new InternalLinkMenuItem(sectionItem, path));
                }
            });
        });
    }

    return internalMenu;
}

function matchQueryString(preDefined: URLSearchParams, currentURL: URLSearchParams) {
    for (const key of preDefined.keys()) {
        if (!currentURL.has(key)) { //Key doesn't exist in the target
            return false;
        }
        const preDefinedValues = preDefined.getAll(key);
        const targetValues = currentURL.getAll(key);
        const intersection = preDefinedValues.filter(preDefinedValue => targetValues.includes(preDefinedValue));
        if (intersection.length === 0) {
            return false;
        }

    }
    return true;
}

function getIsSelectedItemByLocation(menuItem: INavigableMenuItem, location: any, shouldMatchQueryString = true): INavigableMenuItem | null {
    function extractAllMenuItemUrls(menuItem: INavigableMenuItem): (string | RegExp)[] {
        const result: (string | RegExp)[] = [];
        if (menuItem.state) {
            result.push(menuItem.state);
        }
        if (menuItem.urlAliases) {
            result.push(...menuItem.urlAliases);
        }
        return result;
    }

    const itemUrls = extractAllMenuItemUrls(menuItem);
    const currentURL = new URLSearchParams(location.search);

    for (const itemUrl of itemUrls) {
        if (itemUrl instanceof RegExp) {
            if (itemUrl.test(location.pathname)) {
                return menuItem;
            }
        } else {
            const url = new URL(itemUrl, window.location.origin);
            const queryString = new URLSearchParams(url.search);

            if (location.pathname === url.pathname) {
                if (shouldMatchQueryString) {
                    if (matchQueryString(queryString, currentURL)) {
                        return menuItem;
                    }
                } else {
                    return menuItem;
                }
            }
        }
    }

    return null;
}

export function selectMenuSelectionForLocation(internalMenu: IInternalMenuBase[], location: Location<unknown>): IInternalMenuBase | null {
    for (const menuItem of internalMenu) {
        if (!(menuItem instanceof InternalLinkMenuItem))
            continue;
        const internalLinkMenu = menuItem as InternalLinkMenuItem;
        const selectedMenuItem = getIsSelectedItemByLocation(internalLinkMenu, location);
        if (selectedMenuItem) {
            return menuItem;
        }
    }
    // If specific menu item (InternalLinkMenuItem) was not found, search for a 1st level menu item with partial match
    for (const menuItem of internalMenu) {
        if (!(menuItem instanceof InternalMainMenuItem))
            continue;
        const internalMenuItem = menuItem as InternalMainMenuItem;
        const selectedMenuItem = getIsSelectedItemByLocation(internalMenuItem, location, false);
        if (selectedMenuItem) {
            return menuItem;
        }
    }

    return null;
}

export function getMenuItemByPath(internalMenu: IInternalMenuBase[], path: string[]): IInternalMenuBase | undefined {
    const currentMenuItemId = path.join('.');
    const menuItem = internalMenu.find(item => item.id === currentMenuItemId);

    return menuItem;
}
