import { getMagellanLinkGeneratorService } from 'common/module_interface/intelligence/intelligence';
import { Button, Spinner } from 'common/design-system/components-v2';
import { i18nIntelligenceNamespace } from '../../../../../initialize.i18n';
import React, { useEffect, useMemo } from 'react';
import { getCloudAccountsService } from 'common/interface/data_services';
import { getGslGraphCountDataFromFinding } from '../../../Services/IntelligenceHttpService';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { useTranslation } from 'react-i18next';
import { ALink } from 'common/components/ALink';

export const ExploreLogsButton: React.FC<{ finding: IFinding }> = ({ finding }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isEnabled, setIsEnabled] = React.useState<boolean>(false);

    const link = useMemo(() => getMagellanLinkGeneratorService().generateLinkForFinding(finding), [finding]);

    useEffect(() => {
        async function checkAvailableLogs() {
            const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(finding.cloudAccountId);
            if (!cloudAccount?.isLogicEnabled) {
                setIsEnabled(false);
            }
            else {
                const graphDataCount = await getGslGraphCountDataFromFinding(finding);
                if (graphDataCount) {
                    setIsEnabled(true);
                }
                else {
                    setIsEnabled(false);
                }
            }
            setIsLoading(false);
        }

        if (!link) return;
        void checkAvailableLogs();
    }, [finding, link]);

    const getTooltipInfo = () => {
        if (!isEnabled) {
            return t('INT_FINDING_OVERVIEW.EXPLORE_LOGS.DISABLED');
        }
        return '';
    };

    return ( <>
        { isLoading && <Spinner /> }
        { !isLoading && <ALink href={link} target={'_blank'} rel="noreferrer">
            <Button color="normal" iconProps={{ name: 'notebookWithMagnify' }} disabled={!isEnabled} tooltip={getTooltipInfo()}>
                { t('INT_FINDING_OVERVIEW.EXPLORE_LOGS.TITLE') }
            </Button>
        </ALink> }
    </>);
};
