import React from 'react';
import { singletonHook } from 'react-singleton-hook';
import { EventsTableTab, IEventsDatasource } from 'common/interface/events';
import { ISearchFilterViewModel } from 'common/components/Findings/Findings.interface';

interface IUseEventsTableImple {
    isLoading: boolean;
    fetchingData: boolean;
    activeFilters?: ISearchFilterViewModel;
    setSelectedTab: (tab: EventsTableTab) => void;
    dataSource?: IEventsDatasource;
    setArchiveView: React.Dispatch<React.SetStateAction<boolean>>;

    dataValid: boolean;
    setDataValid: React.Dispatch<React.SetStateAction<boolean>>;
}

type UseEventsTableImple = () => IUseEventsTableImple;
const useEventsTableImple: UseEventsTableImple = (): IUseEventsTableImple => {
    const [selectedTab, setSelectedTab] = React.useState<EventsTableTab>();
    const [archiveView, setArchiveView] = React.useState<boolean>(false);
    const [fetchingData, setFetchingData] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [activeFilters, setActiveFilters] = React.useState<ISearchFilterViewModel>();
    const [dataSource, setDataSource] = React.useState<IEventsDatasource>();

    const [dataValid, setDataValid] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!selectedTab) return;
        const newDataSource = selectedTab.createDataSource(archiveView);
        const subscribeKey = newDataSource.subscribeToStateChange((state) => {
            setDataValid(true);
            setIsLoading(state.isLoading);
            setFetchingData(state.isFeching);
            setActiveFilters(state.filters);
        });
        setDataSource(newDataSource);

        return () => {
            newDataSource.unsubscribeFromStateChange(subscribeKey);
        };
    }, [selectedTab, archiveView]);

    return {
        dataSource,
        isLoading,
        activeFilters,
        fetchingData: fetchingData,
        setSelectedTab,
        setArchiveView,

        dataValid,
        setDataValid,
    };
};


const useEventsTable: UseEventsTableImple = singletonHook({
    isLoading: true,
    fetchingData: true,
    setSelectedTab: () => null,
    setArchiveView: () => false,
    dataValid: false,
    setDataValid: () => false,
}, useEventsTableImple);
export default useEventsTable;