import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
    CheckboxSelectionCallbackParams,
    FirstDataRenderedEvent,
    GetRowIdParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IRowNode
} from 'ag-grid-community';
import { StatusType } from 'common/components/StatusMessagePanel/StatusMessagePanel.types';
import StatusCellRenderer from 'common/components/ag-grid/Renderers/StatusCellRenderer';
import StatusMessagePanel from 'common/components/StatusMessagePanel/StatusMessagePanel';
import { Icon, Input, Stack, Table } from 'common/design-system/components-v2';
import { getStoreService } from 'common/interface/services';
import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';
import {
    AzureOnboardingStep,
    AzureOnboardingStepTitle,
    SearchInputWrapper,
} from '../../AzureIntelligenceOnboarding.styled';
import { IReportingSubscriptionsModel } from '../../AzureIntelligenceOnboarding.interface';
import {
    getAllSubscriptions,
    getSelectedSubscriptions,
    setSelectedSubscriptions
} from '../../AzureIntelligenceOnboarding.reducer';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import Switch from 'common/design-system/components-v2/Switch';

const Subscriptions: FunctionComponent = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });
    const dispatch = getStoreService().dispatch;

    const allSubscriptions = useSelector(getAllSubscriptions);
    const selectedSubscriptions = useSelector(getSelectedSubscriptions);

    const [isHideSubscriptions, setIsHideSubscriptions] = useState(false);
    const [rowData, setRowData] = useState<IReportingSubscriptionsModel[]>([]);
    const [selectedRows, setSelectedRows] = useState<IReportingSubscriptionsModel[]>();
    const [defaultSelectedRow, setDefaultSelectedRow] = useState<IRowNode[]>();
    const apiRef = React.useRef<GridApi>();
    const { subscription } = useParams<{ subscription: string }>();

    const defaultColDef = {
        resizable: true,
        suppressMenu: true,
        suppressMovable: true,
        flex: 1,
    };

    useEffect(() => {
        setRowData(allSubscriptions);
    }, [allSubscriptions]);

    useEffect(() => {
        if (!selectedSubscriptions.length && selectedRows?.length) {
            apiRef?.current?.deselectAll();
            setSelectedRows([]);
        }
        if (defaultSelectedRow?.length && defaultSelectedRow[0].rowIndex !== null) {
            apiRef?.current?.setNodesSelected({ nodes: defaultSelectedRow, newValue: true });
        }
    }, [selectedSubscriptions, selectedRows?.length, defaultSelectedRow]);

    useEffect(() => {
        apiRef?.current?.refreshCells({ force: true });
    }, [selectedRows]);

    const getColumnDefs = () => {
        return [
            {
                field: 'subscriptionId',
                tooltipField: 'subscriptionId',
                headerName: t('SUBSCRIPTIONS.SUBSCRIPTION_ID'),
                headerCheckboxSelection: false,
                checkboxSelection: (
                    params: CheckboxSelectionCallbackParams<IReportingSubscriptionsModel>
                ) => {
                    return !!params.data && params.data.status === ConnectionStatus.notConnected && params.data.subscriptionId !== subscription;
                },
                showDisabledCheckboxes: true,
                width: 200
            },
            {
                field: 'name',
                tooltipField: 'name',
                headerName: t('SUBSCRIPTIONS.SUBSCRIPTION_NAME')
            },
            {
                field: 'status',
                tooltipField: 'status',
                headerName: t('STATUS.TITLE'),
                filter: 'agTextColumnFilter',
                cellRenderer: StatusCellRenderer
            },
        ];
    };

    const onGridReady = (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
        apiRef.current = params.api;
    };

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        apiRef.current?.setQuickFilter(event.target.value);
    };

    const applyExternalFilter = () => {
        const filterModel = {
            status: {
                type: 'notEqual',
                filter: ConnectionStatus.connected,
            }
        };
        apiRef.current?.setFilterModel(filterModel);
        apiRef.current?.onFilterChanged();
    };

    const clearExternalFilter = () => {
        apiRef.current?.setFilterModel(null);
        apiRef.current?.onFilterChanged();
    };

    const onHideSubscriptions = (event: ChangeEvent<HTMLInputElement>) => {
        setIsHideSubscriptions(!isHideSubscriptions);

        if (event.target.checked) {
            applyExternalFilter();
        } else {
            clearExternalFilter();
        }
    };

    const onSelectionChanged = () => {
        const selectedRows: IReportingSubscriptionsModel[] = apiRef.current?.getSelectedRows() ?? [];
        const filteredRows = selectedRows.filter(row => row.status === ConnectionStatus.notConnected);

        setSelectedRows(filteredRows);
        dispatch(setSelectedSubscriptions(filteredRows));
    };

    const gridOptions: GridOptions = {
        rowData: rowData,
        columnDefs: getColumnDefs(),
        rowSelection: 'multiple',
        defaultColDef: defaultColDef,
        enableBrowserTooltips: true,
        suppressRowClickSelection: true,
        localeText: { totalRows: t('TABLE_ITEMS.TOTAL') },
        getRowId: (params: GetRowIdParams) => params.data.subscriptionId,
        onFirstDataRendered: (params: FirstDataRenderedEvent<IReportingSubscriptionsModel>) => {
            const nodesToSelect: IRowNode[] = [];

            params.api.forEachNode((node: IRowNode) => {
                if (node.data && node.data.subscriptionId === subscription) {
                    nodesToSelect.push(node);
                }
            });
            setDefaultSelectedRow(nodesToSelect);
            params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
        },
        onGridReady,
        onSelectionChanged
    };

    return (
        <AzureOnboardingStep>
            <AzureOnboardingStepTitle>{t('STEPS.SUBSCRIPTIONS')}</AzureOnboardingStepTitle>

            <Stack padding={[4, 0]}>
                {t('SUBSCRIPTIONS.DESCRIPTION')}
            </Stack>

            <StatusMessagePanel type={StatusType.INFO}
                text={<Trans>{t('SUBSCRIPTIONS.NOTE')}</Trans>}
            />

            <>
                <Stack direction={'row'} spacing={6} alignItems='center' justifyContent='flex-end' margin={[2, 2, 2]}>
                    <Switch checked={isHideSubscriptions} onChange={onHideSubscriptions} label={t('STORAGE.HIDE_STORAGE')} />
                    <SearchInputWrapper>
                        <Input 
                            placeholder={t('SUBSCRIPTIONS.SEARCH_PLACEHOLDER')} 
                            startAdornment={<Icon name='magnify' />} 
                            onChange={onSearchChange}
                            fullWidth
                        />
                    </SearchInputWrapper>
                </Stack>
            </>

            <Stack fullHeight fullWidth>
                <Table gridOptions={gridOptions} disableColumnMenu={true} disableGrouping={true}/>
            </Stack>
        </AzureOnboardingStep>
    );
};

export default Subscriptions;
