import { CpSpinner } from '@dome9/components/react/components';
import { getCloudAccountsService, ICloudAccount } from 'common/interface/data_services';
import { getStoreService, ICustomzationObject } from 'common/interface/services';
import { IDashboard } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import {
    getAllDashboardsFromServer,
    getDefaultDashboardFromServer,
    getFavoritesDashboardsFromServer, TDashboardResponse,
} from 'modules/overview/Api';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import initializeAWSDefaultDashboard from '../../LocalDashboards/InitializeAWSDefaultDashboard';
import initializeAzureDefaultDashboard from '../../LocalDashboards/InitializeAzureDefaultDashboard';
import initializeGCPDefaultDashboard from '../../LocalDashboards/InitializeGCPDefaultDashboard';
import initializeHomeDashboard from '../../LocalDashboards/InitializeHomeDashboard';
import initializeKubernetesDefaultDashboard from '../../LocalDashboards/InitializeKubernetesDefaultDashboard';
import initializeOCIDefaultDashboard from '../../LocalDashboards/InitializeOCIDefaultDashboard';
import initializeServerlessDefaultDashboard from '../../LocalDashboards/InitializeServerlessDefaultDashboard';
import { setAllDashboards, setDefaultDashboardId, setFavoriteDashboardsIds, setRegisteredLocalDashboards, setSelectedDashboard } from '../../Overview.reducer';
import {
    addIdsToSectionsAndWidgets,
    goToDashboardPage,
    goToDefaultGlobalHomePage,
} from '../../Utils';
import DashboardActions from './DashboardActions';
import { GenericDashboardPage } from './GenericDashboardPage';
import { isChina } from 'common/utils/dataCenter';
import { runAll } from 'common/utils/helpFunctions';
import { URL_PREFIX } from 'common/utils/history';
import { DEFAULT_GLOBAL_HOME_PAGE_ID } from 'common/module_interface/overview/Consts';

interface IInitialDashboardData {
    allDashboardsFromServer: TDashboardResponse;
    favoriteDashboardsResponse?: ICustomzationObject<string[]>;
    defaultDashboardResponse?: ICustomzationObject<string>;
    allCloudAccounts: ICloudAccount[];
}

const getInitialDashboardData = async (): Promise<IInitialDashboardData> => {
    const promisesMap: any = {
        allDashboardsFromServer: getAllDashboardsFromServer(),
        favoriteDashboardsResponse: getFavoritesDashboardsFromServer(),
        defaultDashboardResponse: getDefaultDashboardFromServer(),
        allCloudAccounts: getCloudAccountsService().getAllCloudAccounts(true),
    };
    return (await runAll(promisesMap, true)) as IInitialDashboardData;
};

const isInSpecificPage = () => {
    if (!window.location?.pathname) {
        return false;
    }
    const route = window.location.pathname.replace(/\/+$/, '').trim();
    return route && (route !== URL_PREFIX);
};

export const DashboardPageContainer: React.FC = () => {
    const location = useLocation();
    const dispatch = getStoreService().dispatch;
    const queryParams = new URLSearchParams(location.search);
    const dashboardName = queryParams.get('name');
    const [isLoadingAllDashboards, setIsLoadingAllDashboards] = useState(true);
    const [selectedDashboard, _setSelectedDashboard] = useState(null as null | IDashboard);

    const initDashboards = useCallback(async () => {
        try {
            const initialData = await getInitialDashboardData();
            const allDashboardsFromServerCloned = deepCloneObject(initialData.allDashboardsFromServer);
            const allDashboardWithIds = addIdsToSectionsAndWidgets(allDashboardsFromServerCloned);
            const favoriteDashboardsIds = initialData.favoriteDashboardsResponse?.data || [];
            const _defaultDashboardId = initialData.defaultDashboardResponse?.data || DEFAULT_GLOBAL_HOME_PAGE_ID;
            const allCloudAccounts: ICloudAccount[] = initialData.allCloudAccounts;
            const registeredLocalDashboards = isChina() ? [
                initializeHomeDashboard(allCloudAccounts),
                initializeAWSDefaultDashboard(allCloudAccounts),
                initializeAzureDefaultDashboard(),
            ] : [
                initializeHomeDashboard(allCloudAccounts),
                initializeAWSDefaultDashboard(allCloudAccounts),
                initializeAzureDefaultDashboard(),
                initializeGCPDefaultDashboard(),
                initializeKubernetesDefaultDashboard(),
                initializeOCIDefaultDashboard(),
                initializeServerlessDefaultDashboard(),
            ];

            const _allDashboards = [...registeredLocalDashboards, ...allDashboardWithIds];
            const _selectedDashboard = _allDashboards.find(dashboard => dashboard.name === dashboardName);
            const defaultDashboard = _allDashboards.find(dashboard => dashboard.id === _defaultDashboardId);

            dispatch(setAllDashboards(_allDashboards));
            dispatch(setFavoriteDashboardsIds(favoriteDashboardsIds));
            dispatch(setDefaultDashboardId(_defaultDashboardId));
            dispatch(setRegisteredLocalDashboards(registeredLocalDashboards));

            if (!isInSpecificPage()) {
                if (_selectedDashboard) {
                    dispatch(setSelectedDashboard(_selectedDashboard));
                    _setSelectedDashboard(_selectedDashboard);
                } else if (defaultDashboard) {
                    dispatch(setSelectedDashboard(defaultDashboard));
                    goToDashboardPage(defaultDashboard);
                } else {
                    goToDefaultGlobalHomePage();
                }
            }
            setIsLoadingAllDashboards(false);
        } catch (error) {
            console.error(error);
        }

    } ,[dashboardName, dispatch]);

    useEffect(()=>{
        void initDashboards();
    }, [initDashboards]);

    return (
        <div className={'overview-module flex flex-1 h-full flex-col absolute inset-0'}>
            <div className="flex p-7 border-b flex-0">
                {selectedDashboard &&
                        <DashboardActions selectedDashboard={selectedDashboard}></DashboardActions>
                }
            </div>
            {isLoadingAllDashboards ?
                <div className="flex flex-1 items-center justify-center">
                    <CpSpinner />
                </div> :
                <div className="flex-1 overflow-auto p-8">
                    {selectedDashboard && <GenericDashboardPage selectedDashboard={selectedDashboard} />}
                </div>
            }
        </div>
    );
};
