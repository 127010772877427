import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { getNotificationsService, getUserService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { FormValues, inputValidations } from './AwsEditCredentials.types';
import { ModalsProps } from '..';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { useGetAws } from '../../../reducer/hooks/useGetAws';
import { getAwsEnvNamespace } from '../../../initialize.i18n';
import { useAwsSecretGet } from '../../../services/cloudAccounts/hooks/useAwsSecretGet';
import { useUpdateAws } from '../../../reducer/hooks/useUpdateAws';
import { getCheckpointUrl } from 'common/components/Forms/urls';
import { ALink } from 'common/components/ALink';
import { Button, Stack, Typography } from 'common/design-system/components-v2';
import Input from 'common/design-system/components-v2/Input';
import { UpdateAwsReq } from '../../../reducer/aws.actions';

const AwsEditCredentials: React.FC<ModalsProps> = ({ closeModal, awsId }) => {
    const { data: awsData } = useGetAws(awsId);
    const { type, apikey, arn, secret } = awsData?.account.credentials || {};
    const { t } = useTranslation(getAwsEnvNamespace('aws'));
    const { isOwner } = getUserService().getUser();
    const allowEditExternalID = true;
    const [credentialsType, setCredentialsType] = React.useState<'UserBased' | 'RoleBased' | undefined>(type);
    const canGenerate = React.useMemo(() => credentialsType === 'RoleBased', [credentialsType]);
    const { isLoading: isSecretGetLoading, data: secretData, reloadData: getSecret } = useAwsSecretGet();
    
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm<FormValues>({
        defaultValues: {
            apikey,
            arn: arn,
            secret: secret,
        }
    });

    const generateSecret = React.useCallback(async () => {
        if (!awsData?.account.externalAccountNumber) return;
        if (secretData?.secret) {
            setValue('secret', secretData.secret);
            return;
        }
        getSecret(awsData.account.externalAccountNumber);
    }, [awsData, getSecret, secretData, setValue]);

    React.useEffect(() => {
        if (canGenerate) {
            generateSecret();
        }
    }, [canGenerate, generateSecret]);

    React.useEffect(() => {
        if (secretData) {
            setValue('secret', secretData.secret);
        }
    }, [secretData, setValue]);

    const handleOnUpdateSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.onSubmit.success`),
            text: '',
        });
        onClose();
    };
    const handleOnUpdateError = () => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.onSubmit.error`),
            text: '',
        });
    };

    const { updateAws, isLoading: isUpdateLoading } = useUpdateAws({ onError: handleOnUpdateError, onSuccess: handleOnUpdateSuccess });

    const submitUpdate = async (data: FormValues) => {
        if (!credentialsType) return;
        const { apikey, arn, secret } = data;
        const payload: UpdateAwsReq = {
            credentials: {
                data: {
                    secret,
                    type: credentialsType,
                    apikey: credentialsType === 'UserBased' ? apikey : null,
                    arn: credentialsType === 'RoleBased' ? arn : null,
                }
            }
        };
        updateAws(awsId, payload);
    };

    const onClose = () => {
        closeModal();
    };

    return (
        <ConfirmationModal
            title={t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.header.${credentialsType === 'UserBased' ? 'userBased' : 'roleBased'}`)}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => submitUpdate(data))}
            isLoading={isUpdateLoading}
            width='md'
        >
            {(credentialsType === 'UserBased' && awsData?.account.vendor === 'aws') && (
                <Stack spacing={1}>
                    <Typography variant='bodyLg'>
                        <Trans
                            components={{ readMoreLink: <ALink href={getCheckpointUrl('CONVERT_CONNECTION_FROM_USER_TO_ROLE')} target='_blank' rel='noreferrer' /> }}
                            i18nKey={`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.userBasedText.text`}
                        />
                    </Typography>
                    <Button color='brandPrimary' onClick={() => setCredentialsType('RoleBased')}>{t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.userBasedText.switchToIamRoleButton`)}</Button>
                </Stack>
            )}
            <Stack spacing={4}>
                {credentialsType === 'UserBased' && (
                    <Stack spacing={1}>
                        <Controller
                            name='apikey'
                            rules={inputValidations['apikey']}
                            control={control}
                            render={({ field }) => (
                                <Stack>
                                    <Input
                                        {...field}
                                        placeholder={t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.fields.key.placeholder`)}
                                        label={t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.fields.key.label`)}
                                        helperText={errors['apikey']? errors['apikey']['message'] : ''}
                                        isError={errors['apikey'] ? true : false}
                                    />
                                </Stack>
                            )}
                        />
                    </Stack>
                )}
                {credentialsType === 'RoleBased' && (
                    <Stack spacing={1}>
                        <Controller
                            name='arn'
                            rules={inputValidations['arn']}
                            control={control}
                            render={({ field }) => (
                                <Stack>
                                    <Input
                                        fullWidth={true}
                                        {...field}
                                        placeholder={t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.fields.arn.placeholder`)}
                                        label={t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.fields.arn.label`)}
                                        helperText={errors['arn']? errors['arn']['message'] : ''}
                                        isError={errors['arn'] ? true : false}
                                    />
                                </Stack>
                            )}
                        />
                    </Stack>
                )}
                <Stack spacing={1}>
                    <Controller
                        name='secret'
                        rules={inputValidations['secret']}
                        control={control}
                        render={({ field }) => (
                            <Stack spacing={1}>
                                <Stack direction='row' fullWidth justifyContent='center' alignItems='center' spacing={2}>
                                    <Stack direction='column' fullWidth>
                                        <Input
                                            fullWidth={true}
                                            {...field}
                                            readOnly={!isOwner && !allowEditExternalID}
                                            disabled={isSecretGetLoading}
                                            placeholder={t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.fields.${credentialsType === 'UserBased' ? 'secret' : 'externalId'}.placeholder`)} label={t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.fields.${credentialsType === 'UserBased' ? 'secret' : 'externalId'}.label`)}
                                            helperText={errors['secret']? errors['secret']['message'] : ''}
                                            isError={errors['secret'] ? true : false}
                                        />
                                    </Stack>
                                    <Button style={{ alignSelf: 'flex-end' }} disabled={!canGenerate} onClick={generateSecret} color='brandPrimary' loading={isSecretGetLoading}>
                                        {t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.buttons.generate`)}
                                    </Button>
                                </Stack>
                                {credentialsType === 'RoleBased' && (
                                    <Stack alignItems='flex-end'>
                                        <ALink href={getCheckpointUrl('CONVERT_CONNECTION_FROM_USER_TO_ROLE')} target='_blank' rel='noreferrer'>{t(`${getAwsEnvNamespace('aws')}:awsPage.modals.editCredentials.buttons.iamRoleSetupInstructions`)}</ALink>
                                    </Stack>
                                )}
                            </Stack>

                        )}
                    />
                </Stack>
            </Stack>
        </ConfirmationModal>
    );
};

export default AwsEditCredentials;
