import { getAccountService, RULES_MANAGEMENT_SERVICE_ID } from 'common/interface/services';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { getService } from 'common/extensibility/AddinContainer';
import RuleManageService from 'modules/postureManagement/RulesManagement/services/RuleManagement.service';
import { setRemediationPageData } from 'modules/postureManagement/remediation/reducer/remediation.actions';
import { RemediationModuleType } from 'common/interface/remediation';
import { Vendors } from 'common/consts/vendors';

let cachedResponses: any = null;

export const getCiemRuleset = () =>{
    return [{ id: 802 , name: 'Entitlement Management', platform: Vendors.GENERIC }];
};

const getRulesetByType = async (sourceType: RemediationModuleType) => {
    switch (sourceType) {
        case RemediationModuleType.CSPM:
            return await getService<RuleManageService>(RULES_MANAGEMENT_SERVICE_ID).getRulesetsViewsFromComplianceServer();
        case RemediationModuleType.CIEM:
            return getCiemRuleset();
        default:
            return null;
    }
};

export const initRemediationPageData = async (sourceType: RemediationModuleType) => {
    if (cachedResponses) {
        return cachedResponses;
    }
    try {
        const [organizationalUnits, rulesets, cloudAccounts, license] = await Promise.all([
            getOrganizationalUnitService().getOrganizationalUnitsView(),
            getRulesetByType(sourceType),
            getCloudAccountsService().getAllCloudAccounts(),
            getAccountService().getLicense(false),
            // getMyAccountData(), how to get me details?
        ]);
        // Cache and return the combined data
        cachedResponses = { organizationalUnits, rulesets, cloudAccounts, license };
    } catch (error) {
        console.error('Failed to initialize remediation page data:', error);
        throw error;
    }
    setRemediationPageData(cachedResponses);
    return cachedResponses;
};

const RemediationService = {
    initRemediationPageData,
    getCiemRuleset,
};
export default RemediationService;
