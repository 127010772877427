import { RootState } from 'common/services/store/store';
import i18n from 'common/services/translations/translations';
import { t } from 'i18next';
import { addNotification } from 'platform/main/Notifications/Notifications.reducer';
import { NotificationType } from 'common/interface/notifications';
import { getNotificationsService, getStoreService } from 'common/interface/services';
import { AWS_INTERVALS } from './aws.const';
import { AwsOnboardingAccountType, AwsOnboardingType, IntelligenceRulesetDTO, Ruleset } from './aws.interface';
import {
    getAwsStep,
    getIsOrgSelected,
    getChinaPolicyNotValid,
    goBackInAwsStep,
    goToNextAwsStep,
    resetToDefaultIntelligenceRulesets,
    resetToDefaultRulesets,
    setAwsCfts,
    setAwsOnboardingId,
    setAwsOnboardingUrl,
    setBladeStatuses,
    setDefaultIntelligenceRulesets,
    setDefaultRulesets,
    setEnableIntelligence,
    setEnableServerless,
    setGotIntelligenceRulesetsFromServer,
    setGotRulesetsFromServer,
    setIntelligenceRulesets,
    setWelcomeStepClicked,
    setIsTakingLongerThanExpectedAccount,
    setIsTakingLongerThanExpectedSummary,
    setLambdaRepoUrl,
    setNextButtonDisabled,
    setOrgOnboardingDone,
    setOrgOnboardingManagementAccountExternalId,
    setOrgOnboardingMemberAccountConfigurations,
    setRulesets,
    setIsAccountAlreadyOnboarded,
    setIsManagementOnboarded,
    setIsWizardBusy,
    setIsSuccessModalShow,
    isManagementAccountDetails
} from './aws.reducer';
import {
    getBladeStatusesFromServer,
    getCftData,
    getIntelligenceRulesetsFromServer,
    getMemeberAccountConfigurations,
    getOrganizationalOnboardingData,
    getRulesetsFromServer,
    postManagementStack,
    validateManagementStackArn,
    validateStackSetArn
} from './aws.service';

export let summaryInterval: NodeJS.Timer;

export const startSummaryInterval = async () => {
    await getBladeStatusesAndSaveItToState();
    summaryInterval = setInterval(() => {
        getBladeStatusesAndSaveItToState();
    }, AWS_INTERVALS.SUMMARY);
};

export const getCftDataAndSaveToState = async () => {
    const { dispatch, state } = getStoreService().getReduxTools();
    try {
        const isOrgOnboarding = state.onboarding.isOrgOnboarding;
        dispatch(setAwsCfts(undefined));
        dispatch(setAwsOnboardingUrl(undefined));
        dispatch(setAwsOnboardingId(undefined));
        dispatch(setLambdaRepoUrl(undefined));
        dispatch(setIsManagementOnboarded(undefined));
        const buildIntelligenceParams = () => {
            return {
                enabled: state.onboarding.enableIntelligence,
                rulesets: state.onboarding.enableIntelligence ? state.onboarding.selectedIntelligenceRulesets : [],
            };
        };
        const dataRequest = isOrgOnboarding ? getOrganizationalOnboardingData : getCftData;
        const cftData = await dataRequest({
            cloudVendor: state.onboarding.awsOnboardingAccountType,
            onboardType: state.onboarding.awsOnboardingType,
            fullProtection: state.onboarding.selectedProtection === 'fullProtection',
            postureManagementConfiguration: {
                rulesets: state.onboarding.selectedRulesets,
            },
            serverlessConfiguration: {
                enabled: state.onboarding.enableServerless,
            },
            intelligenceConfigurations: buildIntelligenceParams(),
            enableStackModify: state.onboarding.allowCGUpdateStack,
        });
        dispatch(setAwsCfts(cftData.cfts));
        dispatch(setAwsOnboardingUrl(cftData.cftUrl));
        dispatch(setAwsOnboardingId(cftData.onboardingId));
        dispatch(setLambdaRepoUrl(cftData.lambdaRepoUrl));
        if (cftData.isManagementOnboarded) dispatch(setIsManagementOnboarded(cftData.isManagementOnboarded));
        if(isOrgOnboarding) {
            dispatch(setOrgOnboardingManagementAccountExternalId(cftData.externalId));
        }
        return false;
    } catch (e) {
        dispatch(setIsAccountAlreadyOnboarded(true));
        return true;
    }
};

export const getBladeStatusesAndSaveItToState = async () => {
    const { state, dispatch } = getStoreService();
    if (state.onboarding.awsOnboardingId) {
        const statuses = await getBladeStatusesFromServer(state.onboarding.awsOnboardingId as string);
        dispatch(setBladeStatuses(statuses));
    }
};

function setServerlessAndIntelligenceSelection(
    currentStep: number,
    onboardingType: AwsOnboardingType,
    awsOnboardingAccountType: AwsOnboardingAccountType
) {
    const dispatch = getStoreService().dispatch;
    if (currentStep === 0) {
        const enable = awsOnboardingAccountType === AwsOnboardingAccountType.aws;
        dispatch(setEnableIntelligence(enable));
        dispatch(setEnableServerless(enable));
    }
}

function getIsNextPageSummary(
    forward: boolean,
    currentStep: number,
    onboardingType: AwsOnboardingType,
    state: RootState
) {
    return (
        forward &&
        ((currentStep === 1 && onboardingType === AwsOnboardingType.simple) ||
            (currentStep === 5 &&
                onboardingType === AwsOnboardingType.advanced &&
                state.onboarding.awsOnboardingAccountType === AwsOnboardingAccountType.aws) ||
            (currentStep === 3 &&
                onboardingType === AwsOnboardingType.advanced &&
                state.onboarding.awsOnboardingAccountType !== AwsOnboardingAccountType.aws))
    );
}

function getIsNextPageAccount(
    forward: boolean,
    currentStep: number,
    onboardingType: AwsOnboardingType,
    state: RootState
) {
    return (
        forward &&
        ((currentStep === 0 && (onboardingType === AwsOnboardingType.simple) || (onboardingType === AwsOnboardingType.organizational)) ||
            (currentStep === 4 &&
                onboardingType === AwsOnboardingType.advanced &&
                state.onboarding.awsOnboardingAccountType === AwsOnboardingAccountType.aws) ||
            (currentStep === 2 &&
                onboardingType === AwsOnboardingType.advanced &&
                state.onboarding.awsOnboardingAccountType !== AwsOnboardingAccountType.aws))
    );
}
export const goToStep = async (forward: boolean) => {
    const { dispatch, state } = getStoreService().getReduxTools();
    try {
        const alertsService = getNotificationsService();
        dispatch(setIsWizardBusy(true));
        let isAlreadyOnboarded = false;
        const currentStep = state.onboarding.awsStep;
        const onboardingType = state.onboarding.awsOnboardingType;
        const awsOnboardingAccountType = state.onboarding.awsOnboardingAccountType;
        const isSummary = getIsNextPageSummary(forward, currentStep, onboardingType, state);
        const isSetupAccount = getIsNextPageAccount(forward, currentStep, onboardingType, state);
        if (isSetupAccount) {
            dispatch(setWelcomeStepClicked());
            if (getChinaPolicyNotValid(state)) {
                return;
            }
        }
        setServerlessAndIntelligenceSelection(currentStep, onboardingType, awsOnboardingAccountType);
        const isOrgManagement = getIsOrgSelected(state) && getAwsStep(state) === 1 && forward;
        const isOrgMemberAccount = getIsOrgSelected(state) && getAwsStep(state) === 2 && forward;
        const isOrgOnboardingCDRStep = getIsOrgSelected(state) && getAwsStep(state) === 3 && isManagementAccountDetails(state) && forward;
        if (isOrgManagement) {
            return validateManagementStackArnAction();
        }
        if (isOrgMemberAccount) {
            return validateStackSetArnAction();
        }
        if (isOrgOnboardingCDRStep) {
            return postOrgOnboardingAction();
        }
        if (isSetupAccount) {
            isAlreadyOnboarded = await getCftDataAndSaveToState();
        }
        if (isSummary && !state.onboarding.userClickedLaunchStack) {
            alertsService.addNotification({
                text: i18n.t('ON_BOARDING.POSTURE_MANAGEMENT.PLEASE_DEPLOY_STACK_TEXT'),
                type: NotificationType.ERROR,
                title: i18n.t('ON_BOARDING.POSTURE_MANAGEMENT.PLEASE_DEPLOY_STACK_HEADER'),
            });
        }

        if (isAlreadyOnboarded) return;

        if (forward) {
            dispatch(goToNextAwsStep());
        } else {
            dispatch(goBackInAwsStep());
        }
    } catch (error) {
        console.error('goToStep error', error);
    } finally {
        dispatch(setIsWizardBusy(false));
    }
};

export const getIntelligenceRulesetsFromServerAndSaveToState = async () => {
    const dispatch = getStoreService().dispatch;
    dispatch(setGotIntelligenceRulesetsFromServer(false));
    let rulesets = await getIntelligenceRulesetsFromServer();
    rulesets = rulesets.filter((ruleset) => ruleset.vendor === 'aws');
    dispatch(
        setDefaultIntelligenceRulesets(
            rulesets.filter((ruleset: IntelligenceRulesetDTO) => ruleset.is_preselected).map((ruleset) => +ruleset.id)
        )
    );
    dispatch(setIntelligenceRulesets(rulesets.sort((a: IntelligenceRulesetDTO) => (a.is_preselected ? -1 : 1))));
    dispatch(resetToDefaultIntelligenceRulesets());
    dispatch(setGotIntelligenceRulesetsFromServer(true));
};

export const getRulesetsFromServerAndSaveToState = async () => {
    const { dispatch, state } = getStoreService().getReduxTools();
    dispatch(setGotRulesetsFromServer(false));
    const rulesets = await getRulesetsFromServer();
    dispatch(setDefaultRulesets(rulesets.filter((ruleset) => ruleset.default).map((ruleset) => ruleset.id)));
    const stateAfterDefaults = state.onboarding.defaultRulesets;
    dispatch(
        setRulesets(
            rulesets
                .filter((rule: Ruleset) => rule.cloudVendor === 'aws')
                .sort((a: Ruleset) => (stateAfterDefaults.indexOf(a.id) > -1 ? -1 : 1))
        )
    );
    dispatch(resetToDefaultRulesets());
    dispatch(setGotRulesetsFromServer(true));
};

export const backToDefaultRulesetsAction = async () => {
    const { dispatch } = getStoreService().getReduxTools();

    await dispatch(setGotRulesetsFromServer(false));
    await dispatch(resetToDefaultRulesets());
    await dispatch(setGotRulesetsFromServer(true));
};
export const backToDefaultIntelligenceRulesetsAction = async () => {
    const { dispatch } = getStoreService().getReduxTools();
    await dispatch(setGotIntelligenceRulesetsFromServer(false));
    await dispatch(resetToDefaultIntelligenceRulesets());
    await dispatch(setGotIntelligenceRulesetsFromServer(true));
};

export const timoutIsTakingLongerThenExpectedAccount = (timeout: number) => {
    const { dispatch } = getStoreService().getReduxTools();
    setTimeout(() => {
        dispatch(dispatch(setIsTakingLongerThanExpectedAccount(true)));
    }, timeout);
};

export const timoutIsTakingLongerThenExpectedSummary = (timeout: number) => {
    const { dispatch } = getStoreService().getReduxTools();
    setTimeout(() => {
        dispatch(setIsTakingLongerThanExpectedSummary(true));
    }, timeout);
};

export const couldntAssumeRoleHandler = (error: unknown)=>{
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(addNotification({ 
        type: NotificationType.ERROR, 
        text: String(error) === t('HTTP.SERVER_ERROR_CODE', { errorCode: '500' }) ? 
            t('ON_BOARDING.ACCOUNT.COULDNT_ASSUME_ROLE') : 
            String(error),
        autoClose: 10000
    }));

};

export const validateManagementStackArnAction = async () => {
    const { state, dispatch } = getStoreService().getReduxTools();
    let arnValid = true;
    dispatch(setNextButtonDisabled(true));
    try {
        await validateManagementStackArn({
            roleArn: state.onboarding.orgOnboardingArnRole, 
            secret: state.onboarding.orgOnboardingManagementAccountExternalId
        });
        const memberAccountConfigurations = await getMemeberAccountConfigurations();
        dispatch(setOrgOnboardingMemberAccountConfigurations(memberAccountConfigurations));
    } catch (error) {
        couldntAssumeRoleHandler(error);
        arnValid = false;
    } finally {
        if(arnValid) {
            dispatch(goToNextAwsStep());
        }
        dispatch(setIsWizardBusy(false));
        dispatch(setNextButtonDisabled(false));
    }
};

export const validateStackSetArnAction = async () => {
    const { state, dispatch } = getStoreService().getReduxTools();
    let arnValid = true;
    dispatch(setNextButtonDisabled(true));
    try {
        await validateStackSetArn({
            roleArn: state.onboarding.orgOnboardingArnRole,
            secret: state.onboarding.orgOnboardingManagementAccountExternalId,
            stackSetArn: state.onboarding.memberAccountArn
        });
    } catch (error) {
        couldntAssumeRoleHandler(error);
        arnValid = false;
    } finally {
        if(arnValid) {
            dispatch(goToNextAwsStep());
        }
        dispatch(setIsWizardBusy(false));
        dispatch(setNextButtonDisabled(false));
    }
};


export const postOrgOnboardingAction = async () => {
    const { state, dispatch } = getStoreService().getReduxTools();
    const hasOrgOnboardingCDRStep = isManagementAccountDetails(state);
    try {
        await postManagementStack({
            enableStackModify: true,
            secret: state.onboarding.orgOnboardingManagementAccountExternalId,
            roleArn: state.onboarding.orgOnboardingArnRole,
            awsOrganizationName: state.onboarding.orgOnboardingOrganizationName,
            stackSetArn: state.onboarding.memberAccountArn
        });
        if (hasOrgOnboardingCDRStep) {
            dispatch(goToNextAwsStep());
        } else {
            dispatch(setIsSuccessModalShow(true));
            dispatch(setOrgOnboardingDone(true));
        }
    } catch (error) {
        couldntAssumeRoleHandler(error);
    }
};
