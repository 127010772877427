
export const ALIASES = {
    CLIENT_ID_MSP: 'msp',
    CSRFM: 'csrfm',
    CLIENT_ID_CLOUD_INFRA: 'cloudInfraSSO',
    CLIENT_ID_WEBAPP: 'webapp',
    CURRENT_CLIENT_ID: 'aud',
    USER_NAME: 'username',
    LOGIN_ID: 'a',
    CLOUD_INFRA_URL: 'cu',
    LOCAL_EXPIRATION_TIME: 'zuz',
    AUTH_URL: 'au',
    SSOID: 'sso_Id',
    API_KEY: 'apiKey',
    API_SECRET: 'apiSecret',
    REFRESH_PROCESS: 'refreshing',
    REFRESH_TIMESTAMP: 'ts',
    AUTHENTICATE_RUNNING: 'isau',
    CLOUD_INFRA_FIRST_TIME_TOKEN: 'firstTimeToken',
    ROLE: 'role',
    ROOT_USERNAME: 'root_username',
    REFRESH_TOKEN: 'p',
};

export const MESSAGES = {
    REFRESH_TOKEN: 'refresh_token',
    REFRESH_TOKEN_SUCCESS: 'refresh_token_success',
    NAVIGATE: 'navigate',
    REFRESH_TOKEN_FAILED: 'refresh_token_failed',
    LOGOUT: 'logout',
    USER_LOGOUT: 'user_logout',
    REDIRECT_TO_LOGIN_PAGE: 'redirect_to_login_page',
    CLOUD_INFRA_ERROR_PAGE: 'redirect_to_cloud_infra_error_page',
    AUTH_KEYS: 'auth_keys',
    AUTHENTICATE_WITH_CLOUD_INFRA: 'authenticate_with_cloud_infra',
    AUTHENTICATE_TOKEN_SUCCESS: 'authenticate_success',
    AUTHENTICATE_TOKEN_FAILED: 'authenticate_failed',
    PAGE_TITLE_UPDATE: 'page_title_update',
    QUERY_PARAMS_UPDATE: 'query_params_update',
    DUPLICATE_SESSION_ERROR_MESSAGE:
        'We found you was connected before with another CloudGuard environment region. Please login again',
    SUBMENU_ADD_ITEM: 'SUBMENU_ADD_ITEM',
    SUBMENU_CHANGE_SELECTION: 'SUBMENU_CHANGE_SELECTION',
    SUBMENU_REMOVE_ITEM: 'SUBMENU_REMOVE_ITEM',
    USER_NAVIGATED: 'user_navigated',
    DASHBOARDS_CHANGED: 'DASHBOARDS_CHANGED',
    MFA_REQUIRED: 'MFA verification required',
    MFA_ENFORCED: 'Your account has been blocked because you did not enable Multi-Factor Authentication. Please contact the administrator.'
};

export const redirectUrlKey = 'redirectURL';
export const EXPIRED = 'expired';

export const marketplaceUrls = [
    '/aws-marketplace-metered',
    '/aws-marketplace-metered2',
    '/aws-marketplace-contracts',
    '/aws-marketplace-logic',
    '/aws-marketplace-core',
    '/aws-marketplace-d9',
    '/azure-marketplace-d9'
];