import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EntityViewer from 'common/components/EntityViewer/EntityViewer';
import Toolbar from './components/Toolbar/Toolbar';
import { EntityViewerProps, Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { LoaderWrapper } from './OciEnvironment.styled';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { useGetOci } from './reducer/hooks/useGetOci';
import EmptyState from 'common/components/EmptyState/EmptyState';
import { getOciEnvNamespace } from './initialize.i18n';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { OciEnvironmentAddinRegistry } from 'common/helpers/ociEnvironment';
import { ModalType } from './OciEnvironment.types';
import Modals from './components/Modals';
import { EnvironmentChip } from 'common/module_interface/assets/Environments';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';

const getAndSetDataFromOciRegistryController = new AbortController();

const OciEnvironment: React.FunctionComponent = () => {
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation(getOciEnvNamespace('oci'));
    const { isLoading, data: ociData, isError, isDeleted } = useGetOci(id);
    const [tabsWithData, setTabsWithData] = React.useState<{ [tabId: string]: Tab; }>({});
    const [detailsItems, setDetailsItems] = React.useState<{ [detailId: string]: BlockInfoProps; }>({});
    const [chips, setChips] = React.useState<{ [chipId: string]: IChipProps; }>({});
    const { allCloudAccounts } = useAllCloudAccounts();
    const { setBreadCrumbsLastChildren } = useBreadcrumbsLastChildren();

    React.useEffect(() => {
        if (ociData?.account.name) {
            setBreadCrumbsLastChildren([ociData?.account.name]);
        }
    }, [ociData, setBreadCrumbsLastChildren]);

    React.useEffect(() => {
        if (!ociData) return;

        const getAndSetTabsWithData = () => {
            const tabs = OciEnvironmentAddinRegistry.getTabs();
            const tabsFiltered = tabs.filter(tab => {
                return tab.isRelevant ? tab.isRelevant(ociData) : true;
            });
            tabsFiltered.forEach(tab => {
                new Promise<Tab>((resolve, reject) => {
                    getAndSetDataFromOciRegistryController.signal.addEventListener('abort', () => reject());
                    Promise.resolve(tab.getValue(ociData)).then((tabResponse) => {
                        resolve(tabResponse);
                    });
                }).then((tabResponse) => {
                    setTabsWithData(prevState => ({ ...prevState, [tab.id]: tabResponse }));
                }).catch(() => { /* Aborted */ });
            });
        };

        const getAndSetDetails = () => {
            const detailsItems = OciEnvironmentAddinRegistry.getDetailsPanelItems();
            const detailsFiltered = detailsItems.filter(detail => {
                return detail.isRelevant ? detail.isRelevant(ociData) : true;
            });
            detailsFiltered.forEach(detail => {
                new Promise<BlockInfoProps>((resolve, reject) => {
                    getAndSetDataFromOciRegistryController.signal.addEventListener('abort', () => reject());
                    Promise.resolve(detail.getValue(ociData)).then((detailResponse) => {
                        resolve(detailResponse);
                    });
                }).then((detailResponse) => {
                    setDetailsItems(prevState => ({ ...prevState, [detail.id]: detailResponse }));
                }).catch(() => { /* Aborted */ });
            });
        };

        const getAndSetChips = () => {
            const chipsFromRegistry = OciEnvironmentAddinRegistry.getChips();
            const chipsFiltered = chipsFromRegistry.filter(detail => {
                return detail.isRelevant ? detail.isRelevant(ociData) : true;
            });
            chipsFiltered.forEach(chip => {
                new Promise<EnvironmentChip>((resolve, reject) => {
                    getAndSetDataFromOciRegistryController.signal.addEventListener('abort', () => reject());
                    setChips(prevState => ({ ...prevState, [chip.id]: { label: chip.customLoadingLabel || 'Loading', disabled: true, loading: true } }));
                    Promise.resolve(chip.getValue(ociData)).then((chipResponse) => {
                        resolve(chipResponse);
                    });
                }).then((chipResponse) => {
                    setChips(prevState => ({ ...prevState, [chip.id]: chipResponse }));
                }).catch(() => { /* Aborted */ });
            });
        };

        getAndSetTabsWithData();
        getAndSetDetails();
        getAndSetChips();

        return () => {
            getAndSetDataFromOciRegistryController.abort();
            setTabsWithData({});
            setDetailsItems({});
            setChips({});
        };
    }, [id, t, ociData, allCloudAccounts]);

    const ociInfoProps: EntityViewerProps | undefined = React.useMemo(() => {
        if (!ociData) return undefined;
        return ({
            title: ociData.account.name,
            levelIcon: {
                iconProps: {
                    name: 'oracle'
                },
            },
            titleRightElements:
            <Toolbar
                ociAccount={ociData}
                actionClick={(type) => setModalType(type as any)}
            />,
            details: Object.values(detailsItems),
            chips: Object.values(chips),
            tabs: Object.values(tabsWithData),
            isLoading: false,
        });}, [ociData, detailsItems, chips, tabsWithData]);

    if (isLoading) {
        return (
            <LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </LoaderWrapper>
        );
    }

    if (isError || isDeleted || !ociData || !ociInfoProps) {
        return (
            <EmptyState
                label={t('ociPage.notFound.title')}
                description={t('ociPage.notFound.description')}
                links={
                    [{ label: t('ociPage.notFound.backLink'), url: '/cloud-account', icon: { name: 'arrowLeft' } }]
                }
            />
        );
    }

    return (
        <>
            <EntityViewer {...ociInfoProps} />
            <Modals
                modalType={modalType}
                closeModal={() => setModalType(null)}
                setModalType={setModalType}
                ociId={id}
            />
        </>
    );
};

export default OciEnvironment;
