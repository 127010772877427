
import { IPolicy } from 'common/interface/policy';
import { getHttpService, IShiftLeftService } from 'common/interface/services';

const baseApiPath = 'vulnerability/policy';

export class ShiftleftPolicyService implements IShiftLeftService {

    async getPolicies(useCache= true): Promise<IPolicy[]> {
        try {
            return await getHttpService().get<IPolicy[]>({
                path: baseApiPath,
                cachingConfig: { useCache } });
        } catch {
            return [];
        }
    }

    async setShiftLeftPolicy (data: IPolicy[]) {
        return await getHttpService().post<IPolicy[]>(baseApiPath, { data });
    }
    async updateShiftLeftPolicy(data: IPolicy[]): Promise<IPolicy[]>{
        return await getHttpService().put<IPolicy[]>(baseApiPath, { data });
    }

}
