import React, { useEffect, useState } from 'react';
import { I18nIntegrations, INTEGRATION_PAGE_GROUP_TYPE, } from 'common/module_interface/settings/integrations/consts';
import { getIntegrationsService } from 'common/interface/services';
import { ButtonGroup, Stack } from 'common/design-system/components-v2';
import ConfigurationPage from './ConfigurationPage';
import IntegrationPage from './IntegrationPage';
import { useTranslation } from 'react-i18next';
import { integrationNotificationReadOnly } from 'common/utils/userPermissionUtils';


interface IFilteredConfiguration {
    id?:string,
    name?:string,
    integrationsType?:string,
    configurationObj:any,
    createdAt:string,
    inUse:string
}
const IntegrationMainWrapper: React.FC = () => {
    const { t } = useTranslation( I18nIntegrations );
    const [configurations, setConfigurations] = useState({} as any);
    const [filteredConfigurations, setFilteredConfigurations] = useState([] as IFilteredConfiguration[]);
    const [integrationPageGroupButton, setIntegrationPageGroupButton ] = useState<string>(INTEGRATION_PAGE_GROUP_TYPE.INTEGRATION);
    const viewMode = integrationNotificationReadOnly();

    const tabsData = [
        { label: t('GENERAL.ALL_INTEGRATIONS'), id: INTEGRATION_PAGE_GROUP_TYPE.INTEGRATION },
        { label: t('GENERAL.CONFIGURED_INTEGRATIONS'), id: INTEGRATION_PAGE_GROUP_TYPE.CONFIGURATION },
    ];

    const arrangeDataForConfigurationPage = (allConfigurations: any, inUseConfigurations:any) => {
        const result:IFilteredConfiguration[] = [];
        Object.keys(allConfigurations).forEach(key => {
            allConfigurations[key] && allConfigurations[key].forEach((item:any) => {
                result.push({
                    id: item.id,
                    name: item.name,
                    integrationsType: key,
                    configurationObj: item.configurationObj,
                    createdAt: item.createdAt,
                    inUse: inUseConfigurations[item.id] ? t('GENERAL.YES') : t('GENERAL.NO'),
                });
            });
        });
        setFilteredConfigurations(result);
    };

    const getAllConfigurations = async () =>{
        const integrationsService = getIntegrationsService();
        const allConfigurations = await getIntegrationsService().getAllConfigurationsSlim(false);
        const inUseConfigurations = await getIntegrationsService().getInUseConfigurations();

        const tenableConfiguration = await getIntegrationsService().getTenableConfiguration();
        const snsConfiguration = await getIntegrationsService().getSystemSnsConfiguration(false);
        const registeredConfigurations = await integrationsService.getRegisteredConfigurationsData();
        const mergedConfigurations ={ tenable: tenableConfiguration ? [tenableConfiguration] : null , snsAudit: snsConfiguration, ...{ ...allConfigurations, ...registeredConfigurations } };
        arrangeDataForConfigurationPage(mergedConfigurations, inUseConfigurations);
        setConfigurations(mergedConfigurations);
    };

    useEffect(() => {
        getAllConfigurations();
    }, []);

    const handleButtonGroupChange = (e:string) => {
        setIntegrationPageGroupButton(e);
    };

    const renderPage = () => {
        switch (integrationPageGroupButton) {
            case INTEGRATION_PAGE_GROUP_TYPE.INTEGRATION:
                return <IntegrationPage configurations={configurations} configurationUpdated={getAllConfigurations} />;
            case INTEGRATION_PAGE_GROUP_TYPE.CONFIGURATION:
                return <ConfigurationPage filteredConfigurations={filteredConfigurations} configurationUpdated={getAllConfigurations} viewMode={viewMode}/>;
            default:
                return null;
        }
    };

    return (
        <Stack fullHeight>
            <Stack direction={'row-reverse'} margin={[5, 8]}>
                <ButtonGroup
                    options={tabsData.map((tab) => ({ label: tab.label, value: tab.id }))}
                    value={integrationPageGroupButton}
                    onChange={handleButtonGroupChange}/>
            </Stack>
            {renderPage()}
        </Stack>
    );
};

export default IntegrationMainWrapper;

