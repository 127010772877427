import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Chip } from '@dome9/berries/react-components';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';

const LabelCellRender: React.FC<ICellRendererParams> = (params) => {
    let { assetLabels } = params.data;
    if (assetLabels === null) {
        return <div></div>;
    }

    if (!Array.isArray(assetLabels)){
        assetLabels = [assetLabels];
    }

    CellToClipboardHandler(params, assetLabels.join(','));
    return <div> {assetLabels.map((label:string) => {
        return label ? <Chip
            className='self-center'
            label={label}
            key={label}
        /> : <div key={label}></div>;
    })}
    </div>;
};

export default LabelCellRender;