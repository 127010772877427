import React from 'react';
import { lightPallete } from 'common/design-system/theme/colors';
import { StatusGauge } from 'common/erm-components/custom/SummaryStatus/StatusGauge/StatusGauge';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import { IReadinessStatusData } from '../DataSecuritySummaryStatus.interface';
import { StatusBox } from 'common/erm-components/custom/SummaryStatus/StatusBox/StatusBox';

export const ReadinessStatus: React.FC<{ data: IReadinessStatusData }> = ({ data }) => {
    const { t } = useTranslation(I18nRiskNamespace);

    return (
        <StatusBox text={t('DATA_SECURITY_DASHBOARD.SUMMARY.ENVIRONMENTS.TITLE')}>
            <StatusGauge
                value={data.coveredDataAssetsCount}
                outOf={data.allDataAssetsCount}
                color={lightPallete.status.info}
                messages={data.messages}
            />
        </StatusBox>
    );
};
