import { FC } from 'react';
import { TextArea } from 'common/design-system/components-v2';
import { I18nExclusion } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { IExclusionCommentProps } from '../../helpers/exclusions.interfaces';

const ExclusionComment: FC<IExclusionCommentProps> = ({ onChange, isSaveClicked, formValidations, selectedOption, isEnabled }) => {
    const { t } = useTranslation(I18nExclusion);

    const handleCommentChange = (newValue:string) => {
        onChange(newValue);
    };

    return (
        <TextArea
            data-aid='exclusions-comment-input'
            label={t('MODAL.TOPICS.COMMENT.TITLE')}
            required
            value={selectedOption}
            placeholder={t('MODAL.GENERAL.TYPE_HERE')}
            onChange={(e) => handleCommentChange(e.target.value)}
            fullWidth
            maxLength={2500}
            clearable
            rows={4}
            disabled={!isEnabled}
            isError={isSaveClicked && !!(formValidations && formValidations[0]?.message)}
            helperText={(isSaveClicked && formValidations && formValidations[0]?.message) ? formValidations[0]?.message : undefined}
        />
    );
};

export default ExclusionComment;