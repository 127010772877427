import React, { useCallback, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { GetRowIdParams, IsRowSelectable, IRowNode, ColDef } from 'ag-grid-enterprise';
import { GridReadyEvent, RowGroupOpenedEvent } from 'ag-grid-community';
import './MenuPanel.scss';
import { IGroupMainMenuItem, IInternalMenuBase } from './Interface';
import MenuItemRenderer from './CellRenderers/MenuItemRenderer';
import { InternalUrlMenuItem } from './Utils';

interface IMenuPanelProps {
    menu: IInternalMenuBase[];
    selectedMenuItem: IInternalMenuBase | null;
    isMinimized: boolean;
    onRowOpened?: () => void;
}

let timeoutId: number;
const MenuPanel: React.FC<IMenuPanelProps> = ({ menu, selectedMenuItem, isMinimized, onRowOpened }) => {
    const gridRef = useRef<any>(null);
    const autoGroupColumnDef: ColDef = useMemo(() => {
        return {
            flex: 1,
            cellRendererParams: {
                suppressCount: true,
                innerRenderer: MenuItemRenderer
            }
        };
    }, []);
    const isRowSelectable = useCallback<IsRowSelectable>((rowNode: IRowNode<IInternalMenuBase>) => {
        return (!(rowNode.data instanceof InternalUrlMenuItem) && !(rowNode.data as IGroupMainMenuItem).isNotSelectable);
    }, []);
    const getDataPath = useCallback((data: IInternalMenuBase) => data.path, []);
    const getRowId = useCallback((params: GetRowIdParams<IInternalMenuBase>) => params.data.id, []);

    const onGridReady = useCallback((event?: GridReadyEvent) => {
        if (Number.isInteger(timeoutId)) {
            clearTimeout(timeoutId);
        }
        const gridApi = event?.api || gridRef.current?.api;
        if (!selectedMenuItem || !gridApi) return;
        gridApi.forEachNode((node: IRowNode<IInternalMenuBase>) => {
            if (node.data?.id !== selectedMenuItem.id) {
                return;
            }
            timeoutId = window.setTimeout(() => {
                node.setExpanded(true);
                node.setSelected(true);
            }, 200);
        });
        onRowOpened && onRowOpened();
    }, [selectedMenuItem, onRowOpened]);

    React.useEffect(() => {
        onGridReady();
    }, [selectedMenuItem, onGridReady]);

    const onRowExpanded = useCallback((params: RowGroupOpenedEvent<IInternalMenuBase>) => {
        // Handle only when expanding and if group (non-leaf) is expanded then do not collapse current selection tree.
        onRowOpened && onRowOpened();
        if (!params.expanded || params.node.allChildrenCount) {
            return;
        }
        params.api.forEachNode((node: IRowNode<IInternalMenuBase>) => {
            if (node.allLeafChildren.filter((leaf: IRowNode<IInternalMenuBase>) => leaf.data?.id === params.node.data?.id).length > 0) {
                node.setExpanded(true);
            } else {
                node.setExpanded(false);
            }
        });
    }, [onRowOpened]);

    return (
        <div className={`flex-1 bg-content w-[100%] rounded-lg ${isMinimized ? 'hidden' : ''}`} data-aid="main-menu-panel">
            <AgGridReact
                onRowGroupOpened={onRowExpanded}
                headerHeight={0}
                rowHeight={44}
                className="ag-theme-alpine ag-accordion scrollbar--inner p-4"
                ref={gridRef}
                rowData={menu}
                autoGroupColumnDef={autoGroupColumnDef}
                treeData={true}
                animateRows={false}
                groupDefaultExpanded={0}
                rowSelection={'single'}
                getDataPath={getDataPath}
                suppressContextMenu={true}
                suppressRowDeselection={true}
                isRowSelectable={isRowSelectable}
                getRowId={getRowId}
                onGridReady={onGridReady}
                onRowDataUpdated={onGridReady}
                suppressGroupRowsSticky={true}
            />
        </div>
    );
};

export default MenuPanel;
