import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTreeFilter,
    renderDefaultDateFilter,
    renderAddItemFilter
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_API_OBJECT_KEYS, FILTERS_KEYS, FILTER_DISPAY_TYPES } from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'i18next';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { getEventsTableNamespace } from '../initialize.i18n';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { IEventTableFilterParams } from 'common/interface/events';
import { DEFAULT_RANGES_VALUES } from 'common/components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor, getVendorByAssesmentNumber } from 'common/consts/vendors';
import { IFindingModelActionCode, IFindingModelOriginCode, findingModelActionCodeToString, findingModelAlertTypeCodeToString, findingModelOriginCodeToString } from 'common/components/Findings/Findings.interface';
import { ICloudAccount } from 'common/interface/data_services';
import { mapTypeItem } from 'common/module_interface/assets/utils';

const filterDefsAddins: () => Addin<IFilterProps>[] = () =>
    [
        {
            id: 'add filter',
            content: {
                filterProps: { key: 'add-filter' },
                renderFunction: renderAddFilter,
            },
        },
        {
            id: 'free text',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                    title: i18n.t('TABLE_FILTERS.FREE_TEXT.TITLE', { ns: getEventsTableNamespace('table') }),
                },
                keyInObjectForAPI: 'freeTextPhrase',
                renderFunction: renderDefaultFreeTextFilter,
            },
        },
        {
            id: 'organizational unit',
            content: function({ filtersInitialData }: IEventTableFilterParams) {
                return {
                    filterProps: {
                        isMultiSelect: true,
                        initialData: filtersInitialData?.organizationalUnits,
                        key: 'organizationalUnitId',
                        title: i18n.t('TABLE_FILTERS.ORGANIZATIONAL_UNITS.TITLE', { ns: getEventsTableNamespace('table') }),
                    },
                    renderFunction: renderTreeFilter,
                };
            },
        },
        {
            id: 'severity',
            content: function({ aggregations }: IEventTableFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations['severity'],
                        key: 'severity',
                        title: i18n.t('TABLE_FILTERS.SEVERITY.TITLE', { ns: getEventsTableNamespace('table') }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'action',
            content: function({ aggregations }: IEventTableFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['action']?.forEach((action: { value: IFindingModelActionCode }) => {
                    const actionString = findingModelActionCodeToString(action.value);
                    if (!actionString) return;
                    displayMapping[action.value] = {
                        displayText: i18n.t(`TABLE_FILTERS.ACTION.OPTIONS.${actionString}`, { ns: getEventsTableNamespace('table') }),
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['action'],
                        key: 'action',
                        title: i18n.t('TABLE_FILTERS.ACTION.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'origin',
            content: function({ aggregations }: IEventTableFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['origin']?.forEach((origin: { value: IFindingModelOriginCode }) => {
                    const originString = findingModelOriginCodeToString(origin.value);
                    if (!originString) return;
                    displayMapping[origin.value] = {
                        displayText: i18n.t(`TABLE_FILTERS.ORIGIN.OPTIONS.${originString}`, { ns: getEventsTableNamespace('table') }),
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['origin'],
                        key: 'origin',
                        title: i18n.t('TABLE_FILTERS.ORIGIN.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'cloudAccountType',
            content: function({ aggregations }: IEventTableFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['cloudAccountType']?.forEach((platform: { value: number }) => {
                    const vendorName = getVendorByAssesmentNumber(platform.value)?.name;
                    const vendor = getVendor(vendorName || '');
                    if (!vendor) return;
                    displayMapping[platform.value] = {
                        displayText: vendor.displayName,
                        icon: vendor.icon,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['cloudAccountType'],
                        key: 'cloudAccountType',
                        title: i18n.t('TABLE_FILTERS.PLATFORM.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'cloudAccountId',
            content: function({ aggregations, filtersInitialData }: IEventTableFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                const indexCloudAccounts: { [id: string]: ICloudAccount; } = filtersInitialData?.allCloudAccounts?.reduce<{ [id: string]: ICloudAccount; }>((acc, curr) => {
                    acc[curr.id] = curr;
                    return acc;
                }, {}) || {};
                aggregations['cloudAccountId_calc']?.forEach((id: { value: string }) => {
                    const [platformNum, accountId] = id.value.split('|');
                    const vendorName = getVendorByAssesmentNumber(Number(platformNum))?.name;
                    const vendor = getVendor(vendorName || '');
                    
                    if (!vendor) return;
                    displayMapping[id.value] = {
                        displayText: indexCloudAccounts[accountId] ? `${indexCloudAccounts[accountId].name}(${indexCloudAccounts[accountId].externalId})` : accountId,
                        icon: vendor.icon,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['cloudAccountId_calc'],
                        key: 'cloudAccountId_calc',
                        title: i18n.t('TABLE_FILTERS.ENVIRONMENT.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'entityType',
            content: function({ aggregations }: IEventTableFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations['entityType'],
                        key: 'entityType',
                        title: i18n.t('TABLE_FILTERS.ENTITY_TYPE.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping: mapTypeItem(aggregations['entityType']),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'region',
            content: function({ aggregations }: IEventTableFilterParams) {

                const filteredInitialData = aggregations['region']?.filter((region: { value: string }) => region.value);

                return {
                    filterProps: {
                        initialData: filteredInitialData,
                        key: 'region',
                        title: i18n.t('TABLE_FILTERS.REGION.TITLE', { ns: getEventsTableNamespace('table') }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'category',
            content: function({ aggregations }: IEventTableFilterParams) {

                const filteredInitialData = aggregations['category']?.filter((region: { value: string }) => region.value);

                return {
                    filterProps: {
                        initialData: filteredInitialData,
                        key: 'category',
                        title: i18n.t('TABLE_FILTERS.CATEGORY.TITLE', { ns: getEventsTableNamespace('table') }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'ownerUserName',
            content: function({ aggregations }: IEventTableFilterParams) {

                const displayMapping = {
                    '': {
                        displayText: i18n.t('TABLE_FILTERS.ASSIGNEE.OPTIONS.UNASSIGNED', { ns: getEventsTableNamespace('table') }),
                    },
                };

                return {
                    filterProps: {
                        initialData: aggregations['ownerUserName'],
                        key: 'ownerUserName',
                        title: i18n.t('TABLE_FILTERS.ASSIGNEE.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'bundleName',
            content: function({ aggregations }: IEventTableFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations['bundleName'],
                        key: 'bundleName',
                        title: i18n.t('TABLE_FILTERS.BUNDLE_NAME.TITLE', { ns: getEventsTableNamespace('table') }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'alertType',
            content: function({ aggregations }: IEventTableFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['origin']?.forEach((alertType: { value: number }) => {
                    const alertTypeString = findingModelAlertTypeCodeToString(alertType.value);
                    if (!alertTypeString) return;
                    displayMapping[alertType.value] = {
                        displayText: i18n.t(`TABLE_FILTERS.ALERT_TYPE.OPTIONS.${alertTypeString}`, { ns: getEventsTableNamespace('table') }),
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['alertType'],
                        key: 'alertType',
                        title: i18n.t('TABLE_FILTERS.ALERT_TYPE.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'showExcluded',
            content: function({ aggregations }: IEventTableFilterParams) {
                const displayMapping: IDisplayMappingObject = {
                    ['true']: {
                        displayText: i18n.t('TABLE_FILTERS.SHOW_EXCLUDED.OPTIONS.YES', { ns: getEventsTableNamespace('table') }),
                    },
                    ['false']: {
                        displayText: i18n.t('TABLE_FILTERS.SHOW_EXCLUDED.OPTIONS.NO', { ns: getEventsTableNamespace('table') }),
                    },
                };

                const counters = aggregations?.['isExcluded']?.reduce<{ [key: string]: number; }>((acc, curr) => {
                    acc[curr.value] = curr.count;
                    return acc;
                }, {}) || {};
                
                const initialData = [
                    { count: counters['true'] || 0, value: 'true' },
                    { count: counters['false'] || 0, value: 'false' },
                ];

                return {
                    filterProps: {
                        initialData,
                        key: 'isExcluded',
                        title: i18n.t('TABLE_FILTERS.SHOW_EXCLUDED.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'showAcknowledged',
            content: function({ aggregations }: IEventTableFilterParams) {
                const displayMapping: IDisplayMappingObject = {
                    ['true']: {
                        displayText: i18n.t('TABLE_FILTERS.SHOW_ACKNOWLEDGED.OPTIONS.YES', { ns: getEventsTableNamespace('table') }),
                    },
                    ['false']: {
                        displayText: i18n.t('TABLE_FILTERS.SHOW_ACKNOWLEDGED.OPTIONS.NO', { ns: getEventsTableNamespace('table') }),
                    },
                };

                const counters = aggregations?.['acknowledged']?.reduce<{ [key: string]: number; }>((acc, curr) => {
                    acc[curr.value] = curr.count;
                    return acc;
                }, {}) || {};
                
                const initialData = [
                    { count: counters['true'] || 0, value: 'true' },
                    { count: counters['false'] || 0, value: 'false' },
                ];

                return {
                    filterProps: {
                        initialData,
                        key: 'acknowledged',
                        title: i18n.t('TABLE_FILTERS.SHOW_ACKNOWLEDGED.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'entityExternalId',
            content: () => {
                return {
                    filterProps: {
                        initialData: [],
                        key: 'entityExternalId',
                        title: i18n.t('TABLE_FILTERS.ENTITY_ID.TITLE', { ns: getEventsTableNamespace('table') }),
                        value: [],
                    },
                    renderFunction: renderAddItemFilter,
                };
            }
        },
        {
            id: 'showFixable',
            content: function() {
                const displayMapping: IDisplayMappingObject = {
                    ['true']: {
                        displayText: i18n.t('TABLE_FILTERS.SHOW_FIXABLE.OPTIONS.YES', { ns: getEventsTableNamespace('table') }),
                    },
                };
                
                const initialData = [
                    { value: 'true' },
                ];

                return {
                    filterProps: {
                        initialData,
                        key: 'showFixable',
                        title: i18n.t('TABLE_FILTERS.SHOW_FIXABLE.TITLE', { ns: getEventsTableNamespace('table') }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'date picker',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.DATE_PICKER,
                    defaultValue: DEFAULT_RANGES_VALUES.ALL,
                    options: [
                        DEFAULT_RANGES_VALUES.ONE_DAY,
                        DEFAULT_RANGES_VALUES.WEEK,
                        DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                        DEFAULT_RANGES_VALUES.ALL,
                    ],
                    title: i18n.t('TABLE_FILTERS.DATE.TITLE', { ns: getEventsTableNamespace('table') }),
                    limitations: { maxDaysSelected: Infinity, maxDaysBack: Infinity },
                },
                keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.DATE_PICKER,
                displayTypes: [FILTER_DISPAY_TYPES.ROW],
                renderFunction: renderDefaultDateFilter,
            },
        },
        {
            id: 'saved filters',
            content: function({ filtersInitialData, savedFiltersComponentName }: IEventTableFilterParams) {
                return {
                    filterProps: {
                        savedFilters: filtersInitialData?.savedFilters || [],
                        savedFiltersComponentName,
                        selectedFilterID: '',
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                };
            },
        },
        {
            id: 'recently used',
            content: function({ filtersInitialData, recentlyUsedComponentName }: IEventTableFilterParams) {
                return {
                    filterProps: {
                        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                        recentlyUsedList: filtersInitialData?.recentlyUsed,
                        maxLength: 4,
                        componentName: recentlyUsedComponentName,
                        title: i18n.t('TABLE_FILTERS.RECENTLY_USED_FILTERS.TITLE', { ns: getEventsTableNamespace('table') }),
                    },
                    renderFunction: renderRecentlyUsedFilters,
                };
            },
        },
        {
            id: 'clear all',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
            },
        },
    ];


export default function initializeFilters() {
    EventsTableRegistry.addFilterAddins(filterDefsAddins());
}
