import {
    renderAddFilter, renderClearAll, renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import {
    FILTER_DISPAY_TYPES, FILTERS_API_OBJECT_KEYS,
    FILTERS_KEYS
} from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import i18n from 'common/services/translations/translations';
import { i18nReportsNamespace } from 'modules/reporting/initialize.i18n';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { ReportsTableRegistry } from 'common/module_interface/reporting/ReportsTableRegistry';
import { ReportsFields, ReportsFreeTextFields } from '../../Reporting.const';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { translations } from '../../Reporting.utils';

/**
 * common filter definitions for Reports table
 */
function getFilterDefs(): Addin<IFilterProps>[] {
    const t = i18n.getFixedT(null, i18nReportsNamespace);
    const translatedOptions = translations(t);

    return [
        {
            id: FILTERS_KEYS.ADD_FILTER,
            content: {
                filterProps: { key: FILTERS_KEYS.ADD_FILTER },
                renderFunction: renderAddFilter,
            }
        },
        {
            id: FILTERS_KEYS.FREE_TEXT,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.FREE_TEXT,
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.FREE_TEXT.TITLE'),
                    freeTextFields: [ReportsFreeTextFields.REPORT_NAME],
                },
                keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.FREE_TEXT,
                renderFunction: renderDefaultFreeTextFilter,
                displayTypes: [FILTER_DISPAY_TYPES.ROW, FILTER_DISPAY_TYPES.BOX],
            },
        },
        {
            id: FILTERS_KEYS.SAVED_FILTERS,
            content: function (params: IProtectedAssetFilterParams) {
                const filtersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        savedFilters: filtersInitialData?.savedFilters || [],
                        savedFiltersComponentName: EMPTY_STRING,
                        selectedFilterID: EMPTY_STRING,
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                };
            },
        },
        {
            id: FILTERS_KEYS.RECENTLY_USED_FILTERS,
            content: function (params: IProtectedAssetFilterParams) {
                const filtersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                        recentlyUsedList: filtersInitialData?.recentlyUsed,
                        maxLength: 4,
                        componentName: EMPTY_STRING,
                        title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                    },
                    renderFunction: renderRecentlyUsedFilters,
                };
            },
        },
        {
            id: FILTERS_KEYS.CLEAR_BUTTON,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
            },
        },
        {
            id: ReportsFields.TEMPLATE,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[ReportsFields.TEMPLATE]?.forEach((item: { value: string }) => {
                    const templateOption = translatedOptions?.templateOptions?.find(option => option?.value === item.value);
                    displayMapping[item.value] = {
                        displayText: templateOption?.label ?? item.value,
                        icon: templateOption?.icon?.name,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[ReportsFields.TEMPLATE],
                        key: ReportsFields.TEMPLATE,
                        title: i18n.t('REPORTS.FILTERS.TEMPLATE', { ns: i18nReportsNamespace }),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: ReportsFields.GRANULARITY,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[ReportsFields.GRANULARITY]?.forEach((item: { value: string }) => {
                    const granularityIntervalOption = translatedOptions?.granularityIntervalOptions?.find(option => option?.value === item.value);
                    displayMapping[item.value] = {
                        displayText: granularityIntervalOption?.label ?? item.value,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[ReportsFields.GRANULARITY],
                        key: ReportsFields.GRANULARITY,
                        title: i18n.t('REPORTS.FILTERS.GRANULARITY', { ns: i18nReportsNamespace }),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
    ];
}

export function initializeFilterDefs() {
    ReportsTableRegistry.addFilterDefs(getFilterDefs());
}
