import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { LevelIcon, Stack, Typography } from 'common/design-system/components-v2';
import { IEvent } from 'common/module_interface/events/Events';
import { getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';

const TitleCellRenderer = React.forwardRef<any, ICellRendererParams<IEvent>>((params, ref) => {
    const { severity, ruleName } = params.data || {};
    const [displayedRuleName, setDisplayedRuleName] = React.useState<string | undefined>(ruleName);
    const [displayedSeverity, setDisplayedSeverity] = React.useState<string | undefined>(severity);


    React.useImperativeHandle(ref, () => {
        return {
            refresh(params: IEvent) {
                if (params.severity) {
                    setDisplayedSeverity(params.severity);
                }
                if (params.ruleName) {
                    setDisplayedRuleName(params.ruleName);
                }
                return true;
            },
        };
    });

    if (!displayedRuleName) return null;

    const severityInfo = getSafeFindingSeverityInfo(displayedSeverity || '');

    return (
        <Stack alignItems='center' spacing={2} direction='row' overflow='hidden'>
            <LevelIcon size='md' iconProps={{ name: 'securityEvent' }} context={severityInfo.context} />
            <Typography ellipsis>{displayedRuleName}</Typography>
        </Stack>
    );
});
TitleCellRenderer.displayName = 'TitleCellRenderer';

export default TitleCellRenderer;