import { IIssueExclusionDateRange, IIssueExclusionProps } from '../IssueExclusions.interface';
import {
    convertSeverityKeysToLevels,
    convertSeverityLevelsToKeys,
} from '../../Issues/IssueSeverity';
import {
    convertValuesToDates,
    convertValuesToTags, getTrimmedMultiValue, getSafeTrimmedMultiValue, getSafeTrimmedSingleValue,
} from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { IssueExclusionItemNames } from './IssueExclusionEditor.consts';
import { IDateRange } from 'common/design-system/components-v2/DatePicker/DatePicker.types';

const getDatesFromValues = (values?: string[], allowPartialDates?: boolean): IIssueExclusionDateRange | undefined => {
    const dateRange: IDateRange = convertValuesToDates(values);
    if (!dateRange.from && !dateRange.to) {
        return undefined;
    }

    if (!allowPartialDates && (!dateRange.from || !dateRange.to)) {
        return undefined;
    }

    return {
        from: dateRange.from || new Date(),
        to: dateRange.to || dateRange.from || new Date(),
    };
};

export const severityKeysToLevels = (values?: string[]): number[] => {
    if (values) {
        return convertSeverityKeysToLevels(values);
    }
    return [];
};

export const severityLevelsToKeys = (severityLevels?: number[]): string[] | undefined => {
    if (severityLevels) {
        return convertSeverityLevelsToKeys(severityLevels);
    }
};

export const getSafeName = (itemsMap: IItemsMap): string => {
    return getSafeTrimmedSingleValue(itemsMap[IssueExclusionItemNames.name]);
};

export const getExclusionPropsFromValues = (itemsMap: IItemsMap): IIssueExclusionProps => {
    return {
        name: getSafeTrimmedSingleValue(itemsMap[IssueExclusionItemNames.name]),
        description: getSafeTrimmedSingleValue(itemsMap[IssueExclusionItemNames.description]),
        ruleIds: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.rules]),
        severities: severityKeysToLevels(getTrimmedMultiValue(itemsMap[IssueExclusionItemNames.severities])),
        entityTypes: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.entityTypes]),
        envIds: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.environments]),
        orgUnitIds: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.orgUnits]),
        entityNames: getSafeTrimmedMultiValue(itemsMap[IssueExclusionItemNames.entityNames]),
        entityId: getSafeTrimmedSingleValue(itemsMap[IssueExclusionItemNames.entityId]),
        dateRange: getDatesFromValues(getTrimmedMultiValue(itemsMap[IssueExclusionItemNames.dateRange])),
        tags: convertValuesToTags(getTrimmedMultiValue(itemsMap[IssueExclusionItemNames.tags])),
    };
};
