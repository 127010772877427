import { IFieldInfo, SortOrder } from 'common/interface/general';
import { getRiskLevels, IRiskLevelInfo, RiskLevelEnum } from 'common/module_interface/RiskManagement/RiskLevel';
import { lightPallete } from 'common/design-system/theme/colors';
import { ITrendBoxItem } from '../../../../RiskManagement.interface';
import { getWAFProtectionFilters, WAFDistributionProtection } from '../../../WafProtection/WafProtection';
import { WAF_WIDGETS_DATA_URL, wafProtectionRiskChanges } from '../../../WafProtection/WafProtection.consts';
import { IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import {
    IWafCoverageData,
    IWAFImpact,
    IWafRiskChange,
    IWafRiskDistribution,
    IWafRiskDistributionWithTotal,
    IWafWidgetsData,
    IWafWidgetsDataModal,
} from './WAF.interface';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { convertFilterField } from 'common/utils/filterUtils';
import { FieldEntityKind } from 'common/registries/FieldConvertorsRegistry';
import { getWAFFilterFields } from '../RiskDashboard.datasource';
import { ILevelBoxItem } from 'common/erm-components/custom/LevelBoxes/LevelBox.interface';

import { CACHE_TIMOUTS, sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { ASSET_FIELD_NAMES, CONTEXT_FIELD_FULL_NAMES, NETWORK_EXPOSURE_VALUES } from '../../../../consts';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export const fetchWafWidgetsData = async (filterValues?: IFiltersValues) : Promise<IWafWidgetsData> => {
    const wafFilters: Record<string, string[]> = filterValues? getWafFilters(filterValues) : {};
    const response: IWafWidgetsDataModal = await sendHttpRequest<IWafWidgetsDataModal>(WAF_WIDGETS_DATA_URL, {
        data: wafFilters,
        method: 'POST',
    },
    CACHE_TIMOUTS.LONG );
    
    const { coverage, wafProtectedRiskDistribution, wafProtectedRiskChange } = response;

    return {
        isWafEnabled: response.isWafEnabled,
        ...(response.isWafEnabled ?
            {
                coverage: coverage ? getCoverageGaugeInfo(coverage) : undefined,
                wafProtectedRiskDistribution: wafProtectedRiskDistribution ? getRiskLevelBoxesInfo(wafProtectedRiskDistribution) : undefined,
                wafProtectedRiskChange: wafProtectedRiskChange ? getRiskTrendBoxesInfo(wafProtectedRiskChange) : undefined,
                wafImpactInfo: coverage ? getImpactInfo(response) : undefined,
            } : {}),
    };
};

export const getCoverageGaugeInfo = (coverage: IWafCoverageData): IGenericWidgetDataItem[] => {
    const WAFilterFields = getWAFProtectionFilters();
    const goToProtectedAssetsWithWAFFilter = () => {
        getErmUrlsService().goToProtectedAssetsTable(
            [
                { name: CONTEXT_FIELD_FULL_NAMES.networkExposure, value: NETWORK_EXPOSURE_VALUES.PUBLIC },
                { name: CONTEXT_FIELD_FULL_NAMES.networkExposure, value: NETWORK_EXPOSURE_VALUES.PARTIALLY_PUBLIC },
                ...WAFilterFields,
            ],
        );
    };

    return [
        {
            key: 'value',
            value: coverage.protected,
            color: lightPallete.status.info,
            onclick: goToProtectedAssetsWithWAFFilter,
        },
        {
            key: 'outOf',
            value: coverage.total,
            color: lightPallete.status.info,
        },
    ];
};

export const getRiskLevelBoxesInfo = (riskDistribution: IWafRiskDistributionWithTotal): ILevelBoxItem[] => {
    const items: ILevelBoxItem[] = [];
    const WAFilterFields = getWAFProtectionFilters();
    const onClickParams: IFieldInfo[] = WAFilterFields;
    const onClick = riskDistribution.total > 0 ? () => getErmUrlsService().goToProtectedAssetsTable(onClickParams) : undefined;

    items.push({
        onClick,
        count: riskDistribution.total,
        color: lightPallete.status.offline,
        text: 'Total',
        level: -1,
    });

    getRiskLevels(SortOrder.descending, RiskLevelEnum.low).map((riskLevelInfo: IRiskLevelInfo) => {
        const count = riskDistribution[riskLevelInfo.key as unknown as keyof IWafRiskDistribution];

        const onClickParams: IFieldInfo[] = [
            ...WAFilterFields,
            {
                name: ASSET_FIELD_NAMES.riskScore,
                value: `${riskLevelInfo.scoreRange.low}-${riskLevelInfo.scoreRange.high}`,
            }];
        const onClick = count > 0 ? () => getErmUrlsService().goToProtectedAssetsTable(onClickParams) : undefined;

        items.push({
            onClick,
            count,
            color: riskLevelInfo.color,
            text: riskLevelInfo.displayText,
            level: riskLevelInfo.level,
        });
    });

    return items;
};

export const getRiskTrendBoxesInfo = (riskTrend: IWafRiskChange): ITrendBoxItem[] => {
    return [
        {
            originalColumn: {
                text: wafProtectionRiskChanges.criticalToHigh.original.displayText,
                count: riskTrend.criticalToHigh,
            },
            trendColumn: {
                text: wafProtectionRiskChanges.criticalToHigh.trend.displayText,
                count: riskTrend.criticalToHighPercent,
            },
        },
        {
            originalColumn: {
                text: wafProtectionRiskChanges.highToMedium.original.displayText,
                count: riskTrend.highToMedium,
            },
            trendColumn: {
                text: wafProtectionRiskChanges.highToMedium.trend.displayText,
                count: riskTrend.highToMediumPercent,
            },
        },
        {
            originalColumn: {
                text: wafProtectionRiskChanges.mediumToLow.original.displayText,
                count: riskTrend.mediumToLow,
            },
            trendColumn: {
                text: wafProtectionRiskChanges.mediumToLow.trend.displayText,
                count: riskTrend.mediumToLowPercent,
            },
        },
    ];
};

export const getWAFRiskItems = (resData: IWafWidgetsDataModal): IGenericWidgetDataItem[] => {
    const items: IGenericWidgetDataItem[] = [];
    const categories = Object.values(WAFDistributionProtection);
    categories.forEach(category => {
        const categoryObject: IWafRiskDistribution | null = resData[category];
        if (categoryObject) {
            Object.keys(categoryObject).forEach((key, index) => {
                items.push({
                    key: key,
                    value: Object.values(categoryObject)[index],
                    category: category,
                });
            });
        }
    });
    return items;
};

export const getImpactInfo = (resData: IWafWidgetsDataModal): IWAFImpact => (
    {
        coverage: resData.coverage?.coverage,
        totalCount: resData.coverage?.total,
        riskItems: getWAFRiskItems(resData),
    }
);

export const getWafFilters = (filterValues: IFiltersValues): Record<string, string[]> => {
    const relevantFilterFields: IFieldInfo[] = getWAFFilterFields(filterValues);
    const filter: Record<string, string[]> = {};
    relevantFilterFields.map((field: IFieldInfo) => {
        const filterId = convertFilterField(FieldEntityKind.ASSET, FieldEntityKind.WAF, field)?.name || field.name;
        if (!filter[filterId]) {
            filter[filterId] = [];
        }
        filter[filterId].push(field.value);
    });
    return filter;
};
