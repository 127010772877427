import {
    DashboardWidgetTypes,
    IAddinWidgetsDataSource,
    IDashboardWidget,
} from 'common/module_interface/overview/Interface';
import ActivityGauge from 'common/components/Widgets/ActivityGauge/ActivityGauge';
import {
    complianceDataGetter, complianceTrendDataGetter, generateTrendlineChangeSummaryData, generateTrendSummaryData,
    getComplianceGaugeResults, setTrendDataItems,
} from './WidgetData/ComplianceDataGetter';
import AreaWidget from 'common/components/Widgets/Area/AreaWidget';
import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { ComplianceWidgetsSettings } from './WidgetData/ComplianceWidgetsSettings';
import TrendChangeSummary from 'common/components/Widgets/TrendChangeSummary/TrandChangeSummary';
import TrendLineWithChangeSummary
    from 'common/components/Widgets/TrendLineWithChangeSummary/TrendLineWithChangeSummary';
import AssessmentsTableWidget from './AssessmentTable/AssessmentsTableWidget';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import ComplianceGridWidgetsSettings from './WidgetData/ComplianceGridWidgetsSettings';


export const initializeComplianceWidgets = () => {


    const complianceWidgets: IAddinWidgetsDataSource = {
        dataSourceName: 'Compliance',
        widgets: [
            {
                type: DashboardWidgetTypes.Gauge,
                getData: (widget: IDashboardWidget) => complianceDataGetter(widget, getComplianceGaugeResults),
                widgetComponent: ActivityGauge,
                settingsComponent: ComplianceWidgetsSettings
            },
            {
                type: DashboardWidgetTypes.Trend,
                getData: (widget: IDashboardWidget) => complianceTrendDataGetter(widget, setTrendDataItems),
                widgetComponent: AreaWidget,
                settingsComponent: ComplianceWidgetsSettings
            },
            {
                type: DashboardWidgetTypes.TrendChangeSummary,
                getData: (widget: IDashboardWidget) => complianceTrendDataGetter(widget, generateTrendSummaryData),
                widgetComponent: TrendChangeSummary,
                settingsComponent: ComplianceWidgetsSettings
            },
            {
                type: DashboardWidgetTypes.TrendLineChangeSummary,
                getData: (widget: IDashboardWidget) => complianceTrendDataGetter(widget, generateTrendlineChangeSummaryData),
                widgetComponent: TrendLineWithChangeSummary,
                settingsComponent: ComplianceWidgetsSettings
            },
            {
                type: DashboardWidgetTypes.Grid,
                widgetComponent: AssessmentsTableWidget,
                settingsComponent: ComplianceGridWidgetsSettings
            },

        ]
    };

    globalAddinContainer.addMap(DASHBOARD_DYNAMIC_WIDGETS, [complianceWidgets], 'dataSourceName');

};
