import React from 'react';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IIssueExclusion } from './IssueExclusions.interface';
import { ermTrans } from '../../RiskManagement.utils';
import {
    duplicateIssueExclusion,
    getIssueExclusionsService,
} from './IssueExclusions.utils';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { DUPLICATE_ISSUE_EXCLUSION_FEATURE } from '../ActiveFeatures/ActiveFeatures.consts';
import { isAfEnabled } from 'common/utils/debugUtils';
import { List } from 'common/design-system/components-v2';
import {
    showIssueExclusionForm,
} from './IssueExclusionEditor/IssueExclusionEditor.items';

const getExclusionsListDisplay: React.FC<{ issueExclusions: IIssueExclusion[] }> = ({ issueExclusions }) => {
    return (
        <List options={issueExclusions.map(exclusion => ({
            label: exclusion.name,
            value: exclusion.name,
            key: exclusion.id,
            labelProps: { leadingIconProps: { name: 'exclude' } },
        }))} />
    );
};

export const getIssueExclusionsTableActions = (onTableDataChange: () => void): ITableAction<IIssueExclusion>[] => {
    const actions: ITableAction<IIssueExclusion>[] = [
        {
            id: 'addIssueExclusion',
            name: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.ADD'),
            callback: () => {
                showIssueExclusionForm();
            },
            isActionDisabled: () => false,
            buttonProps: {
                iconProps: { name: 'plus' }
            },
        },
        {
            id: 'editIssueExclusion',
            name: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.EDIT'),
            callback: (issueExclusions) => {
                if (issueExclusions.length === 1) {
                    showIssueExclusionForm(issueExclusions[0]);
                }
            },
            isActionDisabled: (issueExclusions) => {
                return (issueExclusions.length !== 1);
            },
            buttonProps: {
                iconProps: { name: 'edit' }
            },
        },
    ];
    if (isAfEnabled(DUPLICATE_ISSUE_EXCLUSION_FEATURE.key)) {
        actions.push({
            id: 'duplicateIssueExclusion',
            name: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.DUPLICATE'),
            callback: (issueExclusions) => {
                const promises: Promise<string>[] = issueExclusions.map(issueExclusion => {
                    return duplicateIssueExclusion(issueExclusion);
                });
                Promise.all(promises).then(() => {
                    getNotificationsService().addNotification({
                        type: NotificationType.SUCCESS,
                        text: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.EXCLUSIONS_DUPLICATED_SUCCESSFULLY'),
                    });
                    onTableDataChange();
                }).catch(() => {
                    getNotificationsService().addNotification({
                        type: NotificationType.ERROR,
                        text: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.FAILED_DUPLICATING_EXCLUSIONS'),
                    });
                    onTableDataChange();
                });
            },
            isActionDisabled: (issueExclusions) => {
                return (issueExclusions.length === 0);
            },
            buttonProps: {
                iconProps: { name: 'copy' }
            },
        });
    }
    actions.push({
        id: 'deleteIssueExclusion',
        name: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.DELETE'),
        callback: (issueExclusions) => {
            globalModelUtils.showConfirmationModal({
                title: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.EXCLUSIONS_DELETE_CONFIRMATION_TITLE'),
                text: issueExclusions.length === 1 ?
                    ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.EXCLUSIONS_DELETE_EXCLUSION', { name: issueExclusions[0].name }) :
                    ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.EXCLUSIONS_DELETE_EXCLUSIONS'),
                submitBtnText: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.DELETE'),
                children: issueExclusions.length > 1 ? getExclusionsListDisplay({ issueExclusions }) : undefined,
                onConfirm: () => {
                    const ids = issueExclusions.map(issueExclusion => issueExclusion.id);
                    getIssueExclusionsService().deleteIssueExclusions(ids).then((failedIds: string[]) => {
                        if (failedIds.length === 0) {
                            getNotificationsService().addNotification({
                                type: NotificationType.SUCCESS,
                                text: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.EXCLUSIONS_DELETED_SUCCESSFULLY'),
                            });
                        } else {
                            console.error('Failed deleting issueExclusions.');
                            getNotificationsService().addNotification({
                                type: NotificationType.ERROR,
                                text: ermTrans('ISSUE_EXCLUSIONS.TABLE.ACTIONS.FAILED_DELETING_EXCLUSIONS'),
                            });
                        }
                        if (failedIds.length < ids.length) {
                            onTableDataChange();
                        }
                    });
                },
            });
        },
        isActionDisabled: (issueExclusions) => {
            return (issueExclusions.length === 0);
        },
        buttonProps: {
            iconProps: { name: 'delete' }
        },
    });
    return actions;
};