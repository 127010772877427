import { StatusType } from 'common/components/StatusMessagePanel/StatusMessagePanel.types';
import i18n, { TFunction } from 'i18next';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { IStorageModel } from './AzureIntelligenceOnboarding.interface';
import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';

/**
 * Retrieves the message status mapping.
 * 
 * @returns {Record<StatusType, string>} The message status mapping.
 */
export const getStatusesMap = (): Record<StatusType, string> => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);

    return {
        [StatusType.ERROR]: t('AZURE_ONBOARDING.ARM_TEMPLATE.DEPLOYMENTS_STATUS.ERROR'),
        [StatusType.SUCCESS]: t('AZURE_ONBOARDING.ARM_TEMPLATE.DEPLOYMENTS_STATUS.SUCCESS'),
        [StatusType.INFO]: t('AZURE_ONBOARDING.ARM_TEMPLATE.DEPLOYMENTS_STATUS.WAITING'),
    };
};

/**
 * Check if all selected storages have logsType field filled
 * 
 * @param storages - The array of storage models.
 * @returns A boolean indicating whether all storages have log types.
 */
export const checkStorageLogTypes = (storages: IStorageModel[]) => {
    const isValid = storages.every(row => 
        row.logsType?.length ||
        row.logTypesFound.some(type => 
            type.logTypeStatus === ConnectionStatus.connected
        )
    );
    return isValid;
};
