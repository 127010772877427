import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { InternalLinkMenuItem, InternalMainMenuItem, buildInternalMenu, getMenuItemByPath, selectMenuSelectionForLocation } from '../Menus/Utils';
import { getMainMenu } from '../Menus/Menus.reducer';
import { Stack } from 'common/design-system/components-v2';
import { BreadcrumbTypography, BreadcrumbsWrapper, SeperatorWrapper, UnderlinedA } from './Breadcrumbs.styled';
import { useTranslation } from 'react-i18next';
import { Icon } from '@dome9/berries/react-components';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';

export const Breadcrumbs: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const mainMenu = useSelector(getMainMenu);
    const internalMenu = useMemo(() => buildInternalMenu(mainMenu), [mainMenu]);
    const selectedItem = useMemo(() => selectMenuSelectionForLocation(internalMenu, location), [internalMenu, location]);
    const { setBreadCrumbsLastChildren } = useBreadcrumbsLastChildren();
    const breadcrumbsLastChildren: string[] | undefined = useSelector((state: any) => state.breadcrumbsState.breadcrumbsLastChildren);
    const seperator = <SeperatorWrapper><Icon name='chevron-right' size={8} /></SeperatorWrapper>;

    useEffect(() => {
        const unlisten = history.listen((newLocation) => {
            if (newLocation.pathname !== location.pathname) {
                setBreadCrumbsLastChildren(undefined);
            }
        });

        return () => {
            unlisten();
        };
    }, [location, history, setBreadCrumbsLastChildren]);

    const genereateBreadcrumbElement = (pathIndex: number) => {
        const currentPartialPath = selectedItem?.path.slice(0, pathIndex + 1) || [];
        const menuItem = getMenuItemByPath(internalMenu, currentPartialPath);
        const menuItemLabel = t(menuItem?.label || '');

        const isNotLastChild = (pathIndex + 1) !== selectedItem?.path.length || breadcrumbsLastChildren;

        if (menuItem instanceof InternalMainMenuItem) {
            const mainMenuItem = menuItem as InternalMainMenuItem;
            return (
                <Stack alignItems='center' direction='row' spacing={1}>
                    <Stack>
                        <Icon name={mainMenuItem.icon} size={16} />
                    </Stack>
                    <BreadcrumbTypography>{menuItemLabel}</BreadcrumbTypography>
                </Stack>
            );
        } else if (menuItem instanceof InternalLinkMenuItem && isNotLastChild) {
            const internalLinkMenu = menuItem as InternalLinkMenuItem;
            return <UnderlinedA href={internalLinkMenu.state}>{menuItemLabel}</UnderlinedA>;
        }

        return <BreadcrumbTypography variant={isNotLastChild ? 'body' : 'body500'}>{menuItemLabel}</BreadcrumbTypography>;
    };

    const baseBreadcrumbs = selectedItem?.path.map((item: string, index: number) => genereateBreadcrumbElement(index));
    const breadcrumbsLastChildElements = breadcrumbsLastChildren?.map((breadcrumb, index) => {
        const isLastChild = index === (breadcrumbsLastChildren.length - 1);
        return <BreadcrumbTypography key={`last-child-${index}`} variant={isLastChild ? 'body500' : 'body'}>{breadcrumb}</BreadcrumbTypography>;
    });
    const breadcrumbs = breadcrumbsLastChildren && breadcrumbsLastChildElements
        ? [...baseBreadcrumbs || [], ...breadcrumbsLastChildElements]
        : baseBreadcrumbs;

    return (
        <BreadcrumbsWrapper direction='row' padding={[2, 5, 1, 5]} spacing={1} alignItems='center'>
            {breadcrumbs && breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={`breadcrump-${index}`}>
                    {breadcrumb}
                    {(index + 1) < breadcrumbs.length && seperator}
                </React.Fragment>
            ))}
        </BreadcrumbsWrapper>
    );
};
