import { Alert, Stack, Typography } from 'common/design-system/components-v2';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';

const StorageErrorWrapper = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });

    return (
        <Stack alignItems='center' justifyContent='center' fullWidth fullHeight>
            <Stack padding={[4, 0]} fullHeight fullWidth>
                <Alert type='error'>
                    <Stack direction='column' spacing={2} padding={[1, 0, 0 ,0]}>
                        <Typography type='key' variant='sm' color='inherit'>{t('STORAGE.ERROR.TITLE')}</Typography>
                        <Typography variant='sm' color='inherit'>{t('STORAGE.ERROR.1')}</Typography>
                        <ul>
                            <li><Typography variant='sm' color='inherit'>{t('STORAGE.ERROR.2')}</Typography></li>
                        </ul>
                    </Stack>
                </Alert>
            </Stack>
        </Stack>
    );
};

export default StorageErrorWrapper;