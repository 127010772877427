import i18n from 'i18next';
import { i18nIntelligenceNamespace } from './initialize.i18n';

export const cdrTrans = (str: string, params?: { [key: string]: any }): string => {
    const finalParams = {
        ...(params || {}),
        ns: i18nIntelligenceNamespace,
    };
    return i18n.t(str, finalParams);
};
