import { FC, useEffect, useState } from 'react';
import { Stack, Card, Input, Button } from 'common/design-system/components-v2';
import { OptionType } from 'common/design-system/components-v2/Select';
import { useTranslation } from 'react-i18next';
import { I18nRemediation } from '../../helpers/remediation.consts';

interface CloudBotsCustomParametersProps {
    selectedCloudBot: OptionType;
    onSubmit: (bot: string) => void;
}
export const CloudBotsCustomParameters: FC<CloudBotsCustomParametersProps> = ({ selectedCloudBot, onSubmit }) => {
    const { t } = useTranslation(I18nRemediation);
    const [customBot, setCustomBot] = useState('');

    useEffect(() => {
        setCustomBot('');
    }, [selectedCloudBot]);
    const submit = () => {
        if (!customBot) return;
        onSubmit(customBot);
        setCustomBot('');
    };

    return (
        <Card
            key={selectedCloudBot?.label}
            title={selectedCloudBot?.label.includes(' ') ? selectedCloudBot.label.split(' ')[0] : selectedCloudBot?.label}
            iconProps={{ name: 'robot' }}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" fullWidth spacing={3}>
                <Input
                    data-aid='cloudbot-custome-input'
                    label={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.CUSTOM_CLOUD_BOT')}
                    required
                    value={customBot}
                    onChange={(ev) => setCustomBot(ev.target.value)}
                    fullWidth
                    clearable
                    helperText={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.CUSTOM_CLOUD_BOT_TEST_HELPER')}
                />
                <Button color="brandPrimary" onClick={submit}>{t('REMEDIATION.MODALS.ADD_EDIT_MODAL.BUTTONS.ADD_BOT')}</Button>
            </Stack>
        </Card>
    );
};

interface CloudBotsParametersProps {
    selectedCloudBot: OptionType;
    savedCloudBots: string[];
    onSubmit: (bot: string) => void;
    params: { key: string, value: string | null }[];
}
export const CloudBotsParameters: FC<CloudBotsParametersProps> = ({ selectedCloudBot, savedCloudBots , params, onSubmit }) => {
    const { t } = useTranslation(I18nRemediation);
    const [parameters, setParameters] = useState<{ key: string, value: string | null }[]>(params || []);

    useEffect(() => {
        setParameters(params);
    }, [params]);
    const submit = () => {
        const values = parameters.map((param) => { return param.value; });
        const currentSavedCloudBot = savedCloudBots?.filter((b) => b.indexOf(selectedCloudBot?.value as string) === -1);
        if (values.includes('') || !currentSavedCloudBot) return;
        const cloudBotValue = `${selectedCloudBot?.value} ${values.join(' ')}`;
        onSubmit(cloudBotValue);
        setParameters([]);
    };

    return (
        <Card
            key={selectedCloudBot?.label}
            title={selectedCloudBot?.label.includes(' ') ? selectedCloudBot.label.split(' ')[0] : selectedCloudBot?.label}
            iconProps={{ name: 'robot' }}>
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-start" fullWidth spacing={3}>
                <Stack direction="column" justifyContent="flex-start" fullWidth spacing={3}>
                    { parameters.map((param: any) => (
                        <Input
                            key={param.key}
                            data-aid={`cloudbot-parameter-input-${param.key}`}
                            label={param.key}
                            required
                            value={param.value}
                            onChange={(ev) => {param.value = ev.target.value;}}
                            fullWidth
                            clearable
                        />
                    ))}
                </Stack>
                <Stack direction="column" justifyContent="flex-start" spacing={3}>
                    <Button color="brandPrimary" onClick={submit}>{t('REMEDIATION.MODALS.ADD_EDIT_MODAL.BUTTONS.ADD_BOT')}</Button>
                </Stack>
            </Stack>
        </Card>
    );
};

