import { changeUrl } from 'common/utils/http';
import { Location } from 'common/utils/history';
import { isEmptyString } from 'common/utils/helpFunctions';
import {
    FINDING_ANOMALY_GRAPH_PANEL_ID,
    FINDING_GSL_PANEL_ID,
    FINDING_VISUALIZATION_GRAPH_PANEL_ID,
} from '../FindingOverview.initialize';
import { FINDINGS_ORIGINS } from 'common/module_interface/events/EventsConsts';


import { INTELLIGENCE_TYPES } from 'common/module_interface/intelligence/Gsl/GslService.const';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';

export function setEntityInUrl(entityId: string | undefined, entityName: string | undefined) {
    const querystring = new URLSearchParams(window.location.search);
    if (entityId && entityName) {
        querystring.set('entityId', entityId);
        querystring.set('entityName', entityName);
    }
    else {
        querystring.delete('entityId');
        querystring.delete('entityName');
    }
    changeUrl(window.location.pathname + `?${querystring.toString()}`);
}

export function getPropertyFromParams(location: Location<unknown>, propertyName: string){
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(propertyName) || '';
}

export const severityIndexByValue = ['Informational','Low', 'Medium','High', 'Critical'];

const severityPriority: Record<string, number> = {
    critical: 0,
    high: 1,
    medium: 2,
    low: 3,
    informational: 4,
};
export function severityComparator(valueA: string, valueB: string) {
    if (!valueA || !valueB) {
        return 0;
    }
    return severityPriority[valueA.toLowerCase() as string] - severityPriority[valueB.toLowerCase() as string];
}
export function getIntelligenceSource(finding: IFinding) {
    if (!finding?.additionalFields?.networkActivity && !finding?.additionalFields?.accountActivity) return undefined;
    return finding.additionalFields?.networkActivity? INTELLIGENCE_TYPES.NETWORK_TRAFFIC : INTELLIGENCE_TYPES.ACCOUNT_ACTIVITY;
}

export function isPanelRelevant(finding: IFinding, panel: string){
    if(!finding || !panel || finding.origin !== FINDINGS_ORIGINS.INTELLIGENCE) return false;

    switch(panel){
        case FINDING_ANOMALY_GRAPH_PANEL_ID:
            return finding?.ruleName?.includes('Anomalous Network');

        case FINDING_VISUALIZATION_GRAPH_PANEL_ID:
            return (!!finding?.additionalFields?.networkActivity || !!finding?.additionalFields?.accountActivity);

        case FINDING_GSL_PANEL_ID:
            return !isEmptyString(finding.ruleLogic);

        default:
            return false;
    }
}
