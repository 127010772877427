import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';
import { CpIcon } from '@dome9/components/react/components';

export const InputWrapper= styled.div`
  width: 50%;
`;
export const StackWrapper= styled(Stack)`
  max-width: 750px;
`;
export const ConfigWrapper = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.palette.surface.primary};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    border: ${({ theme }) => `1px solid ${theme.palette.surfaceStates.tertiaryActive}`};
`;

export const SuccessIcon = styled(CpIcon).attrs(() => ({
    icon: 'success'
}))`
  font-size: 25px;
  fill: #56B00F;
  display: block;
  height: 30px;
`;