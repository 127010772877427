import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import {
    FindingSeveritiesMap,
    getSafeFindingSeverityInfo,
} from 'common/consts/FindingSeverity';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { openFindingDrawer } from '../../Findings.utils';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';
import { SeverityLevelIconPropsLevel } from 'common/design-system/components-v2/LevelIcon/LevelIcon.types';
import {
    IRuleTitleCellComponentProps,
    RuleTitleCellComponent
} from 'common/components/ag-grid/Renderers/RuleTitleCellComponent';

const EventTitleCellRenderer: React.FC<ICellRendererParams<IFinding> & { onClick?: (finding: IFinding) => void, isGroupColumn: boolean }> = (params) => {
    if (!params.data) {
        return null;
    }
    const finding: IFinding = params.data;
    const ruleTitle: string = finding.ruleName;
    if (!ruleTitle) {
        return null;
    }
    const componentProps: IRuleTitleCellComponentProps = {
        params,
        ruleTitle,
        ruleColId: FindingFields.ruleName,
        severityLevel: finding.severityLevel,
        drawerOpener: () => openFindingDrawer(finding),
        getSeverityKey: (severity?: number): SeverityLevelIconPropsLevel => {
            return severity === undefined ? FindingSeveritiesMap.unknown.key : getSafeFindingSeverityInfo(severity).key;
        },
        iconName: 'securityEvent',
    };
    return <RuleTitleCellComponent {...componentProps} />;
};

export default EventTitleCellRenderer;
