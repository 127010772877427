import React from 'react';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { ModalsProps } from '..';
import { Vendors } from 'common/consts/vendors';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import { getAzureEnvNamespace } from '../../../initialize.i18n';
import { useGetAzure } from '../../../reducer/hooks/useGetAzure';
import { useMagellanDisableForCloudAccount } from '../../../../services/magellan/components/useMagellanDisableForCloudAccount';
import { getCloudAccountsService } from 'common/interface/data_services';

const RemoveIntelligence: React.FC<ModalsProps> = ({ closeModal, azureId }) => {
    const { t } = useTranslation(getAzureEnvNamespace('azure'));
    const { data: azureData } = useGetAzure(azureId);

    const handleOnRemoveSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('azurePage.modals.removeIntelligence.onSubmit.success'),
            text: '',
        });
        getCloudAccountsService().clearCache();
        onClose();
    };

    const handleOnRemoveError = () => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('azurePage.modals.removeIntelligence.onSubmit.error'),
            text: '',
        });
        onClose();
    };

    const { disable, isLoading: isDisableLoading } = useMagellanDisableForCloudAccount({ onSuccess: handleOnRemoveSuccess, onError: handleOnRemoveError });

    const onClickRemoveCluster = async () => {
        if (!azureData) return;
        disable({ CloudAccountId: azureData.account.subscriptionId, Vendor: Vendors.AZURE });
    };

    const onClose = React.useCallback(() => {
        closeModal();
    }, [closeModal]);

    return (
        <ConfirmationModal
            title={t('azurePage.modals.removeIntelligence.header')}
            text={t('azurePage.modals.removeIntelligence.text')}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={onClickRemoveCluster}
            isLoading={isDisableLoading}
            width='md'
        />
    );
};

export default RemoveIntelligence;
