import { UserRolesTypes } from 'common/enum/UserRoles';
import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { addMainMenuItem } from 'common/helpers/menu';
import { getUserService } from 'common/interface/services';
import SpectralPage from './SpectralPage';
import { ActiveFeaturesTypes } from 'common/enum/ActiveFeatures';
import { RedirectComponent } from 'common/components/RedirectComponent/RedirectComponent';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { isSpectralEnabled } from 'common/utils/userPermissionUtils';

export const SPECTRAL_DEFAULT_PATH = '/spectral';

export default function initialize() {
    const SHIFTLLEFT_PAGE_REDIRECT_PATH = '/shiftleft-redirect';

    const SPECTRAL = {
        ID: 'NAVIGATION_MENU.SPECTRAL.TITLE',
        DEFAULT: 'NAVIGATION_MENU.SPECTRAL.DEFAULT.ID',
    };

    const defaultPageRoute = () => {
        return {
            exact: true,
            condition: getUserService().hasPermission([UserRolesTypes.ALL]),
            component: SpectralPage,
            path: SPECTRAL_DEFAULT_PATH,
        };
    };
    const spectralShiftLeftSelectorPageRidirect = () => {
        return {
            exact: true,
            condition: !getUserService().hasPermission([ActiveFeaturesTypes.SHIFTLEFT]),
            component: RedirectComponent as any,
            componentProps: { to: SPECTRAL_DEFAULT_PATH },
            path: SHIFTLLEFT_PAGE_REDIRECT_PATH,
        };
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        { id: SPECTRAL.DEFAULT, content: defaultPageRoute },
        {
            id: 'spectralShiftleftRedirect',
            content: spectralShiftLeftSelectorPageRidirect,
        }]);


    addMainMenuItem({
        id: 'shiftleft spectral',
        position: 105,
        icon: 'shiftleft',
        label: SPECTRAL.ID,
        permission: () => isSpectralEnabled() && getUserService().hasPermission([ActiveFeaturesTypes.SPECTRAL]),
        state: SPECTRAL_DEFAULT_PATH,
        sections: [
            {
                id: 'default',
                position: 10,
                items: [
                    {
                        id: 'dashboard',
                        position: 10,
                        label: 'NAVIGATION_MENU.SPECTRAL.DASHBOARD',
                        state: SPECTRAL_DEFAULT_PATH,
                        permission: [ActiveFeaturesTypes.SPECTRAL],
                    },
                ],
            },
        ],
    });
}
