import { getCheckpointUrl } from 'common/components/Forms/urls';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { addMainMenuItem } from 'common/helpers/menu';
import { IMainMenuItem } from 'common/interface/menu';
import { ISearcher } from '../../MainNavigationBar/MainNavigationBar.interface';
import { GLOBAL_SEARCH_SEARCHERS } from 'common/extensibility/WellKnownPaths';
import { menuSearcher } from './MenuSearcher';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { getUserService } from 'common/interface/services';
import i18n from 'common/services/translations/translations';
import { NEW_NOTIFICATIONS_PAGE_AF } from 'common/module_interface/settings/integrations/Integrations';
import { integrationNotificationEnabled } from 'common/utils/userPermissionUtils';

const DOME9_AUDIT_ALERTS = 'dome9-audit-alerts';

export default function initializeMenuItems(isNotChina: boolean) {

    const CloudLogsMenuItem = {
        id: 'cloud logs',
        position: 20,
        title: 'NAVIGATION_MENU.EVENTS.CLOUD_LOGS',
        items: [
            {
                id: 'network traffic',
                position: 10,
                label: 'NAVIGATION_MENU.EVENTS.NETWORK_TRAFFIC',
                state: '/magellan/flowlogs',
                urlAliases: [
                    '/magellan/flowlogs',
                    '/azure-flowlogs',
                    '/k8s-flowlogs',
                    '/stats-network-aws',
                    '/stats-network-azure',
                    '/stats-network-kubernetes',
                ],
                permission: [UserRolesTypes.ALL],
            },
            {
                id: 'account activity',
                position: 20,
                label: 'NAVIGATION_MENU.EVENTS.ACCOUNT_ACTIVITY',
                state: '/magellan/cloudtrail',
                urlAliases: ['/magellan/cloudtrail', '/stats-account-aws'],
                permission: [UserRolesTypes.ALL],
            },
        ],
    };
    const Menus: IMainMenuItem[] = [
        {
            id: 'events',
            position: 20,
            icon: 'events',
            label: 'NAVIGATION_MENU.EVENTS.TITLE',
            permission: [UserRolesTypes.ALL],
            sections: [
                {
                    id: 'operational',
                    position: 30,
                    title: 'NAVIGATION_MENU.EVENTS.OPERATIONAL',
                    items: [
                        {
                            id: 'system events',
                            position: 10,
                            label: 'NAVIGATION_MENU.EVENTS.SYSTEM_EVENTS',
                            state: '/alerts/general',
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'api audit logs',
                            position: 20,
                            label: 'NAVIGATION_MENU.EVENTS.API_AUDIT_LOGS',
                            state: '/audit/apilogs',
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'system audit logs',
                            position: 30,
                            label: 'NAVIGATION_MENU.EVENTS.SYSTEM_AUDIT_LOGS',
                            state: '/audit/systemlogs',
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'audit rulesets',
                            position: 40,
                            label: 'NAVIGATION_MENU.EVENTS.AUDIT_RULESETS',
                            state: '/audit/rulesets',
                            permission: [DOME9_AUDIT_ALERTS],
                        },
                        {
                            id: 'audit policies',
                            position: 50,
                            label: 'NAVIGATION_MENU.EVENTS.AUDIT_POLICIES',
                            state: '/audit/rulesets-assignment',
                            permission: [DOME9_AUDIT_ALERTS],
                        },
                    ],
                },
            ],
        },
        {
            id: 'Integration-hub',
            position: 110,
            icon: 'configuration',
            label: 'NAVIGATION_MENU.INTEGRATION_HUB.TITLE',
            permission: () => integrationNotificationEnabled() && getUserService().hasPermission([NEW_NOTIFICATIONS_PAGE_AF]),
            mainMenuChipTitle: i18n.t('GENERAL.NEW'),
            sections: [
                {
                    id: 'default',
                    position: 10,
                    items: [
                        {
                            id: 'integrations-list',
                            position: 10,
                            label: 'NAVIGATION_MENU.SETTINGS.INTEGRATIONS',
                            state: '/settings/integrations-list',
                            permission: ['new-integrations'],
                        },
                    ],
                },
            ]
        },
        {
            id: 'resources',
            position: 120,
            icon: 'help',
            label: 'NAVIGATION_MENU.RESOURCES.TITLE',
            permission: [UserRolesTypes.ALL],
            descriptionKeys: ['MENU.ADMINISTRATIONS.DESCRIPTION.1', 'MENU.ADMINISTRATIONS.DESCRIPTION.2'],
            sections: [
                {
                    id: 'default',
                    position: 10,
                    items: [
                        {
                            id: 'welcome',
                            position: 10,
                            label: 'NAVIGATION_MENU.RESOURCES.WELCOME',
                            state: '/settings/resources-welcome',
                            permission: [UserRolesTypes.ALL],
                        },
                    ],
                },
                {
                    id: 'documentation',
                    position: 20,
                    title: '',
                    items: [
                        {
                            id: 'documentation',
                            position: 10,
                            label: 'NAVIGATION_MENU.RESOURCES.DOCUMENTATION',
                            url: getCheckpointUrl('HELP_CENTER_DOCUMENTATION'),
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'release notes',
                            position: 20,
                            label: 'NAVIGATION_MENU.RESOURCES.RELEASE_NOTES',
                            url: getCheckpointUrl('RELEASE_NOTES'),
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'help center',
                            position: 30,
                            label: 'NAVIGATION_MENU.RESOURCES.HELP_CENTER',
                            url: getCheckpointUrl('HELP_CENTER'),
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'api',
                            position: 40,
                            label: 'NAVIGATION_MENU.RESOURCES.API',
                            url: getCheckpointUrl('API_V2'),
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'gsl',
                            position: 50,
                            label: 'NAVIGATION_MENU.RESOURCES.GSL_DOME_9',
                            url: getCheckpointUrl('GSL_KNOWLEDGE_BASE'),
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'cloud bots',
                            position: 60,
                            label: 'NAVIGATION_MENU.RESOURCES.CLOUD_BOTS',
                            url: getCheckpointUrl('CLOUD_BOTS'),
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'terraform provider',
                            position: 70,
                            label: 'NAVIGATION_MENU.RESOURCES.TERRAFORM_PROVIDER',
                            url: getCheckpointUrl('TERRAFORM_PROVIDER'),
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'status page',
                            position: 80,
                            label: 'NAVIGATION_MENU.RESOURCES.STATUS_PAGE',
                            url: getCheckpointUrl('STATUS'),
                            permission: [UserRolesTypes.ALL],
                        },
                    ],
                },
            ],
        },
    ];

    isNotChina && Menus?.find(menu => menu.id === 'events')?.sections?.push(CloudLogsMenuItem);

    Menus.forEach((menuItem) => {
        addMainMenuItem(menuItem);
    });

    globalAddinContainer.add<ISearcher>(GLOBAL_SEARCH_SEARCHERS, [{
        id: 'menuSearcher',
        position: 1,
        content: menuSearcher,
    }]);

}