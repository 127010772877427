import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';


const SlackStyled = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SlackAlertWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacing(2)}${theme.units} ${theme.spacing(5)}${theme.units}`};
  display: flex;
  flex-direction: column;
`;

const SlackNameWrapper = styled(Stack)`
  width: 50%;
  margin: ${({ theme }) => `${theme.spacing(2)}${theme.units} ${theme.spacing(5)}${theme.units} ${theme.spacing(5)}${theme.units}`};
`;

const SlackEndpointUrlWrapper = styled(Stack)`
  display: flex;
  margin: ${({ theme }) => `0 ${theme.spacing(5)}${theme.units} ${theme.spacing(5)}${theme.units}`};
  align-items: flex-end;
`;

const SlackEndpointUrlInputWrapper = styled(Stack)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


export {
    SlackStyled,
    SlackNameWrapper,
    SlackEndpointUrlWrapper,
    SlackEndpointUrlInputWrapper,
    SlackAlertWrapper
};