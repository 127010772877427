

// NOTE: Since some of the types are not assets, so we will not use the consts for now
// TODO: Need to think of a better way to map it
export const IntelligenceTypeMap: Record<string, string> = {
    'aws_role': 'AwsIamRole',
    'aws_iamrole': 'AwsIamRole',
    'aws_account': 'AwsCloudAccount',
    'aws_instance': 'AwsEC2Instance',
    'aws_ec2': 'AwsEC2Service',
    'aws_ecstask': 'AwsEcsTask',
    'aws_ecsservice': 'AwsEcsTask',
    'aws_applicationloadbalancer': 'AwsAppLoadBalancer',
    'aws_ecstaskdefinition': 'AwsEcsTaskDefinition',
    'aws_elb': 'AwsElasticLoadBalancer',
    'aws_lambda': 'AwsLambdaFunction',
    'aws_aks': 'AwsElasticKubernetesService',
    'aws_s3bucket': 'AwsS3Bucket',
    'aws_s3': 'AwsS3Bucket',
    'aws_subnet': 'AwsSubnet',
    'aws_securitygroup': 'AwsSecurityGroup',
    'aws_iamuser': 'AwsIamUser',
    'aws_networkinterface': 'AwsNetworkInterface',
    'aws_ecscluster': 'AwsEcsCluster',
    'aws_redshift-data': 'AwsRedshiftCluster',
    'aws_redshift': 'AwsRedshiftCluster',
    'aws_samluser': 'AwsIamUser',
    'aws_autoscalinggroup': 'AwsAutoScalingGroup',
    'aws_routetable': 'AwsRouteTable',
    'aws_iamgroup': 'AwsIamGroup',
    'aws_natgateway': 'AwsNatGateway',
    'aws_organization': 'AwsOrganizationAccount',
    'aws_cloudformationstack': 'AwsCloudFormationStack',
    'aws_iampolicy': 'AwsIamPolicy',
    'aws_rds': 'AwsRDSInstance',
    'aws_dynamodbtable': 'AwsDynamoTable',
    'aws_dynamodb': 'AwsDynamoDBService',
    'aws_elasticache': 'AwsElasticCache',
    'aws_accesskey': 'AwsSts',
    'aws_vpcflowlog': 'AwsFlowLogs',
    'aws_inspector2': 'AwsInspector2',
    'aws_volume': 'AwsVolume',
    'aws_networkfirewall': 'AwsNetworkFirewall',
    'aws_malicious': 'Malicious',
    'aws_amazon': 'AwsCloudAccount',
    'aws_globalaccelerator': 'AwsGlobalAccelerator',
    'aws_route53_healthchecks': 'AwsRoute53Service',
    'aws_cloudfront': 'AwsCloudFrontService',

    'azure_azure portal': 'Azure',
    'azure_vaults': 'AzureKeyVault',
    'azure_roleassignment': 'AzureRoleAssignment',
    'azure_virtualmachine': 'AzureVirtualMachine',
    'azure_functions': 'AzureFunctionApp',
    'azure_userassignedidentity': 'AzureUserAssignedIdentity',
    'azure_serviceprincipal': 'AppRegistration',
    'azure_application': 'AppRegistration',
    'azure_user': 'AzureUser',
    'azure_resourcegroup': 'AzureResourceGroup',
    'azure_account': 'Azure',
    'azure_azure-storage': 'AzureStorage',
    'azure_blob': 'AzureStorage',
    'azure_storageaccount': 'AzureStorage',
    'azure_vnet': 'AzureVNet',
    'azure_malicious': 'Malicious',
    'azure_virtualmachinescaleset': 'AzureVirtualMachineScaleSet',

    'kubernetes_kubernetespod': 'KubernetesPod',
    'kubernetes_kubernetescluster': 'KubernetesCluster',
    'kubernetes_kubernetesnode': 'KubernetesNode',
    'kubernetes_kubernetesnamespace': 'KubernetesNamespace',
    'kubernetes_kubernetesdeployment': 'KubernetesDeployment',
    'kubernetes_kubernetesreplicaset': 'KubernetesReplicaset',
    'kubernetes_kubernetesdaemonset': 'KubernetesDaemonset',
    'kubernetes_kubernetesjob': 'KubernetesJob',
    'kubernetes_kubernetescronjob': 'KubernetesCronjob',
    'kubernetes_kubernetesopenshift': 'KubernetesOpenshift',
    'kubernetes_kubernetesiamge': 'KubernetesImage',
    'kubernetes_kubernetesserviceaccount': 'KubernetesServiceAccount',
    'kubernetes_kubernetesrole': 'KubernetesRole',
    'kubernetes_kubernetesingress': 'KubernetesIngress',
    'kubernetes_kubernetesnetworkpolicy': 'KubernetesNetworkPolicy',
    'kubernetes_malicious': 'Malicious',
};
