import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import i18n from 'i18next';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import { initializeIssueViewer } from './IssueViewer/IssueViewer.initialize';
import { default as IssueRuleTitleCellRender } from './cellRenderers/IssueRuleTitleCellRender';
import EnvironmentCellRender from 'common/components/ProtectedAssets/Renderers/EnvironmentCellRender';
import { IssueEntityNameCellRender } from './cellRenderers/IssueEntityNameCellRender';
import { getVendor, getVendorDisplayName } from 'common/consts/vendors';
import dayjs from 'dayjs';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
    IIssueOrGroup,
    IIssueSeverityValueCount,
    ISSUE_FIELD_NAMES,
    TOP_ISSUE_FIELD_NAMES,
} from 'modules/riskManagement/components/Issues/Issues.interface';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultDateFilter,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTextFilter,
    renderTreeFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import {
    FILTER_DISPAY_TYPES,
    FILTERS_API_OBJECT_KEYS,
    FILTERS_KEYS,
} from 'common/components/FilterPanel/FilterPanel.consts';
import {
    IProtectedAssetDetailPanelProvider,
    IProtectedAssetFilterParams,
} from 'common/module_interface/assets/ProtectedAssets';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { buildCloudAccountList, mapEnvironmentItem, mapTypeItem } from 'common/module_interface/assets/utils';
import { getRegionDisplayName } from 'common/utils/vendorUtils';
import {
    computeRangeFromSelection,
    DEFAULT_RANGES_VALUES,
} from 'common/components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import {
    IdType,
    IIssue,
    IServerInputFilterDetails,
} from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { IssueOrgUnitCellRenderer } from './cellRenderers/IssueOrgUnitCellRenderer';
import { I18nRiskNamespace } from '../../consts';
import { IssueEntityTypeCellRenderer } from './cellRenderers/IssueEntityTypeCellRenderer';
import { FieldConvertorsRegistry, FieldEntityKind, IFieldConvertor } from 'common/registries/FieldConvertorsRegistry';
import {
    assetIdIssueFilterDataSetter, booleanIssueFilterDataSetter,
    defaultIssueFilterDataSetter,
    getEntityNameFromIssue,
    getFullIssueStatus, issueIdFilterDataSetter,
} from './Issues.utils';
import { getOrgUnitFullPath } from 'common/components/ProtectedAssets/AssetUtils';
import { getCloudAccountsService } from 'common/interface/data_services';
import { OccurrencesCellRender } from '../dashboards/RiskDashboard/SecurityIssues/TopIssues/OccurrencesCellRender';
import { getIssueSeverityInfo } from './IssueSeverity';
import { ermPermissionFn, ermTrans } from '../../RiskManagement.utils';
import { ISSUE_FREE_TEXT_FIELDS } from './Issues.consts';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { IssuesTablePanel } from './IssuesPanel/IssuesTablePanel';
import { FilterConditionOperator } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { getSupportedSecurityGraphEntityTypes } from '../../SecurityGraph.supported';
import { SortDirection } from 'common/interface/general';
import { IssueSeverityCellRender } from '../../renderers/IssueSeverityCellRender';
import { SELECTION_GRID_COLUMN } from 'common/utils/tableUtils';
import {
    getDisplayMappingFromOptions,
    getFilterValuesInitialData, RECENTLY_USED_FILTER_ID, SAVED_FILTERS_FILTER_ID
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { getYesNoDisplayMapping } from 'common/erm-components/utils/filters';
import { transBoolean } from 'common/erm-components/utils/types';

const initializeIssuesTableColumnDefs = () => {
    const columnDefs: CGColDef[] = [
        SELECTION_GRID_COLUMN,
        {
            colId: ISSUE_FIELD_NAMES.RULE_TITLE,
            field: ISSUE_FIELD_NAMES.RULE_TITLE,
            sortable: true,
            enableRowGroup: true,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.TITLE.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: IssueRuleTitleCellRender,
            csvExport: {
                order: 20,
                title: i18n.t('ISSUES.TABLE.COLUMNS.TITLE.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue.ruleTitle;
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.SEVERITY,
            field: ISSUE_FIELD_NAMES.SEVERITY,
            sortable: true,
            enableRowGroup: true,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.SEVERITY.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: IssueSeverityCellRender,
            csvExport: {
                order: 10,
                title: i18n.t('ISSUES.TABLE.COLUMNS.SEVERITY.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return getIssueSeverityInfo(issue.severity)?.displayText || '';
                },
            },
            groupOrder: SortDirection.DESC,
        },
        {
            colId: ISSUE_FIELD_NAMES.CREATED_TIME,
            field: ISSUE_FIELD_NAMES.CREATED_TIME,
            sortable: true,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.CREATED_TIME.HEADER', { ns: I18nRiskNamespace }),
            valueGetter: (data: ValueGetterParams<IIssue>) => {
                return data.data?.createdTime ? dayjs(data.data.createdTime).format(i18n.t('COMMON.DATE_FORMATS.LONG_FORMAT')) : '';
            },
            csvExport: {
                order: 30,
                title: i18n.t('ISSUES.TABLE.COLUMNS.CREATED_TIME.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue?.createdTime ? dayjs(issue.createdTime).format(i18n.t('COMMON.DATE_FORMATS.LONG_FORMAT')) : '';
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.ENTITY_NAME,
            field: ISSUE_FIELD_NAMES.ENTITY_NAME,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.ENTITY.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: IssueEntityNameCellRender,
            csvExport: {
                order: 60,
                title: i18n.t('ISSUES.TABLE.COLUMNS.ENTITY.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return getEntityNameFromIssue(issue, false);
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
            field: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
            sortable: true,
            enableRowGroup: true,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.ENTITY_TYPE.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: IssueEntityTypeCellRenderer,
            csvExport: {
                order: 70,
                title: i18n.t('ISSUES.TABLE.COLUMNS.ENTITY_TYPE.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue.entityType;
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.STATUS,
            field: ISSUE_FIELD_NAMES.STATUS,
            sortable: true,
            enableRowGroup: true,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.STATUS.HEADER', { ns: I18nRiskNamespace }),
            resizable: false,
            width: 90,
            valueGetter: (data: ValueGetterParams<IIssue>) => {
                return data.data ? getFullIssueStatus(data.data) : '';
            },
            csvExport: {
                order: 75,
                title: i18n.t('ISSUES.TABLE.COLUMNS.STATUS.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return getFullIssueStatus(issue);
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.IS_EXCLUDED,
            field: ISSUE_FIELD_NAMES.IS_EXCLUDED,
            sortable: true,
            resizable: false,
            width: 105,
            valueGetter: (dataContainer: ValueGetterParams<IIssueOrGroup>) => {
                return transBoolean(dataContainer.data?.isExcluded);
            },
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.IS_EXCLUDED.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 115,
                title: i18n.t('ISSUES.TABLE.COLUMNS.IS_EXCLUDED.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return transBoolean(issue.isExcluded);
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
            field: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.ENVIRONMENT.HEADER', { ns: I18nRiskNamespace }),
            enableRowGroup: true,
            cellRenderer: EnvironmentCellRender,
            csvExport: {
                order: 80,
                title: i18n.t('ISSUES.TABLE.COLUMNS.ENVIRONMENT.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue.environmentId;
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.PLATFORM,
            field: ISSUE_FIELD_NAMES.PLATFORM,
            sortable: true,
            enableRowGroup: true,
            valueGetter: (dataContainer: ValueGetterParams<IIssueOrGroup>) => {
                return dataContainer.data?.platform ? getVendorDisplayName(dataContainer.data.platform) : '';
            },
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.PLATFORM.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 110,
                title: i18n.t('ISSUES.TABLE.COLUMNS.PLATFORM.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return getVendorDisplayName(issue.platform);
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID,
            field: ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID,
            enableRowGroup: true,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.ORGANIZATIONAL_UNIT.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: IssueOrgUnitCellRenderer,
            csvExport: {
                order: 120,
                title: i18n.t('ISSUES.TABLE.COLUMNS.ORGANIZATIONAL_UNIT.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return getOrgUnitFullPath(issue.organizationalUnitId);
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.DESCRIPTION,
            field: ISSUE_FIELD_NAMES.DESCRIPTION,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.DESCRIPTION.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 40,
                title: i18n.t('ISSUES.TABLE.COLUMNS.DESCRIPTION.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue.description;
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.REMEDIATION,
            field: ISSUE_FIELD_NAMES.REMEDIATION,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.REMEDIATION.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 50,
                title: i18n.t('ISSUES.TABLE.COLUMNS.REMEDIATION.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue.remediation;
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.ENTITY_ID,
            field: ISSUE_FIELD_NAMES.ENTITY_ID,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.ENTITY_ID.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 130,
                title: i18n.t('ISSUES.TABLE.COLUMNS.ENTITY_ID.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue.entityId;
                },
            },
        },
        {
            colId: 'environmentName',
            field: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.ENVIRONMENT_NAME.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 100,
                title: i18n.t('ISSUES.TABLE.COLUMNS.ENVIRONMENT_NAME.CSV_HEADER', { ns: I18nRiskNamespace }),
                async calculateValue(issue: IIssue) {
                    const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(issue.environmentId);
                    return cloudAccount?.name;
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.ENVIRONMENT_EXTERNAL_ID,
            field: ISSUE_FIELD_NAMES.ENVIRONMENT_EXTERNAL_ID,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.ENVIRONMENT_EXTERNAL_ID.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 90,
                title: i18n.t('ISSUES.TABLE.COLUMNS.ENVIRONMENT_EXTERNAL_ID.CSV_HEADER', { ns: I18nRiskNamespace }),
                async calculateValue(issue: IIssue) {
                    return issue.environmentExternalId;
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.ID,
            field: ISSUE_FIELD_NAMES.ID,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.ID.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 160,
                title: i18n.t('ISSUES.TABLE.COLUMNS.ID.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue.id;
                },
            },
        },
        {
            colId: 'Dome9id',
            field: ISSUE_FIELD_NAMES.ENTITY_ID,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.DOME9_ID.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 140,
                title: i18n.t('ISSUES.TABLE.COLUMNS.DOME9_ID.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    if (issue.entityIdType === IdType.DOME9_ID_TYPE) {
                        return issue.entityId;
                    }
                    return '';
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.RULE_ID,
            field: ISSUE_FIELD_NAMES.RULE_ID,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.RULE_ID.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 170,
                title: i18n.t('ISSUES.TABLE.COLUMNS.RULE_ID.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue.ruleId;
                },
            },
        },
        {
            colId: ISSUE_FIELD_NAMES.ENTITY_EXTERNAL_ID,
            field: ISSUE_FIELD_NAMES.ENTITY_EXTERNAL_ID,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.ENTITY_EXTERNAL_ID.HEADER', { ns: I18nRiskNamespace }),
            csvExport: {
                order: 150,
                title: i18n.t('ISSUES.TABLE.COLUMNS.ENTITY_EXTERNAL_ID.CSV_HEADER', { ns: I18nRiskNamespace }),
                calculateValue(issue: IIssue) {
                    return issue.entityExternalId;
                },
            },
        },
    ];
    IssuesRegistry.addIssuesColumnDefs(columnDefs, 'colId');
};


const initializeTopIssuesTableColumnDefs = () => {
    const columnDefs: CGColDef[] = [
        {
            colId: TOP_ISSUE_FIELD_NAMES.RULE_TITLE,
            field: TOP_ISSUE_FIELD_NAMES.RULE_TITLE,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.TITLE.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: IssueRuleTitleCellRender,
        },
        {
            colId: TOP_ISSUE_FIELD_NAMES.SEVERITY,
            field: TOP_ISSUE_FIELD_NAMES.SEVERITY,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.SEVERITY.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: IssueSeverityCellRender,
        },
        {
            colId: TOP_ISSUE_FIELD_NAMES.OCCURRENCES,
            field: TOP_ISSUE_FIELD_NAMES.OCCURRENCES,
            headerName: i18n.t('ISSUES.TABLE.COLUMNS.OCCURRENCES.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: OccurrencesCellRender,
        },
    ];
    IssuesRegistry.addTopIssuesColumnDefs(columnDefs, 'colId');
};

const initializeFilterDefs = () => {
    const filterDefsAddins: Addin<IFilterProps<IServerInputFilterDetails>>[] = [
        {
            id: 'add filter',
            content: {
                filterProps: { key: 'add-filter' },
                renderFunction: renderAddFilter,
            },
        },
        {
            id: 'free text',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.FREE_TEXT,
                    title: i18n.t('ISSUES.TABLE.FILTERS.FREE_TEXT.TITLE', { ns: I18nRiskNamespace }),
                },
                keyInObjectForAPI: FILTERS_KEYS.FREE_TEXT,
                setInRequestObj: (requestObj: IServerInputFilterDetails, value: string) => {
                    if (value.length >= 3) {
                        requestObj.freeText = {
                            text: value,
                            fieldNames: ISSUE_FREE_TEXT_FIELDS,
                        };
                    }
                },
                renderFunction: renderDefaultFreeTextFilter,
                displayTypes: [FILTER_DISPAY_TYPES.ROW],
            },
        },
        {
            id: 'date picker',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.DATE_PICKER,
                    defaultValue: DEFAULT_RANGES_VALUES.ALL,
                    options: [
                        DEFAULT_RANGES_VALUES.ONE_DAY,
                        DEFAULT_RANGES_VALUES.WEEK,
                        DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                        DEFAULT_RANGES_VALUES.ALL,
                    ],
                    title: 'Date',
                    limitations: { maxDaysSelected: 5, maxDaysBack: 30 },
                },
                keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.DATE_PICKER,
                displayTypes: [FILTER_DISPAY_TYPES.ROW],
                setInRequestObj: (requestObj: IServerInputFilterDetails, value: any) => {
                    requestObj.fields = requestObj.fields || [];
                    const dateSelection = computeRangeFromSelection(value);
                    if (dateSelection.from && dateSelection.to) {
                        requestObj.fields.push({
                            name: ISSUE_FIELD_NAMES.CREATED_TIME,
                            values: [dateSelection.from, dateSelection.to],
                            operator: FilterConditionOperator.Between,
                        });
                    }
                },
                renderFunction: renderDefaultDateFilter,
            },
        },
        {
            id: 'organizationalUnitId',
            content: ({ filtersInitialData }: IProtectedAssetFilterParams) => {
                return {
                    filterProps: {
                        isMultiSelect: true,
                        initialData: filtersInitialData?.organizationalUnits,
                        key: 'organizationalUnitId',
                        title: i18n.t('ISSUES.TABLE.FILTERS.ORGANIZATIONAL_UNIT.TITLE', { ns: I18nRiskNamespace }),
                    },
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID,
                    setInRequestObj: (requestObj: IServerInputFilterDetails, value: string[]) => {
                        requestObj.orgUnitsIds = value.includes(DEFAULT_ORGANIZATIONAL_UNIT_ID) ? undefined : value;
                        return requestObj;
                    },
                    renderFunction: renderTreeFilter,
                };
            },
        },
        {
            id: ISSUE_FIELD_NAMES.PLATFORM,
            content: ({ aggregations }: IProtectedAssetFilterParams) => {
                const displayMapping: IDisplayMappingObject = {};
                aggregations[ISSUE_FIELD_NAMES.PLATFORM]?.forEach((platform: { value: string }) => {
                    displayMapping[platform.value] = {
                        displayText: getVendorDisplayName(platform.value),
                        icon: getVendor(platform.value)?.icon,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[ISSUE_FIELD_NAMES.PLATFORM],
                        key: ISSUE_FIELD_NAMES.PLATFORM,
                        title: i18n.t('ISSUES.TABLE.FILTERS.PLATFORM.TITLE', { ns: I18nRiskNamespace }),
                        displayMapping,
                    },
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.PLATFORM,
                    renderFunction: renderMultiSelectFilter,
                    setInRequestObj: defaultIssueFilterDataSetter,
                };
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ID,
            content: () => {
                return {
                    filterProps: {
                        initialData: '',
                        key: ISSUE_FIELD_NAMES.ID,
                        title: i18n.t('ISSUES.TABLE.FILTERS.ID.TITLE', { ns: I18nRiskNamespace }),
                    },
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.ID,
                    setInRequestObj: issueIdFilterDataSetter,
                    renderFunction: renderTextFilter,
                };
            }
        },
        {
            id: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
            content: ({ aggregations }: IProtectedAssetFilterParams) => {
                return {
                    filterProps: {
                        initialData: aggregations[ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM],
                        key: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
                        title: i18n.t('ISSUES.TABLE.FILTERS.ENTITY_TYPE.TITLE', { ns: I18nRiskNamespace }),
                        displayMapping: mapTypeItem(aggregations.entityTypeByPlatform),
                    },
                    renderFunction: renderMultiSelectFilter,
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
                    setInRequestObj: defaultIssueFilterDataSetter,
                };
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ENTITY_ID,
            content: () => {
                return {
                    filterProps: {
                        initialData: '',
                        key: ISSUE_FIELD_NAMES.ENTITY_ID,
                        title: i18n.t('ISSUES.TABLE.FILTERS.DOME9_ID.TITLE', { ns: I18nRiskNamespace }),
                    },
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.ENTITY_ID,
                    setInRequestObj: assetIdIssueFilterDataSetter,
                    renderFunction: renderTextFilter,
                };
            }
        },
        {
            id: ISSUE_FIELD_NAMES.SEVERITY,
            content: (filterParams: IProtectedAssetFilterParams) => {
                const { aggregations } = filterParams;
                const displayMapping: IDisplayMappingObject = {};
                const severitiesValueCount: IIssueSeverityValueCount[] = aggregations[ISSUE_FIELD_NAMES.SEVERITY] || [];
                severitiesValueCount.forEach((valueCount: IIssueSeverityValueCount) => {
                    displayMapping[valueCount.value] = { displayText: getIssueSeverityInfo(valueCount.value)?.displayText || '' };
                });
                return {
                    filterProps: {
                        initialData: getFilterValuesInitialData(filterParams, ISSUE_FIELD_NAMES.SEVERITY),
                        key: ISSUE_FIELD_NAMES.SEVERITY,
                        title: i18n.t('ISSUES.TABLE.FILTERS.SEVERITY.TITLE', { ns: I18nRiskNamespace }),
                        displayMapping: getDisplayMappingFromOptions(filterParams, ISSUE_FIELD_NAMES.SEVERITY),
                    },
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.SEVERITY,
                    setInRequestObj: defaultIssueFilterDataSetter,
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
            content: ({ aggregations, allCloudAccounts = [] }: IProtectedAssetFilterParams) => {
                const allCloudAccountsItems = aggregations[ISSUE_FIELD_NAMES.ENVIRONMENT_ID] ? buildCloudAccountList(aggregations[ISSUE_FIELD_NAMES.ENVIRONMENT_ID], allCloudAccounts) : [];
                return {
                    filterProps: {
                        itemCountGroupOption: {
                            enableGrouping: true,
                            countedItemsHeader: i18n.t('ISSUES.TABLE.FILTERS.ENVIRONMENT.COUNTED_ITEMS_HEADER', { ns: I18nRiskNamespace }),
                            nonCountedItemsHeader: i18n.t('ISSUES.TABLE.FILTERS.ENVIRONMENT.NON_COUNTED_ITEMS_HEADER', { ns: I18nRiskNamespace }),
                        },
                        initialData: allCloudAccountsItems,
                        key: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
                        title: i18n.t('ISSUES.TABLE.FILTERS.ENVIRONMENT.TITLE', { ns: I18nRiskNamespace }),
                        displayMapping: mapEnvironmentItem(allCloudAccounts),
                    },
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
                    setInRequestObj: defaultIssueFilterDataSetter,
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: ISSUE_FIELD_NAMES.RULE_TITLE,
            content: (filterParams: IProtectedAssetFilterParams) => {
                const { aggregations } = filterParams;
                return {
                    filterProps: {
                        initialData: aggregations[ISSUE_FIELD_NAMES.RULE_TITLE],
                        key: ISSUE_FIELD_NAMES.RULE_TITLE,
                        title: i18n.t('ISSUES.TABLE.FILTERS.RULE_TITLE.TITLE', { ns: I18nRiskNamespace }),
                        displayMapping: getDisplayMappingFromOptions(filterParams, ISSUE_FIELD_NAMES.RULE_TITLE),
                    },
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.RULE_TITLE,
                    setInRequestObj: defaultIssueFilterDataSetter,
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'region',
            content: ({ aggregations }: IProtectedAssetFilterParams) => {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['region']?.forEach((regionData: { value: string }) => {
                    displayMapping[regionData.value] = { displayText: getRegionDisplayName(regionData.value) };
                });
                return {
                    filterProps: {
                        initialData: aggregations['region'],
                        key: 'region',
                        title: i18n.t('ISSUES.TABLE.FILTERS.REGION.TITLE', { ns: I18nRiskNamespace }),
                        displayMapping,
                    },
                    keyInObjectForAPI: 'region',
                    setInRequestObj: defaultIssueFilterDataSetter,
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: ISSUE_FIELD_NAMES.STATUS,
            content: ({ aggregations }: IProtectedAssetFilterParams) => {
                return {
                    filterProps: {
                        initialData: aggregations[ISSUE_FIELD_NAMES.STATUS],
                        key: ISSUE_FIELD_NAMES.STATUS,
                        title: i18n.t('ISSUES.TABLE.FILTERS.STATUS.TITLE', { ns: I18nRiskNamespace }),
                        displayMapping: aggregations[ISSUE_FIELD_NAMES.STATUS],
                    },
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.STATUS,
                    setInRequestObj: defaultIssueFilterDataSetter,
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: ISSUE_FIELD_NAMES.IS_EXCLUDED,
            content: ({ aggregations }: IProtectedAssetFilterParams) => {
                return {
                    filterProps: {
                        initialData: aggregations[ISSUE_FIELD_NAMES.IS_EXCLUDED],
                        key: ISSUE_FIELD_NAMES.IS_EXCLUDED,
                        title: i18n.t('ISSUES.TABLE.FILTERS.IS_EXCLUDED.TITLE', { ns: I18nRiskNamespace }),
                        displayMapping: getYesNoDisplayMapping(),
                    },
                    keyInObjectForAPI: ISSUE_FIELD_NAMES.IS_EXCLUDED,
                    setInRequestObj: booleanIssueFilterDataSetter,
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: SAVED_FILTERS_FILTER_ID,
            content: ({ filtersInitialData }: IProtectedAssetFilterParams) => {
                return {
                    filterProps: {
                        savedFilters: filtersInitialData?.savedFilters || [],
                        savedFiltersComponentName: '',
                        selectedFilterID: '',
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                    displayTypes: [FILTER_DISPAY_TYPES.ROW],
                };
            },
        },
        {
            id: RECENTLY_USED_FILTER_ID,
            content: ({ filtersInitialData }: IProtectedAssetFilterParams) => {
                return {
                    filterProps: {
                        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                        recentlyUsedList: filtersInitialData?.recentlyUsed,
                        maxLength: 4,
                        componentName: '',
                        title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                    },
                    renderFunction: renderRecentlyUsedFilters,
                };
            },
        },
        {
            id: 'clear all',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
                displayTypes: [FILTER_DISPAY_TYPES.ROW],
            },
        },
    ];

    IssuesRegistry.addFilterAddins(filterDefsAddins);

};

const getSimpleAssetToIssueFieldConvertor = (fieldName: string) => {
    return {
        sourceEntityKind: FieldEntityKind.ASSET,
        targetEntityKind: FieldEntityKind.ISSUE,
        sourceFieldName: fieldName,
    };
};

const initializeFieldConvertor = () => {
    const fieldConvertors: IFieldConvertor[] = [
        {
            sourceEntityKind: FieldEntityKind.ASSET,
            targetEntityKind: FieldEntityKind.ISSUE,
            sourceFieldName: 'cloudAccountId',
            targetFieldName: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
        },
        {
            sourceEntityKind: FieldEntityKind.ASSET,
            targetEntityKind: FieldEntityKind.ISSUE,
            sourceFieldName: 'type',
            targetFieldName: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
        },
        getSimpleAssetToIssueFieldConvertor(ISSUE_FIELD_NAMES.PLATFORM),
        getSimpleAssetToIssueFieldConvertor(ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID),
    ];
    FieldConvertorsRegistry.addFieldConvertors(fieldConvertors);
};


function initializeProtectedAssetIssues() {
    const issuesPanel: IProtectedAssetDetailPanelProvider = {
        isRelevant: ({ typeByPlatform }) => {
            return getSupportedSecurityGraphEntityTypes().includes(typeByPlatform);
        },
        getValue() {
            return {
                name: 'issues',
                title: ermTrans('ISSUES.PANEL.TITLE'),
                isReact: true,
                component: IssuesTablePanel,
            };
        },
    };
    ProtectedAssetsPageRegistry.addPanelAddins([{
        id: 'issues',
        position: 35,
        content: issuesPanel,
        permission: ermPermissionFn
    }]);
}

export default function initializeIssues() {
    initializeIssuesTableColumnDefs();
    initializeTopIssuesTableColumnDefs();
    initializeFilterDefs();
    initializeIssueViewer();
    initializeFieldConvertor();
    initializeProtectedAssetIssues();
}
