import React from 'react';
import {
    BusinessPriorityChipWidget,
    ExposureChipWidget,
    IamSensitivityChipWidget,
    RiskScoreChipWidget,
    WafChipWidget,
} from '../ChipWidgets/ChipWidgets';
import { ExposureNodeData } from 'common/module_interface/insight/ExposureNodeData';
import { UNKNOWN_IAM_EXPOSURE_LEVEL_KEY, UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY } from '../ProtectedAsset/ErmDataUtils';

export const PerimeterExposurePopoverRiskScoreChipWidget: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    return <RiskScoreChipWidget riskScore={data?.riskScore} />;
};
export const PerimeterExposurePopoverExposureChipWidget: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    const networkExposure = data?.exposureLevel;
    const iamExposure = data?.iamExposure;
    return ((networkExposure && networkExposure !== UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY) ||
        (iamExposure && iamExposure !== UNKNOWN_IAM_EXPOSURE_LEVEL_KEY)) ?
        <ExposureChipWidget networkExposure={networkExposure} iamExposure={iamExposure} /> : null;
};

export const PerimeterExposurePopoverBusinessPriorityChipWidget: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    return data?.businessPriority === 'Undefined' ? null : <BusinessPriorityChipWidget businessPriority={data?.businessPriority} />;
};

export const PerimeterExposurePopoverIamSensitivityChipWidget: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    return data?.iamSensitivity !== undefined && data?.iamSensitivity !== null ? <IamSensitivityChipWidget iamSensitivity={data?.iamSensitivity} /> : null;
};

export const PerimeterExposurePopoverWafProtectionChipWidget: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    return data?.wafProtection ? <WafChipWidget wafProtection={data?.wafProtection} /> : null;
};
