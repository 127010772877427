import i18n from 'i18next';
import dayjs from 'dayjs';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { I18nRiskNamespace } from '../../consts';
import { AUTO_ACTION_FIELD_NAMES, IAutoAction } from './AutoActions.interface';
import { SELECTION_GRID_COLUMN } from 'common/utils/tableUtils';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { AutoActionNameCellRender } from './AutoActionRenderers/AutoActionNameCellRender';
import { AutoActionTriggersCellRenderer } from './AutoActionRenderers/AutoActionTriggersCellRender';
import { AutoActionIntegrationsCellRender } from './AutoActionRenderers/AutoActionIntegrationsCellRender';

export const getAutoActionsColumns = (): CGColDef[] => {
    return [
        SELECTION_GRID_COLUMN,
        {
            colId: AUTO_ACTION_FIELD_NAMES.NAME,
            field: AUTO_ACTION_FIELD_NAMES.NAME,
            headerName: i18n.t('AUTO_ACTIONS.TABLE.COLUMNS.NAME.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: AutoActionNameCellRender,
            sortable: true,
            minWidth: 130,
            flex: 6,
            sort: 'asc',
        },
        {
            colId: AUTO_ACTION_FIELD_NAMES.TRIGGERS,
            field: AUTO_ACTION_FIELD_NAMES.TRIGGERS,
            headerName: i18n.t('AUTO_ACTIONS.TABLE.COLUMNS.TRIGGERS.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: AutoActionTriggersCellRenderer,
            minWidth: 90,
            flex: 3,
        },
        {
            colId: AUTO_ACTION_FIELD_NAMES.INTEGRATIONS,
            field: AUTO_ACTION_FIELD_NAMES.INTEGRATIONS,
            headerName: i18n.t('AUTO_ACTIONS.TABLE.COLUMNS.INTEGRATIONS.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: AutoActionIntegrationsCellRender,
            minWidth: 150,
            flex: 4,
        },
        {
            colId: AUTO_ACTION_FIELD_NAMES.DESCRIPTION,
            field: AUTO_ACTION_FIELD_NAMES.DESCRIPTION,
            headerName: i18n.t('AUTO_ACTIONS.TABLE.COLUMNS.DESCRIPTION.HEADER', { ns: I18nRiskNamespace }),
            sortable: true,
            minWidth: 130,
            flex: 6,
        },
        {
            colId: AUTO_ACTION_FIELD_NAMES.CREATED,
            field: AUTO_ACTION_FIELD_NAMES.CREATED,
            headerName: i18n.t('AUTO_ACTIONS.TABLE.COLUMNS.CREATED.HEADER', { ns: I18nRiskNamespace }),
            valueGetter: (data: ValueGetterParams<IAutoAction>) => {
                return data.data?.created ? dayjs(data.data.created).format(i18n.t('COMMON.DATE_FORMATS.LONG_FORMAT')) : '';
            },
            sortable: true,
            minWidth: 120,
            flex: 4,
        },
        {
            colId: AUTO_ACTION_FIELD_NAMES.UPDATED,
            field: AUTO_ACTION_FIELD_NAMES.UPDATED,
            headerName: i18n.t('AUTO_ACTIONS.TABLE.COLUMNS.UPDATED.HEADER', { ns: I18nRiskNamespace }),
            valueGetter: (data: ValueGetterParams<IAutoAction>) => {
                return data.data?.updated ? dayjs(data.data.updated).format(i18n.t('COMMON.DATE_FORMATS.LONG_FORMAT')) : '';
            },
            sortable: true,
            minWidth: 120,
            flex: 4,
        },
    ];
};
