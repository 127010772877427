import React, { useEffect, useState } from 'react';
import { Input, Stack, Typography } from 'common/design-system/components-v2';
import {
    PagerDutyNameWrapper,
    PagerDutyWebhookUrlWrapper,
} from './PagerDutyComponent.styled';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations, SUBMIT_STATUS_RESPONSE } from 'common/module_interface/settings/integrations/consts';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';

interface IGenericConfigurationProps extends IConfigurationContainerProps{
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration{
    configurationObj: IPagerDutyConfigurationObj
}

export interface IPagerDutyConfigurationObj {
    ServiceKey: string,
}

const PagerDutyComponent : React.FC<IGenericConfigurationProps> = ({ onConfigurationChangeCallBack ,configuration, onConfigurationSaved, viewMode }) => {
    const { t } = useTranslation( I18nIntegrations );

    const [pagerDutyNameConfig, setPagerDutyNameConfig] = useState<string>(configuration?.name || '');
    const [pagerDutyApiRoutingKeyConfig, setPagerDutyApiRoutingKeyConfig] = useState<string>(configuration?.configurationObj?.ServiceKey || '');
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);

    const discardChangesButtonHidden = Boolean(idConfiguration);
    const viewOnly = Boolean(viewMode);

    useEffect(() => {
        checkChangesMade();
    }, [pagerDutyNameConfig,
        pagerDutyApiRoutingKeyConfig,
        idConfiguration]);

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        try {
            const resp = onConfigurationSaved && await onConfigurationSaved(pagerDutyNameConfig,
                { ServiceKey: pagerDutyApiRoutingKeyConfig }, idConfiguration);
            resp?.id && setIdConfiguration(resp.id);
            setResponseMessageSuccess(true);
            onConfigurationChangeCallBack && onConfigurationChangeCallBack();
            setIsButtonDisabled(true);
            return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
        } catch (error:any) {
            setResponseMessageSuccess(false);
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
        }
    };

    const checkMissingRequiredInput = () => {
        const variableNotChanged = (
            pagerDutyNameConfig === '' ||
            pagerDutyApiRoutingKeyConfig === ''
        );
        setIsButtonDisabled(variableNotChanged);
    };
    const checkChangesMade = () => {
        const changesDetected = (
            pagerDutyNameConfig !== configuration?.name ||
            pagerDutyApiRoutingKeyConfig !== configuration?.configurationObj?.ServiceKey
        );
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const handleDiscardChanges = () => {
        setPagerDutyNameConfig(configuration?.name || '');
        setPagerDutyApiRoutingKeyConfig(configuration?.configurationObj?.ServiceKey || '');
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setPagerDutyNameConfig(textInput);
    };
    const handleWebhookUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setPagerDutyApiRoutingKeyConfig(textInput);
    };

    return (
        <Stack fullWidth={true} data-aid={'pager-duty'}>
            <PagerDutyNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input type="text" data-aid='nameInput' value={pagerDutyNameConfig} placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange} autoFocus={true} disabled={viewOnly}/>
            </PagerDutyNameWrapper>
            <PagerDutyWebhookUrlWrapper>
                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.PAGER_DUTY.ROUTING_API_KEY')}</Typography>
                <Input type="text" data-aid='urlInput' onChange={handleWebhookUrlChange} placeholder={t('GENERAL.TYPE_HERE')}
                    value={pagerDutyApiRoutingKeyConfig} disabled={viewOnly}/>
            </PagerDutyWebhookUrlWrapper>
            { GenericCancelSubmitButtonWrapper({ handleDiscardChanges ,discardChangesButtonHidden, isButtonDisabled, submitForm, viewOnly, responseMessageSuccess }) }
        </Stack>
    );
};

export default PagerDutyComponent;