import { EXTERNAL_ADDITIONAL_FIELDS_SOURCE } from 'common/components/ProtectedAssets/ProtectedAssetsTable.consts';
import { ProtectedAssetsResponse } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getHttpService } from 'common/interface/services';
import {
    DashboardWidgetTypes,
    IDashboardWidget,
    IGenericWidgetData,
    IGenericWidgetDataItem,
} from 'common/module_interface/overview/Interface';
import { IAggregationDataItem, getCloudAccountsService } from 'common/interface/data_services';
import { limitDataItems } from 'common/components/Widgets/helpers';
import i18n from 'i18next';
import { changeUrl } from 'common/utils/http';
import { getVendor } from 'common/consts/vendors';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';
import { getAssets } from 'common/assets/Assets.service';

const defaultPageSize = 10;
const NONE = 'None';

export const buildProtectedAssetsWidgetLink = (widget: IDashboardWidget, item: any) => {
    const filterState: any[] = widget.filterState;
    const filterPanelObject: any = {};
    filterState.forEach((filter: any) => {
        if(filter.value || filter.value === 0){
            if(filterPanelObject[filter.name]){
                filterPanelObject[filter.name].push(filter.value);
            }
            else{
                if(filter.name === 'free-text' ){
                    filterPanelObject[filter.name] = filter.value;
                }
                else{
                    filterPanelObject[filter.name] = [filter.value];
                }
            }
        }
    });
    if(widget.aggregation && !filterPanelObject[widget.aggregation]){
        filterPanelObject[widget.aggregation] = [item.value];
    }
    const filterPanelStr = JSON.stringify(filterPanelObject);
    return `/protected-asset/index?filterPanel=${filterPanelStr}`;
};

export const defaultOUFilter = { name: 'organizationalUnitId', value: DEFAULT_ORGANIZATIONAL_UNIT_ID };

const defaultIcon = 'cloud';

interface IAssetsFiltersRequest {
    fields: string[];
    freeTextPhrase: string;
    includedEntityTypes: string[];
}

interface IAssetsRequestDataObject {
    filter: IAssetsFiltersRequest;
    pageSize: number;
    skipAggregations: boolean;
    aggregations: string[] | [];
    lowAggregationsSize: boolean;
    externalAdditionalFields: {
        source: string;
    },
    sorting: {
        direction: number;
        fieldName?: string;
    }
}

export const protectedAssetsDataGetter = async (widget: IDashboardWidget, convertData?: Function, aggregationType?: string,useCache=true) => {
    const includedEntityTypes = getAssets().map(asset => asset.typeByPlatform);
    const freeTextPhrase = widget.filterState && widget.filterState.find((filter: any) => filter.name === 'free-text');
    const filterFields = widget.filterState && widget.filterState.filter((filter: any) => filter.name !== 'free-text' && filter.value !== '');
    const isSummaryWidget = widget.type === DashboardWidgetTypes.Summary;

    const requestDataObject: IAssetsRequestDataObject = {
        filter: {
            fields: filterFields || [defaultOUFilter],
            freeTextPhrase: freeTextPhrase?.value || '',
            includedEntityTypes: includedEntityTypes,
        },
        pageSize: widget.limit || defaultPageSize,
        externalAdditionalFields: {
            source: EXTERNAL_ADDITIONAL_FIELDS_SOURCE.THIRD_PARTY,
        },
        aggregations: [],
        lowAggregationsSize: true,
        skipAggregations: false,
        sorting: {
            direction: -1,
            fieldName: aggregationType
        },
    };

    if(aggregationType && !isSummaryWidget){
        requestDataObject.aggregations = [aggregationType];
    } else {
        requestDataObject.skipAggregations = true;
    }

    const response = await getHttpService().post<ProtectedAssetsResponse>(ASSETS_SEARCH_URL, {
        data: requestDataObject,
    },{
        cachingConfig: {
            useCache: useCache
        }
    });

    let selectedAggregation = aggregationType && response.aggregations[aggregationType];
    selectedAggregation = selectedAggregation || [];
    const aggregationData = selectedAggregation && prepareWidgetsDataFromAggregations(selectedAggregation, widget);
    if (convertData && selectedAggregation && !isSummaryWidget) {
        return await convertData(selectedAggregation, widget);
    } else if (convertData && isSummaryWidget) {
        return await convertData(response, widget);
    } else if (convertData) {
        return await convertData(response, widget);
    }
    else {
        return aggregationData;
    }
};

export const prepareWidgetsDataFromAggregations = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget): any => {
    const widgetItems = [] as IGenericWidgetDataItem[];
    for (const data of selectedAggregation) {
        widgetItems.push({ key: data.value || NONE, value: data.count, icon: defaultIcon });
    }
    return { items: limitDataItems(widgetItems, widget.limit || widgetItems.length) };
};

export const getRegionDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((item) => {
        return {
            key: item.value || i18n.t('EVENTS.GLOBAL'),
            value: item.count,
            icon: defaultIcon,
            onclick: ()=> changeUrl(buildProtectedAssetsWidgetLink(widget, item))
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit)
    };
};

export const getPlatformDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation?.map((platform) => {
        const vendorData = getVendor(platform.value);
        return {
            key: vendorData?.displayName || vendorData?.name || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: platform.count,
            icon: vendorData?.icon || defaultIcon,
            onclick: ()=> changeUrl(buildProtectedAssetsWidgetLink(widget, platform))
        };
    });

    return {
        items: widget.limit && limitDataItems(items, widget.limit)
    };
};

export const getEnvironmentsNames = async (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget): Promise<IGenericWidgetData> => {
    const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
    const cloudAccountItems = selectedAggregation.reduce((result: IGenericWidgetDataItem[], aggItem: IAggregationDataItem) => {
        const selectedCloudAccount = allCloudAccounts.find((cloudAccount: any) => aggItem.value === cloudAccount.id);
        if (selectedCloudAccount) {
            result.push({
                key: selectedCloudAccount?.name || aggItem.value || i18n.t('EVENTS.NOT_AVAILABLE'),
                value: aggItem.count,
                icon: selectedCloudAccount.platform
            });
        }
        return result;
    }, []);
    return {
        items: limitDataItems(cloudAccountItems, widget.limit || cloudAccountItems.length)
    };
};

export const getOCIDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation.map(aggregationDataItem => {
        return {
            key: aggregationDataItem.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: aggregationDataItem.count,
            icon: 'cloud',
            onclick: ()=> changeUrl(buildProtectedAssetsWidgetLink(widget, aggregationDataItem))
        };
    });
    return {
        items: limitDataItems(items, widget.limit || items.length)
    };
};

export const getNetworkDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation.map(aggregationDataItem => {
        return {
            key: aggregationDataItem.value || i18n.t('EVENTS.GLOBAL'),
            value: aggregationDataItem.count,
            icon: 'cloud',
            onclick: ()=> changeUrl(buildProtectedAssetsWidgetLink(widget, aggregationDataItem))
        };
    });
    return {
        items: limitDataItems(items, widget.limit || items.length)
    };
};

export const getResourceGroupDisplayData = (selectedAggregation: IAggregationDataItem[], widget: IDashboardWidget) => {
    const items = selectedAggregation.map(aggregationDataItem => {
        return {
            key: aggregationDataItem.value || i18n.t('EVENTS.NOT_AVAILABLE'),
            value: aggregationDataItem.count,
            icon: 'cloud',
            onclick: ()=> changeUrl(buildProtectedAssetsWidgetLink(widget, aggregationDataItem))
        };
    });
    return {
        items: limitDataItems(items, widget.limit || items.length)
    };
};

export const getAssetsSummaryData = (response: ProtectedAssetsResponse, widget: IDashboardWidget) => {
    const totalCount = response.totalCount;
    const assetsIcon = 'assets';
    const defaultThresholds = [{
        color: '#000000',
        min: 0,
        max: 1,
    },
    {
        color: '#56b40a',
        min: 2,
        max: false,
    }
    ];
    const getColorByThresholds = () => {
        const thresholds = widget.options?.thresholds || defaultThresholds;

        if (thresholds && thresholds.length === 2) {
            const thresholdsLessThanNumber = thresholds[1].min;
            const thresholdsMoreThanColor = thresholds[0].color;
            const thresholdsLessThanColor = thresholds[1].color;


            if (totalCount > thresholdsLessThanNumber - 1) {
                return thresholdsLessThanColor;
            } else {
                return thresholdsMoreThanColor;
            }
        } else {
            return null;
        }
    };

    return {
        items: [{
            key: widget.description,
            value: totalCount,
            color: getColorByThresholds(),
            icon: assetsIcon
        }],
    };
};
