import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { getService, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { Vendors } from 'common/consts/vendors';
import { IRulesetService, RULESET_SERVICE_ID } from 'common/interface/services';
import {
    ENVIRONMENT_POLICY_RULESETS,
    IShallowRuleset,
    IVendorRuleset, OU_POLICY_RULESETS, POLICY_ENVIRONMENT_VENDORS,
    POLICY_RULESETS,
    POLICY_VENDORS,
    Vendor
} from 'common/interface/policy';
import AddPolicyPage from './PlatformPolicy/AddPolicyPage';
import AddEnvironmentPolicyPage from './EnvironmentPolicy/AddEnvironmentPolicyPage';
import AddOUPolicyPage from './OUPolicy/AddOUPolicyPage';
import { EditPolicyPage } from './EditPolicy/EditPolicyPage';
import { AddEditServerlessPolicyPage } from './PlatformPolicy/AddServerlessPolicyPage';
export const CSPM = 'CSPM';
export const getRulesets = async (vendor?:Vendors) => {
    let rulesets = await getService<IRulesetService>(RULESET_SERVICE_ID).getAllRulesets();
    rulesets = rulesets.map((ruleset) => {
        return { ...ruleset,
            cloudVendor: ruleset.cloudVendor === 'google' ? 'gcp' : ruleset.cloudVendor,
            availableVersions: [...ruleset.availableVersions, { version: 'Latest' }]
        };
    });

    const shallowRulesets: IShallowRuleset[] = rulesets.map((ruleset) => {
        return {
            id: ruleset.id.toString(),
            name: ruleset.name,
            icon: ruleset.cloudVendor,
            description: ruleset.description,
            availableVersions: ruleset.availableVersions,
            platform: ruleset.cloudVendor
        };
    });
    if(vendor) {
        return shallowRulesets.filter((ruleset: IShallowRuleset) => ruleset.platform === vendor);
    }else {
        return shallowRulesets;
    }
};

export const getEnvironmentRulesetsByVendor = async (vendor?:Vendors) => {
    let rulesets = await getService<IRulesetService>(RULESET_SERVICE_ID).getAllRulesets();
    rulesets = rulesets.map((ruleset) => {
        return { ...ruleset,
            cloudVendor: ruleset.cloudVendor === 'google' ? 'gcp' : ruleset.cloudVendor,
            availableVersions: [...ruleset.availableVersions, { version: 'Latest' }]
        };
    });

    const shallowRulesets: IShallowRuleset[] = rulesets.map((ruleset) => {
        return {
            id: ruleset.id.toString(),
            name: ruleset.name,
            icon: ruleset.cloudVendor,
            description: ruleset.description,
            availableVersions: ruleset.availableVersions,
            platform: ruleset.cloudVendor
        };
    });
    if(vendor) {
        if(vendor === Vendors.AWS) {
            return shallowRulesets.filter((ruleset: IShallowRuleset) => ruleset.platform === vendor || ruleset.platform === Vendors.IMAGE_ASSURANCE);
        }else{
            return shallowRulesets.filter((ruleset: IShallowRuleset) => ruleset.platform === vendor);
        }
    }else {
        return shallowRulesets;
    }
};

export default function initialize() {

    const policyPageRoute = {
        condition: true,
        component: AddPolicyPage,
        path: '/policy/add',
    };
    const policyEnvironmentsPageRoute = {
        condition: true,
        component: AddEnvironmentPolicyPage,
        path: '/policy/environments/add',
    };
    const policyOUPageRoute = {
        condition: true,
        component: AddOUPolicyPage,
        path: '/policy/ou/add',
    };

    const policyEditPageRoute = {
        condition: true,
        component: EditPolicyPage,
        path: '/policy/edit',
    };

    const addEditServerlessPolicyPagePageRoute = {
        condition: true,
        component: AddEditServerlessPolicyPage,
        path: '/serverless/policy/edit',
    };

    const vendors : Vendor[] = [
        {
            id: Vendors.AWS,
            name: Vendors.AWS,
            icon: 'aws',
            title: 'Amazon Web Services'
        },
        {
            id: Vendors.AZURE,
            name: Vendors.AZURE,
            icon: 'azure',
            title: 'Microsoft Azure'
        },
        {
            id: Vendors.GCP,
            name: Vendors.GCP,
            icon: 'gcp',
            title: 'Google Cloud Platform'
        },
        {
            id: Vendors.ALIBABA,
            name: Vendors.ALIBABA,
            icon: 'alibaba',
            title: 'Alibaba'
        },
        {
            id: Vendors.OCI,
            name: Vendors.OCI,
            icon: 'oracle',
            title: 'Oracle Cloud Platform'
        }
    ];


    const getVendorsRulesets = () => {

        const vendorsRulesetsList:IVendorRuleset[] = [];
        for (const vendor of vendors) {
            vendorsRulesetsList.push({ vendorId: vendor.id, getRulesets: () => getRulesets(vendor.id) });
        }
        return vendorsRulesetsList;
    };
    const getEnvironmentRulesets = () => {

        const vendorsRulesetsList:IVendorRuleset[] = [];
        for (const vendor of vendors) {
            vendorsRulesetsList.push({ vendorId: vendor.id, getRulesets: () => getEnvironmentRulesetsByVendor(vendor.id) });
        }
        return vendorsRulesetsList;
    };
    const vendorRulesetsList = getVendorsRulesets();
    const environmentRulesetsList = getEnvironmentRulesets();
    const getMappedRulesetItems = (rulesetsList: IVendorRuleset[]) => {
        return [...rulesetsList.map((item: IVendorRuleset) => ({
            id: item.vendorId, content: {
                getRulesets: item.getRulesets,
                vendorId: item.vendorId
            }
        }))];
    };

    globalAddinContainer.add(POLICY_ENVIRONMENT_VENDORS, [...vendors.map(vendor => ({ id: vendor.id, content: vendor }))]);
    globalAddinContainer.add(POLICY_VENDORS, [...vendors.map(vendor => ({ id: vendor.id, content: vendor }))]);

    globalAddinContainer.add(POLICY_RULESETS, getMappedRulesetItems(vendorRulesetsList));
    
    globalAddinContainer.add(ENVIRONMENT_POLICY_RULESETS, getMappedRulesetItems(environmentRulesetsList));

    globalAddinContainer.add(OU_POLICY_RULESETS, getMappedRulesetItems(environmentRulesetsList));


    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'Policy', content: policyPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'PolicyOU', content: policyOUPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'PolicyEnvironments', content: policyEnvironmentsPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'PolicyEdit', content: policyEditPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'addEditServerlessPolicyPagePageRoute', content: addEditServerlessPolicyPagePageRoute }]);

}

