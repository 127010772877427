let rootAsset:any = null;
export type ItemType ='action'|'operator'|'property'|'function'|'value'|'connector'|'root'|'variable'|'arrayOperator'|'inOperator';
export const startingBracketsConnector :Item = { name: '[',type: 'connector' };
export const closingBracketConnector :Item = { name: ']',type: 'connector' };
export const startingParenthesesConnector :Item = { name: '(',type: 'connector' };
export const closingParenthesesConnector:Item = { name: ')',type: 'connector' };
export interface Item{
    type:ItemType;
    name:string;
    valueType?:'string'|'int'|'object'|'bool'|'array'|'time';
    propertyPath?:string;
}

let optionalUserValues:any = null;
async function initBuilder(asset:any,inputUserValues:any){
    rootAsset = asset;
    optionalUserValues = inputUserValues;
}


const StartingConnectors : Item[] =[
    { name: '(',type: 'connector' },
    { name: ')',type: 'connector' },
    { name: 'and',type: 'connector' },
    { name: 'or',type: 'connector' },
    { name: 'not',type: 'connector' }];

const IntOperators: Item[] = [
    { name: '>',type: 'operator' },
    { name: '<',type: 'operator' },
    { name: '>=',type: 'operator' },
    { name: '<=',type: 'operator' },
    { name: '=',type: 'operator' },
    { name: '!=',type: 'operator' }];

const StringOperators: Item[] = [
    { name: 'like',type: 'operator' },
    { name: 'unlike',type: 'operator' },
    { name: '=',type: 'operator' },
    { name: '!=',type: 'operator' }];

const ArrayOperators: Item[] = [
    { name: 'with',type: 'arrayOperator' },
    { name: 'contain',type: 'arrayOperator' },
    { name: 'contain-any',type: 'arrayOperator' },
    { name: 'contain-none',type: 'arrayOperator' },
    { name: 'contain-all',type: 'arrayOperator' }];

const BoolOperators: Item[] = [
    { name: '=',type: 'operator' },
    { name: '!=',type: 'operator' }];

const suggestedUserInputs : Item[] = [ { name: 'inputValue',type: 'inOperator' }, { name: 'inputList',type: 'inOperator' }];

const logicalOperations = StartingConnectors.filter((item:Item)=>item.name === 'and' || item.name === 'or');

const ConnectorFunctions: Item[] = [{ type: 'function',name: 'containSecrets' }];

const OperatorsFunctions: Item[] = [{ type: 'function',name: 'in' }];

let Properties:Item[];

export const initProperties = async (asset:any,inputUserValues:any)=>{
    await initBuilder(asset,inputUserValues);
    Properties = getObjectNextPropertiesOptions();
};
function getObjectNextPropertiesOptions(items?: Item[]): Item[] {
    let property = rootAsset;
    let item : Item | undefined = undefined;
    if(items?.length) {
        for (let i=items.length;i--;i>0){
            if(items[i].type === 'property'){
                item = items[i];
                break;
            }
        }
    }


    if(item?.propertyPath){
        item?.propertyPath.split('.').forEach(p=>{
            if(p in property){
                // @ts-ignore
                property = property[p];
            }
        });
    }

    if(Array.isArray(property)){
        property = property[0];
    }

    const entries = Object.entries(property);
    return entries.map(entry => {
        let _valueType ;
        if(Array.isArray(entry[1])){
            _valueType = 'array';
        }else{
            _valueType = typeof entry[1] === 'object' ? typeof entry[1] : entry[1];
        }
        return {
            name: entry[0],
            propertyPath: item?.propertyPath ? item.propertyPath + '.' + entry[0] : entry[0],
            valueType: _valueType,
            type: 'property'
        } as Item;
    });
}

export function getNextOptions(current : Item[]):Item[] | null{
    let nextOptions :Item[] | null = [];

    function getClosingOperator(items: any):Item[] {

        const brackets = [];
        for (let i=0;i<items.length;i++){
            if(items[i].name === '['){
                brackets.push(true);
            }
            if(items[i].name === ']'){
                brackets.pop();
            }
        }
        if(brackets.length){
            return [closingBracketConnector];
        }else{
            const startingOperators = items.filter((item:any)=>item.name === '(').length;
            const closingOperators = items.filter((item:any)=>item.name === ')').length;
            if(closingOperators === startingOperators) return [];
            return closingOperators < startingOperators ? [closingParenthesesConnector] : [startingParenthesesConnector];
        }
    }

    const closingOperator:Item[] = getClosingOperator(current);
    const lastItem = current[current.length-1];
    switch (lastItem.type) {
        case 'function':
            if(lastItem.name === 'in'){
                current.push(startingParenthesesConnector);
                nextOptions = suggestedUserInputs;
            }else{
                nextOptions = [...logicalOperations];
            }
            break;
        case 'variable':
            nextOptions = [closingParenthesesConnector,...optionalUserValues];
            break;
        case 'inOperator':
            if(lastItem.name === 'inputList'){
                nextOptions = optionalUserValues;
            }
            if(lastItem.name === 'inputValue'){
                nextOptions = null;
            }
            break;
        case 'value':
            nextOptions= [...logicalOperations,...closingOperator];
            break;
        case 'action':
            nextOptions= [...StartingConnectors.filter((item:Item)=>item.name === '(' || item.name === 'not'),...Properties,...ConnectorFunctions];
            break;
        case 'connector':
            switch (lastItem.name){
                case 'not':
                    nextOptions= [startingParenthesesConnector,...Properties,...ConnectorFunctions];
                    break;
                case 'and':
                case 'or':
                    nextOptions= [...StartingConnectors.filter((item:Item)=>item.name === '(' || item.name === 'not'),...Properties,...ConnectorFunctions];
                    break;
                case ')':
                case ']':
                    nextOptions= [...logicalOperations,...getClosingOperator(current)];
                    break;
                case '(':
                    if(current[current.length-2].name === 'in'){
                        nextOptions = [closingParenthesesConnector];
                    }else {
                        nextOptions= [...StartingConnectors,...Properties,...ConnectorFunctions];
                    }
                    break;
                case '[':
                    nextOptions= [...StartingConnectors.filter((item:Item)=>item.name === '(' || item.name === 'not'),closingBracketConnector,...getObjectNextPropertiesOptions(current)];
                    break;
                default:
                    nextOptions= [...StartingConnectors,...Properties,...ConnectorFunctions];
                    break;
            }
            break;
        case 'operator':
            nextOptions = null;
            break;
        case 'arrayOperator':
            nextOptions = [startingBracketsConnector];
            break;
        case 'property':
            switch (lastItem.valueType) {
                case 'string':
                    nextOptions = [...StringOperators,...OperatorsFunctions];
                    break;
                case 'int':
                    nextOptions = [...IntOperators,...OperatorsFunctions];
                    break;
                case 'bool':
                    nextOptions = [...BoolOperators,...OperatorsFunctions];
                    break;
                case 'object':
                    nextOptions = getObjectNextPropertiesOptions(current);
                    break;
                case 'array':
                    nextOptions = ArrayOperators;
                    break;
            }

            break;
    }


    return nextOptions;
}


