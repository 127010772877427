import React, { useEffect } from 'react';
import { Vendors } from 'common/consts/vendors';
import { useTranslation } from 'react-i18next';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { getFixedVendorIcon } from 'common/components/policy/utils';
import { MultiSelectListComponent } from 'common/components/policy/MultiSelectList';
import { TextIconItem } from 'common/components/policy/MultiSelectList/ListItemTypes/TextIconItem';
import { i18nIntelligenceNamespace } from '../initialize.i18n';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import { IntelligenceRuleset } from 'common/module_interface/intelligence/Intelligence.interface';

export interface PlatformRulesetSelectionProps{
    onValidate:Function;
    platform?: Vendors;
    selectedRulesetsChanged:Function;
    preSelectedItems?: ListItem[];
}

const notGuardDutyRuleset = (item: IntelligenceRuleset) => {
    const guardDutyRulesetId = '999';
    return item.id !== guardDutyRulesetId;
};
export const IntelligencePlatformRulesetSelectionComponent: React.FC<PlatformRulesetSelectionProps> = ({ preSelectedItems, onValidate,platform,selectedRulesetsChanged }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const[rulesets, setRulesets] = React.useState<ListItem[]>(preSelectedItems||[]);

    const init = async () => {
        const vendorsRulesetList = await getIntelligenceService().getIntelligenceRulesets();
        const _platform = platform === Vendors.GCP ? Vendors.GOOGLE : platform;
        const matchedVendorsRulesets = vendorsRulesetList.filter((item) => item.vendor === _platform && notGuardDutyRuleset(item));
        const items : ListItem[] = matchedVendorsRulesets.map((ruleset) => {
            return {
                id: ruleset.id.toString(),
                name: ruleset.name || '',
                icon: getFixedVendorIcon(ruleset.icon),
            };
        }) || [];
        setRulesets(items);
    };
    useEffect(() => {
        init();
    }, []);


    const selectedItemsChanged = (selectedItems: ListItem[]) => {
        onValidate(selectedItems?.length);
        selectedRulesetsChanged(selectedItems);
    };

    return (
        <span data-aid={'rulesets-selection'}>
            <MultiSelectListComponent preSelectedItems={preSelectedItems} itemTemplate={TextIconItem} header={t('POLICY.SELECT_RULESET')} items={rulesets} onSelectedItemsChanged={selectedItemsChanged}/>
        </span>
    );

};