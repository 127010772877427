import i18n, { validLanguages } from 'common/services/translations/translations';
import { I18nIdentityNamespace } from './consts';

export const initializeI18Identity = async () => {
    return Promise.all(validLanguages.map(async (language) => {
        try {
            const contents = await require(`./languages/${language}/translation.json`);
            i18n.addResourceBundle(language, `${I18nIdentityNamespace}`, contents);
        } catch {
            //Nothing to do with missing translation files
        }
    }));
};
