import { IntegrationIcon, StackWrapper } from './IntegrationCard.styled';
import { ALink } from 'common/components/ALink';
import { I18nIntegrations, IntegrationsDefinition } from 'common/module_interface/settings/integrations/consts';
import Typography from 'common/design-system/components-v2/Typography';
import { Chip, Stack } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';


export const IntegrationCard = (integrationCardProps:IntegrationsDefinition)=>{
    const { title,icon,category,configurationUrl, configurationCount = '',onClick } = integrationCardProps;
    const key = title + category;
    const { t } = useTranslation( I18nIntegrations );
    const configurationCountLabel = configurationCount + ' ' + ( +configurationCount > 1 ? t('GENERAL.CONFIGURATIONS') : t('GENERAL.CONFIGURATION'));
    const Content =
        <StackWrapper data-aid={'integration-card'} data-aid2={title} margin={2} padding={2} spacing={2} direction='row' alignItems='center'>
            <IntegrationIcon >
                <img src={icon} alt={''}/>
            </IntegrationIcon>
            <Stack>
                <Typography variant='bodyLg' >{title}</Typography>
                {configurationCount ? <Chip
                    label={configurationCountLabel}
                    size="md"
                /> : ''}
            </Stack>
        </StackWrapper>;
    return configurationUrl ?
        <ALink target={'_blank'} href={configurationUrl}>{Content}</ALink> :
        <div onClick={() => onClick && onClick(key)}>{Content}</div>;
};