import { oci_actions } from '.';
import { batch } from 'react-redux';
import { getStoreService, getWebAppIframeService } from 'common/interface/services';
import OciCloudAccountsService from '../services/cloudAccounts/ociCloudAccounts.service';
import { OciEnvironmentData } from 'common/module_interface/assets/OciEnvironment';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import EnvironmentsService from '../../services/environments.service';


const getMessageFromError: (error: any) => string = (error: any) => {
    const responseError = error.response?.data?.errors?.description?.join(', ');
    const responseErrorOpt2 = error.response?.data?.title;
    const responseErrorOpt3 = error.response?.data?.message;
    const responseErrorOpt4 = error.message;
    return responseError || responseErrorOpt2 || responseErrorOpt3 || responseErrorOpt4;
};

type GetOciAccount = (ociId: string) => Promise<boolean>;

const getOciAccount: GetOciAccount = async (ociId) => {
    const { dispatch } = getStoreService().getReduxTools();
    try {
        dispatch(oci_actions.setLoading({ ociId, loading: true }));
        const results = await Promise.allSettled([
            OciCloudAccountsService.cloudAccountGet(ociId),
            EnvironmentsService.getTotalAssetsNumberOfCloudAccount(ociId)
        ]);
        const [accountData, totalAssets] = results;
        if (accountData.status === 'rejected' || !accountData.value.data) throw new Error('');
        const totalAssetsCount = totalAssets.status === 'fulfilled' ? totalAssets.value : 0;
        const ociData: OciEnvironmentData = {
            account: { ...accountData.value.data, totalAssets: totalAssetsCount },
        };
        batch(() => {
            dispatch(oci_actions.setData({ ociId, data: ociData }));
            dispatch(oci_actions.setError({ ociId, error: false }));
            dispatch(oci_actions.setLoading({ ociId, loading: false }));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(oci_actions.setError({ ociId, error: true }));
            dispatch(oci_actions.setLoading({ ociId, loading: false }));
        });
        throw Error(getMessageFromError(error));
    }
};

export interface UpdateOciAccountNameReq {
    name: string;
}

export interface UpdateOciCredentialsReq {
    accessKey: string;
    accessSecret: string;
}

type UpdateOciAccount = (cloudAccountId: string, name: string) => Promise<boolean>;
const updateOciAccountName: UpdateOciAccount = async (cloudAccountId, name) => {
    try {
        const { state } = getStoreService().getReduxTools();
        const { name: currentName } = state.env_oci.ociEvnironments[cloudAccountId].data?.account || {};

        if (name && name !== currentName) {
            const res = await OciCloudAccountsService.cloudAccountNameUpdate({ cloudAccountId, name });
            if(res){
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.UPDATE_TABPANEL_TABNAME,
                        data: { id: cloudAccountId, newName: name }
                    }),
                );
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                    }),
                );
                getOciAccount(cloudAccountId);
            }
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type UpdateCredentialsOciAccount = (cloudAccountId: string, data: UpdateOciCredentialsReq) => Promise<boolean>;
const updateCredentialsOciAccount: UpdateCredentialsOciAccount = async (cloudAccountId, data) => {
    const { accessKey , accessSecret } = data;
    try {
        if (accessKey && accessSecret) {
            const res = await OciCloudAccountsService.cloudAccountsCredentialsUpdate({ id: cloudAccountId, accessKey, accessSecret });
            if(res){
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                    }),
                );
                getOciAccount(cloudAccountId);
            }
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type DeleteAccount = (ociId: string, forceDelete?: boolean) => Promise<boolean>;
const deleteAccount: DeleteAccount = async (ociId, forceDelete = false) => {
    try {
        const { dispatch } = getStoreService().getReduxTools();
        if (forceDelete) {
            await OciCloudAccountsService.cloudAccountDeleteForce(ociId);
            dispatch(oci_actions.setDeleted({ ociId }));
        }
        else{
            await OciCloudAccountsService.cloudAccountDelete(ociId);
            dispatch(oci_actions.setDeleted({ ociId }));
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};


const OciActions = {
    getOciAccount,
    updateOciAccountName,
    updateCredentialsOciAccount,
    deleteAccount,
};

export default OciActions;
