import {
    GROUPING_COUNT_FIELD_NAME,
    IAggregationInfo,
    IEvidencePathIssueInfo,
    IFlatIssueSearchResponse,
    IFlatIssueSearchResult,
    IFlatIssuesSearchRequest,
    IGroupedIssuesSearchRequest,
    IIssueCve,
    IIssueCveModel,
    IIssueOrGroup,
    IIssuesGroup,
    IIssuesGroupMap,
    IIssuesGroupViewModel,
    IIssuesGroupWithTotal,
    IIssuesTableDataWithTotals,
    IServerInputSort,
    ISSUE_FIELD_NAMES,
} from 'modules/riskManagement/components/Issues/Issues.interface';
import {
    IAssetUrlRequiredProps,
    IProtectedAssetViewModel,
} from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { getCloudEntityData } from 'common/components/ProtectedAssets/AssetUtils';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { isEqualList } from '../../RiskManagement.utils';
import { getFullEntityName, getPureEntityName } from 'common/components/ProtectedAssets/Renderers/EntityCellRender';
import { IFieldInfo, SortDirection } from 'common/interface/general';
import { IRunAllResultsMap, isNil, runAll } from 'common/utils/helpFunctions';
import { IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { ColumnVO } from 'ag-grid-community/dist/lib/interfaces/iColumnVO';
import { GridApi } from 'ag-grid-community';
import {
    FIRST_TOXIC_FIELD_NAME,
    GROUPING_SORT_BY_COUNT,
    IAM_SENSITIVITY_URL,
    ISSUE_URL_PREFIX,
    ISSUE_VULNERABILITIES_URL,
    ISSUES_GROUPING_URL,
    ISSUES_SEARCH_URL,
    MAIN_ENTITIES_URL,
    MALWARE_URL,
    ROOT_ISSUES_GROUP_ID,
    SECOND_TOXIC_FIELD_NAME,
} from './Issues.consts';
import {
    IdType,
    IIssue,
    IIssueViewModel,
    IServerInputFilterDetails,
    ISSUE_ORIGIN_TYPE,
    IssueStatusEnum,
} from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { FieldEntityKind } from 'common/registries/FieldConvertorsRegistry';
import { convertFilterField } from 'common/utils/filterUtils';
import { ColumnApi } from 'ag-grid-community/dist/lib/columns/columnApi';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { ISSUE_VIEWER_KEY } from './IssueViewer/IssueViewer.consts';
import { IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { changeUrl, generalApiError, toQueryString } from 'common/utils/http';
import { getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';
import { IFacetData } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import {
    FilterConditionOperator,
    IFilterCondition,
    IFilterConditionValue,
} from 'common/erm-components/custom/FilterTree/FilterCondition';
import { isBoolean } from 'common/erm-components/utils/types';
import { IamSensitivity } from './IssueViewer/IssueViewerPanels/IssueEvidencePanel/HighIAMPrivileges/IAMSensitivity.interface';

import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { MalwareInfo } from './IssueViewer/IssueViewerPanels/IssueEvidencePanel/Malware/Malware.interface';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

const ISSUE_ORIGIN_CONDITION: IFilterCondition = {
    name: ISSUE_FIELD_NAMES.ORIGIN,
    values: [ISSUE_ORIGIN_TYPE.SECURITY_GRAPH],
    operator: FilterConditionOperator.In,
};

const ISSUE_STATUS_OPEN_CONDITION: IFilterCondition = {
    name: ISSUE_FIELD_NAMES.STATUS,
    values: [IssueStatusEnum.OPEN],
    operator: FilterConditionOperator.In,
};

export const getCloudEntityDataFromIssue = async (issue: IIssue): Promise<ICloudEntityData | undefined> => {
    return getCloudEntityData(issue.entityId, issue.entityType, issue.platform, issue.environmentId, generalApiError).then((entityData: ICloudEntityData | null) => {
        return entityData || undefined;
    });
};

export const getAssetUrlFromIssue = (issue: IIssue, tabName?: string, innerTabIndex?: number): string | undefined => {
    const {
        entityId,
        entityIdType,
        platform,
        entityType,
        entityTypeByPlatform,
        environmentId,
        region,
        entityExternalId,
    } = issue;
    const urlProps: IAssetUrlRequiredProps = {
        entityId: entityIdType === IdType.DOME9_ID_TYPE ? entityExternalId : entityId,
        platform,
        type: entityType,
        typeByPlatform: entityTypeByPlatform,
        cloudAccountId: environmentId,
        region,
        dome9Id: entityIdType === IdType.DOME9_ID_TYPE ? entityId : undefined,
        generateUrl: (asset: IAsset, assetUrl: UrlFuncResult) => getErmUrlsService().generateAssetUrl(asset, assetUrl, tabName, innerTabIndex),
    };
    return getProtectedAssetsService().getProtectedAssetUrlByProps(urlProps) || undefined;
};

export const getAssetUrl = (asset: IProtectedAssetViewModel, tabName?: string, innerTabIndex?: number): string | undefined => {
    const {
        entityId,
        platform,
        type,
        typeByPlatform,
        region,
        cloudAccountId
    } = asset;
    const urlProps: IAssetUrlRequiredProps = {
        entityId: entityId,
        platform,
        type: type,
        typeByPlatform: typeByPlatform,
        cloudAccountId: cloudAccountId,
        region,
        generateUrl: (asset: IAsset, assetUrl: UrlFuncResult) => getErmUrlsService().generateAssetUrl(asset, assetUrl, tabName, innerTabIndex),
    };
    return getProtectedAssetsService().getProtectedAssetUrlByProps(urlProps) || undefined;
};

export const gotoAssetPage = (issue: IIssue, asset?: IProtectedAssetViewModel, tabName?: string, innerTabIndex?: number) => {
    const url = asset ? getAssetUrl(asset, tabName, innerTabIndex) : getAssetUrlFromIssue(issue, tabName, innerTabIndex);
    if (!url) {
        return;
    }
    changeUrl(url);
};

export const getEntityNameFromIssue = (issue: IIssue, isFullName: boolean): string => {
    return isFullName ? getFullEntityName(issue.entityName, issue.entityId, issue.platform) : getPureEntityName(issue.entityName, issue.entityId);
};

const createIssueFromModel = async (issueModel: IIssueViewModel): Promise<IIssue> => {
    const idTypeValues: string[] = Object.values(IdType) as string[];
    return {
        ...issueModel,
        idType: idTypeValues.includes(issueModel.entityIdType.toString()) ? issueModel.entityIdType as IdType : IdType.DOME9_ID_TYPE,
    };
};

export const createSingleIssueFromModel = async (issueModel: IIssueViewModel): Promise<IIssue> => {
    return createIssueFromModel(issueModel);
};

export const createIssuesListFromModel = async (issueModels: IIssueViewModel[]): Promise<IIssue[]> => {
    const issues: IIssue[] = [];
    for (let i = 0; i < issueModels.length; i++) {
        issues.push(await createIssueFromModel(issueModels[i]));
    }
    return issues;
};

export const directionStringToEnum = (direction: 'asc' | 'desc'): SortDirection => {
    return (Object.values(SortDirection) as string[]).includes(direction) ? direction as SortDirection : SortDirection.ASC;
};

export const getSortModelForColumn = (columnApi: ColumnApi, colId: string, sortDirection: 'asc' | 'desc') => {
    const column = columnApi.getColumn(colId);
    const field = column?.getColDef().field;
    if (field) {
        return {
            fieldName: field,
            direction: directionStringToEnum(sortDirection),
        };
    }
    return null;
};

export const getGridGroupingCols = (params: IServerSideGetRowsParams<IIssue>): ColumnVO[] | undefined => {
    return params.request.rowGroupCols.length ? params.request.rowGroupCols : undefined;
};

export const serverFacetsToAggregations = (facets: IFacetData[]): Aggregations => {
    const aggregations: Aggregations = {};
    facets.forEach((facetInfo: IFacetData) => {
        aggregations[facetInfo.facet] = facetInfo.data;
    });
    return aggregations;
};

export const getFinalIssuesFilterDetails = (filterValues: IServerInputFilterDetails = {}, setDefaultStatusOpen?: boolean): IServerInputFilterDetails => {
    const fields: IFilterCondition[] = filterValues.fields ?? [];
    const newFields: IFilterCondition[] = [...fields];
    if (!newFields.find((field: IFilterCondition) => field.name === ISSUE_FIELD_NAMES.ORIGIN)) {
        newFields.push(ISSUE_ORIGIN_CONDITION);
    }

    if (setDefaultStatusOpen) {
        if (!newFields.find((field: IFilterCondition) => field.name === ISSUE_FIELD_NAMES.STATUS)) {
            newFields.push(ISSUE_STATUS_OPEN_CONDITION);
        }
    }

    return {
        ...filterValues,
        fields: newFields,
    };
};

export const getIssuesRouteFields = (filterValues: IServerInputFilterDetails = {}): IFieldInfo[] | undefined => {
    if (!filterValues?.fields) {
        return;
    }

    const fields: IFieldInfo[] = [];
    filterValues.fields.forEach((condition: IFilterCondition) => {
        if ((condition.operator === FilterConditionOperator.In) || (condition.operator === FilterConditionOperator.Equals)) {
            fields.push({
                name: condition.name,
                value: condition.values,
            });
        }
    });
    return fields.length > 0 ? fields : undefined;
};

export const fetchIssue = async (id: string): Promise<IIssue | undefined> => {
    const url = `${ISSUE_URL_PREFIX}/${id}`;
    const issueModel: IIssueViewModel | undefined = await sendHttpRequest<IIssueViewModel>(url, {
        data: {},
        method: 'GET',
    });

    if (!issueModel) {
        return undefined;
    }
    return createSingleIssueFromModel(issueModel);
};

export const getGroupingFieldNames = (groupedCols: ColumnVO[]): string[] => groupedCols.map((col: ColumnVO) => col.field!);

export const isEqualGrouping = (cols1: ColumnVO[] | undefined, cols2: ColumnVO[] | undefined): boolean => {
    return isEqualList<string>(cols1 ? getGroupingFieldNames(cols1) : undefined, cols2 ? getGroupingFieldNames(cols2) : undefined);
};

export const fetchIssuesAggregations = async (aggrFields: string[], origFilter?: IServerInputFilterDetails, useTotalCount = false): Promise<IAggregationInfo> => {
    const filterDetails = getFinalIssuesFilterDetails(origFilter);
    const request: IFlatIssuesSearchRequest = {
        resultSetSize: 0,
        totalCount: useTotalCount,
        filter: filterDetails.fields,
        freeText: filterDetails.freeText,
        orgUnitIdsFilter: filterDetails.orgUnitsIds,
        facets: aggrFields,
    };
    const response: IFlatIssueSearchResponse = await sendHttpRequest<IFlatIssueSearchResponse>(ISSUES_SEARCH_URL, {
        data: request,
        method: 'POST',
    });
    return {
        aggregations: response.facets ? serverFacetsToAggregations(response.facets) : {},
        totalCount: response.totalCount,
    };
};

const createCveFromModel = (cveModel: IIssueCveModel, issue: IIssue): IIssueCve => {
    const severityInfo = getSafeFindingSeverityInfo(cveModel.severity);
    return {
        id: cveModel.cveId,
        severity: severityInfo.key,
        severityLevel: severityInfo.level,
        baseScore: cveModel.baseScore,
        baseScoreVector: cveModel.baseScoreVector,
        fixable: cveModel.fixable ?? false,
        knownExploit: cveModel.knownExploit,
        packages: cveModel.packages,
        epssScore: cveModel.epssScore,
        issueId: issue.id,
        entityId: issue.entityId,
    };
};

export const defaultIssueFilterDataSetter = (requestObj: IServerInputFilterDetails, value: any[], keyInObjectForAPI: string): IServerInputFilterDetails => {
    if (requestObj.fields === undefined) {
        requestObj.fields = [];
    }
    if (value?.length > 0) {
        requestObj.fields.push({
            name: keyInObjectForAPI,
            values: [...value],
            operator: FilterConditionOperator.In,
        });
    }
    return requestObj;
};

export const booleanIssueFilterDataSetter = (requestObj: IServerInputFilterDetails, values: any[], keyInObjectForAPI: string): IServerInputFilterDetails => {
    if (requestObj.fields === undefined) {
        requestObj.fields = [];
    }
    if (values && (values.length === 1)) {
        requestObj.fields.push({
            name: keyInObjectForAPI,
            values: [isBoolean(values[0])],
            operator: FilterConditionOperator.Equals,
        });
    }
    return requestObj;
};

export function assetIdIssueFilterDataSetter(requestObj: IServerInputFilterDetails, value: string) {
    requestObj.fields = requestObj.fields || [];
    requestObj.fields.push({
        name: ISSUE_FIELD_NAMES.ENTITY_ID,
        operator: FilterConditionOperator.In,
        values: value.split(',').map((id) => id.trim()),
    });
    return requestObj;
}

export function issueIdFilterDataSetter(requestObj: IServerInputFilterDetails, value: string) {
    requestObj.fields = requestObj.fields || [];
    requestObj.fields.push({
        name: ISSUE_FIELD_NAMES.ID,
        operator: FilterConditionOperator.Equals,
        values: [(value || '').trim()],
    });
    return requestObj;
}

export const fetchIssueVulnerabilities = (issue: IIssue, asset: IProtectedAssetViewModel): Promise<IIssueCve[]> => {
    const query: IEvidencePathIssueInfo = {
        issueId: issue.id,
        srl: asset.srl,
    };

    const queryString = toQueryString({ query });
    const url = `${ISSUE_VULNERABILITIES_URL}?${queryString}`;

    return sendHttpRequest<IIssueCveModel[]>(url, {
        method: 'GET',
    }).then((cveModels: IIssueCveModel[]) => {
        return cveModels.map(cveModel => createCveFromModel(cveModel, issue));
    });
};

export const fetchIAMSensitivity = (issue: IIssue, asset: IProtectedAssetViewModel): Promise<IamSensitivity> => {
    const query: IEvidencePathIssueInfo = {
        issueId: issue.id,
        srl: asset.srl,
    };

    const queryString = toQueryString({ query });
    const url = `${IAM_SENSITIVITY_URL}?${queryString}`;

    return sendHttpRequest<IamSensitivity>(url, {
        method: 'GET',
    });
};

export const fetchMainEntities = (issueID: string): Promise<IProtectedAssetViewModel[]> => {
    const query: IEvidencePathIssueInfo = {
        issueId: issueID,
    };

    const queryString = toQueryString({ query });
    const url = `${MAIN_ENTITIES_URL}?${queryString}`;

    return sendHttpRequest<IProtectedAssetViewModel[]>(url, {
        method: 'GET',
    });
};

export const fetchMalware = (issue: IIssue, asset: IProtectedAssetViewModel): Promise<MalwareInfo[]> => {
    const query: IEvidencePathIssueInfo = {
        issueId: issue.id,
        srl: asset.srl,
    };
    const queryString = toQueryString({ query });
    const url = `${MALWARE_URL}?${queryString}`;
    return sendHttpRequest<MalwareInfo[]>(url, {
        method: 'GET',
    });
};

export const fetchFlatIssuesWithTotal = async (filterDetails: IServerInputFilterDetails, sortModels?: IServerInputSort[]): Promise<IIssuesTableDataWithTotals> => {
    interface IPromisesMap {
        issuesSearchResults: Promise<IFlatIssueSearchResult>;
        totalCounter: Promise<number>;
    }

    const promisesMap: IPromisesMap = {
        issuesSearchResults: fetchFlatIssues(filterDetails, sortModels),
        totalCounter: fetchTotalIssuesCount(),
    };
    return runAll<IPromisesMap>(promisesMap, true).then((resultsMap: IRunAllResultsMap<IPromisesMap>) => {
        const issuesSearchResults: IFlatIssueSearchResult = resultsMap.issuesSearchResults as IFlatIssueSearchResult;
        return {
            issuesTableData: {
                issuesOrGroups: issuesSearchResults.issues,
                itemCount: issuesSearchResults.itemCount,
            },
            totalCount: Number(resultsMap.totalCounter),
        };
    });
};

export const fetchFlatIssues = async (filterDetails: IServerInputFilterDetails, sortModels?: IServerInputSort[]): Promise<IFlatIssueSearchResult> => {
    const request: IFlatIssuesSearchRequest = {
        totalCount: true,
        filter: filterDetails.fields,
        freeText: filterDetails.freeText,
        orgUnitIdsFilter: filterDetails.orgUnitsIds,
        sort: sortModels?.length ? sortModels : undefined,
    };
    const response: IFlatIssueSearchResponse = await sendHttpRequest<IFlatIssueSearchResponse>(ISSUES_SEARCH_URL, {
        data: request,
        method: 'POST',
    });
    const issues = await createIssuesListFromModel(response.results);
    return {
        issues,
        itemCount: response.totalCount,
    };
};

export const fetchTotalIssuesCount = async (): Promise<number> => {
    const filterDetails: IServerInputFilterDetails = getFinalIssuesFilterDetails();
    const request: IFlatIssuesSearchRequest = {
        resultSetSize: 0,
        totalCount: true,
        filter: filterDetails.fields,
    };
    const response: IFlatIssueSearchResponse = await sendHttpRequest<IFlatIssueSearchResponse>(ISSUES_SEARCH_URL, {
        data: request,
        method: 'POST',
    });
    return response.totalCount;
};

const getGroupFilterFields = (cols: ColumnVO[], leafGroupModel: IIssuesGroupViewModel, depth: number): IFilterCondition[] => {
    const filterFields: IFilterCondition[] = [];
    for (let i = 0; i < depth; i++) {
        const fieldName = cols[i].field!;
        filterFields.push({
            name: fieldName,
            values: [leafGroupModel[fieldName]],
            operator: FilterConditionOperator.In,
        });
    }
    return filterFields;
};

export const areFilterFieldsEqual = (fields1?: IFilterCondition[], fields2?: IFilterCondition[]): boolean => {
    if (fields1?.length !== fields2?.length) {
        return false;
    }

    if (fields1 === undefined || fields2 === undefined) {
        return true;
    }

    for (let i = 0; i < fields1.length; i++) {
        const field1 = fields1[i];
        const field2 = fields2.find((field2: IFilterCondition) => field2.name === field1.name);
        if (!field2) {
            return false;
        }
        if (field1.name !== field2.name || field1.operator !== field2.operator || field1.values.length !== field2.values.length) {
            return false;
        }
        if (!doListsContainSameItems(field2.values, field1.values)) {
            return false;
        }
    }
    return true;
};

function doListsContainSameItems(list1: IFilterConditionValue[] = [], list2: IFilterConditionValue[] = []) {
    const sorted1 = [...list1].sort();
    const sorted2 = [...list2].sort();
    return JSON.stringify(sorted1) === JSON.stringify(sorted2);
}

export const areFiltersEqual = (filter1: IServerInputFilterDetails, filter2: IServerInputFilterDetails): boolean => {
    if (filter1.freeText !== filter2.freeText) {
        return false;
    }

    if (!doListsContainSameItems(filter1.orgUnitsIds, filter2.orgUnitsIds)) {
        return false;
    }
    return areFilterFieldsEqual(filter1.fields, filter2.fields);
};

const updateAncestorsChildCounts = (issuesGroup: IIssuesGroup, childCount: number) => {
    issuesGroup.childCount += childCount;
    if (issuesGroup.parent) {
        updateAncestorsChildCounts(issuesGroup.parent, childCount);
    }
};

const buildIssuesGroupTree = (map: IIssuesGroupMap, cols: ColumnVO[], leafGroupModel: IIssuesGroupViewModel, depth: number, child?: IIssuesGroup) => {
    let issuesGroup: IIssuesGroup;
    let isNewGroup = false;
    if (depth === 0) {
        issuesGroup = map[ROOT_ISSUES_GROUP_ID];
        if (!issuesGroup) {
            isNewGroup = true;
            issuesGroup = {
                childCount: 0,
                nodeId: ROOT_ISSUES_GROUP_ID,
                children: [],
                isGrouped: true,
                isRootGroup: true,
                depth,
                groupedFields: [],
            };
            map[ROOT_ISSUES_GROUP_ID] = issuesGroup;
        }
    } else {
        const groupedFields = getGroupFilterFields(cols, leafGroupModel, depth);
        const nodeId = JSON.stringify(groupedFields);
        issuesGroup = map[nodeId];
        if (!issuesGroup) {
            isNewGroup = true;
            const col = cols[depth - 1];
            issuesGroup = {
                childCount: 0,
                nodeId,
                isLeafGroup: isNil(child),
                children: [],
                isGrouped: true,
                depth,
                col,
                groupedFields: groupedFields,
            };
            cols.forEach((col: ColumnVO) => {
                if (col.field) {
                    const fieldName = col.field;
                    issuesGroup[fieldName] = leafGroupModel[fieldName];
                }
            });
            map[nodeId] = issuesGroup;
        }
    }
    const leafCount = leafGroupModel[GROUPING_COUNT_FIELD_NAME];
    issuesGroup.childCount += leafCount;
    if (child) {
        child.parent = issuesGroup;
        issuesGroup.children.push(child);
    }
    if (depth > 0) {
        if (isNewGroup) {
            buildIssuesGroupTree(map, cols, leafGroupModel, depth - 1, issuesGroup);
        } else if (issuesGroup.parent) {
            updateAncestorsChildCounts(issuesGroup.parent, leafCount);
        }
    }
};

export const createGroupingTree = (groupedCols: ColumnVO[], gridApi: GridApi, issuesGroupViewModels: IIssuesGroupViewModel[]): IIssuesGroup => {
    const issuesGroupsMap: IIssuesGroupMap = {};
    issuesGroupViewModels.forEach((issuesGroupModel: IIssuesGroupViewModel) => {
        buildIssuesGroupTree(issuesGroupsMap, groupedCols, issuesGroupModel, groupedCols.length);
    });
    return issuesGroupsMap[ROOT_ISSUES_GROUP_ID];
};

export const fetchGroupingFlatItems = async (fieldNames: string[], filterDetails: IServerInputFilterDetails, sort?: IServerInputSort[]): Promise<IIssuesGroupViewModel[]> => {
    const request: IGroupedIssuesSearchRequest = {
        filter: filterDetails.fields,
        freeText: filterDetails.freeText,
        orgUnitIdsFilter: filterDetails.orgUnitsIds,
        sort: sort?.length ? sort : [GROUPING_SORT_BY_COUNT],
        groups: fieldNames,
    };
    return sendHttpRequest<IIssuesGroupViewModel[]>(ISSUES_GROUPING_URL, {
        data: request,
        method: 'POST',
    });
};

export const fetchGroupingTree = async (groupedCols: ColumnVO[], gridApi: GridApi, filterDetails: IServerInputFilterDetails, sort?: IServerInputSort[]): Promise<IIssuesGroup> => {
    const fieldNames = getGroupingFieldNames(groupedCols);
    const issuesGroupViewModels: IIssuesGroupViewModel[] = await fetchGroupingFlatItems(fieldNames, filterDetails, sort);
    return createGroupingTree(groupedCols, gridApi, issuesGroupViewModels);
};

export const fetchGroupingTreeWithTotal = async (groupedCols: ColumnVO[], gridApi: GridApi, filterDetails: IServerInputFilterDetails, sort?: IServerInputSort[]): Promise<IIssuesGroupWithTotal> => {
    const promisesMap: any = {
        issuesGroup: fetchGroupingTree(groupedCols, gridApi, filterDetails, sort),
        totalCounter: fetchTotalIssuesCount(),
    };
    return runAll(promisesMap, true).then(resultsMap => {
        const issuesGroup: IIssuesGroup = resultsMap.issuesGroup as IIssuesGroup;
        const totalCount = Number(resultsMap.totalCounter);
        return {
            issuesGroup,
            totalCount,
        };
    });
};

export const isGroupedByField = (issueOrGroup: IIssueOrGroup | undefined, fieldName: string): boolean => {
    if (issueOrGroup?.isGrouped) {
        const group = issueOrGroup as IIssuesGroup;
        return (group.groupedFields.some((field: IFilterCondition) => field.name === fieldName));
    }
    return false;
};

export const assetFilterFieldsToServerInputFilterDetails = (filtersValues: IFiltersValues, setDefaultStatusOpen?: boolean): IServerInputFilterDetails => {
    const result: IServerInputFilterDetails = {};
    if (filtersValues.fields) {
        const finalFields: IFilterCondition[] = [];
        filtersValues.fields.forEach((field: IFieldInfo) => {
            const targetField = convertFilterField(FieldEntityKind.ASSET, FieldEntityKind.ISSUE, field);
            if (targetField) {
                if (targetField.name === ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID) {
                    result.orgUnitsIds = (result.orgUnitsIds ?? []);
                    result.orgUnitsIds.push(targetField.value);
                } else {
                    const indexOfType = finalFields.findIndex(item => item.name === targetField.name);
                    if (indexOfType === -1) {
                        finalFields.push({
                            name: targetField.name,
                            values: [targetField.value],
                            operator: FilterConditionOperator.In,
                        });
                    } else {
                        finalFields[indexOfType].values.push(targetField.value);
                    }
                }
            }
        });
        result.fields = finalFields;
    }
    return getFinalIssuesFilterDetails(result, setDefaultStatusOpen);
};

export const openIssueDrawer = (issueId: string) => {
    showDrawer(ISSUE_VIEWER_KEY, issueId);
};

export const getFullIssueStatus = (issue: IIssue): string => {
    if ((issue.status === IssueStatusEnum.CLOSED) && !isNil(issue.statusReason)) {
        return `${issue.status} (${issue.statusReason})`;
    }
    return issue.status;
};

export const isToxicGrouping = (columnApi: ColumnApi) => {
    const groupColDefs: ColDef[] = columnApi.getRowGroupColumns().map(col => col.getColDef());
    return ((groupColDefs.length > 1) && (groupColDefs[0].field === FIRST_TOXIC_FIELD_NAME) && (groupColDefs[1].field === SECOND_TOXIC_FIELD_NAME));
};