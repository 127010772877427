import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { FINDINGS_OVERVIEW_DRAWER_ID } from './Findings.const';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { TFunction } from 'i18next';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { getVendor, Vendors } from 'common/consts/vendors';
import { IFindingKeysByCloudAccountId } from 'common/module_interface/intelligence/Intelligence.interface';
import { IFindingComment } from 'common/components/Findings/Findings.interface';
import { isEmpty } from 'lodash';
import { isValidJSON } from 'common/erm-components/utils/types';
import { FindingStatusCode } from 'common/module_interface/intelligence/Findings/Findings.const';

/**
 * Function to get the status name based on the status code.
 * It uses internationalization to get the status name in the current language.
 *
 * @param {string} statusCode - The status code for which to get the status name.
 * @returns {string} The status name corresponding to the status code.
 */
export const getStatusName = (statusCode: number): string => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    let statusName = EMPTY_STRING;
    // Switch case to handle different status codes
    switch (statusCode) {
        // If the status code is 'OPEN', get the corresponding status name
        case FindingStatusCode.OPEN:
            statusName = t('FINDINGS_TABLE.CELL_RENDERERS.STATUS.OPEN');
            break;
        // If the status code is 'CLOSE', get the corresponding status name
        case FindingStatusCode.CLOSE:
            statusName = t('FINDINGS_TABLE.CELL_RENDERERS.STATUS.CLOSE');
            break;
    }
    // Return the status name
    return statusName;
};

export const openFindingDrawer = (finding: IFinding) => {
    if (finding.id) {
        showDrawer(FINDINGS_OVERVIEW_DRAWER_ID, {
            finding: finding
        });
    }
};

export const convertStringToNumber = (numberAsString: string): number | undefined => {
    return isFinite(Number(numberAsString)) ? Number(numberAsString) : undefined;
};

export const getSafeServerPlatform = (cloudAccountType: string): Vendors => {
    const vendor: Vendors = getVendor(cloudAccountType)?.name || Vendors.GENERIC;
    return ((vendor: Vendors) => {
        switch (vendor) {
            // convert gcp to google
            case Vendors.GCP:
                return Vendors.GOOGLE;
            default:
                return vendor;
        }
    })(vendor);
};

export const getFindingKeysByCloudAccountId = (findings: IFinding[]): IFindingKeysByCloudAccountId[] => {
    const cloudAccountIdToFindingKeysMap: Map<string, string[]> = findings.reduce((map, finding) => {
        const { cloudAccountId, findingKey } = finding;
        if (!map.has(cloudAccountId)) {
            map.set(cloudAccountId, []);
        }
        map.get(cloudAccountId)?.push(findingKey);
        return map;
    }, new Map<string, string[]>());

    return Array.from(cloudAccountIdToFindingKeysMap, ([cloudAccountId, findingsKeys]) => ({
        cloudGuardCloudAccountId: cloudAccountId,
        findingsKeys
    }));
};

export const formatComments = (comments: string): IFindingComment[] => {
    if (isEmpty(comments) || !isValidJSON(comments)) return [];
    const parsedComments: string[] = JSON.parse(comments);
    return parsedComments.map(comment => {
        const tokens: string[] = comment.split(' | ');
        if (tokens.length === 4) {
            const [userName, timestamp, action, text] = tokens;
            return { timestamp, userName, action, text } as IFindingComment;
        }
        const [userName, timestamp, text] = tokens;
        return { timestamp, userName, text } as IFindingComment;
    });
};

export const isAnyClosedFinding = (findings: IFinding[]): boolean => {
    return findings.some((finding: IFinding) => finding.statusId === FindingStatusCode.CLOSE);
};
