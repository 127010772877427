import { IIssueExclusion, IIssueExclusionProps, IIssueExclusionsService } from './IssueExclusions.interface';
import { ISSUE_EXCLUSIONS_SERVICE_ID } from './IssueExclusions.consts';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { getService } from 'common/extensibility/AddinContainer';
import { Tag } from 'common/components/Tags/Tags.types';
import _ from 'lodash';
import { ISSUE_EXCLUSION_EDITOR_KEY } from './IssueExclusionEditor/IssueExclusionEditor.consts';

export const fetchAllIssueExclusions = async (): Promise<IIssueExclusion[]> => {
    return getIssueExclusionsService().getAllIssueExclusions();
};

export const openIssueExclusionEditorDrawer = (exclusionProps?: IIssueExclusionProps) => {
    showDrawer(ISSUE_EXCLUSION_EDITOR_KEY, exclusionProps);
};

export function getIssueExclusionsService(): IIssueExclusionsService {
    return getService<IIssueExclusionsService>(ISSUE_EXCLUSIONS_SERVICE_ID);
}

let dupCounter = 1;
const DUP_TAG_KEY = 'exc___tag___name';
export const duplicateIssueExclusion = (issueExclusion: IIssueExclusion): Promise<string> => {
    dupCounter++;
    const cleanName = (issueExclusion.name || '').replace(/\[cp-.*\]$/, '');
    const suffix = ` [cp-${(new Date()).getTime()}.${dupCounter}]`;
    const newName = cleanName + suffix;
    const tags: Tag[] = _.cloneDeep(issueExclusion.tags);
    const lastTag: Tag | undefined = (tags.length > 0) ? tags[tags.length - 1] : undefined;
    if (lastTag && (lastTag.key === DUP_TAG_KEY)) {
        lastTag.value = newName;
    } else {
        tags.push({
            key: DUP_TAG_KEY,
            value: newName,
        });
    }
    const issueExclusionProps: IIssueExclusionProps = {
        ...issueExclusion,
        name: newName,
        tags,
    };
    return getIssueExclusionsService().createIssueExclusion(issueExclusionProps);
};

export const createEmptyIssueExclusionUpdateProps = (): IIssueExclusionProps => ({
    name: '',
    description: '',
    ruleIds: [],
    severities: [],
    orgUnitIds: [],
    envIds: [],
    entityNames: [],
    entityId: '',
    entityTypes: [],
    dateRange: undefined,
    tags: [],
});

