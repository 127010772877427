import { IColumnUsageDef } from 'common/interface/general';
import { ReportsColumnTypes } from './Types/ReportsColumnTypes';

export const getReportsTableColumns = (): IColumnUsageDef[] => [
    ReportsColumnTypes.REPORT_NAME,
    ReportsColumnTypes.TEMPLATE,
    ReportsColumnTypes.TIME_RANGE,
    ReportsColumnTypes.TARGET,
    ReportsColumnTypes.NEXT_RUN,
    ReportsColumnTypes.LAST_RUN,
];
