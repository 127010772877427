import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IIssueExclusion } from '../IssueExclusions.interface';
import { MultiChipCellContent } from 'common/erm-components/custom/MultiChipCellContent/MultiChipCellContent';
import { Tag } from 'common/components/Tags/Tags.types';

export const IssueExclusionTagsCellRender: React.FC<ICellRendererParams<IIssueExclusion>> = (params) => {
    const issueExclusion: IIssueExclusion | undefined = params.data;
    if (!issueExclusion) {
        return null;
    }

    return <MultiChipCellContent propsList={issueExclusion.tags.map((tag: Tag) => {
        return {
            label: `${tag.key}: ${tag.value}`,
        };
    })} />;
};
