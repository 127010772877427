import { chain, map } from 'lodash';
import {
    getMagellanLinkGeneratorService,
    IMagellanLinkGeneratorService,
    MAGELLAN_LINK_GENERATOR_SERVICE_ID
} from 'common/module_interface/intelligence/intelligence';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { generateHref, toQueryString } from 'common/utils/http';
import { GenericObject } from 'common/interface/general';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';

const MAGELLAN_DEFAULT_GSL_FOR_PROTECTED_ASSETS: { [index: string]: any } = {
    'SecurityGroup': (finding: { id: any; members: any; }, feature: any) => {
        let members, nicMembers;
        switch (feature) {
            case 'cloudtrail':
                return `target.id='${finding.id}'`;
            case 'flowlogs':
                members = chain(finding.members).map('idForMagellan').filter(memberId => !!memberId).value();
                nicMembers = chain(finding.members).map('nicIdForMagellan').filter(memberId => !!memberId).value();
                if (members.length === 0 && nicMembers.length === 0) {
                    return null;
                }
                if (nicMembers.length === 0) {
                    // only members exist (non-nic assets)
                    return `( dst.asset.assetid in (${`'${members.join('\',\'')}'`}) or src.asset.assetid in (${`'${members.join('\',\'')}'`}) )`;
                }
                if (members.length === 0) {
                    // only nicMembers exist (assets of type nic)
                    return `( dst.asset.nics.id in (${`'${nicMembers.join('\',\'')}'`}) or src.asset.nics.id in (${`'${nicMembers.join('\',\'')}'`}) )`;
                }
                // both members and nicMembers
                return `(( dst.asset.assetid in (${`'${members.join('\',\'')}'`}) or src.asset.assetid in (${`'${members.join('\',\'')}'`}) ) or
                        ( dst.asset.nics.id in (${`'${nicMembers.join('\',\'')}'`}) or src.asset.nics.id in (${`'${nicMembers.join('\',\'')}'`}) ))`;
        }
        return null;
    },
    'Instance': (finding: { id: any; }, feature: any) => {
        switch (feature) {
            case 'flowlogs':
                return `( dst.asset.assetid='${finding.id}' or src.asset.assetid='${finding.id}' )`;
        }
        return null;
    },
    'ELB': (finding: { name: any; }, feature: any) => {
        switch (feature) {
            case 'flowlogs':
                return `( dst.asset.assetid='${finding.name}' or src.asset.assetid='${finding.name}' )`;
        }
        return null;
    },
    'EcsCluster': (finding: { services: any }, feature: any) => {
        const services = map(finding.services, 'id');
        switch (feature) {
            case 'cloudtrail':
                return `identity.id in (${`'${services.join('\',\'')}'`}) or target.id in ${`'${services.join('\',\'')}'`}`;
            case 'flowlogs':
                return `( dst.asset.assetid in (${`'${services.join('\',\'')}'`}) or src.asset.assetid in (${`'${services.join('\',\'')}'`}) )`;
        }
        return null;
    },
    'ApplicationLoadBalancer': (finding: { name: any; }, feature: any) => {
        switch (feature) {
            case 'cloudtrail':
                return `identity.name like '%${finding.name}%' or target.name like '%${finding.name}%'`;
            case 'flowlogs':
                return `( dst.asset.name like '%${finding.name}%' or src.asset.name like '%${finding.name}%' )`;
        }
        return null;
    },
    'DynamoDbTable': (finding: { name: any; }, feature: any) => {
        switch (feature) {
            case 'cloudtrail':
                return `identity.name='${finding.name}' or target.name='${finding.name}'`;
        }
        return null;
    },
    'S3Bucket': (finding: { id: any; }, feature: any) => {
        switch (feature) {
            case 'cloudtrail':
                return `(identity.id = '${finding.id}' and issuer.type like 's3') or (target.name='${finding.id}' and target.type like 's3')`;
        }
        return null;
    },
    'IamUser': (finding: any, feature: any) => {
        return getMagellanLinkGeneratorService().iamFunc(finding, feature);
    },
    'IAMUser': (finding: any, feature: any) => {
        return getMagellanLinkGeneratorService().iamFunc(finding, feature);
    },
    'Iam': (finding: { entityExternalId: any; }, feature: any) => {
        switch (feature) {
            case 'cloudtrail':
                return `identity.id='${finding.entityExternalId}'`;
        }
        return null;
    },
    'IamRole': (finding: { name: string; }, feature: string) => {
        switch (feature) {
            case 'cloudtrail':
                return `(issuer.type like 'role' and issuer.name='${finding.name}') or (target.name='${finding.name}' and target.type like 'role')`;
        }
        return null;
    },
    'VPC': (finding: { externalId: any; vpc: { id: any; }; }, feature: any) => {
        let vpcFromId;
        switch (feature) {
            case 'flowlogs':
                return `vpc='${finding.externalId}'`;
            case 'cloudtrail':
                vpcFromId = finding.vpc?`src.vpc='${finding.vpc.id}' or `:'';
                return `${vpcFromId}identity.vpc='${finding.externalId}' or target.vpc='${finding.externalId}'`;
        }
        return null;
    },
    'NetworkInterface': (finding: { id: any; }, feature: any) => {
        switch (feature) {
            case 'flowlogs':
                return `eni='${finding.id}'`;
        }
        return null;
    },
    'NatGateway': (finding: { natGatewayId: any; }, feature: any) => {
        switch (feature) {
            case 'flowlogs':
                return `dst.asset.name='${finding.natGatewayId}'`;
        }
        return null;
    },
    'Lambda': (finding: { name: any; }, feature: any) => {
        switch (feature) {
            case 'flowlogs':
                return `src.asset.assetid='${finding.name}' or dst.asset.assetid='${finding.name}'`;
        }
        return null;
    },
    'AssumedRole': (finding: { ruleName: any; }, feature: any) => {
        switch (feature) {
            case 'cloudtrail':
                return `issuer.name = '${finding.ruleName}'`;
        }
        return null;
    },
    'VirtualMachine': (finding: { id: any; }, feature: any) => {
        switch (feature) {
            case 'flowlogs':
                return `( dst.asset.assetid='${finding.id}' or src.asset.assetid='${finding.id}' )`;
        }
        return null;
    },
};

export class MagellanLinkGeneratorService implements IMagellanLinkGeneratorService {
    iamFunc(finding: any, feature: string,): string {
        switch (feature) {
            case 'cloudtrail':
                return ` identity.id = '${finding.entityDome9Id}' or target.id='${finding.entityDome9Id}' or (target.id = '${finding.name}' and target.type like 'user')`;
        }
        return '';
    }

    generateFilterForProtectedAssets = (feature: string, asset: { type: string }): string => {
        if (typeof MAGELLAN_DEFAULT_GSL_FOR_PROTECTED_ASSETS[asset.type] === 'function') {
            return MAGELLAN_DEFAULT_GSL_FOR_PROTECTED_ASSETS[asset.type](asset, feature);
        }
        return '';
    };

    generateLinkForFinding(finding: IFinding): string {
        if (!finding.ruleLogic) return '';
        const assetId = finding.entityId || finding.entityName;

        const queryObject: GenericObject<any> = {
            from: finding.magellanWindowStartTime,
            to: finding.magellanWindowEndTime,
            filter: finding.ruleLogic,
            cloudAccount: finding.cloudAccountExternalId,
            assetId
        };

        const queryString = toQueryString({ query: queryObject });

        return generateHref(`/magellan/linker?${queryString}`);
    }

}

export default function initializeMagellanLinkGeneratorService() {
    globalAddinContainer.addService(MAGELLAN_LINK_GENERATOR_SERVICE_ID, new MagellanLinkGeneratorService());
}
