import { RiskLevelsMap } from 'common/module_interface/RiskManagement/RiskLevel';
import { ASSET_FIELD_NAMES } from '../../consts';

export const WAF_WIDGETS_DATA_URL = 'erm/waf/stats';

export const wafProtectionRiskChanges = {
    criticalToHigh: {
        original: RiskLevelsMap.critical,
        trend: RiskLevelsMap.high,
    },
    highToMedium: {
        original: RiskLevelsMap.high,
        trend: RiskLevelsMap.medium,
    },
    mediumToLow: {
        original: RiskLevelsMap.medium,
        trend: RiskLevelsMap.low,
    },
};

export const wafCloudGuardInfoLink = 'https://portal.checkpoint.com/dashboard/appsec/cloudguardwaf#/waf-policy/getting-started';

export const wafDashboardSupportedFilterTypes: string[] = [
    ASSET_FIELD_NAMES.cloudAccountId,
    ASSET_FIELD_NAMES.platform,
];
