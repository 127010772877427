import { Button } from '@dome9/berries/react-components';
import { RenderViewParams } from 'common/components/Wizard/Wizard';
import Snippet from 'common/components/snippet/Snippet';
import { getVendor } from 'common/consts/vendors';
import { FC, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { BtnWrapper } from '../helpers/azure.styled';
import {
    getClientId,
    getClientPassword,
    setClientId,
    setClientPassword,
    getAzureOnboardingScript,
    getAzureCommandCloudShell,
    getCloudShellUrl,
    setIsOnBoardingScriptCompleted,
    getAzureSingleConnectValidations,
} from '../helpers/azure.reducer';
import { getStoreService } from 'common/interface/services';
import { useSelector } from 'react-redux';
import CftModal from '../../components/CftModal';
import { Input, Stack, Link, Typography } from 'common/design-system/components-v2';
import OnboardingInstructions from '../../components/OnboardingInstructions';
import * as actions from '../helpers/azure.reducer';

const AzureSingleConnect:FC<RenderViewParams> = ({ onValidate })=>{
    const { t } = useTranslation();
    const dispatch = getStoreService().dispatch;
    const formValidations = useSelector(getAzureSingleConnectValidations);
    const cloudShellUrl = useSelector(getCloudShellUrl);
    const onboardingScript:string = useSelector(getAzureOnboardingScript);
    const commandCloudShell:string = useSelector(getAzureCommandCloudShell);
    const clientId = useSelector(getClientId);
    const clientPassword = useSelector(getClientPassword);
    const welcomeForm = useSelector(actions.getWelcomeForm);
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

    useEffect(()=>{
        return () => {
            dispatch(setIsOnBoardingScriptCompleted(false));
        };
    }, [dispatch]);


    useEffect(()=>{
        onValidate(formValidations.valid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValidations]);

    const onTenantIdChanged = (value: string) => {
        dispatch(actions.setIsTenantIdAlreadyOnboarded(false));
        dispatch(actions.setWelcomeForm({ key: 'tenantId', value }));
    };
    
    return (
        <Stack data-aid='azure-onboarding-connect-subscription-wrapper'>
            <OnboardingInstructions icon={getVendor('azure')?.icon} title={t('ON_BOARDING.AZURE.AZURE_CONFIGURATION')}>
                <Stack padding={[2, 0]}>
                    <ul className='list-decimal mx-16'>
                        <li className='mb-8'>
                            <div className='mt-3'>
                                {t('ON_BOARDING.AZURE.REVIEW_STATEMENT')}
                                <BtnWrapper>
                                    <Button
                                        icon={'listCheck'}
                                        label={'Onboarding script'}
                                        onClick={() => {
                                            setIsPopupOpen(true);
                                        }}
                                        className='terraform-text-button pointer'
                                        dataAid='onboarding-script'
                                    >
                                    </Button>
                                </BtnWrapper>
                                <span className={'relative left-5'}>(recommended).</span>
                            </div>
                        </li>
                        <li className='mb-8'>
                            <Link externalUrl={cloudShellUrl} target="_blank" rel="noreferrer" data-aid='cloud-shell-url'>
                                {t('ON_BOARDING.AZURE.LOG_IN_TO')} <Typography color="brandPrimary">{t('ON_BOARDING.AZURE.AZURE_CLOUD_SHELL')}</Typography>
                            </Link>
                        </li>
                        <li className='mb-8'>
                            {t('ON_BOARDING.AZURE.RUN_CLOUD_SHELL_COMMAND')}
                            <div className='w-1/2'>
                                <Snippet code={commandCloudShell}></Snippet>
                            </div>
                        </li>
                        <li className='mb-8'>
                            <Trans i18nKey="ON_BOARDING.AZURE.COPY_ARGS_INSTRUCTIONS"/>
                            <Stack direction="column" spacing={3} padding={[3,0,0,0]} style={{ width: '50%' }}>
                                <Input
                                    data-aid='azure-application-client-id-input'
                                    label={t('ON_BOARDING.AZURE.CLIENT_ID')}
                                    value={clientId}
                                    onChange={(ev) => dispatch(setClientId(ev.target.value))}
                                    isError={formValidations?.errors?.clientId}
                                    helperText={formValidations?.errors?.clientId ? formValidations.errors.clientId[0]?.message : undefined}
                                    required
                                    tooltip={t('ON_BOARDING.AZURE.COPY_ARGS')}
                                    fullWidth
                                    clearable
                                />
                                <Input
                                    data-aid='azure-application-secret-key-input'
                                    label={t('ON_BOARDING.AZURE.CLIENT_PASSWORD')}
                                    value={clientPassword}
                                    onChange={(ev) => dispatch(setClientPassword(ev.target.value))}
                                    isError={formValidations?.errors?.clientPassword}
                                    helperText={formValidations?.errors?.clientPassword ? formValidations.errors.clientPassword[0]?.message : undefined}
                                    required
                                    tooltip={t('ON_BOARDING.AZURE.COPY_ARGS')}
                                    fullWidth
                                    clearable
                                />
                                <Input
                                    data-aid='azure-welcome-tenant-id-input'
                                    label={t('ON_BOARDING.AZURE.TENANT_ID')}
                                    required
                                    value={welcomeForm.tenantId}
                                    tooltip={t('ON_BOARDING.AZURE.COPY_ARGS')}
                                    onChange={(ev) => onTenantIdChanged(ev.target.value)}
                                    isError={formValidations?.errors?.tenantId}
                                    helperText={formValidations?.errors?.tenantId ? formValidations.errors.tenantId[0]?.message : undefined}
                                    fullWidth
                                    clearable
                                />
                            </Stack>
                        </li>
                        <li className='mb-8'>
                            {t('ON_BOARDING.AZURE.CLICK_ONBOARD_TO_COMPLETE')}
                        </li>
                    </ul>
                </Stack>
            </OnboardingInstructions>
            <CftModal
                open={isPopupOpen}
                onClose={() => {
                    setIsPopupOpen(false);
                }}
                title={t('ON_BOARDING.AZURE.ONBOARDING_SCRIPT')}
                cfts={[{
                    content: onboardingScript,
                    type: t('ON_BOARDING.AZURE.ONBOARDING_SCRIPT'),
                    url: '',
                    fileName: ''
                }]}
            />
        </Stack>
    );
};


export default AzureSingleConnect;