import { getHttpApiService, getHttpService, IRuleManagementService } from '../../../../common/interface/services';
import { Rule } from 'common/interface/RulesManagement';

class RuleManageService implements IRuleManagementService {
    public async getRulesViewsFromServer(): Promise<Rule[]> {
        return await getHttpApiService().get<Rule[]>('rulesManagement/rules/views');
    }

    public async getRulesetsViewsFromComplianceServer(): Promise<Rule[]> {
        return await getHttpService().get<Rule[]>({ path: 'Compliance/Ruleset/view',cachingConfig: { useCache: true } });
    }

    public async getRulesFromServer(): Promise<Rule[]> {
        return await getHttpApiService().get<Rule[]>('rulesManagement/rules');
    }

    public async getIndividualRuleFromServer(ruleId: string): Promise<Rule> {
        return await getHttpApiService().get<Rule>(`rulesManagement/rules/${ruleId}`);
    }

    public async deleteRuleOnServer(ruleId: string) {
        return await getHttpApiService().delete<Rule>(`rulesManagement/rules/${ruleId}`);
    }

    public async postRuleToServer(newRule: Rule) {
        return await getHttpApiService().post<Rule>('rulesManagement/rules', { data: newRule });
    }

    public async editRuleOnServer(editedRule: Rule): Promise<Rule> {
        return await getHttpApiService().put<Rule>('rulesManagement/rules', { data: editedRule });
    }
}

export default RuleManageService;
