import { FC, useEffect, useState } from 'react';
import { OptionType } from 'common/design-system/components-v2/Select';
import { Typography, Label, Select, Stack } from 'common/design-system/components-v2';
import { I18nRemediation } from '../../helpers/remediation.consts';
import { useTranslation } from 'react-i18next';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { AddCloudBotsOptionProps } from '../../helpers/remediation.interfaces';
import { ICloudBot } from 'common/interface/remediation';
import { getCloudBots } from '../../services/cloudBots.service';
import { CloudBotsCustomParameters, CloudBotsParameters } from './CloudBotsCardParameters';
import CloudBotsList from './CloudBotsList';

const CloudBotsSelect: FC<AddCloudBotsOptionProps> = ({ isEnabled, selectedRuleSet, onChange, selectedOption, isEditMode, formValidations, maxCloudBots = 5, isCiem }) => {
    const { t } = useTranslation(I18nRemediation);
    const [cloudBots, setCloudBots] = useState<ICloudBot[]>([]);
    const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(!isEnabled);
    const [savedCloudBots, setSavedCloudBots] = useState<string[]>([]);
    const [selectedCloudBot, setSelectedCloudBot] = useState<OptionType | null>(null);
    const [cloudBotsList, setCloudBotsList] = useState<{ label:string, value: string, iconProps?: IconProps }[]>([]);
    const [parameters, setParameters] = useState<{ key: string, value: string | null }[]>([]);

    useEffect(() => {
        getCloudBots().then(data => {
            let cloudBotsData = data;
            if (isCiem) {
                const ciemBotNames = ['iam_entity_create_and_attach_permission_boundary'];
                cloudBotsData = data.filter((bot: ICloudBot) => ciemBotNames.includes(bot.name));
            }
            setCloudBots(cloudBotsData);
        });
    }, []);

    useEffect(() => {
        if (selectedOption && isEditMode) setSavedCloudBots(selectedOption);
    }, [selectedOption, selectedRuleSet, isEditMode]);

    useEffect(() => {
        if (savedCloudBots.length >= maxCloudBots) setIsSelectDisabled(true);
        else setIsSelectDisabled(!isEnabled);
        onChange(savedCloudBots);
    }, [savedCloudBots, isEnabled]);

    useEffect(() => {
        if (isEditMode) return;
        setSavedCloudBots([]);
        setParameters([]);
        setSelectedCloudBot(null);
        onChange([]);
    }, [selectedRuleSet]);
    const filterAccountsByVendor = (): { label:string, value: string, iconProps?: IconProps }[] => {
        if (isCiem){
            return cloudBots.map((cloudBot: ICloudBot) => ({ label: cloudBot.name, value: cloudBot.name }));
        } else if (selectedRuleSet?.platform) {
            const cloudBotsByVendor = cloudBots?.filter((cloudBot: ICloudBot) => (cloudBot.platform.toLowerCase() === (selectedRuleSet?.platform || ''))) || [];
            return cloudBotsByVendor.map((cloudBot: ICloudBot) => ({ label: cloudBot.name, value: cloudBot.name }));
        } else return [];
    };

    useEffect(() => {
        const data = filterAccountsByVendor();
        setSelectedCloudBot(null);
        setCloudBotsList(data);
    },[selectedRuleSet, cloudBots]);
    const onSelectedBot = (newValue: OptionType) => {
        if (savedCloudBots?.includes(newValue.value)) return;
        if (newValue?.value !== 'Custom') {
            const cloudBot = cloudBots.find((cloudBot: ICloudBot) => (cloudBot.name === newValue.value));
            const parameters = cloudBot?.parameters?.map((parameter: string) => ({ key: parameter, value: null })) || [];
            if (!parameters.length) {
                const currentCloudBots = [...savedCloudBots || []];
                currentCloudBots.push(newValue.value);
                setSavedCloudBots(currentCloudBots);
            }
            setParameters(parameters);
        }
        if (newValue?.value === 'Custom') {
            setParameters([]);
        }
        setSelectedCloudBot(newValue);
    };
    const removeCloudBot = (bot: string) => {
        if (!savedCloudBots?.length) setSavedCloudBots([]);
        else {
            const currentCloudBots = savedCloudBots?.filter((b) => b !== bot);
            setSavedCloudBots(currentCloudBots);
        }
    };
    const editCloudBot = (cloudBotsString: string) => {
        if (savedCloudBots?.length) {
            const botParams = cloudBotsString.split(' ');
            if (botParams.length === 0) return;
            const currentCloudBot = cloudBots.find((cloudBot: ICloudBot) => cloudBot.name === botParams[0]);
            if (currentCloudBot) {
                const params = currentCloudBot.parameters?.map((param, index) => {
                    return { key: param, value: botParams[index + 1] || null };
                });
                if (params) setParameters(params);
                setSelectedCloudBot({ label: currentCloudBot.name, value: currentCloudBot.name });
            }
        }
    };
    const saveCustomBot = (cloudBotValue: string) => {
        const currentCloudBots = [...savedCloudBots || []];
        currentCloudBots.push(cloudBotValue);
        setSavedCloudBots(currentCloudBots);
    };

    const saveBotsParameters = (cloudBotValue: string) => {
        const currentCloudBots = [...savedCloudBots || []];
        currentCloudBots.push(cloudBotValue);
        setSavedCloudBots(currentCloudBots);
        setParameters([]);
    };

    return ( // TODO: Refactor to SelectV2
        <Stack spacing={3}>
            <Stack>
                <Label text={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.ADD_A_CLOUD_BOT')} required />
                <Select options={cloudBotsList} isMulti={false} isDisabled={isSelectDisabled} value={selectedCloudBot} onChange={(newValue) => onSelectedBot(newValue as OptionType)} />
                {formValidations && <Typography color="alert" variant="bodyXs">{formValidations?.[0]?.message}</Typography>}
            </Stack>
            { selectedCloudBot?.value === 'Custom' && (
                <CloudBotsCustomParameters selectedCloudBot={selectedCloudBot} onSubmit={saveCustomBot} />
            )}
            { selectedCloudBot && parameters?.length ? (
                <CloudBotsParameters selectedCloudBot={selectedCloudBot} savedCloudBots={savedCloudBots} params={parameters} onSubmit={saveBotsParameters} />
            ) : null}
            <CloudBotsList savedCloudBots={savedCloudBots} maxCloudBots={maxCloudBots} removeCloudBot={removeCloudBot} editCloudBot={editCloudBot} />
        </Stack>
    );
};

export default CloudBotsSelect;