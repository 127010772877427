import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Button, Stack } from 'common/design-system/components-v2';
import { IIssueCve } from '../Issues.interface';
import { ermTrans } from '../../../RiskManagement.utils';
import { createIgnoreItemForCve } from '../../IgnoreList/CveIgnoreList/CveIgnoreList.items';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';

export const IgnoreCveActionRenderer: React.FC<ICellRendererParams<IIssueCve>> = (params) => {
    const cve: IIssueCve | undefined = params.data;
    if (!cve) {
        return null;
    }

    const onClick = () => {
        globalModelUtils.showConfirmationModal({
            title: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.ACTIONS.CONFIRM_CVE_IGNORE_TITLE'),
            text: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.ACTIONS.CONFIRM_CVE_IGNORE_TEXT'),
            onConfirm: () => createIgnoreItemForCve(cve),
        });
    };

    return (
        <Stack justifyContent={'center'} alignItems={'center'}>
            <Button
                variant='contained'
                tooltip={ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.ACTIONS.IGNORE_TOOLTIP')}
                onClick={onClick}>
                {ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.ACTIONS.IGNORE')}
            </Button>
        </Stack>
    );
};
