import React from 'react';
import { Stack } from 'common/design-system/components-v2';
import { ConfigurationIcon, NameCellWrapper, StyledTypography } from '../ConfigurationsStyles.styled';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';

const NameCellRenderer: React.FC<any> = ({ dataAid, params , onConfigurationClicked }) => {
    if(!params.data?.name) return;
    const rulesetType:string = params.data?.integrationsType;
    const integrationsDefinitions = IntegrationsRegistry.getIntegrationsDefinitions();
    const icon = integrationsDefinitions.find((integration) => integration.id === rulesetType)?.icon;

    const openConfigurationInDrawer = () => {
        onConfigurationClicked(params.data.integrationsType, params.data.id);
    };

    return (
        <>
            {icon &&
                <Stack data-aid={dataAid} onClick={openConfigurationInDrawer}>
                    <NameCellWrapper>
                        <ConfigurationIcon>
                            <img src={icon} alt={''}/>
                        </ConfigurationIcon>
                        <StyledTypography>
                            {params.data?.name}
                        </StyledTypography>
                    </NameCellWrapper>
                </Stack>
            }
        </>
    );
};

export default NameCellRenderer;