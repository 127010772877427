import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { get, isEmpty, map } from 'lodash';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import { Column, ColumnApi, GridApi } from 'ag-grid-community';
import { CounterStatus } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    computeFilterString,
    convertSourceTypesToFilter,
    dateTimeFilter,
    filterValuesToRequestQueryFilters,
    freeTextToGslFormat,
    getFilterFacetFields,
} from './Components/FilterPanel/FindingsFilterPanel.utils';
import {
    IFilterDetails,
    IGslRunFacetResponse,
    IGslRunRequest,
    ITableApis,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { ALERTS, DEFAULT_MITRE_TACTIC_FILTER } from '../Findings.const';
import { EMPTY_STRING, OPERATORS } from 'common/consts/GeneralConsts';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { GenericObject } from 'common/interface/general';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { isNil } from 'common/utils/helpFunctions';
import { convertFacetsToAggregations } from 'common/erm-components/services/gsl/GslServiceQueries';
import { GSL_CLAUSES } from 'common/module_interface/intelligence/Gsl/GslService.const';
import { ITimeRange } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';

export interface IDataSourceConfig {
    pageSize?: number;
    onRowCountUpdate?: Function,
    originTypes?: string[];
    filters?: IProtectedAssetFilter[];
    mitreInfo?: GenericObject<any>;
    isArchiveView?: boolean;
}

export class FindingsTableDatasource implements IServerSideDatasource {

    public groupColumns: any;
    private readonly pageSize: number;
    public onRowCountUpdate: Function | undefined;
    public totalCount: number;
    public currentCount: number;
    private filterValues: IFilterDetails | undefined;
    private apis: ITableApis | undefined;
    private isFilterChanged: boolean | undefined;
    private readonly originTypes?: string[];
    private readonly filters?: IProtectedAssetFilter[];
    private readonly mitreInfo?: GenericObject<any>;
    private isArchiveView?: boolean;

    constructor(config?: IDataSourceConfig) {
        this.onRowCountUpdate = config?.onRowCountUpdate;
        this.pageSize = config?.pageSize ?? 100;
        this.isFilterChanged = false;
        this.totalCount = CounterStatus.Pending;
        this.currentCount = CounterStatus.Pending;
        this.originTypes = config?.originTypes;
        this.filters = config?.filters;
        this.mitreInfo = config?.mitreInfo;
        this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
        this.isArchiveView = config?.isArchiveView;
    }

    public setApis(gridApi: GridApi, gridColApi: ColumnApi) {
        this.apis = {
            gridApi: gridApi,
            columnApi: gridColApi,
        };
    }

    public getApis = () => {
        return this.apis;
    };

    public setIsArchiveView(isArchiveView?: boolean) {
        this.isArchiveView = isArchiveView;
    }

    public getIsArchiveView() {
        return this.isArchiveView;
    }

    public initiateTableRefresh() {
        this.apis?.gridApi?.refreshServerSide({ purge: true });
    }

    async setFilterFields(filterValues: IFiltersValues | undefined) {
        this.filterValues = filterValues;
        this.isFilterChanged = true;
        this.initiateTableRefresh();
    }

    public async getRows(params: IServerSideGetRowsParams) {
        try {
            this.apis?.gridApi.hideOverlay();
            const selectedGroups = map(params.columnApi.getRowGroupColumns(), column => column.getColId());
            const isGroupActive = selectedGroups.length > 0;
            if (this.isFilterChanged || (params.request.startRow === 0 && !isGroupActive)) {
                this.currentCount = CounterStatus.Pending;
                this.totalCount = CounterStatus.Pending;
                this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
                this.totalCount = await this.getGSLRunDataCount(params, selectedGroups);
                this.isFilterChanged = false;
            }
            const request: IGslRunRequest = this.buildGSLRunRequest(params, selectedGroups);
            const findings: IFinding[] = await getIntelligenceService().getFindings(request);
            this.currentCount = params.request.startRow !== undefined ? params.request.startRow + findings.length : 0;
            this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
            params.success({ rowData: findings, groupLevelInfo: { groupColumns: this.groupColumns } });
            if (isEmpty(findings)) {
                this.apis?.gridApi.showNoRowsOverlay();
            }
        } catch (error) {
            this.currentCount = CounterStatus.Error;
            this.totalCount = CounterStatus.Error;
            this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
            params.fail();
        }
    }

    public buildGSLRunRequest(params: IServerSideGetRowsParams, selectedGroups: string[]): IGslRunRequest {
        const isSelectedGroups = selectedGroups.length > 0;
        const groupKeys = get(params, 'request.groupKeys');
        const shouldRenderGroupRow = isSelectedGroups && groupKeys.length < selectedGroups.length;

        const { mitreFacetFields, isMitreColumnPresent } = this.mitreInfo ?? {
            mitreFacetFields: [],
            isMitreColumnPresent: false,
        };
        const originsFilter = convertSourceTypesToFilter(this.originTypes);
        const { queryFilter, mitreLikeFilter } = filterValuesToRequestQueryFilters(this.filterValues, mitreFacetFields);
        const freeTextFilter: string | undefined = freeTextToGslFormat(this.apis?.columnApi, this.filterValues?.freeTextPhrase);
        const dateRange: ITimeRange | undefined = dateTimeFilter(this.filterValues);
        const computedFilterString: string = computeFilterString({ originsFilter, queryFilter, freeTextFilter, isArchiveView: this.isArchiveView });
        let mitreQueryString = EMPTY_STRING;
        if (!isEmpty(mitreFacetFields) || isMitreColumnPresent) {
            const mitreWhereClause = mitreLikeFilter ? `${GSL_CLAUSES.WHERE} ${mitreLikeFilter}` : EMPTY_STRING;
            mitreQueryString = `${GSL_CLAUSES.JOIN_MITRE} ${mitreWhereClause}`;
        }

        const request: IGslRunRequest = {
            gsl: `${ALERTS} ${computedFilterString} ${mitreQueryString}`.trim(),
            options: {
                source: ALERTS,
                limit: this.pageSize,
                pagination: {
                    page: params.request.startRow !== undefined ? params.request.startRow / this.pageSize + 1 : 1,
                    pageSize: this.pageSize,
                },
                start: dateRange !== undefined ? Number(dateRange.start) : undefined,
                end: dateRange !== undefined ? Number(dateRange.end) : undefined,
            },
        };

        const groupFilters: { name: string, value: string }[] = [];
        if (shouldRenderGroupRow) {
            this.handleGrouping(params, selectedGroups, groupFilters, request, mitreQueryString);
        } else {
            this.handleSort(params, selectedGroups, groupFilters, request);
        }
        if (request.options) request.options.extraFilter = groupFilters;
        return request;
    }

    public handleGrouping(params: IServerSideGetRowsParams, selectedGroups: string[], groupFilters: any[], request: IGslRunRequest, mitreQueryString: string) {
        const groupLevel = get(params, 'parentNode.level') + 1;
        const groupKeys = get(params, 'request.groupKeys');
        const groupBy = selectedGroups[groupLevel];

        const column: Column | undefined = params.columnApi.getRowGroupColumns().find((col: Column) => col.getColId() == groupBy);
        const columnDef: CGColDef | undefined = column?.getColDef() as CGColDef;

        if (columnDef && !isNil(columnDef.groupOrder) && !isNil(columnDef.field)) {
            request.options = request.options || {};
            request.options.order = columnDef.groupOrder;
            request.options.orderBy = columnDef.field;
        }

        // add all columns configured in additionalColumnsInGroupBy property on column definition
        const additionalColumnsInGroupBy = get(columnDef, 'additionalColumnsInGroupBy');
        let transformedGroupBy: string = groupBy;
        if (additionalColumnsInGroupBy) {
            const groups = [transformedGroupBy, ...additionalColumnsInGroupBy];
            transformedGroupBy = groups.join(',');
        }

        // build summarize clause
        const countType = mitreQueryString ? 'dcount' : 'count';
        const summariesClause = ` ${GSL_CLAUSES.SUMMARIZE} ${countType}(findingKey) as numberOfRows by ${transformedGroupBy}`;
        request.gsl += summariesClause;

        // build extra filter
        for (let index = 0; index < groupLevel; index++) {
            const currentGroupValue = groupKeys[index];
            if (typeof currentGroupValue === 'string') currentGroupValue.replaceAll('\'', '\\\\\'');
            groupFilters.push({
                name: selectedGroups[index],
                value: currentGroupValue,
            });
        }
    }

    public handleSort(params: any, selectedGroups: string[], groupFilters: any, request: IGslRunRequest) {
        const sortRequest = get(params, 'request.sortModel[0]', null);
        const groupKeys = get(params, 'request.groupKeys');
        if (sortRequest) {
            if ('colId' in sortRequest && request.options) {
                request.options.orderBy = sortRequest.colId.toString();
            }
            if ('sort' in sortRequest && request.options) {
                request.options.order = sortRequest.sort.toString();
            }
        }
        // build extra filter
        for (let index = 0; index < selectedGroups.length; index++) {
            const currentGroupValue = groupKeys[index];
            if (typeof currentGroupValue === 'string') currentGroupValue.replaceAll('\'', '\\\\\'');
            groupFilters.push({
                name: selectedGroups[index],
                value: currentGroupValue,
            });
        }
    }

    public async getGSLRunDataCount(params: IServerSideGetRowsParams, selectedGroups: string[]): Promise<number> {
        const request: IGslRunRequest = this.buildGSLRunRequest(params, selectedGroups);
        if (request.gsl.includes(GSL_CLAUSES.SUMMARIZE)) {
            const summarizeClause = request.gsl.substring(request.gsl.indexOf(GSL_CLAUSES.SUMMARIZE));
            request.gsl = request.gsl.replace(summarizeClause, EMPTY_STRING);
        }
        request.gsl += ` ${GSL_CLAUSES.SUMMARIZE} dcount(findingKey) as cnt`;

        if (request.options) {
            // disable pagination and sorting while getting records count
            request.options.orderBy = undefined;
            request.options.order = undefined;
            request.options.pagination = undefined;
        }
        try {
            return await getIntelligenceService().getGSLRunDataCount(request);
        } catch (error) {
            this.currentCount = CounterStatus.Error;
            this.totalCount = CounterStatus.Error;
            this.onRowCountUpdate?.({ currentCount: this.currentCount, totalCount: this.totalCount });
            return 0;
        }
    }

    public async fetchIntelligenceFindingsAggregations(filterValues?: IFilterDetails): Promise<Aggregations> {

        const facetFields = getFilterFacetFields(this.filters);
        const { mitreFacetFields, isMitreColumnPresent } = this.mitreInfo ?? {
            mitreFacetFields: [],
            isMitreColumnPresent: false,
        };
        const originsFilter = convertSourceTypesToFilter(this.originTypes);
        const {
            queryFilter,
            mitreFilter,
            mitreLikeFilter,
        } = filterValuesToRequestQueryFilters(filterValues, mitreFacetFields);
        const freeTextFilter: string | undefined = freeTextToGslFormat(this.apis?.columnApi, filterValues?.freeTextPhrase);
        const dateRange: ITimeRange | undefined = dateTimeFilter(filterValues);
        const facetClause: string = !isEmpty(facetFields) ? `${GSL_CLAUSES.FACET_BY} ${facetFields.join(',')}` : EMPTY_STRING;
        const computedFilterString: string = computeFilterString({ originsFilter, queryFilter, freeTextFilter, isArchiveView: this.isArchiveView });

        let facetsQuery = `${ALERTS} ${computedFilterString} ${facetClause}`;
        if (!isEmpty(mitreFacetFields) || isMitreColumnPresent) {
            // build mitre facets query
            const whereClause = `${GSL_CLAUSES.WHERE} ${mitreFilter ? mitreFilter : DEFAULT_MITRE_TACTIC_FILTER}`;
            const joinMitreQuery = `${GSL_CLAUSES.JOIN_MITRE} ${whereClause}`;
            const mitreFacetsQueryString = `${ALERTS} ${computedFilterString} ${joinMitreQuery} ${GSL_CLAUSES.FACET_BY} ${mitreFacetFields?.join(',')}`;
            // build main facets query
            const mitreLikeFilterQuery = mitreLikeFilter ? `${GSL_CLAUSES.JOIN_MITRE} ${GSL_CLAUSES.WHERE} ${mitreLikeFilter}` : EMPTY_STRING;
            const mainFacetsQueryString = `${ALERTS} ${computedFilterString} ${mitreLikeFilterQuery} ${facetClause}`;
            // combine both facets queries together
            facetsQuery = `${mainFacetsQueryString} ${OPERATORS.AND} ${mitreFacetsQueryString}`;
        }

        const request: IGslRunRequest = {
            gsl: `${facetsQuery}`,
            options: {
                source: ALERTS,
                start: Number(dateRange?.start),
                end: Number(dateRange?.end),
                multiStatementStrategy: 'union',
            },
        };
        const facets: IGslRunFacetResponse = await getIntelligenceService().getIntelligenceFacets(request);
        // convert facets to aggregation
        return convertFacetsToAggregations([...facetFields, ...mitreFacetFields], facets?.data);
    }
}
