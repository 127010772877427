import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const Wrapper = styled(Stack)`
    background-color: ${({ theme }) => theme.palette.surface.brandLight };
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
`;

export const RemediationBoxStyled = {
    Wrapper,
};
