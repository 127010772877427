import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import globalAddinContainer from 'common/extensibility/AddinContainer';

import {
    IEventsFilter,
    IEventsFilterParams,
    IEventsService,
    PATH_EVENTS_FILTERS_DEFINITIONS,
} from 'common/module_interface/events/Events';
import { IColumnUsageDef } from 'common/interface/general';
import { ColDef } from 'ag-grid-enterprise';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { mergeColumnDefs } from 'common/utils/tableUtils';

export class EventsService implements IEventsService {
    getColumnDefs(columnUsages: IColumnUsageDef[]): ColDef[] {
        return mergeColumnDefs(columnUsages, EventsTableRegistry.getColumnDefs());
    }

    getFilterDefs(filters: IEventsFilter[], params: IEventsFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = globalAddinContainer.getById<IFilterProps>(PATH_EVENTS_FILTERS_DEFINITIONS, filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    }
}
