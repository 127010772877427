import styled, { css } from 'styled-components';
import { Stack, Typography } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacing(5)}${theme.units}`};
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

const HelperDiv = styled(Stack)`
  height: 100%;
  min-width: 150px;
  min-height: 150px;
  align-items: center;
  justify-content: center;
`;

const PairDiv = styled(Stack)`
  flex-direction: row;
  gap: ${({ theme }) => `${theme.spacing(10)}${theme.units}`};
  align-items: flex-start;
`;

const BulletDiv = styled.div<{ bgColor: string }>`
  background: ${prop => prop.bgColor};
  border-radius: 4px;
  height: 8px;
  width: 8px;
`;

const LegendCounter = styled(Typography)<{ isLink: boolean }>`
  ${({ isLink, theme }) => isLink && css`
    &:hover {
      cursor: pointer;
      color: ${theme.palette.onSurface.brandPrimary};
      text-decoration: underline;
    }
  `}
`;

export const ClassificationStyled = {
    TopDiv,
    HelperDiv,
    PairDiv,
    BulletDiv,
    LegendCounter,
};