import {
    Button,
    Dropdown,
    Modal,
    TextField,
} from '@dome9/berries/react-components';
import { IDashboard, IDashboardSection } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { updateDashboardInServer } from '../../Api';
import { i18nOverviewNamespace } from '../../initialize.i18n';
import MiniMenu, { IMiniMenuItem } from '../MiniMenu';
import { v4 } from 'uuid';
import { addSectionInStore, deleteSectionInStore, renameSectionInStore } from 'modules/overview/Overview.reducer';
import { getStoreService } from 'common/interface/services';

interface IEditSectionProps {
    selectedSection: IDashboardSection
    selectedDashboard: IDashboard
}

interface IModalProps {
    submitAction: ()=> void;
    title: string;
    content: JSX.Element;
}

const SectionActions: React.FC<IEditSectionProps> = ({ selectedSection, selectedDashboard }) => {
    const { t } = useTranslation(i18nOverviewNamespace);
    const { dispatch } = getStoreService().getReduxTools();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProps, setModalProps] = useState({} as IModalProps);
    const [isSubmitButtonDisabled,setIsSubmitButtonDisabled] = useState(false);
    const [modalErrorMessage,setModalErrorMessage] = useState(null as string | null);

    const openModal = ()=>{
        setIsModalOpen(true);
        setIsSubmitButtonDisabled(false);
        setModalErrorMessage(null);
    };
    const isNameDuplicate = (inputText: string)=> selectedDashboard.sections?.find(item=> item.title?.toLocaleLowerCase() === inputText?.toLocaleLowerCase());

    const checkSectionNameInput = (inputText: string) =>{
        if(!inputText){
            setIsSubmitButtonDisabled(true);
            setModalErrorMessage(t('DASHBOARD.MESSAGES.SECTION_NAME_EMPTY'));
        } else if(isNameDuplicate(inputText)){
            setIsSubmitButtonDisabled(true);
            setModalErrorMessage(t('DASHBOARD.MESSAGES.IS_DUPLICATED_OR_NOT_CHANGED', { inputText: inputText }) );
        } else {
            setIsSubmitButtonDisabled(false);
            setModalErrorMessage(null);
        }
    };

    const cloneSection = (section: { [key: string]: any; }) => {
        const clonedSection = deepCloneObject(section);
        clonedSection.widgets.forEach((widget: any) => {
            widget.id = v4() + new Date().getTime();
            widget.options.id = v4() + new Date().getTime();
        });
        return clonedSection;
    };

    const menuItems: IMiniMenuItem[] = [
        {
            title: t('SECTIONS.RENAME_SECTION'),
            icon: 'settings',
            onClick: ()=> {
                openModal();
                const clonedDashboard = deepCloneObject(selectedDashboard);
                const clonedSection = deepCloneObject(selectedSection);
                clonedSection.title = `${selectedSection.title} (${t('DASHBOARD.RENAMED')})`;
                setModalProps(

                    {
                        title: t('SECTIONS.RENAME_SECTION'),
                        content:
                            <div>
                                <TextField
                                    onChange={(e)=> {
                                        const textInput = e.target.value;
                                        checkSectionNameInput(textInput);
                                        clonedSection.title = textInput;
                                    }}
                                    value={clonedSection.title}

                                />

                            </div>
                        ,
                        submitAction: async ()=>{
                            clonedDashboard.sections.map((section: IDashboardSection) => {
                                if(section.id === clonedSection.id){
                                    section.title = clonedSection.title;
                                }
                            });
                            dispatch(renameSectionInStore({ newName: clonedSection.title, sectionId: clonedSection.id }));
                            setIsModalOpen(false);
                            await updateDashboardInServer(clonedDashboard, false);
                        }
                    }
                );
            }
        },
        {
            title: t('SECTIONS.DUPLICATE'),
            icon: 'copy',
            onClick: ()=> {
                setIsModalOpen(true);
                const clonedDashboard = deepCloneObject(selectedDashboard);
                const clonedSection = cloneSection(selectedSection);
                clonedSection.title = `${selectedSection.title} (${t('DASHBOARD.DUPLICATED')})`;
                setModalProps(
                    {
                        title: t('SECTIONS.DUPLICATE'),
                        content:
                            <>
                                <TextField
                                    onChange={(e)=> {
                                        const textInput = e.target.value;
                                        checkSectionNameInput(textInput);
                                        clonedSection.title = textInput;
                                    }}
                                    value={clonedSection.title}
                                />
                            </>,
                        submitAction: async ()=>{
                            clonedSection.id = v4();
                            clonedDashboard.sections.push(clonedSection);
                            dispatch(addSectionInStore(clonedSection));
                            setIsModalOpen(false);
                            await updateDashboardInServer(clonedDashboard, false);
                        }
                    }
                );
            },
        },
        {
            title: t('SECTIONS.DELETE_SECTION.TITLE'),
            icon: 'delete',
            onClick: ()=> {
                openModal();
                const clonedDashboard = deepCloneObject(selectedDashboard);
                setIsSubmitButtonDisabled(false);
                setModalProps(
                    {
                        title: t('SECTIONS.DELETE_SECTION.TITLE'),
                        content:
                            <Trans components={{ b: <strong /> }}>
                                {t('SECTIONS.DELETE_SECTION.MESSAGE', { dashboardName: selectedSection.title })}
                            </Trans>,
                        submitAction: async ()=>{
                            clonedDashboard.sections = clonedDashboard.sections.filter((section: IDashboardSection) => section.id !== selectedSection.id);
                            setIsModalOpen(false);
                            dispatch(deleteSectionInStore(selectedSection.id));
                            await updateDashboardInServer(clonedDashboard, false);
                        }
                    }
                );
            },
        },
        
    ];

    return (
        <div>
            <Dropdown
                hasArrowIcon={false}
                icon='more'
                placement="bottom-end"
                render={()=> <MiniMenu menuItems={menuItems}></MiniMenu>}
                buttonVariant="quiet"    
            />
            <Modal.ModalDialog isOpen={isModalOpen} onRequestClose={()=> setIsModalOpen(false)} width={'md'} shouldCloseOnOverlayClick>
                <Modal.ModalHeader title={modalProps.title}
                    onClose={()=> setIsModalOpen(false)} />
                <Modal.ModalContent>
                    {modalProps.content}
                    <div className='text-danger flex items-center min-h-[30px]'>
                        {modalErrorMessage}
                    </div>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <div className='modal__footer-actions'>
                        <Button onClick={()=> {
                            setIsModalOpen(false);
                            setIsSubmitButtonDisabled(false);
                            setModalErrorMessage(null);}
                        }>{t('SECTIONS.CANCEL')}</Button>
                        <Button color='primary' disabled={isSubmitButtonDisabled} onClick={()=> modalProps.submitAction()}>
                            {t('SECTIONS.SAVE')}
                        </Button>
                    </div>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
        </div>
    );
};

export default SectionActions;