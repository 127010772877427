import React from 'react';
import { ToolBarProps } from './Toolbar.types';
import { ActionType } from '../../OciEnvironment.types';
import { useTranslation } from 'react-i18next';
import { changeUrl } from 'common/utils/http';
import { getOciEnvNamespace } from '../../initialize.i18n';
import { OciEnvironmentAction } from 'common/module_interface/assets/OciEnvironment';
import { OciEnvironmentAddinRegistry } from 'common/helpers/ociEnvironment';
import { TFunction } from 'i18next';
import { Dropdown, List } from 'common/design-system/components-v2';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { getNotificationsService, getUserService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import OciCloudAccountsService from '../../services/cloudAccounts/ociCloudAccounts.service';

interface Action {
    type: ActionType;
    label: string;
    icon?: AllIconsName;
}
interface Actions {
    singleAction?: Action;
    multiActions?: {
        label: string;
        icon?: AllIconsName;
        items: Array<Action>;
    }
}

const getActions = (t: TFunction): Array<Actions> => [
    { singleAction: { type: ActionType.Remove, label: t('ociPage.toolbar.remove'), icon: 'delete' } },
    { singleAction: { type: ActionType.Rename, label: t('ociPage.toolbar.rename'), icon: 'edit' } },
    { singleAction: { type: ActionType.EditCredentials, label: t('ociPage.toolbar.editCredentials'), icon: 'edit' } },
    { singleAction: { type: ActionType.SyncNow, label: t('ociPage.toolbar.syncNow'), icon: 'refresh' } },
];

const Toolbar: React.FunctionComponent<ToolBarProps> = ({
    actionClick,
    ociAccount,
}) => {
    const { t } = useTranslation(getOciEnvNamespace('oci'));

    const [actionsFromAddins, setActionsFromAddins] = React.useState<Array<OciEnvironmentAction>>([]);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!ociAccount) return;

        const getAndSetActionsFromAddins = () => {
            const newActions: Array<any> = [];
            const actionsFromAddin = OciEnvironmentAddinRegistry.getActions();
            const actionsPromises = actionsFromAddin.map(action => {
                return action.isRelevant ? action.isRelevant(ociAccount) : true;
            });
            Promise.all(actionsPromises).then(results => {
                actionsFromAddin.forEach((action, index) => {
                    if (results[index]) {
                        newActions.push(action.getValue(ociAccount));
                    }
                });
                setActionsFromAddins(newActions);
            });
        };

        getAndSetActionsFromAddins();
    }, [ociAccount]);

    const handleOnSyncNowSuccess = React.useCallback(() => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('ociPage.syncNow.onSubmit.success'),
            text: '',
        });
    }, [t]);

    const handleOnSyncNowError = React.useCallback((err: string) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('ociPage.syncNow.onSubmit.error'),
            text: err,
        });
    }, [t]);

    const syncNow = React.useCallback(async (accountId: string) => {
        try{
            await OciCloudAccountsService.cloudAccountsSyncNow(accountId);
            handleOnSyncNowSuccess();
        }
        catch(err: any){
            handleOnSyncNowError(err.message);
        }
    }, [handleOnSyncNowSuccess, handleOnSyncNowError]);

    const handleOnActionClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: ActionType) => {
        setIsDropdownOpen(false);
        switch (type) {
            case ActionType.EditCredentials: {
                const { altKey, shiftKey } = event;
                if (altKey && shiftKey) {
                    handleOnActionClick(event, ActionType.SyncNow);
                    break;
                }
                actionClick(type);
                break;
            }
            case ActionType.SyncNow: {
                syncNow(ociAccount.account.id);
                break;
            }
            case ActionType.AddActionTrail:{
                changeUrl(`/magellan/onboarding-oci?type=actiontrail&account=${ociAccount.account.ociAccountId}&id=${ociAccount.account.id}&accountName=${ociAccount.account.name}`);
                break;
            }
            default:
                actionClick(type);
        }
    }, [actionClick, syncNow, ociAccount]);

    const isActionTypeActive = React.useCallback((type: ActionType) => {
        switch (type) {
            case ActionType.Remove:
            case ActionType.Rename:
            case ActionType.AddActionTrail:
            case ActionType.EditCredentials: {
                return true;
            }
            case ActionType.SyncNow: {
                return getUserService().hasPermission(['cloud-account-sync-now']);
            }
            default:
                return false;
        }
    }, []);

    const getIsLoadingState = React.useCallback((type: ActionType) => {
        switch (type) {
            default:
                return false;
        }
    }, []);

    const filteredActions = React.useMemo(() => {
        return getActions(t).
            filter(action => {
                if (action.singleAction) {
                    return isActionTypeActive(action.singleAction.type);
                } else if (action.multiActions) {
                    return action.multiActions.items.some(act => isActionTypeActive(act.type));
                }
                return false;
            });
    }, [t, isActionTypeActive]);

    const dropdownListOptions = React.useMemo<IListItemProps[]>(() => {
        const listItems: IListItemProps[] = [];
        filteredActions.forEach((action, index) => {
            if (action.singleAction) {
                const { label, type } = action.singleAction;
                listItems.push({
                    label,
                    onClick: (e) => handleOnActionClick(e as any, type),
                    disabled: getIsLoadingState(type),
                    value: `${label}-${index}`
                });
            } else if (action.multiActions) {
                const { label, items } = action.multiActions;
                listItems.push({
                    label,
                    value: `${label}-${index}`,
                    itemType: 'seperator',
                });
                items.forEach(act => {
                    listItems.push({
                        label: act.label,
                        onClick: (e) => handleOnActionClick(e as any, act.type),
                        disabled: getIsLoadingState(act.type),
                        value: `${act.label}-${index}`
                    });
                });
            }
        });
        actionsFromAddins.forEach((action, index) => {
            listItems.push({
                label: action.label,
                icon: action.icon,
                onClick: () => action.callback(ociAccount),
                disabled: action.disabled && action.disabled(ociAccount),
                value: `${action.label}-${index}`
            });
        });
        return listItems;
    }, [filteredActions, actionsFromAddins, getIsLoadingState, handleOnActionClick, ociAccount]);

    
    return (
        <Dropdown
            open={isDropdownOpen}
            onStateChange={(state) => setIsDropdownOpen(state)}
            buttonProps={{
                iconButton: true,
                iconProps: { name: 'more' },
            }}
            maxHeight={500}
        >
            <List
                options={dropdownListOptions}
            />
        </Dropdown>
    );
};


export default Toolbar;
