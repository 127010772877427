import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import {
    ASSETS_FILTER_TYPES,
    createRecentlyUsedFilter,
    createSavedFiltersFilter
} from '../BaseDashboard/DashboardFilters';

export const DATA_SECURITY_FILTERS: IProtectedAssetFilter[] = [
    ASSETS_FILTER_TYPES.ADD_FILTER,
    ASSETS_FILTER_TYPES.ORGANIZATIONAL_UNIT,
    ASSETS_FILTER_TYPES.PLATFORM,
    ASSETS_FILTER_TYPES.TYPE,
    ASSETS_FILTER_TYPES.ENVIRONMENT,
    ASSETS_FILTER_TYPES.BUSINESS_PRIORITY,
    createRecentlyUsedFilter('data-security-dashboard'),
    createSavedFiltersFilter('data-security-dashboard-saved-filters'),
    ASSETS_FILTER_TYPES.CLEAR_ALL,
];
