import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OnboardingStepType } from '../aws/helpers/aws.interface';
import { getAwsStepName, getIsOrgSelected } from '../aws/helpers/aws.reducer';
import { getIsAWSLegacyView, setIsAWSLegacyView } from '../../../App.reducer';
import { Alert, Stack, Typography } from 'common/design-system/components-v2';

const OnboardingInfoBar: FC = () => {
    const awsStepName = useSelector(getAwsStepName);
    const isAWSLegacyView = useSelector(getIsAWSLegacyView);
    const isOrgSelected = useSelector(getIsOrgSelected);
    const dispatch = useDispatch();

    function showOnBoardingAlert() {
        return window.location.pathname === '/v2/aws' && awsStepName !== OnboardingStepType.Summary && !isOrgSelected;
    }

    return (
        <>
            {showOnBoardingAlert() && (
                <Alert type={'info'} className='cp-alert' hideStatusIcon>
                    <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="body">
                            <Trans
                                i18nKey={ isAWSLegacyView
                                    ? 'ON_BOARDING.SWITCHING_TO_NEW_WIZARD_TEMPLATE'
                                    : 'ON_BOARDING.SWITCHING_TO_NEW_LEGACY_TEMPLATE'
                                }>
                                <span />
                                <Link onClick={() => dispatch(setIsAWSLegacyView(!isAWSLegacyView))} to='/aws' />
                            </Trans>
                        </Typography>
                    </Stack>
                </Alert>
            )}
        </>
    );
};

export default OnboardingInfoBar;
