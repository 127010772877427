import React, { useEffect, useState } from 'react';
import {
    Button, Icon,
    Modal,
    Select,
    TextField,
} from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import { i18nReportsNamespace } from 'modules/reporting/initialize.i18n';
import './ReportingModal.scss';
import {
    HelperText,
    ReportModalContent,
    ReportTarget,
    TimeRangeDropdown,
} from './ReportingModal.styled';
import { CronStringInput } from '../CronStringInput';
import { MultiEmailInput } from '../MultiEmailInput';
import {
    ISelectOption,
    IReportTableItem,
    IScheduleDetails,
    IReportNotificationItem,
    IReportSaveItem,
    IReportFilterConfigurationsModel,
    IReportFilterItem,
    IVendorSelectionOption, IEnvironmentSelectionOption, IRulesetSelectionOption
} from '../../interface/Reporting.interface';
import {
    getSeverities,
    getTimeRangeNumberOptions,
    translations,
} from '../../Reporting.utils';
import {
    ReportFormatOptions,
    ReportNotificationType,
    ReportType,
    ReportTimeRangeValue,
    MaxItemSelectionLimit, ReportTimeRangeLimit,
} from '../../Reporting.const';
import { Checkbox, Typography } from 'common/design-system/components-v2';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';

export const ReportingModal: React.FC<{
    environmentOptions: IEnvironmentSelectionOption[];
    vendorOptions: IVendorSelectionOption[];
    rulesetOptions: IRulesetSelectionOption[];
    organizationalUnitOptions: ISelectOption[];
    reportsModels: IReportFilterConfigurationsModel[];
    isModalOpen: boolean;
    title: string;
    errorField: string;
    setIsModalOpen: (isOpen: boolean) => void;
    saveReport: (report: IReportSaveItem) => void;
    editReport: IReportTableItem | null;
    onCancel: () => void;
    clearErrorField: () => void;
}> = ({
    setIsModalOpen,
    isModalOpen,
    saveReport,
    title,
    errorField,
    environmentOptions,
    vendorOptions,
    rulesetOptions,
    organizationalUnitOptions,
    reportsModels,
    editReport,
    onCancel,
    clearErrorField
}) => {
    const { t } = useTranslation(i18nReportsNamespace);
    const translatedOptions = translations(t);
    const [template, setTemplate] = useState<string>(
        editReport?.reportType ?? '',
    );
    const [templatesOptions, setTemplatesOptions] = useState<ISelectOption[]>([]);
    const [reportName, setReportName] = useState<string>(
        editReport?.reportName ?? '',
    );
    const [environmentsOptions, setEnvironmentsOptions] = useState<IEnvironmentSelectionOption[]>(environmentOptions);
    const [rulesetsOptions, setRulesetsOptions] = useState<IRulesetSelectionOption[]>(rulesetOptions);
    const [vendorsOptions, setVendorsOptions] = useState<IVendorSelectionOption[]>(vendorOptions);
    const [isOptOutExclusions, setIsOptOutExclusions] = useState<boolean>(editReport?.isOptOutExclusions ?? false);

    const selectOptionFromEditedReport = (editedReportValue: (string | number)[], options: ISelectOption[] | IEnvironmentSelectionOption[] | IVendorSelectionOption[] | IRulesetSelectionOption[]) => {
        if (!editedReportValue?.length) return [];
        return options.filter(opt => editedReportValue.find(value => value === opt.value));
    };

    const severityOptions = getSeverities();

    useEffect(() => {
        setTemplatesOptions(translatedOptions.templateOptions.filter(templateOption => reportsModels.find(model => templateOption.value === model.reportType)));
        if (editReport) {
            setEnvironment(editReport.environment && environmentsOptions.length ? selectOptionFromEditedReport(editReport.environment, environmentsOptions) as IEnvironmentSelectionOption[] : []);
            setVendors(editReport.vendors && vendorsOptions.length ? selectOptionFromEditedReport(editReport.vendors, vendorsOptions) as IVendorSelectionOption[] : []);
            setSeverity(editReport.severity && severityOptions.length ? selectOptionFromEditedReport(editReport.severity, severityOptions) : []);
            setRulesets(editReport.rulesets && rulesetsOptions.length ? selectOptionFromEditedReport(editReport.rulesets, rulesetsOptions) as IRulesetSelectionOption[] : []);
            setOrganizationalUnits(editReport.organizationalUnits && organizationalUnitOptions.length ? selectOptionFromEditedReport(editReport.organizationalUnits, organizationalUnitOptions) : []);
            setCdrTypes(editReport.cdrTypes ? selectOptionFromEditedReport(editReport.cdrTypes, translatedOptions.reportCdrTypesOptions) : []);
            setShowScheduleSettings(!editReport.isReportWithoutScheduler);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const selectedTemplate = template || translatedOptions.templateOptions[0].value;
        const selectedTemplateOptions = translatedOptions.templateOptions.find(templateOption => templateOption.value === selectedTemplate);
        setTemplate(selectedTemplate);
        setSelectedReportModel(reportsModels.find(model => model.reportType === selectedTemplate));
        setRulesets(previousRulesets => previousRulesets.filter(previousRuleset => selectedTemplateOptions!.rulesetType === previousRuleset.rulesetType));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [environment, setEnvironment] = useState<MultiValue<IEnvironmentSelectionOption>>([]);
    const [vendors, setVendors] = useState<MultiValue<IVendorSelectionOption>>([]);
    const [severity, setSeverity] = useState<MultiValue<ISelectOption>>([]);
    const [rulesets, setRulesets] = useState<MultiValue<IRulesetSelectionOption>>([]);
    const [cdrTypes, setCdrTypes] = useState<MultiValue<ISelectOption>>([]);
    const [organizationalUnits, setOrganizationalUnits] = useState<MultiValue<ISelectOption>>([]);
    const [selectedReportModel, setSelectedReportModel] = useState<IReportFilterConfigurationsModel>();
    const [timeRangeNumber, setTimeRangeNumber] = useState<number>(
        editReport?.timeRangeNumber ?? 1,
    );
    const [timeRange, setTimeRange] = useState<string>(
        editReport?.timeRange ?? '',
    );

    useEffect(() => {
        const supportedVendorIds = selectedReportModel?.supportedFilters.find(item => item.name === 'vendor_ids')?.validValues?.split(',') ?? [];
        const supportedVendorOptions = vendorOptions.filter(option => supportedVendorIds.find(id => option.value === parseInt(id)));
        setVendorsOptions(supportedVendorOptions);
    }, [selectedReportModel, vendorOptions]);

    const getNumberOptionsByTimeRange = () => {
        switch (editReport?.timeRange) {
            case ReportTimeRangeValue.Daily:
                return getTimeRangeNumberOptions(ReportTimeRangeLimit.Daily);
            case ReportTimeRangeValue.Monthly:
                return getTimeRangeNumberOptions(ReportTimeRangeLimit.Monthly);
            default:
                return getTimeRangeNumberOptions(ReportTimeRangeLimit.Weekly);
        }
    };

    const handleTemplateSelection = (option: ISelectOption) => {
        setTemplate(option.value as string);
        const updatedModel = reportsModels.find(model => model.reportType === option.value);
        setSelectedReportModel(updatedModel);
        clearErrorField();
        const selectedTemplateOptions = translatedOptions.templateOptions.find(templateOption => templateOption.value === option.value as string);
        const supportedVendorIds = updatedModel?.supportedFilters.find(item => item.name === 'vendor_ids')?.validValues?.split(',') ?? [];

        setVendors(previousVendors => previousVendors.filter(previousVendor => supportedVendorIds.includes(previousVendor.value.toString())));
        setRulesets(previousRulesets => previousRulesets.filter(previousRuleset => selectedTemplateOptions!.rulesetType === previousRuleset.rulesetType));
    };

    const [timeRangeNumberOptions, setTimeRangeNumberOptions] = useState<ISelectOption[]>(getNumberOptionsByTimeRange());
    const [emailValue, setEmailValue] = useState<IReportNotificationItem[]>(
        editReport?.notifications ?? [],
    );
    const [showEmailField, setShowEmailField] = useState(true);
    const [showScheduleSettings, setShowScheduleSettings] = useState(!(editReport?.isReportWithoutScheduler) ?? true);
    const [scheduleDetails, setScheduleDetails] = useState<IScheduleDetails>(
        editReport?.scheduleDetails ?? {
            minuteOfHour: 0,
            hourOfDay: 0,
            dayOfWeek: null,
            dayOfMonth: null,
        },
    );

    const handleEmails = (emails: string[]) => {
        const notifications: IReportNotificationItem[] = emails.map((email) => {
            return { type: ReportNotificationType.Email, value: email };
        });

        setEmailValue(notifications);
    };

    const onTimeRangeChange = (option: ISelectOption) => {
        option?.value && setTimeRange(option.value as string);
        const defaultTimeRangeNumber = timeRangeNumberOptions.find(
            (opt) => opt.value === 1,
        )?.value;
        switch (option.value) {
            case ReportTimeRangeValue.Daily:
                if (timeRangeNumber > ReportTimeRangeLimit.Daily)
                    setTimeRangeNumber(defaultTimeRangeNumber as number);
                setTimeRangeNumberOptions(getTimeRangeNumberOptions(ReportTimeRangeLimit.Daily));
                break;
            case ReportTimeRangeValue.Monthly:
                if (timeRangeNumber > ReportTimeRangeLimit.Monthly)
                    setTimeRangeNumber(defaultTimeRangeNumber as number);
                setTimeRangeNumberOptions(getTimeRangeNumberOptions(ReportTimeRangeLimit.Monthly));
                break;
            default:
                if (timeRangeNumber > ReportTimeRangeLimit.Weekly)
                    setTimeRangeNumber(defaultTimeRangeNumber as number);
                setTimeRangeNumberOptions(getTimeRangeNumberOptions(ReportTimeRangeLimit.Weekly));
        }
    };

    const buildSavingFilters = (allFilters: IReportFilterItem[]) => {
        if(!selectedReportModel?.supportedFilters) return [];
        const supportedFilters = selectedReportModel.supportedFilters.map((filter) => filter.name);
        return allFilters.filter((filter) => supportedFilters.includes(filter.name));
    };

    const onSave = () => {
        const allFilters = [
            {
                name: 'granularity',
                value: timeRange as ReportTimeRangeValue,
            },
            {
                name: 'timeRange_with_granularity',
                value: timeRangeNumber,
            },
            {
                name: 'severity',
                value: severity.map((severity) => severity.value),
            },
            {
                name: 'rule_set_names',
                value: rulesets.map((ruleset) => ruleset.value),
            },
            {
                name: 'environment_names',
                value: environment.map((env) => env.value),
            },
            {
                name: 'environment_ids',
                value: environment.map((env) => env.id),
            },
            {
                name: 'vendor_ids',
                value: vendors.map((vendor) => vendor.value),
            },
            {
                name: 'organizational_unit_ids',
                value: organizationalUnits.map((ou) => ou.value),
            },
            {
                name: 'organizational_unit_paths',
                value: organizationalUnits.map((ou) => ou.label),
            },
            {
                name: 'cdr_types',
                value: cdrTypes.map((type) => type.value),
            },
            {
                name: 'opt_out_exclusions',
                value: isOptOutExclusions,
            }
        ];
        const requestData: IReportSaveItem = {
            reportType: template as ReportType,
            reportName: reportName,
            filters: buildSavingFilters(allFilters),
            notifications: emailValue,
            scheduleDetails,
            isReportWithoutScheduler: !showScheduleSettings
        };
        if (editReport) requestData.reportId = editReport.reportId;
        saveReport(requestData);
    };

    const onModalClose = () => {
        onCancel();
        setIsModalOpen(false);
    };

    const getSelectedOptions = (inputValues: MultiValue<ISelectOption | IEnvironmentSelectionOption | IRulesetSelectionOption>, options: ISelectOption[] | IEnvironmentSelectionOption[]): ISelectOption[] | IEnvironmentSelectionOption[] | IRulesetSelectionOption[] => {
        if (inputValues.length < MaxItemSelectionLimit) return options;
        const selectedOptions: ISelectOption[] = options.map(option => ({
            ...option,
            isSelected: false,
        })) ?? [];
        inputValues.map(inputValue => {
            const index = options?.findIndex((option) => inputValue.value === option.value);
            if (index !== undefined && selectedOptions) selectedOptions[index].isSelected = true;
        });
        return selectedOptions;
    };

    const helperText = (label: string) => {
        return errorField === label ? <HelperText>
            <Icon name={'error'} color={'error'} size={14}/>
            <Typography color="normal" variant="body">
                {t('REPORTS.NOTIFICATIONS.ERROR.REQUIRED_FIELDS')}
            </Typography>
        </HelperText> : '';
    };

    const errorClass = (label: string) => errorField === label ? 'error-select' : '';

    const isFilterRequired = (name: string) => !!selectedReportModel?.supportedFilters.find(filter => filter.name === name)?.required;

    return (
        <Modal.ModalDialog
            isOpen={isModalOpen}
            onRequestClose={() => onModalClose()}
            width='lg'
            shouldCloseOnOverlayClick={false}
            className={'report-modal-container'}>
            <Modal.ModalHeader
                title={title}
                onClose={() => onModalClose()}
            />

            <Modal.ModalContent>
                <div className={'flex justify-center'}>
                    <ReportModalContent>
                        <div className='my-8'>
                            <Select
                                required={true}
                                isSearchable
                                label={t('REPORTS.MODAL.SELECT_TEMPLATE')}
                                closeMenuOnSelect
                                value={translatedOptions.templateOptions.find(
                                    (opt) => opt.value === template,
                                )}
                                options={templatesOptions}
                                onChange={(option: SingleValue<ISelectOption>) => {
                                    option &&
                                    option.value &&
                                    handleTemplateSelection(option);
                                }}
                            />
                        </div>

                        <div className='my-8'>
                            <TextField
                                required={true}
                                state={errorField === t('REPORTS.MODAL.NAME') || errorField === t('REPORTS.MODAL.DUPLICATE_NAME') ? 'error' : undefined}
                                helper={errorField === t('REPORTS.MODAL.NAME') ? t('REPORTS.NOTIFICATIONS.ERROR.REQUIRED_FIELDS') : errorField === t('REPORTS.MODAL.DUPLICATE_NAME') ? t('REPORTS.NOTIFICATIONS.ERROR.DUPLICATE_NAME') : undefined}
                                value={reportName}
                                label={t('REPORTS.MODAL.NAME')}
                                onChange={(event) =>
                                    setReportName(event.target.value)
                                }
                            />
                        </div>

                        {
                            selectedReportModel?.supportedFilters.find(filter => filter.name === 'timeRange_with_granularity') &&
                            selectedReportModel?.supportedFilters.find(filter => filter.name === 'granularity') && (
                                <div>
                                    <label className='my-8'>{t('REPORTS.MODAL.SCOPE')}</label>
                                    <div className='flex gap-x-8 my-4'>
                                        <div
                                            className={`w-[110px] ${errorClass(translatedOptions.FieldsMapping['timeRange_with_granularity'])}`}>
                                            <Select
                                                required={true}
                                                isSearchable
                                                closeMenuOnSelect
                                                value={translatedOptions.timeRangeOptions.find(
                                                    (opt) => opt.value === timeRange,
                                                )}
                                                label={translatedOptions.FieldsMapping['timeRange_with_granularity']}
                                                options={translatedOptions.timeRangeOptions}
                                                onChange={(option: SingleValue<ISelectOption>) => {
                                                    option?.value &&
                                                    onTimeRangeChange(option);
                                                }}
                                            />
                                        </div>

                                        <TimeRangeDropdown className='w-[90px]'>
                                            <Select
                                                required={false}
                                                isSearchable
                                                closeMenuOnSelect
                                                value={timeRangeNumberOptions.find(
                                                    (opt) => opt.value === timeRangeNumber,
                                                )}
                                                label='-'
                                                maxMenuHeight={110}
                                                options={timeRangeNumberOptions}
                                                onChange={(option: SingleValue<ISelectOption>) =>
                                                    option?.value &&
                                                    setTimeRangeNumber(option.value as number)
                                                }
                                            />
                                        </TimeRangeDropdown>
                                    </div>
                                    {helperText(translatedOptions.FieldsMapping['timeRange_with_granularity'])}
                                </div>
                            )}
                        {selectedReportModel?.supportedFilters.find(filter => filter.name === 'severity') && (
                            <div className='my-8'>
                                <Select
                                    isMulti
                                    isSearchable
                                    label={translatedOptions.FieldsMapping['severity']}
                                    maxMenuHeight={150}
                                    options={severityOptions}
                                    value={severityOptions?.filter((opt) =>
                                        severity.find(
                                            (v) => opt.value === v.value,
                                        ),
                                    )}
                                    onChange={(severity: OnChangeValue<ISelectOption, true>) => {
                                        setSeverity(severity);
                                    }}
                                />
                            </div>
                        )}
                        {selectedReportModel?.supportedFilters.find(filter => filter.name === 'vendor_ids') && (
                            <div className='my-8'>
                                <Select
                                    required={false}
                                    isMulti
                                    isSearchable
                                    label={translatedOptions.FieldsMapping['vendor_ids']}
                                    value={vendorsOptions?.filter((opt) =>
                                        vendors.find((v) => opt.value === v.value),
                                    )}
                                    options={vendorsOptions}
                                    onChange={(vendor: OnChangeValue<IVendorSelectionOption, true>) => {
                                        setVendors(vendor);
                                        setEnvironment(previousEnvironments => {
                                            const vendorNames = vendor.map(vendorSelection => vendorSelection.name);
                                            return previousEnvironments.filter(p => vendorNames.includes(p.platform));
                                        });
                                        setRulesets(previousRulesets => {
                                            const vendorNames = vendor.map(vendorSelection => vendorSelection.name);
                                            return previousRulesets.filter(p => p.vendor ? vendorNames.includes(p.vendor) : true);
                                        });
                                    }}
                                />
                            </div>
                        )}
                        {selectedReportModel?.supportedFilters.find(filter => filter.name === 'organizational_unit_ids') && (
                            <div className='my-8'>
                                <Select
                                    required={false}
                                    isMulti
                                    isSearchable
                                    label={translatedOptions.FieldsMapping['organizational_unit_ids']}
                                    value={organizationalUnitOptions?.filter((opt) =>
                                        organizationalUnits.find((v) => opt.value === v.value),
                                    )}
                                    options={organizationalUnitOptions}
                                    onChange={(ou: OnChangeValue<ISelectOption, true>) => {
                                        setOrganizationalUnits(ou);
                                    }}
                                />
                            </div>
                        )}
                        {selectedReportModel?.supportedFilters.find(filter => filter.name === 'environment_names') && (
                            <div className={`my-8 ${errorClass(translatedOptions.FieldsMapping['environment_names'])}`}>
                                <Select
                                    required={isFilterRequired('environment_names')}
                                    isMulti
                                    isSearchable
                                    value={environmentsOptions?.filter((opt: IEnvironmentSelectionOption) => environment?.find((env) => env.value === opt.value))}
                                    label={translatedOptions.FieldsMapping['environment_names']}
                                    options={environmentsOptions.filter((environmentsOption) => {
                                        const vendorNames = vendors.map(vendorSelection => vendorSelection.name);
                                        const shouldFilterVendors = vendorNames.length > 0;
                                        return shouldFilterVendors ? vendorNames.includes(environmentsOption.platform) : true;
                                    })}
                                    onChange={(environments: OnChangeValue<IEnvironmentSelectionOption, true>) => {
                                        if (environments.length <= MaxItemSelectionLimit) setEnvironment(environments);
                                        const updatedEnvironmentOptions = getSelectedOptions(environments, environmentsOptions) as IEnvironmentSelectionOption[];
                                        setEnvironmentsOptions(updatedEnvironmentOptions);
                                    }}
                                    isOptionDisabled={(env: IEnvironmentSelectionOption) => environment.length >= MaxItemSelectionLimit && !env.isSelected}
                                />
                                {helperText(translatedOptions.FieldsMapping['environment_names'])}
                            </div>
                        )}
                        {!!rulesetsOptions.length && selectedReportModel?.supportedFilters.find(filter => filter.name === 'rule_set_names') && (
                            <div className={`my-8 ${errorClass(translatedOptions.FieldsMapping['rule_set_names'])}`}>
                                <Select
                                    isSearchable
                                    isMulti
                                    required={isFilterRequired('rule_set_names')}
                                    value={rulesetsOptions?.filter((opt) =>
                                        rulesets?.find(
                                            (ruleset) => ruleset.value === opt.value,
                                        ),
                                    )}
                                    label={translatedOptions.FieldsMapping['rule_set_names']}
                                    options={rulesetsOptions.filter((rulesetOption: IRulesetSelectionOption) => {
                                        const vendorNames = vendors.map(vendorSelection => vendorSelection.name);
                                        const shouldFilterVendors = vendorNames.length > 0;
                                        const templateRulesetType = translatedOptions.templateOptions.find(templateOption => templateOption.value === template)!.rulesetType;
                                        return rulesetOption.rulesetType === templateRulesetType && (shouldFilterVendors && rulesetOption.vendor ? vendorNames.includes(rulesetOption.vendor) : true);
                                    })}
                                    onChange={(rulesets: OnChangeValue<IRulesetSelectionOption, true>) => {
                                        if (rulesets.length <= MaxItemSelectionLimit) setRulesets(rulesets);
                                        const updatedRulesetOptions = getSelectedOptions(rulesets, rulesetsOptions) as IRulesetSelectionOption[];
                                        setRulesetsOptions(updatedRulesetOptions);
                                    }}
                                    isOptionDisabled={(ruleset: IRulesetSelectionOption) => rulesets.length >= MaxItemSelectionLimit && !ruleset.isSelected}
                                />
                                {helperText(translatedOptions.FieldsMapping['rule_set_names'])}
                            </div>
                        )}
                        {selectedReportModel?.supportedFilters.find(filter => filter.name === 'cdr_types') && (
                            <div className='my-8'>
                                <Select
                                    required={false}
                                    isSearchable
                                    isMulti
                                    label={translatedOptions.FieldsMapping['cdr_types']}
                                    closeMenuOnSelect
                                    value={translatedOptions.reportCdrTypesOptions.filter((opt) =>
                                        cdrTypes?.find(
                                            (type) => type.value === opt.value,
                                        ),
                                    )}
                                    options={translatedOptions.reportCdrTypesOptions}
                                    onChange={(options: OnChangeValue<ISelectOption, true>) => setCdrTypes(options)}
                                />
                            </div>
                        )}

                        <div className='my-8'>
                            <label>{t('REPORTS.MODAL.FORMAT')}</label>
                            {ReportFormatOptions.map((format) => {
                                return (
                                    <div key={format.value}>
                                        <Checkbox
                                            label={format.label}
                                            checked={format.isChecked}
                                            disabled={true}
                                            onChange={(e) => {
                                                format.isChecked = e.target.checked;
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        <div className='my-8'>
                            <label>Target <span>*</span></label>
                            <div className='flex gap-x-8'>
                                <div key='email'>
                                    <Checkbox
                                        label={t('REPORTS.MODAL.EMAIL.TITLE')}
                                        checked={showEmailField}
                                        disabled={true}
                                        onChange={() =>
                                            setShowEmailField(!showEmailField)
                                        }
                                    />
                                </div>
                            </div>
                            <ReportTarget isVisible={showEmailField}>
                                <MultiEmailInput
                                    onEmailChange={handleEmails}
                                    emailsList={emailValue.map((e) => e.value)}
                                    isError={!!errorClass(t('REPORTS.MODAL.EMAIL.TITLE'))}
                                />
                                {helperText(t('REPORTS.MODAL.EMAIL.TITLE'))}
                                <div className={'mt-2'}>{t('REPORTS.MODAL.EMAIL.NOTE')}</div>
                            </ReportTarget>
                        </div>

                        <div className='my-8'>
                            <label>{t('REPORTS.MODAL.EXCLUSIONS.MAIN_LABEL')}</label>
                            <div className='flex gap-x-8'>
                                <div key='isOptOutExclusions'>
                                    <Checkbox
                                        label={t('REPORTS.MODAL.EXCLUSIONS.CHECKBOX_LABEL')}
                                        checked={isOptOutExclusions}
                                        disabled={false}
                                        onChange={() => setIsOptOutExclusions(() => !isOptOutExclusions)}
                                    />
                                </div>
                            </div>
                        </div>

                        <label>Schedule</label>
                        <div className='flex gap-x-8'>
                            <div key='scheduleSettings'>
                                <Checkbox
                                    label={t('REPORTS.MODAL.SCHEDULE_SETTINGS')}
                                    checked={showScheduleSettings}
                                    disabled={false}
                                    onChange={() =>
                                        setShowScheduleSettings(!showScheduleSettings)
                                    }
                                />
                            </div>
                        </div>

                        { showScheduleSettings && (
                            <div className='my-4'>
                                <div className='my-6'>
                                    <CronStringInput
                                        onSettingsChange={(cronObject: IScheduleDetails) =>
                                            setScheduleDetails({ ...cronObject })
                                        }
                                        scheduleConfig={scheduleDetails}
                                    />
                                </div>
                            </div>
                        )}
                    </ReportModalContent>
                </div>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <div className='modal__footer-actions'>
                    <Button onClick={() => onModalClose()}>
                        {t('REPORTS.MODAL.BUTTONS.CANCEL')}
                    </Button>
                    <Button color='primary' onClick={() => onSave()}>
                        {t('REPORTS.MODAL.BUTTONS.SAVE')}
                    </Button>
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default ReportingModal;
