import React, { Fragment } from 'react';
import { TFunction } from 'i18next';
import { EdgeData } from '../Models/EdgeData';
import { Icon } from '@dome9/berries/react-components';

const maxPortDisplayCount = 10;

const ExposureEdgePopoverComponent: React.FC<EdgeData> = (edgeData: EdgeData, t: TFunction) => {
    const additionalPortCount = (edgeData.securityGroupConfigurations?.length ?? 0) - maxPortDisplayCount;

    return <div className="-mx-6 -mt-5 -mb-5">
        <div className="px-7 pt-7 break-all flex-0" style={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}>
            {t('EDGE.POPOVER.INBOUND_SERVICES')}
        </div>

        <div className={'p-7'}>
            <div className={'grid max-h-[400px] overflow-y-auto text-sm'}>
                <div className={'pr-5 pb-3 pt-3'} style={{ fontWeight: 500, gridArea: '1 / 1 / 2 / 2' }}>{t('EDGE.POPOVER.EXPOSURE')}</div>
                <div className={'px-5 pb-3 pt-3'} style={{ fontWeight: 500, gridArea: '1 / 2 / 2 / 3', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>{`${t('EDGE.POPOVER.PROTOCOL')} : ${t('EDGE.POPOVER.PORT')}`}</div>
                <div className={'px-5 pb-3 pt-3'} style={{ fontWeight: 500, gridArea: '1 / 3 / 2 / 4', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>{t('EDGE.POPOVER.ALLOWED_SOURCES')}</div>
                <div className={'pl-5 pb-3 pt-3'} style={{
                    fontWeight: 500,
                    gridArea: '1 / 4 / 2 / 5',
                    borderLeft: '1px solid rgba(0, 0, 0, 0.1)'
                }}>
                    {t('INSIGHTS.TITLE')}
                </div>

                { edgeData!.securityGroupConfigurations!.slice(0, maxPortDisplayCount).map((securityGroupConfiguration, i) => (
                    <Fragment key={`${i}:${securityGroupConfiguration.portRange.from}`}>
                        <div className={'pr-5 pb-3 pt-3'} style={{ gridArea: `${i + 2} / 1 / ${i + 3} / 2`, display: 'flex', alignItems: 'center', borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}>{securityGroupConfiguration.exposureLevel}</div>
                        <div className={'px-5 pb-3 pt-3'} style={{ gridArea: `${i + 2} / 2 / ${i + 3} / 3`, display: 'flex', alignItems: 'center', borderLeft: '1px solid rgba(0, 0, 0, 0.1)', borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}>{`${securityGroupConfiguration.protocol ? `${securityGroupConfiguration.protocol} : ` : ''}${securityGroupConfiguration.portRange.from} ${securityGroupConfiguration.portName ? `(${securityGroupConfiguration.portName}) ` : ''}${securityGroupConfiguration.portRange.to ? `- ${securityGroupConfiguration.portRange.to}` : ''}`}</div>
                        <div className={'px-5 pb-3 pt-3'} style={{ gridArea: `${i + 2} / 3 / ${i + 3} / 4`, display: 'flex', alignItems: 'center', borderLeft: '1px solid rgba(0, 0, 0, 0.1)', borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}>{securityGroupConfiguration.cidrs?.join(', ') ?? ''}</div>
                        <div className={'pl-5 pb-3 pt-3'} style={{
                            gridArea: `${i + 2} / 4 / ${i + 3} / 5`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                            borderTop: '1px solid rgba(0, 0, 0, 0.1)'
                        }} onClick={() => edgeData!.displaySuggestions!(securityGroupConfiguration, edgeData.entity?.name ?? edgeData.entity?.externalId ?? '' )}>
                            <Icon name={'tips'} size={16} className="pointer"/>
                        </div>
                    </Fragment>
                ))}
            </div>
        </div>

        { additionalPortCount > 0 &&
            <div style={{ fontSize: '12px' }} className="p-7 bg-aside">
                <div>{`${additionalPortCount} additional port${additionalPortCount === 1 ? ' is' : 's are'} open`}</div>
                <div>{`${edgeData?.entity?.name || edgeData?.entity?.externalId ? `Click on ${edgeData.entity.name || edgeData.entity.externalId} to see more details` : ''}`}</div>
            </div>
        }
    </div>;
};

export default ExposureEdgePopoverComponent;