import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace, } from '../../../../../consts';
import Card from 'common/design-system/components-v2/Card';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    DataClassificationsMap,
    IClassificationCountersByCategories,
    IClassificationScoreBoxProps,
    IDataClassificationInfo
} from '../../../../../dataClassification';
import { ClassificationStyled } from './DataClassification.styled';
import { IFieldInfo, LoadingState } from 'common/interface/general';
import FullSizeError from 'common/erm-components/custom/FullSize/FullSizeError';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import Stack from 'common/design-system/components-v2/Stack';
import Alert from 'common/design-system/components-v2/Alert';
import {
    fetchClassificationsAggregationByCategories,
    getDataClassificationFilterFields
} from './DataClassification.datasource';
import {
    getAllSortedCategories,
    getCategoryColor,
    IDataAssetCategoryCounter,
    IDataAssetCategoryInfo
} from '../../../../../DataAssetCategories';
import { Typography } from 'common/design-system/components-v2';
import { ClassificationScoreBox } from './ClassificationScoreBox';
import { IEnvironmentsEmptyStateInfo } from '../../../../../RiskManagement.interface';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export const DataClassification : React.FC<{ emptyEnvStateInfo?: IEnvironmentsEmptyStateInfo, filterValues?: IFiltersValues }> = ({ emptyEnvStateInfo, filterValues }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);
    const [classificationCounters, setClassificationCounters] = useState<IClassificationCountersByCategories[]>([]);

    const getItem = useCallback((classificationInfo: IDataClassificationInfo): IClassificationScoreBoxProps => {
        const counter = classificationCounters.find(aCounter => aCounter.classificationInfo.type === classificationInfo.type);
        return {
            categoryCounters: counter?.categoryCounters || [],
            classificationInfo,
            filterValues,
        };
    }, [classificationCounters, filterValues]);

    const loadData = useCallback(async () => {
        const countersByCategories: IClassificationCountersByCategories[] = await fetchClassificationsAggregationByCategories(filterValues);
        setClassificationCounters(countersByCategories);
        setLoadingState(LoadingState.LOADING_SUCCEEDED);
    }, [filterValues]);

    const getLegendItem = useCallback((categoryInfo: IDataAssetCategoryInfo): ReactElement => {
        let categoryTotal = 0;
        classificationCounters.forEach(classificationCounter => {
            const categoryCounter: IDataAssetCategoryCounter | undefined = classificationCounter.categoryCounters.find(categoryCounter => categoryCounter.categoryInfo.id === categoryInfo.id);
            if (categoryCounter) {
                categoryTotal += categoryCounter.count;
            }
        });

        const onClick = categoryTotal > 0 ? () => {
            const filterFields: IFieldInfo[] = getDataClassificationFilterFields(undefined, categoryInfo, filterValues);
            getErmUrlsService().goToProtectedAssetsTable(filterFields);
        } : undefined;

        return (
            <Stack direction={'row'} spacing={2} alignItems={'center'} key={categoryInfo.id}>
                <ClassificationStyled.BulletDiv bgColor={getCategoryColor(categoryInfo)} />
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Typography>{categoryInfo.getTitle()}:</Typography>
                    <ClassificationStyled.LegendCounter isLink={!!onClick} onClick={onClick} variant={'body500'}>{categoryTotal}</ClassificationStyled.LegendCounter>
                </Stack>
            </Stack>
        );
    }, [classificationCounters, filterValues]);

    const getLegendBar = useCallback((): ReactElement => {
        return (
            <Stack direction={'row'} spacing={10} justifyContent={'center'} fullWidth>
                {getAllSortedCategories().map(classificationInfo => getLegendItem(classificationInfo))}
            </Stack>
        );
    }, [getLegendItem]);


    useEffect(() => {
        setLoadingState(LoadingState.IS_LOADING);
        if (filterValues) {
            void loadData();
        }
    }, [loadData, filterValues]);

    return (
        <Card fullWidth title={t('DATA_SECURITY_DASHBOARD.DATA_INVENTORY.CLASSIFICATION_PER_ASSET_CATEGORY')}>
            {loadingState === LoadingState.LOADING_FAILED &&
                <ClassificationStyled.TopDiv><FullSizeError /></ClassificationStyled.TopDiv>
            }
            {loadingState === LoadingState.IS_LOADING &&
                <ClassificationStyled.TopDiv><FullSizeSpinner /></ClassificationStyled.TopDiv>
            }
            {loadingState === LoadingState.LOADING_SUCCEEDED &&
                <ClassificationStyled.TopDiv padding={[2, 0, 0, 0]}>
                    { emptyEnvStateInfo?.isEmpty && <Alert>{t('DATA_SECURITY_DASHBOARD.DATA_INVENTORY.ENV_HAS_NO_SENSITIVITY_INFO', { count: emptyEnvStateInfo.environmentsCount })}</Alert> }
                    <Stack direction={'column'}>
                        <Stack direction={'row'}>
                            <ClassificationScoreBox {...getItem(DataClassificationsMap.PII)} />
                            <ClassificationScoreBox {...getItem(DataClassificationsMap.PCI)} />
                            <ClassificationScoreBox {...getItem(DataClassificationsMap.PHI)} />
                        </Stack>
                        <Stack direction={'row'}>
                            <ClassificationScoreBox {...getItem(DataClassificationsMap.Credentials)} />
                            <ClassificationScoreBox {...getItem(DataClassificationsMap.Other)} />
                        </Stack>
                    </Stack>
                    {!emptyEnvStateInfo?.isEmpty && getLegendBar()}
                </ClassificationStyled.TopDiv>
            }
        </Card>
    );
};
