import React, { useEffect } from 'react';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { Vendors } from 'common/consts/vendors';
import { IVendorRuleset, POLICY_RULESETS } from 'common/interface/policy';
import { useTranslation } from 'react-i18next';
import { i18nPolicyNamespace } from '../initialize.i18n';
import { getFixedVendorIcon } from 'common/components/policy/utils';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { MultiSelectListComponent } from 'common/components/policy/MultiSelectList';
import { TextIconVersionItem } from 'common/components/policy/MultiSelectList/ListItemTypes/TextIconVersionItem';

export interface PlatformRulesetSelectionProps{
    onValidate:Function;
    platform?: Vendors;
    selectedRulesetsChanged:Function;
    preSelectedItems?: ListItem[];
}
export const PlatformRulesetSelectionComponent: React.FC<PlatformRulesetSelectionProps> = ({ preSelectedItems, onValidate,platform,selectedRulesetsChanged }) => {
    const { t } = useTranslation(i18nPolicyNamespace);
    const[rulesets, setRulesets] = React.useState<ListItem[]|null>(null);

    const init = async () => {
        const vendorsRulesetList = globalAddinContainer.get<IVendorRuleset>(POLICY_RULESETS);
        const matchedVendorsRuleset = vendorsRulesetList.find((vendor) => vendor.vendorId === platform);
        const _rulesets = await matchedVendorsRuleset?.getRulesets();
        const items : ListItem[] = _rulesets?.map((ruleset) => {
            return {
                id: ruleset.id,
                name: ruleset.name || '',
                icon: getFixedVendorIcon(ruleset.icon),
                availableVersions: ruleset.availableVersions,
                versionEnabled: true,
            };
        }) || [];
        setRulesets(items);
    };
    useEffect(() => {
        init();
    }, []);


    const selectedItemsChanged = (selectedItems: ListItem[]) => {
        onValidate(selectedItems?.length);
        selectedRulesetsChanged(selectedItems);
        selectedItems.forEach((item) => {
            const match = rulesets?.find((_ruleset) => _ruleset.id === item.id);
            if(match) {
                match.selectedVersion = item.selectedVersion;
            }
        });
        rulesets && setRulesets([...rulesets]);
    };
    useEffect(() => {
        if(rulesets?.length && preSelectedItems?.length){
            preSelectedItems.forEach((preSelectedItem) => {
                const match = rulesets?.find((_ruleset) => _ruleset.id === preSelectedItem.id);
                if(match) {
                    match.selectedVersion = preSelectedItem.selectedVersion;
                }
            });
            setRulesets([...rulesets]);
        }
    }, [preSelectedItems,rulesets?.length]);

    return (
        <span data-aid={'rulesets-selection'}>
            <MultiSelectListComponent preSelectedItems={preSelectedItems} itemTemplate={ TextIconVersionItem } header={t('GENERAL.SELECT_RULESET')} items={rulesets} onSelectedItemsChanged={selectedItemsChanged}/>
        </span>
    );

};