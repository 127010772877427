import React from 'react';
import WelcomePage from 'common/components/WelcomePage/WelcomePage';
import { useTranslation } from 'react-i18next';
import { changeUrl } from 'common/utils/http';
import backgroundImage from '../../../assets/images/welcomePage/shiftLeft.svg';

export const ShiftLeftWelcomePage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const sections = [
        {
            title: t('SHIFTLEFT.WELCOME_PAGE.ENVIRONMENTS.TITLE'),
            subtitle: t('SHIFTLEFT.WELCOME_PAGE.ENVIRONMENTS.SUBTITLE'),
            items: [
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.ENVIRONMENTS.SHIFTLEFT.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.ENVIRONMENTS.SHIFTLEFT.SUBTITLE'),
                    icon: 'shiftleft',
                    onClick: () => {
                        changeUrl(
                            '/cloud-account/index?query=%7B"filter":%7B"freeTextPhrase":"","filterFields":%5B%7B"name":"resource-type","value":"shiftleft"%7D%5D%7D%7D',
                        );
                    },
                    cardButton: {
                        text: 'Add Environment',
                        icon: 'plus',
                        onClick: () => {
                            changeUrl('/cloud-add/shift-left');
                        },
                    },
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.ENVIRONMENTS.REGISTRIES.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.ENVIRONMENTS.REGISTRIES.SUBTITLE'),
                    icon: 'containers-registry',
                    onClick: () => {
                        changeUrl(
                            '/cloud-account/index?query=%7B"filter":%7B"freeTextPhrase":"","filterFields":%5B%7B"name":"organizationalUnitId"%7D,%7B"name":"resource-type","value":"containerregistry"%7D%5D%7D%7D',
                        );
                    },
                    cardButton: {
                        text: 'Add Environment',
                        icon: 'plus',
                        onClick: () => {
                            changeUrl('/workload/registry-onboarding');
                        },
                    },
                },
            ],
        },
        {
            title: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.TITLE'),
            subtitle: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.SUBTITLE'),
            items: [
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.IMAGES.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.IMAGES.SUBTITLE'),
                    icon: 'container-image',
                    linkTo: '/workload/images',
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.FINDINGS.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.FINDINGS.SUBTITLE'),
                    icon: 'find',
                    onClick: () => {
                        changeUrl(
                            '/posture-findings?query=%7B"filter":%7B"fields":%5B%7B"name":"origin","value":"7"%7D%5D,"freeTextPhrase":""%7D%7D',
                        );
                    },
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.ASSESSMENTS_HISTORY.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.ASSESSMENTS_HISTORY.SUBTITLE'),
                    icon: 'history',
                    linkTo: '/workload/history',
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.RULESETS.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.RULESETS.SUBTITLE'),
                    icon: 'sheet',
                    onClick: () => {
                        changeUrl('/workload/rulesets');
                    },
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.POLICIES.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.IMAGE_ASSURANCE.POLICIES.SUBTITLE'),
                    icon: 'book',
                    onClick: () => {
                        changeUrl('/workload/policies');
                    },
                },
            ],
        },
        {
            title: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.TITLE'),
            subtitle: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.SUBTITLE'),
            items: [
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.FINDINGS.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.FINDINGS.SUBTITLE'),
                    icon: 'find',
                    onClick: () => {
                        changeUrl(
                            '/posture-findings?query=%7B"filter":%7B"fields":%5B%7B"name":"origin","value":"9"%7D%5D,"freeTextPhrase":""%7D%7D',
                        );
                    },
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.ASSESSMENTS_HISTORY.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.ASSESSMENTS_HISTORY.SUBTITLE'),
                    icon: 'history',
                    onClick: () => {
                        changeUrl('/shiftleft/history?viewmode=IacAssurance');
                    },
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.RULESETS.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.RULESETS.SUBTITLE'),
                    icon: 'rule',
                    onClick: () => {
                        changeUrl(
                            '/shiftleft/rulesets?query=%7B"filter":%7B"freeTextPhrase":""%7D,"filterFields":%5B%7B"name":"platform","value":"terraform"%7D,%7B"name":"platform","value":"cft"%7D%5D%7D',
                        );
                    },
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.POLICIES.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.IAC_ASSURANCE.POLICIES.SUBTITLE'),
                    icon: 'book',
                    onClick: () => {
                        changeUrl('/shiftleft/policies?viewmode=IacAssurance');
                    },
                },
            ],
        },
        {
            title: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.TITLE'),
            subtitle: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.SUBTITLE'),
            items: [
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.FINDINGS.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.FINDINGS.SUBTITLE'),
                    icon: 'find',
                    onClick: () => {
                        changeUrl(
                            '/posture-findings?query=%7B"filter":%7B"fields":%5B%7B"name":"origin","value":"8"%7D%5D,"freeTextPhrase":""%7D%7D',
                        );
                    },
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.ASSESSMENTS_HISTORY.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.ASSESSMENTS_HISTORY.SUBTITLE'),
                    icon: 'history',
                    onClick: () => {
                        changeUrl('/shiftleft/history?viewmode=CodeAssurance');
                    },
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.RULESETS.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.RULESETS.SUBTITLE'),
                    icon: 'rule',
                    onClick: () => {
                        changeUrl(
                            '/shiftleft/rulesets?query=%7B"filter":%7B"freeTextPhrase":""%7D,"filterFields":%5B%7B"name":"platform","value":"sourcecodeassurance"%7D%5D%7D',
                        );
                    },
                },
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.POLICIES.TITLE'),
                    subtitle: t('SHIFTLEFT.WELCOME_PAGE.CODE_ASSURANCE.POLICIES.SUBTITLE'),
                    icon: 'book',
                    onClick: () => {
                        changeUrl('/shiftleft/policies?viewmode=CodeAssurance');
                    },
                },
            ],
        },
        {
            title: '',
            subtitle: '',
            items: [
                {
                    title: t('SHIFTLEFT.WELCOME_PAGE.SECTION4.INSTALLATION_INSTRUCTION.TITLE'),
                    subtitle: '',
                    icon: 'installation',
                    onClick: () => {
                        changeUrl('/shiftleft/onboarding-shiftleft');
                    },
                },
            ],
        },
    ];
    return (
        <WelcomePage
            title={t('SHIFTLEFT.WELCOME_PAGE.TITLE')}
            subtitle={t('SHIFTLEFT.WELCOME_PAGE.SUBTITLE')}
            sections={sections}
            backgroundImage={backgroundImage}
        />
    );
};

export default ShiftLeftWelcomePage;
