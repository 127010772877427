import WelcomePage from 'common/components/WelcomePage/WelcomePage';
import { useTranslation } from 'react-i18next';
import backgroundImage from '../../../assets/images/welcomePage/intelligence.svg';
import { IWelcomePageSection } from 'common/components/WelcomePage/WelcomePage.interface';
import { isAfEnabled } from 'common/utils/debugUtils';
import { NEW_NOTIFICATIONS_PAGE_AF } from 'common/module_interface/settings/integrations/Integrations';

const Welcome: React.FC = () => {
    const { t } = useTranslation();
    const PAGE_ID = 'INTELLIGENCE';
    const WELCOME_PAGE_LANG_PATH = `${PAGE_ID}.WELCOME_PAGE`;
    const SECTIONS_LANG_PATH = `${WELCOME_PAGE_LANG_PATH}.SECTIONS`;

    const sections: IWelcomePageSection[] = [
        {
            title: t(`${SECTIONS_LANG_PATH}.THREAT_INTELLIGENCE.TITLE`),
            subtitle: t(`${SECTIONS_LANG_PATH}.THREAT_INTELLIGENCE.SUBTITLE`),
            items: [
                {
                    title: t(`${SECTIONS_LANG_PATH}.THREAT_INTELLIGENCE.CARDS.INTELLIGENCE_STATUS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.THREAT_INTELLIGENCE.CARDS.INTELLIGENCE_STATUS.SUBTITLE`),
                    icon: 'checkCircle',
                    linkTo: '/?name=Home%20Dashboard',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.THREAT_INTELLIGENCE.CARDS.SECURITY_EVENTS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.THREAT_INTELLIGENCE.CARDS.SECURITY_EVENTS.SUBTITLE`),
                    icon: 'security-event',
                    linkTo: '/threat-and-security-events',
                },
            ],
        },
        {
            title: t(`${SECTIONS_LANG_PATH}.EXPLORATION.TITLE`),
            subtitle: t(`${SECTIONS_LANG_PATH}.EXPLORATION.SUBTITLE`),
            items: [
                {
                    title: t(`${SECTIONS_LANG_PATH}.EXPLORATION.CARDS.ACTIVITY_EXPLORER.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.EXPLORATION.CARDS.ACTIVITY_EXPLORER.SUBTITLE`),
                    icon: 'objectExplorer',
                    linkTo: '/magellan/clarity-cloudtrail',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.EXPLORATION.CARDS.TRAFFIC_EXPLORER.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.EXPLORATION.CARDS.TRAFFIC_EXPLORER.SUBTITLE`),
                    icon: 'traffic',
                    linkTo: '/magellan/clarity',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.EXPLORATION.CARDS.ACTIVITY_LOGS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.EXPLORATION.CARDS.ACTIVITY_LOGS.SUBTITLE`),
                    icon: 'log',
                    linkTo: '/magellan/cloudtrail',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.EXPLORATION.CARDS.TRAFFIC_LOGS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.EXPLORATION.CARDS.TRAFFIC_LOGS.SUBTITLE`),
                    icon: 'log',
                    linkTo: '/magellan/flowlogs',
                },
            ],
        },
        {
            title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.TITLE`),
            subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.SUBTITLE`),
            items: [
                {
                    title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_POLICIES.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_POLICIES.SUBTITLE`),
                    icon: 'book',
                    linkTo: '/magellan/rulesets-assignment',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_RULESETS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_RULESETS.SUBTITLE`),
                    icon: 'rule',
                    linkTo: '/magellan/rulesets',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.EXCLUSIONS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.EXCLUSIONS.SUBTITLE`),
                    icon: 'group-exclusion',
                    linkTo: '/magellan/exclusion',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.CONFIGURE_NOTIFICATIONS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.CONFIGURE_NOTIFICATIONS.SUBTITLE`),
                    icon: 'bell',
                    linkTo: isAfEnabled(NEW_NOTIFICATIONS_PAGE_AF) ? '/notifications-list' : '/notifications',
                },
            ],
        },
    ];

    return (
        <WelcomePage
            title={t(`${WELCOME_PAGE_LANG_PATH}.TITLE`)}
            subtitle={t(`${WELCOME_PAGE_LANG_PATH}.SUBTITLE`)}
            sections={sections}
            backgroundImage={backgroundImage}
        />
    );
};

export default Welcome;
