import { FC } from 'react';
import { getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';

export const SeverityRenderer: FC = (props: any) => {
    const severityBackgroundColor = { backgroundColor: getSafeFindingSeverityInfo(props.value).color };
    return (
        <div className='severity-wrapper'>
            <div style={severityBackgroundColor} className='line'></div>
            <div className='text'>{props.value}</div>
        </div>
    );
};
