import React from 'react';
import { AssetsProps, getIncludedEntityTypes } from './Assets.types';
import { AssetsStyled } from './Assets.styled';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import DynamicProtectedAssetsTable from 'common/components/ProtectedAssets/DynamicProtectedAssetsTable';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { defaultProtectedAssetColumns, defaultSortModel, getDefaultFilters } from '../../../Common/defaults';
import {
    ERM_PROTECTED_ASSET_FILTER_IDS,
} from '../../../../../../common/module_interface/RiskManagement/protectedAssets/filters.consts';

const Assets: React.FunctionComponent<AssetsProps> = ({ cloudAccountId }) => {

    const defaultFilters = React.useMemo<IFiltersValues>(() => {
        return getDefaultFilters(cloudAccountId);
    }, [cloudAccountId]);

    const filters = React.useMemo<Array<IProtectedAssetFilter>>(() => {
        return [
            { id: ERM_PROTECTED_ASSET_FILTER_IDS.riskScore },
            { id: ERM_PROTECTED_ASSET_FILTER_IDS.iamSensitivity },
            { id: 'type' },
            { id: 'region' },
            { id: 'network' },
            { id: 'runState' },
            { id: 'tag' },
            { id: 'publicIdAssociated' },
            { id: 'billableAsset' },
            { id: 'labels' },
            { id: 'serverlessRuntimeProtection' },
            { id: 'serverlessAutoProtect' },
            { id: 'serverlessProtectionMode' },
            { id: 'serverlessEnable' },
            { id: ERM_PROTECTED_ASSET_FILTER_IDS.businessPriority },
            { id: ERM_PROTECTED_ASSET_FILTER_IDS.cves },
        ];
    }, []);

    return (
        <AssetsStyled.AssetsWrapper>
            <DynamicProtectedAssetsTable
                defaultFilters={defaultFilters}
                columns={defaultProtectedAssetColumns}
                defaultSortModel={defaultSortModel}
                includedEntityTypes={getIncludedEntityTypes()}
                tableId='alibaba-assets-filter-panel-id'
                withFilterPanel={{ filters }}
            />
        </AssetsStyled.AssetsWrapper>
    );
};

export default Assets;
