import { UserRolesTypes } from 'common/enum/UserRoles';
import { Addin } from 'common/extensibility/AddinRegistry';
import { GenericObject } from 'common/interface/general';
import { IMenuSectionItem } from 'common/interface/menu';
import {
    getCustomizationService,
    getStoreService,
    ICustomzationObject,
} from 'common/interface/services';
import { IDashboard, IDashboardWidget } from 'common/module_interface/overview/Interface';
import { v4 } from 'uuid';
import {
    getAllDashboardsFromServer,
    getInnerDefaultDashboardFromServer,
    setInnerDefaultDashboardInServer,
} from './Api';
import { setAllDashboards, setSelectedDashboard } from './Overview.reducer';
import { DEFAULT_GLOBAL_HOME_PAGE_ID, DEFAULT_LOCAL_DASHBOARD_ID, } from 'common/module_interface/overview/Consts';
import createBrowserHistory from 'common/utils/history';
import { deepCloneObject } from 'common/utils/objectUtils';
import { changeUrl } from 'common/utils/http';
import { OverviewDashboardsRegistry } from 'common/module_interface/overview/OverviewDashboardsRegistry';

interface IItemSizes {
    width: number;
    height: number;
}

interface IItemPositions {
    x: number;
    y: number
}

const history = createBrowserHistory;


export const addIdsToSectionsAndWidgets = (allDashboardsFromServer: IDashboard[]) => {
    allDashboardsFromServer.forEach(dashboard => {
        dashboard.sections?.forEach(section => {
            if (!section.id) {
                section.id = v4();
            }
            section.widgets?.forEach((widget) => {
                const currentWidgetId = v4();
                if (!widget.id) {
                    widget.id = widget.options?.id || currentWidgetId;

                    if (!widget.options || typeof widget.options === 'string') {
                        widget.options = {
                            id: currentWidgetId
                        };
                    } else if (typeof widget.options === 'object') {
                        widget.options.id = widget.options?.id || currentWidgetId;
                    }
                }
            });
        });
    });
    return allDashboardsFromServer;
};

export const refreshAllDashboardsInStore = async () => {
    const { dispatch, state } = getStoreService().getReduxTools();
    const registeredLocalDashboards = state.overviewSlice.registeredLocalDashboards;
    const allDashboardsFromServer = await getAllDashboardsFromServer();
    const allDashboards = [...registeredLocalDashboards, ...allDashboardsFromServer];
    const allDashboardsCloned = deepCloneObject(allDashboards);
    // TODO: IDs for sections and widgets needs to be generated in BE 
    const allDashboardsClonedWithIds = addIdsToSectionsAndWidgets(allDashboardsCloned);

    return dispatch(setAllDashboards(allDashboardsClonedWithIds));
};

export const getDashboardsTree = (dashboardsAddins: Addin[]) => {

    const getItem = (addinItem: GenericObject<any>, key: string): any => {
        const item = {
            ...addinItem.content,
        };

        if (addinItem.children) {
            item[key] = addinItem.children.map((item: Addin) => getItem(item, 'widgets'));
        }
        return item;
    };

    return dashboardsAddins.map((addin) => {
        return getItem(addin, 'sections');
    });
};

export const getDynamicWidgetsPositions = (items: IItemSizes[], numberOfColumns: number) => {
    const createNewRow = () => new Array(numberOfColumns).fill(0);

    function placeItem(item: IItemSizes, grid: number[][]): { x: number, y: number } | null {
        for (let y = 0; y < grid.length; y++) {
            for (let x = 0; x < numberOfColumns; x++) {
                if (canFit(item, y, x, grid)) {
                    // item fits, so place it on the grid
                    for (let row = y; row < y + item.height; row++) {
                        if (!grid[row]) {
                            grid[row] = createNewRow(); // create a new row if necessary
                        }
                        for (let newItem = x; newItem < x + item.width; newItem++) {
                            grid[row][newItem] = 1; // mark cells as occupied by the item
                        }
                    }
                    return { x: x, y: y }; // return the position of the item on the grid
                }
            }
        }
        return null; // item doesn't fit on the grid
    }

    function canFit(item: IItemSizes, row: number, col: number, grid: number[][]): boolean {
        if (col + item.width > numberOfColumns) {
            return false; // item extends beyond the edge of the grid
        }
        for (let i = row; i < row + item.height; i++) {
            if (!grid[i]) {
                grid[i] = createNewRow(); // create a new row if necessary
            }
            for (let j = col; j < col + item.width; j++) {
                if (grid[i][j] !== 0) {
                    return false; // item overlaps with an existing item
                }
            }
        }
        return true; // item fits on the grid
    }

    const itemPositions: IItemPositions[] = [];
    const grid: number[][] = createNewRow();

    for (const item of items) {
        const itemPosition = placeItem(item, grid);
        if (itemPosition) {
            itemPositions.push(itemPosition);
        }
    }

    return itemPositions;
};

export const getDataIdByPath = (obj: IDashboardWidget, path: string): string | null => {
    if (path) {
        const pathAsArray = path.split('/');
        return pathAsArray.reduce((result, key) => {

            if (result && !(key in result)) {
                return null;
            }
            result = result && result[key];
            return result;
        }, obj as any);
    } else {
        return null;
    }
};

export function assignValueToObject(obj: Record<string, any>, path: string, value: any): void {
    const keys = path.split('/').filter(key => key.length > 0);
    const currentKey = keys[0];
    if (keys.length === 1) {
        obj[currentKey] = value;
        return;
    } else if (keys.length > 1) {
        keys.shift();
        assignValueToObject(obj[currentKey], keys.join('/'), value);
    }
}

export const createPinnedMenuItems = (allDashboards: IDashboard[], defaultDashboardId: string, favoriteDashboardsIds: string[]) => {
    const result: IMenuSectionItem[] = favoriteDashboardsIds.reduce((result: IMenuSectionItem[], favoriteItem) => {
        const selectedDashboard = allDashboards.find(dashboard => dashboard.id === favoriteItem);
        if (selectedDashboard && (selectedDashboard.id !== defaultDashboardId)) {
            const menuItem: IMenuSectionItem = {
                id: selectedDashboard.id,
                position: 0,
                label: selectedDashboard.name,
                state: `/?name=${encodeURIComponent(selectedDashboard.name)}`,
                permission: [UserRolesTypes.ALL],
            };
            result.push(menuItem);
        }
        return result;
    }, []);

    const selectedDefaultDashboard = allDashboards.find(dashboard => dashboard.id === defaultDashboardId);
    if (selectedDefaultDashboard) {
        const selectedDefaultDashboardMenuItem = {
            id: selectedDefaultDashboard.id,
            position: 0,
            label: selectedDefaultDashboard.name,
            state: `/?name=${encodeURIComponent(selectedDefaultDashboard.name)}`,
            permission: [UserRolesTypes.ALL],
        };
        result.unshift(selectedDefaultDashboardMenuItem);
    }
    const filteredResult = result.filter(item => item.id !== DEFAULT_LOCAL_DASHBOARD_ID);
    return filteredResult;

};

export const updateDashboardInStore = (dashboard: IDashboard) => {
    const { dispatch } = getStoreService().getReduxTools();

    dispatch(setSelectedDashboard(dashboard));
};

export const goToDashboardPage = ((dashboard: IDashboard) => {
    const { dispatch } = getStoreService().getReduxTools();
    history.push(`?name=${encodeURIComponent(dashboard.name)}`);
    dispatch(setSelectedDashboard(dashboard));
});

export function sortWidgetsByView(widgets: any) {
    return widgets?.sort((a: any, b: any) => {
        if (a.options?.sizes && b.options?.sizes) {
            const yDiff = a.options.sizes.y - b.options.sizes.y;
            if (yDiff !== 0) {
                return yDiff;
            }
            return a.options.sizes.x - b.options.sizes.x;
        } else {
            return widgets;
        }
    });
}

export const goToDefaultGlobalHomePage = (() => {
    const route = OverviewDashboardsRegistry.getDefaultGlobalDashboardRoute();
    if (!route) {
        console.error('Global default dashboard was not defined');
        return;
    }
    changeUrl(route);
});

const DEFAULT_DASHBOARD_SETUP_TIME_CUSTOM_KEY = 'DEFAULT_DASHBOARD_SETUP_TIME_CUSTOM_KEY';
const getLastSetupTime = async (): Promise<string | undefined> => {
    return getCustomizationService().getKeyData<string>(DEFAULT_DASHBOARD_SETUP_TIME_CUSTOM_KEY);
};
const saveCurrentSetupTime = async (): Promise<void> => {
    const nowStr = String((new Date()).getTime());
    void getCustomizationService().setKeyData<string>(DEFAULT_DASHBOARD_SETUP_TIME_CUSTOM_KEY, nowStr);
};

async function innerSetupDefaultDashboard(): Promise<void> {
    const prevSetupTime: string | undefined = await getLastSetupTime();
    if (prevSetupTime) {
        return;
    }

    const defaultDashboardInServer: ICustomzationObject<string> | undefined = await getInnerDefaultDashboardFromServer();
    if (!defaultDashboardInServer || (defaultDashboardInServer.data === DEFAULT_LOCAL_DASHBOARD_ID)) {
        await setInnerDefaultDashboardInServer(DEFAULT_GLOBAL_HOME_PAGE_ID);
    }
    await saveCurrentSetupTime();
}

let defaultDashboardSetupIsDone = false;
export const isDefaultDashboardSetupDone = () => {
    return defaultDashboardSetupIsDone;
};
let setupDefaultDashboardPromise: Promise<void> | undefined;

export async function setupDefaultDashboard() {
    if (defaultDashboardSetupIsDone) {
        return;
    }
    if (!setupDefaultDashboardPromise) {
        setupDefaultDashboardPromise = innerSetupDefaultDashboard();
    }
    try {
        await setupDefaultDashboardPromise;
        defaultDashboardSetupIsDone = true;
    } catch (error) {
        console.error('Failed doing setup for default dashboard', error);
        setupDefaultDashboardPromise = undefined;
        defaultDashboardSetupIsDone = false;
    }
}

export async function resetDashboardSetup() {
    await getCustomizationService().deleteKeyData(DEFAULT_DASHBOARD_SETUP_TIME_CUSTOM_KEY);
    await setInnerDefaultDashboardInServer(DEFAULT_LOCAL_DASHBOARD_ID);
}
