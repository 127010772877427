import { createSelector } from '@reduxjs/toolkit';
import { AzureState } from './azure.interface';
import { RootState } from 'common/services/store/store';

const azureReducer = (state: RootState) => state.env_azure as AzureState;

const getAzure = createSelector([azureReducer, (_state: RootState, id: string) => id], (state, id) => state.azureEvnironments[id]);

export const AzureSelector = {
    getAzure,
};