import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { IIssueOrGroup } from '../Issues.interface';
import { getVendorDisplayName } from 'common/consts/vendors';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import i18next from 'i18next';

export const IssueEntityTypeCellRenderer: React.FC<ICellRendererParams<IIssueOrGroup>> = (params) => {
    const issue: IIssueOrGroup | undefined = params.data;
    if (!issue?.entityTypeByPlatform) {
        if (issue?.isGrouped) {
            return '';
        }

        return i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.UNKNOWN');
    }
    const iAsset = getProtectedAssetsService().getAssetByType(issue.entityTypeByPlatform);
    if (!iAsset) {
        const platformDisplayName = issue.platform ? getVendorDisplayName(issue.platform) : '';
        return platformDisplayName ? `${platformDisplayName} ${issue.entityType}` : issue.entityType;
    }
    const displayName = iAsset.displayName || iAsset.type;
    const options: IIconLinkCellProps = {
        isLink: false,
        value: displayName,
    };

    CellToClipboardHandler(params, displayName);
    return <IconLinkCellRenderer {...options} />;
};
