import {
    IFullReportModel,
    IntelligenceRulesetDTO,
    IRemoveReportRequest,
    IReportFilterConfigurationsModel,
    IReportsAggregation,
    IReportSaveItem,
    IReportsRequest,
    IReportsResponse,
    ISendImmediatelyRequest,
    RulesetDTO,
} from '../interface/Reporting.interface';
import { getHttpService, getStoreService, ICachingConfig } from 'common/interface/services';

const getCacheConfig = (dataAgeLimit: number): ICachingConfig => {
    return {
        useCache: true,
        dataAgeLimit,
    };
};

export const MODELS_CACHE_CONFIG: ICachingConfig = getCacheConfig(30);

export const getIntelligenceRulesetsFromServer = async (): Promise<
    IntelligenceRulesetDTO[]
> => {
    const state = getStoreService().state;
    const magellanBaseUrl = state.app.magellanUrl;
    return await getHttpService().get(
        `${magellanBaseUrl}/rulesets/all-metadata`,
        {}
    );
};

export const getRulesetsFromServer = async (): Promise<RulesetDTO[]> => {
    return await getHttpService().get('Compliance/Ruleset/view', {});
};

export const getReports = async (data: IReportsRequest): Promise<IFullReportModel[]> => {
    const response: IReportsResponse = await getHttpService().post('rptws/reports/search', {
        data,
    });
    return response?.data ?? [];
};

export const getReportsModels = async (): Promise<IReportFilterConfigurationsModel[]> => {
    return await getHttpService().get(
        'rptws/reports/models',
        {},
        { cachingConfig: MODELS_CACHE_CONFIG }
    );
};


export const createReport = async (data: IReportSaveItem): Promise<string> => {
    return await getHttpService().post('rptws/reports', {
        data,
    });
};

export const removeReport = async (data: IRemoveReportRequest): Promise<string> => {
    return await getHttpService().delete('rptws/reports', {
        data,
    });
};

export const updateReport = async (data: IReportSaveItem): Promise<string> => {
    return await getHttpService().put(`rptws/reports/${data.reportId}`, {
        data,
    });
};

export const sendImmediateReports = async (data: ISendImmediatelyRequest): Promise<string> => {
    return await getHttpService().post('rptws/reports/sendImmediateReports', {
        data,
    });
};

export const getLastReportPdfContent = async (reportId: number): Promise<Blob> => {
    const pdfContent = await getHttpService().get(`rptws/reports/${reportId}/getLastReport`, { responseType: 'arraybuffer' });
    return new Blob([pdfContent as BlobPart], { type: 'application/pdf' });
};

export const getReportsAggregations = async (data: IReportsRequest): Promise<IReportsAggregation[]> => {
    const response: IReportsResponse = await getHttpService().post('rptws/reports/search', {
        data,
    });
    return response?.aggregations ?? [];
};
