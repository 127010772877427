import styled from 'styled-components';

const GcpSecurityCommandCenterStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GcpSecurityCommandCenterNameWrapper = styled.div`
  width: 50%;
  margin: 24px ;
`;

const CloudAccountSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 24px;
`;

const EnableCloudWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0 0 40px ;
`;

const GcpSecurityCommandCenterSourceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 24px 24px 0 24px;
`;

const GcpCloudSecuritySource = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export {
    GcpSecurityCommandCenterStyled,
    GcpSecurityCommandCenterNameWrapper,
    CloudAccountSelectionWrapper,
    EnableCloudWrapper,
    GcpSecurityCommandCenterSourceWrapper,
    GcpCloudSecuritySource
};