import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SepDiv = styled.div`
  display: flex;
  width: 100%;
  height: 20px;
  position: relative;
`;

const SepLine = styled.div`
  display: flex;
  height: 1px;
  border-bottom: 1px solid #D2D9E4;
  position: absolute;
  top: 20px;
  left: -24px;
  right: -24px;
`;

export const BaseStyled = {
    TopDiv,
    SepDiv,
    SepLine,
};
