import { CpCommonButton, CpPopover, CpTextBox, CpLoadingDots } from '@dome9/components/react/components';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import { FormFieldModel } from '../../../common/interface/formFieldModel';
import FormValidations from '../helpers/FormValidations';
import PasswordValidationHelper from '../helpers/passwordValidationHelper';
import { useTranslation } from 'react-i18next';
import { getHttpService } from '../../../common/interface/services';
import { logoutAndRedirectToLogin } from '../../user/User.actions';

export const SignupForm: React.FC<{}> = () => {
    const [email, setEmail] = useState<FormFieldModel>({ value: '' });
    const [firstName, setFirstName] = useState<FormFieldModel>({ value: '' });
    const [lastName, setLastName] = useState<FormFieldModel>({ value: '' });
    const [password, setPasswordValue] = useState<FormFieldModel>({ value: '' });
    const [confirmPassword, setConfirmPassword] = useState<FormFieldModel>({ value: '' });
    const [companyName, setCompanyName] = useState<FormFieldModel>({ value: '' });
    const [phoneNumber, setPhoneNumber] = useState<FormFieldModel>({ value: '' });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [signupErrorMessage, setSignupErrorMessage] = useState<string>('');
    const [registrationRequestId, setRegistrationRequestId] = useState<string>('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const searchString = useLocation().search;

    const createAnchorEl = (event: React.MouseEvent<HTMLElement> | null) => {
        if (event) {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const useStyles = createUseStyles({
        signupForm: {
            flex: 2,
            padding: '3.2rem',
            position: 'relative',
            '&  .cpTextBox': {
                marginBottom: '20px',
            },
            '& .title': {
                'margin-bottom': '1rem',
                'text-transform': 'uppercase',
                color: '#777',
                'font-weight': 100,
                'font-size': '2em',
            },
            '& form': {
                height: '100%',
                display: 'flex',
                'flex-direction': 'column',

                '& .footer': {
                    'font-size': '12px',
                    'align-self': 'end',
                },
                '& .registerButton': {
                    'align-self': 'flex-end',
                    'margin-top': '10px',
                },
            },
            '& .errorMessage': {
                color: 'red',
            },
        },
    });


    useEffect(() => {
        const handleToken = async () => {
            const queryParams = new URLSearchParams(searchString);
            const token = queryParams.get('token');
            setIsLoading(true);
            try {
                if (token) {
                    const httpService = getHttpService();
                    const response = await httpService.request<{ email: string; referrer: string | null }>(
                        `account-registration/verify/${token}`,
                        { method: 'GET' },
                        { publicMode: true }
                    );

                    setEmail({ value: response.email });
                    setRegistrationRequestId(token);
                }
            } catch (err: any) {
                if (err.response?.status === 404) {
                    logoutAndRedirectToLogin();
                    return;
                }
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        handleToken();
    }, [searchString]);

    const validateForm = () => {
        const emailValid = FormValidations.validateEmail(email.value, setEmail);
        const firstNameValid = FormValidations.validateFirstName(firstName.value, setFirstName);
        const lastNameValid = FormValidations.validateLastName(lastName.value, setLastName);
        const passwordValid = FormValidations.validatePassword(password.value, setPasswordValue);
        const confirmPasswordValid = FormValidations.validateConfirmPassword(
            confirmPassword.value,
            password.value,
            setConfirmPassword
        );
        const companyNameValid = FormValidations.validateCompanyName(companyName.value, setCompanyName);
        const phoneNumberValid = FormValidations.validatePhoneNumber(phoneNumber.value, setPhoneNumber);
        return (
            emailValid &&
            lastNameValid &&
            firstNameValid &&
            passwordValid &&
            confirmPasswordValid &&
            companyNameValid &&
            phoneNumberValid
        );
    };

    const handleSubmitRequest = async () => {
        if (validateForm()) {
            setIsLoading(true);
            const body = {
                registrationRequestId,
                email: email.value,
                firstName: firstName.value,
                lastName: lastName.value,
                password: password.value,
                accountName: companyName.value,
                PhoneNum: phoneNumber.value,
            };
            try {
                await getHttpService().post<any>('account-registration/sign-up', { data: body }, { publicMode: true });
                logoutAndRedirectToLogin();
            } catch (e: any) {
                setSignupErrorMessage(e.toString());
            } finally {
                setIsLoading(false);
            }
        }
    };

    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.signupForm}>
            <h2 className='title'>{t('FORMS.SIGNUP_FORM.TITLE')}</h2>
            <form>
                {isLoading && <CpLoadingDots append-to-body={true}></CpLoadingDots>}
                <CpTextBox
                    className='cpTextBox'
                    onCpChange={(ev: any) => setEmail({ value: ev.target.value })}
                    value={email.value}
                    label={t('FORMS.SIGNUP_FORM.EMAIL')}
                    helperText={email.errorMessage}
                    state={email.state}
                    readonly
                    type={'email'}
                />
                <CpTextBox
                    className='cpTextBox'
                    onCpChange={(ev: any) => setFirstName({ value: ev.target.value })}
                    value={firstName.value}
                    helperText={firstName.errorMessage}
                    state={firstName.state}
                    label={t('FORMS.SIGNUP_FORM.FIRST_NAME')}
                />

                <CpTextBox
                    className='cpTextBox'
                    onCpChange={(ev: any) => setLastName({ value: ev.target.value })}
                    value={lastName.value}
                    helperText={lastName.errorMessage}
                    state={lastName.state}
                    label={t('FORMS.SIGNUP_FORM.LAST_NAME')}
                />

                <CpTextBox
                    className='cpTextBox'
                    onCpChange={(ev: any) => {
                        setPasswordValue({ value: ev.target.value });
                    }}
                    value={password.value}
                    helperText={password.errorMessage}
                    state={password.state}
                    type={'password'}
                    label={t('FORMS.SIGNUP_FORM.PASSWORD')}
                    onFocus={(ev: any) => {
                        createAnchorEl(ev);
                    }}
                    onBlur={() => {
                        createAnchorEl(null);
                    }}
                />

                <CpPopover anchor={anchorEl} bottom={true} start={true} open={Boolean(anchorEl)}>
                    <PasswordValidationHelper passwordString={password.value} />
                </CpPopover>

                <CpTextBox
                    className='cpTextBox'
                    onCpChange={(ev: any) => setConfirmPassword({ value: ev.target.value })}
                    value={confirmPassword.value}
                    helperText={confirmPassword.errorMessage}
                    state={confirmPassword.state}
                    type={'password'}
                    label={t('FORMS.SIGNUP_FORM.CONFIRM_PASSWORD')}
                />
                <CpTextBox
                    className='cpTextBox'
                    onCpChange={(ev: any) => setCompanyName({ value: ev.target.value })}
                    value={companyName.value}
                    helperText={companyName.errorMessage}
                    state={companyName.state}
                    label={t('FORMS.SIGNUP_FORM.COMPANY_NAME')}
                />

                <CpTextBox
                    className='cpTextBox'
                    onCpChange={(ev: any) => setPhoneNumber({ value: ev.target.value })}
                    value={phoneNumber.value}
                    helperText={phoneNumber.errorMessage}
                    state={phoneNumber.state}
                    type={'tel'}
                    label={t('FORMS.SIGNUP_FORM.PHONE_NUMBER')}
                />

                <span className='footer'>
                    <span>{t('FORMS.SIGNUP_FORM.FOOTER1')}</span>
                    <span>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://www.checkpoint.com/about-us/cloud-terms/'>
                            {t('FORMS.SIGNUP_FORM.FOOTER_LINK')}
                        </a>
                    </span>
                </span>
                <CpCommonButton onClick={handleSubmitRequest} className='registerButton'>
                    {t('FORMS.SIGNUP_FORM.REGISTER_BUTTON')}
                </CpCommonButton>
                <span className='signup-form errorMessage'>{signupErrorMessage}</span>
            </form>
        </div>
    );
};

export default SignupForm;
