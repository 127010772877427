import { initLogin } from 'platform/user/User.actions';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import EmailAndPassword from './EmailAndPassword';
import { getMfaRequired } from './LoginForm.reducer';
import './LoginForm.scss';
import { LoginMfa } from './LoginMfa';
import { useTranslation } from 'react-i18next';

export const LoginForm: React.FC<{}> = () => {
    const isMfaRequired = useSelector(getMfaRequired);

    useEffect(() => {
        initLogin();
    }, []);
    const { t } = useTranslation();
    return (
        <div className='login-form flex-column'>
            <h1 className='login-form__title text-xl'>{t('FORMS.LOGIN_FORM.TITLE')}</h1>
            {isMfaRequired ? <LoginMfa /> : <EmailAndPassword />}
        </div>
    );
};

export default LoginForm;
