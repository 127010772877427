import { CpButton, CpIcon, CpTooltip } from '@dome9/components/react/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckedCorner } from './Components';
import { AwsOnboardingAccountType, AwsOnboardingType, WelcomeCardsProps } from '../helpers/aws.interface';

const WelcomeCards: FC<WelcomeCardsProps> = ({ onCardSelected, awsOnboardingType, awsOnboardingAccountType })=>{
    const { t } = useTranslation();

    return <div>
        <div className='flex-row select-path-header'>
            <span className='sub-header'>{t('ON_BOARDING.WELCOME.SELECT_ONBOARDING_PATH')}</span>
            <CpTooltip className='tooltip' text={t('ON_BOARDING.WELCOME.SELECT_ONBOARDING_PATH_TOOLTIP')}>
                <CpIcon className='aws-onboarding-welcome-info-content icon' icon='info' />
            </CpTooltip>
        </div>
        <div className='aws-onboarding-welcome-cards'>
            <div
                onClick={() => onCardSelected(AwsOnboardingType.simple)}
                className={
                    'left aws-onboarding-welcome-card ' +
                    (awsOnboardingType === AwsOnboardingType.simple ? 'selected' : '')
                }>
                <div
                    className={
                        'aws-onboarding-welcome-card-wrapper ' +
                        (awsOnboardingType === AwsOnboardingType.simple ? 'selected' : '')
                    }>
                    {awsOnboardingType === AwsOnboardingType.simple && <CheckedCorner />}
                    <div
                        className={
                            'aws-onboarding-welcome-card-header ' +
                            (awsOnboardingType === AwsOnboardingType.simple ? 'selected' : '')
                        }>
                        <div className='aws-onboarding-welcome-card-header-title title-large text-body-xtrabold'>
                            {t('ON_BOARDING.WELCOME.ONE_CLICK').toUpperCase()}
                        </div>
                        <div className='aws-onboarding-welcome-card-header-description title'>
                            {t('ON_BOARDING.WELCOME.ONE_CLICK_SUBTITLE')}
                        </div>
                    </div>
                    <div className='aws-onboarding-welcome-card-whats-included title'>
                        {t('ON_BOARDING.WELCOME.WHATS_INCLUDED')}
                    </div>
                    <ul className='aws-onboarding-welcome-card-list text-body list-disc list-inside ml-9 '>
                        <li className='aws-onboarding-welcome-card-list-item'>
                            {t('ON_BOARDING.WELCOME.INVENTORY')}
                        </li>
                        <li className='aws-onboarding-welcome-card-list-item'>
                            {t('ON_BOARDING.WELCOME.POSTURE_MANAGEMENT')}
                        </li>
                        {awsOnboardingAccountType === AwsOnboardingAccountType.aws ? (
                            <li className='aws-onboarding-welcome-card-list-item'>
                                {t('ON_BOARDING.WELCOME.CLOUD_INTELLIGENCE')}
                            </li>
                        ) : (
                            ''
                        )}
                        <li className='aws-onboarding-welcome-card-list-item'>
                            {t('ON_BOARDING.WELCOME.NETWORK_SECURITY')}
                        </li>
                        {awsOnboardingAccountType === AwsOnboardingAccountType.aws ? (
                            <li className='aws-onboarding-welcome-card-list-item'>
                                {t('ON_BOARDING.WELCOME.SERVERLESS_PROTECTION')}
                            </li>
                        ) : (
                            ''
                        )}
                    </ul>
                    <span className='aws-onboarding-welcome-card-selection'>
                        {awsOnboardingType === AwsOnboardingType.simple ? (
                            <span>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.ACTIVE')}</span>
                        ) : (
                            <CpButton>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.SELECT')}</CpButton>
                        )}
                    </span>
                </div>
            </div>
            <div
                onClick={() => onCardSelected(AwsOnboardingType.advanced)}
                className={
                    'right aws-onboarding-welcome-card ' +
                    (awsOnboardingType === AwsOnboardingType.advanced ? 'selected' : '')
                }>
                <div
                    className={
                        'aws-onboarding-welcome-card-wrapper ' +
                        (awsOnboardingType === AwsOnboardingType.advanced ? 'selected' : '')
                    }>
                    {awsOnboardingType === AwsOnboardingType.advanced && <CheckedCorner />}
                    <div
                        className={
                            'aws-onboarding-welcome-card-header ' +
                            (awsOnboardingType === AwsOnboardingType.advanced ? 'selected' : '')
                        }>
                        <div className='aws-onboarding-welcome-card-header-title title-large text-body-xtrabold'>
                            {t('ON_BOARDING.WELCOME.ADVANCED').toUpperCase()}
                        </div>
                        <div className='aws-onboarding-welcome-card-header-description title'>
                            {t('ON_BOARDING.WELCOME.ADVANCED_SUBTITLE')}
                        </div>
                    </div>
                    <div className='aws-onboarding-welcome-card-whats-included title'>
                        {t('ON_BOARDING.WELCOME.WHATS_INCLUDED')}
                    </div>
                    <ul className='aws-onboarding-welcome-card-list text-body list-disc list-inside ml-9'>
                        <li className='aws-onboarding-welcome-card-list-item'>
                            {t('ON_BOARDING.WELCOME.FEATURE_SELECTION')}
                        </li>
                        <li className='aws-onboarding-welcome-card-list-item'>
                            {t('ON_BOARDING.WELCOME.SIMPLE_CONFIGURATION')}
                        </li>
                        <li className='aws-onboarding-welcome-card-list-item'>
                            {t('ON_BOARDING.WELCOME.NOTIFICATIONS_SETUP')}
                        </li>
                        <li className='aws-onboarding-welcome-card-list-item'>
                            {t('ON_BOARDING.WELCOME.POLICY_SETUP')}
                        </li>
                    </ul>
                    <span className='aws-onboarding-welcome-card-selection'>
                        {awsOnboardingType === AwsOnboardingType.advanced ? (
                            <span>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.ACTIVE')}</span>
                        ) : (
                            <CpButton>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.SELECT')}</CpButton>
                        )}
                    </span>
                </div>
            </div>
        </div>
    </div>;
};

export default WelcomeCards;