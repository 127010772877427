import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { getOrganizationalUnitService } from 'common/interface/data_services';
import { setOrganizationalUnitId } from './OciOnboarding.reducer';
import { getStoreService } from 'common/interface/services';
import { flattenTree } from 'common/utils/helpFunctions';


const OciOnboardingOU: React.FC<any> = ({ onValidate }) => {
    const { t } = useTranslation();
    const dispatch = getStoreService().dispatch;
    const rootOUId = '00000000-0000-0000-0000-000000000000';
    const [ouTree, setOuTree] = useState<any[]>([]);

    const onRowClicked = (row: any)=>{
        onValidate(true);
        row.node.setSelected(true);
        const ouId = row.data? row.data.id : rootOUId;
        dispatch(setOrganizationalUnitId(ouId));
    };

    useEffect(()=>{
        const getAllOU = async ()=>{
            const res = await getOrganizationalUnitService().getAllOrganizationalUnits();
            const flatTree = flattenTree(res[0]);
            setOuTree(flatTree);
        };
        getAllOU();
    }, []);

    const getDataPath = (data: any) => {
        return data.path;
    };

    return (
        <div>
            <div className='title-large'>{t('ON_BOARDING.OCI.OU_TITLE')}</div>
            <div className='mb-8 mt-8'>
                {t('ON_BOARDING.OCI.OU_TEXT')}
            </div>
            <AgGridReact
                domLayout='autoHeight'
                rowHeight={28}
                treeData={true}
                rowData={ouTree}
                rowSelection='single'
                onRowClicked={onRowClicked}
                getDataPath={getDataPath}
                columnDefs={[]}
                defaultColDef={{ flex: 1 }}
                animateRows={true}
                className='ag-theme-alpine ag-tree-data ag-header-none border w-6/12'
                groupDefaultExpanded={1}
                headerHeight={0}
                suppressContextMenu={true}
                autoGroupColumnDef={{
                    cellRendererParams: {
                        suppressCount: true,
                    },
                }}/>
        </div>
    );
};

export default OciOnboardingOU;
