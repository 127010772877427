import { Addin } from 'common/extensibility/AddinRegistry';
import { EventsDrawerAddingBase, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import { Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import OverviewTab from '../Tabs/OverviewTab';

const drawerTabs: () => Addin<EventsDrawerAddingBase<Tab>>[] = () => {
    return [
        {
            id: 'overview',
            position: 0,
            content: {
                id: 'overview',
                getValue: event => ({
                    name: 'overview',
                    label: 'Overview',
                    isReactTab: true,
                    content: (
                        <OverviewTab event={event} />
                    )
                }),
                isRelevant: event => event.origin !== IFindingModelOriginString.CIEM,
            }
        },
        {
            id: 'overview-ciem',
            position: 0,
            content: {
                id: 'overview-ciem',
                getValue: () => ({
                    name: 'overview-ciem',
                    label: 'Overview',
                    isReactTab: false,
                }),
                isRelevant: event => event.origin === IFindingModelOriginString.CIEM,
            }
        },
        {
            id: 'cve',
            position: 10,
            content: {
                id: 'cve',
                getValue: () => ({
                    name: 'cve',
                    label: 'CVE',
                    isReactTab: false,
                }),
                isRelevant: event => event.category === 'Package',
            }
        },
        {
            id: 'vulnerability',
            position: 20,
            content: {
                id: 'vulnerability',
                getValue: () => ({
                    name: 'vulnerabilities',
                    label: 'Vulnerability',
                    isReactTab: false,
                }),
                isRelevant: event => {
                    const isVulnDetection = event.origin === IFindingModelOriginString.ComplianceEngine && ['AWS Vulnerabilities Detection', 'Azure Vulnerabilities Detection'].includes(event.bundleName || '');
                    const isImageAssuranceScan = event.origin === IFindingModelOriginString.ImageAssurance && (event.category === 'ImageScan' || event.category === 'ScanSummary');
                    return isVulnDetection || isImageAssuranceScan;
                },
            }
        },
        {
            id: 'entityViewer',
            position: 30,
            content: {
                id: 'entityViewer',
                getValue: () => ({
                    name: 'entityViewer',
                    label: 'Entity Viewer',
                    isReactTab: false,
                }),
                isRelevant: event => Object.keys(event.entityObject || {}).length > 0,
            }
        },
        {
            id: 'permissions',
            position: 40,
            content: {
                id: 'permissions',
                getValue: () => ({
                    name: 'permissions',
                    label: 'Permissions',
                    isReactTab: false,
                }),
                isRelevant: event => event.origin === IFindingModelOriginString.CIEM,
            }
        },
        {
            id: 'occurrences',
            position: 50,
            content: {
                id: 'occurrences',
                getValue: () => ({
                    name: 'occurrences',
                    label: 'Occurrences',
                    isReactTab: false,
                }),
                isRelevant: event => !!(event?.occurrences && event?.occurrences?.length > 1),
            }
        },
        {
            id: 'remediation',
            position: 60,
            content: {
                id: 'remediation',
                getValue: () => ({
                    name: 'remediation',
                    label: 'Remediation',
                    isReactTab: false,
                }),
                isRelevant: async (event) => {
                    if (!event.findingKey) return false;
                    const gslResponse = await getIntelligenceService().getGSLRunData({
                        gsl: `remediation where findingKey='${event.findingKey}' select event_time, remediation, status, params`,
                    });
                    return !!gslResponse.data.length;
                }
            }
        },
    ];
};

export default function initializeOverviewTabs() {
    EventsDrawerRegistry.addTabs(drawerTabs());
}