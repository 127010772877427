import CreatedTimeCellRender from 'common/components/ag-grid/Renderers/CreatedTimeCellRender';
import EnvironmentCellRender from 'common/components/ProtectedAssets/Renderers/EnvironmentCellRender';
import EntityTypeCellRenderer from 'common/components/ag-grid/Renderers/EntityTypeCellRenderer';
import EventTitleCellRenderer from '../CellRenderers/EventTitleCellRenderer';
import EntityNameCellRender from 'common/components/ag-grid/Renderers/EntityNameCellRender';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { MitreCellRender } from 'modules/Intelligence/Findings/FindingsTable/CellRenderers/MitreCellRenderer';
import { FindingsTableRegistry } from 'common/module_interface/intelligence/Findings/FindingsTableRegistry';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { FindingColumns } from '../../Findings.const';
import FindingSeverityCellRender from 'common/components/ag-grid/Renderers/FindingSeverityCellRender';
import TooltipCellRenderer from '../CellRenderers/TooltipCellRenderer';
import StatusCellRenderer from '../CellRenderers/StatusCellRenderer';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { PROTECTED_ASSETS_URL } from 'common/module_interface/assets/ProtectedAssets.consts';
import ActionsCellRenderer from '../CellRenderers/ActionsCellRenderer';
import { SELECTION_GRID_COLUMN } from 'common/utils/tableUtils';
import { TFunction } from 'i18next';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';
import { ValueGetterParams } from 'ag-grid-enterprise';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { SortDirection } from 'common/interface/general';
import AssigneeCellRenderer from '../CellRenderers/AssigneeCellRenderer';

function getColumnDefs(): CGColDef[] {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return [
        SELECTION_GRID_COLUMN,
        {
            colId: FindingFields.ruleName,
            field: FindingFields.ruleName,
            headerName: t('FINDINGS_TABLE.COLUMNS.RULE_NAME.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.RULE_NAME.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.ruleName;
            },
            cellRenderer: EventTitleCellRenderer,
            enableRowGroup: true,
            sortable: true,
            suppressColumnsToolPanel: true,
            flex: 9,
        },
        {
            colId: FindingFields.severityLevel,
            field: FindingFields.severityLevel,
            headerName: t('FINDINGS_TABLE.COLUMNS.SEVERITY.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.SEVERITY.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.severityLevel;
            },
            cellRenderer: FindingSeverityCellRender,
            enableRowGroup: true,
            sortable: true,
            flex: 6,
            groupOrder: SortDirection.DESC,
        },
        {
            colId: FindingFields.eventTime,
            field: FindingFields.eventTime,
            headerName: t('FINDINGS_TABLE.COLUMNS.CREATED_TIME.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.CREATED_TIME.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.eventTime;
            },
            cellRenderer: CreatedTimeCellRender,
            sortable: true,
            initialSort: 'desc',
            flex: 9,
        },
        {
            colId: FindingFields.cloudAccountId,
            field: FindingFields.cloudAccountId,
            headerName: t('FINDINGS_TABLE.COLUMNS.ENVIRONMENT.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ENVIRONMENT.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.cloudAccountId;
            },
            cellRenderer: EnvironmentCellRender,
            enableRowGroup: true,
            sortable: true,
            flex: 8,
        },
        {
            colId: FindingFields.ruleDescription,
            field: FindingFields.ruleDescription,
            headerName: t('FINDINGS_TABLE.COLUMNS.DESCRIPTION.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.DESCRIPTION.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.ruleDescription;
            },
            cellRenderer: TooltipCellRenderer,
            flex: 8,
        },
        {
            colId: FindingFields.rulesetName,
            field: FindingFields.rulesetName,
            headerName: t('FINDINGS_TABLE.COLUMNS.RULESET_NAME.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.RULESET_NAME.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.rulesetName;
            },
            cellRenderer: TooltipCellRenderer,
            enableRowGroup: true,
            sortable: true,
            flex: 6,
        },
        {
            colId: FindingFields.entityName,
            field: FindingFields.entityName,
            headerName: t('FINDINGS_TABLE.COLUMNS.ENTITY.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ENTITY.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.entityName;
            },
            cellRenderer: EntityNameCellRender,
            cellRendererParams: { componentName: EMPTY_STRING, baseUrl: PROTECTED_ASSETS_URL },
            enableRowGroup: true,
            sortable: true,
            flex: 5,
        },
        {
            colId: FindingFields.entityType,
            field: FindingFields.entityType,
            headerName: t('FINDINGS_TABLE.COLUMNS.ENTITY_TYPE.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ENTITY_TYPE.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.entityType;
            },
            cellRenderer: EntityTypeCellRenderer,
            enableRowGroup: true,
            sortable: true,
            flex: 6,
        },
        {
            colId: FindingFields.mitreDict,
            field: FindingFields.mitreDict,
            headerName: t('FINDINGS_TABLE.COLUMNS.MITRE.HEADER'),
            tooltipField: FindingFields.mitreDict,
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.MITRE.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.mitreDict;
            },
            cellRenderer: MitreCellRender,
            flex: 8
        },
        {
            colId: FindingFields.action,
            field: FindingFields.action,
            headerName: t('FINDINGS_TABLE.COLUMNS.ACTION.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ACTION.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.action;
            },
            enableRowGroup: true,
            sortable: true,
            flex: 3
        },
        {
            colId: FindingFields.ruleRemediation,
            field: FindingFields.ruleRemediation,
            headerName: t('FINDINGS_TABLE.COLUMNS.REMEDIATION.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.REMEDIATION.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.ruleRemediation;
            },
            flex: 8
        },
        {
            colId: FindingFields.statusId,
            field: FindingFields.statusId,
            headerName: t('FINDINGS_TABLE.COLUMNS.STATUS.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.STATUS.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.statusId;
            },
            cellRenderer: StatusCellRenderer,
            enableRowGroup: true,
            sortable: true,
            flex: 4,
        },
        {
            colId: FindingFields.minStartTime,
            field: FindingFields.minStartTime,
            headerName: t('FINDINGS_TABLE.COLUMNS.FIRST_OPENED.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.FIRST_OPENED.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.minStartTime;
            },
            cellRenderer: CreatedTimeCellRender,
            sortable: true,
            flex: 9,
        },
        {
            colId: FindingFields.origin,
            field: FindingFields.origin,
            headerName: t('FINDINGS_TABLE.COLUMNS.ORIGIN.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ORIGIN.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.origin;
            },
            enableRowGroup: true,
            sortable: true,
            flex: 8,
        },
        {
            colId: FindingFields.cloudAccountType,
            field: FindingFields.cloudAccountType,
            headerName: t('FINDINGS_TABLE.COLUMNS.PLATFORM.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.PLATFORM.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.cloudAccountType;
            },
            enableRowGroup: true,
            sortable: true,
            flex: 4,
        },
        {
            colId: FindingFields.owner,
            field: FindingFields.owner,
            headerName: t('FINDINGS_TABLE.COLUMNS.ASSIGNEE.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.ASSIGNEE.TOOLTIP'),
            valueGetter: (param: ValueGetterParams<IFinding>) => {
                return param.data?.owner;
            },
            cellRenderer: AssigneeCellRenderer,
            enableRowGroup: true,
            sortable: true,
            flex: 3
        },
        {
            colId: FindingColumns.ACTIONS,
            field: '',
            headerName: '',
            cellStyle: { borderLeft: 'none' },
            cellRenderer: ActionsCellRenderer,
            resizable: false,
            sortable: false,
            flex: 1
        }
    ];
}

export function initializeColumnDefs() {
    FindingsTableRegistry.addColumnDefs(getColumnDefs(), 'colId');
}
