import React, { useEffect, useRef, useState } from 'react';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getCustomizationService } from 'common/interface/services';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { IFilterFieldOptionsMap, IFiltersInitialData } from 'common/module_interface/assets/ProtectedAssets';
import { FilterPanel } from './IssuesFilterPanel.styled';
import { getSavedFilterDefs } from 'common/utils/filterUtils';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import { Button, Spinner } from 'common/design-system/components-v2';
import { isAfIssueExclusions } from '../../../../RiskManagement.utils';
import { IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { getIssuesFilterOptionsMap, IIssuesFilterType, ISSUES_FILTER_TYPES_POOL } from '../../Issues.filters';
import {
    RECENTLY_USED_FILTER_ID,
    SAVED_FILTERS_FILTER_ID
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';

export const ISSUES_FILTER_PANEL_ID = 'issues-filter-panel';

export const createRecentlyUsedFilter = (componentName: string) => {
    return {
        id: RECENTLY_USED_FILTER_ID,
        filterProps: { componentName },
    };
};

export const createSavedFiltersFilter = (componentName: string) => {
    return {
        id: SAVED_FILTERS_FILTER_ID,
        filterProps: {
            savedFiltersComponentName: componentName,
        },
        position: 1000,
    };
};

export const getIssuesTableFilters = (): IIssuesFilterType[] => [
    ISSUES_FILTER_TYPES_POOL.ADD_FILTER,
    ISSUES_FILTER_TYPES_POOL.FREE_TEXT,
    ISSUES_FILTER_TYPES_POOL.DATE_PICKER,
    ISSUES_FILTER_TYPES_POOL.PLATFORM,
    ISSUES_FILTER_TYPES_POOL.ID,
    ISSUES_FILTER_TYPES_POOL.ORGANIZATIONAL_UNIT,
    ISSUES_FILTER_TYPES_POOL.ENVIRONMENT,
    ISSUES_FILTER_TYPES_POOL.SEVERITY,
    ISSUES_FILTER_TYPES_POOL.ENTITY_TYPE_BY_PLATFORM,
    ISSUES_FILTER_TYPES_POOL.ENTITY,
    ISSUES_FILTER_TYPES_POOL.STATUS,
    ...(isAfIssueExclusions() ? [ISSUES_FILTER_TYPES_POOL.IS_EXCLUDED] : []),
    ISSUES_FILTER_TYPES_POOL.RULE_TITLE,
    createRecentlyUsedFilter('issues-recent-dashboard'),
    createSavedFiltersFilter('issues-risk-dashboard-saved-filters'),
    ISSUES_FILTER_TYPES_POOL.CLEAR_ALL,
];

export const getIssuesFacetFields = (): string[] => getIssuesTableFilters()
    .filter((filterType: IIssuesFilterType) => filterType.isField && !filterType.withoutFacet)
    .map((filterType: IIssuesFilterType) => filterType.id);

const emptyFilterBox = (
    <FilterPanel.SpinnerDiv>
        <Button
            iconProps={{ name: 'filter' }}
            variant={'text'}>
            <Spinner size={16} />
        </Button>
    </FilterPanel.SpinnerDiv>
);
export const IssuesFilterPanel: React.FC<{
    getInitialAggregations: () => Promise<Aggregations>,
    getAggregations: (filterValues: IServerInputFilterDetails) => Promise<Aggregations>,
    filterTypes: IIssuesFilterType[],
    initialValues?: IFiltersValues,
}> = ({ getInitialAggregations, getAggregations, filterTypes, initialValues }) => {
    const [filterElement, setFilterElement] = useState(emptyFilterBox);
    const initializedAggregationsRef = useRef<boolean>(false);

    useEffect(() => {
        const getFiltersInitialData = async () => {
            const recentlyUsedComponentName = filterTypes.find(filterType => (filterType.id === RECENTLY_USED_FILTER_ID))?.filterProps?.componentName ?? 'issues-recent';
            const recentlyUsedPromise = getCustomizationService().getCustomization(recentlyUsedComponentName);
            const savedFiltersComponentName: string = filterTypes.find(filterType => (filterType.id === SAVED_FILTERS_FILTER_ID))?.filterProps?.savedFiltersComponentName ?? 'issues-saved-filters';
            const savedFiltersPromise = getCustomizationService().getCustomization(savedFiltersComponentName);

            const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
            return Promise.all([recentlyUsedPromise, savedFiltersPromise, organizationalUnitsPromise]).then(
                ([recentlyUsed, savedFilters, organizationalUnits]) => {
                    return {
                        recentlyUsed,
                        savedFilters,
                        organizationalUnits,
                    };
                },
            );
        };

        async function renderFilterPanel(aggregations: Aggregations) {
            const filtersInitialData: IFiltersInitialData = await getFiltersInitialData();
            const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            const fieldOptionsMap: IFilterFieldOptionsMap = getIssuesFilterOptionsMap();
            const filterPanelElementsList = getSavedFilterDefs(filterTypes, {
                aggregations,
                filtersInitialData,
                allCloudAccounts,
                fieldOptionsMap,
            }, IssuesRegistry.getFilter);

            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations,
                shouldBuildObjectForAPI: true,
                initialValues,
                filterId: ISSUES_FILTER_PANEL_ID,
            });
            setFilterElement(filterPanel);
        }

        if (!initializedAggregationsRef.current) {
            initializedAggregationsRef.current = true;
            getInitialAggregations().then((aggregations: Aggregations) => {
                void renderFilterPanel(aggregations);
            }).catch(() => {
                void renderFilterPanel({});
            });
        }
    }, [initialValues, filterTypes, getAggregations, getInitialAggregations]);

    return <FilterPanel.TopDiv>{filterElement}</FilterPanel.TopDiv>;
};