import { Button, Icon, Modal, Select, TextField } from '@dome9/berries/react-components';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import { getNotificationsService, getStoreService } from 'common/interface/services';
import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { DashboardWidgetTypes, IAddinWidgetsDataSource, IDashboardSection, IDashboardWidget, IGenericWidgetSettingsProps, IWidgetDataConfiguration } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { updateDashboardInServer } from 'modules/overview/Api';
import { addWidgetInStore, getSelectedDashboard, updateWidgetInStore } from 'modules/overview/Overview.reducer';
import { assignValueToObject, getDataIdByPath, sortWidgetsByView } from 'modules/overview/Utils';
import { i18nOverviewNamespace } from 'modules/overview/initialize.i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { GenericDashboardWidget } from './GenericDashboardWidget';
import { Checkbox, Spinner, Stack, Typography } from 'common/design-system/components-v2';
import { SingleValue } from 'react-select';
import { LoadingOverlayStyled } from '../LoadingOverlay/LoadingOverlayComponent.styled';
import { ErrorBoundary } from 'react-error-boundary';
import { NotificationType } from 'common/interface/notifications';


export interface ISelectOption {
    label?: string | null;
    value?: string | null;
}

interface ISelectOptionsByDataType {
    [key: string]:ISelectOption[]
}

export enum ActionTypes {
    New = 'new',
    Edit = 'edit',
    Duplicate = 'duplicate'
}
interface IAddEditWidgetModalProps {
    widget?: IDashboardWidget
    isOpen: boolean
    onRequestClose: Function,
    section?: IDashboardSection,
    action?: ActionTypes
}

let debounceTimeoutId: any = null;


const AddEditWidgetModal: React.FC<IAddEditWidgetModalProps> = ({ widget, isOpen, onRequestClose, section, action }) => {


    const cloneWidgetAndParseWidgetAggregation = (widgetItem: IDashboardWidget) => {
        const clonedWidget = deepCloneObject(widgetItem);
        if(clonedWidget.aggregation) {
            return clonedWidget;
        }
        switch (clonedWidget.type) {
            case 'summary':
                clonedWidget.aggregation = 'summary';
                break;
            case 'trend':
                clonedWidget.aggregation = 'trend';
                break;
        }

        return clonedWidget;
    };


    const { t } = useTranslation(i18nOverviewNamespace);
    const { dispatch } = getStoreService().getReduxTools();
    const selectedDashboard = useSelector(getSelectedDashboard);
    const [isLoading, setIsLoading] = useState(false);
    const selectedWidget = selectedDashboard.sections?.reduce((result: IDashboardWidget | null, sectionItem: IDashboardSection) => {
        const _widget: IDashboardWidget | undefined = sectionItem.widgets?.find((widgetItem: IDashboardWidget) => widgetItem.options?.id === widget?.options?.id);
        if(_widget) {
            result = _widget;
        }
        return result;
    }, null);


    let _widgetClone = selectedWidget && cloneWidgetAndParseWidgetAggregation(selectedWidget);
    const isNewWidget = Boolean(!widget);
    if(isNewWidget && section && selectedDashboard) {
        action = ActionTypes.New;
        _widgetClone = {
            id: v4(),
            sectionId: section.id,
            dashboardId: selectedDashboard.id,
            options: {
                id: v4(),
                sizes: {
                    width: 2,
                    height: 2,
                }
            },
            title: t('WIDGETS.NEW_WIDGET'),
            limit: 10,
            description: '',
            gslFilter: '',
        };
    }

    const [widgetClone, setWidgetClone] = useState(_widgetClone);
    const [dataSourcesOptions, setDataSourcesOptions] = useState<ISelectOption[]>([]);
    const [selectedDataSource, setSelectedDataSource] = useState<IAddinWidgetsDataSource | null>(null);
    const [dataTypeOptions, setDataTypeOptions] = useState<ISelectOption[]>([]);
    const [widgetTypeOptions, setWidgetTypeOptions] = useState<ISelectOption[]>([]);
    const [widgetSetup, setWidgetSetup] = useState<IWidgetDataConfiguration | null>(null);
    const [dataFieldPath, setDataFieldPath] = useState<string | null>(null);
    const [preventReload, setPreventReload] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(_widgetClone ? _widgetClone.title : '');
    const [hideOnNoData, setHideOnNoData] = useState<boolean>(_widgetClone ? _widgetClone.hideOnNoData : false);
    const [description, setDescription] = useState<string>(_widgetClone ? _widgetClone.description: '');


    const closeModal = () =>{
        setPreventReload(false);
        onRequestClose();
    };

    const resetModal = () =>{
        setWidgetClone(deepCloneObject(_widgetClone));
        setTitle(_widgetClone.title);
        setDescription(_widgetClone.description);
    };

    useEffect(() => {
        const defaultDataIdKey = 'defaultDataIdKey';
        const allDataSourcesAddins = globalAddinContainer.get<IAddinWidgetsDataSource>(DASHBOARD_DYNAMIC_WIDGETS);
        const _dataSourcesOptions = allDataSourcesAddins?.map((addin) => { return { label: addin.dataSourceName, value: addin.dataSourceName };});
        const _selectedDataSource = widgetClone && widgetClone.dataSourceName && globalAddinContainer.getById<IAddinWidgetsDataSource>(DASHBOARD_DYNAMIC_WIDGETS, widgetClone.dataSourceName);
        const _dataTypeOptions = [] as ISelectOption[];
        const _widgetSetup = _selectedDataSource && _selectedDataSource?.widgets?.find((widgetItem: IWidgetDataConfiguration)=> (widgetItem.dataId === widgetClone.dataId || (dataFieldPath && widgetItem.dataId === getDataIdByPath(widgetClone, dataFieldPath))) && widgetItem.type === widgetClone.type);
        const _widgetTypeOptionsByDataType = _selectedDataSource?.widgets?.reduce((result: ISelectOptionsByDataType, widgetItem: IWidgetDataConfiguration) => {
            const dataId = widgetItem.dataId || defaultDataIdKey;
            if (!result[dataId]){
                result[dataId] = [];
                _dataTypeOptions.push({ label: widgetItem.displayName || widgetItem.dataId, value: widgetItem.dataId });
            }
            result[dataId].push({ label: t(`WIDGETS.WIDGET_TYPES.${widgetItem.type}`), value: widgetItem.type });
            return result;
        },{} as ISelectOptionsByDataType) || {};
        const _dataFieldPath = _selectedDataSource && _selectedDataSource.dataField?.path;
        const dataId = widgetClone && dataFieldPath && getDataIdByPath(widgetClone , dataFieldPath);
        const _widgetTypeOptions = _widgetTypeOptionsByDataType[dataId || defaultDataIdKey];

        setSelectedDataSource(_selectedDataSource);
        setDataSourcesOptions(_dataSourcesOptions);
        setDataTypeOptions(_dataTypeOptions);
        setWidgetTypeOptions(_widgetTypeOptions);
        setWidgetSetup(_widgetSetup);
        setDataFieldPath(_dataFieldPath);

    }, [widgetClone, dataFieldPath, t]);


    const handleUpdateWidget = (widgetObject: IDashboardWidget) => {
        const _clonedWidget = deepCloneObject(widgetObject);
        setPreventReload(false);
        setWidgetClone(_clonedWidget);
    };

    const getSettingsComponent = () => {
        const SettingsComponentRenderer = widgetSetup && widgetSetup.settingsComponent as React.FC<IGenericWidgetSettingsProps>;
        return SettingsComponentRenderer && <SettingsComponentRenderer widget={widgetClone} updateWidgetCallback={handleUpdateWidget} onFilterPanelAsyncChangeFinished={onFilterPanelAsyncChangeFinished} onFilterPanelAsyncChangeStarted={ onFilterPanelAsyncChangeStarted }/>;
    };


    const handleChangeSource =(option: SingleValue<ISelectOption> )=> {
        const _widgetClone = deepCloneObject(widgetClone);
        _widgetClone.dataSourceName = option?.value;
        _widgetClone.filterState = null;
        _widgetClone.gslFilter = '';
        const _selectedDataSource = option?.value && globalAddinContainer.getById<IAddinWidgetsDataSource>(DASHBOARD_DYNAMIC_WIDGETS, option?.value);
        const _dataFieldPath = _selectedDataSource && _selectedDataSource.dataField?.path;
        const firstWidgetOption = (_selectedDataSource && _selectedDataSource.widgets && _selectedDataSource.widgets.length) && _selectedDataSource.widgets[0];
        if(firstWidgetOption) {
            _widgetClone.dataId = firstWidgetOption.dataId;
            _widgetClone.type = firstWidgetOption.type;
            _dataFieldPath ? assignValueToObject(_widgetClone, _dataFieldPath, firstWidgetOption.dataId) : null;
        }
        setWidgetSetup(null);
        handleUpdateWidget(_widgetClone);
    };


    const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const newTitle = e.target?.value;
        const _clonedWidget = deepCloneObject(widgetClone);
        _clonedWidget.title = newTitle;
        setTitle(newTitle);
        setPreventReload(true);
        setWidgetSetup(null);
        setWidgetClone(_clonedWidget);
    };

    const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const newDescription = e.target?.value;
        const _clonedWidget = deepCloneObject(widgetClone);
        _clonedWidget.description = newDescription;
        setDescription(newDescription);
        setPreventReload(_clonedWidget?.type !== DashboardWidgetTypes.Summary);

        clearTimeout(debounceTimeoutId);

        debounceTimeoutId = setTimeout(async() => {
            setWidgetClone(_clonedWidget);
        }, 500);
        setWidgetSetup(null);
    };

    const handleChangeDataField = (option: SingleValue<ISelectOption>) =>{
        const _widgetClone = deepCloneObject(widgetClone);
        dataFieldPath ? assignValueToObject(_widgetClone, dataFieldPath, option?.value) : null;
        const widgetOptions = dataFieldPath && selectedDataSource?.widgets.filter(widget => widget.dataId === getDataIdByPath(_widgetClone , dataFieldPath));
        if(widgetOptions && !widgetOptions.find(widgetOption=> widgetOption.type === _widgetClone.type)) {
            const firstWidgetOption = dataFieldPath && selectedDataSource?.widgets.find(widget => widget.dataId === getDataIdByPath(_widgetClone , dataFieldPath));
            _widgetClone.type =firstWidgetOption && firstWidgetOption.type;
        }
        _widgetClone.dataId = option?.value;
        setWidgetSetup(null);
        setWidgetClone(_widgetClone);
    };

    const handleChangeWidgetType =(option: SingleValue<ISelectOption>)=> {
        const _widgetClone = deepCloneObject(widgetClone);
        _widgetClone.type = option?.value;
        setWidgetSetup(null);
        setWidgetClone(_widgetClone);
    };

    const getTitleByAction =()=>{
        switch (action){
            case ActionTypes.New:
                return t('WIDGETS.TITLES_BY_ACTION.NEW');
            case ActionTypes.Duplicate:
                return t('WIDGETS.TITLES_BY_ACTION.DUPLICATE');
            default:
                return t('WIDGETS.TITLES_BY_ACTION.EDIT');
        }
    };

    const handleChangeHideIfNoData = (event: React.ChangeEvent<HTMLInputElement>)=> {
        setPreventReload(true);
        const _widgetClone = deepCloneObject(widgetClone);
        _widgetClone.hideOnNoData = event.target.checked;
        setHideOnNoData(event.target.checked);
        setWidgetClone(_widgetClone);
    };


    const handleSubmit = ()=> {
        const selectedDashboardClone = deepCloneObject(selectedDashboard);
        const resetPositions = () => {
            if(widgetClone.options?.sizes){
                widgetClone.options.sizes.y = 10000;
                widgetClone.options.sizes.x = 0;
            } else {
                widgetClone.options = { sizes: { y: 10000, x: 0 } };
            }
        };


        switch (action) {
            case ActionTypes.New: {
                resetPositions();
                widgetClone.id = v4();
                widgetClone.options.id = v4();
                const sections = selectedDashboardClone.sections?.map((sectionItem: IDashboardSection) => {
                    if(sectionItem.id === section?.id){
                        sectionItem.isExpanded = true;
                        sectionItem.widgets?.push(widgetClone);
                    }
                    return sectionItem;
                });
                selectedDashboardClone.sections = sortWidgetsByView(sections);

                if(section){
                    dispatch(addWidgetInStore({ widget: widgetClone, sectionTitle: section.title }));
                }
                resetModal();
                break;
            }
            case ActionTypes.Duplicate: {
                widgetClone.options.sizes = selectedWidget?.options?.sizes && deepCloneObject(selectedWidget?.options?.sizes);
                resetPositions();
                widgetClone.id = v4()+new Date().getTime();
                widgetClone.options.id = v4()+new Date().getTime();
                const sections = selectedDashboardClone.sections?.map((sectionItem: IDashboardSection) => {
                    if(sectionItem.id === section?.id){
                        sectionItem.isExpanded = true;
                        sectionItem.widgets?.push(widgetClone);

                    }
                    return sectionItem;
                });

                selectedDashboardClone.sections = sortWidgetsByView(sections);

                if(section){
                    dispatch(addWidgetInStore({ widget: widgetClone, sectionTitle: section.title }));
                }
                break;
            }
            case ActionTypes.Edit:
            default: {
                widgetClone.options.sizes = selectedWidget?.options?.sizes && deepCloneObject(selectedWidget?.options?.sizes);
                const sections = selectedDashboardClone.sections?.map((sectionItem: IDashboardSection) => {
                    const widgets = [] as IDashboardWidget[];
                    sectionItem?.widgets?.forEach((widgetItem) => {
                        if (widgetItem.options?.id === widgetClone?.options?.id) {
                            widgets.push(widgetClone);
                            sectionItem.isExpanded = true;
                        } else{
                            widgets.push(widgetItem);
                        }
                    });
                    sectionItem.widgets = widgets;
                    return sectionItem;
                });
                selectedDashboardClone.sections = sortWidgetsByView(sections);

                dispatch(updateWidgetInStore(widgetClone));
                break;
            }
        }
        setPreventReload(false);
        closeModal();
        updateDashboardInServer(selectedDashboardClone, false);
    };

    const onFilterPanelAsyncChangeStarted = () => {
        setIsLoading(true);
    };

    const onFilterPanelAsyncChangeFinished = () => {
        setIsLoading(false);
    };

    function errorHandler() {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('DASHBOARD.MESSAGES.ERROR_IN_WIDGET_MODAL'),
            text: '',
        });
        closeModal();
    }

    return (
        <ErrorBoundary
            fallbackRender={() => null}
            onError={errorHandler}>
            <Modal.ModalDialog isOpen={isOpen} onRequestClose={()=> closeModal()} shouldCloseOnOverlayClick={false} className='overview-module'>
                <Modal.ModalHeader
                    title={getTitleByAction()}
                    onClose={()=> {
                        resetModal();
                        closeModal();
                    }}
                />
                <Modal.ModalContent>
                    <div>
                        <LoadingOverlayStyled isLoading={isLoading}>
                            <Stack justifyContent={'center'} alignItems={'center'} spacing={3} direction={'row'} className={'loading-panel'}>
                                <Spinner />
                                <Typography>Loading</Typography>
                            </Stack>
                        </LoadingOverlayStyled>

                        <div className="flex">
                            <div className="flex-[4] max-w-[500px] space-y-6 pr-4">
                                <div>
                                    <div className="font-semibold mb-2">
                                        {t('DASHBOARD.TITLE')}:
                                    </div>
                                    <TextField
                                        dataAid='widget-title'
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>)=> handleChangeTitle(e)}
                                        value={title}
                                        placeholder={t('WIDGETS.ADD_TITLE')}
                                    />
                                </div>
                                <div>
                                    <div className="font-semibold mb-2">
                                        {t('DASHBOARD.DESCRIPTION')}:
                                    </div>
                                    <TextField
                                        dataAid='widget-description'
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>)=> handleChangeDescription(e)}
                                        value={description}
                                    />
                                </div>
                                <div data-aid='source-select-section'>
                                    <div className="font-semibold mb-2">
                                        {t('DASHBOARD.SOURCE')}:
                                    </div>
                                    <Select
                                        required={false}
                                        value={dataSourcesOptions?.filter(option => option.value === widgetClone?.dataSourceName)}
                                        onChange={(option: SingleValue<ISelectOption>) => handleChangeSource(option)}
                                        closeMenuOnSelect={true}
                                        placeholder={'Select Source'}
                                        isSearchable={true}
                                        options={dataSourcesOptions}
                                    />
                                </div>
                                {dataFieldPath && widgetClone && getDataIdByPath(widgetClone, dataFieldPath) &&
                            <div>
                                <div className="font-semibold mb-2">
                                    {selectedDataSource?.dataField?.displayName}:
                                </div>
                                <Select
                                    required={false}
                                    value={dataTypeOptions?.filter(option => option.value === widgetClone?.dataId || (widgetClone && option.value === getDataIdByPath(widgetClone, dataFieldPath)))}
                                    onChange={(option: SingleValue<ISelectOption>) => handleChangeDataField(option)}
                                    closeMenuOnSelect={true}
                                    placeholder={t('DASHBOARD.LOADING')}
                                    isSearchable={true}
                                    options={dataTypeOptions}
                                />
                            </div>
                                }
                                <div>
                                    <Checkbox
                                        checked={hideOnNoData}
                                        label={t('WIDGETS.ACTIONS.HIDE_IF_NO_DATA_AVAILABLE')}
                                        onChange={(event)=> handleChangeHideIfNoData(event)} />
                                </div>
                                {(widgetTypeOptions?.length > 1) && // Showing dropdown for one option is unnecessary.
                               <div>
                                   <div className="font-semibold mb-2">
                                       {t('DASHBOARD.TYPE')}
                                   </div>
                                   <Select
                                       required={false}
                                       value={widgetTypeOptions?.filter(option => option.value === widgetClone.type)}
                                       onChange={(option: SingleValue<ISelectOption>) => handleChangeWidgetType(option)}
                                       closeMenuOnSelect={true}
                                       placeholder={t('DASHBOARD.LOADING')}
                                       isSearchable={true}
                                       options={widgetTypeOptions}
                                   />
                               </div>
                                }
                                {widgetSetup &&
                                <div className='mt-8'>
                                    {getSettingsComponent()}
                                </div>
                                }
                            </div>
                            <div className='flex-[6] min-w-[300px] h-[400px] ml-8 overflow-hidden border rounded-lg'>
                                {selectedDataSource && section ?
                                    <>
                                        <div className='relative h-full'>
                                            <GenericDashboardWidget widget={widgetClone} hideDrag={true} hideActions={true} preventReload={preventReload} section={section}/>
                                            {
                                            // this is quick and dirty code , approved by my GM and will be replaced later with a better solution
                                                (widgetClone.dataSourceName === 'Compliance' && widgetClone.type === 'grid') && <div className='top-0 w-full h-full opacity-[0.5] bg-content absolute'/>
                                            }
                                        </div>

                                    </>
                                    :
                                    <div className={'flex bg-content flex-1 flex-col shadow rounded-lg overflow-hidden h-full'}>
                                        <div className={'flex items-center border-b px-7 py-4 font-medium leading-[22px] flex-0'}>
                                            <div className='flex-1 truncate'>{widgetClone?.title}</div>
                                        </div>
                                        <div className='flex justify-center items-center flex-col flex-1 opacity-50'>
                                            <div className='mb-4'>
                                                <Icon name="timelineChart" size={96}/>
                                            </div>
                                            <div>
                                                {t('WIDGETS.ADD_YOUR_NEW_WIDGET')}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <div className='modal__footer-actions'>
                        <Button dataAid='cancel' onClick={(e)=> {
                            e.stopPropagation();
                            resetModal();
                            closeModal();
                        }}>{t('DASHBOARD.CANCEL')}</Button>
                        <Button dataAid='submit' color='primary' onClick={(e)=>{
                            e.stopPropagation();
                            handleSubmit();
                        }}>{t('DASHBOARD.SAVE')}</Button>
                    </div>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
        </ErrorBoundary>
    );
};

export default AddEditWidgetModal;
