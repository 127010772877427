import { IColumnUsageDef } from 'common/interface/general';
import { ReportsFields } from '../../Reporting.const';

export const ReportsColumnTypes: { [key: string]: IColumnUsageDef } = {
    REPORT_NAME: {
        id: ReportsFields.REPORT_NAME,
    },
    TEMPLATE: {
        id: ReportsFields.TEMPLATE,
    },
    TIME_RANGE: {
        id: ReportsFields.TIME_RANGE,
    },
    TARGET: {
        id: ReportsFields.TARGET,
    },
    NEXT_RUN: {
        id: ReportsFields.NEXT_RUN
    },
    LAST_RUN: {
        id: ReportsFields.LAST_RUN,
    }
};
