import React from 'react';
import ResourceAssetNode from '../Components/ExposureAssetNode';
import ExposureGroupNode from '../Components/ExposureGroupNode';
import InternetNode from '../Components/InternetNode';
import PartialNode from '../Components/PartialNode';
import DummyNode from '../Components/DummyNode';
import { AssetNodeProps } from 'common/components/Graph/Models/AssetNodeProps';
import { Entity } from '../Models/Entity';

export enum ExposureNodeType {
    DefaultAssetNode = 'DefaultAssetNode',
    Internet = 'Internet',
    Partial = 'Partial' ,
    ExposureGroupNode = 'ExposureGroupNode',
    Dummy = 'Dummy'
}

export const nodeTypes: Record<ExposureNodeType, React.FC<AssetNodeProps>> = {
    [ExposureNodeType.DefaultAssetNode]: ResourceAssetNode,
    [ExposureNodeType.Internet]: InternetNode,
    [ExposureNodeType.Partial]: PartialNode,
    [ExposureNodeType.ExposureGroupNode]: ExposureGroupNode,
    [ExposureNodeType.Dummy]: DummyNode
};

export function getExposureNodeType(entity: Entity): ExposureNodeType {
    if (entity.type === 'internet') {
        return ExposureNodeType.Internet;
    }

    if (entity.type === 'publicIps') {
        return ExposureNodeType.Partial;
    }

    if (entity.type === 'group') {
        return ExposureNodeType.ExposureGroupNode;
    }

    return ExposureNodeType.DefaultAssetNode;
}