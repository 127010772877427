import { addAlpha, colors } from 'common/design-system/theme/colors';
import styled, { css } from 'styled-components/macro';

const MinimizeMenuWrapper = styled.div<{ isMinimized: boolean; }>`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-content-default);
    flex-grow: 1;
`;

const MinimizeMenuItem = styled.div<{ isMinimized: boolean; isSelected: boolean; withTriangle: boolean; }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 8px;
    margin: 4px;
    border-radius: 8px;

    &:hover {
        background-color: ${({ theme }) => theme.palette.componentStates.ghostHover};
        color: ${({ theme }) => theme.palette.status.info};
    }

    ${({ withTriangle }) => withTriangle && css`
        &:before {
            content: '';
            position: absolute;
            left: calc(100% + 5px);
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            background: ${({ theme }) => theme.palette.surface.primary};
            box-shadow: ${addAlpha(colors.grey['800'], 0.2)} -4px 4px 5px -1px;
            z-index: 1500;
        }
    `};

    ${({ isSelected }) => isSelected && css`
        background-color: ${({ theme }) => theme.palette.componentStates.ghostActive};
        &:hover {
            background-color: ${({ theme }) => theme.palette.componentStates.ghostActive};
            color: ${({ theme }) => theme.palette.text.strong};
        }
    `};
`;

const MinimizeMenuPopup = styled.div`
    position: absolute;
    top: -25px;
    bottom: 20px;
    left: calc(100% + 5px);
    box-shadow: ${({ theme }) => theme.shadows[2]};
    width: 220px;
    /* background-color: #171f2c; */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    transition: height 0.16s linear;
    overflow: hidden;
    z-index: 1500;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -8px;
        width: 8px;
    }
`;

const MinimizeMenuPopupTitle = styled.div`
    width: 100%;
    /* color: #ffffff; */
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    background-color: var(--color-content-default); 
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.09); */
`;

const MinimizeMenuPopupContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
`;

const MinimizeMenuPopupItem = styled.div`
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    padding: 0 20px;
`;

export default {
    MinimizeMenuWrapper,
    MinimizeMenuItem,
    MinimizeMenuPopup,
    MinimizeMenuPopupTitle,
    MinimizeMenuPopupContent,
    MinimizeMenuPopupItem
};