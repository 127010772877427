import { IApplicationReducer } from 'common/interface/redux';
import { APPLICATION_REDUCERS, REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import RulesManagement from './RulesManagement';
import rulesManagementReducer from './RulesManagement.reducer';
import AddEditRule from './AddEditRule';
import { IReactPageRoute } from 'common/interface/routing';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

export default function initializeRulesManagement() {

    const reducers: IApplicationReducer[] = [
        { name: 'rulesManagement', isBlackList: false, reducer: rulesManagementReducer },
    ];
    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');

    const rulesManagementPageRoute: IReactPageRoute = {
        condition: true,
        component: RulesManagement,
        path: '/compliance-engine/rules',
    };

    const rulesManagementAddPageRoute: IReactPageRoute = {
        condition: true,
        component: AddEditRule,
        path: '/compliance-engine/rules/rule',
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        { id: 'ADD_EDIT_RULE', content: rulesManagementAddPageRoute },
        { id: 'RULES', content: rulesManagementPageRoute },
    ]);
}
