import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table, Stack } from 'common/design-system/components-v2';
import { ColDef, GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import i18n from 'common/services/translations/translations';
import { CONFIGURATION_DRAWER, I18nIntegrations, CONFIGURATION_DRAWER_VIEWS, CONFIGURATION_CHANGED_HANDLER_ID, INTEGRATION_DRAWER } from 'common/module_interface/settings/integrations/consts';
import { FILTER_EVENTS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import NameCellRenderer from '../Components/ConfigurationPage/CellRenderers/NameCellRenderer';
import {
    renderAddFilter, renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { useTranslation } from 'react-i18next';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { getIntegrationsService } from 'common/interface/services';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { INTEGRATIONS_DRAWER_VIEWS } from '../Configurations/ConfigurationDrawerViews';
import { useDrawerHandler } from 'common/components/DrawerInfra/Drawer/UseDrawerHandler';
import { dateComparator } from 'common/utils/helpFunctions';


const defineIntegrationName = (params:any) => {
    const configurationType = params.value;
    const integrationsDefinitions = IntegrationsRegistry.getIntegrationsDefinitions();
    const IntegrationName = integrationsDefinitions.find((integration) => integration.id === configurationType);
    return params.value ? IntegrationName?.title: '';
};
const columnDefConst: (onConfigurationClicked:any) => ColDef<any>[] = (onConfigurationClicked) => [
    {
        colId: 'selection',
        field: 'selection',
        headerName: '',
        minWidth: 52,
        maxWidth: 52,
        resizable: false,
        checkboxSelection: true,
        lockPosition: true,
        suppressColumnsToolPanel: true,
        showDisabledCheckboxes: true,
        headerCheckboxSelection: true,
    },
    {
        colId: 'name',
        field: 'name',
        headerName: i18n.t('GENERAL.NAME', { ns: I18nIntegrations }),
        width: 400,
        suppressSizeToFit: true,
        sortable: true,
        cellRenderer: (params:any) => {
            const WrappedRenderer = () => (
                <NameCellRenderer dataAid={'configuration-item'} params={params} onConfigurationClicked={onConfigurationClicked} />
            );
            return <WrappedRenderer />;
        }
    },
    {
        colId: 'integrationsType',
        field: 'integrationsType',
        headerName: i18n.t('CONFIGURATION_PAGE.INTEGRATION_TYPE', { ns: I18nIntegrations }),
        width: 300,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueFormatter: (params) => `${defineIntegrationName(params)}`,
    },
    {
        colId: 'created',
        field: 'created',
        headerName: i18n.t('CONFIGURATION_PAGE.CREATED', { ns: I18nIntegrations }),
        width: 400,
        suppressSizeToFit: true,
        sortable: true,
        valueGetter: (params) => params.data?.createdAt ? new Date(params.data.createdAt) : null,
        comparator: dateComparator,
        valueFormatter: (params) => params.value ? `${new Date(params.data?.createdAt)}` : '',
    },
    {
        colId: 'inUse',
        field: 'inUse',
        headerName: i18n.t('CONFIGURATION_PAGE.IN_USE', { ns: I18nIntegrations }),
        width: 300,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueFormatter: (params) => params.data?.inUse,
    },
];


const filterPanelElementsList = (data:any) => {
    const uniqueIntegrationsType = Object.values(
        data.reduce((acc:any, item:any) => {
            acc[item.integrationsType] = item;
            return acc;
        }, {} as { [key: string]: any })
    );
    const extractIntegrationName = (configurationType:string) => {
        const integrationsDefinitions = IntegrationsRegistry.getIntegrationsDefinitions();
        const IntegrationName = integrationsDefinitions.find((integration) => integration.id === configurationType);
        return IntegrationName?.title;
    };
    return [
        {
            filterProps: { key: FILTERS_KEYS.ADD_FILTER },
            renderFunction: renderAddFilter,
        },
        {
            filterProps: {
                key: FILTERS_KEYS.FREE_TEXT,
                title: i18n.t('GENERAL.FREE_TEXT', { ns: I18nIntegrations }),
            },
            renderFunction: renderDefaultFreeTextFilter,
        },
        {
            filterProps: {
                id: 'integrationsType',
                initialData: uniqueIntegrationsType.map((elem:any) => {return { value: `${extractIntegrationName(elem.integrationsType)}`, count: 0 };}),
                key: 'integrationsType',
                title: i18n.t('CONFIGURATION_PAGE.INTEGRATION_TYPE', { ns: I18nIntegrations }),
                value: [],
            },
            renderFunction: renderMultiSelectFilter,
        },
        {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,
        }
    ];};

interface IConfigurationsProps {
    filteredConfigurations?: any;
    configurationUpdated: () => Promise<void>;
    viewMode?: boolean;
}

const ConfigurationPage: React.FC<IConfigurationsProps> = ({ filteredConfigurations, configurationUpdated, viewMode } ) => {
    const { t } = useTranslation( I18nIntegrations );
    const [data, setData] = useState<any[]>(filteredConfigurations || []);
    const [filterElement, setFilterElement] = useState(<div></div>);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [modalConfigurations, setModalConfigurations] = useState<any>({});
    const integrationsDefinitions = IntegrationsRegistry.getIntegrationsDefinitions();

    const handleConfigurationChanged = () => {
        configurationUpdated();
    };

    useDrawerHandler(CONFIGURATION_CHANGED_HANDLER_ID, handleConfigurationChanged);

    const onConfigurationClicked = (integrationType : string, configurationId:string ) => {
        const singleIntegrationDefinition = integrationsDefinitions.find(integration => integration.id === integrationType);
        if(singleIntegrationDefinition?.configurationComponent) {
            showDrawer(INTEGRATION_DRAWER.key, {
                integrationID: singleIntegrationDefinition?.id,
                view: INTEGRATIONS_DRAWER_VIEWS.LIST_VIEW,
                title: singleIntegrationDefinition?.title,
                icon: singleIntegrationDefinition?.icon,
                componentProps: { singleConfigurationId: configurationId,mode: 'single' }
            },{ [INTEGRATION_DRAWER.eventTypes.configurationChanged]: CONFIGURATION_CHANGED_HANDLER_ID, });
        }
    };

    const pageSize = 1000;
    const apiRef = useRef<GridApi>();

    const columnDefs = useMemo<ColDef[]>(() => {
        return columnDefConst(onConfigurationClicked);
    } , [data]);

    const onSelectionChanged = (params: SelectionChangedEvent<any>) => {
        const selectedItems = params.api.getSelectedRows();
        setSelectedRows(selectedItems);
    };

    const onGridReady = useCallback((params: GridReadyEvent<any, any>) => {
        apiRef.current = params.api;
        apiRef.current.setRowData(data);
    }, [data]);

    const onFilterEvent = (action: string, filtersValues: IFiltersValues) => {
        const removeSpacesFromIntegrationsType = (filtersValues: IFiltersValues): IFiltersValues => {
            return {
                ...filtersValues,
                integrationsType: filtersValues?.integrationsType?.length && filtersValues.integrationsType.map((elem: string) => {
                    const integration = integrationsDefinitions.find((integration) => integration.title === elem);
                    return integration ? integration.id : elem;
                }) || []
            };
        };
        const updatedFiltersValues = removeSpacesFromIntegrationsType(filtersValues);
        if ( action === FILTER_EVENTS.FILTER_CHANGED ) {
            if ( updatedFiltersValues?.integrationsType.length === 0 && !updatedFiltersValues['free-text'] ) {
                setData(filteredConfigurations);
                return;
            }
            if ( updatedFiltersValues?.integrationsType.length === 0 && updatedFiltersValues['free-text'] ) {
                const filteredData = filteredConfigurations.filter((elem:{}) => {return Object.values(elem).some(value => typeof value === 'string' && value.toLowerCase().includes(filtersValues['free-text'].toLowerCase()));});
                setData(filteredData);
                return;
            }
            if ( updatedFiltersValues?.integrationsType.length !== 0 && !updatedFiltersValues['free-text'] ) {
                const filteredData = filteredConfigurations.filter((elem:{ integrationsType:string }) => { return updatedFiltersValues?.integrationsType.includes(elem.integrationsType);});
                setData(filteredData);
                return;
            }
            if ( updatedFiltersValues?.integrationsType.length !== 0 && updatedFiltersValues['free-text'] ) {
                const filteredData = filteredConfigurations.filter( (elem:{ integrationsType:string }) => { return elem.integrationsType.includes(updatedFiltersValues['free-text']) && filtersValues?.integrationsType.includes(elem.integrationsType);});
                setData(filteredData);
                return;
            }
        }
    };

    const getAggregations = async (filtersValues: IFiltersValues) : Promise<any> => {
        return filtersValues;
    };

    useEffect(() => {
        if (apiRef.current) {
            apiRef.current.setRowData(data);
        }
    }, [data]);

    useEffect(() => {
        setData(filteredConfigurations);
    }, [filteredConfigurations]);

    useEffect(() => {
        const filterPanel = initFilterPanel({
            filterPanelElementsList: filterPanelElementsList(filteredConfigurations),
            getAggregations: getAggregations,
            onFilterChangeCallBack: onFilterEvent,
            shouldUseQueryParams: false,
        });
        setFilterElement(filterPanel);
    },[filteredConfigurations]);

    const gridOptions: GridOptions<any> = {
        columnDefs,
        onGridReady,
        rowSelection: 'multiple',
        onRowSelected: onSelectionChanged,
        getRowId: (params) => params.data.id!,
    };

    const openDeleteModal = (selectedRows: { id:string, integrationsType:string, name:string }[]) => {
        const onDelete = async (configurations: any) => {
            try {
                await getIntegrationsService().deleteConfiguration(configurations[0].id);
            } catch (error:any) {
                console.log('Error: ', error.message);
            }
            await configurationUpdated();
        };
        const onModalClose = () => {
            setModalConfigurations({ ...modalConfigurations, isOpen: false });
        };
        const deleteModalConfigurations: { title?: string, text?: string, onConfirm?: () => void, onClose?: () => void, isOpen?: boolean } = {};
        deleteModalConfigurations.title = t('CONFIGURATION_PAGE.DELETE_CONFIGURATION_TITLE');
        deleteModalConfigurations.text = t('CONFIGURATION_PAGE.DELETE_CONFIGURATION_CONTENT');
        deleteModalConfigurations.onClose = onModalClose;
        deleteModalConfigurations.onConfirm = ()=> {
            onDelete(selectedRows);
            onModalClose();
        };
        deleteModalConfigurations.isOpen = true;
        setModalConfigurations(deleteModalConfigurations);
    };

    const onConfigurationAdd = () => {
        const configurationTitle = t('CONFIGURATION_PAGE.ADD_CONFIGURATION');
        const configurationID = configurationTitle;
        showDrawer(CONFIGURATION_DRAWER.key, {
            configurationID: configurationID,
            view: CONFIGURATION_DRAWER_VIEWS.LIST_VIEW,
            title: configurationTitle,
            componentProps: { configurations: [] }
        },{ [CONFIGURATION_DRAWER.eventTypes.configurationChanged]: CONFIGURATION_CHANGED_HANDLER_ID });
    };

    const defaultActions = useMemo<any>(() => {
        return [
            {
                id: 'deleteConfiguration',
                name: i18n.t('TABLE.BUTTON_DELETE', { ns: I18nIntegrations }),
                buttonProps: { iconProps: { name: 'delete' } },
                callback: () => openDeleteModal(selectedRows),
                isActionDisabled: () => selectedRows.length !== 1 || viewMode,
            },
            {
                id: 'addConfiguration',
                name: i18n.t('TABLE.BUTTON_ADD', { ns: I18nIntegrations }),
                buttonProps: { iconProps: { name: 'plus' } },
                callback: () => onConfigurationAdd(),
                isActionDisabled: () => viewMode,
            }
        ];
    }, [selectedRows]);

    return (
        <>
            <Stack padding={[2,8]} fullHeight spacing={2} data-aid={'configuration-page'}>
                {filterElement}
                <Table
                    pageSize={pageSize}
                    gridOptions={gridOptions}
                    actions={defaultActions}
                    footer={t('CONFIGURATION_PAGE.TABLE_FOOTER', {
                        currentCount: data?.length?.toLocaleString(),
                        totalCount: filteredConfigurations?.length?.toLocaleString() })}
                />
            </Stack>
            { modalConfigurations.isOpen &&
                <ConfirmationModal isOpen={modalConfigurations.isOpen}
                    onConfirm={modalConfigurations.onConfirm}
                    title={modalConfigurations.title}
                    text={modalConfigurations.text}
                    onClose={modalConfigurations.onClose}
                    onCancel={modalConfigurations.onClose} />
            }
        </>
    );
};

export default ConfigurationPage;
