import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { EVENT_DETAILS_DRAWER_KEY } from '../../Consts';
import { EventDetailsDrawer } from 'modules/events/EventDrawer/EventDetailsDrawer';

export const initializeEventsDrawer = () => {
    DrawerRegistry.addContentProvider({
        id: EVENT_DETAILS_DRAWER_KEY.key,
        options: {
            disableSpacing: true,
            width: 'xxl'
        },
        getDrawerContent: async (eventId: string) => {
            return ({
                component: EventDetailsDrawer,
                componentProps: { eventId: eventId },
            });
        },
    });
};