import styled from 'styled-components';
import { Button } from 'common/design-system/components-v2';

const ButtonDiv = styled(Button)`
  display: flex;
  min-width: 200px;
  border: 1px solid #aaa;
  margin: 5px 10px;
`;

export const ActionsStyled = {
    ButtonDiv,
};
