import { SELECTION_GRID_COLUMN } from 'common/utils/tableUtils';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ICdrExclusion } from '../FindingsTable/Modals/CdrExclusion/CdrExclusion.interface';
import { CDR_EXCLUSION_FIELD_NAMES } from '../FindingsTable/Modals/CdrExclusion/CdrExclusion.consts';
import { CdrExclusionRulesetNameCellRender } from './renderers/CdrExclusionRulesetNameCellRender';
import { cdrTrans } from '../../Intelligence.utils';


export const getCdrExclusionsColumns = (): CGColDef<ICdrExclusion>[] => {
    return [
        SELECTION_GRID_COLUMN,
        {
            colId: CDR_EXCLUSION_FIELD_NAMES.RULESET_NAME,
            field: CDR_EXCLUSION_FIELD_NAMES.RULESET_NAME,
            sortable: true,
            headerName: cdrTrans('CDR_EXCLUSIONS.TABLE.RULESET_NAME.HEADER'),
            cellRenderer: CdrExclusionRulesetNameCellRender,
            minWidth: 200,
            flex: 10,
            sort: 'asc',
        },
        {
            colId: CDR_EXCLUSION_FIELD_NAMES.ID,
            field: CDR_EXCLUSION_FIELD_NAMES.ID,
            sortable: true,
            headerName: cdrTrans('CDR_EXCLUSIONS.TABLE.EXCLUSION_ID.HEADER'),
            minWidth: 100,
            flex: 3,
            sort: 'asc',
        },
        {
            colId: CDR_EXCLUSION_FIELD_NAMES.COMMENT,
            field: CDR_EXCLUSION_FIELD_NAMES.COMMENT,
            headerName: cdrTrans('CDR_EXCLUSIONS.TABLE.COMMENT.HEADER'),
            sortable: true,
            minWidth: 120,
            flex: 10,
        },
        {
            colId: CDR_EXCLUSION_FIELD_NAMES.ORG_UNIT_IDS,
            field: CDR_EXCLUSION_FIELD_NAMES.ORG_UNIT_IDS,
            headerName: cdrTrans('CDR_EXCLUSIONS.TABLE.ORG_UNIT_IDS.HEADER'),
            sortable: true,
            minWidth: 120,
            flex: 10,
        },
        {
            colId: CDR_EXCLUSION_FIELD_NAMES.ENV_IDS,
            field: CDR_EXCLUSION_FIELD_NAMES.ENV_IDS,
            headerName: cdrTrans('CDR_EXCLUSIONS.TABLE.ENV_IDS.HEADER'),
            sortable: true,
            minWidth: 120,
            flex: 10,
        },
        {
            colId: CDR_EXCLUSION_FIELD_NAMES.RULE_IDS,
            field: CDR_EXCLUSION_FIELD_NAMES.RULE_IDS,
            headerName: cdrTrans('CDR_EXCLUSIONS.TABLE.RULE_IDS.HEADER'),
            sortable: true,
            minWidth: 120,
            flex: 10,
        },
        {
            colId: CDR_EXCLUSION_FIELD_NAMES.SEVERITIES,
            field: CDR_EXCLUSION_FIELD_NAMES.SEVERITIES,
            headerName: cdrTrans('CDR_EXCLUSIONS.TABLE.SEVERITIES.HEADER'),
            sortable: true,
            minWidth: 120,
            flex: 10,
        },
    ];
};
