import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DashboardFilterPanel } from '../BaseDashboard/DashboardFilterPanel';
import { ExposureStyled } from './ExposureDashboard.styled';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    EXPOSURE_DASHBOARD_FILTERS,
    isFilterRelevantToMaliciousAssets,
    isFilterRelevantToNonMfa,
    isFilterRelevantToSecurityEvents,
    isFilterRelevantToThirdParty,
} from './ExposureDashboardFilterData';
import { CONTEXT_FIELD_FULL_NAMES, IAM_EXPOSURE_VALUES, NETWORK_EXPOSURE_VALUES, } from '../../../consts';
import TotalCounter from '../TotalWidget/TotalCounter';
import DonutPie from '../../../../../common/erm-components/custom/DonutPie/DonutPie';
import {
    IDataState, IExposureSectionInfo, IExposureSectionWidgetInfo,
    IMaliciousAssetInfo,
} from './ExposureDashboard.interface';
import { assetsToListItems, typesToPieItems } from './ExposureDashboard.utils';
import { IAggregationDataItem } from 'common/interface/data_services';
import { IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import { IFieldInfo, LoadingState } from 'common/interface/general';
import SecurityEventsTable from '../../SecurityEvents/SecurityEventsTable/SecurityEventsTable';
import SimpleList from '../SimpleList/SimpleList';
import { ermTrans } from '../../../RiskManagement.utils';
import {
    getMaliciousAssets, getNonMfaFindingsCount,
    getPublicNetworkTop10Types,
    getRoleThirdPartyCount,
    getTotalAggregations,
    MAX_SECURITY_EVENTS_COUNT, NON_MFA_FILTER_FIELDS, NON_MFA_FREE_TEXT,
} from './ExposureDashboardDatasource';
import { isFilterFieldsChanged } from 'common/utils/filterUtils';
import { FindingsAlertType } from 'common/components/Findings/Findings.interface';
import { ExposureSection } from './ExposureSection';
import {
    updateSecurityEvents,
    handleSecurityEventsData,
    incSecEventReqCounter
} from '../../SecurityEvents/securityEventsUtils';
import { IFindingItem, ISecurityEventsDataState } from '../../SecurityEvents/SecurityEvents.interface';
import { Stack } from 'common/design-system/components-v2';
import DonutLegend from '../../../../../common/erm-components/custom/DonutPie/DonutLegend';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

const TOP_SECURITY_EVENTS_URL = 'erm/exposure/top-security-events';

const ExposureDashboard: React.FC = () => {
    const [networkPublicData, setNetworkPublicData] = useState<IDataState<number>>({ loadingState: LoadingState.IS_LOADING });
    const [networkPartialData, setNetworkPartialData] = useState<IDataState<number>>({ loadingState: LoadingState.IS_LOADING });
    const [networkTop10Types, setNetworkTop10Types] = useState<IDataState<IGenericWidgetDataItem[]>>({ loadingState: LoadingState.IS_LOADING });
    const [iamPublicData, setIamPublicData] = useState<IDataState<number>>({ loadingState: LoadingState.IS_LOADING });
    const [iamUsersWoutMfa, setIamUsersWoutMfa] = useState<IDataState<number>>({ loadingState: LoadingState.IS_LOADING });
    const [iamRolesThirdParty, setIamRolesThirdParty] = useState<IDataState<number>>({ loadingState: LoadingState.IS_LOADING });
    const [realtimeSecurityEvents, setRealtimeSecurityEvents] = useState<ISecurityEventsDataState<IFindingItem[]>>({ loadingState: LoadingState.IS_LOADING });
    const [realtimeMaliciousAssets, setRealtimeMaliciousAssets] = useState<IDataState<IGenericWidgetDataItem[]>>({ loadingState: LoadingState.IS_LOADING });
    const filterFieldsRef = useRef<IFieldInfo[] | undefined>();
    const aggregationsRef = useRef<Aggregations | undefined>();

    const networkPublicWidgetInfo: IExposureSectionWidgetInfo = useMemo(() => {
        const onClick = () => getErmUrlsService().goToProtectedAssetsTable([
            {
                name: CONTEXT_FIELD_FULL_NAMES.networkExposure,
                value: [NETWORK_EXPOSURE_VALUES.PUBLIC],
            },
        ]);
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.NETWORK_EXPOSURE.WIDGETS.PUBLIC_ASSETS'),
            content: <TotalCounter value={networkPublicData.value ?? 0} icon={'web'} onClick={onClick} />,
            loadingState: networkPublicData.loadingState,
            width: 25,
        };
    }, [networkPublicData.value, networkPublicData.loadingState]);
    const networkPartialWidgetInfo: IExposureSectionWidgetInfo = useMemo(() => {
        const onClick = () => getErmUrlsService().goToProtectedAssetsTable([
            {
                name: CONTEXT_FIELD_FULL_NAMES.networkExposure,
                value: [NETWORK_EXPOSURE_VALUES.PARTIALLY_PUBLIC],
            },
        ]);
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.NETWORK_EXPOSURE.WIDGETS.PARTIALLY_PUBLIC_ASSETS'),
            content: <TotalCounter value={networkPartialData.value ?? 0} icon={'semiPublic'}
                onClick={onClick} />,
            loadingState: networkPartialData.loadingState,
            width: 25,
        };
    }, [networkPartialData.value, networkPartialData.loadingState]);
    const networkTop10WidgetInfo: IExposureSectionWidgetInfo = useMemo(() => {
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.NETWORK_EXPOSURE.WIDGETS.TOP_10_PUBLIC_ASSET_TYPES'),
            content: (
                <Stack direction={'row'} alignItems={'center'} spacing={4} padding={4}>
                    <DonutPie items={networkTop10Types.value} settings={{ size: 140, thickness: 12 }} />
                    <DonutLegend items={networkTop10Types.value} />
                </Stack>),
            loadingState: networkTop10Types.loadingState,
            width: 50,
        };
    }, [networkTop10Types.value, networkTop10Types.loadingState]);
    const networkExposureSection: IExposureSectionInfo = useMemo(() => {
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.NETWORK_EXPOSURE.TITLE'),
            widgetInfos: [networkPublicWidgetInfo, networkPartialWidgetInfo, networkTop10WidgetInfo],
            height: 240,
        };
    }, [networkPublicWidgetInfo, networkPartialWidgetInfo, networkTop10WidgetInfo]);

    const iamPublicWidgetInfo: IExposureSectionWidgetInfo = useMemo(() => {
        const onClick = () => getErmUrlsService().goToProtectedAssetsTable([
            {
                name: CONTEXT_FIELD_FULL_NAMES.iamExposure,
                value: [IAM_EXPOSURE_VALUES.PUBLIC],
            },
        ]);
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.IAM_EXPOSURE.WIDGETS.PUBLIC_ASSETS'),
            content: <TotalCounter value={iamPublicData.value ?? 0} icon={'web'} onClick={onClick} />,
            loadingState: iamPublicData.loadingState,
            width: 33,
        };
    }, [iamPublicData.value, iamPublicData.loadingState]);
    const iamNoMfaWidgetInfo: IExposureSectionWidgetInfo = useMemo(() => {
        const onClick = () => {
            getErmUrlsService().goToEventsTable(FindingsAlertType.FINDINGS, NON_MFA_FREE_TEXT, NON_MFA_FILTER_FIELDS);
        };
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.IAM_EXPOSURE.WIDGETS.USERS_WOUT_MFA_ENABLED'),
            content: <TotalCounter value={iamUsersWoutMfa.value ?? 0} icon={'user'} onClick={onClick} />,
            loadingState: iamUsersWoutMfa.loadingState,
            width: 34,
        };
    }, [iamUsersWoutMfa.value, iamUsersWoutMfa.loadingState]);
    const iam3rdPartyWidgetInfo: IExposureSectionWidgetInfo = useMemo(() => {
        const onClick = () => getErmUrlsService().goToProtectedAssetsTable([
            {
                name: 'type',
                value: ['aws|IamRole'],
            },
            {
                name: 'assetLabels',
                value: ['ThirdParty'],
            },
        ]);
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.IAM_EXPOSURE.WIDGETS.THIRD_PARTIES'),
            content: <TotalCounter value={iamRolesThirdParty.value ?? 0} icon={'thirdParty'}
                subtitle={ermTrans('EXPOSURE_DASHBOARD.SECTIONS.IAM_EXPOSURE.WIDGETS.AWS_IAM_ROLES_USED_BY_THIRD_PARTIES')}
                onClick={onClick} />,
            loadingState: iamRolesThirdParty.loadingState,
            width: 33,
        };
    }, [iamRolesThirdParty.value, iamRolesThirdParty.loadingState]);
    const iamExposureSection: IExposureSectionInfo = useMemo(() => {
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.IAM_EXPOSURE.TITLE'),
            widgetInfos: [iamPublicWidgetInfo, iamNoMfaWidgetInfo, iam3rdPartyWidgetInfo],
            height: 200,
        };
    }, [iamPublicWidgetInfo, iamNoMfaWidgetInfo, iam3rdPartyWidgetInfo]);

    const rtEventsTableWidgetInfo: IExposureSectionWidgetInfo = useMemo(() => {
        const title = ermTrans('EXPOSURE_DASHBOARD.SECTIONS.REAL_TIME.WIDGETS.LAST_SECURITY_EVENTS');
        const items = realtimeSecurityEvents.value || [];
        return {
            title: title,
            content: <SecurityEventsTable items={items} componentName={title}
                isSearching={realtimeSecurityEvents.isSearching} />,
            loadingState: realtimeSecurityEvents.loadingState,
            width: 75,
        };
    }, [realtimeSecurityEvents]);
    const rtMaliciousAssetsWidgetInfo: IExposureSectionWidgetInfo = useMemo(() => {
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.REAL_TIME.WIDGETS.TOP_5_MALICIOUS_ASSETS'),
            content: <SimpleList items={realtimeMaliciousAssets.value || []} />,
            loadingState: realtimeMaliciousAssets.loadingState,
            width: 25,
        };
    }, [realtimeMaliciousAssets.value, realtimeMaliciousAssets.loadingState]);
    const realTimeSection: IExposureSectionInfo = useMemo(() => {
        return {
            title: ermTrans('EXPOSURE_DASHBOARD.SECTIONS.REAL_TIME.TITLE'),
            widgetInfos: [rtEventsTableWidgetInfo, rtMaliciousAssetsWidgetInfo],
            height: 580,
        };
    }, [rtEventsTableWidgetInfo, rtMaliciousAssetsWidgetInfo]);

    const handleTotalData = (loadingState: LoadingState, aggregations?: Aggregations) => {
        const newNetworkPublicData: IDataState = { loadingState };
        const newNetworkPartialData: IDataState = { loadingState };
        const newIamPublicData: IDataState = { loadingState };
        if (loadingState === LoadingState.LOADING_SUCCEEDED) {
            const networkAggregations = (aggregations?.[CONTEXT_FIELD_FULL_NAMES.networkExposure] || []) as IAggregationDataItem[];
            const networkPublicItem = networkAggregations?.find(item => item.value === NETWORK_EXPOSURE_VALUES.PUBLIC);
            newNetworkPublicData.value = networkPublicItem?.count ?? 0;
            const networkPartialItem = networkAggregations?.find(item => item.value === NETWORK_EXPOSURE_VALUES.PARTIALLY_PUBLIC);
            newNetworkPartialData.value = networkPartialItem?.count ?? 0;
            const iamAggregations = (aggregations?.[CONTEXT_FIELD_FULL_NAMES.iamExposure] || []) as IAggregationDataItem[];
            const iamPublicItem = iamAggregations?.find(item => item.value === IAM_EXPOSURE_VALUES.PUBLIC);
            newIamPublicData.value = iamPublicItem?.count ?? 0;
        }
        setNetworkPublicData(newNetworkPublicData);
        setNetworkPartialData(newNetworkPartialData);
        setIamPublicData(newIamPublicData);
    };

    const handleTop10PublicTypes = (loadingState: LoadingState, aggregations?: Aggregations) => {
        const newNetworkTop10Types: IDataState<IGenericWidgetDataItem[]> = {
            loadingState,
        };
        if (loadingState === LoadingState.LOADING_SUCCEEDED) {
            const typesAggregations = (aggregations?.type || []) as IAggregationDataItem[];
            newNetworkTop10Types.value = typesToPieItems(typesAggregations.slice(0, 10), networkTop10WidgetInfo.title);
        }
        setNetworkTop10Types(newNetworkTop10Types);
    };

    const handleRolesThirdParty = (loadingState: LoadingState, roleCount?: number) => {
        const newIamRolesThirdParty: IDataState<number> = {
            loadingState,
        };
        if (loadingState === LoadingState.LOADING_SUCCEEDED) {
            newIamRolesThirdParty.value = roleCount ?? 0;
        }
        setIamRolesThirdParty(newIamRolesThirdParty);
    };

    const handleUsersWoutMfa = (loadingState: LoadingState, count?: number) => {
        const newIamUsersWoutMfa: IDataState<number> = {
            loadingState,
        };
        if (loadingState === LoadingState.LOADING_SUCCEEDED) {
            newIamUsersWoutMfa.value = count ?? 0;
        }
        setIamUsersWoutMfa(newIamUsersWoutMfa);
    };

    const handleMaliciousAssets = (loadingState: LoadingState, assets?: IMaliciousAssetInfo[]) => {
        const newRealtimeMaliciousAssets: IDataState<IGenericWidgetDataItem[]> = {
            loadingState,
        };
        if (loadingState === LoadingState.LOADING_SUCCEEDED) {
            newRealtimeMaliciousAssets.value = assetsToListItems(assets || [], ermTrans('EXPOSURE_DASHBOARD.SECTIONS.REAL_TIME.WIDGETS.TOP_5_MALICIOUS_ASSETS'));
        }
        setRealtimeMaliciousAssets(newRealtimeMaliciousAssets);
    };

    useEffect(() => {
        return () => {
            incSecEventReqCounter();
        };
    }, []);

    const getDashboardAggregations = (filterValues: IFiltersValues): Promise<Aggregations> => {
        const oldFilterFields: IFieldInfo[] | undefined = filterFieldsRef.current;
        const newFilterFields: IFieldInfo[] = filterValues?.fields || [];
        filterFieldsRef.current = newFilterFields;
        const filterChanged = !oldFilterFields || isFilterFieldsChanged(oldFilterFields, newFilterFields);

        if (filterChanged) {
            handleTop10PublicTypes(LoadingState.IS_LOADING);
            getPublicNetworkTop10Types(filterValues).then(aggregations => {
                handleTop10PublicTypes(LoadingState.LOADING_SUCCEEDED, aggregations);
            }).catch(() => {
                handleTop10PublicTypes(LoadingState.LOADING_FAILED);
            });
        }

        if (!oldFilterFields || isFilterRelevantToThirdParty(oldFilterFields, newFilterFields)) {
            handleRolesThirdParty(LoadingState.IS_LOADING);
            getRoleThirdPartyCount(filterValues).then(roleCount => {
                handleRolesThirdParty(LoadingState.LOADING_SUCCEEDED, roleCount);
            }).catch(() => {
                handleRolesThirdParty(LoadingState.LOADING_FAILED);
            });
        }

        if (!oldFilterFields || isFilterRelevantToNonMfa(oldFilterFields, newFilterFields)) {
            handleUsersWoutMfa(LoadingState.IS_LOADING);
            getNonMfaFindingsCount(filterValues).then((count: number) => {
                handleUsersWoutMfa(LoadingState.LOADING_SUCCEEDED, count);
            }).catch(() => {
                handleUsersWoutMfa(LoadingState.LOADING_FAILED);
            });
        }

        if (!oldFilterFields || isFilterRelevantToSecurityEvents(oldFilterFields, newFilterFields)) {
            handleSecurityEventsData(setRealtimeSecurityEvents, LoadingState.IS_LOADING);
            updateSecurityEvents(setRealtimeSecurityEvents, incSecEventReqCounter(), 0, filterValues, MAX_SECURITY_EVENTS_COUNT, TOP_SECURITY_EVENTS_URL);
        }

        if (!oldFilterFields || isFilterRelevantToMaliciousAssets(oldFilterFields, newFilterFields)) {
            handleMaliciousAssets(LoadingState.IS_LOADING);
            getMaliciousAssets(filterValues).then(assets => {
                handleMaliciousAssets(LoadingState.LOADING_SUCCEEDED, assets);
            }).catch(() => {
                handleMaliciousAssets(LoadingState.LOADING_FAILED);
            });
        }

        if (filterChanged) {
            handleTotalData(LoadingState.IS_LOADING);
            return getTotalAggregations(filterValues).then(aggregations => {
                aggregationsRef.current = aggregations;
                handleTotalData(LoadingState.LOADING_SUCCEEDED, aggregations);
                return aggregations;
            }).catch(() => {
                aggregationsRef.current = undefined;
                handleTotalData(LoadingState.LOADING_FAILED);
                return {};
            });
        } else {
            return Promise.resolve(aggregationsRef.current || {});
        }
    };

    return (
        <ExposureStyled.TopDiv>
            <DashboardFilterPanel filterId={'exposure-dashboard-filter-panel'} getAggregations={getDashboardAggregations} filterTypes={EXPOSURE_DASHBOARD_FILTERS} />
            <ExposureStyled.BodyDiv>
                <ExposureSection sectionInfo={networkExposureSection} />
                <ExposureSection sectionInfo={iamExposureSection} />
                <ExposureSection sectionInfo={realTimeSection} />
            </ExposureStyled.BodyDiv>
        </ExposureStyled.TopDiv>
    );
};

export default ExposureDashboard;
