import { TFunction } from 'i18next';
import { EntityTypesEnum } from './remediation.interfaces';

export const I18nRemediation = 'remediation-modals';
export const entityTypes = (t: TFunction) => [
    {
        dataAid: 'entityType-name',
        value: EntityTypesEnum.byName,
        name: 'entityTypes',
        label: t('REMEDIATION.MODALS.ADD_EDIT_MODAL.ENTITY_NAME'),
    },
    {
        dataAid: 'entityType-id',
        value: EntityTypesEnum.byID,
        name: 'entityTypes',
        label: t('REMEDIATION.MODALS.ADD_EDIT_MODAL.ENTITY_ID'),
    },
];