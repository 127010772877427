import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter, renderSavedFilters,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';

import { NotificationsTableRegistry } from '../NotificationsTableRegestries';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { INotificationsFilterParams } from '../Interfaces/NotificationPageInterfaces';
import i18n from 'common/services/translations/translations';
import { ASSOCIATIONS, I18nNotifications, STATUS, TYPES } from '../NotificationsPage.consts';

const filterDefsAddins: () => Addin<IFilterProps>[] = () =>
    [
        {
            id: FILTERS_KEYS.ADD_FILTER,
            content: {
                filterProps: { key: FILTERS_KEYS.ADD_FILTER },
                renderFunction: renderAddFilter,
            },
        },
        {
            id: FILTERS_KEYS.FREE_TEXT,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                    title: i18n.t('FILTERS.FREE_TEXT', { ns: I18nNotifications }),
                },
                renderFunction: renderDefaultFreeTextFilter,
            },
        },
        {
            id: STATUS,
            content: function( { aggregations } : INotificationsFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[STATUS],
                        key: STATUS,
                        title: i18n.t('FILTERS.STATUS', { ns: I18nNotifications }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: TYPES,
            content: function( { aggregations } : INotificationsFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[TYPES],
                        key: TYPES,
                        title: i18n.t('FILTERS.TYPES', { ns: I18nNotifications }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: ASSOCIATIONS,
            content: function( { aggregations } : INotificationsFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[ASSOCIATIONS],
                        key: ASSOCIATIONS,
                        title: i18n.t('FILTERS.ASSOCIATIONS', { ns: I18nNotifications }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FILTERS_KEYS.SAVED_FILTERS,
            content: function({ filtersInitialData, savedFiltersComponentName }) {
                return {
                    filterProps: {
                        savedFilters: filtersInitialData?.savedFilters || [],
                        savedFiltersComponentName,
                        selectedFilterID: '',
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                };
            },
        },
        {
            id: FILTERS_KEYS.CLEAR_BUTTON,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
            },
        },
    ];


export default function initializeFilters() {
    NotificationsTableRegistry.addFilterAddins(filterDefsAddins());
}
