import React, { useEffect, useRef, useState } from 'react';
import {
    closingParenthesesConnector,
    getNextOptions,
    initProperties,
    Item,
    startingParenthesesConnector
} from './ruleBuilder';
import { getAllTypes, getGenericList, getIPList } from './logic_api';
import './GSLBuilderComponent.scss';
import JsonViewer from 'common/components/jsonViewer/jsonViewer';
import { AssetType, AssetTypesDataSourceMap } from 'modules/Intelligence/Widgets/Consts';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';
import { Button, Tooltip, Spinner } from 'common/design-system/components-v2';
type UserInputType = 'single'|'multiple';


export const GSLBuilderComponent: React.FC<{ predefinedRuleText:string, updateSentence:(val:string)=>void, assetType?:AssetType }> = ({ predefinedRuleText='',updateSentence,assetType = 'flowlogs' }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const gslValidAssetType = AssetTypesDataSourceMap[assetType];
    const rootItems:Item[] = [{ name: gslValidAssetType,type: 'root' },{ name: 'where',type: 'action' }];
    const userInputRef = useRef<any>();
    const userFreeTextInputRef = useRef<any>();
    const userVariableInputRef = useRef<any>();
    const [showUserInput,setShowUserInput] = useState<boolean>(false);
    const [nextOption,setNextOption] = useState<Item[]|null>();
    const [sentence,setSentence] = useState<Item[]>(rootItems);
    const [rootObject,setRootObject] = useState();
    const [nextProperties,setNextProperties] = useState<Item[]>();
    const [GSLInputTypeBuilder,setGSLInputTypeBuilder] = useState<boolean>(false);
    const [userInputType,setUserInputType] = useState<UserInputType>();
    const [isMultipleValuesList,setIsMultipleValuesList] = useState<boolean>(false);
    const [userInputValuesList,setUserInputValuesList] = useState<string[]>([]);
    const [predefinedGSLText,setPredefinedGSLText] = useState<string>(predefinedRuleText);
    const gslTextSentenceRef = useRef<any>();

    const init = async ()=>{
        const ipList :any = await getIPList();
        const genericList:any = await getGenericList();
        let optionalUserValues = [...ipList,...genericList];
        optionalUserValues = optionalUserValues.map((val:any)=> {return { type: 'variable' , name: val.name } as Item;});
        const allTypes:any = await getAllTypes();
        if(allTypes) {
            const asset = allTypes[assetType];
            setRootObject(asset);
            await initProperties(asset,optionalUserValues);
            const options = getNextOptions(sentence);
            setNextOption(options);
        }
    };
    useEffect(()=>{
        init();
    },[]);

    useEffect(()=>{
        updateSentence(gslTextSentenceRef.current.innerText || predefinedGSLText);
    },[sentence]);

    useEffect(()=>{
        setNextProperties(nextOption?.filter(n=>n.type === 'property' && n.valueType !== 'time'));
    },[nextOption]);

    function shouldMultipleValuesBeStrings() {
        return sentence.length > 3 && (sentence[sentence.length-3].valueType === 'string' || sentence[sentence.length-2].valueType === 'string');
    }

    function addValueToList(value:string){
        userFreeTextInputRef.current.value = '';
        userInputValuesList?.push(value);
        setUserInputValuesList([...userInputValuesList]);
    }
    function addVariableToList(value:string){
        userInputValuesList?.push(value);
        setUserInputValuesList([...userInputValuesList]);
    }


    function addItem(item:Item) {
        item && sentence.push(item);
        setSentence([...sentence]);
        const options = getNextOptions(sentence);
        if(options)
        {
            if(options.find(a=>a.name === 'inputList')){
                setUserInputValuesList([]);
                setUserInputType('multiple');
                setShowUserInput(true);
            }
            setNextOption(options);
        }else{
            if(userInputRef?.current){
                userInputRef.current.value = '';
            }
            setUserInputType('single');
            setShowUserInput(true);
        }
    }

    function userMultipleInputsChanged(list:string[]){
        const input : Item = {
            type: 'value',
            name: ''
        };
        if(isMultipleValuesList){
            input.name =list.map(item=>'$'+item).join(',');
        }else{
            if(shouldMultipleValuesBeStrings()) {
                input.name = list.map(item => '\'' + item + '\'').join(',');
            }else{
                input.name = list.join(',');
            }
        }
        sentence.push(startingParenthesesConnector);
        sentence.push(input);
        sentence.push(closingParenthesesConnector);
        setSentence([...sentence]);
        const options = getNextOptions(sentence);
        setNextOption(options);
        setShowUserInput(false);
    }

    function userInputChanged(el:any) {
        const input : Item = {
            name: el?.current?.value,
            type: 'value',
        };
        sentence.push(input);
        setSentence([...sentence]);
        const options = getNextOptions(sentence);
        setNextOption(options);

        setShowUserInput(false);
    }

    function clearValues() {
        const _sentence : Item[] = rootItems;
        setSentence([..._sentence]);
        const options = getNextOptions(_sentence);
        setNextOption(options);
        setPredefinedGSLText('');
    }


    function undoLastAction() {
        sentence.pop();
        setSentence([...sentence]);
        const options = getNextOptions(sentence);
        setNextOption(options);
    }

    function isRootProperty(name: string) {
        return rootObject && Object.hasOwn(rootObject,name);
    }

    function setInFunctionContainer(forVariables: boolean) {
        setUserInputValuesList([]);
        setIsMultipleValuesList(forVariables);
        addItem({ name: 'inputList',type: 'inOperator' } as Item);

    }

    function gslFreeTextChanged(value: string) {
        setPredefinedGSLText(value);
        updateSentence(value);
    }

    function promptUserForInputTypeChange(isGSLInputTypeBuilder: boolean) {
        if(sentence.length > 2 || predefinedGSLText.length){
            if(confirm(t('WIDGETS.GSL_BUILDER.CHANGE_INPUT_TYPE_PROMPT'))){
                setGSLInputTypeBuilder(isGSLInputTypeBuilder);
                clearValues();
            }
        }else {
            setGSLInputTypeBuilder(isGSLInputTypeBuilder);
        }

    }

    return (
        <div className='GSLBuilder'>
            <div className='editor' >
                <div className='gsl-builder-choices'>
                    <div className='mr-5 flex flex-center' ><input type='radio' value='Builder' checked={GSLInputTypeBuilder} onChange={()=>promptUserForInputTypeChange(true)} /><span>{t('WIDGETS.GSL_BUILDER.BUILDER')}</span></div>
                    <div className='mr-5 flex flex-center' ><input type='radio' value='Free Text' checked={!GSLInputTypeBuilder} onChange={()=>promptUserForInputTypeChange(false)} /><span> {t('WIDGETS.GSL_BUILDER.FREE_TEXT')}</span></div>
                </div>
                <div ref={gslTextSentenceRef} >
                    { !GSLInputTypeBuilder ? <div className='gsl-sentence-free-text' > <textarea onChange={(e)=>gslFreeTextChanged(e.target.value)} value={predefinedGSLText} placeholder={t('WIDGETS.GSL_BUILDER.GSL_INPUT_PLACEHOLDER')} /> </div>:
                        <div className='gsl-editor'>
                            {
                                sentence?.map((s:Item,index)=> <>
                                    {(!isRootProperty(s.name) && s.valueType && sentence[index-1].name !== '[') ? <span>.</span> : ' '}
                                    {(s.type === 'variable' && sentence[index-1].type !== 'inOperator') ? <span>,</span> : ''}
                                    {(s.type === 'variable') ? <span>$</span> : ''}
                                    {(s.type === 'value' && sentence[index-2].valueType === 'string') ? <span>'</span> : '' }
                                    {(s.type !== 'inOperator') ?<span className='gsl' key={s.name}>{s.name}</span> : ''}
                                    {(s.type === 'value' && sentence[index-2].valueType === 'string') ? <span>'</span> : '' }
                                </>)
                            }
                        </div>
                    }
                </div>


                { GSLInputTypeBuilder &&
                    <>
                        <div className='flex'>
                            <Button variant='text' onClick={clearValues}>{t('WIDGETS.GSL_BUILDER.CLEAR')}</Button>
                            <Button variant='text' onClick={undoLastAction}>{t('WIDGETS.GSL_BUILDER.UNDO')}</Button>
                        </div>
                        <div className='section'>
                            <p className='title'>{t('WIDGETS.GSL_BUILDER.OPERATORS')}</p>
                            <Tooltip visible={showUserInput} placement={'right'} interactive={true}
                                content={
                                    <>
                                        {userInputType === 'single' &&
                                            <div className='gsl-input-popover'>
                                                <div className='user-input'><span>{sentence[sentence.length-2].name} {sentence[sentence.length-1].name }
                                                </span><input type='text' ref={userInputRef} /></div>
                                                <div className='actions'>
                                                    <Button className='mx-3' onClick={()=>userInputChanged(userInputRef)} >{t('WIDGETS.GSL_BUILDER.SAVE')}</Button>
                                                    <Button onClick={()=>setShowUserInput(false)} >{t('WIDGETS.GSL_BUILDER.CANCEL')}</Button>
                                                </div>
                                            </div>
                                        }

                                        {userInputType === 'multiple' &&
                                            <div className='gsl-input-popover'>
                                                <div className='gsl-builder-choices'>
                                                    <div className='mr-5 flex flex-center'><input type='radio' value='list' checked={isMultipleValuesList} onChange={()=> setInFunctionContainer(true)} /><span>{t('WIDGETS.GSL_BUILDER.LIST')}</span></div>
                                                    <div className='mr-5 flex flex-center'><input type='radio' value='value' checked={!isMultipleValuesList} onChange={()=>setInFunctionContainer(false)} /><span>{t('WIDGETS.GSL_BUILDER.VALUE')}</span></div>
                                                </div>
                                                { !isMultipleValuesList ?
                                                    <div className='user-input'><input type='text' ref={userFreeTextInputRef} />
                                                        <Button color='brandPrimary' onClick={()=>addValueToList(userFreeTextInputRef.current.value)}>{t('WIDGETS.GSL_BUILDER.PLUS_ADD')}</Button>
                                                    </div> :
                                                    <div className='user-input'>
                                                        {nextOption ? <select ref={userVariableInputRef}>
                                                            {
                                                                nextOption?.filter(n=>n.type === 'variable').map(s=> <option key={s.name}>{s.name}</option>)
                                                            }
                                                        </select> : ''}
                                                        <Button color='brandPrimary' onClick={()=>addVariableToList(userVariableInputRef.current.value)}>{t('WIDGETS.GSL_BUILDER.PLUS_ADD')}</Button>
                                                    </div>
                                                }
                                                {userInputValuesList?.length ? <div>
                                                    <span>Preview: in(</span>{userInputValuesList?.map((item,index)=><span key={item}>
                                                        <span >{index === 0 ? '' : ','}{!isMultipleValuesList? '' : '$'}{shouldMultipleValuesBeStrings() ? <span>'</span> : ''}
                                                            <span >{item}</span>
                                                            {shouldMultipleValuesBeStrings() ? <span>'</span> : ''}
                                                        </span>
                                                    </span>)}
                                                    <span>)</span>
                                                </div> : ''}
                                                <div className='actions'>
                                                    <Button onClick={()=>userMultipleInputsChanged(userInputValuesList)} >{t('WIDGETS.GSL_BUILDER.SAVE')}</Button>
                                                    <Button onClick={()=>setShowUserInput(false)} >{t('WIDGETS.GSL_BUILDER.CANCEL')}</Button>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }>
                                <div className='flex w-fit'>
                                    {nextOption?.filter(n=>n.type === 'connector' || n.type === 'operator' || n.type === 'arrayOperator').
                                        map(s=> <Button variant='text' onClick={()=>addItem(s)} key={s.name}>{s.name}</Button>)}
                                </div>
                            </Tooltip>

                        </div>
                        {nextProperties?.length ? <div className='section'>
                            <p className='title'>{t('WIDGETS.GSL_BUILDER.PROPERTIES')}</p>
                            <div className='properties-section'>
                                {nextProperties.map(prop=>
                                    <div key={prop.name} className='property-container'>
                                        <span className='attribute'>{prop.valueType}</span>
                                        <span className='property' onClick={()=>addItem(prop)} >{prop.name}</span>
                                    </div>)}
                            </div>
                        </div> : '' }

                        <div className='section'>
                            <p className='title'>{t('WIDGETS.GSL_BUILDER.FUNCTION')}</p>
                            <div className='flex'>
                                {nextOption?.filter(n=>n.type === 'function').map(s=> <Button className='m-1' variant='text' onClick={()=>addItem(s)} key={s.name}>{s.name}()</Button>)}
                            </div>

                        </div>

                    </>
                }
            </div>
            <div className='objectViewer'>
                {rootObject ? <JsonViewer json={rootObject} /> : <span className='loader'><Spinner /><span>{t('WIDGETS.GSL_BUILDER.LOADING_OBJECT')}</span></span>}
            </div>
        </div>
    );


};
