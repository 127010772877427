import React from 'react';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import DataAssetsCategories from './DataAssetCategories/DataAssetsCategories';
import SensitiveDataChart from './SensitiveDataChart/SensitiveDataChart';
import { DataClassification } from './DataClassification/DataClassification';
import { InventoryStyled } from './DataInventory.styled';
import { IEnvironmentsEmptyStateInfo } from '../../../../RiskManagement.interface';

const DataInventory : React.FC<{ emptyEnvStateInfo?: IEnvironmentsEmptyStateInfo, filterValues?: IFiltersValues }> = ({ emptyEnvStateInfo, filterValues }) => {
    return (
        <InventoryStyled.TopDiv>
            <InventoryStyled.BodyDiv spacing={6}>
                <DataAssetsCategories filterValues={filterValues}/>
                <SensitiveDataChart filterValues={filterValues} emptyEnvStateInfo={emptyEnvStateInfo}/>
                <DataClassification filterValues={filterValues} emptyEnvStateInfo={emptyEnvStateInfo}/>
            </InventoryStyled.BodyDiv>
        </InventoryStyled.TopDiv>
    );
};

export default DataInventory;