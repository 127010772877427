import { NotificationType } from 'common/interface/notifications';
import {
    getHttpService,
    getNotificationsService,
    getStoreService,
    ICustomzationObject
} from 'common/interface/services';
import { IDashboard } from 'common/module_interface/overview/Interface';
import i18next from 'i18next';
import {
    addIdsToSectionsAndWidgets,
    isDefaultDashboardSetupDone,
    refreshAllDashboardsInStore,
    setupDefaultDashboard,
    updateDashboardInStore
} from './Utils';
import { i18nOverviewNamespace } from './initialize.i18n';
import { setAllDashboardsFromServer, setSelectedDashboardAsInServer } from './Overview.reducer';
import { deepCloneObject } from 'common/utils/objectUtils';
import {
    ALL_DASHBOARDS_CACHE_TAG,
    DEFAULT_DASHBOARD_CACHE_TAG, FAVORITES_DASHBOARD_CACHE_TAG,
    HOME_DASHBOARD_DEFAULT_CUSTOMIZATION_KEY
} from 'common/module_interface/overview/Consts';

const baseRoute = 'pageDashboards';
const dashboardsApiUrl = `${baseRoute}/HOME_DASHBOARD_STATE`;
const customizationRoute = 'customization';

export type TDashboardResponse = IDashboard[];

export const getAllDashboardsFromServer = async (): Promise<TDashboardResponse> => {
    try {
        return await getHttpService().request<TDashboardResponse>(dashboardsApiUrl,
            { method: 'GET' },
            { cachingConfig:
                    {
                        useCache: true,
                        tags: [ALL_DASHBOARDS_CACHE_TAG],
                    }
            });
    } catch (error) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: i18next.t('DASHBOARD.MESSAGES.ERROR_OCCURRED', { ns: i18nOverviewNamespace }),
            text: i18next.t('DASHBOARD.MESSAGES.UNABLE_TO_LOAD_DASHBOARDS', { ns: i18nOverviewNamespace }),
        });
        throw new Error();
    }

};

export const updateDashboardInServer = async (dashboard: IDashboard, updateStore = false): Promise<TDashboardResponse> => {
    const { dispatch } = getStoreService().getReduxTools();

    try {
        getHttpService().clearCacheByTag(ALL_DASHBOARDS_CACHE_TAG, 'GET');
        const response = await getHttpService().put<TDashboardResponse>(dashboardsApiUrl, { data: [dashboard] });
        const allDashboardsFromServer = deepCloneObject(response);
        const dashboardsFromServer = allDashboardsFromServer && addIdsToSectionsAndWidgets(allDashboardsFromServer);
        dashboardsFromServer?.length > 0 && dispatch(setSelectedDashboardAsInServer(dashboardsFromServer[0]));
        const updatedDashboardsFromServer = await getAllDashboardsFromServer();
        setAllDashboardsFromServer(updatedDashboardsFromServer);
        if (updateStore) {
            await refreshAllDashboardsInStore();
            const selectedDashboard = updatedDashboardsFromServer.find(updatedDashboard => updatedDashboard.id === dashboard.id);
            if (selectedDashboard) updateDashboardInStore(selectedDashboard);
        }
        return response;
    } catch (error) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: i18next.t('DASHBOARD.MESSAGES.ERROR_OCCURRED', { ns: i18nOverviewNamespace }),
            text: i18next.t('DASHBOARD.MESSAGES.UNABLE_TO_UPDATE_DASHBOARD', { ns: i18nOverviewNamespace }),
        });
        throw new Error();
    }
};

export const createNewDashboard = async (newDashboard: Partial<IDashboard>): Promise<TDashboardResponse> => {
    try {
        getHttpService().clearCacheByTag(ALL_DASHBOARDS_CACHE_TAG, 'GET');
        const response = await getHttpService().post<TDashboardResponse>(dashboardsApiUrl, { data: [newDashboard] });
        await refreshAllDashboardsInStore();
        return response;
    } catch (error) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: i18next.t('DASHBOARD.MESSAGES.ERROR_OCCURRED', { ns: i18nOverviewNamespace }),
            text: i18next.t('DASHBOARD.MESSAGES.UNABLE_TO_CREATE_A_NEW_DASHBOARD', { ns: i18nOverviewNamespace }),
        });
        throw new Error();
    }
};

export const deleteDashboardInServer = async (dashboardId: string): Promise<TDashboardResponse> => {
    try {
        getHttpService().clearCacheByTag(ALL_DASHBOARDS_CACHE_TAG, 'GET');
        const response = await getHttpService().delete<TDashboardResponse>(`${baseRoute}/${dashboardId}`);
        await refreshAllDashboardsInStore();
        return response;
    } catch (error) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: i18next.t('DASHBOARD.MESSAGES.ERROR_OCCURRED', { ns: i18nOverviewNamespace }),
            text: i18next.t('DASHBOARD.MESSAGES.UNABLE_TO_DELETE_DASHBOARD', { ns: i18nOverviewNamespace }),
        });
        throw new Error();
    }
};

export const getFavoritesDashboardsFromServer = async (): Promise<ICustomzationObject<string[]> | undefined> => {
    try {
        const response = await getHttpService().request<any>(`${customizationRoute}?component=HOME_DASHBOARD_FAVORITE`,
            { method: 'GET' },
            { cachingConfig:
                    {
                        useCache: true,
                        tags: [FAVORITES_DASHBOARD_CACHE_TAG],
                    }
            }
        );
        return response?.length ? response[0] : undefined;
    } catch (error) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: i18next.t('DASHBOARD.MESSAGES.ERROR_OCCURRED', { ns: i18nOverviewNamespace }),
            text: i18next.t('DASHBOARD.MESSAGES.UNABLE_TO_GET_FAVORITE_DASHBOARDS', { ns: i18nOverviewNamespace }),
        });
        throw new Error();
    }
};

export const setFavoriteDashboardInServer = async (pinnedList: string[]): Promise<void> => {
    try {
        const favoritesResponse = await getFavoritesDashboardsFromServer();
        if (!favoritesResponse) {
            throw new Error('Could not get favorite dashboards');
        }
        const id = favoritesResponse.id;
        const payload = {
            ...favoritesResponse,
            data: pinnedList,
        };
        getHttpService().clearCacheByTag(FAVORITES_DASHBOARD_CACHE_TAG, 'GET');
        await getHttpService().put<TDashboardResponse>(`${customizationRoute}/${id}`, { data: payload });
    } catch (error) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: i18next.t('DASHBOARD.MESSAGES.ERROR_OCCURRED', { ns: i18nOverviewNamespace }),
            text: i18next.t('DASHBOARD.MESSAGES.UNABLE_TO_PIN_DASHBOARD', { ns: i18nOverviewNamespace }),
        });
        throw new Error();
    }
};

export const getInnerDefaultDashboardFromServer = async (): Promise<ICustomzationObject<string> | undefined> => {
    try {
        const response = await getHttpService().request<any>(`${customizationRoute}?component=${HOME_DASHBOARD_DEFAULT_CUSTOMIZATION_KEY}`,
            { method: 'GET' },
            { cachingConfig:
                {
                    useCache: true,
                    tags: [DEFAULT_DASHBOARD_CACHE_TAG],
                }
            }
        );
        return response?.length ? response[0] : undefined;
    } catch (error) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: i18next.t('DASHBOARD.MESSAGES.ERROR_OCCURRED', { ns: i18nOverviewNamespace }),
            text: i18next.t('DASHBOARD.MESSAGES.UNABLE_TO_GET_DEFAULT_DASHBOARD', { ns: i18nOverviewNamespace }),
        });
        console.error('Failed getting default dashboard', error);
        throw new Error();
    }
};

const createEmptyDefaultDashboardInServer = async (): Promise<ICustomzationObject<string>> => {
    return getHttpService().request<ICustomzationObject<any>>('customization', {
        method: 'POST',
        data: {
            component: HOME_DASHBOARD_DEFAULT_CUSTOMIZATION_KEY,
            data: [],
            scope: 'Private',
        },
    });
};

export const setInnerDefaultDashboardInServer = async (dashboardId: string): Promise<void> => {
    try {
        let defaultDashboardResponse = await getInnerDefaultDashboardFromServer();
        getHttpService().clearCacheByTag(DEFAULT_DASHBOARD_CACHE_TAG, 'GET');
        if (!defaultDashboardResponse) {
            defaultDashboardResponse = await createEmptyDefaultDashboardInServer();
        }

        const id = defaultDashboardResponse.id;
        const payload = {
            ...defaultDashboardResponse,
            data: dashboardId,
        };
        await getHttpService().put<TDashboardResponse>(`${customizationRoute}/${id}`, { data: payload });
    } catch (error) {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: i18next.t('DASHBOARD.MESSAGES.ERROR_OCCURRED', { ns: i18nOverviewNamespace }),
            text: i18next.t('DASHBOARD.MESSAGES.UNABLE_TO_SET_DEFAULT_DASHBOARD', { ns: i18nOverviewNamespace }),
        });
        console.error('Failed setting default dashboard to: ', dashboardId, error);
        throw new Error();
    }
};

export const getDefaultDashboardFromServer = async (): Promise<ICustomzationObject<string> | undefined> => {
    if (!isDefaultDashboardSetupDone()) {
        await setupDefaultDashboard();
    }
    return await getInnerDefaultDashboardFromServer();
};

export const setDefaultDashboardInServer = async (dashboardId: string): Promise<void> => {
    if (!isDefaultDashboardSetupDone()) {
        await setupDefaultDashboard();
    }
    await setInnerDefaultDashboardInServer(dashboardId);
};
