import React from 'react';
import { IInternalMenuBase } from '../Interface';
import MinimizedMenuStyled from './MinimizedMenu.styled';
import { InternalMainMenuItem } from '../Utils';
import { Icon } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import MenuPanel from '../MenuPanel';

export interface MinimizedMenuProps {
    menuItems: Array<IInternalMenuBase>;
    selectedItem: IInternalMenuBase | null;
    isMinimized: boolean;
}

let menuPopupOpenTimeout: NodeJS.Timeout | null = null;
let menuPopupCloseTimeout: NodeJS.Timeout | null = null;

const MinimizedMenu: React.FunctionComponent<MinimizedMenuProps> = ({ isMinimized, menuItems, selectedItem }) => {
    const { t } = useTranslation();
    const minimizedMenuWrapperRef = React.useRef<HTMLDivElement>(null);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = React.useState<number>(0);
    const [menuPopupState, setMenuPopupState] = React.useState<{ item: InternalMainMenuItem; element: HTMLDivElement } | null>(null);

    const mainMenuItems = React.useMemo(() => {
        const items: Array<InternalMainMenuItem> = [];
        menuItems.forEach((menuItem) => {
            if (menuItem instanceof InternalMainMenuItem) {
                items.push(menuItem as InternalMainMenuItem);
            }
        });
        return items;
    }, [menuItems]);

    const mainSelectedItem = React.useMemo(() => {
        return mainMenuItems.find((item) => item.path[0] === selectedItem?.path[0]);
    }, [mainMenuItems, selectedItem]);

    const popupMenuItems = React.useMemo(() => {
        if (!menuPopupState) return [];
        const mainRoutePath = menuPopupState.item.path[0];
        const relevantItems = menuItems.filter((item) => item.path[0] === mainRoutePath);
        const copiedItems: Array<IInternalMenuBase> = JSON.parse(JSON.stringify(relevantItems));
        copiedItems.shift();
        return copiedItems.map((item, index) => {
            item.path.shift();
            item.component = relevantItems[index + 1].component;
            return item;
        });
    }, [menuItems, menuPopupState]);

    const popupMenuSelectedItem = React.useMemo(() => {
        if (!selectedItem) return null;
        const copiedSelecedItem: IInternalMenuBase = JSON.parse(JSON.stringify(selectedItem));
        copiedSelecedItem.path.shift();
        return copiedSelecedItem;
    }, [selectedItem]);

    const handleOnMenuItemMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>,item: InternalMainMenuItem) => {
        if (menuPopupCloseTimeout) {
            clearTimeout(menuPopupCloseTimeout);
        }
        if (menuPopupState) {
            menuPopupOpenTimeout = setTimeout(() => {
                setMenuPopupState({ element: event.target as HTMLDivElement, item });
                calculatePopupHeight();
            }, 50);
            return;
        }
        setMenuPopupState({ element: event.target as HTMLDivElement, item });
    };

    const handleOnMenuItemOrPopupMouseLeave = () => {
        if (menuPopupOpenTimeout) {
            clearTimeout(menuPopupOpenTimeout);
        }
        if (menuPopupCloseTimeout) {
            clearTimeout(menuPopupCloseTimeout);
        }
        menuPopupCloseTimeout = setTimeout(() => {
            setMenuPopupState(null);
        }, 200);
    };

    const hanleOnPopupMouseEnter = () => {
        if (menuPopupCloseTimeout) {
            clearTimeout(menuPopupCloseTimeout);
        }
    };

    const popupMenuStyles = React.useMemo<React.CSSProperties>(() => {
        if (!menuPopupState || !minimizedMenuWrapperRef.current) return {};

        const pageHeight = window.innerHeight;
        const wrapperRect = minimizedMenuWrapperRef.current.getBoundingClientRect();
        
        const menuPopupRect = menuPopupState.element.getBoundingClientRect();

        const gapFromBottom = 8;
        const popupHeight = contentHeight + 50;
        const popupMaxHeight = 450;
        
        let top: string | number = Math.max(0, menuPopupRect.top - (pageHeight - wrapperRect.height));
        let bottom: string | number = 'unset';

        if ((wrapperRect.height - top - Math.min(popupHeight, popupMaxHeight)) < gapFromBottom) {
            top = 'unset';
            bottom = gapFromBottom;
        }

        const styles: React.CSSProperties = {
            top: typeof top === 'string' ? top : `${top}px`,
            bottom: typeof bottom === 'string' ? bottom : `${bottom}px`,
            height: `${popupHeight}px`,
            minHeight: 'unset',
            maxHeight: `${popupMaxHeight}px`,
        };

        return styles;
    }, [menuPopupState, minimizedMenuWrapperRef, contentHeight]);

    const calculatePopupHeight = () => {
        // This timeout is needed because the popup is rendered after the menu item is hovered
        setTimeout(() => {
            const agWrapper = contentRef.current?.querySelector('.ag-center-cols-container')?.scrollHeight || 0;
            setContentHeight(agWrapper);
        }, 0);
    };

    return (
        <MinimizedMenuStyled.MinimizeMenuWrapper isMinimized={isMinimized} ref={minimizedMenuWrapperRef}>
            {mainMenuItems.map((item, index) => (
                <MinimizedMenuStyled.MinimizeMenuItem
                    key={index}
                    isMinimized={isMinimized}
                    isSelected={mainSelectedItem?.id === item.id}
                    withTriangle={menuPopupState?.item.id === item.id}
                    onMouseEnter={(e) => handleOnMenuItemMouseEnter(e, item)}
                    onMouseLeave={handleOnMenuItemOrPopupMouseLeave}
                >
                    <Icon name={item.icon} size={20} />
                </MinimizedMenuStyled.MinimizeMenuItem>
            ))}
            {(menuPopupState) && (
                <MinimizedMenuStyled.MinimizeMenuPopup onMouseOver={hanleOnPopupMouseEnter} onMouseLeave={handleOnMenuItemOrPopupMouseLeave} style={popupMenuStyles}>
                    <MinimizedMenuStyled.MinimizeMenuPopupTitle>{t(menuPopupState.item.label)}</MinimizedMenuStyled.MinimizeMenuPopupTitle>
                    <MinimizedMenuStyled.MinimizeMenuPopupContent ref={contentRef}>
                        <MenuPanel menu={popupMenuItems} selectedMenuItem={popupMenuSelectedItem} isMinimized={false} onRowOpened={calculatePopupHeight} />
                    </MinimizedMenuStyled.MinimizeMenuPopupContent>
                </MinimizedMenuStyled.MinimizeMenuPopup>
            )}
        </MinimizedMenuStyled.MinimizeMenuWrapper>
    );
};

export default MinimizedMenu;
