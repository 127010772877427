import { getHttpService } from 'common/interface/services';
import { useState, useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { XMLParser } from 'fast-xml-parser';
import axios from 'axios';

type UseUnresolvedIncidents = () => boolean;
const useUnresolvedIncidentsImple = () => {
    const [hasIncidents, setHasIncidents] = useState(false);
    const resolvedMatchString = '<strong>Resolved</strong>';
    const completedMatchString = '<strong>Completed</strong>';
    const MaintenanceMatchString = 'Maintenance';
    const incidentsRSSUrl = 'https://status.dome9.com/history.rss';
    const handleIncidentStateDataFromRSS = async () => {
        try {
            const response : any = await axios.get<any>(incidentsRSSUrl);
            const parser = new XMLParser();
            const data = parser.parse(response.data);
            const incidents = data.rss.channel.item.find((item:any)=> !item.title.includes(MaintenanceMatchString) &&
                                                                      !item.description.includes(resolvedMatchString) &&
                                                                      !item.description.includes(completedMatchString));
            setHasIncidents(!!incidents);
        }catch {
            console.warn('Failed to fetch incidents data from RSS');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const httpService = getHttpService();
                // @ts-ignore
                const response = await httpService.request<Array<any>>('IncidentsStatus/UnresolvedIncidents', {}, {}, () => {
                    handleIncidentStateDataFromRSS();
                });
                const hasIncidents = response && Array.isArray(response) && response.length > 0;
                setHasIncidents(hasIncidents);
            } catch (err) { /* empty */ }
        };

        fetchData();
        const intervalConst = setInterval(fetchData, 60 * 1000 * 60);
        return () => {
            clearInterval(intervalConst);
        };
    }, []);

    return hasIncidents;
};


const useUnresolvedIncidents: UseUnresolvedIncidents = singletonHook(false, useUnresolvedIncidentsImple);
export default useUnresolvedIncidents;
