import { useLocalStorage } from 'common/hooks/useLocalStorage';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MainNavigationBarModal from '../../MainNavigationBar/MainNavigationBarModal';
import MenuContainerStyled, { menuResizeAnimationTime } from './MenuContainer.styled';
import MenuPanel from './MenuPanel';
import { getMainMenu } from './Menus.reducer';
import MinimizedMenu from './MinimizedMenu/MinimizedMenu';
import { buildInternalMenu, selectMenuSelectionForLocation } from './Utils';
import { logUrlChangedEvent } from 'common/utils/logUtils';

export const MenuContainer: React.FC = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [minimizedMenu, setMinimizedMenu] = useLocalStorage<boolean>(false, 'minimizedMenuState');
    const mainMenu = useSelector(getMainMenu);
    const internalMenu = useMemo(() => buildInternalMenu(mainMenu), [mainMenu]);
    const selectedItem = useMemo(() => selectMenuSelectionForLocation(internalMenu, location), [internalMenu, location]);
    const [showMainNavigationModal, setShowMainNavigationModal] = useState(false);

    useEffect(() => {
        document.title = t(selectedItem?.label || '');
    }, [selectedItem, t]);

    useEffect(() => {
        logUrlChangedEvent();
    }, [location]);

    const handleMenuMinimizedChanged =()=> {
        setMinimizedMenu(current => !current);
        setTimeout(() => { 
            window.dispatchEvent(new Event('resize'));
        }, menuResizeAnimationTime);
    };

    return (
        <MenuContainerStyled.MenuWrapper isMinimized={minimizedMenu} data-aid="menus-container">
            <MenuContainerStyled.MinimizeButton iconProps={{ name: 'chevronRight', size: 12 }} color='normal' variant='contained' circleShape size='small' isMinimized={minimizedMenu} onClick={() => handleMenuMinimizedChanged()} />
            <MenuContainerStyled.SearchButton
                isMinimized={minimizedMenu}
                iconProps={{ name: 'magnify', size: 20 }}
                data-aid="search-navigation-button"
                onClick={() => setShowMainNavigationModal(true)}
                tooltip={minimizedMenu ? t('MAIN_NAVIGATION_BAR.MODAL.TITLE') : undefined}
                variant='contained'
                iconButton={minimizedMenu}
                tooltipPlacement='right'
            >
                {minimizedMenu ? undefined : t('MAIN_NAVIGATION_BAR.MODAL.TITLE')}
            </MenuContainerStyled.SearchButton>
            <MenuContainerStyled.Seperator />
            <MenuPanel menu={internalMenu} selectedMenuItem={selectedItem} isMinimized={minimizedMenu} />
            {minimizedMenu && <MinimizedMenu menuItems={internalMenu} selectedItem={selectedItem} isMinimized={minimizedMenu} />}
            <MainNavigationBarModal isOpen={showMainNavigationModal} setIsOpen={(isOpen) => setShowMainNavigationModal(isOpen)}/>
        </MenuContainerStyled.MenuWrapper>
    );
};

export default MenuContainer;
