import styled from 'styled-components';
import { Button, Stack } from 'common/design-system/components-v2';

const LevelBoxesDiv = styled(Stack)`
    flex-direction: row;
    gap: 16px;
    padding: 0 2px 0 2px;
    height: 100%;
    align-items: center;
`;

const ButtonDiv = styled(Button)`
    align-self: flex-end;
`;

export const SecurityEventsStatusStyled = {
    LevelBoxesDiv,
    ButtonDiv
};
