import React, { useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { A } from 'common/components/Anchor/Anchor';
import { AssetNodeProps } from 'common/components/Graph/Models/AssetNodeProps';
import { GroupEntity } from '../Models/Groups';
import { GroupType } from '../Models/GroupType';
import { getProtectedAssetsEntityUrl, getCiemEntityUrl } from '../Services/PerimeterExposureHttpService';
import { Icon } from 'common/design-system/components-v2';

const ExposureGroupNode: React.FC<AssetNodeProps> = (props: AssetNodeProps) => {
    const { data, isConnectable } = props;
    const handleRef = useRef(null);
    const [groupEntity] = useState(data as GroupEntity);

    const [groupUrl, setGroupUrl] = useState<string | null>(null);

    useEffect(() => {
        getGroupUrl().then(setGroupUrl);

        async function getGroupUrl(): Promise<string | null> {
            if (groupEntity.groupType === 'iamImpact' && groupEntity.srl) {
                const assetUrl = await getCiemEntityUrl(groupEntity.srl);
                return assetUrl ?? '';
            }

            if (!groupEntity.srl) return null;

            return await getProtectedAssetsEntityUrl(groupEntity.srl);
        }
    }, [groupEntity.srl, groupEntity.groupType]);

    return <>
        <Handle
            type="target"
            position={Position.Left}
            style={{
                top: '50.1%',
                background: 'transparent',
                width: 0,
                height: 0,
                border: 'none',
            }}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={isConnectable}/>

        <div
            className={'hover:shadow-md transition-shadow theme cursor-default'}
            style={{
                height: '180px',
                width: '250px',
                borderRadius: '8px',
                boxSizing: 'border-box',
                backgroundColor: '#FFFFFF',
                border: '1px solid rgba(0, 0, 0, 0.1)'
            }}>

            <div style={{
                height: '30px',
                lineHeight: '30px',
                verticalAlign: 'middle',
                padding: '0 14px',
                background: '#2E3F58',
                borderRadius: '8px 8px 0 0',
                color: '#FFFFFF',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}>
                {groupEntity.name}
            </div>

            <div style={{
                height: '120px',
                overflowY: 'auto',
                padding: '0 14px',
                overflowX: 'hidden'
            }}>
                {groupEntity.items.map(item => (
                    <div
                        key={item.graphId}
                        style={{
                            height: '40px',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center'
                        }}>

                        {item.icon &&
                            <div className={`group-item-icon-container ${groupEntity.groupType === GroupType.networkAccess ? 'dark-group-item-icon-container' : ''}`}>
                                <item.icon/>
                            </div>
                        }

                        <span className={item.gotoUrl ? 'link' : ''} style={{
                            margin: '0 5px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '100%'
                        }}>
                            {item.gotoUrl &&
                                <A href={item.gotoUrl} className='link'>
                                    {item.name}
                                </A>
                            }
                            {(!item.srl || !item.gotoUrl) &&
                                item.name
                            }
                        </span>

                        {item.value &&
                            <span style={{
                                flex: 1,
                                fontWeight: 500,
                                padding: '0 3px',
                                ...item.valueStyle ?? {}
                            }}>
                                {item.value}
                            </span>
                        }
                        {item.valueIconProps &&
                            <span
                                style={{
                                    paddingTop: '4px',
                                    ...item.valueStyle
                                }}>
                                <Icon {...item.valueIconProps} />
                            </span>
                        }
                    </div>
                ))}
            </div>

            <div style={{
                height: '28px',
                lineHeight: '28px',
                verticalAlign: 'middle',
                padding: '0 14px',
                background: '#F5F5F5',
                borderRadius: '0 0 8px 8px',
                color: '#898989',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <div>{groupEntity.items.length} item{groupEntity.items.length === 1 ? '' : 's'}</div>

                {groupUrl &&
                    <div className={'link'}>
                        <A href={groupUrl} className='link'>
                            See details
                        </A>
                    </div>
                }
            </div>
        </div>

        <Handle
            ref={handleRef}
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
            style={{
                top: '50%',
                background: 'transparent',
                width: 0,
                height: 0,
                border: 'none',
            }}/>
    </>;
};

export default ExposureGroupNode;