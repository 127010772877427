import { DataGridWidget } from 'common/components/Widgets/DataGridWidget/DataGridWidget';
import WidgetList from 'common/components/Widgets/List/List';
import { DashboardWidgetTypes, IAddinWidgetsDataSource, IDashboardWidget } from 'common/module_interface/overview/Interface';
import {
    monitoredEnvironmentsDataGetter,
    unmonitoredEnvironmentsDataGetter
} from './WidgetsData/EnvironmentsDataGetter';
import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import EnvironmentsWidgetsSettings from './Components/EnvironmentsWidgetsSettings';
import initializeEnvironmentsWidgetFilters from './Helpers/EnvironmentsFilters';


export const initializeEnvironmentsWidgets = () => {
    initializeEnvironmentsWidgetFilters();
    const environmentsWidgets: IAddinWidgetsDataSource = {
        dataSourceName: 'Environments',
        dataField: { displayName: 'Types', path: 'options/navigateOption' },
        widgets: [   
            {
                dataId: 'monitored',
                dataIdAliases: ['Environments'],
                type: DashboardWidgetTypes.Accounts,
                getData: (widget: IDashboardWidget) => monitoredEnvironmentsDataGetter(widget),
                widgetComponent: DataGridWidget,
                settingsComponent: EnvironmentsWidgetsSettings
            },
            {
                dataId: 'unmonitored',
                type: DashboardWidgetTypes.Accounts,
                getData: () => unmonitoredEnvironmentsDataGetter(),
                widgetComponent: WidgetList,
            },
        ]
        
    };

    globalAddinContainer.addMap(DASHBOARD_DYNAMIC_WIDGETS, [environmentsWidgets], 'dataSourceName');
};
