import { IColumnUsageDef } from 'common/interface/general';
import { FindingsColumnTypes as ColumnTypes } from '../FindingsTable/Types/FindingsColumnTypes';

export const getCdrEventsTableColumns = (): IColumnUsageDef[] => [
    ColumnTypes.SELECTION,
    ColumnTypes.RULE_NAME,
    ColumnTypes.SEVERITY,
    ColumnTypes.CREATED_TIME,
    ColumnTypes.ENVIRONMENT,
    ColumnTypes.DESCRIPTION,
    ColumnTypes.RULESET_NAME,
    ColumnTypes.ENTITY_NAME,
    ColumnTypes.ENTITY_TYPE,
    ColumnTypes.ACTION,
    ColumnTypes.ASSIGNEE,
    ColumnTypes.MITRE
];
