import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { ContainerRegistryAttachOrganizationalUnit, ContainerRegistryDeleteMultiple } from './containerRegistryApi.interface';

const apiBaseUrl = 'containerRegistry';

const attachOrganizationalUnit: ContainerRegistryAttachOrganizationalUnit.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ContainerRegistryAttachOrganizationalUnit.Response>>(
        `${apiBaseUrl}/account/organizationalUnit/attach`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const deleteMultiple: ContainerRegistryDeleteMultiple.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ContainerRegistryDeleteMultiple.Response>>(
        `${apiBaseUrl}/account/deleteMultiple`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const ContainerRegistryApi = {
    attachOrganizationalUnit,
    deleteMultiple,
};

export default ContainerRegistryApi;
