import { ICommonIgnoreItem } from './CommonIgnoreList.interface';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import {
    IAddActionProps, IDeleteActionProps,
    IEditActionProps,
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';
import {
    getPageTableCrudActions
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.actions';

export const getIgnoreListTableActions = <T extends ICommonIgnoreItem>(
    onTableDataChange: () => void,
    pageTableId: string,
    openForm: (item?: T) => void,
    deleteItems: (items: T[]) => Promise<void>,
    iconName: AllIconsName): ITableAction<T>[] => {

    const addProps: IAddActionProps = {
        callback: () => openForm(),
    };

    const editProps: IEditActionProps<T> = {
        callback: (item: T) => openForm(item),
    };

    const deleteProps: IDeleteActionProps<T> = {
        deleteItems,
        getNameFromItem: (item: T) => item.name,
        itemIconName: iconName,
    };

    return getPageTableCrudActions<T>(pageTableId, onTableDataChange, {
        addProps,
        editProps,
        deleteProps,
    });
};