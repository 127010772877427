import { Spinner } from 'common/design-system/components-v2';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { getOrganizationalUnitService } from 'common/interface/data_services';
import { IEvent } from 'common/module_interface/events/Events';
import React from 'react';

interface OUNameProps {
    event: IEvent;
}

export const OUName: React.FC<OUNameProps> = ({ event: { cloudAccountId } }) => {
    const [organizationalUnitPath, setOrganizationalUnitPath] = React.useState<string>('');
    const [isOULoading, setIsOULoading] = React.useState<boolean>(true);

    const { getByAccountId, isLoading } = useAllCloudAccounts();

    React.useEffect(() => {
        const getAndSetOrganizationalUnitPath = async (cloudAccountId: string) => {
            const cloudAccount = await getByAccountId(cloudAccountId);
            if (cloudAccount) {
                const ouNodes = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithAllChildrens(true);
                const relevantOU = ouNodes.find(ou => ou.id === cloudAccount.organizationalUnitId);
                setOrganizationalUnitPath(relevantOU?.path || cloudAccount.organizationalUnitId);
                setIsOULoading(false);
            }
        };
        getAndSetOrganizationalUnitPath(cloudAccountId);
    }, [cloudAccountId, getByAccountId]);

    if (isOULoading || isLoading) {
        return <Spinner size={12} />;
    }
    
    return organizationalUnitPath;
};