import React from 'react';
import { ModalsProps } from '..';
import { useTranslation } from 'react-i18next';
import { getEnvsNamespace } from '../../../initialize.i18n';
import { Message, Stack, Tooltip, Spinner } from 'common/design-system/components-v2';
import ValidatePermissionsStyled from './ValidatePermissions.styled';
import { Icon } from '@dome9/berries/react-components';
import { Vendors, getVendor } from 'common/consts/vendors';
import { useMissingPermissionsReset } from './useMissingPermissionsReset';
import { getCloudAccountsService } from 'common/interface/data_services';

export const validatePermissionsRelevantVendors = [Vendors.AWS, Vendors.AZURE, Vendors.GOOGLE, Vendors.OCI];

const ValidatePermissions: React.FC<ModalsProps> = ({ closeModal, selectedRows, resetSelectedRows }) => {
    const { t } = useTranslation(getEnvsNamespace('table'));
    
    const [isValidateFinished, setIsValidateFinished] = React.useState(false);

    const handleOnClose = () => {
        if (isValidateFinished) {
            resetSelectedRows();
        }
        closeModal();
    };

    const handleOnValidateFinished = () => {
        getCloudAccountsService().clearCache();
        setIsValidateFinished(true);
    };

    const { resetPermissions, isLoading, results } = useMissingPermissionsReset({ onFinish: handleOnValidateFinished });

    const filteredRows = React.useMemo(() => {
        return selectedRows.filter(row => validatePermissionsRelevantVendors.includes(row.platform as Vendors));
    }, [selectedRows]);

    const handleOnConfirm = async () => {
        resetPermissions(filteredRows);
    };

    return (
        <Message
            title={t('ACTIONS.VALIDATE_PERMISSIONS.TITLE')}
            text={[t('ACTIONS.VALIDATE_PERMISSIONS.TEXT', { count: filteredRows.length }), t('ACTIONS.VALIDATE_PERMISSIONS.TEXT_2')]}
            width='lg'
            onClose={handleOnClose}
            onCancel={handleOnClose}
            cancelBtnText={isValidateFinished ? t('ACTIONS.VALIDATE_PERMISSIONS.BUTTONS.CLOSE') : t('ACTIONS.VALIDATE_PERMISSIONS.BUTTONS.CANCEL')}
            submitBtnText={t('ACTIONS.VALIDATE_PERMISSIONS.BUTTONS.VALIDATE')}
            onConfirm={isValidateFinished ? undefined : handleOnConfirm}
            isLoading={isLoading}
        >
            <ValidatePermissionsStyled.Wrapper spacing={3} padding={1}>
                {filteredRows.map((row, index) => (
                    <Stack key={`row-${index}`} direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
                        <Stack spacing={1} direction='row' alignItems='center'>
                            <Icon name={getVendor(row.platform)?.icon || 'refresh'} size={12} />
                            <ValidatePermissionsStyled.StyledTypography>{`${row.name} (${row.id})`}</ValidatePermissionsStyled.StyledTypography>
                        </Stack>
                        {results[row.id]?.isLoading && (
                            <Stack margin={[1, 0]}>
                                <Spinner size={12} />
                            </Stack>)}
                        {(results[row.id] && (results[row.id].isSuccess || results[row.id].error) ) && (
                            <Tooltip
                                content={t(`ACTIONS.VALIDATE_PERMISSIONS.TOOLTIPS.${results[row.id].isSuccess ? 'VALIDATED' : 'FAILED_TO_VALIDATE'}`, { environmentName: row.name })}
                            >
                                <Icon
                                    name={results[row.id].isSuccess ? 'check' : 'remove'}
                                    color={results[row.id].isSuccess ? 'success' : 'danger'}
                                    size={12}
                                />
                            </Tooltip>
                        )}
                    </Stack>
                ))}
            </ValidatePermissionsStyled.Wrapper>
        </Message>
    );
};

export default ValidatePermissions;
