import { IFilterFieldOptionsMap, IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { ISSUE_FIELD_NAMES } from './Issues.interface';
import { SECURITY_CONTROL_FIELD_NAMES } from '../SecurityControls/SecurityControls.consts';
import { getAllIssueSeverityCondOptions } from '../../RiskManagement.options';

export interface IIssuesFilterType extends IProtectedAssetFilter {
    withoutFacet?: boolean;
}

export const ISSUES_FILTER_TYPES_POOL: { [key: string]: IIssuesFilterType } = {
    ADD_FILTER: { id: 'add filter' },
    FREE_TEXT: { id: 'free text' },
    DATE_PICKER: { id: 'date picker' },
    ORGANIZATIONAL_UNIT: { id: ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID, isField: true },
    PLATFORM: { id: ISSUE_FIELD_NAMES.PLATFORM, isField: true },
    REGION: { id: ISSUE_FIELD_NAMES.REGION, isField: true },
    SEVERITY: { id: ISSUE_FIELD_NAMES.SEVERITY, isField: true },
    ENVIRONMENT: { id: ISSUE_FIELD_NAMES.ENVIRONMENT_ID, isField: true },
    ENTITY_TYPE_BY_PLATFORM: { id: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM, isField: true },
    ENTITY: { id: ISSUE_FIELD_NAMES.ENTITY_ID, isField: true, withoutFacet: true },
    ID: { id: ISSUE_FIELD_NAMES.ID, isField: true, withoutFacet: true },
    STATUS: { id: ISSUE_FIELD_NAMES.STATUS, isField: true },
    IS_EXCLUDED: { id: ISSUE_FIELD_NAMES.IS_EXCLUDED, isField: true },
    RULE_TITLE: { id: ISSUE_FIELD_NAMES.RULE_TITLE, isField: true },
    CLEAR_ALL: { id: 'clear all' },
};

export const getIssuesFilterOptionsMap = (): IFilterFieldOptionsMap => {
    return {
        [ISSUE_FIELD_NAMES.RULE_TITLE]: {
            options: (value: string) => ({
                value,
                label: value,
                labelProps: { leadingIconProps: { name: 'rule' } },
            }),
        },
        [SECURITY_CONTROL_FIELD_NAMES.SEVERITY]: {
            options: getAllIssueSeverityCondOptions(),
            showAllOptions: true,
        },
    };
};