import { getStoreService, IBreadcrumbsService } from 'common/interface/services';
import { getBreadcrumbsLastChildren, setBreadcrumbsLastChildren } from 'platform/main/Breadcrumbs/Breadcrumbs.reducer';

export class BreadcrumbsService implements IBreadcrumbsService {
   
    setBreadcrumbsLastChildren(lastChildren: Array<string>): void {
        getStoreService().dispatch(setBreadcrumbsLastChildren(lastChildren));
    }

    getBreadcrumbsLastChildren(): Array<string> | undefined {
        return getBreadcrumbsLastChildren(getStoreService().state);
    }

}
