import { IntelligenceNodeData } from 'common/module_interface/intelligence/intelligenceNodeData';
import { IntelligenceTypeMap } from '../Consts/IntelligenceTypeMap';
import { FunctionComponent, useMemo } from 'react';
import { IconMap } from '../Services/IconMap';
import { InsightPopoverRegistry } from 'common/module_interface/insight/InsightPopoverRegistry';
import NodeDetailsComponent from './NodeDetailsComponent';
import { Stack, Typography } from 'common/design-system/components-v2';
import { IntelligenceNodePopoverStyled } from './IntelligenceNodePopover.styled';
import { getNodePopoverDetailsList, preventNodeSelection } from '../Utils';

const IntelligenceNodePopoverComponent = (props: IntelligenceNodeData) => {
    const platform = props.platform?.toLowerCase();
    const intelligenceType = IntelligenceTypeMap[`${platform}_${props.type.toLowerCase()}`];
    const typeForIcon = intelligenceType || 'Default';
    const NodeIcon: FunctionComponent = IconMap[typeForIcon];

    const nodePopoverDetails = useMemo(() => {
        return getNodePopoverDetailsList(props);
    }, [props]);

    return <IntelligenceNodePopoverStyled.Body onClick={preventNodeSelection}>
        <IntelligenceNodePopoverStyled.Header>
            { NodeIcon &&
                <IntelligenceNodePopoverStyled.Icon className={`intelligence-visualization-popover-icon-container ${platform ?? ''}`}>
                    <NodeIcon/>
                </IntelligenceNodePopoverStyled.Icon>
            }
            <Typography variant="body" color="strong" className={'truncate max-w-[30ch] flex-1'}>{props.name || props.id || 'N/A'}</Typography>
        </IntelligenceNodePopoverStyled.Header>
        <IntelligenceNodePopoverStyled.Details>
            <NodeDetailsComponent NodePopoverDetails={nodePopoverDetails}/>
            <Stack spacing={3} direction={'row'}>
                {InsightPopoverRegistry.getChips().map(item => (
                    <item.component data={props} key={item.id}/>
                ))}
            </Stack>
        </IntelligenceNodePopoverStyled.Details>
        {InsightPopoverRegistry.getItems().map(item => (
            <item.component data={{ ...props, ...{ className: 'popover-item' } }} key={ item.id }/>
        ))}
    </IntelligenceNodePopoverStyled.Body>;
};

export default IntelligenceNodePopoverComponent;
