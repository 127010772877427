import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { ReportsFields } from '../../Reporting.const';

export const ReportsFilterTypes: { [key: string]: IProtectedAssetFilter } = {
    ADD_FILTER: {
        id: FILTERS_KEYS.ADD_FILTER
    },
    FREE_TEXT: {
        id: FILTERS_KEYS.FREE_TEXT
    },
    DATE_PICKER: {
        id: FILTERS_KEYS.DATE_PICKER
    },
    CLEAR_ALL: {
        id: FILTERS_KEYS.CLEAR_BUTTON
    },
    TEMPLATE: {
        id: ReportsFields.TEMPLATE,
        isField: true
    },
    GRANULARITY: {
        id: ReportsFields.GRANULARITY,
        isField: true
    },
};
