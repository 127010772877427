import { EventsDrawerAddingBase, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import { IFindingSeverityInfo, getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';

const chips: () => Addin<EventsDrawerAddingBase<IChipProps>>[] = () => [
    {
        id: 'severity',
        position: 11,
        content: {
            id: 'severity',
            getValue: (event) => {
                const severityInfo: IFindingSeverityInfo = getSafeFindingSeverityInfo(event.severity);
                return {
                    label: severityInfo.displayText,
                    leadingIconProps: { ...severityInfo.iconProps, size: 16 },
                    context: severityInfo.context,
                    disableInteraction: true,
                };
            },
        }
    },
];

export default function initializeChips() {
    EventsDrawerRegistry.addChips(chips());
}