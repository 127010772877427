import {
    MAGELLAN_SCHEMA_SERVICE_ID,
    IMagellanSchemaService,
    SchemaAllTypesResponse
} from 'common/services/datasource/MagellanSchema/MagellanSchema.interface';
import { getHttpService, getStoreService } from 'common/interface/services';
import { HttpMethod } from 'common/common_types';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

const getMagellanUrl = () => {
    const state = getStoreService().state;
    return state.app?.magellanUrl;
};

class MagellanSchemaService implements IMagellanSchemaService {

    public async getAllTypes(): Promise<SchemaAllTypesResponse> {
        const url = `${getMagellanUrl()}/schema/allTypes`;
        return await getHttpService().request<SchemaAllTypesResponse>(url, {
            method: HttpMethod.get
        });
    }
}

export default function initializeMagellanSchemaService() {
    globalAddinContainer.addService(MAGELLAN_SCHEMA_SERVICE_ID, new MagellanSchemaService());
}
