import { useCallback, useMemo, useRef, useState, useEffect, FC } from 'react';
import { ColDef, GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { IRemediationTableProps } from '../helpers/remediation.interfaces';
import { IRemediation } from 'common/interface/remediation';
import { Table, Typography } from 'common/design-system/components-v2';
import { RemediationTableRegistry } from 'modules/postureManagement/remediation/services/RemediationTableRegestries';
import { useTranslation } from 'react-i18next';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { I18nRemediation } from '../helpers/remediation.consts';
import { CellClickedEvent } from 'ag-grid-community/dist/lib/events';

interface IModalConfigurations {
    isOpen: boolean,
    onConfirm: () => any,
    onClose: () => any,
    title: string,
    text: string,
    content: string,
}

const RemediationTable: FC<IRemediationTableProps> = ({ pageSize, data,totalCount, onDelete ,onAdd, onRowClicked }) => {
    const { t } = useTranslation(I18nRemediation);
    const apiRef = useRef<GridApi>();
    const [selectedRows, setSelectedRows] = useState<IRemediation[]>([]);
    const [modalConfigurations, setModalConfigurations] = useState({} as IModalConfigurations);


    useEffect(() => {
        if (apiRef.current) {
            apiRef.current.setRowData(data);
        }
    }, [data]);

    const columnDefs = useMemo<ColDef[]>(() => {
        return RemediationTableRegistry.getColumnDefs();
    } , []);

    const handleCellClicked = (event: CellClickedEvent<IRemediation>) => {
        if(event.data?.id) onRowClicked && onRowClicked(event.data!);
    };

    const onSelectionChanged = (params: SelectionChangedEvent<IRemediation>) => {
        const selectedItems = params.api.getSelectedRows();
        setSelectedRows(selectedItems);
    };

    const onModalClose = () => {
        setModalConfigurations({ ...modalConfigurations, isOpen: false });
    };

    const openDeleteModal = () => {
        const newModalConfigurations = {} as IModalConfigurations;
        // SHOULD CHECK IT WHEN WORK
        newModalConfigurations.title = selectedRows.length > 1 ? t('REMEDIATION.MODALS.DELETE.MULTIPLE_DELETION_TITLE') : t('REMEDIATION.MODALS.DELETE.SINGLE_DELETION_TITLE');
        newModalConfigurations.text = t('REMEDIATION.MODALS.DELETE.CONFIRMATION_TEXT');
        newModalConfigurations.content = selectedRows.length > 1 ? t('REMEDIATION.MODALS.DELETE.REMEDIATION_IDS',{ remediationsLength: selectedRows.length }) : t('REMEDIATION.MODALS.DELETE.REMEDIATION_ID',{ remediationId: selectedRows[0].id });
        newModalConfigurations.onClose = onModalClose;
        newModalConfigurations.onConfirm = ()=>{
            onDelete(selectedRows);
            onModalClose();
        };
        newModalConfigurations.isOpen = true;
        setModalConfigurations(newModalConfigurations);
    };

    const defaultActions = useMemo<ITableAction[]>(() => {
        return [
            {
                id: 'addRemediation',
                name: t('REMEDIATION.BUTTONS.ADD'),
                icon: { name: 'plus' },
                callback: () => onAdd(),
                isActionDisabled: () => false,
            },
            {
                id: 'deleteRemediation',
                name: t('REMEDIATION.BUTTONS.DELETE'),
                icon: { name: 'delete' },
                callback: () => openDeleteModal(),
                isActionDisabled: () => selectedRows.length === 0,
            }
        ];
    }, [selectedRows]);


    const onGridReady = useCallback(
        (params: GridReadyEvent<IRemediation, any>) => {
            apiRef.current = params.api;
        },
        [],
    );

    const gridOptions: GridOptions<IRemediation> = {
        columnDefs,
        rowSelection: 'multiple',
        onGridReady,
        getRowId: (params) => params.data.id!,
        onCellClicked: handleCellClicked,
        onRowSelected: onSelectionChanged
    };

    return (
        <>
            <Table
                pageSize={pageSize}
                gridOptions={gridOptions}
                actions={defaultActions}
                disableGrouping
                footer={t('REMEDIATION.TABLE.FOOTER', { currentCount: data.length.toLocaleString(), totalCount: totalCount.toLocaleString() })}
            />
            {modalConfigurations.isOpen && <ConfirmationModal
                isOpen={modalConfigurations.isOpen}
                onConfirm={modalConfigurations.onConfirm}
                title={modalConfigurations.title} 
                text={modalConfigurations.text}
                onClose={modalConfigurations.onClose} 
                onCancel={modalConfigurations.onClose}>
                <Typography variant='subtitleLg'>{modalConfigurations.content}</Typography>
            </ConfirmationModal>}
        </>
    );
};


export default RemediationTable;
