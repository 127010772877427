
import { IPolicy, IPolicyService, IShallowPolicy } from 'common/interface/policy';
import { getHttpService, ISetPolicyResponse } from 'common/interface/services';

export class ServerlessPolicyService implements IPolicyService {
    async getPolicies(useCache= true): Promise<IShallowPolicy[]> {
        try {
            return await getHttpService().get<IShallowPolicy[]>({
                path: 'serverless/policy',
                cachingConfig: { useCache } });
        } catch {
            return[];
        }
    }
    async setPolicy(data:IPolicy[]): Promise<ISetPolicyResponse[]> {
        return await getHttpService().post<Promise<ISetPolicyResponse[]>>('serverless/policy', { data });
    }
    async updatePolicy(data:IPolicy[]): Promise<ISetPolicyResponse[]> {
        return await getHttpService().put<Promise<ISetPolicyResponse[]>>('serverless/policy', { data });
    }
    async deletePolicy(policyId:string): Promise<ISetPolicyResponse[]> {
        return await getHttpService().delete<Promise<ISetPolicyResponse[]>>('serverless/policy/'+policyId);
    }


}
