import { FC } from 'react';
import { useParams } from 'react-router-dom';
import WelcomeNetworkTraffic from './WelcomeNetworkTraffic';
import WelcomeAccountActivity from './WelcomeAccountActivity';
import { AssetType } from '../../AzureIntelligenceOnboarding.const';
import { AzureWelcomePageProps } from '../../AzureIntelligenceOnboarding.interface';

const Welcome: FC<AzureWelcomePageProps> = (props: AzureWelcomePageProps) => {
    const { type } = useParams<{ type: AssetType }>();

    return (
        type === AssetType.flowLogs ? <WelcomeNetworkTraffic onChangeType={props.onChangeType}/> :
            <WelcomeAccountActivity/>
    );
};

export default Welcome;
