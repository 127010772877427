
export const ISSUE_EXCLUSIONS_RESOURCE_URL = 'security-graph/exclusions';

export const ISSUE_EXCLUSIONS_SERVICE_ID = 'IssueExclusionsService';

export enum ISSUE_EXCLUSION_FIELD_NAMES {
    ID = 'id',
    NAME = 'name',
    DESCRIPTION = 'description',
    RULE_IDS = 'ruleIds',
    SEVERITIES = 'severities',
    ORG_UNIT_IDS = 'orgUnitIds',
    ENV_IDS = 'envIds',
    ENTITY_NAMES = 'entityNames',
    ENTITY_ID = 'entityId',
    ENTITY_TYPES = 'entityTypes',
    DATE_RANGE = 'dateRange',
    TAGS = 'tags',
    ORG_UNIT_OPTIONS = 'orgUnitOptions',
    RULE_OPTIONS = 'ruleOptions',
    ENV_OPTIONS = 'envOptions',
    ENTITY_TYPE_OPTIONS = 'entityTypeOptions',
}

export enum ISSUE_EXCLUSION_COND_NAMES {
    RULE_ID = 'ruleId',
    SEVERITY = 'severity',
    ORG_UNIT_ID = 'orgUnitId',
    ENV_ID = 'environmentId',
    ENTITY_NAME = 'entityName',
    ENTITY_EXTERNAL_ID = 'entityExternalId',
    ENTITY_TYPE_BY_PLATFORM = 'entityTypeByPlatform',
    TIMESTAMP = 'timestamp',
    ENTITY_TAGS = 'entityTags',
}

export const ISSUE_EXCLUSIONS_PAGE_TABLE_ID = 'ISSUE_EXCLUSIONS_PAGE_TABLE_ID';
