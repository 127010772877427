import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams } from 'common/components/Wizard/Wizard';
import { IPolicy, IShallowRuleset } from 'common/interface/policy';
import { CardItem } from 'common/components/policy/Card';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Vendors } from 'common/consts/vendors';
import { Done } from 'common/components/policy/Done/Done';
import { I18nIdentityNamespace } from '../consts';
import { CIEMPlatformsSelectionComponent } from './CIEMPlatformsSelectionComponent';
import { getCiemRuleset, setCIEMPolicies } from './utils';
import { OUSelectionComponent } from 'common/components/policy/OUSelectionComponent';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { finishFlow } from 'common/components/policy/utils';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

export const AddCiemOUPolicyPage: React.FC = () => {
    const selectedRuleset : IShallowRuleset = getCiemRuleset();
    const [selectedOUs,setSelectedOUs] = useState<ListItem[]>([]);
    const { t } = useTranslation(I18nIdentityNamespace);
    const [selectedPlatform,setSelectedPlatform] = useState<Vendors>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const returnUrl = useReturnUrl();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    useBreadcrumbsLastChildren([t('NAVIGATION_MENU.CIEM.TITLE'),t('POLICY.ADD_OU_POLICY')]);
    const saveChanges = useCallback( async () => {
        const policies:IPolicy[] = [];
        if(selectedNotifications?.length ){
            for (const ou of selectedOUs) {
                const policy:IPolicy = {
                    vendor: selectedPlatform,
                    targetType: 'organizationalUnit',
                    rulesetId: +selectedRuleset.id,
                    targetId: ou.id,
                    notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                };
                policies.push(policy);
            }


            if(policies.length){
                setFlowStatusMessage(t('POLICY.PROCESSING'));
                try{
                    const result = await setCIEMPolicies(policies);
                    if(result) {
                        setFlowStatusOK(true);
                        setFlowStatusMessage(t('POLICY.POLICY_ADDED_MESSAGE'));
                    }else {
                        setFlowStatusOK(false);
                        setFlowStatusMessage(t('POLICY.ERROR_OCCURRED'));
                    }
                }catch {
                    setFlowStatusOK(false);
                    setFlowStatusMessage(t('POLICY.ERROR_OCCURRED'));
                }
            }
        }
    },[selectedNotifications, selectedRuleset.id, t]);

    const steps = useMemo(() => [{
        name: t('POLICY.PLATFORM_SELECTION'),
        renderView: (renderViewParams:RenderViewParams) => {
            return <CIEMPlatformsSelectionComponent selectedPlatformId={selectedPlatform} {...renderViewParams } selectedItemChanged={selectedPlatformChanged} />;
        },
        onNext: async ()=>{
            return ;
        },
    },{
        name: t('POLICY.OU_SELECTION'),
        renderView: (renderViewParams:RenderViewParams) => {
            return <OUSelectionComponent selectedOUsChanged={setSelectedOUs} {...renderViewParams } />;
        },
        onNext: async ()=>{
            return ;
        },
    },{
        name: t('POLICY.NOTIFICATION_SELECTION'),
        renderView: (renderViewParams:RenderViewParams) => {
            return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={setSelectedNotifications}/>;
        },
        onNext: async ()=>{
            saveChanges();
        },
    },{
        name: t('POLICY.DONE'),
        renderView: (renderViewParams:RenderViewParams) => {
            return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
        },
        onNext: async ()=>{
            finishFlow(returnUrl);
        },
    }],[flowStatusMessage, flowStatusOK, saveChanges, selectedNotifications, selectedPlatform, t]);

    const selectedPlatformChanged = (selectedItem: CardItem) => {
        selectedItem && setSelectedPlatform(selectedItem.id);
        setSelectedOUs([]);
        setSelectedNotifications([]);
    };

    return (
        <div>
            {steps?.length ? <Wizard
                steps={steps}
                title={t('POLICY.ADD_POLICY')}
                doneButtonText={t('POLICY.DONE')}
                onClose={() => finishFlow(returnUrl)}
            /> : null}
        </div>
    );
};
