import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import FindingGraphWrapper from '../FindingGraphWrapper';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
export const VisualizationGraphPanel: React.FC<{ finding: IFinding }> = ({ finding }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    return (
        <div className={'w-full'}>
            <div className={'font-bold'}>{t('INT_FINDING_OVERVIEW.EVENTS_GRAPH')}</div>
            <FindingGraphWrapper expandedFindingDetailsProps={ { finding: finding } } />
        </div>
    );
};
