import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../consts';

const RiskLevelDottedHeader: React.FC<{ title: string }> = ({ title }) => {
    const { t } = useTranslation();
    return (
        <div className='flex-0 mt-[32px] mb-5 flex items-center'>
            <div className='flex-0 mb-5 font-semibold mr-5'>
                {title}:
            </div>
            <div className='flex gap-[2px] mr-6 -mt-[5px]'>
                <div
                    className='rounded-full font-medium theme text-strong leading-[1em] text-2xs py-[3px] theme-dark px-[6px] flex-0 bg-danger-darkest'>
                    {t('RISK_MANAGEMENT.DASHBOARD.CRITICAL_RANGE', { ns: I18nRiskNamespace })}
                </div>
                <div
                    className='rounded-full font-medium theme text-strong leading-[1em] text-2xs py-[3px] theme-dark px-[6px] flex-0 bg-danger'>
                    {t('RISK_MANAGEMENT.DASHBOARD.HIGH_RANGE', { ns: I18nRiskNamespace })}
                </div>
            </div>
            <div className='mb-2 mr-auto flex-1 self-center border-b border-dashed'></div>
        </div>
    );
};

export default RiskLevelDottedHeader;