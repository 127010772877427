import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Stack from 'common/design-system/components-v2/Stack';
import DropDown from '../../DropDown/DropDown';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { Input, Typography } from 'common/design-system/components-v2';
import { I18nNotifications } from '../../../NotificationsPage.consts';
import { useTranslation } from 'react-i18next';
import { FREQUENCY_TYPES, generateCronExpression, revertCronExpression } from './timeConvertor';

interface ScheduleReportTimeSelectionProps{
    selectedCronExpression?: any,
    onTimeSelectionChangedCallBack: (cronExpression: string) => void,
    isReadOnly?: boolean
}

const DAYS = {
    SUNDAY: '1',
    MONDAY: '2',
    TUESDAY: '3',
    WEDNESDAY: '4',
    THURSDAY: '5',
    FRIDAY: '6',
    SATURDAY: '7'
};


const hourOptions :IListItemProps[] = [...Array(12).keys()].map(i => {return { value: (i+1).toString(), label: (i+1).toString() };});
const minutesOptions :IListItemProps[] = [...Array(6).keys()].map(i => {return { value: ((i)*10).toString(), label: ((i)*10).toString() };});
const AMPMoptions : IListItemProps[] = [{ label: 'AM', value: 'AM' },{ label: 'PM', value: 'PM' }];


const ScheduleReportTimeSelection: React.FC<ScheduleReportTimeSelectionProps> = ({ selectedCronExpression , onTimeSelectionChangedCallBack, isReadOnly=false }) => {
    const { t } = useTranslation(I18nNotifications);

    const frequencyTypeOptions :IListItemProps[] = useMemo(() => [
        { label: t('MODALS.ADD_EDIT_MODAL.FREQUENCY.DAILY'), value: FREQUENCY_TYPES.DAILY },
        { label: t('MODALS.ADD_EDIT_MODAL.FREQUENCY.WEEKLY'), value: FREQUENCY_TYPES.WEEKLY },
        { label: t('MODALS.ADD_EDIT_MODAL.FREQUENCY.MONTHLY'), value: FREQUENCY_TYPES.MONTHLY },
        { label: t('MODALS.ADD_EDIT_MODAL.FREQUENCY.CUSTOM'), value: FREQUENCY_TYPES.CUSTOM },
    ],[t]);
    const daysOptions :IListItemProps[] = useMemo(()=>[
        { label: t('MODALS.ADD_EDIT_MODAL.DAYS.MONDAY'), value: DAYS.MONDAY },
        { label: t('MODALS.ADD_EDIT_MODAL.DAYS.TUESDAY'), value: DAYS.TUESDAY },
        { label: t('MODALS.ADD_EDIT_MODAL.DAYS.WEDNESDAY'), value: DAYS.WEDNESDAY },
        { label: t('MODALS.ADD_EDIT_MODAL.DAYS.THURSDAY'), value: DAYS.THURSDAY },
        { label: t('MODALS.ADD_EDIT_MODAL.DAYS.FRIDAY'), value: DAYS.FRIDAY },
        { label: t('MODALS.ADD_EDIT_MODAL.DAYS.SATURDAY'), value: DAYS.SATURDAY },
        { label: t('MODALS.ADD_EDIT_MODAL.DAYS.SUNDAY'), value: DAYS.SUNDAY },
    ],[t]);
    const getCorrectTimeValues = useCallback((selectedCronExpression: string) => {
        const { frequencyType, selectedMinutes, selectedDay, selectedHour, selectedAMPM } = revertCronExpression(selectedCronExpression);
        return {
            frequencyOption: frequencyTypeOptions.find(type => type.value === frequencyType),
            weekday: daysOptions.find(dayOption => dayOption.value === selectedDay.toString()),
            hour: hourOptions.find(hourOption => hourOption.value === selectedHour.toString()),
            minutes: minutesOptions.find(minutesOption => minutesOption.value === selectedMinutes.toString()),
            meridian: AMPMoptions.find(AMPMoption => AMPMoption.value === selectedAMPM),
        };
    },[daysOptions, frequencyTypeOptions]);
    const [selectedFrequencyType, setSelectedFrequencyType] = useState(selectedCronExpression && getCorrectTimeValues(selectedCronExpression).frequencyOption || frequencyTypeOptions[0]);
    const [selectedHour, setSelectedHour] = useState(selectedCronExpression && getCorrectTimeValues(selectedCronExpression).hour || hourOptions[0]);
    const [selectedMinutes, setSelectedMinutes] = useState(selectedCronExpression && getCorrectTimeValues(selectedCronExpression).minutes || minutesOptions[0]);
    const [selectedAMPM, setSelectedAMPM] = useState(selectedCronExpression && getCorrectTimeValues(selectedCronExpression).meridian || AMPMoptions[0]);
    const [selectedDay, setSelectedDay] = useState(selectedCronExpression && getCorrectTimeValues(selectedCronExpression).weekday || daysOptions[0]);
    const [customInput, setCustomInput] = useState(selectedCronExpression || '');
    const frequencyTypeExtraFields = useMemo(()=> {
        return {
            [FREQUENCY_TYPES.WEEKLY]: <> on <DropDown options={daysOptions} initialSelectedOption={selectedDay.value} onSelectionChangedCallBack={(option) => handleSelectionChanged(option, setSelectedDay)}/></>,
            [FREQUENCY_TYPES.MONTHLY]: <> on the 1st</>,
            [FREQUENCY_TYPES.CUSTOM]: <Stack spacing={2} alignItems={'center'} direction={'row'}><Input inputStyles={{ width: '150px' }} placeholder={t('MODALS.ADD_EDIT_MODAL.ENTER_CRON_EXPRESSION')} disabled={isReadOnly}
                value={customInput} onChange={(event)=>handleSelectionChanged(event.target.value, setCustomInput)}/>
            <Typography>{t('MODALS.ADD_EDIT_MODAL.UTC_TIME')}</Typography></Stack>

        };
    },[isReadOnly, selectedDay.value]);

    useEffect(() => {
        if(selectedFrequencyType.value === FREQUENCY_TYPES.CUSTOM){
            onTimeSelectionChangedCallBack(customInput);
        } else{
            onTimeSelectionChangedCallBack(generateCronExpression({
                frequencyType: selectedFrequencyType.value,
                selectedDay: +selectedDay.value,
                selectedHour: +selectedHour.value,
                selectedMinutes: +selectedMinutes.value,
                selectedAMPM: selectedAMPM.value as 'AM'|'PM'
            }));
        }
    }, [selectedFrequencyType, selectedAMPM, selectedDay, selectedMinutes, selectedHour, customInput]);

    const handleSelectionChanged = (option:any, setFunction: Function) => {
        setFunction(option);
    };
    

    const renderExtraFieldsByFrequencyType = useCallback(() => {
        return frequencyTypeExtraFields[selectedFrequencyType.value];
    },[frequencyTypeExtraFields, selectedFrequencyType.value]);

    return ( <Stack alignItems={'center'} flexWrap={true} direction={'row'} fullWidth spacing={2}>
        <DropDown options={frequencyTypeOptions} initialSelectedOption={selectedFrequencyType.value} onSelectionChangedCallBack={(option) => handleSelectionChanged(option, setSelectedFrequencyType)}/>
        <>{
            renderExtraFieldsByFrequencyType()
        }</>
        <>{
            selectedFrequencyType.value !== FREQUENCY_TYPES.CUSTOM && <>
                <Typography> at </Typography>
                <DropDown options={hourOptions} initialSelectedOption={selectedHour.value} onSelectionChangedCallBack={(option) => handleSelectionChanged(option, setSelectedHour)}/>
                <Typography>:</Typography>
                <DropDown options={minutesOptions} initialSelectedOption={selectedMinutes.value} onSelectionChangedCallBack={(option) => handleSelectionChanged(option, setSelectedMinutes)}/>
                <DropDown options={AMPMoptions} initialSelectedOption={selectedAMPM.value} onSelectionChangedCallBack={(option) => handleSelectionChanged(option, setSelectedAMPM)}/>
                <Typography>{t('MODALS.ADD_EDIT_MODAL.LOCAL_TIME')}</Typography>
            </>}
        </>
    </Stack> );
};

export default ScheduleReportTimeSelection;