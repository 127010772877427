import { ICellRendererParams } from 'ag-grid-enterprise';
import { FC } from 'react';
import { MultiOptionChipsComponent } from 'common/erm-components/custom/MultiChipCellContent/MultiOptionChipsComponent';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';

export const MultiOptionChipsCellRender: FC<ICellRendererParams & { getOptions: () => Promise<SelectOption[]> }> = (params) => {
    const values: string[] | undefined = params.value;
    if (!values || (values.length === 0)) {
        return null;
    }

    return <MultiOptionChipsComponent selectedValues={values} fetchAllOptions={params.getOptions} />;
};
