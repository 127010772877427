import React, { useEffect, useMemo, useState } from 'react';
import { CpLoadingDots } from '@dome9/components/react/components';
import { extractFilterFieldsToNewModel, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getHttpService, getStoreService, ICustomzationResponse } from 'common/interface/services';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import {
    getProtectedAssetsService,
    IProtectedAssetFilter
} from
    'common/module_interface/assets/ProtectedAssets';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import ProtectedAssetsTable, { CounterStatus } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { AssetAggregationCacheConfig, Datasource, IDataSourceConfig } from 'common/components/ProtectedAssets/datasource';
import { FILTER_PANEL_QUERY_NAMES, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { EXTERNAL_ADDITIONAL_FIELDS_SOURCE } from 'common/components/ProtectedAssets/ProtectedAssetsTable.consts';
import { setIsReactPageWithAngular } from '../../../../App.reducer';
import AssetTabs from 'common/components/ProtectedAssets/AssetTabs';
import { profileComponent } from 'common/components/profileComponent';
import { IColumnUsageDef } from 'common/interface/general';
import {
    PATH_PROTECTED_ASSETS_PAGE_TABLE_COLUMNS, PATH_PROTECTED_ASSETS_PAGE_TABLE_FILTERS
} from 'common/module_interface/assets/ProtectedAssets.consts';
import { getAssets } from 'common/assets/Assets.service';

const defaultDatasourceConfig: IDataSourceConfig = {
    pageSize: 50,
    filters: {},
    externalAdditionalFields: {
        source: EXTERNAL_ADDITIONAL_FIELDS_SOURCE.THIRD_PARTY,
    },
};

interface IWebAppFilterParams {
    filter?: {
        fields?: IWebAppFilter[];
        freeTextPhrase?: string;
        tags?: IWebAppKeyValue[]
    };
}

interface IWebAppFilter {
    name: string,
    value: string
}

interface IWebAppKeyValue {
    key: string,
    value: string
}


function getIncludedEntityTypes(): string[] {
    return getAssets().map(asset => asset.typeByPlatform);
}

const ProtectedAssetsList: React.FC = () => {
    const [filterElement, setFilterElement] = useState(<div></div>);
    const [datasource, setDatasource] = useState(new Datasource(defaultDatasourceConfig));
    const [totalCount, setTotalCount] = useState(CounterStatus.Pending);
    const [currentCount, setCurrentCount] = useState(CounterStatus.Pending);
    const [isLoading, setIsLoading] = useState(true);
    const [isFilterLoading, setIsFilterLoading] = useState(false);

    const columns = useMemo(() => {
        return globalAddinContainer.get<IColumnUsageDef>(
            PATH_PROTECTED_ASSETS_PAGE_TABLE_COLUMNS,
        );

    }, []);

    const includedEntityTypes = useMemo<string[]>(() => getIncludedEntityTypes(), []);
    const FILTER_PANEL_ID = 'protected-assets-page-filter-panel-id';

    useEffect(() => {
        const dispatch = getStoreService().dispatch;
        dispatch(setIsReactPageWithAngular(false));
    });

    useEffect(() => {

        async function getAggregations(filtersValues: IFiltersValues): Promise<Aggregations> {
            const tempDatasource = new Datasource({
                ...defaultDatasourceConfig, ...{
                    filters: {
                        ...filtersValues,
                        includedEntityTypes,
                    },
                    cachingConfig: AssetAggregationCacheConfig,
                },
            });
            setDatasource(tempDatasource);
            setIsFilterLoading(true);
            const searchResponse = await tempDatasource.getAdHokDataFromServer();
            setCurrentCount(tempDatasource.totalCount);
            setIsFilterLoading(false);
            return searchResponse.aggregations;
        }

        const extractWebAppFiltersFromQueryParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const webAppQueryParams = queryParams.get(FILTER_PANEL_QUERY_NAMES.QUERY);

            if (webAppQueryParams) {
                const urlFilters: IWebAppFilterParams = JSON.parse(webAppQueryParams);
                const filter = urlFilters?.filter;
                const fields = filter?.fields ? extractFilterFieldsToNewModel(filter.fields) : {};
                const freeTextPhrase = filter?.freeTextPhrase ? { [FILTERS_KEYS.FREE_TEXT]: filter?.freeTextPhrase } : {};
                const tags = filter?.tags ? { [FILTERS_KEYS.TAGS]: filter.tags } : [];

                return { ...fields, ...freeTextPhrase, ...tags };
            }
        };

        async function renderFilterPanel(aggregations: Aggregations) {
            const filtersInitialData = await getFiltersInitialData();
            const webAppQueryParams = extractWebAppFiltersFromQueryParams();
            const filters = globalAddinContainer.get<IProtectedAssetFilter>(PATH_PROTECTED_ASSETS_PAGE_TABLE_FILTERS);
            const filterPanelElementsList = getProtectedAssetsService().getFilterDefs(filters, {
                aggregations,
                filtersInitialData,
                allCloudAccounts: filtersInitialData.allCloudAccounts,
            });
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: true,
                webAppQueryParams: webAppQueryParams,
                filterId: FILTER_PANEL_ID,
            });
            setFilterElement(filterPanel);
        }

        async function initPage() {
            setIsLoading(true);
            const tempDatasource = new Datasource({ ...defaultDatasourceConfig, ...{ filters: { includedEntityTypes }, cachingConfig: AssetAggregationCacheConfig } });
            const initialData = await tempDatasource.getAdHokDataFromServer();
            setTotalCount(initialData.totalCount);
            await renderFilterPanel(initialData.aggregations);
            setIsLoading(false);
        }

        initPage();
    }, [includedEntityTypes]);

    const getFiltersInitialData = async () => {
        const savedFiltersPromise = getHttpService().request<ICustomzationResponse<any>>('customization?component=server_index', {
            method: 'GET',
        });
        const recentlyUsedPromise = getHttpService().request<ICustomzationResponse<any>>('customization?component=server_index_recent', {
            method: 'GET',
        });
        const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
        const allCloudAccountsPromise = getCloudAccountsService().getAllCloudAccounts();
        return Promise.all([savedFiltersPromise, recentlyUsedPromise, organizationalUnitsPromise, allCloudAccountsPromise]).then(
            ([savedFilters, recentlyUsed, organizationalUnits, allCloudAccounts]) => {
                return {
                    savedFilters,
                    recentlyUsed,
                    organizationalUnits,
                    allCloudAccounts,
                };
            },
        );
    };


    return (
        <div className='h-full inset-0 flex-column' data-aid='protected-assets-page'>

            <div className='flex-column grow '>
                {isLoading ? (
                    <CpLoadingDots />
                ) : (
                    <>
                        <AssetTabs pageId={'assetsProtectedAssets'} enableTabSelection={false}
                            closeAllUrl={'/protected-asset'}></AssetTabs>
                        <div className='flex flex-column flex-1 p-8'>
                            <div className='relative z-40'>
                                {filterElement}
                            </div>
                            <ProtectedAssetsTable
                                columns={columns}
                                isLoading={isFilterLoading}
                                totalCount={totalCount}
                                currentCount={currentCount}
                                pageSize={defaultDatasourceConfig.pageSize}
                                datasource={datasource}
                                tableId='protected-asset-table'></ProtectedAssetsTable>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default profileComponent(ProtectedAssetsList);
