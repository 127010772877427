import i18n from 'common/services/translations/translations';
import { EMAIL_REGEX } from 'common/utils/http';
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,20}$/;
export const PHONE_NUMBER_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
export const PASSWORD_LOWER_UPPER_REGEX = /^(?=.*[a-z])(?=.*[A-Z])/;
export const PASSWORD_NUMBER_SPECIAL_REGEX = /^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;

const passwordMin = 8;
const passwordMax = 20;

class FormValidations {
    validateEmail(email: string, setEmail: Function) {
        const emailValid = email && EMAIL_REGEX.test(email);
        if (emailValid) {
            setEmail({ value: email, errorMessage: '', state: 'normal' });
        } else {
            setEmail({ value: email, errorMessage: i18n.t('FORMS.SIGNUP_FORM.ENTER_EMAIL') });
        }
        return emailValid;
    }

    validateFirstName(firstName: string, setFirstName: Function) {
        const firstNameValid = firstName && firstName.length;
        if (firstNameValid) {
            setFirstName({ value: firstName, errorMessage: '', state: 'normal' });
        } else {
            setFirstName({
                value: firstName,
                errorMessage: i18n.t('FORMS.SIGNUP_FORM.FIRST_NAME_EMPTY'),
                state: 'error',
            });
        }
        return firstNameValid;
    }

    validateLastName(lastName: string, setLastName: Function) {
        const lastNameValid = lastName && lastName.length;
        if (lastNameValid) {
            setLastName({ value: lastName, errorMessage: '', state: 'normal' });
        } else {
            setLastName({ value: lastName, errorMessage: i18n.t('FORMS.SIGNUP_FORM.LAST_NAME_EMPTY'), state: 'error' });
        }
        return lastNameValid;
    }

    validatePassword(password: string, setPassword: Function) {
        const passwordValid = password && PASSWORD_REGEX.test(password);
        if (passwordValid) {
            setPassword({ value: password, errorMessage: '', state: 'normal' });
        } else {
            setPassword({
                value: password,
                errorMessage: `
                      ${i18n.t('FORMS.SIGNUP_FORM.PASSWORD_VALIDATION.TILE')} 
${i18n.t('FORMS.SIGNUP_FORM.PASSWORD_VALIDATION.LENGTH', {
        minBound: passwordMin,
        maxBound: passwordMax,
    })} ${i18n.t('FORMS.SIGNUP_FORM.PASSWORD_VALIDATION.CASE_CHARACTERS')} ${i18n.t(
    'FORMS.SIGNUP_FORM.PASSWORD_VALIDATION.NUMBER_SPECIAL'
)}
                    `,
                state: 'error',
            });
        }
        return passwordValid;
    }

    getPasswordValidationHelperValues(password: string): PasswordValidationHelperValues[] {
        return [
            {
                value: i18n.t('FORMS.SIGNUP_FORM.PASSWORD_VALIDATION.LENGTH', {
                    minBound: passwordMin,
                    maxBound: passwordMax,
                }),
                isValid: password.length >= passwordMin && password.length <= passwordMax,
            },
            {
                value: i18n.t('FORMS.SIGNUP_FORM.PASSWORD_VALIDATION.CASE_CHARACTERS'),
                isValid: PASSWORD_LOWER_UPPER_REGEX.test(password),
            },
            {
                value: i18n.t('FORMS.SIGNUP_FORM.PASSWORD_VALIDATION.NUMBER_SPECIAL'),
                isValid: PASSWORD_NUMBER_SPECIAL_REGEX.test(password),
            },
        ];
    }

    validateConfirmPassword(confirmPassword: string, password: string, setConfirmPassword: Function) {
        const confirmPasswordValid = confirmPassword === password;
        if (confirmPasswordValid) {
            setConfirmPassword({ value: confirmPassword, errorMessage: '', state: 'normal' });
        } else {
            setConfirmPassword({
                value: confirmPassword,
                errorMessage: i18n.t('FORMS.SIGNUP_FORM.PASSWORD_VALIDATION.PASSWORD_MATCH'),
                state: 'error',
            });
        }
        return confirmPasswordValid;
    }

    validateCompanyName(companyName: string, setCompanyName: Function) {
        const companyNameValid = companyName && companyName.length;
        if (companyNameValid) {
            setCompanyName({ value: companyName, errorMessage: '', state: 'normal' });
        } else {
            setCompanyName({
                value: companyName,
                errorMessage: i18n.t('FORMS.SIGNUP_FORM.COMPANY_EMPTY'),
                state: 'error',
            });
        }
        return companyNameValid;
    }

    validatePhoneNumber(phoneNumber: string, setPhoneNumber: Function) {
        const phoneNumberValid = phoneNumber && phoneNumber.length && PHONE_NUMBER_REGEX.test(phoneNumber);
        if (phoneNumberValid) {
            setPhoneNumber({ value: phoneNumber, errorMessage: '', state: 'normal' });
        } else {
            setPhoneNumber({
                value: phoneNumber,
                errorMessage: i18n.t('FORMS.SIGNUP_FORM.INVALID_PHONE_NUMBER'),
                state: 'error',
            });
        }
        return phoneNumberValid;
    }
}

interface PasswordValidationHelperValues {
    value: string;
    isValid: boolean;
}

export default new FormValidations();
