import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { MagellanDisableMagellanForCloudAccount } from './magellan.interface';

const magellanDisableMagellanForCloudAccount: MagellanDisableMagellanForCloudAccount.Function = async (request: MagellanDisableMagellanForCloudAccount.Request) => {
    return await getHttpService().request<AxiosResponse<MagellanDisableMagellanForCloudAccount.Response>>(
        'view/magellan/disable-magellan-for-cloud-account',
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const MagellanService = {
    magellanDisableMagellanForCloudAccount,
};

export default MagellanService;
