import { FC } from 'react';
import { I18nExclusion, rangeType } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { IExclusionDateProps } from '../../helpers/exclusions.interfaces';
import { IDateRange } from 'common/design-system/components-v2/DatePicker/DatePicker.types';
import { DatePicker } from 'common/design-system/components-v2';


const ExclusionByDate: FC<IExclusionDateProps> = ({ onChange, selectedOption }) => {
    const { t } = useTranslation(I18nExclusion);
    const handleDateChange = (date?: IDateRange) => {
        onChange(date);
    };

    return (
        <DatePicker
            label={t('MODAL.TOPICS.DATEPICKER.TITLE')}
            type={rangeType}
            value={selectedOption}
            onChange={date => handleDateChange(date)}
        />
    );
};

export default ExclusionByDate;