import React from 'react';
import { useSelector } from 'react-redux';
import AzureActions from '../azure.actions';
import { AzureSelector } from '../azure.selectors';
import { RootState } from 'common/services/store/store';
import { AzureEnvironmentData } from 'common/module_interface/assets/AzureEnvironment';

export interface UseGetAzurePayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isDeleted: boolean;
    data?: AzureEnvironmentData;
    reloadData: () => void;
}

export type UseGetAzure = (azureId: string) => UseGetAzurePayload;

export const useGetAzure: UseGetAzure = (azureId) => {
    const { loading, error, data, deleted } = useSelector((state: RootState) => AzureSelector.getAzure(state, azureId)) || {};
    const getData = (id: string) => AzureActions.getAzureAccount(id);
    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(azureId);
        }
    }, [azureId, data, loading, error]);
    const reloadData = React.useCallback(() => getData(azureId), [azureId]);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        isDeleted: !!deleted,
        data,
        reloadData
    });
};