import { createSelector } from '@reduxjs/toolkit';
import { GcpState } from './gcp.interface';
import { RootState } from 'common/services/store/store';

const gcpReducer = (state: RootState) => state.env_gcp as GcpState;

const getGcp = createSelector([gcpReducer, (_state: RootState, id: string) => id], (state, id) => state.gcpEvnironments[id]);

export const GcpSelector = {
    getGcp,
};