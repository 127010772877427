import { IOrganizationalUnitAllChildrens } from 'common/interface/data_services';
import { IEnvironment } from 'common/interface/environmentsTable';

export interface Filters {
    fields?: { value: string; name: string }[];
    freeTextPhrase?: string;
}

export const filterDataByFilters = (allOus: IOrganizationalUnitAllChildrens[], data: IEnvironment[], filters: Filters) => {
    let filteredData = [...data];
    
    if (filters.fields && filters.fields.length > 0) {
        filteredData = filteredData.filter(cloudAccount => {
            const FiltersNameKeys = (filters.fields || []).reduce<{ [filterName: string]: boolean }>((acc, curr) => ({ ...acc, [curr.name]: false }), {});
            filters.fields?.forEach(filter => {
                if (filter.name === 'organizationalUnitId') {
                    if (cloudAccount[filter.name] === filter.value) {
                        FiltersNameKeys[filter.name] = true;
                        return;
                    }
                    const parentOU = allOus.find(org => org.id === filter.value);

                    if (parentOU && parentOU.allChildrens.length > 0) {
                        const isFoundInParent = parentOU.allChildrens.some(org => org.id === cloudAccount.organizationalUnitId);
                        if (isFoundInParent) {
                            FiltersNameKeys[filter.name] = true;
                        }
                    }
                    return;
                }
                if (cloudAccount[filter.name] === filter.value) {
                    FiltersNameKeys[filter.name] = true;
                }
            });
            return Object.values(FiltersNameKeys).every(x => x);
        });
    }
    if (filters.freeTextPhrase && filters.freeTextPhrase.length > 0) {
        const findInFieldsKeys = ['id', 'externalId', 'name', 'description', 'credentialId', 'organizationalUnitId', 'customData|organizationalUnitPath'];
        filteredData = filteredData.filter(cloudAccount => {
            return findInFieldsKeys.some(key => {
                const valueInAccount = cloudAccount[key];
                if (!valueInAccount) return false;
                if (typeof valueInAccount !== 'string' && typeof valueInAccount !== 'number') return false;
                const valueAsString = valueInAccount.toString();
                return valueAsString.toLowerCase().includes((filters.freeTextPhrase as string).toLowerCase());
            });
        });
    }
    return filteredData;
};