import { Vendors } from 'common/consts/vendors';
import { Addin } from 'common/extensibility/AddinRegistry';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { StatusCellRenderer_Status } from 'common/module_interface/assets/Environments';
import { AddCustomDataById, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';

const syncStatusRelevantPlatforms = [Vendors.AWS, Vendors.AZURE, Vendors.GOOGLE];

const customDataDefs: Addin<AddCustomDataById>[] = [
    {
        id: 'add-env-table-sync-status',
        content: {
            customData: async (allCloudAccounts) => {
                const relevantCloudAccounts = allCloudAccounts.filter(({ platform }) => syncStatusRelevantPlatforms.some(syncPlat => syncPlat === platform));
                try {
                    const allCountAccountsStatus = await getCloudAccountsService().getEnvironmentSyncStatus(true, err => { throw err; });
                    return relevantCloudAccounts.map(({ id }) => {
                        const relevantCloudAccount = allCountAccountsStatus.find(account => account.id === id);
                        return {
                            id,
                            customData: {
                                status: relevantCloudAccount?.hasIssues ? StatusCellRenderer_Status.warning : StatusCellRenderer_Status.success,
                                statusType: 'syncStatus',
                            },
                        };
                    });
                } catch (error) {
                    return relevantCloudAccounts.map(({ id }) => {
                        return {
                            id,
                            customData: {
                                status: StatusCellRenderer_Status.none,
                                statusType: 'syncStatus',
                            },
                        };
                    });
                }
            }
        }
    },
    {
        id: 'add-env-table-ou-path',
        content: {
            customData: async (allCloudAccounts) => {
                const allOUs = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithPath(true);
                const allOUsIndexed = allOUs.reduce<{ [ouId: string]: string; }>((acc, ou) => {
                    acc[ou.id] = ou.path;
                    return acc;
                }, {});

                return allCloudAccounts
                    .map(({ id, organizationalUnitId }) => {
                        return {
                            id,
                            customData: {
                                organizationalUnitPath: allOUsIndexed[organizationalUnitId]
                            },
                        };
                    });
            }
        }
    },
];

export default function initializeCustomDataDefs() {
    EnvironmentsTableRegistry.addCustomDataById(customDataDefs);
}
