import React, { Fragment, useState } from 'react';
import { Button, Modal, Stack, Typography } from 'common/design-system/components-v2';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import {
    IArchiveRequest,
    IFindingKeysByCloudAccountId
} from 'common/module_interface/intelligence/Intelligence.interface';
import { IArchiveModalProps } from './ArchiveModal.types';
import { getFindingKeysByCloudAccountId } from '../../../Findings.utils';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';

export const ArchiveModal: React.FC<IArchiveModalProps> = (props: IArchiveModalProps) => {
    const { findings, closeModal } = props;
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const shouldArchive: boolean = findings.some((finding: IFinding) => !finding.isArchived) || findings.length === 0;
    const termPrefix: string = shouldArchive ? 'ARCHIVE' : 'UN_ARCHIVE';
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onArchiveModalCancel = () => {
        closeModal();
    };

    const prepareArchiveRequest = (): IArchiveRequest => {
        const findingsKeysByCloudAccountId: IFindingKeysByCloudAccountId[] = getFindingKeysByCloudAccountId(findings);
        return { findings: findingsKeysByCloudAccountId, archive: shouldArchive };
    };

    const onArchiveAlertsBtnClick = async () => {
        const request: IArchiveRequest = prepareArchiveRequest();
        try {
            setIsLoading(true);
            await getIntelligenceService().archiveFindings(request);
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                text: t(`${termPrefix}_MODAL.${termPrefix}_ALERTS_SUCCESS`)
            });
            closeModal();
        } catch (message: unknown) {
            const errorTitle = t(`${termPrefix}_MODAL.${termPrefix}_ALERTS_FAILED`);
            getNotificationsService().addNotification({ type: NotificationType.ERROR, text: errorTitle });
            await getLoggerService().error(`${errorTitle} ${message as string}`);
        } finally {
            setIsLoading(false);
            props.refreshTableData();
        }
    };

    return (
        <Fragment>
            <Modal.ModalDialog onRequestClose={onArchiveModalCancel} isOpen={true} width={'md'}>
                <Modal.ModalHeader title={t(`${termPrefix}_MODAL.TITLE`)}
                    onClose={onArchiveModalCancel}/>
                <Modal.ModalContent fullHeight={true}>
                    <Typography>{t(`${termPrefix}_MODAL.DESCRIPTION`, { alertsCount: findings.length })}</Typography>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        <Button key={'cancel-button-action'} variant='text'
                            onClick={onArchiveModalCancel}>{i18n.t('COMMON.CANCEL')}</Button>
                        <Button key={'archive-alerts-button-action'} color='brandPrimary' loading={isLoading}
                            disabled={isLoading}
                            onClick={onArchiveAlertsBtnClick}>{t(`${termPrefix}_MODAL.ACTIONS.${termPrefix}_ALERTS`)}</Button>
                    </Stack>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
        </Fragment>
    );
};
