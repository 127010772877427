import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const LoaderDiv = styled(Stack)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 250px;
`;

const NoteDiv = styled(Stack)`
  padding-left: 24px;
  height: 30px;
`;

export const IAMStyled = {
    LoaderDiv,
    NoteDiv
};