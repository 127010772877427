import initializeRulesManagement from './RulesManagement/initialize';
import { addMainMenuItem } from 'common/helpers/menu';
import { getUserService, RULES_MANAGEMENT_SERVICE_ID } from 'common/interface/services';
import RuleManageService from './RulesManagement/services/RuleManagement.service';
import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import Welcome from './Welcome/Welcome';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { initializeComplianceWidgets } from './initializeComplianceWidgets';
import { initializeRemediationCSPMPage } from './remediation/initialize';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { isNotChina } from 'common/utils/dataCenter';
import RemediationPage from './remediation/RemediationPage';
import { isExclusionsEnabled, isPolicyEnabled, isRemediationEnabled, isRulesetEnabled } from 'common/utils/userPermissionUtils';


const RULES_MANAGEMENT_ADMIN = 'rules-management-admin';
const remediationActiveFeature = 'new-remediation-page';
const initializeServices = () => {
    globalAddinContainer.addService(RULES_MANAGEMENT_SERVICE_ID, new RuleManageService());
};

export default function initialize() {

    const remediationPageRoute = () => {
        return {
            condition: isNotChina() && getUserService().hasPermission([UserRolesTypes.ALL, remediationActiveFeature]),
            component: RemediationPage,
            path: '/posture-management/remediation',
        };
    };

    const welcomePageRoute = () => {
        return {
            condition: getUserService().hasPermission([UserRolesTypes.ALL]),
            component: Welcome,
            path: '/posture-management/welcome-page',
        };
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'posture management welcome', content: welcomePageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'remediation', content: remediationPageRoute }]);

    addMainMenuItem({
        id: 'posture management',
        position: 40,
        icon: 'posture-management',
        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.TITLE',
        permission: [UserRolesTypes.ALL],
        sections: [
            {
                id: 'default',
                position: 10,
                items: [
                    {
                        id: 'welcome',
                        position: 10,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.WELCOME',
                        state: '/posture-management/welcome-page',
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
            {
                id: 'policy',
                position: 20,
                items: [
                    {
                        id: 'rules',
                        position: 10,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.RULES.TITLE',
                        state: '/compliance-engine/rules',
                        urlAliases: ['/rules/rule'],
                        permission: [RULES_MANAGEMENT_ADMIN],
                    },
                    {
                        id: 'rulesets',
                        position: 20,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.RULESETS',
                        state: '/compliance-engine/policies',
                        urlAliases: [
                            /^\/compliance-engine\/policy\/.+/,
                            '/compliance-engine/policies',
                            '/compliance-engine/management',
                        ],
                        permission: () => isRulesetEnabled(),
                    },
                    {
                        id: 'continuous posture',
                        position: 30,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.CONTINUOUS_POSTURE',
                        state: '/compliance-engine/continuous-compliance',
                        permission: () => isPolicyEnabled(),
                    },
                    {
                        id: 'remediation',
                        position: 40,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.REMEDIATION',
                        state: '/compliance-engine/remediation',
                        permission: () => isNotChina() && isRemediationEnabled(),
                    },
                    {
                        id: 'newRemediation',
                        position: 40,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.REMEDIATION',
                        state: '/posture-management/remediation',
                        permission: () => isNotChina() && getUserService().hasPermission([UserRolesTypes.ALL, remediationActiveFeature]) && isRemediationEnabled(),
                    },
                    {
                        id: 'exclusions',
                        position: 50,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.EXCLUSIONS',
                        state: '/compliance-engine/exclusion',
                        permission: () => isExclusionsEnabled(),
                    },
                ],
            },
            {
                id: 'assessment',
                position: 30,
                title: '',
                items: [
                    {
                        id: 'assessment history',
                        position: 10,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.ASSESSMENT_HISTORY',
                        state: '/compliance-engine/history',
                        urlAliases: [/^\/compliance-engine\/result\/.+/],
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'gsl builder',
                        position: 20,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.GSL_BUILDER',
                        state: '/compliance-engine/playground',
                        permission: [UserRolesTypes.ALL],
                    },
                    {
                        id: 'overview',
                        position: 30,
                        label: 'NAVIGATION_MENU.POSTURE_MANAGEMENT.OVERVIEW',
                        state: '/compliance-engine/dashboard',
                        permission: [UserRolesTypes.ALL],
                    },
                ],
            },
        ],
    });

    initializeRulesManagement();
    initializeServices();
    initializeComplianceWidgets();
    initializeRemediationCSPMPage();
}
