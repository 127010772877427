import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
import ExpandablePanel from 'common/components/ExpandablePanel/ExpandablePanel';
import { Checkbox, Stack, Icon as NewIcon, Tooltip, Link, Typography } from 'common/design-system/components-v2';
import { getStoreService } from 'common/interface/services';
import { AzureOnboardingStep, AzureOnboardingStepTitle, } from '../../AzureIntelligenceOnboarding.styled';
import {
    getSelectedLogTypes,
    setAllStorages,
    setAzureOnboardingType,
    setNextButtonDisabled,
    setSelectedLogTypes,
    setSelectedStorageTab
} from '../../AzureIntelligenceOnboarding.reducer';
import {
    AssetType,
    AzureCreateStorageAccountLink,
    AzureIntelligenceOnboardingType,
    AzureStorageAnalyticsLink,
} from '../../AzureIntelligenceOnboarding.const';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { SelectedTab } from '../../AzureIntelligenceOnboarding.interface';

const WelcomeAccountActivity: FunctionComponent = () => {
    const dispatch = getStoreService().dispatch;

    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING.ACCOUNT_ACTIVITY.WELCOME' });
    const selectedLogTypes = useSelector(getSelectedLogTypes);
    const [logTypes, setLogTypes] = useState<AssetType[]>(_.cloneDeep(selectedLogTypes));

    useEffect(() => {
        if (!logTypes.length) setLogTypes([AssetType.activityLogs]);
        dispatch(setSelectedLogTypes(logTypes));
        dispatch(setNextButtonDisabled(false));
    }, [dispatch, logTypes, logTypes.length]);

    useEffect(() => {
        dispatch(setAzureOnboardingType(AzureIntelligenceOnboardingType.centralized));
    }, [dispatch]);

    const selectLogTypes = (event: ChangeEvent<HTMLInputElement>) => {
        const tempLogTypes = _.cloneDeep(logTypes);
        const logType = event.target.name as AssetType;
        const isLogTypePresent = tempLogTypes.includes(logType);

        if (logType === AssetType.activityLogs && logTypes.length === 1 && logTypes.includes(AssetType.activityLogs)) return;

        dispatch(setAllStorages([]));
        dispatch(setSelectedStorageTab(SelectedTab.Manual));

        if (event.target.checked) {
            if (!isLogTypePresent) tempLogTypes.push(logType);
        } else {
            if (isLogTypePresent) tempLogTypes.splice(tempLogTypes.findIndex(type => type === event.target.name), 1);
        }
        setLogTypes(_.cloneDeep(tempLogTypes));

        if (tempLogTypes.length) {
            dispatch(setNextButtonDisabled(false));
        } else {
            dispatch(setNextButtonDisabled(true));
        }
        dispatch(setSelectedLogTypes(tempLogTypes));
    };

    const isChecked = (logType: AssetType) => !!logTypes.find(type => type === logType);

    const LinkText = (props: { children?: React.ReactElement }) => {
        return (
            <Link externalUrl={AzureCreateStorageAccountLink}>
                <Typography context='info' saturation>
                    {props.children}
                </Typography>
            </Link>
        );
    };

    return (
        <AzureOnboardingStep>
            <AzureOnboardingStepTitle>{t('TITLE')}</AzureOnboardingStepTitle>

            <Stack padding={[5, 0]}>
                <div>{t('DESCRIPTION.1')}</div>
                <div>{t('DESCRIPTION.2')}</div>
                <div>{t('DESCRIPTION.3')}</div>
            </Stack>

            <ExpandablePanel title={t('PREREQUISITES.TITLE')} isHighlighted={true}>
                <Stack margin={[0, 0, 0, 4]}>
                    <div>
                        {t('PREREQUISITES.DESCRIPTION')}
                    </div>

                    <ol>
                        <li><Trans>{t('PREREQUISITES.1')}</Trans></li>
                        <li>{t('PREREQUISITES.2')}</li>
                        <li>
                            {t('PREREQUISITES.3.TITLE')}
                            <ol>
                                <li><Trans>{t('PREREQUISITES.3.1')}</Trans></li>
                                <li><Trans>{t('PREREQUISITES.3.2')}</Trans></li>
                            </ol>
                        </li>
                        <div>
                            <Trans i18nKey={t('PREREQUISITES.INFO')}
                                components={{
                                    link1: <LinkText></LinkText>
                                }}/>
                        </div>
                    </ol>

                </Stack>
            </ExpandablePanel>

            <Stack padding={[3, 0]} margin={[0, 0 ,0, 1]}>
                <div><Trans>{t('SELECT_TYPES')}</Trans></div>

                <Checkbox name={AssetType.activityLogs} label={t('AZURE_ACTIVITY_LOGS')}
                    checked={isChecked(AssetType.activityLogs)}
                    onChange={(event) => selectLogTypes(event)}>
                </Checkbox>

                <Stack margin={[0, 0, 0, 8]}>
                    <ExpandablePanel title={t('INSTRUCTIONS')}>
                        <Stack margin={[2, 0, 0, 8]}>
                            <Trans>{t('CREATE_ACTIVITY_LOGS.TITLE')}</Trans>

                            <ol>
                                <li><Trans>{t('CREATE_ACTIVITY_LOGS.1')}</Trans></li>
                                <li><Trans>{t('CREATE_ACTIVITY_LOGS.2')}</Trans></li>
                                <li><Trans>{t('CREATE_ACTIVITY_LOGS.3')}</Trans></li>
                                <li>
                                    <Stack direction='row' alignItems='center' spacing={1}>
                                        {t('CREATE_ACTIVITY_LOGS.4')}
                                        <Tooltip content={t('CREATE_ACTIVITY_LOGS.TOOLTIP')}>
                                            <NewIcon name='info' size={14} />
                                        </Tooltip>
                                    </Stack>
                                </li>
                                <li><Trans>{t('CREATE_ACTIVITY_LOGS.5')}</Trans></li>
                            </ol>
                        </Stack>
                    </ExpandablePanel>
                </Stack>

                <Checkbox name={AssetType.signIns} label={`${t('ENTRA_ID')} - ${t('SIGN_IN_LOGS')}`}
                    checked={isChecked(AssetType.signIns)}
                    onChange={(event) => selectLogTypes(event)}>
                </Checkbox>

                <Stack margin={[0, 0, 0, 8]}>
                    <ExpandablePanel title={t('INSTRUCTIONS')}>
                        <Stack margin={[2, 0, 0, 8]}>
                            <Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.SIGN_IN')}</Trans>

                            <ol>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.1')}</Trans></li>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.2')}</Trans></li>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.3')}</Trans></li>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.4')}</Trans></li>
                                <li>
                                    <Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.5', { type: t('CREATE_AUDIT_SIGN_IN_LOGS.SIGN_IN_LOGS') })}</Trans>
                                </li>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.6')}</Trans></li>
                            </ol>
                        </Stack>
                    </ExpandablePanel>
                </Stack>

                <Checkbox name={AssetType.auditLogs} label={`${t('ENTRA_ID')} - ${t('AUDIT_LOGS')}`}
                    checked={isChecked(AssetType.auditLogs)}
                    onChange={(event) => selectLogTypes(event)}>
                </Checkbox>

                <Stack margin={[0, 0, 0, 8]}>
                    <ExpandablePanel title={t('INSTRUCTIONS')}>
                        <Stack margin={[2, 0, 0, 8]}>
                            <Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.AUDIT')}</Trans>

                            <ol>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.1')}</Trans></li>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.2')}</Trans></li>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.3')}</Trans></li>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.4')}</Trans></li>
                                <li>
                                    <Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.5', { type: t('CREATE_AUDIT_SIGN_IN_LOGS.AUDIT_LOGS') })}</Trans>
                                </li>
                                <li><Trans>{t('CREATE_AUDIT_SIGN_IN_LOGS.6')}</Trans></li>
                            </ol>
                        </Stack>
                    </ExpandablePanel>
                </Stack>

                <Checkbox name={AssetType.storageAccounts} label={t('ANALYTICS_LOGS')}
                    checked={isChecked(AssetType.storageAccounts)}
                    onChange={(event) => selectLogTypes(event)}>
                </Checkbox>

                <Stack margin={[0, 0, 0, 8]}>
                    <ExpandablePanel title={t('ENABLE_ANALYTICS_LOGS')}>
                        <Stack margin={[2, 0, 0, 8]}>
                            <Trans>{t('CREATE_ANALYTICS_LOGS.TITLE')}</Trans>
                            <ol>
                                <li><Trans>{t('CREATE_ANALYTICS_LOGS.1')}</Trans></li>
                                <li><Trans>{t('CREATE_ANALYTICS_LOGS.2')}</Trans></li>
                                <li><Trans>{t('CREATE_ANALYTICS_LOGS.3')}</Trans></li>
                                <li><Trans>{t('CREATE_ANALYTICS_LOGS.4')}</Trans></li>
                                <li><Trans>{t('CREATE_ANALYTICS_LOGS.5')}</Trans></li>
                            </ol>
                            <Typography context='info' saturation>
                                <Link externalUrl={AzureStorageAnalyticsLink}>{t('READ_MORE')}</Link>
                            </Typography>
                        </Stack>
                    </ExpandablePanel>
                </Stack>
            </Stack>

        </AzureOnboardingStep>
    );
};

export default WelcomeAccountActivity;
