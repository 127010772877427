import React from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import {
    BusinessPriorityChipWidget,
    DataSensitivityChipWidget,
    ExposureChipWidget,
    IamSensitivityChipWidget,
    RiskScoreChipWidget, WafChipWidget,
} from '../../ChipWidgets/ChipWidgets';
import { isRiskSupportedForAsset } from '../ErmDataUtils';

export const AssetDetailsRiskScoreChipWidget: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const isRiskSupported = isRiskSupportedForAsset(entity.typeByPlatform);
    return <RiskScoreChipWidget riskScore={isRiskSupported ? entity.protectedAsset?.riskScore : undefined} />;
};

export const AssetDetailsExposureChipWidget: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    return <ExposureChipWidget networkExposure={entity.protectedAsset?.context?.networkExposure} iamExposure={entity.protectedAsset?.context?.iamExposure} />;
};

export const AssetDetailsBusinessPriorityChipWidget: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    return <BusinessPriorityChipWidget businessPriority={entity.protectedAsset?.businessPriority} />;
};

export const AssetDetailsIamSensitivityChipWidget: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    return <IamSensitivityChipWidget iamSensitivity={entity.protectedAsset?.context?.iamSensitivity} />;
};

export const AssetDetailsDataSensitivityChipWidget: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    return <DataSensitivityChipWidget dataSensitivity={entity.protectedAsset?.context?.dataSensitivity} dataClassifications={entity.protectedAsset?.context?.dataClassifications} />;
};

export const AssetDetailsWafChipWidget: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    return <WafChipWidget wafProtection={entity.protectedAsset?.context?.wafProtection}/>;
};
