import { useTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { defaultEntityClassification } from '../Models/Entity';
import { insightNamespace } from '../../consts/i18n';

interface ClassificationProps {
    classifications: Record<string, string[]>;
}

const ClassificationComponent: React.FC<ClassificationProps> = (props: ClassificationProps) => {
    const { classifications } = props;
    const { t } = useTranslation(insightNamespace);

    return !classifications || Object.keys(classifications).length === 0 ? null : <div className={'border-b-1 border-b-solid py-9 overflow-y-auto max-h-[260px]'}>
        <div>
            { t('SERVER_CLASSIFICATION.TITLE') }
        </div>
        <div>
            { Object.entries(classifications).sort(([groupName]) => groupName === defaultEntityClassification ? 1 : -1).map(([groupName, elements]) => (
                <div key={ groupName } className={'pt-6'} style={{ wordBreak: 'break-word' }}>
                    <span className={`mr-4 text-sm ${groupName === defaultEntityClassification && Object.keys(classifications).length === 1 ? 'hidden': 'inline'}`}>
                        {groupName}:
                    </span>

                    { elements.map(classification => (
                        <Fragment key={classification}>
                            <span className={'px-5 rounded border bg-info inline mb-10 whitespace-nowrap text-xs mr-4'}>
                                {classification}
                            </span>
                        </Fragment>
                    ))}
                </div>
            ))}
        </div>
    </div>;
};

export default ClassificationComponent;