import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
  flex-direction: column;
  width: 100%;
`;

export const AttrStyled = {
    TopDiv,
};
