import { TextField } from '@dome9/berries/react-components';
import { debounce } from 'common/utils/functions';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AWSOnboardingAccount from './AWSOnboardingAccount';
import { 
    getStacksetForPermissionsValid, 
    getOrgOnboardingArnRole, 
    getOrgOnboardingManagementAccountExternalId,
    getOrgOnboardingOrganizationName, 
    setOrgOnboardingArnRole, 
    setOrgOnboardingManagementAccountExternalId, 
    setOrgOnboardingOrganizationName
} from './helpers/aws.reducer';
import { Icon_, Tooltip_ } from '../oci/OciOnboarding.styled';

const AWSOrgAccount: FC = ()=>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const externalId = useSelector(getOrgOnboardingManagementAccountExternalId);
    const arn = useSelector(getOrgOnboardingArnRole);
    const organizationName = useSelector(getOrgOnboardingOrganizationName);
    const formValid = useSelector(getStacksetForPermissionsValid);
    return (<>
        <div className='title-large'>{t('ON_BOARDING.ACCOUNT.ORG.CREATE_MANAGEMENT_STACK')}</div>
        <ul className='aws-onboarding-account-org-list aws-onboarding-account-org my-8'>
            <li>
                <AWSOnboardingAccount />
            </li>
            <li className='mt-8'>
                <div className={'step-title'}>
                    {t('ON_BOARDING.ACCOUNT.ORG.PROVIDE_DETAILS')}
                </div>
                <div className={'padder mt-7'}>
                    <div className='mt-8'>
                        {t('ON_BOARDING.ACCOUNT.ORG.EXTERNAL_ID')}
                        <Tooltip_ placement='right' content={t('ON_BOARDING.ACCOUNT.ORG.CAN_BE_FOUND_UNDER_OUTPUT')} >
                            <span>
                                <Icon_ name='info' color={'primary'} size={12} />
                            </span>
                        </Tooltip_>
                        <TextField
                            name='externalId'
                            value={externalId}
                            onChange={debounce((ev: ChangeEvent<HTMLInputElement>) => dispatch(setOrgOnboardingManagementAccountExternalId(ev.target.value)))}
                            onClear={() => {
                                dispatch(setOrgOnboardingManagementAccountExternalId());
                            }}
                            className='input-field'
                            state={formValid.errors?.externalId ? 'error' : 'info'}
                            helper={formValid.errors?.externalId ? formValid.errors?.externalId[0]?.message : undefined}
                        />
                    </div>

                    <div className='mt-8'>
                        CrossAccountRoleArn
                        <Tooltip_ placement='right' content={t('ON_BOARDING.ACCOUNT.ORG.CAN_BE_FOUND_UNDER_OUTPUT')} >
                            <span>
                                <Icon_ name='info' color={'primary'} size={12} />
                            </span>
                        </Tooltip_>
                        <TextField
                            name='roleArn'
                            value={arn}
                            onChange={debounce((ev: ChangeEvent<HTMLInputElement>) => dispatch(setOrgOnboardingArnRole(ev.target.value)))}
                            onClear={() => {
                                dispatch(setOrgOnboardingArnRole());
                            }}
                            className='input-field'
                            state={formValid.errors?.roleArn ? 'error' : 'info'}
                            helper={formValid.errors?.roleArn ? formValid.errors?.roleArn[0]?.message : undefined}
                        />
                    </div>

                    <div className='mt-8'>
                        {t('ON_BOARDING.ACCOUNT.ORG.ORGANIZATION_NAME')}
                        <Tooltip_ placement='right' content={t('ON_BOARDING.ACCOUNT.ORG.TO_BE_DISPLAYED')} >
                            <span>
                                <Icon_ name='info' color={'primary'} size={12} />
                            </span>
                        </Tooltip_>
                        <TextField
                            name='organizationName'
                            value={organizationName}
                            onChange={debounce((ev: ChangeEvent<HTMLInputElement>) => dispatch(setOrgOnboardingOrganizationName(ev.target.value)))}
                            onClear={()=>{
                                dispatch(setOrgOnboardingOrganizationName());
                            }}
                            className='input-field'
                        />
                    </div>
                </div>
            </li>
        </ul>
    </>);
};

export default AWSOrgAccount;