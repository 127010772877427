import React from 'react';
import GcpActions, { UpdateGcpAccountNameReq } from '../gcp.actions';

export interface UseUpdateGcpReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: () => void;
}

export interface UseUpdateGcpPayload {
    isLoading: boolean;
    isSuccess: boolean;
    isError?: { message: string; };
    updateGcp: (cloudAccountId: string, updatePayload: UpdateGcpAccountNameReq) => Promise<void>;
    reset?: () => void;
}

export type UseUpdateGcp = (req?: UseUpdateGcpReq) => UseUpdateGcpPayload;

export const useUpdateGcp: UseUpdateGcp = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const updateGcp = React.useCallback(async (cloudAccountId: string, updatePayload: UpdateGcpAccountNameReq) => {
        setIsLoading(true);
        try {
            const result = await GcpActions.updateGcpAccountName(cloudAccountId, updatePayload.name);
            setIsSuccess(result);
            onSuccess?.();
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isSuccess,
        isError,
        updateGcp,
        reset
    });
};