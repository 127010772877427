
export enum ActionType {
    Remove = 'remove',
    Rename = 'rename',
    RemoveIntelligence = 'remove-intelligence',
    AddFlowLogs = 'add-flow-logs',
    AddActivityLogs = 'add-activity-logs',
    EditCredentials = 'edit-credentials',
    AddCloudBots = 'add-cloud-bots',
    SyncNow = 'sync-now',
}

export enum ModalType {
    Remove = 'remove',
    RemoveWithPolicies = 'remove-with-policies',
    Rename = 'rename',
    RemoveIntelligence = 'remove-intelligence',
    EditCredentials = 'edit-credentials',
}