import React, { useCallback } from 'react';
import { IEventOverviewPanelProvider } from 'common/module_interface/events/Events';
import { EventOverviewRegistry } from 'common/module_interface/events/EventOverviewRegistry';
import { Stack } from 'common/design-system/components-v2';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';

const FindingOverview: React.FC<{ finding: IFinding }> = ({ finding }) => {

    const getPanel = useCallback((panelProvider: IEventOverviewPanelProvider) => {
        if (!finding) return;
        return <panelProvider.panel key={panelProvider.panelId} finding={finding}/>;
    }, [finding]);

    const getPanels = useCallback(() => {
        if (!finding) return;
        const allProviders: IEventOverviewPanelProvider[] = EventOverviewRegistry.getPanelProviders();
        const relevantProviders: IEventOverviewPanelProvider[] = allProviders.filter(
            (panelProvider: IEventOverviewPanelProvider) => panelProvider.isRelevant ? panelProvider.isRelevant(finding) : true);
        return relevantProviders.map((panelProvider: IEventOverviewPanelProvider) => getPanel(panelProvider));
    }, [getPanel, finding]);

    return <Stack direction="column" spacing={5} fullWidth={true} margin={[0, 0, 0, 2]}>
        {finding && getPanels()}
    </Stack>;
};

export default FindingOverview;
