/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'common/design-system/components-v2/Select';
import { SelectV2 } from 'common/design-system/components-v2';
import { RemediateBySeverityOptionProps } from '../../helpers/remediation.interfaces';
import { I18nRemediation } from '../../helpers/remediation.consts';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';

const RemediateBySeverityOption: FC<RemediateBySeverityOptionProps> = ({ isEnabled, selectedRuleSet, onChange, selectedOption, formValidations }) => {
    const { t } = useTranslation(I18nRemediation);
    const [severityList, setSeverityList] = useState<OptionType[]>([]);
    const [selectedSeverities, setSelectedSeverities] = useState<string[] | []>(selectedOption || []);

    useEffect(() => {
        if (selectedRuleSet) {
            const severityArr = Object.entries(FindingSeverityServerEnum).map(([key, value]) => ({
                label: key,
                value: value
            }));
            setSeverityList(severityArr);
            if (selectedOption) {
                setSelectedSeverities(selectedOption);
                onChange(selectedOption);
            } else {
                setSelectedSeverities([]);
                onChange(null);
            }
        } else {
            setSelectedSeverities([]);
            setSeverityList([]);
            onChange(null);
        }
    }, [selectedRuleSet, selectedOption]);

    const onSelectedSeverity = (newValue: string[]) => {
        setSelectedSeverities(newValue);
        onChange(newValue);
    };

    return (
        <SelectV2
            label={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.REMEDIATE_BY_SEVERITY')}
            fullWidth
            disabled={!isEnabled}
            options={severityList}
            isMulti={true}
            value={selectedSeverities}
            onChange={onSelectedSeverity}
            isError={!!formValidations?.[0]?.message}
            helperText={formValidations?.[0]?.message}
        />
    );
};
export default RemediateBySeverityOption;