import styled from 'styled-components/macro';

export const DashboardFilterPanelDiv = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

export const DashboardFilterPanelSpinnerDiv = styled.div`
  background-color: white;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.09);

  .btn__label {
    margin-right: 10px;
  }

  button, button:hover {
    cursor: default;
  }
`;
