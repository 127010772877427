import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { CpCommonButton } from '@dome9/components/react/components';
import i18n from 'common/services/translations/translations';
import { useTranslation } from 'react-i18next';
import { logoutAndRedirectToLogin } from '../../user/User.actions';

export const SignupForm: React.FC<{}> = () => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const searchString = useLocation().search;
    const useStyles = createUseStyles({
        SsoFailedLoginForm: {
            display: 'flex',
            'font-weight': 100,
            'flex-direction': 'column',
            'align-items': 'center',
            flex: 2,
            padding: '3.2rem',
            position: 'relative',
            '& .title': {
                'margin-bottom': '1rem',
                'text-transform': 'uppercase',
                color: 'red',
                'font-weight': 100,
                'font-size': '2em',
            },
            '& form': {
                height: '100%',
                display: 'flex',
                'flex-direction': 'column',
                'align-items': 'center',
                '& .subTitle': {
                    display: 'block',
                    height: '80%',
                    color: 'rgba(0, 0, 0, 0.54)',
                    'text-align': 'center',
                    'margin-top': '5px',
                    'font-size': '18px',
                },
            },
        },
    });

    const handleSubmit = useCallback(() => {
        logoutAndRedirectToLogin();
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(searchString);

        function getErrorMassage() {
            const errorCode = queryParams.get('errorCode');
            const ssoAccountName = queryParams.get('ssoAccountName');
            if (errorCode === null) {
                handleSubmit();
                return '';
            }
            const errorMassages: { [key: string]: string } = {
                throw: i18n.t('FORMS.SSO_LOGIN_FORM.SUBTITLE_CONNECTION'),
                ConfigurationError: i18n.t('FORMS.SSO_LOGIN_FORM.CHECK_AUDIT_TRAIL'),
                AccountMissing: i18n.t('FORMS.SSO_LOGIN_FORM.NO_ACCOUNT_ID'),
                WrongAccount: i18n.t('FORMS.SSO_LOGIN_FORM.WRONG_ACCOUNT_ID'),
            };
            if (ssoAccountName && errorMassages['WrongAccount']) {
                errorMassages['WrongAccount'] = errorMassages['WrongAccount'].replaceAll(
                    '{{accountId}}',
                    ssoAccountName
                );
            }
            setErrorMessage(errorMassages[errorCode]);
        }
        getErrorMassage();
    }, [handleSubmit, searchString]);

    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.SsoFailedLoginForm}>
            <h2 className='title'>{t('FORMS.SSO_LOGIN_FORM.TITLE')}</h2>
            <form>
                <span className='subTitle'>{errorMessage}</span>
                <br />
                <CpCommonButton onClick={() => handleSubmit()}>{t('FORMS.SSO_LOGIN_FORM.LOGIN')}</CpCommonButton>
            </form>
        </div>
    );
};

export default SignupForm;
