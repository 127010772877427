import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const BodyDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 410px;
  gap: 24px;
  text-align: center;
`;

export const EmptyStyled = {
    TopDiv,
    BodyDiv,
};
