import { HttpMethod } from 'common/common_types';
import { getHttpService, getStoreService } from 'common/interface/services';
import {
    IDashboardWidget,
    IGenericWidgetDataItemSet,
    IGenericWidgetDataSet
} from 'common/module_interface/overview/Interface';
import dayjs from 'dayjs';
import {
    IMagellanOvertimeRequest,
    IMagellanOvertimeResponse,
    IWidgetConfigFilterState
} from 'common/module_interface/intelligence/Intelligence.interface';
import { getEventsOvertimeFilterString } from 'common/module_interface/events/Events';
import { getCloudAccountsService } from 'common/interface/data_services';
import { VENDORS } from 'common/consts/vendors';
import { changeUrl } from 'common/utils/http';


export interface IAlertOptionsItem {
    selected: string[] | null;
    selectedName: string | null;
    title: string;
    widgetConfigFilterState: IWidgetConfigFilterState[],
    type: string | null;
}


export const alertsTrendDataGetter = async (widget: IDashboardWidget): Promise<IGenericWidgetDataSet> => {
    const state = getStoreService().state;
    const url = `${state.app?.magellanUrl}/alerts/overtime`;
    const alertsOptions = widget.options?.alertsOptions || [];
    const startTime = dayjs().subtract(29, 'days').set('seconds',0).set('milliseconds', 0).valueOf();
    const endTime = dayjs().set('seconds',0).set('milliseconds', 0).valueOf();

    const lineTitles: string[] = [];
    const requests: IMagellanOvertimeRequest[] = alertsOptions.reduce((result: IMagellanOvertimeRequest[], optionItem: IAlertOptionsItem) => {
        if (optionItem.selected) {
            result.push({
                start: startTime,
                end: endTime,
                limit: 1000,
                cloudAccount: optionItem.selected,
                filter: optionItem.widgetConfigFilterState && getEventsOvertimeFilterString(optionItem.widgetConfigFilterState)
            });
            lineTitles.push(optionItem.title);
        }
        return result;
    }, []);

    const response = await Promise.all(requests.map(request =>
        getHttpService().request<IMagellanOvertimeResponse>(url, {
            method: HttpMethod.post,
            data: request
        },{ cachingConfig: { useCache: true } })));

    const cloudAccounts = await getCloudAccountsService().getAllCloudAccounts(true);
    const filteredCloudAccount = cloudAccounts.filter((account: any) => alertsOptions.map((item: any) => item.selected).flat().includes(account.externalId));
    const vendorData = VENDORS.find(item => item.name === filteredCloudAccount?.[0]?.platform);
    const cloudAccountId = `${vendorData?.assessmentVendorType}|${filteredCloudAccount?.[0]?.id}`;


    const items = response.reduce((result: IGenericWidgetDataItemSet[], responseItem: IMagellanOvertimeResponse, index: number) => {

        result.push({
            key: lineTitles.length > index ? lineTitles[index] : alertsOptions[index].title,
            value: responseItem.data.map((item) => {
                // this will be used when fixing the link to specific timeframe
                // const date = dayjs(item.date_time.value);
                // const fromDate = date.unix();
                // const toDate = date.add(24,'hour').unix();
                return ({
                    key: item.date_time.value,
                    value: item.sum_counts,
                    onclick: () => {
                        return changeUrl(`/alerts/findings?query={"sorting":{"fieldName":"createdTime","direction":-1},"filter":{"fields":[{"name":"organizationalUnitId","value":"00000000-0000-0000-0000-000000000000"},{"name":"cloudAccountId_calc","value":"${cloudAccountId}"}],"freeTextPhrase":""},"lowAggregationsSize":true}&time={"time":"All"}`);
                    }
                });
            }).reverse(),
        });
        return result.sort();
    }, []);

    const itemsHasValues = items.some((item) => item.value?.length > 0);
    if (!itemsHasValues) {
        return {
            items: []
        };
    } else {
        return {
            items: items
        };
    }


};


