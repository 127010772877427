import { IEventsTableColOverride } from 'common/erm-components/custom/EventsTableWidget/EventsTableWidget.interface';
import { ColumnTypes } from 'common/module_interface/events/EventsConsts';
import EventTitleCellRender from 'common/components/ag-grid/Renderers/EventTitleCellRender';
import EventEntityTypeCellRenderer from 'common/components/ag-grid/Renderers/EventEntityTypeCellRenderer';

export const getColumnsOverrideDefs = (componentName?: string): IEventsTableColOverride[] => {
    return [
        {
            colOverride: {
                id: ColumnTypes.CREATED_TIME,
                colDefOverride: {
                    flex: 4,
                },
            },
            field: 'createdTime'
        },
        {
            colOverride: {
                id: ColumnTypes.RULE_NAME,
                colDefOverride: {
                    cellRenderer: EventTitleCellRender,
                    cellRendererParams: { componentName },
                    flex: 8,
                }
            },
            field: 'ruleName'
        },
        {
            colOverride: {
                id: ColumnTypes.SEVERITY,
                colDefOverride: {
                    flex: 2,
                }
            },
            field: 'severity'
        },
        {
            colOverride: {
                id: ColumnTypes.ENTITY_TYPE,
                colDefOverride: {
                    cellRenderer: EventEntityTypeCellRenderer,
                    flex: 4,
                }
            },
            field: 'entityType'
        },
        {
            colOverride: {
                id: ColumnTypes.CLOUD_ACCOUNT_ID,
                colDefOverride: {
                    cellRendererParams: { componentName },
                    flex: 4,
                }
            },
            field: 'cloudAccountId'
        },
    ];
};
