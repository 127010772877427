import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnApi, GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { Table, Typography } from 'common/design-system/components-v2';
import { CounterStatus } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { t } from 'i18next';
import { FindingsTableDatasource, IDataSourceConfig } from './FindingsTableDatasource';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { GenericObject, IActionUsageDef, IColumnUsageDef } from 'common/interface/general';
import { FindingsTableRegistry } from 'common/module_interface/intelligence/Findings/FindingsTableRegistry';
import { mergeActionDefs, mergeColumnDefs, saveColumnsState } from 'common/utils/tableUtils';
import { Trans } from 'react-i18next';
import { ModalType } from '../Findings.const';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import Modals from './Modals/Modals';
import { ITableAction, ITableProps } from 'common/design-system/components-v2/Table/Table.types';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import EventTitleCellRenderer from './CellRenderers/EventTitleCellRenderer';

interface IFindingsTableProps extends ITableProps {
    tableId: string,
    filters: IProtectedAssetFilter[],
    columns: IColumnUsageDef[],
    datasource: FindingsTableDatasource | undefined,
    originTypes?: string[],
    mitreInfo: GenericObject<any>,
    updateDatasource: Function,
    filterValues?: IFiltersValues | undefined,
    actionsCreator?: (openDialog: (dialogType: (ModalType | null)) => void) => IActionUsageDef[],
    isArchiveView?: boolean,
}

const FindingsTable: React.FC<IFindingsTableProps> = ({
    tableId,
    filters,
    columns,
    actionsCreator,
    datasource,
    originTypes,
    filterValues,
    mitreInfo,
    updateDatasource,
    disableColumnMenu,
    disableGrouping,
    isArchiveView,
}) => {

    const COLUMNS_STATE_STORAGE_KEY = useMemo(() => `${tableId}__COLUMNS_STATE`, [tableId]);
    const gridApiRef = useRef<GridApi>();
    const columnApiRef = useRef<ColumnApi>();
    const [counters, setCounters] = useState({ totalCount: CounterStatus.Pending, currentCount: CounterStatus.Pending });
    const [modalType, setModalType] = React.useState<ModalType | null>(null);
    const [selectedRows, setSelectedRows] = React.useState<IFinding[]>([]);

    const onGridReady = useCallback(async (params: GridReadyEvent) => {
        gridApiRef.current = params.api;
        columnApiRef.current = params.columnApi;
        const defaultDatasourceConfig: IDataSourceConfig = {
            onRowCountUpdate: setCounters,
            originTypes: originTypes,
            filters: filters,
            mitreInfo: mitreInfo,
            isArchiveView: isArchiveView
        };
        const findingsDatasource = new FindingsTableDatasource(defaultDatasourceConfig);
        findingsDatasource.setApis(params.api, params.columnApi);
        updateDatasource(findingsDatasource);
    }, [originTypes, filters, mitreInfo, isArchiveView, updateDatasource]);

    const getChildCount = useCallback((data: IFinding) => {
        return data.numberOfRows ?? 0;
    }, []);

    const openDialog = useCallback((dialogType: ModalType | null) => {
        setModalType(dialogType);
    }, []);

    const onSortChanged = useCallback(() => {
        // refresh top level server side store by maintaining the current grouping
        gridApiRef?.current?.refreshServerSide({ purge: false });
    }, []);

    const onSelectionChanged = (params: SelectionChangedEvent<IFinding>) => {
        const selectedItems = params.api.getSelectedRows();
        setSelectedRows(selectedItems);
    };

    const refreshTableData = () => {
        gridApiRef.current?.deselectAll();
        gridApiRef?.current?.refreshServerSide({ purge: true });
    };

    useCallback(() => {
        if (!columnApiRef.current) {
            return;
        }
        saveColumnsState(columnApiRef.current, COLUMNS_STATE_STORAGE_KEY);
    }, [COLUMNS_STATE_STORAGE_KEY]);

    const getColumnDefs = useCallback(() => {
        return mergeColumnDefs(columns, FindingsTableRegistry.getColumnDefs());
    }, [columns]);

    const getActionsDefs: ITableAction[] = useMemo<ITableAction[]>(() => {
        if (!actionsCreator) return [];
        const actions: IActionUsageDef[] = actionsCreator(openDialog);
        return mergeActionDefs(actions, FindingsTableRegistry.getActions());
    }, [actionsCreator, openDialog]);

    const gridOptions: GridOptions = useMemo(() => {
        return {
            rowModelType: 'serverSide',
            rowSelection: 'multiple',
            suppressRowDeselection: false,
            enableRangeSelection: false,
            suppressCellFocus: true,
            columnDefs: getColumnDefs(),
            autoGroupColumnDef: {
                cellRendererSelector: params => ({ component: params.node.group ? 'agGroupCellRenderer' : EventTitleCellRenderer }),
                width: 450,
                minWidth: 200,
            },
            getChildCount: getChildCount,
            onGridReady,
            onSortChanged,
            onRowSelected: onSelectionChanged,
        };
    }, [getColumnDefs, getChildCount, onGridReady, onSortChanged]);

    const footerCounter = React.useMemo(() => {
        if (counters.currentCount === CounterStatus.Error || counters.totalCount === CounterStatus.Error) {
            return t('COMMON.PROTECTED_ASSETS_TABLE.COUNTER_ERROR');
        }
        if (counters.currentCount === CounterStatus.Pending || counters.totalCount === CounterStatus.Pending) {
            return t('COMMON.PROTECTED_ASSETS_TABLE.LOADING');
        }
        return <Typography>
            <Trans
                components={{ boldText: <Typography elementType='span' style={{ fontWeight: 500 }}>.</Typography> }}
                i18nKey='COMMON.PROTECTED_ASSETS_TABLE.RESULTS'
                values={{ count: counters.currentCount, totalCount: counters.totalCount }}
            />
        </Typography>;
    }, [counters]);

    useEffect(() => {
        if (datasource) {
            gridApiRef?.current?.setServerSideDatasource(datasource);
        }
    }, [datasource]);

    useEffect(() => {
        if (filterValues && datasource) {
            void datasource.setFilterFields(filterValues);
        }
    }, [filterValues, datasource]);

    useEffect(() => {
        if(datasource) datasource.setIsArchiveView(isArchiveView);
    }, [datasource, isArchiveView]);

    return (
        <>
            <Table
                tableId={tableId}
                saveColumnsState={true}
                footer={footerCounter}
                actions={getActionsDefs}
                gridOptions={gridOptions}
                disableGrouping={disableGrouping}
                disableColumnMenu={disableColumnMenu}
                appliedFilters={filterValues}
            />
            <Modals
                tableId={tableId}
                modalType={modalType}
                closeModal={() => setModalType(null)}
                setModalType={setModalType}
                selectedRows={selectedRows}
                refreshTableData={refreshTableData}
            />
        </>);
};

export default FindingsTable;
