import './AccessLeases.scss';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CpButton, CpIcon, CpSlider, CpLoadingDots } from '@dome9/components/react/components';
import { NotificationType } from 'common/interface/notifications';

import { getNotificationsService, getHttpService } from 'common/interface/services';

const TIME_CONVERTOR = 60;
const MAX_RANGE = 24;
const MIN_RANGE = 1;

const AccessLeases: React.FC = () => {
    const { t } = useTranslation();
    const [defaultLeaseTime, setDefaultLeaseTime] = useState<number>(0);
    const [leaseTime, setLeaseTime] = useState<number>(0);
    const [isLoading, setIsLodaing] = useState<boolean>(false);
    const [isToastLoading, setIsToastLoading] = useState<boolean>(false);

    const onSaveChanges = async () => {
        let alertType = NotificationType.SUCCESS;
        let alertText = t('SETTINGS.ACCESS_LEASES.TOAST_SUCCESS');
        try {
            setIsToastLoading(true);
            await getHttpService().put<{ defaultLeaseTime: number }>('settings/lease-time', {
                data: leaseTime * TIME_CONVERTOR,
            });

            setDefaultLeaseTime(leaseTime);
        } catch {
            alertType = NotificationType.ERROR;
            alertText = t('SETTINGS.ACCESS_LEASES.TOAST_ERROR');
        } finally {
            setIsToastLoading(false);
        }
        getNotificationsService().addNotification({
            type: alertType,
            title: t('SETTINGS.ACCESS_LEASES.TOAST_TITLE'),
            text: alertText,
        });
    };

    const onLoadSettings = async () => {
        setIsLodaing(true);
        const res = await getHttpService().get<{ defaultLeaseTime: number }>('settings', {});
        const time = res.defaultLeaseTime / TIME_CONVERTOR;
        setLeaseTime(time);
        setDefaultLeaseTime(time);
        setIsLodaing(false);
    };

    useEffect(() => {
        onLoadSettings();
    }, []);

    return (
        <div className='access-leases-container'>
            {isToastLoading && <CpLoadingDots />}
            {isLoading ? (
                <CpLoadingDots />
            ) : (
                <div className='portlet'>
                    <div className='portlet-title'>
                        <div className='caption'>{t('SETTINGS.ACCESS_LEASES.TITLE')}</div>
                    </div>
                    <div className='portlet-body'>
                        <p className='inner-title flex'>
                            <CpIcon icon='clock' />
                            {t('SETTINGS.ACCESS_LEASES.INNER_TITLE')}
                        </p>
                        <div className='flex slider-container'>
                            <CpSlider
                                min={MIN_RANGE}
                                max={MAX_RANGE}
                                onCpUpdate={(e: any) => setLeaseTime(e.detail)}
                                value={leaseTime}
                            />

                            <span className='slider-label'>{t('SETTINGS.ACCESS_LEASES.TIMESTAMP')}</span>
                        </div>

                        {!(defaultLeaseTime === leaseTime) && leaseTime >= MIN_RANGE && leaseTime <= MAX_RANGE && (
                            <p className='btn-container'>
                                <CpButton onClick={onSaveChanges}>{t('SETTINGS.ACCESS_LEASES.SAVE_BUTTON')}</CpButton>
                            </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccessLeases;
