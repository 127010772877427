import React from 'react';
import { SRLServiceType } from 'common/consts/vendors';
import { ICloudAccount, getCloudAccountsService } from 'common/interface/data_services';
import CloudAccountsService from '../../services/cloudAccounts/cloudAccounts.service';
import { CloudAccountMissingPermissionsGet } from '../../services/cloudAccounts/cloudAccounts.interface';
import { Permission } from '../../services/permission/permission.interface';
import PermissionService from '../../services/permission/permission.service';
import { ServerlessAwsAccountStatesGet } from '../../services/serverless/serverless.interface';
import ServerlessService from '../../services/serverless/serverless.service';

export interface UseGetAwsActionsAndTabsDataPayloadData {
    permission: Permission.Response;
    missingPermissions: CloudAccountMissingPermissionsGet.Response;
    serverlessAccount?: ServerlessAwsAccountStatesGet.Response;
    cloudAccount: ICloudAccount;
}

export interface UseGetAwsActionsAndTabsDataPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    data?: UseGetAwsActionsAndTabsDataPayloadData

    reloadData: () => void;
}

export type UseGetAwsActionsAndTabsData = (cloudAccountId: string) => UseGetAwsActionsAndTabsDataPayload;

export const useGetAwsActionsAndTabsData: UseGetAwsActionsAndTabsData = (cloudAccountId) => {
    const [{ loading, error, data }, setState] = React.useState<{ loading: boolean; error: boolean; data?: UseGetAwsActionsAndTabsDataPayloadData }>({
        loading: false,
        error: false
    });

    const getData = async (cloudAccountId: string) => {
        setState(current => ({ loading: true, error: false, data: current.data }));
        try {
            const response = await Promise.allSettled([
                PermissionService.permission(`${SRLServiceType.AWS}|${cloudAccountId}`),
                CloudAccountsService.cloudAccountMissingPermissionsGet(cloudAccountId),
                ServerlessService.serverlessAwsAccountStatesGet(cloudAccountId),
                getCloudAccountsService().getCloudAccountByAccountId(cloudAccountId),
            ]);
            const [permission, missingPermissions, serverlessAccount, cloudAccount] = response;
            if (
                permission.status === 'rejected' ||
                missingPermissions.status === 'rejected' ||
                cloudAccount.status === 'rejected' ||
                !cloudAccount.value
            ) throw new Error('');

            const data: UseGetAwsActionsAndTabsDataPayloadData = {
                permission: permission.value.data,
                missingPermissions: missingPermissions.value.data,
                serverlessAccount: serverlessAccount.status === 'fulfilled' ? serverlessAccount.value.data : undefined,
                cloudAccount: cloudAccount.value
            };
            setState(({ loading: true, error: false, data }));
        } catch (error) {
            setState(({ loading: false, error: true }));
        }
    };
 
    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(cloudAccountId);
        }
    }, [cloudAccountId, data, loading, error]);

    const reloadData = React.useCallback(() => getData(cloudAccountId), [cloudAccountId]);

    return ({
        isLoading: loading && !data,
        isFetching: loading,
        isError: error,
        data,

        reloadData
    });
};
