import {
    AUTO_ACTIONS_RESOURCE_URL,
} from '../components/AutoActions/AutoActions.consts';
import { getHttpService } from 'common/interface/services';
import { generalApiError } from 'common/utils/http';
import {
    IAutoActionModel,
    IAutoActionsService,
    IUpdatedAutoActionProps
} from 'common/module_interface/RiskManagement/autoActions/AutoActions';
import { clearCacheDataByTag, getCacheTag } from 'common/utils/apiCaching';

import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';

const AUTO_ACTION_SERVICE_NAME = 'AUTO_ACTION';

const getAutoActionUrl = (autoActionId: string) => `${AUTO_ACTIONS_RESOURCE_URL}/${autoActionId}`;

export class AutoActionsService implements IAutoActionsService {
    private clearMultiActionsCache() {
        clearCacheDataByTag(AUTO_ACTION_SERVICE_NAME);
    }
    private clearSpecificActionCache(autoActionId: string) {
        clearCacheDataByTag(AUTO_ACTION_SERVICE_NAME, autoActionId);
    }
    getAllAutoActions(): Promise<IAutoActionModel[]> {
        return sendHttpRequest<IAutoActionModel[]>(AUTO_ACTIONS_RESOURCE_URL, { method: 'GET' }, undefined, [getCacheTag(AUTO_ACTION_SERVICE_NAME)]);
    }

    updateAutoAction(autoActionId: string, props: IUpdatedAutoActionProps): Promise<string> {
        this.clearMultiActionsCache();
        this.clearSpecificActionCache(autoActionId);
        return getHttpService().request<string>(getAutoActionUrl(autoActionId), {
            method: 'PUT',
            data: {
                ...props,
            }
        }, undefined, generalApiError);
    }

    createAutoAction(props: IUpdatedAutoActionProps): Promise<string> {
        this.clearMultiActionsCache();
        return getHttpService().request<string>(AUTO_ACTIONS_RESOURCE_URL, {
            method: 'POST',
            data: props
        }, undefined, generalApiError);
    }

    deleteAutoAction(autoActionId: string, multiTagAlreadyCleared?: boolean): Promise<string> {
        if (!multiTagAlreadyCleared) {
            this.clearMultiActionsCache();
        }
        this.clearSpecificActionCache(autoActionId);
        return getHttpService().request<string>(getAutoActionUrl(autoActionId), {
            method: 'DELETE',
        }, undefined, generalApiError);
    }

    // if throwExceptionUponFailures is not true, we return list of IDs of the automated-actions that we failed to delete
    deleteAutoActions(autoActionIds: string[], throwExceptionUponFailures?: boolean): Promise<string[]> {
        this.clearMultiActionsCache();
        const promises = autoActionIds.map(autoActionId => {
            return this.deleteAutoAction(autoActionId, true);
        });
        if (throwExceptionUponFailures) {
            return Promise.all(promises).then(() => []);
        } else {
            const failedIds: string[] = [];
            return Promise.allSettled(promises).then((results: PromiseSettledResult<any>[]) => {
                results.forEach((result: PromiseSettledResult<any>, index) => {
                    if (result.status === 'rejected') {
                        failedIds.push(autoActionIds[index]);
                    }
                });
                return failedIds;
            });
        }
    }
}