import { ActiveFeaturesTypes } from 'common/enum/ActiveFeatures';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { addMainMenuItem } from 'common/helpers/menu';
import { getUserService } from 'common/interface/services';
import Welcome from './Welcome/Welcome';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { isNotChina } from 'common/utils/dataCenter';

const ALLOW_POLICY_REPORT_FEATURES = 'allow-policy-report-features';
export default function initialize() {

    const welcomePageRoute = () => {
        return {
            condition: getUserService().hasPermission([UserRolesTypes.ALL]),
            component: Welcome,
            path: '/network-security/welcome-page',
        };
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'network security welcome', content: welcomePageRoute }]);


    addMainMenuItem(
        {
            id: 'network',
            position: 50,
            icon: 'network',
            label: 'NAVIGATION_MENU.NETWORK_SECURITY.TITLE',
            permission: [UserRolesTypes.ALL],
            sections: [
                {
                    id: 'default',
                    position: 10,
                    items: [
                        {
                            id: 'welcome',
                            position: 10,
                            label: 'NAVIGATION_MENU.NETWORK_SECURITY.WELCOME',
                            state: '/network-security/welcome-page',
                            permission: (() => isNotChina() && getUserService().hasPermission([UserRolesTypes.ALL])),
                        },
                    ],
                },
                {
                    id: 'visibility',
                    position: 20,
                    title: 'NAVIGATION_MENU.NETWORK_SECURITY.VISIBILITY',
                    items: [
                        {
                            id: 'configuration explorer',
                            position: 10,
                            label: 'NAVIGATION_MENU.NETWORK_SECURITY.CONFIGURATION_EXPLORER',
                            state: '/clarity-d3',
                            permission: (() => isNotChina() && getUserService().hasPermission([UserRolesTypes.ALL])),
                        },
                        {
                            id: 'traffic explorer',
                            position: 20,
                            label: 'NAVIGATION_MENU.NETWORK_SECURITY.TRAFFIC_EXPLORER',
                            state: '/magellan/clarity',
                            permission: (() => isNotChina() && getUserService().hasPermission([UserRolesTypes.ALL])),
                        },
                        {
                            id: 'ip addresses',
                            position: 30,
                            label: 'NAVIGATION_MENU.NETWORK_SECURITY.IP_ADDRESSES',
                            state: '/ip-addresses',
                            permission: [UserRolesTypes.ALL],
                        },
                    ],
                },
                {
                    id: 'policy',
                    position: 30,
                    title: 'NAVIGATION_MENU.NETWORK_SECURITY.POLICY',
                    items: [
                        {
                            id: 'security groups',
                            position: 10,
                            label: 'NAVIGATION_MENU.NETWORK_SECURITY.SECURITY_GROUPS',
                            state: '/security-group',
                            urlAliases: [/^\/security-group\/.+/],
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'access leases',
                            position: 20,
                            label: 'NAVIGATION_MENU.SETTINGS.ACCESS_LEASES',
                            state: '/dynamic-access',
                            permission: [UserRolesTypes.ALL],
                        },
                    ],
                },
                {
                    id: 'reports',
                    position: 30,
                    items: [
                        {
                            id: 'policy reports',
                            position: 10,
                            label: 'NAVIGATION_MENU.ALERTING_AND_REPORTING.POLICY_REPORTS',
                            state: '/policy-reports/dashboard',
                            permission: [ALLOW_POLICY_REPORT_FEATURES],
                        },
                        {
                            id: 'black list',
                            position: 20,
                            label: 'NAVIGATION_MENU.NETWORK_SECURITY.BLACK_LIST',
                            state: '/blacklist',
                            permission: [ActiveFeaturesTypes.ALLOW_ANY_AGENT_FEATURES],
                        },
                        {
                            id: 'agent security groups',
                            position: 30,
                            label: 'NAVIGATION_MENU.NETWORK_SECURITY.AGENT_SECURITY_GROUPS',
                            state: '/agent-security-group',
                            permission: [ActiveFeaturesTypes.ALLOW_ANY_AGENT_FEATURES],
                        },
                    ],
                },
            ],
        },
    );
}
