import styled from 'styled-components';

const TopDiv = styled.div`
  width: 100%;
  --padding-y: 5px;
  margin-right: 10px;
`;

const ContentDiv = styled.div`
`;

const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

const graphDiv = styled.div`
    height: 200px;
`;

const tableDiv = styled.div`
  margin-left: 7px;

  .ag-cell-wrapper {
    height: 100%;
  }
`;

const errorDiv = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
`;

export const FindingGraphWrapperStyled = {
    TopDiv,
    ContentDiv,
    SpinnerDiv,
    graphDiv,
    tableDiv,
    errorDiv
};
