import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CONTEXT_FIELD_FULL_NAMES, DATA_SENSITIVITY_VALUES, I18nRiskNamespace } from '../../../../../consts';
import Card from 'common/design-system/components-v2/Card';
import DonutPie from '../../../../../../../common/erm-components/custom/DonutPie/DonutPie';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import useDataAssets from '../useDataAssets';
import { LoadingState } from 'common/interface/general';
import { Spinner, Stack } from 'common/design-system/components-v2';
import i18n from 'i18next';
import { SensitiveDataChartStyled } from './SensitiveDataChart.styled';
import { getAllSortedCategories, getCategoryColor } from '../../../../../DataAssetCategories';
import { IDonutPieSettings } from 'common/erm-components/custom/DonutPie/DonutPie.interface';
import DonutLegend from '../../../../../../../common/erm-components/custom/DonutPie/DonutLegend';
import { IEnvironmentsEmptyStateInfo } from '../../../../../RiskManagement.interface';
import Alert from 'common/design-system/components-v2/Alert';

export const getSensitivityFilter = (filterVal?: IFiltersValues):IFiltersValues | undefined => {
    if (!filterVal) {
        return undefined;
    }
    const newFilter = { ...filterVal };
    newFilter.fields = [
        ...(filterVal?.fields || []),
        { name: CONTEXT_FIELD_FULL_NAMES.dataSensitivity, value: DATA_SENSITIVITY_VALUES.SENSITIVE }
    ];
    return newFilter;
};

const SensitiveDataChart : React.FC<{ emptyEnvStateInfo?: IEnvironmentsEmptyStateInfo, filterValues?: IFiltersValues }> = ({ emptyEnvStateInfo, filterValues }) => {
    const { t } = useTranslation(I18nRiskNamespace);

    const filterWithSensitivity: IFiltersValues | undefined =
        useMemo( () => getSensitivityFilter(filterValues),
            [filterValues]
        );

    const { categories, loadingState, onCategoryClicked } = useDataAssets(filterWithSensitivity);
    const categoriesArray = getAllSortedCategories();
    const settings: IDonutPieSettings = {
        size: 120,
        useSimpleTooltip: true,
        thickness: 8,
    };

    const items = useMemo(() =>
        categoriesArray.map(category => {
            const value = categories?.[category.id] || 0;
            return ({
                key: category.getTitle(),
                value: value,
                color: getCategoryColor(category),
                onclick: value > 0 ? () => onCategoryClicked(category.id) : undefined,
            });
        }), [categories, categoriesArray, onCategoryClicked]);

    return (
        <Card title={t('DATA_SECURITY_DASHBOARD.DATA_INVENTORY.SENSITIVE_DATA_BY_ASSET_CATEGORY')}>
            <SensitiveDataChartStyled.TopDiv padding={[2, 0, 0, 0]} fullHeight>
                {loadingState === LoadingState.IS_LOADING &&
                    <Stack alignItems='center' justifyContent='center' fullWidth fullHeight>
                        <Spinner />
                    </Stack>
                }
                {categories && loadingState === LoadingState.LOADING_SUCCEEDED &&
                    <Stack direction={'column'} spacing={5} fullWidth fullHeight>
                        { emptyEnvStateInfo?.isEmpty && <Alert>{t('DATA_SECURITY_DASHBOARD.DATA_INVENTORY.ENV_HAS_NO_SENSITIVITY_INFO',
                            { count: emptyEnvStateInfo.environmentsCount })}</Alert> }
                        <DonutPie items={items} settings={settings} />
                        { !emptyEnvStateInfo?.isEmpty && <DonutLegend items={items} /> }
                    </Stack>
                }
                {loadingState === LoadingState.LOADING_FAILED &&
                    <SensitiveDataChartStyled.FailureDiv fullWidth fullHeight>
                        {i18n.t('COMMON.ERROR_OCCURRED')}
                    </SensitiveDataChartStyled.FailureDiv>
                }
            </SensitiveDataChartStyled.TopDiv>
        </Card>
    );
};

export default SensitiveDataChart;
