import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { LoginDetails } from 'platform/account/Login/LoginForm.interface';
import {
    setHasError,
    setLoginFormIsLoading,
    setMfaEnforcedError,
    setMfaError,
    validateLogin,
} from 'platform/account/Login/LoginForm.reducer';
import {
    getUserAndSaveToState,
    isUserAllowedToOnboard,
    isUserHadOnboardedAnEnvironment,
    makeCloudInfraAuthenticationRequest,
    makeTokenRequest,
    navigateToApp,
    navigateToLoginAndClearState,
    parseLoginResponse,
    saveTokenValues,
    getReturnUrlFromLocalStorage,
    removeReturnUrlFromLocalStorage
} from 'platform/user/User.actions';
import { RootState } from 'common/services/store/store';
import { v4 } from 'uuid';
import { ALIASES, marketplaceUrls } from './auth.const';
import { setIsAuthenticated } from './Auth.reducer';
import { getLoggerService, getStoreService, getUserService, getWebAppIframeService } from 'common/interface/services';
import { AxiosError } from 'axios';

import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { AFTER_LOGIN_EVENT } from 'common/extensibility/WellKnownPaths';
import { getClientId } from 'App.reducer';
import { changeUrl, getCsrf, getReturnUrl, isUrlStartsWith } from 'common/utils/http';
import { Pages } from 'common/enum/Pages';

const getTokenRequestParams = (params?: LoginDetails) => (state: RootState): LoginDetails => {
    const requestParams: LoginDetails = {
        client_id: getClientId(),
        ...params,
    };

    if (state.loginForm.mfaRequired) {
        requestParams.mfa = state.loginForm.mfa;
    }
    if (state.auth.login_id) {
        requestParams.login_id = state.auth.login_id;
    }

    return requestParams;
};

export const submitLogin = async () => {
    const { state, dispatch } = getStoreService().getReduxTools();
    await validateLogin();
    if (!state.loginForm.error) {
        dispatch(setLoginFormIsLoading(true));
        const loginParams = getTokenRequestParams({
            userName: state.loginForm.username,
            password: state.loginForm.password,
            grant_type: 'password',
        })(state);
        try {
            await makeTokenRequest(loginParams);
            await getUserAndSaveToState();
            await navigateToApp();
            getWebAppIframeService().emitMessage(new IframeMessageModel({ action: IFRAME_MESSAGE_ACTIONS.LOGIN_SUCCESS }));
            const localStorageReturnUrl = getReturnUrlFromLocalStorage() || '/';
            getWebAppIframeService().navigate(decodeURIComponent(localStorageReturnUrl));
            removeReturnUrlFromLocalStorage();
        } catch (e: unknown | any) {
            if (e?.message && e.message === 'mfa_disabled_user') {
                dispatch(setMfaEnforcedError({ type: 'mfa_enforced' }));
            }
            console.log('error in catch is: ', e);
            dispatch(setHasError({ hasError: true }));
            if (loginParams.mfa) {
                dispatch(setMfaError({ type: 'mfa_required' }));
            }
        } finally {
            dispatch(setLoginFormIsLoading(false));
        }
    }
};

export const refreshToken = async (emitMessageToWebApp = false) => {
    const state = getStoreService().state;
    const addedValues: LoginDetails = { grant_type: 'refresh_token', refresh_token: v4(), csrf: getCsrf() };
    const loginObject = getTokenRequestParams(addedValues)(state);
    try {
        await makeTokenRequest(loginObject);
    } catch (e) {
        if (getIsCloudInfra()) {
            console.log('Refresh token failed, trying to reAuthenticate');
            await authenticateWithCloudInfra();
        }
    }
    if (emitMessageToWebApp) {
        const iframeMessage = new IframeMessageModel({ action: IFRAME_MESSAGE_ACTIONS.REFRESH_TOKEN });
        getWebAppIframeService().emitMessage(iframeMessage);
    }
};

const checkUrlMarketplaceExceptions = () => {
    return marketplaceUrls.some(prefix => isUrlStartsWith(prefix));
};

export const initialLogin = async (): Promise<boolean> => {
    const { dispatch } = getStoreService().getReduxTools();
    const isCloudInfra = getIsCloudInfra();

    try {
        if (isCloudInfra) {
            await authenticateWithCloudInfra();
        }
        const sessionAlive = true; // TODO: check if cookie exist and is not expired instead of calling the server
        if (!sessionAlive) {
            navigateToLoginAndClearState(getReturnUrl());
        } else {
            dispatch(setIsAuthenticated(true));
            await getUserAndSaveToState();

            if (isUserAllowedToOnboard()) {
                const userHasEnvs = await isUserHadOnboardedAnEnvironment();
                if (!userHasEnvs && !checkUrlMarketplaceExceptions()) {
                    changeUrl(`/${Pages.CloudOnboarding}`);
                }
            }

            const startTime = performance.now();
            const initialServerDataPromises = globalAddinContainer.get(AFTER_LOGIN_EVENT);
            const results = await Promise.allSettled(initialServerDataPromises);
            results.forEach((result) => {
                if (result.status === 'rejected') {
                    getLoggerService().critical(result.reason);
                }
            });
            const totalTime = performance.now() - startTime;
            const user = getUserService().getUser();
            if (totalTime > 10 * 1000) {
                getLoggerService().warning(`System login is taking too long for account ${user.accountId} time ${totalTime}ms`);
            }
        }
    } catch (error) {
        navigateToLoginAndClearState('', error as AxiosError<any>);
        return false;
    }
    return true;
};

const getCloudInfraFirstTimeTokenLocalStorage = (): string | null => {
    const firstTimeToken = localStorage.getItem(ALIASES.CLOUD_INFRA_FIRST_TIME_TOKEN);
    if (firstTimeToken !== null) {
        localStorage.removeItem(ALIASES.CLOUD_INFRA_FIRST_TIME_TOKEN); //You can use the First Time Token only once
    }
    return firstTimeToken;
};

let cloudInfraAuthenticationPromise: Promise<void> | null = null;

export const authenticateWithCloudInfra = async () => {
    const { state } = getStoreService().getReduxTools();

    const firstTimeToken = getCloudInfraFirstTimeTokenLocalStorage();
    if (firstTimeToken === null) {
        if (cloudInfraAuthenticationPromise !== null) {
            await cloudInfraAuthenticationPromise;
        }
        return;
    }
    cloudInfraAuthenticationPromise = makeCloudInfraAuthenticationRequest(
        state.app.cloudInfraAuthenticateUrl as string,
        firstTimeToken,
    )
        .then((response) => {
            const clientId = ALIASES.CLIENT_ID_CLOUD_INFRA;
            saveTokenValues(parseLoginResponse(response.data, clientId));
            return refreshToken();
        });
    return cloudInfraAuthenticationPromise;
};
