import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IAutoAction } from '../AutoActions.interface';
import { MultiChipCellContent } from 'common/erm-components/custom/MultiChipCellContent/MultiChipCellContent';
import { AutoActionTriggerEnum } from 'common/module_interface/RiskManagement/autoActions/AutoActions';

export const AutoActionTriggersCellRenderer: React.FC<ICellRendererParams<IAutoAction>> = (params) => {
    const autoAction: IAutoAction | undefined = params.data;
    if (!autoAction) {
        return '';
    }

    return <MultiChipCellContent propsList={autoAction.triggers.map((trigger: AutoActionTriggerEnum) => ({ label: String(trigger) }))} />;
};
