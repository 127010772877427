import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const Evidence = styled.div`
    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => `${theme.palette.border.light}`};
    }
`;

const AccordionDiv = styled(Stack)`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

export const EvidencesStyled = {
    AccordionDiv,
    Evidence
};