import React from 'react';
import './SwitchRole.scss';
import Roles from './Roles/Roles';
import {
    CpCommonButton,
    CpModal,
    CpModalBody,
    CpModalFooter,
    CpModalHeader,
    CpTextButton,
} from '@dome9/components/react/components';
import { useSelector } from 'react-redux';
import {
    getSelectedAccount,
    getSelectedRole,
    getSwitchRoleButtonEnabled,
    getUserRolesModalIsOpen,
    setSelectedAccount,
    setSelectedRole,
    setUserRolesModalIsOpen,
} from '../../user/SwitchRoleComponent.reducer';
import IAccount from '../../user/Account.interface';
import { switchRole } from 'platform/user/User.actions';
import { useTranslation } from 'react-i18next';
import { getStoreService } from 'common/interface/services';
import { IUserRole } from 'common/interface/user';
import { setAppLoaded } from '../../../App.reducer';

export const SwitchRole: React.FC = () => {
    const isUserRolesModalOpen = useSelector(getUserRolesModalIsOpen);
    const dispatch = getStoreService().dispatch;
    const selectedAccount = useSelector(getSelectedAccount);
    const selectedRole = useSelector(getSelectedRole);
    const switchRoleButtonEnabled = useSelector(getSwitchRoleButtonEnabled);
    function closeModal() {
        dispatch(setSelectedAccount({} as IAccount));
        dispatch(setSelectedRole(''));
        dispatch(setUserRolesModalIsOpen(false));
    }
    function handleSwitchRole() {
        if (selectedAccount) {
            setUserRolesModalIsOpen(false);
            const userRole = {
                accountName: selectedAccount.accountName,
                accountId: selectedAccount.accountId,
                role: selectedRole,
            } as IUserRole;

            getStoreService().dispatch(setAppLoaded(false));
            switchRole(userRole);
            closeModal();
        }
    }

    function isModalCloseEvent(event: any) {
        //The close event also occur when the inner combo-boxes closes
        return event.target.nodeName === 'CP-MODAL-HEADER';
    }

    function handleCloseAction(event: any) {
        if (isModalCloseEvent(event)) {
            closeModal();
        }
    }
    const { t } = useTranslation();
    return (
        <div className={'switch-role'}>
            <CpModal onCpClose={(event: any) => handleCloseAction(event)} size={'md'} open={isUserRolesModalOpen}>
                <CpModalHeader>{t('SWITCH_ROLE.TITLE')}</CpModalHeader>
                <CpModalBody>
                    <div className={'roles-wrapper'}>
                        <Roles />
                    </div>
                </CpModalBody>
                <CpModalFooter>
                    <CpTextButton onClick={() => closeModal()}>{t('SWITCH_ROLE.CANCEL_BUTTON')}</CpTextButton>
                    <CpCommonButton disabled={!switchRoleButtonEnabled} onClick={() => handleSwitchRole()}>
                        {t('SWITCH_ROLE.SUBMIT_BUTTON')}
                    </CpCommonButton>
                </CpModalFooter>
            </CpModal>
        </div>
    );
};

export default SwitchRole;
