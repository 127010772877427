import { Button, Modal } from '@dome9/berries/react-components';
import { getStoreService } from 'common/interface/services';
import { IDashboardSection, IDashboardWidget } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { updateDashboardInServer } from 'modules/overview/Api';
import { addWidgetInStore, deleteWidgetInStore, getSelectedDashboard } from 'modules/overview/Overview.reducer';
import { sortWidgetsByView } from 'modules/overview/Utils';
import { i18nOverviewNamespace } from 'modules/overview/initialize.i18n';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ActionTypes } from './AddEditWidgetModal';

interface IAddEditWidgetModalProps {
    widget: IDashboardWidget | null
    isOpen: boolean
    onRequestClose: Function,
    action: ActionTypes,
    section: IDashboardSection
}


const DeleteWidgetModal: React.FC<IAddEditWidgetModalProps> = ({ widget, isOpen, onRequestClose, section }) => {
    const { t } = useTranslation(i18nOverviewNamespace);
    const { dispatch } = getStoreService().getReduxTools();
    const selectedDashboard = useSelector(getSelectedDashboard);

    const handleSubmit = async ()=> { 
        const selectedDashboardClone = deepCloneObject(selectedDashboard);

        const sections = selectedDashboardClone.sections?.map((sectionItem: IDashboardSection) => {
            const widgets = [] as IDashboardWidget[];
            sectionItem?.widgets?.forEach((widgetItem) => {
                if (widget && widgetItem.id !== widget.id) {
                    widgets.push(widgetItem);
                } 
            });
            sectionItem.widgets = widgets;
            return sectionItem;
        });
        selectedDashboardClone.sections = sortWidgetsByView(sections);
    
        try { 
            if(widget?.id){
                dispatch(deleteWidgetInStore(widget.id));
            }
            onRequestClose();
            await updateDashboardInServer(selectedDashboardClone, false);
        } catch {
            if(widget?.id){
                dispatch(addWidgetInStore({ widget: widget, sectionTitle: section.title }));
            }
        }
    };

    return (    
        <Modal.ModalDialog width='md' isOpen={isOpen} onRequestClose={()=> onRequestClose()} shouldCloseOnOverlayClick>
            <Modal.ModalHeader
                title={t('WIDGETS.DELETE_WIDGET.TITLE')}
                onClose={()=> onRequestClose()}
            />
            <Modal.ModalContent>
                <div>
                    <Trans components={{ b: <strong /> }}>
                        {t('WIDGETS.DELETE_WIDGET.MESSAGE', { widgetName: widget?.title })}
                    </Trans>
                </div>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <div className='modal__footer-actions'>
                    <Button onClick={()=> {
                        onRequestClose();
                    }}>{t('DASHBOARD.CANCEL')}</Button>
                    <Button color='primary' onClick={async ()=>await handleSubmit()}>{t('DASHBOARD.DELETE')}</Button>
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
        
    );
};

export default DeleteWidgetModal;