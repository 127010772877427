import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { GoogleCloudAccountResetMissingPermissions, GoogleCloudAccountsAttachOrganizationalUnit } from './googleCloudAccountApi.interface';

const apiBaseUrl = 'GoogleCloudAccount';

const attachOrganizationalUnit: GoogleCloudAccountsAttachOrganizationalUnit.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<GoogleCloudAccountsAttachOrganizationalUnit.Response>>(
        `${apiBaseUrl}/organizationalUnit/attach`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const resetMissingPermissions: GoogleCloudAccountResetMissingPermissions.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<GoogleCloudAccountResetMissingPermissions.Response>>(
        `${apiBaseUrl}/${request.id}/MissingPermissions/Reset`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};


const GoogleCloudAccountApi = {
    attachOrganizationalUnit,
    resetMissingPermissions,
};

export default GoogleCloudAccountApi;
