import { Addin } from 'common/extensibility/AddinRegistry';
import { ColDef } from 'ag-grid-community';
import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import {
    IEnvironmentsFilter,
} from 'common/module_interface/assets/Environments';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { INotificationsFilterParams } from './Interfaces/NotificationPageInterfaces';

const PATH_NOTIFICATIONS_PREFIX = buildPath('notifications', 'notifications_settings');
export const PATH_NOTIFICATIONS_PAGE = buildPath(PATH_NOTIFICATIONS_PREFIX, 'page');
export const PATH_NOTIFICATIONS_PAGE_TABLE_COLUMNS = buildPath(PATH_NOTIFICATIONS_PAGE, 'columns');
export const PATH_NOTIFICATIONSS_PAGE_TABLE_FILTERS = buildPath(PATH_NOTIFICATIONS_PAGE, 'filters');

export const NotificationsTableRegistry = {
    addColumnDefAddins: (columnDefAddins: Addin<ColDef>[]) => {
        globalAddinContainer.add<ColDef>(PATH_NOTIFICATIONS_PAGE_TABLE_COLUMNS, columnDefAddins);
    },

    addColumnDefs: (columnDefs: ColDef[]) => {
        globalAddinContainer.addMap(PATH_NOTIFICATIONS_PAGE_TABLE_COLUMNS, columnDefs, 'colId');
    },

    getColumnDefs: (): ColDef[] => {
        return globalAddinContainer.get<ColDef>(PATH_NOTIFICATIONS_PAGE_TABLE_COLUMNS);
    },

    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_NOTIFICATIONSS_PAGE_TABLE_FILTERS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: INotificationsFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(PATH_NOTIFICATIONSS_PAGE_TABLE_FILTERS, filterId, filterParams);
    },


    getFilterDefs(filters: IEnvironmentsFilter[], params: INotificationsFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = NotificationsTableRegistry.getFilter(filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    },

};