import React, { useState } from 'react';
import PerimeterExposureComponent from '../../PerimeterExposure/Components/PerimeterExposureComponent';
import Ec2PerimeterExposureHeaderComponent from './Ec2PerimeterExposureHeaderComponent';
import { GraphData } from '../../PerimeterExposure/Models/GraphData';

const Ec2PerimeterExposure: React.FC = () => {
    const [selectedEc2Srl, setSelectedEc2Srl] = useState<string | undefined>();
    const [graphGetter, setGraphGetter] = useState<(srl: string) => Promise<GraphData>>();

    return <div className={ 'asset-exposure-container' } style={{ padding: '20px', height: 'calc(100vh - 50px)', overflow: 'hidden', backgroundColor: '#ffffff' }}>
        <Ec2PerimeterExposureHeaderComponent
            setSelectedEc2Srl={ setSelectedEc2Srl }
            setGraphGetter={ setGraphGetter }
        />

        <PerimeterExposureComponent
            assetSrl={ selectedEc2Srl }
            getGraphBySrl={ graphGetter }
        />
    </div>;
};

export default Ec2PerimeterExposure;