import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AssetTypesStyled } from './RiskiestAssetTypes.styled';
import { LoadingState } from 'common/interface/general';
import { Spinner, Table } from 'common/design-system/components-v2';
import { GridOptions } from 'ag-grid-community';
import { getAssetTypesColumnDefs } from './RiskiestAssetTypesColumns';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { getAssetTypesScoreCounters, IAssetTypesScoreCounters } from './RiskiestAssetTypes.datasource';
import { ermTrans } from '../../../../../RiskManagement.utils';
import { IRiskLevelCounters } from 'common/module_interface/RiskManagement/RiskLevel';
import { isNil } from 'common/utils/helpFunctions';
import { RiskStyled } from '../../../../../RiskManagement.styled';

interface IAssetTypeItem {
    riskScore: number
    typeByPlatform: string | undefined,
    critical: number,
    high: number,
    total: number,
}

interface IAssetTypeItemsInfo {
    assetTypeItems: IAssetTypeItem[],
    loadingState: LoadingState;
}

export interface IScoreData {
    count: number;
    value: string;
    riskScore: IRiskLevelCounters;
}

const EMPTY_ASSET_TYPES_ITEMS_INFO = () => {
    return {
        assetTypeItems: [],
        loadingState: LoadingState.IS_LOADING,
    };
};

export const RiskiestAssetTypes: React.FC<{ filterValues?: IFiltersValues }> = ({ filterValues, }) => {
    const [itemsInfo, setItemsInfo] = useState<IAssetTypeItemsInfo>(EMPTY_ASSET_TYPES_ITEMS_INFO());

    const gridOptions: GridOptions = useMemo(() => {
        return {
            columnDefs: getAssetTypesColumnDefs(filterValues?.fields),
            defaultColDef: { sortable: false, resizable: true, suppressMenu: true, suppressMovable: true, },
            rowModelType: 'clientSide',
            enableRangeSelection: false,
            suppressContextMenu: true,
            suppressCellFocus: true,
            suppressRowDrag: true,
            suppressDragLeaveHidesColumns: true,
            rowData: itemsInfo.assetTypeItems,
        };
    }, [filterValues?.fields, itemsInfo.assetTypeItems]);

    const updateData = useCallback(() => {
        if (!filterValues) {
            return;
        }

        setItemsInfo(EMPTY_ASSET_TYPES_ITEMS_INFO());
        getAssetTypesScoreCounters(filterValues).then((response: IAssetTypesScoreCounters) => {
            const assetTypeItems = response.groupedItems.map((responseItem): IAssetTypeItem => {
                const assetType = getProtectedAssetsService().getAssetByType(responseItem.fieldValue);
                const scoreData: IScoreData | undefined = response.scoreByType.find((item: IScoreData) => item.value === assetType?.type);
                return {
                    riskScore: responseItem.riskScore ?? 0,
                    typeByPlatform: assetType?.typeByPlatform,
                    critical: scoreData?.riskScore?.critical ?? 0,
                    high: scoreData?.riskScore?.high ?? 0,
                    total: scoreData?.count ?? 0,
                };
            }).filter((typeItem: IAssetTypeItem) => !isNil(typeItem.typeByPlatform));
            setItemsInfo({
                assetTypeItems,
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
        }).catch(() => {
            setItemsInfo({
                assetTypeItems: [],
                loadingState: LoadingState.LOADING_FAILED,
            });
        });
    }, [filterValues]);

    useEffect(() => {
        if (filterValues) {
            updateData();
        }
    }, [filterValues, updateData]);


    if (itemsInfo.loadingState === LoadingState.IS_LOADING) {
        return <AssetTypesStyled.TopDiv ><RiskStyled.SpinnerDiv><Spinner /></RiskStyled.SpinnerDiv></AssetTypesStyled.TopDiv>;
    }

    return (
        <AssetTypesStyled.TopDiv>
            <AssetTypesStyled.HeaderDiv>{ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.TITLE')}</AssetTypesStyled.HeaderDiv>
            <AssetTypesStyled.TopTableDiv>
                <Table
                    gridOptions={gridOptions}
                    disableColumnMenu={true}
                    disableGrouping={true}
                />
            </AssetTypesStyled.TopTableDiv>
        </AssetTypesStyled.TopDiv>
    );
};