import WelcomePage from 'common/components/WelcomePage/WelcomePage';
import { useTranslation } from 'react-i18next';
import backgroundImage from '../../../assets/images/welcomePage/posture.svg';
import { IWelcomePageSection } from 'common/components/WelcomePage/WelcomePage.interface';
import { checkChinaPermissions } from 'common/utils/dataCenter';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { isAfEnabled } from 'common/utils/debugUtils';
import { NEW_NOTIFICATIONS_PAGE_AF } from 'common/module_interface/settings/integrations/Integrations';


const Welcome: React.FC = () => {
    const { t } = useTranslation();
    const PAGE_ID = 'POSTURE_MANAGEMENT';
    const WELCOME_PAGE_LANG_PATH = `${PAGE_ID}.WELCOME_PAGE`;
    const SECTIONS_LANG_PATH = `${WELCOME_PAGE_LANG_PATH}.SECTIONS`;

    const sections: IWelcomePageSection[] = [
        {
            title: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.TITLE`),
            subtitle: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.SUBTITLE`),
            items: [
                {
                    title: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.CARDS.COMPLIANCE_STATUS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.CARDS.COMPLIANCE_STATUS.SUBTITLE`),
                    icon: 'timelineChart',
                    linkTo: '/compliance-engine/dashboard',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.CARDS.COMPLIANCE_FINDINGS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.CARDS.COMPLIANCE_FINDINGS.SUBTITLE`),
                    icon: 'find',
                    linkTo: '/posture-findings',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.CARDS.RUN_ASSESSMENT.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.CARDS.RUN_ASSESSMENT.SUBTITLE`),
                    icon: 'assessment',
                    linkTo: '/compliance-engine',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.CARDS.ASSESSMENT_HISTORY.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.COMPLIANCE.CARDS.ASSESSMENT_HISTORY.SUBTITLE`),
                    icon: 'history',
                    linkTo: '/compliance-engine/history',
                },
            ],
        },
        {
            title: t(`${SECTIONS_LANG_PATH}.RULESETS_AND_RULES.TITLE`),
            subtitle: t(`${SECTIONS_LANG_PATH}.RULESETS_AND_RULES.SUBTITLE`),
            items: [
                {
                    title: t(`${SECTIONS_LANG_PATH}.RULESETS_AND_RULES.CARDS.RULESETS_AND_RULES.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.RULESETS_AND_RULES.CARDS.RULESETS_AND_RULES.SUBTITLE`),
                    icon: 'rule',
                    linkTo: '/compliance-engine',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.RULESETS_AND_RULES.CARDS.RUN_GSL.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.RULESETS_AND_RULES.CARDS.RUN_GSL.SUBTITLE`),
                    icon: 'assessment',
                    linkTo: '/compliance-engine/playground',
                },
            ],
        },
        {
            title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.TITLE`),
            subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.SUBTITLE`),
            items: [
                {
                    title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_POLICIES.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_POLICIES.SUBTITLE`),
                    icon: 'book',
                    linkTo: '/compliance-engine/continuous-compliance',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_REMEDIATION.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_REMEDIATION.SUBTITLE`),
                    icon: 'robot',
                    linkTo: '/compliance-engine/remediation',
                    permission: ()=> checkChinaPermissions([UserRolesTypes.ALL]),
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_EXCLUSIONS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.MANAGE_EXCLUSIONS.SUBTITLE`),
                    icon: 'group-exclusion',
                    linkTo: '/compliance-engine/exclusion',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.CONFIGURE_NOTIFICATIONS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.CONFIGURATION.CARDS.CONFIGURE_NOTIFICATIONS.SUBTITLE`),
                    icon: 'bell',
                    linkTo: isAfEnabled(NEW_NOTIFICATIONS_PAGE_AF) ? '/notifications-list' : '/notifications',
                },
            ],
        },
    ];
    return (
        <WelcomePage
            title={t(`${WELCOME_PAGE_LANG_PATH}.TITLE`)}
            subtitle={t(`${WELCOME_PAGE_LANG_PATH}.SUBTITLE`)}
            sections={sections}
            backgroundImage={backgroundImage}
        />
    );
};

export default Welcome;
