import {
    ICveIgnoreItem,
    ICveIgnoreListService,
    ICveIgnoreModel,
    ICveIgnoreModelUpdate, ICveIgnoreUpdate,
} from './CveIgnoreList.interface';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import {
    getCommonIgnoreItemModelUpdateFromItem,
    getIgnoreItemsFromModels,
} from '../CommonIgnoreList/CommonIgnoreList.model';
import { ICommonIgnoreItem, ICommonIgnoreItemModelUpdate } from '../CommonIgnoreList/CommonIgnoreList.interface';
import { clearCacheDataByTag, getCacheTag } from 'common/utils/apiCaching';
import { getHttpService } from 'common/interface/services';
import { generalApiError } from 'common/utils/http';
import { IdResponse } from 'common/erm-components/utils/ermComponents.interface';
import { CVE_IGNORE_RESOURCE_URL } from './CveIgnoreList.consts';

const CVE_IGNORE_LIST_SERVICE_NAME = 'CVE_IGNORE_LIST_SERVICE_NAME';

const getCveIgnoreListUrl = (itemId: string) => `${CVE_IGNORE_RESOURCE_URL}/${itemId}`;

const getModelUpdateFromItemUpdate = (updateItem: ICveIgnoreUpdate): ICveIgnoreModelUpdate => {
    const commonUpdateModel: ICommonIgnoreItemModelUpdate = getCommonIgnoreItemModelUpdateFromItem(updateItem);
    return {
        ...commonUpdateModel,
    };
};

export class CveIgnoreListService implements ICveIgnoreListService {
    private clearMultiActionsCache() {
        clearCacheDataByTag(CVE_IGNORE_LIST_SERVICE_NAME);
    }
    private clearSpecificActionCache(issueExclusionId: string) {
        clearCacheDataByTag(CVE_IGNORE_LIST_SERVICE_NAME, issueExclusionId);
    }

    public async getAllIgnoreItems(): Promise<ICveIgnoreItem[]> {
        const models: ICveIgnoreModel[] = await sendHttpRequest<ICveIgnoreModel[]>(
            CVE_IGNORE_RESOURCE_URL, { method: 'GET' }, undefined,
            [getCacheTag(CVE_IGNORE_LIST_SERVICE_NAME)]);
        const commonItems: ICommonIgnoreItem[] = getIgnoreItemsFromModels(models);
        return commonItems.map(commonItem => ({
            ...commonItem,
        }));
    }

    public async updateIgnoreItem(itemId: string, updateItem: ICveIgnoreUpdate): Promise<string> {
        this.clearMultiActionsCache();
        this.clearSpecificActionCache(itemId);
        const updateModel: ICveIgnoreModelUpdate = getModelUpdateFromItemUpdate(updateItem);
        return getHttpService().request<string>(getCveIgnoreListUrl(itemId), {
            method: 'PUT',
            data: updateModel,
        }, undefined, generalApiError);
    }

    public async createIgnoreItem(newItem: ICveIgnoreUpdate): Promise<string> {
        this.clearMultiActionsCache();
        const newModel: ICveIgnoreModelUpdate = getModelUpdateFromItemUpdate(newItem);
        const response: IdResponse = await getHttpService().request<IdResponse>(CVE_IGNORE_RESOURCE_URL, {
            method: 'POST',
            data: newModel
        }, undefined, generalApiError);
        return response.id;
    }

    public async deleteIgnoreItem(itemId: string, multiTagAlreadyCleared?: boolean): Promise<string> {
        if (!multiTagAlreadyCleared) {
            this.clearMultiActionsCache();
        }
        this.clearSpecificActionCache(itemId);
        return getHttpService().request<string>(getCveIgnoreListUrl(itemId), {
            method: 'DELETE',
        }, undefined, generalApiError);
    }

    public async deleteIgnoreItems(itemIds: string[], throwExceptionUponFailures?: boolean): Promise<string[]> {
        this.clearMultiActionsCache();
        const promises = itemIds.map(itemId => {
            return this.deleteIgnoreItem(itemId, true);
        });
        if (throwExceptionUponFailures) {
            return Promise.all(promises).then(() => []);
        } else {
            const failedIds: string[] = [];
            return Promise.allSettled(promises).then((results: PromiseSettledResult<any>[]) => {
                results.forEach((result: PromiseSettledResult<any>, index) => {
                    if (result.status === 'rejected') {
                        failedIds.push(itemIds[index]);
                    }
                });
                return failedIds;
            });
        }
    }
}