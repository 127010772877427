import i18n, { validLanguages } from 'common/services/translations/translations';

export const EventsTableNamespacePrefix = 'eventsTable';
export type EventsTableTermTypes = 'table';
const termsFolders: Array<EventsTableTermTypes> = ['table'];

export const getEventsTableNamespace = (term: EventsTableTermTypes) => `${EventsTableNamespacePrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: EventsTableTermTypes; }>>((acc, language) => {
    const filesPAth = termsFolders.map(term => ({ path: `${language}/${term}`, language, term }));
    return [...acc, ...filesPAth];
}, []);

export const initializeEventsTableI18n = async () => {
    await Promise.all(jsonFilesPaths.map(async (file) => {
        try {
            const contents = await require(`./languages/${file.path}.json`);
            i18n.addResourceBundle(file.language, getEventsTableNamespace(file.term), contents);
        // eslint-disable-next-line
        } catch (error) { }
    }));
};