import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { getStoreService } from 'common/interface/services';
import { Stack, Button, CopiableLine, Typography } from 'common/design-system/components-v2';
import StatusMessagePanel from 'common/components/StatusMessagePanel/StatusMessagePanel';
import { StatusType } from 'common/components/StatusMessagePanel/StatusMessagePanel.types';
import { AzureOnboardingStep, AzureOnboardingStepTitle } from '../../AzureIntelligenceOnboarding.styled';
import {
    getAllStorages,
    getAzureOnboardingType,
    getIpAddress,
    getStep,
    getSubscriptionId,
    setIpAddress,
    setNextButtonDisabled
} from '../../AzureIntelligenceOnboarding.reducer';
import { getIntelligenceNATIP, getStoragesWithInvalidFirewall } from '../../Services/AzureIntelligenceOnboardingService';
import { IInvalidFirewallStoragesRequestModel } from '../../AzureIntelligenceOnboarding.interface';
import { AzureIntelligenceOnboardingType } from '../../AzureIntelligenceOnboarding.const';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';

const AzureNetworkFirewall: FunctionComponent = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });
    const dispatch = getStoreService().dispatch;

    const subscriptionId = useSelector(getSubscriptionId);
    const currentStep = useSelector(getStep);
    const ipAddress = useSelector(getIpAddress);
    const allStorages = useSelector(getAllStorages);
    const isCentralizedOnboarding = useSelector(getAzureOnboardingType) === AzureIntelligenceOnboardingType.centralized;

    const [isLoading, setIsLoading] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [status, setStatus] = useState(StatusType.ERROR);

    const selectedStorages = useMemo(() => allStorages.filter(storage => storage.isSelected), [allStorages]);

    useEffect(() => {
        setShowStatus(false);
    }, [currentStep]);

    useEffect(() => { 
        const fetchIp = async () => { 
            const intelligenceNATIP: string[] | undefined = await getIntelligenceNATIP();
            if (intelligenceNATIP?.length) {
                dispatch(setIpAddress(intelligenceNATIP.toString()));
            }
        };

        if (!ipAddress) {
            fetchIp();
        }
    }, [dispatch, ipAddress]);


    const checkStatus = () => {
        const request: IInvalidFirewallStoragesRequestModel[] = [];
        setShowStatus(false);

        selectedStorages.forEach(storage => {
            if (isCentralizedOnboarding || (!isCentralizedOnboarding && !request.find(data => data.StorageAccountName === storage.storageName))) {
                request.push({
                    SubscriptionId: subscriptionId,
                    StorageAccountName: storage.storageName,
                    ResourceGroup: isCentralizedOnboarding ? storage.resourceGroupName : (storage.storageRg ?? '')
                });
            }
        });

        setIsLoading(true);

        getStoragesWithInvalidFirewall(request).then(res => {
            setShowStatus(true);

            if (res?.length === 0) {
                setStatus(StatusType.SUCCESS);
                dispatch(setNextButtonDisabled(false));
            } else {
                setStatus(StatusType.ERROR);
                dispatch(setNextButtonDisabled(true));
            }
        }).catch(() => {
            setStatus(StatusType.ERROR);
            dispatch(setNextButtonDisabled(true));
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const getStatusText = () => {
        if (status === StatusType.SUCCESS)
            return t('FIREWALL.STATUS.SUCCESS');
        else if (status === StatusType.ERROR)
            return t('FIREWALL.STATUS.ERROR');
        else return '';
    };

    return (
        <AzureOnboardingStep>
            <AzureOnboardingStepTitle>{t('STEPS.FIREWALL')}</AzureOnboardingStepTitle>

            <Stack padding={[5, 0]}>{t('FIREWALL.DESCRIPTION')}</Stack>

            <div><Trans>{t('FIREWALL.INSTRUCTIONS.TITLE')}</Trans></div>

            <Stack padding={[1, 0]}>
                <ol>
                    <li><Trans>{t('FIREWALL.INSTRUCTIONS.1')}</Trans></li>
                    <li>{t('FIREWALL.INSTRUCTIONS.2')}</li>
                    <li>
                        <Trans>{t('FIREWALL.INSTRUCTIONS.3')}</Trans>
                        <ul>
                            <li>
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    {t('FIREWALL.INSTRUCTIONS.NOTE')}
                                    <CopiableLine value={ipAddress}>
                                        <Typography variant='body500'>{ipAddress}</Typography>
                                    </CopiableLine>
                                </Stack>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Stack direction='column'>
                            <span>{t('FIREWALL.INSTRUCTIONS.4')}</span>

                            {showStatus &&
                                <Stack padding={[1, 0, 4, 0]}><StatusMessagePanel type={status} text={getStatusText()}/></Stack>
                            }

                            <Button 
                                loading={isLoading} 
                                onClick={checkStatus} 
                                variant='outlined' 
                                color='brandPrimary'
                                disabled={isLoading || status === StatusType.SUCCESS}
                            >
                                {t('BUTTONS.CHECK_ACCESS')}
                            </Button>
                        </Stack>
                    </li>
                </ol>
            </Stack>

        </AzureOnboardingStep>
    );
};

export default AzureNetworkFirewall;
