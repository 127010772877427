import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardItem } from 'common/components/policy/Card';
import CardsSelectComponent from 'common/components/policy/CardsSelectComponent';
import { Vendors } from 'common/consts/vendors';
import { I18nIdentityNamespace } from '../consts';

export const CIEMPlatformsSelectionComponent: React.FC<any> = ({ selectedPlatformId, onValidate, selectedItemChanged }) => {
    const { t } = useTranslation(I18nIdentityNamespace);
    const vendors:CardItem[] = [
        {
            id: Vendors.AWS,
            title: Vendors.AWS,
            icon: 'aws',
        },
        {
            id: Vendors.AZURE,
            title: Vendors.AZURE,
            icon: 'azure',
        }
    ];
    const init = async () => {
        onValidate(true);
        selectedItemChanged(vendors[0]);
    };

    useEffect(() => {
        init();
    }, []);

    return(
        <span data-aid={'platform-selection'} >
            <CardsSelectComponent selectedItemId={selectedPlatformId} header={t('POLICY.SELECT_PLATFORM')} items={vendors} onSelectedItemChanged={selectedItemChanged}/>
        </span>
    );
};
