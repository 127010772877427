import { FC, useEffect, useState } from 'react';
import { SelectV2 } from 'common/design-system/components-v2';
import { I18nExclusion } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { IExclusionSeveritiesProps } from '../../helpers/exclusions.interfaces';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { fetchSeverities } from '../../helpers/exclusions.utils';


const ExclusionBySeverities: FC<IExclusionSeveritiesProps> = ({ isEnabled, selectedRuleset, onChange, selectedOption, initialSelectedOption }) => {
    const { t } = useTranslation(I18nExclusion);
    const [severitiesList, setSeveritiesList] = useState<SelectOption[]>([]);

    useEffect(() => {
        const handleClearListAndValue = () => {
            setSeveritiesList([]);
            onChange([]);
        };
        const updateSeveritiesList = (severity: SelectOption[]) => {
            setSeveritiesList(severity);
        };
        const handleFetchSeverities = () => {
            if (!selectedRuleset) {
                handleClearListAndValue();
                return;
            }
            const severitiesData = fetchSeverities();
            severitiesData && updateSeveritiesList(severitiesData);
        };
        handleFetchSeverities();
    }, [selectedRuleset]);

    useEffect(() => {
        initialSelectedOption ? onChange(initialSelectedOption) : onChange([]);
    }, [severitiesList]);

    const handleSeverityChange = (newValue: string[]) => {
        onChange(newValue);
    };

    return (
        <SelectV2
            label={t('MODAL.TOPICS.SEVERITY.TITLE')}
            onChange={(value: string[]) => handleSeverityChange(value)}
            isMulti={true}
            options={severitiesList}
            readOnly={!isEnabled}
            placeholder={t('MODAL.GENERAL.SELECT')}
            value={selectedOption}
            data-aid='exclusions-severity-select'
            fullWidth
            clearable
        />
    );
};

export default ExclusionBySeverities;