import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import { RulesManagement, Rule } from 'common/interface/RulesManagement';
import { ActionTypes, PlatformTypes } from 'common/common_types';
import { FindingSeverityEnum } from 'common/consts/FindingSeverity';

const initialState: RulesManagement = {
    rules: [
        {
            id: 0,
            cloudGuardManaged: true,
            title: '',
            description: '',
            labels: [],
            severity: FindingSeverityEnum.low,
            action: ActionTypes.detect,
            source: '',
            logics: [
                {
                    id: '',
                    gslId: '',
                    gslExpression: '',
                    gslExpressionHash: '',
                    platform: PlatformTypes.aws,
                    entityType: '',
                    remediation: {
                        reference: '',
                        manualRemediation: '',
                        autoRemediation: [
                            {
                                cloudBotName: '',
                                cloudGuardManaged: true,
                            },
                        ],
                    },
                },
            ],
            references: [
                {
                    id: '',
                    framework: '',
                    frameworkVersion: '',
                    platform: PlatformTypes.aws,
                    referenceSections: [],
                },
            ],
            revisionId: 0,
            created: '',
            createdUser: '',
            modified: '',
            modifiedUser: '',
        },
    ],
};

export const rulesManagementSlice = createSlice({
    name: 'rulesManagement',
    initialState,
    reducers: {
        setRules: (state: RulesManagement, action: PayloadAction<Array<Rule>>) => {
            const payload = action.payload;
            state.rules = payload;
        },

        setSelectedRule: (state: RulesManagement, action: PayloadAction<Rule>) => {
            state.selectedRule = action.payload;
        },
    },
});

export const { setRules, setSelectedRule } = rulesManagementSlice.actions;

export const getRulesManagementData = (state: RootState): Rule[] => state.rulesManagement.rules;
export const getSelectedRule = (state: RootState): Rule => state.rulesManagement.selectedRule;

export default rulesManagementSlice.reducer;
