import React from 'react';
import { RemediationBoxStyled } from './RemediationBox.styled';
import { Icon, Stack, Typography } from 'common/design-system/components-v2';

interface RemediationBoxProps {
    title: string;
    remediation: string;
}

export const RemediationBox: React.FC<RemediationBoxProps> = ({ title, remediation }) => {
    return (
        <RemediationBoxStyled.Wrapper direction='row' padding={6} spacing={3} alignItems='flex-start'>
            <Icon name='remedy' size={24} color='brandPrimary' ></Icon>
            <Stack direction='column' spacing={2}>
                <Typography variant={'body500'}>{title}</Typography>
                <Typography>{remediation}</Typography>
            </Stack>
        </RemediationBoxStyled.Wrapper>
    );
};
