import React, { useEffect } from 'react';
import GraphComponent from 'common/components/Graph/Components/GraphComponent';
import { VisualizationProps } from '../Models/GraphModels';
import { edgeTypes } from '../Services/EdgeTypeHandler';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';
import { nodeTypes } from '../Services/NodeTypeHandler';
import { GridOptions } from 'common/components/Graph/Models/GridOptions';
import {
    GRAPH_CLASSES,
    GRAPH_LAYOUT_MODEL,
    GRAPH_ZOOM_LIMITS,
    GRID_LABELS_MARGIN,
    INTELLIGENCE_GRID,
    NODE_SIZE,
} from '../Consts/GraphProperties';
import { useSelector } from 'react-redux';
import { getVisualizationGraphState } from '../Services/GraphState.reducer';

const VisualizationComponent: React.FC<VisualizationProps> = (props: VisualizationProps) => {
    const { setNodes, setEdges, nodes, edges, onNodeClick, intelligenceSource } = props;
    const directionalLayoutModel = GRAPH_LAYOUT_MODEL;
    const graphDirectionState: LayoutDirection | undefined = useSelector(getVisualizationGraphState);

    useEffect(() => {
        directionalLayoutModel.layoutDirection = graphDirectionState || directionalLayoutModel.layoutDirection;
    }, [graphDirectionState, directionalLayoutModel]);

    const gridOptions: GridOptions = {
        showGrid: true,
        gridLabels: INTELLIGENCE_GRID[intelligenceSource ?? 'accountActivity'].LABELS,
        graphContainerClass: GRAPH_CLASSES.CONTAINER,
        nodeContainerClass: GRAPH_CLASSES.NODE,
        nodeSize: NODE_SIZE,
        gridLabelsMargin: GRID_LABELS_MARGIN
    };

    return <GraphComponent
        edges={ edges }
        setEdges={ setEdges }
        nodes={ nodes }
        setNodes={ setNodes }
        directionalLayoutModel={ directionalLayoutModel }
        nodeTypes={ nodeTypes }
        edgeTypes={ edgeTypes }
        gridOptions = { gridOptions }
        onNodeClick={ onNodeClick }
        zoomLimits={ GRAPH_ZOOM_LIMITS }
    />;

};

export default VisualizationComponent;
