import React, { useCallback, useMemo, useState } from 'react';
import { PageStyled } from './IssuesPage.styled';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IssuesTable } from './IssuesTable/IssuesTable';
import {
    getIssuesFacetFields,
    ISSUES_FILTER_PANEL_ID,
    IssuesFilterPanel,
    getIssuesTableFilters,
} from './IssuesFilterPanel/IssuesFilterPanel';
import { CpLoadingDots } from '@dome9/components/react/components';
import { fetchIssuesAggregations } from '../Issues.utils';
import { isNil } from 'common/utils/helpFunctions';
import { ISSUE_FIELD_NAMES } from '../Issues.interface';
import {
    IServerInputFilterDetails,
    IssueStatusEnum,
} from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { getFilterParamsFromURL } from 'common/components/FilterPanel/FilterPanelManager';
import { getStoredFilterValuesById } from 'common/components/FilterPanel/filterUtils';
import { isEmpty } from 'lodash';
import { isAfIssueExclusions } from '../../../RiskManagement.utils';
import { IIssuesFilterType } from '../Issues.filters';
import { removeEmptyValues } from 'common/erm-components/utils/filters';

export const IssuesPage: React.FC = () => {
    const [filterValues, setFilterValues] = useState<IServerInputFilterDetails | undefined>();
    const issuesTableFilters: IIssuesFilterType[] = useMemo(() => getIssuesTableFilters(), []);
    const issuesFacetFields: string[] = useMemo(() => getIssuesFacetFields(), []);

    const onFilterChanged = useCallback(async (filterValues: IServerInputFilterDetails): Promise<Aggregations> => {
        setFilterValues(filterValues);
        return fetchIssuesAggregations(issuesFacetFields, filterValues).then(aggrInfo => aggrInfo.aggregations);
    }, [issuesFacetFields]);

    const getInitialAggregations = useCallback(async (): Promise<Aggregations> => {
        return fetchIssuesAggregations(issuesFacetFields).then(aggrInfo => aggrInfo.aggregations);
    }, [issuesFacetFields]);

    const initialValues = useMemo((): IFiltersValues => {
        const queryParams = removeEmptyValues(getFilterParamsFromURL());
        if (queryParams && (Object.keys(queryParams).length > 0)) {
            const result = { ...queryParams };
            if (!Object.keys(queryParams).includes(ISSUE_FIELD_NAMES.STATUS)) {
                result.status = [IssueStatusEnum.OPEN];
            }
            return result;
        }

        const storedFilterValues = removeEmptyValues(getStoredFilterValuesById(ISSUES_FILTER_PANEL_ID));
        if (!isEmpty(storedFilterValues)) {
            return storedFilterValues;
        }

        return {
            status: [IssueStatusEnum.OPEN],
            isExcluded: isAfIssueExclusions() ? [false] : undefined,
        };

    }, []);

    return (
        <PageStyled.TopDiv>
            {!filterValues && <CpLoadingDots/>}
            <PageStyled.BodyDiv isVisible={!isNil(filterValues)}>
                <IssuesFilterPanel
                    getInitialAggregations={getInitialAggregations}
                    getAggregations={onFilterChanged}
                    filterTypes={issuesTableFilters}
                    initialValues={initialValues}
                />
                {filterValues && <IssuesTable filterValues={filterValues}/>}
            </PageStyled.BodyDiv>
        </PageStyled.TopDiv>
    );
};
