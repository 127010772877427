import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const StackWrapper = styled(Stack)`
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.normal}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    cursor: pointer;
    &:hover {
        p { 
            text-decoration: underline;
        }
    }
`;

const CardWrapper = styled(Stack)`
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.normal}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    display: flex;
    align-items: start;
    flex-direction: column;
`;

const CloseCardWrapper = styled(Stack)`
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.normal}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    margin: 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: start;
    flex-direction: column;
`;

const CardHeaderWrapper = styled(Stack)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
`;

const ListHeader = styled(Stack)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  margin: 20px 0px;
`;

const DividerLine = styled.hr`
  height: 1px;
  width: 100%;
`;

export {
    StackWrapper, ListHeader, CardWrapper, CardHeaderWrapper, CloseCardWrapper, DividerLine
};