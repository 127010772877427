import {
    ActiveFeatureTopicEnum,
    IActiveFeatureInfo
} from 'common/module_interface/RiskManagement/activeFeatures/ActiveFeatures';

export const ALL_EVENTS_TABLE_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'all-events-table',
    description: 'If enabled, all events table in React from Kusto DB will be shown under Events',
    topic: ActiveFeatureTopicEnum.CDR,
};

export const CDR_EVENTS_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'intelligence-findings',
    description: 'If enabled, CDR events table in React from Kusto DB will be shown under Events',
    topic: ActiveFeatureTopicEnum.CDR,
};

export const CDR_EXCLUSIONS_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'cdr-exclusions',
    description: 'If enabled, CDR exclusions table will be shown under Events',
    topic: ActiveFeatureTopicEnum.CDR,
    hidden: true,
};

export const CDR_REPORT_FORM_POC_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'cdr-report-form-poc',
    description: 'If enabled, CDR report action will be done with the new custom form infra',
    topic: ActiveFeatureTopicEnum.CDR,
    hidden: true,
};

export const EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'events-table-actions',
    description: 'If enabled, actions toolbar will be shown in events table',
    topic: ActiveFeatureTopicEnum.CDR,
};

export const CDR_DASHBOARD_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'cdr-dashboard',
    description: 'If enabled, CDR dashboard will be available under CDR',
    topic: ActiveFeatureTopicEnum.CDR,
};


export const CDR_ACTIVE_FEATURES: IActiveFeatureInfo[] = [
    ALL_EVENTS_TABLE_ACTIVE_FEATURE,
    CDR_EVENTS_ACTIVE_FEATURE,
    EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE,
    CDR_DASHBOARD_ACTIVE_FEATURE,
    CDR_EXCLUSIONS_ACTIVE_FEATURE,
    CDR_REPORT_FORM_POC_ACTIVE_FEATURE,
];

