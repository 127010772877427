import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { removeFilterFields } from 'modules/riskManagement/RiskManagement.utils';
import { IFieldInfo } from 'common/interface/general';
import { formatNumberShorthand, isNil } from 'common/utils/helpFunctions';
import { ASSET_FIELD_NAMES } from 'modules/riskManagement/consts';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export const RiskLevelCellRenderer: React.FC<ICellRendererParams & { riskScoreRange?: string, routeFields?: IFieldInfo[] }> = (params) => {
    const count: number = params.value;
    const value = formatNumberShorthand(count);
    const onClick = count > 0 ? () => {
        let fields: IFieldInfo[] = [
            ...(removeFilterFields([ASSET_FIELD_NAMES.type], params.routeFields) || []),
            {
                name: ASSET_FIELD_NAMES.type,
                value: params.data.typeByPlatform,
            }
        ];
        if (params.riskScoreRange) {
            fields = removeFilterFields([ASSET_FIELD_NAMES.riskScore], fields) || [];
            fields.push({
                name: ASSET_FIELD_NAMES.riskScore,
                value: params.riskScoreRange,
            });
        }
        getErmUrlsService().goToProtectedAssetsTable(fields);
    } : undefined;

    const options: IIconLinkCellProps = {
        isLink: !isNil(onClick),
        value: value,
        onClick,
    };

    CellToClipboardHandler(params, value);
    return <IconLinkCellRenderer {...options} />;
};
