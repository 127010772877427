import React from 'react';
import { useTranslation } from 'react-i18next';
import { IInstructionTypeCard } from '../helpers/azure.interface';
import { Typography, Stack } from 'common/design-system/components-v2';
import { CpIcon } from '@dome9/components/react/components';

export const CheckedCorner = () => (
    <div style={{
        background: 'linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #2ea0ff 50%, #2ea0ff 100%)',
        height: '50px',
        width: '50px',
        position: 'absolute',
        right: 0,
    }}>
        <CpIcon icon='check' style={{ fill: 'white', float: 'right', fontSize: '20px',margin: '5px' }} />
    </div>
);

const InstructionCard: React.FC<IInstructionTypeCard> = ({ onCardSelected, isSelected, config })=>{
    const { t } = useTranslation();
    const CostumeComponent = config.componentRender ? config.componentRender(config) : null;
    const onClick = () => {
        if(onCardSelected) {
            onCardSelected(config.value);
        }
    };
    return (
        <Stack onClick={() => onClick()} direction="column" justifyContent='flex-start' alignItems='flex-start' data-aid={config.dataAid} style={{
            borderRadius: '8px',
            overflow: 'hidden',
            border: `2px solid ${isSelected ? '#4fb4f1' : '#dedfe0'}`,
            flexGrow: 1,
        }}>
            <Stack direction="column" justifyContent='flex-start' alignItems='flex-start' fullWidth style={{
                background: 'white',
                position: 'relative',
                minHeight: '400px',
            }}>
                {isSelected && <CheckedCorner />}
                { (config.title || config.subtitle) ? (
                    <Stack justifyContent='center' alignItems='center' fullWidth padding={[2,0]} style={{ background: isSelected ? '#bce3fe' : '#f7f7f7', }}>
                        {config.title && <Typography variant="h2" style={{ fontWeight: 700 }}>{t(config.title)}</Typography>}
                        { config.subtitle && (<Typography variant="body500">{t(config.subtitle)}</Typography>)}
                    </Stack>
                ) : null }
                <Stack justifyContent='flex-start' alignItems='flex-start' fullWidth padding={[3, 4]}>
                    { config.content?.map((content: any, index: number) => <Typography key={`index_${index}`} variant="body">{content}</Typography>)}
                    { config.list && (
                        <Stack justifyContent='flex-start' alignItems='flex-start' fullWidth padding={[3]}>
                            <ul className='list-disc'>
                                { config.list?.map((listContent: string, index: number) =>
                                    <li key={`index_${index}`}>
                                        <Typography variant="body500" style={{ padding: '5px 0' }}>{listContent}</Typography>
                                    </li>
                                )}
                            </ul>
                        </Stack>
                    )}
                    {CostumeComponent}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default InstructionCard;

