import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCloudBotsService } from 'common/module_interface/intelligence/CloudBots/CloudBots.service';
import { cloneDeep, isEmpty, isNull } from 'lodash';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import {
    ICloudBotRemediationDetails, IRemediationExecutionInfo,
    IRemediationResponse,
    IWorkItem
} from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import {
    BotStatus,
    ExecutionStatus
} from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import { getFixItCustomizationKey } from 'common/module_interface/intelligence/CloudBots/CloudBots.utils';
import { useSelector } from 'react-redux';
import { RootState } from 'common/services/store/store';
import { CloudBotsSelector } from '../../CloudBots/Reducers/cloudBots.selectors';
import CloudBotsActions from '../../CloudBots/Reducers/cloudBots.actions';

interface IUseCloudBotStatusCheckerResponse {
    cloudBotRemediationData?: IRemediationResponse;
    cloudBotStatus: BotStatus;
    clearBotStatus: () => void;
}

interface IUseCloudBotStatusCheckerRequest {
    tableId: string;
}

type UseCloudBotStatusChecker = (props: IUseCloudBotStatusCheckerRequest) => IUseCloudBotStatusCheckerResponse;
export const useCloudBotStatusChecker: UseCloudBotStatusChecker = (props: IUseCloudBotStatusCheckerRequest): IUseCloudBotStatusCheckerResponse => {
    const { tableId } = props;
    const fixItCustomizationKey = useMemo(() => getFixItCustomizationKey(tableId), [tableId]);
    const [executionIds, setExecutionIds] = useState<string[]>([]);
    const {
        cloudBotStatus,
        cloudBotRemediationData
    } = useSelector((state: RootState) => CloudBotsSelector.getCloudBotsState(state, fixItCustomizationKey)) ?? {};
    const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

    // clear cloudBot customization and redux state
    const clearBotStatus = useCallback(async () => {
        await getCloudBotsService().clearRemediationDetails(fixItCustomizationKey);
        CloudBotsActions.deleteCloudBot(fixItCustomizationKey);
    }, [fixItCustomizationKey]);

    const isRemediationExecutionCompleted = useCallback(async (): Promise<boolean> => {
        const remediationExecutionData: IRemediationExecutionInfo[] = await getCloudBotsService().pollRemediationExecutionStatus(executionIds);

        if (isEmpty(remediationExecutionData)) return false;

        const isCompleted: boolean = remediationExecutionData.every((workerExecution: IRemediationExecutionInfo) => {
            return [ExecutionStatus.SUCCESS, ExecutionStatus.FAILED].includes(workerExecution.status);
        });

        if (isCompleted) {
            const isSuccess: boolean = remediationExecutionData.every((workerExecution: IRemediationExecutionInfo) => workerExecution.status === ExecutionStatus.SUCCESS);
            CloudBotsActions.updateCloudBotStatus(fixItCustomizationKey, isSuccess ? BotStatus.SUCCESS : BotStatus.FAILED);
            const tempCloudBotRemediationData: IRemediationResponse | undefined = cloneDeep(cloudBotRemediationData);
            remediationExecutionData.forEach((workerExecution: IRemediationExecutionInfo) => {
                const currentExecutionId: string = workerExecution.executionId;
                const currentWorkItemExecutionStatus: ExecutionStatus = workerExecution.status;
                const currentWorkItem: IWorkItem | undefined = tempCloudBotRemediationData?.workItemsTriggerStatuses?.find((workItem: IWorkItem) => {
                    return workItem.workItemExecutionId === currentExecutionId;
                });
                if (currentWorkItem) {
                    currentWorkItem.executionStatus = currentWorkItemExecutionStatus;
                    currentWorkItem.executionErrorMessage = workerExecution.remediationInfo.Error ?? EMPTY_STRING;
                }
            });
            CloudBotsActions.updateRemediationDetails(fixItCustomizationKey, tempCloudBotRemediationData);
        }

        return isCompleted;
    }, [cloudBotRemediationData, executionIds, fixItCustomizationKey]);

    useEffect(() => {
        const getExecutionIds = (data: IRemediationResponse) => {
            if(!data.totalSuccessfullyTriggeredWorkItem) {
                CloudBotsActions.updateCloudBotStatus(fixItCustomizationKey, BotStatus.FAILED);
            }
            return data.workItemsTriggerStatuses
                ?.filter((workItem: IWorkItem) => {
                    return workItem.isTriggered && !isEmpty(workItem.workItemExecutionId);
                })
                ?.map((workItem: IWorkItem) => {
                    return workItem.workItemExecutionId ?? EMPTY_STRING;
                });
        };
        const initData = async (): Promise<string[] | undefined> => {
            if (!cloudBotRemediationData) {
                const data: ICloudBotRemediationDetails | undefined = await getCloudBotsService().getRemediationDetails(fixItCustomizationKey);
                if (!data || isEmpty(data)) {
                    CloudBotsActions.deleteCloudBot(fixItCustomizationKey);
                    return;
                }
                const remediationData: IRemediationResponse = data.remediationData;
                remediationData.workItemsTriggerStatuses?.forEach((workItem: IWorkItem) => {
                    workItem.executionStatus = workItem.isTriggered ? ExecutionStatus.WAITING : ExecutionStatus.NOT_INITIATED;
                });
                CloudBotsActions.addCloudBot(fixItCustomizationKey);
                CloudBotsActions.updateRemediationDetails(fixItCustomizationKey, remediationData);
                return getExecutionIds(remediationData);
            }
            return getExecutionIds(cloudBotRemediationData);
        };
        initData().then((execIds: string[] | undefined) => {
            if (execIds && !isEmpty(execIds)) setExecutionIds(execIds);
        });
    }, [cloudBotRemediationData, fixItCustomizationKey]);

    useEffect(() => {
        let interval: NodeJS.Timer;
        if (isNull(intervalId) && !isEmpty(executionIds)) {
            if ([BotStatus.SUCCESS, BotStatus.FAILED].includes(cloudBotStatus)) return;
            CloudBotsActions.updateCloudBotStatus(fixItCustomizationKey, BotStatus.IN_PROGRESS);
            isRemediationExecutionCompleted().then((isCompleted: boolean) => {
                if (!isCompleted) {
                    interval = setInterval(() => {
                        isRemediationExecutionCompleted().then((isCompleted: boolean) => {
                            if (isCompleted) {
                                clearInterval(interval);
                            }
                        });
                    }, 10000);
                    setIntervalId(interval);
                }
            });
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [cloudBotStatus, executionIds, fixItCustomizationKey, intervalId, isRemediationExecutionCompleted]);

    return {
        cloudBotRemediationData,
        cloudBotStatus,
        clearBotStatus
    };
};
