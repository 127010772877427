import React, { FC, useState } from 'react';
import { Stack, Typography } from 'common/design-system/components-v2';
import Switch from 'common/design-system/components-v2/Switch';
import { SwitchProps } from 'common/design-system/components-v2/Switch/Switch.types';
import { AzureConfigurationTypes } from '../azure/helpers/azure.interface';
import { ConfigWrapper } from '../azure/helpers/azure.styled';

interface OnboardingConfigurationWrapperProps { 
    dataAid?: string;
    title: string;
    description: string;
    isEnabled: boolean;
    isDisabled?: boolean;
    isSwitchHidden?: boolean;
    onChange?: (value: boolean, type: AzureConfigurationTypes) => void;
    switchProps?: SwitchProps;
    children?: React.ReactNode;
    type: AzureConfigurationTypes
}

export const ConfigurationWrapper:FC<OnboardingConfigurationWrapperProps> = ({ title, description, children, isEnabled, onChange, isDisabled = false, type, isSwitchHidden = false, dataAid })=>{
    const [isConfigActive, setIsConfigActive] = useState(isEnabled || false);
    const onChangeSwitch = ()=>{
        setIsConfigActive(!isConfigActive);
        if (onChange) onChange(!isConfigActive, type);
    };

    return(
        <Stack direction="row" fullWidth margin={[0,0,4,0]} data-aid={dataAid}>
            <ConfigWrapper>
                <Stack direction="column" fullWidth justifyContent="space-between" alignItems="flex-start" padding={5}>
                    <Stack direction="row" fullWidth justifyContent="space-between" alignItems="flex-start">
                        <Stack direction="column">
                            {title && <Stack direction="row" fullWidth padding={[0,0,1]}><Typography variant="body500" style={{ fontWeight: 600 }}>{title}</Typography></Stack>}
                            {description && <Typography variant="body">{description}</Typography>}
                        </Stack>
                        <Stack direction="row">
                            { !isSwitchHidden && <Switch label='' size='medium' checked={isEnabled} disabled={isDisabled} onChange={onChangeSwitch} /> }
                        </Stack>
                    </Stack>
                    { isConfigActive && (
                        <Stack direction="row" flexWrap fullWidth justifyContent="space-between" alignItems="flex-start">
                            {children}
                        </Stack>
                    )}
                </Stack>
            </ConfigWrapper>
        </Stack>
    );
};

export default ConfigurationWrapper;