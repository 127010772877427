import { CpCommonButton, 
    CpIcon, 
    CpModal, 
    CpModalBody, 
    CpModalFooter, 
    CpModalHeader, 
    CpTab, 
    CpTabs, 
    CpTextButton 
} from '@dome9/components/react/components';
import { FC } from 'react';
import { CftModalProps } from '../aws/helpers/aws.interface';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';


const CftModal:FC<CftModalProps> = ({ open, onClose, title, cfts })=>{
    const { t } = useTranslation();
    return (
        <CpModal size='lg' open={open} backdropClose={true}>
            <CpModalHeader onCpClose={onClose}>
                {title}
            </CpModalHeader>
            <CpModalBody>
                <CpTabs>
                    {cfts?.map((cft, i) => {
                        const { fileName, repoUrl, type, content, downloadName } = cft;
                        return (
                            <CpTab key={`${type}-${i}`} tab-header={type}>
                                <pre 
                                    className='text-body aws-onboarding-account-modal-cft'
                                    data-aid={fileName}
                                >{content}</pre>
                                {
                                    fileName &&
                                    <CpTextButton
                                        data-aid='download-cft-button'
                                        onClick={() => {
                                            fileDownload(content, fileName);
                                        }}
                                        className='aws-onboarding-account-modal-download'>
                                        <CpIcon
                                            icon='download'
                                            className='aws-onboarding-account-modal-download-icon'
                                        />
                                        {downloadName || t('ON_BOARDING.ACCOUNT.DOwNLOAD_CFT')}
                                    </CpTextButton>
                                }
                                {repoUrl && (
                                    <a href={repoUrl} target='_blank' rel='noreferrer'>
                                        <CpTextButton className='aws-onboarding-account-modal-download'>
                                            <CpIcon
                                                icon='github'
                                                className='aws-onboarding-account-modal-download-icon'
                                            />
                                            {t('ON_BOARDING.ACCOUNT.GO_TO_GITHUB')}
                                        </CpTextButton>
                                    </a>
                                )}
                            </CpTab>
                        );
                    })}
                </CpTabs>
            </CpModalBody>
            <CpModalFooter>
                <CpCommonButton
                    data-aid='close-cft-modal'
                    onClick={onClose}
                >
                    {t('ON_BOARDING.ACCOUNT.CLOSE')}
                </CpCommonButton>
            </CpModalFooter>
        </CpModal>);

};

export default CftModal;