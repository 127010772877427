import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import EmptyState from 'common/components/EmptyState/EmptyState';
import { getEnvsNamespace } from './initialize.i18n';
import EnvironmentsStyled from './Environments.styled';
import { ButtonGroup, Stack } from 'common/design-system/components-v2';
import EnvironmentsTable from './components/EnvironmentsTable/EnvironmentsTable';
import EnvironmentsFilterPanel from './components/EnvironmentsFilterPanel/EnvironmentsFilterPanel';
import useEnvironmentsTable from 'common/hooks/useEnvironmentsTable';
import { getUserService } from 'common/interface/services';
import { changeUrl } from 'common/utils/http';
import { UserRolesTypes } from 'common/enum/UserRoles';
import { Pages } from 'common/enum/Pages';
import { filterDataByFilters, Filters } from './utils/filterDataByFilters';
import { IOrganizationalUnitAllChildrens, getOrganizationalUnitService } from 'common/interface/data_services';
import { EnvironmentTab, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import { IRowNode } from 'ag-grid-community';
import { IEnvironment } from 'common/interface/environmentsTable';

const envTypeParam = 'envType';

const Environments: React.FunctionComponent = () => {
    const { params, changeParam } = useReactRouterQuery();
    const tabsData = React.useMemo<EnvironmentTab[]>(() => {
        return EnvironmentsTableRegistry.getTabs();
    } , []);

    const { t } = useTranslation(getEnvsNamespace('table'));
    const [allOus, setAllOus] = React.useState<IOrganizationalUnitAllChildrens[]>([]);
    const [selectedEnvType, setSelectedEnvType] = React.useState<string>(() => {
        const selectedTab = params[envTypeParam];
        if (selectedTab && tabsData.find((tab) => tab.id === selectedTab)) {
            return selectedTab;
        }
        return tabsData[0].id;
    
    });
    const [activeFilters, setActiveFilters] = React.useState<Filters>({});

    const { isLoading, fetchingData, environmetsData, setSelectedTab } = useEnvironmentsTable();

    React.useEffect(() => {
        getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithAllChildrens(true).then((res) => {
            setAllOus(res);
        });
    }, []);

    React.useEffect(() => {
        setSelectedTab(selectedEnvType);
    }, [setSelectedTab, selectedEnvType]);

    const tabData = React.useMemo(() => tabsData.find((tab) => tab.id === selectedEnvType) || tabsData[0], [selectedEnvType, tabsData]);

    const filteredDataByTabs = React.useMemo(() => {
        if (!selectedEnvType || !tabData) return [];
        if (!tabData.defaultFilters) return environmetsData;
        return filterDataByFilters(allOus, environmetsData, tabData.defaultFilters);
    }, [environmetsData, selectedEnvType, allOus, tabData]);

    const onFilterChange = React.useCallback(async (filters: Filters) => {
        setActiveFilters(filters);
    }, []);

    const getFilteredData = () => {
        return filterDataByFilters(allOus, filteredDataByTabs, activeFilters);
    };

    const tableId = `environments_main-table_tab-${selectedEnvType}`;

    const onEnvTypeChange = (value: string) => {
        changeParam(envTypeParam, value);
        setSelectedEnvType(value);
    };

    const doesExternalFilterPass = React.useCallback((node: IRowNode<IEnvironment>) => {
        if (!node.data) return false;
        return filterDataByFilters(allOus, [node.data], activeFilters).length > 0;
    }, [activeFilters, allOus]);

    if (isLoading) {
        return (
            <EnvironmentsStyled.LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </EnvironmentsStyled.LoaderWrapper>
        );
    }

    if (environmetsData.length === 0) {
        const allowedToOnboard = getUserService().hasAnyPermission([UserRolesTypes.SUPER_USER, UserRolesTypes.ONBOARDING_PERMITTED]);

        if (allowedToOnboard) {
            changeUrl(`/${Pages.CloudOnboarding}`);
            return null;
        }

        return ( // This is case for user that has no permissions to onboard
            <EmptyState
                iconSize={64}
                iconName='document'
                label={t('HAS_NO_DATA.TITLE')}
                description={t('HAS_NO_DATA.DESCRIPTION')}
            />
        );
    }

    return (
        <Stack fullHeight fullWidth padding={4}>
            <Stack fullHeight>
                <Stack spacing={5} key={`${selectedEnvType}-${environmetsData.length}-filter-panel`}>
                    <ButtonGroup
                        options={tabsData.map((tab) => ({ label: tab.label, value: tab.id }))}
                        value={selectedEnvType}
                        onChange={onEnvTypeChange}
                    />
                    <EnvironmentsFilterPanel
                        tableId={tableId}
                        onFilterChange={onFilterChange}
                        tableData={filteredDataByTabs}
                        tabData={tabData}
                    />
                </Stack>
                <EnvironmentsTable
                    getFilteredData={getFilteredData}
                    data={filteredDataByTabs}
                    allCloudAccountsCount={filteredDataByTabs.length}
                    fetchingData={fetchingData}
                    pageSize={50}
                    tableId={tableId}
                    tabData={tabData}
                    activeFitlers={activeFilters}
                    doesExternalFilterPass={doesExternalFilterPass}
                />
            </Stack>
        </Stack>
    );
};

export default Environments;
