import { get, isEmpty } from 'lodash';
import { ITimeRange } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import {
    IFilterDetails,
    IFindingFilters,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { EMPTY_STRING, OPERATORS } from 'common/consts/GeneralConsts';
import { ColumnApi } from 'ag-grid-community';
import { FindingsTableRegistry } from 'common/module_interface/intelligence/Findings/FindingsTableRegistry';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';
import { GSL_CLAUSES } from 'common/module_interface/intelligence/Gsl/GslService.const';
import { isNil, isString } from 'common/utils/helpFunctions';

interface IQueryFilters {
    queryFilter: string,
    mitreFilter: string,
    mitreLikeFilter: string
}

const updateMitreLikeFilters = (inputField: string, filters: string[], value: string) => {
    if (inputField === EMPTY_STRING) {
        filters.push(`(${FindingFields.mitreDict} = '')`);
    } else {
        filters.push(value);
    }
};

/**
 * The function generates a like filter string from the tactic and technique filters.
 * @param filterValuesMap - A map of filters and their values
 * @returns A like filter string from the tactic and technique filters.
 */
const getMitreLikeFilter = (filterValuesMap: Map<string, string[]>) => {
    let mitreLikeFilter = EMPTY_STRING;
    const mitreTactic: string[] | undefined = filterValuesMap.get(FindingFields.mitreTactic);
    const mitreTechnique: string[] | undefined = filterValuesMap.get(FindingFields.mitreTechnique);
    const mitreLikeFilters: string[] = [];
    if (mitreTactic && mitreTechnique) {
        // if both tactic and technique filters are present, create a filter for each combination of tactic and technique
        for (const tactic of mitreTactic) {
            for (const technique of mitreTechnique) {
                const tacticValue = tactic.replaceAll('\'', '\\\'');
                const techniqueValue = technique.replaceAll('\'', '\\\'');
                updateMitreLikeFilters(tacticValue, mitreLikeFilters, `(${FindingFields.mitreDict}['${tacticValue}'] like '%${techniqueValue}%')`);
            }
        }
        // remove duplicate filters
        const uniqueMitreLikeFilters = mitreLikeFilters.reduce((accumulator: string[], currentValue: string) => {
            if (!accumulator.includes(currentValue)) {
                accumulator.push(currentValue);
            }
            return accumulator;
        }, []);
        mitreLikeFilter = uniqueMitreLikeFilters.join(` ${OPERATORS.OR} `) ?? EMPTY_STRING;
    } else if (mitreTactic) {
        // if only tactic filter is present, create a filter for each tactic
        for (const tactic of mitreTactic) {
            const tacticValue = tactic.replaceAll('\'', '\\\'');
            updateMitreLikeFilters(tacticValue, mitreLikeFilters, `(${FindingFields.mitreDict}['${tacticValue}'] like '%%')`);
        }
        mitreLikeFilter = mitreLikeFilters.join(` ${OPERATORS.OR} `) ?? EMPTY_STRING;
    } else if (mitreTechnique) {
        // if only technique filter is present, create a filter for each technique
        for (const technique of mitreTechnique) {
            const techniqueValue = technique.replaceAll('\'', '\\\'');
            updateMitreLikeFilters(techniqueValue, mitreLikeFilters, `(${FindingFields.mitreDict} like '%${techniqueValue}%')`);
        }
        mitreLikeFilter = mitreLikeFilters.join(` ${OPERATORS.OR} `) ?? EMPTY_STRING;
    }
    return mitreLikeFilter;
};

export const filterValuesToRequestQueryFilters = (filterValues?: IFilterDetails, mitreFields?: string[]): IQueryFilters => {
    if (isEmpty(filterValues)) return {
        queryFilter: EMPTY_STRING,
        mitreFilter: EMPTY_STRING,
        mitreLikeFilter: EMPTY_STRING
    };

    const filterValuesMap = new Map<string, string[]>();
    filterValues?.fields?.forEach((filter: { name: string; value: any; }) => {
        const name = filter.name;
        const value = filter.value;
        if (filterValuesMap.has(name)) {
            filterValuesMap.get(name)?.push(value);
        } else {
            filterValuesMap.set(name, [value]);
        }
    });

    const filters: string[] = [];
    const mitreFilters: string[] = [];
    filterValuesMap.forEach((values, key) => {
        const currFilterEntries = values.map((value: any) => {
            // replace single quote (') with an escaped single quote (\\')
            if(isString(value)) {
                value = value.replaceAll('\'', '\\\'');
            }
            return `${key} = '${value}'`;
        });
        if (mitreFields?.includes(key)) {
            mitreFilters.push(`(${currFilterEntries.join(` ${OPERATORS.OR} `)})`);
        } else {
            filters.push(`(${currFilterEntries.join(` ${OPERATORS.OR} `)})`);
        }
    });
    const queryFilter = filters.join(` ${OPERATORS.AND} `) ?? EMPTY_STRING;
    const mitreFilter = mitreFilters.join(` ${OPERATORS.AND} `) ?? EMPTY_STRING;
    const mitreLikeFilter = getMitreLikeFilter(filterValuesMap);
    return { queryFilter, mitreFilter, mitreLikeFilter };
};

export const freeTextToGslFormat = (columnApi?: ColumnApi, freeText?: string): string | undefined => {
    if (!freeText || (freeText.length < 3)) {
        return EMPTY_STRING;
    }
    // replace single quote (') with an escaped single quote (\\')
    freeText = freeText.replaceAll('\'', '\\\'');

    const freeTextFieldQueries = columnApi?.getColumns()?.filter(column => {
        const colDef = column?.getColDef();
        return get(colDef, 'freeTextField', false);
    }).map(column => {
        const colDef = column?.getColDef();
        return `${colDef.field} like '%${freeText}%'`;
    });

    const freeTextFilterString = freeTextFieldQueries?.join(` ${OPERATORS.OR} `);
    return freeTextFilterString ? `(${freeTextFilterString})` : EMPTY_STRING;
};

export const dateTimeFilter = (filterValues: IFilterDetails | undefined): ITimeRange | undefined => {
    const dateFilter = filterValues?.creationTime;
    if (!dateFilter) {
        // default time frame of 30 days
        const now = new Date();
        return {
            start: now.setDate(now.getDate() - 30).toString(),
            end: new Date().getTime().toString(),
        };
    }
    return {
        start: new Date(dateFilter.from).getTime().toString(),
        end: new Date(dateFilter.to).getTime().toString()
    };
};

export const getFilterFacetFields = (filters?: IProtectedAssetFilter[]): string[] => {
    return filters?.filter((filterType: IProtectedAssetFilter) => {
        const filterInfo = FindingsTableRegistry.getFilterById(filterType.id);
        const isMitreField = filterInfo?.filterProps?.isMitreField ?? false;
        return filterType.isField && !isMitreField;
    }).map((filterType: IProtectedAssetFilter) => filterType.id) ?? [];
};

export const getMitreFilterFacetFields = (filters?: IProtectedAssetFilter[]): string[] => {
    return filters?.filter((filterType: IProtectedAssetFilter) => {
        const filterInfo = FindingsTableRegistry.getFilterById(filterType.id);
        const isMitreField = filterInfo?.filterProps?.isMitreField ?? false;
        return filterType.isField && isMitreField;
    })?.map((filterType: IProtectedAssetFilter) => filterType.id) ?? [];
};

export const convertSourceTypesToFilter = (sourceTypes?: string[]): string => {
    if (!sourceTypes || isEmpty(sourceTypes)) {
        return EMPTY_STRING;
    }
    const filterString = sourceTypes?.map(type => `${FindingFields.origin}='${type}'`).join(` ${OPERATORS.OR} `);
    return `(${filterString})`;
};

export const computeFilterString = (params: IFindingFilters): string => {
    const {
        originsFilter,
        queryFilter,
        freeTextFilter,
        isArchiveView,
    } = params;
    const archiveViewFilter: string | undefined = isNil(isArchiveView) ? undefined: `(${FindingFields.isArchived} = ${isArchiveView ? 'true' : 'false'})`;
    const filterString: string = [originsFilter, archiveViewFilter, queryFilter, freeTextFilter].filter(filter => !!filter).join(` ${OPERATORS.AND} `);
    const where: string = !isEmpty(filterString) ? GSL_CLAUSES.WHERE : EMPTY_STRING;
    return `${where} ${filterString}`.trim();
};
