import React, { JSX, useCallback, useEffect, useState } from 'react';
import { RiskDashboardStyled } from '../RiskDashboard.styled';
import { HighRiskStyled } from './HighRiskAssets.styled';
import RiskLevelDottedHeader from '../TopHeaders/RiskLevelDottedHeader';
import { IValueCount, LoadingState, SortOrder } from 'common/interface/general';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IAssetWidgetInfo } from '../RiskDashborad.interface';
import SingleAssetWidget from 'common/components/AssetWidget/SingleAssetWidget';
import {
    IAssetWidgetContentInfo,
    ILevelsWidgetData,
    IWidgetLevelInfo, ITipProps
} from 'common/components/AssetWidget/AssetWidget.interface';
import i18n from 'i18next';
import { changeUrl } from 'common/utils/http';
import { ERM_URLS, I18nRiskNamespace } from '../../../../consts';
import {
    isAfDataSecurity,
    mappingCriticalHigh,
} from '../../../../RiskManagement.utils';
import { createLevelsSummaryData } from 'common/components/AssetWidget/Utils';
import {
    getCvesResponse,
    getDataSensitivityResponse,
    getIamExposureResponse,
    getNetworkExposureResponse, getSecretsResponse
} from './HighRiskAssets.datasource';
import { useTranslation } from 'react-i18next';
import {
    getRiskLevels,
    IRiskLevelInfo, RiskLevelEnum,
} from 'common/module_interface/RiskManagement/RiskLevel';
import AssetTwinWidgets from 'common/components/AssetWidget/AssetTwinWidgets';
import { IGeneralLevelsCounters } from 'common/consts/levels';
import {
    getCvesFilterFields,
    getDataSensitivityFilterFields,
    getIamExposureFilterFields,
    getNetworkExposureFilterFields, getSecretsFilterFields
} from '../RiskDashboard.datasource';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

const EMPTY_CVES_WIDGET_INFO = () => {
    return {
        widgetData: createCvesWidgetData(),
        loadingState: LoadingState.IS_LOADING,
    };
};

const EMPTY_SECRETS_WIDGET_INFO = () => {
    return {
        widgetData: createSecretsWidgetData(),
        loadingState: LoadingState.IS_LOADING,
    };
};


const EMPTY_PUBLIC_NETWORK_WIDGET_INFO = () => {
    return {
        widgetData: createPublicNetworkWidgetData(),
        loadingState: LoadingState.IS_LOADING,
    };
};

const EMPTY_PUBLIC_IAM_WIDGET_INFO = () => {
    return {
        widgetData: createPublicIamWidgetData(),
        loadingState: LoadingState.IS_LOADING,
    };
};

const EMPTY_DATA_SENSITIVITY_WIDGET_INFO = () => {
    return {
        widgetData: createDataSensitivityWidgetData(),
        loadingState: LoadingState.IS_LOADING,
    };
};

const createCvesWidgetData = (riskScore?: IValueCount[], filterValues?: IFiltersValues): ILevelsWidgetData => {
    const { criticalCount, highCount } = mappingCriticalHigh(riskScore || null);
    const riskAssetsCount: number = criticalCount + highCount;
    return {
        title: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.CVES.TITLE', { ns: I18nRiskNamespace }),
        icon: 'cve',
        helpInfoText: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.CVES.HELP', { ns: I18nRiskNamespace }),
        onClick: riskAssetsCount ? () => getErmUrlsService().goToProtectedAssetsTable(getCvesFilterFields(filterValues)) : null,
        ...createLevelsSummaryData({ high: highCount, critical: criticalCount, highCriticalAssetsCount: riskAssetsCount }),
    };
};

const createSecretsWidgetData = (riskScore?: IValueCount[], filterValues?: IFiltersValues): ILevelsWidgetData => {
    const { criticalCount, highCount } = mappingCriticalHigh(riskScore || null);
    const riskAssetsCount: number = criticalCount + highCount;
    return {
        title: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.SECRETS.TITLE', { ns: I18nRiskNamespace }),
        icon: 'key',
        helpInfoText: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.SECRETS.HELP', { ns: I18nRiskNamespace }),
        onClick: riskAssetsCount ? () => getErmUrlsService().goToProtectedAssetsTable(getSecretsFilterFields(filterValues)) : null,
        ...createLevelsSummaryData({ high: highCount, critical: criticalCount, highCriticalAssetsCount: riskAssetsCount }),
    };
};

const createPublicNetworkWidgetData = (riskScore?: IValueCount[], totalAssets?: number, filterValues?: IFiltersValues): ILevelsWidgetData => {
    const { criticalCount, highCount } = mappingCriticalHigh(riskScore || null);
    const riskAssetsCount: number = criticalCount + highCount;
    return {
        title: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.PUBLIC.NETWORK', { ns: I18nRiskNamespace }),
        icon: 'web',
        helpInfoText: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.PUBLIC.HELP', { ns: I18nRiskNamespace }),
        onClick: riskAssetsCount ? () => getErmUrlsService().goToProtectedAssetsTable(getNetworkExposureFilterFields(filterValues)) : null,
        ...createLevelsSummaryData({
            high: highCount,
            critical: criticalCount,
            highCriticalAssetsCount: riskAssetsCount,
            totalAssets: riskAssetsCount ? totalAssets ?? 0 : undefined,
        }),
    };
};

const createPublicIamWidgetData = (riskScore?: IValueCount[], totalAssets?: number, filterValues?: IFiltersValues): ILevelsWidgetData => {
    const { criticalCount, highCount } = mappingCriticalHigh(riskScore || null);
    const riskAssetsCount: number = criticalCount + highCount;
    return {
        title: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.PUBLIC.IAM', { ns: I18nRiskNamespace }),
        icon: 'user',
        helpInfoText: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.PUBLIC.HELP', { ns: I18nRiskNamespace }),
        onClick: riskAssetsCount ? () => getErmUrlsService().goToProtectedAssetsTable(getIamExposureFilterFields(filterValues)) : null,
        ...createLevelsSummaryData({
            high: highCount,
            critical: criticalCount,
            highCriticalAssetsCount: riskAssetsCount,
            totalAssets: riskAssetsCount ? totalAssets ?? 0 : undefined,
        }),
    };
};

const createDataSensitivityWidgetData = (riskScore?: IValueCount[], totalAssets?: number, filterValues?: IFiltersValues): ILevelsWidgetData => {
    const { criticalCount, highCount } = mappingCriticalHigh(riskScore || null);
    const riskAssetsCount: number = criticalCount + highCount;
    return {
        title: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.DATA_SENSITIVITY.TITLE', { ns: I18nRiskNamespace }),
        icon: 'dataSensitivity',
        helpInfoText: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.DATA_SENSITIVITY.HELP', { ns: I18nRiskNamespace }),
        onClick: riskAssetsCount ? () => getErmUrlsService().goToProtectedAssetsTable(getDataSensitivityFilterFields(filterValues)) : null,
        ...createLevelsSummaryData({
            high: highCount,
            critical: criticalCount,
            highCriticalAssetsCount: riskAssetsCount,
            totalAssets: riskAssetsCount ? totalAssets ?? 0 : undefined,
        }),
    };
};

const createLevelInfos = (counters: IGeneralLevelsCounters | undefined): IWidgetLevelInfo[] => {
    const levels: IWidgetLevelInfo[] = [];
    if (counters !== undefined) {
        const topRiskLevels = getRiskLevels(SortOrder.descending, RiskLevelEnum.high);
        topRiskLevels.forEach((riskLevelInfo: IRiskLevelInfo) => {
            levels.push({
                text: riskLevelInfo.displayText,
                count: counters[riskLevelInfo.key as keyof IGeneralLevelsCounters] ?? 0,
                color: riskLevelInfo.color,
            });
        });
    }
    return levels;
};

const createHighRiskAssetWidget = (widgetInfo: IAssetWidgetInfo): JSX.Element => {
    return (<SingleAssetWidget
        descendingLevelInfos={createLevelInfos(widgetInfo.widgetData.levelCounters)}
        key={widgetInfo.widgetData.title}
        widget={widgetInfo.widgetData}
        loadingState={widgetInfo.loadingState}
        tipProps={widgetInfo.tipProps} />);
};

const createAssetTwinWidget = (
    title: string,
    leftWidgetInfo: IAssetWidgetInfo,
    rightWidgetInfo: IAssetWidgetInfo,
    helpInfoText?: string,
    tipProps?: ITipProps,
): JSX.Element => {

    const leftContentInfo: IAssetWidgetContentInfo = {
        descendingLevelInfos: createLevelInfos(leftWidgetInfo.widgetData.levelCounters),
        widget: leftWidgetInfo.widgetData,
        loadingState: leftWidgetInfo.loadingState,
    };
    const rightContentInfo: IAssetWidgetContentInfo = {
        descendingLevelInfos: createLevelInfos(rightWidgetInfo.widgetData.levelCounters),
        widget: rightWidgetInfo.widgetData,
        loadingState: rightWidgetInfo.loadingState,
    };
    return (
        <AssetTwinWidgets
            key={title}
            title={title}
            leftContentInfo={leftContentInfo}
            rightContentInfo={rightContentInfo}
            helpInfoText={helpInfoText}
            tipProps={tipProps}/>
    );
};

const addToScores = (targetScores: IValueCount[], origScores: IValueCount[]) => {
    origScores.forEach((origScore: IValueCount) => {
        const targetScore = targetScores.find(score => (score.value === origScore.value));
        if (targetScore) {
            targetScore.count += origScore.count;
        } else {
            targetScores.push({ ...origScore });
        }
    });
};

const HighRiskAssets: React.FC<{ filterValues?: IFiltersValues, totalWidgetInfo: IAssetWidgetInfo }> = ({ filterValues, totalWidgetInfo }) => {
    const [cvesWidgetInfo, setCvesWidgetInfo] = useState<IAssetWidgetInfo>(EMPTY_CVES_WIDGET_INFO());
    const [secretsWidgetInfo, setSecretsWidgetInfo] = useState<IAssetWidgetInfo>(EMPTY_SECRETS_WIDGET_INFO());
    const [publicNetworkWidgetInfo, setPublicNetworkWidgetInfo] = useState<IAssetWidgetInfo>(EMPTY_PUBLIC_NETWORK_WIDGET_INFO());
    const [publicIamWidgetInfo, setPublicIamWidgetInfo] = useState<IAssetWidgetInfo>(EMPTY_PUBLIC_IAM_WIDGET_INFO());
    const [dataSensitivityWidgetInfo, setDataSensitivityWidgetInfo] = useState<IAssetWidgetInfo>(EMPTY_DATA_SENSITIVITY_WIDGET_INFO());
    const { t } = useTranslation();

    const updateData = useCallback(() => {
        if (!filterValues) {
            return;
        }

        setCvesWidgetInfo(EMPTY_CVES_WIDGET_INFO());
        setSecretsWidgetInfo(EMPTY_SECRETS_WIDGET_INFO());
        setPublicNetworkWidgetInfo(EMPTY_PUBLIC_NETWORK_WIDGET_INFO());
        setPublicIamWidgetInfo(EMPTY_PUBLIC_IAM_WIDGET_INFO());
        setDataSensitivityWidgetInfo(EMPTY_DATA_SENSITIVITY_WIDGET_INFO());

        getCvesResponse(filterValues).then(response => {
            setCvesWidgetInfo({
                widgetData: createCvesWidgetData(response?.aggregations?.riskScore, filterValues),
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
        }).catch(() => {
            setCvesWidgetInfo({
                widgetData: createCvesWidgetData(),
                loadingState: LoadingState.LOADING_FAILED,
            });
        });

        getSecretsResponse(filterValues).then(response => {
            setSecretsWidgetInfo({
                widgetData: createSecretsWidgetData(response?.aggregations?.riskScore, filterValues),
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
        }).catch(() => {
            setSecretsWidgetInfo({
                widgetData: createSecretsWidgetData(),
                loadingState: LoadingState.LOADING_FAILED,
            });
        });

        getDataSensitivityResponse(filterValues).then(response => {
            setDataSensitivityWidgetInfo({
                widgetData: createDataSensitivityWidgetData(response?.aggregations?.riskScore, response?.totalCount, filterValues),
                loadingState: LoadingState.LOADING_SUCCEEDED,
                tipProps: isAfDataSecurity() ? {
                    text: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.DATA_SENSITIVITY.TOOLTIP', { ns: I18nRiskNamespace }),
                    onClick: () => changeUrl(ERM_URLS.DATA_SECURITY_DASHBOARD),
                } : undefined,
            });
        }).catch(() => {
            setDataSensitivityWidgetInfo({
                widgetData: createDataSensitivityWidgetData(),
                loadingState: LoadingState.LOADING_FAILED,
            });
        });

        getNetworkExposureResponse(filterValues).then(response => {
            const publicRiskScore: IValueCount[] = [];
            addToScores(publicRiskScore, response?.aggregations?.riskScore || []);
            const publicTotalAssets = response?.totalCount;
            setPublicNetworkWidgetInfo({
                widgetData: createPublicNetworkWidgetData(publicRiskScore, publicTotalAssets, filterValues),
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
        }).catch(() => {
            setPublicNetworkWidgetInfo({
                widgetData: createPublicNetworkWidgetData(),
                loadingState: LoadingState.LOADING_FAILED,
            });
        });

        getIamExposureResponse(filterValues).then(response => {
            const publicRiskScore: IValueCount[] = [];
            addToScores(publicRiskScore, response?.aggregations?.riskScore || []);
            const publicTotalAssets = response?.totalCount;
            setPublicIamWidgetInfo({
                widgetData: createPublicIamWidgetData(publicRiskScore, publicTotalAssets, filterValues),
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
        }).catch(() => {
            setPublicIamWidgetInfo({
                widgetData: createPublicIamWidgetData(),
                loadingState: LoadingState.LOADING_FAILED,
            });
        });
    }, [filterValues]);


    const getAssetWidgetComponents = useCallback((): JSX.Element[] => {
        return [
            createHighRiskAssetWidget(totalWidgetInfo),
            createAssetTwinWidget(
                i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.PUBLIC.TITLE', { ns: I18nRiskNamespace }),
                publicNetworkWidgetInfo,
                publicIamWidgetInfo,
                i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.PUBLIC.HELP', { ns: I18nRiskNamespace }),
                {
                    text: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.PUBLIC.TOOLTIP', { ns: I18nRiskNamespace }),
                    onClick: () =>
                        changeUrl(ERM_URLS.EXPOSURE_DASHBOARD)
                }),
            createHighRiskAssetWidget(secretsWidgetInfo),
            createHighRiskAssetWidget(cvesWidgetInfo),
            createHighRiskAssetWidget(dataSensitivityWidgetInfo),
        ];
    }, [totalWidgetInfo, publicNetworkWidgetInfo, publicIamWidgetInfo, secretsWidgetInfo, cvesWidgetInfo, dataSensitivityWidgetInfo]);

    useEffect(() => {
        if (filterValues) {
            updateData();
        }
    }, [filterValues, updateData]);
    return (
        <RiskDashboardStyled.TopSection>
            <RiskLevelDottedHeader title={t('RISK_MANAGEMENT.DASHBOARD.ASSETS_AT_HIGH_RISK',{ ns: I18nRiskNamespace })} />
            <HighRiskStyled.TopDiv>
                {getAssetWidgetComponents()}
            </HighRiskStyled.TopDiv>
        </RiskDashboardStyled.TopSection>
    );
};

export default HighRiskAssets;
