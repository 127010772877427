import React from 'react';
import ConfigurationListManager, { IConfigurationListManagerProps
} from './Componenets/ConfigurationListManager/ConfigurationListManager';
import { CONFIGURATION_DRAWER_VIEWS, INTEGRATIONS_IDS } from 'common/module_interface/settings/integrations/consts';
import SnsSystemComponent, { ISnsConfigurationProps } from './Componenets/SnsAudit/SnsSystemComponent';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';

export const INTEGRATIONS_DRAWER_VIEWS = {
    LIST_VIEW: 'listView'
};

const getSNSDrawerComponent = () => {
    const SNSDrawerComponent : React.FC<ISnsConfigurationProps> = ({ configurations }) => {
        return <div style={{ width: '100%' }}>
            <SnsSystemComponent configurations={configurations} />
        </div>;
    };

    return SNSDrawerComponent;
};

const getTenableDrawerComponent = () => {
    const TenableIntegrationProperties = getConfigurationByIntegrationID(INTEGRATIONS_IDS.TENABLE);
    return TenableIntegrationProperties?.configurationComponent;
};

const getConfigurationByIntegrationID = (integrationTypeID: string) => {
    const allIntegrations = IntegrationsRegistry.getIntegrationsDefinitions();
    return allIntegrations.find(integration => {
        return integration.id === integrationTypeID;
    });
};

const getGenericDrawerComponent = (integrationTypeID : string) => {
    const configurationComponent = getConfigurationByIntegrationID(integrationTypeID)?.configurationComponent;
    const onDelete = getConfigurationByIntegrationID(integrationTypeID)?.onDelete;
    const onSave = getConfigurationByIntegrationID(integrationTypeID)?.onSave;
    const testFormatType = getConfigurationByIntegrationID(integrationTypeID)?.testFormatType;
    const SpecialMessage = getConfigurationByIntegrationID(integrationTypeID)?.SpecialMessage;

    if(configurationComponent) {
        const GenericDrawerComponent: React.FC<IConfigurationListManagerProps> = ({ onConfigurationChangeCallBack,mode,singleConfigurationId }) => {
            return <div style={{ width: '100%' }}>
                <ConfigurationListManager
                    singleConfigurationId={singleConfigurationId}
                    mode={mode}
                    configurationComponent={configurationComponent}
                    integrationTypeID={integrationTypeID}
                    onConfigurationChangeCallBack={onConfigurationChangeCallBack}
                    onConfigurationDelete ={onDelete}
                    onConfigurationSaved ={onSave}
                    testFormatType={testFormatType}
                    SpecialMessage={SpecialMessage}
                />
            </div>;
        };
        return GenericDrawerComponent;
    }
};

const configurationsListViews : any = {
    'snsAudit': getSNSDrawerComponent,
    'tenable': getTenableDrawerComponent,
    'GENERIC': getGenericDrawerComponent,
};

const drawerViews: any = {
    [CONFIGURATION_DRAWER_VIEWS.LIST_VIEW]: configurationsListViews,
};

export const getDrawerComponentByIntegrationID = ( view:string, type: string) => {
    return drawerViews[view][type] && drawerViews[view][type]() || drawerViews[view]['GENERIC'](type);
};


