import React, { useEffect } from 'react';
import './Login.scss';
import { LegalNotesFooter } from '../../../common/components/Forms/LegalNotesFooter';
import LoginForm from './LoginForm';
import CheckpointLogoHeader from 'common/components/Forms/CheckpointLogoHeader';
import LoginInfinityPanel from './LoginInfinityPanel';
import { cleanDoubleReturnUrl } from 'common/utils/http';
import IncidentMessage from 'platform/components/IncidentMessage/IncidentMessage';

export const Login: React.FC<{}> = () => {

    useEffect(() => {
        cleanDoubleReturnUrl();
    }, []);
    return (
        <div className='login-page'>
            <IncidentMessage />
            <CheckpointLogoHeader />
            <div className='login-page__body'>
                <LoginForm />
                <LoginInfinityPanel />
            </div>
            <LegalNotesFooter />
        </div>
    );
};
