import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { TextField } from '@dome9/berries/react-components';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { FormValues } from './GSuiteModal.types';
import { ModalsProps } from '..';
import { Stack, Typography } from 'common/design-system/components-v2';
import { useGetGcp } from '../../../reducer/hooks/useGetGcp';
import { getGcpEnvNamespace } from '../../../initialize.i18n';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import { getCloudAccountsService } from 'common/interface/data_services';
import { useAddGSuite } from '../../../reducer/hooks/useAddGSuite';
import Styled from '../../../../AwsEnvironment/components/Modals/RemoveAccount/RemoveAccount.styled';
import { EMAIL_REGEX } from 'common/utils/http';


const GSuiteModal: React.FC<ModalsProps> = ({ closeModal, gcpId }) => {
    const { data: gcpData } = useGetGcp(gcpId);
    const { t } = useTranslation(getGcpEnvNamespace('gcp'));

    const inputValidations: { [input: string]: RegisterOptions } = React.useMemo(() => ({
        email: {
            required: t('gcpPage.modals.gsuite.fields.email.validation.required'),
            validate: (value: string) => {
                const isValid = new RegExp(EMAIL_REGEX).test(value);
                return isValid || t('gcpPage.modals.gsuite.fields.email.validation.validate');
            },
        },
        domain: {
            required: t('gcpPage.modals.gsuite.fields.domain.validation.required')
        },
    }), [t]);

    const handleOnUpdateSuccess = () => {
        getCloudAccountsService().clearCache();
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('gcpPage.modals.gsuite.onSubmit.success'),
            text: '',
        });
        onClose();
    };
    const handleOnUpdateError = (error: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('gcpPage.modals.gsuite.onSubmit.error'),
            text: error.message,
        });
    };

    const { addGSuite, isLoading: isUpdateLoading } = useAddGSuite({ onError: handleOnUpdateError, onSuccess: handleOnUpdateSuccess });
    const isLoading = isUpdateLoading; 

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm<FormValues>({});
    
    const submitUpdate = async (data: FormValues) => {
        const { email, domain } = data;
        console.log('email', email);
        addGSuite(gcpId, { cloudAccountId: gcpData?.account.id || '', email, domain });
    };

    const onClose = () => {
        closeModal();
    };

    return (
        <ConfirmationModal
            title={t('gcpPage.modals.gsuite.header')}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => submitUpdate(data))}
            isLoading={isLoading}
            submitBtnText={t('gcpPage.modals.gsuite.buttons.add')}
        >
            <Stack spacing={2}>
                <Typography>{t('gcpPage.modals.gsuite.text.subtitle')}</Typography>
                <Typography>{t('gcpPage.modals.gsuite.text.stepsA.title')}</Typography>
                <Stack padding={[0, 0, 0, 8]}>
                    <Styled.List>
                        {[...Array(7).keys()].map((n) => (
                            <li key={n}>
                                <Typography>
                                    <Trans
                                        i18nKey={`${getGcpEnvNamespace('gcp')}:gcpPage.modals.gsuite.text.stepsA.${n + 1}`}
                                        components={{ b: <b />, rgs: <Styled.AsComponent /> }}
                                    />
                                </Typography>
                            </li>
                        ))}
                    </Styled.List>
                </Stack>
                <Typography>{t('gcpPage.modals.gsuite.text.stepsB.title')}</Typography>
                <Stack padding={[0, 0, 0, 8]}>
                    <Styled.List>
                        {[...Array(17).keys()].map((n) => (
                            <li key={n}>
                                <Typography>
                                    <Trans
                                        i18nKey={`${getGcpEnvNamespace('gcp')}:gcpPage.modals.gsuite.text.stepsB.${n + 1}`}
                                        components={{ b: <b />, rgs: <Styled.AsComponent /> }}
                                    />
                                </Typography>
                            </li>
                        ))}
                    </Styled.List>
                </Stack>
                <Typography>{t('gcpPage.modals.gsuite.text.stepsC.title')}</Typography>
            </Stack>
            
            <Stack spacing={1}>
                <Typography variant='bodyLg'>{t('gcpPage.modals.gsuite.fields.email.label')}</Typography>
                <Controller
                    name='email'
                    rules={inputValidations['email']}
                    control={control}
                    render={({ field }) => (
                        <Stack>
                            <TextField
                                {...field}
                                placeholder={t('gcpPage.modals.gsuite.fields.email.placeholder')}
                                onClear={() => setValue('email', '')}
                                helper={errors['email'] ? errors['email']['message'] : ''}
                                state={errors['email'] ? 'error' : 'success'}
                            />
                        </Stack>
                    )}
                />
            </Stack>
            <Stack spacing={1}>
                <Typography variant='bodyLg'>{t('gcpPage.modals.gsuite.fields.domain.label')}</Typography>
                <Controller
                    name='domain'
                    rules={inputValidations['domain']}
                    control={control}
                    render={({ field }) => (
                        <Stack>
                            <TextField
                                {...field}
                                placeholder={t('gcpPage.modals.gsuite.fields.domain.placeholder')}
                                onClear={() => setValue('domain', '')}
                                helper={errors['domain'] ? errors['domain']['message'] : ''}
                                state={errors['domain'] ? 'error' : 'success'}
                            />
                        </Stack>
                    )}
                />
            </Stack>
        </ConfirmationModal>
    );
};

export default GSuiteModal;
