import React, { useCallback, useEffect, useState } from 'react';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import SimpleDottedHeader from '../TopHeaders/SimpleDottedHeader';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../consts';
import { WAFStyled } from './WAF.styled';
import WAFImpact from './WAFImpact/WAFImpact';
import WAFAssetsByRisk from './WAFAssetsByRisk/WAFAssetsByRisk';
import WAFCoverageGauge from './WAFCoverageGauge/WAFCoverageGauge';
import { fetchWafWidgetsData } from './WAF.datasource';
import { LoadingState } from 'common/interface/general';
import { IWafWidgetsData } from './WAF.interface';

const WAF: React.FC<{ filterValues?: IFiltersValues }> = ({ filterValues }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);
    const [widgetsData, setWidgetsData] = useState<IWafWidgetsData>();
    const [isWafEnabled, setIsWafEnabled] = useState<boolean>(false);

    const fetchWAFWidgetsData = useCallback(async () => {
        try {
            const response: IWafWidgetsData = await fetchWafWidgetsData(filterValues);
            setIsWafEnabled(response.isWafEnabled);
            setWidgetsData(response);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        } catch (error) {
            setLoadingState(LoadingState.LOADING_FAILED);
        }
    }, [filterValues]);

    useEffect(() => {
        setLoadingState(LoadingState.IS_LOADING);
        void fetchWAFWidgetsData();
    }, [fetchWAFWidgetsData, filterValues]);

    return (isWafEnabled &&
        <WAFStyled.TopDiv>
            <SimpleDottedHeader title={t('RISK_MANAGEMENT.DASHBOARD.WAF.SECTION_TITLE')} />
            <WAFStyled.Content direction={'row'}>
                <WAFCoverageGauge loadingState={loadingState} 
                    coverageData={widgetsData?.coverage} 
                    filterValues={filterValues}/>
                <WAFAssetsByRisk
                    loadingState={loadingState}
                    riskDistribution={widgetsData?.wafProtectedRiskDistribution}
                    riskChange={widgetsData?.wafProtectedRiskChange}
                    filterValues={filterValues}
                />
                <WAFImpact loadingState={loadingState} 
                    impactData={widgetsData?.wafImpactInfo} 
                    filterValues={filterValues}/>
            </WAFStyled.Content>
        </WAFStyled.TopDiv>
    );
};

export default WAF;
