import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { Datasource } from 'common/components/ProtectedAssets/datasource';
import { createErmDataSourceConfig } from '../../../ProtectedAsset/ErmDataUtils';
import { ASSET_FIELD_NAMES } from '../../../../consts';
import {
    DASHBOARD_CACHE_CONFIG
} from '../../../../RiskManagement.utils';
import {
    getCvesFilterFields,
    getDataSensitivityFilterFields,
    getIamExposureFilterFields,
    getNetworkExposureFilterFields, getSecretsFilterFields
} from '../RiskDashboard.datasource';

export const getCvesResponse = (filterValues: IFiltersValues) => {
    const datasource = new Datasource(createErmDataSourceConfig(
        {
            fields: getCvesFilterFields(filterValues),
        },
        0,
        undefined,
        DASHBOARD_CACHE_CONFIG,
    ));
    return datasource.getAdHokDataFromServer({
        optionalAggregationFields: [ASSET_FIELD_NAMES.riskScore],
        aggregationFields: [],
    });
};
export const getSecretsResponse = (filterValues: IFiltersValues) => {
    const datasource = new Datasource(createErmDataSourceConfig(
        {
            fields: getSecretsFilterFields(filterValues),
        },
        0,
        undefined,
        DASHBOARD_CACHE_CONFIG,
    ));
    return datasource.getAdHokDataFromServer({
        optionalAggregationFields: [ASSET_FIELD_NAMES.riskScore],
        aggregationFields: [],
    });
};
export const getNetworkExposureResponse = (filterValues: IFiltersValues) => {
    const datasource = new Datasource(createErmDataSourceConfig(
        { fields: getNetworkExposureFilterFields(filterValues) },
        0,
        undefined,
        DASHBOARD_CACHE_CONFIG,
    ));
    return datasource.getAdHokDataFromServer({
        optionalAggregationFields: [ASSET_FIELD_NAMES.riskScore],
        aggregationFields: [],
    });
};
export const getIamExposureResponse = (filterValues: IFiltersValues) => {
    const datasource = new Datasource(createErmDataSourceConfig(
        { fields: getIamExposureFilterFields(filterValues) },
        0,
        undefined,
        DASHBOARD_CACHE_CONFIG,
    ));
    return datasource.getAdHokDataFromServer({
        optionalAggregationFields: [ASSET_FIELD_NAMES.riskScore],
        aggregationFields: [],
    });
};
export const getDataSensitivityResponse = (filterValues: IFiltersValues) => {
    const datasource = new Datasource(createErmDataSourceConfig(
        {
            fields: getDataSensitivityFilterFields(filterValues),
        },
        0,
        undefined,
        DASHBOARD_CACHE_CONFIG,
    ));
    return datasource.getAdHokDataFromServer({
        optionalAggregationFields: [ASSET_FIELD_NAMES.riskScore],
        aggregationFields: [],
    });
};