import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  width: 100%;
  grid-gap: 20px;
  height: 330px;
`;

const IssuesBySeverityDiv = styled.div`
  height: 100%;
`;

const TopIssuesDiv = styled.div`
  flex-grow: 1;
  height: 100%;
`;

export const IssuesStyled = {
    TopDiv,
    IssuesBySeverityDiv,
    TopIssuesDiv,
};
