import React, { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import i18n from 'common/services/translations/translations';
import { getOrganizationalUnitValue } from 'common/components/ProtectedAssets/Renderers/ColumnDisplayValueGetters';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';

const OrganizationalUnitCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const { value, data } = params;
    const [displayValue, setDisplayValue] = useState(i18n.t('COMMON.PROTECTED_ASSETS_TABLE.LOADING') as string);
    useEffect(() => {
        async function updateDisplayValue() {
            const result = await getOrganizationalUnitValue(data);
            if (result === null) {
                setDisplayValue(i18n.t('COMMON.PROTECTED_ASSETS_TABLE.NO_DATA'));
            } else {
                setDisplayValue(result);
            }
        }

        updateDisplayValue();
    }, [data]);

    if (data.isGrouped && value === undefined) {
        return (<></>);
    }

    CellToClipboardHandler(params, String(displayValue));
    return <div>{displayValue}</div>;
};

export default OrganizationalUnitCellRenderer;