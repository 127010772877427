import { ColorsChip } from '@dome9/berries/react-components';
import { InsightResponse } from '../Insights.intertface';
import { FindingSeverityServerEnum, getFindingSeverityInfo } from 'common/consts/FindingSeverity';

export function sortByInsightSeverity(insightA: InsightResponse, insightB: InsightResponse): 1 | 0 | -1 {
    const aLevel: number = getFindingSeverityInfo(insightA.severity)?.level ?? 0;
    const bLevel: number = getFindingSeverityInfo(insightB.severity)?.level ?? 0;

    return bLevel === aLevel ?
        0 :
        bLevel > aLevel ?
            1 :
            -1;
}

export function getChipSeverity(insight: InsightResponse): ColorsChip {
    switch (insight.severity) {
        case FindingSeverityServerEnum.Critical:
            return 'severity-critical';
        case FindingSeverityServerEnum.High:
            return 'severity-high';
        case FindingSeverityServerEnum.Medium:
            return 'severity-medium';
        case FindingSeverityServerEnum.Low:
            return 'severity-low';
        default:
            return 'severity-high';
    }
}