import { AwsEnvironmentData } from 'common/module_interface/assets/AwsEnvironment';

export interface AwsState {
    awsEvnironments: {
        [awsId: string]: {
            data?: AwsEnvironmentData;
            loading?: boolean;
            error?: boolean;
            deleted?: boolean;
            notificationCountWarning?: number;
        };
    };
}

export const AwsInitialState: AwsState = {
    awsEvnironments: {},
};