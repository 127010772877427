import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import { ICloudAccount, IGetAccountRequest, IPermissionObject, IUser, IUserRole } from 'common/interface/user';

interface UserState {
    user?: IUser;
    username: string;
    roles: IUserRole[];
    account?: IGetAccountRequest;
    rootUser?: string;
    roleName?: string;
    userError?: string;
    hasRole: boolean;
    cloudAccount?: ICloudAccount;
    permissions: IPermissionObject;
    permissionsRequest: IPermissionObject;
    firstName?: string;
    lastName?: string;
}

const initialState: UserState = {
    username: '',
    roles: [],
    hasRole: false,
    permissions: { all: true },
    permissionsRequest: { all: true },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setRoles: (state: UserState, action: PayloadAction<IUserRole[]>) => {
            state.roles = action.payload;
        },
        setRoleName: (state: UserState, action: PayloadAction<string>) => {
            state.roleName = action.payload;
        },
        setRootUser: (state: UserState, action: PayloadAction<string>) => {
            state.rootUser = action.payload;
        },
        setUserError: (state: UserState, action: PayloadAction<string>) => {
            state.userError = action.payload;
        },
        setUser: (state: UserState, action: PayloadAction<IUser>) => {
            state.user = action.payload;
        },
        setUsername: (state: UserState, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setHasRole: (state: UserState) => {
            state.hasRole = !!(state.roleName && state.rootUser && state.rootUser !== state.username);
        },
        setAccount: (state: UserState, action: PayloadAction<IGetAccountRequest>) => {
            state.account = action.payload;
        },
        setCloudAccount: (state: UserState, action: PayloadAction<ICloudAccount>) => {
            state.cloudAccount = action.payload;
        },
        setPermissions: (state: UserState, action: PayloadAction<IPermissionObject>) => {
            state.permissions = action.payload;
        },
        setPermissionRequest: (state: UserState, action: PayloadAction<IPermissionObject>) => {
            state.permissionsRequest = action.payload;
        },
        setIsRelatedToMsp: (state: UserState, action: PayloadAction<boolean>) => {
            state.permissions.isRelatedToMsp = action.payload;
        },
        clearUser: () => initialState,
        setUserPersonalName: (state: UserState, action: PayloadAction<{ firstname: string, lastname: string }>) => {
            state.firstName = action.payload.firstname;
            state.lastName = action.payload.lastname;
        },
    },
});

export const {
    setRoles,
    setRoleName,
    setRootUser,
    setUser,
    setUsername,
    setHasRole,
    setAccount,
    setCloudAccount,
    setPermissions,
    setPermissionRequest,
    clearUser,
    setUserPersonalName,
    setIsRelatedToMsp,
} = userSlice.actions;

export const getUsername = (state: RootState): string | undefined => state.user.hasRole ? `${state.user.roleName} @ ${state.user.username}` : state.user.user?.name;
export const getBasicUserName = (state: RootState): string | undefined => {
    return state.user.hasRole ? state.user.username : state.user.user?.name;
};
export const userHasRole = (state: RootState): boolean => state.user.hasRole;
export const getRootUsername = (state: RootState): string | undefined => state.user.rootUser;
export const getImageUrl = (state: RootState): string | undefined => state.user.account?.iconUrl;
export const getRoles = (state: RootState): IUserRole[] => state.user.roles;
export const getPermissions = (state: RootState): IPermissionObject => state.user.permissions;

export default userSlice.reducer;
