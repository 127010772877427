import { IApplicationReducer } from 'common/interface/redux';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { APPLICATION_REDUCERS, REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import AzureIntelligenceOnboarding from './AzureIntelligenceOnboarding';
import AzureIntelligenceOnboardingReducer from './AzureIntelligenceOnboarding.reducer';

const azureIntelligenceOnboardingPageRoute = () => {
    return {
        condition: true,
        component: AzureIntelligenceOnboarding,
        path: '/intelligence/azure-onboarding/:accountName?/:subscription?/:type?/:cloudAccountId?'
    };
};

export const initializeAzureIntelligenceOnboardingPages = () => {
    const reducers: IApplicationReducer[] = [
        { name: 'azureIntelligenceOnboarding', reducer: AzureIntelligenceOnboardingReducer, isBlackList: true },
    ];

    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');

    globalAddinContainer.add(REACT_PAGE_ROUTE, [{
        id: 'Azure Intelligence Onboarding Page',
        content: azureIntelligenceOnboardingPageRoute
    }]);
};
