import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { ermTrans, isAfIssueExclusions } from '../../../../RiskManagement.utils';
import { IIssueExclusionProps } from '../../../IssueExclusions/IssueExclusions.interface';
import {
    createEmptyIssueExclusionUpdateProps, openIssueExclusionEditorDrawer,
} from '../../../IssueExclusions/IssueExclusions.utils';
import { colors } from 'common/design-system/theme/colors';
import { TOXIC_GROUP_FIELDS } from '../../Issues.consts';

export function getIssuesTableActions(): ITableAction<IIssue>[] {
    const excludeAction: ITableAction<IIssue> = {
        id: 'exclude',
        name: ermTrans('ISSUES.TABLE.ACTIONS.EXCLUDE.TITLE'),

        callback: (issues) => {
            if (issues.length === 1) {
                const issue = issues[0];
                const issueExclusionProps: IIssueExclusionProps = getExclusionUpdateProps(issue);
                openIssueExclusionEditorDrawer(issueExclusionProps);
            }
        },

        isActionDisabled: (issues) => (issues.length !== 1),

        buttonProps: {
            iconProps: { name: 'exclude' }
        },
        position: 10,
    };

    return [
        {
            id: 'show-toxic-combination',
            name: ermTrans('ISSUES.TABLE.ACTIONS.OVERVIEW'),
            callback: (_, params) => {
                params.columnApi.setRowGroupColumns(TOXIC_GROUP_FIELDS);
            },
            isActionDisabled: () => false,
            buttonProps: {
                iconProps: { name: 'presetRowGroup', size: 16 },
                style: { borderColor: colors.grey[60] },
                variant: 'outlined',
                size: 'medium',
            },
        },
        ...(isAfIssueExclusions() ? [excludeAction] : []),
    ];
}

function getExclusionUpdateProps(issue: IIssue): IIssueExclusionProps {
    return {
        ...createEmptyIssueExclusionUpdateProps(),
        name: ermTrans('ISSUES.TABLE.ACTIONS.EXCLUDE.EXCLUSION_NAME', { ruleTitle: issue.ruleTitle }),
        ruleIds: issue.ruleId ? [issue.ruleId] : [],
        entityId: issue.entityExternalId,
        description: ermTrans('ISSUES.TABLE.ACTIONS.EXCLUDE.EXCLUSION_DESCRIPTION', { issueId: issue.id }),
        envIds: issue.environmentId ? [issue.environmentId] : [],
    };
}