export const CIEM_ASSET_LABELS = {
    THIRD_PARTY: 'ThirdParty'
};

export const I18nIdentityNamespace = 'ciemIdentity';

export const IMPACT_SCORE_COLORS = ['bg-standout-lighter theme theme-dark', 'bg-standout-light theme theme-dark', 'bg-standout-default theme theme-dark', 'bg-standout-dark theme theme-dark', 'bg-standout-dark theme theme-dark'];

export enum VIEW_TYPES {
    ORIGINAL = 'original',
    CONSOLIDATED = 'consolidated'
}

export enum POLICY_TYPES {
    ORIGINAL = 'original',
    EFFECTIVE = 'effective',
    SUGGESTED = 'suggested'
}


export interface DetailsPanePropsItem {
    name: string,
    showTooltip: boolean,
    tooltipText: string,
    chipColor: string,
    chipTextOnHover: string | undefined,
    chipIcon: string | undefined,
    showChip: boolean,
    value: string
}

export interface entityConnection {
    name: string,
    icon: string,
    link: string,
}

export interface additionalEntityConnectionDescription {
    text: string,
    tooltip: string
}

export const itemExample = {
    name: 'Permissions status',
    showTooltip: true,
    tooltipText: 'Indicates if the policy is over permissive',
    chipColor: '',
    chipTextOnHover: 'Policy is overpermissive',
    chipIcon: 'many-keys',
    value: 'Overpermissive - Critical',
    showChip: true
};

export const itemExample2 = {
    name: 'IAM sensitivity',
    showTooltip: true,
    tooltipText: 'A score of 0-100 represents the sensitivity of the IAM permissions granted',
    chipColor: IMPACT_SCORE_COLORS[Math.floor(67 / 25)],
    chipTextOnHover: undefined,
    chipIcon: 'iam-impact',
    value: '67',
    showChip: true
};

export const itemExample3 = {
    name: 'Type',
    showTooltip: false,
    tooltipText: 'Policy type',
    chipColor: '#888d94',
    chipTextOnHover: undefined,
    chipIcon: undefined,
    value: 'Identity-based policy (Customer Managed)',
    showChip: false
};

export const connectionExample = {
    connectionDescription: 'Policy available through trust relationship with:',
    showConnections: true,
    connections: [{ name: 'example2', icon: 'aws__Resource-Icons__Res_Security-Identity-and-Compliance__Res_48_Light__Res_AWS-Identity-Access-Management_Role_48_Light', link: '' },
        { name: 'example3', icon: 'aws__Resource-Icons__Res_Security-Identity-and-Compliance__Res_48_Light__Res_AWS-Identity-Access-Management_Role_48_Light', link: '' }
    ]
};

export const policyExample = {
    'Version': '2012-10-17',
    'Statement': [
        {
            'Action': [
                'kinesis:Describe*',
                'kinesis:List*'
            ],
            'Resource': [
                '*'
            ],
            'Effect': 'Allow',
            'Sid': 'CloudGuardGenerated0'
        },
        {
            'Action': [
                'glue:GetConnections',
                'glue:GetSecurityConfigurations'
            ],
            'Resource': [
                '*'
            ],
            'Effect': 'Allow',
            'Sid': 'CloudGuardGenerated1'
        }
    ]
};

export interface flatAction {
    'service': string,
    'effect': string,
    'action': string,
    'resource': string,
    'accessLevel'?: string | undefined,
    'description'?: string | undefined,
    'isDataAction': boolean
}


export const flatActionsExample: flatAction[] = [
    {
        'service': 'elasticloadbalancing',
        'effect': 'Allow',
        'action': 'elasticloadbalancing:DescribeLoadBalancers',
        'resource': '*',
        'accessLevel': 'Read_Cfg',
        'description': 'Grants permission to describe the specified the load balancers. If no load balancers are specified, the call describes all of your load balancers',
        'isDataAction': false
    },
    {
        'service': 'kinesis',
        'effect': 'Allow',
        'action': 'kinesis:Describe*',
        'resource': '*',
        'isDataAction': false
    },
    {
        'service': 'kinesis',
        'effect': 'Allow',
        'action': 'kinesis:List*',
        'resource': '*',
        'isDataAction': false
    },
    {
        'service': 'glue',
        'effect': 'Allow',
        'action': 'glue:GetConnections',
        'resource': '*',
        'accessLevel': 'Read_Cfg',
        'description': 'Grants permission to retrieve a list of connections',
        'isDataAction': false
    }
];
