import { getHttpService, getStoreService, IAccountService, ILicense } from 'common/interface/services';
import { AccountPlan } from 'common/enum/AccountPlan';
import { BillingProviders } from 'common/interface/user';

export class AccountService implements IAccountService {
    async getLicense(useCache = true): Promise<ILicense> {
        return await getHttpService().get<ILicense>({
            path: '/account/license', cachingConfig: {
                useCache,
            },
        });
    }

    async hasMspActivated():Promise<boolean>{
        const { state } = getStoreService().getReduxTools();
        const account = state.user.account;
        const MSP_FEATURE = 'msp';

        if(!account){
            return false;
        }

        const hasMsp = account.plan === AccountPlan.MSP
            || account.billingProvider === BillingProviders.MSP
            || (!!account.crossAccountIdentifier)
            || this.hasActiveFeature(MSP_FEATURE);


        return hasMsp;
    }

    hasActiveFeature(activeFeature:string):boolean{
        const { state } = getStoreService().getReduxTools();
        const accountActiveFeature:string[] = state.user.account.activeFeatures;

        activeFeature = activeFeature.toLowerCase();
        return accountActiveFeature
            .map(value => value.toLowerCase())
            .some(value => value === activeFeature);
    }

}