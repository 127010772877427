import { ISSUE_EXCLUSION_EDITOR_KEY } from './IssueExclusionEditor.consts';
import { initializeCustomFormDrawer } from 'common/erm-components/custom/CustomForm/CustomForm.initialize';
import { getCdrExclusionFormProps } from './IssueExclusionEditor.items';

export const initializeDrawerContent = () => {
    initializeCustomFormDrawer(ISSUE_EXCLUSION_EDITOR_KEY, getCdrExclusionFormProps);
};

export function initializeIssueExclusionEditor() {
    initializeDrawerContent();
}
