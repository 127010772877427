import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IIssueExclusion } from '../IssueExclusions.interface';
import { MultiOptionChipsComponent } from 'common/erm-components/custom/MultiChipCellContent/MultiOptionChipsComponent';
import { convertSeverityLevelsToKeys } from '../../Issues/IssueSeverity';
import { getAllSeverityOptions } from '../../../RiskManagement.options';

export const IssueExclusionSeveritiesCellRender: React.FC<ICellRendererParams<IIssueExclusion>> = (params) => {
    const exclusion: IIssueExclusion | undefined = params.data;
    if (!exclusion) {
        return null;
    }
    return <MultiOptionChipsComponent
        selectedValues={convertSeverityLevelsToKeys(exclusion.severities)}
        fetchAllOptions={ () => Promise.resolve(getAllSeverityOptions()) } />;
};
