import React, { useCallback, useEffect, useState } from 'react';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { LoadingState } from 'common/interface/general';
import i18n from 'i18next';
import { Stack, Typography } from 'common/design-system/components-v2';
import { ermTrans } from '../../../../../../RiskManagement.utils';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import { MalwareStyled } from './Malware.styled';
import { MalwareInfo } from './Malware.interface';
import MalwareTable from './MalwareTable/MalwareTable';
import { MALWARE_LIST } from './dataMock';

const Malware: React.FC<{ issue: IIssue, asset: IProtectedAssetViewModel, title: string, initiallyOpen?: boolean }>
    = ({ issue, asset }) => {
        const [loadingState, setLoadingState] = useState(LoadingState.IS_LOADING);
        const [data, setData] = useState<MalwareInfo[]>();

        const loadMalware = useCallback(async () => {
            setLoadingState(LoadingState.IS_LOADING);
            setData(undefined);
            try {
                // setData(await fetchMalware(issue, asset)); //uncomment when API will be ready
                setData(MALWARE_LIST);
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
            } catch (e) {
                setLoadingState(LoadingState.LOADING_FAILED);
            }
        },[]);

        useEffect(() => {
            if (issue && asset) {
                void loadMalware();
            }
        }, [issue, asset, loadMalware]);

        return (
            <Stack fullWidth>
                {loadingState === LoadingState.IS_LOADING &&
                    <MalwareStyled.LoaderDiv>
                        <FullSizeSpinner/>
                    </MalwareStyled.LoaderDiv>
                }
                {loadingState === LoadingState.LOADING_FAILED &&
                    <MalwareStyled.NoteDiv fullWidth fullHeight>
                        <Typography color={'alert'}>
                            {i18n.t('COMMON.ERROR_OCCURRED')}
                        </Typography>
                    </MalwareStyled.NoteDiv>
                }
                {loadingState === LoadingState.LOADING_SUCCEEDED && data &&
                    <Stack direction={'column'} fullWidth fullHeight>
                        {data.length > 0 ?
                            <MalwareStyled.TableDiv>
                                <MalwareTable malwareItems={data}/>
                            </MalwareStyled.TableDiv>
                            :
                            <MalwareStyled.NoteDiv>
                                <Typography>{ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.NO_DATA')}</Typography>
                            </MalwareStyled.NoteDiv>
                        }
                    </Stack>
                }
            </Stack>
        );
    };
export default Malware;