import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CpSearch, CpSecondaryMessage, CpToggleButton } from '@dome9/components/react/components';
import { Icon } from '@dome9/berries/react-components';
import './AWSOnboarding.scss';
import { useSelector } from 'react-redux';
import {
    getEnableIntelligence,
    getGotIntelligenceRulesetsFromServer,
    getIntelligenceFilterValue,
    getIntelligenceRulesets,
    getIntelligenceSelectedRulesets,
    setEnableIntelligence,
    setIntelligenceFilterValue,
    setIntelligenceSelectedRulesets,
} from './helpers/aws.reducer';
import { AgGridReact } from 'ag-grid-react';
import { RulesetRenderer } from './components/Components';
import { getIntelligenceRulesetsFromServerAndSaveToState } from './helpers/aws.actions';
import { Link } from 'react-router-dom';
import { getStoreService } from 'common/interface/services';
import { ColDef, GridApi } from 'ag-grid-enterprise';

const columnDefs: ColDef[] =
    [{
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerName: 'Name',
        tooltipField: 'name',
        minWidth: 300,
        cellRenderer: 'rulesetRenderer',
        filter: 'agMultiColumnFilter',
        filterValueGetter: (value: any) => value.data.name,
    },
    {
        tooltipField: 'description',
        headerName: 'Description',
        field: 'description',
        flex: 1,
        filter: 'agMultiColumnFilter',
    },
    {
        cellRenderer: 'linkRenderer',
        headerName: '',
        field: 'id',
        width: 50,
    }];

const AWSOnboardingIntelligence: FC = () => {
    const dispatch = getStoreService().dispatch;
    const { t } = useTranslation();
    const intelligenceRulesets = useSelector(getIntelligenceRulesets);
    const selectedIntelligenceRulesets = useSelector(getIntelligenceSelectedRulesets);
    const gotIntelligenceRulesets = useSelector(getGotIntelligenceRulesetsFromServer);
    const enableIntelligence = useSelector(getEnableIntelligence);
    const [gridApi, setGridApi] = useState<GridApi>();
    const filterValue = useSelector(getIntelligenceFilterValue);

    useEffect(() => {
        getIntelligenceRulesetsFromServerAndSaveToState();
    }, []);

    const rowChanged = (params: any) => {
        setGridApi(params.api);
        params.api.forEachNode((node: any) => {
            if (node.data) {
                node.setSelected(selectedIntelligenceRulesets.includes(node.data.id));
            }
        });
    };

    const LinkRenderer = (props: any) => {
        return (
            <Link to={`/magellan/ruleset/${props?.value}`} target='_blank' rel='noopener noreferrer'>
                <Icon name='externalLink' />
            </Link>
        );
    };

    function onEnableIntelligenceToggle(eventData: any) {
        dispatch(setEnableIntelligence(eventData.isOn));
    }

    function onFilterTextBoxChanged(ev: any) {
        dispatch(setIntelligenceFilterValue(ev.detail));
        gridApi?.setQuickFilter(ev.detail);
    }

    return (
        <div className='aws-onboarding-intelligence'>
            <div className='aws-onboarding-intelligence-header'>
                <div>
                    <div className='title-large'>{t('ON_BOARDING.INTELLIGENCE_ACTIVITY.HEADER')}</div>
                    <div className='title'>{t('ON_BOARDING.INTELLIGENCE_ACTIVITY.DESCRIPTION')}</div>
                    {enableIntelligence && (
                        <CpSecondaryMessage type='info'>
                            {t('ON_BOARDING.INTELLIGENCE_ACTIVITY.ENSURE_CLOUDTRAIL_ENABLED')}
                        </CpSecondaryMessage>
                    )}
                </div>
                <CpToggleButton
                    isOn={enableIntelligence}
                    className='toggle-button'
                    onClick={(event: any) => onEnableIntelligenceToggle(event.target)}
                />
            </div>
            <div className='table-container'>
                <CpSearch className='search-bar' onCpUpdate={onFilterTextBoxChanged} value={filterValue} />

                {gotIntelligenceRulesets && (
                    <>
                        <AgGridReact
                            enableBrowserTooltips={true}
                            components={{ linkRenderer: LinkRenderer, rulesetRenderer: RulesetRenderer }}
                            rowSelection='multiple'
                            groupDisplayType='groupRows'
                            groupDefaultExpanded={-1}
                            rowHeight={46}
                            className='ag-theme-alpine rulesets'
                            onGridReady={rowChanged}
                            onSelectionChanged={(event) => {
                                dispatch(
                                    setIntelligenceSelectedRulesets(
                                        event.api.getSelectedRows().map((ruleset) => ruleset.id),
                                    ),
                                );
                            }}
                            suppressRowClickSelection
                            columnDefs={columnDefs}
                            rowData={intelligenceRulesets}>
                        </AgGridReact>
                        {!enableIntelligence && <div className='disable-panel' />}
                    </>
                )}
            </div>
        </div>
    );
};

export default AWSOnboardingIntelligence;
