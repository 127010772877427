import React from 'react';
import { EventsModalOpenFrom, EventsModalProps } from 'common/module_interface/events/EventsModalsRegistry';
import { useTranslation } from 'react-i18next';
import { Message } from 'common/design-system/components-v2';
import { getEventsNamespace } from 'modules/events/initialize.i18n';
import { ComplianceFindingArchive } from 'modules/events/services/complianceFindingApi/complianceFindingApi.interface';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { useArchiveEvent } from 'modules/events/services/complianceFindingApi/hooks/useArchiveEvent';
import { useUnarchiveEvent } from 'modules/events/services/complianceFindingApi/hooks/useUnarchiveEvent';

const ArchiveAction: React.FC<EventsModalProps> = ({ closeModal, selectedRows, resetSelectedRows, requestNewData, openFrom, additionalData }) => {
    const { t } = useTranslation(getEventsNamespace('actions'));

    const isArchive = additionalData?.isArchive;
    const termPrefix = isArchive ? 'ARCHIVE' : 'UNARCHIVE';
    
    const onSuccess = (response: ComplianceFindingArchive.Response) => {
        if (response.failed) {
            getNotificationsService().addNotification({
                text: t(`${termPrefix}.NOTIFICATIONS.PARTIAL_ERROR`, { failedCount: response.failed, eventsCount: selectedRows.length }),
                type: NotificationType.WARNING
            });
        } else {
            getNotificationsService().addNotification({
                text: t(`${termPrefix}.NOTIFICATIONS.SUCCESS`, { count: selectedRows.length }),
                type: NotificationType.SUCCESS
            });
        }
        if (openFrom === EventsModalOpenFrom.TABLE && resetSelectedRows) {
            resetSelectedRows();
        }
        closeModal();
        requestNewData?.();
    };

    const onError = () => {
        getNotificationsService().addNotification({
            text: t(`${termPrefix}.NOTIFICATIONS.ERROR`, { count: selectedRows.length }),
            type: NotificationType.ERROR
        });
    };

    const { archiveEvents, isLoading: isArchiveLoading } = useArchiveEvent({ onSuccess, onError });
    const { unarchiveEvents, isLoading: isUnArchiveLoading } = useUnarchiveEvent({ onSuccess, onError });

    const onSubmit = () => {
        if (isArchive) {
            archiveEvents(selectedRows);
        } else {
            unarchiveEvents(selectedRows);
        }
    };

    return (
        <Message
            title={t(`${termPrefix}.TITLE`)}
            text={t(`${termPrefix}.TEXT`, { count: selectedRows.length })}
            submitBtnText={t(`${termPrefix}.BUTTONS.SUBMIT`, { count: selectedRows.length })}
            width='lg'
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={onSubmit}
            isLoading={isArchiveLoading || isUnArchiveLoading}
        />
    );
};

export default ArchiveAction;
