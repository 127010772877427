import React, { useEffect, useState } from 'react';
import PerimeterExposureComponent from '../PerimeterExposure/Components/PerimeterExposureComponent';
import { getAssetExposure } from '../PerimeterExposure/Services/PerimeterExposureHttpService';
import { AssetOverviewProps } from './AssetOverviewProps';

const AssetOverviewPerimeterExposure: React.FC<AssetOverviewProps> = (props: AssetOverviewProps) => {
    const { entity } = props;

    const [srl, setSrl] = useState<string | null>(null);

    useEffect(() => {
        setSrl(entity?.protectedAsset.srl ?? null);
    }, [entity?.cloudEntity, entity?.protectedAsset.srl]);

    return srl ?
        <div className="perimeter-exposure">
            <div className="flex items-center gap-5 mx-9 mt-9" >
                <div className="text-lg text-info-darkest opacity-75">Context Graph</div>
                <div className="flex-1 border-dashed border-b"></div>
            </div>
            <div className='mx-9 pb-9 h-[280px]'>
                <PerimeterExposureComponent
                    assetSrl={srl}
                    getGraphBySrl={getAssetExposure}
                    showFullScreenButton={true}/>
            </div>
        </div> :
        <span/>;
};

export default AssetOverviewPerimeterExposure;