export interface ICronAsObject{
    frequencyType: typeof FREQUENCY_TYPES[keyof typeof FREQUENCY_TYPES],
    selectedDay: number,
    selectedHour: number,
    selectedMinutes: number,
    selectedAMPM: 'AM'|'PM',
}
export const FREQUENCY_TYPES = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    CUSTOM: 'custom'
};

export const generateCronExpression = (cronObject:ICronAsObject) => {
    const cronArray: string[] = ['0','0','*','*','*','*','*'];
    const hours = Number(cronObject.selectedHour);
    const minutes = Number(cronObject.selectedMinutes);
    const { adjustedUtcHour,adjustedUtcMinutes,dayOffset } = convertLocalHourToUTCHour(hours,minutes,cronObject.selectedAMPM);
    cronArray[1]= adjustedUtcMinutes.toString();
    cronArray[2]= adjustedUtcHour.toString();
    let selectedDayValue = cronObject.selectedDay;
    switch (cronObject.frequencyType) {
        case FREQUENCY_TYPES.DAILY:
            cronArray[3] = '1/1';
            cronArray[5] = '?';
            break;
        case FREQUENCY_TYPES.WEEKLY:
            cronArray[3] = '?';
            selectedDayValue = +selectedDayValue + dayOffset;
            if (selectedDayValue > 7) {
                selectedDayValue = selectedDayValue % 7;
            } else if (selectedDayValue < 1) {
                selectedDayValue = 7 + (selectedDayValue % 7);
            }
            cronArray[5] = selectedDayValue.toString();
            break;
        case FREQUENCY_TYPES.MONTHLY:
            cronArray[3] = '1';
            cronArray[4] = '1/1';
            cronArray[5] = '?';
            break;
        default:
            break;
    }
    return cronArray.join(' ');
};
export const convertUTCHourToLocalHour = (utcHour: number, utcMinutes: number) => {
    // Create a new Date object with the current date and time
    const currentDate = new Date();

    // Get the current timezone offset in minutes
    const offsetInMinutes = currentDate.getTimezoneOffset() * -1;

    // Adjust the UTC hour for the timezone offset
    const fullHoursFromMinutes = offsetInMinutes > 0 ? Math.floor(offsetInMinutes / 60) : Math.ceil(offsetInMinutes / 60);

    let localHour = utcHour + fullHoursFromMinutes;
    let localMinutes = utcMinutes + offsetInMinutes % 60;

    // If the hour is less than 0 or greater than 24, it might need adjustment for the next or previous day
    let dayOffset = 0;
    if (Math.abs(localMinutes/60) >= 1) {
        localHour += fullHoursFromMinutes;
    }
    localMinutes = Math.abs(localMinutes % 60);
    if (localHour <= 0 || localHour === 24) {
        dayOffset = -1;
    } else if (localHour > 24) {
        dayOffset = 1;
    }

    const meridian = (localHour > 11 && localHour <= 24) ? 'PM' : 'AM';

    // Adjust the local hour for the offset and ensure it's within the 0-23 range
    const adjustedLocalHour = (localHour + 24) % 24;
    const adjustedLocalMinutes = (localMinutes + 60) % 60;

    // If it's 0, it should be adjusted to 12 AM (midnight)
    if (adjustedLocalHour === 0) {
        return { adjustedLocalHour: 12, adjustedLocalMinutes, dayOffset };
    }

    return { adjustedLocalHour, adjustedLocalMinutes, dayOffset, meridian };
};
export const convertLocalHourToUTCHour = (localHour:number, localMinutes:number,period:'AM'|'PM') =>{
    if (period === 'PM') {
        if(localHour !== 12) {
            localHour += 12;
        }
    }else{
        if(localHour === 12){
            localHour = 0;
        }
    }

    const currentDate = new Date();
    const offsetInMinutes = currentDate.getTimezoneOffset();

    // Convert local hour to UTC hour
    const fullHoursFromMinutes = offsetInMinutes > 0 ? Math.floor(offsetInMinutes / 60) : Math.ceil(offsetInMinutes / 60);
    let utcHour = localHour + fullHoursFromMinutes;
    let utcMinutes = localMinutes + offsetInMinutes % 60;
    let dayOffset = 0;
    if (Math.abs(utcMinutes/60) >= 1) {
        utcHour += fullHoursFromMinutes;
        utcMinutes = utcMinutes % 60;
    }
    if(utcHour < 0){
        dayOffset = -1;
    }else if(utcHour > 24){
        dayOffset = +1;
    }
    // Ensure the UTC hour is within the range [0, 23]
    const adjustedUtcHour = (utcHour + 24) % 24;
    const adjustedUtcMinutes = (utcMinutes + 60) % 60;

    return { adjustedUtcHour,adjustedUtcMinutes,dayOffset: +dayOffset };
};
export const revertCronExpression = (cronExpression : string) : ICronAsObject => {
    const cronArray = cronExpression.split(' ');
    const hours = Number(cronArray[2]);
    const minutes = Number(cronArray[1]);

    //Convert the cron from UTC to local time
    const localHourObj = convertUTCHourToLocalHour(hours,minutes);

    if(localHourObj.adjustedLocalHour > 12) {
        localHourObj.adjustedLocalHour = Math.abs(localHourObj.adjustedLocalHour - 12);
    }
    let frequencyOption = FREQUENCY_TYPES.CUSTOM;
    if (cronArray[4] === '1/1'){
        frequencyOption = FREQUENCY_TYPES.MONTHLY;
    } else if(cronArray[3] === '1/1') {
        frequencyOption = FREQUENCY_TYPES.DAILY;
    }else{
        frequencyOption = FREQUENCY_TYPES.WEEKLY;
    }

    return {
        frequencyType: frequencyOption,
        selectedDay: +cronArray[5] + localHourObj.dayOffset,
        selectedHour: localHourObj.adjustedLocalHour,
        selectedMinutes: Number(localHourObj.adjustedLocalMinutes),
        selectedAMPM: localHourObj.meridian as 'AM' | 'PM'
    };
};