import React, { useEffect, useState } from 'react';
import { getHttpService } from 'common/interface/services';
import {
    FilterField,
    FindingsAlertType,
    FindingsDataSource,
    IFindingPaganationViewModel,
    ISearchWithCustomAggregationsRequestViewModel,
} from 'common/components/Findings/Findings.interface';
import { createEventsDataCounters } from 'common/components/AssetWidget/Utils';
import SingleAssetWidget from 'common/components/AssetWidget/SingleAssetWidget';
import { ILevelsWidgetData, IWidgetLevelInfo } from 'common/components/AssetWidget/AssetWidget.interface';
import dayjs from 'dayjs';
import { IValueCount, LoadingState, SortOrder } from 'common/interface/general';
import { FINDINGS_AGGREGATIONS_URL } from 'common/module_interface/events/EventsConsts';
import { getValueCount } from 'common/utils/helpFunctions';
import {
    FindingSeverityEnum,
    FindingSeverityServerEnum, getFindingSeverities,
    IFindingSeverityInfo
} from 'common/consts/FindingSeverity';
import { IGeneralLevelsCounters } from 'common/consts/levels';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

const getSeverityListAggregation = async (alertType: FindingsAlertType, filterFields: FilterField[]): Promise<IValueCount[]> => {
    const fields: FilterField[] = [...filterFields, {
        name: 'alertType',
        value: `${alertType}`,
        isHidden: true,
    }];
    const data: ISearchWithCustomAggregationsRequestViewModel = {
        filter: {
            creationTime: {
                from: new Date(0).toISOString(),
                to: dayjs().startOf('minute').toISOString(),
            },
            fields,
        },
        pageSize: 0,
        dataSource: FindingsDataSource.FINDING,
        aggregations: ['severity'],
    };
    return getHttpService().post<IFindingPaganationViewModel>(FINDINGS_AGGREGATIONS_URL, {
        data,
    },{ cachingConfig: { useCache: true } }).then(response => response.aggregations?.severity);
};

const EventsWidget: React.FC<{
    title: string, icon: AllIconsName, onClick?: Function | null, alertType: FindingsAlertType,
    filterFields: FilterField[]
}> = ({ title, icon, onClick, alertType, filterFields }) => {
    const [severityList, setSeverityList] = useState<IValueCount[] | null>([]);
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);

    const createLevelInfos = (counters: IGeneralLevelsCounters | undefined): IWidgetLevelInfo[] => {
        const levels: IWidgetLevelInfo[] = [];
        if (counters !== undefined) {
            getFindingSeverities(SortOrder.descending, FindingSeverityEnum.high).forEach((severityInfo: IFindingSeverityInfo) => {
                levels.push({
                    text: severityInfo.displayText,
                    count: counters[severityInfo.key as keyof IGeneralLevelsCounters] ?? 0,
                    color: severityInfo.color,
                });
            });
        }
        return levels;
    };

    useEffect(() => {
        setSeverityList([]);
        setLoadingState(LoadingState.IS_LOADING);
        getSeverityListAggregation(alertType, filterFields).then(severity => {
            setSeverityList(severity);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        }).catch(() => {
            setLoadingState(LoadingState.LOADING_FAILED);
        });
    }, [alertType, filterFields]);

    const createPostureFindingsWidgetData = (criticalCount: number, highCount: number): ILevelsWidgetData => {
        return {
            title,
            icon,
            onClick: (criticalCount + highCount) ? onClick : null,
            ...createEventsDataCounters(highCount, criticalCount),
        };
    };

    const criticalCount = severityList ? getValueCount(severityList, FindingSeverityServerEnum.Critical) : 0;
    const highCount = severityList ? getValueCount(severityList, FindingSeverityServerEnum.High) : 0;
    const postureFindingsWidgetData: ILevelsWidgetData = createPostureFindingsWidgetData(criticalCount, highCount);
    return <SingleAssetWidget descendingLevelInfos={createLevelInfos(postureFindingsWidgetData.levelCounters)} widget={postureFindingsWidgetData} loadingState={loadingState}/>;
};

export default EventsWidget;
