import { getHttpService, getStoreService } from 'common/interface/services';
import { changeUrl, generalApiError } from 'common/utils/http';
import {
    AzureCommandCloudShellRequest,
    AzureConfiguration,
    AzureConfigurationTypes,
    azureCreateAccountRequest,
    azureOrgOnboardingRequest
} from './azure.interface';
import { clearAzureOnboardingState, setAllSubscriptionIds, setAzureCommandCloudShell, setAzureOnboardingScript } from './azure.reducer';
import { getOrganizationalUnitService, IOrganizationalUnit } from 'common/interface/data_services';

export const exitWizard = () => {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(clearAzureOnboardingState());
    changeUrl('/cloud-account');
};

export const getAllSubscriptionIdsFromServer = async () => {
    const { dispatch } = getStoreService();
    const data = await getHttpService().get<{ subscriptionId: string; }[]>('AzureCloudAccount');
    const allSubscriptionIds = data.map((account) => account.subscriptionId);
    dispatch(setAllSubscriptionIds(allSubscriptionIds));
};

export const checkOnboardingStatus = async (tenantId: string, managementGroupId: string) => {
    return await getHttpService().get<{ isOnboarded: boolean, tenantId: string, reason?: string | null }>(`azure-organization-management/is-onboarded/${tenantId}/${managementGroupId}`);
};

export const createAzureCloudAccount = async (data: azureCreateAccountRequest): Promise<void> => {
    return await getHttpService().request(
        'AzureCloudAccount',
        { data, method: 'POST' },
        {},
        generalApiError
    );
};

export const getOnboardingScriptFromServer = async () => {
    const { dispatch } = getStoreService();
    const data = await getHttpService().get<string>('AzureCloudAccount/OnboardingExecutionPreview');
    dispatch(setAzureOnboardingScript(data));
};

export const getCommandCloudShellFromServer = async (data: AzureCommandCloudShellRequest) => {
    const { dispatch } = getStoreService();
    const command: string = await getHttpService().request(
        'AzureCloudAccount/OnboardingExecutionCommand',
        { data, method: 'POST' },
        {});

    dispatch(setAzureCommandCloudShell(command));
};

export const getIsSubscriptionIdOnboarded = async (subscriptionId: string) => {
    return await getHttpService().get<{ isOnboarded: boolean }>(`AzureCloudAccount/isOnboarded/${subscriptionId}`);
};


export const onboardAzureOrg = async (data: azureOrgOnboardingRequest): Promise<void> => {
    return await getHttpService().request(
        'azure-organization-management',
        { data, method: 'POST' },
        {},
        generalApiError
    );
};

export const getOrganizationalUnitsNames = async (): Promise<string[]> => {
    const orgNames: string[] = [];
    const organizationalUnitsView: IOrganizationalUnit = await getOrganizationalUnitService().getOrganizationalUnitsView();
    function getName(obj: IOrganizationalUnit) {
        if(obj.name) {
            orgNames.push(obj.name.toLowerCase());
        }
        // If the current node has a "children" property, recurse on each child
        if (Array.isArray(obj.children) && obj.children?.length) {
            obj.children.forEach((child: IOrganizationalUnit) => getName(child));
        }
    }
    getName(organizationalUnitsView);
    return orgNames;
};
export const filterActiveAzureConfigurations = (configurations: AzureConfiguration): AzureConfiguration =>{
    return Object.keys(configurations)
        .reduce((acc: Partial<AzureConfiguration>, key) => {
            const config = configurations[key as AzureConfigurationTypes];
            if (key !== AzureConfigurationTypes.cspm) {
                // Check if 'isEnabled' exists and is explicitly false
                if (Object.prototype.hasOwnProperty.call(config, 'isEnabled') && typeof config?.isEnabled === 'boolean' && !config.isEnabled) {
                    acc[key as AzureConfigurationTypes] = { isEnabled: false };
                } else {
                    acc[key as AzureConfigurationTypes] = config;
                }
            }
            return acc;
        }, {} as Partial<AzureConfiguration>) as AzureConfiguration;
};
