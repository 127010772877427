import initializeFindingsTable from './FindingsTable/FindingsTable.initialize';
import { initializeFindingOverview } from './FindingOverview/FindingOverview.initialize';
import initializeCloudBots from './CloudBots/CloudBots.initialize';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import CdrEventsPage from './CdrEvents/CdrEventsPage';
import { getUserService } from 'common/interface/services';
import { CDR_EVENTS_ACTIVE_FEATURE } from '../ActiveFeatures/ActiveFeatures.consts';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from '../initialize.i18n';

const initializeAllFindingsTable = () => {
    const t = i18n.getFixedT(null, i18nIntelligenceNamespace);
    EventsTableRegistry.addTabs([
        {
            id: 'cdr',
            position: 20,
            permission: () => getUserService().hasPermission([CDR_EVENTS_ACTIVE_FEATURE.key]),
            content: {
                type: 'componentTab',
                id: 'cdr',
                label: t('NAVIGATION_MENU.THREAT.TITLE'),
                component: CdrEventsPage,
            }
        }
    ]);
};

export default function initializeFindings() {
    initializeFindingsTable();
    initializeFindingOverview();
    initializeCloudBots();
    initializeAllFindingsTable();
}
