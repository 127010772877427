import { UserRolesTypes } from 'common/enum/UserRoles';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IColumnUsageDef } from 'common/interface/general';
import { PATH_PROTECTED_ASSETS_PAGE_TABLE_COLUMNS } from 'common/module_interface/assets/ProtectedAssets.consts';

const shownColumnIds = ['entity', 'region', 'type', 'entityId', 'platform', 'organizationalUnit', 'environment'];
const hiddenColumnIds = [
    'labels',
    'isPublic',
    'isRunning',
    'lastRunningDate',
    'cluster',
    'instanceType',
    'publicIpAddress',
    'privateIpAddress',
    'name',
    'digest',
    'riskScore',
    'staticWebsiteEnable',
    'mfaDelete',
    'status',
    'isProtected',
    'operatingSystem',
    'dbType',
    'namespace',
    'kubeletVersion',
    'ports',
    'registry',
    'repository',
    'imageTag',
    'imageId',
    'imageName',
    'businessPriority',
    'cves',
    'threats',
    'secrets',
    'runtimeEnvironment',
    'workloads',
    'vpcFlowLogColumnAction',
];


const shownColumns = shownColumnIds.map((columnId) => {
    return {
        id: columnId,
        permission: [UserRolesTypes.ALL],
    } as IColumnUsageDef;
});

const hiddenColumns = hiddenColumnIds.map((columnId) => {
    return {
        id: columnId,
        colDefOverride: {
            initialHide: true,
        },
        permission: [UserRolesTypes.ALL],
    } as IColumnUsageDef;
});


export default function initializeProtectedAssetPageColumns() {
    globalAddinContainer.addMap(PATH_PROTECTED_ASSETS_PAGE_TABLE_COLUMNS, [...shownColumns, ...hiddenColumns]);
}
