import i18n from 'i18next';
import React, { useState } from 'react';
import { Icon, Button, Popover } from '@dome9/berries/react-components';
import { CpLoadingDots } from '@dome9/components/react/components';
import { SuggestionPopupProps } from '../Suggestion.interface';
import { SuggestionPopupStyled } from './SuggestionPopup.styled';
import { getLoggerService } from 'common/interface/services';
import { insightNamespace } from '../../consts/i18n';
import AiDisclaimerWrapper from '../../AiDisclaimer/Components/AiDisclaimerWrapper';
import AiDisclaimer from '../../AiDisclaimer/Components/AiDisclaimer';
import ResponseGenerator from './ResponseGenerator';

const SuggestionPopupComponent: React.FC<SuggestionPopupProps> = (props: SuggestionPopupProps) => {
    const {
        onXClick,
        displayedPrompts,
        suggestions,
        setSuggestions,
        title,
        subtitleParts,
        user
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isUnhelpfulReason, setIsUnhelpfulReason] = useState(false);
    const [wasFeedbackSent, setWasFeedbackSent] = useState(false);
    const [unhelpfulReason, setUnhelpfulReason] = useState('');

    return <SuggestionPopupStyled.Container>
        <div className={'px-9 pt-9 pb-7 text-xl flex flex-center-space-between'}>
            <div className={'flex flex-center'}>
                {title}
                <Popover content={AiDisclaimer({})} zIndex={99999} maxWidth={600} placement={'left'}>
                    <div><Icon name={'info'} size={14} className={'ml-5'} /></div>
                </Popover>
            </div>
            <div className={'flex flex-center'} onClick={() => onXClick()}>
                <Icon
                    name={'remove'}
                    size={24}
                    className={'pointer self-end width-[100%]'} />
            </div>
        </div>
        <div className={'text-lg flex px-9 pb-7 flex-center'}>
            { props.headerIcon && <props.headerIcon className={'header-icon inline mr-5'} alt="" /> }
            { subtitleParts && <span>{subtitleParts.filter(e => e).join(' / ')}</span> }
        </div>

        <AiDisclaimerWrapper user={user} onDisapproval={onXClick}>
            <div>
                { isLoading && <CpLoadingDots /> }

                <div className={'overflow-y-auto px-9 pb-9 max-h-[700px]'}>
                    { displayedPrompts?.map((prompt, index) => (
                        prompt &&
                        <div key={prompt.promptId} onClick={async () => {
                            if (suggestions[index]) return;

                            try {
                                setWasFeedbackSent(false);
                                setIsUnhelpfulReason(false);
                                setUnhelpfulReason('');
                                setIsLoading(true);

                                const newSuggestions = [];
                                newSuggestions[index] = await prompt.suggest();
                                setSuggestions(newSuggestions);
                            } finally {
                                setIsLoading(false);
                            }
                        }}>
                            <SuggestionPopupStyled.Suggestion className={`${suggestions[index] ? 'open' : 'closed'}`}>
                                <SuggestionPopupStyled.SuggestionTop
                                    className={'flex-center pointer'}
                                    onClick={() => {
                                        if (!suggestions[index]) return;
                                        setSuggestions([]);
                                    }}>

                                    <Icon name={'arrowRight'} size={10} className={`mr-6 ${suggestions[index] ? 'hidden' : ''}`} />
                                    <Icon name={'arrowDown'} size={10} className={`mr-6 ${suggestions[index] ? '' : 'hidden'}`} />
                                    {prompt.text}
                                </SuggestionPopupStyled.SuggestionTop>

                                { suggestions[index] &&
                                    <>
                                        <div className={'px-9 py-7'}><ResponseGenerator response={suggestions[index]} /></div>

                                        <div className={`p-8 ${wasFeedbackSent ? '' : 'hidden'}`}>{`${i18n.t('INSIGHTS.HELPFUL.THANKS_FOR_FEEDBACK', { ns: insightNamespace })}`}</div>
                                        <div className={`p-8 ${wasFeedbackSent ? 'hidden' : ''}`}>
                                            <div className={'flex'}>
                                                <span className={'flex-center'}>{`${i18n.t('INSIGHTS.HELPFUL.IS_IT_HELPFUL', { ns: insightNamespace })}`}</span>

                                                <Button
                                                    className={'ml-6'}
                                                    onClick={() => {
                                                        getLoggerService()
                                                            .info(`perimeterExposure.insights.helpful=yes.prompt=[${JSON.stringify(prompt)}]`)
                                                            .finally(() => {
                                                                setIsUnhelpfulReason(false);
                                                                setWasFeedbackSent(true);
                                                            });
                                                    }}
                                                >{`${i18n.t('INSIGHTS.HELPFUL.YES', { ns: insightNamespace })}`}</Button>

                                                <Button className={'ml-6'} onClick={() => setIsUnhelpfulReason(true)}>
                                                    {i18n.t('INSIGHTS.HELPFUL.NO', { ns: insightNamespace })}
                                                </Button>
                                            </div>

                                            <SuggestionPopupStyled.TextArea
                                                value={unhelpfulReason}
                                                onChange={(e: any) => setUnhelpfulReason(e.target.value)}
                                                id='standard-basic'
                                                className={`flex-grow-[1] ml-5 px-3 ${isUnhelpfulReason ? '' : 'hidden'}`}
                                                placeholder={`${i18n.t('INSIGHTS.HELPFUL.WHY_NOT_HELPFUL', { ns: insightNamespace })}`}
                                            />

                                            <Button
                                                className={`${isUnhelpfulReason ? '' : 'hidden'}`}
                                                onClick={() => {
                                                    getLoggerService()
                                                        .info(`perimeterExposure.insights.helpful=no.prompt=[${JSON.stringify(prompt)}]reason=[${unhelpfulReason}]`)
                                                        .finally(() => {
                                                            setIsUnhelpfulReason(false);
                                                            setWasFeedbackSent(true);
                                                        });
                                                }}
                                            >{i18n.t('INSIGHTS.HELPFUL.SEND', { ns: insightNamespace })}</Button>
                                        </div>
                                    </>
                                }
                            </SuggestionPopupStyled.Suggestion>
                        </div>
                    ))}
                </div>
            </div>
        </AiDisclaimerWrapper>
    </SuggestionPopupStyled.Container>;
};

export default SuggestionPopupComponent;