import i18n from 'common/services/translations/translations';
import { numberWithCommas, percentageNumber } from 'common/utils/helpFunctions';
import { TooltipFormatterContextObject } from 'highcharts';
import { FindingSeveritiesMap } from 'common/consts/FindingSeverity';

const PASSED_STATE = {
    displayText: 'Passed',
    color: '#56b40a',
    bg: '#56b40a1f',
};
export const generateGridData = (reportData: any) => {
    return reportData?.topEnvironmentsWithHighSeverityFindings.map((item: any) => {
        return {
            name: item.name,
            severity: {
                critical: item?.countFindingsBySeverity?.critical ?? 0,
                high: item?.countFindingsBySeverity?.high ?? 0,
                medium: item?.countFindingsBySeverity?.medium ?? 0,
                low: item?.countFindingsBySeverity?.low ?? 0,
                informational: item?.countFindingsBySeverity?.informational ?? 0
            }
        };
    });
};

export const generateFailedAndPassedData = (reportData: any) => {
    const pointWidth = 10;
    let typesCount = [];
    for (const prop in reportData?.passedPerEntityType) {
        const passed = reportData.passedPerEntityType[prop];
        typesCount.push({ name: prop, passed, sum: passed });
    }
    for (const prop in reportData?.failedPerEntityType) {
        const type = typesCount.find(({ name }) => name === prop);
        const failed = reportData.failedPerEntityType[prop];
        if (type) {
            type.failed = failed;
            type.sum += type.failed;
        }
        else {
            typesCount.push({ name: prop, passed: 0, sum: 0, failed });
        }
    }
    typesCount = typesCount.filter((item) => item.failed > 0)?.sort((a, b) => a.sum < b.sum ? 1 : -1)?.slice(0, 9);
    const categoriesData = typesCount.map(({ name }) => name);
    const barChartHeight = categoriesData.length * 50 + 100;
    const passedData = typesCount.map(({ passed }) => {
        return passed > 0 ? passed : null;
    });
    const failedData = typesCount.map(({ failed }) => {
        return failed > 0 ? failed : null;
    });

    return {
        chart: {
            type: 'bar',
            height: barChartHeight
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        xAxis: {
            categories: categoriesData,
            title: {
                text: null,
            },
            labels: {
                style: {
                    display: 'flex',
                    textOverflow: 'none',
                },
                format: '{value}',
            },
        },
        yAxis: {
            min: 0,
            tickHeight: 10,
            title: {
                text: i18n.t('EXECUTIVE_REPORT.NUMBER_OF_ENTITIES'),
                align: 'high',
            },
        },
        tooltip: {
            valueSuffix: '',
        },
        plotOptions: {
            series: {
                enableMouseTracking: false,
                minPointLength: 2,
                pointWidth,
                dataSorting: {
                    enabled: true,
                },
            },
            labels: {
                style: {
                    fill: 'red',
                },
            },
            bar: {
                dataLabels: {
                    enabled: true,
                    allowOverlap: true,
                    formatter: function (this: TooltipFormatterContextObject) {
                        return `<span>${numberWithCommas(this.point.y || 0)}</span>`;
                    }
                },
            }
        },
        legend: {},
        credits: {
            enabled: false,
        },
        series: [
            {
                name: PASSED_STATE.displayText,
                data: passedData,
                color: PASSED_STATE.color,
            },
            {
                name: i18n.t('EXECUTIVE_REPORT.FAILED'),
                data: failedData,
                color: FindingSeveritiesMap.high.color,
            },
        ],
    };
};

export const generateTestScoreData = (reportData: any) => {
    return {
        chart: {
            height: 300,
            type: 'pie',
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                allowPointSelect: false,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    formatter: function (this: TooltipFormatterContextObject) {
                        return `<span class="name">${this.point.name}: </span>
                        <span class="bold">${numberWithCommas(this.point.y || 0)}</span>
                        <span class="name">${percentageNumber(this.point.percentage || 0)}</span>`;
                    },
                },
            },
        },
        series: [
            {
                enableMouseTracking: false,
                data: [
                    {
                        name: PASSED_STATE.displayText,
                        color: PASSED_STATE.color,
                        y: reportData?.allTestsScore?.passed,
                    },
                    {
                        name: FindingSeveritiesMap.critical.displayText,
                        color: FindingSeveritiesMap.critical.color,
                        y: reportData?.failedTestedBySeverity?.critical
                    },
                    {
                        name: FindingSeveritiesMap.high.displayText,
                        color: FindingSeveritiesMap.high.color,
                        y: reportData?.failedTestedBySeverity?.high
                    },
                    {
                        name: FindingSeveritiesMap.medium.displayText,
                        color: FindingSeveritiesMap.medium.color,
                        y: reportData?.failedTestedBySeverity?.medium
                    },
                    {
                        name: FindingSeveritiesMap.low.displayText,
                        color: FindingSeveritiesMap.low.color,
                        y: reportData?.failedTestedBySeverity?.low
                    },
                    {
                        name: FindingSeveritiesMap.informational.displayText,
                        color: FindingSeveritiesMap.informational.color,
                        y: reportData?.failedTestedBySeverity?.informational
                    },
                ],
            },
        ],
        legend: { enabled: false },
    };
};
