import WelcomePage from 'common/components/WelcomePage/WelcomePage';
import { useTranslation } from 'react-i18next';
import { changeUrl } from 'common/utils/http';
import backgroundImage from '../../../assets/images/welcomePage/network.svg';
import { IWelcomePageSection } from 'common/components/WelcomePage/WelcomePage.interface';
import React from 'react';


const Welcome: React.FC = () => {
    const { t } = useTranslation();
    const PAGE_ID = 'NETWORK';
    const WELCOME_PAGE_LANG_PATH = `${PAGE_ID}.WELCOME_PAGE`;
    const SECTIONS_LANG_PATH = `${WELCOME_PAGE_LANG_PATH}.SECTIONS`;

    const sections: IWelcomePageSection[] = [
        {
            title: t(`${SECTIONS_LANG_PATH}.VISIBILITY.TITLE`),
            subtitle: t(`${SECTIONS_LANG_PATH}.VISIBILITY.SUBTITLE`),
            items: [
                {
                    title: t(`${SECTIONS_LANG_PATH}.VISIBILITY.CARDS.CONFIGURATION_EXPLORER.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.VISIBILITY.CARDS.CONFIGURATION_EXPLORER.SUBTITLE`),
                    icon: 'network',
                    linkTo: '/clarity-d3',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.VISIBILITY.CARDS.TRAFFIC_EXPLORER.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.VISIBILITY.CARDS.TRAFFIC_EXPLORER.SUBTITLE`),
                    icon: 'traffic',
                    linkTo: '/magellan/clarity',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.VISIBILITY.CARDS.TRAFFIC_LOGS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.VISIBILITY.CARDS.TRAFFIC_LOGS.SUBTITLE`),
                    icon: 'log',
                    linkTo: '/magellan/flowlogs',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.VISIBILITY.CARDS.IP_ADDRESSES.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.VISIBILITY.CARDS.IP_ADDRESSES.SUBTITLE`),
                    icon: 'location',
                    linkTo: '/ip-addresses',
                },
            ],
        },
        {
            title: t(`${SECTIONS_LANG_PATH}.POLICY.TITLE`),
            subtitle: t(`${SECTIONS_LANG_PATH}.POLICY.SUBTITLE`),
            items: [
                {
                    title: t(`${SECTIONS_LANG_PATH}.POLICY.CARDS.SECURITY_GROUPS.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.POLICY.CARDS.SECURITY_GROUPS.SUBTITLE`),
                    icon: 'security-event',
                    linkTo: '/security-group',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.POLICY.CARDS.ACCESS_LEASES.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.POLICY.CARDS.ACCESS_LEASES.SUBTITLE`),
                    icon: 'accessControl',
                    linkTo: '/dynamic-access',
                },
                {
                    title: t(`${SECTIONS_LANG_PATH}.POLICY.CARDS.ACTIVE_LEASES.TITLE`),
                    subtitle: t(`${SECTIONS_LANG_PATH}.POLICY.CARDS.ACTIVE_LEASES.SUBTITLE`),
                    icon: 'check',
                    onClick: () => {
                        changeUrl('/dynamic-access/active-leases');
                    },
                },
            ],
        },
    ];
    return (
        <WelcomePage
            title={t(`${WELCOME_PAGE_LANG_PATH}.TITLE`)}
            subtitle={t(`${WELCOME_PAGE_LANG_PATH}.SUBTITLE`)}
            sections={sections}
            backgroundImage={backgroundImage}
        />
    );
};

export default Welcome;
