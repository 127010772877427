import { CpLoadingDots } from '@dome9/components/react/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { getIsAuthenticated } from 'platform/auth/Auth.reducer';
import './Main.scss';
import MenuContainer from './Menus/MenuContainer';
import ReactPage from './ReactPage';
import TopNavigationBar from './TopNavigationBar/TopNavigationBar';
import OnboardingInfoBar from '../onboarding/components/OnboardingInfoBar';
import ManualOnboardingBanner from '../onboarding/azure/components/ManualOnboardingBanner';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import { IReactPageRoute } from 'common/interface/routing';
import {
    getHideAngularUntilNavigated,
    getIsReact,
    getIsReactPageWithAngular,
    getIsAngularFrameOnRemote,
} from 'App.reducer';
import { SideBar } from './SideBar/SideBar';
import { getWebAppIframeService } from 'common/interface/services';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { EnvironmentTabs } from './EnvironmentTabs/EnvironmentTabs';
import { Drawer } from 'common/components/DrawerInfra/Drawer/Drawer';
import { GlobalModals } from 'common/components/GlobalModals/GlobalModals';
import IncidentMessage from 'platform/components/IncidentMessage/IncidentMessage';
import EventsPageSwitchView from './EventsPageSwitchView/EventsPageSwitchView';

let timeout: NodeJS.Timeout | null = null;

const Main: React.FC = () => {
    const hideAngularUntilNavigated = useSelector(getHideAngularUntilNavigated);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const isCloudInfra = useSelector(getIsCloudInfra);
    const isReactPage = useSelector(getIsReact);
    const isReactPageWithAngular = useSelector(getIsReactPageWithAngular);
    const isAngularFrameOnRemote = useSelector(getIsAngularFrameOnRemote);
    const reactPageRoutes = globalAddinContainer.get(REACT_PAGE_ROUTE) as IReactPageRoute[];
    const webappWrapperRef = React.useRef<HTMLDivElement>(null);
    const prevIsReactPage = React.useRef<boolean>(true);

    React.useEffect(() => {
        if (!webappWrapperRef.current) return;
        getWebAppIframeService().setDefaultWrapperRef(webappWrapperRef.current);
        return () => {
            getWebAppIframeService().clearDefaultWrapperRef();
        };
    }, [webappWrapperRef]);

    React.useEffect(() => {
        const isWebappHidden = (isReactPage && !isReactPageWithAngular) || hideAngularUntilNavigated;
        if (!isWebappHidden && prevIsReactPage.current && !isReactPage) {
            getWebAppIframeService().setVisibility(false);
            // we set timeout here so the webapp will not show the last page for short time before its navigate to the new route
            timeout = setTimeout(() => {
                getWebAppIframeService().setVisibility(true);
            }, 500);
        } else {
            getWebAppIframeService().setVisibility(!isWebappHidden);
        }
        prevIsReactPage.current = isReactPage;

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isReactPageWithAngular, isReactPage, hideAngularUntilNavigated]);

    const iframeClassName = React.useMemo(() => {
        let base = 'routed-page-layout routed-page-layout__angular';

        if (isReactPageWithAngular && !isAngularFrameOnRemote) {
            base = `${base} routed-page-layout__react_angular`;
        }

        if ((isReactPage && !isReactPageWithAngular) || hideAngularUntilNavigated || isAngularFrameOnRemote) {
            base = `${base} hide`;
        }

        return base;
    }, [isReactPageWithAngular, isReactPage, hideAngularUntilNavigated, isAngularFrameOnRemote]);

    const reactClassName = React.useMemo(() => {
        let base = 'routed-page-layout routed-page-layout__react';
        if (!isReactPage) {
            base = `${base} hide`;
        }
        if (isReactPageWithAngular && !isAngularFrameOnRemote) {
            base = `${base} routed-page-layout__react-above-angular`;
        }

        return base;
    }, [isReactPage, isReactPageWithAngular, isAngularFrameOnRemote]);

    let sidebarClassName = 'flex';
    if ((isReactPageWithAngular && !isAngularFrameOnRemote) || !isReactPage) {
        sidebarClassName += ' flex-1';
    }
    return (
        <div className={`MainLayout ${isCloudInfra && 'CloudInfraLayout'}`}>
            {!isCloudInfra && isAuthenticated && <TopNavigationBar />}
            {isAuthenticated && <MenuContainer />}
            <main className='main-content'>
                <Drawer />
                <GlobalModals />
                <IncidentMessage />
                <Breadcrumbs />
                <EventsPageSwitchView /> {/* TODO: remove when old events page is removed  */}
                <EnvironmentTabs />
                <OnboardingInfoBar />
                <ManualOnboardingBanner/>
                {isAuthenticated ? (
                    <>
                        <div className={reactClassName}>
                            <Switch>
                                {reactPageRoutes.map((route, index) => {
                                    return (
                                        route.condition && (
                                            <ReactPage
                                                key={index}
                                                path={route.path}
                                                exact={route.exact}
                                                onEnter={route.onEnter}
                                            >
                                                {React.createElement(route.component, route.componentProps)}
                                            </ReactPage>
                                        )
                                    );
                                })}
                            </Switch>
                        </div>
                        <div className={sidebarClassName}>
                            <SideBar></SideBar>
                            <div className={iframeClassName}>
                                <div ref={webappWrapperRef} className='webapp-iframe-placeholder-element' />
                            </div>
                        </div>
                    </>
                ) : (
                    <CpLoadingDots />
                )}
            </main>
        </div>
    );
};

export default Main;
