import React from 'react';
import Modal from 'common/design-system/components-v2/Modal';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from 'common/design-system/components-v2';
import { getCustomizationService, getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { resetDashboardSetup } from '../../Utils';
import {
    HOME_DASHBOARD_DEFAULT_CUSTOMIZATION_KEY
} from 'common/module_interface/overview/Consts';
import i18next from 'i18next';
import { i18nOverviewNamespace } from '../../initialize.i18n';
import { ActionsStyled } from './DebugActionsModal.styled';

interface IDebugAction {
    text: string;
    onClick: () => void;
}

const getActions = (): IDebugAction[] => {
    const resetSetupTitle = i18next.t('DASHBOARD.SETUP_DEFAULT.ACTIONS.RESET_DASHBOARD_SETUP', { ns: i18nOverviewNamespace });
    const clearDashboardTitle = i18next.t('DASHBOARD.SETUP_DEFAULT.ACTIONS.CLEAR_DEFAULT_DASHBOARD', { ns: i18nOverviewNamespace });
    return [
        {
            text: resetSetupTitle,
            onClick: async () => {
                try {
                    await resetDashboardSetup();
                    getNotificationsService().addNotification({
                        type: NotificationType.SUCCESS,
                        text: i18next.t('COMMON.DONE') + ': ' + resetSetupTitle,
                    });
                } catch (error) {
                    console.error('Failed clearing default dashboard setup customization');
                }
            },
        },
        {
            text: clearDashboardTitle,
            onClick: async () => {
                try {
                    await getCustomizationService().deleteKeyData(HOME_DASHBOARD_DEFAULT_CUSTOMIZATION_KEY);
                    getNotificationsService().addNotification({
                        type: NotificationType.SUCCESS,
                        text: i18next.t('COMMON.DONE') + ': ' + clearDashboardTitle,
                    });
                } catch (error) {
                    console.error('Failed clearing default dashboard customization');
                }
            },
        },
    ];
};

export const DebugActionsModal: React.FunctionComponent<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    return (
        <Modal.ModalDialog isOpen={isOpen} shouldCloseOnOverlayClick={false} onRequestClose={onClose}>
            <Modal.ModalHeader hasSeparator={false} title={t('COMMON.ACTIONS')} onClose={onClose} />
            <Modal.ModalContent>
                <Stack direction='column' justifyContent='flex-end' fullWidth spacing={2} >
                    {getActions().map(action => <ActionsStyled.ButtonDiv onClick={action.onClick} key={action.text}>{action.text}</ActionsStyled.ButtonDiv>)}
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter hasSeparator>
                <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                    <Button variant='text' onClick={onClose}>{t('COMMON.CLOSE')}</Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );};
