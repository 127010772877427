
export enum ModalType {
    Remove = 'remove',
    Remove_WithPolicies = 'remove_with_policies',
    Remove_legacy = 'remove_legacy',
    Rename = 'rename',
    SyncNow = 'sync-now',
    AddCloudBots = 'add-cloud-bots',
    RemoveIntelligence = 'remove-intelligence',
    AddCloudTrail = 'add-cloud-trail',
    AddFlowLogs = 'add-flow-logs',
    AddGuardDuty = 'add-guard-duty',
    RemoveGuardDuty = 'remove-guard-duty',
    EditCredentials = 'edit-credentials',
}