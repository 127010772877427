import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
  width: 100%;
  flex-direction: column;
`;

const BodyDiv = styled(Stack)`
  min-height: 260px;
  width: 100%;
  flex-direction: row;
`;

export const InventoryStyled = {
    TopDiv,
    BodyDiv,
};