import { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IIssueExclusion } from '../IssueExclusions.interface';
import { MultiChipCellContent } from 'common/erm-components/custom/MultiChipCellContent/MultiChipCellContent';

export const IssueExclusionEntityNameCellRender: FC<ICellRendererParams<IIssueExclusion>> = (params) => {
    const exclusion: IIssueExclusion | undefined = params.data;
    if (!exclusion?.entityNames || (exclusion.entityNames.length === 0)) {
        return null;
    }

    return (
        <MultiChipCellContent propsList={exclusion.entityNames.map(name => ({
            label: name,
        }))} />
    );
};