import React, { useCallback, useMemo } from 'react';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, IColumnToolPanel, SelectionChangedEvent } from 'ag-grid-community';
import { Spinner, Stack, Table, Typography } from 'common/design-system/components-v2';
import { ETColDef, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import { IEnvironmentsAction } from 'common/module_interface/assets/Environments';
import Modals from '../Modals';
import { IEnvironment } from 'common/interface/environmentsTable';
import { EnvironmentsTableProps, ModalType } from './EnvironmentsTable.types';

const EnvironmentsTable: React.FC<EnvironmentsTableProps> = ({
    pageSize,
    fetchingData,
    data,
    getFilteredData,
    tableId,
    allCloudAccountsCount,
    tabData,
    doesExternalFilterPass,
    activeFitlers,
}) => {
    const apiRef = React.useRef<GridApi>();
    const columnApiRef = React.useRef<ColumnApi>();
    const [modalType, setModalType] = React.useState<ModalType | null>(null);
    const [selectedRows, setSelectedRows] = React.useState<IEnvironment[]>([]);
    const [rowsCounts, setRowsCounts] = React.useState<number>(0);

    React.useEffect(() => {
        if (apiRef.current) {
            setRowsCounts(apiRef.current.getDisplayedRowCount());
            apiRef.current.setRowData(data);
            apiRef.current.refreshCells();

            if (data.length === 0 && fetchingData) {
                apiRef.current.showLoadingOverlay();
                return;
            }
        }
    }, [data, fetchingData]);

    React.useEffect(() => {
        if (apiRef.current) {
            apiRef.current.setIsExternalFilterPresent(() => Object.keys(activeFitlers).length > 0);
            apiRef.current.setDoesExternalFilterPass(doesExternalFilterPass);
            apiRef.current.onFilterChanged();

            const newRowsCount = apiRef.current.getDisplayedRowCount();
            setRowsCounts(newRowsCount);
        }
    }, [activeFitlers, doesExternalFilterPass]);

    const resetSelectedRows = () => {
        apiRef.current?.deselectAll();
    };

    const onSelectionChanged = (params: SelectionChangedEvent<IEnvironment>) => {
        const selectedItems = params.api.getSelectedRows();
        setSelectedRows(selectedItems);
    };

    const columnDefs = useMemo<ColDef[]>(() => {
        const allDefs = EnvironmentsTableRegistry.getColumnDefs();
        const defs = tabData.columns.reduce<ETColDef[]>((acc, column) => {
            const defFromAddin = allDefs.find((def) => def.id === column.id);
            if (!defFromAddin) {
                return acc;
            }
            const def = ({ ...defFromAddin, ...column.overrides });
            acc.push(def);
            return acc;
        }, []);
        const sortedDefs = defs.sort((firstColumn, secondColumn) => {
            const firstColumnPosition = firstColumn.position;
            const secondColumnPosition = secondColumn.position;
            if (firstColumnPosition === undefined && secondColumnPosition === undefined) return 0;
            if (firstColumnPosition === undefined) return 1;
            if (secondColumnPosition === undefined) return -1;
            return firstColumnPosition - secondColumnPosition;
        });
        return sortedDefs;
    } , [tabData]);

    const actions = useMemo<IEnvironmentsAction[]>(() => {
        const filteredByIsRelevant = EnvironmentsTableRegistry.getActions().filter(action => action.isRelevant ? action.isRelevant(tabData.id) : true);
        return filteredByIsRelevant.filter(action => tabData.actions.includes(action.id));
    } , [tabData]);

    const onGridReady = useCallback(
        (params: GridReadyEvent<IEnvironment, any>) => {
            params.api.closeToolPanel();
            apiRef.current = params.api;
            columnApiRef.current = params.columnApi;

            const columnToolPanel = params.api.getToolPanelInstance('columns') as IColumnToolPanel | undefined;
            const columns: ColDef[] = [...columnDefs];
            columns.sort((firstColumn, secondColumn) => {
                const first = firstColumn.headerName?.toLowerCase() || '';
                const second = secondColumn.headerName?.toLowerCase() || '';
                return first.localeCompare(second);
            });
            columnToolPanel?.setColumnLayout(columns);
            params.api.setRowData(data);
        },
        [columnDefs, data],
    );

    const gridOptions: GridOptions<IEnvironment> = {
        columnDefs,
        rowSelection: 'multiple',
        onGridReady,
        getRowId: (params) => params.data['customData|uniqueIdForTable'] || params.data.id,
        onRowSelected: onSelectionChanged,
        isExternalFilterPresent: () => Object.keys(activeFitlers).length > 0,
        doesExternalFilterPass
    };

    const exportButtons = useMemo<ITableExportButton[] | undefined>(() => {
        const buttonsToGet = tabData.exportOptions;
        if (!buttonsToGet) return undefined;
        const filtered = EnvironmentsTableRegistry.getExports().filter(action => buttonsToGet.includes(action.id));
        return filtered.map((action) => ({
            label: action.name,
            icon: action.icon,
            onClick: () => action.callback(data, getFilteredData()),
            disabled: action.isActionDisabled && action.isActionDisabled(data, getFilteredData())
        }));
    } , [getFilteredData, data, tabData.exportOptions]);

    return (
        <>
            <Table 
                tableId={tableId}
                saveColumnsState
                key={tableId}
                pageSize={pageSize}
                gridOptions={gridOptions}
                exportButtons={exportButtons}
                actions={actions}
                disableGrouping
                disableRowsResetOnRowDataChange
                disableRowsResetOnFilterChange
                appliedFilters={activeFitlers}
                footer={(
                    <Stack spacing={6} direction='row' alignItems='center'>
                        <Typography>{`Showing ${rowsCounts} of ${allCloudAccountsCount.toLocaleString()} environments`}</Typography>
                        {fetchingData && <Spinner size={12} />}
                    </Stack>
                )}
            />
            <Modals
                modalType={modalType}
                closeModal={() => setModalType(null)}
                setModalType={setModalType}
                selectedRows={selectedRows}
                resetSelectedRows={resetSelectedRows}
            /> 
        </>
    );
};

export default EnvironmentsTable;
