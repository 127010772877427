import { ColDef } from 'ag-grid-enterprise';
import StatusCellRenderer from 'common/components/ag-grid/Renderers/StatusCellRenderer';
import NSGStorageNameCellRenderer from '../CellRenderers/NSGStorageNameCellRenderer';
import i18n, { TFunction } from 'i18next';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';

const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace, 'AZURE_ONBOARDING');

export const getColumnDefs = (): ColDef[] => {
    return [
        {
            field: 'nsg',
            tooltipField: 'nsg',
            headerName: t('NSG.NETWORK_SECURITY_GROUP'),
            headerCheckboxSelection: true,
            checkboxSelection: true,
        },
        {
            field: 'status',
            tooltipField: 'status',
            headerName: t('STATUS.TITLE'),
            filter: 'agTextColumnFilter',
            cellRenderer: StatusCellRenderer,
        },
        {
            field: 'resourceGroupName',
            tooltipField: 'resourceGroupName',
            headerName: t('NSG.RESOURCE_GROUP'),
        },
        {
            field: 'storageName',
            tooltipField: 'storageName',
            headerName: t('NSG.STORAGE_ACCOUNT'),
            cellRenderer: NSGStorageNameCellRenderer,
        },
        {
            field: 'region',
            headerName: t('NSG.REGION'),
            tooltipField: 'region',
        }
    ];
};