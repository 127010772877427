import {
    ASSETS_TAB_NAMES,
    IProtectedAssetDetailPanelProvider,
    IProtectedAssetDetailProvider,
} from 'common/module_interface/assets/ProtectedAssets';
import { InfoPaneFieldProps } from 'common/components/InfoPane/InfoPane';
import i18next from 'i18next';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { SIDEBAR_COMPONENTS } from 'common/extensibility/WellKnownPaths';
import AssetDetailsPanelsList from './AssetDetailsPanelsList';
import { IComponentData } from 'common/interface/general';
import OverviewPanel from './OverviewPanel';
import {
    ADDITIONAL_FINDINGS_ASSETS,
    IAM_POLICIES,
    NETWORK_SECURITY_ASSETS,
    SQL_SERVER_ASSETS,
} from './AssetPanelsMapping';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { Addin } from 'common/extensibility/AddinRegistry';
import { AssetFieldsMap } from 'common/module_interface/assets/AssetsConsts';

export const PROTECTED_ASSET_PANELS_COMPONENT_ID = 'protected asset panels';

export function initializeAssetPage() {

    const fieldAddins: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[] = [
        {
            id: 'name',
            position: 10,
            content: AssetFieldsMap.nameField,
        },
        {
            id: 'type',
            position: 20,
            content: AssetFieldsMap.typeField,
        },
        {
            id: 'id',
            position: 30,
            content: AssetFieldsMap.idField,
        },
        {
            id: 'internalId',
            position: 40,
            content: AssetFieldsMap.internalIdField,
        },
        {
            id: 'createdDate',
            position: 45,
            content: AssetFieldsMap.createdDateField,
        },
        {
            id: 'environment',
            position: 50,
            content: AssetFieldsMap.environmentField,
        },
        {
            id: 'vpc',
            position: 60,
            content: AssetFieldsMap.vpcField,
        },
        {
            id: 'region',
            position: 70,
            content: AssetFieldsMap.regionField,
        },
        {
            id: 'description',
            position: 80,
            content: AssetFieldsMap.descriptionField,
        },
    ];
    ProtectedAssetsPageRegistry.addFieldAddins(fieldAddins);

    const entityViewerPanel: IProtectedAssetDetailPanelProvider = {
        isRelevant: true,
        getValue() {
            return {
                name: ASSETS_TAB_NAMES.ENTITY_VIEW_TAB_NAME,
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.ENTITY_VIEWER'),
                isReact: false,
            };
        },
    };

    const postureFindingsPanel: IProtectedAssetDetailPanelProvider = {
        isRelevant: true,
        getValue() {
            return {
                name: ASSETS_TAB_NAMES.POSTURE_FINDINGS_TAB_NAME,
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.POSTURE_FINDINGS'),
                isReact: false,
            };
        },
    };

    const propertiesPanel: IProtectedAssetDetailPanelProvider = {
        isRelevant: true,
        getValue() {
            return {
                name: ASSETS_TAB_NAMES.PROPERTIES_TAB_NAME,
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.PROPERTIES'),
                isReact: false,
            };
        },
    };

    const overviewPanel: IProtectedAssetDetailPanelProvider = {
        isRelevant: ({ typeByPlatform }) => {
            const noneRelevantTypesForTab = [
                'containerregistry|ContainerRegistryImage',
                'aws|EcsImage',
                'kubernetes|KubernetesImage',
                'kubernetes|KubernetesNode',
                'shiftleft|ShiftLeftImage',
            ];

            return !noneRelevantTypesForTab.some(type => type === typeByPlatform);
        },
        getValue() {
            return {
                name: ASSETS_TAB_NAMES.OVERVIEW_TAB_NAME,
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.OVERVIEW'),
                isReact: true,
                component: OverviewPanel,
            };
        },
    };

    const additionalFindingsPanel: IProtectedAssetDetailPanelProvider = {
        isRelevant: (entity) => {
            return ADDITIONAL_FINDINGS_ASSETS.includes(entity.typeByPlatform);
        },
        getValue() {
            return {
                name: ASSETS_TAB_NAMES.ADDITIONAL_FINDINGS_TAB_NAME,
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.ADDITIONAL_FINDINGS'),
                isReact: false,
            };
        },
    };

    const networkSecurityContent: IProtectedAssetDetailPanelProvider = {
        isRelevant: (entity) => {
            return NETWORK_SECURITY_ASSETS.includes(entity.typeByPlatform);
        },
        getValue() {
            return {
                name: ASSETS_TAB_NAMES.NETWORK_SECURITY_TAB_NAME,
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.NETWORK_SECURITY'),
                isReact: false,
            };
        },
    };
    const sqlServerContent: IProtectedAssetDetailPanelProvider = {
        isRelevant: (entity) => {
            return SQL_SERVER_ASSETS.includes(entity.typeByPlatform);
        },
        getValue() {
            return {
                name: ASSETS_TAB_NAMES.SQL_SERVER_TAB_NAME,
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.SQL_SERVER'),
                isReact: false,
            };
        },
    };
    const iamPoliciesContent: IProtectedAssetDetailPanelProvider = {
        isRelevant: (entity) => {
            return IAM_POLICIES.includes(entity.typeByPlatform);
        },
        getValue() {
            return {
                name: ASSETS_TAB_NAMES.IAM_POLICY_TAB_NAME,
                title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.IAM_POLICIES'),
                isReact: false,
            };
        },
    };

    ProtectedAssetsPageRegistry.addPanelAddins([
        {
            id: 'overview',
            position: 10,
            content: overviewPanel,
        },
        {
            id: 'entityView',
            position: 20,
            content: entityViewerPanel,
        },
        {
            id: 'postureFindings',
            position: 30,
            content: postureFindingsPanel,
        },
        {
            id: 'properties',
            position: 40,
            content: propertiesPanel,
        },
        {
            id: 'additionalFindings',
            position: 50,
            content: additionalFindingsPanel,
        },
        {
            id: 'networkSecurity',
            position: 50,
            content: networkSecurityContent,
        },
        {
            id: 'sqlServer',
            position: 60,
            content: sqlServerContent,
        },
        {
            id: 'IamPolicy',
            position: 70,
            content: iamPoliciesContent,
        },
    ]);
}

export function initializeSideTabs() {
    globalAddinContainer.add<IComponentData>(SIDEBAR_COMPONENTS, [{
        id: PROTECTED_ASSET_PANELS_COMPONENT_ID,
        content: {
            component: AssetDetailsPanelsList,
        },
    }]);
}