import { Icon, Tooltip } from '@dome9/berries/react-components';
import { CpSpinner } from '@dome9/components/react/components';
import NoDataToDisplay from 'common/components/Widgets/NoDataToDisplay/NoDataToDisplay';
import { getGradientBackgroundColorClass } from 'common/components/Widgets/helpers';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { DashboardWidgetTypes, IAddinWidgetsDataSource, IDashboardSection, IDashboardWidget, IGenericWidgetDataProps, IGenericWidgetDataSetProps, IWidgetDataConfiguration } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { getDataIdByPath } from 'modules/overview/Utils';
import { i18nOverviewNamespace } from 'modules/overview/initialize.i18n';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetActions from './WidgetActions';
import { getStoreService } from 'common/interface/services';
import { addItemToHiddenWidgetsWithNoData, removeItemFromHiddenWidgetsWithNoData } from '../../Overview.reducer';
import { useProfiler } from '@sentry/react';
import Styled from './styled';
import { useIntersectionObserver } from 'common/hooks/useIntersectionObserver';
//import { useInViewport } from 'react-in-viewport';


export interface IDashboardWidgetProps{
    widget: IDashboardWidget
    hideActions?: boolean
    preventReload?: boolean;
    section: IDashboardSection;
    hideDrag?: boolean;
}

export const GenericDashboardWidget: React.FC<IDashboardWidgetProps> = ({ widget, hideActions, preventReload, section, hideDrag }) => {
    useProfiler( `${widget.type}|${widget.title}`);
    const { t } = useTranslation(i18nOverviewNamespace);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null) as any | IGenericWidgetDataSetProps | IGenericWidgetDataProps;
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [isError, setIsError] = useState(false);
    const widgetRef = useRef<any>();
    //const { inViewport } = useInViewport(widgetRef);
    const dataSource = widget.dataSourceName && globalAddinContainer.getById<IAddinWidgetsDataSource>(DASHBOARD_DYNAMIC_WIDGETS, widget.dataSourceName);
    const widgetClone = deepCloneObject(widget);
    const dispatch = getStoreService().dispatch;
    const dataFieldPath = dataSource && dataSource.dataField?.path;
    const dataId = dataFieldPath && getDataIdByPath(widgetClone , dataFieldPath);
    const widgetSetup = dataSource && dataSource?.widgets?.find((widgetItem: IWidgetDataConfiguration)=> {
        if(dataId) { // Incase the widget do not have data id (like the environments widget for example)
            return (widgetItem.dataId === dataId || widgetItem.dataIdAliases?.includes(dataId)) && widgetItem.type === widget.type;
        } else {
            return widgetItem.type === widget.type;
        }
    });
    const getData = widgetSetup && widgetSetup.getData;
    const isEditMode = hideActions;

    useEffect(()=>{
        if(!getData){
            setData(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[widgetSetup]);

    const [ref, isIntersecting] = useIntersectionObserver({
        root: null,
        rootMargin: '500px',
        threshold: 0.1,
    });

    useEffect(() => {
        if(!data) {
            setIsLoading(true);
            setIsError(false);
        }
        const getDataFromServer = async () => {
            if(getData && !preventReload) {
                try {
                    const response = getData && await getData(widget);
                    setData(response);
                    setBackgroundColor(response.backgroundColor);
                    setIsError(false);
                } catch (err) {
                    setIsError(true);
                } finally {
                    setIsLoading(false);
                }

            } else { // Incase the widget is initiate with data inside.
                setIsLoading(false);
            }
        };

        if(isIntersecting){
            getDataFromServer();
        }
    }, [widget, widgetSetup, getData, preventReload, isIntersecting]);


    useEffect(() => {
        const widgetHasNoData = data && data.items && data.items.length === 0;
        const summaryWidgetHasZeroInValue = widget.type === DashboardWidgetTypes.Summary && data && data.items && data.items.length > 0 && data.items[0].value === 0;

        if( ( widgetHasNoData || summaryWidgetHasZeroInValue) && (!isEditMode && widget.hideOnNoData)){
            dispatch(addItemToHiddenWidgetsWithNoData(widget.id));
        } else {
            dispatch(removeItemFromHiddenWidgetsWithNoData(widget.id));
        }
    }, [dispatch, data, widget, isEditMode]);


    const getInnerComponent = (widget: IDashboardWidget) => {
        const WidgetComponentRenderer = widgetSetup && widgetSetup.widgetComponent as React.FC<any>;

        if(isLoading && !isError) {
            return <div className="flex flex-1 align-center justify-center h-full"><CpSpinner /></div>;
        } else if (!isError && WidgetComponentRenderer) {
            if(data && data.items && data.items.length === 0) { // This will show only for widgets that have data interface: IGenericWidgetDataSetProps or IGenericWidgetDataProps
                return <NoDataToDisplay />;
            } else {
                return <WidgetComponentRenderer widget={widget} key={widget.id} data={data}/>;
            }
        } else {
            return (
                <div className='flex flex-1 items-center justify-center text-weak'>
                    {t('DASHBOARD.MESSAGES.ERROR_OCCURRED')}
                </div>
            );
        }
    };

    return (
        <Styled.WidgetWrapper ref={widgetRef} className={`group flex bg-content flex-1 flex-col rounded-lg overflow-hidden h-full ${backgroundColor && getGradientBackgroundColorClass(backgroundColor)}`}>
            <div className={'flex items-center border-b px-7 py-4 font-medium leading-[22px] flex-0 relative'}>
                <div className={`items-center flex flex-1 truncate drag-handle ${!hideActions && 'cursor-move'}`}>
                    {!hideDrag &&
                        <Icon name='drag' size={20} className='mr-6' />
                    }
                    <Tooltip content={widget.title}>
                        <div className='text-ellipsis'>
                            {widget.title}
                        </div>
                    </Tooltip>
                    {widget.description &&
                        <Tooltip content={widget.description}>
                            <span className='ml-6 mt-2'>
                                <Icon name="info" size={12} />
                            </span>
                        </Tooltip>
                    }
                </div>
                {!hideActions &&
                    <WidgetActions widget={widget} section={section} />
                }
            </div>
            <div ref={ref} className="group flex flex-1 w-full overflow-y-hidden p-6">
                {getInnerComponent(widget)}
            </div>

        </Styled.WidgetWrapper>
    );
};


