import styled from 'styled-components/macro';

export const SimpleListDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 9px;
  justify-content: space-evenly;
  gap: 6px;
`;

export const SimpleListRowDiv = styled.div<{ isClickable: boolean; }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #F5F5F5 0 0 no-repeat padding-box;
  border: 1px solid #0000000F;
  border-radius: 3px;
  opacity: 1;
  height: 38px;
  padding-left: 9px;
  padding-right: 9px;
  white-space: nowrap;
  overflow: hidden;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};

  .svg-icon-wrapper {
    margin-right: 5px;
  }
`;
