import { INTELLIGENCE_SOURCE, INTELLIGENCE_TYPES } from 'common/module_interface/intelligence/Gsl/GslService.const';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
export const GSL_FINDING_LIMIT = 80;
export const GSL_FINDING_ORDER_BY_PROPERTY = 'assessmentRunTime';

export const ANOMALY_REQUEST_SORT_FIELD: Record<string, string> = {
    [INTELLIGENCE_SOURCE.azurefl]: 'starttime',
    [INTELLIGENCE_SOURCE.flowlogs]: 'endtime',
};

export const ANOMALY_GRAPH_NETWORK_GSL: Record<string, string> = {
    [INTELLIGENCE_SOURCE.azurefl]: `${INTELLIGENCE_SOURCE.azurefl} where action='ACCEPT' summarize sum(bytes) as sum_bytes by stream_owner,`+
    'bin(starttime, 1h) select datetime_part(\'hour\', starttime) as hour, sum_bytes, format_datetime(starttime, \'yyyy-MM-dd\') as date_time, stream_owner',

    [INTELLIGENCE_SOURCE.flowlogs]: `${INTELLIGENCE_SOURCE.flowlogs} where action='ACCEPT' summarize sum(bytes) as sum_bytes by stream_owner,`+
    'bin(endtime, 1h) select datetime_part(\'hour\', endtime) as hour, sum_bytes, format_datetime(endtime, \'yyyy-MM-dd\') as date_time, stream_owner',
};

export const GRAPH_COUNT_GSL: Record<string, string> = {
    [INTELLIGENCE_TYPES.ACCOUNT_ACTIVITY]: ' summarize dcount(hash_many(identity_id, target_id, issuer_id)) as cnt',
    [INTELLIGENCE_TYPES.NETWORK_TRAFFIC]: ' summarize dcount(hash_many(src_id, dst_id)) as cnt',
};

export interface ExpandedFindingDetailsProps {
    finding?: IFinding,
    intelligenceSource?: string | undefined
    anomalyData?: any
}

/*
 This util function helps with getting the correct query to find all the findings which the entity is a participant in.
 For example in case the entity is a target inside a finding, which was created on the identity, we still want to include that finding in the events table of the entity.
 */
export function getIntelligenceFindingDataQuery(entityId: string, entityName: string, finding: IFinding): string {
    const source = finding.additionalFields.networkActivity? INTELLIGENCE_TYPES.NETWORK_TRAFFIC : INTELLIGENCE_TYPES.ACCOUNT_ACTIVITY;
    const additionalFieldsValue = JSON.parse(finding.additionalFields[source]);

    if (!additionalFieldsValue) return ` entity_id='${entityId}' `;

    let queryForGsl = '', mainAssetType = '', secondaryAssetType ='', isEntityIdTransformed= false, entityType = 'src';
    const queryParts: string[] = [];

    switch(source) {
        case INTELLIGENCE_TYPES.NETWORK_TRAFFIC:
            [mainAssetType, secondaryAssetType] = additionalFieldsValue.src?.isMain ? ['src','dst'] : ['dst','src'];
            entityType = (entityId == finding.entityExternalId || entityName == finding.entityName) ? mainAssetType : secondaryAssetType ;
            // in case the id is transformed - for example "internet" or "malicious" don't send the query with those id's, search by IP only
            // entity id is transformed if the original finding doesn't include the id from the graph
            isEntityIdTransformed = additionalFieldsValue.src?.id === entityId || additionalFieldsValue.dst?.id === entityId;

            if (isEntityIdTransformed) {
                queryParts.push(`magellan_network_src_id = '${entityId}'`);
                queryParts.push(`magellan_network_dst_id = '${entityId}'`);
            }
            if (additionalFieldsValue[entityType]?.address) {
                queryParts.push(`magellan_network_dst_address = '${additionalFieldsValue[entityType]?.address}'`);
                queryParts.push(`magellan_network_src_address = '${additionalFieldsValue[entityType]?.address}'`);
            }
            queryForGsl = queryParts.join(' or ');
            break;

        case INTELLIGENCE_TYPES.ACCOUNT_ACTIVITY:
            if (entityId) {
                queryParts.push(`magellan_activity_identity_id = '${entityId}'`);
                queryParts.push(`magellan_activity_target_id = '${entityId}'`);
            }
            if (entityName != entityId) {
                queryParts.push(`magellan_activity_identity_id = '${entityName}'`);
                queryParts.push(`magellan_activity_target_id = '${entityName}'`);
            }
            queryForGsl = queryParts.join(' or ');
            break;
    }
    return queryForGsl;
}

