import React from 'react';
import { useSelector } from 'react-redux';
import GcpActions from '../gcp.actions';
import { GcpSelector } from '../gcp.selectors';
import { RootState } from 'common/services/store/store';
import { GcpEnvironmentData } from 'common/module_interface/assets/GcpEnvironment';

export interface UseGetGcpPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isDeleted: boolean;
    data?: GcpEnvironmentData;
    reloadData: () => void;
}

export type UseGetGcp = (gcpId: string) => UseGetGcpPayload;

export const useGetGcp: UseGetGcp = (gcpId) => {
    const { loading, error, data, deleted } = useSelector((state: RootState) => GcpSelector.getGcp(state, gcpId)) || {};
    const getData = (id: string) => GcpActions.getGcpAccount(id);
    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(gcpId);
        }
    }, [gcpId, data, loading, error]);
    const reloadData = React.useCallback(() => getData(gcpId), [gcpId]);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        isDeleted: !!deleted,
        data,
        reloadData
    });
};