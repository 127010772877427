import { getStoreService, getUserService } from 'common/interface/services';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    AzureOnboardingAccountType,
    AzureOnboardingOperationMode,
    AzureOnboardingScope,
    AzureOnboardingType
} from '../../helpers/azure.interface';
import * as actions from '../../helpers/azure.reducer';
import { RenderViewParams } from 'common/components/Wizard/Wizard';
import { isChina } from 'common/utils/dataCenter';
import { logEvent } from 'common/utils/logUtils';
import { Checkbox, Typography, Stack, GroupSelection } from 'common/design-system/components-v2';
import AzureSingleOperation from '../../components/AzureSingleOperation';
import { azureOnboardingAccountTypeRadioBtns } from '../../helpers/azure.consts';

const AzureWelcome: React.FC<RenderViewParams> = ({ onValidate }) => {
    const { t } = useTranslation();
    const { dispatch } = getStoreService().getReduxTools();
    const formValidations = useSelector(actions.getAzureWelcomeFormValidations);
    const azureOnboardingType = useSelector(actions.getAzureOnboardingType);
    const azureOnboardingAccountType = useSelector(actions.getAzureOnboardingAccountType);
    const isAllowChinaPrivacyPolicy = useSelector(actions.getAllowAzureChinaPrivacyPolicy);

    useEffect(() => {
        onValidate(formValidations.valid);
        // adding 'onValidate' to deps will cause infinite useEffects
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValidations]);

    useEffect(() => {
        if (isChina()) handleAccountTypeSelect(AzureOnboardingAccountType.china);
        else handleOnboardingTypeSelect(AzureOnboardingType.single);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleOnboardingTypeSelect = (onboardingType: AzureOnboardingType) => {
        const accountType: AzureOnboardingAccountType = isChina() ? AzureOnboardingAccountType.china : AzureOnboardingAccountType.standard;
        const accountScope: AzureOnboardingScope = isChina() ? AzureOnboardingScope.chinaScope : AzureOnboardingScope.tenant;
        dispatch(actions.setAzureOnboardingType(onboardingType));
        handleAccountTypeSelect(accountType);
        handleAccountScopeSelect(accountScope);
        toggleChinaPolicy(false);
        if (onboardingType === AzureOnboardingType.single) {
            dispatch(actions.setAzureOnboardingOperationMode(AzureOnboardingOperationMode.read));
        }
    };

    const handleAccountTypeSelect = (accountType: AzureOnboardingAccountType) => {
        dispatch(actions.setAzureOnboardingAccountType(accountType));
        dispatch(actions.setCloudShellUrl(accountType));
        if (accountType === AzureOnboardingAccountType.china) {
            handleAccountScopeSelect(AzureOnboardingScope.chinaScope);
        } else {
            handleAccountScopeSelect(AzureOnboardingScope.tenant);
        }
    };

    const handleAccountScopeSelect = (accountScope: AzureOnboardingScope) => {
        dispatch(actions.setIsTenantIdAlreadyOnboarded(false));
        dispatch(actions.setIsManagementGroupIdAlreadyOnboarded(false));
        dispatch(actions.setAzureOnboardingScope(accountScope));
    };

    const toggleChinaPolicy = (allow: boolean) => {
        const user = getUserService().getUser();
        dispatch(actions.setAllowAzureChinaPrivacyPolicy(allow));
        if (allow) {
            logEvent('policyAllowed', {
                message: t('ON_BOARDING.WELCOME.CHINA_PRIVACY_POLICY_ACKED', { userEmail: user.email }),
                userEmail: user.email,
            });
        }
    };

    return (
        <Stack data-aid="azure-onboarding-welcome-wrapper">
            <Typography variant='h1'>{t('ON_BOARDING.AZURE.WELCOME_TITLE')}</Typography>
            <Stack spacing={1} margin={[5, 0, 0, 0]} direction='column'>
                <Typography variant='bodyLg'>{t('ON_BOARDING.AZURE.WELCOME_SUB_TITLE_WITHOUT')}</Typography>
                <Typography variant='bodyLg'>{t('ON_BOARDING.AZURE.WELCOME_SUB_TITLE_2')}</Typography>
            </Stack>
            <Stack margin={[3, 0, 2, 0]} direction='column'>
                <GroupSelection
                    options={azureOnboardingAccountTypeRadioBtns(t)}
                    onChange={(value: string) => handleAccountTypeSelect(value as AzureOnboardingAccountType)}
                    value={azureOnboardingAccountType}
                    direction="row"
                    label={t('ON_BOARDING.AZURE.SELECT_TYPE')}
                />
            </Stack>
            {azureOnboardingAccountType === AzureOnboardingAccountType.china &&
                <Stack margin={[2, 0, 4, 0]} direction="row" justifyContent="flex-start">
                    <Checkbox checked={isAllowChinaPrivacyPolicy} onChange={(ev: any) => toggleChinaPolicy(ev.target.checked)}>
                        {t('ON_BOARDING.WELCOME.CHINA_PRIVACY_POLICY_AGREEMENT')}
                    </Checkbox>
                </Stack>
            }
            {azureOnboardingType === AzureOnboardingType.single && (
                <Stack margin={[2, 0, 0, 0]} direction="row" justifyContent="flex-start">
                    <AzureSingleOperation/>
                </Stack>
            )}
        </Stack>
    );
};

export default AzureWelcome;
