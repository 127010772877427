import styled, { css } from 'styled-components/macro';

export const MultiEmailInputWrapper = styled.div`
    width: 100%;
    display: inline-block;
`;

export const MultiEmailChipsContainer = styled.div<{ isError: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;

  ${props => props.theme && css`
    padding: ${props.theme.spacing(1)}${props.theme.units};
    border: 1px solid ${props.isError ? props.theme.palette.border.alert : props.theme.palette.border.strong};

    input {
      border: none;
      outline: none;
      flex: 1;
      padding: ${props.theme.spacing(1)}${props.theme.units};
    }
  `}
`;

export const MultiEmailChip = styled.div`
  ${props => props.theme && css`
    background-color: ${props.theme.palette.surface.normal};
    padding: ${props.theme.spacing(1)}${props.theme.units} ${props.theme.spacing(2)}${props.theme.units};
    margin: ${props.theme.spacing(1)}${props.theme.units};
    display: flex;
    align-items: center;
    border-radius: 4px;

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding-left: ${props.theme.spacing(1)}${props.theme.units};
      color: black;
    }
  `}
`;

export const TimeRangeDropdown = styled.div`
    .select-tooltip label {
        color: transparent;
    }
`;

export const ReportModalContent = styled.div`
  width: 680px;

  .text-field {
    z-index: unset;
  }

  ${props => props.theme && css`
    padding: ${props.theme.spacing(1)}${props.theme.units} ${props.theme.spacing(4)}${props.theme.units} 0 ${props.theme.spacing(4)}${props.theme.units};

    label {
      font-weight: 500;
      margin-right: ${props.theme.spacing(2)}${props.theme.units};
      width: fit-content;

      span {
        color: ${props.theme.palette.surface.alert};
        padding-left: ${props.theme.spacing(0.5)}${props.theme.units};
      }
    }

    .error-select {
      .react-select__control {
        border-color: ${props.theme.palette.surface.alert};
      }
    }
  `}
`;

export const ReportTarget = styled.div<{ isVisible: boolean }>` {
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

export const HelperText = styled.div`
  display: flex;
  align-items: center;
  line-height: 12px;

  ${props => props.theme && css`
    padding-top: ${props.theme.spacing(1)}${props.theme.units};

    i {
      margin-right: ${props.theme.spacing(1)}${props.theme.units};
    }
  `}
`;

export const CronStringInputContainer = styled.div`
    display: flex;
    align-items: center;
`;
