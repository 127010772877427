import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { IDataAssetCategoryCounter } from './DataAssetCategories';
import { ermTrans } from './RiskManagement.utils';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';

export enum DataClassificationType {
    PII = 'PII',
    PCI = 'PCI',
    PHI = 'PHI',
    Credentials = 'Credentials',
    Other = 'Other',
}

export interface IDataClassificationInfo {
    type: DataClassificationType;
    name: string;
    getTitle: () => string;
    icon: AllIconsName;
    position: number;
}

export interface IClassificationCountersByCategories {
    classificationInfo: IDataClassificationInfo;
    categoryCounters: IDataAssetCategoryCounter[];
}

export interface IClassificationScoreBoxProps {
    categoryCounters: IDataAssetCategoryCounter[],
    classificationInfo: IDataClassificationInfo;
    filterValues?: IFiltersValues;
}

export type IDataClassificationsMap<T> = { [key in DataClassificationType]: T };

let position = 0;
export const DataClassificationsMap: IDataClassificationsMap<IDataClassificationInfo> = {
    [DataClassificationType.PII]: {
        type: DataClassificationType.PII,
        name: 'PII',
        icon: 'identityCard',
        position: position++,
        getTitle: () => ermTrans('DATA_SECURITY_DASHBOARD.DATA_CLASSIFICATION.PII'),
    },
    [DataClassificationType.PCI]: {
        type: DataClassificationType.PCI,
        name: 'PCI',
        icon: 'card',
        position: position++,
        getTitle: () => ermTrans('DATA_SECURITY_DASHBOARD.DATA_CLASSIFICATION.PCI'),
    },
    [DataClassificationType.PHI]: {
        type: DataClassificationType.PHI,
        name: 'PHI',
        icon: 'plusCircle',
        position: position++,
        getTitle: () => ermTrans('DATA_SECURITY_DASHBOARD.DATA_CLASSIFICATION.PHI'),
    },
    [DataClassificationType.Credentials]: {
        type: DataClassificationType.Credentials,
        name: 'Credentials',
        icon: 'key',
        position: position++,
        getTitle: () => ermTrans('DATA_SECURITY_DASHBOARD.DATA_CLASSIFICATION.CREDENTIALS'),
    },
    [DataClassificationType.Other]: {
        type: DataClassificationType.Other,
        name: 'Other',
        icon: 'moreHorizontal',
        position: position++,
        getTitle: () => ermTrans('DATA_SECURITY_DASHBOARD.DATA_CLASSIFICATION.OTHER'),
    },
};

export const getAllSortedClassifications = (): IDataClassificationInfo[] =>
    Object.values(DataClassificationsMap).sort((a, b) => a.position < b.position ? 0 : 1);

