import { isLocalhost } from 'common/utils/RuntimeEnvironment';
const baseUrl = `${window.location.origin}${isLocalhost ? '' : '/v2'}`;
export const azureManualOnboardingAssets = {
    accessReviewReadAll: baseUrl + '/images/assets/onboarding/azure/access-review-read-all.png',
    activeDirectoryCopyId: baseUrl + '/images/assets/onboarding/azure/active-directory-copy-id.png',
    activeDirectoryPropertiesTab: baseUrl + '/images/assets/onboarding/azure/active-directory-properties-tab.png',
    addPermissionButton: baseUrl + '/images/assets/onboarding/azure/add-a-permission-button.png',
    addAgain: baseUrl + '/images/assets/onboarding/azure/add-again.png',
    addButton: baseUrl + '/images/assets/onboarding/azure/add-button.png',
    addCustomRole: baseUrl + '/images/assets/onboarding/azure/add-custom-role.png',
    addPermissions: baseUrl + '/images/assets/onboarding/azure/add-permissions.png',
    addPermissions2: baseUrl + '/images/assets/onboarding/azure/add-permissions2.png',
    addRoleAssignmentA1: baseUrl + '/images/assets/onboarding/azure/add-role-assignment-a1.png',
    addRoleAssignment1: baseUrl + '/images/assets/onboarding/azure/add-role-assignment.png',
    apiPermissions1: baseUrl + '/images/assets/onboarding/azure/api-permissions.png',
    apiPermissions2: baseUrl + '/images/assets/onboarding/azure/api-permissions2.png',
    appPermissions: baseUrl + '/images/assets/onboarding/azure/app-permissions.png',
    appRegistration: baseUrl + '/images/assets/onboarding/azure/app-registration.png',
    appRegistrationAdd: baseUrl + '/images/assets/onboarding/azure/app-registration-add.png',
    appRegistrationAppInfo: baseUrl + '/images/assets/onboarding/azure/app-registration-app-info.png',
    basicTab: baseUrl + '/images/assets/onboarding/azure/basics-tab.png',
    certificatesSecrets: baseUrl + '/images/assets/onboarding/azure/certificates-secrets.png',
    descriptionExpirationPeriod: baseUrl + '/images/assets/onboarding/azure/description-expiration-period.png',
    directoryReadAll: baseUrl + '/images/assets/onboarding/azure/directory-read-all.png',
    dome9ConnectAppId: baseUrl + '/images/assets/onboarding/azure/dome9-connect-app-id.png',
    menuActiveDirectory: baseUrl + '/images/assets/onboarding/azure/menu-active-directory.png',
    menuAppRegistration: baseUrl + '/images/assets/onboarding/azure/menu-app-registration.png',
    menuSubscription: baseUrl + '/images/assets/onboarding/azure/menu-subscriptions.png',
    microConfigActions: baseUrl + '/images/assets/onboarding/azure/micro-config-actions.png',
    microConfigActions2: baseUrl + '/images/assets/onboarding/azure/micro-config-actions1.png',
    microsoftApis: baseUrl + '/images/assets/onboarding/azure/microsoft-apis.png',
    networkContributor: baseUrl + '/images/assets/onboarding/azure/network-contributor.png',
    clientSecret: baseUrl + '/images/assets/onboarding/azure/new-client-secret.png',
    policyReadAll: baseUrl + '/images/assets/onboarding/azure/policy-read-all.png',
    previouslyCreatedAppA1: baseUrl + '/images/assets/onboarding/azure/previously-created-app-a1.png',
    previouslyCreatedApp: baseUrl + '/images/assets/onboarding/azure/previously-created-app.png',
    redirectUri: baseUrl + '/images/assets/onboarding/azure/redirect-uri.png',
    registerBtn: baseUrl + '/images/assets/onboarding/azure/register-button.png',
    reportsReadAll: baseUrl + '/images/assets/onboarding/azure/reports-read-all.png',
    revealedKey: baseUrl + '/images/assets/onboarding/azure/revealed-key.png',
    reviewAssign: baseUrl + '/images/assets/onboarding/azure/review-assign.png',
    selectRoleRender: baseUrl + '/images/assets/onboarding/azure/select-role-reader.png',
    subscriptionId: baseUrl + '/images/assets/onboarding/azure/subscription-id.png',
    subscriptionUsers: baseUrl + '/images/assets/onboarding/azure/subscription-users.png',
    urlCloudguardGenerate: baseUrl + '/images/assets/onboarding/azure/url-cloudguard-will-generate.png',
    usersAddAccess: baseUrl + '/images/assets/onboarding/azure/users-add-access.png',
};