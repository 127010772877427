import styled from 'styled-components/macro';

const Container = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99999;
  border-radius: 5px;
  max-width: 110ch;
  overflow-x: auto;
  backdrop-filter: blur(25px);
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.8);
  
  .header-icon {
    height: 20px;
    width: 20px;
  }
`;

const SuggestionTop = styled.div`
  padding: 10px;
  font-weight: 500;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(205, 205, 205, 0.5);
  }
  
  .hidden {
    display: none;
  }
`;

const Suggestion = styled.div`
  font-size: 14px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 75px;
  margin: 10px 0 7px;
  padding: 5px;
  resize: none;
  opacity: 0.7;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const SuggestionPopupStyled = {
    Container,
    Suggestion,
    SuggestionTop,
    TextArea
};