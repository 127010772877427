import {
    getHttpService,
    ICustomizationService,
    ICustomzationObject,
    ICustomzationResponse,
} from 'common/interface/services';
import { AxiosError } from 'axios';

export class CustomizationService implements ICustomizationService {

    private getPathForGetCall(key: string): string {
        return `customization?component=${key}`;
    }
    
    private clearCacheForGetCall(key: string) {
        getHttpService().clearCacheByPath(this.getPathForGetCall(key));
    }

    deleteCustomization(id: string): Promise<ICustomzationResponse<any>> {
        return getHttpService().request<ICustomzationResponse<any>>(
            '/customization/' + id,
            { method: 'DELETE' },
            undefined,
            (error: AxiosError<any>) => {
                if (error.response?.status === 404) {
                    return [];
                }
                throw error;
            },
        );
    }

    setCustomization(componentName: string, key: string, data: any, scope?: string): Promise<ICustomzationObject<any>> {
        this.clearCacheForGetCall(componentName);
        return getHttpService().request<ICustomzationObject<any>>('customization', {
            method: 'POST',
            data: {
                component: componentName,
                data: {
                    name: key,
                    state: data,
                },
                scope,
            },
        });
    }

    async getCustomization(componentName: string): Promise<ICustomzationResponse<any>> {
        return getHttpService().request<ICustomzationResponse<any>>(
            this.getPathForGetCall(componentName),
            { method: 'GET' });
    }

    async getKeyData<T>(key: string): Promise<T | undefined> {
        return getHttpService().get<ICustomzationResponse<T>>(this.getPathForGetCall(key)).then(items => {
            return items?.length ? items[items.length - 1].data : undefined;
        });
    }

    async setKeyData<T>(key: string, data: T): Promise<T | undefined> {
        this.clearCacheForGetCall(key);
        return this.deleteKeyData(key).then(() => {
            return getHttpService().request<ICustomzationResponse<T>>('customization', {
                method: 'POST',
                data: {
                    component: key,
                    data,
                    scope: 'Private',
                },
            }).then(items => {
                return items?.length ? items[items.length - 1].data : undefined;
            });
        });
    }

    async deleteKeyData(key: string): Promise<any> {
        return getHttpService().get<ICustomzationResponse<any>>(this.getPathForGetCall(key)).then((items) => {
            this.clearCacheForGetCall(key);
            if (items) {
                const promises = items.map(item => {
                    return this.deleteCustomization(item.id);
                });
                return Promise.allSettled(promises);
            }
        });
    }
}