import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

export const JsonPayloadWrapper = styled(Stack)<{ isPayloadValid?: boolean }>`
    background-color: ${({ theme }) => theme.palette.surface.secondary};
    height: 300px;
    margin-top: 10px;
    textarea {
       resize: none;
       flex-grow:1;
       padding: 5px;
       background-color: ${({ theme }) => theme.palette.surface.secondary};
       overflow-y: auto;
       border-bottom: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
    }
    ul {
       border-left: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
       overflow-y: auto;
       width: 230px;
    }
   li {
      .item-title{
         margin-right: 10px;
      }
      div{
         width: 80%;
      }
      
      padding: 10px;
      margin-top: 10px;
      cursor: pointer;
      .copy-icon{
         display: none;
      }
      &:hover{
         .copy-icon{
            display: block;
        }
                  
      }
   }
   
   .jsonPayloadEditor{
      flex-grow: 1;
   }
   .json-valid-message{
      background-color: ${({ isPayloadValid, theme }) => isPayloadValid ? theme.palette.surface.successLight : theme.palette.surface.alertLight};
      align-items: center;
      padding: 0 10px;
      width: 30%;
      white-space: nowrap;
   }
   
   `;