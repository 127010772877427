import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { AddIntelligencePolicyPage } from './AddIntelligencePolicyPage';
import { EditPolicyPage } from './EditPolicyPage';
import { POLICY_SERVICE } from 'common/interface/policy';
import { IntelligenceService } from '../Services/IntelligenceService';
const INTELLIGENCE = 'intelligence';
export default function initialize() {

    const policyPageRoute = {
        condition: true,
        component: AddIntelligencePolicyPage,
        path: '/intelligence/policy/add',
    };

    const policyEditPageRoute = {
        condition: true,
        component: EditPolicyPage,
        path: '/intelligence/policy/edit',
    };

    globalAddinContainer.add(POLICY_SERVICE, [{ id: 'intelligence-policy-service', content: { moduleName: INTELLIGENCE,service: new IntelligenceService() } }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'intelligence-Policy', content: policyPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'intelligence-edit-Policy', content: policyEditPageRoute }]);

}

