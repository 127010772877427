import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import {
    GROUPING_COUNT_FIELD_NAME,
    IFlatIssueSearchResult,
    IGroupingData,
    IIssuesGroup,
    IIssuesGroupWithTotal,
    IIssuesTableData,
    IIssuesTableDatasourceParams,
    IIssuesTableDataWithTotals,
    IIssueTableDataState,
    IServerInputSort,
} from 'modules/riskManagement/components/Issues/Issues.interface';
import { ColumnVO } from 'ag-grid-community/dist/lib/interfaces/iColumnVO';
import {
    fetchFlatIssues,
    fetchFlatIssuesWithTotal,
    fetchGroupingTreeWithTotal,
    getFinalIssuesFilterDetails,
    getGridGroupingCols,
    getGroupingFieldNames,
    getSortModelForColumn,
    isEqualGrouping,
} from '../../Issues.utils';
import { IIssue, IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { LoadingState, SortDirection } from 'common/interface/general';
import { ITableApis } from '../../../../RiskManagement.interface';
import { gridSortToServerFormat, refreshTable } from '../../../../RiskManagement.utils';
import { IFilterCondition } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';

const DEFAULT_SORT_DEF: IServerInputSort[] = [
    {
        fieldName: 'severity',
        direction: SortDirection.DESC,
    },
    {
        fieldName: 'createdTime',
        direction: SortDirection.DESC,
    },
];

const EMPTY_TABLE_DATA: IIssuesTableData = {
    issuesOrGroups: [],
    itemCount: 0,
};

export class IssuesTableDatasource implements IServerSideDatasource {
    private apis: ITableApis<IIssue>;
    private groupingData?: IGroupingData;
    private filterDetails: IServerInputFilterDetails;
    private readonly onDataStateChange?: (dataState?: IIssueTableDataState) => void;

    constructor(params: IIssuesTableDatasourceParams) {
        this.apis = {
            gridApi: params.gridApi,
            columnApi: params.gridColApi,
        };
        this.onDataStateChange = params.onDataStateChange;
        this.filterDetails = {};
    }

    onTableDataStateChange(dataState?: IIssueTableDataState) {
        this.onDataStateChange && this.onDataStateChange(dataState);
    }

    getSortModels(params: IServerSideGetRowsParams<IIssue>): IServerInputSort[] {
        return gridSortToServerFormat<IIssue>(params) || DEFAULT_SORT_DEF;
    }

    async fetchIssues(sortModels: IServerInputSort[]): Promise<IIssuesTableDataWithTotals> {
        return fetchFlatIssuesWithTotal(this.filterDetails, sortModels);
    }

    async fetchGroupedIssues(params: IServerSideGetRowsParams<IIssue>, groupedCols: ColumnVO[], sortModels: IServerInputSort[]): Promise<IIssuesTableData> {
        if (!this.groupingData || !isEqualGrouping(this.groupingData.cols, groupedCols)) {
            const sortDefArray: IServerInputSort[] = [];

            const columnsDefs = groupedCols
                .map(col => this.apis.columnApi.getColumn(col.id)?.getColDef() as CGColDef | undefined)
                .filter(colDef => colDef?.field);
            
            columnsDefs.forEach((colDef) => {
                if (colDef?.groupOrder) {
                    sortDefArray.push({
                        fieldName: colDef.field ?? '',
                        direction: colDef.groupOrder,
                    });
                } else {
                    sortDefArray.push({
                        fieldName: GROUPING_COUNT_FIELD_NAME,
                        direction: SortDirection.DESC,
                    });
                }
            });

            const issuesGroupWithTotal: IIssuesGroupWithTotal = await fetchGroupingTreeWithTotal(groupedCols, this.apis.gridApi, this.filterDetails, sortDefArray);
            const rootGroup: IIssuesGroup = issuesGroupWithTotal.issuesGroup;
            let itemCount = 0;
            rootGroup.children?.forEach(child => {
                itemCount += child.childCount;
            });

            this.groupingData = {
                cols: groupedCols,
                rootGroup,
                itemCount,
                totalCount: issuesGroupWithTotal.totalCount,
            };
        }

        const selectedGroup = params.parentNode?.data ? params.parentNode.data as IIssuesGroup : this.groupingData.rootGroup;
        if (!selectedGroup) {
            return EMPTY_TABLE_DATA;
        }

        if (selectedGroup.isLeafGroup) {
            const colFieldNames = getGroupingFieldNames(groupedCols);
            const filterFields: IFilterCondition[] = this.filterDetails.fields ? this.filterDetails.fields.filter((field: IFilterCondition) => !colFieldNames.includes(field.name)) : [];
            if (selectedGroup.groupedFields) {
                selectedGroup.groupedFields.forEach((field: IFilterCondition) => filterFields.push(field));
            }
            const filterDetails: IServerInputFilterDetails = {
                ...this.filterDetails,
                fields: filterFields,
            };
            return fetchFlatIssues(filterDetails, sortModels).then((result: IFlatIssueSearchResult) => {
                return {
                    issuesOrGroups: result.issues,
                    itemCount: result.itemCount,
                };
            });
        } else {
            return {
                issuesOrGroups: selectedGroup.children,
                itemCount: selectedGroup.children.length,
            };
        }
    }

    initiateTableRefresh() {
        refreshTable(this.apis.gridApi);
    }

    async setFilterFields(filterValues: IServerInputFilterDetails) {
        this.filterDetails = getFinalIssuesFilterDetails(filterValues);
        this.groupingData = undefined;
        this.initiateTableRefresh();
    }

    getRows(params: IServerSideGetRowsParams<IIssue>) {
        const groupedCols = getGridGroupingCols(params);
        const sortModels = this.getSortModels(params);
        if (groupedCols) {
            if (!this.groupingData?.rootGroup?.children) {
                this.onTableDataStateChange(LoadingState.IS_LOADING);
            }
            this.fetchGroupedIssues(params, groupedCols, sortModels).then((tableData: IIssuesTableData) => {
                const topChildrenCount: number | undefined = this.groupingData?.rootGroup?.children?.length;
                if (topChildrenCount === undefined) {
                    this.onTableDataStateChange(LoadingState.LOADING_FAILED);
                } else {
                    this.onTableDataStateChange({
                        itemCount: this.groupingData?.itemCount || 0,
                        groupCount: topChildrenCount,
                        totalCount: this.groupingData?.totalCount || 0,
                    });
                }
                params.success({
                    rowData: tableData.issuesOrGroups,
                    rowCount: tableData.itemCount,
                });
            }).catch(() => {
                this.onTableDataStateChange(LoadingState.LOADING_FAILED);
                params.fail();
            });
        } else {
            this.onTableDataStateChange(LoadingState.IS_LOADING);
            this.fetchIssues(sortModels).then((dataWithTotals: IIssuesTableDataWithTotals) => {
                this.onTableDataStateChange(
                    {
                        itemCount: dataWithTotals.issuesTableData.itemCount,
                        totalCount: dataWithTotals.totalCount,
                    }
                );
                params.success({
                    rowData: dataWithTotals.issuesTableData.issuesOrGroups,
                    rowCount: dataWithTotals.issuesTableData.itemCount,
                });
            }).catch(() => {
                this.onTableDataStateChange(LoadingState.LOADING_FAILED);
                params.fail();
            });
        }
    }

    async getExportData(): Promise<IIssuesTableData> {
        const columnState = this.apis.columnApi.getColumnState();
        const sortModels: IServerInputSort[] = [];
        columnState.forEach((state) => {
            if (state.sort) {
                const sortModel = getSortModelForColumn(this.apis.columnApi, state.colId, state.sort);
                if (sortModel) {
                    sortModels.push(sortModel);
                }
            }
        });
        return this.fetchIssues(sortModels).then((dataWithTotals: IIssuesTableDataWithTotals) => dataWithTotals.issuesTableData);
    }
}
