import styled from 'styled-components';
import Stack from 'common/design-system/components-v2/Stack';

const TopDiv = styled(Stack)`
  flex-direction: column;
  margin: 20px;
  width: 90%;
  gap: 10px;
  height: calc(100vh - 120px);
`;

const Header = styled(Stack)<{ isChanged?: boolean }>`
  flex-direction: column;
  opacity: ${props => props.isChanged ? '1.0' : '0.1'};
`;

const TopSep = styled.div`
  border-bottom: 1px solid #ddd;
  margin: 0 0 5px;
`;

const TablesDiv = styled(Stack)`
  padding-top: 24px;
    gap: 24px;
`;

export const ManagerStyled = {
    TopDiv,
    TablesDiv,
    TopSep,
    Header,
};
