import { IColumnUsageDef, IFieldInfo } from 'common/interface/general';
import { ColDef } from 'ag-grid-enterprise';
import { RiskManagementRegistry } from '../../../../../RiskManagementRegistry';
import { mergeColumnDefs } from 'common/utils/tableUtils';
import { RISK_RANGES } from '../../../../../consts';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

const getColumnDefs = (routeFields?: IFieldInfo[]): IColumnUsageDef[] => {
    return [
        {
            id: 'ermRiskScore',
            colDefOverride: {
                width: 110,
                minWidth: 50,
            },
        },
        {
            id: 'type',
            colDefOverride: {
                flex: 10,
                cellRendererParams: { onClick: (typeByPlatform: string) => getErmUrlsService().goToProtectedAssetsForType(typeByPlatform, routeFields), showIcon: true, routeFields },
            },
        },
        {
            id: 'critical',
            colDefOverride: {
                width: 95,
                minWidth: 50,
                cellRendererParams: { riskScoreRange: RISK_RANGES.CRITICAL, routeFields },
            },
        },
        {
            id: 'high',
            colDefOverride: {
                width: 95,
                minWidth: 50,
                cellRendererParams: { riskScoreRange: RISK_RANGES.HIGH, routeFields },
            },
        },
        {
            id: 'total',
            colDefOverride: {
                width: 85,
                minWidth: 50,
                cellRendererParams: { routeFields },
            },
        },
    ];
};

export const getAssetTypesColumnDefs = (routeFields?: IFieldInfo[]): ColDef[] => {
    return mergeColumnDefs(getColumnDefs(routeFields), RiskManagementRegistry.getAssetTypesTableColumnDefs());
};