import {
    CircuitBreakerStatus,
    getHttpService,
    INotificationPageService,
    ISlimNotification
} from 'common/interface/services';
import {
    INotification,
    ISaveNotificationRequest,
    ISelectedConfigurations
} from '../Interfaces/NotificationPageInterfaces';
import {
    NOTIFICATION_LIST_NAME_IN_REQUEST,
    NOTIFICATIONS_CATEGORIES_IDS,
} from '../NotificationsPage.consts';
import { INewRawNotification } from '../Components/AddEditNotificationModal/NotificationAddEditModal';
import { OutputTypes } from '../Components/OutputTypeSelector/interfaces';
import { NOTIFICATIONS_TARGET_IDS_DTO_MAPPER } from 'common/interface/notifications';

interface IReportsIntegration {
    integrationId: string,
    filter: any
}

interface IschedueledIntegration {
    integrationId: string,
    outputType: string,
    cronExpression: string
}

interface ISingleNotificationIntegrationSetting {
    integrationId: string,
    outputType?: string,
    filter?: any,
    payload?:any
}

export const ConvertOutputTypeToDTO = (outputType: OutputTypes) => {
    switch (outputType) {
        case OutputTypes.DEFAULT:
            return 'Default';
        case OutputTypes.JSON_BASIC_ENTITY:
            return 'JsonSimpleEntity';
        case OutputTypes.JSON_FULL_ENTITY:
            return 'JsonFullEntity';
        case OutputTypes.PLAIN_TEXT:
            return 'PlainText';
        default:
            return undefined;
    }
};

export const ConvertDTOToOutputType = (outputType: string) => {
    switch (outputType) {
        case 'Default':
            return OutputTypes.DEFAULT;
        case 'JsonSimpleEntity':
            return OutputTypes.JSON_BASIC_ENTITY;
        case 'JsonFullEntity':
            return OutputTypes.JSON_FULL_ENTITY;
        case 'PlainText':
            return OutputTypes.PLAIN_TEXT;
        default:
            return undefined;
    }
};

function AdjustFilterToDTO(notification: INewRawNotification) {
    const filterKeys = Object.keys(notification.filter);
    for (const key of filterKeys) {
        if (Array.isArray(notification.filter[key]) && notification.filter[key].length === 0) {
            notification.filter[key] = null;
        } else {
            if (notification.filter.entityTags?.length === 1 && notification.filter.entityTags[0].key === '' && notification.filter.entityTags[0].value === '') {
                notification.filter.entityTags = null;
            }
        }
    }
    return notification;
}

export class NotificationPageService implements INotificationPageService {

    async getWebhookJiraTokens (useCache = true) {
        return await getHttpService().get<INotification[]>({
            path: 'Compliance/ContinuousComplianceNotification/webhookJiraTokens',
            cachingConfig: { useCache } });
    }

    async getAllNotifications (useCache = true) {
        return await getHttpService().get<INotification[]>({
            path: 'notification/enriched',
            cachingConfig: { useCache } });
    }

    async getAllNotificationsSlim (useCache = true) {
        return await getHttpService().get<ISlimNotification[]>({
            path: 'notification/slim',
            cachingConfig: { useCache } });
    }
    async getNotificationById (notificationId?: string) {
        return await getHttpService().get<INotification>({
            path: `notification/enriched/${notificationId}`,
        });
    }


    async getAllNotificationsCircuitBreakers (useCache = true) {
        return await getHttpService().get<CircuitBreakerStatus[]>({
            path: 'Compliance/ContinuousComplianceNotification/CircuitBreaker',
            cachingConfig: { useCache } });
    }

    async saveNotification (notification: INewRawNotification) : Promise<string> {
        let sendOnEachOccurrence = false;
        const reportsIntegrationSettings : IReportsIntegration[] = [];
        const singleNotificationIntegrationSettings: ISingleNotificationIntegrationSetting[] = [];
        const scheduledIntegrationSettings: IschedueledIntegration[] = notification.scheduledReport.configurationId ? [
            {
                integrationId: notification.scheduledReport.configurationId,
                outputType: notification.scheduledReport.outputType,
                cronExpression: notification.scheduledReport.cronExpression
            }
        ] : [];
        const fillNotificationListModalFunctions: { [key: string]: Function } = {
            [NOTIFICATION_LIST_NAME_IN_REQUEST.SINGLE]: (configuration: ISelectedConfigurations,integrationPayload:string,outputType:OutputTypes) => {
                if(notification.filter){
                    notification = AdjustFilterToDTO(notification);
                }
                singleNotificationIntegrationSettings.push({ integrationId: configuration.id!,
                    outputType: outputType,
                    filter: configuration.filter || notification.filter || null,
                    payload: integrationPayload || null });
            },
            [NOTIFICATION_LIST_NAME_IN_REQUEST.REPORT]: (configuration: ISelectedConfigurations) => {
                if(notification.filter){
                    notification = AdjustFilterToDTO(notification);
                }
                reportsIntegrationSettings.push({
                    integrationId: configuration.id!,
                    filter: configuration.filter || notification.filter || null });

            }
        };

        const selectedCategories = notification.selectedCategories;
        Object.keys(selectedCategories).forEach(categoryId => {
            if(categoryId === NOTIFICATIONS_CATEGORIES_IDS.GENERAL_SECURITY_EVENTS_PER_OCCURRENCE){
                sendOnEachOccurrence = selectedCategories[categoryId].isChecked;
            }
            const categoryObject = selectedCategories[categoryId];
            if(categoryObject.selectedConfiguration?.id){
                fillNotificationListModalFunctions[categoryObject.notificationType](categoryObject.selectedConfiguration,categoryObject.integrationPayload,categoryObject.outputType);
            }
        });

        return new Promise((resolve, reject) => {
            const requestObj:ISaveNotificationRequest = {
                name: notification.name,
                description: notification.description,
                alertsConsole: notification.alertConsole,
                sendOnEachOccurrence: sendOnEachOccurrence,
                origin: 'ComplianceEngine',
                integrationSettingsModel: {
                    reportsIntegrationSettings: reportsIntegrationSettings,
                    singleNotificationIntegrationSettings: singleNotificationIntegrationSettings,
                    scheduledIntegrationSettings: scheduledIntegrationSettings
                }
            };
            getHttpService().request<any>(
                'notification',
                {
                    method: !notification.id ? 'POST' : 'PUT',
                    data: !notification.id ? requestObj : { ...requestObj, id: notification.id },
                },
                {},
                (error) => {
                    reject(error?.response?.data || error?.message || 'server error');
                }
            ).then((resp) => {
                resolve(resp);
            });
        });
    }

    deleteNotification (notificationId :string) : void {
        getHttpService().delete<void>(`notification/${notificationId}`);
    }

    async clearIntegrationIssueInCircuitBreaker (notificationId :string,integrationType:keyof typeof NOTIFICATIONS_TARGET_IDS_DTO_MAPPER) {
        await getHttpService().delete<void>(`Compliance/ContinuousComplianceNotification/CircuitBreaker/${notificationId}/${integrationType.toString()}`);
    }

    async testJiraNotification (configurations:any) {
        return new Promise((resolve, reject) => {
            getHttpService().request<any>(
                'Compliance/ContinuousComplianceNotification/testWebhook',
                {
                    method: 'POST',
                    data: configurations
                },
                {},
                (error) => {
                    reject('test failed. '.concat(error?.response?.data?.message || ''));
                }
            ).then((resp) => {
                if(resp){
                    resolve('test succeeded');
                }
            });
        });
    }
}
