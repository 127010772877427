import React, { useMemo } from 'react';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import PropertyBox from 'common/components/PropertyBox/PropertyBox';
import FindingMitreSection from '../FindingMitreSection';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';

export const MitrePanel: React.FC<{ finding: IFinding }> = ({ finding }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const mitreDict = finding.mitreDict;

    const tagsView = useMemo(() => {
        return <FindingMitreSection mitreDetails={mitreDict}/>;
    }, [mitreDict]);

    return (
        mitreDict &&
        <PropertyBoxesRow>
            <PropertyBox title={t('INT_FINDING_OVERVIEW.MITRE.TITLE')} content={tagsView} />
        </PropertyBoxesRow>
    );
};
