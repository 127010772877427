import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { OciCloudAccountResetMissingPermissions, OciCloudAccountsAttachOrganizationalUnit } from './ociCloudAccountApi.interface';

const apiBaseUrl = 'oci-cloud-account';

const attachOrganizationalUnit: OciCloudAccountsAttachOrganizationalUnit.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<OciCloudAccountsAttachOrganizationalUnit.Response>>(
        `${apiBaseUrl}/organizational-unit/attach`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const resetMissingPermissions: OciCloudAccountResetMissingPermissions.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<OciCloudAccountResetMissingPermissions.Response>>(
        `${apiBaseUrl}/${request.id}/missing-permissions/reset`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};


const CloudAccountsApi = {
    attachOrganizationalUnit,
    resetMissingPermissions,
};

export default CloudAccountsApi;
