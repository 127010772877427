import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ParameterDisplay } from './components/Components';
import {
    getMemberAccountArn,
    getOrgOnboardingArnRole,
    getOrgOnboardingManagementAccountExternalId,
    getOrgOnboardingOrganizationName,
} from './helpers/aws.reducer';
import { defaultOrgName } from './helpers/aws.const';

const AwsOrgOnboardManagementAccount: FC = ()=> {
    const { t } = useTranslation();
    const externalId = useSelector(getOrgOnboardingManagementAccountExternalId);
    const managementStackArn = useSelector(getOrgOnboardingArnRole);
    const organizationName = useSelector(getOrgOnboardingOrganizationName);
    const memberAccountArn = useSelector(getMemberAccountArn);

    return <div>
        <div className='title-large'>{t('ON_BOARDING.ORG_ONBOARDING_ONBOARD_MANAGEMENT_ACCOUNT.TITLE')}</div>
        
        <div className='w-2/3 my-10'>
            <div className='text-md mb-10'>
                {t('ON_BOARDING.ORG_ONBOARDING_ONBOARD_MANAGEMENT_ACCOUNT.PARAMETERS')}
            </div>
            <ParameterDisplay 
                title={t('ON_BOARDING.ORG_ONBOARDING_ONBOARD_MANAGEMENT_ACCOUNT.MANAGEMENT_ACCOUNT_EXTERNAL_ID')}
                value={externalId}
            />
            <ParameterDisplay 
                title= {t('ON_BOARDING.ORG_ONBOARDING_ONBOARD_MANAGEMENT_ACCOUNT.MANAGEMENT_STACK_ARN')}
                value={managementStackArn}
            />
            <ParameterDisplay 
                title= {t('ON_BOARDING.ORG_ONBOARDING_ONBOARD_MANAGEMENT_ACCOUNT.ORGANIZATION_NAME')}
                value={organizationName || defaultOrgName}
            />
            <ParameterDisplay 
                title= {t('ON_BOARDING.ORG_ONBOARDING_ONBOARD_MANAGEMENT_ACCOUNT.MEMBER_ACCOUNT_ARN')}
                value={memberAccountArn}
            />
        </div>
    </div>;
};

export default AwsOrgOnboardManagementAccount;
