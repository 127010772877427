import { Addin } from 'common/extensibility/AddinRegistry';
import { EventsDrawerAddingBase, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { OUName } from '../Components/OUName/OUName';
import { fixOriginName } from 'modules/events/helpers/fixOriginName';
import { formatDate, numberWithCommas } from 'common/utils/helpFunctions';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { Chip, ComponentOverflow, Icon, Tooltip } from 'common/design-system/components-v2';
import { IEvent } from 'common/module_interface/events/Events';
import { getCloudAccountsService } from 'common/interface/data_services';
import { getVendor } from 'common/consts/vendors';

const getAlertId = (eventData: IEvent) => {
    const isExternalFinding = eventData.origin === IFindingModelOriginString.ExternalFindingSource || eventData.origin === IFindingModelOriginString.Tenable;
    const isKubernetesImageScanning = eventData.origin === IFindingModelOriginString.KubernetesImageScanning;
    return (isKubernetesImageScanning || isExternalFinding) ? eventData.ruleId : eventData.findingKey;
};

const detailsPanelItems: () => Addin<EventsDrawerAddingBase<BlockInfoProps>>[] = () => {
    return [
        {
            id: 'organizationalUnit',
            content: {
                id: 'organizationalUnit',
                getValue: (event) => {
                    return {
                        title: 'Organizational Unit',
                        info: <OUName event={event} />,
                    };
                },
            },
        },
        {
            id: 'environment',
            content: {
                id: 'environment',
                getValue: async (event) => {
                    const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(event.cloudAccountId);
                    if (!cloudAccount) {
                        return {
                            title: 'Environment',
                            info: event.cloudAccountId,
                        };
                    }
                    const vendor = getVendor(cloudAccount.platform);
                    return {
                        title: 'Environment',
                        info: cloudAccount.name,
                        iconProps: vendor?.newIcon ? { name: vendor.newIcon } : undefined,
                        internalUrl: `/cloud-account/${cloudAccount.platform}/${event.cloudAccountId}`,
                    };
                },
            },
        },
        {
            id: 'source',
            content: {
                id: 'source',
                getValue: (event) => {
                    return {
                        title: 'Source',
                        info: fixOriginName(event.origin),
                    };
                },
            },
        },
        {
            id: 'date',
            content: {
                id: 'date',
                getValue: (event) => {
                    return {
                        title: 'Date',
                        info: formatDate(event.createdTime),
                    };
                },
            },
        },
        {
            id: 'category',
            content: {
                id: 'category',
                getValue: (event) => {
                    return {
                        title: 'Category',
                        info: event.category,
                    };
                },
                isRelevant: (event) => !!event.category,
            },
        },
        {
            id: 'alertId',
            content: {
                id: 'alertId',
                getValue: (event) => {
                    return {
                        title: 'Alert ID',
                        info: getAlertId(event),
                        copyable: true,
                    };
                },
                isRelevant: (event) => !!getAlertId(event),
            },
        },
        {
            id: 'action',
            content: {
                id: 'action',
                getValue: (event) => {
                    return {
                        title: 'Action',
                        info: event.action,
                    };
                },
            },
        },
        {
            id: 'occurrences',
            content: {
                id: 'occurrences',
                getValue: (event) => {
                    return {
                        title: 'Occurrences',
                        info: event.occurrences?.length ? numberWithCommas(event.occurrences.length) : '0',
                    };
                },
                isRelevant: (event) => !!event.occurrences && event.occurrences.length > 1,
            },
        },
        {
            id: 'assignee',
            content: {
                id: 'assignee',
                getValue: (event) => {
                    return {
                        title: 'Assignee',
                        info: event.ownerUserName || 'Unassigned',
                    };
                },
            },
        },
        {
            id: 'ruleset',
            content: {
                id: 'ruleset',
                getValue: (event) => {
                    return {
                        title: 'Ruleset',
                        info: event.bundleName,
                    };
                },
            },
        },
        {
            id: 'acknowledge',
            content: {
                id: 'acknowledge',
                getValue: () => {
                    return {
                        title: 'Acknowledged',
                        info: '',
                        iconProps: { name: 'checkCircle', color: 'success' },
                    };
                },
                isRelevant: (event) => event.acknowledged,
            },
        },
        {
            id: 'remediation',
            content: {
                id: 'remediation',
                getValue: () => {
                    return {
                        title: 'Remediation',
                        info: (
                            <Tooltip content='Event has remediation actions' placement='bottom'>
                                <Icon name='checkCircle' color='success' />
                            </Tooltip>
                        ),
                    };
                },
                isRelevant: (event) => {
                    const hasActions = event.remediationActions?.length > 0;
                    const notRelevantOrigins: IFindingModelOriginString[] = [IFindingModelOriginString.Serverless, IFindingModelOriginString.ContainersRuntimeProtection];
                    return hasActions && !notRelevantOrigins.includes(event.origin);
                },
            },
        },
        {
            id: 'excluded',
            content: {
                id: 'excluded',
                getValue: () => {
                    return {
                        title: 'Excluded',
                        info: '',
                        iconProps: { name: 'checkCircle', color: 'success' },
                    };
                },
                isRelevant: (event) => event.isExcluded,
            },
        },
        {
            id: 'complianceSections',
            position: Infinity,
            content: {
                id: 'complianceSections',
                getValue: (event) => {
                    return {
                        title: 'Compliance Sections',
                        info: <ComponentOverflow components={
                            (event.tag || '').split('|').map((tag, index) => <Chip label={tag} disableInteraction key={index} />)
                        } />,
                        maxWidth: undefined,
                        fullWidth: true,
                        copyable: true,
                    };
                },
                isRelevant: (event) => !!event.tag,
            },
        },
    ];
};

export default function initializeDetailsPanelItems() {
    EventsDrawerRegistry.addDetailsPanelItems(detailsPanelItems());
}