import styled from 'styled-components/macro';
import { AgGridReact } from 'ag-grid-react';

export const FormWrapper= styled.div`
  width: 50%;
`;

export const BtnWrapper = styled.div`
  display: inline-block;
  margin-left: 3px;
`;

export const Line=styled.div`
  height: ${({ theme }) => `${theme.border.width.standard}${theme.units}`};
  background-color: ${({ theme }) => `${theme.palette.surfaceStates.tertiaryActive}`};
`;

export const CDRContainer= styled.div`
  border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.surfaceStates.tertiaryActive}`};
  background-color: ${({ theme }) => `${theme.palette.surfaceStates.primaryFocus}`};
  padding: ${({ theme }) => `${theme.spacing(5)}${theme.units}`};
  border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
  width: 100%;
  margin-bottom: ${({ theme }) => `${theme.spacing(4)}${theme.units}`};
`;

export const ShellUrl= styled.a`
  color: #3594ff !important;
  cursor: pointer;
`;

export const Arrow = styled.span`
    margin-left: 5px;
    font-size: 16px;
    line-height: 1;
    text-decoration: underline;
    transition: all 0.3s linear;
    &.down{
      transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      display: inline-block;
      font-size: 16px;
      line-height: 1;
      text-decoration: none;
    }
`;

export const ALink = styled.a`
  margin-top: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
  color: ${({ theme }) => theme.palette.status.info};
    :visited, :hover {
        color: ${({ theme }) => theme.palette.status.info};
    }
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }  
`;

export const ConfigWrapper = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.palette.surface.primary};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    border: ${({ theme }) => `1px solid ${theme.palette.surfaceStates.tertiaryActive}`};
`;


export const AgGridReactWrapper = styled(AgGridReact)`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
  overflow-y: auto;
  max-height: 350px;
`;
