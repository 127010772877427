import React from 'react';
import { EmptyStyle } from './EmptyState.styled';
import emptyStateIcon from '../../../../assets/logos/dspm-logo.svg';
import macieIcon from 'assets/integrations/Icons/Amazon_macie.svg';
import purviewIcon from 'assets/integrations/Icons/Microsoft_purview.svg';
import sentraIcon from 'assets/integrations/Icons/Sentra.svg';
import { Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { DocUrlEnum, I18nRiskNamespace } from '../../../../consts';
import { DocLinkButton } from 'common/erm-components/custom/DocLinkButton/DocLinkButton';

const IconLabel: React.FC<{ src: string, text: string }> = ({ src, text }) => {
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <img src={src} height='24px' width='24px' alt={''}/>
            <Typography variant={'subtitleLg'}>{text}</Typography>
        </Stack>
    );
};

export const EmptyState: React.FC = () => {
    const { t } = useTranslation(I18nRiskNamespace);

    return (
        <EmptyStyle.TopDiv fullWidth fullHeight direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={8}>
            <img src={emptyStateIcon} height='96px' width='96px' alt={''}/>
            <Typography variant={'h1'}>{t('DATA_SECURITY_DASHBOARD.EMPTY_STATE.ENHANCE_DATA_SECURITY')}</Typography>
            <Typography variant={'h2'}>{t('DATA_SECURITY_DASHBOARD.EMPTY_STATE.CHOOSE_CONFIGURATION')}</Typography>
            <Stack direction={'row'} alignItems={'center'} padding={[8, 0, 0]}>
                <Stack direction={'column'} spacing={4} alignItems={'center'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={6} padding={[0, 0, 5]}>
                        <IconLabel src={macieIcon} text={t('DATA_SECURITY_DASHBOARD.EMPTY_STATE.MACIE')} />
                        <Typography variant={'subtitleLg'}>or</Typography>
                        <IconLabel src={purviewIcon} text={t('DATA_SECURITY_DASHBOARD.EMPTY_STATE.PURVIEW')} />
                    </Stack>
                    <Typography >{t('DATA_SECURITY_DASHBOARD.EMPTY_STATE.FOLLOW_SETUP_GUIDE')}</Typography>
                    <DocLinkButton docUrl={DocUrlEnum.DATA_SENSITIVITY} label={t('DATA_SECURITY_DASHBOARD.EMPTY_STATE.READ_GUIDE')} />
                </Stack>
                <EmptyStyle.SepDiv />
                <Stack direction={'column'} spacing={4} alignItems={'center'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={6} padding={[0, 0, 5]}>
                        <IconLabel src={sentraIcon} text={t('DATA_SECURITY_DASHBOARD.EMPTY_STATE.SENTRA')} />
                    </Stack>
                    <Typography >{t('DATA_SECURITY_DASHBOARD.EMPTY_STATE.CONFIGURE_DIRECTLY')}</Typography>
                    <DocLinkButton docUrl={DocUrlEnum.SENTRA} label={t('DATA_SECURITY_DASHBOARD.EMPTY_STATE.CONFIGURE')} />
                </Stack>
            </Stack>
        </EmptyStyle.TopDiv>
    );
};
