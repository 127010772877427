import React from 'react';
import { TextField, Select, Button, Tooltip } from '@dome9/berries/react-components';
import { HeaderProps } from '../Models/HeaderProps';
import { SingleValue } from 'react-select';
import { ISelectOption } from 'common/interface/general';

export const HeaderComponent: React.FC<HeaderProps> = (props: HeaderProps) => {
    const { clearGraphCallback, submitQueryCallback, errorMsg, queries, cloudAccounts, runBtnDisable, runBtnText } = props;
    return (<>
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <div className={'insight-environment'} style={{ marginTop: '-3px' }}>
                    <Select
                        required={false}
                        closeMenuOnSelect
                        isSearchable
                        options={cloudAccounts || []}
                        onChange={(option: SingleValue<ISelectOption>) => {
                            props.setEnvValueCallback(option?.value ?? '');
                            clearGraphCallback();
                        }}
                        placeholder = 'Environment'
                    />
                </div>

                <div className={'insight-query'} style={{ marginTop: '-3px' }}>
                    <Select
                        required={false}
                        closeMenuOnSelect
                        isSearchable
                        placeholder = 'Assets'
                        options={queries}
                        onChange={(option: SingleValue<ISelectOption>) => {
                            props.setQueryValueCallback(option?.value ?? '');
                        }}
                    />
                </div>

                <div className={'insight-search'}>
                    <TextField value={props.search} type={'text'} onChange={(e: any) => props.setSearchValueCallback(e.target.value)}
                        id='standard-basic' style={{ width: '350px' }}
                        placeholder={'Search'}
                        onClear={() => props.setSearchValueCallback('')}
                    />
                </div>

                <div className={'insight-node-limit'}>
                    <Tooltip
                        content='Number of maximum nodes which should return from the query (up to 1000). Empty or 0 has no restrictions.'
                        aria-label='add'>
                        <>
                            <TextField
                                type='number'
                                value={props.nodesLimit} onChange={event => {
                                    if (Number(event.target.value) > 1000) {
                                        event.target.value = '1000';
                                    }
                                    props.setQueryNodeLimitCallback(parseInt(event.target.value));
                                }}/>
                            <span className={'node-text'}>nodes</span>
                        </>
                    </Tooltip>
                </div>

                <div className={'insight-execute'}>
                    <Tooltip
                        content='Adding the following nodes to the graph (not removing displayed entities)'
                        aria-label='add'>
                        <Button disabled = {runBtnDisable} onClick={ () => submitQueryCallback() } style={{ width: '150px' }}>{runBtnText}</Button>
                    </Tooltip>

                </div>

                <div className={'insight-clear'}>
                    <Tooltip
                        content='Clearing the displayed entities from the graph'
                        aria-label='add'>
                        <Button onClick={ () => clearGraphCallback() } style={{ width: '150px' }}>Clear Graph</Button>
                    </Tooltip>
                </div>
            </div>

            <div style={{ color: 'red' }}>{errorMsg}</div>
        </div>
    </>
    );
};