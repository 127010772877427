import { Vendors } from 'common/consts/vendors';
import { IntelligenceRulesetDTO, RulesetDTO } from '../interface/Reporting.interface';
import { RulesetType } from '../Reporting.const';

export class Ruleset {
    public name: string;
    public id: number;
    public vendor: Vendors;
    public rulesetType: RulesetType;
    public shouldDisplayRuleset = true;

    constructor (ruleset: RulesetDTO | IntelligenceRulesetDTO, rulesetType: RulesetType) {
        this.name = ruleset.name;
        this.id = ruleset.id;
        this.rulesetType = rulesetType;
        if (rulesetType === RulesetType.CDR) {
            const cdrRuleset = ruleset as IntelligenceRulesetDTO;
            this.vendor = cdrRuleset.vendor as Vendors;
        } else {
            const complianceRuleset = ruleset as RulesetDTO;
            this.vendor = complianceRuleset.cloudVendor as Vendors;
            this.shouldDisplayRuleset = !complianceRuleset.systemBundle;
        }
    }
}
