import React from 'react';
import { StatusBox } from 'common/erm-components/custom/SummaryStatus/StatusBox/StatusBox';
import { ILevelBoxItem } from 'common/erm-components/custom/LevelBoxes/LevelBox.interface';
import { LevelBox } from 'common/erm-components/custom/LevelBoxes/LevelBox';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { useTranslation } from 'react-i18next';
import { SecurityEventsStatusStyled } from './SecurityEventsStatus.styled';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';
import { FindingsAlertType, FindingSearchFields } from 'common/components/Findings/Findings.interface';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';

const SecurityEventsStatus: React.FC<{ items: ILevelBoxItem[] }> = ({ items }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    // TODO: need to change the link to the new table, once it will be GA
    const goToCdrEvents = () => {
        getErmUrlsService().goToEventsTable(FindingsAlertType.SECURITY , '',
            [{ name: FindingSearchFields.ORIGIN, value: 1 },
                { name: FindingSearchFields.SEVERITY, value: FindingSeverityServerEnum.Critical },
                { name: FindingSearchFields.SEVERITY, value: FindingSeverityServerEnum.High }]);
    };

    return ( items.length > 0 &&
        <StatusBox text={t('CDR_DASHBOARD.SUMMARY_STATUS.ADDRESS_SECURITY_EVENTS.TITLE')} >
            <SecurityEventsStatusStyled.LevelBoxesDiv>
                {items.map((item: ILevelBoxItem, index: number) => <LevelBox item={item} key={index} width={'25%'}/>)}
            </SecurityEventsStatusStyled.LevelBoxesDiv>
            <SecurityEventsStatusStyled.ButtonDiv data-aid='explore-security-events' onClick={goToCdrEvents}
                variant={'outlined'} color={'brandPrimary'}>
                {t('CDR_DASHBOARD.SUMMARY_STATUS.ADDRESS_SECURITY_EVENTS.EXPLORE')}
            </SecurityEventsStatusStyled.ButtonDiv>
        </StatusBox>
    );
};

export default SecurityEventsStatus;
