import { IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import i18n from 'i18next';
import { renderMultiSelectFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { UNKNOWN_IAM_EXPOSURE_LEVEL_KEY, UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY } from '../../ProtectedAsset/ErmDataUtils';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { CONTEXT_FIELD_FULL_NAMES } from '../../../consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import {
    FieldConvertorsRegistry,
    FieldEntityKind,
    IFieldConvertor,
} from 'common/registries/FieldConvertorsRegistry';

export function initializeRiskDashboard() {
    const filterDefsAddins:Addin<IFilterProps>[] = [
        {
            id: 'networkExposure',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.networkExposure]?.filter((item) => {
                            return item.value !== UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY;
                        }),
                        key: CONTEXT_FIELD_FULL_NAMES.networkExposure,
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.NETWORK_EXPOSURE.TITLE'),
                    },
                    renderFunction: renderMultiSelectFilter,
                } as IFilterProps;
            },
        },
        {
            id: 'iamExposure',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.iamExposure]?.filter((item) => {
                            return item.value !== UNKNOWN_IAM_EXPOSURE_LEVEL_KEY;
                        }),
                        key: CONTEXT_FIELD_FULL_NAMES.iamExposure,
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.IAM_EXPOSURE.TITLE'),
                    },
                    renderFunction: renderMultiSelectFilter,
                } as IFilterProps;
            },
        },
        {
            id: 'dataSensitivity',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.dataSensitivity],
                        key: CONTEXT_FIELD_FULL_NAMES.dataSensitivity,
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.DATA_SENSITIVITY.TITLE'),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'businessPriority',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations?.businessPriority,
                        key: 'businessPriority',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.BUSINESS_PRIORITY.TITLE'),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        }];

    ProtectedAssetsTableRegistry.addFilterAddins(filterDefsAddins);
    initializeWidgetsFieldConvertors();
}

function initializeWidgetsFieldConvertors() {
    const fieldConvertors: IFieldConvertor[] = [
        {
            sourceEntityKind: FieldEntityKind.ASSET,
            targetEntityKind: FieldEntityKind.WAF,
            sourceFieldName: 'cloudAccountId',
            targetFieldName: 'environment'
        },
    ];
    FieldConvertorsRegistry.addFieldConvertors(fieldConvertors);
}