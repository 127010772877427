import React from 'react';
import { EventsModalOpenFrom, EventsModalProps } from 'common/module_interface/events/EventsModalsRegistry';
import { IRemediationCreation } from 'common/interface/remediation';
import { RemediationRegistry } from 'common/module_interface/remediation/RemediationRegistry';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';

const EventsRemediationModal: React.FC<EventsModalProps> = ({ closeModal, selectedRows, resetSelectedRows, requestNewData, openFrom }) => {
    const event = selectedRows[0];
    const sourceType = event.origin;
    const RemediationModal = RemediationRegistry.getRemediationModals().find(modal => modal.id === sourceType)?.component;
    if (!RemediationModal) {
        return null;
    }
    const configuredRemediationObject = (): IRemediationCreation => {
        switch (sourceType) {
            case IFindingModelOriginString.CIEM: {
                return {
                    cloudAccountIds: [event.cloudAccountId],
                    cloudAccountType: event.cloudAccountType,
                    cloudBots: [],
                    comment: '',
                    logicExpressions: event.entityName ? [`name like '${event.entityName}'`] : undefined,
                    platform: event.cloudAccountType,
                    ruleLogicHash: undefined,
                    ruleName: event.ruleName,
                    ruleId: event.ruleId,
                    severities: [event.severity],
                    rulesetId: event.bundleId ? parseInt(event?.bundleId) : undefined,
                };
            }
            default: {
            // IFindingModelOriginString.ComplianceEngine: {
                return {
                    cloudAccountId: event.cloudAccountId,
                    cloudAccountType: event.cloudAccountType,
                    cloudBots: [],
                    comment: '',
                    logic: event.entityName ? `name like '${event.entityName}'` : undefined, // event.ruleId
                    platform: event.cloudAccountType,
                    ruleLogicHash: undefined, // event.ruleLogicHash,
                    ruleName: event.ruleName,
                    ruleId: event.ruleId,
                    rulesetId: event.bundleId ? parseInt(event?.bundleId) : undefined,
                };
            }
        }
    };
    const object = configuredRemediationObject();


    const onSave = () => {
        if (openFrom === EventsModalOpenFrom.TABLE && resetSelectedRows) {
            resetSelectedRows();
        }
        closeModal();
        requestNewData?.();
    };

    return (
        <RemediationModal
            remediation={object}
            isOpen={true}
            onClose={()=> closeModal()}
            onSave={()=> onSave()}
            sourceType={event.origin}
        />
    );
};

export default EventsRemediationModal;
