import { ISelectOption } from './interface/Reporting.interface';
import { ReportIntervalValue, ReportSeverities, ReportTimeRangeValue, ReportType, RulesetType } from './Reporting.const';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { TFunction } from 'i18next';
import { Vendors } from 'common/consts/vendors';

dayjs.extend(duration);

export const translations = (t: TFunction) => ({
    templateOptions: [
        {
            value: ReportType.Compliance,
            label: t('REPORTS.TEMPLATE_OPTIONS.POSTURE_MANAGEMENT'),
            icon: {
                name: 'posture-management',
            },
            rulesetType: RulesetType.Compliance
        },
        {
            value: ReportType.CompliancePerAccount,
            label: t('REPORTS.TEMPLATE_OPTIONS.POSTURE_MANAGEMENT_DETAILED_PER_ACCOUNT'),
            icon: {
                name: 'posture-management',
            },
            rulesetType: RulesetType.Compliance
        },
        {
            value: ReportType.CompliancePerRuleSet,
            label: t('REPORTS.TEMPLATE_OPTIONS.POSTURE_MANAGEMENT_DETAILED_PER_RULESET'),
            icon: {
                name: 'posture-management',
            },
            rulesetType: RulesetType.Compliance
        },
        {
            value: ReportType.Cdr,
            label: t('REPORTS.TEMPLATE_OPTIONS.CDR'),
            icon: {
                name: 'microscope',
            },
            rulesetType: RulesetType.CDR
        },
        {
            value: ReportType.CdrPerAccount,
            label: t('REPORTS.TEMPLATE_OPTIONS.CDR_DETAILED_PER_ACCOUNT'),
            icon: {
                name: 'microscope',
            },
            rulesetType: RulesetType.CDR
        },
        {
            value: ReportType.CdrPerRuleset,
            label: t('REPORTS.TEMPLATE_OPTIONS.CDR_DETAILED_PER_RULESET'),
            icon: {
                name: 'microscope',
            },
            rulesetType: RulesetType.CDR
        }
    ],
    timeRangeOptions: [
        {
            value: ReportTimeRangeValue.Daily,
            label: t('REPORTS.TIME_RANGE_OPTIONS.DAYS'),
        },
        {
            value: ReportTimeRangeValue.Weekly,
            label: t('REPORTS.TIME_RANGE_OPTIONS.WEEKS'),
        },
        {
            value: ReportTimeRangeValue.Monthly,
            label: t('REPORTS.TIME_RANGE_OPTIONS.MONTHS'),
        },
    ],
    granularityIntervalOptions: [
        {
            value: ReportTimeRangeValue.Daily,
            label: t('REPORTS.INTERVAL_OPTIONS.DAILY'),
        },
        {
            value: ReportTimeRangeValue.Weekly,
            label: t('REPORTS.INTERVAL_OPTIONS.WEEKLY'),
        },
        {
            value: ReportTimeRangeValue.Monthly,
            label: t('REPORTS.INTERVAL_OPTIONS.MONTHLY'),
        },
    ],
    repeatIntervalOptions: [
        {
            value: ReportIntervalValue.Daily,
            label: t('REPORTS.INTERVAL_OPTIONS.DAILY'),
        },
        {
            value: ReportIntervalValue.Weekly,
            label: t('REPORTS.INTERVAL_OPTIONS.WEEKLY'),
        },
        {
            value: ReportIntervalValue.Monthly,
            label: t('REPORTS.INTERVAL_OPTIONS.MONTHLY'),
        },
    ],
    reportCdrTypesOptions: [
        {
            value: 'network',
            label: t('REPORTS.CDRTYPE_OPTIONS.FLOWLOGS')
        },
        {
            value: 'activity',
            label: t('REPORTS.CDRTYPE_OPTIONS.CLOUDTRAIL')
        }
    ],
    FieldsMapping: {
        granularity: t('REPORTS.MODAL.TIME_RANGE'),
        timeRange_with_granularity: t('REPORTS.MODAL.TIME_RANGE'),
        rule_set_names: t('REPORTS.MODAL.RULESETS'),
        environment_names: t('REPORTS.MODAL.ENVIRONMENTS'),
        severity: t('REPORTS.MODAL.SEVERITY'),
        vendor_ids: t('REPORTS.MODAL.CLOUD_PROVIDER'),
        organizational_unit_ids: t('REPORTS.MODAL.ORGANIZATIONAL_UNIT'),
        cdr_types: t('REPORTS.MODAL.CDR_TYPES')
    }
});

export const getTimeRangeNumberOptions = (
    length: number,
    start = 1,
    isFormatted = false,
    showStringTranslation = false,
) => {
    // creates an array of numbers with specified length in label-value format
    const numberOptions: ISelectOption[] = [];

    for (let i = start; i <= length; i++) {

        let label = '';

        if (isFormatted) { // format the number to HH:mm
            label = dayjs.duration(i, 'hours').format('HH:mm');
        } else if (showStringTranslation) { // format the number to day of week
            label = dayjs().day(i).format('dddd');
        }
        else {
            label = i.toString();
        }

        numberOptions.push({
            label,
            value: i,
        });
    }
    return numberOptions;
};

export const getSeverities = () => {
    const severities = [];
    for (const value of ReportSeverities) {
        severities.push({ label: value, value: value });
    }
    return severities;
};

export const convertPlatformNameToIcon = (platform: string) => {
    switch (platform) {
        case Vendors.OCI:
            return 'oracle';
        case Vendors.KUBERNETES_RUNTIME_ASSURANCE:
            return Vendors.KUBERNETES;
        case Vendors.GOOGLE:
            return Vendors.GCP;
        default:
            return platform;
    }
};
