import {
    GRAPH_ENTITY_TYPE_URL,
    SUPPORTED_INTEGRATION_TYPES_URL,
} from 'common/module_interface/RiskManagement/autoActions/AutoActions';
import { getTypeByPlatform } from 'common/assets/common.assets';

import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';

export interface ISecurityGraphSupportedElements {
    entityTypes: string[];
    platforms: string[];
    integrationTypes: string[];
}

interface ISecurityGraphTypeModel {
    entityType: string;
    platform: string;
}

const SECURITY_GRAPH_SUPPORTED_ENTITY_TYPES: ISecurityGraphSupportedElements = {
    entityTypes: [],
    platforms: [],
    integrationTypes: [],
};

const fetchSupportedIntegrationTypes = async (): Promise<string[]> => {
    const supportedTypes: string[] = await sendHttpRequest<string[]>(SUPPORTED_INTEGRATION_TYPES_URL, {
        method: 'POST',
    }) || [];
    return supportedTypes;
};

export const setupSecurityGraphData = async () => {
    SECURITY_GRAPH_SUPPORTED_ENTITY_TYPES.integrationTypes = await fetchSupportedIntegrationTypes() || [];
    const typeModels: ISecurityGraphTypeModel[] = await sendHttpRequest<ISecurityGraphTypeModel[]>(GRAPH_ENTITY_TYPE_URL, {
        method: 'GET',
    }) || [];
    const platformsSet: Set<string> = new Set<string>();
    SECURITY_GRAPH_SUPPORTED_ENTITY_TYPES.entityTypes = [];
    typeModels.forEach((typeModel: ISecurityGraphTypeModel) => {
        SECURITY_GRAPH_SUPPORTED_ENTITY_TYPES.entityTypes.push(getTypeByPlatform(typeModel.platform, typeModel.entityType));
        platformsSet.add(typeModel.platform);
    });
    SECURITY_GRAPH_SUPPORTED_ENTITY_TYPES.platforms = Array.from(platformsSet);
};

export const getSupportedSecurityGraphEntityTypes = (): string[] => {
    return [...SECURITY_GRAPH_SUPPORTED_ENTITY_TYPES.entityTypes];
};

export const getSupportedSecurityGraphPlatforms = (): string[] => {
    return [...SECURITY_GRAPH_SUPPORTED_ENTITY_TYPES.platforms];
};

export const getSupportedIntegrationTypes = (): string[] => {
    return [...SECURITY_GRAPH_SUPPORTED_ENTITY_TYPES.integrationTypes];
};
