import React, { useMemo } from 'react';
import { AUTO_ACTION_FIELD_NAMES, IAutoAction } from './AutoActions.interface';
import { fetchAllAutoActions, getAllTriggerCondOptions } from './AutoActions.utils';
import { AUTO_ACTIONS_PAGE_TABLE_ID } from './AutoActions.consts';
import { AutoActionsEmptyState } from './AutoActionsEmptyState/AutoActionsEmptyState';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../consts';
import { getAutoActionsTableActions } from './AutoActions.actions';
import { getAutoActionsFilters } from './AutoActions.filters';
import { ClientFilterPageTable } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable';
import { getAutoActionsColumns } from './AutoActions.columns';
import {
    IClientFilterPageTableProps
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';

export const AutoActionsPage: React.FC = () => {
    const { t } = useTranslation(I18nRiskNamespace);
    const pageProps: IClientFilterPageTableProps<IAutoAction> = useMemo(() => ({
        fetchAllItems: fetchAllAutoActions,
        getTableColumnDefs: getAutoActionsColumns,
        getTableActions: getAutoActionsTableActions,
        getTableFilterDefs: getAutoActionsFilters,
        emptyStateComponent: AutoActionsEmptyState,
        getFooterItemTitle: (count: number) => t('AUTO_ACTIONS.GENERAL.AUTO_ACTION', { count }),
        pageTableId: AUTO_ACTIONS_PAGE_TABLE_ID,
        getFieldOptionsMap: () => Promise.resolve({
            [AUTO_ACTION_FIELD_NAMES.TRIGGERS]: {
                options: getAllTriggerCondOptions(),
                showAllOptions: true,
            },
        }),
    }), [t]);
    return <ClientFilterPageTable {...pageProps} />;
};
