import styled from 'styled-components/macro';
import { IconButton, Stack } from 'common/design-system/components-v2';

export const FindingsFilterPanelDiv = styled.div<{ isVisible: boolean }>`
    display: ${props => props.isVisible ? 'flex' : 'none'};
    flex-direction: column;
    z-index: 100;
`;

const Wrapper = styled(Stack)`
    background-color: white;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    padding: 2px;
`;

const FilterIconButton = styled(IconButton)`
    padding-left: 2px;
`;

export const FilterPanelLoaderStyles = {
    Wrapper,
    FilterIconButton
};
