import React from 'react';
import { ViewerStyled } from './SecurityControlViewer.styled';
import { ISecurityControl } from '../SecurityControls.interface';
import { BasicPropertiesPanel } from './BasicPropertiesPanel/BasicPropertiesPanel';
import { DescriptionPanel } from '../../DrawerPanels/DescriptionPanel/DescriptionPanel';
import { RemediationPanel } from '../../DrawerPanels/RemediationPanel/RemediationPanel';
export const SecurityControlViewer: React.FC<{ securityControl: ISecurityControl }> = ({ securityControl }) => {
    return (
        <ViewerStyled.TopDiv>
            <BasicPropertiesPanel securityControl={securityControl} />
            <DescriptionPanel description={securityControl.description} />
            <RemediationPanel remediation={securityControl.remediation} />
        </ViewerStyled.TopDiv>
    );
};
