import { IAsset } from 'common/assets/common.assets';
import { DEFAULT_RANGES_VALUES } from 'common/components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import {
    FILTER_BOX_DISPLAY_TYPES, FILTER_DISPAY_TYPES,
    FILTERS_API_OBJECT_KEYS,
    FILTERS_KEYS
} from 'common/components/FilterPanel/FilterPanel.consts';
import {
    renderAddFilter,
    renderClearAll,
    renderDefaultDateFilter,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTreeFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { VENDORS } from 'common/consts/vendors';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { Addin } from 'common/extensibility/AddinRegistry';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { IEventsFilterParams, PATH_EVENTS_FILTERS_DEFINITIONS } from 'common/module_interface/events/Events';
import i18n from 'i18next';
import { ORIGIN } from '../Consts';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { EnvironmentCodes } from 'common/utils/environments';


const filterDefsAddins: Addin<IFilterProps>[] =
    [
        {
            id: 'add filter',
            content: {
                filterProps: { key: 'add-filter' },
                renderFunction: renderAddFilter,
            },
        },
        {
            id: 'free text',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.FREE_TEXT.TITLE'),

                },
                keyInObjectForAPI: 'freeTextPhrase',
                displayTypes: [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1, FILTER_DISPAY_TYPES.SEPARATE_FIELDS],
                renderFunction: renderDefaultFreeTextFilter,
            },
        },
        {
            id: 'date-picker',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.DATE_PICKER,
                    defaultValue: DEFAULT_RANGES_VALUES.ALL,
                    options: [
                        DEFAULT_RANGES_VALUES.ONE_DAY,
                        DEFAULT_RANGES_VALUES.WEEK,
                        DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                        DEFAULT_RANGES_VALUES.ALL,
                    ],
                    title: 'Date',
                    limitations: { maxDaysSelected: 5, maxDaysBack: 30 },
                },
                keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.DATE_PICKER,
                displayTypes: [FILTER_BOX_DISPLAY_TYPES.BOX_CONTENT_1, FILTER_DISPAY_TYPES.SEPARATE_FIELDS],
                renderFunction: renderDefaultDateFilter,
            }
        },
        {
            id: 'saved filters',
            content: function ({ filtersInitialData }: IEventsFilterParams) {
                return {
                    filterProps: {
                        savedFilters: filtersInitialData?.savedFilters || [],
                        savedFiltersComponentName: '',
                        selectedFilterID: '',
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                };
            },
        },
        {
            id: 'recently used',
            content: function ({ filtersInitialData }: IEventsFilterParams) {
                return {
                    filterProps: {
                        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                        recentlyUsedList: filtersInitialData?.recentlyUsed,
                        maxLength: 4,
                        componentName: '',
                        title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                    },
                    renderFunction: renderRecentlyUsedFilters,
                };
            },
        },
        {
            id: 'clear all',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
            },
        },
        {
            id: 'organizationalUnit',
            content: function ({ filtersInitialData }: IEventsFilterParams) {
                return {
                    filterProps: {
                        initialData: filtersInitialData?.organizationalUnits,
                        key: 'organizationalUnitId',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ORGANIZATIONAL_UNITS.TITLE'),
                    },
                    renderFunction: renderTreeFilter,
                };
            },
        },
        {
            id: 'severity',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['severity']?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['severity'],
                        key: 'severity',
                        title: i18n.t('EVENTS.FILTERS.SEVERITY.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'cloudAccountId_calc',
            content: function ({ aggregations, allCloudAccounts = [] }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['cloudAccountId_calc']?.forEach((item: { value: string }) => {
                    const environmentAsArr = item.value.split('|');
                    const environmentId = environmentAsArr.length >= 2 ? environmentAsArr[1] : '';
                    const filteredAccount = allCloudAccounts.filter((account: any) => account.id === environmentId);
                    displayMapping[item.value] = {
                        displayText: filteredAccount[0]?.name,
                        icon: filteredAccount[0].platform
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['cloudAccountId_calc'],
                        key: 'cloudAccountId_calc',
                        title: i18n.t('EVENTS.FILTERS.ENVIRONMENT.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'alertType',
            content: function ({ aggregations }: IEventsFilterParams) {
                const alertTypes = ['EVENTS.ALERT_TYPE.THREAT_AND_SECURITY', 'EVENTS.ALERT_TYPE.TASKS'];
                const displayMapping: IDisplayMappingObject = {};
                aggregations['alertType']?.forEach((item: { value: number }) => {
                    displayMapping[item.value] = {
                        displayText: i18n.t(alertTypes[item.value])
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['alertType'],
                        key: 'alertType',
                        title: i18n.t('EVENTS.ALERT_TYPE.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'acknowledged',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['acknowledged']?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value === 'true' ? i18n.t('EVENTS.FILTERS.ACKNOWLEDGED.YES') : i18n.t('EVENTS.FILTERS.ACKNOWLEDGED.NO')
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['acknowledged'],
                        key: 'acknowledged',
                        title: i18n.t('EVENTS.FILTERS.ACKNOWLEDGED.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'cloudAccountType',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['cloudAccountType']?.forEach((item: { value: number }) => {
                    const vendorData = VENDORS.find(vendor => vendor.assessmentVendorType == item.value);
                    displayMapping[item.value] = {
                        displayText: vendorData?.displayName || i18n.t('EVENTS.NOT_AVAILABLE'),
                        icon: vendorData?.icon || ''
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['cloudAccountType'],
                        key: 'cloudAccountType',
                        title: i18n.t('EVENTS.FILTERS.CLOUD_ACCOUNT_TYPE.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'entityType',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                const initialData: any[] = [];
                aggregations['entityTypeByEnvironmentType']?.forEach((item: { value: string }) => {
                    const [vendorCode, platformType] = item.value.split('|');
                    const vendor = EnvironmentCodes[vendorCode].toLowerCase();
                    const typeByPlatform = `${vendor}|${platformType}`;
                    const asset = getProtectedAssetsService().getAssetByType(typeByPlatform) as IAsset;
                    if (asset?.displayName){
                        displayMapping[item.value] = {
                            displayText: asset?.displayName ?? item.value,
                            icon: asset?.icon
                        };
                        initialData.push(item);
                    }
                });
                return {
                    filterProps: {
                        initialData: initialData,
                        key: 'entityTypeByEnvironmentType',
                        title: i18n.t('EVENTS.FILTERS.ENTITY_TYPE.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'isExcluded',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['isExcluded']?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value === 'true' ? i18n.t('EVENTS.FILTERS.EXCLUDED.YES') : i18n.t('EVENTS.FILTERS.EXCLUDED.NO')
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['isExcluded'],
                        key: 'isExcluded',
                        title: i18n.t('EVENTS.FILTERS.EXCLUDED.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'origin',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['origin']?.forEach((item: { value: number }) => {
                    displayMapping[item.value] = {
                        displayText: i18n.t(ORIGIN[item.value])
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['origin'],
                        key: 'origin',
                        title: i18n.t('EVENTS.FILTERS.ORIGIN.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'labels',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['labels']?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['labels'],
                        key: 'labels',
                        title: i18n.t('EVENTS.FILTERS.LABELS.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'action',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                const actions = [i18n.t('EVENTS.FILTERS.LABELS.TITLE')];
                aggregations['action']?.forEach((item: { value: number }) => {
                    displayMapping[item.value] = {
                        displayText: actions[item.value]
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['action'],
                        key: 'action',
                        title: i18n.t('EVENTS.FILTERS.LABELS.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'ownerUserName',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['ownerUserName']?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['ownerUserName'],
                        key: 'ownerUserName',
                        title: i18n.t('EVENTS.FILTERS.ASSIGNEE.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'bundleName',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['bundleName']?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['bundleName'],
                        key: 'bundleName',
                        title: i18n.t('EVENTS.FILTERS.RULESET.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'category',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['category']?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['category'],
                        key: 'category',
                        title: i18n.t('EVENTS.FILTERS.CATEGORY.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'region',
            content: function ({ aggregations }: IEventsFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['region']?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: item.value
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['region'],
                        key: 'region',
                        title: i18n.t('EVENTS.FILTERS.REGION.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
    ];

export default function initializeFilters() {
    globalAddinContainer.add<IFilterProps>(PATH_EVENTS_FILTERS_DEFINITIONS, filterDefsAddins);
}
