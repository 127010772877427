import React from 'react';
import OciActions from '../oci.actions';

export interface UseRemoveAccountReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: (result: boolean) => void;
}

export interface UseRemoveAccountPayload {
    isLoading: boolean;
    isDeleted: boolean;
    isError?: { message: string; };
    deleteAccount: (ociId: string, forceDelete?: boolean) => Promise<void>;
    reset?: () => void;
}

export type UseRemoveOci = (req?: UseRemoveAccountReq) => UseRemoveAccountPayload;

export const useRemoveOci: UseRemoveOci = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDeleted, setIsDeleted] = React.useState(false);
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const deleteAccount = React.useCallback(async (ociId: string, forceDelete?: boolean) => {
        setIsLoading(true);
        try {
            const result = await OciActions.deleteAccount(ociId, forceDelete);
            setIsDeleted(result);
            onSuccess?.(result);
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsDeleted(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isDeleted,
        isError,
        deleteAccount,
        reset
    });
};