import i18n, { validLanguages } from 'common/services/translations/translations';

export const i18nPolicyNamespace = 'policy';

export const initializeI18nPolicy = async () => {
    return Promise.all(validLanguages.map(async (language) => {
        try {
            const contents = await require(`./languages/${language}/translation.json`);
            i18n.addResourceBundle(language, `${i18nPolicyNamespace}`, contents);
        } catch {
            //Nothing to do with missing translation files
        }
    }));
};