import { IColumnUsageDef } from 'common/interface/general';
import { FindingColumns } from '../../Findings.const';
import { FindingToKustoMapping } from '../../FindingOverview/Models/FindingKustoModel';
import { SELECTION_GRID_COLUMN, TABLE_SELECTION_COL_ID } from 'common/utils/tableUtils';
import { EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE } from '../../../ActiveFeatures/ActiveFeatures.consts';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';

export const FindingsColumnTypes: { [key: string]: IColumnUsageDef } = {
    SELECTION: {
        id: SELECTION_GRID_COLUMN.colId ?? TABLE_SELECTION_COL_ID,
        colDefOverride: {
            headerCheckboxSelection: false,
            pinned: false,
            lockPosition: false
        },
        permission: [EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
    },
    RULE_NAME: {
        id: FindingFields.ruleName,
        colDefOverride: {
            freeTextField: true,
            minWidth: 400,
        }
    },
    SEVERITY: {
        id: FindingFields.severityLevel,
        colDefOverride: {
            freeTextField: true,
            minWidth: 130,
        }
    },
    CREATED_TIME: {
        id: FindingFields.eventTime,
        colDefOverride: {
            minWidth: 200,
        }
    },
    ENVIRONMENT: {
        id: FindingFields.cloudAccountId,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
        }
    },
    DESCRIPTION: {
        id: FindingFields.ruleDescription,
        colDefOverride: {
            minWidth: 200,
        }
    },
    RULESET_NAME: {
        id: FindingFields.rulesetName,
        colDefOverride: {
            minWidth: 200,
        }
    },
    ENTITY_NAME: {
        id: FindingFields.entityName,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
            additionalColumnsInGroupBy: [FindingFields.entityExternalId, FindingFields.entityId, FindingFields.entityType],
        }
    },
    ENTITY_TYPE: {
        id: FindingFields.entityType,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
            additionalColumnsInGroupBy: [FindingFields.cloudAccountType],
        }
    },
    MITRE: {
        id: FindingFields.mitreDict,
        colDefOverride: {
            minWidth: 200,
        }
    },
    ACTION: {
        id: FindingFields.action,
        colDefOverride: {
            freeTextField: true,
            minWidth: 100,
        }
    },
    REMEDIATION: {
        id: FindingFields.ruleRemediation,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
        }
    },
    STATUS: {
        id: FindingFields.statusId,
        colDefOverride: {
            minWidth: 100,
            additionalColumnsInGroupBy: [FindingToKustoMapping.statusName],
        }
    },
    FIRST_OPENED_TIME: {
        id: FindingFields.minStartTime,
        colDefOverride: {
            minWidth: 200,
        }
    },
    ORIGIN: {
        id: FindingFields.origin,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
        }
    },
    PLATFORM: {
        id: FindingFields.cloudAccountType,
        colDefOverride: {
            freeTextField: true,
            minWidth: 200,
        }
    },
    ASSIGNEE: {
        id: FindingFields.owner,
        colDefOverride: {
            freeTextField: true,
            minWidth: 100,
        }
    },
    // DO NOT use this column in the table for now, the actions are not implemented yet
    ACTIONS: {
        id: FindingColumns.ACTIONS,
        colDefOverride: {
            width: 5,
            pinned: 'right',
            lockPinned: true,
        }
    }
};
