import {
    renderAddFilter,
    renderClearAll,
    renderDefaultDateFilter,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTreeFilter
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import {
    FILTER_DISPAY_TYPES,
    FILTERS_API_OBJECT_KEYS,
    FILTERS_KEYS
} from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { IFiltersInitialData, IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor, getVendorDisplayName } from 'common/consts/vendors';
import { buildCloudAccountList, mapEnvironmentItem, mapTypeItem } from 'common/module_interface/assets/utils';
import { getRegionDisplayName } from 'common/utils/vendorUtils';
import { isEmpty } from 'lodash';
import { DEFAULT_RANGES_VALUES } from 'common/components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import { FindingsTableRegistry } from 'common/module_interface/intelligence/Findings/FindingsTableRegistry';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { FindingSeverityCellComponent } from 'common/components/Findings/FindingSeverityCellComponent';
import { getStatusName } from '../../Findings.utils';
import { TFunction } from 'i18next';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { ICloudAccount } from 'common/interface/data_services';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';
import {
    ensureDefaultBooleanAggregations, ensureDefaultStatusAggregations,
    getYesNoDisplayMapping,
} from 'common/erm-components/utils/filters';

/**
 * common filter definitions for Intelligence Findings table
 */
function getFilterDefs(): Addin<IFilterProps>[] {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return [
        {
            id: FILTERS_KEYS.ADD_FILTER,
            content: {
                filterProps: { key: FILTERS_KEYS.ADD_FILTER },
                renderFunction: renderAddFilter,
            }
        },
        {
            id: FILTERS_KEYS.FREE_TEXT,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.FREE_TEXT,
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.FREE_TEXT.TITLE'),
                },
                keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.FREE_TEXT,
                renderFunction: renderDefaultFreeTextFilter,
            },
        },
        {
            id: FILTERS_KEYS.SAVED_FILTERS,
            content: function (params: IProtectedAssetFilterParams) {
                const filtersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        savedFilters: filtersInitialData?.savedFilters || [],
                        savedFiltersComponentName: EMPTY_STRING,
                        selectedFilterID: EMPTY_STRING,
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                };
            },
        },
        {
            id: FILTERS_KEYS.RECENTLY_USED_FILTERS,
            content: function (params: IProtectedAssetFilterParams) {
                const filtersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                        recentlyUsedList: filtersInitialData?.recentlyUsed,
                        maxLength: 4,
                        componentName: EMPTY_STRING,
                        title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                    },
                    renderFunction: renderRecentlyUsedFilters,
                };
            },
        },
        {
            id: FILTERS_KEYS.DATE_PICKER,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.DATE_PICKER,
                    defaultValue: DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                    options: [
                        DEFAULT_RANGES_VALUES.FOUR_HOURS,
                        DEFAULT_RANGES_VALUES.TWELVE_HOURS,
                        DEFAULT_RANGES_VALUES.ONE_DAY,
                        DEFAULT_RANGES_VALUES.WEEK,
                        DEFAULT_RANGES_VALUES.THIRTY_DAYS,
                    ],
                    title: 'Date',
                    limitations: { maxDaysSelected: 30, maxDaysBack: 30 },
                },
                keyInObjectForAPI: FILTERS_API_OBJECT_KEYS.DATE_PICKER,
                displayTypes: [FILTER_DISPAY_TYPES.ROW],
                renderFunction: renderDefaultDateFilter,
            }
        },
        {
            id: FILTERS_KEYS.CLEAR_BUTTON,
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
            },
        },
        {
            id: FindingFields.organizationalUnitId,
            content: function (params: IProtectedAssetFilterParams) {
                const filtersInitialData: IFiltersInitialData = params?.filtersInitialData ?? {};
                return {
                    filterProps: {
                        isMultiSelect: true,
                        initialData: filtersInitialData?.organizationalUnits,
                        key: FindingFields.organizationalUnitId,
                        title: t('FINDINGS_TABLE.FILTERS.ORGANIZATIONAL_UNITS.TITLE'),
                    },
                    renderFunction: renderTreeFilter,
                };
            },
        },
        {
            id: FindingFields.cloudAccountType,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[FindingFields.cloudAccountType]?.forEach((platform: { value: string }) => {
                    displayMapping[platform.value] = {
                        displayText: getVendorDisplayName(platform.value),
                        icon: getVendor(platform.value)?.icon,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.cloudAccountType],
                        key: FindingFields.cloudAccountType,
                        title: t('FINDINGS_TABLE.FILTERS.PLATFORM.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.entityType,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.entityType],
                        key: FindingFields.entityType,
                        title: t('FINDINGS_TABLE.FILTERS.ENTITY_TYPE.TITLE'),
                        displayMapping: mapTypeItem(aggregations?.entity_type),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.cloudAccountId,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const allCloudAccounts: ICloudAccount[] = params?.allCloudAccounts ?? [];
                const allCloudAccountsItems = aggregations[FindingFields.cloudAccountId] ?
                    buildCloudAccountList(aggregations[FindingFields.cloudAccountId], allCloudAccounts) : [];
                return {
                    filterProps: {
                        itemCountGroupOption: {
                            enableGrouping: true,
                            countedItemsHeader: t('FINDINGS_TABLE.FILTERS.ENVIRONMENT.COUNTED_ITEMS_HEADER'),
                            nonCountedItemsHeader: t('FINDINGS_TABLE.FILTERS.ENVIRONMENT.NON_COUNTED_ITEMS_HEADER')
                        },
                        initialData: allCloudAccountsItems,
                        key: FindingFields.cloudAccountId,
                        title: t('FINDINGS_TABLE.FILTERS.ENVIRONMENT.TITLE'),
                        displayMapping: mapEnvironmentItem(allCloudAccounts),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.severityName,
            content: function (params: IProtectedAssetFilterParams) {
                const allowedSeverities: FindingSeverityServerEnum[] = [FindingSeverityServerEnum.Critical, FindingSeverityServerEnum.High, FindingSeverityServerEnum.Medium, FindingSeverityServerEnum.Low, FindingSeverityServerEnum.Informational];
                const aggregations: Aggregations = params?.aggregations ?? {};
                for (const currSeverityServerKey of allowedSeverities) {
                    if (!aggregations[FindingFields.severityName]?.find((aggregationItem) => currSeverityServerKey === aggregationItem.value)) {
                        aggregations[FindingFields.severityName]?.push({ value: currSeverityServerKey, count: 0 });
                    }
                }
                const displayMapping: IDisplayMappingObject = aggregations[FindingFields.severityName]?.reduce((prev, aggregationItem) => {
                    prev[aggregationItem.value] = {
                        displayComponent: FindingSeverityCellComponent,
                        displayComponentProps: { severityNameOrLevel: aggregationItem.value, isLink: true }
                    };
                    return prev;
                }, {});
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.severityName],
                        key: FindingFields.severityName,
                        title: t('FINDINGS_TABLE.FILTERS.SEVERITY.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: FindingFields.entityName,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.entityName],
                        key: FindingFields.entityName,
                        title: t('FINDINGS_TABLE.FILTERS.ENTITY.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.entityRegion,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[FindingFields.entityRegion]?.forEach((regionData: { value: string }) => {
                    displayMapping[regionData.value] = { displayText: getRegionDisplayName(regionData.value) };
                });
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.entityRegion],
                        key: FindingFields.entityRegion,
                        title: t('FINDINGS_TABLE.FILTERS.REGION.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.ruleName,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.ruleName],
                        key: FindingFields.ruleName,
                        title: t('FINDINGS_TABLE.FILTERS.RULE_NAME.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.rulesetName,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.rulesetName],
                        key: FindingFields.rulesetName,
                        title: t('FINDINGS_TABLE.FILTERS.RULESET_NAME.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.action,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.action],
                        key: FindingFields.action,
                        title: t('FINDINGS_TABLE.FILTERS.ACTION.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.ruleRemediation,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[FindingFields.ruleRemediation]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: isEmpty(item.value) ? 'Empty' : item.value
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.ruleRemediation],
                        key: FindingFields.ruleRemediation,
                        title: t('FINDINGS_TABLE.FILTERS.REMEDIATION.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.category,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[FindingFields.category]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: isEmpty(item.value) ? 'None' : item.value
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.category],
                        key: FindingFields.category,
                        title: t('FINDINGS_TABLE.FILTERS.CATEGORY.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.isExcluded,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                ensureDefaultBooleanAggregations(aggregations, FindingFields.isExcluded);
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.isExcluded],
                        key: FindingFields.isExcluded,
                        title: t('FINDINGS_TABLE.FILTERS.SHOW_EXCLUDED.TITLE'),
                        displayMapping: getYesNoDisplayMapping(),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.statusId,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                ensureDefaultStatusAggregations(aggregations);
                const displayMapping: IDisplayMappingObject = {};
                aggregations[FindingFields.statusId]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: getStatusName(Number(item.value))
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.statusId],
                        key: FindingFields.statusId,
                        title: t('FINDINGS_TABLE.FILTERS.STATUS.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.origin,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.origin],
                        key: FindingFields.origin,
                        title: t('FINDINGS_TABLE.FILTERS.ORIGIN.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.mitreTactic,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[FindingFields.mitreTactic]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: !isEmpty(item.value) ? item.value : 'Empty',
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.mitreTactic],
                        key: FindingFields.mitreTactic,
                        title: t('FINDINGS_TABLE.FILTERS.TACTIC.TITLE'),
                        isMitreField: true,
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.mitreTechnique,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[FindingFields.mitreTechnique]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: !isEmpty(item.value) ? item.value : 'Empty',
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.mitreTechnique],
                        key: FindingFields.mitreTechnique,
                        title: t('FINDINGS_TABLE.FILTERS.TECHNIQUE.TITLE'),
                        isMitreField: true,
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.owner,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                const displayMapping: IDisplayMappingObject = {};
                aggregations[FindingFields.owner]?.forEach((item: { value: string }) => {
                    displayMapping[item.value] = {
                        displayText: isEmpty(item.value) ? t('FINDINGS_TABLE.CELL_RENDERERS.ASSIGNEE.UNASSIGNED') : item.value
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.owner],
                        key: FindingFields.owner,
                        title: t('FINDINGS_TABLE.FILTERS.ASSIGNEE.TITLE'),
                        displayMapping
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: FindingFields.isAcknowledged,
            content: function (params: IProtectedAssetFilterParams) {
                const aggregations: Aggregations = params?.aggregations ?? {};
                ensureDefaultBooleanAggregations(aggregations, FindingFields.isAcknowledged);
                return {
                    filterProps: {
                        initialData: aggregations[FindingFields.isAcknowledged],
                        key: FindingFields.isAcknowledged,
                        title: t('FINDINGS_TABLE.FILTERS.SHOW_ACKNOWLEDGED.TITLE'),
                        displayMapping: getYesNoDisplayMapping(),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
    ];
}

export function initializeFilterDefs() {
    FindingsTableRegistry.addFilterDefs(getFilterDefs());
}
