import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { IRemediation, RemediationModuleType } from 'common/interface/remediation';
import { generalApiError } from 'common/utils/http';

const apiBaseUrls = {
    [RemediationModuleType.CSPM]: '/ComplianceRemediation', // Example for another API endpoint
    [RemediationModuleType.CIEM]: 'ciem/remediation',
    [RemediationModuleType.CDR]: '/remediations', // Example for another API endpoint
};

const getComplianceRuleset = async (useCache = true): Promise<AxiosResponse<any>> => {
    return getHttpService().request<AxiosResponse<any>>(
        'Compliance/Ruleset/view',
        { method: 'GET' },
        { cachingConfig: { useCache: useCache }, returnAsAxiosResponse: true },
        generalApiError
    );
};


const getList = async (useCache = true, sourceType: keyof typeof apiBaseUrls ): Promise<IRemediation[]> => {
    const data = await getHttpService().request<AxiosResponse<any>>(
        `${apiBaseUrls[sourceType]}`,
        { method: 'GET' },
        { cachingConfig: { useCache: useCache }, returnAsAxiosResponse: true },
        generalApiError
    );
    if (sourceType === RemediationModuleType.CSPM) return data.data.filter((remediationItem: IRemediation) => remediationItem.platform !== 'CIEM');
    return data?.data || [];
};
const deleteRemediation = async (id: string, sourceType: keyof typeof apiBaseUrls) => {
    const apiDeleteBySourceType = {
        [RemediationModuleType.CSPM]: `${apiBaseUrls[sourceType]}/${id}`,
        [RemediationModuleType.CIEM]: `${apiBaseUrls[sourceType]}?id=${id}`,
        [RemediationModuleType.CDR]: `${apiBaseUrls[sourceType]}`,
    };
    return await getHttpService().request<AxiosResponse<any>>(
        apiDeleteBySourceType[sourceType],
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        generalApiError // err => { throw err; }
    );
};

const getComplianceRuleByRuleset = async (id: string): Promise<AxiosResponse<any>> => {
    return getHttpService().request<AxiosResponse<any>>(
        `/Compliance/Ruleset/${id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        generalApiError
    );
};

const RemediationApi = {
    getList,
    deleteRemediation,
    getComplianceRuleByRuleset,
    getComplianceRuleset,
};

export default RemediationApi;
