import React, { useCallback, useEffect, useState } from 'react';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { LoadingState } from 'common/interface/general';
import i18n from 'i18next';
import { Stack, Typography } from 'common/design-system/components-v2';
import { IAMStyled } from './HighIAMPrivileges.styled';
import IAMSensitivityTable from './IAMSensitivityTable/IAMSensitivityTable';
import { ermTrans } from '../../../../../../RiskManagement.utils';
import { fetchIAMSensitivityInfo } from './HighIAMPrivileges.datasource';
import { IamSensitivityRoleAndTableInfo } from './IAMSensitivity.interface';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';

const HighIAMPrivileges: React.FC<{ issue: IIssue, asset: IProtectedAssetViewModel, title: string, initiallyOpen?: boolean }>
    = ({ issue, asset }) => {
        const [loadingState, setLoadingState] = useState(LoadingState.IS_LOADING);
        const [data, setData] = useState<IamSensitivityRoleAndTableInfo[]>();

        const loadIAMSensitivity = useCallback(async () => {
            setLoadingState(LoadingState.IS_LOADING);
            setData(undefined);
            try {
                const sensitivityFullData = await fetchIAMSensitivityInfo(issue, asset);
                setData(sensitivityFullData);
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
            } catch (e) {
                setLoadingState(LoadingState.LOADING_FAILED);
            }
        },[issue, asset]);

        useEffect(() => {
            if (issue && asset) {
                void loadIAMSensitivity();
            }
        }, [issue, asset, loadIAMSensitivity]);

        return (
            <Stack fullWidth>
                {loadingState === LoadingState.IS_LOADING &&
                    <IAMStyled.LoaderDiv>
                        <FullSizeSpinner/>
                    </IAMStyled.LoaderDiv>
                }
                {(loadingState === LoadingState.LOADING_FAILED || (loadingState === LoadingState.LOADING_SUCCEEDED && !data)) &&
                    <IAMStyled.NoteDiv fullWidth fullHeight>
                        <Typography color={'alert'}>
                            {i18n.t('COMMON.ERROR_OCCURRED')}
                        </Typography>
                    </IAMStyled.NoteDiv>}
                {loadingState === LoadingState.LOADING_SUCCEEDED && data &&
                    <Stack direction={'column'} fullWidth fullHeight>
                        {data.length > 0 ?
                            data.map(({ id, roleAsset, actions }) =>
                                <IAMSensitivityTable
                                    key={id}
                                    actions={actions}
                                    roleAsset={roleAsset}
                                />)
                            :
                            <IAMStyled.NoteDiv>
                                <Typography>{ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.IAM_SENSITIVITY_TABLE.NO_DATA')}</Typography>
                            </IAMStyled.NoteDiv>
                        }
                    </Stack>
                }
            </Stack>
        );
    };
export default HighIAMPrivileges;