import { IColumnUsageDef, IFieldInfo } from 'common/interface/general';
import { ColDef } from 'ag-grid-enterprise';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

const getColumnDefs = (routeFields?: IFieldInfo[]): IColumnUsageDef[] => {
    return [
        {
            id: 'ermRiskScore',
            colDefOverride: {
                width: 65,
                minWidth: 50,
                resizeable: false,
                sortable: false,
            },
        },
        {
            id: 'entity',
            colDefOverride: {
                flex: 10,
                sortable: false,
                cellRendererParams: {
                    generateUrl: getErmUrlsService().generateAssetUrl,
                },
            },
        },
        {
            id: 'type',
            colDefOverride: {
                flex: 10,
                cellRendererParams: { onClick: (typeByPlatform: string) => getErmUrlsService().goToProtectedAssetsForType(typeByPlatform, routeFields) },
                sortable: false,
            },
        },
        {
            id: 'environment',
            colDefOverride: {
                flex: 10,
                sortable: false,
            },
        },
    ];
};

export const getTopAssetsColumnDefs = (routeFields?: IFieldInfo[]): ColDef[] => {
    return getProtectedAssetsService().getColumnDefs(getColumnDefs(routeFields));
};