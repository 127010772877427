import React from 'react';
import CloudAccountsApi from 'modules/assets/Environments/services/cloudAccountsApi/cloudAccountsApi.service';
import AzureCloudAccountApi from 'modules/assets/Environments/services/azureCloudAccountApi/azureCloudAccountApi.service';
import GoogleCloudAccountApi from 'modules/assets/Environments/services/googleCloudAccountApi/googleCloudAccountApi.service';
import AlibabaCloudAccountApi from 'modules/assets/Environments/services/alibabaCloudAccountApi/alibabaCloudAccountApi.service';
import KubernetesApi from 'modules/assets/Environments/services/kubernetesApi/kubernetesApi.service';
import ContainerRegistryApi from 'modules/assets/Environments/services/containerRegistryApi/containerRegistryApi.service';
import OciCloudAccountsApi from 'modules/assets/Environments/services/ociCloudAccountApi/ociCloudAccountApi.service';
import { IEnvironment } from 'common/interface/environmentsTable';
import { Vendors } from 'common/consts/vendors';

export interface UseAssociateToOUResults {
    isLoading: boolean;
    isSuccess?: boolean;
    error?: string;
}

export interface UseAssociateToOUReq {
    onFinish?: (response: { successAmount: number; failedAmount: number; }) => void;
}

export interface UseAssociateToOUPayload {
    isLoading: boolean;
    results: { [vendor: string]: UseAssociateToOUResults; };

    associateToOU: (cloudAccounts: IEnvironment[], organizationalUnitId: string) => Promise<void>;
    reset?: () => void;
}

export type UseAssociateToOU = (req?: UseAssociateToOUReq) => UseAssociateToOUPayload;

export const useAssociateToOU: UseAssociateToOU = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<{ [vendor: string]: UseAssociateToOUResults; }>({});

    const associateToOU = React.useCallback(async (cloudAccounts: IEnvironment[], organizationalUnitId: string) => {
        setIsLoading(true);
        const groupedCloudAccounts = cloudAccounts.reduce<{ [vendor: string]: string[] }>((acc, cloudAccount) => {
            if (!acc[cloudAccount.platform]) {
                acc[cloudAccount.platform] = [];
            }
            acc[cloudAccount.platform].push(cloudAccount.id);
            return acc;
        }, {});

        const allResults = await Promise.allSettled(
            Object.keys(groupedCloudAccounts).map(vendor => {
                setResults(prev => ({ ...prev, [vendor]: { isLoading: true } }));
                let request = CloudAccountsApi.attachOrganizationalUnit;
                switch (vendor as Vendors) {
                    case Vendors.AZURE: {
                        request = AzureCloudAccountApi.attachOrganizationalUnit;
                        break;
                    }
                    case Vendors.GCP:
                    case Vendors.GOOGLE: {
                        request = GoogleCloudAccountApi.attachOrganizationalUnit;
                        break;
                    }
                    case Vendors.KUBERNETES: {
                        request = KubernetesApi.attachOrganizationalUnit;
                        break;
                    }
                    case Vendors.CONTAINER_REGISTRY: {
                        request = ContainerRegistryApi.attachOrganizationalUnit;
                        break;
                    }
                    case Vendors.OCI: {
                        request = OciCloudAccountsApi.attachOrganizationalUnit;
                        break;
                    }
                    case Vendors.ALIBABA: {
                        request = AlibabaCloudAccountApi.attachOrganizationalUnit;
                        break;
                    }
                }
                return request({ organizationalUnitId, entries: groupedCloudAccounts[vendor] })
                    .then((result) => {
                        setResults(prev => ({ ...prev, [vendor]: { isLoading: false, isSuccess: true } }));
                        return result;
                    })
                    .catch((error) => {
                        setResults(prev => ({ ...prev, [vendor]: { isLoading: false, isSuccess: false, error: error.message } }));
                        return error;
                    });
            })
        );
        req?.onFinish?.({
            successAmount: allResults.filter(result => result.status === 'fulfilled').length,
            failedAmount: allResults.filter(result => result.status === 'rejected').length
        });
        setIsLoading(false);
    }, [req]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setResults({});
    }, []);

    return ({
        isLoading,
        results,

        associateToOU,
        reset
    });
};