import React, { useEffect, useState } from 'react';
import { extractFilterFieldsToNewModel, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getHttpService, ICustomzationResponse } from 'common/interface/services';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import { FILTER_PANEL_QUERY_NAMES, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import FilterPanelPlaceholder from 'common/components/FilterPanel/FilterPanelPlaceholder/FilterPanelPlaceholder';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { EventsFilterPanelProps } from './EventsFilterPanel.types';
import { IWebAppFilterParams } from 'common/interface/general';

const EventsFilterPanel: React.FC<EventsFilterPanelProps> = props => {
    const { tableId, onFilterChange, tabData, dataSource } = props;

    const [filterElement, setFilterElement] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);

    const savedFiltersComponentName = `events-table_index_${tableId}`;
    const recentlyUsedComponentName = `events-table_index_${tableId}_recent`;

    const FILTER_PANEL_ID = `events-table-assets-page-filter-panel-id_${tableId}`;

    useEffect(() => {
        const getFiltersInitialData = async () => {
            const savedFiltersPromise = getHttpService().request<ICustomzationResponse<any>>(`/customization?component=${savedFiltersComponentName}`,
                { method: 'GET', }, { cachingConfig: { useCache: true, dataAgeLimit: 60 * 60 } }
            );
            const recentlyUsedPromise = getHttpService().request<ICustomzationResponse<any>>(`/customization?component=${recentlyUsedComponentName}`,
                { method: 'GET', }, { cachingConfig: { useCache: true, dataAgeLimit: 60 * 60 } }
            );
            const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
            const allCloudAccounts = getCloudAccountsService().getAllCloudAccounts();
            return Promise.all([savedFiltersPromise, recentlyUsedPromise, organizationalUnitsPromise, allCloudAccounts]).then(
                ([savedFilters, recentlyUsed, organizationalUnits, allCloudAccounts]) => {
                    return {
                        savedFilters,
                        recentlyUsed,
                        organizationalUnits,
                        allCloudAccounts,
                    };
                },
            );
        };
        const getAggregations = async (filtersValues?: IFiltersValues): Promise<Aggregations> => {
            if (filtersValues) {
                onFilterChange(filtersValues);
            }
            return dataSource.getAggregations();
        };

        const extractWebAppFiltersFromQueryParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const webAppQueryParams = queryParams.get(FILTER_PANEL_QUERY_NAMES.QUERY);

            if (webAppQueryParams) {
                const urlFilters: IWebAppFilterParams = JSON.parse(webAppQueryParams);
                const filter = urlFilters?.filter;
                const fields = filter?.fields ? extractFilterFieldsToNewModel(filter.fields) : {};
                const freeTextPhrase = filter?.freeTextPhrase ? { [FILTERS_KEYS.FREE_TEXT]: filter?.freeTextPhrase } : {};
                const tags = filter?.tags ? { [FILTERS_KEYS.TAGS]: filter.tags } : [];

                return { ...fields, ...freeTextPhrase, ...tags };
            }
        };

        async function renderFilterPanel() {
            const filtersInitialData = await getFiltersInitialData();
            const webAppQueryParams = extractWebAppFiltersFromQueryParams();
            const aggregations = await getAggregations();
            
            const defaultFitlers = [
                { id: 'add filter' },
                { id: 'free text' },
                { id: 'saved filters' },
                { id: 'recently used' },
                { id: 'clear all' },
            ];
            const filterPanelElementsList = EventsTableRegistry.getFilterDefs(
                [...defaultFitlers, ...tabData.filters.map(filterId => ({ id: filterId }))],
                {
                    aggregations: aggregations || {},
                    filtersInitialData: filtersInitialData || {},
                    savedFiltersComponentName,
                    recentlyUsedComponentName
                });
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: true,
                webAppQueryParams: webAppQueryParams,
                filterId: FILTER_PANEL_ID,
            });
            setFilterElement(filterPanel);
            setIsLoading(false);
        }

        async function initPage() {
            setIsLoading(true);
            await renderFilterPanel();
        }

        initPage();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabData, dataSource]);

    if (isLoading) {
        return <FilterPanelPlaceholder />;
    }

    return filterElement;
};

export default React.memo(EventsFilterPanel);
