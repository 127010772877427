import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from '@dome9/berries/react-components';
import { CpLoadingDots } from '@dome9/components/react/components';
import { useTranslation } from 'react-i18next';
import { i18nReportsNamespace } from 'modules/reporting/initialize.i18n';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    IOrganizationalUnit,
} from 'common/interface/data_services';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import { CloudAccount } from 'common/interface/environments';
import { IVendor, VENDORS } from 'common/consts/vendors';
import ReportingModal from '../components/ReportingModal/ReportingModal';
import {
    createReport,
    getIntelligenceRulesetsFromServer,
    getLastReportPdfContent,
    getReports,
    getReportsModels,
    getRulesetsFromServer,
    removeReport,
    sendImmediateReports,
    updateReport,
} from '../services/Reporting.service';
import {
    IEnvironmentSelectionOption,
    IFullReportModel,
    IReportFilterConfigurationsModel,
    IReportFilterItem,
    IReportNotificationItem,
    IReportSaveItem,
    IReportsRequest,
    IReportTableItem,
    IRulesetSelectionOption,
    ISelectOption,
    IVendorSelectionOption,
} from '../interface/Reporting.interface';
import { convertPlatformNameToIcon, translations } from '../Reporting.utils';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Table } from 'common/design-system/components-v2';
import { Ruleset } from '../models/Reporting.model';
import { ReportsActions, RulesetType } from '../Reporting.const';
import fileDownload from 'js-file-download';
import { ReportsFilterPanel } from './FilterPanel/ReportsFilterPanel';
import { IFilterDetails } from 'common/module_interface/intelligence/Intelligence.interface';
import { getReportsTableFilters } from './ReportsTable.filters';
import { getReportsTableColumns } from './ReportsTable.columns';
import { mergeActionDefs, mergeColumnDefs } from 'common/utils/tableUtils';
import { ReportsTableRegistry } from 'common/module_interface/reporting/ReportsTableRegistry';
import { ReportTableStyles as Styles } from './ReportsTable.styled';
import { transformFilterValues } from './FilterPanel/ReportsFilterPanel.utils';
import { getReportsTableActions } from './ReportsTable.actions';
import { isNull } from 'lodash';

const ReportsTable: React.FC = () => {
    const { t: tCommon } = useTranslation();
    const { t } = useTranslation(i18nReportsNamespace);
    const translatedOptions = translations(t);

    const gridApiRef = useRef<GridApi>();

    const [rowData, setRowData] = useState<IReportTableItem[]>([]);
    const [selectedRows, setSelectedRows] = useState<IReportTableItem[]>([]);
    const [editingRow, setEditingRow] = useState<IReportTableItem | null>(null);
    const [environments, setEnvironments] = useState<IEnvironmentSelectionOption[]>([]);
    const [vendors, setVendors] = useState<IVendorSelectionOption[]>([]);
    const [rulesets, setRulesets] = useState<IRulesetSelectionOption[]>([]);
    const [reportsModels, setReportModels] = useState<IReportFilterConfigurationsModel[]>([]);
    const [organizationalUnits, setOrganizationalUnits] = useState<ISelectOption[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteReportModalOpen, setIsDeleteReportModalOpen] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorField, setErrorField] = useState<string>('');
    const [filterValues, setFilterValues] = useState<IFilterDetails | undefined>();

    const reportsTableFilters = useMemo(() => getReportsTableFilters(), []);
    const reportsTableColumns = useMemo(() => getReportsTableColumns(), []);
    const reportsTableActions = useMemo(() => getReportsTableActions(), []);

    useEffect(() => {
        loadEnvironments();
        loadVendors();
        loadOrganizationalUnits();
        loadRulesets();
        loadReports();
        loadReportsModel();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const columnDefs = useMemo(() => {
        return mergeColumnDefs(reportsTableColumns, ReportsTableRegistry.getColumnDefs());
    }, [reportsTableColumns]);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        gridApiRef.current = params.api;
        params.api.sizeColumnsToFit();
    }, []);

    const loadEnvironments = async (selectedVendors?: IVendorSelectionOption[]) => {
        try {
            const allCloudAccounts =
                await getCloudAccountsService().getAllCloudAccounts(true);
            if (!allCloudAccounts) return;
            const options = allCloudAccounts.filter((environment: CloudAccount) => selectedVendors?.length ? selectedVendors.find((selectedVendor: IVendorSelectionOption) => environment.platform === selectedVendor.name) : true).map((environment: CloudAccount) => {
                return {
                    label: `${environment.name} (${environment.externalId})`,
                    value: environment.name,
                    platform: environment.platform,
                    id: environment.id,
                    icon: {
                        name: environment.platform
                    }
                };
            });
            setEnvironments(options);
        } catch (e: any) {
            await getLoggerService().error(`${t('REPORTS.LOGGER.ERROR.ENVIRONMENTS_ERROR')}: ${e?.message}`);
        }
    };

    const loadOrganizationalUnits = async () => {
        try {
            const allOrganizationalUnits = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithPath();
            if (!allOrganizationalUnits) return;
            const options = allOrganizationalUnits.map((ou: IOrganizationalUnit) => {
                return {
                    label: ou.path,
                    value: ou.id,
                };
            });
            setOrganizationalUnits(options);
        } catch (e: any) {
            await getLoggerService().error(`${t('REPORTS.LOGGER.ERROR.ORGANIZATIONAL_UNIT_ERROR')}: ${e?.message}`);
        }
    };

    const getExistingVendorNames = async (): Promise<IVendor[]> => {
        const environments =
            await getCloudAccountsService().getAllCloudAccounts(true);
        if (!environments) return [];
        const vendors = [
            ...new Set(environments.map((env) => env.platform)),
        ].map((uniqueEnv) => VENDORS.find((vend) => vend.name === uniqueEnv));
        return vendors.filter((platform): platform is IVendor => !!platform);
    };

    const loadRulesets = async () => {
        const vendors = await getExistingVendorNames();
        if (!vendors) return;
        const vendorNames: string[] = vendors.map(vendor => vendor.name);
        try {
            const [complianceRulesets, intelligenceRulesets] = await Promise.all([getRulesetsFromServer(), getIntelligenceRulesetsFromServer()]);
            const allRulesets = complianceRulesets.map(complianceRuleset => new Ruleset(complianceRuleset, RulesetType.Compliance)).concat(intelligenceRulesets.map(intelligenceRuleset => new Ruleset(intelligenceRuleset, RulesetType.CDR)));
            if (!allRulesets) return;

            setRulesets(
                allRulesets
                    .filter(ruleset => ruleset.shouldDisplayRuleset && vendorNames.includes(ruleset.vendor))
                    .map((ruleset) => {
                        return {
                            label: ruleset.name,
                            value: ruleset.id,
                            icon: {
                                name: convertPlatformNameToIcon(ruleset.vendor || 'unknown'),
                            },
                            vendor: ruleset.vendor,
                            rulesetType: ruleset.rulesetType
                        };
                    })
                    .sort((rs1, rs2) => rs1.icon.name.localeCompare(rs2.icon.name))
            );
        } catch (e: any) {
            await getLoggerService().error(`${t('REPORTS.LOGGER.ERROR.RULESETS_ERROR')}: ${e?.message}`);
        }
    };

    const loadVendors = async () => {
        const vendors = await getExistingVendorNames();
        if (!vendors) return;
        const newVendors: IVendorSelectionOption[] = vendors.map((platform: IVendor) => {
            return {
                value: platform.assessmentVendorType,
                label: platform.displayName,
                icon: {
                    name: platform.icon,
                },
                name: platform.name
            };
        });
        setVendors(newVendors);
    };

    const convertReportsToTable = (reports: IFullReportModel[]) => {
        return reports.map((report) => {
            const { filters, notifications, nextRun = null, lastRun = null } = report;
            const timeRangeValue = filters.find(
                (filter) => filter.name === 'timeRange_with_granularity',
            )?.value;
            const timeRangeGranularityLabel = translatedOptions.timeRangeOptions.find(
                (opt) =>
                    filters.find((filter) => filter.name === 'granularity')
                        ?.value === opt.value,
            )?.label;
            const timeRange =
                timeRangeValue &&
                timeRangeGranularityLabel &&
                `Last ${timeRangeValue} ${timeRangeGranularityLabel}`;
            const target = `${notifications
                .filter((filter) => filter.type === 'email')
                .map((filter) => filter.value)
                .join(' ,')}`;
            return {
                ...report,
                target,
                timeRange,
                nextRun: nextRun,
                lastRun: lastRun,
            };
        });
    };

    const loadReports = async (_filterValues?: IFilterDetails) => {
        setIsLoading(true);
        try {
            const { fields, freeText } = transformFilterValues(_filterValues ?? filterValues);
            const request: IReportsRequest = {
                filters: {
                    fields,
                    freeText,
                },
                skipAggregations: true,
                skipData: false,
            };
            const reports: IFullReportModel[] = await getReports(request);
            setRowData(convertReportsToTable(reports));
        } catch (e: any) {
            getNotificationsService().error(
                t('REPORTS.NOTIFICATIONS.FETCH_STATUS'),
                t('REPORTS.NOTIFICATIONS.ERROR.FETCH_STATUS_DESC'),
            );
            await getLoggerService().error(`${t('REPORTS.LOGGER.ERROR.FETCH_ERROR')}: ${e?.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const loadReportsModel = async () => {
        setIsLoading(true);
        try {
            const reports: IReportFilterConfigurationsModel[] = await getReportsModels();
            setReportModels(reports);
        } catch (e: any) {
            getNotificationsService().error(
                t('REPORTS.NOTIFICATIONS.MODELS_FETCH_STATUS'),
                t('REPORTS.NOTIFICATIONS.MODELS_FETCH_STATUS_DESC'),
            );
            await getLoggerService().error(`${t('REPORTS.LOGGER.ERROR.MODELS_FETCH_ERROR')}: ${e?.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const resetTableProps = () => {
        setSelectedRows([]);
        gridApiRef.current?.deselectAll();
    };

    const onCreateReport = useCallback(() => {
        setIsCreate(true);
        setIsModalOpen(true);
    }, []);

    const onEditReport = useCallback((rows: IReportTableItem[]) => {
        const editingRow: IReportTableItem | null = rows?.length === 1 ? rows[0]: null;
        if(isNull(editingRow)) return;
        editingRow.environment = editingRow?.filters.find(
            (row) => row.name === 'environment_names',
        )?.value;
        editingRow.severity = editingRow?.filters.find(
            (row) => row.name === 'severity',
        )?.value;
        editingRow.rulesets = editingRow?.filters.find(
            (row) => row.name === 'rule_set_names',
        )?.value;
        editingRow.organizationalUnits = editingRow?.filters.find(
            (row) => row.name === 'organizational_unit_ids',
        )?.value;
        editingRow.vendors = editingRow?.filters.find(
            (row) => row.name === 'vendor_ids',
        )?.value;
        editingRow.timeRangeNumber = editingRow?.filters.find(
            (row) => row.name === 'timeRange_with_granularity',
        )?.value;
        editingRow.timeRange = editingRow?.filters.find(
            (row) => row.name === 'granularity',
        )?.value;
        editingRow.cdrTypes = editingRow?.filters.find(
            (row) => row.name === 'cdr_types'
        )?.value;
        editingRow.isOptOutExclusions = editingRow?.filters.find(
            (row) => row.name === 'opt_out_exclusions'
        )?.value;
        setEditingRow(editingRow);
        setIsModalOpen(true);
    }, []);

    const deleteReport = async () => {
        try {
            if (!selectedRows.length) return;
            const reportIds = selectedRows.map((row) => row.reportId);
            setIsLoading(true);
            await removeReport({
                ids: reportIds,
            });
            setRowData(
                rowData.filter((row) => !reportIds.includes(row.reportId)),
            );
            resetTableProps();
            getNotificationsService().success(
                t('REPORTS.NOTIFICATIONS.DELETE_STATUS'),
                t('REPORTS.NOTIFICATIONS.SUCCESS.DELETE_STATUS_DESC'),
            );
        } catch (e: any) {
            getNotificationsService().error(
                t('REPORTS.NOTIFICATIONS.DELETE_STATUS'),
                t('REPORTS.NOTIFICATIONS.ERROR.DELETE_STATUS_DESC'),
            );
            await getLoggerService().error(`${t('REPORTS.LOGGER.ERROR.DELETE_ERROR')}: ${e?.message}`);
        } finally {
            setIsDeleteReportModalOpen(false);
            setIsLoading(false);
        }
    };

    const sendReports = useCallback(async (rows: IReportTableItem[]) => {
        try {
            if (!rows.length) return;
            const reportIds = rows.map((row) => row.reportId);
            setIsLoading(true);
            await sendImmediateReports({
                ids: reportIds,
            });

            getNotificationsService().success(
                t(rows.length > 1 ? 'REPORTS.NOTIFICATIONS.REPORTS_SENT' : 'REPORTS.NOTIFICATIONS.REPORT_SENT'),
                t(''),
            );
        } catch (e: any) {
            getNotificationsService().error(
                t('REPORTS.NOTIFICATIONS.SEND_IMMEDIATELY_STATUS'),
                t('REPORTS.NOTIFICATIONS.ERROR.SEND_IMMEDIATELY_STATUS_DESC'),
            );
            await getLoggerService().error(`${t('REPORTS.LOGGER.ERROR.SEND_IMMEDIATELY_ERROR')}: ${e?.message}`);
        } finally {
            setIsLoading(false);
        }
    }, [t]);

    const downloadLatest = useCallback(async (rows: IReportTableItem[]) => {
        if (!rows.length) return;
        const selectedReport = rows.at(0);
        if (!selectedReport) return;
        const reportId = selectedReport.reportId;
        const reportName = selectedReport.reportName;
        setIsLoading(true);
        try {
            const reportLink = await getLastReportPdfContent(reportId);
            fileDownload(reportLink, `${reportName}.pdf`);
        } catch (e: any) {
            const errorData = new TextDecoder().decode(e?.response?.data ?? '');
            const errorMessage = errorData ? JSON.parse(errorData).message : '';
            getNotificationsService().error(
                t('REPORTS.NOTIFICATIONS.ERROR.DOWNLOAD_FAILED_TITLE', { reportName }),
                t(errorMessage)
            );
            await getLoggerService().error(`${t('REPORTS.LOGGER.ERROR.SEND_IMMEDIATELY_ERROR')}: ${e?.message}`);
        } finally {
            setIsLoading(false);
        }
    }, [t]);

    const isFieldNotEmpty = (field: string | IReportNotificationItem[] | undefined, label: string) => {
        if (!field?.length) {
            setErrorField(label);
            return false;
        }
        return true;
    };

    const isReportNameUnique = (reportName: string, reportId: number | undefined) => {
        const rows = reportId ? rowData.filter(row => row.reportId !== reportId) : rowData;
        const isUnique = !rows.map(row => row.reportName).includes(reportName);
        if (!isUnique) {
            setErrorField(t('REPORTS.MODAL.DUPLICATE_NAME'));
        }
        return isUnique;
    };

    const isReportValid = (report: IReportSaveItem) => {
        const checkFilters = (name: string, label: string) => {
            return report.filters.find((item: IReportFilterItem) => item.name === name && isFieldNotEmpty(Number.isInteger(item.value) ? item.value.toString() : item.value, label));
        };
        const reportModel = reportsModels.find((model) => model.reportType === report?.reportType);
        const onlyRequiredFilters = reportModel?.supportedFilters.filter((filter) => filter.required);
        const checkedFilters = onlyRequiredFilters?.every((filter) => {
            const filterKey = translatedOptions.FieldsMapping[filter.name as keyof typeof translatedOptions.FieldsMapping];
            if (!(filter.name in translatedOptions.FieldsMapping)) {
                return false;
            }
            return checkFilters(filter.name, filterKey);
        });
        return (
            isFieldNotEmpty(report?.reportType, t('REPORTS.MODAL.TEMPLATE')) &&
            isFieldNotEmpty(report?.reportName, t('REPORTS.MODAL.NAME')) &&
            isReportNameUnique(report?.reportName, report?.reportId) &&
            checkedFilters &&
            isFieldNotEmpty(report?.notifications, t('REPORTS.MODAL.EMAIL.TITLE'))
        );
    };

    const submitReport = async (report: IReportSaveItem) => {
        if (isReportValid(report)) {
            setErrorField('');
            try {
                setIsLoading(true);
                if (isCreate) await createReport(report);
                else {
                    await updateReport(report);
                }
                await loadReports();
                setIsModalOpen(false);
                setIsCreate(false);
                resetTableProps();
                getNotificationsService().success(
                    t('REPORTS.NOTIFICATIONS.SAVE_STATUS'),
                    t('REPORTS.NOTIFICATIONS.SUCCESS.SAVE_STATUS_DESC'),
                );
            } catch (e: any) {
                getNotificationsService().error(
                    t('REPORTS.NOTIFICATIONS.SAVE_STATUS'),
                    t('REPORTS.NOTIFICATIONS.ERROR.SAVE_STATUS_DESC'),
                );
                await getLoggerService().error(`${t('REPORTS.LOGGER.ERROR.SAVE_ERROR')}: ${e?.message}`);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const updateFilterValues = async (_filterValues: IFilterDetails) => {
        setFilterValues(() => _filterValues);
        await loadReports(_filterValues);
    };

    const actions = useMemo(() => {
        const mergedActions = mergeActionDefs(reportsTableActions, ReportsTableRegistry.getActionDefs());
        mergedActions.forEach(action => {
            switch (action.id) {
                case ReportsActions.CREATE_REPORT:
                    action.callback = onCreateReport;
                    break;
                case ReportsActions.EDIT_REPORT:
                    action.callback = onEditReport;
                    break;
                case ReportsActions.RUN_REPORTS:
                    action.callback = sendReports;
                    break;
                case ReportsActions.DOWNLOAD_REPORTS:
                    action.callback = downloadLatest;
                    break;
                case ReportsActions.DELETE_REPORTS:
                    action.callback = () => setIsDeleteReportModalOpen(true);
                    break;
            }
        });
        return mergedActions;
    }, [reportsTableActions, onCreateReport, onEditReport, sendReports, downloadLatest]);

    return (
        <Styles.Wrapper data-aid='reports-list'>
            {isLoading && <CpLoadingDots />}
            <ReportsFilterPanel
                filters={reportsTableFilters}
                updateFilterValues={updateFilterValues} />
            {filterValues &&
                <Table
                    gridOptions={{
                        rowData: rowData,
                        columnDefs: columnDefs,
                        rowSelection: 'multiple',
                        enableBrowserTooltips: true,
                        onSelectionChanged: (event) => {
                            const selectedRows = event.api.getSelectedRows();
                            if (selectedRows.length > 0) {
                                setSelectedRows(selectedRows);
                            }
                        },
                        onGridReady
                    }}
                    actions={actions}
                    disableGrouping
                />
            }
            {isModalOpen && (
                <ReportingModal
                    environmentOptions={environments}
                    vendorOptions={vendors}
                    rulesetOptions={rulesets}
                    organizationalUnitOptions={organizationalUnits}
                    reportsModels={reportsModels}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    saveReport={submitReport}
                    errorField={errorField}
                    title={isCreate ? t('REPORTS.MODAL.CREATE_REPORT') : t('REPORTS.MODAL.EDIT_REPORT')}
                    editReport={
                        !isCreate ? editingRow : null
                    }
                    onCancel={() => {
                        setErrorField('');
                        setIsCreate(false);
                    }}
                    clearErrorField={() => {
                        setErrorField('');
                    }}
                />
            )}
            {isDeleteReportModalOpen && <div>
                <Modal.ModalDialog
                    isOpen={isDeleteReportModalOpen}
                    onRequestClose={() => setIsDeleteReportModalOpen(false)}
                    width={'md'}
                    shouldCloseOnOverlayClick={false}
                >
                    <Modal.ModalHeader
                        title={t('REPORTS.MODAL.DELETE_REPORTS.TITLE')}
                    />
                    <Modal.ModalContent>
                        <div>
                            {t('REPORTS.MODAL.DELETE_REPORTS.DESCRIPTION', { count: selectedRows.length })}
                            <div>
                                {selectedRows.map((row, index) => (
                                    <div key={row.scheduleId}>
                                        {index + 1}. {row.reportName}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Modal.ModalContent>
                    <Modal.ModalFooter>
                        <div className='modal__footer-actions'>
                            <Button onClick={() => setIsDeleteReportModalOpen(false)}>
                                {tCommon('COMMON.CANCEL')}
                            </Button>
                            <Button color='primary' onClick={() => deleteReport()}>
                                {tCommon('COMMON.DELETE')}
                            </Button>
                        </div>
                    </Modal.ModalFooter>
                </Modal.ModalDialog>
            </div>}
        </Styles.Wrapper>
    );
};

export default ReportsTable;
