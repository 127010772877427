import { Button, Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const TableWrapper = styled(Stack)`
  & :not(.ag-row-group) > .ag-cell {
    cursor: pointer;
  }
`;

const DropdownButton = styled(Button)`
  && {
    max-width: unset;
  }
`;

const EventsTableStyles = {
    TableWrapper,
    DropdownButton,
};

export default EventsTableStyles;
