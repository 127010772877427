import React from 'react';
import './EmptyRulesList.scss';
import { useTranslation } from 'react-i18next';
import { CpCommonButton, CpIcon, CpTooltip } from '@dome9/components/react/components';
import { I18nRiskNamespace } from 'modules/riskManagement/consts';

const EmptyRulesList: React.FC<{ addRules: Function; isSuperUser: boolean }> = ({ addRules, isSuperUser }) => {
    const { t } = useTranslation();
    const addRuleButton = () => {
        return (
            <CpCommonButton disabled={!isSuperUser} onClick={addRules}>
                <CpIcon icon='plus' />
                <span>{t('RISK_MANAGEMENT.BUSINESS_PRIORITY.ADD_RULE',{ ns: I18nRiskNamespace })}</span>
            </CpCommonButton>
        );
    };
    return (
        <div className='business-empty-list-container'>
            <div className='empty-icon-container'>
                <CpIcon icon='businessPriorityEmptyList' />
            </div>
            <div className='no-rules-text'>{t('RISK_MANAGEMENT.BUSINESS_PRIORITY.NO_RULES',{ ns: I18nRiskNamespace })}</div>

            <div className='rules-explain-container'>{t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULES_EXPLAIN',{ ns: I18nRiskNamespace })}</div>

            {isSuperUser ? (
                addRuleButton()
            ) : (
                <CpTooltip
                    top
                    className='tooltip'
                    text={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.USER_PERMISSIONS_TOOLTIP',{ ns: I18nRiskNamespace })}>
                    {addRuleButton()}
                </CpTooltip>
            )}
        </div>
    );
};

export default EmptyRulesList;
