import React from 'react';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { IssuesTable } from '../IssuesPage/IssuesTable/IssuesTable';
import { assetIdIssueFilterDataSetter, getFinalIssuesFilterDetails } from '../Issues.utils';
import { Stack } from 'common/design-system/components-v2';

const COLUMNS_STATE_STORAGE_KEY = 'PROTECTED_ASSET_ISSUES_TABLE_COLUMNS_STATE';

export const IssuesTablePanel: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const filterValues = getFinalIssuesFilterDetails({},true);
    assetIdIssueFilterDataSetter(filterValues, entity.protectedAsset.id);
    return <Stack fullHeight ><IssuesTable filterValues={filterValues} columnStateStorageKey={COLUMNS_STATE_STORAGE_KEY}></IssuesTable></Stack>;
};