import React from 'react';
import OciActions, { UpdateOciAccountNameReq } from '../oci.actions';

export interface UseUpdateOciReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: () => void;
}

export interface UseUpdateOciPayload {
    isLoading: boolean;
    isSuccess: boolean;
    isError?: { message: string; };
    updateOci: (cloudAccountId: string, updatePayload: UpdateOciAccountNameReq) => Promise<void>;
    reset?: () => void;
}

export type UseUpdateOci = (req?: UseUpdateOciReq) => UseUpdateOciPayload;

export const useUpdateOci: UseUpdateOci = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const updateOci = React.useCallback(async (cloudAccountId: string, updatePayload: UpdateOciAccountNameReq) => {
        setIsLoading(true);
        try {
            const result = await OciActions.updateOciAccountName(cloudAccountId, updatePayload.name);
            setIsSuccess(result);
            onSuccess?.();
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isSuccess,
        isError,
        updateOci,
        reset
    });
};