import { Addin } from 'common/extensibility/AddinRegistry';
import { AwsEnvironmentAddinRegistry, AwsEnvironmentAddingBase } from 'common/helpers/awsEnvironment';
import { AwsEnvironmentTab } from 'common/module_interface/assets/AwsEnvironment';

export const initialAwsEnvironmentTabs = () => {
    const tabs: Addin<AwsEnvironmentAddingBase<AwsEnvironmentTab>>[] = [
        {
            id: 'magellan',
            position: 30,
            content: {
                id: 'magellan',
                isRelevant: (awsData, extraData) => {
                    const isLogicOnboarded = extraData.cloudAccount.isLogicEventActivityOnboarded || extraData.cloudAccount.isLogicTrafficActivityOnboarded;
                    return !!isLogicOnboarded;
                },
                getValue: () => ({
                    name: 'magellan',
                    label: 'Intelligence',
                    isReactTab: false,
                })
            }
        }];
    AwsEnvironmentAddinRegistry.addTabs(tabs);
};