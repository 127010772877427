import {
    CpButton,
    CpCommonButton,
    CpTextButton,
    CpWizard,
    CpWizardBody,
    CpWizardFooter,
    CpWizardHeader,
    CpWizardStep,
} from '@dome9/components/react/components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AwsOnboardingAccountType, AwsOnboardingType, OnboardingStepType, WizardBody } from './helpers/aws.interface';
import {
    backToDefaultIntelligenceRulesetsAction,
    backToDefaultRulesetsAction,
    goToStep,
    postOrgOnboardingAction,
    startSummaryInterval,
    summaryInterval,
    timoutIsTakingLongerThenExpectedSummary,
} from './helpers/aws.actions';
import {
    canGoBack,
    canGoForward,
    clearOnboardingState,
    getAwsOnboadingAccountType,
    getAwsOnboadingType,
    getAwsOnboardingNextButtonDisabled,
    getAwsStep,
    getAwsStepName,
    getEnableIntelligence,
    getFinishButtonDisabled,
    getIsAccountPage,
    getIsOrgSelected,
    getIsWizardBusy,
    getManagementAccountDetails,
    getOrgOnboardingDone,
    getIsSuccessModalShow,
    goToAwsStep,
    setNextButtonDisabled,
    setUserClickedLaunchStack,
    setIsSuccessModalShow,
} from './helpers/aws.reducer';
import AWSOnboardingAccount from './AWSOnboardingAccount';
import AWSOnboardingServerless from './AWSOnboardingServerless';
import AWSOnboardingSummary from './AWSOnboardingSummary';
import AWSOnboardingWelcome from './AWSOnboardingWelcome';
import AwsSecurityGroupProtectionSummaryButton from './components/AwsSecurityGroupProtectionSummaryButton';
import AwsOrgOnboardManagementAccount from './AwsOrgOnboardManagementAccount';
import AwsOrgOnboardCdr from './AwsOrgOnboardCdr';
import PostureManagement from './components/PostureManagement';
import SecurityGroupProtection from './components/SecurityGroupProtection';
import AWSOnboardingIntelligence from './AWSOnboardingIntelligence';
import { exitOnBoardingWizard } from './helpers/aws.service';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { useHistory } from 'react-router-dom';
import { AWS_INTERVALS } from './helpers/aws.const';
import AWSOrgAccount from './AWSOrgAccount';
import AWSOrgMemberAccount from './AWSOrgMemberAccount';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { getWebAppIframeService } from 'common/interface/services';
import { Spinner, Stack } from 'common/design-system/components-v2';
import SuccessModal from '../components/SuccessModal';
import './AWSOnboarding.scss';

const AWSOnboarding: FunctionComponent = () => {
    const dispatch = useDispatch();
    const isWizardBusy = useSelector(getIsWizardBusy);
    const awsStep = useSelector(getAwsStep);
    const awsStepName = useSelector(getAwsStepName);
    const awsOnboardingType = useSelector(getAwsOnboadingType);
    const { t } = useTranslation();
    const bodyRef = React.useRef();
    const canGoStepForward = useSelector(canGoForward);
    const canGoStepBack = useSelector(canGoBack);
    const isAccountPage = useSelector(getIsAccountPage);
    const awsOnboardingAccountType = useSelector(getAwsOnboadingAccountType);
    const enableIntelligence = useSelector(getEnableIntelligence);
    const isOrgOnboarding = useSelector(getIsOrgSelected);
    const nextButtonDisabled = useSelector(getAwsOnboardingNextButtonDisabled);
    const finishButtonDisabled = useSelector(getFinishButtonDisabled);
    const isOrgOnboardingDone = useSelector(getOrgOnboardingDone);
    const managementAccountDetails = useSelector(getManagementAccountDetails);
    const isSuccessModalShow = useSelector(getIsSuccessModalShow);

    useBreadcrumbsLastChildren([t('ON_BOARDING.ON_BOARDING_TITLE')]);

    const [canResetIntelligenceRulesetsTable, setCanResetIntelligenceRulesetsTable] = useState(true);
    const history = useHistory();
    const rerenderBody = async () => {
        const wizardBody = (bodyRef.current as unknown) as WizardBody;
        await wizardBody.updateSteps();
    };

    useEffect(() => {
        setCanResetIntelligenceRulesetsTable(
            awsStepName === OnboardingStepType.Intelligence &&
            awsOnboardingType === AwsOnboardingType.advanced &&
            enableIntelligence,
        );
    }, [awsStepName, awsOnboardingType, enableIntelligence]);

    useEffect(() => {
        rerenderBody();
        dispatch(setNextButtonDisabled(false));
    }, [awsStep, dispatch]);

    useEffect(() => {
        rerenderBody();
    }, [managementAccountDetails]);

    useEffect(() => {
        if (awsStepName === OnboardingStepType.CloudAccount) {
            startSummaryInterval();
            timoutIsTakingLongerThenExpectedSummary(AWS_INTERVALS.IS_TAKING_LONGER_THAN_EXPECTED_SUMMARY);
        }

        return () => {
            clearInterval(summaryInterval);
        };
    }, [awsStepName]);

    useEffect(() => {
        return () => {
            dispatch(clearOnboardingState());

            // this is a hotfix to tell Webapp that we changed url,
            // to prevent the url that come form webapp
            // This is using the back button action to validate that is going back and informing the webapp of what to do.
            if (history.action === 'POP') {
                (async function() {
                    const iframeMessage = new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.URL_UPDATE,
                        data: { relativePath: '/cloud-account' },
                    });
                    getWebAppIframeService().emitMessage(iframeMessage);
                })();
            }
        };
    }, [dispatch, history.action]);

    const handlePostOrgOnboarding = async () => {
        if (isOrgOnboarding && !isOrgOnboardingDone) {
            await postOrgOnboardingAction();
        } else exitOnBoardingWizard();
    };

    return (
        <div className='aws-onboarding aws-onboarding-wrapper'>
            <CpWizard current-step={awsStep}>
                <CpWizardHeader>
                    { isOrgOnboarding ? t('ON_BOARDING.ORG_ONBOARDING_TITLE') : t('ON_BOARDING.ON_BOARDING_TITLE')}
                </CpWizardHeader>
                <CpWizardBody ref={bodyRef}>
                    <CpWizardStep header={t('ON_BOARDING.WELCOME.WELCOME')} key={t('ON_BOARDING.WELCOME.WELCOME')}>
                        <AWSOnboardingWelcome onChangeType={rerenderBody} />
                    </CpWizardStep>

                    {
                        isOrgOnboarding &&
                        <>
                            <CpWizardStep
                                header={t('ON_BOARDING.ORG_ONBOARDING_CREATE_STACKSETS.TITLE')}
                                key={t('ON_BOARDING.ORG_ONBOARDING_CREATE_STACKSETS.TITLE')}>
                                <AWSOrgAccount />
                            </CpWizardStep>
                            <CpWizardStep
                                header={t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.TITLE')}
                                key={t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.TITLE')}>
                                <AWSOrgMemberAccount />
                            </CpWizardStep>
                            <CpWizardStep
                                header={t('ON_BOARDING.ORG_ONBOARDING_ONBOARD_MANAGEMENT_ACCOUNT.TITLE')}
                                key={t('ON_BOARDING.ORG_ONBOARDING_ONBOARD_MANAGEMENT_ACCOUNT.TITLE')}
                            >
                                <AwsOrgOnboardManagementAccount />
                            </CpWizardStep>
                            { managementAccountDetails && managementAccountDetails.id ? (
                                <CpWizardStep
                                    header={t('ON_BOARDING.AWS_ORG_ONBOARDING_ONBOARD_CDR.TITLE')}
                                    key={t('ON_BOARDING.AWS_ORG_ONBOARDING_ONBOARD_CDR.TITLE')}
                                >
                                    <AwsOrgOnboardCdr />
                                </CpWizardStep>
                            ) : null}
                        </>
                    }

                    {awsOnboardingType === AwsOnboardingType.advanced && (
                        <>
                            <CpWizardStep
                                header={t('ON_BOARDING.SECURITY_GROUP_PROTECTION.TITLE')}
                                key={t('ON_BOARDING.SECURITY_GROUP_PROTECTION.TITLE')}>
                                <SecurityGroupProtection />
                            </CpWizardStep>
                            <CpWizardStep
                                header={t('ON_BOARDING.POSTURE_MANAGEMENT.TITLE')}
                                key={t('ON_BOARDING.POSTURE_MANAGEMENT.TITLE')}>
                                <PostureManagement />
                            </CpWizardStep>
                            {awsOnboardingAccountType === AwsOnboardingAccountType.aws ? (
                                <CpWizardStep
                                    header={t('ON_BOARDING.INTELLIGENCE_ACTIVITY.TITLE')}
                                    key={t('ON_BOARDING.INTELLIGENCE_ACTIVITY.TITLE')}>
                                    <AWSOnboardingIntelligence />
                                </CpWizardStep>
                            ) : (
                                ''
                            )}
                            {awsOnboardingAccountType === AwsOnboardingAccountType.aws ? (
                                <CpWizardStep
                                    header={t('ON_BOARDING.SERVERLESS.TITLE')}
                                    key={t('ON_BOARDING.SERVERLESS.TITLE')}>
                                    <AWSOnboardingServerless />
                                </CpWizardStep>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                    {
                        !isOrgOnboarding &&
                        <>
                            <CpWizardStep header={t('ON_BOARDING.ACCOUNT.TITLE')} key={t('ON_BOARDING.ACCOUNT.TITLE')}>
                                <AWSOnboardingAccount />
                            </CpWizardStep>
                            <CpWizardStep header={t('ON_BOARDING.SUMMARY.DONE')} key={t('ON_BOARDING.SUMMARY.DONE')}>
                                <AWSOnboardingSummary />
                            </CpWizardStep>
                        </>
                    }
                </CpWizardBody>
                <CpWizardFooter>
                    {awsStepName === OnboardingStepType.PostureManagement &&
                        awsOnboardingType === AwsOnboardingType.advanced && (
                        <div className='aws-onboarding-posture-management-reset-button'>
                            <CpButton
                                onClick={() => {
                                    backToDefaultRulesetsAction();
                                }}>
                                {t('ON_BOARDING.RESET_TO_DEFAULT')}
                            </CpButton>
                        </div>
                    )}
                    {awsStepName === OnboardingStepType.Intelligence && (
                        <div className='aws-onboarding-posture-management-reset-button'>
                            <CpButton
                                disabled={!canResetIntelligenceRulesetsTable}
                                onClick={() => {
                                    backToDefaultIntelligenceRulesetsAction();
                                }}>
                                {t('ON_BOARDING.RESET_TO_DEFAULT')}
                            </CpButton>
                        </div>
                    )}
                    { canGoStepBack && (
                        <CpTextButton
                            onClick={() => {
                                goToStep(false);
                                if (awsStepName === OnboardingStepType.CloudAccount) {
                                    dispatch(setUserClickedLaunchStack(false));
                                }
                            }}
                            id='wizard-back-button'>
                            {t('ON_BOARDING.BACK')}
                        </CpTextButton>
                    )}
                    {canGoStepForward ? (
                        isAccountPage ? (
                            <AwsSecurityGroupProtectionSummaryButton />
                        ) : (
                            <CpCommonButton
                                onClick={() => {goToStep(true);}}
                                disabled={nextButtonDisabled || isWizardBusy}
                                id='wizard-next-button'>
                                <Stack spacing={2} direction="row">
                                    {t('ON_BOARDING.NEXT')}
                                    {isWizardBusy && <Spinner size={14}/>}
                                </Stack>
                            </CpCommonButton>
                        )
                    ) : (
                        <React.Fragment>
                            {
                                !isOrgOnboarding &&
                                <CpButton
                                    className='onboard-another-env-button'
                                    onClick={() => {
                                        dispatch(goToAwsStep(0));
                                    }}>
                                    {t('ON_BOARDING.ONBOARD_ANOTHER_ENVIRONMENT')}
                                </CpButton>
                            }
                            <CpCommonButton
                                onClick={handlePostOrgOnboarding}
                                disabled={finishButtonDisabled}
                                data-aid='finish-onboarding-button'>
                                {
                                    isOrgOnboarding && !managementAccountDetails?.id ?
                                        t('ON_BOARDING.ORG_ONBOARDING_ONBOARD_MANAGEMENT_ACCOUNT.ONBOARD_ORGANIZATION') :
                                        t('ON_BOARDING.DONE')
                                }
                            </CpCommonButton>
                        </React.Fragment>
                    )}
                </CpWizardFooter>
            </CpWizard>
            <SuccessModal
                open={isSuccessModalShow}
                text={t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.ORGANIZATION_ONBOARDED_SUCCESSFULLY')}
                isOrgOnboarding={true}
                onClose={()=>{
                    dispatch(setIsSuccessModalShow(false));
                    exitOnBoardingWizard();
                }}
            />
        </div>
    );
};
export default AWSOnboarding;
