import { ToastContainer } from 'react-toastify';
import styled from 'styled-components/macro';
import { Icon as IconBerries } from '@dome9/berries/react-components';
import { NotificationType } from 'common/interface/notifications';

type GetBackgroundColor = (status: NotificationType) => string | undefined
const getBackgroundColor: GetBackgroundColor = (status) => {
    switch (status) {
        case NotificationType.ERROR:
            return '#F24E4E';
        case NotificationType.SUCCESS:
            return '#56B40A';
        case NotificationType.WARNING:
            return '#FFB72D';
        case NotificationType.INFO:
            return '#3B8FD6';
    }
};

type GetIconByStatus = (status: NotificationType) => { name: string, customColor: string }
const getIconByStatus: GetIconByStatus = (status: NotificationType) => {
    switch (status) {
        case NotificationType.ERROR:
            return { name: 'error', customColor: 'color-error-default' };
        case NotificationType.SUCCESS:
            return { name: 'checkCircle', customColor: 'color-ok-default' };
        case NotificationType.WARNING:
            return { name: 'warning', customColor: 'color-warning-default' };
        case NotificationType.INFO:
            return { name: 'info', customColor: 'color-info-default' };
        default:
            return { name: 'error', customColor: 'color-error-default' };
    }
};

const ToastProvider = styled(ToastContainer)`
  
  &&&.Toastify__toast-container {
    height: 60px;

    .Toastify__toast-body {
      padding: 0;
      display: flex;
      margin: 0;
      align-items: normal;
    }

    .Toastify__toast-body > div:last-child {
      display: flex;
    }
    
    .Toastify__toast {
      
      &.error {
        background-color: #F7E6E6;
      }
      
      &.info {
        background-color: #E4EDF4;
      }
      
      &.warning {
        background-color: #F8F1E2;
      }
      
      &.success {
        background-color: #EEF7E6;
      }
    }
  }
`;

const ContentToast = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
  .content-text {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: 10px;
    color: #333333;
  }
`;

const StatusBar = styled.div<{ type: NotificationType }>`
  width: 5px;
  align-self: stretch;
  border-radius: 3px;
  background-color: ${({ type }) => getBackgroundColor(type)};
`;

const Icon = styled(IconBerries).attrs<{ type: NotificationType }>(({ type }) => ({
    name: getIconByStatus(type).name,
    customColor: getIconByStatus(type).customColor
}))<{ type: NotificationType }>``;

export default {
    Icon,
    StatusBar,
    ContentToast,
    ToastProvider
};
