import i18next from 'i18next';
import { i18nIntelligenceNamespace } from '../initialize.i18n';
import { IDashboardWidget, IGenericWidgetDataItemSet } from 'common/module_interface/overview/Interface';
import { getFilteredCloudAccountsBySource } from './Utils';
import { SourceOptionsByDataSourceName } from './Consts';
import dayjs from 'dayjs';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { IAsset } from 'common/assets/common.assets';
import { limitDataItems } from 'common/components/Widgets/helpers';
import moment from 'moment-mini';
import { IGslRunResponse } from 'common/module_interface/intelligence/Intelligence.interface';

const defaultIcon = 'cloud';

export const intelligenceGenericDataConvertor = (response: IGslRunResponse, widget: IDashboardWidget) => {

    const items = response?.data?.reduce((result: { key: any, value: any, icon?:string }[], item: any[], index: number) => {
        const key = item[1];
        const value = item[0];
        const icon = item[2];
        const asset = typeof icon === 'string' ? getProtectedAssetsService().getAssetByType(icon) as IAsset : null;

        if (item.length > 1 && (widget.limit ? index < widget.limit : true)) {
            result.push({
                key: key === false ? i18next.t('WIDGETS.FALSE', { ns: i18nIntelligenceNamespace }) : key === true ? i18next.t('WIDGETS.TRUE', { ns: i18nIntelligenceNamespace }) : key,
                value: value,
                icon: asset?.icon
            });
        } else if (item.length > 1 && (widget.limit ? index === widget.limit : true)) {
            result.push({
                key: i18next.t('WIDGETS.OTHER', { ns: i18nIntelligenceNamespace }),
                value: value,
                icon: defaultIcon,
            });
        } else if (item.length > 1) {
            result[result.length - 1].value += value;
        }
        return result;
    }, []) || [];
    return { items: widget.limit && limitDataItems(items, widget.limit) };
};


export const intelligenceTrendDataConvertor = async (response: IGslRunResponse, widget: IDashboardWidget) => {
    const widgetDataSourceName = widget.dataSourceName as keyof typeof SourceOptionsByDataSourceName;
    const cloudAccounts = await getFilteredCloudAccountsBySource(widgetDataSourceName);
    let items: {};

    if (widget.aggregation === 'sumBytes') {
        items = response?.data?.reduce((result: IGenericWidgetDataItemSet[], item: any[]) => {
            if (item && item.length && item.length > 3) {
                const hour = String(item[0]).padStart(2, '0');
                const sumBytes = item[1];
                const date = item[2];
                const cloudAccountExternalId = item[3];

                const parsedDate = new Date(`${date}T${hour}:00:00Z`);
                const originalDate = moment.utc(parsedDate);
                const dateWithHour = originalDate.format('YYYY-MM-DD HH:mm');

                const newItem = {
                    key: dateWithHour,
                    value: sumBytes
                };
                const selectedCloudAccountName = cloudAccounts.reduce((acc: string, cloudAccount) => {
                    if (cloudAccount.externalId === cloudAccountExternalId) {
                        return cloudAccount.name;
                    }
                    return acc;
                }, '');
                const resultSelectedItem = result.find((res) => res.key === selectedCloudAccountName);
                if (resultSelectedItem) {
                    resultSelectedItem.value.push(newItem);
                } else {
                    result.push({
                        key: selectedCloudAccountName,
                        value: [newItem],
                    });
                }
            }
            return result;
        }, []);
    } else {
        items = response?.data?.reduce((result: IGenericWidgetDataItemSet[], item: any[]) => {
            if (item && item.length && item.length > 2) {
                const ctn = item[0];
                const time = item[1];
                const key = item[2];

                const newItem = {
                    key: time,
                    value: ctn
                };

                const resultSelectedItem = result.find((res) => res.key === key);
                if (resultSelectedItem) {
                    resultSelectedItem.value.push(newItem);
                } else {
                    result.push({
                        key: key,
                        value: [newItem],
                    });
                }
            }
            return result;
        }, []);

    }

    return {
        items: items,
        options: {
            xAxis: {
                labels: {
                    formatter: (category: any) => {
                        return dayjs(category.value).format('HH:mm');
                    }
                }
            }
        }
    };
};
