/* eslint-disable react/prop-types */

import { Card, Media } from '@dome9/berries/react-components';
import { getVendor } from 'common/consts/vendors';
import { getUserService } from 'common/interface/services';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IERMRuleset } from './Rulesets.interface';
import { Button, Icon } from 'common/design-system/components-v2';
import { I18nRiskNamespace } from '../../consts';

const RulesetCard: React.FC<{ ruleset: IERMRuleset, replaceRuleset: (ruleset?: IERMRuleset) => void }> = ({
    ruleset,
    replaceRuleset,
}) => {
    const { t } = useTranslation();
    const vendor = useMemo(() => getVendor(ruleset?.cloudVendor || ''), [ruleset?.cloudVendor]);
    const permissions = getUserService().getUser().calculatedPermissions;
    const isUserPermissions: Boolean = getUserService().getIsSuperUser() || Boolean(permissions?.rulesets?.length && permissions?.notifications?.length);
    return (
        <div className='flex flex-col flex-1'>
            <div className='mb-7 flex items-center'>
                <div className='border-b flex-1 border-dashed' />
                <div className='flex items-center mx-8'>
                    {vendor?.icon && <Icon vendorNameOrPath={vendor.icon} className={'inline-block mr-6'} size={20} />}
                    {vendor?.displayName}
                </div>
                <div className='border-b flex-1 border-dashed' />
            </div>
            <Card>
                <Media icon='ruleset' className='mb-8' title={ruleset.name} />
                <div className='flex mb-8'>
                    <div className=''>
                        <div className='label'>{t('RISK_MANAGEMENT.RULESETS.MANAGED_BY', { ns: I18nRiskNamespace })}:</div>
                        <div className=''>
                            {ruleset.isAllOption ?
                                <div>{t('RISK_MANAGEMENT.RULESETS.NO_MANAGED_BY', { ns: I18nRiskNamespace })}</div> :
                                <div className='flex items-center gap-6'>
                                    {ruleset.icon && <Icon name={ruleset.icon} />}
                                    {ruleset.managedBy}
                                </div>

                            }
                        </div>
                    </div>
                    {Boolean(ruleset.rulesCount) && !ruleset.isAllOption && (
                        <>
                            <div className='border-r mx-8 border-dashed flex-0' />
                            <div>
                                <div className='label'>{t('RISK_MANAGEMENT.RULESETS.RULES', { ns: I18nRiskNamespace })}:</div>
                                <div>{ruleset.rulesCount}</div>
                            </div>
                        </>
                    )}
                </div>

                <div className='flex'>
                    <div>
                        {ruleset.description ?
                            (<div className='label'>{t('RISK_MANAGEMENT.RULESETS.DESCRIPTION', { ns: I18nRiskNamespace }) + ':'} </div>)
                            : <div className='h-[20px]' />
                        }
                        <div className='min-h-[80px] max-h-[80px] overflow-auto break-all'>{ruleset.description}</div>
                    </div>
                </div>
                {isUserPermissions && <>
                    <div className='flex justify-end'>
                        <Button
                            color='brandPrimary'
                            variant='outlined'
                            onClick={() => replaceRuleset(ruleset)}
                            label={t('RISK_MANAGEMENT.RULESETS.REPLACE_RULESET_BTN', { ns: I18nRiskNamespace })}
                            title={t('RISK_MANAGEMENT.RULESETS.REPLACE_RULESET_BTN', { ns: I18nRiskNamespace })}
                        />
                    </div>
                </>}
            </Card>
        </div>);
};

export default RulesetCard;