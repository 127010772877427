import { AUTO_ACTION_FIELD_NAMES, IAutoAction } from './AutoActions.interface';
import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { ermTrans } from '../../RiskManagement.utils';
import { IItemDataGetter } from 'common/erm-components/custom/ClientFilter/ClientFilter.interface';
import {
    createFreeTextFilter,
    createSelectionFilter,
    getCommonFiltersMap
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { CommonFilterEnum } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.consts';
import { IClientFilterType } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';

const integrationNamesGetter: IItemDataGetter<IAutoAction> = (autoAction: IAutoAction) => {
    return autoAction.integrations.map(integration => integration.name);
};
export const getAutoActionsFilters = (): IClientFilterType<IAutoAction, IFilterConditionsContainer>[] => {
    const commonMap = getCommonFiltersMap<IAutoAction>();
    return [
        commonMap[CommonFilterEnum.ADD_FILTER],
        commonMap[CommonFilterEnum.CLEAR_ALL],
        createFreeTextFilter<IAutoAction>([
            AUTO_ACTION_FIELD_NAMES.NAME,
            AUTO_ACTION_FIELD_NAMES.DESCRIPTION,
            AUTO_ACTION_FIELD_NAMES.TRIGGERS,
            integrationNamesGetter
        ]),
        createSelectionFilter<IAutoAction>(
            AUTO_ACTION_FIELD_NAMES.TRIGGERS, ermTrans('AUTO_ACTIONS.TABLE.FILTERS.TRIGGERS.TITLE')),
    ];
};