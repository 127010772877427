import React, { useEffect, useState } from 'react';
import CheckpointLogoHeader from '../../common/components/Forms/CheckpointLogoHeader';
import { LegalNotesFooter } from '../../common/components/Forms/LegalNotesFooter';
import './StoredReport.scss';
import { useHistory, useLocation } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { ResponseType } from 'axios';
import { getReturnUrl } from '../../common/utils/http';
import { useTranslation } from 'react-i18next';
import { getHttpService } from '../../common/interface/services';
import i18next from 'i18next';

export const StoredReport: React.FC<{}> = () => {
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const querystring = new URLSearchParams(location.search);
        const key = querystring.get('key')!;
        const filename = querystring.get('filename')!;
        const reportType = querystring.get('reportType')!;
        const api = querystring.get('api') || 'bigReport';
        let responseType = 'text';
        if (reportType === 'fullcsvzip') {
            responseType = 'blob';
        }
        getHttpService()
            .request(`${api}/${reportType}/${key}`, { responseType: responseType as ResponseType })
            .then((response: any) => {
                fileDownload(response, filename);
                history.push('');
            })
            .catch(async (err) => {
                if (err.response.status === 401) {
                    history.push('/login?returnUrl=' + getReturnUrl());
                    return;
                }

                let message: string = err.message;

                try {
                    const body = await err.response.data.text();
                    if (i18next.exists(`STORED_REPORT.${body}`)) {
                        message = i18next.t(`STORED_REPORT.${body}`);
                    }
                } finally {
                    setErrorMessage(message);
                    setError(true);
                }
            });
    }, [location.search, history]);
    const { t } = useTranslation();
    return (
        <div className='stored-report-page'>
            <CheckpointLogoHeader />
            <div className='stored-report-page__body flex-column'>
                <h2 className='stored-report-main'>{t('STORED_REPORT.PLEASE_WAIT')}</h2>
                {isError ? <h2 className='stored-report-error'>{errorMessage}</h2> : <></>}
            </div>
            <div>
                <LegalNotesFooter />
            </div>
        </div>
    );
};
