import styled from 'styled-components';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
`;

export const AllEventsPageStyled = {
    Body
};
