import React, { useEffect, useState } from 'react';
import './BusinessRuleCard.scss';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { TextField } from '@dome9/berries/react-components';
import KeyValuePairs from './KeyValuePairs/KeyValuePairs';
import { BUSINESS_PRIORITY_TYPES, I18nRiskNamespace } from '../../../consts';
import BUSINESS_PRIORITY_CONST from '../BusinessPriority.const';
import {
    Environment,
    IOrganizationalUnitId,
    KeyValue,
    Rule,
} from '../BusinessPriority.interface';
import { GenericObject } from 'common/interface/general';
import { Button, Checkbox, Icon, IconButton, SelectV2, Stack } from 'common/design-system/components-v2';
import { IOrganizationalUnit } from 'common/interface/data_services';
import MultiSelectorTreeDropdown from 'common/components/MultiSelector/MultiSelectorTreeDropdown';
import MultiSelectorListDropdown from 'common/components/MultiSelector/MultiSelectorListDropdown';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';

const { ASSET_NAME_CONTAINS, ENVIRONMENTS, ORGANIZATIONAL_UNITS, ASSET_TAGS } = BUSINESS_PRIORITY_CONST.PARAMETERS;

const findSelectedEnvironments = (environments?: Environment[] | null, selectedIds?: string[]): Environment[] => {
    if (!environments || !selectedIds) {
        return [];
    }

    const environmentsMap: { [key: string]: Environment } = {};
    environments.forEach((environment: Environment) => environmentsMap[environment.id] = environment);
    return selectedIds.map(id => environmentsMap[id]);
};

const BusinessRuleCard: React.FC<{
    rule: Rule;
    ruleIdx: number;
    organizationalUnitsRoot: IOrganizationalUnit | null;
    environments: Environment[] | null;
    removeRule: Function;
    updateSaveRule: Function;
    setIsRuleEditMode: Function;
    isDuplicateName: Function;
    isRuleEditMode: boolean;
    isSuperUser: boolean;
    cancelNewRule: Function;
}> = ({
    rule,
    ruleIdx,
    organizationalUnitsRoot,
    environments,
    removeRule,
    updateSaveRule,
    isRuleEditMode,
    setIsRuleEditMode,
    isDuplicateName,
    isSuperUser,
    cancelNewRule,
}) => {
    const { t } = useTranslation(I18nRiskNamespace);

    const businessPriorities: SelectOption[] = [
        {
            value: BUSINESS_PRIORITY_TYPES.CROWN_JEWEL,
            label: t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.CRITICAL'),
        },
        {
            value: BUSINESS_PRIORITY_TYPES.HIGH_IMPORTANCE,
            label: t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.HIGH'),
        },
        {
            value: BUSINESS_PRIORITY_TYPES.IMPORTANT,
            label: t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.MEDIUM'),
        },
        {
            value: BUSINESS_PRIORITY_TYPES.MINOR_IMPORTANCE,
            label: t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.LOW'),
        },
    ];

    const getParameter = (name: 'organizationalUnits' | 'environments' | 'assetNameContains' | 'assetTags') => {
        return rule && rule?.ruleDefinition?.[name]?.length ? [...(rule.ruleDefinition?.[name] || [])] : null;
    };

    const tagsListWithIds = () => {
        const list = getParameter('assetTags') as KeyValue[];
        return list?.map((pair: KeyValue) => ({ id: v4(), key: pair?.key, value: pair?.value })) || [{ id: v4() }];
    };
    //validation
    const [isValidRule, setIsValidRule] = useState<boolean>(true);
    const [validTitle, setValidTitle] = useState<{ isValid: boolean; errText: string | null }>({
        isValid: true,
        errText: null,
    });

    const [isEdit, setIsEdit] = useState<boolean>(rule.isEdit);
    const [priority, setPriority] = useState<string>(rule.priority);
    const [ruleName, setRuleName] = useState<string | null>(rule.ruleName);
    const [selectedOrganizationalUnitIds, setSelectedOrganizationalUnitIds] = useState<IOrganizationalUnitId[] | null>(() => getParameter('organizationalUnits') as IOrganizationalUnitId[]);
    const [selectedEnvironments, setSelectedEnvironments] = useState<Environment[] | null>(() => getParameter('environments') as Environment[]);
    const [tagsList, setTagsList] = useState<KeyValue[] | null>(tagsListWithIds);
    const [assetNameContains, setAssetNameContains] = useState<string | null>(() => getParameter('assetNameContains')?.join(',') || '');

    const isTags = () => {
        return !!(tagsList?.length && (tagsList.length > 1 || tagsList[0]?.key || tagsList[0]?.value));
    };

    const [checkboxValues, setCheckboxValues] = useState<GenericObject<boolean>>({
        assetTags: isTags(),
        organizationalUnits: Boolean(selectedOrganizationalUnitIds?.length),
        environments: Boolean(selectedEnvironments?.length),
        assetNameContains: Boolean(assetNameContains),
    });

    const handleCheckbox = (event: any) => {
        if (!isEdit) {
            return;
        }
        const { name, checked } = event.target;
        setCheckboxValues({ ...checkboxValues, [name]: checked });
        if (!checked) {
            switch (name) {
                case ORGANIZATIONAL_UNITS:
                    setSelectedOrganizationalUnitIds(null);
                    break;

                case ENVIRONMENTS:
                    setSelectedEnvironments(null);
                    break;

                case ASSET_TAGS:
                    setTagsList([{ id: v4() }]);
                    break;

                case ASSET_NAME_CONTAINS:
                    setAssetNameContains(null);
                    break;
            }
        }
    };

    const handleRuleName = (newName: string) => {
        let isValidTitle = true;
        let errText = null;
        if (!newName) {
            isValidTitle = false;
            errText = t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.REQUIRED_ERROR', { ns: I18nRiskNamespace });
        }
        if (ruleName !== newName) {
            setRuleName(newName);
            if (isDuplicateName(newName, ruleIdx)) {
                isValidTitle = false;
                errText = t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.ERROR_TITLE', { ns: I18nRiskNamespace });
            }
        }
        setValidTitle({ isValid: isValidTitle, errText });
    };

    const saveRule = () => {
        if (!isValidation()) {
            return;
        }
        const lessIdsList = tagsList?.map((pair: KeyValue) => (pair.key || pair.value ? {
            key: pair.key || null,
            value: pair.value || null,
        } : null)) as KeyValue[];
        const assetTags = lessIdsList?.filter((tag: KeyValue) => (tag && !Object.keys(tag).length ? null : tag));
        const ruleToSave = {
            id: rule.id,
            ruleName,
            priority,
            ruleDefinition: {
                organizationalUnits: selectedOrganizationalUnitIds?.length ? selectedOrganizationalUnitIds : null,
                environments: selectedEnvironments?.length ? selectedEnvironments : null,
                assetTags: assetTags?.length ? assetTags : null,
                assetNameContains: assetNameContains ? assetNameContains?.split(',').filter(Boolean) : null,
            },
        };
        updateSaveRule(ruleToSave, ruleIdx);
        editRule(false);
    };

    const cancelRule = () => {
        if (!rule.id) {
            cancelNewRule();
            return;
        }
        const assetTags = tagsListWithIds();
        const assetNameContains = getParameter('assetNameContains') as string[];
        const organizationalUnitIds = getParameter('organizationalUnits') as IOrganizationalUnitId[];
        const environments = getParameter('environments') as Environment[];
        setTagsList(assetTags);
        setAssetNameContains(assetNameContains && assetNameContains?.join(','));
        setRuleName(rule.ruleName);
        setSelectedOrganizationalUnitIds(organizationalUnitIds);
        setSelectedEnvironments(environments);
        setPriority(rule.priority);
        setCheckboxValues({
            assetTags: !!(assetTags?.length && (assetTags.length > 1 || assetTags[0]?.key || assetTags[0]?.value)),
            organizationalUnits: Boolean(organizationalUnitIds?.length),
            environments: Boolean(environments?.length),
            assetNameContains: Boolean(assetNameContains),
        });
        editRule(false);
        setIsValidRule(true);
        setValidTitle({ isValid: true, errText: '' });
    };

    const editRule = (isEditMode = true) => {
        setIsRuleEditMode(isEditMode);
        setIsEdit(isEditMode);
    };

    const isValidParameters = () => {
        return !!(selectedOrganizationalUnitIds?.length || (selectedEnvironments && selectedEnvironments?.length > 0) || isTags() || assetNameContains);
    };

    const isValidation = () => {
        const isParameters: boolean = isValidParameters();

        if (!ruleName && validTitle.isValid) {
            setValidTitle({ isValid: false, errText: t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.REQUIRED_ERROR', { ns: I18nRiskNamespace }) });
        }

        setIsValidRule(isParameters && priority !== '' && Boolean(ruleName) && validTitle.isValid);
        return isParameters && priority !== '' && Boolean(ruleName) && validTitle.isValid;
    };

    //key value pairs functions
    const addKeyValueItem = () => {
        setTagsList([...(tagsList ?? []), { id: v4(), key: '', value: '' }]);
    };

    const handleKeyValueInput = (name: string, value: KeyValue, itemIdx: number) => {
        const cloneKeyValuePairs = [...(tagsList ?? [])];
        cloneKeyValuePairs[itemIdx] = { ...cloneKeyValuePairs[itemIdx], [name]: value };
        setTagsList(cloneKeyValuePairs);
        const isAssetTags = Boolean(cloneKeyValuePairs?.length && (cloneKeyValuePairs.length > 1 || cloneKeyValuePairs[0]?.key || cloneKeyValuePairs[0]?.value));
        setCheckboxValues({ ...checkboxValues, assetTags: isAssetTags });
    };

    const deleteKeyValueItem = (itemId: string | null) => {
        const cloneKeyValuePairs = tagsList?.filter((pair: KeyValue) => pair.id !== itemId);
        setTagsList(cloneKeyValuePairs ?? [{ id: v4() }]);
    };

    useEffect(() => {
        setIsEdit(rule.isEdit);
    }, [rule.isEdit]);

    const disabledEditButton = !rule.id || isEdit || isRuleEditMode;
    const disabledRemoveButton = !rule.id || (!isEdit && isRuleEditMode);
    return (
        <div className={`business-rule-container ${isEdit ? 'is-edit' : ''}`}>
            <div className='rule-header flex-center-space-between'>
                <div className='rule-name-container flex-center'>
                    <Icon size={24} name='sheet' />
                    <div className='input-container'>
                        <input placeholder={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.RULE_NAME', { ns: I18nRiskNamespace })} disabled={!isEdit}
                            onChange={(event: any) => handleRuleName(event?.target?.value)}
                            className={`rule-name-text ${isEdit ? 'edit' : ''}`} value={ruleName || ''} />
                        {!validTitle.isValid && <div className='err-input'>{validTitle.errText}</div>}
                    </div>
                </div>
                {isSuperUser && (
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <IconButton
                            iconProps={{ name: 'edit' }}
                            onClick={disabledEditButton ? undefined : () => editRule()}
                            disabled={disabledEditButton}
                        />
                        <div className='icons-separator' />
                        <IconButton
                            iconProps={{ name: 'delete' }}
                            onClick={disabledRemoveButton ? undefined : () => removeRule(rule)}
                            disabled={disabledRemoveButton}
                        />
                    </Stack>
                )}
            </div>
            <div className='rule-body flex-center'>
                <div className='parameters-container'>
                    <div className='parameters-title'>
                        <span
                            className='parameters-text-title'>{t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.PARAMETERS', { ns: I18nRiskNamespace })}:</span>
                        {!isValidRule && !isValidParameters() && <div
                            className='err-input'>{t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.SELECTED_PARAMETER_ERROR', { ns: I18nRiskNamespace })}</div>}
                    </div>
                    <div className='parameters-items'>
                        <Stack direction='row' spacing={2}>
                            <Checkbox
                                className='checkbox-element'
                                name={ORGANIZATIONAL_UNITS}
                                onChange={handleCheckbox}
                                readOnly={!isEdit}
                                checked={checkboxValues.organizationalUnits}
                                labelProps={{ size: 'md' }}
                                label={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.ORGANIZATIONAL_UNITS', { ns: I18nRiskNamespace })}
                            />
                            <MultiSelectorTreeDropdown
                                organizationalUnitsRoot={organizationalUnitsRoot || undefined}
                                settings={{
                                    maxHeight: '200px',
                                    minHeight: '150px',
                                }}
                                isEdit={isEdit}
                                selectedIds={selectedOrganizationalUnitIds?.map((ouIdObj: IOrganizationalUnitId) => ouIdObj.id)}
                                nonSelectableIds={organizationalUnitsRoot ? [organizationalUnitsRoot.id] : undefined }
                                nonSelectableReason={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.ROOT_OU_CANNOT_BE_SELECTED', { ns: I18nRiskNamespace })}
                                onSelectionChanged={(selectedIds: string[]) => {
                                    const selectedUnitIdObjs = selectedIds.map(id => {
                                        return { id };
                                    });
                                    setSelectedOrganizationalUnitIds(selectedUnitIdObjs || null);
                                    setCheckboxValues({
                                        ...checkboxValues,
                                        organizationalUnits: Boolean(selectedIds.length),
                                    });
                                }}
                            />
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <Checkbox
                                className='checkbox-element'
                                name={ENVIRONMENTS}
                                onChange={handleCheckbox}
                                readOnly={!isEdit}
                                checked={checkboxValues.environments}
                                labelProps={{ size: 'md' }}
                                label={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.ENVIRONMENT', { ns: I18nRiskNamespace })}
                            />
                            <MultiSelectorListDropdown
                                entities={environments || undefined}
                                settings={{
                                    maxHeight: '200px',
                                    minHeight: '150px',
                                }}
                                isEdit={isEdit}
                                selectedIds={selectedEnvironments?.map((env: Environment) => env.cloudAccountId)}
                                onSelectionChanged={(selectedIds: string[]) => {
                                    const selectedEnvironments = findSelectedEnvironments(environments, selectedIds);
                                    setSelectedEnvironments(selectedEnvironments || null);
                                    setCheckboxValues({
                                        ...checkboxValues,
                                        environments: Boolean(selectedIds.length),
                                    });
                                }}
                            />
                        </Stack>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <Checkbox
                                className='checkbox-element'
                                name={ASSET_TAGS}
                                onChange={handleCheckbox}
                                readOnly={!isEdit}
                                checked={checkboxValues.assetTags}
                                labelProps={{ size: 'md' }}
                                label={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.ASSET_TAGS', { ns: I18nRiskNamespace })}
                            />
                            <KeyValuePairs deleteItem={deleteKeyValueItem} addItem={addKeyValueItem}
                                handleInput={handleKeyValueInput} className='parameter' disabled={!isEdit}
                                pairs={tagsList} />
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <Checkbox
                                className='checkbox-element'
                                name={ASSET_NAME_CONTAINS}
                                onChange={handleCheckbox}
                                readOnly={!isEdit}
                                checked={checkboxValues.assetNameContains}
                                labelProps={{ size: 'md' }}
                                label={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.ASSET_NAME_CONTAINS', { ns: I18nRiskNamespace })}
                            />
                            <TextField
                                maxLength={255}
                                className='parameter'
                                disabled={!isEdit}
                                placeholder={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.ASSET_NAME_PLACEHOLDER', { ns: I18nRiskNamespace })}
                                value={assetNameContains || ''}
                                onChange={(event: any) => {
                                    setAssetNameContains(event.target.value || null);
                                    setCheckboxValues({
                                        ...checkboxValues,
                                        assetNameContains: Boolean(event.target.value),
                                    });
                                }}
                                onClear={() => {
                                    setAssetNameContains(null);
                                    setCheckboxValues({ ...checkboxValues, assetNameContains: false });
                                }}
                            />
                        </Stack>
                    </div>
                </div>

                <div className='priority-container'>
                    <div className='priority-title'>
                        <span
                            className='priority-text-title'>{t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.BUSINESS_PRIORITY', { ns: I18nRiskNamespace })}:</span>
                    </div>
                    <div className='priority-dropdown-container'>
                        <SelectV2 
                            value={businessPriorities.find((type: SelectOption) => type.value === priority)?.value}
                            isMulti={false} 
                            onChange={(value: string) => setPriority(value)}
                            options={businessPriorities}
                            disabled={!isEdit}
                            placeholder={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.PRIORITY')} />
                        {!isValidRule && !priority && <div
                            className='err-input'>{t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.REQUIRED_ERROR', { ns: I18nRiskNamespace })}</div>}
                    </div>
                </div>
            </div>
            {isEdit && (
                <div className='business-footer-container'>
                    <Stack className='business-footer-actions' direction='row' spacing={2}>
                        <Button
                            color='brandPrimary'
                            variant='outlined'
                            label={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.CANCEL', { ns: I18nRiskNamespace })}
                            onClick={cancelRule}
                        />
                        <Button
                            color='brandPrimary'
                            variant='contained'
                            label={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.SAVE', { ns: I18nRiskNamespace })}
                            onClick={saveRule} />
                    </Stack>
                </div>
            )}
        </div>
    );
};

export default BusinessRuleCard;
