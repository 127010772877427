import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { FINDINGS_OVERVIEW_DRAWER_ID, RightPanelItemType } from '../Findings.const';
import FindingOverview from './Components/FindingOverview';
import { EventOverviewRegistry } from 'common/module_interface/events/EventOverviewRegistry';
import UpperPanel from './Components/Panels/UpperPanel';
import { isEmptyString } from 'common/utils/helpFunctions';
import { IEvent } from 'common/module_interface/events/Events';
import { DescriptionPanel } from './Components/Panels/DescriptionPanel';
import { VisualizationGraphPanel } from './Components/Panels/VisualizationGraphPanel';
import { MitrePanel } from './Components/Panels/MitrePanel';
import { BundleNamePanel } from './Components/Panels/BundleNamePanel';
import { GslPanel } from './Components/Panels/GslPanel';
import { isPanelRelevant } from './Services/Utils';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';
import i18n from 'i18next';
import { AlertIdPanel } from './Components/Panels/AlertIdPanel';
import { getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';
import { IconWrapper } from './FindingOverviewStyled';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { InfoPaneFieldProps } from 'common/components/InfoPane/InfoPane';
import { AssetFieldsMap } from 'common/module_interface/assets/AssetsConsts';
import { FindingAssetPanel } from 'common/components/Findings/FindingAssetPanel/FindingAssetPanel';
import { Icon } from 'common/design-system/components-v2';
import { FindingOverviewRegistry } from 'common/module_interface/intelligence/Findings/FindingOverviewRegistry';
import { ExploreLogsButton } from './Components/Panels/RightPanel/ExploreLogsButton';
import { RightHeaderPanel } from './Components/Panels/RightPanel/RightHeaderPanel';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import FindingComments from './Components/Panels/FindingComments';
import { isEmpty } from 'lodash';
import { EventOverviewTabProps, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import React from 'react';
import { FINDING_ASSET_PANEL_ID } from 'common/module_interface/intelligence/Findings/Findings.const';

const FINDING_UPPER_PANEL_ID = 'intelligence-upper-panel';
const FINDING_TAG_PANEL_ID = 'intelligence-tag-panel';
const FINDING_DESCRIPTION_PANEL_ID = 'intelligence-description-panel';
const FINDING_ALERT_ID_PANEL_ID = 'intelligence-alert-id-panel';
export const FINDING_ANOMALY_GRAPH_PANEL_ID = 'intelligence-anomaly-graph-panel';
export const FINDING_VISUALIZATION_GRAPH_PANEL_ID = 'intelligence-visualization-panel';
const FINDING_BUNDLE_NAME_PANEL_ID = 'intelligence-bundle-name-panel';
export const FINDING_GSL_PANEL_ID = 'intelligence-gsl-panel';
export const FINDING_COMMENTS_PANEL_ID = 'intelligence-comments-panel';

function initializeDrawerContent() {
    DrawerRegistry.addContentProvider({
        id: FINDINGS_OVERVIEW_DRAWER_ID,
        getDrawerContent: async ({ finding }): Promise<IDrawerContent | undefined> => {
            const { findingKey, ruleName, severity } = finding;
            const severityInfo = getSafeFindingSeverityInfo(severity);
            const iconColor = severityInfo.iconColors.color;
            const iconColorLightness = severityInfo.iconColors.colorLightness;
            const iconColorBg = severityInfo.bg;
            const result = findingKey ? {
                title: ruleName ?? i18n.t('INT_FINDING_OVERVIEW.OVERVIEW', { ns: i18nIntelligenceNamespace }),
                icon: <IconWrapper justifyContent='center' alignItems='center' withBackground={true} bg={iconColorBg}>
                    <Icon
                        vendorNameOrPath='security-event'
                        color={iconColor}
                        colorLightness={iconColorLightness}
                        size={24}
                    />
                </IconWrapper>,
                component: FindingOverview,
                componentProps: { findingKey, ruleName, finding },
                rightHeaderContent: <RightHeaderPanel finding={finding} />,
            } : undefined;
            return Promise.resolve(result);
        },
    });
}

function initializeUpperPanel(){
    EventOverviewRegistry.addPanelProvider(
        {
            panelId: FINDING_UPPER_PANEL_ID,
            panel: UpperPanel,
            isRelevant: () => true,
        }, 0);
}

export const initializeEventAssetPanel = () => {
    EventOverviewRegistry.addPanelProvider(
        {
            panelId: FINDING_ASSET_PANEL_ID,
            isRelevant: (finding: IFinding) => !isEmptyString(finding.entityId),
            panel: ({ finding }) => {
                const FindingAssetPanelComponent: React.FC<{ finding: IFinding }> = ({ finding }) => (
                    <FindingAssetPanel
                        cloudAccountId={finding.cloudAccountId}
                        cloudAccountType={finding.cloudAccountType}
                        entityDome9Id={finding.entityId}
                        entityExternalId={finding.entityExternalId}
                        entityType={finding.entityType}
                        entityName={finding.entityName} />
                );
                return <FindingAssetPanelComponent finding={finding} />;
            },
        }, 20);

    EventsDrawerRegistry.addOverviewSections([
        {
            id: 'findingAsset',
            position: 0,
            content: {
                id: 'findingAsset',
                isRelevant: (finding: IEvent) => !isEmptyString(finding.entityDome9Id) && finding.entityDome9Id !== 'N-A',
                getValue: (finding: IEvent) => {
                    const FindingAssetPanelComponent: React.FC<EventOverviewTabProps> = () => (
                        <FindingAssetPanel
                            cloudAccountId={finding.cloudAccountId}
                            cloudAccountType={finding.cloudAccountType}
                            entityDome9Id={finding.entityDome9Id || ''}
                            entityExternalId={finding.entityExternalId}
                            entityType={finding.entityType}
                            entityName={finding.entityName}
                        />
                    );
                    return FindingAssetPanelComponent;
                },
            }
        }
    ]);

    const fieldAddins: Addin<IProtectedAssetDetailProvider<InfoPaneFieldProps>>[] = [
        {
            id: 'type',
            position: 20,
            content: AssetFieldsMap.typeField,
        },
        {
            id: 'id',
            position: 30,
            content: AssetFieldsMap.idField,
        },
        {
            id: 'organizationalUnit',
            position: 40,
            content: AssetFieldsMap.organizationalUnitField,
        },
        {
            id: 'environment',
            position: 50,
            content: AssetFieldsMap.environmentField,
        },
        {
            id: 'platform',
            position: 60,
            content: AssetFieldsMap.platformField,
        },
        {
            id: 'region',
            position: 70,
            content: AssetFieldsMap.regionField,
        },
        {
            id: 'vpc',
            position: 80,
            content: AssetFieldsMap.vpcField,
        },
        {
            id: 'tags',
            position: 90,
            content: AssetFieldsMap.tagsField,
        },
    ];
    EventOverviewRegistry.addFieldAddins(fieldAddins, FINDING_ASSET_PANEL_ID);
};

export const initializeEventMitreSectionPanel = () => {
    EventOverviewRegistry.addPanelProvider(
        {
            panelId: FINDING_TAG_PANEL_ID,
            panel: MitrePanel,
            isRelevant: (finding: IFinding) => finding.mitreDict !== undefined,
        }, 10);
};

export const initializeEventDescriptionPanel = () => {
    EventOverviewRegistry.addPanelProvider(
        {
            panelId: FINDING_DESCRIPTION_PANEL_ID,
            panel: DescriptionPanel,
            isRelevant: (finding: IFinding) => !isEmptyString(finding.ruleDescription),
        }, 20);

};

export const initializeEventVisualizationGraphPanel = () => {
    EventOverviewRegistry.addPanelProvider(
        {
            panelId: FINDING_VISUALIZATION_GRAPH_PANEL_ID,
            panel: VisualizationGraphPanel,
            isRelevant: (finding: IFinding) => isPanelRelevant(finding, FINDING_VISUALIZATION_GRAPH_PANEL_ID),
        }, 40);
};

export const initializeEventBundleNamePanel = () => {
    EventOverviewRegistry.addPanelProvider(
        {
            panelId: FINDING_BUNDLE_NAME_PANEL_ID,
            panel: BundleNamePanel,
            isRelevant: (finding: IFinding) => !isEmptyString(finding.rulesetName),
        }, 50);
};

export const initializeEventGslPanel = () => {
    EventOverviewRegistry.addPanelProvider(
        {
            panelId: FINDING_GSL_PANEL_ID,
            panel: GslPanel,
            isRelevant: (finding: IFinding) => (isPanelRelevant(finding, FINDING_GSL_PANEL_ID) && !(finding.additionalFields?.['showQuery'] === '0')),
        }, 60);
};

export const initializeEventAlertIdPanel = () => {
    EventOverviewRegistry.addPanelProvider(
        {
            panelId: FINDING_ALERT_ID_PANEL_ID,
            panel: AlertIdPanel,
            isRelevant: (finding: IFinding) => !isEmptyString(finding.findingKey),
        }, 70);
};

export const initializeFindingCommentsPanel = () => {
    EventOverviewRegistry.addPanelProvider(
        {
            panelId: FINDING_COMMENTS_PANEL_ID,
            panel: FindingComments,
            isRelevant: (finding: IFinding) => !isEmpty(finding.comments),
        }, 80);
};

export const initializeEventRightHeaderPanel = () => {
    FindingOverviewRegistry.addRightHeaderPanelProvider(
        {
            id: RightPanelItemType.ExploreLogs,
            isRelevant: (finding: IEvent) => finding.origin === IFindingModelOriginString.Intelligence,
            component: ExploreLogsButton,
        }, 10);
};

export function initializeFindingOverview() {
    initializeUpperPanel();
    initializeEventAssetPanel();
    initializeEventDescriptionPanel();
    initializeEventMitreSectionPanel();
    initializeEventVisualizationGraphPanel();
    initializeEventBundleNamePanel();
    initializeEventGslPanel();
    initializeEventAlertIdPanel();
    initializeFindingCommentsPanel();
    initializeEventRightHeaderPanel();
    initializeDrawerContent();
}
