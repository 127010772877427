import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams } from 'common/components/Wizard/Wizard';
import { i18nPolicyNamespace } from '../initialize.i18n';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Done } from 'common/components/policy/Done/Done';
import { useHistory } from 'react-router-dom';
import { getCloudAccountsService } from 'common/interface/data_services';
import { getServerlessPolicyService } from 'common/interface/services';
import { IPolicy } from 'common/interface/policy';
import { finishFlow } from 'common/components/policy/utils';
import { useReturnUrl } from 'common/hooks/useReturnUrl';
interface IEnvironment {
    id?: string;
    vendor?: string;
}
type PolicyType = 'TASK' | 'SECURITY_EVENT' | 'NULL';
export const AddEditServerlessPolicyPage: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation(i18nPolicyNamespace);
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>([]);
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const [selectedEnvironment, setSelectedEnvironment] = useState<IEnvironment>();
    const [currentPolicy, setCurrentPolicy] = useState<IPolicy>();
    const returnUrl = useReturnUrl();
    const [policyTopic, setPolicyTopic] = useState<PolicyType>('NULL');
    const saveEditChanges = useCallback( async () => {
        let policy:IPolicy ;
        setFlowStatusMessage(t('GENERAL.PROCESSING'));
        try {
            if(currentPolicy){
                policy = {
                    ...currentPolicy,
                    notificationIds: selectedNotifications.map((notification:ListItem) => notification.id)
                };
                if(selectedNotifications.length === 0){
                    await getServerlessPolicyService().deletePolicy(policy?.id || '');
                }else{
                    await getServerlessPolicyService().updatePolicy([policy]);
                }
            }else{
                policy = {
                    provider: selectedEnvironment?.vendor,
                    vendor: selectedEnvironment?.vendor,
                    topic: policyTopic,
                    cloudAccountId: selectedEnvironment?.id,
                    notificationIds: selectedNotifications.map((notification:ListItem) => notification.id)
                };
                await getServerlessPolicyService().setPolicy([policy]);
            }
            setFlowStatusOK(true);
            setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
        }catch{
            setFlowStatusOK(false);
            setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
        }

    },[currentPolicy, policyTopic, selectedEnvironment?.id, selectedEnvironment?.vendor, selectedNotifications, t]);

    const steps = useMemo(() => [
        {
            name: t('GENERAL.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={selectedNotificationsChanged}/>;
            },
            onNext: async ()=>{
                saveEditChanges();
            },
        },{
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ],[t, selectedNotifications, saveEditChanges, flowStatusMessage, flowStatusOK, history]);

    useEffect(() => {
        const init = async () => {
            const querystring = new URLSearchParams(location.search);
            const accountId = querystring.get('accountId');
            const policyTopic = querystring.get('policyTopic') as PolicyType;
            setPolicyTopic(policyTopic);
            if(policyTopic){
                const policyService = getServerlessPolicyService();
                const policies = await policyService.getPolicies(false);
                if(policies?.length){
                    const _policy = policies.find((policy) => policy.topic === policyTopic);
                    if(_policy?.cloudAccountId === accountId){
                        setCurrentPolicy(_policy);
                    }
                    if(_policy){
                        setSelectedNotifications(_policy.notificationIds.map((notificationId:string) => ({ id: notificationId })) as ListItem[]);
                    }
                }

                if(accountId){
                    const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
                    const environment = allCloudAccounts.find((cloudAccount) => cloudAccount.id === accountId);
                    const _environment:IEnvironment = {
                        id: environment?.id,
                        vendor: environment?.platform,
                    };
                    setSelectedEnvironment(_environment);
                }
            }

        };
        init();
    }, []);

    const selectedNotificationsChanged = (selectedItems: ListItem[]) => {
        setSelectedNotifications(selectedItems);
    };

    return (
        <div>
            {steps?.length ? <Wizard
                steps={steps}
                title={t('GENERAL.ADD_POLICY')}
                doneButtonText={t('GENERAL.DONE')}
                onClose={()=>finishFlow(returnUrl)}
                ShowBackButtonInLastStep={false}
            /> : null}
        </div>
    );
};
