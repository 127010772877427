import { createSelector } from '@reduxjs/toolkit';
import { OciState } from './oci.interface';
import { RootState } from 'common/services/store/store';

const ociReducer = (state: RootState) => state.env_oci as OciState;

const getOci = createSelector([ociReducer, (_state: RootState, id: string) => id], (state, id) => state.ociEvnironments[id]);

export const OciSelector = {
    getOci,
};