import { Node, Edge } from 'reactflow';
import { getVendorBySrl } from 'common/consts/vendors';
import { ExposureNodeData } from 'common/module_interface/insight/ExposureNodeData';
import { EdgeCSSProperties, GraphModel } from 'common/components/Graph/Models/GraphModel';
import { getIcon } from './IconMap';
import { EdgeData } from '../Models/EdgeData';
import { Entity } from '../Models/Entity';
import { GraphData } from '../Models/GraphData';
import { GroupEntity, GroupItem } from '../Models/Groups';
import { GroupType } from '../Models/GroupType';
import { Relationship } from '../Models/Relationship';
import { getExposureColor } from 'common/components/Graph/Services/ColorGetter';
import { ExposureEdgeType } from './EdgeTypeHandler';
import { ExposureNodeType, getExposureNodeType } from './NodeTypeHandler';
import { TitleMap } from './TitleMap';
import { ImportanceLevelMap } from 'common/consts/ImportanceLevel';

const nodeSize = 42;
const nodeScore = 1;

function createGroupItem(entity: Entity, groupType: string): GroupItem | null {
    switch (groupType) {
        case GroupType.iamImpact: {
            return {
                graphId: entity.graphId,
                icon: getIcon(entity),
                name: entity.name,
                value: entity.additionalData?.iamImpactData?.entityCount.toString()
            };
        }
        case GroupType.networkAccess: {
            const isCrownJewel = entity.additionalData?.riskData?.businessPriority === 'Crown Jewel';
            const groupItem: GroupItem = {
                graphId: entity.graphId,
                icon: getIcon(entity),
                name: entity.name || entity.externalId,
                srl: entity.srl,
                gotoUrl: entity.gotoUrl
            };

            if (isCrownJewel) {
                groupItem.valueIconProps = { name: 'assetCrownJewel', customColor: ImportanceLevelMap.critical.fg };
                groupItem.valueStyle = {
                    backgroundColor: ImportanceLevelMap.critical.bg,
                    'display': 'flex',
                    'borderRadius': '100px',
                    'minWidth': '26px',
                    'minHeight': '26px',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                };
            }

            return groupItem;
        }
        default: {
            return null;
        }
    }
}

function createGroupEntity(groupId: string, connectedTo: string, groupType: GroupType, entity: Entity): GroupEntity {
    const group: GroupEntity = {
        graphId: groupId,
        groupType: groupType,
        connectedTo: connectedTo,
        items: [],
        name: ''
    };

    switch (groupType) {
        case GroupType.iamImpact: {
            group.name = 'IAM Access';
            group.srl = entity.gotoSrl as string;
            break;
        }
        case GroupType.networkAccess: {
            group.name = 'Network Access';
            break;
        }
    }

    return group;
}

function createGroupEntities(entities: Entity[]): GroupEntity[] {
    const groups: Record<string, GroupEntity> = {};

    for (const entityToGroup of entities) {
        for (const [groupType, fields] of Object.entries(entityToGroup.additionalData?.groups!)) {
            const concatenatedFields = Object.entries(fields).map(([fieldKey, fieldValue]) => `${fieldKey}:${fieldValue}`).join(',');
            const groupId = `${groupType}-${concatenatedFields}`;
            if (!groups[groupId]) {
                groups[groupId] = createGroupEntity(groupId, fields.graphId, groupType as GroupType, entityToGroup);
            }

            const groupItem = createGroupItem(entityToGroup, groupType);
            if (!groupItem) continue;

            groups[groupId].items.push(groupItem);
        }
    }

    return Object.values(groups).filter(g => g.items?.length);
}

function mapGroupNodes(entities: Entity[]): Node[] {
    const entitiesToGroup = entities.filter(e => e.additionalData?.groups && Object.keys(e.additionalData?.groups).length > 0);
    const groupEntities = createGroupEntities(entitiesToGroup);

    groupEntities.forEach(group => {
        group.items.sort((groupItem1, groupItem2) => {
            if (groupItem2.valueIconProps && !groupItem1.valueIconProps) return 1;
            if (!groupItem2.valueIconProps && groupItem1.valueIconProps) return -1;
            if (groupItem2.valueIconProps && groupItem1.valueIconProps) return 0;

            if ((groupItem2?.value ?? 0) > (groupItem1?.value ?? 0)) return 1;
            if ((groupItem2?.value ?? 0) < (groupItem1?.value ?? 0)) return -1;

            return 0;
        });
    });

    return groupEntities.map(e => {
        return {
            id: e.graphId,
            position: { x: 0, y: 0 },
            data: e,
            type: ExposureNodeType.ExposureGroupNode
        };
    });
}

function mapAssetNodes(entities: Entity[]): Node[] {
    return entities.filter(e => !e?.additionalData?.groups || Object.keys(e?.additionalData?.groups).length === 0).map(e => {
        const vendor = getVendorBySrl(e.srl);

        const data: ExposureNodeData = {
            id: e.externalId,
            label: TitleMap[e.type] ?? e.type,
            size: nodeSize,
            score: nodeScore,
            exposureLevel: e.additionalData?.riskData?.exposureLevel,
            iamExposure: e.additionalData?.riskData?.iamExposure,
            name: e.name,
            type: e.type,
            externalId: e.externalId,
            businessPriority: e.additionalData?.riskData?.businessPriority,
            riskScore: e.additionalData?.riskData?.riskScore,
            postureFindings: e.additionalData?.riskData?.postureFindings,
            iamSensitivity: e.additionalData?.riskData?.iamSensitivity,
            cves: e.additionalData?.riskData?.cves,
            gotoUrl: e.gotoUrl,
            isInContext: e.isInContext ?? false,
            srl: e.srl,
            platform: vendor?.name,
            headerIcon: getIcon(e),
            securityEvents: e.additionalData?.riskData?.securityEvents,
            classifications: e.additionalData?.classifications,
            wafProtection: e.additionalData?.riskData?.wafProtection,
        };

        const node: Node = {
            id: e.graphId,
            position: { x: 0, y: 0 },
            data: data,
            type: getExposureNodeType(e)
        };

        return node;
    });
}

function mapEdges(relationships: Relationship[], entities: Entity[]): Edge[] {
    return relationships.map(r => {
        const source = entities.find(e => e.graphId === r.fromGraphId);
        const sourceRiskColor = getExposureColor(source?.additionalData?.riskData?.riskScore);
        const target = entities.find(e => e.graphId === r.toGraphId);
        const targetRiskColor = getExposureColor(target?.additionalData?.riskData?.riskScore);
        const linkedEntity = entities.find(e => e.graphId === r.additionalData?.networkTraffic?.exposingGraphId);
        const edgeId = `${r.fromGraphId}To${r.toGraphId}`;
        const edgeStyle: EdgeCSSProperties = {
            '--stopColorStart': sourceRiskColor,
            '--stopColorEnd': targetRiskColor
        };
        return {
            id: edgeId,
            source: r.fromGraphId,
            target: r.toGraphId,
            type: ExposureEdgeType.ExposureEdge,
            data: buildEdgeData(r, linkedEntity, sourceRiskColor, targetRiskColor, false),
            style: edgeStyle,
            markerEnd: `marker-end-${edgeId}`
        };
    });
}

function buildEdgeData(relationship: Relationship, entity: Entity | undefined, sourceRiskColor: string, targetRiskColor: string, gradientBackground: boolean): EdgeData | null {
    if (!relationship?.additionalData?.networkTraffic?.networkTrafficConfigurations) return null;

    const data: EdgeData = {
        securityGroupConfigurations: relationship.additionalData.networkTraffic.networkTrafficConfigurations,
        sourceRiskColor: gradientBackground ? sourceRiskColor : getExposureColor(),
        targetRiskColor: gradientBackground ? targetRiskColor : getExposureColor(),
    };

    if (entity?.srl) {
        data.entity = {
            name: entity.name,
            srl: entity.srl,
            type: entity.type,
            externalId: entity.externalId,
            gotoUrl: entity?.gotoUrl
        };
    }

    return data;
}

function manipulateRelationshipsAccordingToGroups(originalRelationships: Relationship[], groupNodes: Node[]): Relationship[] {
    const groupedItemConnections = groupNodes.flatMap(gn => gn.data.items.map((groupItem: GroupItem) => `${gn.data.connectedTo}$|$${groupItem.graphId}`));

    const relationships = originalRelationships.filter(r => !groupedItemConnections.includes(`${r.fromGraphId}$|$${r.toGraphId}`));
    groupNodes.forEach(gn => relationships.push({
        fromGraphId: gn.data.connectedTo,
        toGraphId: gn.id,
        type: ''
    }));

    return relationships;
}

export function mapToGraphEntities(graphData: GraphData): GraphModel {
    const assetNodes = mapAssetNodes(graphData.entities);
    const groupNodes = mapGroupNodes(graphData.entities);
    const relationships = manipulateRelationshipsAccordingToGroups(graphData.relationships, groupNodes);
    const edges = mapEdges(relationships, graphData.entities);

    return {
        nodes: [
            ...assetNodes,
            ...groupNodes
        ],
        edges: edges
    };
}