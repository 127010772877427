import React from 'react';
import { EventOverviewTabProps } from 'common/module_interface/events/EventsDrawerRegistry';
import EntityCard from 'common/design-system/components-v2/EntityCard/EntityCard';
// import { useTranslation } from 'react-i18next';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import RemediationPanel from 'common/components/RemediationPanel/RemediationPanel';
import { Command } from 'common/design-system/components-v2';
import { getCheckpointUrl } from 'common/components/Forms/urls';

const EventsRuleCardComponent: React.FC<EventOverviewTabProps> = ({ finding: event }) => {
    // const { t } = useTranslation(); //TODO: fix terms

    const buildEntityCardBlocks = (): BlockInfoProps[] => {
        const getRulesetUrl = () => {
            if (!event.bundleId) return undefined;
            switch (event.origin) {
                case IFindingModelOriginString.ComplianceEngine:
                case IFindingModelOriginString.KubernetesRuntimeAssurance:
                    return `/compliance-engine/policy/${event.bundleId}`;
                case IFindingModelOriginString.Magellan:
                    return `/magellan/ruleset/${event.bundleId}`;
                default:
                    return undefined;
            }
        };

        const isExternalFinding = event.origin === IFindingModelOriginString.ExternalFindingSource || event.origin === IFindingModelOriginString.Tenable;

        const blocks: (BlockInfoProps & { isVisible?: boolean; })[] = [
            {
                title: 'Ruleset',
                info: event.bundleName,
                copyable: true,
                internalUrl: getRulesetUrl(),
                isVisible: !!event.bundleName,
            },
            {
                title: 'RuleID',
                info: event.ruleId,
                copyable: true,
                externalUrl: `${getCheckpointUrl('GSL_KNOWLEDGE_BASE')}/${event.ruleId}.html`,
                isVisible: !!event.ruleId && Number(event.bundleId) < 0,
            },
            {
                title: 'Rule Description',
                info: event.description,
                copyable: true,
                fullWidth: true,
                maxWidth: undefined,
                isVisible: !!event.description,
            },
            {
                title: 'Remediation',
                fullWidth: true,
                maxWidth: undefined,
                info: <RemediationPanel remediation={event.remediation || ''}/>,
                isVisible: !!event.remediation,
            },
            {
                title: 'Remediation Actions',
                fullWidth: true,
                maxWidth: undefined,
                info: (
                    <RemediationPanel
                        remediation={event.remediationActions.reduce((acc, action, index) => {
                            if (index === 0) return action;
                            return `${acc}\n\n${action}`;
                        }, '')}
                    />
                ),
                isVisible: event.remediationActions.length > 0,
            },
            {
                title: 'GSL',
                fullWidth: true,
                maxWidth: undefined,
                info: <Command text={event.ruleLogic} />,
                isVisible: !!event.ruleLogic,
            },
            {
                title: 'Scan ID',
                fullWidth: true,
                maxWidth: undefined,
                info: event.scanId,
                copyable: true,
                isVisible: isExternalFinding && !!event.scanId,
            },
        ];
        
        return blocks.filter(block => block.isVisible).map(block => ({ ...block, isVisible: undefined }));

    };
    
    return (
        <EntityCard
            title={event.ruleName}
            initialShowMoreState
            cardTitle='Rule'
            blocks={buildEntityCardBlocks()}
            levelIcon={{
                iconProps: {
                    name: 'rule',
                },
            }}
        />
    );
};

export default EventsRuleCardComponent;