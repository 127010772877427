import { IServerSideGetRowsParams } from 'ag-grid-enterprise';
import {
    
    findingModelActionStringToCode,
    findingModelAlertTypeStringToCode,
    findingModelCloudAccountTypeStringToCode,
    findingModelOriginStringToCode,
    FindingsDataSource,
    IFindingModelCloudAccountType,
    ISearchFilterViewModel,
} from 'common/components/Findings/Findings.interface';
import {
    FINDINGS_AGGREGATIONS_URL,
    FINDINGS_GROUPING_URL,
} from 'common/module_interface/events/EventsConsts';
import { IEventsDatasource, IEventsElasticDatasourceParams } from 'common/interface/events';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import FindingsReportApi from 'common/services/apis/FindingsReport/findingsReport.service';
import { BaseFindingReportExportToCsvRequest } from 'common/services/apis/FindingsReport/findingsReport.interface';
import ComplianceFindingApi from 'modules/events/services/complianceFindingApi/complianceFindingApi.service';
import { ComplianceFindingGroupsByProperties, ComplianceFindingSearchWithCustomAggregations } from 'modules/events/services/complianceFindingApi/complianceFindingApi.interface';

export class EventsElasticDatasource implements IEventsDatasource {
    public isFeching;
    public isLoading;
    public isError;
    public rowCount;
    public totalRowCount;
    public initialParams: IEventsElasticDatasourceParams;
    public filters: ISearchFilterViewModel | undefined;

    private searchAfter: string[];
    private subscribeList: { [key: string]: (state: IEventsDatasource) => void } = {};

    constructor(private params: IEventsElasticDatasourceParams) {
        this.isFeching = false;
        this.isLoading = true;
        this.isError = false;
        this.rowCount = 0;
        this.totalRowCount = 0;
        this.initialParams = this.params;
        this.searchAfter = [];
    }

    private mergeFiltersWithDefaults(filters?: ISearchFilterViewModel, additionalFilters?: ISearchFilterViewModel): ISearchFilterViewModel {
        return {
            ...this.initialParams.defaultFilters,
            ...filters,
            ...additionalFilters,
            fields: [...(this.initialParams.defaultFilters?.fields || []), ...(filters?.fields || []), ...(additionalFilters?.fields || [])],
            filterFields: [...(this.initialParams.defaultFilters?.filterFields || []), ...(filters?.filterFields || []), ...(additionalFilters?.filterFields || [])],
        };
    }

    public async downloadCSV(selectedColumns?: string[]): Promise<boolean> {
        const payload: BaseFindingReportExportToCsvRequest = {
            searchRequest: {
                dataSource: this.initialParams.findingsDataSource,
                filter: this.mergeFiltersWithDefaults(this.filters),
            },
            selectedColumns
        };

        try {
            const response = await FindingsReportApi.downloadCSV(payload);
            const anchor = document.createElement('a');
            const blob = new Blob([response.data], { 'type': 'application/octet-stream' });
            anchor.href = URL.createObjectURL(blob);
            anchor.download = 'CSV_findings_report.zip';
            anchor.click();
            return true;
        } catch (err) {
            return false;
        }
    }

    public async emailCSV(recipents: string[], selectedColumns?: string[]): Promise<boolean> {
        const payload: BaseFindingReportExportToCsvRequest = {
            searchRequest: {
                dataSource: this.initialParams.findingsDataSource,
                filter: this.mergeFiltersWithDefaults(this.filters),
            },
            recipents: recipents,
            selectedColumns
        };

        try {
            FindingsReportApi.exportToCsv(payload);
            return true;
        } catch (err) {
            return false;
        }
    }

    public updateFilters(filters?: ISearchFilterViewModel) {
        this.filters = filters;
        this.rowCount = 0;
        this.notifySubscribers();
    }

    public async getAggregations(): Promise<Aggregations> {
        const defaultAggregations = [
            'aggregationEntityTypeByEnvironmentType',
            'cloudAccountId',
            'severity',
            'alertType',
            'acknowledged',
            'cloudAccountType',
            'entityType',
            'isExcluded',
            'origin',
            'labels',
            'action',
            'ownerUserName',
            //'ruleName',
            'bundleName',
            'entityNetwork',
            'category',
            'region'
        ];

        const payload: ComplianceFindingSearchWithCustomAggregations.Request = {
            dataSource: this.initialParams.findingsDataSource,
            skipAggregations: false,
            aggregations: defaultAggregations,
            filter: this.mergeFiltersWithDefaults(this.initialParams.defaultFilters),
            lowAggregationsSize: true,
            pageSize: 0
        };

        try {
            const promiseResults = await ComplianceFindingApi.searchWithCustomAggregations(payload);
            this.totalRowCount = promiseResults.data.totalFindingsCount;
            this.isLoading = false;
            return promiseResults.data.aggregations;
        } catch (err) {
            return {};
        }
    }

    public getRows(params: IServerSideGetRowsParams): void {
        if (params.request.rowGroupCols.length > 0) {
            this.handleGrouping(params);
        } else {
            this.handleSearch(params, undefined, params.request.rowGroupCols.length > 0);
        }
    }

    public destroy(): void {
        this.isFeching = false;
        this.isError = false;
        this.rowCount = 0;
        this.totalRowCount = 0;
        this.filters = undefined;
        this.notifySubscribers();
    }

    fixRemediationFilter(filters?: ISearchFilterViewModel): void {
        const hasRemediation = filters?.fields?.findIndex(field => field.name === 'showFixable') || -1;
        if ((hasRemediation > -1) && filters?.fields) {
            filters.fields.splice(hasRemediation, 1);
            filters.hasRemediation = true;
        }
    }

    private handleGrouping(params: IServerSideGetRowsParams) {
        const rowGroupCols = params.request.rowGroupCols;
        const groupKeys = params.request.groupKeys;

        const fixFilterValueByKey = (key: string, value: any) => {
            switch (key) {
                case 'origin':
                    return findingModelOriginStringToCode(value);
                case 'action':
                    return findingModelActionStringToCode(value);
                case 'alertType':
                    return findingModelAlertTypeStringToCode(value);
                default:
                    return value;
            }
        };

        const fields = params.request.groupKeys.map((groupKey, index) => ({
            name: params.request.rowGroupCols[index].id,
            value: fixFilterValueByKey(params.request.rowGroupCols[index].id, groupKey),
        }));

        if (rowGroupCols.length === groupKeys.length) {
            return this.handleSearch(params, {
                fields,
            }, true);
        }

        if (params.parentNode?.data?.nestedBuckets !== undefined) {
            params.success({
                rowData: params.parentNode.data.nestedBuckets.map((item: any) => ({
                    isGrouped: true,
                    [item.fieldName]: item.fieldValue,
                    id: item.fieldValue,
                    childCount: item.numberOfDocuments,
                    nestedBuckets: item.nestedBuckets,
                })),
            });
            return;
        }

        let direction = -1;
        if (params.request.sortModel.length > 0) {
            direction = params.request.sortModel[0].sort === 'asc' ? 1 : -1;
        }

        fields.push({
            name: 'status',
            value: this.initialParams.findingsDataSource === FindingsDataSource.ARCHIVE ? '1' : '0'
        });

        const payload: ComplianceFindingGroupsByProperties.Request = {
            filter: this.mergeFiltersWithDefaults(this.filters, { fields }),
            propertiesList: rowGroupCols.map((group) => ({
                property: group.id,
                sortOption: 'BucketsCount',
                direction,
                maxSize: 10000
            })),
        };

        this.fixRemediationFilter(payload.filter);

        this.isFeching = true;
        ComplianceFindingApi.groupsByProperties(payload)
            .then(({ data }) => {
                const rowData: any[] = [];
                let rowCount = 0;
                data.forEach((item) => {
                    rowData.push({
                        isGrouped: true,
                        [item.fieldName]: item.fieldValue,
                        id: item.fieldValue,
                        childCount: item.numberOfDocuments,
                        nestedBuckets: item.nestedBuckets,
                    });
                    rowCount += item.numberOfDocuments;
                });
                
                this.rowCount = rowCount;
                params.success({
                    rowData,
                    rowCount: data.length,
                });
                this.isFeching = false;
                this.notifySubscribers();
            })
            .catch((err) => {
                params.context = {
                    error: err,
                };
                params.fail();
                console.error(`Could not fetch data from server getting ${FINDINGS_GROUPING_URL} - ${err}`);
                this.isError = true;
                this.rowCount = 0;
            });

    }

    private handleSearch(params: IServerSideGetRowsParams, additionalFilters?: ISearchFilterViewModel, ignoreRowCountChange?: boolean) {
        if (params.request.startRow === 0) {
            this.searchAfter = [];
            if (!ignoreRowCountChange) {
                this.rowCount = 0;
            }
        }
        
        this.isFeching = true;
        this.notifySubscribers();

        const mergedFilters = this.mergeFiltersWithDefaults(this.filters, additionalFilters);
        if (additionalFilters?.fields) { // merge group values
            additionalFilters.fields.forEach(({ name, value }) => {
                mergedFilters.fields = mergedFilters.fields?.filter(field => field.name !== name) || []; // remove values with same group name
                mergedFilters.fields.push({ name, value }); // add new group value
            });
        }

        this.fixRemediationFilter(mergedFilters);
        
        const payload: ComplianceFindingSearchWithCustomAggregations.Request = {
            dataSource: this.initialParams.findingsDataSource,
            aggregations: [],
            filter: mergedFilters,
            pageSize: 50,
            searchAfter: this.searchAfter,
            skipAggregations: true,
        };

        if (params.request.sortModel.length === 1) {
            payload.sorting = {
                fieldName: params.request.sortModel[0].colId,
                direction: params.request.sortModel[0].sort === 'asc' ? 1 : -1,
            };
        } else if (params.request.sortModel.length > 1) {
            payload.multiSorting = params.request.sortModel.map((sortModel) => ({
                fieldName: sortModel.colId,
                direction: sortModel.sort === 'asc' ? 1 : -1,
            }));
        }

        const fixFilterValueByKey = (key: string, value: any) => {
            switch (key) {
                case 'cloudAccountType': {
                    const isStringKey = IFindingModelCloudAccountType[value as IFindingModelCloudAccountType] !== undefined;
                    if (isStringKey) return findingModelCloudAccountTypeStringToCode(value);
                    return value;
                }
                default:
                    return value;
            }
        };

        payload.filter?.fields?.forEach((field) => {
            field.value = fixFilterValueByKey(field.name, field.value);
        });
        
        ComplianceFindingApi.searchWithCustomAggregations(payload)
            .then(async ({ data }) => {
                if (!ignoreRowCountChange) {
                    this.rowCount = data.totalFindingsCount;
                }
                this.searchAfter = data.searchAfter || [];
                params.success({
                    rowData: data.findings,
                });
            })
            .catch((err) => {
                params.context = {
                    error: err,
                };
                params.fail();
                console.error(`Could not fetch data from server getting ${FINDINGS_AGGREGATIONS_URL} - ${err}`);
                this.isError = true;
                this.rowCount = 0;

            }).finally(() => {
                this.isFeching = false;
                this.notifySubscribers();
            });
    }

    private notifySubscribers() {
        Object.values(this.subscribeList).forEach((callback) => callback(this));
    }

    public subscribeToStateChange(callback: (state: IEventsDatasource) => void): string {
        const subscribeKey = Math.floor(Math.random() * (10000000 - 1000000 + 1) + 1000000).toString();
        this.subscribeList[subscribeKey] = callback;
        callback(this);
        return subscribeKey;
    }

    public unsubscribeFromStateChange(subscribeKey: string): void {
        delete this.subscribeList[subscribeKey];
    }
}
