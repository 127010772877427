import { getCloudAccountsService, IAggregationDataItem, ICloudAccount } from 'common/interface/data_services';
import { getFindingsService } from 'common/module_interface/intelligence/intelligence';
import { getSummaryDateRange } from './CdrDashboard.utils';
import { ILevelBoxItem } from 'common/erm-components/custom/LevelBoxes/LevelBox.interface';
import {
    FindingSeverityEnum, FindingSeverityServerEnum,
    getFindingSeverities,
    IFindingSeverityInfo,
} from 'common/consts/FindingSeverity';
import { SortOrder } from 'common/interface/general';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { CompoundFilterLogicalOperator } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { FilterConditionOperator } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { OriginType } from 'common/module_interface/intelligence/Intelligence.const';
import { IFindingGslFilter } from 'common/module_interface/intelligence/Gsl/FindingGslService.interface';
import { ICoverageData } from './CdrDashboard.interface';

const fetchSummaryCoverageData = async (): Promise<ICoverageData> => {
    const data: ICloudAccount[] = await getCloudAccountsService().getAllCloudAccounts();
    const totalCloudAccounts = data.length;
    const onboardedCloudAccounts = data.filter((account: ICloudAccount) =>
        account.isLogicTrafficActivityOnboarded || account.isLogicEventActivityOnboarded).length;

    const getCoverage = () => {
        return totalCloudAccounts > 0 ? Number((onboardedCloudAccounts * 100 / totalCloudAccounts).toFixed(1)) : 0;
    };

    return { value: onboardedCloudAccounts, outOf: totalCloudAccounts, coverage: getCoverage() };
};

const fetchSummarySevereEventsData = async (): Promise<ILevelBoxItem[]> => {
    const dateRange = getSummaryDateRange();
    const filter: IFindingGslFilter = {
        compoundFilter: {
            root: {
                logicalOperator: CompoundFilterLogicalOperator.AND,
                operands: [
                    {
                        name: FindingFields.origin,
                        operator: FilterConditionOperator.In,
                        values: [OriginType.Intelligence]
                    },
                    {
                        name: FindingFields.severityName,
                        operator: FilterConditionOperator.In,
                        values: [FindingSeverityServerEnum.High, FindingSeverityServerEnum.Critical]
                    }
                ]
            }
        },
        timeFilter: {
            field: FindingFields.eventTime,
            range: {
                from: dateRange.start,
                to: dateRange.end,
            }
        }
    };
    const aggregations = [FindingFields.severityName];
    const findingAggregations: Aggregations = await getFindingsService().getFindingFacets(filter, aggregations);
    if (!findingAggregations) return [];

    const severitiesBoxItems: ILevelBoxItem[] = [];
    const severities = getFindingSeverities(SortOrder.descending, FindingSeverityEnum.high);
    const convertedSeverities = findingAggregations[FindingFields.severityName];

    convertedSeverities.forEach((result: IAggregationDataItem) => {
        const severity = severities.find((severity: IFindingSeverityInfo) =>
            severity.serverKey === result.value);
        if (severity) {
            severitiesBoxItems.push({
                count: result.count,
                text: severity.displayText,
                color: severity.color,
                level: severity.level,
            });
        }
    });

    return severitiesBoxItems;
};

export const fetchSummaryStatusData = async () => {
    const [coverageData, securityEventsData] = await Promise.all([
        fetchSummaryCoverageData(),
        fetchSummarySevereEventsData()
    ]);

    return {
        coverageData,
        securityEventsData,
    };
};
