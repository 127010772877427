import { IActionUsageDef } from 'common/interface/general';
import { FindingsActions, ModalType } from '../../Findings.const';
import { UserRolesTypes } from 'common/enum/UserRoles';
import FixItAction from '../Actions/FixItAction/FixItAction';
import {
    CDR_REPORT_FORM_POC_ACTIVE_FEATURE,
    EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE,
} from '../../../ActiveFeatures/ActiveFeatures.consts';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { showCdrExclusionForm } from '../Modals/CdrExclusion/CdrExclusion.items';
import dayjs from 'dayjs';
import { cdrTrans } from '../../../Intelligence.utils';
import { isAfEnabled } from 'common/utils/debugUtils';
import { showCdrReportForm } from '../Modals/ReportIssueModal/ReportIssue.items';
import { isNil } from 'common/utils/helpFunctions';

export const createFixItAction = (tableId: string, openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef => {
    return {
        id: FindingsActions.FIX_IT,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            customComponent: (selectedRows: IFinding[]) => {
                return <FixItAction tableId={tableId} selectedRows={selectedRows}
                    openDialog={openDialog}></FixItAction>;
            }
        }
    };
};

export const createRemediateAction = (openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef => {
    return {
        id: FindingsActions.REMEDIATE,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.Remediate);
            }
        }
    };
};

export const createExcludeAction = (): IActionUsageDef => {
    return {
        id: FindingsActions.EXCLUDE,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            callback: (selectedRows: IFinding[]) => {
                if (selectedRows.length === 1) {
                    const finding: IFinding = selectedRows[0];
                    showCdrExclusionForm({
                        name: finding.ruleName,
                        comment: cdrTrans('CDR_EXCLUSIONS.EDITOR.COMMENT_BY_RULE_PREFIX', { title: finding.ruleName }),
                        rulesetId: finding.rulesetId ? String(finding.rulesetId) : '',
                        ruleIds: [finding.ruleId],
                        envIds: [finding.cloudAccountId],
                        dateRange: {
                            from: new Date(),
                            to: dayjs().add(24, 'hours').toDate(),
                        },
                        entityNames: finding.entityName ? [finding.entityName] : undefined,
                    });
                }
            },
        }
    };
};

export const createReportIssueAction = (openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef => {
    return {
        id: FindingsActions.REPORT_ISSUE,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            callback: (selectedRows: IFinding[]) => {
                if (isAfEnabled(CDR_REPORT_FORM_POC_ACTIVE_FEATURE.key)) {
                    if (selectedRows.length > 0) {
                        showCdrReportForm(selectedRows);
                    }
                } else {
                    openDialog(ModalType.ReportIssue);
                }
            }
        }
    };
};

export const createAcknowledgeAction = (openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef => {
    return {
        id: FindingsActions.ACKNOWLEDGE,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.Acknowledge);
            },
            buttonProps: (selectedFindings: IFinding[]) => {
                const shouldAcknowledge: boolean = selectedFindings.some((finding: IFinding) => !finding.isAcknowledged) || selectedFindings.length === 0;
                const termPrefix: string = shouldAcknowledge ? 'ACKNOWLEDGE' : 'UN_ACKNOWLEDGE';
                return {
                    iconProps: { name: 'acknowledge' },
                    label: cdrTrans(`FINDINGS_TABLE.ACTIONS.${termPrefix}.TITLE`),
                    tooltip: cdrTrans(`FINDINGS_TABLE.ACTIONS.${termPrefix}.TOOLTIP`),
                };
            }
        }
    };
};

export const createCommentAction = (openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef => {
    return {
        id: FindingsActions.COMMENT,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.Comment);
            }
        }
    };
};

export const createCloseAction = (openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef => {
    return {
        id: FindingsActions.CLOSE,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.Close);
            }
        }
    };
};

export const createChangeSeverityAction = (openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef => {
    return {
        id: FindingsActions.CHANGE_SEVERITY,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.ChangeSeverity);
            }
        }
    };
};

export const createAssignUserAction = (openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef => {
    return {
        id: FindingsActions.ASSIGN_USER,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.AssignUser);
            }
        }
    };
};

export const createArchiveAction = (openDialog: (dialogType: (ModalType | null)) => void, isArchiveView?: boolean): IActionUsageDef => {
    const termPrefix: string = isArchiveView ? 'UN_ARCHIVE' : 'ARCHIVE';
    return {
        id: FindingsActions.ARCHIVE,
        permission: [UserRolesTypes.SUPER_USER, EVENTS_TABLE_ACTIONS_ACTIVE_FEATURE.key],
        actionDefOverride: {
            callback: () => {
                openDialog(ModalType.Archive);
            },
            name: cdrTrans(`FINDINGS_TABLE.ACTIONS.${termPrefix}.TITLE`),
            buttonProps: (selectedFindings: IFinding[]) => {
                const shouldArchive: boolean = selectedFindings.some((finding: IFinding) => !finding.isArchived) || selectedFindings.length === 0;
                const prefix: string = isNil(isArchiveView) ? (shouldArchive ? 'ARCHIVE' : 'UN_ARCHIVE') : (isArchiveView ? 'UN_ARCHIVE' : 'ARCHIVE' );
                return {
                    iconProps: { name: 'archive' },
                    label: cdrTrans(`FINDINGS_TABLE.ACTIONS.${prefix}.TITLE`),
                    tooltip: cdrTrans(`FINDINGS_TABLE.ACTIONS.${prefix}.TOOLTIP`),
                };
            }
        }
    };
};
