import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalsProps } from '..';
import { Checkbox, Icon, List, Spinner, Stack, Tooltip } from 'common/design-system/components-v2';
import { useGetAzure } from '../../../reducer/hooks/useGetAzure';
import { getAzureEnvNamespace } from '../../../initialize.i18n';
import { useUpdateCredentialsAzure } from '../../../reducer/hooks/useUpdateCredentialsAzure';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import Input from 'common/design-system/components-v2/Input';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { Vendors } from 'common/consts/vendors';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import Styled from './EditAzureCredentialsModal.styled';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';

interface FormValues {
    key: string;
    secret: string;
}

const EditAzureCredentialsModal: React.FC<ModalsProps> = ({ closeModal, azureId }) => {
    const { data: azureData } = useGetAzure(azureId);
    const { t } = useTranslation(getAzureEnvNamespace('azure'));

    const { allCloudAccounts, isLoading: accountsIsLoading } = useAllCloudAccounts();

    const [showMultipleAccounts, setShowMultipleAccounts] = React.useState(false);
    const [selectedMultipleAccounts, setSelectedMultipleAccounts] = React.useState<{ [cloudAccontId: string]: boolean; }>({});

    const azureCloudAccountWithIdenticalApplicationId = React.useMemo(() => {
        if (!azureData) return [];
        return allCloudAccounts.filter(account => {
            const selectedAccount = azureData.account.id === account.id;
            const isAzure = account.platform === Vendors.AZURE;
            const isSameCredentials = account.credentialId === azureData.account.credentials.clientId;
            return !selectedAccount && isAzure && isSameCredentials;
        });
    }, [allCloudAccounts, azureData]);

    const isAllMultipleAccountsSelected = React.useMemo(() => {
        return azureCloudAccountWithIdenticalApplicationId.length === Object.keys(selectedMultipleAccounts).filter(id => selectedMultipleAccounts[id]).length;
    }, [azureCloudAccountWithIdenticalApplicationId, selectedMultipleAccounts]);

    const inputValidations: { [input: string]: RegisterOptions } = React.useMemo(() => ({
        key: {
            required: t('azurePage.modals.editCredentials.fields.key.validation.required'),
            validate: (value: string) => {
                const isValid = !new RegExp(/(<([^>]+)>)/gi).test(value);
                return isValid || t('azurePage.modals.editCredentials.fields.key.validation.validate');
            }, 
        },
        secret: {
            required: t('azurePage.modals.editCredentials.fields.secret.validation.required'),
            validate: (value: string) => {
                const isValid = !new RegExp(/(<([^>]+)>)/gi).test(value);
                return isValid || t('azurePage.modals.editCredentials.fields.secret.validation.validate');
            },
        }
    }), [t]);

    const [isUpdateFinished, setIsUpdateFinished] = React.useState(false);

    const handleOnUpdateFinished = () => {
        setIsUpdateFinished(true);
    };

    const { updateCredentials, isLoading: isUpdateLoading, results, reset } = useUpdateCredentialsAzure({ onFinish: handleOnUpdateFinished });

    React.useEffect(() => {
        if (!isUpdateFinished || showMultipleAccounts || !results[azureId]) return;
        const singleResult = results[azureId];

        getNotificationsService().addNotification({
            type: singleResult.isSuccess ? NotificationType.SUCCESS : NotificationType.ERROR,
            title: t(`azurePage.modals.edit.onSubmit.${singleResult.isSuccess ? 'success' : 'error'}`),
            text: singleResult.error || '',
        });
        if (singleResult.isSuccess) {
            closeModal();
        } else {
            reset();
            setShowMultipleAccounts(false);
            setIsUpdateFinished(false);
        }
    }, [results, reset, azureId, isUpdateFinished, closeModal, showMultipleAccounts, t]);

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<FormValues>({
        defaultValues: {
            key: azureData?.account.credentials.clientId,
            secret: '',
        }
    });
    
    const submitUpdate = async (data: FormValues) => {
        const { key, secret } = data;
        const multipleAccounts = Object.keys(selectedMultipleAccounts).filter(id => selectedMultipleAccounts[id]);
        updateCredentials([azureId, ...multipleAccounts], { applicationId: key, applicationKey: secret });
    };

    if (!azureData) return null;

    return (
        <ConfirmationModal
            title={t('azurePage.modals.editCredentials.header')}
            onClose={closeModal}
            onCancel={closeModal}
            cancelBtnText={t(`azurePage.modals.editCredentials.${isUpdateFinished ? 'close' : 'cancel'}`)}
            onConfirm={isUpdateFinished ? undefined : handleSubmit((data) => submitUpdate(data))}
            isLoading={accountsIsLoading || isUpdateLoading}
            submitBtnDisabled={accountsIsLoading || isUpdateLoading}
            width='lg'
        >
            <Stack spacing={2}>
                <Controller
                    name='key'
                    rules={inputValidations['key']}
                    control={control}
                    render={({ field }) => (
                        <Input
                            fullWidth={true}
                            {...field}
                            placeholder={t('azurePage.modals.editCredentials.fields.key.placeholder')}
                            label={t('azurePage.modals.editCredentials.fields.key.label')}
                            helperText={errors['key']? errors['key']['message'] : ''}
                            isError={errors['key'] ? true : false}
                            readOnly={isUpdateFinished || isUpdateLoading}
                        />
                    )}
                />
                <Controller
                    name='secret'
                    rules={inputValidations['secret']}
                    control={control}
                    render={({ field }) => (
                        <Input
                            fullWidth={true}
                            {...field}
                            placeholder={t('azurePage.modals.editCredentials.fields.secret.placeholder')}
                            label={t('azurePage.modals.editCredentials.fields.secret.label')}
                            helperText={errors['secret']? errors['secret']['message'] : ''}
                            isError={errors['secret'] ? true : false}
                            readOnly={isUpdateFinished || isUpdateLoading}
                        />
                    )}
                />
                {azureCloudAccountWithIdenticalApplicationId.length > 0 && (
                    <Stack>
                        <Checkbox
                            label={t('azurePage.modals.editCredentials.multipleAccounts')}
                            onChange={event => setShowMultipleAccounts(event.target.checked)}
                            disabled={isUpdateFinished || isUpdateLoading}
                        />
                        {showMultipleAccounts && (
                            <Styled.MultiAccountsWrapper padding={2} overflow='auto'>
                                <List
                                    options={[
                                        {
                                            label: t(`azurePage.modals.editCredentials.${isAllMultipleAccountsSelected ? 'deselectAll' : 'selectAll'}`),
                                            value: 'all',
                                            indeterminate: !isAllMultipleAccountsSelected,
                                            selected: isAllMultipleAccountsSelected,
                                            disabled: isUpdateFinished || isUpdateLoading,
                                        },
                                        {
                                            label: azureData.account.name,
                                            value: azureData.account.id,
                                            selected: true,
                                            disabled: true,
                                            endAdornment: (
                                                !results[azureData.account.id] ? null :
                                                    results[azureData.account.id].isLoading ? <Spinner size={12} /> :
                                                        <Tooltip content={results[azureData.account.id].error}>
                                                            <Icon name={results[azureData.account.id].isSuccess ? 'statusSuccess' : 'statusError'} />
                                                        </Tooltip>
                                            )
                                        },
                                        ...azureCloudAccountWithIdenticalApplicationId.map<IListItemProps>(account => ({
                                            label: account.name,
                                            value: account.id,
                                            selected: selectedMultipleAccounts[account.id] || false,
                                            disabled: isUpdateFinished || isUpdateLoading,
                                            endAdornment: (
                                                !results[account.id] ? null :
                                                    results[account.id].isLoading ? <Spinner size={12} /> :
                                                        <Tooltip content={results[account.id].error}>
                                                            <Icon name={results[account.id].isSuccess ? 'statusSuccess' : 'statusError'} />
                                                        </Tooltip>
                                            )
                                        })),
                                    ]}
                                    withCheckbox={true}
                                    onOptionClick={(option) => {
                                        setSelectedMultipleAccounts(prev => {
                                            if (option.value === 'all') {
                                                return azureCloudAccountWithIdenticalApplicationId.reduce<{ [cloudAccontId: string]: boolean; }>((acc, account) => ({ ...acc, [account.id]: !isAllMultipleAccountsSelected }), {});
                                            }
                                            return { ...prev, [option.value]: !prev[option.value] };
                                        });
                                    }}
                                />
                            </Styled.MultiAccountsWrapper>
                        )}
                    </Stack>
                )}
            </Stack>
        </ConfirmationModal>
    );
};

export default EditAzureCredentialsModal;
