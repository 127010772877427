import { getHttpService } from 'common/interface/services';

const BASE_URL = 'content/assets/graph/entity-explorer';

interface IEntityNode {
    nodeId: string;
    type: string;
    id: string;
    srl: string;
    properties: any;
    updateTimestamp: string | null;
}

interface IEntityRelationship {
    name: string;
    from: string;
    to: string;
    id: string;
    type: string;
}

export interface IQueryGraphResult {
    entities: IEntityNode[];
    relationships: IEntityRelationship[];
}

interface IEntitiesGraphEngineService {
    query(
        env: string,
        queryId: string,
        search: string | null,
        limit: number): Promise<IQueryGraphResult>;

    fetchEntityRelationships(env: string, nodeId: string): Promise<IQueryGraphResult>;
}

class EntitiesGraphEngineService implements IEntitiesGraphEngineService {
    async query(env: string, queryId: string, search: string | null, limit: number): Promise<IQueryGraphResult> {
        return await getHttpService().post<IQueryGraphResult>(
            `${BASE_URL}/query`,
            {
                data: {
                    query: queryId,
                    internalEnvironmentId: env,
                    search: search,
                    nodeLimit: limit,
                },
            });
    }

    async fetchEntityRelationships(env: string, nodeId: string): Promise<IQueryGraphResult> {
        return await getHttpService().post<IQueryGraphResult>(
            `${BASE_URL}/relationships`,
            {
                data: {
                    internalEnvironmentId: env, nodeId: nodeId,
                },
            });
    }
}

const service = new EntitiesGraphEngineService();

export function getEntitiesGraphEngineService(): IEntitiesGraphEngineService {
    return service;
}