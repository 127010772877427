import React from 'react';
import { LevelIcon, Stack, Typography } from 'common/design-system/components-v2';
import { formatNumberShorthand } from 'common/utils/helpFunctions';
import { DataAssetCardStyled } from './DataAssetCard.styled';
import { DataAssetsCategory, IDataAssetCategoryInfo } from '../../DataAssetCategories';

const DataAssetCard : React.FC<{ cardInfo: IDataAssetCategoryInfo, onClick: (category: DataAssetsCategory) => void }> = ({ cardInfo, onClick } ) => {
    const { id, value, iconName, getTitle } = cardInfo;
    return (
        <DataAssetCardStyled.TopDiv direction='row' key={id} spacing={4}>
            <LevelIcon
                size="2xl"
                iconProps={{
                    name: iconName as 'database' | 'storage' | 'datalake',
                }}
            />
            <Stack direction='column' justifyContent={'center'} spacing={1}>
                <Typography variant={'body'}>{getTitle()}</Typography>
                <DataAssetCardStyled.CounterDiv
                    variant={'h1'}
                    clickable={value > 0}
                    onClick={value !== 0 ? () => onClick(id) : undefined}
                >
                    {formatNumberShorthand(value)}
                </DataAssetCardStyled.CounterDiv>
            </Stack>
        </DataAssetCardStyled.TopDiv>
    );};

export default DataAssetCard;