import React, { Fragment, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Button } from 'common/design-system/components-v2';
import { RootState } from 'common/services/store/store';
import i18n from 'common/services/translations/translations';
import { ModalType } from '../../../Findings.const';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { BotStatus } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import { useSelector } from 'react-redux';
import { CloudBotsSelector } from '../../../CloudBots/Reducers/cloudBots.selectors';
import {
    getCloudBotVendors,
    getFixItCustomizationKey
} from 'common/module_interface/intelligence/CloudBots/CloudBots.utils';
import { useCloudBotStatusChecker } from '../../Hooks/useCloudBotStatusChecker';
import { IFixItActionProps } from './FixItAction.types';
import { IFixItActionButtonProps } from './FixItActionButton.types';
import FixItActionButton from './FixItActionButton';
import { getVendor, IVendor, Vendors } from 'common/consts/vendors';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, useTheme } from 'styled-components';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { isAnyClosedFinding } from '../../../Findings.utils';

type FixItActionBotStatus = Exclude<BotStatus, BotStatus.NOT_STARTED>;
type BotStatusPropMappings = { [key in FixItActionBotStatus]: IFixItActionButtonProps };

const getTooltip = (selectedRows: any[], isInvalidVendor: boolean, isDifferentCloudAccount: boolean): string => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    if (isEmpty(selectedRows)) {
        return t('FINDINGS_TABLE.ACTIONS.FIX_IT.TOOLTIP.NO_ROW_SELECTED');
    }
    if (isInvalidVendor) {
        return t('FINDINGS_TABLE.ACTIONS.FIX_IT.TOOLTIP.INVALID_VENDOR');
    }
    if (isDifferentCloudAccount) {
        return t('FINDINGS_TABLE.ACTIONS.FIX_IT.TOOLTIP.DIFFERENT_CLOUD_ACCOUNT');
    }
    return t('FINDINGS_TABLE.ACTIONS.FIX_IT.TOOLTIP.DEFAULT');
};

const FixItAction: React.FC<IFixItActionProps> = (props: IFixItActionProps) => {
    const { tableId, selectedRows, openDialog } = props;
    const theme: DefaultTheme = useTheme();
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const cloudBotVendors: Vendors[] = useMemo(() => getCloudBotVendors(), []);
    const fixItCustomizationKey: string = useMemo(() => getFixItCustomizationKey(tableId), [tableId]);
    const { cloudBotStatus } = useSelector((state: RootState) => CloudBotsSelector.getCloudBotsState(state, fixItCustomizationKey)) ?? {};
    const { clearBotStatus } = useCloudBotStatusChecker({ tableId });

    const selectedFindings: IFinding[] = useMemo(() => selectedRows, [selectedRows]);
    const firstFinding: IFinding | undefined = useMemo(() => isEmpty(selectedFindings) ? undefined : selectedFindings[0], [selectedFindings]);

    const isInvalidVendor: boolean = useMemo(() => selectedFindings.some((row: IFinding) => {
        const vendor: IVendor | null = getVendor(row.cloudAccountType);
        return !vendor || !cloudBotVendors.includes(vendor.name);
    }), [selectedFindings, cloudBotVendors]);

    const isDifferentCloudAccount: boolean = useMemo(() => selectedFindings.some((row: IFinding) => {
        return row.cloudAccountId !== firstFinding?.cloudAccountId;
    }), [selectedFindings, firstFinding]);

    const isActionDisabled = useCallback((): boolean => {
        if (isAnyClosedFinding(selectedFindings)) return true;
        if (!cloudBotStatus || cloudBotStatus === BotStatus.NOT_STARTED) {
            return !selectedFindings.length || isInvalidVendor || isDifferentCloudAccount;
        }
        return false;
    }, [cloudBotStatus, selectedFindings, isInvalidVendor, isDifferentCloudAccount]);

    const openModal = useCallback(() => {
        const modalType: ModalType = !cloudBotStatus || cloudBotStatus === BotStatus.NOT_STARTED ? ModalType.FixIt : ModalType.BotStatusCheck;
        openDialog(modalType);
    }, [cloudBotStatus, openDialog]);

    const fixItActionBtnPropsMappings: BotStatusPropMappings = useMemo((): BotStatusPropMappings => {
        const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
        return {
            [BotStatus.IN_PROGRESS]: {
                label: t('CLOUD_BOTS.BOT_STATUS.FIXING'),
                loading: true,
                color: 'normal',
                tooltip: t('CLOUD_BOTS.BOT_STATUS.TOOLTIP.FIX_IN_PROGRESS'),
                onClick: openModal,
            },
            [BotStatus.SUCCESS]: {
                label: t('CLOUD_BOTS.BOT_STATUS.FIX_COMPLETED'),
                iconProps: {
                    name: 'checkCircle',
                    color: 'success',
                },
                color: 'success',
                bgColor: theme.palette.riskLevel.lowBg,
                tooltip: t('CLOUD_BOTS.BOT_STATUS.TOOLTIP.FIX_COMPLETED'),
                onClick: openModal,
                closeButtonInfo: {
                    tooltip: t('CLOUD_BOTS.BOT_STATUS.TOOLTIP.CLEAR_FIX_INFO'),
                    onClick: clearBotStatus,
                },
            },
            [BotStatus.FAILED]: {
                label: t('CLOUD_BOTS.BOT_STATUS.FIX_FAILED'),
                iconProps: {
                    name: 'error',
                    color: 'alert',
                },
                color: 'alert',
                bgColor: theme.palette.riskLevel.highBg,
                tooltip: t('CLOUD_BOTS.BOT_STATUS.TOOLTIP.FIX_FAILED'),
                onClick: openModal,
            }
        };
    }, [clearBotStatus, openModal, theme.palette.riskLevel.highBg, theme.palette.riskLevel.lowBg]);

    return <Fragment>
        {(!cloudBotStatus || cloudBotStatus === BotStatus.NOT_STARTED) &&
            <Button
                variant='text'
                label={t('FINDINGS_TABLE.ACTIONS.FIX_IT.TITLE')}
                iconProps={{
                    name: 'checkCircle'
                }}
                disabled={isActionDisabled()}
                onClick={openModal}
                tooltip={getTooltip(selectedFindings, isInvalidVendor, isDifferentCloudAccount)}
            />
        }
        {cloudBotStatus && cloudBotStatus !== BotStatus.NOT_STARTED &&
            Object.keys(fixItActionBtnPropsMappings).includes(cloudBotStatus) &&
            <FixItActionButton {...fixItActionBtnPropsMappings[cloudBotStatus]}/>
        }
    </Fragment>;
};
FixItAction.displayName = 'FixItAction';
export default FixItAction;
