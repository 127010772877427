import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const MultiAccountsWrapper = styled(Stack)`
    border: ${({ theme }) => `1px solid ${theme.palette.border.normal}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}px`};
    max-height: 200px;
`;

const Styles = {
    MultiAccountsWrapper
};

export default Styles;