import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { ISecurityControl } from '../SecurityControls.interface';
import { isNil } from 'common/utils/helpFunctions';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { openSecurityControlViewerDrawer } from '../SecurityControls.utils';

export const SecurityControlsNameCellRender: React.FC<ICellRendererParams<ISecurityControl>> = (params) => {
    const securityControl: ISecurityControl | undefined = params.data;
    if (!securityControl?.name) {
        return null;
    }

    const onClick = () => {
        openSecurityControlViewerDrawer(securityControl);
    };

    const options: IIconLinkCellProps = {
        isLink: !isNil(onClick),
        value: params.value,
        onClick,
        levelIconProps: {
            iconProps: { name: 'rule' },
            size: 'md',
        }
    };

    CellToClipboardHandler(params, securityControl.name);
    return (<IconLinkCellRenderer {...options} />);
};
