import initializeFilters from './Filters/FilterDefinitions';
import { EVENTS_SERVICE_ID } from 'common/module_interface/events/Events';
import { EventsService } from './EventsService';
import { initializeEventsWidgets } from './Widgets/initializeWidgets';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { initializeEventsTable } from './EventsTable/initialize';
import { initializeEventsTablePageAndMenu } from './EventsTable/initialize.pageAndMenu';
import initializeEventsModalsDefs from './Modals/initialize.modals';
import { initializeEventsDrawer } from './EventsTable/Definitions/DrawerDefinitions';
import { initializeEventAssetPanel } from './EventsTable/Definitions/AssetsPanelDefinitions';
import { initializeEventDrawer } from './EventDrawer/initialize.eventDrawer';

export default function initialize() {
    initializeFilters();
    initializeEventsWidgets();
    initializeEventsTablePageAndMenu();
    initializeEventsTable();
    initializeEventsModalsDefs();
    initializeEventsDrawer();
    initializeEventAssetPanel();
    initializeEventDrawer();
    globalAddinContainer.addService(EVENTS_SERVICE_ID, new EventsService());
}
