import React from 'react';
import AzureActions, { UpdateAzureCredentialsReq } from '../azure.actions';

export interface UseUpdateCredentialsAzureResults {
    isLoading: boolean;
    isSuccess?: boolean;
    error?: string;
}

export interface UseUpdateCredentialsAzureReq {
    onFinish?: (response: { successAmount: number; failedAmount: number; }) => void;
}

export interface UseUpdateCredentialsAzurePayload {
    isLoading: boolean;
    results: { [id: string]: UseUpdateCredentialsAzureResults; };

    updateCredentials: (cloudAccounts: string[], updatePayload: UpdateAzureCredentialsReq) => Promise<void>;
    reset: () => void;
}

export type UseUpdateCredentialsAzure = (req?: UseUpdateCredentialsAzureReq) => UseUpdateCredentialsAzurePayload;

export const useUpdateCredentialsAzure: UseUpdateCredentialsAzure = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<{ [id: string]: UseUpdateCredentialsAzureResults; }>({});

    const updateCredentials = React.useCallback(async (cloudAccounts: string[], updatePayload: UpdateAzureCredentialsReq) => {
        setIsLoading(true);

        setResults(prev => cloudAccounts.reduce((acc, id) => ({ ...acc, [id]: { isLoading: true } }), prev));
        const allResults = await Promise.allSettled(
            cloudAccounts.map((cloudAccountId, index) => {
                return AzureActions.updateCredentialsAzureAccount(cloudAccountId, updatePayload, index === 0)
                    .then((result) => {
                        setResults(prev => ({ ...prev, [cloudAccountId]: { isLoading: false, isSuccess: result, error: result ? undefined : 'Faild to update' } }));
                        return result;
                    })
                    .catch((error) => {
                        setResults(prev => ({ ...prev, [cloudAccountId]: { isLoading: false, isSuccess: false, error: (error?.message || 'Failed to update').toString() } }));
                        return error;
                    });
            })
        );
        req?.onFinish?.({
            successAmount: allResults.filter(result => result.status === 'fulfilled').length,
            failedAmount: allResults.filter(result => result.status === 'rejected').length
        });
        setIsLoading(false);
    }, [req]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setResults({});
    }, []);

    return ({
        isLoading,
        results,

        updateCredentials,
        reset
    });
};