import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { CloudAccountsResetMissingPermissions, CloudAccountsAttachOrganizationalUnit } from './cloudAccountsApi.interface';

const apiBaseUrl = 'cloudaccounts';

const attachOrganizationalUnit: CloudAccountsAttachOrganizationalUnit.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<CloudAccountsAttachOrganizationalUnit.Response>>(
        `${apiBaseUrl}/organizationalUnit/attach`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const resetMissingPermissions: CloudAccountsResetMissingPermissions.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<CloudAccountsResetMissingPermissions.Response>>(
        `${apiBaseUrl}/${request.id}/MissingPermissions/Reset`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};


const CloudAccountsApi = {
    attachOrganizationalUnit,
    resetMissingPermissions,
};

export default CloudAccountsApi;
