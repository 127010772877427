import i18n from 'i18next';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { getEventsTableNamespace } from '../EventsTable/initialize.i18n';

export const fixOriginName = (origin: IFindingModelOriginString): string => {
    if (!origin) return '';
    switch (origin) {
        case IFindingModelOriginString.Magellan:
            return IFindingModelOriginString.Intelligence;
        default:
            return i18n.t(`TABLE_FILTERS.ORIGIN.OPTIONS.${origin}`, { ns: getEventsTableNamespace('table'), defaultValue: origin });
    }
};