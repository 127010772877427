import { Stack, Typography } from 'common/design-system/components-v2';
import styled from 'styled-components';

const Wrapper = styled(Stack)`
    max-width: 100%;
    overflow: auto;
    overflow-x: hidden;
`;

const EnvsListWrapper = styled(Stack)`
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;
`;

const StyledTypography = styled(Typography)`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default {
    Wrapper,
    EnvsListWrapper,
    StyledTypography
};
