export const ISSUE_EXCLUSION_EDITOR_KEY = 'ISSUE_EXCLUSION_EDITOR';
export enum SimpleItemName {
    conditionsAreaDivider = 'conditionsAreaDivider',
    conditionsAreaLabel = 'conditionsAreaLabel',
}

export enum IssueExclusionItemNames {
    name = 'name',
    description = 'description',
    rules = 'rules',
    severities = 'severities',
    entityTypes = 'entityTypes',
    environments = 'environments',
    entityNames = 'entityNames',
    entityId = 'entityId',
    orgUnits = 'orgUnits',
    dateRange = 'dateRange',
    tags = 'tags',
    rulesRadio = 'rulesRadio',
    orgEnvRadio = 'orgEnvRadio',
    entityRadio = 'entityRadio',
    rulesRadioOption = 'rules',
    severitiesRadioOption = 'severitiesRadioOption',
    orgUnitsRadioOption = 'orgUnitsRadioOption',
    environmentsRadioOption = 'environmentsRadioOption',
    entityNamesRadioOption = 'entityNamesRadioOption',
    entityIdRadioOption = 'entityIdRadioOption',
}

