import { FunctionComponent } from 'react';
import { Entity } from '../Models/Entity';
import { ReactComponent as AwsUserIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_General-Icons/Res_48_Light/Res_User_48_Light.svg';
import { ReactComponent as AwsNlbIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Network-Load-Balancer_48_Light.svg';
import { ReactComponent as AwsAlbIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Application-Load-Balancer_48_Light.svg';
import { ReactComponent as AwsRouteTableIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Route-Table_48_Light.svg';
import { ReactComponent as AwsNatIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_NAT-Gateway_48_Light.svg';
import { ReactComponent as AwsEniIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Elastic-Network-Interface_48_Light.svg';
import { ReactComponent as AwsNaclIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Network-Access-Control-List_48_Light.svg';
import { ReactComponent as AwsEc2Icon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Compute/Res_48_Light/Res_Amazon-EC2_Instance_48_Light.svg';
import { ReactComponent as AwsEcsTaskDefinitionIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_ECS-Anywhere_48_Light.svg';
import { ReactComponent as AwsEcsContainerIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Container-2_48_Light.svg';
import { ReactComponent as AwsEcsServiceIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Service_48_Light.svg';
import { ReactComponent as AwsEksClusterIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Containers/16/Arch_Amazon-Elastic-Kubernetes-Service_16.svg';
import { ReactComponent as AwsEcsTaskIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Task_48_Light.svg';
import { ReactComponent as AwsVpcIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Networking-Content-Delivery/16/Arch_Amazon-Virtual-Private-Cloud_16.svg';
import { ReactComponent as AwsApiGatewayIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Application-Integration/Res_48_Light/Res_Amazon-API-Gateway_Endpoint_48_Light.svg';
import { ReactComponent as AwsAsgIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Compute/Res_48_Light/Res_Amazon-EC2_Auto-Scaling_48_Light.svg';
import { ReactComponent as AwsClassicLbIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Classic-Load-Balancer_48_Light.svg';
import { ReactComponent as AwsOrganizationAccountIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Account_48_Light.svg';
import { ReactComponent as AwsOrganizationManagementAccountIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Management-Account_48_Light.svg';
import { ReactComponent as AwsOrganizationUnitIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Organizational-Unit_48_Light.svg';
import { ReactComponent as AwsRedshiftIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Analytics/Res_48_Light/Res_Amazon-Redshift_ML_48_Light.svg';
import { ReactComponent as AwsS3Icon } from 'common/erm-components/icons/vendor-icons/S3Bucket.svg';
import { ReactComponent as AwsRoleIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Role_48_Light.svg';
import { ReactComponent as AwsPolicyIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_loT/Res_48_Light/Res_AWS-IoT_Policy_48_Light.svg';
import { ReactComponent as AwsLambdaIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Compute/Res_48_Light/Res_AWS-Lambda_Lambda-Function_48_Light.svg';
import { ReactComponent as AwsSubnetIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_VPN-Gateway_48_Dark.svg';
import { ReactComponent as AwsUserGroupIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_General-Icons/Res_48_Light/Res_Users_48_Light.svg';
import { ReactComponent as AwsSecurityGroupIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_General-Icons/Res_48_Light/Res_Shield_48_Light.svg';
import { ReactComponent as AwsGearIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_General-Icons/Res_48_Light/Res_Gear_48_Light.svg';
import { ReactComponent as AwsCloudAccountIcon } from '@dome9/berries/vendor-icons/dist/aws/aws.svg';
import { ReactComponent as ShieldChecked } from 'common/design-system/icons/svg/system-icons/shield-checked.svg';

import { ReactComponent as AwsAnalyticsCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Analytics_16.svg';
import { ReactComponent as AwsApplicationIntegrationCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Application-Integration_16.svg';
import { ReactComponent as AwsComputeCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Compute_16.svg';
import { ReactComponent as AwsContainersCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Containers_16.svg';
import { ReactComponent as AwsDatabaseCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Database_16.svg';
import { ReactComponent as AwsMachineLearningCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Machine-Learning_16.svg';
import { ReactComponent as AwsManagementAndGovernanceCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Management-Governance_16.svg';
import { ReactComponent as AwsMediaCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Media-Services_16.svg';
import { ReactComponent as AwsNetworkingCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Networking-Content-Delivery_16.svg';
import { ReactComponent as AwsIdentityCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Security-Identity-Compliance_16.svg';
import { ReactComponent as AwsStorageCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Storage_16.svg';
import { ReactComponent as AwsBlockchainCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Blockchain_16.svg';
import { ReactComponent as AwsBusinessApplicationsCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Business-Applications_16.svg';
import { ReactComponent as AwsCustomerEnablementCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Customer-Enablement_16.svg';
import { ReactComponent as AwsDeveloperToolsCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Developer-Tools_16.svg';
import { ReactComponent as AwsEndUserComputingCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_End-User-Computing_16.svg';
import { ReactComponent as AwsGameDevelopmentCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Game-Tech_16.svg';
import { ReactComponent as AwsIotCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Internet-of-Things_16.svg';
import { ReactComponent as AwsMigrationAndTransferCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Migration-Transfer_16.svg';
import { ReactComponent as AwsMobileCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Front-End-Web-Mobile_16.svg';
import { ReactComponent as AwsRoboticsCategoryIcon } from '@dome9/berries/vendor-icons/dist/aws/Category-Icons/Arch-Category_16/Arch-Category_Robotics_16.svg';

import { ReactComponent as AzureVirtualMachine } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/compute/10021-icon-service-Virtual-Machine.svg';
import { ReactComponent as AzureNetworkSecurityGroupIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10067-icon-service-Network-Security-Groups.svg';
import { ReactComponent as AzureNetworkInterfaceIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10080-icon-service-Network-Interfaces.svg';
import { ReactComponent as AzureSubnetIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/02742-icon-service-Subnet.svg';
import { ReactComponent as AzureApplicationGatewayIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10076-icon-service-Application-Gateways.svg';
import { ReactComponent as AzureVirtualNetworkIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10061-icon-service-Virtual-Networks.svg';
import { ReactComponent as AzurePublicIPAddressIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10068-icon-service-Public-IP-Addresses-(Classic).svg';
import { ReactComponent as AzureLoadBalancerIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10062-icon-service-Load-Balancers.svg';
import { ReactComponent as AzureWebIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/web/10035-icon-service-App-Services.svg';
import { ReactComponent as AzureFunctionAppIcon } from '@dome9/berries/vendor-icons/dist/old-pack/azure-icons/functionApps.svg';
import { ReactComponent as AzureMigrateIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/migrate/10281-icon-service-Azure-Migrate.svg';
import { ReactComponent as AzureMediaIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/general/10854-icon-service-Media.svg';
import { ReactComponent as AzureManagementAndGovernanceIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-manually/management_and_governance.svg';
import { ReactComponent as AzureIotIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/iot/10182-icon-service-IoT-Hub.svg';
import { ReactComponent as AzureIntegrationServiceEnvironmentsIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/integration/00555-icon-service-Integration-Service-Environments.svg';
import { ReactComponent as AzureAadIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/identity/10221-icon-service-Azure-Active-Directory.svg';
import { ReactComponent as AzureLabServicesIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/devops/10265-icon-service-Lab-Services.svg';
import { ReactComponent as AzureDatabasesIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/databases/10137-icon-service-Cache-Redis.svg';
import { ReactComponent as AzureContainersIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/new icons/02884-icon-service-Worker-Container-App.svg';
import { ReactComponent as AzureAnalyticsIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/analytics/10148-icon-service-Analysis-Services.svg';
import { ReactComponent as AzureVirtualDesktopIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/other/00327-icon-service-Azure-Virtual-Desktop.svg';
import { ReactComponent as AzureStorageIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/storage/10087-icon-service-Storage-Accounts-(Classic).svg';
import { ReactComponent as AzureSecurityIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/identity/00321-icon-service-Security.svg';
import { ReactComponent as AzureNetworkingIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10075-icon-service-Virtual-Networks-(Classic).svg';
import { ReactComponent as AzureMonitorIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/management governance/00001-icon-service-Monitor.svg';
import { ReactComponent as AzureMixedRealityIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/mixed reality/00698-icon-service-Remote-Rendering.svg';
import { ReactComponent as AzureMobileIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/other/02794-icon-service-Mobile-Networks.svg';
import { ReactComponent as AzureIntuneIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/intune/10329-icon-service-Intune.svg';
import { ReactComponent as AzureAiIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/ai + machine learning/10162-icon-service-Cognitive-Services.svg';
import { ReactComponent as AzureOtherIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-manually/other.svg';
import { ReactComponent as AzureEntity } from '@dome9/berries/vendor-icons/dist/logos/azure.svg';


export const IconMap: Record<string, FunctionComponent> = {
    AwsIamGroup: AwsUserGroupIcon,
    AwsIamUser: AwsUserIcon,
    AwsSecurityGroup: AwsSecurityGroupIcon,
    AwsVpc: AwsVpcIcon,
    AwsSubnet: AwsSubnetIcon,
    AwsAppLoadBalancerBase: AwsAlbIcon,
    AwsAppLoadBalancerTargetGroup: AwsNlbIcon,
    AwsElasticLoadBalancer: AwsAlbIcon,
    AwsNetworkLoadBalancer: AwsNlbIcon,
    AwsClassicLoadBalancer: AwsClassicLbIcon,
    AwsDbInstance: AwsDatabaseCategoryIcon,
    AwsNacl: AwsNaclIcon,
    AwsNatGateway: AwsNatIcon,
    AwsNetworkInterface: AwsEniIcon,
    AwsEC2Instance: AwsEc2Icon,
    AwsApiGateway: AwsApiGatewayIcon,
    AwsApiGatewayV2: AwsApiGatewayIcon,
    AwsAutoScalingGroup: AwsAsgIcon,
    AwsCloudAccount: AwsCloudAccountIcon,
    AwsEcsCluster: AwsEcsContainerIcon,
    AwsEcsService: AwsEcsServiceIcon,
    AwsEksCluster: AwsEksClusterIcon,
    AwsEcsTask: AwsEcsTaskIcon,
    AwsEcsTaskDefinition: AwsEcsTaskDefinitionIcon,
    AwsIamPolicy: AwsPolicyIcon,
    AwsIamRole: AwsRoleIcon,
    AwsLambdaFunction: AwsLambdaIcon,
    AwsOrganizationAccount: AwsOrganizationAccountIcon,
    AwsOrganizationUnit: AwsOrganizationUnitIcon,
    AwsRouteTable: AwsRouteTableIcon,
    AwsOrganization: AwsOrganizationManagementAccountIcon,
    AwsRedshiftCluster: AwsRedshiftIcon,
    AwsS3Bucket: AwsS3Icon,
    Analytics: AwsAnalyticsCategoryIcon,
    'Application integration': AwsApplicationIntegrationCategoryIcon,
    AwsWafCoverage: ShieldChecked,

    AzureEntity: AzureEntity,
    VirtualMachine: AzureVirtualMachine,
    NetworkSecurityGroup: AzureNetworkSecurityGroupIcon,
    NetworkInterface: AzureNetworkInterfaceIcon,
    Subnet: AzureSubnetIcon,
    ApplicationGateway: AzureApplicationGatewayIcon,
    VNet: AzureVirtualNetworkIcon,
    PublicIPAddress: AzurePublicIPAddressIcon,
    LoadBalancer: AzureLoadBalancerIcon,
    WebApp: AzureWebIcon,
    FunctionApp: AzureFunctionAppIcon,
    'AzureCategory|Web': AzureWebIcon,
    'AzureCategory|Mobile': AzureMobileIcon,
    'AzureCategory|Migration': AzureMigrateIcon,
    'AzureCategory|Media': AzureMediaIcon,
    'AzureCategory|All Services': AzureOtherIcon,
    'AzureCategory|General': AzureOtherIcon,
    'AzureCategory|Other': AzureOtherIcon,
    'AzureCategory|Management and governance': AzureManagementAndGovernanceIcon,
    'AzureCategory|Internet of things': AzureIotIcon,
    'AzureCategory|Integration': AzureIntegrationServiceEnvironmentsIcon,
    'AzureCategory|Identity': AzureAadIcon,
    'AzureCategory|DevOps': AzureLabServicesIcon,
    'AzureCategory|Databases': AzureDatabasesIcon,
    'AzureCategory|Containers': AzureContainersIcon,
    'AzureCategory|Analytics': AzureAnalyticsIcon,
    'AzureCategory|Virtual desktop infrastructure': AzureVirtualDesktopIcon,
    'AzureCategory|Storage': AzureStorageIcon,
    'AzureCategory|Security': AzureSecurityIcon,
    'AzureCategory|Networking': AzureNetworkingIcon,
    'AzureCategory|Monitor': AzureMonitorIcon,
    'AzureCategory|Mixed reality': AzureMixedRealityIcon,
    'AzureCategory|Compute': AzureVirtualMachine,
    'AzureCategory|Intune': AzureIntuneIcon,
    'AzureCategory|AI + machine learning': AzureAiIcon,

    Compute: AwsComputeCategoryIcon,
    Containers: AwsContainersCategoryIcon,
    Database: AwsDatabaseCategoryIcon,
    'Machine learning': AwsMachineLearningCategoryIcon,
    'Management and governance': AwsManagementAndGovernanceCategoryIcon,
    Media: AwsMediaCategoryIcon,
    'Networking and content delivery': AwsNetworkingCategoryIcon,
    'Security, identity, and compliance': AwsIdentityCategoryIcon,
    Storage: AwsStorageCategoryIcon,
    Blockchain: AwsBlockchainCategoryIcon,
    'Business applications': AwsBusinessApplicationsCategoryIcon,
    'Cryptography and PKI': AwsGearIcon,
    'Customer enablement': AwsCustomerEnablementCategoryIcon,
    'Customer engagement': AwsGearIcon,
    'Billing and cost management': AwsGearIcon,
    'Developer tools': AwsDeveloperToolsCategoryIcon,
    'End user computing': AwsEndUserComputingCategoryIcon,
    'Game development': AwsGameDevelopmentCategoryIcon,
    'Internet of Things': AwsIotCategoryIcon,
    'Migration and transfer': AwsMigrationAndTransferCategoryIcon,
    Mobile: AwsMobileCategoryIcon,
    Robotics: AwsRoboticsCategoryIcon,
};

export function getIcon(entity:Entity){
    return IconMap[entity.typeByPlatform ?? entity.type];
}

//Additional available category icons:
//Arch-Category_VR-AR_16
//Arch-Category_Satellite_16
//Arch-Category_Cloud-Financial-Management_16
//Arch-Category_Quantum-Technologies_16
//Arch-Category_Serverless_16