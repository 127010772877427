import { FC, useEffect, useState } from 'react';
import { SelectV2 } from 'common/design-system/components-v2';
import { I18nExclusion } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { IExclusionRegionProps } from '../../helpers/exclusions.interfaces';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { fetchRegions, formatRegions, handleFetchingError } from '../../helpers/exclusions.utils';


const ExclusionByRegion: FC<IExclusionRegionProps> = ({ isEnabled, selectedRuleset,selectedRulesetDetails, onClose, onChange, selectedOption, initialSelectedOption }) => {
    const { t } = useTranslation(I18nExclusion);
    const [regionsList, setRegionsList] = useState<SelectOption[]>([]);

    useEffect(() => {
        const handleClearListAndValue = () => {
            setRegionsList([]);
            onChange([]);
        };
        const updateRegionsList = (rules: SelectOption[]) => {
            setRegionsList(rules);
        };
        const handleFetchRules = async () => {
            if (!selectedRuleset || !selectedRulesetDetails?.cloudVendor) {
                handleClearListAndValue();
                return;
            }
            try {
                const regionsData = await fetchRegions(selectedRulesetDetails?.cloudVendor);
                const formattedRegions = formatRegions(regionsData);
                formattedRegions && updateRegionsList(formattedRegions);
            } catch (error) {
                handleFetchingError(t, onClose);
            }
        };
        handleFetchRules();
    }, [selectedRuleset]);

    useEffect(() => {
        initialSelectedOption ? onChange(initialSelectedOption) : onChange([]);
    }, [regionsList]);

    const handleRegionChange = (newValue: string[]) => {
        onChange(newValue);
    };

    return (
        <SelectV2
            label={t('MODAL.TOPICS.REGIONS.TITLE')}
            onChange={(value: string[]) => handleRegionChange(value)}
            isMulti={true}
            options={regionsList}
            readOnly={!isEnabled || !selectedRulesetDetails?.cloudVendor}
            placeholder={t('MODAL.GENERAL.SELECT')}
            value={selectedOption}
            data-aid='exclusions-regions-select'
            fullWidth
            clearable
        />
    );
};

export default ExclusionByRegion;