import { UserRolesTypes } from 'common/enum/UserRoles';
import { addMainMenuItem } from 'common/helpers/menu';
import { AssetDetailsThirdPartyChipWidget } from './ProtectedAsset/Components/Widgets/AssetDetailsChipWidgets';
import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { InfoPanelActionProps } from 'common/components/InfoPane/InfoPane';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import initializePermissionsPanel from './Panels/PermissionsPanel';
import initializeCiemFindingsPanel from './Panels/CiemFindingsPanel';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { checkChinaPermissions } from 'common/utils/dataCenter';
import i18n from 'i18next';
import { I18nIdentityNamespace } from './consts';
import { initializePolicies } from './policy/initialize';
import initializeExclusionEvents from './exclusion/initialize';
import { isExclusionsEnabled, isPolicyEnabled, isRemediationEnabled } from 'common/utils/userPermissionUtils';

function initializeDetailsPaneItems() {
    const thirdPartyChipProvider: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: true,
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: AssetDetailsThirdPartyChipWidget,
                componentProps: { entity },

            };
        },
    };

    ProtectedAssetsPageRegistry.addChipProviders([
        {
            id: 'third party chip',
            position: 1, // to be the most left chip (compared to values of ERM chips)
            content: thirdPartyChipProvider,
        },
    ]);
}

export default function initialize() {

    addMainMenuItem(
        {
            id: 'identity',
            position: 70,
            icon: 'CIEM',
            iconByPermission: [
                { permission: [UserRolesTypes.ALL], value: 'CIEM' },
            ],
            label: i18n.t('NAVIGATION_MENU.IDENTITY.TITLE', { ns: I18nIdentityNamespace }),
            labelByPermission: [
                { permission: [UserRolesTypes.ALL], value: i18n.t('NAVIGATION_MENU.CIEM.TITLE' , { ns: I18nIdentityNamespace }) },
            ],
            permission: [UserRolesTypes.ALL],
            sections: [
                {
                    id: 'default',
                    position: 10,
                    items: [
                        {
                            id: 'dashboard',
                            position: 10,
                            label: i18n.t('NAVIGATION_MENU.IDENTITY.DASHBOARD', { ns: I18nIdentityNamespace }),
                            state: '/ciem/overview',
                            permission: [UserRolesTypes.ALL],
                        },
                    ],
                },
                {
                    id: 'findings',
                    position: 20,
                    items: [
                        {
                            id: 'findings',
                            position: 10,
                            label: i18n.t('NAVIGATION_MENU.IDENTITY.FINDINGS', { ns: I18nIdentityNamespace }),
                            state: '/ciem/findings',
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'policies',
                            position: 20,
                            label: i18n.t('NAVIGATION_MENU.THREAT.POLICIES', { ns: I18nIdentityNamespace }),
                            state: '/ciem/rulesets-assignment',
                            permission: () => isPolicyEnabled(),
                        },
                        {
                            id: 'exclusions',
                            position: 30,
                            label: i18n.t('NAVIGATION_MENU.THREAT.EXCLUSIONS', { ns: I18nIdentityNamespace }),
                            state: '/ciem/exclusion',
                            permission: () => isExclusionsEnabled(),
                            urlAliases: ['/ciem/exclusion']
                        },
                        {
                            id: 'remediations',
                            position: 40,
                            label: i18n.t('NAVIGATION_MENU.THREAT.REMEDIATIONS', { ns: I18nIdentityNamespace }),
                            state: '/ciem/remediation',
                            permission: () => checkChinaPermissions([UserRolesTypes.ALL]) && isRemediationEnabled(),
                        },
                    ],
                },
                {
                    id: 'visibility',
                    position: 30,
                    items: [
                        {
                            id: 'activity explorer',
                            position: 10,
                            label: i18n.t('NAVIGATION_MENU.IDENTITY.ACTIVITY_EXPLORER', { ns: I18nIdentityNamespace }),
                            state: '/magellan/clarity-cloudtrail',
                            permission: [UserRolesTypes.ALL],
                        },
                    ],
                },
                {
                    id: 'iam safety',
                    position: 40,
                    title: i18n.t('NAVIGATION_MENU.IDENTITY.IAM_SAFETY', { ns: I18nIdentityNamespace }),
                    items: [
                        {
                            id: 'accounts',
                            position: 10,
                            label: i18n.t('NAVIGATION_MENU.IDENTITY.ACCOUNTS', { ns: I18nIdentityNamespace }),
                            state: '/iam-safe',
                            permission: () => checkChinaPermissions([UserRolesTypes.SUPER_USER]),
                        },
                        {
                            id: 'access leases',
                            position: 20,
                            label: i18n.t('NAVIGATION_MENU.IDENTITY.ACCESS_LEASES', { ns: I18nIdentityNamespace }),
                            state: '/iam-safe/settings',
                            permission: () => checkChinaPermissions([UserRolesTypes.ALL])
                        },
                        {
                            id: 'credentials report',
                            position: 30,
                            label: i18n.t('NAVIGATION_MENU.IDENTITY.CREDENTIALS_REPORT', { ns: I18nIdentityNamespace }),
                            state: '/iam-reports/credentials',
                            urlAliases: ['/iam-reports/credentials'],
                            permission: () => checkChinaPermissions([UserRolesTypes.ALL])
                        },
                        {
                            id: 'policy report',
                            position: 40,
                            label: i18n.t('NAVIGATION_MENU.IDENTITY.POLICY_REPORT', { ns: I18nIdentityNamespace }),
                            state: '/iam-reports/policy',
                            urlAliases: ['/iam-reports/policy'],
                            permission: () => checkChinaPermissions([UserRolesTypes.ALL])
                        },
                    ],
                }
            ],
        },
    );

    initializeDetailsPaneItems();
    initializePermissionsPanel();
    initializeCiemFindingsPanel();
    initializePolicies();
    initializeExclusionEvents();
}
