import { getCdrExclusionsService } from '../FindingsTable/Modals/CdrExclusion/CdrExclusionService';
import { ICdrExclusion } from '../FindingsTable/Modals/CdrExclusion/CdrExclusion.interface';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import {
    getPageTableCrudActions,

} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.actions';
import { showCdrExclusionForm } from '../FindingsTable/Modals/CdrExclusion/CdrExclusion.items';
import { CDR_EXCLUSIONS_PAGE_TABLE_ID } from '../FindingsTable/Modals/CdrExclusion/CdrExclusion.consts';
import {
    IAddActionProps,
    IDeleteActionProps,
    IEditActionProps,
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';

const deleteItems = (cdrExclusions: ICdrExclusion[]): Promise<void> => {
    const ids = cdrExclusions.map(cdrExclusion => cdrExclusion.id);
    return getCdrExclusionsService().deleteCdrExclusions(ids);
};

export const getCdrExclusionsTableActions = (onTableDataChange: () => void): ITableAction<ICdrExclusion>[] => {
    const addProps: IAddActionProps = {
        callback: () => showCdrExclusionForm(),
    };

    const editProps: IEditActionProps = {
        callback: (cdrExclusion: ICdrExclusion) => showCdrExclusionForm(cdrExclusion),
    };

    const deleteProps: IDeleteActionProps<ICdrExclusion> = {
        deleteItems,
        itemIconName: 'exclude',
    };

    return getPageTableCrudActions<ICdrExclusion>(CDR_EXCLUSIONS_PAGE_TABLE_ID, onTableDataChange, {
        addProps,
        editProps,
        deleteProps,
    });
};