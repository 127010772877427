import styled from 'styled-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@dome9/berries/react-components';
import { Stack, Typography } from 'common/design-system/components-v2';

export const ConfigWrapper = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.palette.surface.primary};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    border: ${({ theme }) => `1px solid ${theme.palette.surfaceStates.tertiaryActive}`};
`;

export const Line=styled.div`
  height: ${({ theme }) => `${theme.border.width.standard}${theme.units}`};
  background-color: ${({ theme }) => `${theme.palette.surfaceStates.tertiaryActive}`};
`;
interface OnboardingInstructionsWrapperProps { 
    children: JSX.Element;
    icon?: string; 
    title?: string;
    loading?: boolean;
}

const OnboardingInstructions:FC<OnboardingInstructionsWrapperProps> = ({
    children,
    title,
    icon
})=>{
    const { t } = useTranslation();
    return (
        <ConfigWrapper>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={3} padding={3}>
                <Icon name={icon!} size={20}/>
                <Typography variant="bodyLg">{title || t('ON_BOARDING.ACCOUNT.AWS_CONFIGURATIONS')}</Typography>
            </Stack>
            <Stack fullWidth>
                <Line></Line>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={3} padding={3}>
                {children}
            </Stack>
        </ConfigWrapper>
    );
};


export default OnboardingInstructions;