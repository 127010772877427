import { IEntityService } from 'common/interface/data_services';
import { getHttpService, ICachingConfig } from 'common/interface/services';
import { getVendor } from 'common/consts/vendors';
import { ICloudEntity } from 'common/module_interface/assets/ICloudEntity';
import { AxiosError } from 'axios';

export class EntityService implements IEntityService {

    async getEntity(id: string, entityType: string, platform: string, cloudAccountId: string, cachingConfig?: ICachingConfig, customErrHandler?: (error: AxiosError<ICloudEntity>) => ICloudEntity): Promise<ICloudEntity | null> {
        const assessmentCloudAccountType = getVendor(platform)?.assessmentVendorType;
        if (!assessmentCloudAccountType) {
            return null;
        }

        const request = {
            entityIdentifierFields: ['id', 'dome9Id', 'externalId'],
            entityIdentifierValue: id,
            entityType: entityType,
            cloudAccountType: assessmentCloudAccountType,
            cloudAccountTargetId: cloudAccountId,
        };
        const cacheTimeout = 8 * 60; //8 minutes
        const defaultCachingConfig = { useCache: true, cacheTimeout };

        return await getHttpService().request<ICloudEntity>('EntityBuilder/CloudEntity', {
            method: 'POST',
            data: request,
        }, { cachingConfig: cachingConfig ?? defaultCachingConfig }, customErrHandler);
    }
}
