import { createSelector } from '@reduxjs/toolkit';
import { AwsState } from './aws.interface';
import { RootState } from 'common/services/store/store';

const awsReducer = (state: RootState) => state.env_aws as AwsState;

const getAws = createSelector([awsReducer, (_state: RootState, id: string) => id], (state, id) => state.awsEvnironments[id]);
const getAwsAccountNotifications = createSelector([awsReducer, (_state: RootState, id: string) => id], (state, id) => state.awsEvnironments[id].notificationCountWarning);

export const AwsSelector = {
    getAws,
    getAwsAccountNotifications,
};