import { RiskLevelServerEnum } from 'common/module_interface/RiskManagement/RiskLevel';

export interface MalwareInfo {
    malwareId: string,
    severity: RiskLevelServerEnum,
    family: string,
    hash: string,
    scanData: {
        filePath: string,
    }
}

export enum MALWARE_FIELD_NAMES {
    MALWARE_ID = 'malwareId',
    SEVERITY = 'severity',
    FAMILY = 'family',
    HASH = 'hash',
    FILE_PATH = 'scanData.filePath',
}