import { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IIssueExclusion } from '../IssueExclusions.interface';
import { MultiOptionChipsComponent } from 'common/erm-components/custom/MultiChipCellContent/MultiOptionChipsComponent';
import { getAllRulesOptions } from '../../../RiskManagement.options';

export const IssueExclusionRuleCellRender: FC<ICellRendererParams<IIssueExclusion>> = (params) => {
    const exclusion: IIssueExclusion | undefined = params.data;
    if (!exclusion) {
        return null;
    }

    return <MultiOptionChipsComponent selectedValues={exclusion?.ruleIds} fetchAllOptions={getAllRulesOptions} />;
};