import styled from 'styled-components/macro';
import { Stack } from 'common/design-system/components-v2';

export const TopDiv = styled(Stack)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  gap: 20px;
`;

export const FilterWrapper = styled(Stack)`
  width: 100%;
  padding-top: 20px;
`;

export const DataSecurityStyled = {
    TopDiv,
    FilterWrapper,
};
