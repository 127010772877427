import styled from 'styled-components';
import { Typography } from 'common/design-system/components-v2';

const ConfigurationIcon = styled.div`
    width:32px;
    height: 32px;
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
`;
const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    margin-top: 24px;
  height: 100%;
`;

const NameCellWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    &:hover {
        cursor: pointer;
    }
`;

const StyledTypography = styled(Typography)`
    ${NameCellWrapper}:hover & {
        text-decoration: underline;
    }
`;

const Breadcrumb = styled('div')`
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export {
    ConfigurationIcon,
    DrawerBody,
    NameCellWrapper,
    StyledTypography,
    Breadcrumb
};
