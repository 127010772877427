import styled from 'styled-components/macro';
import { Alert, Stack } from 'common/design-system/components-v2';
import Trend from 'common/design-system/components-v2/Trend';

const TopDiv = styled(Alert)`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

const CounterDiv = styled(Stack)`
    font-size: 18px;
    font-weight: 500;
    flex-shrink: 0;
`;

const TextDiv = styled(Stack)`
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.light};
    font-weight: 400;
`;

const TitleDiv = styled(Stack)`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
`;

const TrendDiv = styled(Trend)`
    font-size: 12px;
`;

const ContentDiv = styled(Stack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

export const TrendBoxStyled = {
    TopDiv,
    CounterDiv,
    TextDiv,
    ContentDiv,
    TrendDiv,
    TitleDiv,
};
