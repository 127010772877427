import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edge, Node } from 'reactflow';
import { getUserService } from 'common/interface/services';
import GraphComponent from 'common/components/Graph/Components/GraphComponent';
import SuggestionPopupComponent from '../../Suggestions/Components/SuggestionPopup';
import { DirectionalLayoutModel } from 'common/components/Graph/Models/DirectionalLayoutModel';
import { RankAssigmentAlgorithm } from 'common/components/Graph/Models/RankAssigmentAlgorithm';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';
import { PerimeterExposureProps } from '../Models/PerimeterExposureProps';
import { edgeTypes } from '../Services/EdgeTypeHandler';
import { mapToGraphEntities } from '../Services/GraphEntityMapper';
import { nodeTypes } from '../Services/NodeTypeHandler';
import {
    spreadEdgesForNodesAccessibleBy2OrMoreNodesWithOneOfThemBeingTheFirstNode,
} from '../Services/GraphEdgeSpreader';
import { handleGraphSuggestions } from '../../Suggestions/Services/SuggestionHandler';
import { insightNamespace } from '../../consts/i18n';
import { DisplayPrompt, ResponseElement } from '../../Suggestions/Suggestion.interface';
import { IconMap } from '../Services/IconMap';
import { Spinner } from 'common/design-system/components-v2';
import { PerimeterExpStyled } from './PerimeterExposureComponent.styled';
import { getAssetExposure } from '../Services/PerimeterExposureHttpService';

const directionalLayoutModel: DirectionalLayoutModel = {
    layoutDirection: LayoutDirection.LeftToRight,
    nodeSeparation: 100,
    rankSeparation: 200,
    rankAssignmentAlgorithm: RankAssigmentAlgorithm.longestPath
};

const PerimeterExposureComponent: React.FC<PerimeterExposureProps> = (props: PerimeterExposureProps) => {
    const { assetSrl, getGraphBySrl, isFullScreen, showFullScreenButton } = props;
    const { t } = useTranslation(insightNamespace);

    const [nodes, setNodes] = useState<Node<{ label: string }>[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuggestionsPopup, setShowSuggestionsPopup] = useState(false);
    const [displayedPrompts, setDisplayedPrompts] = useState<{ displayName?: string, prompts?: DisplayPrompt[] }>({});
    const [suggestions, setSuggestions] = useState<ResponseElement[][]>([]);

    useEffect(() => {
        setExposure();

        async function setExposure(): Promise<void> {
            setNodes([]);
            setEdges([]);
            if (!assetSrl || !getGraphBySrl) return;

            setIsLoading(true);
            let exposure;
            try {
                exposure = await getGraphBySrl(assetSrl);
            } finally {
                setIsLoading(false);
            }

            if (!exposure) return;

            const newGraph = mapToGraphEntities(exposure);
            spreadEdgesForNodesAccessibleBy2OrMoreNodesWithOneOfThemBeingTheFirstNode(newGraph);

            handleGraphSuggestions(newGraph, setDisplayedPrompts, setSuggestions, setShowSuggestionsPopup);

            setEdges(newGraph.edges);
            setNodes(newGraph.nodes);
        }
    }, [assetSrl, getGraphBySrl]);

    return <>
        {isLoading &&
            <PerimeterExpStyled.SpinnerDiv>
                <Spinner size={20} />
            </PerimeterExpStyled.SpinnerDiv>}
        <GraphComponent
            edges={ edges }
            setEdges={ setEdges }
            nodes={ nodes }
            setNodes={ setNodes }
            directionalLayoutModel={ directionalLayoutModel }
            nodeTypes={ nodeTypes }
            edgeTypes={ edgeTypes }
            fullScreenProps={{
                showFullScreenButton: showFullScreenButton && !isFullScreen,
                modalTitle: t('CONTEXT_GRAPH.FULL_SCREEN_TITLE'),
                component: PerimeterExposureComponent,
                componentProps: {
                    assetSrl: assetSrl,
                    getGraphBySrl: getAssetExposure,
                },
            }}
        />

        { showSuggestionsPopup && displayedPrompts?.prompts?.length &&
            <SuggestionPopupComponent
                user={`${getUserService().getUser().id}`}
                displayedPrompts={displayedPrompts.prompts}
                setSuggestions={setSuggestions}
                suggestions={suggestions}
                onXClick={() => setShowSuggestionsPopup(false)}
                headerIcon={IconMap.AwsSecurityGroup}
                title={t('INSIGHTS.TITLE')}
                subtitleParts={ [displayedPrompts.displayName, t('EDGE.POPOVER.INBOUND_SERVICES')] } />
        }
    </>;
};

export default PerimeterExposureComponent;