import React from 'react';
import CloudAccountService from '../cloudAccounts.service';
import { CloudAccountsSyncNow } from '../cloudAccounts.interface';

export interface UseSyncNowReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: (response: CloudAccountsSyncNow.Response) => void;
}

export interface UseSyncNowPayload {
    isLoading: boolean;
    isRequestSuccess: boolean;
    requestData?: CloudAccountsSyncNow.Response;
    isError?: { message: string; };
    syncNow: (request: CloudAccountsSyncNow.Request) => Promise<void>;
    reset: () => void;
}

export type UseSyncNow = (req?: UseSyncNowReq) => UseSyncNowPayload;

export const useSyncNow: UseSyncNow = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = React.useState(false);
    const [requestData, setRequestData] = React.useState<CloudAccountsSyncNow.Response | undefined>();
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const syncNow = React.useCallback(async (request: CloudAccountsSyncNow.Request) => {
        setIsLoading(true);
        try {
            const response = await CloudAccountService.cloudAccountsSyncNow(request);
            setIsRequestSuccess(true);
            setRequestData(response.data);
            onSuccess?.(response.data);
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsRequestSuccess(false);
        setRequestData(undefined);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isRequestSuccess,
        requestData,
        isError,
        syncNow,
        reset
    });
};