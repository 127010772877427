import React, { useEffect, useState } from 'react';
import { extractFilterFieldsToNewModel, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getHttpService, ICustomzationResponse } from 'common/interface/services';
import { getOrganizationalUnitService } from 'common/interface/data_services';
import { FILTER_PANEL_QUERY_NAMES, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { IEnvironment } from 'common/interface/environmentsTable';
import FilterPanelPlaceholder from '../FilterPanelPlaceholder/FilterPanelPlaceholder';
import { EnvironmentTab, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';

interface IWebAppFilterParams {
    filter?: {
        fields?: IWebAppFilter[];
        freeTextPhrase?: string;
        tags?: IWebAppKeyValue[]
    };
}

interface IWebAppFilter {
    name: string,
    value: string
}

interface IWebAppKeyValue {
    key: string,
    value: string
}

export interface EnvironmentsFilterPanelProps {
    tableId: string;
    onFilterChange: (filters: IFiltersValues) => void;
    tableData: Array<IEnvironment>;
    tabData: EnvironmentTab;
}

const EnvironmentsFilterPanel: React.FC<EnvironmentsFilterPanelProps> = props => {
    const { tableId, onFilterChange, tableData, tabData } = props;

    const [filterElement, setFilterElement] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);
    const recentDeps = React.useRef<string[]>(['']);
    const mustRecentDeps = React.useRef<string>('');

    const savedFiltersComponentName = `environments_index_${tableId}`;
    const recentlyUsedComponentName = `environments_index_${tableId}_recent`;

    const FILTER_PANEL_ID = `environments-assets-page-filter-panel-id_${tableId}`;

    useEffect(() => {
        return () => {
            recentDeps.current = [''];
            mustRecentDeps.current = '';
        };
    }, [tabData.id]);

    useEffect(() => {
        const newDepsString = JSON.stringify({ tableData, tabData: tabData.id });
        if (recentDeps.current.includes(newDepsString)) return;
        mustRecentDeps.current = newDepsString;
        recentDeps.current = [...recentDeps.current, newDepsString];

        const getFiltersInitialData = async () => {
            const savedFiltersPromise = getHttpService().request<ICustomzationResponse<any>>(`/customization?component=${savedFiltersComponentName}`,
                { method: 'GET', }, { cachingConfig: { useCache: true, dataAgeLimit: 60 * 60 } }
            );
            const recentlyUsedPromise = getHttpService().request<ICustomzationResponse<any>>(`/customization?component=${recentlyUsedComponentName}`,
                { method: 'GET', }, { cachingConfig: { useCache: true, dataAgeLimit: 60 * 60 } }
            );
            const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
            return Promise.all([savedFiltersPromise, recentlyUsedPromise, organizationalUnitsPromise]).then(
                ([savedFilters, recentlyUsed, organizationalUnits]) => {
                    return {
                        savedFilters,
                        recentlyUsed,
                        organizationalUnits
                    };
                },
            );
        };
        const getAggregations = async (filtersValues?: IFiltersValues): Promise<Aggregations> => {
            if (filtersValues) {
                onFilterChange(filtersValues);
            }
            interface Aggregations {
                [key: string]: { value: any; count: number }[];
            }
            const aggregations = tableData.reduce<Aggregations>((acc, cloudAccount) => {
                Object.entries(cloudAccount).forEach(([key, value]) => {
                    if (typeof value === 'object' || value === undefined || value === null || Array.isArray(value)) return acc;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    const existingValue = acc[key].find((item) => item.value === value);
                    if (existingValue) {
                        existingValue.count++;
                    } else {
                        acc[key].push({ value, count: 1 });
                    }
                });
                return acc;
            }, {});
            
            return aggregations;
        };

        const extractWebAppFiltersFromQueryParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const webAppQueryParams = queryParams.get(FILTER_PANEL_QUERY_NAMES.QUERY);

            if (webAppQueryParams) {
                const urlFilters: IWebAppFilterParams = JSON.parse(webAppQueryParams);
                const filter = urlFilters?.filter;
                const fields = filter?.fields ? extractFilterFieldsToNewModel(filter.fields) : {};
                const freeTextPhrase = filter?.freeTextPhrase ? { [FILTERS_KEYS.FREE_TEXT]: filter?.freeTextPhrase } : {};
                const tags = filter?.tags ? { [FILTERS_KEYS.TAGS]: filter.tags } : [];

                return { ...fields, ...freeTextPhrase, ...tags };
            }
        };

        async function renderFilterPanel() {
            const filtersInitialData = await getFiltersInitialData();
            const webAppQueryParams = extractWebAppFiltersFromQueryParams();
            const aggregations = await getAggregations();
            const defaultFitlers = [
                { id: 'add filter' },
                { id: 'free text' },
                { id: 'saved filters' },
                { id: 'recently used' },
                { id: 'clear all' },
            ];
            const filterPanelElementsList = EnvironmentsTableRegistry.getFilterDefs(
                [...defaultFitlers, ...tabData.filters.map(filterId => ({ id: filterId }))],
                {
                    aggregations: aggregations || {},
                    filtersInitialData: filtersInitialData || {},
                    savedFiltersComponentName,
                    recentlyUsedComponentName
                });
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: true,
                webAppQueryParams: webAppQueryParams,
                filterId: FILTER_PANEL_ID,
            });
            if (newDepsString !== mustRecentDeps.current) return;
            setFilterElement(filterPanel);
            setIsLoading(false);
        }

        async function initPage() {
            setIsLoading(true);
            await renderFilterPanel();
        }

        initPage();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, tabData]);

    if (isLoading) {
        return <FilterPanelPlaceholder />;
    }

    return filterElement;
};

export default React.memo(EnvironmentsFilterPanel);
