import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { addSection } from 'common/helpers/menu';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import EventsTable from './EventsTable';
import { getUserService } from 'common/interface/services';
import { isNotChina } from 'common/utils/dataCenter';
import RedirectToNewEventsTable from './components/RedirectToNewEventsTable';
import { newEventsPageCurrentView, newEventsPageFeatureFlagName } from 'common/interface/events';
import { UserRolesTypes } from 'common/enum/UserRoles';

const isNewEventsTableEnabled = () => { // TODO: remove when old events page is removed
    return getUserService().hasPermission([newEventsPageFeatureFlagName]);
};

export const isNewEventsViewEnabled = () => { // TODO: remove when old events page is removed
    return localStorage.getItem(newEventsPageCurrentView) === 'true';
};

function initializePage() {

    const eventsTableRoute = () => {
        return {
            component: EventsTable,
            path: ['/alerts/findings-new'], // TODO: change to /alerts/findings when old events page is removed
            exact: true,
            condition: isNewEventsTableEnabled() && isNewEventsViewEnabled()
        };
    };
    const eventsTableRedirectRoute = () => { // TODO: remove when old events page is removed
        return {
            component: RedirectToNewEventsTable,
            path: ['/alerts/findings', '/threat-and-security-events', '/posture-findings'],
            exact: true,
            condition: isNewEventsTableEnabled() && isNewEventsViewEnabled()
        };
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        { id: 'Events Table Page - New', content: eventsTableRoute },
        { id: 'Events Table Page - Redirect route', content: eventsTableRedirectRoute }, // TODO: remove when old events page is removed
    ]);
}

function initializeMenu() {
    addSection('events', 
        {
            id: 'default',
            position: 10,
            items: [
                { // TODO: remove when old events page is removed
                    id: 'findings',
                    position: 5,
                    label: 'NAVIGATION_MENU.EVENTS.POSTURE_FINDINGS',
                    state: '/posture-findings',
                    permission: () => getUserService().hasPermission([UserRolesTypes.ALL]) && !isNewEventsTableEnabled(),
                },
                { // TODO: remove when old events page is removed
                    id: 'threat and security',
                    position: 10,
                    label: 'NAVIGATION_MENU.EVENTS.THREAT_AND_SECURITY',
                    state: '/threat-and-security-events',
                    permission: () => getUserService().hasPermission([UserRolesTypes.ALL]) && !isNewEventsTableEnabled(),
                },
                { // TODO: remove when old events page is removed
                    id: 'all-events-page',
                    position: 15,
                    label: 'NAVIGATION_MENU.EVENTS.All',
                    state: '/alerts/findings',
                    permission: (() => isNotChina() && !isNewEventsTableEnabled()),
                },
                { // TODO: remove when old events page is removed
                    id: 'all-events-page-old',
                    position: 15,
                    label: 'NAVIGATION_MENU.EVENTS.All_EVENTS',
                    state: '/alerts/findings',
                    permission: (() => isNotChina() && isNewEventsTableEnabled() && !isNewEventsViewEnabled()),
                },
                {
                    id: 'all-events-page-new',
                    position: 15,
                    label: 'NAVIGATION_MENU.EVENTS.All_EVENTS',
                    chip: { label: 'Preview' },
                    state: '/alerts/findings-new', // TODO: change to /alerts/findings when old events page is removed
                    permission: (() => isNotChina() && isNewEventsTableEnabled() && isNewEventsViewEnabled()),
                }
            ],
        },);
}

export const initializeEventsTablePageAndMenu = () => {
    initializePage();
    initializeMenu();
};
