import React, { useMemo } from 'react';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import PropertyBox from 'common/components/PropertyBox/PropertyBox';
import Command from 'common/design-system/components-v2/Command/Command';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
export const GslPanel: React.FC<{ finding: IFinding }> = ({ finding
}) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const rule = finding.ruleLogic;

    const gsl = useMemo(() => {
        return <Command text={rule} />;
    }, [rule]);

    return ( rule &&
        <PropertyBoxesRow>
            <PropertyBox title={t('INT_FINDING_OVERVIEW.GSL')} content={gsl} />
        </PropertyBoxesRow>
    );
};
