import { Tooltip , Icon } from 'common/design-system/components-v2';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RulesetCard from './RulesetCard';
import { IERMRuleset } from './Rulesets.interface';
import { CpLoadingDots } from '@dome9/components/react/components';
import RulesetModal from './RulesetModal';
import { ACTIONS_KEYS, initialState, reducer } from './Ruleset.Reducer';
import { fetchRulesets, getRulesetVendorName } from './Ruleset.actions';
import { isChina } from 'common/utils/dataCenter';
import { I18nRiskNamespace } from '../../consts';

const Rulesets: React.FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation(I18nRiskNamespace);
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalCurrentRuleset, setModalCurrentRuleset] = useState<IERMRuleset | null>(null);
    const [modalCurrentPlatformRulesets, setModalCurrentPlatformRulesets] = useState<IERMRuleset[]>([]);

    const loadRulesets = async () => {
        setIsLoadingPage(true);
        try {
            const res = await fetchRulesets();
            if (isChina()) {
                delete res.rulesetsByPlatform.GCP;
                const google = 'google';
                res.viewRulesets = res.viewRulesets.filter((ruleset:any) => { return ruleset.cloudVendor !== google ;});
            }

            dispatch({ type: ACTIONS_KEYS.SET_VIEW_RULESETS, payload: res.viewRulesets });
            dispatch({ type: ACTIONS_KEYS.SET_RULESETS_BY_PLATFORM, payload: res.rulesetsByPlatform });
        } finally {
            setIsLoadingPage(false);
        }
    };

    const saveRuleset = (ruleset: IERMRuleset) => {
        const replaceRulesetIdx = state.viewRulesets.findIndex((viewRuleset: IERMRuleset) => {
            return getRulesetVendorName(ruleset?.cloudVendor) === getRulesetVendorName(viewRuleset?.cloudVendor);
        });
        const cloneViewRulesets = [...state.viewRulesets];
        cloneViewRulesets[replaceRulesetIdx] = ruleset;
        dispatch({ type: ACTIONS_KEYS.SET_VIEW_RULESETS, payload: cloneViewRulesets });
    };

    const replaceRuleset = (ruleset?: IERMRuleset) => {
        if (ruleset) {
            setModalCurrentPlatformRulesets(ruleset?.cloudVendor && state.rulesetsByPlatform[ruleset?.cloudVendor]);
            setModalCurrentRuleset(ruleset);
            setModalOpen(true);
        }
    };


    useEffect(() => {
        loadRulesets();
    }, []);

    return (
        <div className='bg-content flex flex-1 flex-col'>
            {isLoadingPage ? (<CpLoadingDots />)
                : <div className='flex flex-1 flex-col overflow-auto p-9'>
                    <div className='mb-7 items-center text-xl text-primary flex gap-6 flex-0'>
                        {t('RISK_MANAGEMENT.RULESETS.TITLE')}
                        <Tooltip content={t('RISK_MANAGEMENT.RULESETS.TOOLTIP')}>
                            <Icon name={'info'} color={'brandPrimary'} />
                        </Tooltip>
                    </div>
                    <div className='grid grid-cols-[repeat(auto-fit,_minmax(320px,_1fr))] gap-8'>
                        {state.viewRulesets.map((ruleset: IERMRuleset) => {
                            return <RulesetCard replaceRuleset={replaceRuleset} ruleset={ruleset} key={ruleset.cloudVendor} />;
                        })}
                    </div>
                    <RulesetModal state={state} dispatch={dispatch} saveRuleset={saveRuleset}
                        rulesets={modalCurrentPlatformRulesets} ruleset={modalCurrentRuleset}
                        setModalOpen={setModalOpen} isModalOpen={isModalOpen} />
                </div>}
        </div>);
};

export default Rulesets;