import {
    IProtectedAssetDetailPanelProvider,
} from 'common/module_interface/assets/ProtectedAssets';
import i18next from 'i18next';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';

const threatAndSecurityEventsPanel: IProtectedAssetDetailPanelProvider = {
    isRelevant: ({ typeByPlatform }) => {
        const noneRelevantTypesForTab = [
            'kubernetes|KubernetesImage',
            'containerregistry|ContainerRegistryImage',
            'shiftleft|ShiftLeftImage',
            'aws|EcsImage'
        ];

        return !noneRelevantTypesForTab.some(type => type === typeByPlatform);
    },
    getValue() {
        return {
            name: 'SecurityEvent',
            title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.THREAT_AND_SECURITY_EVENTS'),
            isReact: false,
        };
    },
};

export default function initializeThreatAndSecurityEventsPanel() {
    ProtectedAssetsPageRegistry.addPanelAddins([
        {
            id: 'securityEvent',
            position: 31,
            content: threatAndSecurityEventsPanel,
        },
    ]);
}
