import {
    fetchIssuesAggregations,
    getFinalIssuesFilterDetails,
    getIssuesRouteFields,
} from '../../../../Issues/Issues.utils';
import { IAggregationInfo, IIssueSeverityValueCount, ISSUE_FIELD_NAMES } from '../../../../Issues/Issues.interface';
import {
    IServerInputFilterDetails,
    IssueSeverityEnum,
} from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { getIssueSeverities, IIssueSeverityInfo } from '../../../../Issues/IssueSeverity';
import { IFieldInfo, SortOrder } from 'common/interface/general';
import { removeFilterFields } from '../../../../../RiskManagement.utils';
import { ILevelBoxItem } from 'common/erm-components/custom/LevelBoxes/LevelBox.interface';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export const getSeveritiesAggregation = async (filter?: IServerInputFilterDetails): Promise<IIssueSeverityValueCount[]> => {
    try {
        const response: IAggregationInfo = await fetchIssuesAggregations([ISSUE_FIELD_NAMES.SEVERITY], filter);
        return response.aggregations.severity || [];
    } catch {
        return [];
    }
};

export const getSeveritiesLevelBoxItems = async (filterDetails?: IServerInputFilterDetails): Promise<ILevelBoxItem[]> => {
    const finalFilter: IServerInputFilterDetails = getFinalIssuesFilterDetails(filterDetails, true);
    return getSeveritiesAggregation(finalFilter).then((severitiesValueCount: IIssueSeverityValueCount[]) => {
        const items: ILevelBoxItem[] = [];
        const severityCountMap: { [key: number]: number } = {};
        severitiesValueCount.forEach((valueCount: IIssueSeverityValueCount) => {
            severityCountMap[valueCount.value] = valueCount.count;
        });
        getIssueSeverities(SortOrder.descending, IssueSeverityEnum.low).forEach((severityInfo: IIssueSeverityInfo) => {
            const count = severityCountMap[severityInfo.level] ?? 0;
            const issuesRouteFields: IFieldInfo[] | undefined = getIssuesRouteFields(finalFilter);
            const routeFields: IFieldInfo[] = [
                ...(removeFilterFields([ISSUE_FIELD_NAMES.SEVERITY], issuesRouteFields) || []),
                {
                    name: ISSUE_FIELD_NAMES.SEVERITY,
                    value: String(severityInfo.level),
                },
            ];
            const onClick = (count > 0) ? () => {
                getErmUrlsService().gotoSecurityIssuesTable(routeFields);
            } : undefined;
            items.push({
                onClick,
                count,
                text: severityInfo.displayText,
                color: severityInfo.color,
                level: severityInfo.level,
            });
        });
        return items;
    });
};
