import React from 'react';
import FindingSeverityBox from 'common/components/SeverityBox/FindingSeverityBox';
import { FindingSeverityEnum } from 'common/consts/FindingSeverity';

export interface FindingSeverityListItem {
    title: React.ReactNode;
    type?: string,
    criticalCount: number;
    highCount: number;
    mediumCount: number;
    lowCount: number;
}

const FindingSeverityList: React.FC<{ severities: FindingSeverityListItem[], getOnClickSeverityLine?: Function,
    getOnClickSeverityCounter?: Function }> = ({ severities, getOnClickSeverityLine, getOnClickSeverityCounter }) => {
        return (
            <table className='table-auto w-full'>
                <tbody>
                    {severities.map((severityListItem, index) => {
                        return <tr className='border-b' key={index}>
                            <td className={`leading-none ${getOnClickSeverityLine?.(severityListItem) ? 'cursor-pointer' : ''}`}
                                onClick={getOnClickSeverityLine?.(severityListItem)}>{severityListItem.title}</td>
                            <td className='leading-none'>
                                <div className='flex justify-end pl-6'>
                                    <FindingSeverityBox onClick={getOnClickSeverityCounter?.(severityListItem, FindingSeverityEnum.critical, severityListItem.criticalCount)} number={severityListItem.criticalCount} severity={FindingSeverityEnum.critical}
                                        size={'standard'}></FindingSeverityBox>
                                    <FindingSeverityBox onClick={getOnClickSeverityCounter?.(severityListItem, FindingSeverityEnum.high, severityListItem.highCount)} number={severityListItem.highCount} severity={FindingSeverityEnum.high}
                                        size={'standard'}></FindingSeverityBox>
                                    <FindingSeverityBox onClick={getOnClickSeverityCounter?.(severityListItem, FindingSeverityEnum.medium, severityListItem.mediumCount)} number={severityListItem.mediumCount} severity={FindingSeverityEnum.medium}
                                        size={'standard'}></FindingSeverityBox>
                                    <FindingSeverityBox onClick={getOnClickSeverityCounter?.(severityListItem, FindingSeverityEnum.low, severityListItem.lowCount)} number={severityListItem.lowCount} severity={FindingSeverityEnum.low}
                                        size={'standard'}></FindingSeverityBox>
                                </div>
                            </td>
                        </tr>;
                    })}
                </tbody>
            </table>);
    }
;

export default FindingSeverityList;