import React from 'react';

const SimpleDottedHeader: React.FC<{ title: string }> = ({ title }) => {
    return (
        <div className='flex-0 mt-[32px] mb-5 flex items-center'>
            <div className='flex-0 mb-5 font-semibold mr-5'>
                {title}:
            </div>
            <div className='mb-2 mr-auto flex-1 self-center border-b border-dashed'></div>
        </div>
    );
};

export default SimpleDottedHeader;