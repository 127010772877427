import React, { useEffect } from 'react';
import { TextField } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import { FormWrapper, Tooltip_, Icon_ } from './OciOnboarding.styled';
import {
    getAccountName,
    getTenancyId,
    getHomeRegion,
    getUserName,
    getGroupName,
    getPolicyName,
    setAccountName,
    setTenancyId,
    setHomeRegion,
    setUserName,
    setGroupName,
    setPolicyName,
    getOciOnboardingValid,
    getUserEmailAddress,
    setUserEmailAddress,
} from './OciOnboarding.reducer';
import { useDispatch, useSelector } from 'react-redux';

const OciOnboardingGetStarted: React.FC<any> = ({ onValidate }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountName = useSelector(getAccountName);
    const tenancyId = useSelector(getTenancyId);
    const homeRegion = useSelector(getHomeRegion);
    const userEmailAddress = useSelector(getUserEmailAddress);
    const userName = useSelector(getUserName);
    const groupName = useSelector(getGroupName);
    const policyName = useSelector(getPolicyName);
    const formValidations = useSelector(getOciOnboardingValid);

    useEffect(() => {
        onValidate(formValidations.valid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountName, tenancyId, homeRegion, userEmailAddress]);

    const onAccountNameChanged = (event: any)=>{
        dispatch(setAccountName(event?.target?.value || ''));
    };

    const onTenancyIdChanged = (event: any)=>{
        dispatch(setTenancyId(event?.target?.value || ''));
    };

    const onHomeRegionChanged = (event: any)=>{
        dispatch(setHomeRegion(event?.target?.value || ''));
    };

    const onUserEmailAddressChanged = (event: any)=>{
        dispatch(setUserEmailAddress(event?.target?.value || ''));
    };

    const onUserNameChanged = (event: any)=>{
        dispatch(setUserName(event?.target?.value));
    };

    const onGroupNameChanged = (event: any)=>{
        dispatch(setGroupName(event?.target?.value));
    };

    const onPolicyNameChanged = (event: any)=>{
        dispatch(setPolicyName(event?.target?.value));
    };

    const clearAccountName = () => {
        onAccountNameChanged('');
    };

    const clearTenancyId = () => {
        onTenancyIdChanged('');
    };

    const clearHomeRegion = () => {
        onHomeRegionChanged('');
    };

    const clearUserEmailAddress = () => {
        onUserEmailAddressChanged('');
    };


    return (
        <div>
            <div className='title-large'>{t('ON_BOARDING.OCI.WELCOME_TITLE')}</div>
            <div className='text-body aws-onboarding-welcome-description'>
                {t('ON_BOARDING.OCI.OCID_INSTRUCTIONS')}
            </div>
            <FormWrapper>
                <div className='mt-7 mb-4 font-medium'>
                    {t('ON_BOARDING.OCI.ACCOUNT_NAME')}*
                    <Tooltip_ placement='right' content={t('ON_BOARDING.OCI.ACCOUNT_NAME_INFO')} >
                        <span>
                            <Icon_ name='info' color={'info'} size={12} />
                        </span>
                    </Tooltip_>
                </div>
                <TextField
                    className={'max-w-[50ch]'}
                    value={accountName}
                    onChange={onAccountNameChanged}
                    onClear={clearAccountName}
                    state={formValidations?.errors?.accountName ? 'error' : 'info'}
                    helper={formValidations?.errors?.accountName ? formValidations.errors.accountName[0]?.message : undefined}
                ></TextField>

                <div className='text-sm mt-7'>
                    <div className={'mt-7 mb-4 font-medium'}>
                        {t('ON_BOARDING.OCI.TENANCY_ID')}*
                    </div>
                    <div className={'mb-4'}>
                        {t('ON_BOARDING.OCI.TENANCY_ID_INFO')}
                    </div>
                </div>
                <TextField value={tenancyId}
                    className={'max-w-[100ch]'}
                    onChange={onTenancyIdChanged}
                    onClear={clearTenancyId}
                    state={formValidations?.errors?.tenancyId ? 'error' : 'info'}
                    helper={formValidations?.errors?.tenancyId ? formValidations.errors.tenancyId[0]?.message : undefined}
                ></TextField>

                <div className='text-sm mt-7'>
                    <div className={'mt-7 mb-4 font-medium'}>
                        {t('ON_BOARDING.OCI.HOME_REGION')}*
                    </div>
                    <div className={'mb-4'}>
                        {t('ON_BOARDING.OCI.HOME_REGION_INFO')}
                    </div>
                </div>
                <TextField
                    className={'max-w-[25ch]'}
                    value={homeRegion}
                    onChange={onHomeRegionChanged}
                    onClear={clearHomeRegion}
                    state={formValidations?.errors?.homeRegion ? 'error' : 'info'}
                    helper={formValidations?.errors?.homeRegion ? formValidations.errors.homeRegion[0]?.message : undefined}
                ></TextField>


                <div className='mt-7 mb-4 font-medium'>
                    {t('ON_BOARDING.OCI.USER_EMAIL_ADDRESS')}*
                </div>
                <TextField
                    className={'max-w-[50ch]'}
                    value={userEmailAddress}
                    onChange={onUserEmailAddressChanged}
                    onClear={clearUserEmailAddress}
                    state={formValidations?.errors?.emailAddress ? 'error' : 'info'}
                    helper={formValidations?.errors?.emailAddress ? formValidations.errors.emailAddress[0]?.message : undefined}
                ></TextField>


                <div className='mt-7 mb-4 font-medium'>
                    {t('ON_BOARDING.OCI.USER_NAME')}
                    <Tooltip_ placement='right' content={t('ON_BOARDING.OCI.USER_NAME_INFO')} >
                        <span>
                            <Icon_ name='info' color={'info'} size={12} />
                        </span>
                    </Tooltip_>
                </div>
                <TextField className={'max-w-[50ch]'} value={userName} onChange={onUserNameChanged} placeholder={'CloudGuard-User'}></TextField>

                <div className='mt-7 mb-4 font-medium'>
                    {t('ON_BOARDING.OCI.GROUP_NAME')}
                    <Tooltip_ placement='right' content={t('ON_BOARDING.OCI.GROUP_NAME_INFO')} >
                        <span>
                            <Icon_ name='info' color={'info'} size={12} />
                        </span>
                    </Tooltip_>
                </div>
                <TextField className={'max-w-[50ch]'} value={groupName} onChange={onGroupNameChanged} placeholder={'CloudGuard-Group'}></TextField>

                <div className='mt-7 mb-4 font-medium'>
                    {t('ON_BOARDING.OCI.POLICY_NAME')}
                    <Tooltip_ placement='right' content={t('ON_BOARDING.OCI.POLICY_NAME_INFO')} >
                        <span>
                            <Icon_ name='info' color={'info'} size={12} />
                        </span>
                    </Tooltip_>
                </div>
                <TextField className={'max-w-[50ch]'} value={policyName} onChange={onPolicyNameChanged} placeholder={'CloudGuard-Policy'}></TextField>
            </FormWrapper>
        </div>
    );
};

export default OciOnboardingGetStarted;
