import { getVendor } from 'common/consts/vendors';
import { getService } from 'common/extensibility/AddinContainer';
import { GenericObject } from 'common/interface/general';
import { INotification, IPolicy } from 'common/interface/policy';
import { IRuleset } from 'common/interface/ruleset';
import {
    getHttpService,
    IPolicyService,
    IRulesetService,
    POLICY_SERVICE_ID,
    RULESET_SERVICE_ID,
} from 'common/interface/services';
import { t } from 'i18next';
import { ICustomRuleset, IERMRuleset } from './Rulesets.interface';
import { ERM_CACHE_TAGS, I18nRiskNamespace } from '../../consts';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

const BASE_URL = 'erm/ruleset';

const PLATFORM_NUM: { [key: string]: number } = {
    AWS: 1,
    Azure: 2,
    GCP: 3,
    Kubernetes: 4,
};

export const getRulesetVendorName = (platform?: string) => {
    return getVendor(platform || '')?.displayName;
};

const getVendorNum = (cloudVendor?: string) => {
    const vendor = getRulesetVendorName(cloudVendor);
    return vendor && PLATFORM_NUM[vendor];
};

export const isErmRulesetId = (id?: number) => {
    if (!id) {
        return false;
    }
    // return id <= -91 && id >= -98; // US9 ids
    return id <= -132 && id >= -135;
};

const getAllRulesetOptionObj = (ruleset: IERMRuleset, isSelected?: boolean) => {
    return {
        name: t('RISK_MANAGEMENT.RULESETS.ALL_DESCRIPTION_NAME', { ns: I18nRiskNamespace }),
        isAllOption: true,
        cloudVendor: ruleset.cloudVendor,
        description: t('RISK_MANAGEMENT.RULESETS.ALL_DESCRIPTION', { ns: I18nRiskNamespace }),
        isSelected,
        rulesCount: t('RISK_MANAGEMENT.RULESETS.NO_MANAGED_BY', { ns: I18nRiskNamespace }),
        managedBy: t('RISK_MANAGEMENT.RULESETS.NO_MANAGED_BY', { ns: I18nRiskNamespace }),
        policies: t('RISK_MANAGEMENT.RULESETS.NO_MANAGED_BY', { ns: I18nRiskNamespace }),
    };
};

const getPolicies = async () => {
    try {
        return await getService<IPolicyService>(POLICY_SERVICE_ID).getCompliancePolicies();
    } catch {
        return [];
    }
};

export const getCustomRulesets = async () => {
    try {
        const customRulesets = await getHttpService().get<{
            customRulesets: ICustomRuleset[]
        }>(`${BASE_URL}/get-custom-ruleset`);
        return customRulesets.customRulesets;
    } catch {
        return [];
    }
};

export const fetchRulesets = async () => {
    const viewRulesets: IERMRuleset[] = [];
    const policies: IPolicy[] | [] = await getPolicies();
    const rulesetsRes = await getService<IRulesetService>(RULESET_SERVICE_ID).getAllRulesets();
    const ermRulesets = rulesetsRes?.filter((res: IRuleset) => isErmRulesetId(res.id));
    const customRulesets = await getCustomRulesets();
    const rulesetsByPlatform: GenericObject<IERMRuleset[]> = {};

    ermRulesets.forEach((ruleset: IRuleset) => {
        const customRuleset = customRulesets.find((customRuleset: ICustomRuleset) => {
            return getRulesetVendorName(customRuleset.platform) === getRulesetVendorName(ruleset?.cloudVendor);
        });
        const allRuleset = getAllRulesetOptionObj(ruleset, true);
        if (customRuleset) {
            const rulesetDetails: IERMRuleset | undefined = rulesetsRes.find((ruleset: IRuleset) => customRuleset.rulesetId === ruleset.id);
            if (rulesetDetails) {
                const managedBy = ruleset.isTemplate ? t('RISK_MANAGEMENT.RULESETS.FILTER.CLOUD_GUARD', { ns: I18nRiskNamespace }) : t('RISK_MANAGEMENT.RULESETS.FILTER.USER', { ns: I18nRiskNamespace });
                const icon: AllIconsName = ruleset.isTemplate ? 'cloudGuard' : 'user';
                rulesetDetails.managedBy = managedBy;
                rulesetDetails.icon = icon;
                rulesetDetails.isSelected = true;
            }
            viewRulesets.push(rulesetDetails || {} as IERMRuleset);
        } else {
            viewRulesets.push(allRuleset);
        }
    });

    const rulesets: IERMRuleset[] = rulesetsRes.map((ruleset: IRuleset): IERMRuleset => {
        const currentRuleset: IERMRuleset = {
            ...ruleset,
            policies: policies.filter((policy: IPolicy) => policy.rulesetId === ruleset.id)?.length || null,
            rulesCount: ruleset.rulesCount,
            managedBy: ruleset.isTemplate ? t('RISK_MANAGEMENT.RULESETS.FILTER.CLOUD_GUARD', { ns: I18nRiskNamespace }) : t('RISK_MANAGEMENT.RULESETS.FILTER.USER', { ns: I18nRiskNamespace }),
            icon: ruleset.isTemplate ? 'cloudGuard' : 'user',
            isSelected: viewRulesets.some((viewRuleset: IERMRuleset) => ruleset.id === viewRuleset.id),
        };
        return currentRuleset;
    });

    viewRulesets.forEach((viewRuleset: IERMRuleset) => {
        if (viewRuleset.isAllOption) {
            rulesets.unshift(viewRuleset);
        } else {
            rulesets.unshift(getAllRulesetOptionObj(viewRuleset) as IERMRuleset);
        }
    });

    rulesets.forEach((ruleset: IERMRuleset) => {
        const vendor = getRulesetVendorName(ruleset.cloudVendor);
        if (vendor) {
            if (isErmRulesetId(ruleset?.id)) {
                const recommendedText = t('RISK_MANAGEMENT.RULESETS.RECOMMENDED', { ns: I18nRiskNamespace });
                ruleset.name = `${ruleset.name}${recommendedText}`;
            }
            if (rulesetsByPlatform?.[vendor]) {
                rulesetsByPlatform[vendor].push(ruleset);
            } else {
                rulesetsByPlatform[vendor] = [ruleset];
            }
        }
    });

    return { viewRulesets, rulesetsByPlatform };
};

const getNotificationId = async (accountId: number | undefined) => {
    try {
        const notificationName = `erm-custom-ruleset-notification-(${accountId})`;
        const notifications = await getHttpService().get<INotification[]>('Compliance/ContinuousComplianceNotification');
        const existNotification = notifications.find((notification: INotification) => notification.name === notificationName);
        let ermNotificationId = null;

        if (existNotification) {
            ermNotificationId = existNotification.id;
        } else {
            const notification = {
                alertsConsole: true,
                changeDetection: {
                    awsSecurityHubIntegrationState: 'Disabled',
                    azureSecurityCenterIntegrationState: 'Disabled',
                    emailPerFindingSendingState: 'Disabled',
                    emailSendingState: 'Disabled',
                    externalTicketCreatingState: 'Disabled',
                    slackIntegrationState: 'Disabled',
                    snsSendingState: 'Disabled',
                    teamsIntegrationState: 'Disabled',
                    webhookIntegrationState: 'Disabled',
                },
                description: '',
                filter: {
                    entityIds: [],
                    entityNames: [],
                    entityTypes: [],
                    severities: [],
                },
                gcpSecurityCommandCenterIntegration: {
                    state: 'Disabled',
                },
                name: notificationName,
                scheduledReport: {
                    emailSendingState: 'Disabled',
                },
            };
            const ermNotification = await getHttpService().post<INotification>('Compliance/ContinuousComplianceNotification', { data: notification });
            ermNotificationId = ermNotification.id;
        }
        return ermNotificationId;
    } catch {
        return null;
    }
};

export const createPolicy = async (ruleset: IERMRuleset) => {
    const notificationId = await getNotificationId(ruleset.accountId);
    const vendor = getRulesetVendorName(ruleset.cloudVendor);
    await getService<IPolicyService>(POLICY_SERVICE_ID).setPolicy(
        [
            {
                notificationIds: [notificationId],
                rulesetId: ruleset.id,
                targetType: 'Vendor',
                vendor,
            },
        ] as IPolicy [],
    );
};

export const saveAllOptionRuleset = async (ruleset: IERMRuleset) => {
    return await getHttpService().delete<{
        customRuleset: ICustomRuleset;
    }>(`${BASE_URL}/remove-all-custom-rulesets`, { data: { platform: getVendorNum(ruleset?.cloudVendor) } });
};


export const saveSelectedRuleset = async (ruleset: IERMRuleset) => {
    const vendor = getRulesetVendorName(ruleset?.cloudVendor);
    const vendorNum = vendor && PLATFORM_NUM[vendor];

    const customRuleset = {
        platform: vendorNum,
        rulesetName: ruleset.name,
        rulesetId: ruleset.id,
    };

    const response = await getHttpService().post<{
        customRuleset: ICustomRuleset;
    }>(`${BASE_URL}/set-custom-ruleset`, { data: customRuleset });
    getHttpService().clearCacheByTag(ERM_CACHE_TAGS.TOP_REMEDIATION_ACTIONS, 'POST');
    return response;
};