import React, { useEffect, useState } from 'react';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import {
    SnsCancelSubmitButtonWrapper,
    SnsSystemComponentStyled,
    ToggleButtonWrapper,
    TopicWrapper
} from './SnsSystemComponent.styled';
import { Button, Typography, Modal, Input, IconButton, Spinner } from 'common/design-system/components-v2';
import Switch from 'common/design-system/components-v2/Switch';
import snsInstructions from './sns_permissions.png';
import { ALink } from 'common/components/ALink';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations } from 'common/module_interface/settings/integrations/consts';
import { getIntegrationsService, getUserService, ISystemSnsConfiguration } from 'common/interface/services';
import { setShouldRefreshData } from '../../../Integrations.reducer';
import { useDispatch } from 'react-redux';
import { integrationNotificationReadOnly } from 'common/utils/userPermissionUtils';


export interface ISnsConfigurationProps extends IConfigurationContainerProps{
    configurations: ISystemSnsConfiguration[];
}

const SnsSystemComponent : React.FC<ISnsConfigurationProps> = ({ configurations }) => {
    const snsInitTopicConfig:string = (configurations && configurations.length > 0 && configurations[0].configurationObj.snsTopicArn) || '';
    const { t } = useTranslation( I18nIntegrations );
    const dispatch = useDispatch();
    const [toggleButtonOn, setToggleButtonOn] = useState(Boolean(snsInitTopicConfig));
    const [modalOpen, setModalOpen] = useState(false);
    const [modalError, setModalError] = useState< string>( '' );
    const [snsTopicConfig, setSnsTopicConfig] = useState<string>(snsInitTopicConfig);
    const [snsModalInput, setSnsModalInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [cloudguardUsAwsAccountID, setCloudguardUsAwsAccountID] = useState('');

    const snsLearnMoreURL = 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Documentation/Integrations/AWS-SNS-Integration.htm?cshid=integrate_sns';
    const viewMode = integrationNotificationReadOnly();

    useEffect(() => {
        const getCloudguardUsAwsAccountID = async () => {
            const cloudguardUsAwsAccountID = await getUserService().getCloudguardAccount().d9AwsAccountNumber;
            setCloudguardUsAwsAccountID(cloudguardUsAwsAccountID);
        };
        getCloudguardUsAwsAccountID();
    });

    const submitSnsTopicArn = async (snsModalInput:string) => {
        try {
            handleChangeSpinner(true);
            setModalError('');
            if (snsTopicConfig) {
                await getIntegrationsService().deleteSystemSnsConfiguration();
                setSnsTopicConfig('');
            }
            await getIntegrationsService().postSystemSnsConfiguration(snsModalInput);
            setSnsTopicConfig(snsModalInput);
            setModalOpen(false);
        } catch (error:any) {
            setModalError( error || t('CONFIGURATIONS.SNS_AUDIT.NETWORK_ERROR'));
        } finally {
            handleChangeSpinner(false);
            dispatch(setShouldRefreshData(true));
        }
    };

    const handleChangeSpinner = ( requiredState : boolean ) => {
        setLoading(requiredState);
    };

    const handleToggleButtonClick = async () => {
        if (toggleButtonOn && snsTopicConfig) {
            setSnsTopicConfig('');
            await getIntegrationsService().deleteSystemSnsConfiguration();
            dispatch(setShouldRefreshData(true));
        }
        setToggleButtonOn(!toggleButtonOn);
    };

    let debounceTimer: NodeJS.Timeout;
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            setSnsModalInput(text);
        }, 300);
    };

    const openModal = () => {
        setModalError('');
        setModalOpen(true);
    };

    return (
        <SnsSystemComponentStyled data-aid={'sns-system'}>
            <Typography variant='subtitleLg'>{t('CONFIGURATIONS.SNS_AUDIT.SYSTEM_NOTIFICATIONS_TO_AWS_SNS')}</Typography>
            <Typography style={{ margin: 10 }}>{t('CONFIGURATIONS.SNS_AUDIT.SNS_INSTRUCTIONS')}</Typography>
            <ALink style={{ display: 'flex', alignItems: 'center' }} target={'_blank'} href={snsLearnMoreURL}>
                <IconButton color="brandPrimary" iconProps={{ name: 'externalLinkInline' }} size="small"/>
                <Typography variant='body' color='link'>{t('CONFIGURATIONS.SNS_AUDIT.LEARN_MORE')}</Typography>
            </ALink>
            <ToggleButtonWrapper>
                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.SNS_AUDIT.ENABLE_SNS')}</Typography>
                <Switch size='medium' checked={toggleButtonOn} disabled={viewMode} onChange={() => handleToggleButtonClick()} />
            </ToggleButtonWrapper>
            {snsTopicConfig ?
                <TopicWrapper>
                    <Typography variant='bodyLg' style={{ background: '#EBF4FF' }}>{snsTopicConfig}</Typography>
                    <Button variant='outlined' size='medium' disabled={!toggleButtonOn || viewMode} onClick={()=>openModal()}>{t('CONFIGURATIONS.SNS_AUDIT.CHANGE_TOPIC')}</Button>
                </TopicWrapper>
                : <Button variant='outlined' size='medium' disabled={!toggleButtonOn || viewMode} onClick={()=>openModal()}>{t('CONFIGURATIONS.SNS_AUDIT.SELECT_TOPIC')}</Button>}
            <Modal.ModalDialog width={'lg'} isOpen={modalOpen} >
                <Modal.ModalHeader title={'AWS SNS Events integration'} onClose={()=> setModalOpen(false)} />
                <Modal.ModalContent>
                    <Typography variant='bodyLg'>{t('CONFIGURATIONS.SNS_AUDIT.SNS_MODAL_ITEM_1')}</Typography>
                    <Typography variant='bodyLg'>{t('CONFIGURATIONS.SNS_AUDIT.SNS_MODAL_ITEM_2')}</Typography>
                    <Typography variant='bodyLg'>{t('CONFIGURATIONS.SNS_AUDIT.SNS_MODAL_ITEM_3')}<strong>{ cloudguardUsAwsAccountID }</strong> to publish messages</Typography>
                    <div>
                        <img src={snsInstructions} alt="AWS SNS instructions" style={{ maxWidth: 280 }}/>
                    </div>
                    <Typography variant='bodyLg'>{t('CONFIGURATIONS.SNS_AUDIT.SNS_MODAL_ITEM_4')}</Typography>
                    <Input type="text" placeholder={t('GENERAL.TYPE_HERE')} autoFocus fullWidth={true} onChange={handleOnChange}
                        isError={Boolean(modalError)} helperText={modalError} />
                </Modal.ModalContent>
                <SnsCancelSubmitButtonWrapper>
                    <Button variant='text' size='medium' onClick={()=>{setModalOpen(false);}}>{t('GENERAL.CANCEL')}</Button>
                    <Button variant='contained' color='brandPrimary' size='medium' style={{ height: 32, marginLeft: 10 }} onClick={()=>{submitSnsTopicArn(snsModalInput);}}>{loading ? <Spinner size={12}/> : t('GENERAL.SAVE')}</Button>
                </SnsCancelSubmitButtonWrapper>
            </Modal.ModalDialog>
        </SnsSystemComponentStyled>
    );
};


export default SnsSystemComponent;