import { TFunction } from 'i18next';
import { IRuleset } from 'common/interface/ruleset';
import * as yup from 'yup';
import { EXCLUSION_SELECTED_TYPE, FindingSeverityEnum } from './exclusions.consts';
import { ExclusionsService, getNotificationsService } from 'common/interface/services';
import { ICloudAccount, IExclusionConfig, IExclusionSavePayload, ITags } from './exclusions.interfaces';
import { IOrganizationalUnit } from 'common/interface/data_services';
import { IRegion, IRule } from 'common/interface/exclusion';
import { Vendors } from 'common/consts/vendors';


type CloudVendorKey = keyof typeof cloudVendorIcons;
export const cloudVendorIcons = {
    alibaba: Vendors.ALIBABA,
    aws: Vendors.AWS,
    azure: Vendors.AZURE,
    google: Vendors.GCP,
    oci: Vendors.ORACLE,
    imageassurance: Vendors.KUBERNETES,
    kubernetesruntimeassurance: Vendors.KUBERNETES,
};

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const formatRules = (rules: IRule[] ) => rules?.length && rules.map(rule => {return ({ label: rule.name, value: rule.logicHash });});
export const formatRegions = (regions: IRegion[] ) => regions?.length && regions.map((region:IRegion) => {return { label: `${region.description} (${region.id})`, value: region.id };});
export const formatOrganizationalUnits = (organizationalUnits:IOrganizationalUnit[]) => organizationalUnits?.length && organizationalUnits.map((organizationalUnit) => {
    return { label: organizationalUnit?.name, value: organizationalUnit?.id };
});
export const filterEnvironments = (environments: ICloudAccount[], vendor:string) => environments?.length && environments.filter(environment => environment.platform === vendor);
export const formatEnvironments = (environments: ICloudAccount[] ) => environments?.length && environments.map(environment => {
    return ({ label: environment.name, value: environment.id });
});
export const formatAccountNumber = (selectedOption : string[]) => {
    const accountNumberString = selectedOption.find(item => item.startsWith('accountNumber like'));
    const regex = /accountNumber like '([^']*)'/g;
    const match = accountNumberString && regex.exec(accountNumberString);
    return match?.length && match[1];
};
export const formatTags = (selectedOption: string[]) => {
    const tagString = selectedOption.find(item => item.startsWith('tags contain'));
    const keyValuePairs:ITags[] = [];
    if (tagString) {
        const keyValuePairs = [];
        const regex = /key like '([^']*)' and value like '([^']*)'/g;
        let match;
        while ((match = regex.exec(tagString)) !== null) {
            keyValuePairs.push({ key: match[1], value: match[2] });
        }
    }
    return keyValuePairs;
};
export const fetchRulesets = async () => {
    const rulesetsResponse = await ExclusionsService().getRuleSets();
    return rulesetsResponse;
};
export const fetchRegions = async (vendor:string) => {
    const exclusionsResponse = await ExclusionsService().getVendorRegions(vendor);
    return exclusionsResponse;
};
export const fetchRules = async (id:number) => {
    const exclusionsResponse = await ExclusionsService().getRulesForRuleset(id);
    return exclusionsResponse;
};
export const fetchOrganizationalUnits = async () => {
    const exclusionsResponse = await ExclusionsService().getOrganizationalUnit();
    return exclusionsResponse;
};
export const fetchEnvironments = async () => {
    const exclusionsResponse = await ExclusionsService().getEnvironment();
    return exclusionsResponse;
};
export const fetchSeverities = () => {
    return Object.values(FindingSeverityEnum).map((severity) => ({ label: severity, value: severity }));
};

export const handleFetchingError = (t: TFunction<string, undefined>, onClose:()=>void) => {
    getNotificationsService().error(
        t('MODAL.TOAST.NETWORK_ERROR.MESSAGE'),
        t('MODAL.TOAST.NETWORK_ERROR.DESCRIPTION'),
    );
    onClose();
};

export const createRulesetList = async (rulesetsFromApi:IRuleset[]) => {
    if (!rulesetsFromApi || !rulesetsFromApi.length) return;
    const filteredForCompliance = rulesetsFromApi.filter((ruleSet: { hideInCompliance: boolean, cloudVendor:string }) => !ruleSet.hideInCompliance);
    const sortedAlphabetically = filteredForCompliance.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));
    const sortedByCloudVendor = sortedAlphabetically.sort((a: { cloudVendor: string }, b: { cloudVendor: string }) => a.cloudVendor.localeCompare(b.cloudVendor));
    const filteredSortedRulesetListWithIcon = sortedByCloudVendor.map((ruleSet: { name: string, cloudVendor:string, systemBundle:boolean, icon:string }) => {
        const { name, cloudVendor } = ruleSet;
        return {
            label: name,
            value: name,
            labelProps: { leadingIconProps: { vendorNameOrPath: cloudVendorIcons[cloudVendor as CloudVendorKey] ?? cloudVendor } },
        };
    } );
    return filteredSortedRulesetListWithIcon;
};

export const getRulesetDetails = (rulesetsFromApi:IRuleset[], selectedRulesetName: string) => {
    if (!selectedRulesetName) return;
    const selectedRulesetDetails = rulesetsFromApi.find((ruleset: { name: string }) => ruleset.name === selectedRulesetName);
    return selectedRulesetDetails;
};

const formatPayloadForSave = (IExclusionConfig: IExclusionConfig) => {
    const { selectedComment, ouEnvironmentRadioButton, selectedOrganizationalUnit, selectedDateRange,
        selectedEnvironment, selectedRegion, selectedRule, selectedAccountNumber, entityLogic,
        selectedTags, selectedSeverities, exclusionId, selectedRulesetDetails , findingTypeLogic } = IExclusionConfig;

    const addLogicExpression = () => {
        const generateTagsGslString = (selectedTags: ITags[]) => {
            const conditions = selectedTags.map(tag => tag.key && tag.value && `key like '${tag.key}' and value like '${tag.value}'`);
            const joinedConditions = conditions.join(' or ');
            return `tags contain [${joinedConditions}]`;
        };
        selectedAccountNumber && exclusionSavePayload.logicExpressions.push(`accountNumber like '${selectedAccountNumber}'`);
        selectedTags?.length && exclusionSavePayload.logicExpressions.push(generateTagsGslString(selectedTags));
        if (findingTypeLogic?.length) {
            exclusionSavePayload.logicExpressions = exclusionSavePayload.logicExpressions.concat(findingTypeLogic);
        }
        entityLogic && exclusionSavePayload.logicExpressions.push(entityLogic);
    };

    const exclusionSavePayload: IExclusionSavePayload = {
        id: exclusionId || null,
        rulesetId: selectedRulesetDetails?.id,
        comment: selectedComment || '',
        cloudAccountType: selectedRulesetDetails ? capitalizeFirstLetter(selectedRulesetDetails?.cloudVendor) : undefined,
        logicExpressions: [],
        organizationalUnitIds: ouEnvironmentRadioButton===EXCLUSION_SELECTED_TYPE.ORGANIZATIONAL_UNIT ? selectedOrganizationalUnit: null,
        platform: selectedRulesetDetails?.cloudVendor,
        regions: selectedRegion,
        rules: selectedRule && selectedRule.map(rule => ({ logicHash: rule })),
        severities: selectedSeverities,
        cloudAccountIds: ouEnvironmentRadioButton===EXCLUSION_SELECTED_TYPE.ENVIRONMENT ? selectedEnvironment: null,
        dateRange: selectedDateRange
    };
    addLogicExpression();
    return exclusionSavePayload;
};

export const exclusionSave = async (exclusionConfig: IExclusionConfig) => {
    const exclusionSavePayload = formatPayloadForSave(exclusionConfig);
    const exclusionsResponse = await ExclusionsService().saveExclusionCspm(exclusionSavePayload);
    return exclusionsResponse;
};

export const exclusionSaveCiem = async (exclusionConfig: IExclusionConfig) => {
    const exclusionSavePayload = formatPayloadForSave(exclusionConfig);
    return await ExclusionsService().saveExclusionCiem(exclusionSavePayload);
};

export const determineSearchEntityOption = (query: string) => {
    const endsWithPattern = /^%(.+)$/;
    const startsWithPattern = /^(.+)%$/;
    const includesPattern = /^%(.+)%$/;
    if (includesPattern.test(query)) {
        return `(includes) ${query.replace(/%/g, '')}`;
    } else if (startsWithPattern.test(query)) {
        return `(starts with) ${query.replace(/%/g, '')}`;
    } else if (endsWithPattern.test(query)) {
        return `(ends with) ${query.replace(/%/g, '')}`;
    }
    return null;
};

export const exclusionCspmValidation = (t: TFunction) => ({
    ruleset: yup.string().required(t('MODAL.VALIDATIONS.RULESET.REQUIRED')),
    comment: yup.string().required(t('MODAL.VALIDATIONS.COMMENT.REQUIRED')),
    requiredAdditionalInfo: yup.boolean().isTrue(t('MODAL.VALIDATIONS.AT_LEAST_ONE_REQUIRED')),
});