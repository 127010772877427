import { AutoActionTriggerEnum } from 'common/module_interface/RiskManagement/autoActions/AutoActions';
import { ermTrans } from '../../../RiskManagement.utils';
import { MAX_SELECTED_INTEGRATIONS } from './AutoActionEditor.consts';
import { isEmptyString } from 'common/utils/helpFunctions';
import { IAutoAction } from '../AutoActions.interface';

export const getTriggersErrorMsg = (triggers: AutoActionTriggerEnum[]) => {
    if (triggers.length === 0) {
        return ermTrans('AUTO_ACTIONS.EDITOR.WHEN.TRIGGERS_REQUIRED_ERROR_MESSAGE');
    }
};

export const getIntegrationIdsErrorMsg = (integrationIds: string[]): string | undefined => {
    if ((integrationIds.length === 0)) {
        return ermTrans('AUTO_ACTIONS.EDITOR.THEN.INTEGRATIONS_REQUIRED_ERROR_MESSAGE');
    }
    if ((integrationIds.length > MAX_SELECTED_INTEGRATIONS)) {
        return ermTrans('AUTO_ACTIONS.EDITOR.THEN.INTEGRATIONS_EXCEEDED_LIMIT_ERROR_MESSAGE', { max: MAX_SELECTED_INTEGRATIONS });
    }
};

export const getNameErrorMsg = (name: string, otherAutoActions: IAutoAction[]): string | undefined => {
    if (isEmptyString(name)) {
        return ermTrans('AUTO_ACTIONS.EDITOR.ATTR.NAME_REQUIRED_ERROR_MESSAGE');
    }
    if (otherAutoActions.some(autoAction => autoAction.name === name)) {
        return ermTrans('AUTO_ACTIONS.EDITOR.ATTR.NAME_ALREADY_EXISTS_ERROR_MESSAGE');
    }
};
