import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { ITopIssue } from 'modules/riskManagement/components/Issues/Issues.interface';
import { OccurrencesStyled } from './TopIssues.styled';
import { useTranslation } from 'react-i18next';
import { Stack } from 'common/design-system/components-v2';
import { formatNumberShorthand } from 'common/utils/helpFunctions';
import { I18nRiskNamespace } from '../../../../../consts';

export const OccurrencesCellRender: React.FC<ICellRendererParams<ITopIssue>> = (params) => {
    const { t } = useTranslation();
    const occurrences = params.data?.occurrences;
    if (occurrences === undefined) {
        return '';
    }

    return (
        <OccurrencesStyled.TopOccurrencesDiv>
            <Stack>{ t('RISK_MANAGEMENT.DASHBOARD.SECURITY_ISSUE', { ns: I18nRiskNamespace, count: occurrences, countStr: formatNumberShorthand(occurrences) }) }</Stack>
        </OccurrencesStyled.TopOccurrencesDiv>
    );
};