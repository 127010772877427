import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams, WizardStep } from 'common/components/Wizard/Wizard';
import {
    getNotificationsService, getRulesetService, getShiftLeftService
} from 'common/interface/services';
import { IPolicy } from 'common/interface/policy';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Done } from 'common/components/policy/Done/Done';
import { NotificationType } from 'common/interface/notifications';
import { IRuleset, RulesetVersion } from 'common/interface/ruleset';
import { RulesetVersionSelectComponent } from 'common/components/policy/RulesetVersionSelectComponent';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { useReturnUrl } from 'common/hooks/useReturnUrl';
import { finishFlow } from 'common/components/policy/utils';

const stepIds : any={
    notificationSelection: false,
    rulesetVersion: false,
    done: false,
};

export const EditShiftleftPolicyPage: React.FC = () => {
    const { t } = useTranslation();
    const [policy,setPolicy] = useState<IPolicy>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [selectedRuleset,setSelectedRuleset] = useState<ListItem>();
    const [selectedVersion,setSelectedVersion] = useState<RulesetVersion>();
    const returnUrl = useReturnUrl();
    useBreadcrumbsLastChildren([t('NAVIGATION_MENU.SHIFTLEFT.TITLE'),t('POLICY.EDIT_POLICY')]);

    const saveChanges = useCallback(async () => {
        if(policy && selectedNotifications?.length){
            setFlowStatusMessage(t('POLICY.PROCESSING'));
            const allRulesets = await getRulesetService().getAllRulesets();
            const ruleset = allRulesets.find((ruleset) => ruleset.id === policy.rulesetId);
            policy.rulesetPlatform = ruleset?.cloudVendor;
            policy.targetId = policy.targetId || policy.targetInternalId;
            policy.notificationIds = selectedNotifications.map((notification:ListItem) => notification.id);
            policy.rulesetVersion = selectedVersion?.version;
            setFlowStatusMessage(t('POLICY.PROCESSING'));
            const result = await getShiftLeftService().updateShiftLeftPolicy([policy]);
            if(result) {
                setFlowStatusOK(true);
                setFlowStatusMessage(t('POLICY.POLICY_ADDED_MESSAGE'));
            }else {
                setFlowStatusOK(false);
                setFlowStatusMessage(t('POLICY.ERROR_OCCURRED'));
            }
        }
    },[policy, selectedNotifications, selectedVersion?.version, t]);


    const steps :WizardStep[] = useMemo(() => [
        {
            id: 'rulesetVersion',
            name: t('GENERAL.RULESET_VERSION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <RulesetVersionSelectComponent onSelectedItemChanged={rulesetVersionChanged}
                    ruleset={selectedRuleset} preSelectedVersion={selectedVersion} {...renderViewParams}/>;
            },
            onNext: async ()=>{
                return;
            },
        },
        {
            id: 'notificationSelection',
            name: t('POLICY.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return(
                    <>
                        { selectedNotifications && <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={setSelectedNotifications}/>}
                    </>
                );
            },
            onNext: async ()=>{
                saveChanges();
            },
        },{
            id: 'done',
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ],[flowStatusMessage, flowStatusOK, returnUrl, saveChanges, selectedNotifications, selectedRuleset, selectedVersion, t]);

    useEffect(() => {
        const raisePolicyNotFoundErrorMessage = () => {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('GENERAL.ERROR_OCCURRED'),
                text: t('POLICY.POLICY_NOT_FOUND'),
            });
        };
        const handlePolicyLoad = async (policyId: string) => {
            const allPolicies = await getShiftLeftService().getPolicies(false);
            const _policy = allPolicies.find(policy => policy.id === policyId);
            if(_policy){
                setPolicy(_policy);
                setSelectedNotifications(_policy.notificationIds.map((notificationId:string) => ({ id: notificationId })) as ListItem[]);
                const allRuleset = await getRulesetService().getAllRulesets();
                const ruleset : IRuleset | undefined = allRuleset.find((ruleset:IRuleset) => ruleset.id === _policy.rulesetId);
                if(ruleset){
                    const item = {
                        id: ruleset.id.toString(),
                        name: ruleset.name,
                        icon: ruleset.icon,
                        availableVersions: [...ruleset.availableVersions, { version: 'Latest' }],
                        versionEnabled: true,
                        platform: ruleset.cloudVendor,
                    };
                    setSelectedRuleset(item);
                    setSelectedVersion({ version: _policy.rulesetVersion || '',
                        createdTime: ruleset.availableVersions.find(ruleset=>ruleset.version === _policy.rulesetVersion)?.createdTime });
                    stepIds.rulesetVersion = !!(ruleset?.availableVersions.length);
                    stepIds.done = true;
                    stepIds.notificationSelection = true;
                }
            }else{
                raisePolicyNotFoundErrorMessage();
            }
        };
        if(!policy){
            const querystring = new URLSearchParams(location.search);
            const policyId = querystring.get('policyId');
            if(!policyId){
                raisePolicyNotFoundErrorMessage();
                return;
            }else{
                handlePolicyLoad(policyId);
            }
        }
    }, [selectedRuleset, steps.length, policy, t]);

    const rulesetVersionChanged = (item: ListItem) => {
        setSelectedVersion(item.selectedVersion);
    };
    const getSteps = () => {
        return steps?.filter((step) => step.id && stepIds[step.id]);
    };

    return (
        <div>
            {getSteps().length ? <Wizard
                steps={getSteps()}
                title={t('POLICY.EDIT_POLICY')}
                doneButtonText={t('POLICY.DONE')}
                onClose={()=>finishFlow(returnUrl)} />
                : null}
        </div>
    );
};