import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import IAccount from '../../../user/Account.interface';
import { getUserAccountsAndRoles } from '../../../roles/Roles.service';
import { getStoreService } from 'common/interface/services';

interface RoleState {
    accounts: IAccount[];
}

const initialState: RoleState = {
    accounts: [],
};

export const RoleSlice = createSlice({
    name: 'switch role',
    initialState,
    reducers: {
        setUserRoles: (state: RoleState, action: PayloadAction<any[]>) => {
            state.accounts = action.payload;
        },
        clearSwitchRoleState: () => initialState,
    },
});
export const { setUserRoles, clearSwitchRoleState } = RoleSlice.actions;
export const getUserAccounts = (state: RootState): any[] => state.roleState.accounts;

export const getUserRolesFromServer = async (): Promise<any> => {
    const { dispatch } = getStoreService().getReduxTools();
    try {
        const roles: any[] = await getUserAccountsAndRoles();
        dispatch(setUserRoles(roles));
    } catch (error) {
        console.error(error);
    }
};

export default RoleSlice.reducer;
