import appReducer from './App.reducer';
import { APPLICATION_REDUCERS } from 'common/extensibility/WellKnownPaths';
import { IApplicationReducer } from 'common/interface/redux';

import initializeCommon from './common';
import initializeK8s from './modules/workloads';
import initializeRiskManagement from './modules/riskManagement';
import initializePostureManagement from './modules/postureManagement';
import initializeIdentity from './modules/Identity';
import initializeNetworkSecurity from './modules/NetworkSecurity';
import initializeIntelligence from './modules/Intelligence';
import initializeSettings from './modules/settings';
import initializeAssets from './modules/assets';
import initializeSpectral from './modules/spectral';
import initializeApp from './platform';
import initializeInsight from './modules/Insight';
import initializePolicy from './modules/policy';
import initializeOverview from './modules/overview';
import initializeServices from './common/services/initialize';
import initializeShiftLeft from './modules/shiftLeft/initialize';
import initializeEvents from './modules/events/initialize';
import initializeReporting from './modules/reporting/initialize';
import initializeRemediationModals from './modules/remediation/initialize';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { isNotChina } from 'common/utils/dataCenter';
import { initializeComponenetsI18n } from 'common/design-system/initialize.i18n';
import { initializeI18nIntelligence } from './modules/Intelligence/initialize.i18n';
import { initializeI18nEvents } from './modules/events/initialize.i18n';
import { initializeI18nOverview } from './modules/overview/initialize.i18n';
import { initializeI18nPosture } from './modules/postureManagement/initialize.i18n';
import { initializeI18nReports } from './modules/reporting/initialize.i18n';
import { initializeI18nRiskManagement } from './modules/riskManagement/initialize.i18n';
import { initializeI18nIntegrations } from './modules/settings/Integrations/initialize.i18n';
import { initializeK8sI18n } from './modules/workloads/initialize.i18n';
import { initializeI18nNotifications } from './modules/settings/NotificationsPage/initialize';
import { initializeEnvironmentsTableI18n } from 'modules/assets/Environments/EnvironmentsTable/initialize.i18n';
import { initializeI18Identity } from './modules/Identity/initialize.i18n';
import { initializeI18nPolicy } from './modules/policy/initialize.i18n';
import { initializeAwsEnvI18n } from 'modules/assets/Environments/AwsEnvironment/initialize.i18n';
import { initializeAzureEnvI18n } from 'modules/assets/Environments/AzureEnvironment/initialize.i18n';
import { initializeGcpEnvI18n } from 'modules/assets/Environments/GcpEnvironment/initialize.i18n';
import { initializeAlibabaEnvI18n } from 'modules/assets/Environments/AlibabaEnvironment/initialize.i18n';
import { initializeInsightI18n } from './modules/Insight/initialize.i18n';
import { initializeEventsTableI18n } from 'modules/events/EventsTable/initialize.i18n';
import { initializeOciEnvI18n } from 'modules/assets/Environments/OciEnvironment/initialize.i18n';
import { initializeI18nExclusion } from './modules/exclusion/initialize.i18n';
import initializeExclusion from './modules/exclusion/initialize';

let isInitialized = false;

function initializeReducers() {
    const reducers: IApplicationReducer[] = [
        { name: 'app', reducer: appReducer, isBlackList: true },
    ];
    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');
}

function initializeModules() {
    initializeOverview();
    initializeRiskManagement();
    initializeSettings();
    initializePolicy();
    initializeExclusion();
    initializePostureManagement();
    initializeIdentity();
    initializeReporting();
    initializeAssets();
    initializeEvents();
    initializeApp();
    initializeIntelligence();
    initializeK8s();
    initializeNetworkSecurity();
    initializeInsight();
    initializeRemediationModals();
    if (isNotChina()) {
        initializeSpectral();
        initializeShiftLeft();
    }
}

export function initialize() {
    if (isInitialized) {
        return;
    }
    initializeReducers();
    initializeCommon();
    initializeModules();
    initializeServices();
    isInitialized = true;
}

export async function initializeI18n(){
    await Promise.all([
        initializeI18nExclusion(),
        initializeI18nEvents(),
        initializeI18nIntelligence(),
        initializeI18nOverview(),
        initializeI18nPosture(),
        initializeI18nReports(),
        initializeComponenetsI18n(),
        initializeI18nRiskManagement(),
        initializeI18nIntegrations(),
        initializeI18nNotifications(),
        initializeK8sI18n(),
        initializeEnvironmentsTableI18n(),
        initializeI18Identity(),
        initializeI18nPolicy(),
        initializeAwsEnvI18n(),
        initializeAzureEnvI18n(),
        initializeGcpEnvI18n(),
        initializeAlibabaEnvI18n(),
        initializeInsightI18n(),
        initializeEventsTableI18n(),
        initializeOciEnvI18n()
    ]);
}
