import React, { useEffect } from 'react';
import { getRulesetService } from 'common/interface/services';
import { Vendors } from 'common/consts/vendors';
import { useTranslation } from 'react-i18next';
import { getFixedVendorIcon } from 'common/components/policy/utils';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { MultiSelectListComponent } from 'common/components/policy/MultiSelectList';
import { TextIconVersionItem } from 'common/components/policy/MultiSelectList/ListItemTypes/TextIconVersionItem';

export interface PlatformRulesetSelectionProps{
    onValidate:Function;
    platform?: Vendors;
    selectedRulesetsChanged:Function;
    preSelectedItems?: ListItem[];
}
export const RulesetSelectionComponent: React.FC<PlatformRulesetSelectionProps> = ({ preSelectedItems, onValidate, selectedRulesetsChanged }) => {
    const { t } = useTranslation();

    const[rulesets, setRulesets] = React.useState<ListItem[]|null>(null);

    const init = async () => {
        const allRulesets = await getRulesetService().getAllRulesets();
        const _rulesets = allRulesets.filter((ruleset) => [Vendors.SOURCE_CODE_ASSURANCE, Vendors.TERRAFORM,Vendors.CFT].includes(ruleset.cloudVendor as Vendors));
        const items : ListItem[] = _rulesets?.map((ruleset) => {
            return {
                id: ruleset.id.toString(),
                name: ruleset.name || '',
                icon: getFixedVendorIcon(ruleset.cloudVendor),
                availableVersions: [...ruleset.availableVersions, { version: 'Latest' }],
                versionEnabled: true,
            };
        }) || [];
        setRulesets(items);
    };
    useEffect(() => {
        init();
    }, []);


    const selectedItemsChanged = (selectedItems: ListItem[]) => {
        onValidate(selectedItems?.length);
        selectedRulesetsChanged(selectedItems);
        selectedItems.forEach((item) => {
            const match = rulesets?.find((_ruleset) => _ruleset.id === item.id);
            if(match) {
                match.selectedVersion = item.selectedVersion;
            }
        });
        rulesets && setRulesets([...rulesets]);
    };
    useEffect(() => {
        if(rulesets?.length && preSelectedItems?.length){
            preSelectedItems.forEach((preSelectedItem) => {
                const match = rulesets?.find((_ruleset) => _ruleset.id === preSelectedItem.id);
                if(match) {
                    match.selectedVersion = preSelectedItem.selectedVersion;
                }
            });
            setRulesets([...rulesets]);
        }
    }, [preSelectedItems,rulesets?.length]);

    return (
        <span data-aid={'rulesets-selection'}>
            <MultiSelectListComponent preSelectedItems={preSelectedItems} itemTemplate={ TextIconVersionItem } header={t('GENERAL.SELECT_RULESET')} items={rulesets} onSelectedItemsChanged={selectedItemsChanged}/>
        </span>
    );

};