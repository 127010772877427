import onboardingReducer from './aws/helpers/aws.reducer';
import ociOnboardingReducer from './oci/OciOnboarding.reducer';
import { IApplicationReducer } from 'common/interface/redux';
import { APPLICATION_REDUCERS, REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import AWSOnboarding from './aws/AWSOnboarding';
import OciOnboarding from './oci/OciOnboarding';
import { getIsAWSLegacyView } from '../../App.reducer';
import { getStoreService } from 'common/interface/services';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import AzureOnboarding from './azure/AzureOnboarding';
import AzureOnboardingReducer from './azure/helpers/azure.reducer';
export default function initializeOnboarding() {
    const reducers: IApplicationReducer[] = [
        { name: 'onboarding', reducer: onboardingReducer, isBlackList: false },
        { name: 'ociOnboarding', reducer: ociOnboardingReducer, isBlackList: true },
        { name: 'azureOnboarding', reducer: AzureOnboardingReducer, isBlackList: true },
    ];
    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');

    const onboardingPageRoute = () => {
        const isAWSLegacyView = getIsAWSLegacyView(getStoreService().state);
        return {
            condition: !isAWSLegacyView,
            component: AWSOnboarding,
            path: '/aws',
        };
    };
    const ociOnboardingPageRoute = () => {
        return {
            condition: true,
            component: OciOnboarding,
            path: '/oci-onboarding',
        };
    };
    const azureOnboardingPageRoute = () => {
        return {
            condition: true,
            component: AzureOnboarding,
            path: '/azure-onboarding',
        };
    };
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'Onboarding Page', content: onboardingPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'OCI Onboarding Page', content: ociOnboardingPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'Azure Onboarding Page', content: azureOnboardingPageRoute }]);

}
