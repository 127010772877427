import React, { useCallback, useMemo, } from 'react';
import { TableStyled } from './TopIssues.styled';
import { GridOptions } from 'ag-grid-community';
import { DefaultTextCellRender } from 'common/components/ag-grid/Renderers/DefaultTextCellRender';
import { Table } from 'common/design-system/components-v2';
import { getTopIssuesDashboardColumnDefs } from './TopIssuesColumns';
import { ISSUE_FIELD_NAMES, ITopIssue } from '../../../../Issues/Issues.interface';
import { IFieldInfo } from 'common/interface/general';
import { removeFilterFields } from '../../../../../RiskManagement.utils';
import { IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { getIssuesRouteFields } from '../../../../Issues/Issues.utils';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export const TopIssuesDashboardTable: React.FC<{ items: ITopIssue[], filterDetails: IServerInputFilterDetails }> = ({ items, filterDetails }) => {
    const onClickRuleTitle = useCallback((ruleTitle: string, severityLevel: number) => {
        const issuesRouteFields: IFieldInfo[] | undefined = getIssuesRouteFields(filterDetails);
        const filterFields: IFieldInfo[] = [
            ...(removeFilterFields([ISSUE_FIELD_NAMES.SEVERITY], issuesRouteFields) || []),
            {
                name: ISSUE_FIELD_NAMES.SEVERITY,
                value: String(severityLevel),
            }
        ];
        filterDetails.orgUnitsIds?.forEach((org_unit_id: string) => {
            filterFields.push(
                {
                    name: ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID,
                    value: org_unit_id,
                });
        });
      
        getErmUrlsService().gotoSecurityIssuesTable(filterFields, ruleTitle);
    }, [filterDetails]);

    const gridOptions: GridOptions = useMemo(() => {
        return {
            columnDefs: getTopIssuesDashboardColumnDefs(onClickRuleTitle),
            defaultColDef: { cellRenderer: DefaultTextCellRender },
            rowModelType: 'clientSide',
            domLayout: 'autoHeight',
            rowData: items,
            enableRangeSelection: false,
            suppressCellFocus: true,
        };
    }, [items, onClickRuleTitle]);

    return (
        <TableStyled.TopTableDiv>
            <Table
                gridOptions={gridOptions}
                disableColumnMenu={true}
                disableGrouping={true}
            />
        </TableStyled.TopTableDiv>
    );
};
