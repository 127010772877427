import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IssuesStatus } from './IssuesStatus/IssuesStatus';
import { EnvironmentsStatus } from './EnvironmentsStatus/EnvironmentsStatus';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../consts';
import { LoadingState } from 'common/interface/general';
import { ISummaryStatusData } from './DataSecuritySummaryStatus.interface';
import { fetchSummaryStatusData } from './DataSecuritySummaryStatus.datasource';
import { IssueSeveritiesMap } from '../../../Issues/IssueSeverity';
import { ReadinessStatus } from './ReadinessStatus/ReadinessStatus';
import { SummaryStatus } from 'common/erm-components/custom/SummaryStatus/SummaryStatus';
import { ILevelBoxItem } from 'common/erm-components/custom/LevelBoxes/LevelBox.interface';

export const DataSecuritySummaryStatus: React.FC = () => {
    const { t } = useTranslation(I18nRiskNamespace);
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);
    const [statusData, setStatusData] = useState<ISummaryStatusData>();
    const title: string = useMemo(() => {
        if (!statusData) {
            return '';
        }
        if (statusData.readinessStatus.coveragePercentage < 70) {
            return t('DATA_SECURITY_DASHBOARD.SUMMARY.HEADER.CAN_BE_IMPROVED');
        }
        const severeItems = statusData.issuesStatus.items.filter(
            (item: ILevelBoxItem) => (item.level >= IssueSeveritiesMap.high.level));
        if (severeItems.length > 0) {
            return t('DATA_SECURITY_DASHBOARD.SUMMARY.HEADER.CAN_BE_IMPROVED');
        }
        return t('DATA_SECURITY_DASHBOARD.SUMMARY.HEADER.GREAT_JOB');
    }, [statusData, t]);

    const loadData = useCallback(async () => {
        fetchSummaryStatusData().then((data: ISummaryStatusData) => {
            setStatusData(data);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        }).catch(() => {
            setStatusData(undefined);
            setLoadingState(LoadingState.LOADING_FAILED);
        });
    }, []);

    useEffect(() => {
        void loadData();
    }, [loadData]);
    
    const summaryComponents = useMemo(() => {
        if (!statusData) {
            return [];
        }
        return [
            <ReadinessStatus data={statusData.readinessStatus} key={'readiness'}/>, 
            <IssuesStatus data={statusData.issuesStatus} key={'issues'}/>, 
            <EnvironmentsStatus data={statusData.environmentsStatus} key={'environments'}/>
        ];
    }, [statusData]);

    return (
        <SummaryStatus components={summaryComponents}
            title={title}
            loadingState={loadingState} />
    );
};
