import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ReportsTableRegistry } from 'common/module_interface/reporting/ReportsTableRegistry';
import i18n from 'i18next';
import { i18nReportsNamespace } from '../../initialize.i18n';
import { translations } from '../../Reporting.utils';
import { ReportsFields } from '../../Reporting.const';
import dayjs from 'dayjs';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';

function getColumnDefs(): CGColDef[] {
    const t = i18n.getFixedT(null, i18nReportsNamespace);
    const translatedOptions = translations(t);
    const getReportTypeLabel = (value: string) => translatedOptions.templateOptions.find((opt) => opt.value === value)?.label ?? '';
    return [
        {
            colId: ReportsFields.REPORT_NAME,
            field: ReportsFields.REPORT_NAME,
            headerName: i18n.t('REPORTS.COLUMNS.NAME', { ns: i18nReportsNamespace }),
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 200,
            tooltipField: ReportsFields.REPORT_NAME,
            sortable: true,
            suppressColumnsToolPanel: true,
        },
        {
            colId: ReportsFields.TEMPLATE,
            field: ReportsFields.TEMPLATE,
            headerName: i18n.t('REPORTS.COLUMNS.TEMPLATE', { ns: i18nReportsNamespace }),
            valueFormatter: (event: any) => getReportTypeLabel(event.value),
            tooltipValueGetter: (event: any) => getReportTypeLabel(event.value),
            sortable: true,
        },
        {
            colId: ReportsFields.TIME_RANGE,
            field: ReportsFields.TIME_RANGE,
            tooltipField: ReportsFields.TIME_RANGE,
            headerName: i18n.t('REPORTS.COLUMNS.TIME_RANGE', { ns: i18nReportsNamespace })
        },
        {
            colId: ReportsFields.TARGET,
            field: ReportsFields.TARGET,
            tooltipField: ReportsFields.TARGET,
            headerName: i18n.t('REPORTS.COLUMNS.TARGET', { ns: i18nReportsNamespace }),
            sortable: true
        },
        {
            colId: ReportsFields.NEXT_RUN,
            field: ReportsFields.NEXT_RUN,
            tooltipField: ReportsFields.NEXT_RUN,
            headerName: i18n.t('REPORTS.COLUMNS.NEXT_RUN', { ns: i18nReportsNamespace }),
            valueGetter: (data) => {
                return data.data?.nextRun;
            },
            valueFormatter: (data) => {
                const nextRunValue = data.value;
                return dayjs(nextRunValue).isValid() ? dayjs(nextRunValue).format('MMM D, YYYY h:mm A Z') : EMPTY_STRING;
            },
            sortable: true,
        },
        {
            colId: ReportsFields.LAST_RUN,
            field: ReportsFields.LAST_RUN,
            tooltipField: ReportsFields.LAST_RUN,
            headerName: i18n.t('REPORTS.COLUMNS.LAST_RUN', { ns: i18nReportsNamespace }),
            valueGetter: (data) => {
                return data.data?.lastRun;
            },
            valueFormatter: (data) => {
                const lastRunValue = data.value;
                return dayjs(lastRunValue).isValid() ? dayjs(lastRunValue).format('MMM D, YYYY h:mm A Z') : EMPTY_STRING;
            },
            sortable: true,
        },
    ];
}

export function initializeColumnDefs() {
    ReportsTableRegistry.addColumnDefs(getColumnDefs(), 'colId');
}
