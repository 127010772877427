import { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { MultiChipCellContent } from 'common/erm-components/custom/MultiChipCellContent/MultiChipCellContent';

export const MultiValueChipsCellRender: FC<ICellRendererParams> = (params) => {
    const values: string[] | undefined = params.value;
    if (!values || (values.length === 0)) {
        return null;
    }

    return (
        <MultiChipCellContent propsList={values.map(val => ({
            label: val,
        }))} />
    );
};