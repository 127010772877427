import React, { ReactElement, useMemo } from 'react';
import moment from 'moment-mini';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import PropertyBox from 'common/components/PropertyBox/PropertyBox';
import TextPropertyBox from 'common/components/PropertyBox/TextPropertyBox';
import { FindingSeverityCellComponent } from 'common/components/Findings/FindingSeverityCellComponent';
import { ALink } from 'common/components/ALink';
import { ENTITIES_WITH_PERMISSIONS_TAB } from 'common/module_interface/identity/IdentityConsts';
import { Icon } from 'common/design-system/components-v2';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { generateEntityLink } from 'common/module_interface/intelligence/Intelligence.utils';

const UpperPanel: React.FC<{ finding: IFinding }> = ({ finding }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const formattedDate = moment(finding.eventTime).format('lll');
    const entity = finding.entityName || finding.entityExternalId;
    const logType = finding.additionalFields?.accountActivity ? t('INT_FINDING_OVERVIEW.ACCOUNT_ACTIVITY') :
        (finding.additionalFields?.netwrokActivity ? t('INT_FINDING_OVERVIEW.NETWORK_TRAFFIC') : undefined);

    const severity = useMemo(() => {
        return <FindingSeverityCellComponent severityNameOrLevel={finding.severityName}/>;
    }, [finding]);

    const entityLink = useMemo(() => {
        const tabName = ENTITIES_WITH_PERMISSIONS_TAB.includes(finding.entityType) ? 'permissions' : undefined;
        const entityLink = generateEntityLink(finding.entityType, finding.entityExternalId, finding.cloudAccountId, finding.entityId, tabName);
        if (entityLink) {
            return <ALink href={entityLink} target='_blank' rel="noreferrer">{entity}</ALink>;
        }
        return <div>{entity}</div>;
    }, [entity, finding]);

    const isAcknowledgeContent: ReactElement = useMemo(() => <Icon name={'checkCircle'} color={'success'}/>, []);

    const isExcludedContent: ReactElement = useMemo(() => <Icon name={'groupExclusion'}/>, []);

    return (
        <PropertyBoxesRow>
            <PropertyBox title={t('INT_FINDING_OVERVIEW.SEVERITY')} content={severity}/>
            <TextPropertyBox title={t('INT_FINDING_OVERVIEW.DATE')} value={formattedDate}/>
            {entity && <PropertyBox title={t('INT_FINDING_OVERVIEW.ENTITY')} content={entityLink}/>}
            {logType && <TextPropertyBox title={t('INT_FINDING_OVERVIEW.LOG_TYPE')} value={logType}/>}
            {finding.isAcknowledged && <PropertyBox title={t('INT_FINDING_OVERVIEW.ACK')} content={isAcknowledgeContent}/>}
            {finding.isExcluded && <PropertyBox title={t('INT_FINDING_OVERVIEW.IS_EXCLUDED')} content={isExcludedContent}/>}
        </PropertyBoxesRow>
    );
};

export default UpperPanel;
