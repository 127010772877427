import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getStoreService } from 'common/interface/services';
import { getOrganizationalUnitsNames } from './helpers/azure.service';
import * as actions from './helpers/azure.reducer';
import { Line } from './helpers/azure.styled';
import {
    AzureConfigurationObjectType,
    AzureConfigurationTypes,
    AzureOnboardingType
} from './helpers/azure.interface';
import { Input, Stack, Typography } from 'common/design-system/components-v2';
import { RenderViewParams } from 'common/components/Wizard/Wizard';
import ConfigurationWrapper from '../components/ConfigurationWrapper';
import AWPConfigSection from './components/AWPConfigSection';
import CDRConfigSection from './components/CDRConfigSection';
import AzureSingleOU from './azureSingle/AzureSingleOU';

const AzureConfiguration: React.FC<RenderViewParams> = ({ onValidate }) => {
    const { t } = useTranslation();
    const dispatch = getStoreService().dispatch;
    const [organizationList, setOrganizationList] = useState<string[]>([]);
    const [nameInUseToolTip, setNameInUseToolTip] = useState<string>();
    const formValidations = useSelector(actions.getAzureOrgConfigurationFormValidations);
    const azureOnboardingType = useSelector(actions.getAzureOnboardingType);
    const name = useSelector(actions.getAzureOnboardingName);
    const azureConfigurations = useSelector(actions.getAzureConfigurations);

    const stepTitle = azureOnboardingType === AzureOnboardingType.single ? t('ON_BOARDING.AZURE.NAME') : t('ON_BOARDING.AZURE.NAME_ORG');
    const tooltipContent = azureOnboardingType === AzureOnboardingType.single ? t('ON_BOARDING.AZURE.ACCOUNT_NAME_INFO') : t('ON_BOARDING.AZURE.AZURE_ORG_CONFIG_TOOLTIP');

    const getOrganizationNames = async () => {
        try {
            const ouList = await getOrganizationalUnitsNames();
            setOrganizationList(ouList);
        } catch (e) { /* empty */ }
    };

    useEffect(() => {
        onValidate(formValidations.valid);
        getOrganizationNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValidations]);

    const handleDisplayNameChange = (ev: any) => {
        try {
            const name = ev.target.value;
            if (azureOnboardingType === AzureOnboardingType.org &&
                organizationList && organizationList.includes(name.toLowerCase())) {
                setNameInUseToolTip(t('ON_BOARDING.AZURE.ACCOUNT_NAME_IN_USE'));
            }
            else {
                setNameInUseToolTip(undefined);
                dispatch(actions.setAzureOnboardingName(name));
            }
        } catch (e) {
            setNameInUseToolTip(t('ON_BOARDING.AZURE.ACCOUNT_NAME_INVALID'));
        }
    };
    const handleConfigActivation = (isEnabled: boolean, key: AzureConfigurationTypes) => {
        const payload = {
            key,
            value: { ...azureConfigurations[key] || {}, isEnabled: isEnabled },
        };
        dispatch(actions.setAzureConfigurations(payload));
    };
    const handleConfigurationsChange = (key: AzureConfigurationTypes, config: AzureConfigurationObjectType) => {
        const payload = {
            key,
            value: config || azureConfigurations[key]
        };
        dispatch(actions.setAzureConfigurations(payload));
    };


    return (
        <Stack data-aid="azure-onboarding-configuration-wrapper">
            <Typography variant='h1'>{t('ON_BOARDING.AZURE.CONFIGURATION_TITLE')}</Typography>
            <Stack margin={[5,0,0,0]} style={{ width: '50%' }}>
                <Input
                    data-aid='azure-onboarding-display-name-input'
                    label={stepTitle}
                    value={name}
                    onChange={handleDisplayNameChange}
                    tooltip={tooltipContent}
                    isError={!!nameInUseToolTip}
                    helperText={nameInUseToolTip}
                    fullWidth
                    clearable
                />
            </Stack>
            <Stack margin={[5,0]}>
                <Line></Line>
            </Stack>
            { azureOnboardingType === AzureOnboardingType.org && (
                <Stack direction="column" fullWidth>
                    <ConfigurationWrapper
                        dataAid="cspm-configuration"
                        title={t('ON_BOARDING.AZURE.CSPM')}
                        description={t('ON_BOARDING.AZURE.CSPM_DESCRIPTION')}
                        type={AzureConfigurationTypes.cspm}
                        isEnabled={true}
                        isDisabled={true}
                    />
                    <ConfigurationWrapper
                        dataAid="cdr-configuration"
                        title={t('ON_BOARDING.AZURE.CDR')}
                        description={t('ON_BOARDING.AZURE.CDR_DESCRIPTION')}
                        type={AzureConfigurationTypes.cdr}
                        isEnabled={azureConfigurations.cdr?.isEnabled || false}
                        onChange={handleConfigActivation}>
                        <CDRConfigSection onChange={(config: AzureConfigurationObjectType) => handleConfigurationsChange(AzureConfigurationTypes.cdr, config)} initialValue={azureConfigurations.cdr} />
                    </ConfigurationWrapper>
                    <ConfigurationWrapper
                        dataAid="awp-configuration"
                        title={t('ON_BOARDING.AZURE.AWP')}
                        description={t('ON_BOARDING.AZURE.AWP_DESCRIPTION')}
                        type={AzureConfigurationTypes.awp}
                        isEnabled={azureConfigurations.awp?.isEnabled || false}
                        onChange={handleConfigActivation}>
                        <AWPConfigSection onChange={(config: AzureConfigurationObjectType) => handleConfigurationsChange(AzureConfigurationTypes.awp, config)} formValidations={formValidations} initialValue={azureConfigurations.awp} />
                    </ConfigurationWrapper>
                </Stack>
            )}
            { azureOnboardingType === AzureOnboardingType.single && (
                <Stack spacing={3}>
                    <ConfigurationWrapper
                        title={t('ON_BOARDING.OCI.OU_TITLE')}
                        description={t('ON_BOARDING.OCI.OU_TEXT')}
                        type={AzureConfigurationTypes.ou}
                        isSwitchHidden={true}
                        isEnabled={true}>
                        <AzureSingleOU />
                    </ConfigurationWrapper>
                </Stack>
            )}
        </Stack>
    );
};

export default AzureConfiguration;
