import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Alert, Typography, Stack, Button } from 'common/design-system/components-v2';
import { Trans } from 'react-i18next';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { changeUrl } from 'common/utils/http';
import { useSessionStorage } from 'common/hooks/useSessionStorage';
import { getUserService } from 'common/interface/services';
import { newEventsPageCurrentView, newEventsPageFeatureFlagName } from 'common/interface/events';

const EventsPageSwitchView: React.FC = () => { // TODO: remove when old events page is removed
    const [newEventsView, setNewEventsView] = useLocalStorage<boolean>(true, newEventsPageCurrentView);
    const [hideView, setHideView] = useSessionStorage<boolean>(false, 'events-page-view-hide');
    const switchViewAnalyticsPrefix = `events-page-switch-view-to-${newEventsView ? 'old' : 'new'}`;
    const isNewEventsActive = getUserService().hasPermission([newEventsPageFeatureFlagName]);

    const switchView = () => {
        const searchParams = new URLSearchParams(window.location.search);
        setNewEventsView(current => !current);
        changeUrl(newEventsView ? `/alerts/findings?${searchParams}` : `/alerts/findings-new?${searchParams}`);
        window.location.reload();
    };

    if (hideView || !isNewEventsActive) return null;
    
    return (

        <Switch>
            <Route path={['/alerts/findings', '/alerts/findings-new']}>
                <Stack fullWidth padding={[2, 4, 0, 4]}>
                    <Alert type='info' hideStatusIcon withCloseButton onClose={() => setHideView(true)}>
                        <Typography>
                            <Trans
                                i18nKey={`EVENTS_VIEW.SWITCH_TO_${newEventsView ? 'OLD' : 'NEW'}`}
                                components={{
                                    button: <Button variant='text' removePadding disableHoverEffect color='brandPrimary' dataAid={switchViewAnalyticsPrefix} onClick={switchView} />
                                }}
                            />
                        </Typography>
                    </Alert>
                </Stack>
            </Route>
        </Switch>
    );
};

export default EventsPageSwitchView;
