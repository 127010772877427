/* eslint-disable react-hooks/exhaustive-deps */
import { CpIcon, CpSecondaryMessage, CpSpinner } from '@dome9/components/react/components';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getStoreService } from 'common/interface/services';
import './AWSOnboarding.scss';
import CftModal from '../components/CftModal';
import { timoutIsTakingLongerThenExpectedAccount } from './helpers/aws.actions';
import { AWS_INTERVALS } from './helpers/aws.const';
import { ConfigWrapper } from './helpers/aws.styled';
import { getCloudAccountById } from './helpers/aws.service';
import {
    getAwsCftPopupOpen,
    getAwsCfts,
    getAwsOnboardingStarted,
    getAwsOnboardingUrl,
    getIsManagementOnboarded,
    getIsOrgSelected,
    getIsTakingLongerThanExpectedAccount,
    getManagementAccountId,
    getShowAwsLoader,
    getShowPostureLoader,
    setAwsCftPopupOpen,
    setUserClickedLaunchStack,
    setManagementAccountDetails,
} from './helpers/aws.reducer';
import { Button } from '@dome9/berries/react-components';
import { BtnWrapper } from '../oci/OciOnboarding.styled';
import { Alert, Stack, Label, Typography } from 'common/design-system/components-v2';
import { getCloudAccountsService } from 'common/interface/data_services';
import { checkForDisabledBlades, isOrgOnboardingValidation } from './helpers/aws.validations';

const AWSOnboardingAccount: FC = () => {
    const { dispatch } = getStoreService().getReduxTools();
    const { t } = useTranslation();
    const [isShowCDRAlert, setIsShowCDRAlert] = useState(false);
    const cfts = useSelector(getAwsCfts);
    const onboardingUrl = useSelector(getAwsOnboardingUrl);
    const isManagementOnboarded = useSelector(getIsManagementOnboarded);
    const popupOpen = useSelector(getAwsCftPopupOpen);
    const showAwsLoader = useSelector(getShowAwsLoader);
    const showPostureLoader = useSelector(getShowPostureLoader);
    const isOnboardingStarted = useSelector(getAwsOnboardingStarted);
    const isTakingLongerThanExpectedAccount = useSelector(getIsTakingLongerThanExpectedAccount);
    const isOrgOnboarding = useSelector(getIsOrgSelected);
    const managementId = useSelector(getManagementAccountId);

    useEffect(() => {
        if (isManagementOnboarded && managementId) showCDRAlert();
    }, [isManagementOnboarded, managementId]);
    const showDisabledBladesAlert = () => {
        const disabledBlades = onboardingUrl ? checkForDisabledBlades(onboardingUrl as string) : [];
        return disabledBlades.length > 0;
    };
    const showCDRAlert = async () => {
        if (isManagementOnboarded && onboardingUrl?.indexOf('CDR') === -1) {
            const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            if (allCloudAccounts?.length) {
                const cloudAccount = allCloudAccounts.find((account) => account.externalId === managementId);
                const { data: cloudAccountDetails } = await getCloudAccountById(cloudAccount?.id as string);
                if (!isOrgOnboardingValidation(cloudAccountDetails)){
                    dispatch(setManagementAccountDetails(cloudAccountDetails));
                    setIsShowCDRAlert(true);
                    return;
                }
            }
        }
        setIsShowCDRAlert(false);
    };
    const renderAlertContent = () => {
        let alertContent = '';
        const disabledBlades = onboardingUrl ? checkForDisabledBlades(onboardingUrl as string) : [];
        if (disabledBlades.length) {
            alertContent = t('ON_BOARDING.ACCOUNT.ORG.ALERT_COMPLIANCE_STACK_WARNING', { disabledBlades: disabledBlades.join(', ') });
        } else if (isShowCDRAlert) {
            alertContent = t('ON_BOARDING.ACCOUNT.ORG.ALERT_COMPLIANCE_CDR_WARNING');
        }
        if (disabledBlades.length && isShowCDRAlert) {
            alertContent = t('ON_BOARDING.ACCOUNT.ORG.ALERT_COMPLIANCE_AND_STACK_WARNING', { disabledBlades: disabledBlades.join(', ') });
        }
        return <Label size="lg" color="inherit" text={alertContent} whiteSpace="pre-line" />;
    };

    return (
        <div
            className='aws-onboarding-account-wrapper'
            data-aid='account-cft-modal'
        >
            {
                cfts &&
                <CftModal
                    open={popupOpen}
                    onClose={() => {
                        dispatch(setAwsCftPopupOpen(false));
                    }}
                    title={t('ON_BOARDING.ACCOUNT.MODAL_TITLE')}
                    cfts={cfts}
                />
            }
            {
                isOrgOnboarding ?
                    <div className={'step-title'}>
                        {t('ON_BOARDING.ACCOUNT.ORG.CREATE_STACK_STEPS')}
                    </div>
                    :
                    <div className='title-large'>
                        {t('ON_BOARDING.ACCOUNT.SETUP_YOUR_CLOUD_ACCOUNT')}
                    </div>
            }

            <div className={'padder'}>
                <ul className={'sub-class'}>
                    <li className='text-spacing'>
                        {t('ON_BOARDING.ACCOUNT.REVIEW')}
                        <span
                            onClick={() => {
                                dispatch(setAwsCftPopupOpen(true));
                            }}
                            data-aid='cft-button'
                            className='aws-onboarding-account-configurations-list-cft pointer'>
                            {t('ON_BOARDING.ACCOUNT.CFT_TEMPLATE')}
                        </span>
                        {t('ON_BOARDING.ACCOUNT.RECOMMENDED')}
                    </li>
                </ul>
                {cfts && onboardingUrl ? (
                    <Stack margin={[5,0,0,0]}>
                        <ConfigWrapper>
                            <div className='aws-onboarding-account-configurations-header'>
                                <div className='flex-center'>
                                    <CpIcon icon='aws' className='aws-onboarding-account-configurations-header-icon' />
                                    <div className='title aws-onboarding-account-configurations-header-title'>
                                        <div>{t('ON_BOARDING.ACCOUNT.AWS_CONFIGURATIONS')}</div>
                                    </div>
                                </div>
                                {showAwsLoader && !isOnboardingStarted && <CpSpinner className={'spinner-position'} />}
                                {isOnboardingStarted && <CpIcon icon={'success'} color={'green'} />}
                            </div>
                            <div>
                                <Stack padding={[2,0,0,0]}>
                                    { (isShowCDRAlert || showDisabledBladesAlert()) && (<Stack direction="row" padding={[2,4]}>
                                        <Alert type={'warning'}>
                                            <Stack spacing={1}>
                                                <Typography>{t('ON_BOARDING.ACCOUNT.ORG.IMPORTANT')}</Typography>
                                                {renderAlertContent()}
                                            </Stack>
                                        </Alert>
                                    </Stack>)}
                                </Stack>
                                <ol className='aws-onboarding-account-configurations-list text-body text-body high-line list-decimal list-inside my-4 mx-8'>
                                    <li>
                                        {t('ON_BOARDING.ACCOUNT.CLICK')}
                                        <a
                                            href={onboardingUrl}
                                            target='_blank'
                                            rel='noreferrer'
                                            data-aid={`${isOrgOnboarding ? 'org-' : ''}account-launch-stack`}
                                        >
                                            <BtnWrapper>
                                                <Button
                                                    label={'Launch Stack'}
                                                    onClick={() => {
                                                        dispatch(setUserClickedLaunchStack(true));
                                                        timoutIsTakingLongerThenExpectedAccount(
                                                            AWS_INTERVALS.IS_TAKING_LONGER_THAN_EXPECTED_ACCOUNT,
                                                        );
                                                    }}
                                                    className='lunch-stack_btn'
                                                >
                                                </Button>
                                            </BtnWrapper>
                                        </a>
                                        {t('ON_BOARDING.ACCOUNT.TO_START_STACK')}
                                    </li>
                                    <li>
                                        {t('ON_BOARDING.ACCOUNT.SELECT_THE_OPTION')}
                                        <span className='text-body-bold'>{t('ON_BOARDING.ACCOUNT.I_ACKNOWLEDGE_AWS')}</span>
                                    </li>
                                    <li>
                                        <Trans components={{ b: <strong /> }}>
                                            {t('ON_BOARDING.ACCOUNT.CLICK_CREATE')}
                                        </Trans>
                                    </li>
                                </ol>
                            </div>
                            <div className='aws-onboarding-posture-management-reset-button'>
                                {showPostureLoader ? (
                                    showAwsLoader && isTakingLongerThanExpectedAccount ? (
                                        <CpSecondaryMessage
                                            className='aws-onboarding-posture-management-reset-button'
                                            centerMessage={false}
                                            type={'warning'}>
                                            <span>{t('ON_BOARDING.ACCOUNT.TAKING_LONGER_THAN_EXPECTED')}</span>
                                        </CpSecondaryMessage>
                                    ) : (
                                        <CpSecondaryMessage
                                            className='aws-onboarding-posture-management-reset-button'
                                            centerMessage={false}
                                            type={'info'}>
                                            <span>{t('ON_BOARDING.ACCOUNT.WAIT_FOR_STACK_TO_DEPLOY')}</span>
                                        </CpSecondaryMessage>
                                    )
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </ConfigWrapper>
                    </Stack>
                ) : (
                    <div className='loading'>
                        <CpSpinner />
                        <span> {t('ON_BOARDING.LOADING_CONFIGURATION')}</span>
                    </div>
                )}
            </div>
        </div>
    );
};
export default AWSOnboardingAccount;
