import React from 'react';
import { Card } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace, } from '../../../../../consts';
import { WAFImpactStyled } from './WAFImpact.styled';
import { LoadingState } from 'common/interface/general';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import i18n from 'i18next';
import ColumnChart from 'common/components/Highcharts/ColumnChart';
import { getRiskLevelInfo, RiskLevelEnum } from 'common/module_interface/RiskManagement/RiskLevel';
import { useTheme } from 'styled-components';
import { IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import { WAFDistributionProtection } from '../../../../WafProtection/WafProtection';
import { IWAFImpact } from '../WAF.interface';
import { capitalizeWords } from 'common/utils/helpFunctions';
import WAFFiltersAlert from '../WAFFiltersAlert/WAFFiltersAlert';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';

const WAFImpact: React.FC<{ loadingState: LoadingState,
    impactData?: IWAFImpact, filterValues?: IFiltersValues }> = ({ impactData, loadingState, filterValues }) => {

        const { t } = useTranslation(I18nRiskNamespace);
        const theme = useTheme();
        const items = impactData?.riskItems || [];

        const getData = (risk: RiskLevelEnum, items: IGenericWidgetDataItem[], categories: WAFDistributionProtection[]): number[] => {
            const dataToReturn: number[] = [];
            categories.forEach((category) => {
                const found = items.find(item => item.key === risk && item.category === category);
                if (found?.value !== undefined) {
                    dataToReturn.push(Number(found.value));
                }
                else {
                    dataToReturn.push(0);
                }
            });
            return dataToReturn;
        };

        function formatter (label: { value: WAFDistributionProtection }) {
            let title;
            let details;
            switch (label.value) {
                case WAFDistributionProtection.NO_WAF :
                    title = t('RISK_MANAGEMENT.DASHBOARD.WAF.WAF_PROTECTION_IMPACT.NO_PROTECTION.TITLE');
                    details = t('RISK_MANAGEMENT.DASHBOARD.WAF.WAF_PROTECTION_IMPACT.NO_PROTECTION.DETAILS');
                    break;
                case WAFDistributionProtection.WITH_WAF:
                    title = t('RISK_MANAGEMENT.DASHBOARD.WAF.WAF_PROTECTION_IMPACT.FULL_PROTECTION.TITLE');
                    details = t('RISK_MANAGEMENT.DASHBOARD.WAF.WAF_PROTECTION_IMPACT.FULL_PROTECTION.DETAILS');
                    break;
                case (WAFDistributionProtection.CURRENT):
                    title = t('RISK_MANAGEMENT.DASHBOARD.WAF.WAF_PROTECTION_IMPACT.PARTIAL_PROTECTION.TITLE');
                    details = t(
                        'RISK_MANAGEMENT.DASHBOARD.WAF.WAF_PROTECTION_IMPACT.PARTIAL_PROTECTION.DETAILS',
                        { coveragePercents: (impactData?.coverage || 0).toFixed(1) }
                    );
                    break;
                default:
                    title = '';
                    details = '';
                    break;
            }
            return (
                `<div style="
                background-color:${theme.palette.surface.brandLight};
                display: flex;
                flex-direction: column;
                border-radius: 5px;
                padding: 10px 14px;
                gap: 8px;
                font-size: 12px;
                color:${theme.palette.text.normal};
             ">
                <div style="font-weight: 550">${title}</div>
                <div>${details}</div>
            </div>`
            );
        }

        const categories: WAFDistributionProtection[] = Object.values(WAFDistributionProtection);

        const customOptions = {
            series:
            [ {
                name: capitalizeWords(RiskLevelEnum.critical),
                data: getData(RiskLevelEnum.critical, items, categories),
                pointPlacement: 0.145,
                allowPointSelect: false,
                color: getRiskLevelInfo(RiskLevelEnum.critical)?.color,
            },
            {
                name: capitalizeWords(RiskLevelEnum.high),
                data: getData(RiskLevelEnum.high, items, categories),
                pointPlacement: 0.048,
                allowPointSelect: false,
                color: getRiskLevelInfo(RiskLevelEnum.high)?.color,
            },
            {
                name: capitalizeWords(RiskLevelEnum.medium),
                data: getData(RiskLevelEnum.medium, items, categories),
                pointPlacement: -0.048,
                allowPointSelect: false,
                color: getRiskLevelInfo(RiskLevelEnum.medium)?.color,
            },
            {
                name: capitalizeWords(RiskLevelEnum.low),
                data: getData(RiskLevelEnum.low, items, categories),
                pointPlacement: -0.145,
                allowPointSelect: false,
                color: getRiskLevelInfo(RiskLevelEnum.low)?.color,
            }],
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                style: {
                    fontFamily: 'Verdana, sans-serif',
                    fontSize: '12px',
                },
                pointCount: 1,
            },
            legend: {
                enabled: true,
                itemStyle: {
                    fontStyle: 'normal',
                    fontWeight: 500,
                },
            },
            credits: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    borderRadius: 3,
                    pointWidth: 12,
                    allowPointSelect: true,
                },
            },
            xAxis: {
                type: 'category',
                categories: categories,
                labels: {
                    useHTML: true,
                    formatter: formatter,
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
                gridLineDashStyle: 'Dash',
            },
            title: {
                align: 'left',
                style: {
                    fontSize: '4px',
                },
                text: '',
            },
            subtitle: {
                text: t('RISK_MANAGEMENT.DASHBOARD.WAF.WAF_PROTECTION_IMPACT.CHART_TITLE',{ assets: impactData?.totalCount }),
                align: 'left'
            },
            tooltip: {
                headerFormat: '',
            }
        };

        return(impactData && 
            <Card title={t('RISK_MANAGEMENT.DASHBOARD.WAF.WAF_PROTECTION_IMPACT.TITLE')} fullWidth>
                <WAFImpactStyled.TopDiv>
                    <WAFImpactStyled.AlertDiv>
                        <WAFFiltersAlert filterValues={filterValues} />
                    </WAFImpactStyled.AlertDiv>
                    {loadingState === LoadingState.IS_LOADING && <FullSizeSpinner />}
                    {loadingState === LoadingState.LOADING_SUCCEEDED && (
                        <WAFImpactStyled.ContentDiv>
                            <ColumnChart
                                data={{ items: items }}
                                customOptions={customOptions}
                            />
                        </WAFImpactStyled.ContentDiv>
                    )}
                    {loadingState === LoadingState.LOADING_FAILED &&
                    <WAFImpactStyled.FailureDiv fullWidth fullHeight>
                        {i18n.t('COMMON.ERROR_OCCURRED')}
                    </WAFImpactStyled.FailureDiv>
                    }
                </WAFImpactStyled.TopDiv>
            </Card>
        );
    };

export default WAFImpact;