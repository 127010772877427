import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const ButtonBar = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const EnvStyle = {
    ButtonBar,
};
