import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon, Link, Stack, Typography } from 'common/design-system/components-v2';
import { AzureOnboardingStep, IconWrapper, SummaryWrapper } from '../../AzureIntelligenceOnboarding.styled';
import { AssetType } from '../../AzureIntelligenceOnboarding.const';
import { getAzureIntelligenceType, getIsLoading, getOnboardingStatus } from '../../AzureIntelligenceOnboarding.reducer';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';

const Summary: FunctionComponent = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });

    const onboardingStatus = useSelector(getOnboardingStatus);
    const isLoading = useSelector(getIsLoading);
    const assetType = useSelector(getAzureIntelligenceType);

    const [summaryText, setSummaryText] = useState('');

    useEffect(() => {
        if (!isLoading) {
            if (assetType === AssetType.activityLogs) {
                setSummaryText(onboardingStatus ? t('ACCOUNT_ACTIVITY.SUMMARY.SUCCESS') : t('ACCOUNT_ACTIVITY.SUMMARY.FAILURE'));
            } else {
                setSummaryText(onboardingStatus ? t('NETWORK_TRAFFIC.SUMMARY.SUCCESS') : t('NETWORK_TRAFFIC.SUMMARY.FAILURE'));
            }
        }
    }, [onboardingStatus, isLoading, t, assetType]);

    return (
        <AzureOnboardingStep>
            <SummaryWrapper status={onboardingStatus} showBackground={!isLoading} fullHeight fullWidth alignItems='center' justifyContent='center'>
                {!isLoading && <Stack justifyContent='center' alignItems='center'>
                    <IconWrapper padding={6} status={onboardingStatus}>
                        <Icon 
                            name={onboardingStatus ? 'checkCircle' : 'error'}
                            color={onboardingStatus ? 'success' : 'alert'}
                            size={48}
                        />
                    </IconWrapper>
                    <Stack padding={3}>{summaryText}</Stack>
                    {!onboardingStatus && (
                        <Stack direction='row' spacing={1}>
                            <Typography variant='lg' color='alert' elementType='span'>
                                {t(`${assetType === AssetType.activityLogs ? 'ACCOUNT_ACTIVITY' : 'NETWORK_TRAFFIC'}.SUMMARY.TRY_AGAIN`)}
                            </Typography>
                            <Link externalUrl='https://www.checkpoint.com/support-services/contact-support/' removeExternalIcon>
                                <Typography textDecoration='underline' variant='lg' color='alert' elementType='span'>
                                    {t(`${assetType === AssetType.activityLogs ? 'ACCOUNT_ACTIVITY' : 'NETWORK_TRAFFIC'}.SUMMARY.SUPPORT_TEAM`)}
                                </Typography>
                            </Link>
                        </Stack>
                    )}
                </Stack>}
            </SummaryWrapper>
        </AzureOnboardingStep>
    );
};

export default Summary;
