import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';

const TooltipCellRenderer: React.FC<ICellRendererParams & { tooltip?: string }> = (params) => {
    const { tooltip } = params;
    const options: IIconLinkCellProps = {
        isLink: false,
        value: params.value ?? EMPTY_STRING,
        tooltip: tooltip,
    };
    return <IconLinkCellRenderer {...options} />;
};

export default TooltipCellRenderer;
