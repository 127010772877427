import styled from 'styled-components';
const SpinnerDiv = styled.div<{ alignLeft?: boolean, alignTop?: boolean }>`
  display: flex;
  align-items: center;
  align-items: ${prop => prop.alignTop ? 'start' : 'center'};
  height: 100%;
  width: 100%;
  justify-content: ${prop => prop.alignLeft ? 'left' : 'center'};
  margin: 10px 5px;
`;


const ErrorDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: red;
`;


export const RiskStyled = {
    SpinnerDiv,
    ErrorDiv,
};
