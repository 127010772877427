import {
    DashboardWidgetTypes,
    IDashboard,
} from 'common/module_interface/overview/Interface';
import { v4 } from 'uuid';
import { LOCAL_GCP_DEFAULT_DASHBOARD } from '../Consts';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';


export default function initializeGCPDefaultDashboard() {
    const gcpDefaultDashboard: IDashboard = {
        id: LOCAL_GCP_DEFAULT_DASHBOARD,
        name: LOCAL_GCP_DEFAULT_DASHBOARD,
        isLocalDashboard: true,
        sections: [
            {
                isExpanded: true,
                id: 'inventory',
                title: 'INVENTORY',
                dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                position: 1,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Summary,
                        'title': 'Inventory',
                        'limit': 10,
                        'description': 'Total Protected Assets',
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'platform',
                                'value': 'google'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 1
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m',
                            'thresholds': []
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Protected assets by Environment',
                        'limit': 10,
                        'description': 'Total Protected Assets',
                        'hideOnNoData': false,
                        'aggregation': 'cloudAccountId',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'platform',
                                'value': 'google'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Top Protected Assets by type',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'type',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'platform',
                                'value': 'google'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Pie,
                        'title': 'Protected assets by region',
                        'limit': 5,
                        'description': 'Total Protected Assets',
                        'hideOnNoData': false,
                        'aggregation': 'region',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'platform',
                                'value': 'google'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    }
                ]
            },
            {
                id: 'postureManagement',
                title: 'POSTURE MANAGEMENT',
                dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                position: 2,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Gauge,
                        'title': 'System ruleset score',
                        'limit': 5,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Compliance',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'compliance': [
                                {
                                    'type': 'Organizational Unit',
                                    'selected': DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    'selectedName': 'Organizational Unit',
                                    'rulesetName': 'GCP Dashboard System Ruleset',
                                    'rulesetId': -65,
                                    'platform': 'Organizational Unit'
                                }
                            ],
                            'navigateOption': 'Compliance',
                            'binSize': '10m',
                            'thresholds': [
                                {
                                    'min': 0,
                                    'max': 45,
                                    'color': '#F24E4E',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ],
                                    'disableColorPicker': true
                                },
                                {
                                    'min': 46,
                                    'max': 70,
                                    'color': '#FFD546',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ],
                                    'disableColorPicker': true
                                },
                                {
                                    'min': 71,
                                    'max': 0,
                                    'color': '#56B40A',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ],
                                    'disableColorPicker': true
                                }
                            ]
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Assets with most high severity alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 10
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Latest,
                        'title': 'Latest high and critical alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'ruleName',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 10
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Environments with high severity alerts',
                        'limit': 10,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'cloudAccountId_calc',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 10
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'External findings',
                        'limit': 10,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'ruleName',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 10
                            },
                            {
                                'name': 'origin',
                                'value': '102'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Compute Alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '10|VMInstance'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|instance'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|Instance'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '7|VirtualMachine'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 10
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Storage with high severity alerts',
                        'limit': 50,
                        'description': 'High risks on storage',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|S3Bucket'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '7|StorageAccount'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|S3'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '10|StorageBucket'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 10
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_GCP_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Entities with encryption and key management alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 10
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            },
                            {
                                'name': 'free-text',
                                'value': 'Encryption and Key Management'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    }
                ]
            }
        ]
    };

    return gcpDefaultDashboard;

}