import { Button, Modal } from '@dome9/berries/react-components';
import { CpIcon, CpSecondaryMessage } from '@dome9/components/react/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Stack, Typography } from 'common/design-system/components-v2';

const SuccessIcon = styled(CpIcon).attrs(() => ({
    icon: 'success'
}))`
    font-size: 40px;
    fill: #56B00F;
    margin: auto;
    display: block;
`;

interface SuccessModalProps {
    open: boolean; 
    onClose: () => void; 
    text: string;
    isOrgOnboarding:boolean;
}

const SuccessModal: FC<SuccessModalProps> = ({ open, onClose, text, isOrgOnboarding }) => {
    const { t } = useTranslation();
    return <div>
        <Modal.ModalDialog 
            isOpen={open} 
            onRequestClose={onClose} 
            width='md' 
            shouldCloseOnOverlayClick
        >
            <Modal.ModalHeader
                title={''}
                onClose={onClose}
                dataAid="success"
            />
            {isOrgOnboarding ?
                <Modal.ModalContent>
                    <Stack justifyContent='center' alignItems='center' fullWidth padding={[2,0]}>
                        <Stack justifyContent='center' alignItems='center' fullWidth >
                            <SuccessIcon />
                        </Stack>
                        <Stack justifyContent='center' alignItems='center' fullWidth padding={[5,0,0,0]} >
                            <Typography variant="subtitleLg">{text}</Typography>
                            <Stack justifyContent='center' alignItems='center' fullWidth padding={[2,0,0,0]} >
                                <CpSecondaryMessage type='info'>
                                    {t('ON_BOARDING.AZURE.TAKE_TIME')}
                                </CpSecondaryMessage>
                            </Stack>
                        </Stack>
                    </Stack>
                </Modal.ModalContent>
                :
                <Modal.ModalContent>
                    <Stack justifyContent='center' alignItems='center' fullWidth padding={[2,0]}>
                        <Stack justifyContent='center' alignItems='center' fullWidth >
                            <SuccessIcon />
                        </Stack>
                        <Stack justifyContent='center' alignItems='center' fullWidth padding={[5,0,0,0]} >
                            <Typography variant="subtitleLg">{text}</Typography>
                        </Stack>
                    </Stack>
                </Modal.ModalContent>
            }
            <Modal.ModalFooter>
                <div className='modal__footer-actions'>
                    <Button 
                        className='btn--min-width' 
                        dataAid='success-modal-close'
                        onClick={onClose}
                    >
                        {t('COMMON.CLOSE')}
                    </Button>
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    </div>;
};

export default SuccessModal;