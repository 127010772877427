import React, { useEffect, useState } from 'react';
import { Input, Stack, Typography, Alert, IconButton } from 'common/design-system/components-v2';
import {
    TeamsWebhookUrlWrapper,
    TeamsNameWrapper,
    TeamsAlertWrapper
} from './TeamsComponent.styled';
import { useTranslation } from 'react-i18next';
import {
    I18nIntegrations,
    SUBMIT_STATUS_RESPONSE,
    URL_ENDPOINT_PROTOCOL
} from 'common/module_interface/settings/integrations/consts';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import i18n from 'common/services/translations/translations';

interface IGenericConfigurationProps extends IConfigurationContainerProps{
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration{
    configurationObj: ITeamsConfigurationObj
}

export interface ITeamsConfigurationObj {
    Url: string,
    type: string,
}
export const TeamsSpecialMessage = () => {
    const content = i18n.t('CONFIGURATIONS.TEAMS.SPECIAL_MESSAGE', { ns: I18nIntegrations });
    const url = 'https://devblogs.microsoft.com/microsoft365dev/retirement-of-office-365-connectors-within-microsoft-teams/';
    return (
        <Stack direction={'row'} alignItems={'center'}>
            <Typography variant='body500'>
                {content}
            </Typography>
            <IconButton
                iconProps={{ name: 'externalLinkInline', }}
                onClick={() => window.open(url, '_blank')}
            />
        </Stack>
    );
};

const TeamsComponent : React.FC<IGenericConfigurationProps> = ({ onConfigurationChangeCallBack ,configuration, onConfigurationSaved, viewMode }) => {
    const { t } = useTranslation( I18nIntegrations );
    const urlFromConfig = configuration?.configurationObj?.Url?.replace(/^https?:\/\//, '') || '';

    const [teamsNameConfig, setTeamsNameConfig] = useState<string>(configuration?.name || '');
    const [teamsWebhookUrlConfig, setTeamsWebhookUrlConfig] = useState<string>(urlFromConfig);
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [teamsTestButtonError, setTeamsTestButtonError] = useState<string>('');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);

    const discardChangesButtonHidden = Boolean(idConfiguration);
    const viewOnly = Boolean(viewMode);

    useEffect(() => {
        checkChangesMade();
    }, [teamsNameConfig,
        teamsWebhookUrlConfig,
        idConfiguration]);

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        try {
            setTeamsTestButtonError('');
            const resp = onConfigurationSaved && await onConfigurationSaved(teamsNameConfig,
                { Url: URL_ENDPOINT_PROTOCOL + teamsWebhookUrlConfig }, idConfiguration);
            resp?.id && setIdConfiguration(resp.id);
            setResponseMessageSuccess(true);
            onConfigurationChangeCallBack && onConfigurationChangeCallBack();
            setIsButtonDisabled(true);
            return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
        } catch (error:any) {
            setResponseMessageSuccess(false);
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
        }
    };

    const checkMissingRequiredInput = () => {
        const variableNotChanged = (
            teamsNameConfig === '' ||
            teamsWebhookUrlConfig === ''
        );
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected = (
            teamsNameConfig !== configuration?.name ||
            teamsWebhookUrlConfig !== urlFromConfig
        );
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const handleDiscardChanges = () => {
        setTeamsNameConfig(configuration?.name || '');
        setTeamsWebhookUrlConfig(urlFromConfig);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTeamsTestButtonError('');
        const textInput = event.target.value;
        setTeamsNameConfig(textInput);
    };
    const handleWebhookUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTeamsTestButtonError('');
        const textInput = event.target.value;
        const domain = textInput.replace(/^https?:\/\//, '');
        setTeamsWebhookUrlConfig(domain);
    };

    return (
        <Stack fullWidth={true} data-aid={'teams-component'}>
            <TeamsAlertWrapper>
                <Alert
                    type={'info'}
                >
                    <Stack spacing={1}>
                        <Typography variant="body500">{t('GENERAL.IMPORTANT')}</Typography>
                        <Typography variant="bodyLg">
                            {t('CONFIGURATIONS.TEAMS.TEAMS_RATE_LIMIT_MSG')}
                        </Typography>
                    </Stack>

                </Alert>
            </TeamsAlertWrapper>
            <TeamsNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input type="text" data-aid='nameInput' value={teamsNameConfig} placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange} autoFocus={true} disabled={viewOnly}/>
            </TeamsNameWrapper>
            <TeamsWebhookUrlWrapper>
                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.TEAMS.TEAMS_WEBHOOK_URL')}</Typography>
                <Input type="text" data-aid='urlInput' startAdornment={<div>{URL_ENDPOINT_PROTOCOL}</div>} onChange={handleWebhookUrlChange} fullWidth={true}
                    value={teamsWebhookUrlConfig} isError={Boolean(teamsTestButtonError)} helperText={teamsTestButtonError} disabled={viewOnly}/>
            </TeamsWebhookUrlWrapper>
            { GenericCancelSubmitButtonWrapper({ handleDiscardChanges ,discardChangesButtonHidden, isButtonDisabled, submitForm, viewOnly, responseMessageSuccess }) }
        </Stack>
    );
};


export default TeamsComponent;