import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import SimpleText from 'common/components/ProtectedAssets/Renderers/SimpleText';

import { ICommonIgnoreItem } from '../CommonIgnoreList.interface';
import { getFormattedExpirationDate, isPassedDate } from '../CommonIgnoreList.options';
import { theme } from 'common/design-system/theme/theme';
import { ermTrans } from '../../../../RiskManagement.utils';


export const ExpirationDateCellRender: React.FC<ICellRendererParams<ICommonIgnoreItem>> = (params) => {
    const expirationDate: Date | undefined = params.data?.expirationDate;
    if (!expirationDate) {
        return null;
    }

    const expired = isPassedDate(expirationDate);
    const style: React.CSSProperties = {
        color: expired ? theme().palette.text.alert : undefined,
    };
    const dateText = getFormattedExpirationDate(expirationDate);
    const suffix = expired ? ermTrans('IGNORE_LIST.COMMON.FORM.EXPIRED_SUFFIX') : '';
    const fullText = `${dateText}${suffix}`;

    return <SimpleText value={fullText} style={style} />;
};