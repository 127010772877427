import { Button, Modal, Select } from '@dome9/berries/react-components';
import { ISelectOption } from 'common/interface/general';
import { getStoreService } from 'common/interface/services';
import { IDashboardSection, IDashboardWidget } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { updateDashboardInServer } from 'modules/overview/Api';
import { getSelectedDashboard, moveWidgetToSectionInStore } from 'modules/overview/Overview.reducer';
import { i18nOverviewNamespace } from 'modules/overview/initialize.i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SingleValue } from 'react-select';
import { ActionTypes } from './AddEditWidgetModal';

interface IAddEditWidgetModalProps {
    widget: IDashboardWidget | null
    isOpen: boolean
    action: ActionTypes,
    onRequestClose: Function,
    section: IDashboardSection
}


const MoveSectionWidgetModal: React.FC<IAddEditWidgetModalProps> = ({ widget, isOpen, onRequestClose, section }) => {
    const { t } = useTranslation(i18nOverviewNamespace);
    const { dispatch } = getStoreService().getReduxTools();
    const selectedDashboard = useSelector(getSelectedDashboard);
    const [selectedSectionId, setSelectedSectionId] = useState<string | null>(null);
    const [sectionsOptions, setSectionOptions] = useState<ISelectOption[] | null>(null);


    const handleSubmit = async ()=> { 
        const selectedDashboardClone = deepCloneObject(selectedDashboard);
        const widgetClone: IDashboardWidget = widget && deepCloneObject(widget);
        
        if(widgetClone.options.sizes) {
            widgetClone.options.sizes = {
                width: widgetClone.options.sizes.width,
                height: widgetClone.options.sizes.height,
            };
        }
        const sections = selectedDashboardClone.sections?.map((sectionItem: IDashboardSection) => {
            let widgets = sectionItem.widgets as IDashboardWidget[] || [];

            if(sectionItem.id === selectedSectionId && widgetClone) {  
                widgets.push(widgetClone);
            } else {
                const filteredWidgets: IDashboardWidget[] = widgets?.filter((widgetItem) => {
                    return widgetItem.id !== widgetClone.id;
                });
                widgets = filteredWidgets;
            }
           
            sectionItem.widgets = widgets;

            return sectionItem;
        });
        
        selectedDashboardClone.sections = sections;
        selectedSectionId && dispatch(moveWidgetToSectionInStore({ widget: widgetClone, sectionId: selectedSectionId }));
        onRequestClose();
        await updateDashboardInServer(selectedDashboardClone, false);
    };


    useEffect(()=>{
        const filteredSections = selectedDashboard.sections?.filter(sectionItem => sectionItem.id !== section.id);
        const _sectionsOptions = filteredSections?.map(sectionItem => ({ value: sectionItem.id, label: sectionItem.title }));
        _sectionsOptions && setSelectedSectionId(_sectionsOptions[0]?.value);
        _sectionsOptions && setSectionOptions(_sectionsOptions);
    }, [selectedDashboard, section]);

    return (    
        <Modal.ModalDialog width='md' isOpen={isOpen} onRequestClose={()=> onRequestClose()} shouldCloseOnOverlayClick>
            <Modal.ModalHeader
                title={t('WIDGETS.MOVE_WIDGET.TITLE')}
                onClose={()=> onRequestClose()}
            />
            <Modal.ModalContent>
                {sectionsOptions ?
                    <div>
                        <div className="font-semibold mb-2">
                            {t('WIDGETS.MOVE_WIDGET.MESSAGE')}
                        </div>
                        <Select
                            required={false}
                            value={sectionsOptions.filter((option) => option.value === selectedSectionId)}
                            onChange={(option: SingleValue<ISelectOption>) => option && option.value && setSelectedSectionId(option.value)}
                            closeMenuOnSelect={true}
                            isSearchable={true}
                            options={sectionsOptions}
                        />
                    </div>
                    :
                    <div></div>
                }
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <div className='modal__footer-actions'>
                    <Button onClick={()=> {
                        onRequestClose();
                    }}>{t('DASHBOARD.CANCEL')}</Button>
                    <Button color='primary' onClick={handleSubmit}>{t('WIDGETS.MOVE_WIDGET.MOVE')}</Button>
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
        
    );
};

export default MoveSectionWidgetModal;