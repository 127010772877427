import { Button, Icon } from '@dome9/berries/react-components';
import { getNotificationsService, getStoreService } from 'common/interface/services';
import { IDashboard, IDashboardSection, IDashboardWidget } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import Highcharts from 'highcharts';
import {
    getHiddenWidgetsWithNoData,
    getIsPrinting,
    getSelectedDashboard,
    getSelectedDashboardAsInServer,
    setIsPrinting,
    setSectionsToPrint,
    updateWidgetInStore
} from 'modules/overview/Overview.reducer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RGL, { Layout, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { getAllDashboardsFromServer, updateDashboardInServer } from '../../Api';
import { getDynamicWidgetsPositions, sortWidgetsByView, updateDashboardInStore } from '../../Utils';
import AddEditWidgetModal from '../Widget/AddEditWidgetModal';
import { GenericDashboardWidget } from '../Widget/GenericDashboardWidget';
import SectionActions from './SectionActions';
import './style.scss';
import * as htmlToImage from 'html-to-image';
import { NotificationType } from 'common/interface/notifications';
import { useTranslation } from 'react-i18next';
import { i18nOverviewNamespace } from '../../initialize.i18n';


interface IDashboardSectionProps{
    section: IDashboardSection;
}

interface ILayoutOfWidgets {
    i: string,
    x: number,
    y: number,
    w: number,
    h: number,
    static: boolean
}
let debounceTimeoutId: any = null;

const numberOfColumns = 8;

const getIsOpen = (selectedDashboard: IDashboard, section: IDashboardSection) => Boolean(selectedDashboard?.sections?.find(sectionItem=> sectionItem.id === section.id)?.isExpanded);

export const GenericDashboardSection: React.FC<IDashboardSectionProps> = ({ section }) => {

    const { t } = useTranslation(i18nOverviewNamespace);
    const ReactGridLayout = useMemo(()=> WidthProvider(RGL), []);
    const dispatch = getStoreService().dispatch;

    const selectedDashboardAsInServer = useSelector(getSelectedDashboardAsInServer);
    const selectedDashboardInStore = useSelector(getSelectedDashboard);
    const hiddenWidgetsWithNoData = useSelector(getHiddenWidgetsWithNoData);
    const selectedDashboard = selectedDashboardInStore;
    const _widgets = selectedDashboard.sections?.find(sectionItem=> sectionItem.title === section.title)?.widgets;
    const [filteredWidgets, setFilteredWidgets] = useState<IDashboardWidget[] | undefined>(_widgets);
    const _isSectionOpen = getIsOpen(selectedDashboard, section) || getIsOpen(selectedDashboardAsInServer, section);
    const [isSectionOpen, setIsSectionOpen] = useState<boolean>(_isSectionOpen);
    const [layout, setLayout] = useState<Layout[]>([]);
    const [isAddEditWidgetModalOpen, setIsAddEditWidgetModalOpen] = useState<boolean>(false);
    const isPrint = useSelector(getIsPrinting);
    const componentRef = React.useRef<HTMLDivElement | null>(null);
    const displayHiddenWidgets = selectedDashboard.displayHiddenWidgets;
    const title = section.title;
    const isLocalDashboard = selectedDashboard.isLocalDashboard;
    let layoutTemp = layout;

    useEffect(() => {
        if (componentRef.current && isPrint && isSectionOpen) {
            window.requestIdleCallback(() => {
                htmlToImage.toPng(componentRef.current!).then((imageData64Base)=> {
                    dispatch(setSectionsToPrint({ title: section.title || '', imgData: imageData64Base }));
                    dispatch(setIsPrinting(false));
                }).catch(error => {
                    getNotificationsService().addNotification({
                        type: NotificationType.ERROR,
                        title: t('DASHBOARD.MESSAGES.ERROR_OCCURRED'),
                        text: t('DASHBOARD.MESSAGES.UNABLE_TO_EXPORT_WIDGET'),
                    });
                    console.error('Failed to export the widget', error);
                });
            });
        }

    },[isPrint, dispatch, t, section.isExpanded]);

    const handleLayoutChange = async (newLayout: Layout[], newItem: RGL.Layout, widgs: IDashboardWidget[] | undefined) => {
        // This will make sure that highCharts widgets will resize properly on resize of widget
        Highcharts.charts.forEach(chart => chart && chart.reflow());
        let changedItems = null;
        if(layoutTemp){
            changedItems = layoutTemp.filter((item) => {
                const selectedItem = newLayout.find(newItem=> item.i === newItem.i);
                return selectedItem && (item.w !== selectedItem.w || item.h !== selectedItem.h || item.x !== selectedItem.x || item.y !== selectedItem.y);
            });
        }

        const getModifiedWidgetsList = (newLayout:Layout[]) => widgs?.reduce((acc: IDashboardWidget[],widget) => {
            const widgetClone = deepCloneObject(widget);
            const selectedLayoutItem = newLayout.find(layoutItem => layoutItem.i === widget.options.id);
            const widgetOptionsSizes = widgetClone.options?.sizes;

            if(selectedLayoutItem && widgetOptionsSizes){
                widgetOptionsSizes.height = selectedLayoutItem.h;
                widgetOptionsSizes.width = selectedLayoutItem.w;
                widgetOptionsSizes.x = selectedLayoutItem.x;
                widgetOptionsSizes.y = selectedLayoutItem.y;
            }
            acc.push(widgetClone);
            return acc;
        }, []);
        const allDashboardsFromServer = await getAllDashboardsFromServer();
        const _selectedDashboard = allDashboardsFromServer.find(dashboard => dashboard.id === selectedDashboard.id);
        const _selectedDashboardClone = _selectedDashboard && deepCloneObject(_selectedDashboard);
        const modifiedWidgetsList = getModifiedWidgetsList(newLayout);
        const sortedWidgetsByLayout = sortWidgetsByView(modifiedWidgetsList);

        _selectedDashboardClone.sections.forEach((sectionItem: IDashboardSection) => {
            if(sectionItem.title=== section.title) {
                sectionItem.widgets = sortedWidgetsByLayout;
            }
        });
        changedItems?.forEach((changedItem) => {
            const newSizes = newLayout.find(layoutItem => layoutItem.i === changedItem.i);
            const modifiedWidget = widgs?.find(widget => widget.options.id === changedItem.i);
            if(modifiedWidget && newSizes){
                const modifiedWidgetClone = deepCloneObject(modifiedWidget);
                modifiedWidgetClone.options.sizes = {
                    width: newSizes.w,
                    height: newSizes.h,
                    x: newSizes.x,
                    y: newSizes.y,
                };
                modifiedWidgetClone.id = newSizes.i;
                dispatch(updateWidgetInStore(modifiedWidgetClone));
            }
        });
        layoutTemp = newLayout;

        clearTimeout(debounceTimeoutId);

        debounceTimeoutId = setTimeout(async() => {
            updateDashboardInServer(_selectedDashboardClone);
        }, 500);
    };


    const generateLayout = useCallback(() => {

        const defaultWidgetSize = { width: 2, height: 1 };
        const _flitteredWidgets = displayHiddenWidgets ? _widgets : _widgets?.filter((widget: IDashboardWidget) => !hiddenWidgetsWithNoData.includes(widget.id));
        const noHiddenWidgets = _flitteredWidgets === _widgets;
        setFilteredWidgets(_flitteredWidgets);

        const widgetsSizes = selectedDashboard && _flitteredWidgets?.map((widget: IDashboardWidget) => {
            return {
                width: widget.options?.sizes?.width || defaultWidgetSize.width,
                height: widget.options?.sizes?.height || defaultWidgetSize.height,
                x: widget.options?.sizes?.x,
                y: widget.options?.sizes?.y
            };
        });

        const dynamicPositions = widgetsSizes && getDynamicWidgetsPositions(widgetsSizes, numberOfColumns);

        const layoutOfWidgets = _flitteredWidgets?.map((widget: IDashboardWidget, index: number) => {
            const widgetSizes = widget.options?.sizes;

            const getAxisPosition =(axis: 'x' | 'y')=>{
                const axisPosition = widgetSizes ? widgetSizes[axis] : null;
                const dynamicPosition = dynamicPositions && dynamicPositions[index];

                if(typeof axisPosition === 'number' && noHiddenWidgets) {
                    return axisPosition;
                } else if(dynamicPosition && typeof dynamicPosition[axis] === 'number') {
                    return dynamicPosition[axis];
                } else {
                    return 0;
                }
            };

            return {
                i: widget.options.id,
                x: getAxisPosition('x'),
                y: getAxisPosition('y'),
                w: widgetSizes?.width || defaultWidgetSize.width,
                h: widgetSizes?.height || defaultWidgetSize.height,
                static: Boolean(isLocalDashboard)
            };
        }) as ILayoutOfWidgets[];

        layoutOfWidgets && setLayout(layoutOfWidgets);

    }, [ isLocalDashboard, _widgets, selectedDashboard, hiddenWidgetsWithNoData, displayHiddenWidgets]);

    useEffect(()=> {
        generateLayout();
    }, [generateLayout]);

    const handleIsSectionOpen = async ()=>{
        function updateExpandedSection(dashboard: IDashboard) {
            dashboard?.sections?.forEach((sectionItem: IDashboardSection)=>{
                if(sectionItem.title === section.title){
                    sectionItem.isExpanded = !isSectionOpen;
                }
            });
        }
        if(!isLocalDashboard) {
            const allDashboardsFromServer = await getAllDashboardsFromServer();
            const _selectedDashboard = allDashboardsFromServer.find(dashboard => dashboard.id === selectedDashboard.id);
            const _selectedDashboardClone:IDashboard = _selectedDashboard && deepCloneObject(_selectedDashboard);
            updateExpandedSection(_selectedDashboardClone);
            clearTimeout(debounceTimeoutId);
            debounceTimeoutId = setTimeout(async() => {
                updateDashboardInServer(_selectedDashboardClone);
            }, 500);
        } else {
            const localDashBoard = selectedDashboardInStore && deepCloneObject(selectedDashboardInStore);
            updateExpandedSection(localDashBoard);
            updateDashboardInStore(localDashBoard);
        }
        setIsSectionOpen(!isSectionOpen);
    };

    return (
        <div className="flex flex-col mb-8">
            <div className="flex">
                <Button variant='integrated' className={'flex flex-1 items-center'} onClick={() => handleIsSectionOpen()}>
                    <Icon name={isSectionOpen ?'chevron-down': 'chevron-right'} size={8}
                        data-aid={isSectionOpen ?'top-down-dropdown-button': 'top-right-dropdown-button'} />
                    <div className={'uppercase mx-5 font-semibold text-sm flex-0'}>
                        {title}
                    </div>
                    <div className="flex-1 self-center ml-5 border-b border-dashed"></div>
                </Button>
                {!isLocalDashboard &&
                    <>
                        {isSectionOpen &&
                            <Button onClick={()=>{
                                setIsAddEditWidgetModalOpen(true);
                            }} variant={'quiet'} label={t('WIDGETS.TITLES_BY_ACTION.NEW')} icon='plus' dataAid='add-widget' />
                        }
                        <div className='grow-0'>
                            <SectionActions selectedSection={section} selectedDashboard={selectedDashboard}></SectionActions>
                        </div>
                        {isAddEditWidgetModalOpen && <AddEditWidgetModal
                            section={section}
                            isOpen={isAddEditWidgetModalOpen}
                            onRequestClose={() => {
                                setIsAddEditWidgetModalOpen(false);
                            }}/>}
                    </>
                }
            </div>


            {(layout.length > 0 ) &&
                        <div ref={componentRef}>
                            <ReactGridLayout
                                className={`layout ${isSectionOpen ? '': 'hide'}`}
                                layout={layout}
                                cols={numberOfColumns}
                                rowHeight={200}
                                useCSSTransforms={false}
                                margin={[20, 20]}
                                containerPadding={[10, 10]}
                                onDragStop={(layout: RGL.Layout[], oldItem: RGL.Layout, newItem: RGL.Layout, )=> {handleLayoutChange(layout, newItem, _widgets);}}
                                onResizeStop={(layout: RGL.Layout[], oldItem: RGL.Layout, newItem: RGL.Layout )=> {handleLayoutChange(layout, newItem, _widgets);}}
                                draggableHandle=".drag-handle"
                            >
                                {
                                    filteredWidgets?.map((widget) => {
                                        return (
                                            <div key={widget.options.id} className={`layout ${isSectionOpen ? '': 'hide'}`}>
                                                {isSectionOpen &&
                                                        <GenericDashboardWidget
                                                            widget={widget}
                                                            section={section}
                                                            hideActions={isLocalDashboard}
                                                            hideDrag={isLocalDashboard}
                                                        />
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </ReactGridLayout>
                        </div>
            }
        </div>
    );
};


