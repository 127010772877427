import { ChangeEvent, useState } from 'react';
import { Button, TextField, Icon } from '@dome9/berries/react-components';
import ExpandButton from 'common/components/ExpandButton/ExpandButton';
import { useHistory } from 'react-router-dom';
import { PlatformTypes } from 'common/common_types';
import { useTranslation } from 'react-i18next';

const AddEditRule = () => {
    const { push } = useHistory();
    const { t } = useTranslation();
    const [ruleName, setRuleName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [gslQuery, setGslQuery] = useState<string>('');
    const [remediationDescription, setRemediationDescription] = useState<string>('');
    const [manualRemediation, setManualRemediation] = useState<string>('');
    const [framework, setFramework] = useState<string>('');
    const [version, setVersion] = useState<string>('');
    const [vendorsExpandable, setVendorsExpandable] = useState<JSX.Element[]>([]);
    const [vendorIcon, setVendorIcon] = useState<string>(PlatformTypes.aws.toUpperCase());

    const vendorsButtonsMockData = [PlatformTypes.aws, PlatformTypes.azure, PlatformTypes.gcp];

    const renderIcons = () => {
        return vendorsButtonsMockData.map((vendor) => {
            return (
                <Button key={vendor} onClick={() => setVendorIcon(vendor.toUpperCase())} variant='quiet'
                    className='mr-5'>
                    <Icon name={vendor} />
                </Button>
            );
        });
    };

    const createNewVendorExpandable = () => {
        const newVendor = (
            <ExpandButton title={vendorIcon}>
                <div>
                    <div>
                        <div>
                            <div className='text-sm font-extralight mb-5'>
                                {t('RULES.RULE_PAGE.VENDORS.GSL_QUERY')}
                            </div>
                            <div className='mb-5'>
                                <div className={'flex'}>
                                    <TextField
                                        allowClear={true}
                                        value={gslQuery}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setGslQuery(e.target.value)}
                                    />
                                    <Button color='primary'>{t('RULES.RULE_PAGE.VENDORS.VALIDATE_BUTTON')}</Button>
                                </div>
                            </div>
                        </div>
                        <div className='mb-8'>
                            <Icon name={'info'} color='info' />
                            {t('RULES.RULE_PAGE.VENDORS.VALIDATE_GSL')}
                        </div>
                    </div>
                    <div>{t('RULES.RULE_PAGE.VENDORS.REMEDIATION.TITLE')}</div>
                    <div>
                        <div>
                            <div className='text-sm font-extralight'>
                                {t('RULES.RULE_PAGE.VENDORS.REMEDIATION.DESCRIPTION')}
                            </div>
                            <TextField
                                allowClear={true}
                                value={remediationDescription}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setRemediationDescription(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <div className='text-sm font-extralight'>
                                {t('RULES.RULE_PAGE.VENDORS.REMEDIATION.MANUAL')}
                            </div>
                            <TextField
                                allowClear={true}
                                value={manualRemediation}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setManualRemediation(e.target.value)}
                            />
                        </div>
                        <div>
                            <div className='text-sm font-extralight'>
                                {t('RULES.RULE_PAGE.VENDORS.REMEDIATION.AUTOMATED')}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={'flex mt-8 justify-between'}>
                            {t('RULES.RULE_PAGE.VENDORS.REFERENCE.TITLE')}
                            <Button color='primary'>
                                {t('RULES.RULE_PAGE.VENDORS.REFERENCE.ADD_REFERENCE_BUTTON')}
                            </Button>
                        </div>
                        <div>
                            <div className='text-sm font-extralight'>
                                {t('RULES.RULE_PAGE.VENDORS.REFERENCE.FRAMEWORK')}
                            </div>
                            <TextField
                                allowClear={true}
                                value={framework}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFramework(e.target.value)}
                            />
                        </div>
                        <div>
                            <div className='text-sm font-extralight'>
                                {t('RULES.RULE_PAGE.VENDORS.REFERENCE.VERSION')}
                            </div>
                            <TextField
                                allowClear={true}
                                value={version}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setVersion(e.target.value)}
                            />
                        </div>
                        <div>
                            <div className='text-sm font-extralight'>
                                {t('RULES.RULE_PAGE.VENDORS.REFERENCE.SECTIONS')}
                            </div>
                        </div>
                    </div>
                </div>
            </ExpandButton>
        );
        setVendorsExpandable([...vendorsExpandable, newVendor]);
    };

    return (
        <div className='m-9 mr-9'>
            <div className='my-8 text-lg font-bold'>{t('RULES.RULE_PAGE.CREATE_RULE')}</div>
            <div className='flex'>
                <div className='flex-1 mr-11'>
                    <div>
                        <div className='text-base font-semibold'>{t('RULES.RULE_PAGE.OVERVIEW.TITLE')}</div>
                    </div>
                    <div>
                        <div className='text-sm font-extralight'>{t('RULES.RULE_PAGE.OVERVIEW.RULE_NAME')}</div>
                        <TextField
                            allowClear={true}
                            value={ruleName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setRuleName(e.target.value)}
                        />
                    </div>
                    <div>
                        <div className='text-sm font-extralight'>{t('RULES.RULE_PAGE.OVERVIEW.DESCRIPTION')}</div>
                        <TextField
                            allowClear={true}
                            value={description}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                        />
                    </div>
                    <div>
                        <div className='text-sm font-extralight'>{t('RULES.RULE_PAGE.OVERVIEW.LABELS')}</div>
                    </div>
                    <div>
                        <div className='text-sm font-extralight'>
                            {t('RULES.RULE_PAGE.OVERVIEW.INFRA_EXPOSURE_LEVEL')}
                        </div>
                    </div>
                    <div>
                        <div className='text-sm font-extralight'>
                            {t('RULES.RULE_PAGE.OVERVIEW.INFORMATION_DISCLOSURE_LEVEL')}
                        </div>
                    </div>
                    <div>
                        <div className='text-sm font-extralight'>
                            {t('RULES.RULE_PAGE.OVERVIEW.POTENTIAL_IMPAIRMENT_LEVEL')}
                        </div>
                    </div>
                    <div>
                        <div className='text-sm font-extralight'>
                            {t('RULES.RULE_PAGE.OVERVIEW.REMEDIATION_ACTION_LEVEL')}
                        </div>
                    </div>
                    <div>
                        <div className='text-sm font-extralight'>{t('RULES.RULE_PAGE.OVERVIEW.SEVERITY')}</div>
                    </div>
                </div>
                <div className='flex-1 mr-9 overflow-x-auto'>
                    <div className='flex justify-between'>
                        <div className='text-base font-semibold'>{t('RULES.RULE_PAGE.VENDORS.TITLE')}</div>
                        <Button onClick={() => createNewVendorExpandable()} color='primary'>
                            {t('RULES.RULE_PAGE.VENDORS.ADD_VENDOR_BUTTON')}
                        </Button>
                    </div>
                    <div className='flex'>{renderIcons()}</div>
                    <ExpandButton title={'AWS'}>
                        <div>
                            <div>
                                <div>
                                    <div className='text-sm font-extralight mb-5'>
                                        {t('RULES.RULE_PAGE.VENDORS.GSL_QUERY')}
                                    </div>
                                    <div className='mb-5'>
                                        <div className={'flex'}>
                                            <TextField
                                                allowClear={true}
                                                value={gslQuery}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    setGslQuery(e.target.value)
                                                }
                                            />
                                            <Button color='primary'>
                                                {t('RULES.RULE_PAGE.VENDORS.VALIDATE_BUTTON')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-8'>
                                    <Icon name={'info'} color='info' />
                                    {t('RULES.RULE_PAGE.VENDORS.VALIDATE_GSL')}
                                </div>
                            </div>
                            <div>{t('RULES.RULE_PAGE.VENDORS.REMEDIATION.TITLE')}</div>
                            <div>
                                <div>
                                    <div className='text-sm font-extralight'>
                                        {t('RULES.RULE_PAGE.VENDORS.REMEDIATION.DESCRIPTION')}
                                    </div>
                                    <TextField
                                        allowClear={true}
                                        value={remediationDescription}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                            setRemediationDescription(e.target.value)
                                        }
                                    />
                                </div>
                                <div>
                                    <div className='text-sm font-extralight'>
                                        {t('RULES.RULE_PAGE.VENDORS.REMEDIATION.MANUAL')}
                                    </div>
                                    <TextField
                                        allowClear={true}
                                        value={manualRemediation}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                            setManualRemediation(e.target.value)
                                        }
                                    />
                                </div>
                                <div>
                                    <div className='text-sm font-extralight'>
                                        {t('RULES.RULE_PAGE.VENDORS.REMEDIATION.AUTOMATED')}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={'flex mt-8 justify-between'}>
                                    {t('RULES.RULE_PAGE.VENDORS.REFERENCE.TITLE')}
                                    <Button color='primary'>
                                        {t('RULES.RULE_PAGE.VENDORS.REFERENCE.ADD_REFERENCE_BUTTON')}
                                    </Button>
                                </div>
                                <div>
                                    <div className='text-sm font-extralight'>
                                        {t('RULES.RULE_PAGE.VENDORS.REFERENCE.FRAMEWORK')}
                                    </div>
                                    <TextField
                                        allowClear={true}
                                        value={framework}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setFramework(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm font-extralight'>
                                        {t('RULES.RULE_PAGE.VENDORS.REFERENCE.VERSION')}
                                    </div>
                                    <TextField
                                        allowClear={true}
                                        value={version}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setVersion(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm font-extralight'>
                                        {t('RULES.RULE_PAGE.VENDORS.REFERENCE.SECTIONS')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ExpandButton>
                    {vendorsExpandable && vendorsExpandable.map((vendor) => vendor)}
                </div>
            </div>
            <div className='flex justify-end mr-9 mt-9'>
                <Button onClick={() => push('/compliance-engine/rules')} variant='quiet' className='mr-8'>
                    {t('RULES.RULE_PAGE.VENDORS.REFERENCE.BUTTONS.CANCEL')}
                </Button>
                <Button variant='quiet' disabled className='mr-8'>
                    {t('RULES.RULE_PAGE.VENDORS.REFERENCE.BUTTONS.EXPORT_YMAL')}
                </Button>
                <Button onClick={() => push('/compliance-engine/rules')} color='primary'>
                    {t('RULES.RULE_PAGE.VENDORS.REFERENCE.BUTTONS.SAVE')}
                </Button>
            </div>
        </div>
    );
};

export default AddEditRule;
