import React, { useEffect, useMemo, useState } from 'react';
import * as Highcharts from 'highcharts';
import dayjs from 'dayjs';
import HighchartsReact from 'highcharts-react-official';
import './ExecutiveReport.scss';
import { useLocation } from 'react-router-dom';
import { getExecutiveReportData, getExecutiveReportDataByKey } from './ExecutiveReport.service';
import { generateFailedAndPassedData, generateGridData, generateTestScoreData } from './ExecutiveReport.utils';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { CpIcon, CpLoadingDots } from '@dome9/components/react/components';
import FindingSeverityBox from 'common/components/SeverityBox/FindingSeverityBox';
import cpLogo from '../../../assets/images/cp-logo.svg';
import cloudGuardLogo from '../../../assets/images/cloud-guard.svg';
import Error from 'common/components/Error/Error';
import i18n from 'i18next';
import { getVendorDisplayName } from 'common/consts/vendors';
import { ColDef } from 'ag-grid-enterprise';
import { FindingSeverityEnum } from 'common/consts/FindingSeverity';

const columnDefs: ColDef[] =
    [{
        headerName: 'Name',
        field: 'name',
        resizable: true,
        flex: 1,
        cellRenderer: 'nameRenderer',
    },
    {
        headerName: 'Severity',
        field: 'Severity',
        resizable: false,
        maxWidth: 200,
        flex: 1,
        cellRenderer: 'severityRenderer',
    }];

export const ExecutiveReport: React.FC = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [reportData, setReportData] = useState<any>(null);
    const [errorState, setErrorState] = useState('');
    const gridData = useMemo(() => {
        return generateGridData(reportData);
    }, [reportData]);

    const testScoreChart = useMemo(() => {
        return generateTestScoreData(reportData);
    }, [reportData]);

    const failedAndPassedChart = useMemo(() => {
        return generateFailedAndPassedData(reportData);
    }, [reportData]);

    useEffect(() => {
        function loadReportData() {
            const querystring = new URLSearchParams(location.search);
            const platform = querystring.get('platform')!;
            const rulesetId = querystring.get('rulesetId')!;
            const key = querystring.get('key')!;

            if (key) {
                getExecutiveReportDataByKey(key)
                    .then((reportData) => {
                        setReportData(reportData);
                    })
                    .catch(() => {
                        setErrorState(i18n.t('EXECUTIVE_REPORT.ERROR'));
                    });
            } else {
                getExecutiveReportData(platform, rulesetId)
                    .then((reportData) => {
                        setReportData(reportData);
                    })
                    .catch(() => {
                        setErrorState(i18n.t('EXECUTIVE_REPORT.ERROR'));
                    });
            }
        }

        loadReportData();
    }, [location.search]);


    const renderError = (text: string) => {
        return <Error title={'Error'} text={text} />;
    };

    const NameRenderer = ({ value }: any) => {
        return (
            <div className='ellipsis'>
                <CpIcon {...{ icon: reportData.vendor }} />
                <span className='cloud-account-name'>{value}</span>
            </div>
        );
    };

    const SeverityRenderer = ({ data }: any) => {
        return data ? (
            <div className='severity-box'>
                <FindingSeverityBox severity={FindingSeverityEnum.critical} number={data.severity.critical} size='tiny'></FindingSeverityBox>
                <FindingSeverityBox severity={FindingSeverityEnum.high} number={data.severity.high} size='tiny'></FindingSeverityBox>
                <FindingSeverityBox severity={FindingSeverityEnum.medium} number={data.severity.medium} size='tiny'></FindingSeverityBox>
                <FindingSeverityBox severity={FindingSeverityEnum.low} number={data.severity.low} size='tiny'></FindingSeverityBox>
                <FindingSeverityBox severity={FindingSeverityEnum.informational} number={data.severity.informational} size='tiny'></FindingSeverityBox>
            </div>
        ) : (
            ''
        );
    };

    return (
        <div className='executive-report-page-container'>
            {errorState ? (
                renderError(errorState)
            ) : reportData ? (
                <div className='executive-report-page'>
                    <div className='initial-page'>
                        <img className='cp-icon' src={cpLogo} />
                        <img className='cloud-guard-icon' src={cloudGuardLogo} />
                        <div className='cloud-guard-title'>CloudGuard</div>
                    </div>
                    <div className='data-page'>
                        <div className='main-title'>{t('EXECUTIVE_REPORT.EXECUTIVE_SUMMARY_REPORT')}</div>
                        <div className='sub-title'>{t('EXECUTIVE_REPORT.DESCRIPTION_SUMMARY_REPORT')}</div>
                        <div className='data-boxes'>
                            <div className='data-box'>
                                <div className='data-key'>{t('EXECUTIVE_REPORT.RULESET')}:</div>
                                <div className='data-value ellipsis'>{reportData.rulesetName}</div>
                            </div>
                            <div className='data-box'>
                                <div className='data-key'>{t('EXECUTIVE_REPORT.VENDOR_TYPE')}:</div>
                                <div className='data-value'>{getVendorDisplayName(reportData.vendor)}</div>
                            </div>
                            <div className='data-box'>
                                <div className='data-key'>{t('EXECUTIVE_REPORT.DATE_TIME')}:</div>
                                <div className='data-value'>
                                    {dayjs(new Date(reportData.date)).format('MMM D, YYYY h:mm A')}
                                </div>
                            </div>
                            <div className='data-box'>
                                <div className='data-key'>{t('EXECUTIVE_REPORT.NUMBER_OF_ENVS')}:</div>
                                <div className='data-value'>{reportData.numberOfEnvironments}</div>
                            </div>
                        </div>
                        <div className='grid-container'>
                            <div className='mini-title'>{t('EXECUTIVE_REPORT.TOP_10_ENVS')}</div>
                            {gridData && (
                                <AgGridReact
                                    className='executive-grid ag-theme-balham'
                                    domLayout={'autoHeight'}
                                    rowData={gridData}
                                    columnDefs={columnDefs}
                                    components={{
                                        nameRenderer: NameRenderer,
                                        severityRenderer: SeverityRenderer,
                                    }}>
                                </AgGridReact>
                            )}
                        </div>
                        <div className='mini-title'>{t('EXECUTIVE_REPORT.ENTITIES_BY_TYPE')}</div>
                        <div className='chart-box failed_passed_chart_container'>
                            <HighchartsReact highcharts={Highcharts} options={failedAndPassedChart} />
                        </div>
                        <div>
                            <div className='mini-title'>{t('EXECUTIVE_REPORT.TEST_SCORE')}</div>
                            <div className='chart-box test_score_chart_container'>
                                <HighchartsReact highcharts={Highcharts} options={testScoreChart} />
                            </div>
                        </div>
                        <div>
                            <div className='mini-title'>{t('EXECUTIVE_REPORT.FAILED_TEST_BY_RULE')}</div>
                            <div className='chart-box severities-container'>
                                <FindingSeverityBox
                                    isAddCommas
                                    severity={FindingSeverityEnum.critical}
                                    number={reportData.failedTestedBySeverity.critical}
                                    size='extend' />
                                <FindingSeverityBox
                                    isAddCommas
                                    severity={FindingSeverityEnum.high}
                                    number={reportData.failedTestedBySeverity.high}
                                    size='extend' />
                                <FindingSeverityBox
                                    isAddCommas
                                    severity={FindingSeverityEnum.medium}
                                    number={reportData.failedTestedBySeverity.medium}
                                    size='extend' />
                                <FindingSeverityBox
                                    isAddCommas
                                    severity={FindingSeverityEnum.low}
                                    number={reportData.failedTestedBySeverity.low}
                                    size='extend' />
                                <FindingSeverityBox
                                    isAddCommas
                                    severity={FindingSeverityEnum.informational}
                                    number={reportData.failedTestedBySeverity.informational}
                                    size='extend' />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <CpLoadingDots />
            )}
        </div>
    );
};
