import i18next from 'i18next';
import { changeUrl } from 'common/utils/http';
import { getHttpService, getLoggerService, getNotificationsService, getStoreService } from 'common/interface/services';
import {
    IAllStoragesModel,
    ICheckARMTemplateStatusRequest,
    ICheckARMTemplateStatusResponse,
    INetworkTrafficARMTemplateRequestModel,
    IAccountActivityARMTemplateRequestModel,
    IARMTemplateResponseModel,
    IDiscoverStoragesRequestModel,
    IInvalidFirewallStoragesRequestModel,
    IInvalidFirewallStoragesResponseModel,
    IOnboardingAccountActivityRequestModel,
    IOnboardingNetworkTrafficRequestModel,
    IOnboardedStoragesResponseModel,
    IReportingSubscriptionsRequestModel,
    IReportingSubscriptionsResponseModel,
    IAzureResourceGroupsRequestModel,
    IAzureResourceGroupsResponseModel,
    INsgARMTemplateRequestModel,
    IAzureAddStorageModel,
    IAzureAddStorageResponseModel,
} from '../AzureIntelligenceOnboarding.interface';
import { setHideAngularUntilNavigated } from '../../../../../App.reducer';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { AxiosError } from 'axios';
import { getCloudAccountsService } from 'common/interface/data_services';

const t = i18next.getFixedT(null, i18nIntelligenceNamespace, 'AZURE_ONBOARDING');

const handleCustomError = (err: AxiosError) => {
    const message = (err?.response?.data as { message: string, statusCode: number })?.message || err.message;
    throw new Error(message);
};

const showError = async (err: any, text: string, tags: string[]) => {
    const { state } = getStoreService().getReduxTools();
    const { subscriptionId } = state.azureIntelligenceOnboarding;
    const { user } = state.user;
    const accountId = `${t('ACCOUNT_ID')}: ${user?.accountId}`;
    const subscription = `${t('SUBSCRIPTIONS.SUBSCRIPTION_ID')}: ${subscriptionId}`;

    getNotificationsService().error(text, err?.message);
    await getLoggerService().error(`${accountId} ${subscription} : ${text} : ${err?.message}`, '', tags.concat([t('LOGGER_TAGS.AZURE_ONBOARDING')]));
};

export const getOnboardedStorageData = async (subscriptionId: string, isAccountActivity = false): Promise<IOnboardedStoragesResponseModel | undefined> => {
    const state = getStoreService().state;
    const magellanUrl = state.app.magellanUrl;
    const params = `cloudAccount=${subscriptionId}`;

    try {
        if (isAccountActivity) {
            return await getHttpService().get(`${magellanUrl}/onboarding/get-onboarded-activity-storages?${params}`, {});
        } else {
            return await getHttpService().get(`${magellanUrl}/onboarding/get-onboarded-flowlogs-storages?${params}`, {});
        }
    } catch (err) {
        await showError(err, t('NOTIFICATIONS.ERROR.STORAGE'), [t('STEPS.STORAGE')]);
        return;
    }
};

export const discoverStorages = async (data: IDiscoverStoragesRequestModel): Promise<IAllStoragesModel[] | undefined> => {
    return await getHttpService().post<IAllStoragesModel[]>('intelligence/azure/discover-storages', { data }, undefined, handleCustomError);
};

export const getAzureResourceGroupsForSubscription = async (data: IAzureResourceGroupsRequestModel): Promise<IAzureResourceGroupsResponseModel | undefined> => {
    try {
        return await getHttpService().post('intelligence/azure/get-azure-resource-groups-for-subscription', { data });
    } catch (err) {
        await showError(err, t('NOTIFICATIONS.ERROR.STORAGE'), [t('STEPS.STORAGE')]);
        return;
    }
};


export const getCredentialsServicePrincipalId = async (subscriptionId: string): Promise<string | undefined> => {
    try {
        return await getHttpService().get(`intelligence/azure/${subscriptionId}/CredentialsServicePrincipalId`, {});
    } catch (err) {
        await showError(err, t('NOTIFICATIONS.ERROR.PRINCIPLE_ID'), [t('STEPS.ARM_TEMPLATE')]);
        return;
    }
};

export const getNetworkTrafficARMTemplate = async (data: INetworkTrafficARMTemplateRequestModel): Promise<IARMTemplateResponseModel | undefined> => {
    const state = getStoreService().state;
    const magellanUrl = state.app.magellanUrl;

    try {
        return await getHttpService().post(`${magellanUrl}/onboarding/get-network-traffic-arm-template`, { data });
    } catch (err) {
        await showError(err, t('NOTIFICATIONS.ERROR.ARM_TEMPLATE'), [t('STEPS.ARM_TEMPLATE')]);
        return;
    }
};

export const getNSGARMTemplate = async (data: INsgARMTemplateRequestModel): Promise<IARMTemplateResponseModel | undefined> => {
    const state = getStoreService().state;
    const magellanUrl = state.app.magellanUrl;

    try {
        return await getHttpService().post(`${magellanUrl}/onboarding/get-arm-template`, { data });
    } catch (err) {
        await showError(err, t('NOTIFICATIONS.ERROR.ARM_TEMPLATE'), [t('STEPS.ARM_TEMPLATE')]);
        return;
    }
};

export const getAccountActivityARMTemplate = async (data: IAccountActivityARMTemplateRequestModel): Promise<IARMTemplateResponseModel | undefined> => {
    const state = getStoreService().state;
    const magellanUrl = state.app.magellanUrl;

    try {
        return await getHttpService().post(`${magellanUrl}/onboarding/get-account-activity-arm-template`, { data });
    } catch (err) {
        await showError(err, t('NOTIFICATIONS.ERROR.ARM_TEMPLATE'), [t('STEPS.ARM_TEMPLATE')]);
        return;
    }
};

export const checkARMTemplateStatus = async (data: ICheckARMTemplateStatusRequest): Promise<ICheckARMTemplateStatusResponse | undefined> => {
    return await getHttpService().post<ICheckARMTemplateStatusResponse>(
        'intelligence/azure/check-now', 
        { data },
        undefined,
        handleCustomError
    );
};

export const getReportingSubscriptionsData = async (data: IReportingSubscriptionsRequestModel): Promise<IReportingSubscriptionsResponseModel | undefined> => {
    const state = getStoreService().state;
    const magellanUrl = state.app.magellanUrl;

    try {
        return await getHttpService().post(`${magellanUrl}/onboarding/azure-storage-reporting-subscriptions`, { data });
    } catch (err) {
        await showError(err, t('NOTIFICATIONS.ERROR.SUBSCRIPTIONS'), [t('STEPS.SUBSCRIPTIONS')]);
        return;
    }
};

export const getIntelligenceNATIP = async (): Promise<string[] | undefined> => {
    const state = getStoreService().state;
    const magellanUrl = state.app.magellanUrl;

    try {
        return await getHttpService().get(`${magellanUrl}/onboarding/intelligence-NAT-ip`, {});
    } catch (err) {
        await showError(err, t('NOTIFICATIONS.ERROR.NAT_IP'), [t('STEPS.FIREWALL')]);
        return;
    }
};

export const getStoragesWithInvalidFirewall = async (data: IInvalidFirewallStoragesRequestModel[]): Promise<IInvalidFirewallStoragesResponseModel[] | undefined> => {
    try {
        return await getHttpService().post('intelligence/azure/storages-with-invalid-network-firewall', { data });
    } catch (err) {
        await showError(err, t('NOTIFICATIONS.ERROR.FIREWALL_CHECK_STATUS'), [t('STEPS.FIREWALL')]);
        return;
    }
};

export const onboardAzureNetworkTraffic = async (data: IOnboardingNetworkTrafficRequestModel) => {
    return await getHttpService().post(
        'intelligence/azure/onboarding-network-traffic', 
        { data }, 
        undefined,
        handleCustomError
    );
};

export const onboardAzureAccountActivity = async (data: IOnboardingAccountActivityRequestModel, apiDetails: string) => {
    try {
        return await getHttpService().post('intelligence/azure/onboarding-account-activity', { data });
    } catch (err) {
        await showError(err, `${t('ACCOUNT_ACTIVITY.SUMMARY.FAILURE')}  ${apiDetails}`, [t('LOGGER_TAGS.ACCOUNT_ACTIVITY')]);
        throw err;
    }
};

export const exitOnboardingWizard = () => {
    const dispatch = getStoreService().dispatch;
    dispatch(setHideAngularUntilNavigated(true));
    getCloudAccountsService().clearCache();
    changeUrl('/cloud-account');
};

export const getAzureStorageById = async (data: IAzureAddStorageModel): Promise<IAzureAddStorageResponseModel[]> => {
    return await getHttpService().post<IAzureAddStorageResponseModel[]>(
        'intelligence/azure/discover-storage-manually', 
        { data },
        undefined,
        handleCustomError
    );
};