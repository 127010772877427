import styled from 'styled-components';
import { Button } from 'common/design-system/components-v2';

const ButtonDiv = styled(Button)`
    align-self: flex-end;
`;

export const CoverageStatusStyled = {
    ButtonDiv,
};
