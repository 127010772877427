import React from 'react';
import MagellanService from '../magellan.service';
import { MagellanDisableMagellanForCloudAccount } from '../magellan.interface';

export interface UseMagellanDisableForCloudAccountReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: (response: MagellanDisableMagellanForCloudAccount.Response) => void;
}

export interface UseMagellanDisableForCloudAccountPayload {
    isLoading: boolean;
    isRequestSuccess: boolean;
    requestData?: MagellanDisableMagellanForCloudAccount.Response;
    isError?: { message: string; };
    disable: (request: MagellanDisableMagellanForCloudAccount.Request) => Promise<void>;
    reset: () => void;
}

export type UseMagellanDisableForCloudAccount = (req?: UseMagellanDisableForCloudAccountReq) => UseMagellanDisableForCloudAccountPayload;

export const useMagellanDisableForCloudAccount: UseMagellanDisableForCloudAccount = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = React.useState(false);
    const [requestData, setRequestData] = React.useState<MagellanDisableMagellanForCloudAccount.Response | undefined>();
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const disable = React.useCallback(async (request: MagellanDisableMagellanForCloudAccount.Request) => {
        setIsLoading(true);
        try {
            const response = await MagellanService.magellanDisableMagellanForCloudAccount(request);
            setIsRequestSuccess(true);
            setRequestData(response.data);
            onSuccess?.(response.data);
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsRequestSuccess(false);
        setRequestData(undefined);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isRequestSuccess,
        requestData,
        isError,
        disable,
        reset
    });
};