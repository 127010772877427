import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { ShiftLeftDeleteMultiple } from './shiftLeftApi.interface';

const apiBaseUrl = 'ShiftLeftAccount';

const deleteMultiple: ShiftLeftDeleteMultiple.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ShiftLeftDeleteMultiple.Response>>(
        `${apiBaseUrl}/deleteMultiple`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const ShiftLeftApi = {
    deleteMultiple,
};

export default ShiftLeftApi;
