import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const FormFieldsWrapper = styled(Stack)``;
const AddCloudBotsWrapper = styled(Stack)``;
const CloudBotsSelectWrapper = styled(Stack)``;
const CloudBotCardListWrapper = styled(Stack)``;
const CloudBotsCounterWrapper = styled(Stack)``;
const AddButtonWrapper = styled(Stack)<{ isHelperTextVisible?: boolean }>`
    justify-content: ${prop => prop?.isHelperTextVisible ? 'center' : 'flex-end' };
`;
const EndpointWrapper = styled(Stack)``;
const FooterActionsWrapper = styled(Stack)``;

export const FixItModalStyles = {
    FormFieldsWrapper,
    AddCloudBotsWrapper,
    CloudBotsSelectWrapper,
    CloudBotCardListWrapper,
    CloudBotsCounterWrapper,
    AddButtonWrapper,
    EndpointWrapper,
    FooterActionsWrapper
};
