import React from 'react';
import { useSelector } from 'react-redux';
import OciActions from '../oci.actions';
import { OciSelector } from '../oci.selectors';
import { RootState } from 'common/services/store/store';
import { OciEnvironmentData } from 'common/module_interface/assets/OciEnvironment';

export interface UseGetOciPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isDeleted: boolean;
    data?: OciEnvironmentData;
    reloadData: () => void;
}

export type UseGetOci = (ociId: string) => UseGetOciPayload;

export const useGetOci: UseGetOci = (ociId) => {
    const { loading, error, data, deleted } = useSelector((state: RootState) => OciSelector.getOci(state, ociId)) || {};
    const getData = (id: string) => OciActions.getOciAccount(id);
    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(ociId);
        }
    }, [ociId, data, loading, error]);
    const reloadData = React.useCallback(() => getData(ociId), [ociId]);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        isDeleted: !!deleted,
        data,
        reloadData
    });
};