import { AzureEnvironmentData } from 'common/module_interface/assets/AzureEnvironment';

export interface AzureState {
    azureEvnironments: {
        [azureId: string]: {
            data?: AzureEnvironmentData;
            loading?: boolean;
            error?: boolean;
            deleted?: boolean;
        };
    };
}

export const AzureInitialState: AzureState = {
    azureEvnironments: {},
};