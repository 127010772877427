import React from 'react';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { Icon } from 'common/design-system/components-v2';
import {
    TotalCounterDiv,
    TotalCounterHeaderDiv, TotalCounterIconDiv,
    TotalCounterSubtitleDiv,
    TotalCounterTextDiv
} from './TotalCounter.styled';
import { formatNumberShorthand } from 'common/utils/helpFunctions';


const TotalCounter: React.FC<{ value: number, icon?: AllIconsName, subtitle?: string, onClick?: Function }> = ({ value, icon, subtitle, onClick }) => {
    return (
        <TotalCounterDiv>
            <TotalCounterHeaderDiv>
                <TotalCounterIconDiv>
                    {icon && <Icon name={icon} size={48} />}
                </TotalCounterIconDiv>
                <TotalCounterTextDiv isClickable={!!onClick} onClick={() => onClick && onClick()}>{formatNumberShorthand(value)}</TotalCounterTextDiv>
            </TotalCounterHeaderDiv>
            {subtitle && <TotalCounterSubtitleDiv>{subtitle}</TotalCounterSubtitleDiv>}
        </TotalCounterDiv>
    );
};

export default TotalCounter;
