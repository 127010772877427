import { IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import i18n from 'i18next';
import { renderMultiSelectFilter, renderRangeFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { Addin } from 'common/extensibility/AddinRegistry';
import { CONTEXT_FIELD_FULL_NAMES, I18nRiskNamespace } from '../../consts';
import { UNKNOWN_IAM_EXPOSURE_LEVEL_KEY, UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY } from '../ProtectedAsset/ErmDataUtils';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';
import { ermPermissionFn } from '../../RiskManagement.utils';
import { WAF_PROTECTION_ACTIVE_FEATURE } from '../ActiveFeatures/ActiveFeatures.consts';
import { IFilterDisplay } from '../../../../common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { IssueSeverityEnum } from '../../../../common/module_interface/RiskManagement/issues/Issues.interface';
import { IssueSeveritiesMap } from '../Issues/IssueSeverity';
import {
    ERM_PROTECTED_ASSET_FILTER_IDS,
} from '../../../../common/module_interface/RiskManagement/protectedAssets/filters.consts';

const severityInitialData = [
    { value: FindingSeverityServerEnum.Low },
    { value: FindingSeverityServerEnum.Medium },
    { value: FindingSeverityServerEnum.High },
    { value: FindingSeverityServerEnum.Critical },
];

const issueSeverityLevelInitialData = [
    { value: IssueSeverityEnum.low },
    { value: IssueSeverityEnum.medium },
    { value: IssueSeverityEnum.high },
    { value: IssueSeverityEnum.critical },
];


const filterDefsAddins: Addin<IFilterProps>[] =
    [
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.riskScore,
            content: function() {
                return {
                    filterProps: {
                        min: 0,
                        max: 10,
                        step: 0.1,
                        key: 'riskScore',
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.RISK_SCORE.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderRangeFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.iamSensitivity,
            content: function() {
                return {
                    filterProps: {
                        min: 0,
                        max: 100,
                        key: CONTEXT_FIELD_FULL_NAMES.iamSensitivity,
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.IAM_SENSITIVITY.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderRangeFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.dataSensitivity,
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.dataSensitivity],
                        key: CONTEXT_FIELD_FULL_NAMES.dataSensitivity,
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.DATA_SENSITIVITY.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.dataClassification,
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.dataClassifications],
                        key: CONTEXT_FIELD_FULL_NAMES.dataClassifications,
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.DATA_CLASSIFICATION.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.businessPriority,
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations['businessPriority'],
                        key: 'businessPriority',
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.BUSINESS_PRIORITY.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.networkExposure,
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.networkExposure]?.filter((item) => {
                            return item.value !== UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY;
                        }),
                        key: CONTEXT_FIELD_FULL_NAMES.networkExposure,
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.NETWORK_EXPOSURE.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.iamExposure,
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.iamExposure]?.filter((item) => {
                            return item.value !== UNKNOWN_IAM_EXPOSURE_LEVEL_KEY;
                        }),
                        key: CONTEXT_FIELD_FULL_NAMES.iamExposure,
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.IAM_EXPOSURE.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.secrets,
            content: function() {
                const initialData = [...severityInitialData];
                return {
                    filterProps: {
                        initialData,
                        key: 'secrets',
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.SECRETS.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.cves,
            content: function() {
                const initialData = [...severityInitialData];
                return {
                    filterProps: {
                        initialData,
                        key: 'CVEs',
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.CVES.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.openSecurityIssues,
            content: function() {
                const initialData = [...issueSeverityLevelInitialData];
                return {
                    filterProps: {
                        initialData,
                        key: CONTEXT_FIELD_FULL_NAMES.openSecurityIssues,
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.OPEN_SECURITY_ISSUES.TITLE', { ns: I18nRiskNamespace }),
                        displayMapping: initialData.reduce<Partial<{ [key in IssueSeverityEnum]: IFilterDisplay }>>((mapping, item: {
                            value: IssueSeverityEnum;
                        }) => {
                            mapping[item.value] = { displayText: IssueSeveritiesMap[item.value].displayText };
                            return mapping;
                        }, {}),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: ermPermissionFn,
        },
        {
            id: ERM_PROTECTED_ASSET_FILTER_IDS.wafProtection,
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[CONTEXT_FIELD_FULL_NAMES.wafProtection],
                        key: CONTEXT_FIELD_FULL_NAMES.wafProtection,
                        title: i18n.t('PROTECTED_ASSETS.FILTERS.WAF_PROTECTION.TITLE', { ns: I18nRiskNamespace }),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: () => ermPermissionFn(WAF_PROTECTION_ACTIVE_FEATURE.key),
        },
    ];

export default function initializeFilters() {
    ProtectedAssetsTableRegistry.addFilterAddins(filterDefsAddins);
}