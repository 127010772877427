import styled from 'styled-components';


const IntegrationSearch = styled.div`
    width: 260px;
    margin: 32px 0 0 28px;
    display: flex;
`;
export {
    IntegrationSearch
};

