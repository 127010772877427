import React, { useEffect, useState } from 'react';
import {
    GenericWebhookStyled,
    GenericWebhookNameWrapper,
    GenericWebhookEndpointUrlWrapper,
    GenericWebhookEndpointUrlInputWrapper,
    GenericWebhookAuthenticationWrapper,
    BasicAuthWrapper,
    BasicAuthCredentialsInputWrapper,
    IgnoreCertificateValidationWrapper,
} from './GenericWebhook.styled';
import { Stack, Button, Typography, Dropdown, Input, List, Checkbox, Icon, Tooltip } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations, URL_ENDPOINT_PROTOCOL, AUTHENTICATION_TYPE, COMPONENT_TEXT_COLOR, SUBMIT_STATUS_RESPONSE } from 'common/module_interface/settings/integrations/consts';
import { getIntegrationsService } from 'common/interface/services';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import { isUrlValid } from 'common/utils/http';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';

interface IGenericConfigurationProps extends IConfigurationContainerProps{
    configuration?: IGenericConfiguration;
    children?: React.ReactNode;
    childConfigurationObject?: childConfigurationDynamicObject;
    childrenHandleDiscardChanges?: () => void;
    childChangesDetected?: boolean;
}

interface childConfigurationDynamicObject {
    [key: string]: string | boolean;
}

export interface IGenericConfiguration extends IConfiguration{
    configurationObj: IGenericWebhookConfigurationObj
}

export interface IGenericWebhookConfigurationObj {
    Url: string,
    AuthType: string,
    Username: string,
    Password: string,
    IgnoreCertificate: boolean,
    availableAuthTypes?:string[]
}

const GenericWebhookComponent : React.FC<IGenericConfigurationProps> = ({ onConfigurationChangeCallBack ,configuration, onConfigurationSaved,viewMode,
    children, childConfigurationObject, childrenHandleDiscardChanges , childChangesDetected, testFormatType }) => {
    const { t } = useTranslation( I18nIntegrations );
    const urlFromConfig = configuration?.configurationObj?.Url?.replace(/^https?:\/\//, '') || '';
    const authFromConfig = configuration?.configurationObj?.AuthType === AUTHENTICATION_TYPE.BasicAuth ? AUTHENTICATION_TYPE.BASIC : AUTHENTICATION_TYPE.NO_AUTHENTICATION;
    const authTypes = configuration?.configurationObj?.availableAuthTypes;
    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [endpointUrlInputConfig, SetEndpointUrlInputConfig] = useState<string>(urlFromConfig);
    const [authenticationTypeInputConfig, setAuthenticationTypeInputConfig] = useState<string>(authFromConfig);
    const [endpointUrlTestButtonError, setEndpointUrlTestButtonError] = useState<string>('');
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [basicAuthUsernameInputConfig, setBasicAuthUsernameInputConfig] = useState<string>(configuration?.configurationObj.Username || '');
    const [basicAuthPasswordInputConfig, setBasicAuthPasswordInputConfig] = useState<string>(configuration?.configurationObj.Password || '');
    const [ignoreCertificateValidation, SetIgnoreCertificateValidation] = useState<boolean>(configuration?.configurationObj.IgnoreCertificate || false);
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [isTestButtonDisabled, setTestIsButtonDisabled] = useState<boolean>(false);
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);

    const discardChangesButtonHidden = Boolean(idConfiguration);
    const getAuthTypes = () :IListItemProps[] => {
        if(authTypes){
            return authTypes.map((option:string)=> {
                return {
                    label: AUTHENTICATION_TYPE.BASIC, value: option, onClick: () => {
                        handleAuthenticationTypeChange(option);
                    }
                };
            }) || [];
        }else{
            return [
                { label: AUTHENTICATION_TYPE.BASIC, value: AUTHENTICATION_TYPE.BASIC, onClick: ()=>{handleAuthenticationTypeChange(AUTHENTICATION_TYPE.BASIC);} },
                { label: AUTHENTICATION_TYPE.NO_AUTHENTICATION, value: AUTHENTICATION_TYPE.NO_AUTHENTICATION, onClick: ()=>{handleAuthenticationTypeChange(AUTHENTICATION_TYPE.NO_AUTHENTICATION);} }
            ];
        }

    };
    const authenticationTypes = getAuthTypes();

    const Basic = t('GENERAL.BASIC');
    const NoAuthentication = t('GENERAL.No_AUTHENTICATION');
    const AuthenticationType:{ [key: string]: string } = {
        [Basic]: 'BasicAuth',
        [NoAuthentication]: 'noAuth'
    };
    const findAuthenticationKeyByValue = (value: string|undefined) => {
        return Object.keys(AuthenticationType).find(key => AuthenticationType[key] === value);
    };

    const viewOnly = Boolean(viewMode);

    useEffect(() => {
        checkChangesMade();
        checkTestButtonDisabled();
    }, [nameInputConfig,
        endpointUrlInputConfig,
        authenticationTypeInputConfig,
        basicAuthUsernameInputConfig,
        basicAuthPasswordInputConfig,
        ignoreCertificateValidation,
        idConfiguration,
        childChangesDetected
    ]);

    const checkMissingRequiredInput = () => {
        const variableNotChanged = (
            nameInputConfig === '' ||
            endpointUrlInputConfig === ''
        );
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected = (
            nameInputConfig !== configuration?.name ||
            endpointUrlInputConfig !== urlFromConfig ||
            authenticationTypeInputConfig !== findAuthenticationKeyByValue(configuration?.configurationObj.AuthType) ||
            basicAuthUsernameInputConfig !== configuration?.configurationObj.Username ||
            basicAuthPasswordInputConfig !== configuration?.configurationObj.Password ||
            ignoreCertificateValidation !== configuration?.configurationObj.IgnoreCertificate ||
            childChangesDetected === true
        );
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const checkTestButtonDisabled = () => {
        const testButtonDisabled = (
            !endpointUrlInputConfig ||
            (authenticationTypeInputConfig !== AUTHENTICATION_TYPE.NO_AUTHENTICATION) && (!basicAuthUsernameInputConfig || !basicAuthPasswordInputConfig) ||
            (Boolean(endpointUrlInputConfig) && !isUrlValid(endpointUrlInputConfig))
        );
        setTestIsButtonDisabled(testButtonDisabled);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setNameInputConfig(textInput);
    };

    const handleEndpointUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndpointUrlTestButtonError('');
        const textInput = event.target.value;
        const domain = textInput.replace(/^https?:\/\//, '');
        SetEndpointUrlInputConfig(domain);
    };

    const testUrlEndpoint = async () => {
        setEndpointUrlTestButtonError('');
        try {
            const testButtonResponse = await getIntegrationsService().testGenericWebhookService(URL_ENDPOINT_PROTOCOL + endpointUrlInputConfig,
                authenticationTypeInputConfig, basicAuthUsernameInputConfig, basicAuthPasswordInputConfig, ignoreCertificateValidation, testFormatType);
            setEndpointUrlTestButtonError( testButtonResponse || t('CONFIGURATIONS.GENERIC_WEBHOOK.NETWORK_ERROR'));
            setResponseMessageSuccess(true);
            return true;
        } catch (error:any) {
            setEndpointUrlTestButtonError( error || t('CONFIGURATIONS.GENERIC_WEBHOOK.NETWORK_ERROR'));
            setResponseMessageSuccess(false);
            return false;
        }
    };

    const closeDropbox = () => {
        setIsDropdownOpen(false);
    };

    const handleAuthenticationTypeChange = (typeSelected: string) => {
        setEndpointUrlTestButtonError('');
        setBasicAuthUsernameInputConfig('');
        setBasicAuthPasswordInputConfig('');
        setAuthenticationTypeInputConfig(typeSelected);
        closeDropbox();
    };

    const handleBasicAuthUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndpointUrlTestButtonError('');
        const textInput = event.target.value;
        setBasicAuthUsernameInputConfig(textInput);
    };

    const handleBasicAuthPasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndpointUrlTestButtonError('');
        const textInput = event.target.value;
        setBasicAuthPasswordInputConfig(textInput);
    };

    const handleIgnoreCertificateValidationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndpointUrlTestButtonError('');
        const textInput = event.target.checked;
        SetIgnoreCertificateValidation(textInput);
    };

    const handleDiscardChanges = () => {
        setNameInputConfig(configuration?.name || '');
        SetEndpointUrlInputConfig(urlFromConfig);
        setAuthenticationTypeInputConfig(authFromConfig || AUTHENTICATION_TYPE.NO_AUTHENTICATION);
        setBasicAuthUsernameInputConfig(configuration?.configurationObj.Username || '');
        setBasicAuthPasswordInputConfig(configuration?.configurationObj.Password || '');
        SetIgnoreCertificateValidation(configuration?.configurationObj.IgnoreCertificate || false);
        childrenHandleDiscardChanges && childrenHandleDiscardChanges();
    };

    const testConfigurationData = async () => {
        return await testUrlEndpoint();
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        const testPassed = await testConfigurationData();
        if (testPassed) {
            try {
                setEndpointUrlTestButtonError('');
                const resp = onConfigurationSaved && await onConfigurationSaved(nameInputConfig,
                    { Url: URL_ENDPOINT_PROTOCOL + endpointUrlInputConfig, authType: AuthenticationType[authenticationTypeInputConfig],
                        username: basicAuthUsernameInputConfig, password: basicAuthPasswordInputConfig, ignoreCertificate: ignoreCertificateValidation, ...childConfigurationObject },
                    idConfiguration);
                resp?.id && setIdConfiguration(resp.id);
                onConfigurationChangeCallBack && await onConfigurationChangeCallBack();
                setIsButtonDisabled(true);
                return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
            } catch (error:any) {
                setResponseMessageSuccess(false);
                return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
            }
        } else {
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_TEST };
        }
    };

    return (
        <GenericWebhookStyled data-aid={'generic-webhook-component'}>
            <GenericWebhookNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input type="text" data-aid='nameInput' value={nameInputConfig} placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange} autoFocus={true} disabled={viewOnly}/>
            </GenericWebhookNameWrapper>
            <GenericWebhookEndpointUrlWrapper>
                <GenericWebhookEndpointUrlInputWrapper>
                    <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GENERIC_WEBHOOK.ENDPOINT_URL')}</Typography>
                    <Input type="text" data-aid='urlInput' value={endpointUrlInputConfig} fullWidth={true} onChange={handleEndpointUrlChange}
                        startAdornment={<div>{URL_ENDPOINT_PROTOCOL}</div>} disabled={viewOnly}/>
                </GenericWebhookEndpointUrlInputWrapper>
                <Stack margin={[0,0,0,2]} >
                    <Button size='medium' color='normal' data-aid='testButton'
                        onClick={testConfigurationData} active={true} loading={false}
                        disabled={viewOnly || isTestButtonDisabled }
                    >{t('GENERAL.TEST')}
                    </Button>
                </Stack>
            </GenericWebhookEndpointUrlWrapper>
            <Stack margin={[0,6]}>
                <Typography elementType='h5' color={responseMessageSuccess ? COMPONENT_TEXT_COLOR.SUCCESS : COMPONENT_TEXT_COLOR.ALERT}>{endpointUrlTestButtonError}</Typography>
            </Stack>
            <GenericWebhookAuthenticationWrapper>
                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GENERIC_WEBHOOK.AUTHENTICATION_TYPE')}</Typography>
                <Dropdown
                    label={authenticationTypeInputConfig}
                    open={isDropdownOpen}
                    onStateChange={setIsDropdownOpen}
                    buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { width: '60%' } }}
                    maxHeight={500}
                    placement="bottom-start"
                >
                    <List
                        options={authenticationTypes}
                        disabled={viewOnly}
                    />
                </Dropdown>
            </GenericWebhookAuthenticationWrapper>
            {
                authenticationTypeInputConfig === AUTHENTICATION_TYPE.BASIC &&
                <BasicAuthWrapper>
                    <BasicAuthCredentialsInputWrapper>
                        <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GENERIC_WEBHOOK.USERNAME')}</Typography>
                        <Input type="text" placeholder={t('GENERAL.TYPE_HERE')} fullWidth={true} value={basicAuthUsernameInputConfig}
                            onChange={handleBasicAuthUserInput} disabled={viewOnly}/>
                    </BasicAuthCredentialsInputWrapper>
                    <BasicAuthCredentialsInputWrapper>
                        <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GENERIC_WEBHOOK.PASSWORD')}</Typography>
                        <Input type="password" placeholder={t('GENERAL.PASSWORD_PLACEHOLDER')} fullWidth={true} disabled={viewOnly}
                            value={basicAuthPasswordInputConfig} onChange={handleBasicAuthPasswordInput} />
                    </BasicAuthCredentialsInputWrapper>
                </BasicAuthWrapper>
            }
            <IgnoreCertificateValidationWrapper>
                <Stack margin={[0, 2, 0, 0]}>
                    <Checkbox
                        checked={ignoreCertificateValidation}
                        labelProps={{ color: 'strong' }}
                        label={t('CONFIGURATIONS.GENERIC_WEBHOOK.IGNORE_CERTIFICATE_VALIDATION')}
                        onChange={handleIgnoreCertificateValidationChange}
                        disabled={viewOnly}
                    />
                </Stack>
                <Tooltip content={<Stack spacing={2}><div>{t('CONFIGURATIONS.GENERIC_WEBHOOK.IGNORE_CERTIFICATE_VALIDATION_TOOLTIP')}</div></Stack>} placement="top">
                    <Icon color="normal" name="info" size={12} />
                </Tooltip>
            </IgnoreCertificateValidationWrapper>
            { children }
            { GenericCancelSubmitButtonWrapper({ handleDiscardChanges ,discardChangesButtonHidden, isButtonDisabled, submitForm, viewOnly, responseMessageSuccess }) }

        </GenericWebhookStyled>
    );
};

export default GenericWebhookComponent;