import React from 'react';
import AssociateToOU from './AssociateToOU';
import { IEnvironment } from 'common/interface/environmentsTable';
import ValidatePermissions from './ValidatePermissions/ValidatePermissions';
import MultiDelete from './MultiDelete';
import { EnvironmentTableModalTypeUrlParam, ModalType } from '../EnvironmentsTable/EnvironmentsTable.types';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';

export interface ModalsProps {
    selectedRows: IEnvironment[];
    modalType: ModalType | null;
    closeModal: () => void
    setModalType: (modalType: ModalType) => void;
    resetSelectedRows: () => void;
}

const Modals: React.FC<ModalsProps> = props => {
    const { setModalType, modalType } = props;

    const { params, deleteParams } = useReactRouterQuery();

    React.useEffect(() => {
        const modalTypeFromUrl = params?.[EnvironmentTableModalTypeUrlParam] as ModalType;
        if (modalTypeFromUrl && !modalType) {
            setModalType(modalTypeFromUrl as ModalType);
            deleteParams([EnvironmentTableModalTypeUrlParam]);
        }
    }, [params, deleteParams, modalType, setModalType]);

    switch (props.modalType) {
        case ModalType.AssociateToOU:
            return <AssociateToOU {...props} />;
        case ModalType.ValidatePermissions:
            return <ValidatePermissions {...props} />;
        case ModalType.MultiDelete:
            return <MultiDelete {...props} />;
        default:
            return null;
    }
};

export default Modals;
