import {
    integrationCategory,
    INTEGRATIONS_IDS,
    IntegrationsDefinitionWrapper, IntegrationDataLoader,
} from 'common/module_interface/settings/integrations/consts';
import Cyera from 'assets/integrations/Icons/Cyera.svg';
import CyeraComponent from 'modules/riskManagement/components/Integrations/CyeraIntegration/CyeraComponent';
import { getCyeraIntegrationService } from 'common/module_interface/RiskManagement/Services';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { addOrUpdateCyeraAccount } from './Cyera.utils';
import { CYERA_INTEGRATION_ID } from 'common/module_interface/RiskManagement/integrations/cyeraintegration/CyeraIntegration.consts';
import { ermTrans } from '../../../RiskManagement.utils';
import { getUserService } from 'common/interface/services';
import { CYERA_INTEGRATION_ACTIVE_FEATURE } from '../../ActiveFeatures/ActiveFeatures.consts';

export function initializeCyeraIntegration() {
    const cyeraIntegrationObject: IntegrationsDefinitionWrapper[] = [{
        id: CYERA_INTEGRATION_ID,
        content: {
            id: INTEGRATIONS_IDS.CYERA,
            title: ermTrans('CYERA_INTEGRATION.TITLE'),
            icon: Cyera,
            category: integrationCategory.DSPM,
            configurationComponent: CyeraComponent,
            onDelete: (accountId: string) => getCyeraIntegrationService().deleteCyeraAccount(accountId),
            onSave: (name: string, configurationObj: any, configurationId?: string) => addOrUpdateCyeraAccount(name, configurationObj?.clientId, configurationObj?.secret, configurationId),
        },
        permission: () => getUserService().hasPermission([CYERA_INTEGRATION_ACTIVE_FEATURE.key]),
    }];
    IntegrationsRegistry.addIntegrationsDefinition(cyeraIntegrationObject);
    initializeCyeraIntegrationDataFunction();
}
export function initializeCyeraIntegrationDataFunction() {
    const cyeraConfigurations: Addin<IntegrationDataLoader> = {
        id: CYERA_INTEGRATION_ID,
        content: {
            getData: getCyeraIntegrationService().getCyeraConfigurations
        },
        permission: () => getUserService().hasPermission([CYERA_INTEGRATION_ACTIVE_FEATURE.key]),
    };
    IntegrationsRegistry.addConfigurationDataLoader(cyeraConfigurations);
}
