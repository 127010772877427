import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IIssueExclusion } from '../IssueExclusions.interface';
import SimpleText from 'common/components/ProtectedAssets/Renderers/SimpleText';

import { convertPartialDateRangeToString } from '../../../RiskManagement.options';

export const IssueExclusionDateRangeCellRender: React.FC<ICellRendererParams<IIssueExclusion>> = (params) => {
    const exclusion: IIssueExclusion | undefined = params.data;
    if (!exclusion?.dateRange) {
        return null;
    }

    return <SimpleText value={convertPartialDateRangeToString(exclusion.dateRange)} />;
};