import styled from 'styled-components/macro';

const Wrapper = styled.div`
    height: 100%;
    inset: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding: 20px;
`;

export const ReportTableStyles ={
    Wrapper
};
