import React, { useEffect, useState } from 'react';
import { getOrganizationalUnitService } from 'common/interface/data_services';
import { setOrganizationalUnitId } from '../helpers/azure.reducer';
import { getStoreService } from 'common/interface/services';
import { flattenTree } from 'common/utils/helpFunctions';
import { Stack } from 'common/design-system/components-v2';
import { AgGridReactWrapper, ConfigWrapper } from '../helpers/azure.styled';

const AzureSingleOU: React.FC<any> = () => {
    const dispatch = getStoreService().dispatch;
    const rootOUId = '00000000-0000-0000-0000-000000000000';
    const [ouTree, setOuTree] = useState<any[]>([]);

    const onRowClicked = (row: any)=>{
        row.node.setSelected(true);
        const ouId = row.data? row.data.id : rootOUId;
        dispatch(setOrganizationalUnitId(ouId));
    };

    useEffect(()=>{
        const getAllOU = async ()=>{
            const res = await getOrganizationalUnitService().getAllOrganizationalUnits();
            const flatTree = flattenTree(res[0]);
            setOuTree(flatTree);
        };
        getAllOU();
    }, []);

    const getDataPath = (data: any) => {
        return data.path;
    };

    return (
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" fullWidth spacing={0} padding={[5,0,1,0]} style={{ maxWidth: '600px' }}>
            <ConfigWrapper >
                <AgGridReactWrapper
                    domLayout='autoHeight'
                    rowHeight={28}
                    treeData={true}
                    rowData={ouTree}
                    rowSelection='single'
                    onRowClicked={onRowClicked}
                    getDataPath={getDataPath}
                    columnDefs={[]}
                    defaultColDef={{ flex: 1 }}
                    animateRows={true}
                    className='ag-theme-alpine ag-tree-data ag-header-none'
                    groupDefaultExpanded={1}
                    headerHeight={0}
                    suppressContextMenu={true}
                    autoGroupColumnDef={{
                        cellRendererParams: {
                            suppressCount: true,
                        },
                    }}/>
            </ConfigWrapper>
        </Stack>
    );
};

export default AzureSingleOU;
