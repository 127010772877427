import styled from 'styled-components/macro';
import { Stack } from 'common/design-system/components-v2';
export const TopDiv = styled(Stack)`
    min-height: 335px;
    & > :nth-child(1) {
        flex: 0 0 540px;
    }
    & > :nth-child(2) {
        width: 100%;
        height: 100%;
    }
`;

export const IssuesAndEventsStyled = {
    TopDiv,
};