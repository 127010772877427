import { IActionUsageDef } from 'common/interface/general';
import { ReportsActions } from '../../Reporting.const';

export const ReportsActionTypes: { [key: string]: IActionUsageDef } = {
    CREATE_REPORT: {
        id: ReportsActions.CREATE_REPORT,
    },
    EDIT_REPORT: {
        id: ReportsActions.EDIT_REPORT,
    },
    RUN_REPORTS: {
        id: ReportsActions.RUN_REPORTS,
    },
    DOWNLOAD_REPORTS: {
        id: ReportsActions.DOWNLOAD_REPORTS,
    },
    DELETE_REPORTS: {
        id: ReportsActions.DELETE_REPORTS,
    },
};
