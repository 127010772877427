import i18n from 'i18next';
import { AFTER_LOGIN_EVENT, REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { addMainMenuItem } from 'common/helpers/menu';
import { getUserService } from 'common/interface/services';
import {
    getCurrentDataCenter,
    getRuntimeEnvironmentType,
    RuntimeEnvironmentType,
} from 'common/utils/RuntimeEnvironment';
import AssetOverviewPerimeterExposure from './AssetOverview/AssetOverviewPerimeterExposureGraph';
import Ec2PerimeterExposure from './Ec2PerimeterExposure/Components/Ec2PerimeterExposure';
import EntitiesGraphExplorer from './EntitiesGraphExplorer/Components/EntitiesGraphExplorer';
import InsightWelcomePage from './Welcome/Welcome';
import { PATHS } from './paths.const';
import { SUPPORTED } from './SupportedAssets';
import { RiskManagementPanelRegistry } from 'common/module_interface/RiskManagement/RiskManagementPanelRegistry';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import AssetOverviewInsights from './AssetOverview/AssetOverviewInsights';
import { getErmSupportedAssets } from 'common/module_interface/RiskManagement/DataUtils';
import { SUPPORTED_AI_DATA_CENTERS } from './Suggestions/Services/SuggestionHandler';
import { EvidencesRegistry } from 'common/module_interface/RiskManagement/issues/EvidencesRegistry';
import { IIssue, SystemLabels } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import EvidencesPerimeterExposureGraph from './PerimeterExposure/Components/EvidencesPerimeterExposureGraph';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { insightNamespace } from './consts/i18n';

const INSIGHT_ACTIVE_FEATURE = 'INSIGHT';

function hasPermission(): boolean {
    return getRuntimeEnvironmentType() === RuntimeEnvironmentType.INSPECT ||
        getUserService().hasPermission([INSIGHT_ACTIVE_FEATURE]);
}

function initializeAssetOverviewPanelItems() {
    const perimeterExposureItemId = 'perimeter exposure';
    RiskManagementPanelRegistry.addRisksItemWidget(perimeterExposureItemId, 20, {
        id: perimeterExposureItemId,
        isRelevant: entity => SUPPORTED.ASSET_TYPES.includes(entity.typeByPlatform),
        wrapperClass: 'flex flex-col *bg-aside relative',
        component: AssetOverviewPerimeterExposure,
    });

    const insightsItemId = 'insights';
    RiskManagementPanelRegistry.addRisksItemWidget(insightsItemId, 21, {
        id: insightsItemId,
        isRelevant: entity => SUPPORTED_AI_DATA_CENTERS.includes(getCurrentDataCenter()?.dataCenter ?? '') && SUPPORTED.INSIGHTS_ASSET_TYPES.includes(entity.typeByPlatform),
        wrapperClass: 'flex flex-col *bg-aside relative',
        component: AssetOverviewInsights,
    });
}

function initializeInsightPanelItems() {
    const welcomePageRoute = {
        condition: true,
        component: InsightWelcomePage,
        path: `/${PATHS.INSIGHT}/${PATHS.WELCOME}`,
    };

    const EntitiesGraphExplorerPageRoute = {
        condition: true,
        component: EntitiesGraphExplorer,
        path: `/${PATHS.INSIGHT}/${PATHS.GRAPH_EXPLORER}`,
    };

    const perimeterExposureRoute = {
        condition: true,
        component: Ec2PerimeterExposure,
        path: `/${PATHS.INSIGHT}/${PATHS.PERIMETER_EXPOSURE}/:urlGraphMode?/:urlEnvironmentId?/:urlAssetSrl?`,
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'INSIGHT_WELCOME', content: welcomePageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{
        id: 'ENTITIES_GRAPH_EXPLORER',
        content: EntitiesGraphExplorerPageRoute,
    }]);

    EvidencesRegistry.addItem(20, {
        id: 'evidence_context_graph',
        title: i18n.t('CONTEXT_GRAPH.TITLE', { ns: insightNamespace }),
        isRelevant: (issue: IIssue, asset: IProtectedAssetViewModel) =>
            issue.systemLabels.includes(SystemLabels.NETWORK_EXPOSURE) && SUPPORTED.ASSET_TYPES.includes(asset.typeByPlatform),
        component: EvidencesPerimeterExposureGraph,
    });

    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'ASSET_EXPOSURE', content: perimeterExposureRoute }]);

    // Add main menu items
    addMainMenuItem({
        id: 'insight',
        position: 60,
        icon: 'magnify',
        label: 'NAVIGATION_MENU.INSIGHT.TITLE',
        permission: hasPermission,
        sections: [
            {
                id: 'default',
                position: 10,
                items: [
                    {
                        id: 'welcome',
                        position: 10,
                        label: 'NAVIGATION_MENU.INSIGHT.WELCOME',
                        state: `/${PATHS.INSIGHT}/${PATHS.WELCOME}`,
                        permission: hasPermission,
                    },
                    {
                        id: 'entities-graph-explorer',
                        position: 10,
                        label: 'NAVIGATION_MENU.INSIGHT.ENTITIES_GRAPH_EXPLORER',
                        state: `/${PATHS.INSIGHT}/${PATHS.GRAPH_EXPLORER}`,
                        permission: hasPermission,
                    },
                    {
                        id: 'perimeter-exposure',
                        position: 10,
                        label: 'NAVIGATION_MENU.INSIGHT.ASSET_EXPOSURE',
                        state: `/${PATHS.INSIGHT}/${PATHS.PERIMETER_EXPOSURE}`,
                        urlAliases: [new RegExp(`\\/${PATHS.INSIGHT}\\/${PATHS.PERIMETER_EXPOSURE}\\/?([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\\/?)?([0-9]{1,2}(\\||%7[Cc])[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\\||%7[Cc])rg(\\||%7[Cc])[0-9]{1,3}(\\||%7[Cc])Ec2Instance(\\||%7[Cc])i-[0-9a-fA-F]{8,17})?\\/{0,1}`)],
                        permission: hasPermission,
                    },
                    {
                        id: 'incident-rulesets',
                        position: 20,
                        label: 'NAVIGATION_MENU.INSIGHT.INCIDENT_RULESETS',
                        state: '/insight/rulesets',
                        permission: [INSIGHT_ACTIVE_FEATURE],
                    }
                ],
            },
        ],
    });
}

const setupInitialData = () => {
    globalAddinContainer.add(AFTER_LOGIN_EVENT, [{
        id: 'setup-erm-context-supported-assets',
        content: async () => {
            const supportedAssetsData = await getErmSupportedAssets();
            SUPPORTED.ASSET_TYPES = supportedAssetsData.contextGraphSupportedTypes;
        },
    }]);
};
export default function initialize() {
    setupInitialData();
    initializeInsightPanelItems();
    initializeAssetOverviewPanelItems();
}