import { SortDirection } from 'common/interface/general';
import { GROUPING_COUNT_FIELD_NAME, IServerInputSort, ISSUE_FIELD_NAMES } from './Issues.interface';

export const ISSUES_SEARCH_URL = 'findings/search';
export const ISSUES_GROUPING_URL = 'findings/group';
export const ISSUE_URL_PREFIX = 'findings';
export const ISSUE_VULNERABILITIES_URL = 'security-graph/issues/vulnerabilities';
export const IAM_SENSITIVITY_URL = 'security-graph/issues/iam-sensitivity';
export const MAIN_ENTITIES_URL = 'security-graph/issues/main-entities';
export const MALWARE_URL = 'security-graph/issues/malware';
export const ISSUE_FREE_TEXT_FIELDS = [
    ISSUE_FIELD_NAMES.RULE_TITLE,
    ISSUE_FIELD_NAMES.ENTITY_NAME,
];
export const GROUPING_SORT_BY_COUNT: IServerInputSort = {
    fieldName: GROUPING_COUNT_FIELD_NAME,
    direction: SortDirection.DESC,
};
export const ROOT_ISSUES_GROUP_ID = 'ROOT';
export const IGNORE_CVE_ACTION_COLUMN = 'IGNORE_CVE_ACTION_COLUMN';

export const FIRST_TOXIC_FIELD_NAME = ISSUE_FIELD_NAMES.SEVERITY;
export const SECOND_TOXIC_FIELD_NAME = ISSUE_FIELD_NAMES.RULE_TITLE;
export const TOXIC_GROUP_FIELDS = [FIRST_TOXIC_FIELD_NAME, SECOND_TOXIC_FIELD_NAME];
