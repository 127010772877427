import { AxiosResponse } from 'axios';
import { ICachingConfig, getHttpService } from 'common/interface/services';
import {
    ComplianceFindingComment,
    ComplianceFindingSeverity,
    ComplianceFindingAcknowledge,
    ComplianceFindingAssign,
    ComplianceFindingClose,
    ComplianceFindingArchive,
    ComplianceFindingSearch,
    ComplianceFindingSearchWithCustomAggregations,
    ComplianceFindingGroupsByProperties
} from './complianceFindingApi.interface';

const apiBaseUrl = 'Compliance/Finding';

const addComment: ComplianceFindingComment.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingComment.Response>>(
        `${apiBaseUrl}/bulk/comment`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const changeSeverity: ComplianceFindingSeverity.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingSeverity.Response>>(
        `${apiBaseUrl}/bulk/severity`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const acknowledge: ComplianceFindingAcknowledge.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingAcknowledge.Response>>(
        `${apiBaseUrl}/bulk/acknowledge`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const assign: ComplianceFindingAssign.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingAssign.Response>>(
        `${apiBaseUrl}/bulk/assign`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const close: ComplianceFindingClose.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingClose.Response>>(
        `${apiBaseUrl}/bulk/close`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const archive: ComplianceFindingArchive.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingArchive.Response>>(
        `${apiBaseUrl}/bulk/archive`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const unarchive: ComplianceFindingArchive.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingArchive.Response>>(
        `${apiBaseUrl}/bulk/unarchive`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const search: ComplianceFindingSearch.Function = async (request, cachingConfig?: ICachingConfig) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingSearch.Response>>(
        `${apiBaseUrl}/search`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true, cachingConfig },
        err => { throw err; }
    );
};

const searchWithCustomAggregations: ComplianceFindingSearchWithCustomAggregations.Function = async (request, cachingConfig?: ICachingConfig) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingSearchWithCustomAggregations.Response>>(
        `${apiBaseUrl}/searchWithCustomAggregations`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true, cachingConfig },
        err => { throw err; }
    );
};

const groupsByProperties: ComplianceFindingGroupsByProperties.Function = async (request, cachingConfig?: ICachingConfig) => {
    return await getHttpService().request<AxiosResponse<ComplianceFindingGroupsByProperties.Response>>(
        `${apiBaseUrl}/GroupsByProperties`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true, cachingConfig },
        err => { throw err; }
    );
};


const ComplianceFindingApi = {
    addComment,
    changeSeverity,
    acknowledge,
    assign,
    close,
    archive,
    unarchive,
    search,
    searchWithCustomAggregations,
    groupsByProperties
};

export default ComplianceFindingApi;
