import React from 'react';
import { useSelector } from 'react-redux';
import AlibabaActions from '../alibaba.actions';
import { AlibabaSelector } from '../alibaba.selectors';
import { RootState } from 'common/services/store/store';
import { AlibabaEnvironmentData } from 'common/module_interface/assets/AlibabaEnvironment';

export interface UseGetAlibabaPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isDeleted: boolean;
    data?: AlibabaEnvironmentData;
    reloadData: () => void;
}

export type UseGetAlibaba = (alibabaId: string) => UseGetAlibabaPayload;

export const useGetAlibaba: UseGetAlibaba = (alibabaId) => {
    const { loading, error, data, deleted } = useSelector((state: RootState) => AlibabaSelector.getAlibaba(state, alibabaId)) || {};
    const getData = (id: string) => AlibabaActions.getAlibabaAccount(id);
    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(alibabaId);
        }
    }, [alibabaId, data, loading, error]);
    const reloadData = React.useCallback(() => getData(alibabaId), [alibabaId]);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        isDeleted: !!deleted,
        data,
        reloadData
    });
};