import styled from 'styled-components/macro';

export const BorderContainer = styled.div` 
  .fat-border {
    border-radius: 8px;
    border: 2px solid transparent;
    background: linear-gradient(to right top, #5787fb, #CEBDFF, #5787fb) border-box;
  }
  
  .fat-border-child {
    background-color: #fff;
    border-radius: 6px;
    position: relative;
    
    .collapser {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }

  button.expander {
   svg {
      height: 24px;
      width: 24px;
    }
  }
  
  button {
    svg {
      path {
        fill: rgb(51, 136, 255) !important;
      }
    }
  }
`;