import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { FormValues } from './EditAlibabaNameModal.types';
import { ModalsProps } from '..';
import { Stack } from 'common/design-system/components-v2';
import { useGetAlibaba } from '../../../reducer/hooks/useGetAlibaba';
import { getAlibabaEnvNamespace } from '../../../initialize.i18n';
import { useUpdateAlibaba } from '../../../reducer/hooks/useUpdateAlibaba';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import { getCloudAccountsService } from 'common/interface/data_services';
import Input from 'common/design-system/components-v2/Input';


const EditAlibabaNameModal: React.FC<ModalsProps> = ({ closeModal, alibabaId }) => {
    const { data: alibabaData } = useGetAlibaba(alibabaId);
    const { t } = useTranslation(getAlibabaEnvNamespace('alibaba'));

    const inputValidations: { [input: string]: RegisterOptions } = React.useMemo(() => ({
        name: {
            required: t('alibabaPage.modals.edit.fields.name.validation.required'),
            validate: (value: string) => {
                const isValid = !new RegExp(/(<([^>]+)>)/gi).test(value);
                return isValid || t('alibabaPage.modals.edit.fields.name.validation.validate');
            }, 
        }
    }), [t]);

    const handleOnUpdateSuccess = () => {
        getCloudAccountsService().clearCache();
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('alibabaPage.modals.edit.onSubmit.success'),
            text: '',
        });
        onClose();
    };
    const handleOnUpdateError = (error: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('alibabaPage.modals.edit.onSubmit.error'),
            text: error.message,
        });
    };

    const { updateAlibaba, isLoading: isUpdateLoading } = useUpdateAlibaba({ onError: handleOnUpdateError, onSuccess: handleOnUpdateSuccess });
    const isLoading = isUpdateLoading; 

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<FormValues>({
        defaultValues: {
            name: alibabaData?.account.name
        }
    });
    
    const submitUpdate = async (data: FormValues) => {
        const { name } = data;
        updateAlibaba(alibabaId, { name });
    };

    const onClose = () => {
        closeModal();
    };

    return (
        <ConfirmationModal
            submitBtnText={t('alibabaPage.modals.edit.submit')}
            title={t('alibabaPage.modals.edit.header')}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => submitUpdate(data))}
            isLoading={isLoading}
            width='md'
        >
            <Stack spacing={1}>
                <Controller
                    name='name'
                    rules={inputValidations['name']}
                    control={control}
                    render={({ field }) => (
                        <Stack>
                            <Input
                                label={t('alibabaPage.modals.edit.fields.name.label')}
                                {...field}
                                placeholder={t('alibabaPage.modals.edit.fields.name.placeholder')}
                                helperText={errors['name']? errors['name']['message'] : ''}
                                isError={errors['name'] ? true : false}
                            />
                        </Stack>

                    )}
                />
            </Stack>
        </ConfirmationModal>
    );
};

export default EditAlibabaNameModal;
