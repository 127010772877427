import { Button, Modal, Select } from '@dome9/berries/react-components';
import { CpSpinner } from '@dome9/components/react/components';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { ISelectOption } from 'common/interface/general';
import { deepCloneObject } from 'common/utils/objectUtils';
import { getSectionImagesToPrint, getSelectedDashboard } from 'modules/overview/Overview.reducer';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { i18nOverviewNamespace } from '../../../initialize.i18n';
import ExportToPdf, { PageOrientation, PageSize } from './ExportToPdfPage';
import { SingleValue } from 'react-select';


interface IExportModalProps {
    isExportModalOpen: boolean;
    closeModal: () => void;
}


const DashboardActions: React.FC<IExportModalProps> = ({ isExportModalOpen, closeModal }) => {
    const { t } = useTranslation(i18nOverviewNamespace);
    const selectedDashboard = useSelector(getSelectedDashboard);
    const widgetImagesToPrint = useSelector(getSectionImagesToPrint);


    const [isExportLoading, setIsExportLoading] = useState(true);
    const [documentProperties, setDocumentProperties] = useState({
        pageSize: 'A4' as PageSize,
        orientation: 'landscape' as PageOrientation,
        cols: 1,
        rows: 1
    });

    const pdfDocumentOrientationOptions = [
        {
            label: t('DASHBOARD.EXPORT_TO_PDF.LANDSCAPE'),
            value: 'landscape'
        },
        {
            label: t('DASHBOARD.EXPORT_TO_PDF.PORTRAIT'),
            value: 'portrait'
        }
    ];

    const pdfDocumentSizeOptions = [
        {
            label: 'A4',
            value: 'A4'
        },
        {
            label: 'A3',
            value: 'A3'
        },
        {
            label: t('DASHBOARD.EXPORT_TO_PDF.LETTER'),
            value: 'LETTER'
        }
    ];


    const handleChangePageSize = (option: SingleValue<ISelectOption>)=>{
        const clonedDocumentProperties = deepCloneObject(documentProperties);
        clonedDocumentProperties.pageSize = option?.value! as PageSize;
        setDocumentProperties(clonedDocumentProperties);
    };

    const handleChangePageOrientation = (option: SingleValue<ISelectOption>)=>{
        const clonedDocumentProperties = deepCloneObject(documentProperties);
        clonedDocumentProperties.orientation = option?.value! as PageOrientation;
        setDocumentProperties(clonedDocumentProperties);
    };

    const handleChangeNumberOfRows = (event: ChangeEvent<HTMLInputElement>)=>{
        const clonedDocumentProperties = deepCloneObject(documentProperties);
        clonedDocumentProperties.rows = event.target.valueAsNumber;
        setDocumentProperties(clonedDocumentProperties);
    };

    const handleChangeNumberOfCols = (event: ChangeEvent<HTMLInputElement> )=>{
        const clonedDocumentProperties = deepCloneObject(documentProperties);
        clonedDocumentProperties.cols = event.target.valueAsNumber;
        setDocumentProperties(clonedDocumentProperties);
    };

    useEffect(()=>{
        setIsExportLoading(true);
        const expandedSections = selectedDashboard.sections?.filter(section => section.isExpanded && section.widgets?.length);
        if(expandedSections?.length && widgetImagesToPrint.length >= expandedSections?.length){
            setIsExportLoading(false);
        }
    }, [widgetImagesToPrint, selectedDashboard]);

    
    return (
        <Modal.ModalDialog isOpen={isExportModalOpen} onRequestClose={()=> closeModal()} width={'lg'} shouldCloseOnOverlayClick>
            <Modal.ModalHeader title={t('DASHBOARD.EXPORT_TO_PDF.TITLE')}
                onClose={()=>closeModal()} />
            <Modal.ModalContent>
                <div className='flex'>
                    <div className='flex-1 mr-8 space-y-7'>
                        <div>
                            <div className='font-semibold mb-2'>
                                { t('DASHBOARD.EXPORT_TO_PDF.SELECT_PAGE_SIZE')}
                            </div>
                            <Select
                                required={false}
                                value={pdfDocumentSizeOptions?.filter(option => option.value === documentProperties.pageSize)}
                                onChange={(option: SingleValue<ISelectOption>) => handleChangePageSize(option)}
                                closeMenuOnSelect={true}
                                placeholder={'Select Source'}
                                isSearchable={true}
                                options={pdfDocumentSizeOptions}
                            />
                        </div>
                        <div>
                            <div className='font-semibold mb-2'>
                                { t('DASHBOARD.EXPORT_TO_PDF.SELECT_PAGE_ORIENTATION')}
                            </div>
                            <Select
                                required={false}
                                value={pdfDocumentOrientationOptions?.filter(option => option.value === documentProperties.orientation )}
                                onChange={(option: SingleValue<ISelectOption>) => handleChangePageOrientation(option)}
                                closeMenuOnSelect={true}
                                placeholder={'Select Source'}
                                isSearchable={true}
                                options={pdfDocumentOrientationOptions}
                            />
                        </div>
                        <div>
                            <div className='font-semibold mb-2'>
                                {`${t('DASHBOARD.EXPORT_TO_PDF.NUMBER_OF_ROWS')} (${documentProperties.rows})` }
                            </div>
                            <input type="range" value={documentProperties.rows} max={5} min={1} onChange={handleChangeNumberOfRows}></input>
                        </div>
                                    
                        <div>
                            <div className='font-semibold mb-2'>
                                {`${t('DASHBOARD.EXPORT_TO_PDF.NUMBER_OF_COLUMNS')} (${documentProperties.cols})` }                                
                            </div>
                            <input type="range" value={documentProperties.cols} max={10} min={1} onChange={handleChangeNumberOfCols}></input>
                        </div>
                    </div>
                    <div className='flex-1'>
                        <PDFViewer className='w-full h-full'>
                            <ExportToPdf selectedDashboard={selectedDashboard} widgetImagesToPrint={widgetImagesToPrint} documentProperties={documentProperties} fileName={selectedDashboard.name} />
                        </PDFViewer>
                    </div>
                </div>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <div className='modal__footer-actions justify-between flex w-full'>
                    <div className='flex-1'>
                        {isExportLoading &&
                            <div className='flex items-center justify-start'>
                                <CpSpinner />
                                <span className='ml-6'>
                                    { t('DASHBOARD.EXPORT_TO_PDF.LOADING_MESSAGE')}
                                </span>
                            </div>
                        }
                    </div>
                    <div className='flex flex-1 justify-end'>
                        <Button className='mr-6' onClick={()=>closeModal()}>{t('DASHBOARD.CANCEL')}</Button>
                        <PDFDownloadLink document={<ExportToPdf selectedDashboard={selectedDashboard} widgetImagesToPrint={widgetImagesToPrint} documentProperties={documentProperties} />} fileName={selectedDashboard.name}>
                            {() => (
                                <Button disabled={isExportLoading} color='primary' onClick={()=> closeModal()}>
                                    <span className='uppercase'>
                                        {t('DASHBOARD.EXPORT_TO_PDF.DOWNLOAD')}
                                    </span>
                                </Button>
                            )}
                        </PDFDownloadLink>                            
                    </div>
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default DashboardActions;