import React from 'react';
import IntelligenceAssetNode from '../Components/IntelligenceAssetNode';
import { Entity } from '../Models/GraphModels';
import { AssetNodeProps } from 'common/components/Graph/Models/AssetNodeProps';
import InternetNode from '../../NetworkTraffic/Components/InternetNode';
import ServiceNode from '../../NetworkTraffic/Components/ServiceNode';
import MaliciousNode from '../../NetworkTraffic/Components/MaliciousNode';

export enum IntelligenceNodeType {
    DefaultAssetNode = 'DefaultAssetNode',
    InternetNode = 'InternetNode',
    ServiceNode = 'ServiceNode',
    MaliciousNode = 'MaliciousNode'
}

enum NetworkNodeTypes {
    Service = 'service',
    Malicious = 'malicious',
    Internet = 'internet',
    DefaultAssetNode = 'defaultassetnode'
}

export const nodeTypes: Record<IntelligenceNodeType, React.FC<AssetNodeProps>> = {
    [IntelligenceNodeType.DefaultAssetNode]: IntelligenceAssetNode,
    [IntelligenceNodeType.InternetNode]: InternetNode,
    [IntelligenceNodeType.ServiceNode]: ServiceNode,
    [IntelligenceNodeType.MaliciousNode]: MaliciousNode
};

export function getIntelligenceNodeType(entity: Entity): IntelligenceNodeType {
    const entityType = entity.type.toLowerCase();
    const intelligenceNodeType = entity.additionalData?.intelligenceNodeType?.toLowerCase();

    if (intelligenceNodeType === NetworkNodeTypes.Service){
        return IntelligenceNodeType.ServiceNode;
    }

    if (intelligenceNodeType === NetworkNodeTypes.Malicious){
        return IntelligenceNodeType.MaliciousNode;
    }

    if (entityType === NetworkNodeTypes.Internet) {
        return IntelligenceNodeType.InternetNode;
    }

    if (entityType === NetworkNodeTypes.DefaultAssetNode) {
        return IntelligenceNodeType.DefaultAssetNode;
    }

    return IntelligenceNodeType.DefaultAssetNode;
}
