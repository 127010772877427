import { FunctionComponent } from 'react';

export enum PromptType {
    NetworkTraffic = 'NetworkTraffic'
}

export enum SuggestionFormat {
    Text = 'text',
    Html = 'html',
    Json = 'json'
}

export interface Prompt {
    promptId: string;
    text: string;
}

export type DisplayPrompt = Prompt & {
    suggest: () => Promise<ResponseElement[]>
}

export interface PromptRequestModel {
    promptType: PromptType;
    model?: unknown;
}

export interface SuggestionRequestModel {
    promptId: string;
    suggestionFormat: SuggestionFormat;
    model?: unknown;
}

export interface SuggestionPopupProps {
    user: string;
    displayedPrompts: DisplayPrompt[];
    suggestions: ResponseElement[][];
    setSuggestions: (suggestions: ResponseElement[][]) => void;
    onXClick: () => void;
    title: string;
    subtitleParts?: (string | undefined)[];
    headerIcon: FunctionComponent<{ alt: string; className?:string }>;
}

export interface ResponseElement {
    heading?: string;
    subheading?: string;
    table?: string[][];
    lines?: string[];
    numberedLines?: string[];
}

export interface ResponseGeneratorProps {
    response: ResponseElement[];
    styleClasses?: ResponseElement;
}