import { UserRolesTypes } from 'common/enum/UserRoles';
import { APPLICATION_REDUCERS, REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { addMainMenuItem, addMenuSectionItem } from 'common/helpers/menu';
import { getUserService } from 'common/interface/services';
import initializeIntelligenceFilters from './Definitions/FilterDefinitions';
import initializeNetworkTrafficPageFilters from './NetworkTrafficPage/filters';
import initializeMagellanPanels from './Panels/MagellanPanels';
import initializeThreatAndSecurityEventsPanel from './Panels/ThreatAndSecurityEventsPanel';
import initializeIntelligenceService from './Services/IntelligenceService';
import initializeMagellanLinkGeneratorService from './Services/MagellanLinkGeneratorService';
import initializeMagellanSchemaService from './Services/MagellanSchemaService';
import Welcome from './Welcome/Welcome';
import { initialize_AWS_EventActivityWidgets } from './Widgets/Initialize_AWS_EventActivityWidgets';
import { initialize_AWS_TrafficActivityWidgets } from './Widgets/Initialize_AWS_TrafficActivityWidgets';
import { initialize_AZURE_EventActivityWidgets } from './Widgets/Initialize_AZURE_EventActivityWidgets';
import { initialize_AZURE_TrafficActivityWidgets } from './Widgets/Initialize_AZURE_TrafficActivityWidgets';
import { initialize_K8_TrafficActivityWidgets } from './Widgets/Initialize_K8_TrafficActivityWidgets';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { initialAwsEnvironmentTabs } from './initialize.awsEnv';
import { initializeAzureIntelligenceOnboardingPages } from './Onboarding/Azure/InitializeAzureOnboarding';
import { IApplicationReducer } from 'common/interface/redux';
import visualizationGraphStateReducer from './Visualizations/Common/Services/GraphState.reducer';
import { isNotChina } from 'common/utils/dataCenter';
import CdrEventsPage from './Findings/CdrEvents/CdrEventsPage';
import { InitializeEnvironmetsDefs } from './EnvironmetsDefs/initialize.envDefs';
import initializeFindings from './Findings/Findings.initialize';
import i18n from 'i18next';
import { i18nIntelligenceNamespace } from './initialize.i18n';
import AllEventsPage from './Findings/AllEvents/AllEventsPage';
import initializePolicy from './Policy/initialize';
import initializeCloudBotsService from './Services/CloudBotsService';
import {
    ALL_EVENTS_TABLE_ACTIVE_FEATURE,
    CDR_DASHBOARD_ACTIVE_FEATURE,
    CDR_EVENTS_ACTIVE_FEATURE,
    CDR_EXCLUSIONS_ACTIVE_FEATURE,
} from './ActiveFeatures/ActiveFeatures.consts';
import initializeActiveFeatures from './ActiveFeatures/ActiveFeatures.initialize';
import initializeRemediationService from './Services/RemediationService';
import CdrDashboard from './Dashboard/CdrDashboard';
import initializeGslService from 'common/erm-components/services/gsl/GslService';
import initializeFindingGslService from './Services/FindingGslService';
import initializeCdrExclusion from './Findings/FindingsTable/Modals/CdrExclusion/CdrExclusion.initialize';
import { CdrExclusionsPage } from './Findings/CdrExclusions/CdrExclusionsPage';
import { newEventsPageCurrentView, newEventsPageFeatureFlagName } from 'common/interface/events';
import { isExclusionsEnabled, isPolicyEnabled, isRemediationEnabled, isRulesetEnabled } from 'common/utils/userPermissionUtils';

const SECURITY_ALERTS = 'security-alerts';
export default function initialize() {
    const welcomePageRoute = () => {
        return {
            condition: getUserService().hasPermission([UserRolesTypes.ALL]),
            component: Welcome,
            path: '/magellan/intelligence/welcome-page',
        };
    };

    const cdrEventsPageRoute = () => {
        return {
            condition: getUserService().hasPermission([UserRolesTypes.ALL]),
            component: CdrEventsPage,
            path: '/cdr-events',
        };
    };

    function initializeCrdEventsTable() { // TODO: remove when old events page is removed
        addMenuSectionItem('events', 'default', {
            id: 'intelligence-findings-page',
            position: 25,
            label: i18n.t('NAVIGATION_MENU.EVENTS.CDR_EVENTS', { ns: i18nIntelligenceNamespace }),
            state: '/cdr-events',
            chip: {
                label: i18n.t('GENERAL.PREVIEW')
            },
            permission: () =>
                isNotChina() &&
                getUserService().hasPermission([CDR_EVENTS_ACTIVE_FEATURE.key]) &&
                (!getUserService().hasPermission([newEventsPageFeatureFlagName]) || localStorage.getItem(newEventsPageCurrentView) === 'false'),
        });
    }

    const cdrExclusionsPageRoute = () => {
        return {
            condition: getUserService().hasPermission([UserRolesTypes.ALL]),
            component: CdrExclusionsPage,
            path: '/cdr-exclusions',
        };
    };

    function initializeCrdExclusionsTable() {
        addMenuSectionItem('events', 'default', {
            id: 'cdr-exclusions-page',
            position: 26,
            label: 'CDR Exclusions',
            state: '/cdr-exclusions',
            chip: {
                label: i18n.t('GENERAL.PREVIEW')
            },
            permission: () => isNotChina() && getUserService().hasPermission([CDR_EXCLUSIONS_ACTIVE_FEATURE.key]),
        });
    }

    const allEventsPageRoute = () => {
        return {
            condition: () => getUserService().hasPermission([UserRolesTypes.ALL]),
            component: AllEventsPage,
            path: '/all-events',
        };
    };

    const dashboardRoute = () => {
        return {
            condition: getUserService().hasPermission([CDR_DASHBOARD_ACTIVE_FEATURE.key]),
            component: CdrDashboard,
            path: '/cdr-dashboard'
        };
    };

    function initializeAllEventsTable() {
        addMenuSectionItem('events', 'default', {
            id: 'all-events-page-kusto',
            position: 28,
            label: i18n.t('NAVIGATION_MENU.EVENTS.ALL_EVENTS', { ns: i18nIntelligenceNamespace }),
            state: '/all-events',
            chip: {
                label: i18n.t('GENERAL.PREVIEW')
            },
            permission: () => isNotChina() && getUserService().hasPermission([ALL_EVENTS_TABLE_ACTIVE_FEATURE.key]),
        });
    }

    function initializeReducers() {
        const reducers: IApplicationReducer[] = [
            { name: 'visualizationGraphState', reducer: visualizationGraphStateReducer, isBlackList: true },
        ];
        globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');
    }

    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        { id: 'intelligence welcome', content: welcomePageRoute },
        { id: 'cdr events page', content: cdrEventsPageRoute as any },
        { id: 'cdr exclusions page', content: cdrExclusionsPageRoute },
        { id: 'all events page', content: allEventsPageRoute },
        { id: 'cdr dashboard', content: dashboardRoute },
    ]);

    addMainMenuItem(
        {
            id: 'intelligence',
            position: 90,
            icon: 'microscope',
            label: i18n.t('NAVIGATION_MENU.THREAT.TITLE', { ns: i18nIntelligenceNamespace }),
            permission: [UserRolesTypes.ALL],
            sections: [
                {
                    id: 'default',
                    position: 10,
                    items: [
                        {
                            id: 'welcome',
                            position: 10,
                            label: i18n.t('NAVIGATION_MENU.THREAT.WELCOME', { ns: i18nIntelligenceNamespace }),
                            state: '/magellan/intelligence/welcome-page',
                            permission: [UserRolesTypes.ALL],
                        },
                        {
                            id: 'cdr dashboard',
                            position: 20,
                            label: i18n.t('NAVIGATION_MENU.THREAT.CDR_DASHBOARD', { ns: i18nIntelligenceNamespace }),
                            state: '/cdr-dashboard',
                            permission: [CDR_DASHBOARD_ACTIVE_FEATURE.key],
                            chip: {
                                label: i18n.t('GENERAL.PREVIEW')
                            },
                        },
                    ],
                },
                {
                    id: 'threat monitoring',
                    position: 30,
                    title: i18n.t('NAVIGATION_MENU.THREAT.THREAT_MONITORING', { ns: i18nIntelligenceNamespace }),
                    items: [
                        {
                            id: 'rulesets',
                            position: 10,
                            label: i18n.t('NAVIGATION_MENU.THREAT.RULESETS', { ns: i18nIntelligenceNamespace }),
                            state: '/magellan/rulesets',
                            urlAliases: [/^\/magellan\/ruleset\/.+/, '/magellan/ruleset'],
                            permission: () => isRulesetEnabled(),
                        },
                        {
                            id: 'policies',
                            position: 20,
                            label: i18n.t('NAVIGATION_MENU.THREAT.POLICIES', { ns: i18nIntelligenceNamespace }),
                            state: '/magellan/rulesets-assignment',
                            permission: () => isPolicyEnabled(),
                        },
                        {
                            id: 'exclusions',
                            position: 30,
                            label: i18n.t('NAVIGATION_MENU.THREAT.EXCLUSIONS', { ns: i18nIntelligenceNamespace }),
                            state: '/magellan/exclusion',
                            permission: () => isExclusionsEnabled(),
                        },
                        {
                            id: 'remediations',
                            position: 40,
                            label: i18n.t('NAVIGATION_MENU.THREAT.REMEDIATIONS', { ns: i18nIntelligenceNamespace }),
                            state: '/magellan/remediation',
                            permission: () => isNotChina() && isRemediationEnabled(),
                        },
                        {
                            id: 'securityAlerts',
                            position: 40,
                            label: i18n.t('NAVIGATION_MENU.THREAT.SECURITY_ALERTS', { ns: i18nIntelligenceNamespace }),
                            state: '/magellan/security-alerts/log-table',
                            permission: [SECURITY_ALERTS],
                        },
                    ],
                },
            ],
        },
    );

    initializeIntelligenceService();
    initializeMagellanLinkGeneratorService();
    initializeMagellanSchemaService();
    initializeCloudBotsService();
    initializeRemediationService();
    initializeGslService();
    initializeFindingGslService();
    initializeIntelligenceFilters();
    initializeNetworkTrafficPageFilters();
    initializeMagellanPanels();
    initializeThreatAndSecurityEventsPanel();
    initialize_AWS_TrafficActivityWidgets();
    initialize_AWS_EventActivityWidgets();
    initialize_AZURE_EventActivityWidgets();
    initialize_AZURE_TrafficActivityWidgets();
    initialize_K8_TrafficActivityWidgets();
    initialAwsEnvironmentTabs();
    initializeAzureIntelligenceOnboardingPages();
    initializeReducers();
    initializeFindings();
    InitializeEnvironmetsDefs();
    initializeCrdEventsTable();
    initializeCrdExclusionsTable();
    initializeAllEventsTable();
    initializePolicy();
    initializeActiveFeatures();
    initializeCdrExclusion();
    // Alibaba widgets are not working in angular
    // initialize_ALIBABA_EventActivityWidgets();
}
