import React from 'react';
import SsoFailedLoginForm from './SsoFailedLoginForm';
import LoginInfinityPanel from './LoginInfinityPanel';
import { createUseStyles } from 'react-jss';
import { LegalNotesFooter } from '../../../common/components/Forms/LegalNotesFooter';
import CheckpointLogoHeader from 'common/components/Forms/CheckpointLogoHeader';

export const SsoFailedLogin: React.FC<{}> = () => {
    const useStyles = createUseStyles({
        SsoFailedLoginPage: {
            overflow: 'auto',
            height: '100%',
            '& .mainContent': {
                margin: '1rem auto 0',
                border: '1px solid #e0e4e8',
                display: 'flex',
                alignItems: 'stretch',
                background: '#fff',
                color: '#777',
                width: '60%',
            },
        },
        '@media screen and (max-width: 1024px)': {
            SsoFailedLoginPage: {
                '& .mainContent': {
                    margin: '1rem  2rem',
                    width: 'auto',
                    flexDirection: 'column',
                },
            },
        },
    });

    const classes = useStyles();
    return (
        <div className={classes.SsoFailedLoginPage}>
            <CheckpointLogoHeader />
            <div className='mainContent'>
                <SsoFailedLoginForm />
                <LoginInfinityPanel />
            </div>
            <LegalNotesFooter />
        </div>
    );
};
