import { TextField } from '@dome9/berries/react-components';
import { debounce } from 'common/utils/functions';
import { ChangeEvent, FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberAccountArn, getMemberAccountFormValid, getOrgOnboardingMemberAccountConfigurations, setMemberAccountArn } from './helpers/aws.reducer';
import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import { AdminLink } from './helpers/aws.const';
import { CpCopyToClipboard } from '@dome9/components/react/components';
import CftModal from '../components/CftModal';

const AWSOrgMemberAccount:FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const memberAccountConfigs = useSelector(getOrgOnboardingMemberAccountConfigurations);
    
    const memberAccountArn = useSelector(getMemberAccountArn);
    const formValidation = useSelector(getMemberAccountFormValid);
    const intructionsLink = getIsCloudInfra() ? 
        AdminLink.CloudInfra : 
        AdminLink.StandAlone;
    return <div 
        className='aws-onboarding-member-account'
        data-aid='member-account-cft-modal'
    >
        <div className='title-large mb-8'>{t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.HEADER_TEXT')}</div>
        <Trans
            components={{
                a: <a 
                    target="_blank" 
                    rel='noreferrer' 
                    href={intructionsLink}>
                </a>,
            }}
        >
            {t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.FOLLOW_STACKSET_CREATION_INTRUCTIONS')}
        </Trans>
        <ul className='list-decimal mx-8'>
            <li>
                <Trans
                >
                    {t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.SPECIFY_TEMPLATE')}
                </Trans>
                <ol className='list-disc ml-8'>
                    <li>
                        <Trans
                            components={{
                                'cft-button': <span
                                    onClick={() => {
                                        setIsPopupOpen(true);
                                    }}
                                    className='aws-onboarding-account-configurations-list-cft pointer' 
                                    data-aid='member-account-cft-button'
                                />,
                            }}
                        >
                            {t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.UPLOAD_TEMPLATE')}
                        </Trans>
                    </li>
                    <li>
                        <Trans
                            components={{
                                'copy-s3-url': <CpCopyToClipboard 
                                    className='inline-block align-middle' 
                                    text={memberAccountConfigs?.onboardingCftUrl || ''} 
                                    data-aid='copy-member-account-cft-url'
                                />,
                            }}
                        >
                            {t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.PASTE_S3_URL')}
                        </Trans>
                    </li>
                </ol>
            </li>
            <li>
                <Trans
                    components={{
                        'copy-external-id': <CpCopyToClipboard 
                            className='inline-block align-middle' 
                            text={memberAccountConfigs?.externalId || ''} 
                            data-aid='copy-member-account-external-id'
                        />,
                        b: <strong />
                    }}
                    values={{ externalId: memberAccountConfigs?.externalId || '' }}
                >
                    {t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.PASTE_EXTERNAL_ID')}
                </Trans>
            </li>
            <li>
                <Trans 
                    components={{ 
                        sp: <span />,
                        b: <strong />, 
                        'arn-input': <TextField 
                            name='memberRoleArn'
                            value={memberAccountArn} 
                            onChange={debounce((ev: ChangeEvent<HTMLInputElement>) => dispatch(setMemberAccountArn(ev.target.value)))}
                            onClear={() => { dispatch(setMemberAccountArn()); }}
                            className='input-field w-2/4 my-6'
                            state={formValidation.errors?.memberRoleArn ? 'error' : 'info'}
                            helper={formValidation.errors?.memberRoleArn ? formValidation.errors?.memberRoleArn[0]?.message : undefined}
                        />
                    }}>
                    {t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.INPUT_ARN')}
                </Trans>
            </li>
        </ul>
        {
            memberAccountConfigs &&
            <CftModal 
                open={isPopupOpen}
                onClose={() => {
                    setIsPopupOpen(false);
                }}
                title={t('ON_BOARDING.ACCOUNT.MODAL_TITLE')}
                cfts={[{ 
                    content: memberAccountConfigs.content, 
                    type: t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.MEMBER_ACCOUNT'), 
                    url: memberAccountConfigs.onboardingCftUrl,
                    fileName: `CloudGuard_${t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.MEMBER_ACCOUNT')}_CFT.yaml`
                }]}
            />
        }
    </div>;
};

export default AWSOrgMemberAccount;