import { isEmptyString } from 'common/utils/helpFunctions';
import { IntelligenceNodeData } from 'common/module_interface/intelligence/intelligenceNodeData';
import { NodeDetailsItem } from '../Common/Components/NodeDetailsComponent';
import i18n from 'i18next';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';

export function getNetworkNodePopoverDetailsList(props: IntelligenceNodeData): NodeDetailsItem[]{
    const ip = props.additionalData?.ip;
    const region = props.additionalData?.region;
    const countryName = props.additionalData?.geoLocation?.countryName;
    const countryCode = props.additionalData?.geoLocation?.countryCode;

    return [ { label: i18n.t('INT_GRAPH.NODE_DETAILS.IP', { ns: i18nIntelligenceNamespace }), value: ip, isRelevant: !isEmptyString(ip) },
        { label: i18n.t('INT_GRAPH.NODE_DETAILS.COUNTRY_NAME', { ns: i18nIntelligenceNamespace }), value: countryName, isRelevant: !isEmptyString(countryName) },
        { label: i18n.t('INT_GRAPH.NODE_DETAILS.COUNTRY_CODE', { ns: i18nIntelligenceNamespace }), value: countryCode, isRelevant: !isEmptyString(countryCode) },
        { label: i18n.t('INT_GRAPH.NODE_DETAILS.REGION', { ns: i18nIntelligenceNamespace }), value: region, isRelevant: !isEmptyString(region) }];

}
