import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import { ermTrans } from 'modules/riskManagement/RiskManagement.utils';
import { IssueSeverityCellRender } from '../../../../../../../renderers/IssueSeverityCellRender';
import { MALWARE_FIELD_NAMES, MalwareInfo } from '../Malware.interface';
import { ExternalLinkHeaderRenderer } from '../../../../../headerRenderers/ExternalLinkHeaderRenderer';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import ExternalLinkRenderer from 'common/components/ag-grid/Renderers/ExternalLinkRenderer';

export const initializeMalwareTableColumns = () => {
    const columnDefs: CGColDef[] = [
        {
            colId: MALWARE_FIELD_NAMES.MALWARE_ID,
            field: MALWARE_FIELD_NAMES.MALWARE_ID,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.MALWARE_ID'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.MALWARE_ID'),
            flex: 3
        },
        {
            colId: MALWARE_FIELD_NAMES.SEVERITY,
            field: MALWARE_FIELD_NAMES.SEVERITY,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.SEVERITY'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.SEVERITY'),
            cellRenderer: IssueSeverityCellRender,
            width: 120,
        },
        {
            colId: MALWARE_FIELD_NAMES.FAMILY,
            field: MALWARE_FIELD_NAMES.FAMILY,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.FAMILY'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.FAMILY'),
            flex: 1,
        },
        {
            colId: MALWARE_FIELD_NAMES.HASH,
            field: MALWARE_FIELD_NAMES.HASH,
            valueGetter: (dataContainer: ValueGetterParams<MalwareInfo>) => (
                {
                    label: dataContainer?.data?.hash,
                    url: 'https://www.virustotal.com'.concat(`/gui/search/${dataContainer?.data?.hash}` ?? '')
                }),
            cellRenderer: ExternalLinkRenderer,
            headerComponent: ExternalLinkHeaderRenderer,
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.HASH.TITLE'),
            headerComponentParams: {
                headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.HASH.TITLE'),
                tooltipText: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.HASH.EXTENDED_TOOLTIP'),
                url: 'https://www.virustotal.com',
            },
            flex: 2,
        },
        {
            colId: MALWARE_FIELD_NAMES.FILE_PATH,
            field: MALWARE_FIELD_NAMES.FILE_PATH,
            headerName: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.FILE_PATH'),
            headerTooltip: ermTrans('ISSUES.ISSUE_VIEWER.PANELS.EVIDENCE.MALWARE_TABLE.COLUMNS.FILE_PATH'),
            flex: 2,
        },
    ];

    IssuesRegistry.addMalwareColumnDefs(columnDefs, 'colId');
};
