import React, { useEffect, useState } from 'react';
import InfoPane, { InfoPaneFieldProps, InfoPanelActionProps } from 'common/components/InfoPane/InfoPane';
import { useLocation } from 'react-router-dom';
import {
    getEntityActions,
    getEntityFields,
    getProtectedAssetsService,
} from 'common/module_interface/assets/ProtectedAssets';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';


const AssetDetailsPane: React.FC<{ entity: ICloudEntityData | null }> = ({ entity }) => {
    const location = useLocation();
    const [title, setTitle] = useState<string>('');
    const [iconName, setIconName] = useState<string>('');
    const [fields, setFields] = useState<InfoPaneFieldProps[]>([]);
    const [actions, setActions] = useState<InfoPanelActionProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {

        const getAndSetFields = async (entity: ICloudEntityData) => {
            const fields = await getEntityFields(entity, ProtectedAssetsPageRegistry.getFields());
            setFields(fields);
        };

        const getAndSetActions = async (entity: ICloudEntityData) => {
            const actions = await getEntityActions(entity);
            setActions(actions);
        };

        if (entity === null) {
            setIsLoading(true);
            return;
        }
        setIsLoading(false);
        setTitle(entity.protectedAsset.name || entity.entityId);

        const asset = getProtectedAssetsService().getAssetByType(entity.typeByPlatform);
        setIconName(asset?.icon || 'error');
        getAndSetActions(entity);
        getAndSetFields(entity);

    }, [location, entity]);

    let actualTitle = title;
    if (isLoading) {
        actualTitle = 'Loading...';
    }
    return (<>
        <InfoPane title={actualTitle} icon={iconName} fields={fields} actions={actions} />
    </>);
};

export default AssetDetailsPane;