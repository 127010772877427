export const SECURITY_CONTROLS_RESOURCE_URL = 'security-graph/rules';
export const SECURITY_CONTROLS_PREFIX_ID = 'SECURITY_CONTROLS';

export enum SECURITY_CONTROL_FIELD_NAMES {
    ID = 'id',
    NAME = 'name',
    DESCRIPTION = 'description',
    SYSTEM_LABELS = 'systemLabels',
    PLATFORMS = 'platforms',
    SEVERITY = 'severity',
    FRAMEWORKS = 'frameworks',
    REMEDIATION = 'remediation',
    ENTITY_LABELS = 'entityLabels',
}