import { Vendors } from 'common/consts/vendors';
import {
    ICloudBot,
    ICloudBotDefinition,
    ICloudBotParameter,
    ICloudBotParameterOptions
} from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { CloudBotsRegistry } from 'common/module_interface/intelligence/CloudBots/CloudBotsRegistry';
import { CloudBotKeys, CloudBotParameterType } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';
import { TFunction } from 'i18next';

const getCloudBotsDefinitions = (): ICloudBotDefinition[] => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return [
        {
            id: CloudBotKeys.CUSTOM,
            name: t('CLOUD_BOTS.DICTIONARY.CUSTOM.TITLE'),
            platform: [Vendors.AWS, Vendors.AZURE, Vendors.GOOGLE, Vendors.GCP],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CUSTOM.PARAMETERS.CLOUD_BOT.TITLE'),
                    order: 1,
                    required: true,
                    helperText: t('CLOUD_BOTS.DICTIONARY.CUSTOM.PARAMETERS.CLOUD_BOT.HELPER_TEXT'),
                }
            ]
        },
        {
            id: CloudBotKeys.ACM_DELETE_CERTIFICATE,
            name: t('CLOUD_BOTS.DICTIONARY.ACM_DELETE_CERTIFICATE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.AMI_SET_TO_PRIVATE,
            name: t('CLOUD_BOTS.DICTIONARY.AMI_SET_TO_PRIVATE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.CLOUDTRAIL_ENABLE,
            name: t('CLOUD_BOTS.DICTIONARY.CLOUDTRAIL_ENABLE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CLOUDTRAIL_ENABLE.PARAMETERS.TRAIL_NAME.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CLOUDTRAIL_ENABLE.PARAMETERS.BUCKET_NAME.TITLE'),
                    order: 2,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.CLOUDTRAIL_ENABLE_LOG_FILE_VALIDATION,
            name: t('CLOUD_BOTS.DICTIONARY.CLOUDTRAIL_ENABLE_LOG_FILE_VALIDATION.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.CLOUDTRAIL_SEND_TO_CLOUDWATCH,
            name: t('CLOUD_BOTS.DICTIONARY.CLOUDTRAIL_SEND_TO_CLOUDWATCH.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CLOUDTRAIL_SEND_TO_CLOUDWATCH.PARAMETERS.LOG_GROUP_NAME.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.CLOUDWATCH_CREATE_METRIC_FILTER,
            name: t('CLOUD_BOTS.DICTIONARY.CLOUDWATCH_CREATE_METRIC_FILTER.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CLOUDWATCH_CREATE_METRIC_FILTER.PARAMETERS.EMAIL_ADDRESS.TITLE'),
                    order: 1,
                    required: true,
                    type: CloudBotParameterType.EMAIL
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CLOUDWATCH_CREATE_METRIC_FILTER.PARAMETERS.FILTER_1.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CLOUDWATCH_CREATE_METRIC_FILTER.PARAMETERS.FILTER_2.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CLOUDWATCH_CREATE_METRIC_FILTER.PARAMETERS.FILTER_3.TITLE'),
                    order: 4,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.CONFIG_ENABLE,
            name: t('CLOUD_BOTS.DICTIONARY.CONFIG_ENABLE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CONFIG_ENABLE.PARAMETERS.BUCKET_NAME.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CONFIG_ENABLE.PARAMETERS.BUCKET_REGION.TITLE'),
                    order: 2,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.EC2_ATTACH_INSTANCE_ROLE,
            name: t('CLOUD_BOTS.DICTIONARY.EC2_ATTACH_INSTANCE_ROLE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.EC2_ATTACH_INSTANCE_ROLE.PARAMETERS.ROLE_ARN.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.EC2_ATTACH_SG,
            name: t('CLOUD_BOTS.DICTIONARY.EC2_ATTACH_SG.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.EC2_ATTACH_SG.PARAMETERS.SG_NAME.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.EC2_CREATE_SNAPSHOT,
            name: t('CLOUD_BOTS.DICTIONARY.EC2_CREATE_SNAPSHOT.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.EC2_RELEASE_EIPS,
            name: t('CLOUD_BOTS.DICTIONARY.EC2_RELEASE_EIPS.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.EC2_QUARANTINE_INSTANCE,
            name: t('CLOUD_BOTS.DICTIONARY.EC2_QUARANTINE_INSTANCE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.EC2_STOP_INSTANCE,
            name: t('CLOUD_BOTS.DICTIONARY.EC2_STOP_INSTANCE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.EC2_TERMINATE_INSTANCE,
            name: t('CLOUD_BOTS.DICTIONARY.EC2_TERMINATE_INSTANCE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.EC2_UPDATE_INSTANCE_ROLE,
            name: t('CLOUD_BOTS.DICTIONARY.EC2_UPDATE_INSTANCE_ROLE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.EC2_UPDATE_INSTANCE_ROLE.PARAMETERS.POLICY_ARN.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.ECS_SERVICE_ROLE_DETACH_INLINE_POLICY,
            name: t('CLOUD_BOTS.DICTIONARY.ECS_SERVICE_ROLE_DETACH_INLINE_POLICY.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.IAM_DETACH_POLICY,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_DETACH_POLICY.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.IAM_GENERATE_CREDENTIAL_REPORT,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_GENERATE_CREDENTIAL_REPORT.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.IAM_GROUP_DELETE_INLINE_GROUP,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_GROUP_DELETE_INLINE_GROUP.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.IAM_ROLE_ATTACH_POLICY,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_ROLE_ATTACH_POLICY.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_ROLE_ATTACH_POLICY.PARAMETERS.POLICY_ARN.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.IAM_USER_ATTACH_POLICY,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_USER_ATTACH_POLICY.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_USER_ATTACH_POLICY.PARAMETERS.POLICY_ARN.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.IAM_USER_DEACTIVATE_UNUSED_ACCESS_KEY,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_USER_DEACTIVATE_UNUSED_ACCESS_KEY.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_USER_DEACTIVATE_UNUSED_ACCESS_KEY.PARAMETERS.NO_OF_DAYS.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.IAM_USER_DELETE_INLINE_POLICIES,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_USER_DELETE_INLINE_POLICIES.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_USER_DELETE_INLINE_POLICIES.PARAMETERS.MANAGED_POLICIES_ARN.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.IAM_USER_DISABLE_CONSOLE_PASSWORD,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_USER_DISABLE_CONSOLE_PASSWORD.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.IAM_USER_FORCE_PASSWORD_CHANGE,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_USER_FORCE_PASSWORD_CHANGE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.IAM_QUARANTINE_ROLE,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_QUARANTINE_ROLE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.IAM_QUARANTINE_USER,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_QUARANTINE_USER.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.IAM_ENTITY_CREATE_AND_ATTACH_PERMISSION_BOUNDARY,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_ENTITY_CREATE_AND_ATTACH_PERMISSION_BOUNDARY.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_ENTITY_CREATE_AND_ATTACH_PERMISSION_BOUNDARY.PARAMETERS.POLICY_NAME.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.IAM_ROLE_CLONE_WITH_NON_ENUMERABLE_NAME,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_ROLE_CLONE_WITH_NON_ENUMERABLE_NAME.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.IAM_TURN_ON_PASSWORD_POLICY,
            name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.PARAMETERS.MIN_PASSWORD_LEN.TITLE'),
                    order: 1,
                    required: true,
                    type: CloudBotParameterType.NUMBER
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.PARAMETERS.REQ_SYMBOLS.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.PARAMETERS.REQ_NUMBERS.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.PARAMETERS.REQ_UPPERCASE.TITLE'),
                    order: 4,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.PARAMETERS.REQ_LOWERCASE.TITLE'),
                    order: 5,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.PARAMETERS.ALLOW_CHANGE_PASSWORD.TITLE'),
                    order: 6,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.PARAMETERS.MAX_PASSWORD_AGE.TITLE'),
                    order: 7,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.PARAMETERS.PASSWORD_REUSE_PREVENTION.TITLE'),
                    order: 8,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.IAM_TURN_ON_PASSWORD_POLICY.PARAMETERS.HARD_EXPIRY.TITLE'),
                    order: 9,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.IGW_DELETE,
            name: t('CLOUD_BOTS.DICTIONARY.IGW_DELETE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.KMS_CMK_ENABLE_KEY,
            name: t('CLOUD_BOTS.DICTIONARY.KMS_CMK_ENABLE_KEY.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.KMS_ENABLE_ROTATION,
            name: t('CLOUD_BOTS.DICTIONARY.KMS_ENABLE_ROTATION.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.LAMBDA_DETACH_BLANKET_PERMISSIONS,
            name: t('CLOUD_BOTS.DICTIONARY.LAMBDA_DETACH_BLANKET_PERMISSIONS.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.LAMBDA_ENABLE_ACTIVE_TRACING,
            name: t('CLOUD_BOTS.DICTIONARY.LAMBDA_ENABLE_ACTIVE_TRACING.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.LAMBDA_TAG,
            name: t('CLOUD_BOTS.DICTIONARY.LAMBDA_TAG.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.LAMBDA_TAG.PARAMETERS.KEY.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.LAMBDA_TAG.PARAMETERS.VALUE.TITLE'),
                    order: 2,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.LOAD_BALANCER_ENABLE_ACCESS_LOGS,
            name: t('CLOUD_BOTS.DICTIONARY.LOAD_BALANCER_ENABLE_ACCESS_LOGS.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.MARK_FOR_STOP_EC2_RESOURCE,
            name: t('CLOUD_BOTS.DICTIONARY.MARK_FOR_STOP_EC2_RESOURCE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.MARK_FOR_STOP_EC2_RESOURCE.PARAMETERS.UNIT.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.NETWORK_FIREWALL_ENABLE_LOGGING,
            name: t('CLOUD_BOTS.DICTIONARY.NETWORK_FIREWALL_ENABLE_LOGGING.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.NETWORK_FIREWALL_ENABLE_LOGGING.PARAMETERS.LOGGING_TYPE.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.NETWORK_FIREWALL_ENABLE_LOGGING.PARAMETERS.LOG_DEST_TYPE.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.NETWORK_FIREWALL_ENABLE_LOGGING.PARAMETERS.LOG_DEST.TITLE'),
                    order: 3,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.RDS_QUARANTINE_INSTANCE,
            name: t('CLOUD_BOTS.DICTIONARY.RDS_QUARANTINE_INSTANCE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.ROUTE53DOMAIN_ENABLE_AUTO_RENEW,
            name: t('CLOUD_BOTS.DICTIONARY.ROUTE53DOMAIN_ENABLE_AUTO_RENEW.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.ROUTE53DOMAIN_ENABLE_TRANSFER_LOCK,
            name: t('CLOUD_BOTS.DICTIONARY.ROUTE53DOMAIN_ENABLE_TRANSFER_LOCK.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.S3_BLOCK_ALL_PUBLIC_ACCESS,
            name: t('CLOUD_BOTS.DICTIONARY.S3_BLOCK_ALL_PUBLIC_ACCESS.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.S3_DELETE_ACLS,
            name: t('CLOUD_BOTS.DICTIONARY.S3_DELETE_ACLS.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.S3_DELETE_PERMISSIONS,
            name: t('CLOUD_BOTS.DICTIONARY.S3_DELETE_PERMISSIONS.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.S3_DISABLE_STATIC_WEBSITE_HOSTING,
            name: t('CLOUD_BOTS.DICTIONARY.S3_DISABLE_STATIC_WEBSITE_HOSTING.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.S3_ENABLE_ENCRYPTION,
            name: t('CLOUD_BOTS.DICTIONARY.S3_ENABLE_ENCRYPTION.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.S3_ENABLE_ENCRYPTION.PARAMETERS.ENCRYPTION_TYPE.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.S3_ENABLE_ENCRYPTION.PARAMETERS.ARN.TITLE'),
                    order: 2,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.S3_ENABLE_LOGGING,
            name: t('CLOUD_BOTS.DICTIONARY.S3_ENABLE_LOGGING.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.S3_ENABLE_VERSIONING,
            name: t('CLOUD_BOTS.DICTIONARY.S3_ENABLE_VERSIONING.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.S3_LIMIT_ACCESS,
            name: t('CLOUD_BOTS.DICTIONARY.S3_LIMIT_ACCESS.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.S3_ONLY_ALLOW_SSL,
            name: t('CLOUD_BOTS.DICTIONARY.S3_ONLY_ALLOW_SSL.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.SECRETS_MANAGER_ENABLE_ENCRYPTION,
            name: t('CLOUD_BOTS.DICTIONARY.SECRETS_MANAGER_ENABLE_ENCRYPTION.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SECRETS_MANAGER_ENABLE_ENCRYPTION.PARAMETERS.KMS_KEY.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SG_CLEAR_RULES_FOR_ANY_SCOPE,
            name: t('CLOUD_BOTS.DICTIONARY.SG_CLEAR_RULES_FOR_ANY_SCOPE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_CLEAR_RULES_FOR_ANY_SCOPE.PARAMETERS.PORT.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_CLEAR_RULES_FOR_ANY_SCOPE.PARAMETERS.PROTOCOL.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_CLEAR_RULES_FOR_ANY_SCOPE.PARAMETERS.DIRECTION.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_CLEAR_RULES_FOR_ANY_SCOPE.PARAMETERS.WHITELIST.TITLE'),
                    order: 4
                }
            ]
        },
        {
            id: CloudBotKeys.SG_DELETE,
            name: t('CLOUD_BOTS.DICTIONARY.SG_DELETE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.SG_DELETE_NOT_MATCHING_CIDR,
            name: t('CLOUD_BOTS.DICTIONARY.SG_DELETE_NOT_MATCHING_CIDR.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_DELETE_NOT_MATCHING_CIDR.PARAMETERS.PORT.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_DELETE_NOT_MATCHING_CIDR.PARAMETERS.CIDR.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_DELETE_NOT_MATCHING_CIDR.PARAMETERS.DIRECTION.TITLE'),
                    order: 3,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SG_MODIFY_SCOPE_BY_PORT,
            name: t('CLOUD_BOTS.DICTIONARY.SG_MODIFY_SCOPE_BY_PORT.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_MODIFY_SCOPE_BY_PORT.PARAMETERS.PORT.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_MODIFY_SCOPE_BY_PORT.PARAMETERS.CHANGE_SCOPE_FROM.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_MODIFY_SCOPE_BY_PORT.PARAMETERS.CHANGE_SCOPE_TO.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_MODIFY_SCOPE_BY_PORT.PARAMETERS.DIRECTION.TITLE'),
                    order: 4,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SG_RULES_DELETE,
            name: t('CLOUD_BOTS.DICTIONARY.SG_RULES_DELETE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.SG_RULES_DELETE_BY_SCOPE,
            name: t('CLOUD_BOTS.DICTIONARY.SG_RULES_DELETE_BY_SCOPE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_RULES_DELETE_BY_SCOPE.PARAMETERS.SCOPE.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_RULES_DELETE_BY_SCOPE.PARAMETERS.DIRECTION.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_RULES_DELETE_BY_SCOPE.PARAMETERS.PORT.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_RULES_DELETE_BY_SCOPE.PARAMETERS.PROTOCOL.TITLE'),
                    order: 4,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SG_SINGLE_RULE_DELETE,
            name: t('CLOUD_BOTS.DICTIONARY.SG_SINGLE_RULE_DELETE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_SINGLE_RULE_DELETE.PARAMETERS.SPLIT.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_SINGLE_RULE_DELETE.PARAMETERS.PROTOCOL.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_SINGLE_RULE_DELETE.PARAMETERS.SCOPE.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_SINGLE_RULE_DELETE.PARAMETERS.DIRECTION.TITLE'),
                    order: 4,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SG_SINGLE_RULE_DELETE.PARAMETERS.PORT.TITLE'),
                    order: 5,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SNS_ENFORCE_SSE,
            name: t('CLOUD_BOTS.DICTIONARY.SNS_ENFORCE_SSE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SNS_ENFORCE_SSE.PARAMETERS.KMS_KEY.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SNS_SET_TOPIC_PRIVATE,
            name: t('CLOUD_BOTS.DICTIONARY.SNS_SET_TOPIC_PRIVATE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SNS_SET_TOPIC_PRIVATE.PARAMETERS.POLICY.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SNS_TOPIC_DELETE,
            name: t('CLOUD_BOTS.DICTIONARY.SNS_TOPIC_DELETE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.SQS_CONFIGURE_DLQ,
            name: t('CLOUD_BOTS.DICTIONARY.SQS_CONFIGURE_DLQ.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.SQS_ENFORCE_SSE,
            name: t('CLOUD_BOTS.DICTIONARY.SQS_ENFORCE_SSE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQS_ENFORCE_SSE.PARAMETERS.KMS_KEY.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQS_ENFORCE_SSE.PARAMETERS.KMS_REGION.TITLE'),
                    order: 2,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.TAG_EC2_RESOURCE,
            name: t('CLOUD_BOTS.DICTIONARY.TAG_EC2_RESOURCE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.TAG_EC2_RESOURCE.PARAMETERS.KEY.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.TAG_EC2_RESOURCE.PARAMETERS.VALUE.TITLE'),
                    order: 2,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SSM_DOCUMENT_SET_PRIVATE,
            name: t('CLOUD_BOTS.DICTIONARY.SSM_DOCUMENT_SET_PRIVATE.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SSM_DOCUMENT_SET_PRIVATE.PARAMETERS.ACCOUNT_ID.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.VPC_DELETE,
            name: t('CLOUD_BOTS.DICTIONARY.VPC_DELETE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.VPC_ISOLATE,
            name: t('CLOUD_BOTS.DICTIONARY.VPC_ISOLATE.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.VPC_TURN_ON_FLOW_LOGS,
            name: t('CLOUD_BOTS.DICTIONARY.VPC_TURN_ON_FLOW_LOGS.TITLE'),
            platform: [Vendors.AWS],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.VPC_TURN_ON_FLOW_LOGS.PARAMETERS.TRAFFIC_TYPE.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.VPC_TURN_ON_FLOW_LOGS.PARAMETERS.DESTINATION.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.VPC_TURN_ON_FLOW_LOGS.PARAMETERS.S3_ARN.TITLE'),
                    order: 3,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.EC2_TAG_INSTANCE_FROM_VPC,
            name: t('CLOUD_BOTS.DICTIONARY.EC2_TAG_INSTANCE_FROM_VPC.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.S3_DELETE_BUCKET,
            name: t('CLOUD_BOTS.DICTIONARY.S3_DELETE_BUCKET.TITLE'),
            platform: [Vendors.AWS]
        },
        {
            id: CloudBotKeys.COSMOS_DB_ACCOUNT_ADD_TAG,
            name: t('CLOUD_BOTS.DICTIONARY.COSMOS_DB_ACCOUNT_ADD_TAG.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.COSMOS_DB_ACCOUNT_ADD_TAG.PARAMETERS.KEY.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.COSMOS_DB_ACCOUNT_ADD_TAG.PARAMETERS.VALUE.TITLE'),
                    order: 2,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.CREATE_DELETE_RESOURCE_LOCK_AT_RESOURCE_GROUP_LEVEL,
            name: t('CLOUD_BOTS.DICTIONARY.CREATE_DELETE_RESOURCE_LOCK_AT_RESOURCE_GROUP_LEVEL.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CREATE_DELETE_RESOURCE_LOCK_AT_RESOURCE_GROUP_LEVEL.PARAMETERS.LOCK_NAME.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.CREATE_READONLY_RESOURCE_LOCK_AT_RESOURCE_GROUP_LEVEL,
            name: t('CLOUD_BOTS.DICTIONARY.CREATE_READONLY_RESOURCE_LOCK_AT_RESOURCE_GROUP_LEVEL.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.CREATE_READONLY_RESOURCE_LOCK_AT_RESOURCE_GROUP_LEVEL.PARAMETERS.LOCK_NAME.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.ENABLE_NSG_FLOW_LOGS,
            name: t('CLOUD_BOTS.DICTIONARY.ENABLE_NSG_FLOW_LOGS.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.ENABLE_NSG_FLOW_LOGS.PARAMETERS.STORAGE_ACCOUNT_NAME.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.ENABLE_NSG_FLOW_LOGS.PARAMETERS.STORAGE_ACCOUNT_RESOURCE_GROUP.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.ENABLE_NSG_FLOW_LOGS.PARAMETERS.NETWORK_WATCHER_NAME.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.ENABLE_NSG_FLOW_LOGS.PARAMETERS.LOG_RETENTION_DAYS.TITLE'),
                    order: 4,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.ENABLE_NSG_FLOW_LOGS.PARAMETERS.FLOW_LOG_NAME.TITLE'),
                    order: 5,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.MODIFY_NETWORK_SECURITY_GROUP_SCOPE_BY_PORT,
            name: t('CLOUD_BOTS.DICTIONARY.MODIFY_NETWORK_SECURITY_GROUP_SCOPE_BY_PORT.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.MODIFY_NETWORK_SECURITY_GROUP_SCOPE_BY_PORT.PARAMETERS.PORT.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.MODIFY_NETWORK_SECURITY_GROUP_SCOPE_BY_PORT.PARAMETERS.DIRECTION.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.MODIFY_NETWORK_SECURITY_GROUP_SCOPE_BY_PORT.PARAMETERS.SCOPE.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.MODIFY_NETWORK_SECURITY_GROUP_SCOPE_BY_PORT.PARAMETERS.ACCESS.TITLE'),
                    order: 4,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.DELETE_NETWORK_SECURITY_GROUP,
            name: t('CLOUD_BOTS.DICTIONARY.DELETE_NETWORK_SECURITY_GROUP.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.DELETE_NETWORK_SECURITY_GROUP_SINGLE_RULE,
            name: t('CLOUD_BOTS.DICTIONARY.DELETE_NETWORK_SECURITY_GROUP_SINGLE_RULE.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.DELETE_NETWORK_SECURITY_GROUP_SINGLE_RULE.PARAMETERS.DEST_PORT.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.DELETE_NETWORK_SECURITY_GROUP_SINGLE_RULE.PARAMETERS.DEST_SCOPE.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.DELETE_NETWORK_SECURITY_GROUP_SINGLE_RULE.PARAMETERS.SOURCE_PORT.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.DELETE_NETWORK_SECURITY_GROUP_SINGLE_RULE.PARAMETERS.SOURCE_SCOPE.TITLE'),
                    order: 4,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.DELETE_NETWORK_SECURITY_GROUP_SINGLE_RULE.PARAMETERS.ACCESS.TITLE'),
                    order: 5,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.DISABLE_BLOB_PUBLIC_ACCESS,
            name: t('CLOUD_BOTS.DICTIONARY.DISABLE_BLOB_PUBLIC_ACCESS.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.POSTGRES_ENABLE_CONNECTION_THROTTLING,
            name: t('CLOUD_BOTS.DICTIONARY.POSTGRES_ENABLE_CONNECTION_THROTTLING.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.POSTGRES_ENABLE_LOG_CONNECTIONS,
            name: t('CLOUD_BOTS.DICTIONARY.POSTGRES_ENABLE_LOG_CONNECTIONS.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.POSTGRES_ENABLE_LOG_DISCONNECTS,
            name: t('CLOUD_BOTS.DICTIONARY.POSTGRES_ENABLE_LOG_DISCONNECTS.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.POSTGRES_ENABLE_LOG_DURATION,
            name: t('CLOUD_BOTS.DICTIONARY.POSTGRES_ENABLE_LOG_DURATION.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.POSTGRES_ENABLE_LOG_RETENTION_DAYS_7,
            name: t('CLOUD_BOTS.DICTIONARY.POSTGRES_ENABLE_LOG_RETENTION_DAYS_7.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.POSTGRES_ENFORCE_SSL_CONNECTION,
            name: t('CLOUD_BOTS.DICTIONARY.POSTGRES_ENFORCE_SSL_CONNECTION.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.POSTGRES_ENFORCE_SSL_CONNECTION_TLS_12,
            name: t('CLOUD_BOTS.DICTIONARY.POSTGRES_ENFORCE_SSL_CONNECTION_TLS_12.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.REDIS_CACHE_SSL_ONLY,
            name: t('CLOUD_BOTS.DICTIONARY.REDIS_CACHE_SSL_ONLY.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.SQL_ADD_FIREWALL_RULE_BY_EXISTING_SUBNET,
            name: t('CLOUD_BOTS.DICTIONARY.SQL_ADD_FIREWALL_RULE_BY_EXISTING_SUBNET.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ADD_FIREWALL_RULE_BY_EXISTING_SUBNET.PARAMETERS.FIREWALL_RULE_NAME.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ADD_FIREWALL_RULE_BY_EXISTING_SUBNET.PARAMETERS.VNET_RESOURCE_GROUP.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ADD_FIREWALL_RULE_BY_EXISTING_SUBNET.PARAMETERS.EXISTING_VNET_NAME.TITLE'),
                    order: 3,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ADD_FIREWALL_RULE_BY_EXISTING_SUBNET.PARAMETERS.EXISTING_SUBNET_NAME.TITLE'),
                    order: 4,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SQL_ADD_FIREWALL_RULE_BY_IP,
            name: t('CLOUD_BOTS.DICTIONARY.SQL_ADD_FIREWALL_RULE_BY_IP.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ADD_FIREWALL_RULE_BY_IP.PARAMETERS.FIREWALL_RULE_NAME.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ADD_FIREWALL_RULE_BY_IP.PARAMETERS.FIREWALL_RULE_STARTING_IP.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ADD_FIREWALL_RULE_BY_IP.PARAMETERS.FIREWALL_RULE_ENDING_IP.TITLE'),
                    order: 3,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SQL_DISABLE_PUBLIC_ACCESS,
            name: t('CLOUD_BOTS.DICTIONARY.SQL_DISABLE_PUBLIC_ACCESS.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_DISABLE_PUBLIC_ACCESS.PARAMETERS.MIN_TLS_VERSION.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.SQL_ENABLE_ACCESS_FROM_ALL_VNETS,
            name: t('CLOUD_BOTS.DICTIONARY.SQL_ENABLE_ACCESS_FROM_ALL_VNETS.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.SQL_ENABLE_DATA_ENCRYPTION,
            name: t('CLOUD_BOTS.DICTIONARY.SQL_ENABLE_DATA_ENCRYPTION.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.SQL_ENABLE_AZURE_AD_AUTHENTICATION,
            name: t('CLOUD_BOTS.DICTIONARY.SQL_ENABLE_AZURE_AD_AUTHENTICATION.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ENABLE_AZURE_AD_AUTHENTICATION.PARAMETERS.AZURE_AD_ADMIN_EMAIL.TITLE'),
                    order: 1,
                    required: true,
                    type: CloudBotParameterType.EMAIL
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ENABLE_AZURE_AD_AUTHENTICATION.PARAMETERS.AZURE_AD_ADMIN_SID.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.SQL_ENABLE_AZURE_AD_AUTHENTICATION.PARAMETERS.AZURE_AD_TENANT_ID.TITLE'),
                    order: 3,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.STORAGE_ACCOUNT_DISABLE_BLOB_PUBLIC_ACCESS,
            name: t('CLOUD_BOTS.DICTIONARY.STORAGE_ACCOUNT_DISABLE_BLOB_PUBLIC_ACCESS.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.STORAGE_ACCOUNT_DISABLE_PUBLIC_NETWORK_ACCESS,
            name: t('CLOUD_BOTS.DICTIONARY.STORAGE_ACCOUNT_DISABLE_PUBLIC_NETWORK_ACCESS.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.STORAGE_ACCOUNT_DISABLE_PUBLIC_NETWORK_ACCESS.PARAMETERS.VNET_RESOURCE_GROUP.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.STORAGE_ACCOUNT_DISABLE_PUBLIC_NETWORK_ACCESS.PARAMETERS.VNET.TITLE'),
                    order: 2,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.STORAGE_ACCOUNT_DISABLE_PUBLIC_NETWORK_ACCESS.PARAMETERS.SUBNET.TITLE'),
                    order: 3,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.STORAGE_ACCOUNT_ENABLE_HTTPS_TRAFFIC_ONLY,
            name: t('CLOUD_BOTS.DICTIONARY.STORAGE_ACCOUNT_ENABLE_HTTPS_TRAFFIC_ONLY.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.TAG_VIRTUAL_MACHINE,
            name: t('CLOUD_BOTS.DICTIONARY.TAG_VIRTUAL_MACHINE.TITLE'),
            platform: [Vendors.AZURE],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.TAG_VIRTUAL_MACHINE.PARAMETERS.KEY.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.TAG_VIRTUAL_MACHINE.PARAMETERS.VALUE.TITLE'),
                    order: 2,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.VIRTUAL_MACHINE_DEALLOCATE,
            name: t('CLOUD_BOTS.DICTIONARY.VIRTUAL_MACHINE_DEALLOCATE.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.VIRTUAL_MACHINE_STOP,
            name: t('CLOUD_BOTS.DICTIONARY.VIRTUAL_MACHINE_STOP.TITLE'),
            platform: [Vendors.AZURE]
        },
        {
            id: CloudBotKeys.FIREWALL_RULES_DELETE,
            name: t('CLOUD_BOTS.DICTIONARY.FIREWALL_RULES_DELETE.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP]
        },
        {
            id: CloudBotKeys.VM_INSTANCE_STOP,
            name: t('CLOUD_BOTS.DICTIONARY.VM_INSTANCE_STOP.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP]
        },
        {
            id: CloudBotKeys.GKE_CHANGE_IMAGETYPE_TO_COS,
            name: t('CLOUD_BOTS.DICTIONARY.GKE_CHANGE_IMAGETYPE_TO_COS.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP]
        },
        {
            id: CloudBotKeys.GKE_ENABLE_MASTER_AUTHORIZED_NETWORKS,
            name: t('CLOUD_BOTS.DICTIONARY.GKE_ENABLE_MASTER_AUTHORIZED_NETWORKS.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.GKE_ENABLE_MASTER_AUTHORIZED_NETWORKS.PARAMETERS.CIDR_BLOCKS.TITLE'),
                    order: 1,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.GKE_SUBNET_SET_PRIVATE_GOOGLE_ACCESS_ON,
            name: t('CLOUD_BOTS.DICTIONARY.GKE_SUBNET_SET_PRIVATE_GOOGLE_ACCESS_ON.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP]
        },
        {
            id: CloudBotKeys.VM_INSTANCE_ADD_LABEL,
            name: t('CLOUD_BOTS.DICTIONARY.VM_INSTANCE_ADD_LABEL.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP],
            parameters: [
                {
                    name: t('CLOUD_BOTS.DICTIONARY.VM_INSTANCE_ADD_LABEL.PARAMETERS.KEY.TITLE'),
                    order: 1,
                    required: true
                },
                {
                    name: t('CLOUD_BOTS.DICTIONARY.VM_INSTANCE_ADD_LABEL.PARAMETERS.VALUE.TITLE'),
                    order: 2,
                    required: true
                }
            ]
        },
        {
            id: CloudBotKeys.VM_INSTANCE_DISABLE_PUBLIC_IP,
            name: t('CLOUD_BOTS.DICTIONARY.VM_INSTANCE_DISABLE_PUBLIC_IP.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP]
        },
        {
            id: CloudBotKeys.SUBNET_SET_PRIVATE_GOOGLE_ACCESS_ON,
            name: t('CLOUD_BOTS.DICTIONARY.SUBNET_SET_PRIVATE_GOOGLE_ACCESS_ON.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP]
        },
        {
            id: CloudBotKeys.CLOUD_SQL_DELETE_PUBLIC_IP_RANGES,
            name: t('CLOUD_BOTS.DICTIONARY.CLOUD_SQL_DELETE_PUBLIC_IP_RANGES.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP]
        },
        {
            id: CloudBotKeys.STORAGE_BUCKET_REMOVE_ALLOW_PUBLIC_ACCESS_RULES,
            name: t('CLOUD_BOTS.DICTIONARY.STORAGE_BUCKET_REMOVE_ALLOW_PUBLIC_ACCESS_RULES.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP]
        },
        {
            id: CloudBotKeys.FLOW_LOGS_ENABLE,
            name: t('CLOUD_BOTS.DICTIONARY.FLOW_LOGS_ENABLE.TITLE'),
            platform: [Vendors.GOOGLE, Vendors.GCP]
        }
    ];
};

const createCloudBotParameter = (parameter: ICloudBotParameterOptions): ICloudBotParameter => {
    const { type = CloudBotParameterType.TEXT } = parameter;
    return { ...parameter, type };
};

const createCloudBot = (options: ICloudBotDefinition): ICloudBot => {
    const { id, name, platform, parameters = [] } = options;
    return {
        id,
        name,
        platform,
        parameters: parameters.map((param: ICloudBotParameterOptions) => {
            return createCloudBotParameter(param);
        })
    };
};

const createCloudBots = (): ICloudBot[] => {
    const dictionary: ICloudBot[] = [];
    getCloudBotsDefinitions().forEach((options: ICloudBotDefinition) => {
        const cloudBot: ICloudBot = createCloudBot(options);
        dictionary.push(cloudBot);
    });
    dictionary.sort((a: ICloudBot, b: ICloudBot) => a.id.localeCompare(b.id));
    return dictionary;
};

export const initializeCloudBotsDictionary = () => {
    CloudBotsRegistry.addCloudBots(createCloudBots());
};
