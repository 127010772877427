import { IColumnUsageDef } from 'common/interface/general';
import { ColDef } from 'ag-grid-enterprise';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import { IIssueOrGroup, ISSUE_FIELD_NAMES } from 'modules/riskManagement/components/Issues/Issues.interface';
import { mergeColumnDefs, TABLE_SELECTION_COL_ID } from 'common/utils/tableUtils';
import { isAfIssueExclusions } from '../../../../RiskManagement.utils';

const getColumnDefs = (withMultiSelect: boolean, onClickRuleTitle: (issueOrGroup: IIssueOrGroup) => void): IColumnUsageDef[] => {
    const exclusionCol: IColumnUsageDef = {
        id: ISSUE_FIELD_NAMES.IS_EXCLUDED,
        colDefOverride: {
            hide: true,
        },
    };

    const colDefs: IColumnUsageDef[] = [
        {
            id: ISSUE_FIELD_NAMES.RULE_TITLE,
            colDefOverride: {
                width: 450,
                minWidth: 200,
                pinned: 'left',
                lockPinned: true,
                cellRendererParams: {
                    onClick: onClickRuleTitle,
                }
            },
        },
        {
            id: ISSUE_FIELD_NAMES.SEVERITY,
            colDefOverride: {
                minWidth: 80,
                flex: 3,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.CREATED_TIME,
            colDefOverride: {
                minWidth: 120,
                flex: 4,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ENTITY_NAME,
            colDefOverride: {
                minWidth: 130,
                flex: 6,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
            colDefOverride: {
                minWidth: 90,
                flex: 3,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.STATUS,
        },
        ...(isAfIssueExclusions() ? [exclusionCol] : []),
        {
            id: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
            colDefOverride: {
                minWidth: 120,
                flex: 5,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.PLATFORM,
            colDefOverride: {
                hide: true,
                minWidth: 80,
                flex: 3,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ID,
            colDefOverride: {
                minWidth: 80,
                flex: 3,
            },
        },
        {
            id: ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID,
            colDefOverride: {
                hide: true,
                minWidth: 100,
                flex: 5,
            },
        },
    ];

    if (withMultiSelect) {
        return [
            {
                id: TABLE_SELECTION_COL_ID,
            },
            ...colDefs,
        ];
    }
    return colDefs;
};

export const getIssuesPageColumnDefs = (withMultiSelect: boolean, onClickRuleTitle: (issueOrGroup: IIssueOrGroup) => void): ColDef[] => {
    return mergeColumnDefs(getColumnDefs(withMultiSelect, onClickRuleTitle), IssuesRegistry.getIssuesColumnDefs());
};