import { APPLICATION_REDUCERS } from 'common/extensibility/WellKnownPaths';
import { IApplicationReducer } from 'common/interface/redux';
import ociReducer from './reducer';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { initialOciEnvironmentData } from './OciEnvironment.consts';


const initializeOciEnvRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'env_oci',
            reducer: ociReducer,
            isBlackList: true,
        },
    ];
    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');
};


export const initializeOciEnv = () => {
    initializeOciEnvRedux();
    initialOciEnvironmentData();
};