import { CGColDef, ColumnType } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getVendorDisplayName } from 'common/consts/vendors';
import i18n from 'common/services/translations/translations';
import EntityCellRender from 'common/components/ProtectedAssets/Renderers/EntityCellRender';
import EnvironmentCellRender from 'common/components/ProtectedAssets/Renderers/EnvironmentCellRender';
import OrganizationalUnitCellRenderer from '../CellRenderers/OrganizationalUnitCellRenderer';
import RegionCellRenderer from '../CellRenderers/RegionCellRenderer';
import TypeCellRenderer from '../../../../common/components/ProtectedAssets/Renderers/TypeCellRenderer';
import {
    getAdditionalFieldValue,
    getAdditionalFieldValueExport,
    getAdditionalFieldValueGetter,
    getOrganizationalUnitValue,
    getEntityTypeValue,
} from 'common/components/ProtectedAssets/Renderers/ColumnDisplayValueGetters';
import SelectionHeaderComponent from '../HeaderComponent/SelectionHeaderComponent';
import LabelCellRender from '../CellRenderers/LabelsCellRender';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { getEnvironmentDisplayNameById } from 'common/components/ProtectedAssets/AssetUtils';
import { getRegionDisplayName } from 'common/utils/vendorUtils';
import SeverityCountersCellRenderer from 'common/components/ag-grid/Renderers/SeverityCountersCellRenderer';


const columnDefs: CGColDef[] = [
    {
        colId: 'checkbox',
        field: 'checkbox',
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.SELECTION.HEADER'),
        headerName: '',
        minWidth: 52,
        maxWidth: 52,
        resizable: false,
        checkboxSelection: true,
        lockPosition: true,
        suppressColumnsToolPanel: true,
        headerComponent: SelectionHeaderComponent,
        showDisabledCheckboxes: true,
        headerCheckboxSelection: true,
    },
    {
        colId: 'id',
        field: 'id',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ID.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ID.TOOLTIP'),
        sortable: true,
    },
    {
        colId: 'externalCloudAccountId',
        field: 'externalCloudAccountId',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.EXTERNAL_CLOUD_ACCOUNT_ID.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.EXTERNAL_CLOUD_ACCOUNT_ID.TOOLTIP'),
        sortable: true,
        csvExport: {
            calculateValue: (data) => data.externalCloudAccountId,
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.EXTERNAL_CLOUD_ACCOUNT_ID.CSV_HEADER'),
            order: 5,
        },
    },
    {
        colId: 'environment',
        field: 'cloudAccountId',
        width: 250,
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENVIRONMENT.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENVIRONMENT.TOOLTIP'),
        cellRenderer: EnvironmentCellRender,
        enableRowGroup: true,
        sortable: true,
    },
    {
        colId: 'environmentName',
        field: 'cloudAccountId',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENVIRONMENT.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENVIRONMENT.TOOLTIP'),
        cellRenderer: EnvironmentCellRender,
        width: 250,
        enableRowGroup: true,
        sortable: true,
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENVIRONMENT.CSV_HEADER'),
            calculateValue: (data) => getEnvironmentDisplayNameById(data.cloudAccountId),
            order: 6,
        },
    },
    {
        colId: 'region',
        field: 'region',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.REGION.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.REGION.TOOLTIP'),
        cellRenderer: RegionCellRenderer,
        enableRowGroup: true,
        sortable: true,
        width: 100,
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.REGION.CSV_HEADER'),
            calculateValue: (data) => getRegionDisplayName(data.region, data.platform),
            order: 8,
        },
    },
    {
        colId: 'type',
        field: 'typeByPlatform',
        sortField: 'type',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.TYPE.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.TYPE.TOOLTIP'),
        cellRenderer: TypeCellRenderer,
        enableRowGroup: true,
        sortable: true,
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.TYPE.CSV_HEADER'),
            calculateValue: (data: any) => getEntityTypeValue(data?.typeByPlatform),
            order: 1,
        },
    },
    {
        colId: 'entity',
        field: 'entityId',
        sortField: 'name',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENTITY.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENTITY.TOOLTIP'),
        cellRenderer: EntityCellRender,
        width: 260,
        sortable: true,
        csvExport: {
            calculateValue: (data) => data.entityId,
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ID.CSV_HEADER'),
            order: 2,
        },
    },
    {
        colId: 'platform',
        field: 'platform',
        valueFormatter: (params: any) => {
            return getVendorDisplayName(params.value);
        },
        width: 100,
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PLATFORM.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PLATFORM.TOOLTIP'),
        enableRowGroup: true,
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PLATFORM.CSV_HEADER'),
            calculateValue: (data) => getVendorDisplayName(data.platform),
            order: 7,
        },
    },
    {
        colId: 'organizationalUnit',
        field: 'cloudAccountId',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ORGANIZATIONAL_UNIT.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ORGANIZATIONAL_UNIT.TOOLTIP'),
        cellRenderer: OrganizationalUnitCellRenderer,
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.ORGANIZATIONAL_UNIT.CSV_HEADER'),
            calculateValue: getOrganizationalUnitValue,
            order: 4,
        },
    },
    {
        colId: 'isPublic',
        field: 'IsPublic',
        valueGetter: getAdditionalFieldValueGetter('IsPublic'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_PUBLIC.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_PUBLIC.TOOLTIP'),
        columnType: ColumnType.Additional,
    },
    {
        colId: 'cluster',
        field: 'Cluster',
        valueGetter: getAdditionalFieldValueGetter('Cluster'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.CLUSTER.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.CLUSTER.TOOLTIP'),
        columnType: ColumnType.Additional
    },
    {
        colId: 'instanceType',
        field: 'InstanceType',
        valueGetter: getAdditionalFieldValueGetter('InstanceType'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.INSTANCE_TYPE.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.INSTANCE_TYPE.TOOLTIP'),
        csvExport: {
            calculateValue: getAdditionalFieldValueExport('InstanceType'),
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.INSTANCE_TYPE.CSV_HEADER'),
            order: 11,
        },
        columnType: ColumnType.Additional
    },
    {
        colId: 'publicIpAddress',
        field: 'PublicIpAddress',
        valueGetter: getAdditionalFieldValueGetter('PublicIpAddress'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PUBLIC_IP.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PUBLIC_IP.TOOLTIP'),
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PUBLIC_IP.CSV_HEADER'),
            calculateValue: getAdditionalFieldValueExport('PublicIpAddress'),
            order: 13,
        },
        columnType: ColumnType.Additional,
    },
    {
        colId: 'privateIpAddress',
        field: 'PrivateIpAddress',
        valueGetter: getAdditionalFieldValueGetter('PrivateIpAddress'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PRIVATE_IP.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PRIVATE_IP.TOOLTIP'),
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PRIVATE_IP.CSV_HEADER'),
            calculateValue: getAdditionalFieldValueExport('PrivateIpAddress'),
            order: 14,
        },
        columnType: ColumnType.Additional,
    },
    {
        colId: 'name',
        field: 'name',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.NAME.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.NAME.TOOLTIP'),
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.NAME.CSV_HEADER'),
            calculateValue: (data) => data.name,
            order: 3,
        },
    },
    {
        colId: 'staticWebsiteEnable',
        field: 'StaticWebsiteEnable',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.STATIC_WEBSITE_ENABLE.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.STATIC_WEBSITE_ENABLE.TOOLTIP'),
    },
    {
        colId: 'mfaDelete',
        field: 'MFADelete',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.MFA_DELETE.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.MFA_DELETE.TOOLTIP'),
    },
    {
        colId: 'status',
        field: 'Status',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.STATUS.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.STATUS.TOOLTIP'),
    },
    {
        colId: 'isProtected',
        field: 'IsProtected',
        valueGetter: getAdditionalFieldValueGetter('IsProtected'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_PROTECTED.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_PROTECTED.TOOLTIP'),
        columnType: ColumnType.Additional,
    },
    {
        colId: 'operatingSystem',
        field: 'OperatingSystem',
        valueGetter: getAdditionalFieldValueGetter('OperatingSystem'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.OPERATING_SYSTEM.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.OPERATING_SYSTEM.TOOLTIP'),
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.OPERATING_SYSTEM.CSV_HEADER'),
            calculateValue: (data) => getAdditionalFieldValue(data, 'Platform') || getAdditionalFieldValue(data, 'OperatingSystem'),
            order: 15,
        },
        columnType: ColumnType.Additional,
    },
    {
        colId: 'dbType',
        field: 'DbType',
        valueGetter: getAdditionalFieldValueGetter('DbType'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.DB_TYPE.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.DB_TYPE.TOOLTIP'),
        columnType: ColumnType.Additional,
    },
    {
        colId: 'namespace',
        field: 'Namespace',
        valueGetter: getAdditionalFieldValueGetter('Namespace'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.NAMESPACE.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.NAMESPACE.TOOLTIP'),
    },
    {
        colId: 'kubeletVersion',
        field: 'KubeletVersion',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.VERSION.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.VERSION.TOOLTIP'),
    },
    {
        colId: 'network',
        field: 'network',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.NETWORK.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.NETWORK.TOOLTIP'),
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.NETWORK.CSV_HEADER'),
            calculateValue: (data) => data.network,
            order: 9,
        },
    },
    {
        colId: 'compartment',
        field: 'compartment',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.COMPARTMENT.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.COMPARTMENT.TOOLTIP'),
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.COMPARTMENT.CSV_HEADER'),
            calculateValue: (data) => data.compartment,
            order: 10,
        },
    },
    {
        colId: 'resourceGroup',
        field: 'resourceGroup',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RESOURCE_GROUP.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RESOURCE_GROUP.TOOLTIP'),
        csvExport: {
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RESOURCE_GROUP.CSV_HEADER'),
            calculateValue: (data) => data.resourceGroup,
            order: 10,
        },
    },
    {
        colId: 'ports',
        field: 'Ports',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PORTS.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.PORTS.TOOLTIP'),
    },
    {
        colId: 'imageName',
        field: 'ImageName',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IMAGE_NAME.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IMAGE_NAME.TOOLTIP'),
        valueGetter: getAdditionalFieldValueGetter('ImageName'),
        csvExport: {
            calculateValue: getAdditionalFieldValueExport('ImageName'),
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IMAGE_NAME.CSV_HEADER'),
            order: 17,
        },
        columnType: ColumnType.Additional
    },
    {
        colId: 'businessPriority',
        field: 'businessPriority',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.BUSINESS_PRIORITY.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.BUSINESS_PRIORITY.TOOLTIP'),
        sortable: true,
        width: 150,
        minWidth: 100,
    },
    {
        colId: 'cves',
        field: 'CVEs',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.CVES.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.CVES.TOOLTIP'),
        valueGetter: (data) => {
            return data.data.vulnerability?.stats?.cve || data.data.cvEs;
        },
        cellRenderer: SeverityCountersCellRenderer,
        width: 175,
        minWidth: 175,
    },
    {
        colId: 'runtimeEnvironment',
        field: 'RuntimeEnvironment',
        valueGetter: getAdditionalFieldValueGetter('RuntimeEnvironment'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RUNTIME_ENVIRONMENT.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RUNTIME_ENVIRONMENT.TOOLTIP'),
        columnType: ColumnType.Additional,
    },
    {
        colId: 'workloads',
        field: 'Workloads',
        valueGetter: getAdditionalFieldValueGetter('Workloads'),
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.WORKLOADS.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.WORKLOADS.TOOLTIP'),
    },
    {
        colId: 'vpcFlowLogColumnAction',
        field: 'vpcFlowLogColumnAction',
        maxWidth: 40,
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.FLOW_LOGS.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.FLOW_LOGS.TOOLTIP'),
    },
    {
        colId: 'isBillable',
        field: 'isBillable',
        maxWidth: 40,
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_BILLABLE.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_BILLABLE.TOOLTIP'),
        valueGetter: getAdditionalFieldValueGetter('IsBillable'),
        csvExport: {
            calculateValue: (data) => {
                const value = getAdditionalFieldValue(data, 'IsBillable');
                if (value === null) {
                    return null;
                }
                if (value === 'True') {
                    return i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_BILLABLE.YES');
                } else {
                    return i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_BILLABLE.NO');
                }
            },
            title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.IS_BILLABLE.CSV_HEADER'),
            order: 18,
        },
        columnType: ColumnType.Additional,
    },
    {
        colId: 'digest',
        field: 'Digest',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.DIGEST.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.DIGEST.TOOLTIP'),
        valueGetter: getAdditionalFieldValueGetter('Digest'),
        sortable: true,
        columnType: ColumnType.Additional,
    },
    {
        colId: 'labels',
        field: 'assetLabels',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.LABELS.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.LABELS.TOOLTIP'),
        cellRenderer: LabelCellRender,
        sortable: true,
    },

];

export default function initializeColumnDefs() {
    ProtectedAssetsTableRegistry.addColumnDefs(columnDefs, 'colId');
}
