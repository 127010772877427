import React from 'react';
import moment from 'moment-mini';
import { EdgeData } from '../Models/GraphModels';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';

const OccurrencesTablePopoverComponent: React.FC<EdgeData> = (edgeData: EdgeData) => {
    const occurrences = edgeData.additionalData?.occurrences;
    const occurrencesMap : Record<string, number> = {};
    occurrences?.forEach((occurrence: string) => {
        const date = moment(occurrence).format('lll');
        occurrencesMap[date] = (occurrencesMap[date] || 0) + 1;
    });
    const occurrencesMapEntries = Object.entries(occurrencesMap);
    const { t } = useTranslation(i18nIntelligenceNamespace);
    
    return <div className="-mx-6 -mt-5 -mb-5">
        <div className="p-7 break-all flex-0 text-sm bg-content">
            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <table className={'table--tertiary table'}>
                    <thead>
                        <tr>
                            <th className={'border-r px-4 py-2'}>{t('INT_GRAPH.EDGE_INFO.OCCURRENCES_TABLE.DATE')}</th>
                            <th>{t('INT_GRAPH.EDGE_INFO.OCCURRENCES_TABLE.OCCURRENCES')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {occurrencesMapEntries.map(([dateString, count], index) => (
                            <tr key={index} className={'border-t'}>
                                <td className={'border-r px-4 py-2'}>{dateString}</td>
                                <td>{count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
};

export default OccurrencesTablePopoverComponent;
