import WidgetLatestList from 'common/components/Widgets/Latest/WidgetLatestList';
import { IAggregationSettings, IWidgetsSettings } from 'common/module_interface/overview/Interface';
import { listWidgetAdditionalFields } from 'common/components/Widgets/List/ListWidgetAdditionalFields';
import WidgetList from 'common/components/Widgets/List/List';
import WidgetPieChart from 'common/components/Widgets/Pie/PieWidget';
import WidgetColumn from 'common/components/Widgets/Column/ColumnWidget';
import AreaWidget from 'common/components/Widgets/Area/AreaWidget';
import StackedColumnWidget from 'common/components/Widgets/StackedColumn/StackedColumnWidget';
import {
    alertsStackedColumnDataGetter,
    getActionDisplayData,
    getAlertsTypeDisplayData,
    getAssigneeDisplayData,
    getCategoryDisplayData,
    getEntityData,
    getEntityTypeDisplayData,
    getEnvironmentDisplayData,
    getLabelsDisplayData,
    getRuleDisplayData,
    getRulesetDisplayData,
    getSeverityDisplayData,
    getSourceDisplayData,
    getPlatformDisplayData,
    getRegionDisplayData
} from './WidgetsData/AlertsDataGetter';


export const aggregationTypesOptions: IAggregationSettings = {
    action: { dataId: 'action', displayName: 'Action', dataGetter: getActionDisplayData },
    alertType: { dataId: 'alertType', displayName: 'Alert Type', dataGetter: getAlertsTypeDisplayData },
    ownerUserName: { dataId: 'ownerUserName', displayName: 'Assignee', dataGetter: getAssigneeDisplayData },
    category: { dataId: 'category', displayName: 'Category', dataGetter: getCategoryDisplayData },
    entity: { dataId: 'entity', displayName: 'Entity', stackColumnName: 'entityExternalIdAndName', dataGetter: getEntityData },
    entityType: { dataId: 'entityTypeByEnvironmentType', displayName: 'Entity Type', dataGetter: getEntityTypeDisplayData, dataIdAliases: ['entityType'] },
    cloudAccountId_calc: { dataId: 'cloudAccountId_calc', displayName: 'Environment', stackColumnName: 'cloudAccountId', dataGetter: getEnvironmentDisplayData },
    labels: { dataId: 'labels', displayName: 'Labels', dataGetter: getLabelsDisplayData },
    cloudAccountType: { dataId: 'cloudAccountType', displayName: 'Platform', dataGetter: getPlatformDisplayData },
    region: { dataId: 'region', displayName: 'Region', dataGetter: getRegionDisplayData },
    ruleName: { dataId: 'ruleName', displayName: 'Rule', dataGetter: getRuleDisplayData },
    bundleName: { dataId: 'bundleName', displayName: 'Ruleset', dataGetter: getRulesetDisplayData },
    severity: { dataId: 'severity', displayName: 'Severity', dataGetter: getSeverityDisplayData },
    origin: { dataId: 'origin', displayName: 'Source', dataGetter: getSourceDisplayData },
    trend: { dataId: 'trend', displayName: 'Trend', dataGetter: getSourceDisplayData },
    entityExternalIdAndName: { dataId: 'entityExternalIdAndName', displayName: 'Entity', dataGetter: alertsStackedColumnDataGetter },
    entityTypeByEnvironmentType: { dataId: 'entityTypeByEnvironmentType', displayName: 'Entity Type', dataGetter: alertsStackedColumnDataGetter }
};


export const widgetsTypesOptions: IWidgetsSettings = {
    pie: { component: WidgetPieChart, displayName: 'Pie' },
    column: { component: WidgetColumn, displayName: 'Columnm' },
    top: { component: WidgetList, displayName: 'Top', additionalFields: listWidgetAdditionalFields },
    latest: { component: WidgetLatestList, displayName: 'Latest' },
    trend: { component: AreaWidget, displayName: 'Trend' },
    stackedColumn: { component: StackedColumnWidget, displayName: 'Stacked Column' }
};
