import i18n, { validLanguages } from 'common/services/translations/translations';

export const EnvsNamespacePrefix = 'environments';
export type EnvsTermTypes = 'table';
const termsFolders: Array<EnvsTermTypes> = ['table'];

export const getEnvsNamespace = (term: EnvsTermTypes) => `${EnvsNamespacePrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: EnvsTermTypes; }>>((acc, language) => {
    const filesPAth = termsFolders.map(term => ({ path: `${language}/${term}`, language, term }));
    return [...acc, ...filesPAth];
}, []);

export const initializeEnvironmentsTableI18n = async () => {
    await Promise.all(jsonFilesPaths.map(async (file) => {
        try {
            const contents = await require(`./languages/${file.path}.json`);
            i18n.addResourceBundle(file.language, getEnvsNamespace(file.term), contents);
        // eslint-disable-next-line
        } catch (error) { }
    }));
};