import {
    createFreeTextFilter,
    createSelectionFilter, getCommonFiltersMap
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { IClientFilterType } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';
import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { ICommonIgnoreItem } from './CommonIgnoreList.interface';
import { IGNORE_LIST_ITEM_FIELDS } from './CommonIgnoreList.consts';
import { ermTrans } from '../../../RiskManagement.utils';
import { CommonFilterEnum } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.consts';

export const getCommonIgnoreListFilters = <T extends ICommonIgnoreItem>(): IClientFilterType<T, IFilterConditionsContainer>[] => {
    const commonMap = getCommonFiltersMap<T>();
    return [
        commonMap[CommonFilterEnum.ADD_FILTER],
        commonMap[CommonFilterEnum.CLEAR_ALL],
        createFreeTextFilter([
            IGNORE_LIST_ITEM_FIELDS.NAME,
            IGNORE_LIST_ITEM_FIELDS.ENTITY_NAMES,
            IGNORE_LIST_ITEM_FIELDS.ENTITY_ID,
            IGNORE_LIST_ITEM_FIELDS.DESCRIPTION,
            IGNORE_LIST_ITEM_FIELDS.CVE_IDS,
        ]),
        createSelectionFilter(
            IGNORE_LIST_ITEM_FIELDS.PACKAGE_NAMES, ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.PACKAGE_NAMES.HEADER')),
        createSelectionFilter(
            IGNORE_LIST_ITEM_FIELDS.PACKAGE_PATHS, ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.PACKAGE_PATHS.HEADER')),
        createSelectionFilter(
            IGNORE_LIST_ITEM_FIELDS.ORG_UNIT_IDS, ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ORG_UNIT_IDS.HEADER')),
        createSelectionFilter(
            IGNORE_LIST_ITEM_FIELDS.ENV_IDS, ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ENV_IDS.HEADER')),
    ];
};
