import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    SplitLine,
    Icon_,
    ZipFileImage,
    RootImage,
    RunApplyImage,
    JobSucceedImage,
    BtnWrapper
} from './OciOnboarding.styled';
import { getTerraformText, createTerraformAndDowloadZip } from './OciOnboarding.service';
import { TextField, Popover, Button } from '@dome9/berries/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { getOcidUser, getOciIdValid, setIsLoading, setOcidUser } from './OciOnboarding.reducer';
import CftModal from '../components/CftModal';
import { getStoreService } from 'common/interface/services';
import { OciOnboardingState } from './OciOnboarding.interface';

const OciOnboardingAccount: React.FC<any> = ({ onValidate }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [isZipPopupVisiable, setIsZipPopupVisiable] = useState<boolean>(false);
    const [isRootPopupVisiable, setIsRootPopupVisiable] = useState<boolean>(false);
    const [isRunApplyPopupVisiable, setIsRunApplyPopupVisiable] = useState<boolean>(false);
    const [isJobSucceedPopupVisiable, setIsJobSucceedPopupVisiable] = useState<boolean>(false);
    const [terraFormText, setTerraFormText] = useState('');
    const userOcid = useSelector(getOcidUser);
    const formValidations = useSelector(getOciIdValid);

    useEffect(() => {
        async function getTerraform() {
            const text = await getTerraformText();
            setTerraFormText(text.responseData);
        }
        getTerraform();
    }, []);

    useEffect(() => {
        onValidate(formValidations.valid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userOcid]);

    const onOcidUserChanged = (event: any)=>{
        dispatch(setOcidUser(event?.target?.value || ''));
    };

    const clearOcidUser = () => {
        onOcidUserChanged('');
    };

    const dowloadTerraformZip = async()=>{
        const { state, dispatch } = getStoreService().getReduxTools();
        dispatch(setIsLoading(true));
        await createTerraformAndDowloadZip(state.ociOnboarding as OciOnboardingState).finally(()=> dispatch(setIsLoading(false)));
    };

    return (
        <div>
            <div className='title-large'>{t('ON_BOARDING.OCI.CA_TITLE')}</div>
            <div className='mt-3'>
                {t('ON_BOARDING.OCI.TERRAFORM_STATEMENT_2')}
                <BtnWrapper>
                    <Button
                        icon={'listCheck'}
                        label={'Terraform Template'}
                        onClick={() => {
                            setIsPopupOpen(true);
                        }}
                        className='terraform-text-button pointer'
                        data-aid='terraform-text-button'
                    >
                    </Button>
                </BtnWrapper>
            </div>
            <div className='mt-7'>
                {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_1')}
            </div>
            <div className='mt-7'>
                {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_2')}
            </div>
            <div className='mt-3'>
                {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_DOWNLOAD')}
                <BtnWrapper>
                    <Button
                        icon={'download'}
                        label={'Download'}
                        onClick={() => {
                            dowloadTerraformZip();
                        }}
                        className='terraform-text-button pointer'
                        data-aid='terraform-text-button'
                    >
                    </Button>
                </BtnWrapper>
            </div>
            <div className='mt-7'>
                {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_3')}
                <div className={'ml-10'}>
                    <div>
                        {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_3_A')}
                        <Popover visible={isZipPopupVisiable} placement={'right'} content={<ZipFileImage></ZipFileImage>}>
                            <span onMouseEnter={()=>setIsZipPopupVisiable(true)} onMouseLeave={()=>setIsZipPopupVisiable(false)}>
                                <Icon_ name='info' color={'info'} size={12} ></Icon_>
                            </span>
                        </Popover>
                    </div>
                    <div>
                        {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_3_B')}
                    </div>
                    <div>
                        {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_3_C')}
                    </div>
                    <div>
                        {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_3_D')}
                        <Popover visible={isRootPopupVisiable} placement={'right'} content={<RootImage></RootImage>}>
                            <span onMouseEnter={()=>setIsRootPopupVisiable(true)} onMouseLeave={()=>setIsRootPopupVisiable(false)}>
                                <Icon_ name='info' color={'info'} size={12} />
                            </span>
                        </Popover>
                    </div>
                </div>
            </div>

            <div className='mt-7'>
                {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_4')}
            </div>
            <div className='mt-7'>
                {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_5')}
            </div>
            <div className='mt-7'>
                {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_6')}
                <Popover visible={isRunApplyPopupVisiable} placement={'right'} content={<RunApplyImage></RunApplyImage>}>
                    <span onMouseEnter={()=>setIsRunApplyPopupVisiable(true)} onMouseLeave={()=>setIsRunApplyPopupVisiable(false)}>
                        <Icon_ name='info' color={'info'} size={12} />
                    </span>
                </Popover>
                <div className={'ml-7'}>
                    {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_6_B')}
                </div>
            </div>
            <div className='mt-7'>
                {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_7')}
            </div>
            <div className='mt-7'>
                {t('ON_BOARDING.OCI.STACK_INSTRUCTIONS_8')}
                <Popover visible={isJobSucceedPopupVisiable} placement={'right'} content={<JobSucceedImage></JobSucceedImage>}>
                    <span onMouseEnter={()=>setIsJobSucceedPopupVisiable(true)} onMouseLeave={()=>setIsJobSucceedPopupVisiable(false)}>
                        <Icon_ name='info' color={'info'} size={12} />
                    </span>
                </Popover>
            </div>
            <div className='mt-7'>
                <SplitLine>
                    <TextField value={userOcid}
                        onChange={onOcidUserChanged}
                        onClear={clearOcidUser}
                        state={formValidations?.errors?.userOcid ? 'error' : 'info'}
                        helper={formValidations?.errors?.userOcid ? formValidations.errors.userOcid[0]?.message : undefined}
                    ></TextField>
                </SplitLine>
            </div>
            <CftModal
                open={isPopupOpen}
                onClose={() => {
                    setIsPopupOpen(false);
                }}
                title={''}
                cfts={[{
                    content: terraFormText,
                    type: t('ON_BOARDING.OCI.ONBOARDING'),
                    fileName: 'Terraform.yaml',
                    downloadName: 'Download terraform'
                }]}
            />
        </div>
    );
};

export default OciOnboardingAccount;
