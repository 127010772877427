import { FunctionComponent } from 'react';
import { ReactComponent as AwsUserIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_General-Icons/Res_48_Light/Res_User_48_Light.svg';
import { ReactComponent as AwsNlbIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Network-Load-Balancer_48_Light.svg';
import { ReactComponent as AwsAlbIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Application-Load-Balancer_48_Light.svg';
import { ReactComponent as AwsRouteTableIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-Route-53_Route-Table_48_Light.svg';
import { ReactComponent as AwsNatIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_NAT-Gateway_48_Light.svg';
import { ReactComponent as AwsEniIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Elastic-Network-Interface_48_Light.svg';
import { ReactComponent as AwsNaclIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Network-Access-Control-List_48_Light.svg';
import { ReactComponent as AwsEc2Icon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Compute/Res_48_Light/Res_Amazon-EC2_Instance_48_Light.svg';
import { ReactComponent as AwsEcsTaskDefinitionIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_ECS-Anywhere_48_Light.svg';
import { ReactComponent as AwsEcsContainerIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Container-2_48_Light.svg';
import { ReactComponent as AwsEcsServiceIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Service_48_Light.svg';
import { ReactComponent as AwsEcsTaskIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Containers/Res_48_Light/Res_Amazon-Elastic-Container-Service_Task_48_Light.svg';
import { ReactComponent as AwsVpcIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Networking-Content-Delivery/16/Arch_Amazon-Virtual-Private-Cloud_16.svg';
import { ReactComponent as AwsApiGatewayIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Application-Integration/Res_48_Light/Res_Amazon-API-Gateway_Endpoint_48_Light.svg';
import { ReactComponent as AwsAsgIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Compute/Res_48_Light/Res_Amazon-EC2_Auto-Scaling_48_Light.svg';
import { ReactComponent as AwsClassicLbIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Elastic-Load-Balancing_Classic-Load-Balancer_48_Light.svg';
import { ReactComponent as AwsOrganizationAccountIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Account_48_Light.svg';
import { ReactComponent as AwsOrganizationManagementAccountIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Management-Account_48_Light.svg';
import { ReactComponent as AwsOrganizationUnitIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Management-Governance/Res_48_Light/Res_AWS-Organizations_Organizational-Unit_48_Light.svg';
import { ReactComponent as AwsRedshiftIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Analytics/Res_48_Light/Res_Amazon-Redshift_ML_48_Light.svg';
import { ReactComponent as AwsS3Icon } from 'common/erm-components/icons/vendor-icons/S3Bucket.svg';
import { ReactComponent as AwsRoleIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_Role_48_Light.svg';
import { ReactComponent as AwsPolicyIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_loT/Res_48_Light/Res_AWS-IoT_Policy_48_Light.svg';
import { ReactComponent as AwsLambdaIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Compute/Res_48_Light/Res_AWS-Lambda_Lambda-Function_48_Light.svg';
import { ReactComponent as AwsSubnetIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Dark/Res_Amazon-VPC_VPN-Gateway_48_Dark.svg';
import { ReactComponent as AwsUserGroupIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_General-Icons/Res_48_Light/Res_Users_48_Light.svg';
import { ReactComponent as AwsSecurityGroupIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_General-Icons/Res_48_Light/Res_Shield_48_Light.svg';
import { ReactComponent as AwsCloudAccountIcon } from '@dome9/berries/vendor-icons/dist/aws/aws.svg';
import { ReactComponent as AwsCloudFormationStackIcon } from '@dome9/berries/vendor-icons/dist/old-pack/AWSSimpleIcons/ManagementTools/AWS-CloudFormation_Stack.svg';
import { ReactComponent as AwsRDSInstanceIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Database/Res_48_Dark/Res_Amazon-Aurora_Amazon-RDS-Instance_48_Dark.svg';
import { ReactComponent as AwsDynamoTableIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Database/Res_48_Dark/Res_Amazon-DynamoDB_Table_48_Dark.svg';
import { ReactComponent as AwsElasticKubernetesIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Containers/16/Arch_Amazon-Elastic-Kubernetes-Service_16.svg';
import { ReactComponent as AwsCloudTrailIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Management-Governance/Res_48_Light/Res_Amazon-Cloudtrail_48_Light.svg';
import { ReactComponent as AwsElasticCacheIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Database/Res_48_Light/Res_Amazon-ElasticCache_48_Light.svg';
import { ReactComponent as AwsFlowLogsIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Networking-and-Content-Delivery/Res_48_Light/Res_Amazon-VPC_Flow-Logs_48_Light.svg';
import { ReactComponent as AwsInspector2Icon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-inspector2_48_Light.svg';
import { ReactComponent as AwsVolumeIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Storage/Res_48_Light/Res_Amazon-Elastic-Block-Store_Volume_48_Light.svg';
import { ReactComponent as AwsStsIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_Security-Identity-and-Compliance/Res_48_Light/Res_AWS-Identity-Access-Management_AWS-STS_48_Light.svg';
import { ReactComponent as AwsNetworkFirewallIcon } from '@dome9/berries/vendor-icons/dist/aws/Resource-Icons/Res_General-Icons/Res_48_Light/Res_Firewall_48_Light.svg';
import { ReactComponent as AwsEfsServiceIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Storage/16/Arch_Amazon-EFS_16.svg';
import { ReactComponent as AwsCloudFrontIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Networking-Content-Delivery/16/Arch_Amazon-CloudFront_16.svg';
import { ReactComponent as AwsDynamoDBServiceIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Database/16/Arch_Amazon-DynamoDB_16.svg';
import { ReactComponent as AwsEC2ServiceIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Compute/16/Arch_Amazon-EC2_16.svg';
import { ReactComponent as AwsRoute53ServiceIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Networking-Content-Delivery/16/Arch_Amazon-Route-53_16.svg';
import { ReactComponent as AwsGlobalAcceleratorIcon } from '@dome9/berries/vendor-icons/dist/aws/Architecture-Service-Icons/Arch_Networking-Content-Delivery/48/Arch_AWS-Global-Accelerator_48.svg';


import { ReactComponent as AzureVirtualMachine } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/compute/10021-icon-service-Virtual-Machine.svg';
import { ReactComponent as AzureNetworkSecurityGroupIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10067-icon-service-Network-Security-Groups.svg';
import { ReactComponent as AzureNetworkInterfaceIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10080-icon-service-Network-Interfaces.svg';
import { ReactComponent as AzureSubnetIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/02742-icon-service-Subnet.svg';
import { ReactComponent as AzureApplicationGatewayIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10076-icon-service-Application-Gateways.svg';
import { ReactComponent as AzureVirtualNetworkIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10061-icon-service-Virtual-Networks.svg';
import { ReactComponent as AzurePublicIPAddressIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10068-icon-service-Public-IP-Addresses-(Classic).svg';
import { ReactComponent as AzureLoadBalancerIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/networking/10062-icon-service-Load-Balancers.svg';
import { ReactComponent as AzureWebIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/web/10035-icon-service-App-Services.svg';
import { ReactComponent as AzureEntity } from '@dome9/berries/vendor-icons/dist/logos/azure.svg';
import { ReactComponent as AzureKeyVaultIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/security/10245-icon-service-Key-Vaults.svg';
import { ReactComponent as AzureRoleAssignmentIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/general/10002-icon-service-Subscriptions.svg';
import { ReactComponent as AzureFunctionAppIcon } from '@dome9/berries/vendor-icons/dist/old-pack/azure-icons/functionApps.svg';
import { ReactComponent as AzureUserAssignedIdentityIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/identity/10227-icon-service-Managed-Identities.svg';
import { ReactComponent as AzureAppRegistrationIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/identity/10232-icon-service-App-Registrations.svg';
import { ReactComponent as AzureUserIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/identity/10230-icon-service-Users.svg';
import { ReactComponent as AzureResourceGroupIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/general/10007-icon-service-Resource-Groups.svg';
import { ReactComponent as AzureStorageIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/storage/10087-icon-service-Storage-Accounts-(Classic).svg';
import { ReactComponent as AzureVirtualMachineScaleSetIcon } from '@dome9/berries/vendor-icons/dist/azure/maintained-by-azure/compute/10034-icon-service-VM-Scale-Sets.svg';

import { ReactComponent as KubernetesPodIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/k8pod.svg';
import { ReactComponent as KubernetesJobIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/job.svg';
import { ReactComponent as KubernetesCronjobIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/cronjob.svg';
import { ReactComponent as KubernetesNodeIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/k8node.svg';
import { ReactComponent as KubernetesIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/kubernetes.svg';
import { ReactComponent as KubernetesOpenshiftIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/openshift.svg';
import { ReactComponent as KubernetesReplicasetIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/rs.svg';
import { ReactComponent as KubernetesDeploymentIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/deploy.svg';
import { ReactComponent as KubernetesDaemonsetIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/ds.svg';
import { ReactComponent as KubernetesImageIcon } from 'common/erm-components/icons/vendor-icons/docker.svg';
import { ReactComponent as KubernetesNamespaceIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/ns.svg';
import { ReactComponent as KubernetesServiceAccountIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/sa.svg';
import { ReactComponent as KubernetesRoleIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/c-role.svg';
import { ReactComponent as KubernetesIngressIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/ing.svg';
import { ReactComponent as KubernetesNetworkPolicyIcon } from '@dome9/berries/vendor-icons/dist/old-pack/kubernetes/icons/netpol.svg';

import { ReactComponent as DefaultIcon } from '@dome9/berries/icons/src/system-icons/cloud.svg';
import { ReactComponent as MaliciousIcon } from '@dome9/berries/icons/src/system-icons/malicious.svg';

export const IconMap: Record<string, FunctionComponent> = {
    Default: DefaultIcon,
    Malicious: MaliciousIcon,
    AwsNetworkFirewall: AwsNetworkFirewallIcon,
    AwsSts: AwsStsIcon,
    AwsVolume: AwsVolumeIcon,
    AwsInspector2: AwsInspector2Icon,
    AwsFlowLogs: AwsFlowLogsIcon,
    AwsCloudTrail: AwsCloudTrailIcon,
    AwsElasticCache: AwsElasticCacheIcon,
    AwsElasticKubernetesService: AwsElasticKubernetesIcon,
    AwsCloudFormationStack: AwsCloudFormationStackIcon,
    AwsRDSInstance: AwsRDSInstanceIcon,
    AwsDynamoTable: AwsDynamoTableIcon,
    AwsIamGroup: AwsUserGroupIcon,
    AwsIamUser: AwsUserIcon,
    AwsSecurityGroup: AwsSecurityGroupIcon,
    AwsVpc: AwsVpcIcon,
    AwsSubnet: AwsSubnetIcon,
    AwsAppLoadBalancer: AwsAlbIcon,
    AwsAppLoadBalancerTargetGroup: AwsNlbIcon,
    AwsElasticLoadBalancer: AwsAlbIcon,
    AwsNetworkLoadBalancer: AwsNlbIcon,
    AwsClassicLoadBalancer: AwsClassicLbIcon,
    AwsNacl: AwsNaclIcon,
    AwsNatGateway: AwsNatIcon,
    AwsNetworkInterface: AwsEniIcon,
    AwsEC2Instance: AwsEc2Icon,
    AwsApiGateway: AwsApiGatewayIcon,
    AwsApiGatewayV2: AwsApiGatewayIcon,
    AwsAutoScalingGroup: AwsAsgIcon,
    AwsCloudAccount: AwsCloudAccountIcon,
    AwsEcsCluster: AwsEcsContainerIcon,
    AwsEcsService: AwsEcsServiceIcon,
    AwsEcsTask: AwsEcsTaskIcon,
    AwsEcsTaskDefinition: AwsEcsTaskDefinitionIcon,
    AwsIamPolicy: AwsPolicyIcon,
    AwsIamRole: AwsRoleIcon,
    AwsLambdaFunction: AwsLambdaIcon,
    AwsOrganizationAccount: AwsOrganizationAccountIcon,
    AwsOrganizationUnit: AwsOrganizationUnitIcon,
    AwsRouteTable: AwsRouteTableIcon,
    AwsOrganization: AwsOrganizationManagementAccountIcon,
    AwsRedshiftCluster: AwsRedshiftIcon,
    AwsS3Bucket: AwsS3Icon,
    AwsCloudFrontService: AwsCloudFrontIcon,
    AwsDynamoDBService: AwsDynamoDBServiceIcon,
    AwsEfsService: AwsEfsServiceIcon,
    AwsEC2Service: AwsEC2ServiceIcon,
    AwsGlobalAccelerator: AwsGlobalAcceleratorIcon,
    AwsRoute53Service: AwsRoute53ServiceIcon,

    Azure: AzureEntity,
    AzureVirtualMachine: AzureVirtualMachine,
    AzureNetworkSecurityGroup: AzureNetworkSecurityGroupIcon,
    AzureNetworkInterface: AzureNetworkInterfaceIcon,
    AzureSubnet: AzureSubnetIcon,
    AzureApplicationGateway: AzureApplicationGatewayIcon,
    AzureVNet: AzureVirtualNetworkIcon,
    AzurePublicIPAddress: AzurePublicIPAddressIcon,
    AzureLoadBalancer: AzureLoadBalancerIcon,
    AzureWebApp: AzureWebIcon,
    AzureKeyVault: AzureKeyVaultIcon,
    AzureRoleAssignment: AzureRoleAssignmentIcon,
    AzureFunctionApp: AzureFunctionAppIcon,
    AzureUserAssignedIdentity: AzureUserAssignedIdentityIcon,
    AppRegistration: AzureAppRegistrationIcon,
    AzureUser: AzureUserIcon,
    AzureResourceGroup: AzureResourceGroupIcon,
    AzureStorage: AzureStorageIcon,
    AzureVirtualMachineScaleSet: AzureVirtualMachineScaleSetIcon,

    KubernetesPod: KubernetesPodIcon,
    KubernetesJob: KubernetesJobIcon,
    KubernetesCronjob: KubernetesCronjobIcon,
    KubernetesNode: KubernetesNodeIcon,
    Kubernetes: KubernetesIcon,
    KubernetesOpenshift: KubernetesOpenshiftIcon,
    KubernetesReplicaset: KubernetesReplicasetIcon,
    KubernetesDeployment: KubernetesDeploymentIcon,
    KubernetesDaemonset: KubernetesDaemonsetIcon,
    KubernetesImage: KubernetesImageIcon,
    KubernetesNamespace: KubernetesNamespaceIcon,
    KubernetesServiceAccount: KubernetesServiceAccountIcon,
    KubernetesRole: KubernetesRoleIcon,
    KubernetesIngress: KubernetesIngressIcon,
    KubernetesNetworkPolicy: KubernetesNetworkPolicyIcon,
};
