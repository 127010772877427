import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { DashboardWidgetTypes, IAddinWidgetsDataSource } from 'common/module_interface/overview/Interface';
import { ILogicWidgetsOptions, createWidgets } from './Utils';
import { EVENT_ACTIVITY_AZURE, defaultIntelligenceWidgetsTypeOptions } from './Consts';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

export const initialize_AZURE_EventActivityWidgets = () => {
 
    const AZURE_eventActivityOptions: ILogicWidgetsOptions = {
        'event.d9readonly': defaultIntelligenceWidgetsTypeOptions,
        'event.error.message': defaultIntelligenceWidgetsTypeOptions,
        'event.name': defaultIntelligenceWidgetsTypeOptions,
        'event.status': defaultIntelligenceWidgetsTypeOptions,
        'event.time': defaultIntelligenceWidgetsTypeOptions,
        'event.type': defaultIntelligenceWidgetsTypeOptions,
        'identity.account.id': defaultIntelligenceWidgetsTypeOptions,
        'identity.assetid': defaultIntelligenceWidgetsTypeOptions,
        'identity.id': defaultIntelligenceWidgetsTypeOptions,
        'identity.image': defaultIntelligenceWidgetsTypeOptions,
        'identity.name': defaultIntelligenceWidgetsTypeOptions,
        'identity.region': defaultIntelligenceWidgetsTypeOptions,
        'identity.type': defaultIntelligenceWidgetsTypeOptions,
        'identity.useragent': defaultIntelligenceWidgetsTypeOptions,
        'identity.vpc': defaultIntelligenceWidgetsTypeOptions,
        'issuer.id': defaultIntelligenceWidgetsTypeOptions,
        'issuer.mfa': defaultIntelligenceWidgetsTypeOptions,
        'issuer.name': defaultIntelligenceWidgetsTypeOptions,
        'issuer.region': defaultIntelligenceWidgetsTypeOptions,
        'issuer.sts.token': defaultIntelligenceWidgetsTypeOptions,
        'issuer.token': defaultIntelligenceWidgetsTypeOptions,
        'issuer.type': defaultIntelligenceWidgetsTypeOptions,
        'request.parameters': defaultIntelligenceWidgetsTypeOptions,
        'response.parameters': defaultIntelligenceWidgetsTypeOptions,
        'src.address.geolocation.countryname': defaultIntelligenceWidgetsTypeOptions,
        'src.address.ip': defaultIntelligenceWidgetsTypeOptions,
        'src.address.ismalicious': defaultIntelligenceWidgetsTypeOptions,
        'src.address.maliciousinfo.class': defaultIntelligenceWidgetsTypeOptions,
        'src.address.maliciousinfo.confidence': defaultIntelligenceWidgetsTypeOptions,
        'src.address.maliciousinfo.malwarefamily': defaultIntelligenceWidgetsTypeOptions,
        'src.address.maliciousinfo.owner': defaultIntelligenceWidgetsTypeOptions,
        'src.assetid': defaultIntelligenceWidgetsTypeOptions,
        'src.image': defaultIntelligenceWidgetsTypeOptions,
        'src.region': defaultIntelligenceWidgetsTypeOptions,
        'src.type': defaultIntelligenceWidgetsTypeOptions,
        'src.vpc': defaultIntelligenceWidgetsTypeOptions,
        'target.account.id': defaultIntelligenceWidgetsTypeOptions,
        'target.arn': defaultIntelligenceWidgetsTypeOptions,
        'target.assetid': defaultIntelligenceWidgetsTypeOptions,
        'target.id': defaultIntelligenceWidgetsTypeOptions,
        'target.image': defaultIntelligenceWidgetsTypeOptions,
        'target.name': defaultIntelligenceWidgetsTypeOptions,
        'target.region': defaultIntelligenceWidgetsTypeOptions,
        'target.type': defaultIntelligenceWidgetsTypeOptions,
        'target.vpc': defaultIntelligenceWidgetsTypeOptions,
        'src.address.geolocation.countrycode': [
            DashboardWidgetTypes.Map,
        ]
    };

   
    const AZURE_EventActivityWidgets: IAddinWidgetsDataSource = {
        dataSourceName: EVENT_ACTIVITY_AZURE,
        dataField: { displayName: 'Aggregation', path: 'aggregation' },
        widgets: createWidgets(AZURE_eventActivityOptions)
    };

    globalAddinContainer.addMap(DASHBOARD_DYNAMIC_WIDGETS, [AZURE_EventActivityWidgets], 'dataSourceName');

};
