import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import {
    Button,
    Modal,
    Stack,
    TextArea,
    Typography
} from 'common/design-system/components-v2';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { IRemediateModalProps } from './RemediateModal.types';
import { CloudBotsInput } from '../../../CloudBots/Components/CloudBotsInput';
import {
    ICloudBot,
    ICloudBotParameter,
    IFieldError
} from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { ICloudBotsInputRef } from '../../../CloudBots/Components/CloudBotsInput.types';
import { RemediateModalStyles as Styles } from './RemediateModal.styled';
import { COMMENT_MAX_LENGTH, FormFields } from './RemediateModal.const';
import { FindingRuleCard } from '../../Components/FindingRuleCard/FindingRuleCard';
import { FindingAssetCard } from '../../Components/FindingAssetCard/FindingAssetCard';
import { ICreateRemediationRequest } from 'common/module_interface/intelligence/Remediation/Remediation.interface';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import { getRemediationService } from 'common/module_interface/intelligence/Remediation/Remediation.service';
import { NotificationType } from 'common/interface/notifications';
import { Vendors } from 'common/consts/vendors';
import { AxiosError, AxiosResponse } from 'axios';
import { getSafeServerPlatform } from '../../../Findings.utils';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';

export const RemediateModal: React.FC<IRemediateModalProps> = (props: IRemediateModalProps) => {
    const { finding, closeModal, fieldErrorsProps } = props;
    const { addFieldErrors, getFieldErrorText, clearFieldErrors } = fieldErrorsProps;
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const [isCreatingRemediation, setIsCreatingRemediation] = useState<boolean>(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const commentRef: React.RefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>(null);
    const cloudBotsInputRef: React.RefObject<ICloudBotsInputRef> = useRef<ICloudBotsInputRef>(null);
    const [isModalDirty, setIsModalDirty] = useState(false);

    const commentFieldErrorText: string | undefined = useMemo(() => {
        return getFieldErrorText(FormFields.COMMENT);
    }, [getFieldErrorText]);

    const getComment = useCallback((): string | undefined => {
        return commentRef.current?.value?.trim();
    }, []);

    const isValidCloudBots = useCallback((): boolean | undefined => {
        return cloudBotsInputRef.current?.isValidCloudBots();
    }, []);

    const getSelectedCloudBots = useCallback((): ICloudBot[] | undefined => {
        return cloudBotsInputRef.current?.getSelectedCloudBots();
    }, []);

    const updateCommentFieldError = (errors: IFieldError[]) => {
        const comment: string | undefined = getComment();
        if (!comment) {
            errors.push({
                field: FormFields.COMMENT,
                label: t('REMEDIATE_MODAL.INPUT_FIELDS.COMMENT.ERRORS.REQUIRED')
            });
        } else if (comment.length > COMMENT_MAX_LENGTH) {
            errors.push({
                field: FormFields.COMMENT,
                label: t('REMEDIATE_MODAL.INPUT_FIELDS.COMMENT.ERRORS.MAX_LENGTH')
            });
        }
    };

    const updateFieldsErrorState = (): boolean => {
        const errors: IFieldError[] = [];
        updateCommentFieldError(errors);
        addFieldErrors(errors);
        return errors.length > 0;
    };

    const onCommentChange = () => {
        clearFieldErrors([FormFields.COMMENT]);
        setIsModalDirty(true);
    };

    const onConfirmationModalCancel = () => {
        setIsConfirmationModalOpen(false);
    };

    const onDiscardChanges = () => {
        closeModal();
    };

    const onRemediationModalCancel = () => {
        const isCloudBotFieldDirty: boolean | undefined = cloudBotsInputRef.current?.isFieldDirty();
        if (isModalDirty || isCloudBotFieldDirty) {
            return setIsConfirmationModalOpen(true);
        } else {
            closeModal();
        }
    };

    const onCreateRemediation = async () => {
        const isValidBots: boolean | undefined = isValidCloudBots();
        const isAnyFieldError: boolean = updateFieldsErrorState();
        if (!isValidBots || isAnyFieldError) return;

        const cloudBots: string[] = (getSelectedCloudBots() ?? []).map((cloudBot: ICloudBot) => {
            const cloudBotId: string = cloudBot.id;
            const cloudBotParams: string = cloudBot.parameters.map((param: ICloudBotParameter) => param.value).join(' ').trim();
            return [cloudBotId, cloudBotParams].join(' ').trim();
        });
        const severities: string[] = finding.severityName ? [finding.severityName] : [];
        const platform: Vendors = getSafeServerPlatform(finding.cloudAccountType || EMPTY_STRING);
        const comment: string = getComment() as string;
        const ruleLogic = `name like '${finding.entityName || EMPTY_STRING}'`;

        const requestData: ICreateRemediationRequest = {
            rulesetId: finding.rulesetId,
            platform: platform,
            cloudAccountId: finding.cloudAccountId,
            cloudBots: cloudBots,
            comment: comment,
            ruleName: finding.ruleName,
            logic: ruleLogic,
            ruleLogicHash: finding.ruleLogicHash,
            severities: severities
        };
        try {
            setIsCreatingRemediation(true);
            await getRemediationService().createRemediation(requestData);
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                text: t('REMEDIATE_MODAL.REMEDIATION_CREATED')
            });
            closeModal();
        } catch (error: unknown) {
            const errorTitle = t('REMEDIATE_MODAL.REMEDIATION_CREATION_FAILED');
            if (error instanceof AxiosError) {
                const axiosError: AxiosError = error as AxiosError;
                const response: AxiosResponse = axiosError.response as AxiosResponse;
                const errorMessage: string = response.data.message ?? response.data;
                getNotificationsService().error(errorTitle, errorMessage);
                await getLoggerService().error(`${errorTitle} ${errorMessage}`);
            } else {
                await getLoggerService().error(`${errorTitle} ${(error as Error).message}`);
            }
        } finally {
            setIsCreatingRemediation(false);
        }
    };

    return (
        <Fragment>
            <Modal.ModalDialog onRequestClose={onRemediationModalCancel} isOpen={true} width={'lg'}>
                <Modal.ModalHeader title={t('REMEDIATE_MODAL.TITLE')}
                    onClose={onRemediationModalCancel}/>
                <Modal.ModalContent fullHeight={true}>
                    <Styles.FormFieldsWrapper direction={'column'} spacing={6}>
                        <FindingRuleCard finding={finding} key={'rules-card'}/>
                        <FindingAssetCard finding={finding} key={'entity-card'}/>
                        <CloudBotsInput key={'cloud-bots'} maxCloudBotsAllowed={5} fieldErrorsProps={fieldErrorsProps}
                            selectedRows={[finding]} ref={cloudBotsInputRef}/>
                        <TextArea
                            key={'comment'}
                            ref={commentRef}
                            required={true}
                            clearable={true}
                            inputSize="large"
                            maxLength={COMMENT_MAX_LENGTH}
                            rows={4}
                            onChange={onCommentChange}
                            isError={!!commentFieldErrorText}
                            helperText={commentFieldErrorText}
                            label={t('REMEDIATE_MODAL.INPUT_FIELDS.COMMENT.TITLE')}
                            placeholder={t('REMEDIATE_MODAL.INPUT_FIELDS.COMMENT.PLACEHOLDER')}
                        />
                    </Styles.FormFieldsWrapper>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        <Button key={'cancel-button-action'} variant='text'
                            onClick={onRemediationModalCancel}>{i18n.t('COMMON.CANCEL')}</Button>
                        <Button key={'create-button-action'} color='brandPrimary' loading={isCreatingRemediation}
                            disabled={isCreatingRemediation}
                            onClick={onCreateRemediation}>{t('REMEDIATE_MODAL.ACTIONS.CREATE')}</Button>
                    </Stack>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
            {isConfirmationModalOpen &&
                <ConfirmationModal width={'md'}
                    isOpen={isConfirmationModalOpen}
                    title={t('ACTIONS.CONFIRMATION_MODAL.TITLE')}
                    submitBtnText={i18n.t('COMMON.DISCARD')}
                    onConfirm={onDiscardChanges}
                    onClose={onConfirmationModalCancel}
                    onCancel={onConfirmationModalCancel}>
                    <Typography>{t('ACTIONS.CONFIRMATION_MODAL.DESCRIPTION')}</Typography>
                </ConfirmationModal>}
        </Fragment>
    );
};
