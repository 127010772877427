/* eslint-disable react-hooks/exhaustive-deps */
import { getStoreService, getUserService } from 'common/interface/services';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    AzureOnboardingAccountType,
    AzureOnboardingOperationMode,
    AzureOnboardingScope,
    AzureOnboardingType
} from './helpers/azure.interface';
import * as actions from './helpers/azure.reducer';
import { RenderViewParams } from 'common/components/Wizard/Wizard';
import { isChina } from 'common/utils/dataCenter';
import { logEvent } from 'common/utils/logUtils';
import { Checkbox, Typography, Stack, Input, GroupSelection, Link } from 'common/design-system/components-v2';
import AzureSingleOperation from './components/AzureSingleOperation';

import { azureOnboardingAccountTypeRadioBtns, azureOnboardingOrgRadioBtns, azureOnboardingRadioBtns } from './helpers/azure.consts';
import { CHECK_POINT_URLS, getCheckpointUrl } from 'common/components/Forms/urls';

const AzureWelcome: React.FC<RenderViewParams> = ({ onValidate }) => {
    const { t } = useTranslation();
    const { dispatch } = getStoreService().getReduxTools();
    const formValidations = useSelector(actions.getAzureWelcomeFormValidations);
    const azureOnboardingType = useSelector(actions.getAzureOnboardingType);
    const azureOnboardingAccountType = useSelector(actions.getAzureOnboardingAccountType);
    const azureOnboardingScope = useSelector(actions.getAzureOnboardingScope);
    const isTenantIdAlreadyOnboarded = useSelector(actions.getIsTenantIdAlreadyOnboarded);
    const isSubscriptionIdAlreadyOnboarded = useSelector(actions.getIsSubscriptionIdAlreadyOnboarded);
    const isManagementGroupIdAlreadyOnboarded = useSelector(actions.getIsManagementGroupIdAlreadyOnboarded);
    const welcomeForm = useSelector(actions.getWelcomeForm);
    const isAllowChinaPrivacyPolicy = useSelector(actions.getAllowAzureChinaPrivacyPolicy);
    const documentationLink: keyof typeof CHECK_POINT_URLS = azureOnboardingType === AzureOnboardingType.org ? 'AZURE_ORG_ONBOARDING_DOCS' : 'AZURE_SINGLE_ONBOARDING_DOCS';

    useEffect(() => {
        onValidate(formValidations.valid);
    }, [formValidations]);

    useEffect(() => {
        if (isChina()) handleAccountTypeSelect(AzureOnboardingAccountType.china);
    }, [dispatch]);

    const handleOnboardingTypeSelect = (onboardingType: AzureOnboardingType) => {
        const accountType: AzureOnboardingAccountType = isChina() ? AzureOnboardingAccountType.china : AzureOnboardingAccountType.standard;
        const accountScope: AzureOnboardingScope = isChina() ? AzureOnboardingScope.chinaScope : AzureOnboardingScope.tenant;
        dispatch(actions.setAzureOnboardingType(onboardingType));
        handleAccountTypeSelect(accountType);
        handleAccountScopeSelect(accountScope);
        toggleChinaPolicy(false);
        if (onboardingType === AzureOnboardingType.single) {
            dispatch(actions.setAzureOnboardingOperationMode(AzureOnboardingOperationMode.read));
        }
    };

    const handleAccountTypeSelect = (accountType: AzureOnboardingAccountType) => {
        dispatch(actions.setAzureOnboardingAccountType(accountType));
        dispatch(actions.setCloudShellUrl(accountType));
        if (accountType === AzureOnboardingAccountType.china) {
            handleAccountScopeSelect(AzureOnboardingScope.chinaScope);
        } else {
            handleAccountScopeSelect(AzureOnboardingScope.tenant);
        }
    };

    const handleAccountScopeSelect = (accountScope: AzureOnboardingScope) => {
        dispatch(actions.setIsTenantIdAlreadyOnboarded(false));
        dispatch(actions.setIsManagementGroupIdAlreadyOnboarded(false));
        dispatch(actions.setAzureOnboardingScope(accountScope));
    };

    const toggleChinaPolicy = (allow: boolean) => {
        const user = getUserService().getUser();
        dispatch(actions.setAllowAzureChinaPrivacyPolicy(allow));
        if (allow) {
            logEvent('policyAllowed', {
                message: t('ON_BOARDING.WELCOME.CHINA_PRIVACY_POLICY_ACKED', { userEmail: user.email }),
                userEmail: user.email,
            });
        }
    };

    const onTenantIdChanged = (value: string) => {
        dispatch(actions.setIsTenantIdAlreadyOnboarded(false));
        dispatch(actions.setIsManagementGroupIdAlreadyOnboarded(false));
        dispatch(actions.setWelcomeForm({ key: 'tenantId', value }));
    };

    const onManagementGroupChanged = (value: string) => {
        dispatch(actions.setIsSubscriptionIdAlreadyOnboarded(false));
        dispatch(actions.setWelcomeForm({ key: 'managementGroupId', value }));
    };

    const onSubscriptionIdChanged = (value: string) => {
        dispatch(actions.setIsManagementGroupIdAlreadyOnboarded(false));
        dispatch(actions.setWelcomeForm({ key: 'subscriptionId', value }));
    };
    const handleValidationText = (message?: string, alreadyOnboarded?: string | boolean) => {
        if (message) return message;
        if (alreadyOnboarded){
            if (typeof alreadyOnboarded == 'string') return alreadyOnboarded;
            else return t('ON_BOARDING.AZURE.VALIDATIONS.ALREADY_ONBOARDED');
        }
        return undefined;
    };

    return (
        <Stack data-aid="azure-onboarding-welcome-wrapper">
            <Typography variant='h1'>{t('ON_BOARDING.AZURE.WELCOME_TITLE')}</Typography>
            <Stack spacing={1} margin={[5, 0, 0, 0]} direction='column'>
                <Typography variant='bodyLg'>{t('ON_BOARDING.AZURE.WELCOME_SUB_TITLE_WITHOUT')}</Typography>
                <Typography variant='bodyLg'>{t('ON_BOARDING.AZURE.WELCOME_SUB_TITLE_2')}</Typography>
                <Typography variant='bodyLg'>
                    {t('ON_BOARDING.AZURE.WELCOME_SUB_TITLE_3')}
                    <Link externalUrl={getCheckpointUrl(documentationLink)}>
                        <Typography variant='bodyLg' color="brandPrimary">{t('ON_BOARDING.AZURE.DOCUMENTATION_LINK')}</Typography>
                    </Link>
                    {t('ON_BOARDING.AZURE.WELCOME_SUB_TITLE_3_2')}
                </Typography>
                <Stack margin={[5, 0, 2, 0]} direction='column'>
                    <GroupSelection
                        options={azureOnboardingRadioBtns(t)}
                        onChange={(value) => handleOnboardingTypeSelect(value as AzureOnboardingType)}
                        value={azureOnboardingType}
                        direction="row"
                        label={t('ON_BOARDING.WELCOME.SELECT_WHAT_TO_ONBOARD')}
                    />
                </Stack>
            </Stack>
            <Stack spacing={1} margin={[0, 0, 2, 0]} direction='column'>
                <Typography variant='bodyLg'
                    color="brandPrimary">{t('ON_BOARDING.AZURE.PREREQUISITES')}</Typography>
                <Typography variant='bodyLg'>{t('ON_BOARDING.AZURE.USER_ACCESS_MESSAGE')}</Typography>
            </Stack>
            {azureOnboardingType === AzureOnboardingType.single &&
                <Stack margin={[1, 0, 2, 0]} direction='column'>
                    <GroupSelection
                        options={azureOnboardingAccountTypeRadioBtns(t)}
                        onChange={(value) => handleAccountTypeSelect(value as AzureOnboardingAccountType)}
                        value={azureOnboardingAccountType}
                        direction="row"
                        label={t('ON_BOARDING.AZURE.SELECT_TYPE')}
                    />
                </Stack>
            }
            {azureOnboardingType === AzureOnboardingType.org && azureOnboardingAccountType !== AzureOnboardingAccountType.china &&
                <Stack margin={[1, 0, 2, 0]} direction='column'>
                    <GroupSelection
                        options={azureOnboardingOrgRadioBtns(t)}
                        onChange={(value) => handleAccountScopeSelect(value as AzureOnboardingScope)}
                        value={azureOnboardingScope}
                        direction="row"
                        label={t('ON_BOARDING.AZURE.SELECT_SCOPE')}
                    />
                </Stack>
            }
            {azureOnboardingAccountType === AzureOnboardingAccountType.china &&
                <Stack margin={[2, 0, 4, 0]} direction="row" justifyContent="flex-start">
                    <Checkbox checked={isAllowChinaPrivacyPolicy} onChange={(ev: any) => toggleChinaPolicy(ev.target.checked)}>
                        {t('ON_BOARDING.WELCOME.CHINA_PRIVACY_POLICY_AGREEMENT')}
                    </Checkbox>
                </Stack>
            }
            <Stack margin={[2, 0, 0, 0]} direction="row" justifyContent="space-between">
                <Stack spacing={3} direction="column" style={{ width: '50%' }}>
                    {(azureOnboardingType === AzureOnboardingType.org && azureOnboardingAccountType !== AzureOnboardingAccountType.china) &&
                    <Input
                        data-aid='azure-welcome-tenant-id-input'
                        label={t('ON_BOARDING.AZURE.TENANT_ID')}
                        required
                        value={welcomeForm.tenantId}
                        tooltip={t('ON_BOARDING.AZURE.TENANT_ID_TOOLTIP')}
                        onChange={(ev) => onTenantIdChanged(ev.target.value)}
                        isError={formValidations?.errors?.tenantId || isTenantIdAlreadyOnboarded}
                        helperText={handleValidationText(formValidations?.errors?.tenantId?.[0]?.message, isTenantIdAlreadyOnboarded)}
                        fullWidth
                        clearable
                    />
                    }
                    {((azureOnboardingType === AzureOnboardingType.single) ||
                        (azureOnboardingScope === AzureOnboardingScope.subscription && azureOnboardingAccountType !== AzureOnboardingAccountType.china)) &&
                    <Stack padding={ azureOnboardingType === AzureOnboardingType.single ? [0,1,3,0] : [0]}>
                        <Input
                            data-aid='azure-welcome-subscription-id-input'
                            label={t('ON_BOARDING.AZURE.SUBSCRIPTION_ID')}
                            required
                            value={welcomeForm.subscriptionId}
                            onChange={(ev) => onSubscriptionIdChanged(ev.target.value)}
                            isError={formValidations?.errors?.subscriptionId || isSubscriptionIdAlreadyOnboarded}
                            helperText={handleValidationText(formValidations?.errors?.subscriptionId?.[0]?.message, isSubscriptionIdAlreadyOnboarded)}
                            fullWidth
                            clearable
                        />
                    </Stack>
                    }
                    {(azureOnboardingScope === AzureOnboardingScope.managementGroup && azureOnboardingAccountType !== AzureOnboardingAccountType.china) &&
                    <Input
                        data-aid='azure-welcome-management-group-id-input'
                        label={t('ON_BOARDING.AZURE.MANAGEMENT_GROUP_ID')}
                        tooltip={t('ON_BOARDING.AZURE.MANAGEMENT_GROUP_ID_TOOLTIP')}
                        required
                        value={welcomeForm.managementGroupId}
                        isError={formValidations?.errors?.managementGroupId || isManagementGroupIdAlreadyOnboarded}
                        onChange={(ev) => onManagementGroupChanged(ev.target.value)}
                        helperText={handleValidationText(formValidations?.errors?.managementGroupId?.[0]?.message, isManagementGroupIdAlreadyOnboarded)}
                        fullWidth
                        clearable
                    />
                    }
                </Stack>
            </Stack>
            {azureOnboardingType === AzureOnboardingType.single && (
                <Stack margin={[2, 0, 0, 0]} direction="row" justifyContent="flex-start">
                    <AzureSingleOperation/>
                </Stack>
            )}
        </Stack>
    );
};

export default AzureWelcome;
