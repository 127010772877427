import { FC } from 'react';
import { CpIcon } from '@dome9/components/react/components';
import { getVendor } from 'common/consts/vendors';

export const PlatformRenderer: FC = (props: any) => {
    const vendor = props.data.logics[0].platform;
    return (
        <div className='aws-onboarding-posture-management-ruleset-renderer-wrapper'>
            <CpIcon
                icon={getVendor(vendor)?.icon}
                className={'aws-onboarding-posture-management-ruleset-renderer-wrapper-info-icon'}
            />

            <div
                className='aws-onboarding-welcome-info-content aws-onboarding-summary-info-content aws-onboarding-posture-management-ruleset-renderer-wrapper-cell-text'>
                {vendor}
            </div>
        </div>
    );
};
