import { getHttpService, ICachingConfig } from 'common/interface/services';
import { ERM_URLS } from '../consts';
import { IRiskDataResponse, IRiskManagementService } from 'common/module_interface/RiskManagement/Services';


const defaultCachingConfig: ICachingConfig = {
    useCache: true,
    dataAgeLimit: 3 * 60, //3 minutes
};

export class RiskManagementService implements IRiskManagementService {
    async getAllEnvironmentsRisk() {
        try {
            const response = await getHttpService().post<IRiskDataResponse>(ERM_URLS.RISK_DATA, {
                data: {
                    groupType: 'Environment', groupsIds: [],
                },
            }, {
                cachingConfig: defaultCachingConfig,
            });
            return response.groups;
        } catch {
            return [];
        }
    }

    async getEnvironmentRisk(cloudAccountId: string) {
        try {
            const response = await getHttpService().post<IRiskDataResponse>(ERM_URLS.RISK_DATA, {
                data: {
                    groupType: 'Environment', groupsIds: [cloudAccountId], limit: 1,
                },
            }, {
                cachingConfig: defaultCachingConfig,
            });
            return response.groups[0];
        } catch {
            return undefined;
        }
    }
}