import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import { DashboardWidgetTypes, IAddinWidgetsDataSource } from 'common/module_interface/overview/Interface';
import { ILogicWidgetsOptions, createWidgets } from './Utils';
import { TRAFFIC_ACTIVITY_AWS, defaultIntelligenceWidgetsTypeOptions } from './Consts';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

export const initialize_AWS_TrafficActivityWidgets = () => {
    
    const trafficActivityAWSoptions: ILogicWidgetsOptions = {
        'action': defaultIntelligenceWidgetsTypeOptions,
        'direction': defaultIntelligenceWidgetsTypeOptions,
        'dst.address': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.assetid': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.availabilityzone': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.description': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.image': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.ispublic': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.name': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.id': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.privateipaddress': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.publicdnsname': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.publicipaddress': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.nics.subnet.subnetid': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.region': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.subtype': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.type': defaultIntelligenceWidgetsTypeOptions,
        'dst.asset.vpc': defaultIntelligenceWidgetsTypeOptions,
        'dst.geolocation.countryname': defaultIntelligenceWidgetsTypeOptions,
        'dst.ismalicious': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.class': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.confidence': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.malwarefamily': defaultIntelligenceWidgetsTypeOptions,
        'dst.maliciousinfo.owner': defaultIntelligenceWidgetsTypeOptions,
        'dst.port': defaultIntelligenceWidgetsTypeOptions,
        'eni': defaultIntelligenceWidgetsTypeOptions,
        'packets': defaultIntelligenceWidgetsTypeOptions,
        'protocol': defaultIntelligenceWidgetsTypeOptions,
        'src.address': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.assetid': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.availabilityzone': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.description': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.image': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.ispublic': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.name': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.id': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.privateipaddress': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.publicdnsname': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.publicipaddress': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.nics.subnet.subnetid': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.region': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.subtype': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.type': defaultIntelligenceWidgetsTypeOptions,
        'src.asset.vpc': defaultIntelligenceWidgetsTypeOptions,
        'src.geolocation.countryname': defaultIntelligenceWidgetsTypeOptions,
        'src.ismalicious': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.class': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.confidence': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.malwarefamily': defaultIntelligenceWidgetsTypeOptions,
        'src.maliciousinfo.owner': defaultIntelligenceWidgetsTypeOptions,
        'src.port': defaultIntelligenceWidgetsTypeOptions,
        'starttime': defaultIntelligenceWidgetsTypeOptions,
        'vpc': defaultIntelligenceWidgetsTypeOptions,
        'sumBytes': [
            DashboardWidgetTypes.Trend
        ],
        'dst.geolocation.countrycode': [
            DashboardWidgetTypes.Map,
            DashboardWidgetTypes.MapLinks
        ],
        'src.geolocation.countrycode': [
            DashboardWidgetTypes.Map,
            DashboardWidgetTypes.MapLinks
        ]
    };


    const AWS_TrafficActivityWidgets: IAddinWidgetsDataSource = {
        dataSourceName: TRAFFIC_ACTIVITY_AWS,
        dataField: { displayName: 'Aggregation (Sum in bytes)', path: 'aggregation' },
        widgets: createWidgets(trafficActivityAWSoptions)
    };

    globalAddinContainer.addMap(DASHBOARD_DYNAMIC_WIDGETS, [AWS_TrafficActivityWidgets], 'dataSourceName');

};
