import {
    IProtectedAssetDetailPanelProvider,
} from 'common/module_interface/assets/ProtectedAssets';
import i18next from 'i18next';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';

const ciemFindingsPanel: IProtectedAssetDetailPanelProvider = {
    // disable this tab for all asset types for now. Refer PermissionsPanel in case this tab need to be enabled for specific asset types
    isRelevant: false,
    getValue() {
        return {
            name: 'ciemFindings',
            title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.CIEM_FINDINGS'),
            isReact: false,
        };
    },
};

export default function initializeCiemFindingsPanel() {
    ProtectedAssetsPageRegistry.addPanelAddins([
        {
            id: 'ciemFindings',
            position: 32,
            content: ciemFindingsPanel,
        },
    ]);
}
