import React from 'react';
import { IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import { SimpleListDiv, SimpleListRowDiv } from './SimpleList.styled';
import { Icon } from 'common/design-system/components-v2';
import NoDataToDisplay from 'common/components/Widgets/NoDataToDisplay/NoDataToDisplay';

const SimpleList: React.FC<{ items: IGenericWidgetDataItem[] }> = ({ items }) => {
    const createRows = () => {
        return items.map((item, index) => {
            return (
                <SimpleListRowDiv key={index} isClickable={!!item.onclick} onClick={ () => item.onclick && item.onclick() }>
                    {item.icon && <Icon vendorNameOrPath={item.icon} size={16} />}
                    <div>{item.key}</div>
                </SimpleListRowDiv>
            );
        });
    };

    if (!items?.length) {
        return <NoDataToDisplay />;
    }
    return (
        <SimpleListDiv>
            {createRows()}
        </SimpleListDiv>
    );
};

export default SimpleList;
