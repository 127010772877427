import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { AwsEnvironmentAddingBase } from 'common/helpers/awsEnvironment';
import { ActionType, AwsEnvironmentToolbarActions, AwsEnvironmentData, AwsEnvironmentDataExtraData, AwsAction, ActionItem } from 'common/module_interface/assets/AwsEnvironment';

export type ToolbarItems = AwsAction | ActionItem;

export function evaluateToolbarActions(
    actions: Pick<AwsEnvironmentAddingBase<AwsEnvironmentToolbarActions>, 'getValue' | 'isRelevant'>[],
    awsAccount: AwsEnvironmentData,
    extraData: AwsEnvironmentDataExtraData
): Promise<ToolbarItems[]> {
    return actions.reduce<Promise<ToolbarItems[]>>(async (accum, current) => {
        const isRelevant = current.isRelevant?.(awsAccount, extraData) ?? true;
        if (!isRelevant) {
            return accum;
        }
        try {
            const actionValue = await current.getValue(awsAccount, extraData);
            if (actionValue.type !== 'seperator') {
                return accum.then(prev => [...prev, actionValue]);
            }
            return accum.then(prev => [...prev, { type: 'seperator', label: actionValue.label }]);
        } catch {
            return accum;
        }
    }, Promise.resolve([]));
}

export function awsActionItem(
    action: AwsAction,
    getIsLoadingState: (type: ActionType) => boolean,
    handleOnActionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: ActionType) => void,
): IListItemProps {
    return {
        label: action.label,
        onClick: (e) => handleOnActionClick(e as any, action.actionType),
        disabled: getIsLoadingState(action.actionType),
        value: action.label
    };
}

export function addinActionItem(
    action: ActionItem,
    awsAccount: AwsEnvironmentData,
    extraData: AwsEnvironmentDataExtraData,
    reloadActions: () => void,
    onClickCallback?: () => void,
): IListItemProps {
    return {
        label: action.label,
        itemType: action.type !== 'button' ? action.type : undefined,
        disabled: action.disabled?.(awsAccount, extraData) ?? false,
        onClick: () => {
            onClickCallback && onClickCallback();
            action.callback && action.callback(awsAccount, extraData, () => reloadActions());
        },
        value: action.label,
    };
}