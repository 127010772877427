import styled from 'styled-components/macro';

const TopDiv = styled.div`
  background-color: white;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.09);

  .btn__label {
    margin-right: 10px;
  }

  button, button:hover {
    cursor: default;
    padding-left: 4px;
  }
`;

export const FilterStyled = {
    TopDiv,
};