import {
    ICommonIgnoreItem,
    ICommonIgnoreItemModel,
    ICommonIgnoreItemModelUpdate,
    ICommonIgnoreItemUpdate,
} from './CommonIgnoreList.interface';
import {
    addConditionIfExists,
    addDateConditionIfExists,
    addSingleValueConditionIfExists, getConditionsMap,
    getConditionValueAsDate,
    getConditionValueAsSingle, getConditionValues, IConditionsMap,
} from '../../../RiskManagement.conditions';
import { IFilterCondition } from 'common/erm-components/custom/FilterTree/FilterCondition';
import {
    ICompoundFilterNode,
} from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { IGNORE_LIST_MODEL_FIELDS } from './CommonIgnoreList.consts';
import { getCleanOrgUnitIds } from 'common/erm-components/utils/orgUnits';
import { getCleanCompoundFilter } from 'common/erm-components/utils/filters';

export const getCommonIgnoreItemFromModel = (model: ICommonIgnoreItemModel): ICommonIgnoreItem => {
    const { filter, organizationalUnitIdsFilter } = model;
    const condMap: IConditionsMap = getConditionsMap(filter);
    return {
        id: model._id,
        name: model.name,
        description: model.description,
        expirationDate: getConditionValueAsDate(IGNORE_LIST_MODEL_FIELDS.TIMESTAMP, condMap),
        cveIds: getConditionValues(IGNORE_LIST_MODEL_FIELDS.TARGET_EXTERNAL_ID, condMap),
        packageNames: getConditionValues(IGNORE_LIST_MODEL_FIELDS.PACKAGE_NAME, condMap),
        packagePaths: getConditionValues(IGNORE_LIST_MODEL_FIELDS.PACKAGE_PATH, condMap),
        entityId: getConditionValueAsSingle(IGNORE_LIST_MODEL_FIELDS.SOURCE_EXTERNAL_ID, condMap),
        envIds: getConditionValues(IGNORE_LIST_MODEL_FIELDS.SOURCE_ENVIRONMENT_ID, condMap),
        orgUnitIds: organizationalUnitIdsFilter,
        entityNames: getConditionValues(IGNORE_LIST_MODEL_FIELDS.SOURCE_NAME, condMap),
    };
};

export const getIgnoreItemsFromModels = (models: ICommonIgnoreItemModel[]): ICommonIgnoreItem[] => {
    return models.map(model => getCommonIgnoreItemFromModel(model));
};

export const getCommonIgnoreItemModelUpdateFromItem = (ignoreItemUpdate: ICommonIgnoreItemUpdate): ICommonIgnoreItemModelUpdate => {
    const conditions: IFilterCondition[] = [];
    addDateConditionIfExists(conditions, IGNORE_LIST_MODEL_FIELDS.TIMESTAMP, ignoreItemUpdate.expirationDate);
    addConditionIfExists(conditions, IGNORE_LIST_MODEL_FIELDS.TARGET_EXTERNAL_ID, ignoreItemUpdate.cveIds);
    addConditionIfExists(conditions, IGNORE_LIST_MODEL_FIELDS.PACKAGE_NAME, ignoreItemUpdate.packageNames);
    addConditionIfExists(conditions, IGNORE_LIST_MODEL_FIELDS.PACKAGE_PATH, ignoreItemUpdate.packagePaths);
    addSingleValueConditionIfExists(conditions, IGNORE_LIST_MODEL_FIELDS.SOURCE_EXTERNAL_ID, ignoreItemUpdate.entityId);
    addConditionIfExists(conditions, IGNORE_LIST_MODEL_FIELDS.SOURCE_ENVIRONMENT_ID, ignoreItemUpdate.envIds);
    addConditionIfExists(conditions, IGNORE_LIST_MODEL_FIELDS.SOURCE_NAME, ignoreItemUpdate.entityNames);

    const filter: ICompoundFilterNode | undefined = getCleanCompoundFilter(conditions);
    return {
        filter,
        name: ignoreItemUpdate.name,
        description: ignoreItemUpdate.description,
        organizationalUnitIdsFilter: getCleanOrgUnitIds(ignoreItemUpdate.orgUnitIds),
    };
};
