import { IMenuSectionItem } from 'common/interface/menu';
import { getMenuService, getStoreService } from 'common/interface/services';
import { DEFAULT_LOCAL_DASHBOARD_ID, FAVORITES_MENU_SECTION, OVERVIEW_MENU_ITEM, DEFAULT_GLOBAL_HOME_PAGE_ID } from 'common/module_interface/overview/Consts';
import { IDashboard } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { deleteDashboardInServer, setDefaultDashboardInServer, setFavoriteDashboardInServer, updateDashboardInServer } from 'modules/overview/Api';
import { getAllDashboards, getDefaultDashboardId, getFavoriteDashboardsIds, setDefaultDashboardId, setFavoriteDashboardsIds } from 'modules/overview/Overview.reducer';
import { createPinnedMenuItems, goToDashboardPage } from 'modules/overview/Utils';

export const handleChangeDefaultDashboard = async (dashboardId: string) => {
    const { state, dispatch } = getStoreService().getReduxTools();
    const allDashboards = getAllDashboards(state);
    const currentDefaultDashboardId = getDefaultDashboardId(state);
    const isSelectedDashboardDefault = dashboardId === currentDefaultDashboardId;
    const favoriteDashboardIds = getFavoriteDashboardsIds(state);

    let updatedPinnedItems: IMenuSectionItem[] | null = null;
    if (isSelectedDashboardDefault) {
        setDefaultDashboardInServer(DEFAULT_GLOBAL_HOME_PAGE_ID);
        dispatch(setDefaultDashboardId(DEFAULT_GLOBAL_HOME_PAGE_ID));
        updatedPinnedItems = createPinnedMenuItems(allDashboards, DEFAULT_GLOBAL_HOME_PAGE_ID, favoriteDashboardIds);

    } else {
        setDefaultDashboardInServer(dashboardId);
        dispatch(setDefaultDashboardId(dashboardId));
        updatedPinnedItems = createPinnedMenuItems(allDashboards, dashboardId, favoriteDashboardIds);
    }

    updatedPinnedItems && getMenuService().addMenuItems(OVERVIEW_MENU_ITEM, FAVORITES_MENU_SECTION, updatedPinnedItems);
};


export const handlePinnedDashboardChange = (dashboardId: string) => {
    const { state, dispatch } = getStoreService().getReduxTools();
    const allDashboards = getAllDashboards(state);
    const currentDefaultDashboardId = getDefaultDashboardId(state);
    const favoriteDashboardIds = getFavoriteDashboardsIds(state);
    let updatedFavoriteDashboardIds: string[] = [];

    if (favoriteDashboardIds.includes(dashboardId)) {
        updatedFavoriteDashboardIds = favoriteDashboardIds.filter(item => item !== dashboardId);
    } else {
        updatedFavoriteDashboardIds = [...favoriteDashboardIds, dashboardId];
    }
    setFavoriteDashboardInServer(updatedFavoriteDashboardIds);
    dispatch(setFavoriteDashboardsIds(updatedFavoriteDashboardIds));
    const updatedPinnedItems = createPinnedMenuItems(allDashboards, currentDefaultDashboardId, updatedFavoriteDashboardIds);
    getMenuService().addMenuItems(OVERVIEW_MENU_ITEM, FAVORITES_MENU_SECTION, updatedPinnedItems);
};


export const handleDeleteDashboard = async (dashboardId: string, deleteInSever?: boolean) => {
    const { state, dispatch } = getStoreService().getReduxTools();
    const allDashboards = getAllDashboards(state);
    const currentDefaultDashboardId = getDefaultDashboardId(state);
    const favoriteDashboardIds = getFavoriteDashboardsIds(state);

    let _defaultDashboardId = currentDefaultDashboardId;
    let updatedFavoriteDashboardIds: string[] = [currentDefaultDashboardId, ...favoriteDashboardIds];
    if (favoriteDashboardIds.includes(dashboardId)) {
        updatedFavoriteDashboardIds = favoriteDashboardIds.filter(item => item !== dashboardId);
    }
    if (dashboardId === currentDefaultDashboardId) {
        setDefaultDashboardInServer(DEFAULT_GLOBAL_HOME_PAGE_ID);
        dispatch(setDefaultDashboardId(DEFAULT_GLOBAL_HOME_PAGE_ID));
        _defaultDashboardId = DEFAULT_GLOBAL_HOME_PAGE_ID;
    }
    const updatedPinnedItems = createPinnedMenuItems(allDashboards, DEFAULT_LOCAL_DASHBOARD_ID, updatedFavoriteDashboardIds);
    getMenuService().addMenuItems(OVERVIEW_MENU_ITEM, FAVORITES_MENU_SECTION, updatedPinnedItems);
    if (deleteInSever) {
        await deleteDashboardInServer(dashboardId);
    }
    const defaultDashboard = allDashboards.find(dashboard => dashboard.id === _defaultDashboardId);
    const homeDashboard = allDashboards.find(dashboard => dashboard.id === DEFAULT_LOCAL_DASHBOARD_ID);
    defaultDashboard ? goToDashboardPage(defaultDashboard) : homeDashboard && goToDashboardPage(homeDashboard);

};


export const handleRenameDashboard = async (newName: string) => {
    const { state } = getStoreService().getReduxTools();
    const queryParams = new URLSearchParams(window.location.search);
    const selectedDashboardName = queryParams.get('name');
    const allDashboards = getAllDashboards(state);
    const currentDefaultDashboardId = getDefaultDashboardId(state);
    const favoriteDashboardIds = getFavoriteDashboardsIds(state);
    const selectedDashboard = allDashboards.find(dashboard => dashboard.name === selectedDashboardName);
    const allDashboardsCloned: IDashboard[] = deepCloneObject(allDashboards);
    const selectedDashboardCloned = selectedDashboard && deepCloneObject(selectedDashboard);
    selectedDashboardCloned.name = newName;


    const updatedFavoriteDashboardIds: string[] = [currentDefaultDashboardId, ...favoriteDashboardIds];
    const newAllDashboards = allDashboardsCloned.map(item => {
        if (item.id === selectedDashboardCloned.id) {
            item = selectedDashboardCloned;
        }
        return item;
    });

    const updatedPinnedItems = createPinnedMenuItems(newAllDashboards, DEFAULT_LOCAL_DASHBOARD_ID, updatedFavoriteDashboardIds);
    getMenuService().addMenuItems(OVERVIEW_MENU_ITEM, FAVORITES_MENU_SECTION, []);
    selectedDashboardCloned && await updateDashboardInServer(selectedDashboardCloned, true);
    getMenuService().addMenuItems(OVERVIEW_MENU_ITEM, FAVORITES_MENU_SECTION, updatedPinnedItems);
    selectedDashboardCloned && goToDashboardPage(selectedDashboardCloned);
};