import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDashboardWidget } from 'common/module_interface/overview/Interface';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import { IEnvironmentsFilter, PATH_ENVIRONMENTS_WIDGETS_FILTERS } from 'common/module_interface/assets/Environments';
import { renderFilterPanelForWidgets } from 'common/components/FilterPanel/DashboardFilterPanel/dashboardFilterUtils';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { EnvironmentsServices } from '../Helpers/EnvironmentsServices';

export function getAggregationsFunction(): (filtersValues: IFiltersValues) => Promise<Aggregations> {
    return (async () => {
        return {};
    });
}

const EnvironmentsWidgetsSettings: React.FC<{ widget: IDashboardWidget, updateWidgetCallback: Function,onFilterPanelAsyncChangeFinished?: Function,onFilterPanelAsyncChangeStarted?: Function }> = ({ widget, updateWidgetCallback, onFilterPanelAsyncChangeFinished,onFilterPanelAsyncChangeStarted }) => {
    const [filterPanelComponent, setFilterPanelComponent] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);
    const FILTER_PANEL_ID = 'environments-widgets-filter-panel-id';
    const { t } = useTranslation();

    const handleFilterChange = (filterValues:any) => {
        widget.filterState = filterValues;
        updateWidgetCallback(widget);
    };

    useEffect(() => {
        const filters = globalAddinContainer.get<IEnvironmentsFilter>(PATH_ENVIRONMENTS_WIDGETS_FILTERS);
        async function initPage() {
            const filterPanel = await renderFilterPanelForWidgets(FILTER_PANEL_ID, filters, getAggregationsFunction , EnvironmentsServices.getFilterDefs, widget,
                onFilterPanelAsyncChangeFinished,onFilterPanelAsyncChangeStarted ,handleFilterChange,{}, widget.filterState);
            filterPanel && setFilterPanelComponent(filterPanel);
            setIsLoading(false);
        }
        initPage();
    }, []);


    return (
        <div>
            <>
                {isLoading ?
                    <span>{t('GENERAL.LOADING_FILTERS')}</span>
                    :
                    <div>
                        <div className="font-semibold mb-2">{t('GENERAL.FILTERS')}:</div>
                        { filterPanelComponent }
                    </div>
                }
            </>
        </div>
    );
};


export default EnvironmentsWidgetsSettings;