import {
    IProtectedAssetFilter,

} from 'common/module_interface/assets/ProtectedAssets';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { isNotChina } from 'common/utils/dataCenter';
import { PATH_PROTECTED_ASSETS_PAGE_TABLE_FILTERS } from 'common/module_interface/assets/ProtectedAssets.consts';

const ociCompartmentFilter: IProtectedAssetFilter = {
    id: 'compartment',
};

const filters: IProtectedAssetFilter[] = [
    {
        id: 'add filter',
        position: 10,
    },
    {
        id: 'free text',
    },
    {
        id: 'organizational unit',
    },
    {
        id: 'platform',
    },
    {
        id: 'type',
    },
    {
        id: 'environment',
    },
    {
        id: 'tag',
    },
    {
        id: 'region',
    },
    {
        id: 'network',
    },
    {
        id: 'resourceGroup',
    },
    {
        id: 'runState',
    },

    {
        id: 'publicIdAssociated',
    },
    {
        id: 'billableAsset',
    },
    {
        id: 'labels',
    },
    {
        id: 'saved filters',
        filterProps: {
            savedFiltersComponentName: 'server_index',
        },
        position: 1000,
    },
    {
        id: 'recently used',
        filterProps: {
            componentName: 'server_index_recent',
        },
    },
    {
        id: 'clear all',
    },
];

isNotChina() && filters.push(ociCompartmentFilter);

export default function initializeProtectedAssetPageFilters() {
    globalAddinContainer.addMap(PATH_PROTECTED_ASSETS_PAGE_TABLE_FILTERS, filters);
}