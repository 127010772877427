import React, { useEffect, useState } from 'react';
import { Modal } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import { getAssessmentTrend } from './AssessmentTableDataFetcher';
import LineSetWidget from 'common/components/Widgets/Line/LineSetWidget';
import { IGenericWidgetDataSet } from 'common/module_interface/overview/Interface';


export interface IAssessmentTrendModalProps {
    isOpen: boolean;
    onCancel: () => void;
    assessmentTrendProps: IAssessmentTrendProps;
}

export interface IAssessmentTrendProps {
    rulesetId: number;
    cloudAccountId: string;
    rulesetName: string;
    cloudAccountName: string;
}

export interface IAssessmentTrendData {
    assessmentDate: string;
    failedTests?: number;
    totalTests: number;
    passedTests: number;
}

const AssessmentTrendModal: React.FC<IAssessmentTrendModalProps> = ({ isOpen, onCancel, assessmentTrendProps }) => {

    const { t } = useTranslation('posture');
    const [assessmentTrendData, setAssessmentTrendData] = useState<IAssessmentTrendData[]>([]);
    const [trendData, setTrendData] = useState<IGenericWidgetDataSet>({});
    const { rulesetId, cloudAccountId, rulesetName, cloudAccountName } = assessmentTrendProps;

    useEffect(() => {
        if(rulesetId && cloudAccountId) {
            getAssessmentTrendData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessmentTrendProps]);

    useEffect(() => {
        const assessmentTrendDataFromProps = getTrendData(assessmentTrendData);
        setTrendData(assessmentTrendDataFromProps);
    }, [assessmentTrendData]);

    const getAssessmentTrendData = async () => {
        try {
            setAssessmentTrendData(await getAssessmentTrend(rulesetId, cloudAccountId));
        }
        catch(error) {
            console.error(error);
        }
    };

    const getTrendData = (trendData: IAssessmentTrendData[]): IGenericWidgetDataSet => {
        const dataScore = (passed: number, total: number) => {
            return parseFloat(((passed / total) * 100).toFixed());
        };

        const items = trendData.map(item => {
            const score = dataScore(item.passedTests, item.totalTests);
            return ({
                key: item.assessmentDate, value: score
            });
        });

        return { items: [{ key: 'SCORE', value: items }],
            options: {
                yAxis: {
                    min: 0,
                    max: 100
                }
            }
        };
    };
    
    return(
        <Modal.ModalDialog isOpen={isOpen} width={'lg'}>
            <Modal.ModalHeader title={t('WIDGETS.GRID.ASSESSMENT_TREND.TITLE')} onClose={onCancel}/>
            <Modal.ModalContent>
                <div>{t('WIDGETS.GRID.ASSESSMENT_TREND.RULESET', { rulesetName: rulesetName })}</div>
                <div>{t('WIDGETS.GRID.ASSESSMENT_TREND.CLOUD_ACCOUNT', { cloudAccountName: cloudAccountName })}</div>
                <LineSetWidget data={trendData}/>
            </Modal.ModalContent>
        </Modal.ModalDialog>
    );
};

export default AssessmentTrendModal;