import React from 'react';
import { ExposureNodeData } from 'common/module_interface/insight/ExposureNodeData';
import { BUSINESS_PRIORITY_TYPES } from '../../consts';
import { getBusinessPriority, IBusinessPriorityDisplayExtended } from '../ProtectedAsset/ErmDataUtils';
import {
    getHighestFindingSeverityInfo,
    IFindingSeverityInfo
} from 'common/consts/FindingSeverity';
import { Icon } from 'common/design-system/components-v2';
import { BadgeStyled } from './AssetBadges.styled';

export const BusinessPriorityBadge: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    const businessPriorityDisplay: IBusinessPriorityDisplayExtended = getBusinessPriority(data.businessPriority);
    if (businessPriorityDisplay.id !== BUSINESS_PRIORITY_TYPES.CROWN_JEWEL) return null;

    return (
        <BadgeStyled.BusinessTopDiv bg={businessPriorityDisplay.bg}>
            <Icon name={businessPriorityDisplay.iconName} customColor={businessPriorityDisplay.fg} />
        </BadgeStyled.BusinessTopDiv>
    );
};

export const SecurityEventsBadge: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    if (!data?.securityEvents) return null;
    const count = data.securityEvents.high + data.securityEvents.critical;
    if (count === 0) return null;

    const displayCount = count < 100 ? count : '99+';
    const findingSeverityInfo: IFindingSeverityInfo | undefined = getHighestFindingSeverityInfo(data.securityEvents);
    if (!findingSeverityInfo) {
        return null;
    }

    return <BadgeStyled.SecurityTopDiv bg={findingSeverityInfo.color}>
        {displayCount}
    </BadgeStyled.SecurityTopDiv>;
};