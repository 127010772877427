import { FunctionComponent, useCallback, useEffect } from 'react';
import { setIsReact } from 'App.reducer';
import { Route } from 'react-router';
import { getStoreService } from 'common/interface/services';
import { logPageEnteredEvent } from 'common/utils/logUtils';

interface ReactPageProps {
    path: string | string[];
    onEnter?: () => void;
    exact?: boolean;
    children?: JSX.Element;
}

const ReactPage: FunctionComponent<ReactPageProps> = (props: ReactPageProps) => {
    const { path, exact, onEnter } = props;
    const dispatch = getStoreService().dispatch;
    const setIsReactFalse = useCallback(() => {
        dispatch(setIsReact(false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIsReact(true));
        return setIsReactFalse;
    }, [dispatch, setIsReactFalse]);

    return <Route path={path} exact={exact} render={() => {
        if (onEnter) {
            onEnter();
        }
        logPageEnteredEvent();
        return (props.children);
    }}></Route>;
};

export default ReactPage;
