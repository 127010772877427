import i18n from 'common/services/translations/translations';
import { CONTEXT_FIELD_FULL_NAMES, I18nRiskNamespace } from '../../consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import BusinessPriorityCellRenderer from '../CellRenderers/BusinessPriorityCellRenderer';
import FindingSeverityCountersCellRenderer from '../CellRenderers/FindingSeverityCountersCellRenderer';
import { UNKNOWN_IAM_EXPOSURE_LEVEL_KEY, UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY } from '../ProtectedAsset/ErmDataUtils';
import RiskScoreCellRenderer from 'common/components/ProtectedAssets/Renderers/RiskScoreCellRenderer';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import DataClassificationCellRenderer from '../CellRenderers/DataClassificationCellRenderer';
import { ermPermissionFn } from '../../RiskManagement.utils';
import WAFProtectionCellRenderer from '../CellRenderers/WAFProtectionCellRenderer';
import { WAF_PROTECTION_ACTIVE_FEATURE } from '../ActiveFeatures/ActiveFeatures.consts';
import IssueSeverityCountersCellRenderer from '../CellRenderers/IssueSeverityCountersCellRenderer';


const columnDefAddins: Addin<CGColDef>[] = [
    {
        id: 'ermRiskScore',
        content: () => {
            return {
                colId: 'ermRiskScore',
                field: 'riskScore',
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.RISK_SCORE.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.RISK_SCORE.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true,
                cellRenderer: RiskScoreCellRenderer,
                width: 80,
                minWidth: 80,
                groupFieldGetter: (data) => {
                    return data.riskScore?.toString();
                },
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'ermBusinessPriority',
        content: () => {
            return {
                colId: 'ermBusinessPriority',
                field: 'businessPriority',
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.BUSINESS_PRIORITY.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.BUSINESS_PRIORITY.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true,
                enableRowGroup: true,
                cellRenderer: BusinessPriorityCellRenderer,
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'ermIamSensitivity',
        content: () => {
            return {
                colId: 'ermIamSensitivity',
                field: CONTEXT_FIELD_FULL_NAMES.iamSensitivity,
                valueGetter: (data) => {
                    return data.data.context?.iamSensitivity ?? '';
                },
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.IAM_SENSITIVITY.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.IAM_SENSITIVITY.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true,
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'ermDataSensitivity',
        content: () => {
            return {
                colId: 'ermDataSensitivity',
                field: CONTEXT_FIELD_FULL_NAMES.dataSensitivity,
                valueGetter: (data) => {
                    return data.data.context?.dataSensitivity ?? '';
                },
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.DATA_SENSITIVITY.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.DATA_SENSITIVITY.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true,
                enableRowGroup: true,
            };
        },
    },
    {
        id: 'ermDataClassification',
        content: () => {
            return {
                colId: 'ermDataClassification',
                field: CONTEXT_FIELD_FULL_NAMES.dataClassifications,
                valueGetter: (data) => {
                    return data.data.context?.dataClassifications ?? [];
                },
                cellRenderer: DataClassificationCellRenderer,
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.DATA_CLASSIFICATION.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.DATA_CLASSIFICATION.TOOLTIP', { ns: I18nRiskNamespace }),
                enableRowGroup: true,
                width: 180,
                minWidth: 180,
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'ermNetworkExposure',
        content: () => {
            return {
                colId: 'ermNetworkExposure',
                field: CONTEXT_FIELD_FULL_NAMES.networkExposure,
                valueGetter: (data) => {
                    let networkExposure = data.data.context?.networkExposure ?? '';
                    if (networkExposure === UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY) {
                        networkExposure = '';
                    }
                    return networkExposure;
                },
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.NETWORK_EXPOSURE.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.NETWORK_EXPOSURE.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true,
                enableRowGroup: true,
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'ermIamExposure',
        content: () => {
            return {
                colId: 'ermIamExposure',
                field: CONTEXT_FIELD_FULL_NAMES.iamExposure,
                valueGetter: (data) => {
                    let iamExposure = data.data.context?.iamExposure ?? '';
                    if (iamExposure === UNKNOWN_IAM_EXPOSURE_LEVEL_KEY) {
                        iamExposure = '';
                    }
                    return iamExposure;
                },
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.IAM_EXPOSURE.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.IAM_EXPOSURE.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true,
                enableRowGroup: true,
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'ermMisconfigurations',
        content: () => {
            return {
                colId: 'ermMisconfigurations',
                field: 'postureFindings',
                valueGetter: (data) => {
                    return data.data.postureFindings;
                },
                cellRenderer: FindingSeverityCountersCellRenderer,
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.MISCONFIGURATIONS.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.MISCONFIGURATIONS.TOOLTIP', { ns: I18nRiskNamespace }),
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'ermHasIssues',
        content: () => {
            return {
                colId: 'ermHasIssues',
                minWidth: 130,
                width: 150,
                cellRenderer: IssueSeverityCountersCellRenderer,
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.HAS_ISSUES.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.HAS_ISSUES.TOOLTIP', { ns: I18nRiskNamespace }),
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'ermWafProtection',
        content: () => {
            return {
                colId: 'ermWafProtection',
                field: CONTEXT_FIELD_FULL_NAMES.wafProtection,
                cellRenderer: WAFProtectionCellRenderer,
                headerName: i18n.t('PROTECTED_ASSETS.COLUMNS.WAF_PROTECTION.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('PROTECTED_ASSETS.COLUMNS.WAF_PROTECTION.TOOLTIP', { ns: I18nRiskNamespace }),
                enableRowGroup: true,
            };
        },
        permission: () => ermPermissionFn(WAF_PROTECTION_ACTIVE_FEATURE.key),
    },
];

export default function initializeColumnDefs() {
    ProtectedAssetsTableRegistry.addColumnDefAddins(columnDefAddins);
}
