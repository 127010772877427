import React from 'react';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import TextPropertyBox from 'common/components/PropertyBox/TextPropertyBox';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
export const DescriptionPanel: React.FC<{ finding: IFinding }> = ({ finding }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const description = finding.ruleDescription;

    return ( description &&
        <PropertyBoxesRow>
            <TextPropertyBox title={t('INT_FINDING_OVERVIEW.DESCRIPTION')} value={description || ''} />
        </PropertyBoxesRow>
    );
};
