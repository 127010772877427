import { ISSUE_FIELD_NAMES } from '../../Issues/Issues.interface';
import {
    IFilterTreeFieldDefinition
} from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import {
    ermTrans,

} from '../../../RiskManagement.utils';
import { DefaultConditionOperatorsMap } from 'common/erm-components/custom/FilterTree/FilterTreeDefaults/DefaultConditionConsts';
import { DefaultValuesSelector } from 'common/erm-components/custom/FilterTree/FilterTreeDefaults/DefaultValuesSelector';
import {
    getAllEntityTypeCondOptions,
    getAllEnvironmentCondOptions, getAllIssueSeverityCondOptions, getAllPlatformCondOptions,
    getAllRulesCondOptions,
    getEnvironmentCondOptions, getIssueSeverityCondOptions,
} from '../../../RiskManagement.options';

export const AUTO_ACTION_EDITOR_KEY = 'AUTO_ACTION_EDITOR';
export const MAX_SELECTED_INTEGRATIONS = 5;
export const AUTO_ACTION_EDITOR_DRAWER_EVENT_HANDLER_ID = 'AUTO_ACTION_EDITOR_DRAWER_EVENT_HANDLER_ID';

export const INTEGRATIONS_PAGE_RELATIVE_URL = 'settings/integrations-list';
export const AUTO_ACTION_PREVIEW_FACETS = [
    ISSUE_FIELD_NAMES.SEVERITY,
    ISSUE_FIELD_NAMES.PLATFORM,
    ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
    ISSUE_FIELD_NAMES.ORGANIZATIONAL_UNIT_ID,
    ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
    ISSUE_FIELD_NAMES.RULE_TITLE,
];

const selectorCommonProps = {
    conditionOperatorsInfo: DefaultConditionOperatorsMap.selection,
    conditionValuesComponent: DefaultValuesSelector,
};

export const getAutoActionsFilterDefinitions = async (): Promise<IFilterTreeFieldDefinition[]> => [
    {
        name: ISSUE_FIELD_NAMES.SEVERITY,
        header: ermTrans('ISSUES.TABLE.FILTERS.SEVERITY.TITLE'),
        getSelectOptions: getIssueSeverityCondOptions,
        valueOptions: getAllIssueSeverityCondOptions(),
        ...selectorCommonProps,
    },
    {
        name: ISSUE_FIELD_NAMES.PLATFORM,
        header: ermTrans('ISSUES.TABLE.FILTERS.PLATFORM.TITLE'),
        valueOptions: getAllPlatformCondOptions(),
        ...selectorCommonProps,
    },
    {
        name: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
        header: ermTrans('ISSUES.TABLE.FILTERS.ENTITY_TYPE.TITLE'),
        valueOptions: getAllEntityTypeCondOptions(),
        ...selectorCommonProps,
    },
    {
        name: ISSUE_FIELD_NAMES.ENVIRONMENT_ID,
        header: ermTrans('ISSUES.TABLE.FILTERS.ENVIRONMENT.TITLE'),
        getSelectOptions: getEnvironmentCondOptions,
        valueOptions: await getAllEnvironmentCondOptions(),
        ...selectorCommonProps,
    },
    {
        name: ISSUE_FIELD_NAMES.RULE_ID,
        header: ermTrans('ISSUES.TABLE.FILTERS.RULE_TITLE.TITLE'),
        valueOptions: await getAllRulesCondOptions(),
        ...selectorCommonProps,
    },
];
