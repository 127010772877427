import styled from 'styled-components/macro';
import { Tooltip, Icon } from '@dome9/berries/react-components';
import stackWithZip from '../../../assets/images/oci/stack-with-zip.png';
import rootImage from '../../../assets/images/oci/root-compartment.png';
import runApplyImage from '../../../assets/images/oci/run-apply.png';
import jobSucceedImage from '../../../assets/images/oci/job-succeed.png';

export const FormWrapper= styled.div`
  width: 50%;
`;

export const BtnWrapper = styled.div`
  display: inline-block;
  margin-left: 3px;
`;

export const Tooltip_ = styled(Tooltip)`
  margin-left: 1px;
`;

export const Icon_ = styled(Icon)`
  margin-left: 5px;
  font-size: 14px;
      svg {
        fill: grey;
      }
`;

export const ZipFileImage = styled.div`
  background-image: url(${stackWithZip});
  background-size: cover;
  width: 380px;
  height: 315px;
  background-repeat: no-repeat;
`;

export const RootImage = styled.div`
  background-image: url(${rootImage});
  background-size: cover;
  width: 175px;
  height: 75px;
  background-repeat: no-repeat;
`;

export const RunApplyImage = styled.div`
  background-image: url(${runApplyImage});
  background-size: cover;
  width: 320px;
  height: 90px;
  background-repeat: no-repeat;
`;

export const JobSucceedImage = styled.div`
  background-image: url(${jobSucceedImage});
  background-size: cover;
  width: 380px;
  height: 335px;
  background-repeat: no-repeat;
`;

export const SplitLine= styled.div`
  width: 50%;
`;