import { getHttpService } from 'common/interface/services';
import { DashboardWidgetTypes, IDashboardWidget } from 'common/module_interface/overview/Interface';
import dayjs from 'dayjs';
import {
    __ALL_ACCOUNTS__,
    AssetSourceMap,
    EVENT_ACTIVITY_ALIBABA,
    EVENT_ACTIVITY_AWS,
    EVENT_ACTIVITY_AZURE,
    SourceOptionsByDataSourceName,
} from './Consts';
import { getFilteredCloudAccountsBySource } from './Utils';
import { IGslRunResponse } from 'common/module_interface/intelligence/Intelligence.interface';

export const logicDataGetter = async (widget: IDashboardWidget, dataConvertor: Function) => {
    const widgetDataSourceName = widget.dataSourceName as keyof typeof SourceOptionsByDataSourceName;
    const intelligenceWidgetSource = SourceOptionsByDataSourceName[widgetDataSourceName];
    const assetSource = AssetSourceMap[widgetDataSourceName];
    const isEventActivity = intelligenceWidgetSource === SourceOptionsByDataSourceName[EVENT_ACTIVITY_AWS] || intelligenceWidgetSource === SourceOptionsByDataSourceName[EVENT_ACTIVITY_AZURE] || intelligenceWidgetSource === SourceOptionsByDataSourceName[EVENT_ACTIVITY_ALIBABA];
    const defaultTimeFrame = 1;
    let currentTime = dayjs();
    let XHoursAgo = currentTime.subtract(widget.timeFrame || defaultTimeFrame, 'hour');
    XHoursAgo = XHoursAgo.set('seconds', 0).set('milliseconds', 0);
    currentTime = currentTime.set('seconds', 0).set('milliseconds', 0);

    const generateGslString = () => {
        const getSumByExpression = () => {
            if (isEventActivity) {
                return 'count()';
            } else {
                return 'sum(bytes)';
            }
        };

        const getNodeTypeExpression = () => {
            if (isEventActivity) {
                return `, ${widget.aggregation} as nodeType`;
            } else {
                return '';
            }

        };

        const getEventExpression = () => {
            if (isEventActivity) {
                return ', event.type';
            } else {
                return '';
            }
        };

        const getAggExpression = () => {
            const orderName = getOrderName();
            if (intelligenceWidgetSource === SourceOptionsByDataSourceName[EVENT_ACTIVITY_AWS]) {
                return `cnt by bin(event_time, ${widget.options.binSize || '10m'}), ${widget.aggregation} select cnt, event_time as timeSlice, ${widget.aggregation}`;
            } else {
                return `sum_bytes by stream_owner, bin(endtime, 1h) select datetime_part('hour', endtime) as hour, sum_bytes, format_datetime(endtime, 'yyyy-MM-dd') as date_time, stream_owner order by date_time ${orderName}, hour ${orderName}`;
            }
        };

        if (widget.type === DashboardWidgetTypes.Trend) {
            return `${SourceOptionsByDataSourceName[widgetDataSourceName]} summarize ${getSumByExpression()} as ${getAggExpression()}`;
        } else {
            const validFilter = widget?.gslFilter?.split(' ').slice(2).join(' ') || '';
            return `${SourceOptionsByDataSourceName[widgetDataSourceName]} where isnotempty(${widget.aggregation}) ${validFilter ? `and ${validFilter}` : ''} summarize ${getSumByExpression()} as count by ${widget.aggregation} ${getEventExpression()} select count, ${widget.aggregation} as field ${getNodeTypeExpression()}`;
        }

    };

    const getOrderName = () => {
        if (widget.type === DashboardWidgetTypes.Trend || widget.type === DashboardWidgetTypes.Bottom) {
            return intelligenceWidgetSource === SourceOptionsByDataSourceName[EVENT_ACTIVITY_AWS] ? '' : 'asc';
        } else {
            return 'desc';
        }
    };

    const getWidgetLimit = () => {
        if (widget.type === DashboardWidgetTypes.Trend && intelligenceWidgetSource === SourceOptionsByDataSourceName[EVENT_ACTIVITY_AWS]) {
            return 1000;
        } else if (widget.type === DashboardWidgetTypes.Trend) {
            return -1;
        } else {
            return widget.limit || 10;
        }
    };

    const getCloudAccountExternalIds = async () => {
        const filteredCloudAccounts = await getFilteredCloudAccountsBySource(widgetDataSourceName);
        return filteredCloudAccounts.map(cloudAccount => cloudAccount.externalId);
    };


    const cloudAccounts = widget.cloudAccountId === __ALL_ACCOUNTS__ ? await getCloudAccountExternalIds() : [widget.cloudAccountId];

    try {
        const response = await getHttpService().post<IGslRunResponse>({
            path: 'gslws/gsl-run?action=fetch',
            requestObject: {
                data: {
                    cloudAccount: cloudAccounts,
                    gsl: generateGslString(),
                    options: {
                        decorators: [],
                        start: XHoursAgo.valueOf(),
                        end: currentTime.valueOf(),
                        limit: getWidgetLimit(),
                        source: assetSource,
                    },
                    withCredentials: true,
                },
            },
            cachingConfig: { useCache: true },
        });

        return await dataConvertor(response, widget);

    } catch (error: any) {
        console.error(error);
    }
};
