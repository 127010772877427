import { IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { ISSUE_FIELD_NAMES } from '../../../Issues/Issues.interface';
import { FilterConditionOperator } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { getAllDataAssetsTypes } from '../../../ProtectedAsset/ErmDataUtils';
import {
    getSeveritiesLevelBoxItems
} from '../../RiskDashboard/SecurityIssues/IssuesBySeverity/IssuesBySeverity.datasource';
import {
    IEnvironmentsStatusData,
    IIssuesStatusData,
    IReadinessStatusData,
    ISummaryStatusData,
} from './DataSecuritySummaryStatus.interface';
import { runAll } from 'common/utils/helpFunctions';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { ILevelBoxItem } from 'common/erm-components/custom/LevelBoxes/LevelBox.interface';
export const DSPM_COVERAGE_URL = 'erm/dspm/coverage-status';

interface ITotalCoverage {
    dataAssets: number,
    dataAssetsCovered: number,
    coveragePercentage: number,
    environmentsWithSensitiveData: number,
    environmentsWithSensitiveDataAndSecurityIssues: number,
}

interface ICoverageByType {
    type: string,
    totalEnvironments: number,
    dspmEnabledEnvironments: number,
    totalAssets: number,
    coveredAssets: number,
}

interface IFullCoverageModel {
    dataAssets: string[];
    dataAssetsWithDspmSupport: string[];
    totalCoverage: ITotalCoverage;
    coverageByTypes: ICoverageByType[];
    sugggestions: string[];
}

const getFullCoverage = async (): Promise<IFullCoverageModel> => {
    return sendHttpRequest<IFullCoverageModel>(DSPM_COVERAGE_URL, { method: 'GET' });
};

const calcReadinessData = (fullCoverage: IFullCoverageModel): IReadinessStatusData => {
    const allDataAssetsCount = fullCoverage.totalCoverage.dataAssets;
    const coveredDataAssetsCount = fullCoverage.totalCoverage.dataAssetsCovered;
    return {
        coveredDataAssetsCount,
        allDataAssetsCount,
        coveragePercentage: allDataAssetsCount > 0 ? (coveredDataAssetsCount * 100 / allDataAssetsCount) : 0,
        messages: fullCoverage.sugggestions,
    };
};

const fetchIssuesData = async (): Promise<IIssuesStatusData> => {
    const filter: IServerInputFilterDetails = {
        fields: [
            {
                name: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
                operator: FilterConditionOperator.In,
                values: getAllDataAssetsTypes(),
            }
        ],
    };

    const items: ILevelBoxItem[] = await getSeveritiesLevelBoxItems(filter);
    return {
        items,
    };
};

const calcEnvironmentsData = (fullCoverage: IFullCoverageModel): IEnvironmentsStatusData => {
    return {
        riskySensitiveEnvsCount: fullCoverage.totalCoverage.environmentsWithSensitiveDataAndSecurityIssues,
        allSensitiveEnvsCount: fullCoverage.totalCoverage.environmentsWithSensitiveData,
    };
};

export const fetchSummaryStatusData = async (): Promise<ISummaryStatusData> => {
    const promisesMap: any = {
        fullCoverage: getFullCoverage(),
        issuesData: fetchIssuesData(),
    };

    const resultsMap: any = await runAll(promisesMap, true);
    const fullCoverage: IFullCoverageModel = resultsMap.fullCoverage;
    return {
        readinessStatus: calcReadinessData(fullCoverage),
        issuesStatus: resultsMap.issuesData,
        environmentsStatus: calcEnvironmentsData(fullCoverage),
    };
};
