/* eslint-disable react/prop-types */

import { IGenericWidgetSettingsProps } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { IAlertOptionsItem } from '../WidgetsData/AlertsTrendDataGetter';
import AlertsOptionLineSettings from './AlertsOptionLineSettings';
import { useEffect, useState } from 'react';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    ICloudAccount,
    IOrganizationalUnitTreeNode
} from 'common/interface/data_services';
import { flattenTree } from 'common/utils/helpFunctions';
import { ISelectOption } from 'common/interface/general';


interface ouFlatTree {
    name: string,
    id: string,
    path: string[],
    pathIds: string[],
}

const AlertsTrendWidgetSettings: React.FC<IGenericWidgetSettingsProps> = ({ widget, updateWidgetCallback }) => {
    const [allCloudAccounts, setAllCloudAccounts] = useState<ICloudAccount[]>([]);
    const [ousSelectOptions, setOusSelectOptions] = useState<ISelectOption[]>([]);
    const [envsSelectOptions, setEnvsSelectOptions] = useState<ISelectOption[]>([]);
    const [ouFlatTree, setOuFlatTree] = useState<ouFlatTree[]>([]);
    const [rootOu, setRootOu] = useState<IOrganizationalUnitTreeNode | null>(null);

    const numberOfFilterComponents = 3;
    //const widgetClone = deepCloneObject(widget);
    if(!widget.options) {
        widget.options = {};
    }
    if(!widget.options.alertsOptions) {
        widget.options.alertsOptions = [];
    }


    for (let index = 0; index < numberOfFilterComponents; index++) {
        if(!widget.options.alertsOptions[index]){
            const defaultAlertsOptions = {
                selected: null,
                selectedName: null,
                title: `Line ${index + 1}`,
                type: 'None',
                widgetConfigFilterState: []
            };

            widget.options.alertsOptions.push(defaultAlertsOptions);
        }
    }


    useEffect(() => {
        async function setOuSelectOptions() {
            const _organizationalUnitsTree = await getOrganizationalUnitService().getAllOrganizationalUnits();
            const _flatTree = flattenTree(_organizationalUnitsTree[0]);
            const _rootOu = (_organizationalUnitsTree && _organizationalUnitsTree.length > 0) ? _organizationalUnitsTree[0]: null;
            const _ousSelectOptions = _flatTree.map((ou) => {
                return {
                    label: ou.name,
                    value: ou.id,
                };
            });
            _rootOu && _ousSelectOptions.unshift({ label: _rootOu.item.name, value: _rootOu.item.id });

            setOusSelectOptions(_ousSelectOptions);
            setOuFlatTree(_flatTree);
            setRootOu(_rootOu);
        }

        async function setEnvSelectOptions() {
            const _allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            const _envsSelectOptions = _allCloudAccounts.map((env) => {
                return {
                    label: env.name,
                    value: env.externalId,
                };
            });

            setAllCloudAccounts(_allCloudAccounts);
            setEnvsSelectOptions(_envsSelectOptions);
        }

        setOuSelectOptions();
        setEnvSelectOptions();


    }, []);


    const updateAlertOptionCallback = (alertsOption: IAlertOptionsItem, index: number)=>{
        const _widgetClone = deepCloneObject(widget);
        _widgetClone.options.alertsOptions[index] = { ...alertsOption };
        updateWidgetCallback(_widgetClone);
    };

    const updateAlertFilterCallback = (filterState: any, index: number) => {
        const _widgetClone = deepCloneObject(widget);
        _widgetClone.options.alertsOptions[index].widgetConfigFilterState = filterState;
        updateWidgetCallback(_widgetClone);
    };

    return (
        <div className='mt-8'>
            {widget.options?.alertsOptions.map((alertsOption: IAlertOptionsItem, index: number) => {
                return (
                    <AlertsOptionLineSettings
                        key={index}
                        alertsOption={alertsOption}
                        index={index}
                        widget={widget}
                        updateAlertOptionCallback={updateAlertOptionCallback}
                        updateAlertFilterCallback={updateAlertFilterCallback}
                        optionsData = {{ allCloudAccounts: allCloudAccounts , ousSelectOptions: ousSelectOptions, envsSelectOptions: envsSelectOptions, ouFlatTree: ouFlatTree, rootOu: rootOu }}/>
                );
            })}
        </div>
    );
};

export default AlertsTrendWidgetSettings;
