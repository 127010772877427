import { Addin } from 'common/extensibility/AddinRegistry';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { IEventTableExport, IEventsDatasource } from 'common/interface/events';
import { getNotificationsService, getUserService } from 'common/interface/services';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import { Input } from 'common/design-system/components-v2';
import { isEmailValid } from 'common/utils/http';
import i18n from 'common/services/translations/translations';
import { getEventsNamespace } from 'modules/events/initialize.i18n';

export const exportsDefs = () => {
    const exportOptions: Addin<IEventTableExport>[] = [
        {
            id: 'export-download',
            content: {
                id: 'export-download',
                name: i18n.t('CSV_DOWNLOAD.NAME', { ns: getEventsNamespace('exports') }),
                icon: { name: 'download' },
                callback: (_, __, dataSource) => {
                    const downloadPromise = dataSource.downloadCSV();
                    getNotificationsService().runPromise({
                        promise: downloadPromise,
                        autoClose: 3000,
                        success: { title: i18n.t('CSV_DOWNLOAD.NOTIFICATIONS.SUCCESS', { ns: getEventsNamespace('exports') }) },
                        error: { title: i18n.t('CSV_DOWNLOAD.NOTIFICATIONS.ERROR', { ns: getEventsNamespace('exports') }) },
                        pending: { title: i18n.t('CSV_DOWNLOAD.NOTIFICATIONS.PENDING', { ns: getEventsNamespace('exports') }) }
                    });
                },
                tooltip: (_, __, dataSource) => {
                    if (dataSource.rowCount > 10000) {
                        return i18n.t('CSV_DOWNLOAD.TOOLTIP_DISABLED_LARGE_AMOUNT', { ns: getEventsNamespace('exports') });
                    }
                },
                isActionDisabled: (_, __, dataSource) => {
                    return dataSource.rowCount > 10000;
                },
            }
        },
        {
            id: 'export-email',
            content: {
                id: 'export-email',
                name: i18n.t('CSV_EMAIL.NAME', { ns: getEventsNamespace('exports') }),
                icon: { name: 'emailAttachment' },
                callback: (_, __, dataSource) => openEmailModal(dataSource),
            }
        }
    ];
    return exportOptions;
};

export default function initializeExportsDefs() {
    EventsTableRegistry.addExports(exportsDefs());
}

const openEmailModal = (dataSource: IEventsDatasource, emailError?: string) => {
    const user = getUserService().getUser();
    let userEmail = user.email;
    globalModelUtils.showConfirmationModal({
        width: 'lg',
        title: i18n.t('CSV_EMAIL.EMAIL_MODAL.TITLE', { ns: getEventsNamespace('exports') }),
        text: i18n.t('CSV_EMAIL.EMAIL_MODAL.TEXT', { ns: getEventsNamespace('exports') }),
        submitBtnText: i18n.t('CSV_EMAIL.EMAIL_MODAL.SUBMIT_BTN', { ns: getEventsNamespace('exports') }),
        children: (
            <Input
                autoFocus
                required
                label={i18n.t('CSV_EMAIL.EMAIL_MODAL.EMAIL_INPUT.LABEL', { ns: getEventsNamespace('exports') })}
                placeholder={i18n.t('CSV_EMAIL.EMAIL_MODAL.EMAIL_INPUT.PLACEHOLDER', { ns: getEventsNamespace('exports') })}
                defaultValue={userEmail}
                onChange={(e) => userEmail = e.target.value}
                isError={!!emailError}
                helperText={emailError}
                fullWidth
            />
        ),
        onConfirm: () => {
            if (!userEmail || !isEmailValid(userEmail)) {
                openEmailModal(dataSource, i18n.t(`CSV_EMAIL.EMAIL_MODAL.EMAIL_INPUT.VALIDATIONS.${!userEmail ? 'REQUIRED' : 'INVALID'}`, { ns: getEventsNamespace('exports') }));
                return true;
            } else {
                const downloadPromise = dataSource.emailCSV([userEmail]);
                getNotificationsService().runPromise({
                    promise: downloadPromise,
                    autoClose: 3000,
                    success: { title: i18n.t('CSV_EMAIL.NOTIFICATIONS.SUCCESS', { ns: getEventsNamespace('exports') }) },
                    error: { title: i18n.t('CSV_EMAIL.NOTIFICATIONS.ERROR', { ns: getEventsNamespace('exports') }) },
                    pending: { title: i18n.t('CSV_EMAIL.NOTIFICATIONS.PENDING', { ns: getEventsNamespace('exports') }) }
                });
            }
        }
    });
};