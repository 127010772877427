import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { IFieldInfo } from 'common/interface/general';
import { isArray, isString } from 'common/utils/helpFunctions';
import { Pages } from 'common/enum/Pages';
import { changeUrl } from 'common/utils/http';
import { ASSET_FIELD_NAMES, ERM_CONSTS, ERM_URLS } from 'modules/riskManagement/consts';
import { FindingsAlertType } from 'common/components/Findings/Findings.interface';
import { ITimeFromTo } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { generateAssetPageUrl, getEventsUrl } from 'common/module_interface/assets/utils';
import { IAsset, UrlFuncResult } from 'common/assets/common.assets';
import { isRiskSupportedForAsset } from '../components/ProtectedAsset/ErmDataUtils';
import { getCompactFilterFields } from '../RiskManagement.utils';
import { IErmUrlsService } from 'common/module_interface/RiskManagement/Services';
import { FilterValueType, ICompactFilterItem } from '../RiskManagement.filters';

export class ErmUrlsService implements IErmUrlsService {

    private fieldValueToUrl(value: any): string {
        if (value === undefined) {
            return '';
        } else if (isArray(value)) {
            const values: any[] = value;
            const strings: string[] = values.map((val: any) => this.fieldValueToUrl(val));
            return `[${strings.join(',')}]`;
        } else if (isString(value)) {
            const str = String(value).trim();
            if (str && (!isNaN(Number(str)))) {
                return str;
            }
            else if (str === 'true' || str === 'false') {
                return str;
            }
        } else if (typeof value === 'number') {
            return String(value);
        }
        return JSON.stringify(value);
    }

    private getUrlWithFilters(baseUrl: string, filtersItems?: IFieldInfo[], freeText?: string) {
        let url;
        const compactFields: ICompactFilterItem[] = getCompactFilterFields(filtersItems) || [];
        if (freeText) {
            compactFields.push({
                name: FILTERS_KEYS.FREE_TEXT,
                value: freeText,
                valueType: FilterValueType.STRING,
            });
        }
        if (compactFields?.length) {
            const filterParts = compactFields.map(field => {
                const strValue = this.fieldValueToUrl(field.value);
                return `${JSON.stringify(field.name)}:${strValue}`;
            });
            const filterString = filterParts.join(',');
            url = `${baseUrl}?filterPanel={${filterString}}`;
        } else {
            url = baseUrl;
        }
        return url.startsWith('/') ? url : `/${url}`;
    }

    private getEnvironmentTableUrl(filterFields?: IFieldInfo[], freeText?: string, envType?: string) {
        const urlWithFilters = this.getUrlWithFilters(Pages.CloudAccountEnv, filterFields, freeText);
        return envType ? `${urlWithFilters}&envType=${envType}` : urlWithFilters;
    }

    public gotoEnvironmentTable(filterFields?: IFieldInfo[], freeText?: string, envType?: string) {
        changeUrl(this.getEnvironmentTableUrl(filterFields, freeText, envType));
    }

    public goToProtectedAssetsForType(typeByPlatform: string, filterFields?: IFieldInfo[]) {
        const fields: IFieldInfo[] = [
            ...(filterFields || []).filter(field => field.name !== ASSET_FIELD_NAMES.type),
            {
                name: ASSET_FIELD_NAMES.type,
                value: typeByPlatform,
            }
        ];

        this.goToProtectedAssetsTable(fields);
    }

    private getProtectedAssetsTableUrl(filtersItems?: IFieldInfo[], freeText?: string) {
        return this.getUrlWithFilters(ERM_CONSTS.ERM_PROTECTED_ASSETS_URL, filtersItems, freeText);
    }

    public goToProtectedAssetsTable(filtersItems?: IFieldInfo[], freeText?: string){
        changeUrl(this.getProtectedAssetsTableUrl(filtersItems, freeText));
    }

    public goToEventsTable(alertType: FindingsAlertType, freeTextPhrase: string, filterFields: IFieldInfo[], creationTime?: ITimeFromTo) {
        changeUrl(getEventsUrl(alertType, freeTextPhrase, filterFields, creationTime));
    }

    private getSecurityIssuesTableUrl(filterFields?: IFieldInfo[], freeText?: string) {
        return this.getUrlWithFilters(ERM_URLS.ISSUES, filterFields, freeText);
    }

    public gotoSecurityIssuesTable(filterFields?: IFieldInfo[], freeText?: string) {
        changeUrl(this.getSecurityIssuesTableUrl(filterFields, freeText));
    }

    public generateAssetUrl(asset: IAsset, assetUrl: UrlFuncResult, tabName?: string, innerTabIndex?: number) {
        if (isRiskSupportedForAsset(asset.typeByPlatform)) {
            return generateAssetPageUrl(asset, assetUrl, ERM_CONSTS.ERM_PROTECTED_ASSETS_URL,
                ERM_CONSTS.ERM_PROTECTED_ASSETS_EXTRA_PARAMS, tabName, innerTabIndex);
        } else {
            return generateAssetPageUrl(asset, assetUrl, undefined, undefined, tabName, innerTabIndex);
        }
    }

}
