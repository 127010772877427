import { IApplicationReducer } from 'common/interface/redux';
import { CloudBotsRegistry } from 'common/module_interface/intelligence/CloudBots/CloudBotsRegistry';
import cloudBotsReducer from './Reducers';
import { initializeCloudBotsDictionary } from './CloudBots.definitions';

const initializeCloudBotsReducers = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'cloudBots',
            reducer: cloudBotsReducer,
            isBlackList: false,
        },
    ];
    CloudBotsRegistry.addReducers(reducers, 'name');
};

export default function initializeCloudBots() {
    initializeCloudBotsDictionary();
    initializeCloudBotsReducers();
}
