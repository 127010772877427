import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import {
    getIsLoginFormHasErrors,
    getIsLoginFormLoading,
    getLoginFormErrors,
    getLoginFormPassword,
    getLoginFormUsername,
    handleKeyPress,
    setHasError,
    setPassword,
    setUsername,
} from './LoginForm.reducer';
import { CpComboBox, CpCommonButton, CpLoadingDots, CpMenu, CpMenuItem } from '@dome9/components/react/components';
import { Link } from 'react-router-dom';
import { REACT_HOOK_FORM_VALIDATIONS } from './Login.consts';
import { submitLogin } from 'platform/auth/Auth.actions';
import { useTranslation } from 'react-i18next';
import { getStoreService } from 'common/interface/services';
import { getRegistrationUrl } from 'common/utils/RuntimeEnvironment';
import { Pages } from 'common/enum/Pages';
import DATA_CENTERS from 'common/consts/data.centers';

const EmailAndPassword: FunctionComponent = () => {
    const dispatch = getStoreService().dispatch;
    const isLoading = useSelector(getIsLoginFormLoading);
    const errors = useSelector(getLoginFormErrors);
    const loginFormHasError = useSelector(getIsLoginFormHasErrors);
    const username = useSelector(getLoginFormUsername);
    const password = useSelector(getLoginFormPassword);
    const registrationUrl = getRegistrationUrl();

    const { t } = useTranslation();
    const submitForm = async () => {
        await submitLogin();
    };
    return (
        <form className='form login-form__form flex-column' onSubmit={submitForm} onKeyPress={(e) => handleKeyPress(e)}>
            {isLoading && <CpLoadingDots append-to-body={true} />}
            <input
                autoComplete='on'
                className={`form__input  ${
                    errors.password?.type === REACT_HOOK_FORM_VALIDATIONS.REQUIRED ? 'form__input-error' : ''
                }`}
                autoFocus
                type='email'
                name='userName'
                data-aid='username'
                aria-invalid={errors.username ? 'true' : 'false'}
                placeholder={t('FORMS.LOGIN_FORM.EMAIL_PLACEHOLDER')}
                value={username}
                onChange={(ev) => {
                    dispatch(setUsername(ev.target.value));
                }}
            />
            {errors.username?.type === REACT_HOOK_FORM_VALIDATIONS.VALIDATE && (
                <div className='form__input-text-error'>{t('FORMS.LOGIN_FORM.INVALID_EMAIL')}</div>
            )}
            <input
                autoComplete='on'
                className={`form__input  ${
                    errors.password && errors.password.type === REACT_HOOK_FORM_VALIDATIONS.REQUIRED
                        ? 'form__input-error'
                        : ''
                }`}
                type='password'
                name='password'
                data-aid='password'
                aria-invalid={errors.password ? 'true' : 'false'}
                placeholder={t('FORMS.LOGIN_FORM.PASSWORD_PLACEHOLDER')}
                value={password}
                onChange={(ev) => {
                    dispatch(setPassword(ev.target.value));
                }}
            />
            <div className='margin-bottom-1-rem'>
                <CpComboBox placeholder={t('FORMS.LOGIN_FORM.SELECT_DATA_CENTER')}>
                    <CpMenu>
                        {DATA_CENTERS.map((dataCenter) => (
                            <CpMenuItem
                                key={dataCenter.id}
                                isSelected={dataCenter.urls.includes(window.location.hostname)}
                                onClick={() => {
                                    window.location.href = `https://${dataCenter.urls[0]}`;
                                }}>
                                {dataCenter.label}
                            </CpMenuItem>
                        ))}
                    </CpMenu>
                </CpComboBox>
            </div>
            <a className='margin-bottom-1-rem' href={registrationUrl}>
                {t('FORMS.LOGIN_FORM.CREATE_ACCOUNT')}
            </a>
            <div className='login-form__form--sign-in'>
                <Link to={`/${Pages.ForgotPassword}`}
                    data-aid={'forgot-password'}>{t('FORMS.LOGIN_FORM.FORGOT_PASSWORD')}</Link>
                <CpCommonButton data-aid='submit-login-button' onClick={submitForm}>
                    {t('FORMS.LOGIN_FORM.SIGN_IN')}
                </CpCommonButton>
            </div>
            {loginFormHasError && (
                <div className='login-form__error-submit' data-aid='login-error'>
                    {t('FORMS.LOGIN_FORM.INVALID_SUBMIT')}{' '}
                    <i
                        className='login-form__error-submit--x-button'
                        onClick={() => dispatch(setHasError({ hasError: false }))}>
                        x
                    </i>
                </div>
            )}
        </form>
    );
};

export default EmailAndPassword;
