import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 210px;
  min-width: 240px;
  max-width: 240px;
  border-radius: 8px;
  border: 1px solid var(--borders-strength-border-regular, #D2D8E3);
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 16px;
  color: #15294B;
  font-weight: 500;
  line-height: 32px;
  font-size: 12px;
`;


const ContentDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const BySeverityStyled = {
    TopDiv,
    HeaderDiv,
    ContentDiv,
};