import { Icon } from '@dome9/berries/react-components';
import React from 'react';

export interface IMiniMenuItem {
    title?: string;
    onClick?: Function;
    divider?: boolean;
    icon?: string;
    disabled?: boolean;
    dataAid?: string;
}

interface IMiniMenuProps {
  menuItems: IMiniMenuItem[]
}


const MiniMenu: React.FC<IMiniMenuProps> = ({ menuItems }) => {
    return (
        <div>
            {menuItems?.map((item: IMiniMenuItem, index)=>
                item.divider ?
                    <div key={index} className='h-1 w-full bg-aside'></div>
                    :
                    <div key={item.title}
                        onClick={()=> {(!item.disabled && item.onClick) && item.onClick();}}
                        className={`px-7 py-6 w-96 flex items-center ${item.disabled ? 'opacity-30': 'hover:cursor-pointer hover:bg-info-lightest'}`}
                    >{item.icon && <Icon className='mr-6' size={12} name={item.icon}></Icon>}
                        {item.title}
                    </div>
            )}
        </div>
    );
};


export default MiniMenu;
