import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { getRegionDisplayName } from 'common/utils/vendorUtils';

const RegionCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const { value } = params;
    const { platform } = params.data;
    const displayName = getRegionDisplayName(value, platform);

    CellToClipboardHandler(params, String(displayName));
    return <div>{displayName}</div>;
};

export default RegionCellRenderer;