import { RegisterOptions } from 'react-hook-form';

export interface FormValues {
    apikey: string;
    arn: string;
    secret: string;
}

export const inputValidations: { [input: string]: RegisterOptions } = {
    apikey: {
        required: true,
    },
    arn: {
        required: true,
    },
    secret: {
        required: true,
    },
};