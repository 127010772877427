import React, { useEffect, useState } from 'react';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { Datasource, ICustomAggregation } from 'common/components/ProtectedAssets/datasource';
import { getCustomizationService } from 'common/interface/services';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import {
    getProtectedAssetsService,
    IFiltersInitialData,
    IProtectedAssetFilter,
} from 'common/module_interface/assets/ProtectedAssets';
import { DashboardFilterPanelDiv } from './DashboardFilterPanel.styled';
import { createErmDataSourceConfig } from '../../ProtectedAsset/ErmDataUtils';
import { FULL_ERM_AGGREGATION } from '../../../consts';
import { DASHBOARD_CACHE_CONFIG } from '../../../RiskManagement.utils';
import { DashboardEmptyFilter } from './DashboardEmptyFilter';
import {
    RECENTLY_USED_FILTER_ID,
    SAVED_FILTERS_FILTER_ID
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';

export const DashboardFilterPanel: React.FC<{
    filterId: string,
    getAggregations: (filterValues: IFiltersValues) => Promise<Aggregations>,
    setIsFilterReady?: Function,
    filterTypes: IProtectedAssetFilter[],
    initialAggregationConfig?: ICustomAggregation,
    initialFilter?: IFiltersValues,
}> = ({ filterId, getAggregations, setIsFilterReady, filterTypes, initialAggregationConfig, initialFilter }) => {
    const [filterElement, setFilterElement] = useState(<DashboardEmptyFilter />);
    const getFiltersInitialData = async () => {
        const recentlyUsedComponentName: string = filterTypes.find(filterType => (filterType.id === RECENTLY_USED_FILTER_ID))?.filterProps?.componentName ?? 'erm-dashboard-generic-recent';
        const recentlyUsedPromise = getCustomizationService().getCustomization(recentlyUsedComponentName);
        const savedFiltersComponentName: string = filterTypes.find(filterType => (filterType.id === SAVED_FILTERS_FILTER_ID))?.filterProps?.savedFiltersComponentName ?? 'erm-dashboard-generic-favorites';
        const savedFiltersPromise = getCustomizationService().getCustomization(savedFiltersComponentName);


        const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
        return Promise.all([recentlyUsedPromise, savedFiltersPromise, organizationalUnitsPromise]).then(
            ([recentlyUsed, savedFilters, organizationalUnits]) => {
                return {
                    savedFilters,
                    recentlyUsed,
                    organizationalUnits,
                };
            },
        );
    };

    useEffect(() => {
        async function renderFilterPanel(aggregations: Aggregations) {
            const filtersInitialData: IFiltersInitialData = await getFiltersInitialData();
            const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            const filterPanelElementsList = getProtectedAssetsService().getFilterDefs(filterTypes, {
                aggregations,
                filtersInitialData,
                allCloudAccounts,
            });
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations,
                shouldBuildObjectForAPI: true,
                filterId,
            });
            setFilterElement(filterPanel);
            setIsFilterReady && setIsFilterReady(true);
        }

        const datasource = new Datasource(createErmDataSourceConfig({}, 10, undefined, DASHBOARD_CACHE_CONFIG));
        datasource.getAdHokDataFromServer(initialAggregationConfig || FULL_ERM_AGGREGATION, 0, initialFilter).then((results) => {
            void renderFilterPanel(results?.aggregations || {});
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <DashboardFilterPanelDiv>{filterElement}</DashboardFilterPanelDiv>;
};