import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FindingsFilterTypes as FilterTypes } from '../FindingsTable/Types/FindingsFilterTypes';
import { CDR_EVENTS_TABLE } from './CdrEventsPage.const';
import { createFavoritesFilter, createRecentlyUsedFilter } from 'common/utils/filterUtils';

export const getCdrEventsTableFilters = (): IProtectedAssetFilter[] => [
    FilterTypes.ADD_FILTER,
    FilterTypes.DATE_PICKER,
    FilterTypes.FREE_TEXT,
    createRecentlyUsedFilter(CDR_EVENTS_TABLE),
    createFavoritesFilter(CDR_EVENTS_TABLE),
    FilterTypes.CLEAR_ALL,
    FilterTypes.PLATFORM,
    FilterTypes.ORGANIZATIONAL_UNITS,
    FilterTypes.ENVIRONMENT,
    FilterTypes.RULE_NAME,
    FilterTypes.SEVERITY,
    FilterTypes.ENTITY_TYPE,
    FilterTypes.RULESET_NAME,
    FilterTypes.REGION,
    FilterTypes.ACTION,
    FilterTypes.ASSIGNEE,
    FilterTypes.STATUS,
    FilterTypes.SHOW_ACKNOWLEDGED,
    FilterTypes.SHOW_EXCLUDED,
    FilterTypes.MITRE_TACTIC,
    FilterTypes.MITRE_TECHNIQUE,
];
