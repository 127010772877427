import { getHttpService } from 'common/interface/services';
import { NetworkTrafficConfiguration } from '../../PerimeterExposure/Models/NetworkTrafficConfiguration';
import {
    DisplayPrompt,
    Prompt,
    PromptRequestModel,
    PromptType,
    ResponseElement,
    SuggestionFormat,
    SuggestionRequestModel
} from '../Suggestion.interface';

export async function search(securityGroupConfiguration: NetworkTrafficConfiguration): Promise<DisplayPrompt[]> {
    const requestModel: PromptRequestModel = {
        promptType: PromptType.NetworkTraffic,
        model: securityGroupConfiguration
    };

    const path = 'content/ai/prompt/search';
    const requestObject = { data: requestModel };
    const serviceConfig = { cachingConfig: { useCache: true } };

    const prompts = await getHttpService().post<Prompt[]>(path, requestObject, serviceConfig);

    return prompts.reduce<(DisplayPrompt)[]>((executablePrompts, prompt) => {
        executablePrompts.push({
            ...prompt,
            suggest: async () => await run(prompt.promptId, securityGroupConfiguration)
        });

        return executablePrompts;
    }, []);
}

async function run(promptId: string, securityGroupConfiguration: NetworkTrafficConfiguration): Promise<ResponseElement[]> {
    const request: SuggestionRequestModel = {
        promptId: promptId,
        suggestionFormat: SuggestionFormat.Json,
        model: securityGroupConfiguration
    };

    const path = 'content/ai/prompt/run';
    const requestObject = { data: request };
    const serviceConfig = { cachingConfig: { useCache: true } };

    const response = await getHttpService().post<string>(path, requestObject, serviceConfig);
    return JSON.parse(response) as ResponseElement[];
}