import React from 'react';
import SignupForm from './SignupForm';
import { createUseStyles } from 'react-jss';
import { LegalNotesFooter } from '../../../common/components/Forms/LegalNotesFooter';
import CheckpointLogoHeader from 'common/components/Forms/CheckpointLogoHeader';

export const Signup: React.FC<{}> = () => {
    const useStyles = createUseStyles({
        signupPage: {
            overflow: 'auto',
            height: '100%',
            'max-width': '700px',
            width: '60%',
            margin: 'auto',
            '& .mainContent': {
                border: '1px solid #e0e4e8',
                display: 'flex',
                alignItems: 'stretch',
                background: '#fff',
                color: '#777',
            },
        },
        '@media screen and (max-width: 1024px)': {
            signupPage: {
                '& .mainContent': {
                    width: 'auto',
                    flexDirection: 'column',
                },
            },
        },
    });

    const classes = useStyles();
    return (
        <div className={classes.signupPage}>
            <CheckpointLogoHeader />
            <div className='mainContent'>
                <SignupForm />
            </div>

            <LegalNotesFooter />
        </div>
    );
};
