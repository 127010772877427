import { getHttpService } from 'common/interface/services';
import { ProtectedAssetsResponse } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { SUPPORTED } from '../../SupportedAssets';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';

const BATCH_SIZE = 1000;

export async function getAssets(environmentId: string): Promise<{ srl?: string, name: string, type: string }[]> {
    const ec2s: { srl?: string, name: string, type: string }[] = [];
    let response: ProtectedAssetsResponse;
    let searchAfter: string[] | null | undefined = undefined;

    const supportedTypeFields = SUPPORTED.ASSET_TYPES.map(supportedType => {
        return { name: 'typeByPlatform', value: supportedType };
    });
    const supportedPlatformFields = SUPPORTED.VENDORS.map(supportedVendor => {
        return { name: 'platform', value: supportedVendor };
    });

    do {
        response = await getHttpService().post<ProtectedAssetsResponse>(
            ASSETS_SEARCH_URL, {
                data: {
                    pageSize: BATCH_SIZE,
                    filter: {
                        fields: [
                            ...supportedTypeFields,
                            ...supportedPlatformFields,
                            { name: 'cloudAccountId', value: environmentId },
                        ],
                        includedEntityTypes: SUPPORTED.ASSET_TYPES,
                    },
                    searchAfter: searchAfter,
                },
            },
        );

        ec2s.push(...response.assets.filter(e => e));

        searchAfter = response.searchAfter;
    } while (searchAfter?.length && ec2s.length < response.totalCount);

    return ec2s;
}