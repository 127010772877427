import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';

const getTotalAssetsNumberOfCloudAccount: (cloudAccountId: string) => Promise<number> = async (cloudAccountId) => {
    const reqObj = {
        'pageSize': 0,
        filter: {
            fields: [
                {
                    name: 'cloudAccountId',
                    value: cloudAccountId
                }
            ]
        }
    };

    const response = await getHttpService().request<AxiosResponse<any>>(
        'protected-asset/search',
        { method: 'POST', data: reqObj },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
    return response.data.totalCount;
};


const EnvironmentsService = {
    getTotalAssetsNumberOfCloudAccount,
};

export default EnvironmentsService;