import globalAddinContainer from 'common/extensibility/AddinContainer';
import { IIntelligenceService, INTELLIGENCE_SERVICE_ID } from 'common/module_interface/intelligence/intelligence';
import { getHttpService, getStoreService, IHttpServiceConfig } from 'common/interface/services';
import { IIntelligenceCloudAccount } from 'common/interface/data_services';
import { IFRAME_TAGS } from 'common/interface/IFrame.message.model';
import {
    IAcknowledgeRequest, IArchiveRequest, IAssignUserRequest, IChangeSeverityRequest, ICloseRequest,
    IColumnMapper, ICommentRequest,
    IGslDataItem,
    IGslRunFacetResponse,
    IGslRunRequest,
    IGslRunResponse,
    IIntelligencePolicy, IntelligenceRule, IntelligenceRuleModel,
    IntelligenceRuleset, IntelligenceRulesetDetails,
    IPolicyDto,
    IUpdatePolicyDto,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';
import { isEmpty } from 'lodash';
import { IEvent } from 'common/module_interface/events/Events';
import { IFindingModel } from 'common/components/Findings/Findings.interface';
import { clearCacheDataByTag, getCacheTag } from 'common/utils/apiCaching';
import {
    IServerValueToUiValueConvertors,
    mapGslResponseToType,
} from 'common/module_interface/intelligence/Intelligence.utils';
import { convertStringToNumber, formatComments } from '../Findings/Findings.utils';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';
import { isBoolean } from 'common/erm-components/utils/types';

const POLICY_SERVICE_NAME = 'POLICY';
const EVENTS_SERVICE_NAME = 'EVENTS';
const DEFAULT_CACHE_TIMEOUT = 300;
const DEFAULT_SERVICE_CONFIG: IHttpServiceConfig = {
    cachingConfig: {
        useCache: true,
        dataAgeLimit: DEFAULT_CACHE_TIMEOUT,
        tags: [getCacheTag(EVENTS_SERVICE_NAME)],
    },
};
const cachingConfig = (useCache: boolean) => ({ useCache, tags: [IFRAME_TAGS.CLEAR_CACHE.ENVIRONMENTS] });

export class IntelligenceService implements IIntelligenceService {

    async getGSLRunData<T = IGslDataItem>(data: IGslRunRequest): Promise<IGslRunResponse<T>> {
        return await getHttpService().post<IGslRunResponse<T>>({
            ...DEFAULT_SERVICE_CONFIG,
            path: 'gslws/gsl-run?action=fetch',
            requestObject: {
                data: data,
            },
        });
    }

    async getFindings(data: IGslRunRequest): Promise<IFinding[]> {
        const response: IGslRunResponse = await this.getGSLRunData(data);
        const valueConvertors: IServerValueToUiValueConvertors = {
            accountId: convertStringToNumber,
            assessmentCloudAccountType: convertStringToNumber,
            originId: convertStringToNumber,
            rulesetId: convertStringToNumber,
            severityLevel: convertStringToNumber,
            statusId: convertStringToNumber,
            isAcknowledged: isBoolean,
            isExcluded: isBoolean,
            comments: formatComments,
        };
        return mapGslResponseToType<IFinding>(FindingFields, response, valueConvertors);
    }

    async getGSLRunDataCount(data: IGslRunRequest): Promise<number> {
        const response: IGslRunResponse = await this.getGSLRunData(data);
        const columnMapper: IColumnMapper = response.cols;
        const dataItem: IGslDataItem = response.data[columnMapper['cnt']];
        return !isEmpty(dataItem) ? dataItem[0] as number : 0;
    }

    async getIntelligenceCloudAccounts(useCache = true): Promise<IIntelligenceCloudAccount[]> {
        const state = getStoreService().state;
        const magellanUrl = state.app.magellanUrl;
        return getHttpService().get<IIntelligenceCloudAccount[]>({
            path: `${magellanUrl}/cloudAccounts`,
            cachingConfig: cachingConfig(useCache),
        });
    }

    async getIntelligenceFindingGraph(findingKey: string): Promise<any> {
        const encodedFindingKey = encodeURIComponent(findingKey);
        const response = await getHttpService().get<any>({
            ...DEFAULT_SERVICE_CONFIG,
            path: `cdrws/findings/graph?finding_key=${encodedFindingKey}`,

        });
        return response.res;
    }

    async getIntelligenceFinding(findingKey: string): Promise<IEvent | undefined> {
        const encodedFindingKey = encodeURIComponent(findingKey);
        const response = await getHttpService().get<any>({
            ...DEFAULT_SERVICE_CONFIG,
            path: `cdrws/findings/search?finding_key=${encodedFindingKey}`,
        });
        return response.res;
    }

    async getIntelligenceFindingQuery(data: IGslRunRequest): Promise<IFindingModel[]> {
        const response = await getHttpService().post<any>({
            ...DEFAULT_SERVICE_CONFIG,
            path: 'cdrws/findings/query',
            requestObject: {
                data: data,
            },

        });
        return response.res;
    }

    async getIntelligenceFacets(data: IGslRunRequest): Promise<IGslRunFacetResponse> {
        return await this.getGSLRunData<IGslRunResponse>(data);
    }

    async getIntelligenceRulesets(): Promise<IntelligenceRuleset[]> {
        const state = getStoreService().state;
        const magellanBaseUrl = state.app.magellanUrl;
        return await getHttpService().get(`${magellanBaseUrl}/rulesets/all-metadata`, {}, { cachingConfig: { useCache: true } });
    }

    async getIntelligenceRules(rulesetId: number): Promise<IntelligenceRule[]> {
        const state = getStoreService().state;
        const magellanBaseUrl = state.app.magellanUrl;
        const rulesetDetails: IntelligenceRulesetDetails = await getHttpService().get(`${magellanBaseUrl}/rulesets/specific?bundleId=${rulesetId}`, {}, { cachingConfig: { useCache: true } });
        const ruleModels: IntelligenceRuleModel[] = rulesetDetails?.alerts ? rulesetDetails.alerts : [];
        return ruleModels.map(model => ({
            ...model,
            description: model.alert_explanation,
            cloudAccountId: model.cloud_account_id,
        }));
    }

    private clearMultiPoliciesCache() {
        clearCacheDataByTag(POLICY_SERVICE_NAME);
    }

    async savePolicies(payload: IPolicyDto): Promise<void> {
        this.clearMultiPoliciesCache();
        const state = getStoreService().state;
        const magellanUrl = state.app.magellanUrl;
        return getHttpService().post<any>({
            path: magellanUrl + '/policies',
            requestObject: {
                data: payload,
            },
        });
    }

    async updatePolicy(payload: IUpdatePolicyDto): Promise<void> {
        this.clearMultiPoliciesCache();
        const state = getStoreService().state;
        const magellanUrl = state.app.magellanUrl;
        return getHttpService().put<any>({
            path: magellanUrl + '/policies',
            requestObject: {
                data: payload,
            },
        });
    }

    async getPolicies(useCache = true): Promise<IIntelligencePolicy[]> {
        const state = getStoreService().state;
        const magellanUrl = state.app.magellanUrl;
        return getHttpService().get<IIntelligencePolicy[]>({
            path: `${magellanUrl}/policies/all`,
            cachingConfig: { useCache: useCache, tags: [getCacheTag(POLICY_SERVICE_NAME)] },
        });
    }

    private clearMultiEventsCache() {
        clearCacheDataByTag(EVENTS_SERVICE_NAME);
    }

    async acknowledgeFindings(request: IAcknowledgeRequest): Promise<void> {
        this.clearMultiEventsCache();
        return await sendHttpRequest<void>('findings-update/acknowledge', {
            method: 'POST',
            data: request,
        });
    }

    async addComment(request: ICommentRequest): Promise<void> {
        this.clearMultiEventsCache();
        return await sendHttpRequest<void>('findings-update/comment', {
            method: 'POST',
            data: request,
        });
    }

    async closeFindings(request: ICloseRequest): Promise<void> {
        this.clearMultiEventsCache();
        return await sendHttpRequest<void>('findings-update/close', {
            method: 'POST',
            data: request,
        });
    }

    async changeSeverity(request: IChangeSeverityRequest): Promise<void> {
        this.clearMultiEventsCache();
        return await sendHttpRequest<void>('findings-update/severity', {
            method: 'POST',
            data: request,
        });
    }

    async assignUser(request: IAssignUserRequest): Promise<void> {
        this.clearMultiEventsCache();
        return await sendHttpRequest<void>('findings-update/assign', {
            method: 'POST',
            data: request,
        });
    }

    async archiveFindings(request: IArchiveRequest): Promise<void> {
        this.clearMultiEventsCache();
        return await sendHttpRequest<void>('findings-update/archive', {
            method: 'POST',
            data: request,
        });
    }
}

export default function initializeIntelligenceService() {
    globalAddinContainer.addService(INTELLIGENCE_SERVICE_ID, new IntelligenceService());
}
