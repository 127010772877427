import {
    checkIpCidr,
    checkPortRange,
} from './CdrExclusion.validations';
import {
    CDR_EXCLUSIONS_PAGE_TABLE_ID,
    CdrExclusionItemNames,
} from './CdrExclusion.consts';
import {
    getCdrExclusionSubmitFailedNotification,
    getCdrExclusionSubmitSuccessNotification,
    saveCdrExclusion,
} from './CdrExclusion.datasource';
import {
    commonInputItemProps,
    commonLazySelectFieldItemProps,
    commonMultiSelectFieldItemProps,
    commonOrgUnitsFieldItemProps, commonRadioItemProps, commonSingleSelectFieldItemProps, commonTextAreaItemProps,
    commonDateRangeFieldItemProps, FormItemType,
} from 'common/erm-components/custom/CustomForm/CustomForm.consts';
import { getCdrRulesAfterChange } from './CdrExclusion.values';
import { cdrTrans } from 'modules/Intelligence/Intelligence.utils';
import { ICdrExclusionProps } from './CdrExclusion.interface';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import {
    getCdrEntityNamesOptions,
    getCdrEnvironmentSelectOptions, getCdrIpListOptions, getCdrOrgUnitsRoot, getCdrRulesetsSelectOptions,
    getCdrSeveritiesSelectOptions, getRulesOptionsByRuleset,
} from './CdrExclusion.options';
import { ICustomFormProps, IItem, IRadioItem } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { convertDatesToValues } from 'common/erm-components/custom/CustomForm/CustomForm.values';

const getOrgEnvRadio = async (cdrExclusion?: ICdrExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: CdrExclusionItemNames.orgEnvRadio,
    state: {},
    options: [
        {
            name: CdrExclusionItemNames.orgUnitsRadioOption,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.ORGANIZATIONAL_UNIT'),
            subItems: [{
                ...commonOrgUnitsFieldItemProps,
                name: CdrExclusionItemNames.orgUnits,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.ORGANIZATIONAL_UNIT'),
                    orgUnitsRoot: await getCdrOrgUnitsRoot(),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.ORG_UNITS'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.ORG_UNITS'),
                    value: cdrExclusion?.orgUnitIds,
                },
            }],
        },
        {
            name: CdrExclusionItemNames.environmentsRadioOption,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.ENVIRONMENT'),
            subItems: [{
                ...commonMultiSelectFieldItemProps,
                name: CdrExclusionItemNames.environments,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.ENVIRONMENT'),
                    options: await getCdrEnvironmentSelectOptions(),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.ENVIRONMENTS'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.ENVIRONMENTS'),
                    value: cdrExclusion?.envIds,
                },
            }],
        },
    ],
});

const getRuleRadio = async (cdrExclusion?: ICdrExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: CdrExclusionItemNames.ruleRadio,
    state: {},
    options: [
        {
            name: CdrExclusionItemNames.rulesRadioOption,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.RULE'),
            subItems: [{
                ...commonMultiSelectFieldItemProps,
                name: CdrExclusionItemNames.rules,
                getNewItemState: getCdrRulesAfterChange,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.RULE'),
                    options: await getRulesOptionsByRuleset(cdrExclusion?.rulesetId),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.RULES'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.RULES'),
                    value: cdrExclusion?.ruleIds,
                },
            }],
        },
        {
            name: CdrExclusionItemNames.severitiesRadioOption,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.SEVERITY'),
            subItems: [{
                ...commonMultiSelectFieldItemProps,
                name: CdrExclusionItemNames.severities,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.SEVERITY'),
                    options: await getCdrSeveritiesSelectOptions(),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.SEVERITIES'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.SEVERITIES'),
                    value: cdrExclusion?.severities,
                },
            }],
        },
    ],
});

const getEntityRadio = async (cdrExclusion?: ICdrExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: CdrExclusionItemNames.entityRadio,
    state: {},
    options: [
        {
            name: CdrExclusionItemNames.entityNamesRadioOption,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.ENTITY_NAME'),
            subItems: [{
                ...commonLazySelectFieldItemProps,
                name: CdrExclusionItemNames.entityNames,
                getLazyOptions: getCdrEntityNamesOptions,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.ENTITY_NAME'),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.ENTITY_NAMES'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.ENTITY_NAMES'),
                    value: cdrExclusion?.entityNames,
                },
            }],
        },
        {
            name: CdrExclusionItemNames.entityIdRadioOption,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.ENTITY_ID'),
            subItems: [{
                ...commonInputItemProps,
                name: CdrExclusionItemNames.entityId,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.ENTITY_ID'),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.ENTITY_ID'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.ENTITY_ID'),
                    value: cdrExclusion?.entityId,
                },
            }],
        },
    ],
});

const getSrcIpRadio = async (cdrExclusion?: ICdrExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: CdrExclusionItemNames.srcIpRadio,
    state: {},
    options: [
        {
            name: CdrExclusionItemNames.srcIpCidrRadioOption,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.IP_CIDR'),
            subItems: [{
                ...commonInputItemProps,
                name: CdrExclusionItemNames.srcIpCidr,
                checkValidation: checkIpCidr,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.IP_CIDR'),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.IP_CIDR'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.IP_CIDR'),
                    value: cdrExclusion?.srcIpCidr,
                },
            }],
        },
        {
            name: CdrExclusionItemNames.srcSavedIpRadioOption,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.SAVED_IP'),
            subItems: [{
                ...commonSingleSelectFieldItemProps,
                name: CdrExclusionItemNames.srcSavedIp,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.SAVED_IP'),
                    options: await getCdrIpListOptions(),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.SAVED_IP'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.SAVED_IP'),
                    value: cdrExclusion?.srcSavedIp,
                },
            }],
        },
    ],
});

const getDestIpRadio = async (cdrExclusion?: ICdrExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: CdrExclusionItemNames.destIpRadio,
    state: {},
    options: [
        {
            name: CdrExclusionItemNames.destIpCidr,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.IP_CIDR'),
            subItems: [{
                ...commonInputItemProps,
                name: CdrExclusionItemNames.destIpCidr,
                checkValidation: checkIpCidr,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.IP_CIDR'),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.IP_CIDR'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.IP_CIDR'),
                    value: cdrExclusion?.destIpCidr,
                },
            }],
        },
        {
            name: CdrExclusionItemNames.destSavedIpRadioOption,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.SAVED_IP'),
            subItems: [{
                ...commonSingleSelectFieldItemProps,
                name: CdrExclusionItemNames.destSavedIp,
                state: {
                    label: cdrTrans('CDR_EXCLUSIONS.EDITOR.SAVED_IP'),
                    options: await getCdrIpListOptions(),
                    tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.SAVED_IP'),
                    placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.SAVED_IP'),
                    value: cdrExclusion?.destSavedIp,
                },
            }],
        },
    ],
});

const getItems = async (cdrExclusion?: ICdrExclusionProps): Promise<IItem[]> => [
    {
        ...commonSingleSelectFieldItemProps,
        name: CdrExclusionItemNames.rulesetId,
        state: {
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.RULESET'),
            options: await getCdrRulesetsSelectOptions(),
            isRequired: true,
            tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.RULESET'),
            placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.RULESET'),
            value: cdrExclusion?.rulesetId,
            readonly: !!(cdrExclusion?.rulesetId && !cdrExclusion.id),
        },
    },
    {
        ...commonTextAreaItemProps,
        name: CdrExclusionItemNames.comment,
        state: {
            isRequired: true,
            charsLimit: 200,
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.COMMENT'),
            placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.COMMENT'),
            value: cdrExclusion?.comment,
        },
    },
    await getOrgEnvRadio(cdrExclusion),
    {
        ...commonDateRangeFieldItemProps,
        name: CdrExclusionItemNames.dateRange,
        state: {
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.DATE_RANGE'),
            tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.DATE_RANGE'),
            placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.DATE_RANGE'),
            value: cdrExclusion?.dateRange ? convertDatesToValues(cdrExclusion.dateRange) : [],
        },
    },
    await getRuleRadio(cdrExclusion),
    await getEntityRadio(cdrExclusion),
    await getSrcIpRadio(cdrExclusion),
    {
        ...commonInputItemProps,
        name: CdrExclusionItemNames.srcPort,
        checkValidation: checkPortRange,
        state: {
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.SOURCE_PORT_RANGE'),
            tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.PORT_RANGE'),
            placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.PORT_RANGE'),
            value: cdrExclusion?.srcPort,
        },
    },
    await getDestIpRadio(cdrExclusion),
    {
        ...commonInputItemProps,
        name: CdrExclusionItemNames.destPort,
        checkValidation: checkPortRange,
        state: {
            label: cdrTrans('CDR_EXCLUSIONS.EDITOR.DESTINATION_PORT_RANGE'),
            tooltip: cdrTrans('CDR_EXCLUSIONS.TOOLTIP.PORT_RANGE'),
            placeholder: cdrTrans('CDR_EXCLUSIONS.PLACEHOLDER.PORT_RANGE'),
            value: cdrExclusion?.destPort,
        },
    },
];

export const getCdrExclusionFormProps = (cdrExclusion?: ICdrExclusionProps): ICustomFormProps => {
    return {
        getItems,
        submitData: saveCdrExclusion,
        getSubmitSuccessNotification: getCdrExclusionSubmitSuccessNotification,
        getSubmitFailedNotification: getCdrExclusionSubmitFailedNotification,
        title: cdrExclusion?.id ? `${cdrExclusion.rulesetName} (${cdrExclusion.id})` || '' : cdrTrans('CDR_EXCLUSIONS.EDITOR.ADD_CDR_EXCLUSION'),
        minDialogHeight: 300,
        eventHandlerId: CDR_EXCLUSIONS_PAGE_TABLE_ID,
    };
};

export const showCdrExclusionForm = (cdrExclusion?: ICdrExclusionProps) => {
    globalModelUtils.showCustomFormModal({
        formProps: getCdrExclusionFormProps(cdrExclusion),
        data: cdrExclusion,
    });
};
