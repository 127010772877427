import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { BusinessPriorityService, IBusinessPriorityService } from './services/BusinessPriorityService';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { Addin } from 'common/extensibility/AddinRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';

const BUSINESS_PRIORITY_SERVICE = 'BusinessPriorityService';
const PATH_ERM_RULESETS_PREFIX = buildPath('risk management', 'ruleset');
const PATH_ERM_RULESETS_TABLE_COLUMNS = buildPath(PATH_ERM_RULESETS_PREFIX, 'columns');
const PATH_ERM_RULESETS_TABLE_FILTERS = buildPath(PATH_ERM_RULESETS_PREFIX, 'filters');
const PATH_ASSET_TYPES_TABLE_COLUMNS = buildPath(PATH_ERM_RULESETS_PREFIX, 'asset types');

export const RiskManagementRegistry = {
    getBusinessPriorityService: (): IBusinessPriorityService => {
        return globalAddinContainer.getService<IBusinessPriorityService>(BUSINESS_PRIORITY_SERVICE);
    },

    addNewBusinessPriorityService: () => {
        globalAddinContainer.addService(BUSINESS_PRIORITY_SERVICE, new BusinessPriorityService());
    },

    addRulesetsTableColumnDefs: (columnDefAddins: Addin<CGColDef>[]) => {
        globalAddinContainer.add<CGColDef>(PATH_ERM_RULESETS_TABLE_COLUMNS, columnDefAddins);
    },

    getRulesetsTableColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(PATH_ERM_RULESETS_TABLE_COLUMNS);
    },

    addRulesetsTableFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_ERM_RULESETS_TABLE_FILTERS, filterAddins);
    },

    getRulesetsTableFilters: (aggregations: Aggregations): IFilterProps[] => {
        return globalAddinContainer.get<IFilterProps>(PATH_ERM_RULESETS_TABLE_FILTERS, [{ aggregations }]);
    },

    addAssetTypesTableColumnDefAddins: (columnDefAddins: Addin<CGColDef>[]) => {
        globalAddinContainer.add<CGColDef>(PATH_ASSET_TYPES_TABLE_COLUMNS, columnDefAddins);
    },

    getAssetTypesTableColumnDefs: (): CGColDef[] => {
        return globalAddinContainer.get<CGColDef>(PATH_ASSET_TYPES_TABLE_COLUMNS);
    },
};
