import { GroupType } from './GroupType';
import { RiskData } from './RiskData';
import { IamImpactData } from './IamImpactData';

export const defaultEntityClassification = 'Other';

export interface Entity {
    graphId: string;
    srl: string;
    gotoSrl?: string;
    externalId: string;
    name: string;
    type: string;
    typeByPlatform?: string,
    additionalData?: {
        riskData?: RiskData,
        iamImpactData?: IamImpactData,
        groups?: Record<GroupType, Record<string, string>>,
        classifications?: Record<string, string[]>
    };

    gotoUrl?: string;
    isInContext?: boolean;
}