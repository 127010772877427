import React, { useEffect, useState } from 'react';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { NotificationsTableRegistry } from '../NotificationsTableRegestries';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    Aggregations,
    AggregationOption,
    INotificationFilterPanelProps, INotification
} from '../Interfaces/NotificationPageInterfaces';
import { FILTER_EVENTS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { ASSOCIATIONS, I18nNotifications, savedFiltersComponentName, TYPES } from '../NotificationsPage.consts';
import { getHttpService, ICustomzationResponse } from 'common/interface/services';
import { getNotificationTypes } from './utils';
import { useTranslation } from 'react-i18next';

interface IFilter{
    key: string,
    options: AggregationOption[],
    getAllOptionsFromNotification: (notification: INotification) => string[] | null | undefined;
}

const NotificationsFilterPanel: React.FC<INotificationFilterPanelProps> = ( { tableData, rawData, isLoading, onFilterChange }) => {
    const [filterElement, setFilterElement] = useState(<div></div>);
    const { t } = useTranslation(I18nNotifications);
    const onFilterEvent = (action: string, filtersValues: IFiltersValues) => {
        if( action === FILTER_EVENTS.FILTER_CHANGED) {
            onFilterChange(filtersValues);
        }
    };

    const getFiltersInitialData = async () => {
        const savedFilters = await getHttpService().request<ICustomzationResponse<any>>(`customization?component=${savedFiltersComponentName}`, {
            method: 'GET',
        });

        return { savedFilters };
    };

    useEffect(() => {

        async function getAggregations(filtersValues: IFiltersValues, data = tableData): Promise<Aggregations> {
            const associationFilter : IFilter = { key: ASSOCIATIONS, options: [] as AggregationOption[] , getAllOptionsFromNotification: (notification: INotification) => { return notification.associations; } };
            const typesFilter : IFilter = { key: TYPES, options: [] as AggregationOption[] , getAllOptionsFromNotification: (notification) => getNotificationTypes(notification,t) };

            const aggregations = [associationFilter,typesFilter];

            for (const notification of data) {
                for (const aggregation of aggregations) {
                    const optionsFromNotification = aggregation.getAllOptionsFromNotification(notification);
                    optionsFromNotification?.forEach(option => {
                        let isOptionExist = false;
                        aggregation.options.forEach(filterOption =>{
                            if (option === filterOption.value) {
                                filterOption.count++;
                                isOptionExist = true;
                            }
                        });
                        !isOptionExist && aggregation.options.push({ value: option, count: 1 });
                    });
                }
            }
            return { associations: associationFilter.options, types: typesFilter.options };
        }

        async function renderFilterPanel() {
            const filtersInitialData = await getFiltersInitialData();
            const aggregations = await getAggregations({}, rawData);
            const filterPanelElementsList = NotificationsTableRegistry.getFilterDefs([
                { id: FILTERS_KEYS.ADD_FILTER },
                { id: FILTERS_KEYS.FREE_TEXT },
                { id: ASSOCIATIONS },
                { id: TYPES },
                { id: FILTERS_KEYS.SAVED_FILTERS },
                { id: FILTERS_KEYS.CLEAR_BUTTON },
            ], {
                aggregations: aggregations || {},
                filtersInitialData: filtersInitialData || {},
                savedFiltersComponentName,
            });
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: false,
                filterId: 'NotificationFilter',
                onFilterChangeCallBack: onFilterEvent
            });
            setFilterElement(filterPanel);
        }

        if(!isLoading) {
            renderFilterPanel();
        }

    },[tableData, isLoading]);

    
    return (
        <>
            { filterElement }
        </>
    );
};

export default NotificationsFilterPanel;