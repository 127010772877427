import React from 'react';
import { Card, Media } from '@dome9/berries/react-components';
import { IEnvironmentsGetStarted } from 'common/module_interface/assets/GetStartedPage';
import { changeUrl } from 'common/utils/http';
import { getVendor } from 'common/consts/vendors';


const GetStartedCard = ({ vendor, linkTo, isReact, title, cardIndex }: IEnvironmentsGetStarted) => {
    const cardVendor = typeof (vendor) === 'string' ? getVendor(vendor as string)! : vendor;
    return (
        <div className='relative' key={`welcome-page-card-${cardIndex}-${title}`}>
            <Card
                status='primary'
                header={
                    <Media
                        title={title}
                        icon={cardVendor.icon}
                    ></Media>
                }
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    !isReact && changeUrl(linkTo);
                }}
                className='hover:cursor-pointer hover:border-info-strongest'
            >
            </Card>
        </div>
    );
};


export default GetStartedCard;
