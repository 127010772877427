import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modals from './components/Modals';
import { ModalType } from './AwsEnvironment.types';
import Toolbar from './components/Toolbar/Toolbar';
import { useTranslation } from 'react-i18next';
import EntityViewer from 'common/components/EntityViewer/EntityViewer';
import { EntityViewerProps, Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { LoaderWrapper } from './AwsEnvironment.styled';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { useGetAws } from './reducer/hooks/useGetAws';
import { useGetAwsActionsAndTabsData } from './reducer/hooks/useGetAwsActionsAndTabsData';
import EmptyState from 'common/components/EmptyState/EmptyState';
import { getAwsEnvNamespace } from './initialize.i18n';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { AwsEnvironmentAddinRegistry } from 'common/helpers/awsEnvironment';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';
import { EnvironmentChip } from 'common/module_interface/assets/Environments';
import { IWebappIframeServicePlacement } from 'common/interface/services';

const getAndSetDataFromAwsRegistryController = new AbortController();

const AwsEnvironment: React.FunctionComponent<{ customId?: string }> = ({ customId }) => {
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation(getAwsEnvNamespace('aws'));
    const { isLoading, data: awsData, isError, isDeleted } = useGetAws(customId || id);
    const { isLoading: isLoadingExtraData, data: extraData, reloadData } = useGetAwsActionsAndTabsData(customId || id);
    const [tabsWithData, setTabsWithData] = React.useState<{ [tabId: string]: Tab; }>({});
    const [detailsItems, setDetailsItems] = React.useState<{ [detailId: string]: BlockInfoProps; }>({});
    const [chips, setChips] = React.useState<{ [chipId: string]: IChipProps; }>({});
    const { allCloudAccounts } = useAllCloudAccounts();
    
    const { setBreadCrumbsLastChildren } = useBreadcrumbsLastChildren();
    React.useEffect(() => {
        if (awsData?.account.name) {
            setBreadCrumbsLastChildren([awsData?.account.name]);
        }
    }, [awsData, setBreadCrumbsLastChildren]);

    React.useEffect(() => {
        if (!awsData || !extraData) return;

        const getAndSetTabsWithData = () => {
            const tabs = AwsEnvironmentAddinRegistry.getTabs();
            const tabsFiltered = tabs.filter(tab => {
                return tab.isRelevant ? tab.isRelevant(awsData, extraData) : true;
            });
            tabsFiltered.forEach(tab => {
                new Promise<Tab>((resolve, reject) => {
                    getAndSetDataFromAwsRegistryController.signal.addEventListener('abort', () => reject());
                    Promise.resolve(tab.getValue(awsData, extraData)).then((tabResponse) => {
                        resolve(tabResponse);
                    });
                }).then((tabResponse) => {
                    setTabsWithData(prevState => ({ ...prevState, [tab.id]: tabResponse }));
                }).catch(() => { /* Aborted */ });
            });
        };

        const getAndSetDetails = () => {
            const detailsItems = AwsEnvironmentAddinRegistry.getDetailsPanelItems();
            const detailsFiltered = detailsItems.filter(detail => {
                return detail.isRelevant ? detail.isRelevant(awsData, extraData) : true;
            });
            detailsFiltered.forEach(detail => {
                new Promise<BlockInfoProps>((resolve, reject) => {
                    getAndSetDataFromAwsRegistryController.signal.addEventListener('abort', () => reject());
                    Promise.resolve(detail.getValue(awsData, extraData)).then((detailResponse) => {
                        resolve(detailResponse);
                    });
                }).then((detailResponse) => {
                    setDetailsItems(prevState => ({ ...prevState, [detail.id]: detailResponse }));
                }).catch(() => { /* Aborted */ });
            });
        };

        const getAndSetChips = () => {
            const chipsFromRegistry = AwsEnvironmentAddinRegistry.getChips();
            const chipsFiltered = chipsFromRegistry.filter(detail => {
                return detail.isRelevant ? detail.isRelevant(awsData, extraData) : true;
            });
            chipsFiltered.forEach(chip => {
                new Promise<EnvironmentChip>((resolve, reject) => {
                    getAndSetDataFromAwsRegistryController.signal.addEventListener('abort', () => reject());
                    setChips(prevState => ({ ...prevState, [chip.id]: { label: chip.customLoadingLabel || 'Loading', disabled: true, loading: true } }));
                    Promise.resolve(chip.getValue(awsData, extraData)).then((chipResponse) => {
                        resolve(chipResponse);
                    });
                }).then((chipResponse) => {
                    setChips(prevState => ({ ...prevState, [chip.id]: chipResponse }));
                }).catch(() => { /* Aborted */ });
            });
        };

        getAndSetTabsWithData();
        getAndSetDetails();
        getAndSetChips();

        return () => {
            getAndSetDataFromAwsRegistryController.abort();
            setTabsWithData({});
            setDetailsItems({});
            setChips({});
        };
    }, [customId, id, t, awsData, extraData, allCloudAccounts]); // dependenciy for allCloudAccounts added to allow the useEffect to run when the allCloudAccounts changes (status change)

    const awsInfoProps: EntityViewerProps | undefined = React.useMemo(() => {
        if (!awsData || !extraData) return undefined;
        return ({
            title: awsData.account.name,
            levelIcon: {
                iconProps: {
                    name: 'aws'
                },
            },
            titleRightElements:
            <Toolbar
                reloadData={reloadData}
                awsAccount={awsData}
                extraData={extraData}
                actionClick={(type) => setModalType(type as any)}
            />,
            details: Object.values(detailsItems),
            chips: Object.values(chips),
            tabs: Object.values(tabsWithData),
            isLoading: false,
            entityPlacement: IWebappIframeServicePlacement.DRAWER
        });}, [reloadData, awsData, extraData, detailsItems, tabsWithData, chips]);

    if (isLoading || isLoadingExtraData) {
        return (
            <LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </LoaderWrapper>
        );
    }

    if (isError || isDeleted || !awsData || !awsInfoProps) {
        return (
            <EmptyState
                label={t('awsPage.notFound.title')}
                description={t('awsPage.notFound.description')}
                links={
                    [{ label: t('awsPage.notFound.backLink'), url: '/cloud-account', icon: { name: 'arrowLeft' } }]
                }
            />
        );
    }

    return (
        <>
            <EntityViewer {...awsInfoProps} />
            <Modals
                modalType={modalType}
                closeModal={() => setModalType(null)}
                setModalType={setModalType}
                awsId={customId || id}
            /> 
        </>
    );
};

export default AwsEnvironment;
