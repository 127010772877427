import styled from 'styled-components';
import { Chip, Label, Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TopAreaDiv = styled(Stack)`
  padding-right: 10px;
`;

const PanelTopDiv = styled(Stack)`
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
`;

const PanelLeftDiv = styled(Stack)`
  flex-direction: column;
  min-width: 64px;
  max-width: 64px;
`;

const PanelRightDiv = styled(Stack)`
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacing(3)}${theme.units}`};
  width: 100%;
`;

const CategoryChipDiv = styled(Chip)`
`;

const AreaHeaderDiv = styled(Stack)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => `${theme.spacing(5)}${theme.units}`};
`;

const AreaDiv = styled(Stack)`
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacing(1)}${theme.units}`};
`;

const ConfigurationAreaDiv = styled(Stack)`
  flex-direction: column;
`;

const ConfigurationContentDiv = styled(Stack)`
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacing(5)}${theme.units}`};
`;


const LabelDiv = styled(Label)`
  display: flex;
  flex-direction: row;
`;

const CategoryHeaderDiv = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => `${theme.spacing(1)}${theme.units}`};
`;

const CategoryElementWrapper = styled(Stack)`
  align-items: center;
  flex-direction: row;
  height: 32px;
`;


const SelectWrapperDiv = styled(Stack)<{ width?: string }>`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: ${({ theme }) => `${theme.spacing(1)}${theme.units}`};
`;

const ActionsButtonsDiv = styled(Stack)`
  flex-direction: row;
  gap: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
  align-items: center;
`;

const ButtonsDiv = styled(Stack)`
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 70px;
  padding: 3px 8px;
  line-height: 20px;
  border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
  border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
`;

const CardIndentedContentDiv = styled(Stack)`
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacing(4)}${theme.units}`};
`;

const EditorCardDiv = styled(Stack)<{ isClickable?: boolean, background?: 'brandLight' | 'normal' }>`
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme, background }) => theme.palette.surface[background || 'normal']};
  border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
  border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
  padding: ${({ theme }) => `${theme.spacing(3)}${theme.units}`};
  gap: ${({ theme }) => `${theme.spacing(2)}${theme.units}`};
`;

const EditorCardContentDiv = styled(Stack)`
  flex-direction: column;
  padding-left: 20px;
  gap: ${({ theme }) => `${theme.spacing(3)}${theme.units}`};
`;

export const EditorStyled = {
    PanelTopDiv,
    PanelRightDiv,
    PanelLeftDiv,
    TopDiv,
    TopAreaDiv,
    AreaHeaderDiv,
    AreaDiv,
    CategoryChipDiv,
    CategoryElementWrapper,
    ConfigurationAreaDiv,
    ConfigurationContentDiv,
    CategoryHeaderDiv,
    SelectWrapperDiv,
    LabelDiv,
    ActionsButtonsDiv,
    ButtonsDiv,
    CardIndentedContentDiv,
    TextArea,
    EditorCardDiv,
    EditorCardContentDiv,
};