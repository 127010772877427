import React from 'react';
import { Addin } from 'common/extensibility/AddinRegistry';
import { EventOverviewTabProps, EventsDrawerAddingBaseValueComponent, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import EventsRuleCardComponent from '../Components/EventsRuleCardComponent';
import EventsComments from '../Components/EventsComments';

const tabsOverviewSections: () => Addin<EventsDrawerAddingBaseValueComponent<React.FC<EventOverviewTabProps>>>[] = () => {
    return [
        {
            id: 'commonRuleCard',
            position: 10,
            content: {
                id: 'commonRuleCard',
                getValue: () => EventsRuleCardComponent,
                isRelevant: (event) => {
                    const notRelevantOrigins: IFindingModelOriginString[] = [
                        IFindingModelOriginString.Serverless,
                        IFindingModelOriginString.ContainersRuntimeProtection,
                        IFindingModelOriginString.CIEM,
                        IFindingModelOriginString.ImageAssurance,
                    ];
                    return !notRelevantOrigins.includes(event.origin);
                }
            }
        },
        {
            id: 'comments',
            position: 20,
            content: {
                id: 'comments',
                getValue: () => EventsComments,
                isRelevant: (event) => event.comments.length > 0
            }
        }
    ];
};

export default function initializeOverviewSections() {
    EventsDrawerRegistry.addOverviewSections(tabsOverviewSections());
}