import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import {
    getCloudAccountsService,
    getOrganizationalUnitService, ICloudAccount,
    IOrganizationalUnit,
} from 'common/interface/data_services';
import {
    fetchAllRulesets,
    fetchRulesetRules,
    fetchNormalIpListNames, fetchMagicIpListNames, INormalIpListModel, IMagicIpListModel,
} from './CdrExclusion.datasource';
import { IntelligenceRule, IntelligenceRuleset } from 'common/module_interface/intelligence/Intelligence.interface';
import { FindingSeverityEnum, getFindingSeverities, IFindingSeverityInfo } from 'common/consts/FindingSeverity';
import { SortOrder } from 'common/interface/general';
import {
    getEnvironmentDisplayNameFromCloudAccount,
    getMatchingAssetsOptions,
} from 'common/components/ProtectedAssets/AssetUtils';
import { getVendor } from 'common/consts/vendors';
import { getErmSupportedAssets } from 'common/module_interface/RiskManagement/DataUtils';
import { IRunAllResultsMap, runAll } from 'common/utils/helpFunctions';

export const getCdrRulesetsSelectOptions = async (): Promise<SelectOption[]> => {
    const rulesets: IntelligenceRuleset[] = await fetchAllRulesets();
    return rulesets.map(ruleset => ({
        value: String(ruleset.id),
        label: ruleset.name,
        labelProps: ruleset.icon ? { leadingIconProps: { vendorNameOrPath: ruleset.icon } } : undefined,
    }));
};

export const getCdrOrgUnitsRoot = async (): Promise<IOrganizationalUnit> => {
    return getOrganizationalUnitService().getOrganizationalUnitsView();
};

export const getCdrEnvironmentSelectOptions = async (): Promise<SelectOption[]> => {
    const options: SelectOption[] = [];
    const environments: ICloudAccount[] = await getCloudAccountsService().getAllCloudAccounts();
    environments.forEach(env => {
        const envName: string = getEnvironmentDisplayNameFromCloudAccount(env, env.id);
        const platform = getVendor(env.platform);
        if (platform) {
            options.push({
                value: env.id,
                label: envName,
                labelProps: { leadingIconProps: { vendorNameOrPath: platform.icon } },
            });
        }
    });
    return options;
};

export const getCdrEntityNamesOptions = async (freeText?: string): Promise<SelectOption[]> => {
    const includedEntityTypes: string[] = (await getErmSupportedAssets()).allSupportedTypes;
    return getMatchingAssetsOptions(freeText, includedEntityTypes);
};

export const getRulesOptionsByRuleset = async (rulesetId?: string): Promise<SelectOption[]> => {
    if (!rulesetId) {
        return [];
    }

    const rules: IntelligenceRule[] = await fetchRulesetRules(Number(rulesetId));
    return rules.map(rule => ({
        value: rule.id,
        label: rule.name,
        labelProps: { leadingIconProps: { name: 'rule' } },
    }));
};

export const getCdrSeveritiesSelectOptions = (): Promise<SelectOption[]> => {
    const severitiesInfos: IFindingSeverityInfo[] = getFindingSeverities(SortOrder.descending, FindingSeverityEnum.informational);
    return Promise.resolve(severitiesInfos.map((severityInfo: IFindingSeverityInfo) => {
        return {
            value: severityInfo.key,
            label: severityInfo.displayText,
            labelProps: { leadingIconProps: severityInfo.iconProps },
        };
    }));
};

export const getCdrIpListOptions = async (): Promise<SelectOption[]> => {
    interface IPromisesMap {
        normalIpListNames: Promise<INormalIpListModel[]>;
        magicIpListNames: Promise<IMagicIpListModel[]>;
    }

    const promisesMap = {
        normalIpListNames: fetchNormalIpListNames(),
        magicIpListNames: fetchMagicIpListNames(),
    };
    const resultsMap: IRunAllResultsMap<IPromisesMap> = await runAll<IPromisesMap>(promisesMap, true);
    const normalOptions: SelectOption[] = resultsMap.normalIpListNames.map((ipInfo: INormalIpListModel) => ({
        value: ipInfo.name,
        label: ipInfo.name,
    }));

    const magicOptions: SelectOption[] = resultsMap.magicIpListNames.map((ipInfo: INormalIpListModel) => ({
        value: ipInfo.name,
        label: ipInfo.name,
    }));

    return [...normalOptions, ...magicOptions];
};
