import React from 'react';
import { ModalsProps } from '..';
import { useTranslation } from 'react-i18next';
import { getEnvsNamespace } from '../../../initialize.i18n';
import { Message, Stack, Tooltip, Spinner, Typography, Command } from 'common/design-system/components-v2';
import ValidatePermissionsStyled from './MultiDelete.styled';
import { Icon } from '@dome9/berries/react-components';
import { Vendors, getVendor } from 'common/consts/vendors';
import { useMultiDelte } from './useMultiDelete';
import { getCloudAccountsService } from 'common/interface/data_services';

export const multiDeleteRelevantVendors = [Vendors.KUBERNETES, Vendors.CONTAINER_REGISTRY, Vendors.SHIFT_LEFT];

const MultiDelete: React.FC<ModalsProps> = ({ closeModal, selectedRows, resetSelectedRows }) => {
    const { t } = useTranslation(getEnvsNamespace('table'));
    
    const [isValidateFinished, setIsValidateFinished] = React.useState(false);

    const handleOnClose = () => {
        if (isValidateFinished) {
            resetSelectedRows();
            getCloudAccountsService().clearCache();
        }
        closeModal();
    };

    const handleOnValidateFinished = () => {
        setIsValidateFinished(true);
    };

    const { deleteEnvironments, isLoading, results } = useMultiDelte({ onFinish: handleOnValidateFinished });

    const filteredRows = React.useMemo(() => {
        return selectedRows.filter(row => multiDeleteRelevantVendors.includes(row.platform as Vendors));
    }, [selectedRows]);

    const hasAnyKubernetes = filteredRows.some(row => row.platform === Vendors.KUBERNETES);

    const handleOnConfirm = async () => {
        deleteEnvironments(filteredRows);
    };

    return (
        <Message
            title={t('ACTIONS.MULTI_DELETE.TITLE')}
            text={[t('ACTIONS.MULTI_DELETE.TEXT', { count: filteredRows.length }), t('ACTIONS.MULTI_DELETE.TEXT_BODY')]}
            width='lg'
            variant='danger'
            onClose={handleOnClose}
            onCancel={handleOnClose}
            cancelBtnText={isValidateFinished ? t('ACTIONS.MULTI_DELETE.CLOSE_BUTTON') : t('ACTIONS.MULTI_DELETE.CANCEL_BUTTON')}
            submitBtnText={t('ACTIONS.MULTI_DELETE.SUBMIT_BUTTON')}
            onConfirm={isValidateFinished ? undefined : handleOnConfirm}
            isLoading={isLoading}
        >
            <ValidatePermissionsStyled.Wrapper spacing={3} padding={1}>
                {hasAnyKubernetes && (
                    <Stack spacing={1}>
                        <Typography>{t('ACTIONS.MULTI_DELETE.TEXT_BODY_KUBERNETES')}</Typography>
                        <Command text='helm uninstall asset-mgmt --namespace <NAMESPACE>' />
                    </Stack>
                )}
                <ValidatePermissionsStyled.EnvsListWrapper spacing={3}>
                    {filteredRows.map((row, index) => (
                        <Stack key={`row-${index}`} direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
                            <Stack spacing={1} direction='row' alignItems='center'>
                                <Icon name={getVendor(row.platform)?.icon || 'refresh'} size={12} />
                                <ValidatePermissionsStyled.StyledTypography>{`${row.name} (${row.id})`}</ValidatePermissionsStyled.StyledTypography>
                            </Stack>
                            {results[row.id]?.isLoading && (
                                <Stack margin={[1, 0]}>
                                    <Spinner size={12} />
                                </Stack>)}
                            {(results[row.id] && (results[row.id].isSuccess || results[row.id].error) ) && (
                                <Tooltip
                                    content={results[row.id].error}
                                >
                                    <Icon
                                        name={results[row.id].isSuccess ? 'check' : 'remove'}
                                        color={results[row.id].isSuccess ? 'success' : 'danger'}
                                        size={12}
                                    />
                                </Tooltip>
                            )}
                        </Stack>
                    ))}
                </ValidatePermissionsStyled.EnvsListWrapper>
            </ValidatePermissionsStyled.Wrapper>
        </Message>
    );
};

export default MultiDelete;
