import React, { useCallback } from 'react';
import { ViewerStyled } from './IssueViewer.styled';
import { IssueViewerRegistry } from 'common/module_interface/RiskManagement/issues/IssueViewerRegistry';
import { IIssue, IIssueViewerPanelProvider } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { ISSUE_BASIC_PROPS_PANEL_KEY } from './IssueViewer.initialize';

export const IssueViewer: React.FC<{ issue: IIssue }> = ({ issue }) => {

    const getPanel = useCallback((panelProvider: IIssueViewerPanelProvider) => {
        return <panelProvider.panel key={panelProvider.panelId} issue={issue} />;
    }, [issue]);

    const getRelevantPanels = useCallback(() => {
        return IssueViewerRegistry.getPanelProviders().filter(
            (panelProvider: IIssueViewerPanelProvider) => panelProvider.isRelevant ? panelProvider.isRelevant(issue) : true);
    }, [issue]);

    const getBasePropPanel = useCallback(() => {
        const provider: IIssueViewerPanelProvider | undefined = getRelevantPanels().find(
            (panelProvider: IIssueViewerPanelProvider) => (panelProvider.panelId === ISSUE_BASIC_PROPS_PANEL_KEY));
        return provider ? getPanel(provider) : null;
    }, [getPanel, getRelevantPanels]);

    const getOtherPanels = useCallback(() => {
        const relevantProviders: IIssueViewerPanelProvider[] = getRelevantPanels().filter(
            (panelProvider: IIssueViewerPanelProvider) => (panelProvider.panelId !== ISSUE_BASIC_PROPS_PANEL_KEY));
        return relevantProviders.map((panelProvider: IIssueViewerPanelProvider) => getPanel(panelProvider));
    }, [getPanel, getRelevantPanels]);

    return (
        <ViewerStyled.TopDiv>
            {getBasePropPanel()}
            {getOtherPanels()}
        </ViewerStyled.TopDiv>
    );
};
