import 'ag-grid-enterprise/styles/ag-grid.css';
import { AgGridReact } from 'ag-grid-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../AWSOnboarding.scss';
import { RulesetRenderer } from './Components';
import { getRulesetsFromServerAndSaveToState } from '../helpers/aws.actions';
import {
    getGotRulesetsFromServer,
    getPostureManagementFilterValue,
    getRulesets,
    getSelectedRulsets,
    setPostureManagementFilterValue,
    setSelectedRulesets,
} from '../helpers/aws.reducer';
import { CpSearch } from '@dome9/components/react/components';
import { Icon } from '@dome9/berries/react-components';
import { getStoreService } from 'common/interface/services';
import { ColDef, GridApi } from 'ag-grid-enterprise';

const columnDefs: ColDef[] =
    [{
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerName: 'Name',
        tooltipField: 'name',
        minWidth: 300,
        cellRenderer: 'rulesetRenderer',
        filter: 'agMultiColumnFilter',
        filterValueGetter: (value: any) => value.data.name,
    },
    {
        headerName: 'Category',
        field: 'category',
        hide: true,
        rowGroup: true,
    },
    {
        tooltipField: 'description',
        headerName: 'Description',
        field: 'description',
        flex: 1,
        filter: 'agMultiColumnFilter',
    },
    {
        cellRenderer: 'linkRenderer',
        headerName: '',
        field: 'id',
        width: 50,
    }];


const PostureManagement: FC = () => {
    const dispatch = getStoreService().dispatch;
    const { t } = useTranslation();
    const rulesets = useSelector(getRulesets);
    const selectedRulesets = useSelector(getSelectedRulsets);
    const gotRulesets = useSelector(getGotRulesetsFromServer);
    const [gridApi, setGridApi] = useState<GridApi>();
    const filterValue = useSelector(getPostureManagementFilterValue);

    useEffect(() => {
        getRulesetsFromServerAndSaveToState();
    }, []);

    const rowChanged = (params: any) => {
        setGridApi(params.api);
        params.api.forEachNode((node: any) => {
            if (node.data) {
                node.setSelected(selectedRulesets.indexOf(node.data.id) > -1);
            }
        });
    };

    const LinkRenderer = (props: any) => {
        return (
            <Link to={`/compliance-engine/policy/${props?.value}`} target='_blank' rel='noopener noreferrer'>
                <Icon name='externalLink' />
            </Link>
        );
    };

    function onFilterTextBoxChanged(ev: any) {
        dispatch(setPostureManagementFilterValue(ev.detail));
        gridApi?.setQuickFilter(ev.detail);
    }

    return (
        <div className='aws-onboarding-posture-management'>
            <div>
                <div className='title-large'>{t('ON_BOARDING.POSTURE_MANAGEMENT.HEADER')}</div>
                <div className='title'>{t('ON_BOARDING.POSTURE_MANAGEMENT.DESCRIPTION')}</div>
            </div>
            <div className='table-container'>
                <CpSearch className='search-bar' onCpUpdate={onFilterTextBoxChanged} value={filterValue} />
                {gotRulesets && (
                    <AgGridReact
                        enableBrowserTooltips={true}
                        components={{ linkRenderer: LinkRenderer, rulesetRenderer: RulesetRenderer }}
                        rowSelection='multiple'
                        groupDisplayType='groupRows'
                        groupDefaultExpanded={-1}
                        initialGroupOrderComparator={({ nodeA, nodeB }) => {
                            const a = nodeA.key || 0;
                            const b = nodeB.key || 0;
                            return a > b ? -1 : a < b ? 1 : 0;
                        }}
                        rowHeight={46}
                        className='ag-theme-alpine rulesets'
                        onGridReady={rowChanged}
                        onSelectionChanged={(event) => {
                            dispatch(setSelectedRulesets(event.api.getSelectedRows().map((ruleset) => ruleset.id)));
                        }}
                        suppressRowClickSelection
                        columnDefs={columnDefs}
                        rowData={rulesets}>
                    </AgGridReact>
                )}
            </div>
        </div>
    );
};

export default PostureManagement;
