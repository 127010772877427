import { getNotificationsService, getStoreService } from 'common/interface/services';
import { Rule } from 'common/interface/RulesManagement';
import { setSelectedRule } from './RulesManagement.reducer';
import i18n from 'common/services/translations/translations';

export const setSelectedRuleAction = async (rule: Rule) => {
    const { dispatch } = getStoreService().getReduxTools();
    try {
        await dispatch(setSelectedRule(rule));
    } catch {
        getNotificationsService().error(i18n.t('RULES.ERROR.SELECT_RULE.TITLE'), i18n.t('RULES.ERROR.SELECT_RULE.MESSAGE'));
    }
};
