import { Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

const TopDiv = styled(Stack)`
    flex-direction: column;
`;

const Content = styled(Stack)`
    min-height: 330px;
    grid-gap: 20px;
`;

export const WAFStyled = {
    Content,
    TopDiv,
};