import {
    ISSUE_EXCLUSION_FIELD_NAMES,
} from './IssueExclusions.consts';
import { IssueExclusionNameCellRender } from './IssueExclusionsRenderers/IssueExclusionNameCellRender';
import { SELECTION_GRID_COLUMN } from 'common/utils/tableUtils';
import { ermTrans } from '../../RiskManagement.utils';
import { IssueExclusionTagsCellRender } from './IssueExclusionsRenderers/IssueExclusionTagsCellRender';
import {
    IssueExclusionDateRangeCellRender
} from './IssueExclusionsRenderers/IssueExclusionDateRangeCellRender';
import {
    IssueExclusionRuleCellRender
} from './IssueExclusionsRenderers/IssueExclusionRuleCellRender';
import {
    IssueExclusionEnvironmentCellRender
} from './IssueExclusionsRenderers/IssueExclusionEnvironmentCellRender';
import {
    IssueExclusionOrgUnitsCellRender
} from './IssueExclusionsRenderers/IssueExclusionOrgUnitsCellRender';
import {
    IssueExclusionEntityTypeCellRender
} from './IssueExclusionsRenderers/IssueExclusionEntityTypeCellRender';
import {
    IssueExclusionEntityNameCellRender
} from './IssueExclusionsRenderers/IssueExclusionEntityNameCellRender';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { IssueExclusionSeveritiesCellRender } from './IssueExclusionsRenderers/IssueExclusionSeveritiesCellRender';

export const getIssueExclusionsColumns = (): CGColDef[] => {
    return [
        SELECTION_GRID_COLUMN,
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.NAME,
            field: ISSUE_EXCLUSION_FIELD_NAMES.NAME,
            sortable: true,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.NAME.HEADER'),
            cellRenderer: IssueExclusionNameCellRender,
            minWidth: 200,
            flex: 10,
            sort: 'asc',
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_NAMES,
            field: ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_NAMES,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.ENTITY_NAMES.HEADER'),
            cellRenderer: IssueExclusionEntityNameCellRender,
            minWidth: 120,
            flex: 10,
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.SEVERITIES,
            field: ISSUE_EXCLUSION_FIELD_NAMES.SEVERITIES,
            sortable: true,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.SEVERITIES.HEADER'),
            cellRenderer: IssueExclusionSeveritiesCellRender,
            minWidth: 140,
            flex: 8,
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_TYPES,
            field: ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_TYPES,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.ENTITY_TYPES.HEADER'),
            cellRenderer: IssueExclusionEntityTypeCellRender,
            minWidth: 150,
            flex: 8,
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.RULE_IDS,
            field: ISSUE_EXCLUSION_FIELD_NAMES.RULE_IDS,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.RULE_IDS.HEADER'),
            cellRenderer: IssueExclusionRuleCellRender,
            minWidth: 200,
            flex: 6,
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.ENV_IDS,
            field: ISSUE_EXCLUSION_FIELD_NAMES.ENV_IDS,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.ENV_IDS.HEADER'),
            cellRenderer: IssueExclusionEnvironmentCellRender,
            minWidth: 200,
            flex: 6,
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.ORG_UNIT_IDS,
            field: ISSUE_EXCLUSION_FIELD_NAMES.ORG_UNIT_IDS,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.ORG_UNIT_IDS.HEADER'),
            cellRenderer: IssueExclusionOrgUnitsCellRender,
            minWidth: 200,
            flex: 6,
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_ID,
            field: ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_ID,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.ENTITY_ID.HEADER'),
            minWidth: 200,
            flex: 6,
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.DATE_RANGE,
            field: ISSUE_EXCLUSION_FIELD_NAMES.DATE_RANGE,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.DATE_RANGE.HEADER'),
            cellRenderer: IssueExclusionDateRangeCellRender,
            minWidth: 160,
            flex: 4,
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.TAGS,
            field: ISSUE_EXCLUSION_FIELD_NAMES.TAGS,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.TAGS.HEADER'),
            cellRenderer: IssueExclusionTagsCellRender,
            minWidth: 160,
            flex: 4,
        },
        {
            colId: ISSUE_EXCLUSION_FIELD_NAMES.DESCRIPTION,
            field: ISSUE_EXCLUSION_FIELD_NAMES.DESCRIPTION,
            headerName: ermTrans('ISSUE_EXCLUSIONS.TABLE.COLUMNS.DESCRIPTION.HEADER'),
            minWidth: 200,
            flex: 6,
        },
    ];
};
