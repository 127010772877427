import styled from 'styled-components';


const CyeraComponentStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CyeraNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 24px 24px 30px 24px;
  gap: 8px;
`;

const CyeraClientIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 24px 30px 24px ;
  gap: 8px;
`;

export {
    CyeraComponentStyled,
    CyeraNameWrapper,
    CyeraClientIdWrapper,
};
