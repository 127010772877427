import i18n, { validLanguages } from 'common/services/translations/translations';

export const i18nExclusionNamespace = 'exclusion';

export const initializeI18nExclusion = async () => {
    return Promise.all(validLanguages.map(async (language) => {
        try {
            const contents = await require(`./languages/${language}/translation.json`);
            i18n.addResourceBundle(language, `${i18nExclusionNamespace}`, contents);
        } catch {
            //Nothing to do with missing translation files
        }
    }));
};