import { Vendors } from 'common/consts/vendors';
import { IPolicy, IShallowRuleset } from 'common/interface/policy';
import { getHttpService, ISetPolicyResponse } from 'common/interface/services';
export const getCiemRuleset = () : IShallowRuleset =>{
    return { id: '802',name: 'Entitlement Management',platform: Vendors.GENERIC };
};

export const setCIEMPolicies = async (data: IPolicy[]) => {
    return await getHttpService().post< Promise<ISetPolicyResponse[]>>('ciem/policy', { data });
};

export const getPolicy = async (id: string, useCache?:boolean) :Promise<IPolicy| undefined> => {
    const results = await getCIEMPolicies(useCache);
    return results.find(policy => policy.id === id);
};
export const getCIEMPolicies = async (useCache = true) =>{
    try {
        return await getHttpService().get<IPolicy[]>({
            path: 'ciem/policy',
            cachingConfig: { useCache } });
    } catch {
        return[];
    }
};

export const updatePolicies = async (data: IPolicy[]) => {
    try {
        return await getHttpService().put< Promise<ISetPolicyResponse[]>>('ciem/policy', { data });
    } catch {
        return false;
    }
};