import { APPLICATION_REDUCERS } from 'common/extensibility/WellKnownPaths';
import { IApplicationReducer } from 'common/interface/redux';
import alibabaReducer from './reducer';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { initialAlibabaEnvironmentData } from './AlibabaEnvironment.consts';


const initializeAlibabaEnvRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'env_alibaba',
            reducer: alibabaReducer,
            isBlackList: true,
        },
    ];
    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');
};


export const initializeAlibabaEnv = () => {
    initializeAlibabaEnvRedux();
    initialAlibabaEnvironmentData();
};