import React from 'react';
import { IEnvironment } from 'common/interface/environmentsTable';
import { Vendors } from 'common/consts/vendors';
import KubernetesApi from 'modules/assets/Environments/services/kubernetesApi/kubernetesApi.service';
import ContainerRegistryApi from 'modules/assets/Environments/services/containerRegistryApi/containerRegistryApi.service';
import ShiftLeftApi from 'modules/assets/Environments/services/shiftLeftApi/shiftLeftApi.service';

export interface UseResetPermissionsResults {
    isLoading: boolean;
    isSuccess?: boolean;
    error?: string;
}

export interface UseMultiDelteReq {
    onFinish?: (response: { successAmount: number; failedAmount: number; }) => void;
}

export interface UseMultiDeltePayload {
    isLoading: boolean;
    results: { [id: string]: UseResetPermissionsResults; };

    deleteEnvironments: (environments: IEnvironment[]) => Promise<void>;
    reset?: () => void;
}

export type UseMultiDelte = (req?: UseMultiDelteReq) => UseMultiDeltePayload;

export const useMultiDelte: UseMultiDelte = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<{ [id: string]: UseResetPermissionsResults; }>({});

    const deleteEnvironments = React.useCallback(async (cloudAccounts: IEnvironment[]) => {
        setIsLoading(true);
        
        const groupedCloudAccountsByPlatform = cloudAccounts.reduce<{ [platform: string]: string[] }>((acc, cloudAccount) => {
            if (!acc[cloudAccount.platform]) {
                acc[cloudAccount.platform] = [];
            }
            acc[cloudAccount.platform].push(cloudAccount.id);
            return acc;
        }, {});

        const allResults = await Promise.allSettled(
            Object.entries(groupedCloudAccountsByPlatform).map(([platform, ids]) => {
                setResults(prev => ids.reduce((acc, id) => ({ ...acc, [id]: { isLoading: true } }), prev));
                let request = KubernetesApi.deleteMultiple; // Vendors.KUBERNETES
                switch (platform as Vendors) {
                    case Vendors.CONTAINER_REGISTRY:{
                        request = ContainerRegistryApi.deleteMultiple;
                        break;
                    }
                    case Vendors.SHIFT_LEFT:{
                        request = ShiftLeftApi.deleteMultiple;
                        break;
                    }
                }
                return request(ids)
                    .then((result) => {
                        const newResults = result.data.reduce<{ [id: string]: UseResetPermissionsResults }>((acc, row) => {
                            acc[row.cloudAccountId] = { isLoading: false, isSuccess: row.deleted, error: row.failureReason };
                            return acc;
                        }, {});
                        setResults(prev => ({ ...prev, ...newResults }));
                        return result;
                    })
                    .catch((error) => {
                        const newResults = ids.reduce<{ [id: string]: UseResetPermissionsResults }>((acc, id) => {
                            acc[id] = { isLoading: false, isSuccess: false, error: error.message };
                            return acc;
                        }, {});
                        setResults(prev => ({ ...prev, ...newResults }));
                        return error;
                    });
            })
        );
        req?.onFinish?.({
            successAmount: allResults.filter(result => result.status === 'fulfilled').length,
            failedAmount: allResults.filter(result => result.status === 'rejected').length
        });
        setIsLoading(false);
    }, [req]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setResults({});
    }, []);

    return ({
        isLoading,
        results,

        deleteEnvironments,
        reset
    });
};