import {
    ICdrExclusionProps,
} from './CdrExclusion.interface';
import {
    CdrExclusionItemNames,
} from './CdrExclusion.consts';
import {
    convertValuesToDates, getTrimmedMultiValue,
    getTrimmedSingleValue, getSafeTrimmedSingleValue,
} from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { getRulesOptionsByRuleset } from './CdrExclusion.options';
import {
    IMultiSelectState,
    IItemChanges, IMultiSelectItem, IItemsMap,
} from 'common/erm-components/custom/CustomForm/CustomForm.interface';

export const getExclusionFromState = (itemsMap: IItemsMap): ICdrExclusionProps => {
    return {
        rulesetId: getSafeTrimmedSingleValue(itemsMap[CdrExclusionItemNames.rulesetId]),
        comment: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.comment]),
        orgUnitIds: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.orgUnits]),
        envIds: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.environments]),
        dateRange: convertValuesToDates(getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.dateRange])),
        ruleIds: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.rules]),
        entityNames: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.entityNames]),
        entityId: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.entityId]),
        severities: getTrimmedMultiValue(itemsMap[CdrExclusionItemNames.severities]),
        srcIpCidr: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.srcIpCidr]),
        srcSavedIp: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.srcSavedIp]),
        srcPort: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.srcPort]),
        destIpCidr: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.destIpCidr]),
        destSavedIp: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.destSavedIp]),
        destPort: getTrimmedSingleValue(itemsMap[CdrExclusionItemNames.destPort]),
    };
};

export const getCdrRulesAfterChange = async (item: IMultiSelectItem, changeDetails: IItemChanges): Promise<IMultiSelectState | undefined> => {
    if (changeDetails.newItem.name !== CdrExclusionItemNames.rulesetId) {
        return Promise.resolve(undefined);
    }

    const rulesetId: string | undefined = getTrimmedSingleValue(changeDetails.newItem);
    return {
        ...item.state,
        options: rulesetId ? await getRulesOptionsByRuleset(rulesetId) : [],
    };
};
