import { FC, useEffect, useState } from 'react';
import { SelectV2 } from 'common/design-system/components-v2';
import { I18nExclusion } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { IExclusionEnvironmentProps } from '../../helpers/exclusions.interfaces';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import {
    fetchEnvironments,
    filterEnvironments,
    formatEnvironments,
    handleFetchingError
} from '../../helpers/exclusions.utils';


const ExclusionByEnvironment: FC<IExclusionEnvironmentProps> = ({ isEnabled, selectedRuleset,selectedRulesetDetails , onClose, onChange, selectedOption, initialSelectedOption }) => {
    const { t } = useTranslation(I18nExclusion);
    const [environmentList, setEnvironmentList] = useState<SelectOption[]>([]);

    useEffect(() => {
        const handleClearListAndValue = () => {
            setEnvironmentList([]);
            onChange([]);
        };
        const updateEnvironmentsList = (environments: SelectOption[]) => {
            setEnvironmentList(environments);
        };
        const handleFetchEnvironments = async () => {
            if (!selectedRuleset || !selectedRulesetDetails?.cloudVendor) {
                handleClearListAndValue();
                return;
            }
            try {
                const environmentsData = await fetchEnvironments();
                const filteredEnvironments = environmentsData && filterEnvironments(environmentsData, selectedRulesetDetails?.cloudVendor);
                const formattedEnvironments = filteredEnvironments && formatEnvironments(filteredEnvironments);
                formattedEnvironments && updateEnvironmentsList(formattedEnvironments);
            } catch (error) {
                handleFetchingError(t, onClose);
            }
        };
        handleFetchEnvironments();
    }, [selectedRuleset]);

    useEffect(() => {
        initialSelectedOption ? onChange(initialSelectedOption) : onChange([]);
    }, [environmentList]);

    const handleEnvironmentChange = (newValue: string[]) => {
        onChange(newValue);
    };

    return (
        <SelectV2
            onChange={(value: string[]) => handleEnvironmentChange(value)}
            isMulti={true}
            options={environmentList}
            readOnly={!isEnabled }
            placeholder={t('MODAL.GENERAL.SELECT')}
            value={selectedOption}
            data-aid='exclusions-environment-select'
            fullWidth
            clearable
        />
    );
};

export default ExclusionByEnvironment;