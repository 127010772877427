import { Fragment, FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { initialLogin } from '../auth/Auth.actions';

const AuthenticatedPage: FunctionComponent<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        initialLogin().then((value) => {
            setIsLoggedIn(value);
        });
    }, []);

    return <Fragment>{isLoggedIn ? props.children : <></>}</Fragment>;
};

export default AuthenticatedPage;
