import React, { useMemo } from 'react';
import { FindingOverviewRegistry } from 'common/module_interface/intelligence/Findings/FindingOverviewRegistry';
import { IRightHeaderPanelComponent } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { Stack } from 'common/design-system/components-v2';
import { IEvent } from 'common/module_interface/events/Events';

export const RightHeaderPanel: React.FC<{ finding: IEvent }> = ({ finding }) => {

    const rightHeaderPanelItems: IRightHeaderPanelComponent[] = useMemo(() => {
        return FindingOverviewRegistry.getRightHeaderPanelProviders().filter((item) => item.isRelevant ? item.isRelevant(finding) : true);
    }, [finding]);

    const rightHeaderPanelComponents = useMemo(() => {
        return rightHeaderPanelItems.map((item) => <item.component key={item.id} finding={finding} { ...(item.componentProps ?? {}) } />);
    }, [rightHeaderPanelItems, finding]);

    return (
        <Stack spacing={2}>
            { rightHeaderPanelComponents }
        </Stack>
    );
};
