import { DashboardWidgetTypes } from 'common/module_interface/overview/Interface';

export const EVENT_ACTIVITY_AWS = 'Event Activity (AWS)';
export const EVENT_ACTIVITY_AZURE = 'Event Activity (Azure)';
export const EVENT_ACTIVITY_ALIBABA = 'Event Activity (Alibaba)';
export const TRAFFIC_ACTIVITY_AWS = 'Traffic Activity (AWS)';
export const TRAFFIC_ACTIVITY_AZURE = 'Traffic Activity (Azure)';
export const TRAFFIC_ACTIVITY_KUBERNETES = 'Traffic Activity (Kubernetes)';
export const __ALL_ACCOUNTS__ = '__ALL_ACCOUNTS__';

export const SourceOptionsByDataSourceName = {
    [TRAFFIC_ACTIVITY_AWS]: 'vpcfl',
    [EVENT_ACTIVITY_AWS]: 'cloudtrail',
    [TRAFFIC_ACTIVITY_AZURE]: 'azurefl',
    [EVENT_ACTIVITY_AZURE]: 'azurect',
    [TRAFFIC_ACTIVITY_KUBERNETES]: 'k8s',
    [EVENT_ACTIVITY_ALIBABA]: 'alibabaact'
};

export const defaultIntelligenceWidgetsTypeOptions = [DashboardWidgetTypes.Pie, DashboardWidgetTypes.Column, DashboardWidgetTypes.Top, DashboardWidgetTypes.Bottom];

export type AssetType = 'flowlogs'|'cloudtrail'|'azureflowlogs'|'flowlogskubernets'|'azurecloudtrail'|'alibabaactiontrail';

export const AssetTypesDataSourceMap= {
    'flowlogs': 'vpcfl',
    'cloudtrail': 'cloudtrail',
    'azureflowlogs': 'azurefl',
    'azurecloudtrail': 'azurect',
    'alibabaactiontrail': 'alibabaact',
    'flowlogskubernets': 'k8s'
};

export const AssetSourceMap= {
    [TRAFFIC_ACTIVITY_AWS]: 'flowlogs',
    [EVENT_ACTIVITY_AWS]: 'cloudtrail',
    [TRAFFIC_ACTIVITY_AZURE]: 'azureflowlogs',
    [EVENT_ACTIVITY_AZURE]: 'azurecloudtrail',
    [EVENT_ACTIVITY_ALIBABA]: 'alibabaactiontrail',
    [TRAFFIC_ACTIVITY_KUBERNETES]: 'flowlogskubernets'
};
