import React from 'react';
import { LevelBox } from 'common/erm-components/custom/LevelBoxes/LevelBox';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import { IIssuesStatusData } from '../DataSecuritySummaryStatus.interface';
import { Stack } from 'common/design-system/components-v2';
import { StatusBox } from 'common/erm-components/custom/SummaryStatus/StatusBox/StatusBox';
import { ILevelBoxItem } from 'common/erm-components/custom/LevelBoxes/LevelBox.interface';

export const IssuesStatus: React.FC<{ data: IIssuesStatusData }> = ({ data }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    return (
        <StatusBox text={t('DATA_SECURITY_DASHBOARD.SUMMARY.ISSUES.TITLE')} >
            <Stack direction={'row'} spacing={4} padding={[0, 2, 0, 2]}>
                {data.items.map((item: ILevelBoxItem, index: number) => <LevelBox item={item} key={index} width={'25%'}/>)}
            </Stack>
        </StatusBox>
    );
};
