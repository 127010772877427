import React, { useEffect, useState } from 'react';
import { Checkbox, CopiableLine, Icon, Stack, Typography } from 'common/design-system/components-v2';
import { ActiveFeaturesManagerTableStyled } from './ActiveFeaturesManagerTable.styled';
import {
    ActiveFeatureTopicEnum,
    ActiveFeatureTopicsMap,
    IActiveFeatureStatusInfo
} from 'common/module_interface/RiskManagement/activeFeatures/ActiveFeatures';
import { updateLocalStorageActiveFeature } from './ActiveFeaturesManagerTable.utils';

interface IExperimentsTableProps {
    activeFeaturesStatus: IActiveFeatureStatusInfo[];
    setIsChanged: (isChanged: boolean) => void;
    topic: ActiveFeatureTopicEnum;
}
export const ActiveFeaturesManagerTable: React.FC<IExperimentsTableProps> = ({ activeFeaturesStatus, setIsChanged, topic }) => {
    const [selectedActiveFeatures, setSelectedActiveFeatures] = useState<Record<string, boolean>>();

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectedActiveFeatures) return;

        setSelectedActiveFeatures(prevItems => ({
            ...prevItems,
            [event.target.name]: event.target.checked,
        }));
        setIsChanged(true);
        updateLocalStorageActiveFeature(event.target.name, event.target.checked);
    };

    useEffect(() => {
        const selectedActiveFeatures = activeFeaturesStatus.reduce((acc, curr) => {
            acc[curr.key] = curr.localStorageActive;
            return acc;
        }, {} as Record<string, boolean>);
        setSelectedActiveFeatures(selectedActiveFeatures);
    }, [activeFeaturesStatus]);

    return (
        <Stack direction={'column'} spacing={2}>
            <ActiveFeaturesManagerTableStyled.TableTitle>
                <Icon name={ActiveFeatureTopicsMap[topic].icon} size={24}/>
                <Typography variant="h2">{ActiveFeatureTopicsMap[topic].title} Active features:</Typography>
            </ActiveFeaturesManagerTableStyled.TableTitle>
            <ActiveFeaturesManagerTableStyled.TableDiv>
                <ActiveFeaturesManagerTableStyled.TableRow key={`${ActiveFeatureTopicsMap[topic].title}-header`}>
                    <ActiveFeaturesManagerTableStyled.TableCell key={'active'} width={'100px'} isText={false}>
                        <Typography variant={'bodyLg'}>Active</Typography>
                    </ActiveFeaturesManagerTableStyled.TableCell>
                    <ActiveFeaturesManagerTableStyled.TableCell key={'key'} width={'250px'} isText={true}>
                        <Typography variant={'bodyLg'}>Key</Typography>
                    </ActiveFeaturesManagerTableStyled.TableCell>
                    <ActiveFeaturesManagerTableStyled.TableCell key={'Active in local storage'} width={'200px'} isText={false}>
                        <Typography variant={'bodyLg'}>Activated locally</Typography>
                    </ActiveFeaturesManagerTableStyled.TableCell>
                    <ActiveFeaturesManagerTableStyled.TableCell key={'description'} isText={true}>
                        <Typography variant={'bodyLg'} >Description</Typography>
                    </ActiveFeaturesManagerTableStyled.TableCell>
                    <ActiveFeaturesManagerTableStyled.TableCell key={'Active in server'} width={'150px'} isText={false}>
                        <Typography variant={'bodyLg'}>Active in server</Typography>
                    </ActiveFeaturesManagerTableStyled.TableCell>
                </ActiveFeaturesManagerTableStyled.TableRow>
                <ActiveFeaturesManagerTableStyled.TableBody>
                    {activeFeaturesStatus.map((activeFeatureInfo: IActiveFeatureStatusInfo) => {
                        return <ActiveFeaturesManagerTableStyled.TableRow key={`${activeFeatureInfo.key}-header-`}>
                            <ActiveFeaturesManagerTableStyled.TableCell key={`${activeFeatureInfo.key}-active`} width={'100px'} isText={false}>
                                <Icon name={activeFeatureInfo.isActive ? 'checkCircle' : 'remove'} color={activeFeatureInfo.isActive ? 'success' : 'alert' }/>
                            </ActiveFeaturesManagerTableStyled.TableCell>
                            <ActiveFeaturesManagerTableStyled.TableCell key={`${activeFeatureInfo.key}-key`} width={'250px'} isText={true}>
                                <Typography variant={selectedActiveFeatures?.[activeFeatureInfo.key] ? 'body500' : undefined}
                                    color={selectedActiveFeatures?.[activeFeatureInfo.key] ? 'brandPrimary' : undefined}>
                                    <CopiableLine value={activeFeatureInfo.key}>{activeFeatureInfo.key}</CopiableLine>
                                </Typography>
                            </ActiveFeaturesManagerTableStyled.TableCell>
                            <ActiveFeaturesManagerTableStyled.TableCell key={`${activeFeatureInfo.key}-Active in local storage`} width={'200px'} isText={false}>
                                <Checkbox name={activeFeatureInfo.key} checked={selectedActiveFeatures?.[activeFeatureInfo.key]} onChange={handleOnChange}/>
                            </ActiveFeaturesManagerTableStyled.TableCell>
                            <ActiveFeaturesManagerTableStyled.TableCell key={`${activeFeatureInfo.key}-description`} isText={true}>
                                <Typography>{activeFeatureInfo.description}</Typography>
                            </ActiveFeaturesManagerTableStyled.TableCell>
                            <ActiveFeaturesManagerTableStyled.TableCell key={`${activeFeatureInfo.key}-Active in server`} width={'150px'} isText={false}>
                                <Icon name={activeFeatureInfo.serverActive ? 'checkCircle' : 'remove'} />
                            </ActiveFeaturesManagerTableStyled.TableCell>
                        </ActiveFeaturesManagerTableStyled.TableRow>;
                    })}
                </ActiveFeaturesManagerTableStyled.TableBody>
            </ActiveFeaturesManagerTableStyled.TableDiv>
        </Stack>
    );
};

export default ActiveFeaturesManagerTable;
