import { AFTER_LOGIN_EVENT, REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import BusinessPriorityRules from './components/BusinessPriorityRules/BusinessPriorityRules';
import DashboardPage from './components/dashboards/RiskDashboard/RiskDashboard';
import Rulesets from './components/Rulesets/Rulesets';
import { addMainMenuItem, addMenuSectionItem } from 'common/helpers/menu';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import initializeRulesetsColumnDefs from './components/Rulesets/Definitions/RulesetsColumnDefinitions';
import initializeRulesetsFiltersDefs from './components/Rulesets/Definitions/FilterDefinitions';
import { initializeRiskDashboard } from './components/dashboards/RiskDashboard/initialize';
import { IProtectedAssetDetailProvider } from 'common/module_interface/assets/ProtectedAssets';
import { DATA_SENSITIVITY_VALUES, ERM_URLS, I18nRiskNamespace } from './consts';
import i18n, { t } from 'i18next';
import RisksWidget from './components/ProtectedAsset/Widgets/RisksWidget';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import {
    AssetDetailsBusinessPriorityChipWidget,
    AssetDetailsDataSensitivityChipWidget,
    AssetDetailsExposureChipWidget,
    AssetDetailsIamSensitivityChipWidget,
    AssetDetailsRiskScoreChipWidget,
    AssetDetailsWafChipWidget,
} from './components/ProtectedAsset/Widgets/AssetDetailsChipWidgets';
import { InfoPanelActionProps } from 'common/components/InfoPane/InfoPane';
import { Cves, PostureFindings, SecurityEvents } from './components/PerimeterExposure/PopoverItems';
import { initializeProtectedAssetsPage } from './components/ProtectedAssetsPage/initialize';
import {
    PerimeterExposurePopoverBusinessPriorityChipWidget,
    PerimeterExposurePopoverExposureChipWidget,
    PerimeterExposurePopoverIamSensitivityChipWidget,
    PerimeterExposurePopoverRiskScoreChipWidget, PerimeterExposurePopoverWafProtectionChipWidget,
} from './components/PerimeterExposure/PopoverChips';
import { BusinessPriorityBadge, SecurityEventsBadge } from './components/PerimeterExposure/AssetBadges';
import TopRemediationsWidget from './components/ProtectedAsset/Widgets/TopRemediationsWidget';
import {
    OVERVIEW_PANEL_RISK_MANAGEMENT_CATEGORIES_IDS,
} from 'common/module_interface/RiskManagement/RiskManagementComponentsIds';
import {
    getBusinessPriority,
    getIamExposureLevelInfo,
    getNetworkExposureLevelInfo,
    isDataSensitivityRelevant,
    isIamExposureRelevant,
    isIamSensitivityRelevant,
    isNetworkExposureRelevant,
    isRiskSupportedForAsset,
    setupRiskData,
    UNDEFINED_BUSINESS_PRIORITY_ID,
} from './components/ProtectedAsset/ErmDataUtils';
import { isNil } from 'common/utils/helpFunctions';
import { OVERVIEW_MENU_ITEM } from 'common/module_interface/overview/Consts';
import { OverviewPanelRegistry } from 'common/module_interface/assets/OverviewPanelRegistry';
import { InsightPopoverRegistry } from 'common/module_interface/insight/InsightPopoverRegistry';
import { IReactPageRoute } from 'common/interface/routing';
import { RiskManagementPanelRegistry } from 'common/module_interface/RiskManagement/RiskManagementPanelRegistry';
import { RiskManagementRegistry } from './RiskManagementRegistry';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import ExposureDashboard from './components/dashboards/ExposureDashboard/ExposureDashboard';
import { EnvironmentsTableRegistry, ETColDef } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { IEnvironment } from 'common/interface/environmentsTable';
import { EnvironmentChip, IEnvironmentsFilterParams } from 'common/module_interface/assets/Environments';
import { renderMultiSelectFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import EnvTableRiskCellRenderer from './components/CellRenderers/EnvTableRiskCellRenderer';
import {
    RISK_MANAGEMENT_WIDGETS_SERVICE_ID,
    RiskManagementWidgetsService,
} from './DynamicWidgets/RiskManagementWidgetsService';
import { IssuesPage } from './components/Issues/IssuesPage/IssuesPage';
import initializeIssues from './components/Issues/Issues.initialize';
import { RiskManagementService } from './services/RiskManagementService';
import {
    CYERA_INTEGRATION_SERVICE_ID,
    getRiskManagementService,
    RISK_MANAGEMENT_SERVICE_ID,
    RISK_MANAGEMENT_URLS_SERVICE_ID,
    SENTRA_INTEGRATION_SERVICE_ID,
} from 'common/module_interface/RiskManagement/Services';
import { getVendor, Vendors } from 'common/consts/vendors';
import {
    FieldConvertorsRegistry,
    FieldEntityKind,
    IFieldConvertor,
    IGNORE_FIELD,
} from 'common/registries/FieldConvertorsRegistry';
import {
    assetTypeToFindingType,
    ermPermissionFn,
    hasAutoActionsPermissionFn,
    hasSecurityControlsPermissionFn,
    hasIssueExclusionsPermissionFn,
    hasCveIgnoreListPermissionFn,
    hasMalwareIgnoreListPermissionFn,
} from './RiskManagement.utils';
import { OverviewDashboardsRegistry } from 'common/module_interface/overview/OverviewDashboardsRegistry';
import {
    initializeAssetTypesColumnDefs,
} from './components/dashboards/RiskDashboard/RiskiestEntities/RiskiestAssetTypes/RiskiestAssetTypes.initialize';
import { IRowNode } from 'ag-grid-community';
import { AwsEnvironmentAddingBase, AwsEnvironmentAddinRegistry } from 'common/helpers/awsEnvironment';
import { AzureEnvironmentAddingBase, AzureEnvironmentAddinRegistry } from 'common/helpers/azureEnvironment';
import { GcpEnvironmentAddingBase, GcpEnvironmentAddinRegistry } from 'common/helpers/gcpEnvironment';
import { getRiskLevelInfo, RiskLevelServerEnum } from 'common/module_interface/RiskManagement/RiskLevel';
import { Addin } from 'common/extensibility/AddinRegistry';
import { initializeSentraIntegration } from './components/Integrations/SentraIntegration/SentraIntegration.initialize';
import { SentraIntegrationService } from './services/SentraIntegrationService';
import { AutoActionsPage } from './components/AutoActions/AutoActionsPage';
import initializeAutoActions from './components/AutoActions/AutoActions.initialize';
import { setupSecurityGraphData } from './SecurityGraph.supported';
import DataSecurityDashboard from './components/dashboards/DataSecurityDashboard/DataSecurityDashboard';
import ActiveFeaturesManagerPage from './components/ActiveFeatures/ActiveFeaturesManagerPage/ActiveFeaturesManagerPage';
import initializeActiveFeatures from './components/ActiveFeatures/ActiveFeatures.initialize';
import {
    ACTIVE_FEATURE_PAGE_MANAGER,
    DATA_SECURITY_ACTIVE_FEATURE, WAF_PROTECTION_ACTIVE_FEATURE,
} from './components/ActiveFeatures/ActiveFeatures.consts';
import initializeSecurityControl from './components/SecurityControls/SecurityControls.initialize';
import initializeIssueExclusion from './components/IssueExclusions/IssueExclusions.initialize';
import { SecurityControlsPage } from './components/SecurityControls/SecurityControlsPage';
import { IssueExclusionsPage } from './components/IssueExclusions/IssueExclusionsPage';
import { getSafeWafProtectionInfo } from './components/WafProtection/WafProtection';
import { CveIgnoreListPage } from './components/IgnoreList/CveIgnoreList/CveIgnoreListPage';
import { MalwareIgnoreListPage } from './components/IgnoreList/MalwareIgnoreList/MalwareIgnoreListPage';
import initializeCveIgnoreList from './components/IgnoreList/CveIgnoreList/CveIgnoreList.initialize';
import { CyeraIntegrationService } from './services/CyeraIntegrationService';
import { initializeCyeraIntegration } from './components/Integrations/CyeraIntegration/CyeraIntegration.initialize';
import { isRulesetEnabled } from 'common/utils/userPermissionUtils';
import { ErmUrlsService } from './services/ErmUrlsService';

function initializeRoutes() {
    const dashboardPage: IReactPageRoute = {
        condition: true,
        component: DashboardPage,
        path: ERM_URLS.OVERVIEW_RISK_DASHBOARD,
    };

    const dashboardPageErm: IReactPageRoute = {
        condition: true,
        component: DashboardPage,
        path: ERM_URLS.ERM_RISK_DASHBOARD,
    };

    const issuesPage: IReactPageRoute = {
        condition: true,
        component: IssuesPage,
        path: ERM_URLS.ISSUES,
    };

    const autoActionsPage: IReactPageRoute = {
        condition: true,
        component: AutoActionsPage,
        path: ERM_URLS.AUTO_ACTIONS,
    };

    const securityControlsPage: IReactPageRoute = {
        condition: true,
        component: SecurityControlsPage,
        path: ERM_URLS.SECURITY_CONTROLS,
    };

    const cveIgnoreListPage: IReactPageRoute = {
        condition: true,
        component: CveIgnoreListPage,
        path: ERM_URLS.CVE_IGNORE_LIST,
    };

    const malwareIgnoreListPage: IReactPageRoute = {
        condition: true,
        component: MalwareIgnoreListPage,
        path: ERM_URLS.MALWARE_IGNORE_LIST,
    };

    const issueExclusionsPage: IReactPageRoute = {
        condition: true,
        component: IssueExclusionsPage,
        path: ERM_URLS.ISSUE_EXCLUSIONS,
    };

    const businessPriorityRulesPage: IReactPageRoute = {
        condition: true,
        component: BusinessPriorityRules,
        path: ERM_URLS.BUSINESS_PRIORITY,
    };

    const RulesetsPage: IReactPageRoute = {
        condition: true,
        component: Rulesets,
        path: ERM_URLS.RULESETS,
    };

    const activeFeaturesManagerPage: IReactPageRoute = {
        condition: true,
        component: ActiveFeaturesManagerPage,
        path: ERM_URLS.ACTIVE_FEATURES_MANAGER,
    };

    const getExposureDashboardPage = (): IReactPageRoute => {
        return {
            condition: true,
            component: ExposureDashboard,
            path: ERM_URLS.EXPOSURE_DASHBOARD,
        };
    };

    const getDataSecurityPage= (): IReactPageRoute => {
        return {
            condition: true,
            component: DataSecurityDashboard,
            path: ERM_URLS.DATA_SECURITY_DASHBOARD,
        };
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        { id: 'ERM_DASHBOARD_PAGE', content: dashboardPage, permission: ermPermissionFn },
        { id: 'ERM_DASHBOARD_PAGE_ERM', content: dashboardPageErm, permission: ermPermissionFn },
        { id: 'ERM_ISSUES_PAGE', content: issuesPage, permission: ermPermissionFn },
        { id: 'ERM_AUTO_ACTIONS_PAGE', content: autoActionsPage, permission: hasAutoActionsPermissionFn },
        { id: 'ERM_SECURITY_CONTROLS_PAGE', content: securityControlsPage, permission: hasSecurityControlsPermissionFn },
        { id: 'ERM_CVE_IGNORE_LIST_PAGE', content: cveIgnoreListPage, permission: hasCveIgnoreListPermissionFn },
        { id: 'ERM_MALWARE_IGNORE_LIST_PAGE', content: malwareIgnoreListPage, permission: hasMalwareIgnoreListPermissionFn },
        { id: 'ERM_ISSUE_EXCLUSIONS_PAGE', content: issueExclusionsPage, permission: hasIssueExclusionsPermissionFn },
        { id: 'ERM_BUSINESS_PRIORITY_RULES_PAGE', content: businessPriorityRulesPage, permission: ermPermissionFn },
        { id: 'ERM_RULESETS_PAGE', content: RulesetsPage, permission: ermPermissionFn },
        { id: 'ERM_EXTERNAL_EXPOSURE_DASHBOARD_PAGE', content: getExposureDashboardPage, permission: ermPermissionFn },
        { id: 'ERM_DATA_SECURITY_DASHBOARD_PAGE', content: getDataSecurityPage, permission: () => ermPermissionFn(DATA_SECURITY_ACTIVE_FEATURE.key) },
        { id: 'ERM_ACTIVE_FEATURES_MANAGER_PAGE', content: activeFeaturesManagerPage, permission: () => ermPermissionFn(ACTIVE_FEATURE_PAGE_MANAGER.key) },
    ]);
    RiskManagementRegistry.addNewBusinessPriorityService();
}

function initializeDashboards() {
    addMenuSectionItem(OVERVIEW_MENU_ITEM, 'default', {
        id: 'dashboard',
        position: 0,
        label: t('OVERVIEW_MENU.RISK_MANAGEMENT', { ns: I18nRiskNamespace }),
        state: ERM_URLS.OVERVIEW_RISK_DASHBOARD,
        permission: ermPermissionFn,
    });

    addMenuSectionItem(OVERVIEW_MENU_ITEM, 'default', {
        id: 'external exposure dashboard',
        position: 7,
        label: t('OVERVIEW_MENU.EXTERNAL_EXPOSURE', { ns: I18nRiskNamespace }),
        state: ERM_URLS.EXPOSURE_DASHBOARD,
        permission: ermPermissionFn,
    });

    addMenuSectionItem(OVERVIEW_MENU_ITEM, 'default', {
        id: 'data security dashboard',
        position: 8,
        label: t('OVERVIEW_MENU.DATA_SECURITY', { ns: I18nRiskNamespace }),
        state: ERM_URLS.DATA_SECURITY_DASHBOARD,
        permission: () => ermPermissionFn(DATA_SECURITY_ACTIVE_FEATURE.key),
    });
}

function initializeMenu() {
    addMainMenuItem({
        id: 'risk management',
        position: 15,
        icon: 'risk-management_4',
        label: t('MAIN_MENU.RISK_MANAGEMENT', { ns: I18nRiskNamespace }),
        permission: ermPermissionFn,
        sections: [
            {
                id: 'default',
                position: 10,
                items: [
                    {
                        id: 'dashboard',
                        position: 10,
                        label: t('MAIN_MENU.DASHBOARD', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.ERM_RISK_DASHBOARD,
                        permission: ermPermissionFn,
                    },
                ],
            },
            {
                id: 'issues',
                position: 12,
                items: [
                    {
                        id: 'issues page',
                        position: 12,
                        label: t('MAIN_MENU.SECURITY_ISSUES', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.ISSUES,
                        chip: {
                            label: t('GENERAL.PREVIEW', { ns: I18nRiskNamespace }),
                        },
                        permission: ermPermissionFn,
                    },
                ],
            },
            {
                id: 'automation actions',
                position: 14,
                items: [
                    {
                        id: 'automation actions page',
                        position: 14,
                        label: t('MAIN_MENU.AUTO_ACTIONS', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.AUTO_ACTIONS,
                        chip: {
                            label: i18n.t('GENERAL.PREVIEW'),
                        },
                        permission: () => hasAutoActionsPermissionFn(),
                    },
                ],
            },
            {
                id: 'issue exclusions',
                position: 15,
                items: [
                    {
                        id: 'issue exclusions page',
                        position: 16,
                        label: t('MAIN_MENU.ISSUE_EXCLUSIONS', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.ISSUE_EXCLUSIONS,
                        chip: {
                            label: i18n.t('GENERAL.PREVIEW'),
                        },
                        permission: () => hasIssueExclusionsPermissionFn(),
                    },
                ],
            },
            {
                id: 'security controls',
                position: 16,
                items: [
                    {
                        id: 'security controls page',
                        position: 18,
                        label: t('MAIN_MENU.SECURITY_CONTROLS', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.SECURITY_CONTROLS,
                        chip: {
                            label: i18n.t('GENERAL.PREVIEW'),
                        },
                        permission: () => hasSecurityControlsPermissionFn(),
                    },
                ],
            },
            {
                id: 'cve ignore list',
                position: 17,
                items: [
                    {
                        id: 'cve ignore list page',
                        position: 17,
                        label: t('MAIN_MENU.CVE_IGNORE_LIST', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.CVE_IGNORE_LIST,
                        chip: {
                            label: t('GENERAL.PREVIEW', { ns: I18nRiskNamespace }),
                        },
                        permission: () => hasCveIgnoreListPermissionFn(),
                    },
                ],
            },
            {
                id: 'malware ignore list page',
                position: 18,
                items: [
                    {
                        id: 'malware ignore list',
                        position: 18,
                        label: t('MAIN_MENU.MALWARE_IGNORE_LIST', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.MALWARE_IGNORE_LIST,
                        chip: {
                            label: t('GENERAL.PREVIEW', { ns: I18nRiskNamespace }),
                        },
                        permission: () => hasMalwareIgnoreListPermissionFn(),
                    },
                ],
            },
            {
                id: 'assets',
                position: 20,
                title: '',
                items: [
                    {
                        id: 'protected assets',
                        position: 10,
                        label: t('MAIN_MENU.PROTECTED_ASSETS', { ns: I18nRiskNamespace }),
                        state: '/risk-management/protected-assets',
                        urlAliases: ['/risk-management/protected-assets/details', '/risk-management/protected-assets/generic'],
                        permission: ermPermissionFn,
                    },
                ],
            },
            {
                id: 'rules',
                position: 30,
                title: '',
                items: [
                    {
                        id: 'rulesets',
                        position: 10,
                        label: t('MAIN_MENU.RULESETS', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.RULESETS,
                        urlAliases: [/^\/risk-management\/ruleset\/.+/, '/risk-management/ruleset'],
                        permission: () => ermPermissionFn() && isRulesetEnabled(),
                    },
                    {
                        id: 'business priority rules',
                        position: 20,
                        label: t('MAIN_MENU.BUSINESS_PRIORITY_RULES', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.BUSINESS_PRIORITY,
                        permission: ermPermissionFn,
                    },
                ],
            },
            {
                id: 'active-features-manager',
                position: 40,
                title: '',
                items: [
                    {
                        id: 'active-features-manager',
                        position: 10,
                        label: t('MAIN_MENU.ACTIVE_FEATURES_MANAGER', { ns: I18nRiskNamespace }),
                        state: ERM_URLS.ACTIVE_FEATURES_MANAGER,
                        permission: () => ermPermissionFn(ACTIVE_FEATURE_PAGE_MANAGER.key),
                    },
                ],
            }
        ],
    });
    initializeDashboards();
}

function initializeRiskDashboardAsDefault() {
    OverviewDashboardsRegistry.addDefaultGlobalDashboardRoute(
        ERM_URLS.OVERVIEW_RISK_DASHBOARD, {
            route: ERM_URLS.OVERVIEW_RISK_DASHBOARD,
            hasRoutePermissions: ermPermissionFn,
        }, 10);
}

function initializeRiskCategory() {
    const riskCategoryId = OVERVIEW_PANEL_RISK_MANAGEMENT_CATEGORIES_IDS.RISK_CATEGORY_ID;
    const riskItemId = 'risk data';
    const topRemediationsItemId = 'top remediations';

    OverviewPanelRegistry.addCategory(riskCategoryId, 10,
        () => i18n.t('ASSET_DETAILS.PANELS.OVERVIEW.TITLE', { ns: I18nRiskNamespace }),
        (entity: ICloudEntityData) => isRiskSupportedForAsset(entity.typeByPlatform),
        ermPermissionFn,
    );

    OverviewPanelRegistry.addCategoryItem(riskItemId, riskCategoryId, 10, {
        id: riskItemId,
        component: RisksWidget,
    },
    ermPermissionFn,
    );

    RiskManagementPanelRegistry.addRisksItemWidget(topRemediationsItemId, 30, {
        id: topRemediationsItemId,
        wrapperClass: 'flex flex-col *bg-aside relative',
        component: TopRemediationsWidget,
    }, ermPermissionFn);
}

function initializeDetailsPaneItems() {
    const riskChipProvider: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: (entity) => {
            const riskScore = entity.protectedAsset?.riskScore;
            return !isNil(riskScore);

        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: AssetDetailsRiskScoreChipWidget,
                componentProps: { entity },

            };
        },
    };

    const exposureChipProvider: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: (entity) => {
            if (isNetworkExposureRelevant(entity.typeByPlatform) || isIamExposureRelevant(entity.typeByPlatform)) {
                const networkExposureLevelInfo = getNetworkExposureLevelInfo(entity.protectedAsset?.context?.networkExposure);
                const iamExposureLevelInfo = getIamExposureLevelInfo(entity.protectedAsset?.context?.iamExposure);
                return !networkExposureLevelInfo.isUnknown || !iamExposureLevelInfo.isUnknown;
            }
            return false;
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: AssetDetailsExposureChipWidget,
                componentProps: { entity },
            };
        },
    };

    const businessPriorityChipProvider: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: (entity) => {
            const businessPriority = entity.protectedAsset?.businessPriority;
            if (!businessPriority || businessPriority === UNDEFINED_BUSINESS_PRIORITY_ID) {
                return false;
            }

            const businessPriorityExtension = getBusinessPriority(businessPriority);
            return !!businessPriorityExtension;
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: AssetDetailsBusinessPriorityChipWidget,
                componentProps: { entity },

            };
        },
    };

    const iamSensitivityChipProvider: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: (entity) => {
            if (isIamSensitivityRelevant(entity.typeByPlatform)) {
                const iamSensitivity = entity.protectedAsset?.context?.iamSensitivity;
                return !isNil(iamSensitivity);
            }
            return false;

        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: AssetDetailsIamSensitivityChipWidget,
                componentProps: { entity },

            };
        },
    };

    const dataSensitivityChipProvider: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: (entity) => {
            if (isDataSensitivityRelevant(entity.typeByPlatform)) {
                const dataSensitivity = entity.protectedAsset?.context?.dataSensitivity;
                return (dataSensitivity === DATA_SENSITIVITY_VALUES.SENSITIVE);
            }
            return false;
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: AssetDetailsDataSensitivityChipWidget,
                componentProps: { entity },
            };
        },
    };

    const wafChipProvider: IProtectedAssetDetailProvider<InfoPanelActionProps> = {
        isRelevant: (entity) => {
            const wafProtectionInfo = getSafeWafProtectionInfo(entity.protectedAsset?.context?.wafProtection);
            return wafProtectionInfo.isProtected;
        },
        getValue(entity: ICloudEntityData): InfoPanelActionProps {
            return {
                component: AssetDetailsWafChipWidget,
                componentProps: { entity },
            };
        },
    };

    ProtectedAssetsPageRegistry.addChipProviders([
        {
            id: 'risk score chip',
            position: 1000, //needs to be rightmost
            content: riskChipProvider,
            permission: ermPermissionFn,
        },
        {
            id: 'exposure chip',
            position: 20,
            content: exposureChipProvider,
            permission: ermPermissionFn,
        },
        {
            id: 'business priority chip',
            position: 30,
            content: businessPriorityChipProvider,
            permission: ermPermissionFn,
        },
        {
            id: 'iam sensitivity chip',
            position: 40,
            content: iamSensitivityChipProvider,
            permission: ermPermissionFn,
        },
        {
            id: 'data sensitivity chip',
            position: 50,
            content: dataSensitivityChipProvider,
            permission: ermPermissionFn,
        },
        {
            id: 'waf chip',
            position: 60,
            content: wafChipProvider,
            permission: () => ermPermissionFn(WAF_PROTECTION_ACTIVE_FEATURE.key),
        },
    ]);
}

function initializePerimeterExposureAssetPopoverChips() {
    InsightPopoverRegistry.addChips([{
        id: 'risk score popover chip',
        position: 1000,
        component: PerimeterExposurePopoverRiskScoreChipWidget,
        permission: ermPermissionFn,
    }, {
        id: 'exposure popover chip',
        position: 200,
        component: PerimeterExposurePopoverExposureChipWidget,
        permission: ermPermissionFn,
    }, {
        id: 'business priority popover chip',
        position: 300,
        component: PerimeterExposurePopoverBusinessPriorityChipWidget,
        permission: ermPermissionFn,
    }, {
        id: 'iam sensitivity popover chip',
        position: 400,
        component: PerimeterExposurePopoverIamSensitivityChipWidget,
        permission: ermPermissionFn,
    }, {
        id: 'waf protection popover chip',
        position: 500,
        component: PerimeterExposurePopoverWafProtectionChipWidget,
        permission: () => ermPermissionFn(WAF_PROTECTION_ACTIVE_FEATURE.key),
    }]);
}

function initializePerimeterExposureAssetPopoverItems() {
    InsightPopoverRegistry.addItems([{
        id: 'cves popover item',
        position: 100,
        component: Cves,
        permission: ermPermissionFn,
    }, {
        id: 'posture findings popover item',
        position: 200,
        component: PostureFindings,
        permission: ermPermissionFn,
    }, {
        id: 'security events popover item',
        position: 300,
        component: SecurityEvents,
        permission: ermPermissionFn,
    }]);
}

function initializePerimeterExposureAssetBadges() {
    InsightPopoverRegistry.addBadges([{
        id: 'business priority badge',
        component: BusinessPriorityBadge,
        position: 'nw',
        permission: ermPermissionFn,
    }, {
        id: 'security events badge',
        component: SecurityEventsBadge,
        position: 'ne',
        permission: ermPermissionFn,
    }]);
}

function initializeErmWidgetServices() {
    globalAddinContainer.addService(RISK_MANAGEMENT_WIDGETS_SERVICE_ID, new RiskManagementWidgetsService());
}

function initializeFindingsFieldConvertors() {
    const fieldConvertors: IFieldConvertor[] = [
        {
            sourceEntityKind: FieldEntityKind.ASSET,
            targetEntityKind: FieldEntityKind.FINDING,
            sourceFieldName: 'platform',
            targetFieldName: 'cloudAccountType',
            getTargetValue: (sourceValue: any) => {
                const vendor = getVendor(sourceValue);
                if (vendor) {
                    return String(vendor.assessmentVendorType);
                } else {
                    return '';
                }
            },
        },
        {
            sourceEntityKind: FieldEntityKind.ASSET,
            targetEntityKind: FieldEntityKind.FINDING,
            sourceFieldName: 'cloudAccountId',
            targetFieldName: 'cloudAccountId_calc',
        },
        {
            sourceEntityKind: FieldEntityKind.ASSET,
            targetEntityKind: FieldEntityKind.FINDING,
            sourceFieldName: 'type',
            targetFieldName: 'entityTypeByEnvironmentType',
            getTargetValue: (sourceValue: any): any => {
                const findingType = assetTypeToFindingType(sourceValue);
                return findingType || IGNORE_FIELD;
            },
        },
        {
            sourceEntityKind: FieldEntityKind.ASSET,
            targetEntityKind: FieldEntityKind.FINDING,
            sourceFieldName: 'organizationalUnitId',
        },
    ];
    FieldConvertorsRegistry.addFieldConvertors(fieldConvertors);
}

const setupErmData = async () => {
    await Promise.all([setupRiskData(), setupSecurityGraphData()]);
};

const setupInitialData = () => {
    globalAddinContainer.add(AFTER_LOGIN_EVENT, [{
        id: 'setup-erm-supported-assets',
        content: setupErmData,
        permission: ermPermissionFn,
    }]);
};

const initializeEnvironmentsColumnDefs = () => {
    const columnDefs: ETColDef<IEnvironment>[] = [{
        id: 'risk-score',
        position: 0,
        colId: 'customData|riskLevel',
        field: 'customData|riskLevel',
        headerName: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RISK.HEADER'),
        headerTooltip: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RISK.TOOLTIP'),
        initialSort: 'desc',
        sortable: true,
        width: 100,
        cellRenderer: EnvTableRiskCellRenderer,
        comparator: (a, b, nodeA, nodeB, isDescending) => {
            const getNodeValue = (node: IRowNode<IEnvironment>) => {
                let value = node.data?.['customData|riskScore'];
                if (value === undefined) { // this is a solution to ignore the undefined values when sorting
                    value = isDescending ? -1 : 999;
                }
                return value;
            };

            const valueA = getNodeValue(nodeA);
            const valueB = getNodeValue(nodeB);

            if (valueA === valueB) return 0;
            return (valueA > valueB) ? 1 : -1;
        },
        permission: ermPermissionFn,
    }];
    EnvironmentsTableRegistry.addColumnDefs(columnDefs);
};

const initializeEnvironmentPagesChips = () => {
    const awsChips: Addin<AwsEnvironmentAddingBase<EnvironmentChip>>[] = getRelevantRiskChipByVendor(Vendors.AWS, (data) => data.account.id);
    AwsEnvironmentAddinRegistry.addChips(awsChips);

    const azureChips: Addin<AzureEnvironmentAddingBase<EnvironmentChip>>[] = getRelevantRiskChipByVendor(Vendors.AZURE, (data) => data.account.id);
    AzureEnvironmentAddinRegistry.addChips(azureChips);

    const gcpChips: Addin<GcpEnvironmentAddingBase<EnvironmentChip>>[] = getRelevantRiskChipByVendor(Vendors.GCP, (data) => data.account.id);
    GcpEnvironmentAddinRegistry.addChips(gcpChips);
};

const getRelevantRiskChipByVendor = (vendor: string, getAccountId: (data: any)=> string ) => {
    const chips : Addin<any>[] = [
        {
            id: `${vendor}-environment-risk-chip`,
            position: 10,
            content: {
                id: `${vendor}-environment-risk-chip`,
                customLoadingLabel: i18n.t('ENVIRONMENT.CHIP_TOOLTIP_LOADING', { ns: I18nRiskNamespace }),
                getValue: async (data: any) => {
                    const riskData = await getRiskManagementService().getEnvironmentRisk(getAccountId(data));
                    const riskLevel = riskData?.riskLevel || RiskLevelServerEnum.Unknown;
                    const riskLevelInfo = getRiskLevelInfo(riskLevel);

                    return ({
                        label: riskLevelInfo?.displayText || '',
                        customColor: riskLevelInfo?.color,
                        leadingIconProps: { name: 'riskManagement4' },
                        tooltip: i18n.t('ENVIRONMENT.CHIP_TOOLTIP', { ns: I18nRiskNamespace }),
                    });
                },
            },
        }
    ];
    return chips;
};

const initializeEnvironmentsFilters = () => {
    const filterDefs = [
        {
            id: 'customData|riskLevel',
            content: function({ aggregations }: IEnvironmentsFilterParams) {

                return {
                    filterProps: {
                        initialData: aggregations['customData|riskLevel'],
                        key: 'customData|riskLevel',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.COLUMNS.RISK.HEADER'),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
            permission: ermPermissionFn,
        },
    ];
    EnvironmentsTableRegistry.addFilterAddins(filterDefs);
};

const initializeEnvironmentsTableData = () => {
    EnvironmentsTableRegistry.addCustomDataById([{
        id: 'add-env-table-risk',
        content: {
            customData: async () => {
                const allEnvironmentsRisk = await getRiskManagementService().getAllEnvironmentsRisk();
                return allEnvironmentsRisk.map((environmentRisk) => {
                    return ({
                        id: environmentRisk.id,
                        customData: {
                            riskScore: environmentRisk.riskScore,
                            riskLevel: environmentRisk.riskLevel,
                        },
                    });
                });
            },
        },
        permission: ermPermissionFn,
    }]);
};

function initializeServices() {
    globalAddinContainer.addService(RISK_MANAGEMENT_SERVICE_ID, new RiskManagementService());
    globalAddinContainer.addService(SENTRA_INTEGRATION_SERVICE_ID, new SentraIntegrationService());
    globalAddinContainer.addService(CYERA_INTEGRATION_SERVICE_ID, new CyeraIntegrationService());
    globalAddinContainer.addService(RISK_MANAGEMENT_URLS_SERVICE_ID, new ErmUrlsService());
}

export default function initialize() {
    initializeRoutes();
    initializeRulesetsColumnDefs();
    initializeRulesetsFiltersDefs();
    initializeAssetTypesColumnDefs();
    initializeMenu();
    initializeRiskDashboard();
    initializeRiskDashboardAsDefault();
    initializeRiskCategory();
    initializePerimeterExposureAssetPopoverItems();
    initializePerimeterExposureAssetPopoverChips();
    initializePerimeterExposureAssetBadges();
    initializeDetailsPaneItems();
    initializeProtectedAssetsPage();
    setupInitialData();
    initializeErmWidgetServices();
    initializeEnvironmentsColumnDefs();
    initializeEnvironmentPagesChips();
    initializeEnvironmentsFilters();
    initializeEnvironmentsTableData();
    initializeFindingsFieldConvertors();
    initializeIssues();
    initializeAutoActions();
    initializeSecurityControl();
    initializeIssueExclusion();
    initializeCveIgnoreList();
    initializeServices();
    initializeSentraIntegration();
    initializeActiveFeatures();
    initializeCyeraIntegration();
}
