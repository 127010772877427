import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FindingsFilterTypes as FilterTypes } from '../FindingsTable/Types/FindingsFilterTypes';
import { ALL_EVENTS_TABLE } from './AllEventsPage.const';
import { DEFAULT_RANGES_VALUES } from 'common/components/FilterPanel/DefaultFilters/DateFilter/DateFilter.consts';
import { createFavoritesFilter, createRecentlyUsedFilter } from 'common/utils/filterUtils';

// date filter with default date range as 24 hours
const getDateFilter = () => {
    return {
        id: FilterTypes.DATE_PICKER.id,
        filterProps: {
            defaultValue: DEFAULT_RANGES_VALUES.ONE_DAY,
        }
    };
};

export const getAllEventsTableFilters = (): IProtectedAssetFilter[] => [
    FilterTypes.ADD_FILTER,
    getDateFilter(),
    FilterTypes.FREE_TEXT,
    createRecentlyUsedFilter(ALL_EVENTS_TABLE),
    createFavoritesFilter(ALL_EVENTS_TABLE),
    FilterTypes.CLEAR_ALL,
    FilterTypes.PLATFORM,
    FilterTypes.ORGANIZATIONAL_UNITS,
    FilterTypes.ENVIRONMENT,
    FilterTypes.RULE_NAME,
    FilterTypes.SEVERITY,
    FilterTypes.ENTITY_TYPE,
    FilterTypes.RULESET_NAME,
    FilterTypes.REGION,
    FilterTypes.ACTION,
    FilterTypes.STATUS,
    FilterTypes.SHOW_ACKNOWLEDGED,
    FilterTypes.SHOW_EXCLUDED,
    FilterTypes.ORIGIN,
];
