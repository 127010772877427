import { SortingModel } from 'common/components/Findings/Findings.interface';
import { ICustomAggregation } from 'common/components/ProtectedAssets/datasource';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';
import { DOC_PLACEHOLDER_PART } from 'common/erm-components/custom/DocLinkButton/DocLinkButton.info';

export const ERM_URLS = {
    EXPOSURE_DASHBOARD: '/risk-management/external-exposure',
    DATA_SECURITY_DASHBOARD: '/risk-management/data-security',
    RULESETS: '/risk-management/rulesets',
    OVERVIEW_RISK_DASHBOARD: '/risk-management/dashboard',
    ERM_RISK_DASHBOARD: '/risk-management/dashboard-erm',
    ISSUES: '/risk-management/issues',
    AUTO_ACTIONS: '/risk-management/automated-actions',
    SECURITY_CONTROLS: '/risk-management/security-controls',
    CVE_IGNORE_LIST: '/risk-management/cve-ignore-list',
    MALWARE_IGNORE_LIST: '/risk-management/malware-ignore-list',
    ISSUE_EXCLUSIONS: '/risk-management/issue-exclusions',
    BUSINESS_PRIORITY: '/risk-management/business-priority',
    RISK_DATA: 'erm/groups/risk-data',
    SENTRA_INTEGRATION: 'erm/dspm/sentra-account',
    CYERA_INTEGRATION: 'erm/dspm/cyera-account',
    ACTIVE_FEATURES_MANAGER: '/risk-management/active-features-manager',
};

export const CONTEXT_FIELD_FULL_NAMES = {
    dataSensitivity: 'context|dataSensitivity',
    dataClassifications: 'context|dataClassifications',
    iamSensitivity: 'context|iamSensitivity',
    networkExposure: 'context|networkExposure',
    iamExposure: 'context|iamExposure',
    wafProtection: 'context|wafProtection',
    openSecurityIssues: 'context|openSecurityIssues',
};

export const ASSET_FIELD_NAMES = {
    riskScore: 'riskScore',
    type: 'type',
    riskScoreByType: 'riskScoreByType',
    businessPriority: 'businessPriority',
    organizationalUnitId: 'organizationalUnitId',
    platform: 'platform',
    cloudAccountId: 'cloudAccountId',
    cves: 'CVEs',
    secrets: 'secrets',
};

export const ALL_FILTER_FIELDS_NAMES = {
    ...ASSET_FIELD_NAMES,
    ...CONTEXT_FIELD_FULL_NAMES,
};

export const BUSINESS_PRIORITY_TYPES = {
    CROWN_JEWEL: 'Crown Jewel',
    HIGH_IMPORTANCE: 'High Importance',
    IMPORTANT: 'Important',
    MINOR_IMPORTANCE: 'Minor Importance',
};

export const DEFAULT_PROTECTED_ASSETS_SORT_MODEL: SortingModel = {
    direction: -1,
    fieldName: ASSET_FIELD_NAMES.riskScore,
};

export const DATA_SENSITIVITY_VALUES = {
    SENSITIVE: 'Sensitive',
    NOT_SENSITIVE: 'Not Sensitive',
};

export const NETWORK_EXPOSURE_VALUES = {
    PRIVATE: 'Private',
    PARTIAL: 'Partial',
    PARTIALLY_PUBLIC: 'Partially Public',
    PUBLIC: 'Public',
};

export const IAM_EXPOSURE_VALUES = {
    PRIVATE: 'Private',
    PARTIAL: 'Partial',
    PARTIALLY_PUBLIC: 'Partially Public',
    PUBLIC: 'Public',
};

export const ERM_OPTIONAL_AGGREGATION_FIELDS = [
    ASSET_FIELD_NAMES.riskScore,
    ASSET_FIELD_NAMES.riskScoreByType,
    ASSET_FIELD_NAMES.businessPriority,
    CONTEXT_FIELD_FULL_NAMES.networkExposure,
    CONTEXT_FIELD_FULL_NAMES.iamExposure,
    CONTEXT_FIELD_FULL_NAMES.dataSensitivity,
    CONTEXT_FIELD_FULL_NAMES.dataClassifications,
    CONTEXT_FIELD_FULL_NAMES.wafProtection,
    CONTEXT_FIELD_FULL_NAMES.openSecurityIssues
];

export const FULL_ERM_AGGREGATION: ICustomAggregation = {
    optionalAggregationFields: ERM_OPTIONAL_AGGREGATION_FIELDS,
    aggregationFields: undefined,
};

export const RISK_RANGES = {
    LOW: '0-4.9',
    CRITICAL: '9-10',
    HIGH: '7-8.9',
    MEDIUM: '5-6.9',
    HIGH_CRITICAL: '7-10',
};

export const PREDEFINED_FILTERS = {
    HIGH_CRITICAL_RISK: {
        name: ASSET_FIELD_NAMES.riskScore,
        value: RISK_RANGES.HIGH_CRITICAL,
    },
    HIGH_RISK: {
        name: ASSET_FIELD_NAMES.riskScore,
        value: RISK_RANGES.HIGH,
    },
    CRITICAL_RISK: {
        name: ASSET_FIELD_NAMES.riskScore,
        value: RISK_RANGES.CRITICAL,
    },
    NETWORK_EXPOSURE_PUBLIC: {
        name: CONTEXT_FIELD_FULL_NAMES.networkExposure,
        value: NETWORK_EXPOSURE_VALUES.PUBLIC,
    },
    IAM_EXPOSURE_PUBLIC: {
        name: CONTEXT_FIELD_FULL_NAMES.iamExposure,
        value: IAM_EXPOSURE_VALUES.PUBLIC,
    },
    DATA_SENSITIVITY_SENSITIVE: {
        name: CONTEXT_FIELD_FULL_NAMES.dataSensitivity,
        value: DATA_SENSITIVITY_VALUES.SENSITIVE,
    },
    DATA_SENSITIVITY_NOT_SENSITIVE: {
        name: CONTEXT_FIELD_FULL_NAMES.dataSensitivity,
        value: DATA_SENSITIVITY_VALUES.NOT_SENSITIVE,
    },
};

export const ERM_CONSTS = {
    ERM_RULESET_NAMES: ['AWS', 'AZURE', 'GCP', 'GOOGLE', 'KUBERNETES'],
    ERM_PROTECTED_ASSETS_URL: 'risk-management/protected-assets',
    ERM_PROTECTED_ASSETS_EXTRA_PARAMS: { isRiskManagementPage: true },
    DASHBOARD_DEFAULT_FILTERS_FIELDS: {
        TOTAL_ASSETS: [{ name: ASSET_FIELD_NAMES.riskScore, value: RISK_RANGES.HIGH_CRITICAL }],
        NETWORK_EXPOSURE: [{ name: ASSET_FIELD_NAMES.riskScore, value: RISK_RANGES.HIGH_CRITICAL }, {
            name: CONTEXT_FIELD_FULL_NAMES.networkExposure,
            value: NETWORK_EXPOSURE_VALUES.PUBLIC,
        }],
        IAM_EXPOSURE: [{ name: ASSET_FIELD_NAMES.riskScore, value: RISK_RANGES.HIGH_CRITICAL }, {
            name: CONTEXT_FIELD_FULL_NAMES.iamExposure,
            value: IAM_EXPOSURE_VALUES.PUBLIC,
        }],
        DATA_SENSITIVITY: [{ name: ASSET_FIELD_NAMES.riskScore, value: RISK_RANGES.HIGH_CRITICAL }, {
            name: CONTEXT_FIELD_FULL_NAMES.dataSensitivity,
            value: DATA_SENSITIVITY_VALUES.SENSITIVE,
        }],
        CVES: [
            { name: ASSET_FIELD_NAMES.riskScore, value: RISK_RANGES.HIGH_CRITICAL },
            { name: ASSET_FIELD_NAMES.cves, value: FindingSeverityServerEnum.Critical },
            { name: ASSET_FIELD_NAMES.cves, value: FindingSeverityServerEnum.High }
        ],
        SECRETS: [
            { name: ASSET_FIELD_NAMES.riskScore, value: RISK_RANGES.HIGH_CRITICAL },
            { name: ASSET_FIELD_NAMES.secrets, value: FindingSeverityServerEnum.Critical },
            { name: ASSET_FIELD_NAMES.secrets, value: FindingSeverityServerEnum.High }
        ],
    },
    DATA_SENSITIVITY_FIELDS: [
        PREDEFINED_FILTERS.DATA_SENSITIVITY_SENSITIVE,
        PREDEFINED_FILTERS.DATA_SENSITIVITY_NOT_SENSITIVE,
    ]
};

export const I18nRiskNamespace = 'riskManagement';

export const ERM_CACHE_TAGS = {
    TOP_REMEDIATION_ACTIONS: 'topRemediationActions',
};

export enum VULNERABILITIES_INNER_TAB_INDEX {
    CVES = 0,
    THREATS = 1,
    SECRETS = 2,
    REMEDIATION_SUMMARY = 3,
}

export const ORIGIN_TYPES = {
    COMPLIANCE_ENGINE: 0,
    INTELLIGENCE: 1,
    SERVERLESS_RUNTIME_PROTECTION: 2,
    CIEM: 10,
};

export enum RULESET_NAMES {
    VULNERABLE_DEPENDENCY = 'VulnerableDependency',
    CREDENTIALS_USAGE = 'CredentialsUsage',
}

export const SHOW_DRAWER_COL_TYPE = 'show-drawer';

export enum DocUrlEnum {
    DATA_SENSITIVITY = `https://sc1.checkpoint.com/documents/${DOC_PLACEHOLDER_PART}/Documentation/Risk-Management/Data-Sensitivity.htm`,
    SENTRA = `https://sc1.checkpoint.com/documents/${DOC_PLACEHOLDER_PART}/Documentation/Integrations/Sentra-Integration.htm`,
}