import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../consts';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import Stack from 'common/design-system/components-v2/Stack';
import { IssuesAndEventsStyled } from './SecurityIssuesAndEvents.styled';
import { IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { areFiltersEqual, assetFilterFieldsToServerInputFilterDetails } from '../../../Issues/Issues.utils';
import { TopIssues } from '../../RiskDashboard/SecurityIssues/TopIssues/TopIssues';
import DataAssetSecurityEvents from './DataAssetSecurityEvents/DataAssetSecurityEvents';

const SecurityIssuesAndEvents : React.FC<{ filterValues?: IFiltersValues }> = ({ filterValues }) => {
    const [issuesFilterDetails, setIssuesFilterDetails] = useState<IServerInputFilterDetails>();
    const { t } = useTranslation(I18nRiskNamespace);

    useEffect(() => {
        if (filterValues) {
            const externalFilter = assetFilterFieldsToServerInputFilterDetails(filterValues || {}, true);
            if (!issuesFilterDetails || !areFiltersEqual(issuesFilterDetails, externalFilter)) {
                setIssuesFilterDetails(externalFilter);
            }
        }
    }, [filterValues, issuesFilterDetails]);

    return (
        <Stack direction="column">
            <IssuesAndEventsStyled.TopDiv direction="row" fullWidth spacing={6}>
                <TopIssues filterDetails={issuesFilterDetails} title={t('DATA_SECURITY_DASHBOARD.SECURITY_ISSUES_AND_EVENTS.TOP_SECURITY_ISSUES_ON_DATA_ASSETS')}/>
                <DataAssetSecurityEvents filterValues={filterValues}/>
            </IssuesAndEventsStyled.TopDiv>
        </Stack>
    );
};

export default SecurityIssuesAndEvents;