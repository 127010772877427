import i18n, { validLanguages } from 'common/services/translations/translations';

export const i18nEventsNamespace = 'events';
export type EventsTermTypes = 'actions' | 'translation' | 'exports';
const termsFolders: Array<EventsTermTypes> = ['actions', 'translation', 'exports'];

export const getEventsNamespace = (term: EventsTermTypes) => `${i18nEventsNamespace}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: EventsTermTypes; }>>((acc, language) => {
    const filesPAth = termsFolders.map(term => ({ path: `${language}/${term}`, language, term }));
    return [...acc, ...filesPAth];
}, []);

export const initializeI18nEvents = async () => {
    await Promise.all(jsonFilesPaths.map(async (file) => {
        try {
            const contents = await require(`./languages/${file.path}.json`);
            i18n.addResourceBundle(file.language, getEventsNamespace(file.term), contents);
        // eslint-disable-next-line
        } catch (error) { }
    }));
};