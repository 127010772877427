import React from 'react';
import { Stack } from 'common/design-system/components-v2';
import { IEvent } from 'common/module_interface/events/Events';
import { EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';

interface OverviewTabProps {
    event: IEvent;
}
const OverviewTab: React.FC<OverviewTabProps> = ({ event }) => {

    const relevantOverviewTabs = React.useMemo(() => {
        const allOverviewSections = EventsDrawerRegistry.getOverviewSections();
        return allOverviewSections.filter((section) => section.isRelevant?.(event) ?? true);
    }, [event]);

    return (
        <Stack spacing={8}>
            {relevantOverviewTabs.map((section, index) => {
                const Component = section.getValue(event);
                return <Component key={index} finding={event} />;
            })}
        </Stack>
    );
};


export default OverviewTab;
