import styled, { css } from 'styled-components';
import { Stack, Typography } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
`;

const BodyDiv = styled(Stack)`
  width: 100px;
`;

const Pie = styled(Stack)`
`;

const CounterDiv = styled(Typography)<{ isLink: boolean }>`
  font-size: 32px;
  ${({ isLink, theme }) => isLink && css`
    &:hover {
      cursor: pointer;
      color: ${theme.palette.onSurface.brandPrimary};
      text-decoration: underline;
    }
  `}
`;

export const BoxStyle = {
    TopDiv,
    BodyDiv,
    Pie,
    CounterDiv,
};
