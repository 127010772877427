import React, { useEffect, useState } from 'react';
import {
    SnsStyled,
    SnsNameWrapper,
    SnsEndpointUrlWrapper,
    SnsEndpointUrlInputWrapper
} from './Sns.styled';
import { Stack, Button, Typography, Input, CopiableLine, Link, Alert } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations, COMPONENT_TEXT_COLOR, SUBMIT_STATUS_RESPONSE } from 'common/module_interface/settings/integrations/consts';
import { getIntegrationsService, getUserService } from 'common/interface/services';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import { getCheckpointUrl } from 'common/components/Forms/urls';

interface IGenericConfigurationProps extends IConfigurationContainerProps{
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration{
    configurationObj: IGenericWebhookConfigurationObj
}

export interface IGenericWebhookConfigurationObj {
    TopicArn: string,
}

const SnsComponent : React.FC<IGenericConfigurationProps> = ({ onConfigurationChangeCallBack ,configuration, onConfigurationSaved, viewMode }) => {
    const { t } = useTranslation( I18nIntegrations );
    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [topicArnInputConfig, SetTopicArnInputConfig] = useState<string>(configuration?.configurationObj.TopicArn || '');
    const [endpointUrlTestButtonError, setEndpointUrlTestButtonError] = useState<string>('');
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);
    const [d9CloudAccount, setD9CloudAccount] = useState<string>('');

    const discardChangesButtonHidden = Boolean(idConfiguration);

    const getCloudAccount = async () => {
        const { d9AwsAccountNumber } = await getUserService().getCloudguardAccount();
        setD9CloudAccount(d9AwsAccountNumber);
    };

    useEffect(() => {
        getCloudAccount();
    },[]);
    const viewOnly = Boolean(viewMode);

    useEffect(() => {
        checkChangesMade();
    }, [nameInputConfig, topicArnInputConfig, idConfiguration]);

    const checkMissingRequiredInput = () => {
        const variableNotChanged = (
            nameInputConfig === '' ||
            topicArnInputConfig === ''
        );
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected = (
            nameInputConfig !== configuration?.name ||
            topicArnInputConfig !== configuration?.configurationObj.TopicArn
        );
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setNameInputConfig(textInput);
    };

    const handleTopicArnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndpointUrlTestButtonError('');
        const textInput = event.target.value;
        SetTopicArnInputConfig(textInput);
    };

    const sendTestMessage = async () => {
        setEndpointUrlTestButtonError('');
        try {
            const testButtonResponse = await getIntegrationsService().testSnsService(topicArnInputConfig);
            setEndpointUrlTestButtonError( testButtonResponse || t('GENERAL.NETWORK_ERROR'));
            setResponseMessageSuccess(true);
            return true;
        } catch (error:any) {
            setEndpointUrlTestButtonError( error || t('GENERAL.NETWORK_ERROR'));
            setResponseMessageSuccess(false);
            return false;
        }
    };

    const handleDiscardChanges = () => {
        setNameInputConfig(configuration?.name || '');
        SetTopicArnInputConfig(configuration?.configurationObj.TopicArn || '');
    };

    const testConfigurationData = async () => {
        return await sendTestMessage();
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        const testPassed = await testConfigurationData();
        if (testPassed) {
            try {
                setEndpointUrlTestButtonError('');
                const resp = onConfigurationSaved && await onConfigurationSaved(nameInputConfig,
                    { topicArn: topicArnInputConfig },
                    idConfiguration);
                resp?.id && setIdConfiguration(resp.id);
                onConfigurationChangeCallBack && onConfigurationChangeCallBack();
                setIsButtonDisabled(true);
                return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
            } catch (error:any) {
                setResponseMessageSuccess(false);
                return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
            }
        } else {
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_TEST };
        }
    };

    return (
        <SnsStyled data-aid={'sns-component'}>
            <SnsNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input type="text" data-aid='nameInput' value={nameInputConfig} placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange} autoFocus={true} disabled={viewOnly}/>
            </SnsNameWrapper>
            <SnsEndpointUrlWrapper>
                <SnsEndpointUrlInputWrapper>
                    <Typography variant='subtitleLg'>{t('CONFIGURATIONS.SNS.SNS_TOPIC_ARN')}</Typography>
                    <Input type="text" data-aid='urlInput' value={topicArnInputConfig} fullWidth={true}
                        onChange={handleTopicArnChange} disabled={viewOnly}/>
                </SnsEndpointUrlInputWrapper>
                <Stack margin={[0,0,0,2]} >
                    <Button size='medium' color='normal'
                        onClick={testConfigurationData} active={true} loading={false}
                        disabled={ viewOnly || !topicArnInputConfig }
                    >{t('CONFIGURATIONS.SNS.SEND_TEST_MESSAGE')}
                    </Button>
                </Stack>
            </SnsEndpointUrlWrapper>
            <Stack margin={[0,6]}>
                <Typography elementType='h5' color={responseMessageSuccess ? COMPONENT_TEXT_COLOR.SUCCESS : COMPONENT_TEXT_COLOR.ALERT} >{endpointUrlTestButtonError}</Typography>
                <Stack margin={[1,0]} direction="row" alignItems="flex-start">
                    <Alert
                        type={'info'}
                    >
                        <Stack spacing={1}>
                            <Typography variant="body500">Information</Typography>
                            <Typography variant="bodyLg">{t('CONFIGURATIONS.SNS.SNS_TOPIC_CG_ACCOUNT_INFO')}
                                <CopiableLine value={d9CloudAccount}>
                                    <strong>{d9CloudAccount ? ` ${d9CloudAccount}` : '-'}</strong>
                                </CopiableLine><br/>
                                <Link externalUrl={getCheckpointUrl('SNS_EVENTS_INTEGRATION_HOW_TO')}>
                                    <Typography color="brandPrimary">{t('CONFIGURATIONS.SNS.READ_MORE')}</Typography>
                                </Link>
                            </Typography>
                        </Stack>
                    </Alert>
                </Stack>
            </Stack>
            { GenericCancelSubmitButtonWrapper({ handleDiscardChanges ,discardChangesButtonHidden, isButtonDisabled, submitForm, viewOnly, responseMessageSuccess }) }

        </SnsStyled>
    );
};

export default SnsComponent;