import i18n from 'i18next';
import { I18nRiskNamespace } from '../../consts';
import { IssueSeverityCellRender } from '../../renderers/IssueSeverityCellRender';
import { SECURITY_CONTROL_FIELD_NAMES } from './SecurityControls.consts';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { SecurityControlsNameCellRender } from './SecurityControlsRenderers/SecurityControlsNameCellRender';
import SecurityControlPlatformsCellRenderer from './SecurityControlsRenderers/SecurityControlPlatformsCellRenderer';
import SecurityControlSystemLabelsCellRenderer
    from './SecurityControlsRenderers/SecurityControlSystemLabelsCellRenderer';

export const getSecurityControlsColumns = (): CGColDef[] => {
    return [
        {
            colId: SECURITY_CONTROL_FIELD_NAMES.NAME,
            field: SECURITY_CONTROL_FIELD_NAMES.NAME,
            sortable: true,
            headerName: i18n.t('SECURITY_CONTROLS.TABLE.COLUMNS.NAME.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: SecurityControlsNameCellRender,
            minWidth: 130,
            flex: 6,
            sort: 'asc',
        },
        {
            colId: SECURITY_CONTROL_FIELD_NAMES.SEVERITY,
            field: SECURITY_CONTROL_FIELD_NAMES.SEVERITY,
            sortable: true,
            headerName: i18n.t('SECURITY_CONTROLS.TABLE.COLUMNS.SEVERITY.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: IssueSeverityCellRender,
            minWidth: 80,
            flex: 3,
        },
        {
            colId: SECURITY_CONTROL_FIELD_NAMES.PLATFORMS,
            field: SECURITY_CONTROL_FIELD_NAMES.PLATFORMS,
            headerName: i18n.t('SECURITY_CONTROLS.TABLE.COLUMNS.PLATFORMS.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: SecurityControlPlatformsCellRenderer,
            minWidth: 180,
            flex: 6,
        },
        {
            colId: SECURITY_CONTROL_FIELD_NAMES.SYSTEM_LABELS,
            field: SECURITY_CONTROL_FIELD_NAMES.SYSTEM_LABELS,
            headerName: i18n.t('SECURITY_CONTROLS.TABLE.COLUMNS.SYSTEM_LABELS.HEADER', { ns: I18nRiskNamespace }),
            cellRenderer: SecurityControlSystemLabelsCellRenderer,
            minWidth: 230,
            flex: 8,
        },
    ];
};
