import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAzureOnboardingType, getManualOnBoardingFlow, setManualOnBoardingFlow, } from '../helpers/azure.reducer';
import { AzureOnboardingType } from '../helpers/azure.interface';
import { Stack, Typography, Alert } from 'common/design-system/components-v2';

const ManualOnboardingBanner: FC = () => {
    const isManualOnBoardingFlow = useSelector(getManualOnBoardingFlow);
    const onboardingType = useSelector(getAzureOnboardingType);
    const dispatch = useDispatch();

    function showOnBoardingAlert() {
        return window.location.pathname === '/v2/azure-onboarding' && onboardingType !== AzureOnboardingType.org;
    }

    return (
        <>
            {showOnBoardingAlert() && (
                <Alert type={'info'} className='cp-alert' hideStatusIcon>
                    <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="body">
                            <Trans
                                i18nKey={ isManualOnBoardingFlow
                                    ? 'ON_BOARDING.SWITCHING_TO_NEW_WIZARD_TEMPLATE'
                                    : 'ON_BOARDING.SWITCHING_TO_NEW_LEGACY_TEMPLATE'
                                }>
                                <span />
                                <Link onClick={() => dispatch(setManualOnBoardingFlow(!isManualOnBoardingFlow))} to='/azure-onboarding' />
                            </Trans>
                        </Typography>
                    </Stack>
                </Alert>
            )}
        </>
    );
};

export default ManualOnboardingBanner;
