import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';

interface IAssetDetailsState {
    selectedEntity: ICloudEntityData | null;

}

const initialState: IAssetDetailsState = {
    selectedEntity: null,
};

export const assetDetailsSlice = createSlice({
    name: 'protectedAssetDetails',
    initialState,
    reducers: {
        setSelectedEntity: (state: IAssetDetailsState, action: PayloadAction<ICloudEntityData | null>) => {
            state.selectedEntity = action.payload;
        },
    },
});
export const { setSelectedEntity } = assetDetailsSlice.actions;

export const getSelectedEntity = (state: RootState): ICloudEntityData | null => {
    return state.protectedAssetDetails.selectedEntity;
};

export default assetDetailsSlice.reducer;
