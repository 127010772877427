import { Button, Modal, Select, TextField, Tooltip } from '@dome9/berries/react-components';
import { ISelectOption } from 'common/interface/general';
import { getStoreService } from 'common/interface/services';
import { IDashboard } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { addSectionInStore, clearSectionImagesToPrint, getAllDashboards, getDefaultDashboardId, getFavoriteDashboardsIds, getSelectedDashboard, setIsPrinting } from 'modules/overview/Overview.reducer';
import { goToDashboardPage, updateDashboardInStore } from 'modules/overview/Utils';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { createNewDashboard, updateDashboardInServer } from '../../Api';
import { i18nOverviewNamespace } from '../../initialize.i18n';
import { IMiniMenuItem } from '../MiniMenu';
import { handleChangeDefaultDashboard, handleDeleteDashboard, handlePinnedDashboardChange, handleRenameDashboard } from './DashboardActionsFunctions';
import ExportToPdfModal from './ExportToPdf/ExportToPdfModal';
import { Checkbox, Spinner } from 'common/design-system/components-v2';
import { SingleValue } from 'react-select';
import { DebugActionsModal } from './DebugActionsModal';
import { DebugActiveFeaturesMap, isAfEnabled } from 'common/utils/debugUtils';

interface IEditDashboardProps {
    selectedDashboard?: IDashboard
}

interface IModalProps {
    submitAction: ()=> void;
    title: string;
    content: JSX.Element;
}

const DashboardActions: React.FC<IEditDashboardProps> = () => {
    const { t } = useTranslation(i18nOverviewNamespace);
    const { dispatch } = getStoreService().getReduxTools();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProps, setModalProps] = useState({} as IModalProps);
    const [isSubmitButtonDisabled,setIsSubmitButtonDisabled] = useState(false);
    const [modalErrorMessage,setModalErrorMessage] = useState(null as string | null);
    const allDashboards = useSelector(getAllDashboards);
    const favoriteDashboardIds = useSelector(getFavoriteDashboardsIds);
    const defaultDashboardId = useSelector(getDefaultDashboardId);
    const selectedDashboard = useSelector(getSelectedDashboard);
    const [isPublic, setIsPublic] = useState(Boolean(selectedDashboard.isPublic));
    const [isPinned, setIsPinned] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [selectOptions, setSelectOptions] = useState([] as ISelectOption[]);
    const [submitButtonText, setSubmitButtonText] = useState('DASHBOARD.SAVE');
    const [isExportToPdfModalOpen, setIsExportToPdfModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const container: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const [widthOfContainer, setWidthOfContainer] = useState(0);
    const [displayHiddenWidgets, setDisplayHiddenWidgets] = useState(selectedDashboard.displayHiddenWidgets);
    const [isOpenDebugActionsDialog, setIsOpenDebugActionsDialog] = useState<boolean>(false);

    const openModal = ()=>{
        setIsModalOpen(true);
        setIsSubmitButtonDisabled(false);
        setModalErrorMessage(null);
    };

    const checkTextInput = (inputText: string) =>{
        if(!inputText){
            setIsSubmitButtonDisabled(true);
            setModalErrorMessage(t('DASHBOARD.MESSAGES.DASHBOARD_NAME_NOT_EMPTY'));
        } else if(allDashboards.find(item=> item.name?.toLocaleLowerCase() === inputText?.toLocaleLowerCase())){
            setIsSubmitButtonDisabled(true);
            setModalErrorMessage(t('DASHBOARD.MESSAGES.IS_DUPLICATED_OR_NOT_CHANGED', { inputText: inputText }) );
        } else {
            setIsSubmitButtonDisabled(false);
            setModalErrorMessage(null);
        }
    };

    const isNameDuplicate = (inputText: string)=> selectedDashboard.sections?.find(item=> item.title?.toLocaleLowerCase() === inputText?.toLocaleLowerCase());

    const checkSectionNameInput = (inputText: string) =>{
        if(!inputText){
            setIsSubmitButtonDisabled(true);
            setModalErrorMessage(t('DASHBOARD.MESSAGES.SECTION_NAME_EMPTY'));
        } else if(isNameDuplicate(inputText)){
            setIsSubmitButtonDisabled(true);
            setModalErrorMessage(t('DASHBOARD.MESSAGES.IS_DUPLICATED_OR_NOT_CHANGED', { inputText: inputText }) );
        } else {
            setIsSubmitButtonDisabled(false);
            setModalErrorMessage(null);
        }
    };

    const menuItems: IMiniMenuItem[] = [
        {
            dataAid: 'add-dashboard',
            title: t('DASHBOARD.ADD_DASHBOARD.TITLE'),
            icon: 'plus',
            onClick: ()=> {
                setIsModalOpen(true);
                setIsSubmitButtonDisabled(true);
                setSubmitButtonText('DASHBOARD.SAVE');
                const newDashboard: Partial<IDashboard> = {
                    name: '',
                    displayHiddenWidgets: true,
                    sections: [{
                        title: t('SECTIONS.TITLE_NEW_SECTION'),
                        widgets: [],
                        position: 0,
                        isExpanded: true,
                        id: v4(),
                        dashboardId: v4()
                    }],
                };
                setModalProps(
                    {
                        title: t('DASHBOARD.ADD_DASHBOARD.TITLE'),
                        content:
                            <>
                                <TextField
                                    onChange={(e)=> {
                                        const inputText = e.target.value;
                                        checkTextInput(inputText);
                                        newDashboard.name = inputText;
                                    }}
                                    value={newDashboard?.name}
                                    placeholder={t('DASHBOARD.ADD_DASHBOARD.PLACEHOLDER')}
                                />
                                <Checkbox label="Public" onChange={(e)=> {newDashboard.isPublic = e.target.checked;}}></Checkbox>
                            </>,
                        submitAction: async ()=>{
                            const newSavedDashboard = {
                                isPublic: newDashboard.isPublic,
                                name: newDashboard.name,
                                sections: newDashboard.sections,
                            };
                            try {
                                const newDashboardResponse = await createNewDashboard(newSavedDashboard);
                                if(newDashboardResponse && newDashboardResponse.length > 0) {
                                    goToDashboardPage(newDashboardResponse[0]);
                                }
                            } finally {
                                setIsModalOpen(false);
                            }

                        }
                    }
                );
            }
        },
        {
            dataAid: 'add-section',
            title: t('SECTIONS.ADD_NEW_SECTION'),
            icon: 'add-section',
            disabled: selectedDashboard.isLocalDashboard,
            onClick: ()=> {
                const newSectionName = isNameDuplicate(t('SECTIONS.TITLE_NEW_SECTION'))? `${t('SECTIONS.TITLE_NEW_SECTION')} (${t('DASHBOARD.NEW')})`: t('SECTIONS.TITLE_NEW_SECTION');
                checkSectionNameInput(newSectionName);
                setSubmitButtonText('DASHBOARD.SAVE');
                setIsModalOpen(true);
                const clonedDashboard = deepCloneObject(selectedDashboard);
                const newSection = {
                    title: newSectionName,
                    widgets: [],
                    position: clonedDashboard.sections.length,
                    id: v4(),
                    dashboardId: selectedDashboard.id,
                    isExpanded: true
                };
                setModalProps(
                    {
                        title: t('SECTIONS.ADD_NEW_SECTION'),
                        content:
                            <>
                                <TextField
                                    onChange={(e)=> {
                                        const inputText = e.target.value;
                                        newSection.title = inputText;
                                        checkSectionNameInput(inputText);
                                    }}
                                    value={newSection?.title}
                                />
                            </>,
                        submitAction: async ()=>{
                            clonedDashboard.sections.push(newSection);
                            dispatch(addSectionInStore(newSection));
                            setIsModalOpen(false);
                            await updateDashboardInServer(clonedDashboard, false);
                        }
                    }
                );
            },
        },
        {
            dataAid: 'rename-dashboard',
            title: t('DASHBOARD.RENAME_DASHBOARD'),
            disabled: selectedDashboard.isLocalDashboard,
            icon: 'edit',
            onClick: ()=> {
                openModal();
                setSubmitButtonText('DASHBOARD.RENAME');
                const clonedDashboard = deepCloneObject(selectedDashboard);
                clonedDashboard.name = `${clonedDashboard.name} (${t('DASHBOARD.RENAMED')})`;
                checkTextInput(clonedDashboard.name);
                setModalProps(
                    {
                        title: t('DASHBOARD.RENAME_DASHBOARD'),
                        content:
                        <div>
                            <TextField
                                onChange={(e)=> {
                                    const textInput = e.target.value;
                                    clonedDashboard.name = textInput;
                                    checkTextInput(textInput);
                                }}
                                value={clonedDashboard?.name}
                            />

                        </div>
                        ,
                        submitAction: async ()=>{
                            await handleRenameDashboard(clonedDashboard.name);
                            setIsModalOpen(false);
                        }
                    }
                );
            }
        },
        {
            dataAid: 'clone-dashboard',
            title: t('DASHBOARD.CLONE_DASHBOARD'),
            icon: 'duplicate',
            onClick: ()=> {
                setSubmitButtonText('DASHBOARD.CLONE');
                setIsModalOpen(true);
                const clonedDashboard: IDashboard = deepCloneObject(selectedDashboard);
                const newName = `${clonedDashboard?.name} (${t('DASHBOARD.CLONED')})`;
                checkTextInput(newName);
                clonedDashboard.name = newName;
                if(allDashboards.find(item=> item.name?.toLocaleLowerCase() === newName?.toLocaleLowerCase())){
                    clonedDashboard.name = `${newName} (${t('DASHBOARD.CLONED')})`;
                }
                clonedDashboard.sections?.forEach((sectionItem) => {
                    sectionItem.isExpanded = true;
                });
                setModalProps(
                    {
                        title: t('DASHBOARD.CLONE_DASHBOARD'),
                        content:
                            <>
                                <TextField
                                    onChange={(e)=> {
                                        clonedDashboard.name = e.target.value;
                                        checkTextInput(e.target.value);
                                    }}
                                    value={clonedDashboard?.name}
                                />
                                <Checkbox label="Public" onChange={(e)=> {clonedDashboard.isPublic = e.target.checked;}}></Checkbox>
                            </>,
                        submitAction: async ()=>{
                            const newDashboard = {
                                isPublic: clonedDashboard.isPublic,
                                name: clonedDashboard.name,
                                sections: clonedDashboard.sections,
                            };
                            try {
                                const newDashboardResponse = await createNewDashboard(newDashboard);
                                if(newDashboardResponse && newDashboardResponse.length > 0) {
                                    goToDashboardPage(newDashboardResponse[0]);
                                }
                            } finally {
                                setIsModalOpen(false);
                            }

                        }
                    }
                );
            }
        },
        {
            dataAid: 'delete-dashboard',
            title: t('DASHBOARD.DELETE_DASHBOARD.TITLE'),
            disabled: selectedDashboard.isLocalDashboard,
            icon: 'delete',
            onClick: ()=> {
                openModal();
                setSubmitButtonText('DASHBOARD.DELETE');
                setModalProps(
                    {
                        title: t('DASHBOARD.DELETE_DASHBOARD.TITLE'),
                        content:
                            <Trans components={{ b: <strong /> }}>
                                {t('DASHBOARD.DELETE_DASHBOARD.MESSAGE', { dashboardName: selectedDashboard.name })}
                            </Trans>,
                        submitAction: async ()=>{
                            setIsLoading(true);
                            await handleDeleteDashboard(selectedDashboard.id, true);
                            setIsLoading(false);
                            setIsModalOpen(false);
                        }
                    }
                );
            }
        },
        {
            dataAid: 'export-to-pdf',
            title: t('DASHBOARD.EXPORT_TO_PDF.TITLE'),
            icon: 'export',
            onClick: ()=> {
                dispatch(clearSectionImagesToPrint());
                setIsExportToPdfModalOpen(true);
                setIsModalOpen(false);
                dispatch(setIsPrinting(true));
            }
        },
    ];

    const handlePublicChange = (_isPublic: boolean) => {
        const selectedDashboardClone = deepCloneObject(selectedDashboard);
        selectedDashboardClone.isPublic = _isPublic;
        updateDashboardInServer(selectedDashboardClone);
        setIsPublic(_isPublic);
    };


    const handleSelectDashboardChange =(dashboardName: string)=>{
        const selectedOptionDashboard = allDashboards.find(dashboard => dashboard.name === dashboardName);
        selectedOptionDashboard && goToDashboardPage(selectedOptionDashboard);
    };

    const handleCloseExportModal = () => {
        setIsExportToPdfModalOpen(false);
    };

    const handleShowWidgetsWithNoDataChange = (showWidgetsWithNoData: boolean) => {
        const _selectedDashboardClone = deepCloneObject(selectedDashboard);
        _selectedDashboardClone.displayHiddenWidgets = showWidgetsWithNoData;
        setDisplayHiddenWidgets(showWidgetsWithNoData);
        updateDashboardInServer(_selectedDashboardClone);
        updateDashboardInStore(_selectedDashboardClone);
    };


    useEffect(()=>{
        const _selectOptions = allDashboards.map((dashboard) => { return { label: dashboard.name, value: dashboard.name, icon: { name: dashboard.isLocalDashboard? 'cloudGuard': 'user' } };});
        const _isDefault = defaultDashboardId === selectedDashboard.id;
        const _isPinned = favoriteDashboardIds?.includes(selectedDashboard.id);
        const _widthOfContainer = container?.current?.clientWidth;
        if(typeof _widthOfContainer === 'number'){
            setWidthOfContainer(_widthOfContainer);
        }
        setSelectOptions(_selectOptions);
        setIsPinned(_isPinned);
        setIsDefault(_isDefault);


    },[selectedDashboard, favoriteDashboardIds, defaultDashboardId, allDashboards]);

    const tooltipBreakPoint = 1200;

    const onDebugClickDefault = (e: any) => {
        if (isAfEnabled(DebugActiveFeaturesMap.DEBUG_ACTIONS_ACTIVE_FEATURE.key) && e && e.altKey && e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            setIsOpenDebugActionsDialog(true);
        }
    };

    return (
        <>
            <DebugActionsModal isOpen={isOpenDebugActionsDialog} onClose={() => setIsOpenDebugActionsDialog(false)} />
            <div className="flex flex-1 justify-between">
                <div className="flex flex-1 ml-auto @container/dashboard-toolbar" ref={container}>
                    {menuItems.map(button =>{
                        return(
                            <Tooltip disabled={widthOfContainer > tooltipBreakPoint} key={button.title} content={button.title} placement='bottom'>
                                <Button
                                    dataAid={button.dataAid}
                                    key={button.title}
                                    icon={button.icon}
                                    disabled={button.disabled}
                                    className='btn--integrated'
                                    onClick={()=> button.onClick && button.onClick()}>
                                    <div className={'hidden ml-5 @[1200px]/dashboard-toolbar:block'}>
                                        {button.title}
                                    </div>
                                </Button>
                            </Tooltip>

                        );
                    })}

                    <Checkbox
                        label={t('DASHBOARD.DEFAULT')}
                        checked={isDefault}
                        onMouseDown={onDebugClickDefault}
                        onChange={()=>{ handleChangeDefaultDashboard(selectedDashboard.id);}}
                        className='mr-7 ml-6'
                    />
                    <Checkbox
                        label={t('DASHBOARD.PUBLIC')}
                        checked={isPublic || selectedDashboard.isLocalDashboard || false}
                        onChange={(e) =>{ handlePublicChange(e.target.checked);}}
                        className='mr-7'
                        disabled={selectedDashboard.isLocalDashboard}
                    />
                    <Tooltip placement='bottom' content={t('DASHBOARD.PINNED.TOOLTIP')}>
                        <Checkbox
                            className='mr-7'
                            label={t('DASHBOARD.PINNED.TITLE')}
                            checked={selectedDashboard.isPinned || isPinned}
                            disabled={selectedDashboard.isLocalDashboard}
                            onChange={() =>{ handlePinnedDashboardChange(selectedDashboard.id); }}
                        />
                    </Tooltip>
                    <Checkbox
                        label={t('DASHBOARD.SHOW_HIDDEN_WIDGETS')}
                        checked={displayHiddenWidgets || selectedDashboard.isLocalDashboard || false}
                        disabled={selectedDashboard.isLocalDashboard}
                        onChange={(event) =>{ handleShowWidgetsWithNoDataChange(event.target.checked); }}
                    />
                </div>

                <div className="w-96 ml-6 grow-0">
                    <Select
                        required={false}
                        value={selectOptions.filter(option => option.value === selectedDashboard?.name)}
                        onChange={(option: SingleValue<ISelectOption>) => option?.value && handleSelectDashboardChange(option.value)}
                        closeMenuOnSelect={true}
                        placeholder={t('DASHBOARD.LOADING')}
                        isSearchable={true}
                        options={selectOptions}
                    />
                </div>
            </div>
            <ExportToPdfModal isExportModalOpen={isExportToPdfModalOpen} closeModal={handleCloseExportModal} />
            <Modal.ModalDialog isOpen={isModalOpen} onRequestClose={()=> setIsModalOpen(false)} width={'md'} shouldCloseOnOverlayClick>
                <Modal.ModalHeader title={modalProps.title}
                    onClose={()=>setIsModalOpen(false)} />
                <Modal.ModalContent>
                    <>
                        {modalProps.content}
                        <div className='text-danger flex items-center min-h-[30px]'>
                            {modalErrorMessage}
                        </div>
                    </>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <div className='modal__footer-actions'>
                        {isLoading ?
                            <Spinner />
                            :
                            <>
                                <Button dataAid='cancel' onClick={()=>{
                                    setIsModalOpen(false);
                                    setIsSubmitButtonDisabled(false);
                                    setModalErrorMessage(null);}
                                }>{t('DASHBOARD.CANCEL')}</Button>
                                <Button disabled={isSubmitButtonDisabled} color='primary' onClick={()=> modalProps.submitAction()} dataAid='submit'>
                                    <span className='uppercase'>{t(submitButtonText)}</span>
                                </Button>
                            </>
                        }

                    </div>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
        </>
    );
};

export default DashboardActions;
