import { CpCommonButton } from '@dome9/components/react/components';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBladeStatusesAndSaveItToState, goToStep } from '../helpers/aws.actions';
import { AWS_INTERVALS } from '../helpers/aws.const';
import { getAwsOnboardingStarted, getIsOrgSelected, setIsTakingLongerThanExpectedSummary } from '../helpers/aws.reducer';
import { getStoreService } from 'common/interface/services';

let interval: NodeJS.Timer | null = null;

const startInterval = async () => {
    await getBladeStatusesAndSaveItToState();
    interval = setInterval(() => {
        getBladeStatusesAndSaveItToState();
    }, AWS_INTERVALS.ACCOUNT);
};

const AwsSecurityGroupProtectionSummaryButton: FC = () => {
    const { t } = useTranslation();
    const dispatch = getStoreService().dispatch;
    const isButtonEnabled = useSelector(getAwsOnboardingStarted);
    const isOrgOnboarding = useSelector(getIsOrgSelected);
    useEffect(() => {
        if (isOrgOnboarding) {
            startInterval().then();
            dispatch(setIsTakingLongerThanExpectedSummary(true));
            return () => {
                clearInterval(interval!);
            };
        }
    }, [dispatch, isButtonEnabled, isOrgOnboarding]);

    return (
        <CpCommonButton
            onClick={() => {
                goToStep(true);
            }}
            id='wizard-next-button'
            disabled={!isButtonEnabled}>
            {t('ON_BOARDING.NEXT')}
        </CpCommonButton>
    );
};
export default AwsSecurityGroupProtectionSummaryButton;
