import React, { useState } from 'react';
import {
    I18nIntegrations,
    ICategoryIntegrationObject,
    INTEGRATION_DRAWER,
    integrationCategory,
    IntegrationsDefinition
} from 'common/module_interface/settings/integrations/consts';
import { IntegrationCard } from '../Components/IntegrationCard/IntegrationCard';
import { Stack } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { IntegrationSearchBar } from '../Components/IntegrationSearch/IntegrationSearch';
import { INTEGRATIONS_DRAWER_VIEWS } from '../Configurations/ConfigurationDrawerViews';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { useDrawerHandler } from 'common/components/DrawerInfra/Drawer/UseDrawerHandler';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';


const setIntegrationsInCategories = ( integrations: IntegrationsDefinition[] ) : ICategoryIntegrationObject => {
    const categoriesObject = {} as any;
    for (const integration of integrations) {
        Object.hasOwn(categoriesObject, integration.category) ? categoriesObject[integration.category].push(integration) :
            categoriesObject[integration.category] = [integration];
    }
    return categoriesObject;
};

const CONFIGURATION_CHANGED_HANDLER_ID = 'CONFIGURATION_CHANGED_HANDLER';

const orderIntegrations = (integrationsDefinitions: ICategoryIntegrationObject) => {
    const categoriesOrderedList = [integrationCategory.EVENTS_AND_LOGGING,integrationCategory.TICKETING_SYSTEM,
        integrationCategory.COLLABORATIONS_AND_MESSAGING,integrationCategory.DSPM,integrationCategory.VULNERABILITY_SECURITY_SCANNER,
        integrationCategory.SSO_CI,integrationCategory.SSO_CG,integrationCategory.CLOUD_SERVICES,integrationCategory.PLATFORMS_CLOUD_PROVIDERS,
        integrationCategory.SYSTEM_AUDIT,integrationCategory.THIRD_PARTY];
    return Object.entries(integrationsDefinitions).sort((a, b) => {
        return categoriesOrderedList.indexOf(a[0]) - categoriesOrderedList.indexOf(b[0]);
    });
};

interface IntegrationPageProps {
    configurations: any;
    configurationUpdated: () => Promise<void>;
}

const IntegrationPage: React.FC<IntegrationPageProps> = ({ configurations, configurationUpdated } ) => {

    const { t } = useTranslation( I18nIntegrations );
    const [searchValue, setSearchValue] = useState('');
    const integrationsDefinitions = setIntegrationsInCategories(IntegrationsRegistry.getIntegrationsDefinitions());
    const orderedIntegrationsEntries = orderIntegrations(integrationsDefinitions);

    const filterIntegrations = orderedIntegrationsEntries && Object.fromEntries(
        orderedIntegrationsEntries
            .map(([category, integrations]) => [
                category,
                (integrations as any[]).filter((integration) =>
                    integration.title.toLowerCase().includes(searchValue.toLowerCase())
                ),
            ])
    );

    const getComponentDataByIntegrationType = (type: string | undefined): Partial<IConfigurationContainerProps> | undefined=> {
        if (!type){
            return undefined;
        }
        return {
            integrationType: type,
            configurations: configurations[type],
        };
    };

    const handleConfigurationChanged = () => {
        configurationUpdated();
    };

    useDrawerHandler(CONFIGURATION_CHANGED_HANDLER_ID, handleConfigurationChanged);

    const onIntegrationCardClicked = (integration : IntegrationsDefinition) => {
        if(integration.configurationComponent) {
            showDrawer(INTEGRATION_DRAWER.key, {
                integrationID: integration.id,
                view: INTEGRATIONS_DRAWER_VIEWS.LIST_VIEW,
                title: integration.title,
                icon: integration.icon,
                componentProps: getComponentDataByIntegrationType(integration.id)
            },{ [INTEGRATION_DRAWER.eventTypes.configurationChanged]: CONFIGURATION_CHANGED_HANDLER_ID, });
        }
    };

    const handleSearchChange = (e:any) => setSearchValue(e.target.value);

    return (
        <div>
            <IntegrationSearchBar type={'text'} placeholder={t('GENERAL.SEARCH')} onChange={handleSearchChange}/>
            <Stack margin={[5, 5]} direction={'column'} data-aid={'integration-card-wrapper'}>
                {
                    Object.keys(filterIntegrations).filter(category => filterIntegrations[category].length > 0).map(category => {
                        return <div key={category}>
                            <div style={{ marginLeft: '8px' }}>{t(category)}</div>
                            <div>
                                {
                                    filterIntegrations[category].map(integration => {
                                        return <div style={{ display: 'inline-block' }}
                                            key={integration.title + integration.category}>
                                            <IntegrationCard configurationCount={configurations[integration.id]?.length} title={integration.title} icon={integration.icon}
                                                category={integration.category}
                                                onClick={() => onIntegrationCardClicked(integration)}
                                                configurationUrl={integration.configurationUrl}/>
                                        </div>;
                                    })
                                }
                            </div>
                        </div>;
                    })
                }
            </Stack>
        </div>
    );
};

export default IntegrationPage;