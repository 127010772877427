import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { Typography } from 'common/design-system/components-v2';
import Stack from 'common/design-system/components-v2/Stack';
import Chip from 'common/design-system/components-v2/Chip';

const TechniquesList: React.FC<{ mitreDetails: Record<string, string[]> }> = ({ mitreDetails }) => {
    return <>
        { Object.keys(mitreDetails).map((key) => (
            <Stack spacing={1} direction={'column'} key={'mitre'} alignItems={'flex-start'} justifyContent={'flex-start'} padding={[0,2,0,0]}>
                <Stack padding={[0,0,0,2]}>
                    <Typography color={'strong'} variant={'body500'}>
                        { key }
                    </Typography>
                </Stack>
                {mitreDetails[key].map((technique: string) => (
                    <Chip
                        label={technique}
                        key={`${key}-${technique}`}
                    />))}
            </Stack>
        ))
        }
    </>;
};

const FindingMitreSection: React.FC<{ mitreDetails: Record<string, string[]> }> = ({ mitreDetails }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    if (!mitreDetails) return;

    return ( <>
        <Stack spacing={2} padding={[0 , 4, 0, 0]}>
            <React.Fragment key=".0">
                <Typography variant="body" color="light">
                    { t('INT_FINDING_OVERVIEW.MITRE.TACTICS') }
                </Typography>
                <Typography variant="body" color="light">
                    { t('INT_FINDING_OVERVIEW.MITRE.TECHNIQUES') }
                </Typography>
            </React.Fragment>
        </Stack>
        { mitreDetails && <TechniquesList mitreDetails={mitreDetails} /> }
    </>
    );

};

export default FindingMitreSection;
