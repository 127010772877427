import React, { ReactElement, useCallback, useMemo } from 'react';
import { BoxStyle } from './ClassificationScoreBox.styled';
import { formatNumberShorthand, isNil } from 'common/utils/helpFunctions';
import { IClassificationScoreBoxProps } from '../../../../../dataClassification';
import { Typography } from 'common/design-system/components-v2';
import { getDataClassificationFilterFields } from './DataClassification.datasource';
import { IFieldInfo } from 'common/interface/general';
import DonutPie from '../../../../../../../common/erm-components/custom/DonutPie/DonutPie';
import { IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import { getCategoryColor } from '../../../../../DataAssetCategories';
import { IDonutPieSettings } from 'common/erm-components/custom/DonutPie/DonutPie.interface';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export const ClassificationScoreBox: React.FC<IClassificationScoreBoxProps> = ({ categoryCounters, classificationInfo, filterValues }) => {
    const total = useMemo(() => {
        let count = 0;
        categoryCounters.forEach(counter => count += counter.count);
        return count;
    }, [categoryCounters]);

    const onClick = total ? () => {
        const filterFields: IFieldInfo[] = getDataClassificationFilterFields(classificationInfo, undefined, filterValues);
        getErmUrlsService().goToProtectedAssetsTable(filterFields);
    } : undefined;

    const getPieItems = useCallback((): IGenericWidgetDataItem[] => categoryCounters.map(categoryCounter => ({
        key: categoryCounter.categoryInfo.id,
        value: categoryCounter.count,
        color: getCategoryColor(categoryCounter.categoryInfo),
        onclick: categoryCounter.count ? () => {
            const filterFields: IFieldInfo[] = getDataClassificationFilterFields(classificationInfo, categoryCounter.categoryInfo, filterValues);
            getErmUrlsService().goToProtectedAssetsTable(filterFields);
        } : undefined,
    })), [categoryCounters, classificationInfo, filterValues]);

    const getPieComponent = useCallback((): ReactElement => {
        const settings: IDonutPieSettings = {
            size: 72,
            suppressTitle: true,
            suppressSubtitle: true,
            centralIconProps: {
                name: classificationInfo.icon,
                size: 32
            },
            useSimpleTooltip: true,
            thickness: 5,
        };

        return <DonutPie items={getPieItems()} settings={settings} />;
    }, [classificationInfo, getPieItems]);

    return (
        <BoxStyle.TopDiv direction={'row'} alignItems={'center'} spacing={1}>
            <BoxStyle.Pie>{getPieComponent()}</BoxStyle.Pie>
            <BoxStyle.BodyDiv direction={'column'} spacing={1} >
                <Typography variant={'body'}>{classificationInfo.getTitle()}</Typography>
                <BoxStyle.CounterDiv isLink={!isNil(onClick)} variant={'h1'} onClick={onClick}>{formatNumberShorthand(total)}</BoxStyle.CounterDiv>
            </BoxStyle.BodyDiv>
        </BoxStyle.TopDiv>
    );
};