import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
    min-width: 250px;
    width: 100%;
`;

const FailureDiv = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.status.error};
`;

export const SensitiveDataChartStyled = {
    TopDiv,
    FailureDiv
};