import { ICompoundFilter, ICompoundFilterNode } from 'common/erm-components/custom/FilterTree/CompoundFilter';
import { IFacetData } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import {
    AutoActionTriggerEnum,
    IAutoActionModel,
} from 'common/module_interface/RiskManagement/autoActions/AutoActions';
import { IssueSeverityEnum } from 'common/module_interface/RiskManagement/issues/Issues.interface';

export interface IAutoActionProps extends Omit<IAutoActionModel, 'filter' | 'id' | 'name' | 'triggers'> {
    integrations?: IAutoActionIntegration[],
    name?: string;
    triggers?: AutoActionTriggerEnum[];
    orgUnitIdsFilter?: string[],
    serverFilter?: ICompoundFilterNode;
    filter?: ICompoundFilter;
    id?: string;
}

export interface IAutoAction extends IAutoActionProps {
    name: string;
    triggers: AutoActionTriggerEnum[];
    integrations: IAutoActionIntegration[],
    orgUnitIdsFilter: string[],
    id: string;
}

export enum AUTO_ACTION_FIELD_NAMES {
    ID = 'id',
    NAME = 'name',
    DESCRIPTION = 'description',
    FILTER = 'filter',
    TRIGGERS = 'triggers',
    INTEGRATION_IDS = 'integrationIds',
    ORG_UNIT_IDS = 'orgUnitIdsFilter',
    INTEGRATIONS = 'integrations',
    ORG_UNIT_IDS_FILTER = 'orgUnitIdsFilter',
    CREATED = 'created',
    UPDATED = 'updated',
}

export interface IAutoActionIntegration {
    id: string;
    name: string;
    icon?: string;
}

export interface IAutoActionEditorParams {
    autoActionProps?: IAutoActionProps;
    onAutoActionsChangedHandlerId: string,
}

export interface IAutoActionPreviewRequest {
    filter?: ICompoundFilterNode,
    orgUnitIdsFilter?: string[],
}

export interface IFieldNameCounters {
    fieldName: string;
    count: number;
}

export interface IAutoActionPreviewInfo {
    facets: IFacetData[];
    distinctValuesCount: IFieldNameCounters[];
    totalCount: number;
}

export interface ISecurityRuleModel {
    id: string;
    name: string;
    description: string;
    systemLabels: string[];
    platforms: string[];
    severity: string; // lowercase: e.g. critical,
    frameworks: string[];
    remediation: string;
    entityLabels: string[];
}

export interface ISecurityRule extends ISecurityRuleModel {
    title: string;
    severity: IssueSeverityEnum;
}