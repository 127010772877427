import { useCallback, useMemo, useRef, useState, useEffect, FC } from 'react';
import { INotification, INotificationTableProps } from '../Interfaces/NotificationPageInterfaces';
import {
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
} from 'ag-grid-community';
import { Table } from 'common/design-system/components-v2';
import { NotificationsTableRegistry } from '../NotificationsTableRegestries';
import { useTranslation } from 'react-i18next';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { I18nNotifications } from '../NotificationsPage.consts';
import { CellClickedEvent } from 'ag-grid-community/dist/lib/events';
import { integrationNotificationReadOnly } from 'common/utils/userPermissionUtils';

interface IModalConfigurations {
    isOpen: boolean,
    onConfirm: () => any,
    onClose: () => any,
    title: string,
    text: string
}

const NotificationsTable: FC<INotificationTableProps> = ({ onInnerGridReady, pageSize, data,allNotificationCount, onDelete ,onAdd, onNotificationClicked }) => {
    const { t } = useTranslation( I18nNotifications );
    const apiRef = useRef<GridApi>();
    const [selectedRows, setSelectedRows] = useState<INotification[]>([]);
    const [modalConfigurations, setModalConfigurations] = useState({} as IModalConfigurations);
    const viewMode = integrationNotificationReadOnly();


    useEffect(() => {
        if (apiRef.current) {
            apiRef.current.setRowData(data);
        }
    }, [data]);

    const columnDefs = useMemo<ColDef[]>(() => {
        return NotificationsTableRegistry.getColumnDefs();
    } , []);

    const handleCellClicked = (event: CellClickedEvent<INotification>) => {
        if(event.value === event.data?.name){
            onNotificationClicked && onNotificationClicked(event.data!);
        }
    };

    const onSelectionChanged = (params: any) => {
        if(params.node.isSelected() && !params.data.associations.includes(t('ASSOCIATIONS.NONE', { ns: I18nNotifications }))){
            params.node.setSelected(false);
        }

        const selectedItems = params.api.getSelectedRows();
        setSelectedRows(selectedItems);
    };

    const onModalClose = () => {
        setModalConfigurations({ ...modalConfigurations, isOpen: false });
    };

    const openDeleteModal = () => {
        const newModalConfigurations = {} as IModalConfigurations;
        newModalConfigurations.title = selectedRows.length > 1 ? t('MODALS.DELETE.MULTIPLE_DELETION_TITLE') : t('MODALS.DELETE.SINGLE_DELETION_TITLE',{ notificationName: selectedRows[0].name });
        newModalConfigurations.text = t('MODALS.DELETE.CONFIRMATION_TEXT');
        newModalConfigurations.onClose = onModalClose;
        newModalConfigurations.onConfirm = ()=>{
            onDelete(selectedRows);
            onModalClose();
        };
        newModalConfigurations.isOpen = true;
        setModalConfigurations(newModalConfigurations);
    };

    const defaultActions = useMemo<ITableAction[]>(() => {
        return [
            {
                id: 'addNotification',
                name: t('BUTTONS.ADD'),
                buttonProps: {
                    iconProps: { name: 'plus' }
                },
                callback: () => onAdd(),
                isActionDisabled: () => viewMode,
            },
            {
                id: 'deleteNotification',
                name: t('BUTTONS.DELETE'),
                buttonProps: {
                    iconProps: { name: 'delete' }
                },
                callback: () => openDeleteModal(),
                isActionDisabled: () => selectedRows.length === 0 || viewMode,
            }
        ];
    }, [selectedRows]);


    const onGridReady = useCallback(
        (params: GridReadyEvent<INotification, any>) => {
            apiRef.current = params.api;
            onInnerGridReady(params);
        },
        [],
    );

    const gridOptions: GridOptions<INotification> = {
        columnDefs,
        rowSelection: 'multiple',
        onGridReady,
        getRowId: (params) => params.data.id!,
        onCellClicked: handleCellClicked,
        onRowSelected: onSelectionChanged,
        isRowSelectable: () => {
            return true;
        },
    };

    return (
        <>
            <Table
                pageSize={pageSize}
                gridOptions={gridOptions}
                actions={defaultActions}
                footer={t('TABLE.FOOTER', { currentCount: data.length.toLocaleString(), totalCount: allNotificationCount.toLocaleString() })}
            />
            {modalConfigurations.isOpen && <ConfirmationModal isOpen={modalConfigurations.isOpen}
                onConfirm={modalConfigurations.onConfirm}
                title={modalConfigurations.title} text={modalConfigurations.text}
                onClose={modalConfigurations.onClose}
                onCancel={modalConfigurations.onClose}/>}
        </>
    );
};


export default NotificationsTable;
