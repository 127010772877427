import React, { useEffect, useState } from 'react';
import { Icon, Popover } from '@dome9/berries/react-components';
import InsightsComponent from '../Insights/Components/InsightsComponent';
import { AssetOverviewProps } from './AssetOverviewProps';
import { PromptType } from '../Suggestions/Suggestion.interface';
import AiDisclaimer from '../AiDisclaimer/Components/AiDisclaimer';
import { getUserService } from 'common/interface/services';
import InsightsDisclaimer from '../Insights/Components/InsightsDisclaimerComponent';

const AssetOverviewInsights: React.FC<AssetOverviewProps> = (props: AssetOverviewProps) => {
    const { entity } = props;

    const approvedSave = localStorage.getItem(`aidisclaimer.${getUserService().getUser().id}`);
    const [approved, setApproved] = useState<boolean | undefined>(approvedSave === 'true');
    const [srl, setSrl] = useState<string | null>(null);
    const [showSection, setShowSection] = useState<boolean>(true);

    useEffect(() => {
        setSrl(entity?.protectedAsset.srl ?? null);
    }, [entity?.cloudEntity, entity?.protectedAsset.srl]);

    return srl && showSection ?
        <div>
            { approved ? <div className={'pb-7'}>
                <div className="flex items-center gap-5 mx-9 mt-9 mb-7">
                    <div className="text-lg text-info-darkest opacity-75">AI generated insights</div>
                    <Popover content={AiDisclaimer({})} zIndex={99999} maxWidth={600} placement={'right'}>
                        <div className={'flex'}><Icon name={'info'} size={14} className={'ml-3'} /></div>
                    </Popover>
                    <div className="flex-1 border-dashed border-b"></div>
                </div>
                <div className='mx-9'>
                    <InsightsComponent
                        setShowSection={setShowSection}
                        srl={srl}
                        type={PromptType.NetworkTraffic} />
                </div>
            </div> : <InsightsDisclaimer setApproved={setApproved} /> }
        </div> :
        <span/>;
};

export default AssetOverviewInsights;