import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropStyled } from 'common/components/PropertyBox/PropertyBox.styled';
import { I18nRiskNamespace } from '../../../consts';
import { ViewerStyled } from '../../SecurityControls/SecurityControlViewer/SecurityControlViewer.styled';

export const DescriptionPanel: React.FC<{ description?: string }> = ({ description = '' }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    return (
        <ViewerStyled.PanelTopDiv>
            <ViewerStyled.PanelTitleDiv>{t('SECURITY_CONTROLS.PROPERTIES.DESCRIPTION')}</ViewerStyled.PanelTitleDiv>
            <PropStyled.ContentDiv>{description || ''}</PropStyled.ContentDiv>
        </ViewerStyled.PanelTopDiv>
    );
};
