import styled from 'styled-components';
import { Alert } from 'common/design-system/components-v2';

const Tip = styled(Alert)`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
`;

const ChipsWrapper = styled.div`
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    gap: 4px;
`;

export const WAFFiltersAlertStyled = {
    Tip,
    ChipsWrapper,
};
