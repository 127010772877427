import { ISecurityControl, ISecurityControlModel } from './SecurityControls.interface';
import { getSafeIssueSeverityInfo, IIssueSeverityInfo } from '../Issues/IssueSeverity';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { SECURITY_CONTROL_VIEWER_KEY } from './SecurityControlViewer/SecurityControlViewer.initialize';
import { SECURITY_CONTROLS_RESOURCE_URL } from './SecurityControls.consts';

import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';

const createSecurityControlFromModel = (securityControlModel: ISecurityControlModel): ISecurityControl => {
    const severityInfo: IIssueSeverityInfo = getSafeIssueSeverityInfo(securityControlModel.severity);
    return {
        ...securityControlModel,
        severity: severityInfo.level,
    };
};

export const createSecurityControlsListFromModel = (securityControlModels: ISecurityControlModel[]): ISecurityControl[] => {
    return securityControlModels.map(secControlModel => createSecurityControlFromModel(secControlModel));
};

export const fetchAllSecurityControls = async (): Promise<ISecurityControl[]> => {
    const allControlModels: ISecurityControlModel[] = await sendHttpRequest<ISecurityControlModel[]>(SECURITY_CONTROLS_RESOURCE_URL, { method: 'GET' });
    return createSecurityControlsListFromModel(allControlModels);
};

export const openSecurityControlViewerDrawer = (securityControl: ISecurityControl) => {
    showDrawer(SECURITY_CONTROL_VIEWER_KEY, securityControl);
};