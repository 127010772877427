import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const CloudBotCardListWrapper = styled(Stack)``;

const CloudBotCardWrapper = styled(Stack)``;

export const CloudBotCardListStyles = {
    CloudBotCardListWrapper,
    CloudBotCardWrapper,
};
