import { aws_actions } from '.';
import { batch } from 'react-redux';
import { AxiosResponse } from 'axios';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { getStoreService, getWebAppIframeService } from 'common/interface/services';
import CloudAccountsService from '../services/cloudAccounts/cloudAccounts.service';
import { CloudAccountsCredentialsUpdate } from '../services/cloudAccounts/cloudAccounts.interface';
import { AwsEnvironmentData } from 'common/module_interface/assets/AwsEnvironment';

const getMessageFromError: (error: any) => string = (error: any) => {
    const responseError = error.response?.data?.errors?.description?.join(', ');
    const responseErrorOpt2 = error.response?.data?.title;
    const responseErrorOpt3 = error.response?.data?.message;
    const responseErrorOpt4 = error.message;
    return responseError || responseErrorOpt2 || responseErrorOpt3 || responseErrorOpt4;
};

type GetAwsAccount = (awsId: string) => Promise<boolean>;
const getAwsAccount: GetAwsAccount = async (awsId) => {
    const { dispatch } = getStoreService().getReduxTools();
    try {
        dispatch(aws_actions.setLoading({ awsId, loading: true }));
        const results = await Promise.all([
            CloudAccountsService.cloudAccountGet(awsId),
            CloudAccountsService.cloudAccountStatsGet(awsId)
        ]);
        const [accountData, accountStatsData] = results;
        if (!accountData.data || !accountStatsData.data) throw new Error('');
        const awsData: AwsEnvironmentData = {
            account: accountData.data,
            stats: accountStatsData.data,
        };
        batch(() => {
            dispatch(aws_actions.setData({ awsId, data: awsData }));
            dispatch(aws_actions.setError({ awsId, error: false }));
            dispatch(aws_actions.setLoading({ awsId, loading: false }));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(aws_actions.setError({ awsId, error: true }));
            dispatch(aws_actions.setLoading({ awsId, loading: false }));
        });
        throw Error(getMessageFromError(error));
    }
};

export interface UpdateAwsReq {
    name?: string;
    credentials?: Omit<CloudAccountsCredentialsUpdate.Request, 'cloudAccountId'>;
}
type UpdateAws = (cloudAccountId: string, req: UpdateAwsReq) => Promise<boolean>;
const updateAws: UpdateAws = async (cloudAccountId, { name, credentials }) => {
    try {
        const { state } = getStoreService().getReduxTools();
        const { name: currentName, credentials: currentCredentials } = state.env_aws.awsEvnironments[cloudAccountId].data?.account || {};

        const updatePromiseArr: Array<Promise<AxiosResponse<any>>> = [];

        if (name && name !== currentName) {
            updatePromiseArr.push(CloudAccountsService.cloudAccountNameUpdate({ cloudAccountId, data: name }));
        }

        if (credentials) {
            const { apikey, arn, secret, type } = credentials.data;
            const { apikey: currentApikey, arn: currentArn, secret: currentSecret, type: currentType } = currentCredentials || {};
            if (apikey !== currentApikey || arn !== currentArn || secret !== currentSecret || type !== currentType) {
                updatePromiseArr.push(CloudAccountsService.cloudAccountsCredentialsUpdate({ cloudAccountId, data: credentials.data }));
            }
        }

        const result = await Promise.all(updatePromiseArr);
        
        if (result.length > 0) {
            getWebAppIframeService().emitMessage(
                new IframeMessageModel({
                    action: IFRAME_MESSAGE_ACTIONS.UPDATE_TABPANEL_TABNAME,
                    data: { id: cloudAccountId, newName: name }
                }),
            );
            getWebAppIframeService().emitMessage(
                new IframeMessageModel({
                    action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                }),
            );
            getAwsAccount(cloudAccountId);
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type DeleteAccount = (awsId: string, forceDelete?: boolean) => Promise<boolean>;
const deleteAccount: DeleteAccount = async (awsId, forceDelete = false) => {
    try {
        const { dispatch } = getStoreService().getReduxTools();
        if (forceDelete) {
            await CloudAccountsService.cloudAccountDeleteForce(awsId);
            dispatch(aws_actions.setDeleted({ awsId }));
            return true;
        }
        await CloudAccountsService.cloudAccountDelete(awsId);
        dispatch(aws_actions.setDeleted({ awsId }));
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

const setNotificationCountWarning = (awsId: string, count: number) => {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(aws_actions.setNotificationCountWarning({ awsId, count }));
};

const removeNotificationCountWarning = (awsId: string) => {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(aws_actions.setNotificationCountWarning({ awsId }));
};

const AwsActions = {
    getAwsAccount,
    updateAws,
    deleteAccount,
    setNotificationCountWarning,
    removeNotificationCountWarning,
};

export default AwsActions;