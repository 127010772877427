import { ReportsTableRegistry } from 'common/module_interface/reporting/ReportsTableRegistry';
import i18n from 'i18next';
import { i18nReportsNamespace } from '../../initialize.i18n';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { ReportsActions } from '../../Reporting.const';

function getActionsDefs(): ITableAction[] {
    return [
        {
            id: ReportsActions.CREATE_REPORT,
            name: i18n.t('REPORTS.ACTIONS.CREATE', { ns: i18nReportsNamespace }),
            buttonProps: {
                iconProps: { name: 'plus' }
            },
            callback: () => null,
            isActionDisabled: () => false,
        },
        {
            id: ReportsActions.EDIT_REPORT,
            name: i18n.t('REPORTS.ACTIONS.EDIT', { ns: i18nReportsNamespace }),
            buttonProps: {
                iconProps: { name: 'edit' }
            },
            callback: () => null,
            isActionDisabled: (selectedRows) => selectedRows.length !== 1,
        },
        {
            id: ReportsActions.RUN_REPORTS,
            name: i18n.t('REPORTS.ACTIONS.RUN', { ns: i18nReportsNamespace }),
            buttonProps: {
                iconProps: { name: 'play' }
            },
            callback: () => null,
            isActionDisabled: (selectedRows) => selectedRows.length === 0,
        },
        {
            id: ReportsActions.DOWNLOAD_REPORTS,
            name: i18n.t('REPORTS.ACTIONS.DOWNLOAD', { ns: i18nReportsNamespace }),
            buttonProps: {
                iconProps: { name: 'download' }
            },
            callback: () => null,
            isActionDisabled: (selectedRows) => selectedRows.length !== 1,
        },
        {
            id: ReportsActions.DELETE_REPORTS,
            name: i18n.t('REPORTS.ACTIONS.DELETE', { ns: i18nReportsNamespace }),
            buttonProps: {
                iconProps: { name: 'delete' }
            },
            callback: () => null,
            isActionDisabled: (selectedRows) => selectedRows.length === 0,
        },
    ];
}

export function initializeActionDefs() {
    ReportsTableRegistry.addActionDefs(getActionsDefs(), 'id');
}
