import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Modal, Stack, TextArea, Typography } from 'common/design-system/components-v2';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { IFieldError } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { COMMENT_TEXT_MAX_LENGTH, FormFields } from './CommentModal.const';
import { CommentModalStyles } from './CommentModal.styled';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import {
    ICommentRequest, IFindingKeysByCloudAccountId
} from 'common/module_interface/intelligence/Intelligence.interface';
import { ICommentModalEditableProps, ICommentModalProps } from './CommentModal.types';
import { isEmpty } from 'lodash';
import { getFindingKeysByCloudAccountId } from '../../../Findings.utils';

export const CommentModal: React.FC<ICommentModalProps> = (props: ICommentModalProps) => {
    const { findings, closeModal, fieldErrorsProps } = props;
    const { addFieldErrors, getFieldErrorText, clearFieldErrors } = fieldErrorsProps;
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const commentTextRef: React.RefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>(null);

    const commentTextError: string | undefined = useMemo(() => {
        return getFieldErrorText(FormFields.COMMENT_TEXT);
    }, [getFieldErrorText]);

    const getCommentText = useCallback((): string => {
        return commentTextRef.current?.value?.trim() ?? EMPTY_STRING;
    }, []);

    const updateCommentTextFieldError = (errors: IFieldError[]) => {
        const commentText: string = getCommentText();
        if (isEmpty(commentText)) {
            errors.push({
                field: FormFields.COMMENT_TEXT,
                label: t('COMMENT_MODAL.INPUT_FIELDS.COMMENT_TEXT.ERRORS.REQUIRED')
            });
        } else if (commentText.length > COMMENT_TEXT_MAX_LENGTH) {
            errors.push({
                field: FormFields.COMMENT_TEXT,
                label: t('COMMENT_MODAL.INPUT_FIELDS.COMMENT_TEXT.ERRORS.MAX_LENGTH')
            });
        }
    };

    const updateFieldsErrorState = (): boolean => {
        const errors: IFieldError[] = [];
        updateCommentTextFieldError(errors);
        addFieldErrors(errors);
        return errors.length > 0;
    };

    const onCommentTextChange = () => {
        clearFieldErrors([FormFields.COMMENT_TEXT]);
    };

    const onConfirmationModalCancel = () => {
        setIsConfirmationModalOpen(false);
    };

    const onDiscardChanges = () => {
        closeModal();
    };

    const onCommentModalCancel = () => {
        if (hasModalPropsChanged()) {
            return setIsConfirmationModalOpen(true);
        } else {
            closeModal();
        }
    };

    const prepareAddCommentRequest = (): ICommentRequest => {
        const findingsKeysByCloudAccountId: IFindingKeysByCloudAccountId[] = getFindingKeysByCloudAccountId(findings);
        const commentText: string = getCommentText();
        return { findings: findingsKeysByCloudAccountId, comment: commentText };
    };

    const onAddComment = async () => {
        const isAnyFieldError: boolean = updateFieldsErrorState();
        if (isAnyFieldError) return;

        const request: ICommentRequest = prepareAddCommentRequest();
        try {
            setIsLoading(true);
            await getIntelligenceService().addComment(request);
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                text: t('COMMENT_MODAL.ADD_COMMENT_SUCCESS')
            });
            closeModal();
        } catch (message: unknown) {
            const errorTitle = t('COMMENT_MODAL.ADD_COMMENT_FAILED');
            getNotificationsService().addNotification({ type: NotificationType.ERROR, title: errorTitle });
            await getLoggerService().error(`${errorTitle} ${message as string}`);
        } finally {
            setIsLoading(false);
            props.refreshTableData();
        }
    };

    const prepareModalEditableProps = useCallback((): ICommentModalEditableProps => {
        return {
            commentText: getCommentText(),
        };
    }, [getCommentText]);
    const initialModalEditablePropsRef: React.MutableRefObject<ICommentModalEditableProps> = useRef<ICommentModalEditableProps>(prepareModalEditableProps());

    const hasModalPropsChanged = useCallback(() => {
        const currModalProps: ICommentModalEditableProps = prepareModalEditableProps();
        return (JSON.stringify(initialModalEditablePropsRef.current) !== JSON.stringify(currModalProps));
    }, [prepareModalEditableProps]);

    return (
        <Fragment>
            <Modal.ModalDialog onRequestClose={onCommentModalCancel} isOpen={true} width={'md'}>
                <Modal.ModalHeader title={t('COMMENT_MODAL.TITLE')}
                    onClose={onCommentModalCancel}/>
                <Modal.ModalContent fullHeight={true}>
                    <CommentModalStyles.FormFieldsWrapper direction={'column'} spacing={6}>
                        <TextArea
                            key={'comment-text-field'}
                            ref={commentTextRef}
                            required={true}
                            clearable={true}
                            fullWidth={true}
                            inputSize="large"
                            maxLength={COMMENT_TEXT_MAX_LENGTH}
                            rows={4}
                            onChange={onCommentTextChange}
                            isError={!!commentTextError}
                            helperText={commentTextError}
                            placeholder={t('COMMENT_MODAL.INPUT_FIELDS.COMMENT_TEXT.PLACEHOLDER')}
                        />
                    </CommentModalStyles.FormFieldsWrapper>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        <Button key={'cancel-button-action'} variant='text'
                            onClick={onCommentModalCancel}>{i18n.t('COMMON.CANCEL')}</Button>
                        <Button key={'add-comment-button-action'} color='brandPrimary' loading={isLoading}
                            disabled={isLoading}
                            onClick={onAddComment}>{t('COMMENT_MODAL.ACTIONS.ADD_COMMENT')}</Button>
                    </Stack>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
            {isConfirmationModalOpen &&
                <ConfirmationModal width={'md'}
                    isOpen={isConfirmationModalOpen}
                    title={t('ACTIONS.CONFIRMATION_MODAL.TITLE')}
                    submitBtnText={i18n.t('COMMON.DISCARD')}
                    onConfirm={onDiscardChanges}
                    onClose={onConfirmationModalCancel}
                    onCancel={onConfirmationModalCancel}>
                    <Typography>{t('ACTIONS.CONFIRMATION_MODAL.DESCRIPTION')}</Typography>
                </ConfirmationModal>}
        </Fragment>
    );
};
