import React from 'react';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { CloudAccountOnboardingMode } from 'common/module_interface/assets/AwsEnvironment';
import { changeUrl } from 'common/utils/http';
import { ModalsProps } from '..';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import { ModalType } from '../../../AwsEnvironment.types';
import { getAwsEnvNamespace } from '../../../initialize.i18n';
import { useGetAws } from '../../../reducer/hooks/useGetAws';
import { useRemoveAccount } from '../../../reducer/hooks/useRemoveAccount';
import { getCloudAccountsService } from 'common/interface/data_services';
import { OrgOnboardingContent, ManagedOnboardingContent, UnifiedOnboardingContent } from './RemoveAccountContent';
const RemoveAccount: React.FC<ModalsProps> = ({ closeModal, setModalType, awsId }) => {
    const { t } = useTranslation(getAwsEnvNamespace('aws'));
    const { data: awsData } = useGetAws(awsId);
    const handleOnRemoveSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('awsPage.modals.removeAccount.onSubmit.success'),
            text: '',
        });
        getCloudAccountsService().clearCache();
        onClose();
        changeUrl('/cloud-account');
    };

    const handleOnRemoveError = ({ message }: { message: string }) => {
        if (message.includes('409')) {
            return setModalType(ModalType.Remove_WithPolicies);
        }
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('awsPage.modals.removeAccount.onSubmit.error'),
            text: '',
        });
        onClose();
    };

    const { isLoading: isDeleteLoading, deleteAccount } = useRemoveAccount({ onSuccess: handleOnRemoveSuccess, onError: handleOnRemoveError });

    const onClickRemoveCluster = async () => {
        deleteAccount(awsId);
    };

    const onClose = React.useCallback(() => {
        closeModal();
    }, [closeModal]);

    const isOrgOnboarding = awsData?.account.organizationId !== null && awsData?.account.organizationId !== 'NoOrg' && awsData?.account.organizationId !== 'OrgDetached';

    const renderContentByOnboardingMode = () => {
        if (isOrgOnboarding) return <OrgOnboardingContent />;
        if (awsData?.account.onboardingMode === CloudAccountOnboardingMode.CUSTOMER_MANAGED) return <UnifiedOnboardingContent />;
        return <ManagedOnboardingContent account={awsData?.account} />;
    };

    return (
        <ConfirmationModal
            title={t('awsPage.modals.removeAccount.header', { name: awsData?.account.name })}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={onClickRemoveCluster}
            isLoading={isDeleteLoading}
            submitBtnText={t('awsPage.modals.removeAccount.submit')}
            submitBtnColor='alert'>
            {renderContentByOnboardingMode()}
        </ConfirmationModal>
    );
};

export default RemoveAccount;
