import { IIssueExclusion } from './IssueExclusions.interface';
import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { ermTrans } from '../../RiskManagement.utils';
import { ISSUE_EXCLUSION_FIELD_NAMES } from './IssueExclusions.consts';
import { IItemDataGetter } from 'common/erm-components/custom/ClientFilter/ClientFilter.interface';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import {
    createFreeTextFilter,
    createOrgUnitFilter,
    createSelectionFilter, getCommonFiltersMap
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { CommonFilterEnum } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.consts';
import { IClientFilterType } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';

export const getOptionsLabelGetter = (optionsFieldName: string) => {
    const getter: IItemDataGetter<IIssueExclusion> = (exclusion: IIssueExclusion) => {
        const options: SelectOption[] | undefined = exclusion[optionsFieldName as keyof IIssueExclusion] as SelectOption[];
        if (!options || (options.length === 0)) {
            return;
        }
        return options.map(option => option.label);
    };
    return getter;
};

export const tagsKeyValueGetter: IItemDataGetter<IIssueExclusion> = (exclusion: IIssueExclusion) => {
    if (!exclusion.tags || (exclusion.tags.length === 0)) {
        return;
    }
    const values: string[] = [];
    exclusion.tags.forEach(tag => {
        values.push(tag.key);
        values.push(tag.value);
    });
    return values;
};

export const getIssueExclusionsFilters = (): IClientFilterType<IIssueExclusion, IFilterConditionsContainer>[] => {
    const commonMap = getCommonFiltersMap<IIssueExclusion>();
    return [
        commonMap[CommonFilterEnum.ADD_FILTER],
        commonMap[CommonFilterEnum.CLEAR_ALL],
        createFreeTextFilter([
            ISSUE_EXCLUSION_FIELD_NAMES.NAME,
            ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_NAMES,
            ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_ID,
            ISSUE_EXCLUSION_FIELD_NAMES.DESCRIPTION,
            getOptionsLabelGetter(ISSUE_EXCLUSION_FIELD_NAMES.ORG_UNIT_OPTIONS),
            getOptionsLabelGetter(ISSUE_EXCLUSION_FIELD_NAMES.RULE_OPTIONS),
            getOptionsLabelGetter(ISSUE_EXCLUSION_FIELD_NAMES.ENV_OPTIONS),
            getOptionsLabelGetter(ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_TYPE_OPTIONS),
            tagsKeyValueGetter,
        ]),
        createSelectionFilter(
            ISSUE_EXCLUSION_FIELD_NAMES.SEVERITIES, ermTrans('ISSUE_EXCLUSIONS.TABLE.FILTERS.SEVERITIES.TITLE')),
        createSelectionFilter(
            ISSUE_EXCLUSION_FIELD_NAMES.ENV_IDS, ermTrans('ISSUE_EXCLUSIONS.TABLE.FILTERS.ENV_IDS.TITLE')),
        createSelectionFilter(
            ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_TYPES, ermTrans('ISSUE_EXCLUSIONS.TABLE.FILTERS.ENTITY_TYPES.TITLE')),
        createSelectionFilter(
            ISSUE_EXCLUSION_FIELD_NAMES.RULE_IDS, ermTrans('ISSUE_EXCLUSIONS.TABLE.FILTERS.RULE_IDS.TITLE')),
        createOrgUnitFilter(
            ISSUE_EXCLUSION_FIELD_NAMES.ORG_UNIT_IDS, ermTrans('ISSUE_EXCLUSIONS.TABLE.FILTERS.ORG_UNIT_IDS.TITLE')),
    ];
};
