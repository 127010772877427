import { ColumnVO } from 'ag-grid-community/dist/lib/interfaces/iColumnVO';
import { GridApi } from 'ag-grid-enterprise';
import { ColumnApi } from 'ag-grid-community';
import {
    IdField,
    IdType,
    IIssue,
    IIssueViewModel,
    IServerInputFreeText,
} from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { FindingSeverityEnum } from 'common/consts/FindingSeverity';
import { LoadingState, SortDirection } from 'common/interface/general';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { IFacetData } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import { IFilterCondition } from 'common/erm-components/custom/FilterTree/FilterCondition';

export enum ISSUE_FIELD_NAMES {
    ACCOUNT_ID = 'accountId',
    ALERT_TYPE = 'alertType',
    CREATED_TIME = 'createdTime',
    DESCRIPTION = 'description',
    ENTITY_ID = 'entityId',
    ENTITY_ID_TYPE = 'entityIdType',
    ENTITY_EXTERNAL_ID = 'entityExternalId',
    ENTITY_LABELS = 'entityLabels',
    ENTITY_NAME = 'entityName',
    ENTITY_TAGS = 'entityTags',
    ENTITY_TYPE = 'entityType',
    ENTITY_TYPE_BY_PLATFORM = 'entityTypeByPlatform',
    ENVIRONMENT_EXTERNAL_ID = 'environmentExternalId',
    ENVIRONMENT_EXTERNAL_NAME = 'environmentExternalName',
    ENVIRONMENT_ID = 'environmentId',
    FINDING_KEY = 'findingKey',
    FRAMEWORKS = 'frameworks',
    ID = 'id',
    IS_EXCLUDED = 'isExcluded',
    ORGANIZATIONAL_UNIT_ID = 'organizationalUnitId',
    ORIGIN = 'origin',
    PAYLOAD = 'payload',
    PLATFORM = 'platform',
    REGION = 'region',
    REMEDIATION = 'remediation',
    RULE_ID = 'ruleId',
    RULE_LOGIC = 'ruleLogic',
    RULE_TITLE = 'ruleTitle',
    SEVERITY = 'severity',
    STATUS = 'status',
    STATUS_REASON = 'statusReason',
    SYSTEM_LABELS = 'systemLabels',
    UPDATED_TIME = 'updatedTime',
    OCCURRENCES = 'occurrences',
}

export enum TOP_ISSUE_FIELD_NAMES {
    RULE_TITLE = 'ruleTitle',
    SEVERITY = 'severity',
    OCCURRENCES = 'occurrences',
}

export enum CVE_FIELD_NAMES {
    CVE_ID = 'cveId',
    SEVERITY = 'severity',
    EPSS_SCORE = 'epssScore',
    SEVERITY_LEVEL = 'severityLevel',
    CVSS_SCORE = 'baseScore',
    BASE_SCORE_VECTOR = 'baseScoreVector',
    FIXABLE = 'fixable',
    KNOWN_EXPLOIT = 'knownExploit',
}

export enum CVE_PACKAGE_FIELD_NAMES {
    NAME = 'name',
    FILE_PATH = 'filePath',
    UPGRADE_TO = 'upgradeTo',
}

export const ID_TYPE_TO_FIELD_MAP: { [key: string] : IdField } = {
    [IdType.DOME9_ID_TYPE]: IdField.FIELD_NAME_ID,
    [IdType.EXTERNAL_ID_TYPE]: IdField.FIELD_NAME_ENTITY_ID,
};

export type IIssuesGroupMap = { [key: string]: IIssuesGroup };

export const GROUPING_COUNT_FIELD_NAME = 'count';
export interface IIssuesGroupViewModel {
    [key: string]: any;
    [GROUPING_COUNT_FIELD_NAME]: number;
}

export interface IIssuesGroup {
    [key: string]: any;
    isGrouped: boolean;
    isLeafGroup?: boolean;
    isRootGroup?: boolean;
    childCount: number;
    col?: ColumnVO;
    children: IIssuesGroup[];
    parent?: IIssuesGroup;
    depth: number;
    nodeId: string;
    groupedFields: IFilterCondition[];
}

export interface IIssuesGroupWithTotal {
    issuesGroup: IIssuesGroup;
    totalCount: number;
}

export type IIssueOrGroup = IIssue | IIssuesGroup;

export interface IFlatIssueSearchResult {
    issues: IIssue[],
    itemCount: number,
}

export interface IFlatIssueSearchResponse {
    results: IIssueViewModel[],
    facets: IFacetData[],
    totalCount: number,
}

export interface IIssueTableDataCounters {
    itemCount: number;
    totalCount: number;
    groupCount?: number;
}

export type IIssueTableDataState = IIssueTableDataCounters | LoadingState;

export interface IIssuesTableDatasourceParams {
    gridApi: GridApi<IIssue>;
    gridColApi: ColumnApi;
    onDataStateChange?: (dataState?: IIssueTableDataState) => void;
}

export interface IServerInputSort {
    fieldName: string;
    direction: SortDirection;
}

export interface IFlatIssuesSearchRequest {
    filter?: IFilterCondition[];
    freeText?: IServerInputFreeText
    orgUnitIdsFilter?: string[];
    sort?: IServerInputSort[];
    totalCount?: boolean;
    resultSetSize?: number;
    facets?: string[];
}

export interface IGroupedIssuesSearchRequest {
    filter?: IFilterCondition[];
    freeText?: IServerInputFreeText
    orgUnitIdsFilter?: string[];
    sort?: IServerInputSort[];
    groups: string[];
}

export interface IGroupingData {
    cols: ColumnVO[];
    rootGroup: IIssuesGroup;
    itemCount: number;
    totalCount: number;
}

export interface IIssuesTableData {
    issuesOrGroups: IIssueOrGroup[];
    itemCount: number;
}

export interface IIssuesTableDataWithTotals {
    issuesTableData: IIssuesTableData;
    totalCount: number;
}

export interface ITopIssue extends IIssuesGroup {
    ruleTitle: string;
    severity: number;
    occurrences: number;
}

export interface IIssueSeverityValueCount {
    value: number;
    count: number;
}

export interface IIssueCvePackage {
    name?: string;
    filePath?: string;
    upgradeTo?: string;
}

export interface IIssueCve {
    id: string;
    severity: FindingSeverityEnum;
    severityLevel: number;
    baseScore?: number;
    epssScore?: number;
    baseScoreVector?: string;
    fixable: boolean;
    knownExploit: boolean;
    packages: IIssueCvePackage[];
    issueId: string;
    entityId: string;
}

export interface IIssueCvePackageModel {
    name?: string;
    filePath?: string;
    upgradeTo?: string;
}

export interface IIssueCveModel {
    cveId: string;
    baseScore?: number;
    epssScore?: number;
    baseScoreVector?: string;
    severity: string;
    fixable?: boolean | null;
    knownExploit: boolean;
    packages: IIssueCvePackageModel[];
}

export interface IAggregationInfo {
    aggregations: Aggregations;
    totalCount: number | undefined;
}

export interface IEvidencePathIssueInfo {
    issueId: string,
    srl?: string,
}
