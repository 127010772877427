import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams } from 'common/components/Wizard/Wizard';
import { getRulesetService, getShiftLeftService } from 'common/interface/services';
import { IPolicy } from 'common/interface/policy';
import { EnvironmentsSelectionComponent } from 'common/components/policy/EnvironmentsSelectionComponent';
import { Vendors } from 'common/consts/vendors';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Done } from 'common/components/policy/Done/Done';
import { RulesetSelectionComponent } from './RulesetSelectionComponent';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { useReturnUrl } from 'common/hooks/useReturnUrl';
import { finishFlow } from 'common/components/policy/utils';

export interface IEnvironmentPolicy {
    id?: string;
    rulesetId: number;
    targetId: string;
    externalAccountId: string;
    notificationIds: string[];
    targetType: string;
    action: string;
    vendor: string;
    admissionControl: string;
    rulesetPlatform: string;
}
const SHIFTLEFT_TYPE = 'Shiftleft';
const AddEnvironmentPolicyPage: React.FC = () => {
    const { t } = useTranslation();
    const selectedPlatform = Vendors.SHIFT_LEFT;
    const [selectedEnvironments,setSelectedEnvironments] = useState<ListItem[]>([]);
    const [selectedRulesets,setSelectedRulesets] = useState<ListItem[]>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const returnUrl = useReturnUrl();
    useBreadcrumbsLastChildren([t('NAVIGATION_MENU.SHIFTLEFT.TITLE'),t('POLICY.ADD_ENVIRONMENT_POLICY')]);

    const selectedRulesetsChanged = (selectedItems: ListItem[]) => {
        setSelectedRulesets(selectedItems);
    };

    const selectedNotificationsChanged = (selectedItems: ListItem[]) => {
        setSelectedNotifications(selectedItems);
    };

    const selectedEnvironmentsChanged = (selectedItems: ListItem[]) => {
        setSelectedEnvironments(selectedItems);
    };

    const saveChanges = useCallback(async () => {
        if(selectedRulesets?.length && selectedNotifications?.length ){
            const policies:IPolicy[] = [];
            const allRulesets = await getRulesetService().getAllRulesets();
            for (const ruleset of selectedRulesets) {
                const _ruleset = allRulesets.find((_ruleset) => _ruleset.id === +ruleset.id);

                for (const environment of selectedEnvironments) {
                    const rulesetId = +ruleset.id;
                    const newPolicy:IPolicy = {
                        targetId: environment.id,
                        rulesetId: rulesetId,
                        rulesetPlatform: _ruleset?.cloudVendor,
                        notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                        targetType: SHIFTLEFT_TYPE,
                    };
                    if(rulesetId < 0){
                        newPolicy.rulesetVersion = ruleset.selectedVersion?.version;
                    }
                    policies.push(newPolicy);
                }
            }

            if(policies?.length){
                setFlowStatusMessage(t('POLICY.PROCESSING'));
                const result = await getShiftLeftService().setShiftLeftPolicy(policies);
                if(result) {
                    setFlowStatusOK(true);
                    setFlowStatusMessage(t('POLICY.POLICY_ADDED_MESSAGE'));
                }else {
                    setFlowStatusOK(false);
                    setFlowStatusMessage(t('POLICY.ERROR_OCCURRED'));
                }
            }
        }
    }, [selectedEnvironments, selectedRulesets, selectedNotifications, t]);


    const steps = useMemo(() => [
        {
            name: t('POLICY.ENVIRONMENTS_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <EnvironmentsSelectionComponent preSelectedItems={selectedEnvironments} {...renderViewParams } platform={selectedPlatform} selectedEnvironmentsChanged={selectedEnvironmentsChanged} />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            name: t('POLICY.RULESET_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <RulesetSelectionComponent preSelectedItems={selectedRulesets} platform={selectedPlatform} {...renderViewParams } selectedRulesetsChanged={selectedRulesetsChanged}/>;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            name: t('POLICY.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={selectedNotificationsChanged}/>;
            },
            onNext: async ()=>{
                saveChanges();
            },
        },
        {
            name: t('POLICY.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ], [t, selectedEnvironments, selectedPlatform, selectedRulesets, selectedNotifications, saveChanges, flowStatusMessage, flowStatusOK]);

    return (
        <div>
            {steps?.length ? <Wizard
                steps={steps}
                title={t('POLICY.ADD_POLICY')}
                doneButtonText={t('POLICY.DONE')}
                onClose={()=>finishFlow(returnUrl)} />
                : null}
        </div>
    );
};

export default AddEnvironmentPolicyPage;