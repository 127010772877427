import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';

export const FindingsFilterTypes: { [key: string]: IProtectedAssetFilter } = {
    // common filters
    ADD_FILTER: {
        id: FILTERS_KEYS.ADD_FILTER
    },
    FREE_TEXT: {
        id: FILTERS_KEYS.FREE_TEXT
    },
    DATE_PICKER: {
        id: FILTERS_KEYS.DATE_PICKER
    },
    CLEAR_ALL: {
        id: FILTERS_KEYS.CLEAR_BUTTON
    },
    // field filters
    PLATFORM: {
        id: FindingFields.cloudAccountType,
        isField: true
    },
    ENTITY_TYPE: {
        id: FindingFields.entityType,
        isField: true
    },
    ENVIRONMENT: {
        id: FindingFields.cloudAccountId,
        isField: true
    },
    SEVERITY: {
        id: FindingFields.severityName,
        isField: true
    },
    ENTITY_NAME: {
        id: FindingFields.entityName,
        isField: true
    },
    ORGANIZATIONAL_UNITS: {
        id: FindingFields.organizationalUnitId,
        isField: true
    },
    REGION: {
        id: FindingFields.entityRegion,
        isField: true
    },
    RULE_NAME: {
        id: FindingFields.ruleName,
        isField: true
    },
    RULESET_NAME: {
        id: FindingFields.rulesetName,
        isField: true
    },
    ACTION: {
        id: FindingFields.action,
        isField: true
    },
    REMEDIATION: {
        id: FindingFields.ruleRemediation,
        isField: true
    },
    SHOW_EXCLUDED: {
        id: FindingFields.isExcluded,
        isField: true
    },
    STATUS: {
        id: FindingFields.statusId,
        isField: true
    },
    ORIGIN: {
        id: FindingFields.origin,
        isField: true
    },
    MITRE_TACTIC: {
        id: FindingFields.mitreTactic,
        isField: true
    },
    MITRE_TECHNIQUE: {
        id: FindingFields.mitreTechnique,
        isField: true
    },
    ASSIGNEE: {
        id: FindingFields.owner,
        isField: true
    },
    SHOW_ACKNOWLEDGED: {
        id: FindingFields.isAcknowledged,
        isField: true
    },
};
