import { SortDirection } from 'common/interface/general';
import {
    GROUPING_COUNT_FIELD_NAME,
    IIssuesGroup,
    IIssuesGroupViewModel,
    IServerInputSort,
    ISSUE_FIELD_NAMES,
} from '../../../../Issues/Issues.interface';
import { fetchGroupingFlatItems } from '../../../../Issues/Issues.utils';
import { IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';

export interface ITopIssue extends IIssuesGroup {
    ruleTitle: string;
    severity: number;
    occurrences: number;
}

export const getTopIssues = async (filterDetails: IServerInputFilterDetails): Promise<ITopIssue[]> => {
    const sort: IServerInputSort[] = [
        {
            fieldName: ISSUE_FIELD_NAMES.SEVERITY,
            direction: SortDirection.DESC,
        },
        {
            fieldName: GROUPING_COUNT_FIELD_NAME,
            direction: SortDirection.DESC,
        },
    ];
    const groupByFields = [ISSUE_FIELD_NAMES.RULE_TITLE, ISSUE_FIELD_NAMES.SEVERITY];
    const issuesGroupViewModels: IIssuesGroupViewModel[] = await fetchGroupingFlatItems(groupByFields, filterDetails, sort);
    const topIssues: ITopIssue[] = issuesGroupViewModels.map((group: IIssuesGroupViewModel) => {
        const childCount = group[GROUPING_COUNT_FIELD_NAME];
        return {
            isGrouped: true,
            ruleTitle: group.ruleTitle,
            severity: group.severity,
            occurrences: childCount,
            childCount,
            children: [],
            depth: 2,
            nodeId: `ruleTitle:${group.ruleTitle},severity:${group.severity}`,
            groupedFields: [],
        };
    });
    return topIssues.slice(0, 5);
};