import React, { useMemo } from 'react';
import { IIssueExclusion } from './IssueExclusions.interface';
import {
    fetchAllIssueExclusions
} from './IssueExclusions.utils';
import {
    ISSUE_EXCLUSION_FIELD_NAMES,
    ISSUE_EXCLUSIONS_PAGE_TABLE_ID,
} from './IssueExclusions.consts';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../consts';
import { getIssueExclusionsTableActions } from './IssueExclusion.actions';
import { getIssueExclusionsFilters } from './IssueExclusions.filters';
import { IFilterFieldOptionsMap } from 'common/module_interface/assets/ProtectedAssets';
import { IConditionValueOption } from 'common/erm-components/custom/FilterTree/FilterTree.interface';
import { ClientFilterPageTable } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable';
import { getIssueExclusionsColumns } from './IssueExclusions.columns';
import { IssueExclusionsEmptyState } from './IssueExclusionsEmptyState/IssueExclusionsEmptyState';
import {
    IClientFilterPageTableProps
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';
import {
    getAllEntityTypeCondOptions,
    getAllEnvironmentCondOptions,
    getAllIssueSeverityCondOptions,
    getAllRulesCondOptions,
} from '../../RiskManagement.options';

const fetchFilterOptionsMap = async (): Promise<IFilterFieldOptionsMap> => {
    const rulesOptions: IConditionValueOption[] = await getAllRulesCondOptions();
    const entityTypeOptions: IConditionValueOption[] = getAllEntityTypeCondOptions();
    const envOptions: IConditionValueOption[] = await getAllEnvironmentCondOptions();
    return {
        [ISSUE_EXCLUSION_FIELD_NAMES.RULE_IDS]: {
            options: rulesOptions,
        },
        [ISSUE_EXCLUSION_FIELD_NAMES.ENTITY_TYPES]: {
            options: entityTypeOptions,
        },
        [ISSUE_EXCLUSION_FIELD_NAMES.ENV_IDS]: {
            options: envOptions,
        },
        [ISSUE_EXCLUSION_FIELD_NAMES.SEVERITIES]: {
            options: getAllIssueSeverityCondOptions(),
            showAllOptions: true,
        },
    };
};

export const IssueExclusionsPage: React.FC = () => {
    const { t } = useTranslation(I18nRiskNamespace);
    const pageProps: IClientFilterPageTableProps<IIssueExclusion> = useMemo(() => ({
        fetchAllItems: fetchAllIssueExclusions,
        getTableColumnDefs: getIssueExclusionsColumns,
        getTableActions: getIssueExclusionsTableActions,
        getTableFilterDefs: getIssueExclusionsFilters,
        emptyStateComponent: IssueExclusionsEmptyState,
        getFooterItemTitle: (count: number) => t('ISSUE_EXCLUSIONS.GENERAL.ISSUE_EXCLUSION', { count }),
        pageTableId: ISSUE_EXCLUSIONS_PAGE_TABLE_ID,
        getFieldOptionsMap: fetchFilterOptionsMap,
    }), [t]);
    return <ClientFilterPageTable {...pageProps} />;
};
