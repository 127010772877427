import React, { useCallback, useMemo } from 'react';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, IColumnToolPanel, SelectionChangedEvent, CellClickedEvent } from 'ag-grid-community';
import { Spinner, Stack, Table, Typography } from 'common/design-system/components-v2';
import { EventsTableProps } from './EventsTable.types';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { EventsTableColDef } from 'common/interface/events';
import { IEvent } from 'common/module_interface/events/Events';
import { ITableAction, ITableExportButton } from 'common/design-system/components-v2/Table/Table.types';
import Modals from 'modules/events/Modals';
import { EventsModalOpenFrom } from 'common/module_interface/events/EventsModalsRegistry';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import useEventsTable from '../../hooks/useEventsTable';
import EventsTableStyles from './EventsTable.styles';

const EventsTable: React.FC<EventsTableProps> = ({
    isLoading,
    pageSize,
    tableId,
    tabData,
    dataSource,
    activeFilters,
    archiveView,
    onEventClicked
}) => {
    const [gridApi, setGridApi] = React.useState<GridApi<IEvent>>();
    const [columnApi, setColumnApi] = React.useState<ColumnApi>();
    const [modalType, setModalType] = React.useState<string | null>(null);
    const [selectedRows, setSelectedRows] = React.useState<IEvent[]>([]);
    const { params: { drawer } } = useReactRouterQuery();

    const { dataValid, setDataValid } = useEventsTable();

    const resetSelectedRows = React.useCallback(() => {
        gridApi?.deselectAll();
    }, [gridApi]);

    const onSelectionChanged = (params: SelectionChangedEvent<IEvent>) => {
        const selectedItems = params.api.getSelectedRows();
        setSelectedRows(selectedItems);
    };

    React.useEffect(() => {
        if (!dataValid) {
            const hasActiveGroup = (columnApi?.getRowGroupColumns().length || 0) > 0;
            gridApi?.refreshServerSide({ purge: hasActiveGroup });
            resetSelectedRows();
            setDataValid(true);
        }
    }, [columnApi, dataValid, gridApi, resetSelectedRows, setDataValid]);

    React.useEffect(() => {
        if (!dataSource || !gridApi) return;
        gridApi.onFilterChanged();
    }, [gridApi, activeFilters, dataSource]);

    const columnDefs = useMemo<ColDef[]>(() => {
        const allDefs = EventsTableRegistry.getColumnDefs();
        
        const defs = tabData.columns.reduce<EventsTableColDef[]>((acc, column) => {
            const defFromAddin = allDefs.find((def) => def.id === column.id);
            if (!defFromAddin) {
                return acc;
            }
            const def = ({ ...defFromAddin, ...column.overrides });
            acc.push(def);
            return acc;
        }, []);
        const sortedDefs = defs.sort((firstColumn, secondColumn) => {
            const firstColumnPosition = firstColumn.position;
            const secondColumnPosition = secondColumn.position;
            if (firstColumnPosition === undefined && secondColumnPosition === undefined) return 0;
            if (firstColumnPosition === undefined) return 1;
            if (secondColumnPosition === undefined) return -1;
            return firstColumnPosition - secondColumnPosition;
        });
        return sortedDefs;
    } , [tabData]);

    const actions = useMemo<ITableAction[]>(() => {
        const filteredByIsRelevant = EventsTableRegistry.getActions().filter(action => action.isRelevant ? action.isRelevant(tabData.id) : true);
        return filteredByIsRelevant
            .filter(action => (archiveView ? tabData.archiveActions : tabData.actions).includes(action.id))
            .sort((firstAction, secondAction) => {
                const firstActionPosition = firstAction.position;
                const secondActionPosition = secondAction.position;
                if (firstActionPosition === undefined && secondActionPosition === undefined) return 0;
                if (firstActionPosition === undefined) return 1;
                if (secondActionPosition === undefined) return -1;
                return firstActionPosition - secondActionPosition;
            });
    } , [tabData, archiveView]);

    const onGridReady = useCallback(
        (params: GridReadyEvent<IEvent, any>) => {
            params.api.closeToolPanel();
            setGridApi(params.api);
            setColumnApi(params.columnApi);

            const columnToolPanel = params.api.getToolPanelInstance('columns') as IColumnToolPanel | undefined;
            const columns: ColDef[] = [...columnDefs];
            columns.sort((firstColumn, secondColumn) => {
                const first = firstColumn.headerName?.toLowerCase() || '';
                const second = secondColumn.headerName?.toLowerCase() || '';
                return first.localeCompare(second);
            });
            columnToolPanel?.setColumnLayout(columns);
            params.api.setServerSideDatasource(dataSource);
        },
        [columnDefs, dataSource],
    );

    const handleCellClicked = (event: CellClickedEvent<IEvent>) => {
        if (event?.data?.isGrouped) return;
        onEventClicked && onEventClicked(event.data!);
    };

    const gridOptions: GridOptions<IEvent> = {
        columnDefs,
        rowSelection: 'multiple',
        onGridReady,
        getChildCount: (dataItem) => dataItem.childCount,
        getRowId: (params) => params.data.id,
        onCellClicked: handleCellClicked,
        rowBuffer: 0,
        maxConcurrentDatasourceRequests: 1,
        rowModelType: 'serverSide',
        onRowSelected: onSelectionChanged,
    };

    const exportButtons = useMemo<ITableExportButton[] | undefined>(() => {
        if (!gridApi || !columnApi) return undefined;
        const buttonsToGet = tabData.exportOptions;
        if (!buttonsToGet) return undefined;
        const filtered = EventsTableRegistry.getExports().filter(action => buttonsToGet.includes(action.id));
        return filtered.map((action) => ({
            label: action.name,
            icon: action.icon,
            onClick: () => action.callback(gridApi, columnApi, dataSource),
            disabled: action.isActionDisabled && action.isActionDisabled(gridApi, columnApi, dataSource),
            tooltip: action.tooltip && action.tooltip(gridApi, columnApi, dataSource),
        }));
    } , [tabData.exportOptions, dataSource, gridApi, columnApi]);

    if (isLoading) {
        return (
            <Table
                gridOptions={{
                    columnDefs
                }}
                actions={actions}
                exportButtons={exportButtons}
                pageSize={pageSize}
                footer={(
                    <Spinner size={12} />
                )}
            />
        );
    }

    return (
        <EventsTableStyles.TableWrapper fullHeight>
            <Table<IEvent>
                tableId={tableId}
                saveColumnsState
                key={tableId}
                pageSize={pageSize}
                gridOptions={gridOptions}
                exportButtons={exportButtons}
                actions={actions}
                footer={(
                    <Stack spacing={6} direction='row' alignItems='center'>
                        <Typography>{`Showing ${dataSource.rowCount.toLocaleString()} of ${dataSource.totalRowCount.toLocaleString()} events`}</Typography>
                        {dataSource.isFeching && <Spinner size={12} />}
                    </Stack>
                )}
                isLoading={isLoading}
            />
            {!drawer && (
                <Modals
                    openFrom={EventsModalOpenFrom.TABLE}
                    modalType={modalType}
                    closeModal={() => setModalType(null)}
                    setModalType={setModalType}
                    selectedRows={selectedRows}
                    resetSelectedRows={resetSelectedRows}
                    requestNewData={() => gridApi?.refreshServerSide({ purge: true })}
                />
            )}
        </EventsTableStyles.TableWrapper>
    );
};

export default EventsTable;
