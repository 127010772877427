import { isEmptyString } from 'common/utils/helpFunctions';
import { IntelligenceNodeData } from 'common/module_interface/intelligence/intelligenceNodeData';
import { NodeDetailsItem } from './Components/NodeDetailsComponent';
import { getNetworkNodePopoverDetailsList } from '../NetworkTraffic/Utils';
import i18n from 'i18next';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';

export function getNodePopoverDetailsList(props: IntelligenceNodeData): NodeDetailsItem[]{
    const cloudAccountName = props.additionalData?.cloudAccount?.name;
    const cloudAccountId = props.additionalData?.cloudAccount?.externalId;

    const basicNodeDetails: NodeDetailsItem[] = [{
        label: i18n.t('INT_GRAPH.NODE_DETAILS.ID', { ns: i18nIntelligenceNamespace }),
        value: props.id,
        isRelevant: props.type !== 'malicious' && !isEmptyString(props.id)
    },
    {
        label: i18n.t('INT_GRAPH.NODE_DETAILS.TYPE', { ns: i18nIntelligenceNamespace }),
        value: props.type,
        isRelevant: props.type !== 'malicious' && !isEmptyString(props.type)
    },
    {
        label: i18n.t('INT_GRAPH.NODE_DETAILS.NAME', { ns: i18nIntelligenceNamespace }),
        value: props.name,
        isRelevant: props.type !== 'malicious' && !isEmptyString(props.name)
    },
    {
        label: i18n.t('INT_GRAPH.NODE_DETAILS.CLOUD_ACCOUNT_NAME', { ns: i18nIntelligenceNamespace }),
        value: cloudAccountName,
        isRelevant: !isEmptyString(cloudAccountName)
    },
    {
        label: i18n.t('INT_GRAPH.NODE_DETAILS.CLOUD_ACCOUNT_ID', { ns: i18nIntelligenceNamespace }),
        value: cloudAccountId,
        isRelevant: !isEmptyString(cloudAccountId)
    },
    {
        label: i18n.t('INT_GRAPH.NODE_DETAILS.USER_AGENT', { ns: i18nIntelligenceNamespace }),
        value: props.additionalData?.userAgent,
        isRelevant: !isEmptyString(props.additionalData?.userAgent)
    }];

    basicNodeDetails.push(...getNetworkNodePopoverDetailsList(props));

    return basicNodeDetails;
}
export function preventNodeSelection(event: any){
    event.stopPropagation();
}
