import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams } from 'ag-grid-community';
import { Stack, Typography } from 'common/design-system/components-v2';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import styled from 'styled-components';

const ErrorDetailsWrapper = styled(Stack)`
    &:hover {
        background-color: white;
    }
`;

const ErrorTextTypography = styled(Typography)`
    white-space: pre-wrap;
    word-wrap: break-word;    
`;

const BotTriggerErrorWrapper = styled(Stack)``;
const BotExecutionErrorWrapper = styled(Stack)``;

const CloudBotErrorDetailsCellRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    return <ErrorDetailsWrapper padding={[2, 2, 4, 2]} spacing={2}>
        {params.data?.errorMessage &&
            <BotTriggerErrorWrapper spacing={1}>
                <Typography variant='body500' color='alert'>
                    {t('CLOUD_BOTS.ERROR_DETAILS_TABLE.TRIGGER_ERROR_HEADER')}:</Typography>
                <ErrorTextTypography variant='body' color='alert'>{params.data?.errorMessage}</ErrorTextTypography>
            </BotTriggerErrorWrapper>
        }
        {params.data?.executionErrorMessage &&
            <BotExecutionErrorWrapper spacing={1}>
                <Typography variant='body500' color='alert'>
                    {t('CLOUD_BOTS.ERROR_DETAILS_TABLE.EXECUTION_ERROR_HEADER')}:</Typography>
                <ErrorTextTypography variant='body' color='alert'>{params.data?.executionErrorMessage}</ErrorTextTypography>
            </BotExecutionErrorWrapper>
        }
    </ErrorDetailsWrapper>;
};

export default CloudBotErrorDetailsCellRenderer;
