import { INumbersMap } from 'common/utils/helpFunctions';
import { IFieldInfo } from 'common/interface/general';
import { getRiskManagementService, IRiskDataGroup } from 'common/module_interface/RiskManagement/Services';
import { getCloudAccountsService, getOrganizationalUnitService, ICloudAccount } from 'common/interface/data_services';
import { getCompactFilterFields } from '../../../../../RiskManagement.utils';
import { ASSET_FIELD_NAMES } from '../../../../../consts';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';
import { getRiskLevelInfo, IRiskLevelInfo } from 'common/module_interface/RiskManagement/RiskLevel';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { ICompactFilterItem } from '../../../../../RiskManagement.filters';

interface IEnvFullInfo {
    id: string,
    env: ICloudAccount,
    envRiskModel: IRiskDataGroup,
}

export const getEnvRiskLevelsMap = async (filterFields?: IFieldInfo[]): Promise<INumbersMap> => {
    const compactFields: ICompactFilterItem[] = getCompactFilterFields(filterFields) || [];

    const orgUnitsFilter: ICompactFilterItem | undefined = compactFields.find((item: ICompactFilterItem) => item.name === ASSET_FIELD_NAMES.organizationalUnitId);
    const orgUnitsIds: string[] = (orgUnitsFilter?.value || []) as string[];
    let filterOrgUnitIdsSet: Set<string> | undefined;
    if (orgUnitsIds.length && !orgUnitsIds.includes(DEFAULT_ORGANIZATIONAL_UNIT_ID)) {
        const filterOrgUnitIds: string[] = await getOrganizationalUnitService().getFlatOrgUnitIdsBySelection(orgUnitsIds);
        filterOrgUnitIdsSet = new Set<string>(filterOrgUnitIds);
    }

    const platformsFilter: ICompactFilterItem | undefined = compactFields.find((item: ICompactFilterItem) => item.name === ASSET_FIELD_NAMES.platform);
    const platformsSet: Set<string> | undefined = platformsFilter ? new Set<string>(platformsFilter.value as string[]) : undefined;

    const allEnvs = await getCloudAccountsService().getAllCloudAccounts();
    const allEnvsMap: { [key: string]: ICloudAccount } = {};
    allEnvs.forEach((env: ICloudAccount) => allEnvsMap[env.id] = env);
    const envsFilter: ICompactFilterItem | undefined = compactFields.find((item: ICompactFilterItem) => item.name === ASSET_FIELD_NAMES.cloudAccountId);
    const filterEnvIdsSet: Set<string> | undefined = envsFilter ? new Set<string>(envsFilter.value as string[]) : undefined;

    const envRisksModels = await getRiskManagementService().getAllEnvironmentsRisk();
    const envRisks: IEnvFullInfo[] = envRisksModels.map((envRiskModel: IRiskDataGroup) => {
        const env = allEnvsMap[envRiskModel.id];
        return {
            id: envRiskModel.id,
            env,
            envRiskModel,
        };
    });

    const filteredEnvRisks = (filterOrgUnitIdsSet || filterEnvIdsSet || platformsSet) ? envRisks.filter((envRisk: IEnvFullInfo) => {
        if (filterOrgUnitIdsSet && !filterOrgUnitIdsSet.has(envRisk.env.organizationalUnitId)) {
            return false;
        }
        if (platformsSet && !platformsSet.has(envRisk.env.platform)) {
            return false;
        }
        return !(filterEnvIdsSet && !filterEnvIdsSet.has(envRisk.id));

    }) : envRisks;

    const riskLevelsMap: INumbersMap = {};
    filteredEnvRisks.forEach((envRisk: IEnvFullInfo) => {
        const riskLevelInfo: IRiskLevelInfo | undefined = getRiskLevelInfo(envRisk.envRiskModel.riskLevel);
        if (riskLevelInfo && (riskLevelInfo.level >= 0)) {
            const riskLevelKey = riskLevelInfo.key;
            riskLevelsMap[riskLevelKey] = riskLevelsMap[riskLevelKey] ?? 0;
            riskLevelsMap[riskLevelKey]++;
        }
    });
    return riskLevelsMap;
};

export const getEnvRouteFields = (filterValues?: IFiltersValues): IFieldInfo[] | undefined => {
    if (!filterValues?.fields) {
        return;
    }

    const fields: IFieldInfo[] = filterValues.fields.filter((field: IFieldInfo) => (field.name === ASSET_FIELD_NAMES.organizationalUnitId) || (field.name === ASSET_FIELD_NAMES.platform));
    return fields.length > 0 ? fields : undefined;
};