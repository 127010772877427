import { Stack, Tooltip, Typography } from 'common/design-system/components-v2';
import { Link } from 'common/erm-components/basic';

interface ExternalLinkHeaderProps {
    headerName: string,
    tooltipText: string,
    url: string
}

export const ExternalLinkHeaderRenderer = ({ headerName, tooltipText, url }: ExternalLinkHeaderProps) =>
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Typography variant={'body500'} >{headerName}</Typography>
        <Tooltip content={
            <Typography>
                {tooltipText}
            </Typography>
        }>
            <Link externalUrl={url} leadingIconProps={{ name: 'tips', size: 14 }} />
        </Tooltip>
    </Stack>;
