import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { ServerlessAwsAccountStatesGet } from './serverless.interface';

const serverlessAwsAccountStatesGet: ServerlessAwsAccountStatesGet.Function = async (id, cachingConfig) => {
    return await getHttpService().request<AxiosResponse<ServerlessAwsAccountStatesGet.Response>>(
        `serverless/aws/accounts/states/${id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true, cachingConfig: { useCache: true, dataAgeLimit: 5 * 60 * 1000, ...(cachingConfig || {}) } },
        err => { throw err; }
    );
};

const ServerlessService = {
    serverlessAwsAccountStatesGet
};

export default ServerlessService;
