import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import { AuthState } from './Auth.interface';


const initialState: AuthState = {
    isAuthenticated: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTokenLocalExpirationTime: (state: AuthState, action: PayloadAction<string | undefined>) => {
            state.tokenLocalExpirationTime = action.payload;
        },
        setIsAuthenticated: (state: AuthState, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setLoginId: (state: AuthState, action: PayloadAction<string>) => {
            state.login_id = action.payload;
        },
        clearAuthReducer: () => initialState,
    },
});

export const {
    setTokenLocalExpirationTime,
    setIsAuthenticated,
    clearAuthReducer,
    setLoginId,
} = authSlice.actions;

export const getIsAuthenticated = (state: RootState): boolean => state.auth.isAuthenticated;

export default authSlice.reducer;
