import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Styled from './RemoveAccount.styled';
import { Stack, Typography } from 'common/design-system/components-v2';
import { getAwsEnvNamespace } from '../../../initialize.i18n';
import { AWSCloudAccount, CloudAccountOnboardingMode } from 'common/module_interface/assets/AwsEnvironment';
export interface ManagedModalsProps {
    account: AWSCloudAccount;
}
export const ManagedOnboardingContent: React.FC<ManagedModalsProps> = ({ account }) => {
    const { t } = useTranslation(getAwsEnvNamespace('aws'));
    return (
        <Stack spacing={[2,0]}>
            <Typography>{t('awsPage.modals.removeAccount.content', { cloudAccountName: account.name })}</Typography>
            { account.onboardingMode === CloudAccountOnboardingMode.CLOUD_GUARD_MANAGED && (
                <Stack>
                    <Typography>{t('awsPage.modals.removeAccount.managedOnboarding.text_1', { cloudAccountName: account.name })}</Typography>
                    <Typography>{t('awsPage.modals.removeAccount.managedOnboarding.text_2')}</Typography>
                </Stack>
            )}
        </Stack>
    );
};
export const OrgOnboardingContent = () => {
    const { t } = useTranslation(getAwsEnvNamespace('aws'));
    return (
        <Stack spacing={2}>
            <Typography>{t('awsPage.modals.removeAccount.orgOnboarding.text.title')}</Typography>
            <Typography>{t('awsPage.modals.removeAccount.orgOnboarding.text.subtitle')}</Typography>
            <Stack padding={[0, 0, 0, 8]}>
                <Styled.List>
                    {[...Array(10).keys()].map((n) => (
                        <li key={n}>
                            <Typography>
                                <Trans
                                    i18nKey={`${getAwsEnvNamespace('aws')}:awsPage.modals.removeAccount.orgOnboarding.text.steps.${n + 1}`}
                                    components={{ b: <b />, rgs: <Styled.AsComponent /> }}
                                />
                            </Typography>
                        </li>
                    ))}
                </Styled.List>
            </Stack>
        </Stack>
    );
};
export const UnifiedOnboardingContent = () => {
    const { t } = useTranslation(getAwsEnvNamespace('aws'));
    return (
        <Stack spacing={2}>
            <Typography>{t('awsPage.modals.removeAccount.unifiedOnboarding.text.title')}</Typography>
            <Typography>{t('awsPage.modals.removeAccount.unifiedOnboarding.text.subtitle')}</Typography>
            <Stack padding={[0, 0, 0, 8]}>
                <Styled.List>
                    {[...Array(10).keys()].map((n) => (
                        <li key={n}>
                            <Typography>
                                <Trans
                                    i18nKey={`${getAwsEnvNamespace('aws')}:awsPage.modals.removeAccount.unifiedOnboarding.text.steps.${n + 1}`}
                                    components={{ b: <b />, rgs: <Styled.AsComponent /> }}
                                />
                            </Typography>
                        </li>
                    ))}
                </Styled.List>
            </Stack>
        </Stack>
    );
};
