import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import {
    RECENTLY_USED_FILTER_ID,
    SAVED_FILTERS_FILTER_ID
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';

export const ASSETS_FILTER_TYPES: { [key: string]: IProtectedAssetFilter } = {
    ADD_FILTER: { id: 'add filter' },
    FREE_TEXT: { id: 'free text' },
    ORGANIZATIONAL_UNIT: { id: 'organizational unit' },
    PLATFORM: { id: 'platform' },
    TYPE: { id: 'type' },
    ENVIRONMENT: { id: 'environment' },
    BUSINESS_PRIORITY: { id: 'businessPriority' },
    NETWORK_EXPOSURE: { id: 'networkExposure' },
    IAM_EXPOSURE: { id: 'iamExposure' },
    DATA_SENSITIVITY: { id: 'dataSensitivity' },
    CLEAR_ALL: { id: 'clear all' },
};

export const createRecentlyUsedFilter = (componentName: string) => {
    return {
        id: RECENTLY_USED_FILTER_ID,
        filterProps: { componentName },
    };
};
export const createSavedFiltersFilter = (componentName: string) => {
    return {
        id: SAVED_FILTERS_FILTER_ID,
        filterProps: {
            savedFiltersComponentName: componentName,
        },
        position: 1000,
    };
};