import React from 'react';
import { ModalsProps } from '..';
import { useTranslation } from 'react-i18next';
import { getEnvsNamespace } from '../../../initialize.i18n';
import { Message, Stack, Tooltip, Spinner, Icon } from 'common/design-system/components-v2';
import AssociateToOUStyled from './AssociateToOU.styled';
import { getVendor } from 'common/consts/vendors';
import { useAssociateToOU } from './useAssociateToOU';
import { getCloudAccountsService } from 'common/interface/data_services';
import OrganizationalUnit from 'common/components/OrganizationalUnit/OrganizationalUnit';

const AssociateToOU: React.FC<ModalsProps> = ({ closeModal, selectedRows, resetSelectedRows }) => {
    const { t } = useTranslation(getEnvsNamespace('table'));
    
    const [selectedOU, setSelectedOU] = React.useState<string>();
    const [isAssociateFinished, setIsAssociateFinished] = React.useState(false);

    const handleOnClose = () => {
        if (isAssociateFinished) {
            resetSelectedRows();
        }
        closeModal();
    };

    const handleOnAssociateFinished = () => {
        getCloudAccountsService().clearCache();
        setIsAssociateFinished(true);
    };

    const { associateToOU, isLoading, results } = useAssociateToOU({ onFinish: handleOnAssociateFinished });

    const handleOnConfirm = async () => {
        if (!selectedOU) return;
        associateToOU(selectedRows, selectedOU);
    };

    return (
        <Message
            title={t('ACTIONS.ASSOCIATE_TO_OU.TITLE')}
            text={t('ACTIONS.ASSOCIATE_TO_OU.TEXT', { count: selectedRows.length })}
            width='lg'
            onClose={handleOnClose}
            onCancel={handleOnClose}
            cancelBtnText={isAssociateFinished ? t('ACTIONS.ASSOCIATE_TO_OU.BUTTONS.CLOSE') : t('ACTIONS.ASSOCIATE_TO_OU.BUTTONS.CANCEL')}
            submitBtnText={t('ACTIONS.ASSOCIATE_TO_OU.BUTTONS.ASSOCIATE')}
            onConfirm={isAssociateFinished ? undefined : handleOnConfirm}
            isLoading={isLoading}
            submitBtnDisabled={!selectedOU}
        >

            <AssociateToOUStyled.Wrapper spacing={3} padding={1}>
                <OrganizationalUnit
                    disabled={isAssociateFinished || isLoading}
                    selectedOrganizationalUnit={selectedOU}
                    onChange={(value) => {
                        setSelectedOU(value);
                        return true;
                    }} />
                <AssociateToOUStyled.EnvsListWrapper spacing={3}>
                    {selectedRows.map((row, index) => (
                        <Stack key={`row-${index}`} direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
                            <Stack spacing={1} direction='row' alignItems='center'>
                                <Icon name={getVendor(row.platform)?.newIcon || 'refresh'} size={12} />
                                <AssociateToOUStyled.StyledTypography>{`${row.name} (${row.id})`}</AssociateToOUStyled.StyledTypography>
                            </Stack>
                            {results[row.id]?.isLoading && (
                                <Stack margin={[1, 0]}>
                                    <Spinner size={12} />
                                </Stack>)}
                            {(results[row.platform] && (results[row.platform].isSuccess || results[row.platform].error) ) && (
                                <Tooltip
                                    content={t(`ACTIONS.ASSOCIATE_TO_OU.TOOLTIPS.${results[row.platform].isSuccess ? 'ASSOCIATED' : 'FAILED_TO_ASSOCIATE'}`, { environmentName: row.name })}
                                >
                                    <Icon
                                        name={results[row.platform].isSuccess ? 'check' : 'remove'}
                                        color={results[row.platform].isSuccess ? 'success' : 'alert'}
                                        size={12}
                                    />
                                </Tooltip>
                            )}
                        </Stack>
                    ))}
                </AssociateToOUStyled.EnvsListWrapper>
            </AssociateToOUStyled.Wrapper>
        </Message>
    );
};

export default AssociateToOU;
