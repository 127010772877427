import React from 'react';
import { Card } from 'common/design-system/components-v2';
import { Trans, useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import WidgetActivityGauge from 'common/components/Widgets/ActivityGauge/ActivityGauge';
import { IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import { WAFGaugeStyled } from './WAFCoverageGauge.styled';
import { lightPallete } from 'common/design-system/theme/colors';
import { LoadingState } from 'common/interface/general';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import i18n from 'i18next';
import { useTheme } from 'styled-components';
import { wafCloudGuardInfoLink } from '../../../../WafProtection/WafProtection.consts';
import { ALink } from 'common/components/ALink';
import WAFFiltersAlert from '../WAFFiltersAlert/WAFFiltersAlert';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';

const WAFCoverageGauge: React.FC<{ loadingState: LoadingState, coverageData?: IGenericWidgetDataItem[], filterValues?: IFiltersValues }> =
    ({ loadingState, coverageData, filterValues }) => {
        const { t } = useTranslation(I18nRiskNamespace);
        const theme = useTheme();

        const options = {
            useCustomColor: true,
            suppressLegend: true,
            titleStyle: {
                fontSize: '32px',
                fontWeight: 'bold',
                color: theme.palette.status.info
            },
            subtitleStyle: {
                fontSize: '10px',
                color: lightPallete.status.info,
            }
        };

        return ( coverageData && 
        <Card title={t('RISK_MANAGEMENT.DASHBOARD.WAF.COVERAGE_GAUGE.TITLE')}>
            <WAFGaugeStyled.TopDiv>
                <WAFGaugeStyled.AlertDiv>
                    <WAFFiltersAlert filterValues={filterValues} />
                </WAFGaugeStyled.AlertDiv>
                {loadingState === LoadingState.IS_LOADING && <FullSizeSpinner />}
                {loadingState === LoadingState.LOADING_SUCCEEDED && (
                    <>
                        <WAFGaugeStyled.GaugeDiv>
                            <WidgetActivityGauge data={{ items: coverageData, options }} />
                        </WAFGaugeStyled.GaugeDiv>
                        <WAFGaugeStyled.Tip>
                            <Trans
                                i18nKey={`${I18nRiskNamespace}:RISK_MANAGEMENT.DASHBOARD.WAF.COVERAGE_GAUGE.TIP`}
                                components={{ Link: <ALink href={wafCloudGuardInfoLink} target={'_blank'} rel="noreferrer" /> }}
                            />
                        </WAFGaugeStyled.Tip>
                    </>
                )}
                {loadingState === LoadingState.LOADING_FAILED &&
                    <WAFGaugeStyled.FailureDiv fullWidth fullHeight>
                        {i18n.t('COMMON.ERROR_OCCURRED')}
                    </WAFGaugeStyled.FailureDiv>
                }
            </WAFGaugeStyled.TopDiv>
        </Card>
        );
    };

export default WAFCoverageGauge;