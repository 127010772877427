import { TFunction } from 'i18next';
import { IRuleset } from 'common/interface/ruleset';
import * as yup from 'yup';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';

const idRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export const sortRuleSetsSelectOptions = (a: IRuleset, b: IRuleset) => {
    if (a.cloudVendor < b.cloudVendor) return -1;
    if (a.cloudVendor > b.cloudVendor) return 1;
    // Second sort condition, prioritizing names starting with alphabets
    const regex = /[a-zA-Z]/;
    const aFirst = regex.test(a.name[0]) ? 0 : 1;
    const bFirst = regex.test(b.name[0]) ? 0 : 1;
    if (aFirst < bFirst) return -1;
    if (aFirst > bFirst) return 1;
    // Third sort by 'name'
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
};

export const remediationCSPMValidation = (t: TFunction) => ({
    rulesetId: yup.number().required(t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.RULESET_IS_REQUIRED')),
    cloudAccountId: yup.string().matches(idRegex, t('ON_BOARDING.AZURE.VALIDATIONS.ENVIRONMENT_ID_NOT_VALID')).notRequired().nullable(),
    platform: yup.string().required(t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.ENVIRONMENT_IS_REQUIRED')).notRequired().nullable(),
    cloudBots: yup.array().of(yup.string()).required(t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.CLOUDBOTS_ARE_REQUIRED')).min(1, t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.CLOUDBOTS_ARE_REQUIRED')),
    comment: yup.string().required(),
    logic: yup.string().nullable(),
    ruleLogicHash: yup.string().nullable(),
    ruleName: yup.string().nullable(),
});

export const remediationCiemValidation = (t: TFunction) => ({
    rulesetId: yup.number().required(t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.RULESET_IS_REQUIRED')),
    cloudAccountIds: yup.array().of(yup.string().matches(idRegex, t('ON_BOARDING.AZURE.VALIDATIONS.ENVIRONMENT_ID_NOT_VALID')).notRequired().nullable()),
    cloudAccountType: yup.string().required(t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.ENVIRONMENT_IS_REQUIRED')).notRequired().nullable(),
    platform: yup.string().required(t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.ENVIRONMENT_IS_REQUIRED')).notRequired().nullable(),
    cloudBots: yup.array().of(yup.string()).required(t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.CLOUDBOTS_ARE_REQUIRED')).min(1, t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.CLOUDBOTS_ARE_REQUIRED')),
    comment: yup.string().required(),
    logic: yup.string().nullable(),
    severities: yup.array().of(yup.string().oneOf(Object.values(FindingSeverityServerEnum))).notRequired().nullable(),
    ruleLogicHash: yup.string().nullable(),
    ruleName: yup.string().nullable(),
});
