import { Stack, Table } from 'common/design-system/components-v2';
import React, { useCallback, useMemo, useRef } from 'react';
import { GridApi, GridOptions } from 'ag-grid-community';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import withShowMoreButton from '../../../../../../../../../common/erm-components/utils/WithShowMore/WithShowMore';
import { MalwareInfo } from '../Malware.interface';

const MalwareTable: React.FC<{ malwareItems: MalwareInfo[] }> = ({ malwareItems }) => {

    const apiRef = useRef<GridApi>();

    const onGridReady = useCallback(
        (params: any) => {
            apiRef.current = params.api;
        }, []);

    const gridOptions: GridOptions = useMemo(() => {
        return {
            columnDefs: IssuesRegistry.getMalwareColumnDefs(),
            defaultColDef: {
                sortable: false,
                resizable: true,
                suppressMenu: true,
                suppressMovable: true,
            },
            rowModelType: 'clientSide',
            domLayout: 'autoHeight',
            suppressDragLeaveHidesColumns: true,
            rowData: malwareItems,
            enableRangeSelection: false,
            suppressCellFocus: true,
            onGridReady,
            masterDetail: true,
            detailRowAutoHeight: true,
        };
    }, [malwareItems, onGridReady]);

    const TableWithButton = withShowMoreButton(Table);

    return (
        <Stack fullWidth>
            <TableWithButton
                gridOptions={gridOptions}
                disableColumnMenu={true}
                disableGrouping={true}
            />
        </Stack>
    );
};

export default MalwareTable;