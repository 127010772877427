import {
    commonSingleSelectFieldItemProps, commonTextAreaItemProps,
} from 'common/erm-components/custom/CustomForm/CustomForm.consts';
import { cdrTrans } from '../../../../Intelligence.utils';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { IReportIssueMessageModal } from './ReportIssueModal.types';
import { REPORTING_ISSUE_TYPE } from './ReportIssueModal.const';
import { getLoggerService } from 'common/interface/services';
import { isArray } from 'common/utils/helpFunctions';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { ICustomFormProps, IItem, IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { getSafeTrimmedSingleValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';

enum ReportItemNames {
    issue = 'issue',
    details = 'details',
}

const getIssuesOptions = (): Promise<SelectOption[]> => {
    return Promise.resolve(Object.keys(REPORTING_ISSUE_TYPE).map((issueType) => ({
        label: cdrTrans(`REPORT_ISSUE_MODAL.ISSUE_TYPES.${issueType}`),
        value: issueType,
    })));
};

const getItems = async (): Promise<IItem[]> => {
    return [
        {
            ...commonSingleSelectFieldItemProps,
            name: ReportItemNames.issue,
            state: {
                label: cdrTrans('REPORT_ISSUE_MODAL.INPUT_FIELDS.ISSUE_TYPE.TITLE'),
                options: await getIssuesOptions(),
                isRequired: true,
            },
        },
        {
            ...commonTextAreaItemProps,
            name: ReportItemNames.details,
            state: {
                isRequired: true,
                charsLimit: 200,
                label: cdrTrans('REPORT_ISSUE_MODAL.INPUT_FIELDS.DETAILS.TITLE'),
            },
        },
    ];
};

export const getSuccessNotification = (): string => {
    return cdrTrans('REPORT_ISSUE_MODAL.STATUS.SUCCESS_MESSAGE');
};

export const getFailedNotification = (): string => {
    return cdrTrans('REPORT_ISSUE_MODAL.STATUS.ERROR_MESSAGE');
};

const sendReport = async (itemsMap: IItemsMap, data?: any): Promise<void> => {
    if (!data || !isArray(data)) {
        return Promise.resolve();
    }
    const findings: IFinding[] = data as IFinding[];

    const issueType: string = getSafeTrimmedSingleValue(itemsMap[ReportItemNames.issue]);
    const details: string = getSafeTrimmedSingleValue(itemsMap[ReportItemNames.details]);
    const messages: string[] = findings.map((finding: IFinding) => {
        const message: IReportIssueMessageModal = {
            reportType: REPORTING_ISSUE_TYPE[issueType as keyof typeof REPORTING_ISSUE_TYPE],
            description: details,
            finding: finding,
        };
        return JSON.stringify(message);
    });
    getLoggerService()
        .info(messages, undefined, ['Finding Alerts Report Tracking'])
        .catch(() => console.log('error while sending report'));
};

export const getReportFormProps = (): ICustomFormProps => {
    return {
        getItems,
        submitData: sendReport,
        getSubmitSuccessNotification: getSuccessNotification,
        getSubmitFailedNotification: getFailedNotification,
        title: cdrTrans('REPORT_ISSUE_MODAL.TITLE'),
        isShortDialog: true,
    };
};

export const showCdrReportForm = (findings: IFinding[]) => {
    globalModelUtils.showCustomFormModal({
        formProps: getReportFormProps(),
        data: findings,
    });
};
