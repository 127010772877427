import React, { useState } from 'react';
import { Select } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import { i18nReportsNamespace } from 'modules/reporting/initialize.i18n';
import {
    getTimeRangeNumberOptions,
    translations,
} from '../Reporting.utils';
import { ReportIntervalValue } from '../Reporting.const';
import { ISelectOption, IScheduleDetails } from '../interface/Reporting.interface';
import { CronStringInputContainer } from './ReportingModal/ReportingModal.styled';
import { SingleValue } from 'react-select';

export const CronStringInput: React.FC<{
    onSettingsChange: (settings: IScheduleDetails) => void
    scheduleConfig: IScheduleDetails;
}> = ({ onSettingsChange, scheduleConfig }) => {
    const { t } = useTranslation(i18nReportsNamespace);
    const [repeatInterval, setRepeatInterval] = useState<ReportIntervalValue>(
        scheduleConfig?.dayOfMonth
            ? ReportIntervalValue.Monthly
            : scheduleConfig?.dayOfWeek
                ? ReportIntervalValue.Weekly
                : ReportIntervalValue.Daily,
    );
    const [hour, setHour] = useState<number>(scheduleConfig?.hourOfDay ?? 0);
    const [minute, setMinute] = useState<number>(scheduleConfig?.minuteOfHour ?? 0);
    const [day, setDay] = useState<number>(
        scheduleConfig?.dayOfMonth
            ? scheduleConfig.dayOfMonth
            : scheduleConfig?.dayOfWeek
                ? scheduleConfig.dayOfWeek
                : 1,
    );
    const [dayOptions, setDayOptions] = useState<ISelectOption[]>(
        scheduleConfig?.dayOfMonth
            ? getTimeRangeNumberOptions(31)
            : scheduleConfig?.dayOfWeek
                ? getTimeRangeNumberOptions(7, 1, false, true)
                : getTimeRangeNumberOptions(1, 1, false, false),
    );
    const [hourOptions] = useState<ISelectOption[]>(
        getTimeRangeNumberOptions(23, 0, false),
    );
    const [minuteOptions] = useState<ISelectOption[]>(
        getTimeRangeNumberOptions(59, 0, false),
    );
    const [isDayDisabled, setIsDayDisabled] = useState<boolean>(
        repeatInterval === ReportIntervalValue.Daily,
    );

    const repeatIntervalOptionsTranslated = translations(t).repeatIntervalOptions;

    const onRepeatIntervalChange = (option: ISelectOption) => {
        option?.value && setRepeatInterval(option.value as number);
        const defaultDayOptions = getTimeRangeNumberOptions(7, 1, false, false);
        const defaultDay = defaultDayOptions.find((opt) => opt.value === 1)?.value as number;
        setIsDayDisabled(false);
        let updatedDay: number = day;

        switch (option.value) {
            case ReportIntervalValue.Daily:
                setDayOptions(defaultDayOptions);
                setIsDayDisabled(true);
                setDay(defaultDay);
                updatedDay = defaultDay;
                break;
            case ReportIntervalValue.Monthly:
                setDayOptions(getTimeRangeNumberOptions(31));
                break;
            default:
                if (day > 7) setDay(defaultDay);
                setDayOptions(getTimeRangeNumberOptions(7, 1, false, true));
                updatedDay = defaultDay;
        }
        return { updatedDay };
    };

    return (
        <div>
            <div className="flex items-start gap-x-8 h-[150px]">
                <CronStringInputContainer>
                    <div className="w-[100px]">
                        <Select
                            required={false}
                            value={repeatIntervalOptionsTranslated.find((t) => t.value === repeatInterval)}
                            onChange={(option: SingleValue<ISelectOption>) => {
                                if (!option?.value) return;
                                const { updatedDay } = onRepeatIntervalChange(option);
                                const obj = {
                                    minuteOfHour: minute,
                                    hourOfDay: hour,
                                    dayOfWeek:
                                        option.value === ReportIntervalValue.Weekly ? updatedDay : null,
                                    dayOfMonth:
                                        option.value === ReportIntervalValue.Monthly ? updatedDay : null,
                                };
                                onSettingsChange(obj);
                            }}
                            options={ repeatIntervalOptionsTranslated }
                            maxMenuHeight={110}
                            closeMenuOnSelect
                        />
                    </div>
                </CronStringInputContainer>
                <CronStringInputContainer>
                    <label>{t('REPORTS.MODAL.ON_DAY')}:</label>
                    <div className='w-[120px]'>
                        <Select
                            required={false}
                            value={dayOptions.find((t) => t.value === day)}
                            onChange={(option: SingleValue<ISelectOption>) => {
                                if (!option?.value) return;
                                setDay(option.value as number);
                                const obj: IScheduleDetails = {
                                    minuteOfHour: minute,
                                    hourOfDay: hour,
                                    dayOfWeek: repeatInterval === ReportIntervalValue.Weekly ? option.value as number : null,
                                    dayOfMonth: repeatInterval === ReportIntervalValue.Monthly ? option.value as number : null,
                                };
                                onSettingsChange(obj);
                            }}
                            isDisabled={isDayDisabled}
                            options={dayOptions}
                            maxMenuHeight={110}
                            closeMenuOnSelect
                        />
                    </div>
                </CronStringInputContainer>
                <CronStringInputContainer>
                    <label>{t('REPORTS.MODAL.AT_HOUR')}:</label>
                    <div className='w-[70px]'>
                        <Select
                            required={false}
                            value={hourOptions.find((t) => t.value === hour)}
                            onChange={(option: SingleValue<ISelectOption>) => {
                                const hourValue = option?.value as number ?? 0;
                                setHour(hourValue);
                                const obj: IScheduleDetails = {
                                    minuteOfHour: minute,
                                    hourOfDay: hourValue,
                                    dayOfWeek: repeatInterval === ReportIntervalValue.Weekly ? day : null,
                                    dayOfMonth: repeatInterval === ReportIntervalValue.Monthly ? day : null,
                                };
                                onSettingsChange(obj);
                            }}
                            options={hourOptions}
                            maxMenuHeight={110}
                            closeMenuOnSelect
                        />
                    </div>
                </CronStringInputContainer>
                <CronStringInputContainer>
                    <label>{t('REPORTS.MODAL.AT_MINUTE')}:</label>
                    <div className='w-[70px]'>
                        <Select
                            required={false}
                            value={minuteOptions.find((t) => t.value === minute)}
                            onChange={(option: SingleValue<ISelectOption>) => {
                                const minuteValue = option?.value as number ?? 0;
                                setMinute(minuteValue);
                                const obj: IScheduleDetails = {
                                    minuteOfHour: minuteValue,
                                    hourOfDay: hour,
                                    dayOfWeek: repeatInterval === ReportIntervalValue.Weekly ? day : null,
                                    dayOfMonth: repeatInterval === ReportIntervalValue.Monthly ? day : null,
                                };
                                onSettingsChange(obj);
                            }}
                            options={minuteOptions}
                            maxMenuHeight={110}
                            closeMenuOnSelect
                        />
                    </div>
                    <div className='mx-6'>({t('REPORTS.MODAL.UTC')})</div>
                </CronStringInputContainer>
            </div>
        </div>
    );
};
