/* eslint-disable react/prop-types */

import { Select } from '@dome9/berries/react-components';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import { ICloudAccount, IOrganizationalUnitTreeNode } from 'common/interface/data_services';
import { ISelectOption } from 'common/interface/general';
import {
    IEventsFilter,
    PATH_EVENTS_TREND_WIDGETS_FILTERS,
    getEventsService,
} from 'common/module_interface/events/Events';
import { IDashboardWidget } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { getEventsNamespace } from 'modules/events/initialize.i18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { alertsFilterPanelDataGetter, getRawSearchData } from '../WidgetsData/AlertsDataGetter';
import { IAlertOptionsItem } from '../WidgetsData/AlertsTrendDataGetter';
import { renderFilterPanelForWidgets } from 'common/components/FilterPanel/DashboardFilterPanel/dashboardFilterUtils';
import { getAggregationsFunction } from './AlertsWidgetsSettings';
import { SingleValue } from 'react-select';


interface IAlertsTrendWidgetSettingsProps {
    alertsOption: IAlertOptionsItem,
    index: number,
    widget: IDashboardWidget,
    updateAlertOptionCallback: Function,
    updateAlertFilterCallback: Function,
    optionsData: { ouFlatTree: ouFlatTree[], rootOu : IOrganizationalUnitTreeNode | null, allCloudAccounts: ICloudAccount[], ousSelectOptions : ISelectOption[], envsSelectOptions: ISelectOption[] }
 }


interface ouFlatTree {
    name: string,
    id: string,
    path: string[],
    pathIds: string[],
}

let aggregationsCache: any = null;
let debounceTimeoutId: any = null;


const AlertsOptionLineSettings: React.FC<IAlertsTrendWidgetSettingsProps> = ({ alertsOption, index, widget, updateAlertOptionCallback, updateAlertFilterCallback , optionsData }) => {
    const { t } = useTranslation(getEventsNamespace('translation'));
    const [title, setTitle] = useState(alertsOption.title || '');
    const [filterPanelComponent, setFilterPanelComponent] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);
    const ENV = 'Environment';
    const OU = 'Organizational Unit';
    const NONE = 'None';
    const typeEnvOrOuSelectOptions = [
        { label: t('WIDGETS.NONE'), value: NONE },
        { label: t('WIDGETS.ENVIRONMENT'), value: ENV },
        { label: t('WIDGETS.ORGANIZATIONAL_UNIT'), value: OU },
    ];
    const { ouFlatTree, rootOu, allCloudAccounts, ousSelectOptions, envsSelectOptions } = optionsData;

    const updateAlertOption = (_alertsOption: any) => {
        updateAlertOptionCallback(_alertsOption, index);
    };

    const handleFilterChange = (filterValues:any) => {
        if(JSON.stringify(widget.options.alertsOptions[index].widgetConfigFilterState) !== JSON.stringify(filterValues)){
            updateAlertFilterCallback(filterValues,index);
        }
    };

    useEffect(() => {
        // if(alertsOption.type !== 'None' && alertsOption.selectedName !==null) {
        //     setIsFilterPanelInitiated(true);
        // }
        if(alertsOption.type !== 'NONE' && ( alertsOption.selectedName !== null || alertsOption.selected !== null)){
            const initFilter = async () => {
                const filters = globalAddinContainer.get<IEventsFilter>(PATH_EVENTS_TREND_WIDGETS_FILTERS);
                if (!aggregationsCache) {
                    const searchResponse = await alertsFilterPanelDataGetter({} as IDashboardWidget, getRawSearchData);
                    aggregationsCache = searchResponse.aggregations;
                }
                const aggregations = aggregationsCache;
                const filterPanel = aggregations && await renderFilterPanelForWidgets('trend' + index,
                    filters, getAggregationsFunction, getEventsService().getFilterDefs, {},null,null, handleFilterChange, aggregations, alertsOption.widgetConfigFilterState);

                filterPanel && setFilterPanelComponent(filterPanel);

                setIsLoading(false);
            };


            initFilter();
        }
    }, [alertsOption.type, alertsOption.selectedName, alertsOption.selected, widget]);

    // useEffect(() => {
    //     if(isFilterPanelInitiated) {
    //         initFilter();
    //     }
    // }, [isFilterPanelInitiated]);
    const handleChangeFilterType = (option: SingleValue<ISelectOption>) => {
        if (!option) return;
        const { value } = option;
        //const _alertsOption = deepCloneObject(alertsOption);
        if(value === NONE) {
            updateAlertOption(alertsOption);
            alertsOption.type = null;
            alertsOption.selected = null;
            alertsOption.selectedName = null;
        }else {
            alertsOption.type = value;
            alertsOption.selected = null;
            alertsOption.selectedName = null;
        }
        updateAlertOption(alertsOption);
    };

    const handleChangeOu = async (option: SingleValue<ISelectOption>) => {
        if (!option) return;
        const { value } = option;
        const isRootOuSelected = value === rootOu?.item.id;
        let selectedCloudAccounts = [] as string[];

        if(!isRootOuSelected && value) {
            const ouPathIds = ouFlatTree.reduce((result: string[], item) => {
                if(item.pathIds.includes(value)){
                    result.push(item.id);
                }
                return result;
            }, []);
            selectedCloudAccounts = allCloudAccounts.reduce((result: string[], account) => {
                if(ouPathIds?.includes(account.organizationalUnitId)){
                    result.push(account.externalId);
                }
                return result;
            }, []);
        }

        alertsOption.selected = selectedCloudAccounts;
        alertsOption.selectedName = value;
        updateAlertOption(alertsOption);
    };

    const handleChangeEnv = async (option: SingleValue<ISelectOption>) => {
        if (!option) return;
        const { value } = option;
        // @ts-ignore
        alertsOption.selected = value;
        alertsOption.selectedName = value;
        updateAlertOption(alertsOption);
    };

    const handleChangeLineTitle = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setTitle(value);
        clearTimeout(debounceTimeoutId);

        debounceTimeoutId = setTimeout(() => {
            const _alertsOption = deepCloneObject(alertsOption);
            _alertsOption.title = value;
            updateAlertOption(_alertsOption);
        }, 500);


    };


    return (
        <div className='mb-8'>
            <div className='flex mb-4'>
                <div className='flex-1 w-[37%] mr-[2%]'>
                    <div className="font-semibold mb-1">{t('WIDGETS.TYPE')}:</div>
                    <Select
                        required={false}
                        value={typeEnvOrOuSelectOptions?.filter(option => option.value === alertsOption.type)}
                        onChange={(option: SingleValue<ISelectOption>) => handleChangeFilterType(option)}
                        isSearchable={true}
                        options={typeEnvOrOuSelectOptions}
                        closeMenuOnSelect
                    />
                </div>
                <div className='flex-1 w-[60%]'>
                    {alertsOption.type === OU &&
                    <div>
                        <div className="font-semibold mb-1">{t('WIDGETS.SELECT_OU')}:</div>
                        <Select
                            required={false}
                            value={ousSelectOptions?.filter(option => option.value === alertsOption.selectedName)}
                            onChange={(option: SingleValue<ISelectOption>) => handleChangeOu(option)}
                            isSearchable={true}
                            options={ousSelectOptions}
                            closeMenuOnSelect
                        />
                    </div>
                    }
                    {alertsOption.type === ENV &&
                    <div>
                        <div className="font-semibold mb-1">{t('WIDGETS.SELECT_ENVIRONMENT')}:</div>
                        <Select
                            required={false}
                            value={envsSelectOptions?.filter(option => option.value === alertsOption.selectedName)}
                            onChange={(option: SingleValue<ISelectOption>) => handleChangeEnv(option)}
                            isSearchable={true}
                            options={envsSelectOptions}
                            closeMenuOnSelect
                        />
                    </div>
                    }
                </div>
            </div>
            {(alertsOption.type === OU || alertsOption.type === ENV) &&
                <div className='mb-4'>
                    <div className="font-semibold mb-2">{t('WIDGETS.LINE_TITLE')}:</div>
                    <input type='text'
                        className='text_field'
                        value={title}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeLineTitle(event)} />
                </div>
            }
            <div>
                {(alertsOption.type !== 'NONE' && ( alertsOption.selectedName !== null || alertsOption.selected !== null)) ?
                    isLoading ?
                        <div className="mb-2">{t('WIDGETS.LOADING_FILTERS')}</div>
                        :
                        <>
                            <div className="font-semibold mb-2">{t('WIDGETS.FILTERS')}:</div>
                            {filterPanelComponent}
                        </>
                    :
                    <></>
                }
            </div>
        </div>
    );
};


export default AlertsOptionLineSettings;
