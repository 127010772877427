import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBox } from 'common/erm-components/custom/SummaryStatus/StatusBox/StatusBox';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { CoverageStatusStyled } from './CoverageStatus.styled';
import { CDR_ENVIRONMENT_TABLE_FIELDS_FULL_NAMES } from '../../CdrDashboard.const';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';
import { lightPallete } from 'common/design-system/theme/colors';
import { StatusGauge } from 'common/erm-components/custom/SummaryStatus/StatusGauge/StatusGauge';
import { ICoverageData } from '../../CdrDashboard.interface';

const CoverageStatus: React.FC<{ data: ICoverageData }> = ({ data }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const gotToEnvironmentTableWithCDRFilter = () => {
        getErmUrlsService().gotoEnvironmentTable(([{ name: CDR_ENVIRONMENT_TABLE_FIELDS_FULL_NAMES.cdrEnabled, value: false }]));
    };

    return (
        <StatusBox text={t('CDR_DASHBOARD.SUMMARY_STATUS.COVERAGE.TITLE')}>
            <StatusGauge
                value={data.value}
                outOf={data.outOf}
                color={lightPallete.status.info}
                messages={[t('CDR_DASHBOARD.SUMMARY_STATUS.COVERAGE.DESCRIPTION', { coverage: data.coverage })]}
            />
            <CoverageStatusStyled.ButtonDiv data-aid='improve-coverage' onClick={gotToEnvironmentTableWithCDRFilter} 
                variant={'outlined'} color={'brandPrimary'}>
                {t('CDR_DASHBOARD.SUMMARY_STATUS.COVERAGE.IMPROVE_NOW')}
            </CoverageStatusStyled.ButtonDiv>
        </StatusBox>
    );
};

export default CoverageStatus;
