import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'common/utils/functions';
import { ISearcher } from './MainNavigationBar.interface';
import { Icon, Input, Stack, Tabs } from 'common/design-system/components-v2';
import { ITabItemProps } from 'common/design-system/components-v2/Tabs/Tabs.types';
import { useLocalStorage } from 'common/hooks/useLocalStorage';

function MainNavigationBar(props: { searchers: ISearcher[], onSelect: Function }) {

    const { searchers,onSelect } = props;
    const [tabsOptions, setTabOptions] = useState<ITabItemProps[]>([]);
    const [selectedTabId, setSelectedTabId] = useLocalStorage<string | undefined>(undefined, 'MainApplicationSearch_SelectedTabId');
    const [searchText, setSearchText] = useState<string>('');
    const { t } = useTranslation();

    useEffect(() => {
        const tabs: ITabItemProps[] = [];
        for (const searcher of searchers) {
            tabs.push({ value: searcher.id, label: searcher.title });
        }
        if (tabs.length > 0 && (selectedTabId === undefined || tabs.find((tab) => tab.value === selectedTabId) === undefined)) {
            setSelectedTabId(String(tabs[0].value));
        }
        setTabOptions(tabs);
    }, []);

    const ComponentToRender = searchers.find((searcher) => searcher.id === selectedTabId)?.component;

    return (<div data-aid='search-navigation-view'>
        <Input
            placeholder={t('MAIN_NAVIGATION_BAR.SEARCH_TEXT')}
            startAdornment={(<Icon name={'magnify'}></Icon>)}
            autoFocus={true}
            enterKeyHint={'search'}
            fullWidth
            clearable
            onChange={debounce((event: any) => setSearchText(event?.target?.value ?? ''), 700)}
            dataAid='search-navigation' />
        <div className='mt-8 overflow-y-auto h-[450px]'>
            <Stack fullWidth padding={[1, 0, 0, 0]}>
                <Tabs
                    tabs={tabsOptions}
                    selectedTab={selectedTabId ?? ''}
                    onTabSelected={(tab) => setSelectedTabId(String(tab.value))}
                    alignment='start'
                    tabsMaxWidth={200}
                />
            </Stack>
            <Stack padding={[1,0,0,0]} direction={'column'}>
                {ComponentToRender ? <ComponentToRender searchStr={searchText} onResultClicked={onSelect}></ComponentToRender> : <></>}
            </Stack>
        </div>
    </div>);
}

export default MainNavigationBar;
