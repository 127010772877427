import { Modal } from '@dome9/berries/react-components';
import styled from 'styled-components/macro';

const ModalContent = styled(Modal.ModalContent)`
    padding: ${({ theme }) => `${theme.spacing(6)}${theme.units}`};
    opacity: 1;
    background-color: ${({ theme }) => theme.palette.surface.primary};
    flex: 1;
    max-height: 80vh;
    overflow: auto;

    border-radius: ${({ theme }) => `0 0 ${theme.spacing(2)}${theme.units} ${theme.spacing(2)}${theme.units}`};
`;

export default {
    ModalContent,
};