import React from 'react';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { DeleteModalProps } from '..';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import { getAlibabaEnvNamespace } from '../../../initialize.i18n';
import { useGetAlibaba } from '../../../reducer/hooks/useGetAlibaba';
import { useRemoveAlibaba } from '../../../reducer/hooks/useRemoveAlibaba';
import { getCloudAccountsService } from 'common/interface/data_services';
import { changeUrl } from 'common/utils/http';
import { ModalType } from '../../../AlibabaEnvironment.types';

const RemoveAlibabaModal: React.FC<DeleteModalProps> = ({ forceDelete = false, closeModal, alibabaId, setModalType }) => {
    const { t } = useTranslation(getAlibabaEnvNamespace('alibaba'));
    const { data: alibabaData } = useGetAlibaba(alibabaId);

    const handleOnRemoveSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('alibabaPage.modals.removeAccount.onSubmit.success'),
            text: '',
        });
        getCloudAccountsService().clearCache();
        onClose();
        changeUrl('/cloud-account');
    };

    const handleOnRemoveError = ({ message }: { message: string }) => {
        if (message.includes('409')) {
            return setModalType(ModalType.RemoveWithPolicies);
        }
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('alibabaPage.modals.removeAccount.onSubmit.error'),
            text: '',
        });
        onClose();
    };

    const { deleteAccount, isLoading: isDisableLoading } = useRemoveAlibaba({ onSuccess: handleOnRemoveSuccess, onError: handleOnRemoveError });

    const onClickRemoveAccount = async () => {
        if (!alibabaData) return;
        deleteAccount(alibabaId, forceDelete);
    };

    const onClose = React.useCallback(() => {
        closeModal();
    }, [closeModal]);


    return (
        <ConfirmationModal
            title={forceDelete ? t('alibabaPage.modals.removeAccountWithPolicies.header', { name: alibabaData?.account.name }) : t('alibabaPage.modals.removeAccount.header', { name: alibabaData?.account.name })}
            text={forceDelete ? t('alibabaPage.modals.removeAccountWithPolicies.text', { name: alibabaData?.account.name }) : t('alibabaPage.modals.removeAccount.text', { name: alibabaData?.account.name })}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={onClickRemoveAccount}
            isLoading={isDisableLoading}
            width='md'
            submitBtnText={t('alibabaPage.modals.removeAccount.submit')}
            submitBtnColor='alert'
        />
    );
};

export default RemoveAlibabaModal;
