import { ICustomFormProps, IItem } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import {
    getCommonIgnoreFailedNotification,
    getCommonIgnoreItemsMap,
    getCommonIgnoreSuccessNotification,
} from '../CommonIgnoreList/CommonIgnoreList.items';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import { ICveIgnorePartial } from './CveIgnoreList.interface';
import { ICommonItemsMap } from '../CommonIgnoreList/CommonIgnoreList.interface';
import { CVE_IGNORE_LIST_PAGE_ID } from './CveIgnoreList.consts';
import { fetchAllCveIgnoreItems, saveIgnoreItem } from './CveIgnoreList.datasource';
import { IIssueCve } from '../../Issues/Issues.interface';
import { ermTrans } from '../../../RiskManagement.utils';
import { CommonIgnoreNames } from '../CommonIgnoreList/CommonIgnoreList.consts';

const getItems = async (ignoreItem?: ICveIgnorePartial): Promise<IItem[]> => {
    const commonItemsMap: ICommonItemsMap = await getCommonIgnoreItemsMap(fetchAllCveIgnoreItems, ignoreItem);
    return [
        commonItemsMap[CommonIgnoreNames.name],
        commonItemsMap[CommonIgnoreNames.description],
        commonItemsMap[CommonIgnoreNames.expirationDate],
        commonItemsMap[CommonIgnoreNames.cveBox],
        commonItemsMap[CommonIgnoreNames.vulBox],
    ];
};

export const getCveIgnoreForm = (origIgnoreItem?: ICveIgnorePartial): ICustomFormProps => {
    return {
        getItems,
        submitData: saveIgnoreItem,
        getSubmitSuccessNotification: getCommonIgnoreSuccessNotification,
        getSubmitFailedNotification: getCommonIgnoreFailedNotification,
        title: origIgnoreItem?.id ? origIgnoreItem?.name || origIgnoreItem.id : '(New CVE Ignore Item)',
        minDialogHeight: 300,
        eventHandlerId: CVE_IGNORE_LIST_PAGE_ID,
    };
};

export const showCveIgnoreForm = (origIgnoreItem?: ICveIgnorePartial): void => {
    globalModelUtils.showCustomFormModal({
        formProps: getCveIgnoreForm(origIgnoreItem),
        data: origIgnoreItem,
    });
};

export const createIgnoreItemForCve = (cve: IIssueCve) => {
    const initialIgnoreItem: ICveIgnorePartial = {
        name: cve.id,
        description: ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.ACTIONS.SUGGESTED_IGNORE_ITEM_DESCRIPTION', { cveId: cve.id }),
        cveIds: [cve.id],
        entityId: cve.entityId,
    };

    showCveIgnoreForm(initialIgnoreItem);
};