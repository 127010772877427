import { ExposureNodeData } from 'common/module_interface/insight/ExposureNodeData';
import { InsightPopoverRegistry } from 'common/module_interface/insight/InsightPopoverRegistry';
import ClassificationComponent from './ClassificationComponent';
import { isNilOrEmpty } from 'common/utils/helpFunctions';

const ExposurePopoverComponent = (props: ExposureNodeData) => {
    return <div className="-mx-6 -mt-5 -mb-5">
        <div className="px-8 pt-8 pb-8 break-all bg-aside flex-0">
            <div className="flex items-center popover-icon">
                { props.headerIcon &&
                    <div className={`popover-icon-container ${props.platform ?? ''}`}>
                        <props.headerIcon alt="" title={''}/>
                    </div>
                }
                <div className="mx-3">
                    <div className="font-bold line-clamp-2">{props.name || props.id || 'N/A'}</div>
                    { props.name && props.id &&
                        <div className="flex items-center py-5 text-weak text-xs">
                            <div className="mr-5 font-semibold"> ID:</div>
                            <div className="truncate max-w-[30ch] flex-1">{`${props.id}`}</div>
                        </div>
                    }
                </div>
            </div>
            <div className={'mt-3 flex gap-5 flex-wrap'}>
                {InsightPopoverRegistry.getChips().map(item => (
                    <item.component data={props} key={item.id}/>
                ))}
            </div>
        </div>

        { !!props.classifications && !isNilOrEmpty(props.classifications) &&
            <div className="px-8 pb-8">
                <ClassificationComponent classifications={props.classifications!} />
            </div>
        }

        <div className="px-8">
            {InsightPopoverRegistry.getItems().map(item => (
                <item.component data={{ ...props, ...{ className: 'popover-item' } }} key={ item.id }/>
            ))}
        </div>
    </div>;
};

export default ExposurePopoverComponent;