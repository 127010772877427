import HeaderWithInfoTooltipRenderer from 'common/components/ag-grid/Renderers/HeaderWithInfoTooltipRenderer';
import StatusCellRenderer from 'common/components/ag-grid/Renderers/StatusCellRenderer';
import AutoOnboardingCellRenderer from '../CellRenderers/AutoOnboardingCellRenderer';
import LogTypesCellRenderer from '../CellRenderers/LogTypesCellRenderer';
import { ColDef } from 'ag-grid-enterprise';
import i18n, { TFunction } from 'i18next';
import { SelectedTab } from '../../AzureIntelligenceOnboarding.interface';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { AzureStorageTableFieldNames } from '../../AzureIntelligenceOnboarding.const';
import RemoveRowCellRenderer from '../CellRenderers/RemoveRowCellRenderer';

const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace, 'AZURE_ONBOARDING');

export interface IColumnDefsProps {
    isAccountActivity: boolean;
    selectedTab: SelectedTab;
}

export const getColumnDefs = ({
    isAccountActivity,
    selectedTab,
}: IColumnDefsProps): ColDef[] => {
    return [
        {
            field: AzureStorageTableFieldNames.storageName,
            colId: 'azureStorageName',
            tooltipField: AzureStorageTableFieldNames.storageName,
            headerName: t('STORAGE.STORAGE_NAME'),
            headerCheckboxSelection: selectedTab === SelectedTab.List,
            checkboxSelection: selectedTab === SelectedTab.List,
            showDisabledCheckboxes: true,
            flex: 4,
        },
        {
            field: AzureStorageTableFieldNames.status,
            colId: 'azureStorageStatus',
            tooltipField: AzureStorageTableFieldNames.status,
            headerName: t('STATUS.TITLE'),
            filter: 'agTextColumnFilter',
            cellRenderer: StatusCellRenderer,
            flex: 2,
        },
        {
            field: AzureStorageTableFieldNames.logsType,
            colId: 'azureStorageLogsType',
            tooltipField: AzureStorageTableFieldNames.logsType,
            headerName: t('STORAGE.LOG_TYPES'),
            hide: !isAccountActivity,
            suppressColumnsToolPanel: !isAccountActivity,
            wrapText: true,
            autoHeight: true,
            minWidth: 250,
            cellRenderer: LogTypesCellRenderer,
            flex: 2,
        },
        {
            field: AzureStorageTableFieldNames.autoOnboard,
            colId: 'azureStorageAutoOnboard',
            headerTooltip: isAccountActivity ? t('ACCOUNT_ACTIVITY.AUTO_ONBOARD_TOOLTIP') : t('NETWORK_TRAFFIC.AUTO_ONBOARD_TOOLTIP'),
            headerComponent: HeaderWithInfoTooltipRenderer,
            headerComponentParams: { text: t('STORAGE.AUTO_ONBOARD') },
            cellRenderer: AutoOnboardingCellRenderer,
            flex: 2,
        },
        {
            field: AzureStorageTableFieldNames.remove,
            colId: 'azureStorageRemove',
            headerName: '',
            hide: selectedTab === SelectedTab.List,
            cellRenderer: RemoveRowCellRenderer,
            flex: 1,
        }
    ];
};