import React from 'react';
import './LoginInfinityPanel.scss';
import infinityLogo from '../../../assets/images/login/infinity-portal.svg';
import cloud from '../../../assets/images/login/cloud.svg';
import network from '../../../assets/images/login/network.svg';
import laptop from '../../../assets/images/login/laptop.svg';
import mobile from '../../../assets/images/login/mobile.svg';
import { useTranslation } from 'react-i18next';

export const LoginInfinityPanel: React.FC<{}> = () => {
    const { t } = useTranslation();
    return (
        <div className='login-infinity'>
            <img className='login-infinity__logo' src={infinityLogo} alt='infinity' />
            <div className='login-infinity__icons'>
                <img src={cloud} alt='cloud' />
                <img src={network} alt='network' />
                <img src={laptop} alt='laptop' />
                <img src={mobile} alt='mobile' />
            </div>
            <h2>{t('FORMS.LOGIN_INFINITY.TITLE1')}</h2>
            <h3>{t('FORMS.LOGIN_INFINITY.TITLE2')}</h3>
        </div>
    );
};

export default LoginInfinityPanel;
