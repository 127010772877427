import React from 'react';
import { EdgeData } from '../Models/GraphModels';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { EdgeLabelRenderer } from 'reactflow';
import OccurrencesTablePopoverComponent from './OccurrencesTablePopoverComponent';
import { Tooltip, Stack } from 'common/design-system/components-v2';
import styled from 'styled-components';

interface occurrencesTableProps{
    edgeData: EdgeData,
    labelX: number,
    labelY: number
}

const OccurrencesHeaderStyled = styled(Stack)<{ labelX: number, labelY: number }>`
    position: absolute;
    transform: ${ props => `translate(-50%, -50%) translate(${props.labelX}px,${props.labelY + 25}px)` };
    background-color: ${({ theme }) => theme.palette.surface.strong};;
    padding: 0 5px;
    border-radius: 5px;
    font-size: 11px;
    color: black;
    font-weight: 600;
`;

const OccurrencesTableComponent: React.FC<occurrencesTableProps> = ({ edgeData, labelX, labelY }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const occurrences = edgeData.additionalData?.occurrences;

    return <>
        { occurrences && occurrences.length > 0 && (
            <EdgeLabelRenderer>
                <Tooltip content={OccurrencesTablePopoverComponent(edgeData, t)} interactive={true} appendTo={() => document.body} delay={200} maxWidth={400}>
                    <OccurrencesHeaderStyled labelX={labelX} labelY={labelY} >
                        {`${occurrences.length} ${t('INT_FINDING_OVERVIEW.OCCURRENCES')}`}
                    </OccurrencesHeaderStyled>
                </Tooltip>
            </EdgeLabelRenderer>
        )}
    </>;
};

export default OccurrencesTableComponent;
