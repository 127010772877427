import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import '../AWSOnboarding.scss';
import { useSelector } from 'react-redux';
import { getSelectedProtection, setProtectionType } from '../helpers/aws.reducer';
import { CpButton, CpIcon } from '@dome9/components/react/components';
import { ProtectionType } from '../helpers/aws.interface';
import { CheckedCorner } from './Components';
import { getStoreService } from 'common/interface/services';

const SecurityGroupProtection: FC = () => {
    const { t } = useTranslation();
    const dispatch = getStoreService().dispatch;
    const selectedProtection = useSelector(getSelectedProtection);
    const securityGroupsPermissions = [
        {
            name: t('ON_BOARDING.SECURITY_GROUP_PROTECTION.CLOUDGUARD_CLARITY_FOR_VISUALIZATION_OF_NETWORK_SECURITY'),
            read: true,
            readWrite: true,
        },
        {
            name: t('ON_BOARDING.SECURITY_GROUP_PROTECTION.CHANGE_NOTIFICATIONS'),
            read: true,
            readWrite: true,
        },
        {
            name: t('ON_BOARDING.SECURITY_GROUP_PROTECTION.AUDIT_TRAIL'),
            read: true,
            readWrite: true,
        },
        {
            name: t('ON_BOARDING.SECURITY_GROUP_PROTECTION.COMPLIANCE_REPORTS'),
            read: true,
            readWrite: true,
        },
        {
            name: t('ON_BOARDING.SECURITY_GROUP_PROTECTION.ALERTS'),
            read: true,
            readWrite: true,
        },
        {
            name: t('ON_BOARDING.SECURITY_GROUP_PROTECTION.POLICY_REPORTS'),
            read: true,
            readWrite: true,
        },
        {
            name: t(
                'ON_BOARDING.SECURITY_GROUP_PROTECTION.DYNAMIC_ACCESS_LEASES_TIME_LIMITED_ON_DEMAND_RESOURCE_ACCESS'
            ),
            read: false,
            readWrite: true,
        },
        {
            name: t(
                'ON_BOARDING.SECURITY_GROUP_PROTECTION.SECURITY_GROUP_MANAGEMENT_CONSOLE_TO_EDIT_POLICIES_IN_PLACE'
            ),
            read: false,
            readWrite: true,
        },
        {
            name: t('ON_BOARDING.SECURITY_GROUP_PROTECTION.TAMPER_PROTECTION_AND_REGION_LOCK_FOR_ACTIVE_ENFORCEMENT'),
            read: false,
            readWrite: true,
        },
        {
            name: t('ON_BOARDING.SECURITY_GROUP_PROTECTION.REUSABLE_POLICY_OBJECTS_SUCH_AS_IP_LISTS_AND_DNS_OBJECTS'),
            read: false,
            readWrite: true,
        },
    ];

    function handleProtectionSelection(protectionType: ProtectionType) {
        dispatch(setProtectionType(protectionType));
    }

    return (
        <>
            <div className='aws-onboarding-security-group-protection'>
                <div className='header-content'>
                    <div className='title-large'>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.HEADER')}</div>
                    <div className='title'>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.DESCRIPTION')}</div>
                </div>

                <div className='protection-content'>
                    <div className='selection-layer'>
                        <div className='name un-selectable' />
                        <div
                            className={`read-write-space selectable ${
                                selectedProtection === 'monitor' ? 'selected' : ''
                            }`}>
                            <div className={'read-write-space-icon'}>
                                {selectedProtection === 'monitor' ? <CheckedCorner /> : ''}
                            </div>
                        </div>
                        <div
                            className={`read-write-space selectable ${
                                selectedProtection === 'fullProtection' ? 'selected' : ''
                            }`}>
                            <div className={'read-write-space-icon'}>
                                {selectedProtection === 'fullProtection' ? <CheckedCorner /> : ''}
                            </div>
                        </div>
                    </div>

                    <div className='row selection'>
                        <span className='name' />
                        <span className='read-write'>
                            <span>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.MONITOR')}</span>
                        </span>
                        <span className='read-write'>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.FULL_PROTECTION')}</span>
                    </div>
                    <div className='row header'>
                        <span className='name'>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.PERMISSIONS')}</span>
                        <span className='read-write'>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.READ')}</span>
                        <span className='read-write'>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.READ_WRITE')}</span>
                    </div>
                    {securityGroupsPermissions.map((permission) => {
                        return (
                            <div key={permission.name} className='row'>
                                <span className='name'>{permission.name}</span>
                                <span className='read-write'>
                                    {permission.read && (
                                        <CpIcon
                                            icon='success'
                                            className='aws-onboarding-security-group-protection-success-icon'
                                        />
                                    )}
                                </span>
                                <span className='read-write'>
                                    {permission.readWrite && (
                                        <CpIcon
                                            icon='success'
                                            className='aws-onboarding-security-group-protection-success-icon'
                                        />
                                    )}
                                </span>
                            </div>
                        );
                    })}
                    <div className='row footer'>
                        <span className='name' />
                        <span className='read-write'>
                            {selectedProtection === 'monitor' ? (
                                <span>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.ACTIVE')}</span>
                            ) : (
                                <CpButton onClick={() => handleProtectionSelection('monitor')}>
                                    {t('ON_BOARDING.SECURITY_GROUP_PROTECTION.SELECT')}
                                </CpButton>
                            )}
                        </span>
                        <span className='read-write'>
                            {selectedProtection === 'fullProtection' ? (
                                <span>{t('ON_BOARDING.SECURITY_GROUP_PROTECTION.ACTIVE')}</span>
                            ) : (
                                <CpButton onClick={() => handleProtectionSelection('fullProtection')}>
                                    {t('ON_BOARDING.SECURITY_GROUP_PROTECTION.SELECT')}
                                </CpButton>
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SecurityGroupProtection;
