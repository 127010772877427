import styled from 'styled-components';
import Stack from 'common/design-system/components-v2/Stack';

const TableTitle = styled(Stack)`
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const TableDiv = styled(Stack)`
  flex-direction: column;
  width: 100%;
  gap: 15px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
`;

const TableBody = styled(Stack)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  gap: 8px
`;

const TableRow = styled(Stack)`
  flex-direction: row;
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: flex-start;

  &:hover {
    background: ${({ theme }) => theme.palette.componentStates.ghostHover};
  }
`;

const TableCell = styled.div<{ width?: string, isText: boolean }>`
    width: ${props => props.width ?? '100%'};
    display: flex;
    justify-content: ${props => props.isText ? 'flex-start' : 'center'};
    align-items: center;
    text-align: ${props => props.isText ? 'flex-start' : 'center'};
`;


export const ActiveFeaturesManagerTableStyled = {
    TableTitle,
    TableDiv,
    TableBody,
    TableRow,
    TableCell,
};
export default ActiveFeaturesManagerTableStyled;
