import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getErrorText, getErrorTitle } from './ErrorPage.reducer';
import './ErrorPage.scss';
import Error from 'common/components/Error/Error';

export const ErrorPage: FC = () => {
    const title = useSelector(getErrorTitle);
    const text = useSelector(getErrorText);

    return <Error title={title} text={text} />;
};
