import { getStoreService, INotificationService } from 'common/interface/services';
import { NotificationType, Notification, PromiseNotification } from 'common/interface/notifications';
import { addNotification, clearAllNotifications, handlePromise } from '../main/Notifications/Notifications.reducer';

export class NotificationsService implements INotificationService {
    success(title: string, text: string): void {
        this.addNotification({
            type: NotificationType.SUCCESS,
            title,
            text,
        });
    }

    error(title: string, text: string): void {
        this.addNotification({
            type: NotificationType.ERROR,
            title,
            text,
        });
    }

    info(title: string, text: string): void {
        this.addNotification({
            type: NotificationType.INFO,
            title,
            text,
        });
    }

    warning(title: string, text: string): void {
        this.addNotification({
            type: NotificationType.WARNING,
            title,
            text,
        });
    }

    addNotification(notification: Notification): void {
        getStoreService().dispatch(addNotification(notification));
    }

    runPromise(promiseNotification: PromiseNotification): void {
        getStoreService().dispatch(handlePromise(promiseNotification));
    }

    clearAllNotifications(): void {
        getStoreService().dispatch(clearAllNotifications());
    }
}
