import { FC, useEffect, useState } from 'react';
import { I18nExclusion } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { Input } from 'common/design-system/components-v2';
import { IExclusionAccountNumberProps } from '../../helpers/exclusions.interfaces';
import { formatAccountNumber } from '../../helpers/exclusions.utils';


const ExclusionByAccountNumber: FC<IExclusionAccountNumberProps> = ({ isEnabled, selectedRuleset, onChange, selectedOption }) => {
    const { t } = useTranslation(I18nExclusion);
    const [accountNumber, setAccountNumber] = useState<string>();

    useEffect(() => {
        setAccountNumber('');
    }, [selectedRuleset]);

    useEffect(() => {
        const handlePreselectedOption = () => {
            if(selectedOption){
                const formattedAccountNumber = formatAccountNumber(selectedOption);
                formattedAccountNumber && setAccountNumber(formattedAccountNumber);
            }
        };
        handlePreselectedOption();
    }, [selectedOption]);

    useEffect(() => {
        accountNumber && onChange(accountNumber);
    }, [accountNumber, onChange]);

    const handleAccountNumberChange = (value: string ) => {
        setAccountNumber(value);
    };

    return (
        <Input
            label={t('MODAL.TOPICS.ACCOUNT_VENDOR_ID.TITLE')}
            type="text"
            value={accountNumber as string}
            disabled={!isEnabled || !selectedRuleset}
            placeholder={t('MODAL.GENERAL.TYPE_HERE')}
            onChange={(e)=>handleAccountNumberChange(e.target.value)}
        />
    );
};
export default ExclusionByAccountNumber;