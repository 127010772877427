import {
    ActiveFeatureTopicEnum,
    IActiveFeatureInfo
} from 'common/module_interface/RiskManagement/activeFeatures/ActiveFeatures';

export const ERM_EXPERIMENTS_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'erm-experiments',
    description: 'If enabled, it allows developer led customer facing features which were not yet approved by the product team',
    topic: ActiveFeatureTopicEnum.ERM,
};
export const POLICIES_PAGE_ACTIVE_FEATURE: IActiveFeatureInfo= {
    key: 'policies-page',
    description: 'Deprecated. Was replaced by "auto-actions-page"',
    topic: ActiveFeatureTopicEnum.ERM,
};
export const DATA_SECURITY_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'data-security',
    description: 'If enabled, Data Security menu item will be available in menu',
    topic: ActiveFeatureTopicEnum.ERM,
};

export const DUPLICATE_AUTO_ACTION_FEATURE: IActiveFeatureInfo = {
    key: 'dup-auto-action',
    description: 'If enabled, UI will display a "Duplicate" action in the auto-actions table',
    topic: ActiveFeatureTopicEnum.ERM,
    hidden: true,
};

export const AUTO_ACTIONS_PAGE_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'auto-actions-page',
    description: 'If enabled, Automated-Actions menu item will be available. ' +
        'Allows user to view, create, edit and remove Automated Actions. ' +
        'Note: The activation of the this feature depends on 2 additional conditions: (1) Activating flag new-integrations AND ' +
        '(2) Either the user is a super-user or has "policies" permission',
    topic: ActiveFeatureTopicEnum.ERM,
};

export const SECURITY_CONTROLS_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'security-controls',
    description: 'If enabled, Security-Controls sub menu item will be available under Risk-Management menu.',
    topic: ActiveFeatureTopicEnum.ERM,
};

export const ISSUE_EXCLUSIONS_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'issue-exclusions',
    description: 'If enabled, Issue-exclusions sub menu item will be available under Risk-Management menu.',
    topic: ActiveFeatureTopicEnum.ERM,
};

export const DUPLICATE_ISSUE_EXCLUSION_FEATURE: IActiveFeatureInfo = {
    key: 'dup-issue-exclusion',
    description: 'If enabled, UI will display a "Duplicate" action in the issue-exclusions table',
    topic: ActiveFeatureTopicEnum.ERM,
    hidden: true,
};

export const CVE_IGNORE_LIST_FEATURE: IActiveFeatureInfo = {
    key: 'cve-ignore-list',
    description: 'If enabled, CVE Ignore List page will be displayed under Risk Management',
    topic: ActiveFeatureTopicEnum.ERM,
};

export const MALWARE_IGNORE_LIST_FEATURE: IActiveFeatureInfo = {
    key: 'malware-ignore-list',
    description: 'If enabled, Malware Ignore List page will be displayed under Risk Management',
    topic: ActiveFeatureTopicEnum.ERM,
};

export const WAF_PROTECTION_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'waf-protection',
    description: 'If enabled, WAF indication chip will be available in asset details and in the assets table',
    topic: ActiveFeatureTopicEnum.ERM,
};

export const MALWARE_EVIDENCE_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'erm-malware-evidence',
    description: 'If enabled, Malware section with list of threads shown in evidence path.',
    topic: ActiveFeatureTopicEnum.ERM,
    hidden: true,
};

export const ACTIVE_FEATURE_PAGE_MANAGER: IActiveFeatureInfo = {
    key: 'active-features-manager',
    description: 'If enabled, Active Features Manager page will be available, it allows management of active features through local storage',
    topic: ActiveFeatureTopicEnum.ERM,
};

export const CYERA_INTEGRATION_ACTIVE_FEATURE: IActiveFeatureInfo = {
    key: 'cyera-integration',
    description: 'If enabled, Cyera integration will be available in the integrations Hub',
    topic: ActiveFeatureTopicEnum.ERM,
};

export const ERM_ACTIVE_FEATURES: IActiveFeatureInfo[] = [
    ERM_EXPERIMENTS_ACTIVE_FEATURE,
    DATA_SECURITY_ACTIVE_FEATURE,
    DUPLICATE_AUTO_ACTION_FEATURE,
    AUTO_ACTIONS_PAGE_ACTIVE_FEATURE,
    SECURITY_CONTROLS_ACTIVE_FEATURE,
    ISSUE_EXCLUSIONS_ACTIVE_FEATURE,
    DUPLICATE_ISSUE_EXCLUSION_FEATURE,
    CVE_IGNORE_LIST_FEATURE,
    MALWARE_IGNORE_LIST_FEATURE,
    WAF_PROTECTION_ACTIVE_FEATURE,
    MALWARE_EVIDENCE_ACTIVE_FEATURE,
    CYERA_INTEGRATION_ACTIVE_FEATURE,
];
