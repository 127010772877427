import React, { Fragment } from 'react';
import { ResponseGeneratorProps } from '../Suggestion.interface';

const ResponseGenerator: React.FC<ResponseGeneratorProps> = (props: ResponseGeneratorProps) => {
    const { response, styleClasses } = props;

    return response && response.length ? <div>
        { response.map((responseElement, elementIndex) => (
            <Fragment key={`main.${elementIndex}`}>
                { responseElement.heading &&
                    <div key={`heading.${elementIndex}`} className={`pb-7 ${styleClasses?.heading || 'text-xl'}`}>{`${responseElement.heading}`}</div>
                }
                { responseElement.subheading &&
                    <div key={`subheading.${elementIndex}`} className={`py-6 ${styleClasses?.subheading || 'text-lg'}`}>{`${responseElement.subheading}`}</div>
                }
                { Array.isArray(responseElement.lines) && responseElement.lines.length &&
                    <div key={`lines.${elementIndex}`} className={'pb-6'}>
                        { responseElement.lines.map((line, lineIndex) => (
                            <div key={`lines.${lineIndex}`} className={`pb-3 ${styleClasses?.lines?.join(' ')}`}>
                                {`${line}`}
                            </div>
                        )) }
                    </div>
                }
                { Array.isArray(responseElement.numberedLines) && responseElement.numberedLines.length &&
                    <div key={`numberedLines.${elementIndex}`} className={'pl-7 py-4'}>
                        { responseElement.numberedLines.map((line, lineIndex) => (
                            <div key={`numberedLines.${elementIndex}.${lineIndex}`} className={styleClasses?.numberedLines?.join(' ')}>
                                <span>{`${Number(lineIndex)+1}.`}</span>
                                <span className={'pl-5'}>{`${line.replace(/^[0-9]+\. /, '')}`}</span>
                            </div>
                        ))}
                    </div>
                }
                { Array.isArray(responseElement.table) && responseElement.table.length &&
                    <div key={`table.${elementIndex}`} className={'grid py-4'}>
                        { responseElement.table.map((row, rowIndex) => (
                            <Fragment key={`table.${elementIndex}.${rowIndex}`}>
                                { Array.isArray(row) && row.length &&
                                    row.map((cell, cellIndex) => (
                                        <div key={`table.${elementIndex}.${rowIndex}.${cellIndex}`} className={`${rowIndex === 0 ? 'font-bold' : 'border-t-1 border-t-solid'} ${cellIndex === 0 ? '' : 'border-l-1 border-l-solid'} p-5`} style={{ gridArea: `${rowIndex + 1} / ${cellIndex + 1} / ${rowIndex + 2} / ${cellIndex + 2}` }}>
                                            {`${cell}`}
                                        </div>
                                    ))
                                }
                            </Fragment>
                        ))}
                    </div>
                }
            </Fragment>
        ))}
    </div> : null;
};

export default ResponseGenerator;