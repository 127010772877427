import React, { useCallback, useRef } from 'react';
import { DashboardFilterPanel } from '../../BaseDashboard/DashboardFilterPanel';
import { DATA_SECURITY_FILTERS } from '../DataSecurityDashboardFilters';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getAggregationsFromServer, ICustomAggregation } from 'common/components/ProtectedAssets/datasource';
import { getAllDataAssetsTypes } from '../../../ProtectedAsset/ErmDataUtils';
import { FilterStyled } from './DataSecurityFilterPanel.styled';
import { valuesToFilterFields } from '../../../../RiskManagement.utils';
import { IFilterFieldsProps } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { ASSET_FIELD_NAMES } from '../../../../consts';


const MAIN_AGGR = [
    ASSET_FIELD_NAMES.type,
    ASSET_FIELD_NAMES.organizationalUnitId,
    ASSET_FIELD_NAMES.platform,
    ASSET_FIELD_NAMES.cloudAccountId,
];
const OPTIONAL_AGGR = [ ASSET_FIELD_NAMES.businessPriority ];
const INITIAL_AGGR: ICustomAggregation = {
    aggregationFields: MAIN_AGGR,
    optionalAggregationFields: OPTIONAL_AGGR,
};

export const getDataAssetsFilterFields = (): IFilterFieldsProps[] => {
    return valuesToFilterFields('type', getAllDataAssetsTypes());
};
export const DataSecurityFilterPanel: React.FC<{ onFilterChanged: (filterVals: IFiltersValues) => void }> = ({ onFilterChanged }) => {
    const initialFilterFieldsRef = useRef<IFilterFieldsProps[]>(getDataAssetsFilterFields());

    const onChanges = useCallback((filterValues: IFiltersValues): Promise<Aggregations> => {
        const finalFields: IFilterFieldsProps[] = filterValues?.fields || [];
        if (!finalFields.some(item => item.name === 'type')) {
            finalFields.push(...initialFilterFieldsRef.current);
        }

        const updatedFilterValues: IFiltersValues = {
            ...(filterValues || {}),
            fields: finalFields,
        };
        onFilterChanged(updatedFilterValues);
        return getAggregationsFromServer(MAIN_AGGR, OPTIONAL_AGGR, updatedFilterValues);
    }, [onFilterChanged]);

    return (
        <FilterStyled.TopDiv>
            <DashboardFilterPanel
                filterId={'data-security-dashboard-filter-panel'}
                getAggregations={onChanges}
                filterTypes={DATA_SECURITY_FILTERS}
                initialAggregationConfig={INITIAL_AGGR}
                initialFilter={{ fields: initialFilterFieldsRef.current }}
            />
        </FilterStyled.TopDiv>
    );
};
