import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import Chip from 'common/design-system/components-v2/Chip';
import { isNil } from 'common/utils/helpFunctions';
import { getSafeWafProtectionInfo } from '../WafProtection/WafProtection';

const WAFProtectionCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const wafProtection = params.data?.context?.wafProtection;
    if (isNil(wafProtection)) return null;

    const wafProtectionInfo = getSafeWafProtectionInfo(wafProtection);
    if (!wafProtectionInfo.useChip) return wafProtectionInfo.title;

    return (
        <Chip
            label={wafProtectionInfo.title}
            leadingIconProps={wafProtectionInfo.iconProps}
            customColor={wafProtectionInfo.bg}
        />
    );
};

export default WAFProtectionCellRenderer;
