import styled from 'styled-components/macro';

export const OverviewCategoryWrapper = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  padding-top: 20px;
  padding-right: 20px;
`;

export const OverviewCategoryInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  min-height: 0;
  min-width: 0;
`;

export const OverviewCategorySectionTitle = styled.div`
  text-transform: uppercase;
  font-size: var(--text-lg);
  color: hsla(var(--color-info-h), var(--color-info-s), var(--color-info-l-darkest), var(--opacity));
  opacity: .75;
`;

export const OverviewCategoryDashyLine = styled.div`
  flex: 1 1 auto;
  flex-shrink: 1;
  flex-grow: 1;
  --border-top-style: dashed;
  --border-right-style: dashed;
  --border-bottom-style: dashed;
  --border-left-style: dashed;
  --border-bottom-width: 1px;
  margin-left: 14px;
`;

