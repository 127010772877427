import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserAccounts, getUserRolesFromServer } from './Roles.reducer';
import './Roles.scss';
import { CpComboBox, CpMenu, CpMenuItem } from '@dome9/components/react/components';
import { getSelectedAccount, setSelectedAccount, setSelectedRole } from '../../../user/SwitchRoleComponent.reducer';
import { useTranslation } from 'react-i18next';
import { getStoreService } from '../../../../common/interface/services';

export const Roles: React.FC = () => {
    const dispatch = getStoreService().dispatch;
    const selectedAccount = useSelector(getSelectedAccount);
    const userAccountAndRoles = useSelector(getUserAccounts);
    const { t } = useTranslation();

    useEffect(() => {
        getUserRolesFromServer();
    }, []);

    function accountSelected(event: any) {
        dispatch(setSelectedAccount(userAccountAndRoles.find((account) => account.accountName === event.text)));
    }

    function roleSelected(event: any) {
        dispatch(setSelectedRole(event.text));
    }

    return (
        <div className={'roles flex'}>
            <CpComboBox onCpSelect={(event: any) => accountSelected(event.detail)}>
                <CpMenu>
                    {userAccountAndRoles &&
                        userAccountAndRoles.map((userAccount) => <CpMenuItem
                            key={userAccount.accountName}>{userAccount.accountName}</CpMenuItem>)}
                </CpMenu>
            </CpComboBox>
            <CpComboBox
                disabled={!selectedAccount?.roles?.length}
                placeholder={t('TOP_BAR_MENU.SELECT_ROLE')}
                onCpSelect={(event: any) => roleSelected(event.detail)}>
                <CpMenu>
                    {selectedAccount?.roles?.map((role: string) => (
                        <CpMenuItem key={role}>{role}</CpMenuItem>
                    ))}
                </CpMenu>
            </CpComboBox>
        </div>
    );
};

export default Roles;
