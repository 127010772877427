import { Node, Edge } from 'reactflow';
import { EdgeCSSProperties, GraphModel } from 'common/components/Graph/Models/GraphModel';
import { EdgeData, Entity, GraphData, Relationship } from '../Models/GraphModels';
import { IntelligenceNodeData } from 'common/module_interface/intelligence/intelligenceNodeData';
import {
    getIntelligenceEdgeAdditionalData,
    getIntelligenceEdgeType,
    isEdgeAnimated,
} from './EdgeTypeHandler';
import { getIntelligenceNodeType } from './NodeTypeHandler';
import { getNodeColor } from './ColorGetter';

const nodeSize = 42;
const nodeScore = 1;


function mapAssetNodes(entities: Entity[]): Node[] {
    return entities.map(e => {

        const backgroundColor = getNodeColor(e.additionalData?.riskData?.riskScore, e.additionalData?.intelligenceNodeType);

        const data: IntelligenceNodeData = {
            id: e.externalId,
            label: e.type,
            size: nodeSize,
            score: nodeScore,
            name: e.name,
            type: e.type,
            externalId: e.externalId,
            gotoUrl: e.gotoUrl,
            isInContext: e.isInContext ?? false,
            srl: e.srl,
            platform: e.additionalData?.vendor,
            headerIcon: () => null,
            backgroundColor: backgroundColor,
            classification: e.classification,
            additionalData: e.additionalData,
            exposureLevel: e.additionalData?.riskData?.exposureLevel,
            iamExposure: e.additionalData?.riskData?.iamExposure,
            businessPriority: e.additionalData?.riskData?.businessPriority,
            riskScore: e.additionalData?.riskData?.riskScore,
            postureFindings: e.additionalData?.riskData?.postureFindings,
            iamSensitivity: e.additionalData?.riskData?.iamSensitivity,
            cves: e.additionalData?.riskData?.cves,
            securityEvents: e.additionalData?.riskData?.securityEvents
        };

        const node: Node = {
            id: e.graphId,
            position: { x: 0, y: 0 },
            data: data,
            type: getIntelligenceNodeType(e),
            selectable: true
        };

        return node;
    });
}

function mapEdges(relationships: Relationship[], entities: Entity[]): Edge[] {
    return relationships.map(r => {
        const source = entities.find(e => e.graphId === r.fromGraphId);
        const target = entities.find(e => e.graphId === r.toGraphId);
        const sourceBackground = getNodeColor(source?.additionalData?.riskData?.riskScore, source?.additionalData?.intelligenceNodeType);
        const targetBackground = getNodeColor(target?.additionalData?.riskData?.riskScore, target?.additionalData?.intelligenceNodeType);
        const edgeId = `${r.fromGraphId}To${r.toGraphId}`;
        const edgeType = getIntelligenceEdgeType(r);
        const edgeStyle: EdgeCSSProperties = {
            '--stopColorStart': sourceBackground,
            '--stopColorEnd': targetBackground
        };
        return {
            id: edgeId,
            source: r.fromGraphId,
            target: r.toGraphId,
            type: edgeType,
            data: buildEdgeData(r, sourceBackground, targetBackground),
            style: edgeStyle,
            markerEnd: `marker-end-${edgeId}`,
            animated: isEdgeAnimated(r)
        };
    });
}

function buildEdgeData(relationship: Relationship, sourceColor: string, targetColor: string): EdgeData | null {
    const data : EdgeData = getIntelligenceEdgeAdditionalData(relationship);
    data.sourceColor = sourceColor;
    data.targetColor = targetColor;

    return data;
}

export function mapToGraphEntities(graphData: GraphData): GraphModel {
    const assetNodes = mapAssetNodes(graphData.entities);
    const edges = mapEdges(graphData.relationships, graphData.entities);

    return {
        nodes: [
            ...assetNodes,
        ],
        edges: edges
    };
}
