import React from 'react';
import { openAutoActionEditorDrawer } from '../AutoActions.utils';
import { EmptyStyled } from './AutoActionsEmptyState.styled';
import { Button, Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../consts';
import emptyStateIcon from './empty-state-icon.svg';

export const AutoActionsEmptyState: React.FC = () => {
    const { t } = useTranslation(I18nRiskNamespace);
    return (
        <EmptyStyled.TopDiv>
            <EmptyStyled.BodyDiv>
                <img src={emptyStateIcon} height='90px' width='90px' />
                <Stack direction={'column'} spacing={2} alignItems={'center'} >
                    <Typography variant={'h2'}>{t('AUTO_ACTIONS.EMPTY_STATE.START_CREATION_TITLE')}</Typography>
                    <Typography variant={'body500'}>{t('AUTO_ACTIONS.EMPTY_STATE.START_CREATION_DESCRIPTION')}</Typography>
                </Stack>
                <Stack direction={'column'} spacing={12} alignItems={'center'}>
                    <Button label={t('AUTO_ACTIONS.EMPTY_STATE.CREATE')} onClick={() => openAutoActionEditorDrawer()} color={'brandPrimary'} iconProps={{ name: 'plus' }} />
                </Stack>
            </EmptyStyled.BodyDiv>
        </EmptyStyled.TopDiv>
    );
};
