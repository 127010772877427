import React, { useMemo } from 'react';
import { ExposureStyled } from './ExposureDashboard.styled';
import { ExposureSectionWidgetCard } from './ExposureSectionWidgetCard';
import { Section } from '../../Section/Section';
import { IExposureSectionInfo, IExposureSectionWidgetInfo } from './ExposureDashboard.interface';

export const ExposureSection: React.FC<{ sectionInfo: IExposureSectionInfo }> = ({ sectionInfo }) => {
    const { widgetInfos, height, title } = sectionInfo;
    const widgetCards = useMemo(() => {
        return widgetInfos.map((widgetInfo: IExposureSectionWidgetInfo) => {
            return (
                <ExposureSectionWidgetCard widgetInfo={widgetInfo} key={widgetInfo.title}/>
            );
        });
    }, [widgetInfos]);

    return (
        <Section title={title} isLargeTitle={true}>
            <ExposureStyled.SectionContentDiv height={height}>{widgetCards}</ExposureStyled.SectionContentDiv>
        </Section>
    );
};
