import {
    renderAddFilter,
    renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter,
    renderRecentlyUsedFilters,
    renderSavedFilters,
    renderTagFilter,
    renderTreeFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { FILTER_PANEL_CONSTS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import i18n from 'i18next';
import { IProtectedAssetFilterParams } from 'common/module_interface/assets/ProtectedAssets';
import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { getVendor, getVendorDisplayName } from 'common/consts/vendors';
import { ProtectedAssetsTableRegistry } from 'common/module_interface/assets/ProtectedAssetsTableRegistry';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { buildCloudAccountList, mapEnvironmentItem, mapTypeItem } from 'common/module_interface/assets/utils';
import { GENERAL_BOOLEAN_VALUES } from 'common/consts/GeneralConsts';
import { getRegionDisplayName } from 'common/utils/vendorUtils';

const filterDefsAddins: Addin<IFilterProps>[] =
    [
        {
            id: 'add filter',
            content: {
                filterProps: { key: 'add-filter' },
                renderFunction: renderAddFilter,
            },
        },
        {
            id: 'free text',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.FREE_TEXT, //This needs to be the same as in stored filter/query params
                    title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.FREE_TEXT.TITLE'),
                },
                keyInObjectForAPI: 'freeTextPhrase',
                renderFunction: renderDefaultFreeTextFilter,
            },
        },
        {
            id: 'organizational unit',
            content: function({ filtersInitialData }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        isMultiSelect: true,
                        initialData: filtersInitialData?.organizationalUnits,
                        key: 'organizationalUnitId',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ORGANIZATIONAL_UNITS.TITLE'),
                    },
                    renderFunction: renderTreeFilter,
                };
            },
        },
        {
            id: 'platform',
            content: function({ aggregations }: IProtectedAssetFilterParams) {

                const displayMapping: IDisplayMappingObject = {};
                aggregations['platform']?.forEach((platform: { value: string }) => {
                    displayMapping[platform.value] = {
                        displayText: getVendorDisplayName(platform.value),
                        icon: getVendor(platform.value)?.icon,
                    };
                });
                return {
                    filterProps: {
                        initialData: aggregations['platform'],
                        key: 'platform',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PLATFORM.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'type',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations['type'],
                        key: 'type',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.TYPE.TITLE'),
                        displayMapping: mapTypeItem(aggregations?.type),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'environment',
            content: function({ aggregations, allCloudAccounts = [] }: IProtectedAssetFilterParams) {
                const allCloudAccountsItems = aggregations['cloudAccountId'] ? buildCloudAccountList(aggregations['cloudAccountId'], allCloudAccounts) : [];
                return {
                    filterProps: {
                        itemCountGroupOption: { enableGrouping: true,
                            countedItemsHeader: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ENVIRONMENT.COUNTED_ITEMS_HEADER'),
                            nonCountedItemsHeader: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ENVIRONMENT.NON_COUNTED_ITEMS_HEADER') },
                        initialData: allCloudAccountsItems,
                        key: 'cloudAccountId',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ENVIRONMENT.TITLE'),
                        displayMapping: mapEnvironmentItem(allCloudAccounts),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'region',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                const displayMapping: IDisplayMappingObject = {};
                aggregations['region']?.forEach((regionData: { value: string }) => {
                    displayMapping[regionData.value] = { displayText: getRegionDisplayName(regionData.value) };
                });
                return {
                    filterProps: {
                        initialData: aggregations['region'],
                        key: 'region',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.REGION.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'labels',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                const initialData = [];
                if(aggregations['assetLabels']) {
                    for (const data of aggregations['assetLabels']) {
                        if (data.value !== '') {
                            initialData.push(data);
                        }
                    }
                }
                return {
                    filterProps: {
                        initialData: initialData,
                        key: 'assetLabels',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.LABELS.TITLE'),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'network',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations['network'],
                        key: 'network',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.NETWORK.TITLE'),
                        displayMapping: { '': { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.NETWORK.NONE') } },
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'resourceGroup',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations['resourceGroup'],
                        key: 'resourceGroup',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RESOURCE_GROUP.TITLE'),
                        displayMapping: { '': { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RESOURCE_GROUP.NONE') } },
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'compartment',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations['compartment'],
                        key: 'compartment',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.COMPARTMENT.TITLE'),
                        displayMapping: { '': { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.COMPARTMENT.NONE') } },
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'runState',
            content: () => {
                const initialData = [
                    { value: GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T },
                    { value: GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F },
                ];

                const displayMapping = {
                    [GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T]: { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RUN_STATE.VALUE1') },
                    [GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F]: { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RUN_STATE.VALUE2') },
                };

                return {
                    filterProps: {
                        initialData: initialData,
                        key: 'additionalFields|IsRunning',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.RUN_STATE.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'publicIdAssociated',
            content: () => {
                const initialData = [
                    { value: GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T },
                    { value: GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F },
                ];

                const displayMapping = {
                    [GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T]: { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PUBLIC_IP_ASSOCIATED.VALUE1') },
                    [GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F]: { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PUBLIC_IP_ASSOCIATED.VALUE2') },
                };

                return {
                    filterProps: {
                        initialData: initialData,
                        key: 'additionalFields|IsPublic',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PUBLIC_IP_ASSOCIATED.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },

        },
        {
            id: 'billableAsset',
            content: () => {
                const initialData = [
                    { value: GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T },
                    { value: GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F },
                ];

                const displayMapping = {
                    [GENERAL_BOOLEAN_VALUES.TRUE_CAPITAL_T]: { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.BILLABLE_ASSET.VALUE1') },
                    [GENERAL_BOOLEAN_VALUES.FALSE_CAPITAL_F]: { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.BILLABLE_ASSET.VALUE2') },
                };

                return {
                    filterProps: {
                        initialData: initialData,
                        key: 'additionalFields|IsBillable',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.BILLABLE_ASSET.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'thirdParty',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations?.thirdParty,
                        key: 'externalAdditionalFields|ThirdParty',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.THIRD_PARTY.TITLE'),
                        displayMapping: { '': { displayText: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.THIRD_PARTY.NONE') } },
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'tag',
            content: function({ aggregations }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        initialData: aggregations[FILTER_PANEL_CONSTS.ADD_FILTER_TAG],
                        key: 'tag',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.TAGS.TITLE'),
                    },
                    renderFunction: renderTagFilter,
                };
            },
        },
        {
            id: 'saved filters',
            content: function({ filtersInitialData }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        savedFilters: filtersInitialData?.savedFilters || [],
                        savedFiltersComponentName: '',
                        selectedFilterID: '',
                        key: FILTERS_KEYS.SAVED_FILTERS,
                    },
                    renderFunction: renderSavedFilters,
                };
            },
        },
        {
            id: 'recently used',
            content: function({ filtersInitialData }: IProtectedAssetFilterParams) {
                return {
                    filterProps: {
                        key: FILTERS_KEYS.RECENTLY_USED_FILTERS,
                        recentlyUsedList: filtersInitialData?.recentlyUsed,
                        maxLength: 4,
                        componentName: '',
                        title: i18n.t('FILTER_PANEL.RECENTLY_USED_FILTERS'),
                    },
                    renderFunction: renderRecentlyUsedFilters,
                };
            },
        },
        {
            id: 'clear all',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
            },
        },
    ];


export default function initializeFilters() {
    ProtectedAssetsTableRegistry.addFilterAddins(filterDefsAddins);
}
