import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { IIssueExclusion } from '../IssueExclusions.interface';
import { showIssueExclusionForm } from '../IssueExclusionEditor/IssueExclusionEditor.items';

export const IssueExclusionNameCellRender: React.FC<ICellRendererParams<IIssueExclusion>> = (params) => {
    const issueExclusion: IIssueExclusion | undefined = params.data;
    if (!issueExclusion?.name) {
        return null;
    }

    const onClick = () => {
        showIssueExclusionForm(issueExclusion);
    };

    const options: IIconLinkCellProps = {
        isLink: true,
        value: issueExclusion.name,
        onClick,
        levelIconProps: {
            iconProps: { name: 'exclude' },
            size: 'md',
        }
    };

    CellToClipboardHandler(params, issueExclusion.name);
    return (<IconLinkCellRenderer {...options} />);
};
