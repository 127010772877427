import { FC } from 'react';
import { SeverityRenderer } from './components/SeverityRenderer';
import { PlatformRenderer } from './components/PlatformRenderer';
import { AgGridReact } from 'ag-grid-react';
import './RulesManagement.scss';
import i18n from 'common/services/translations/translations';
import RulesManagementPreview from './RuleManagementPreview';
import { setSelectedRuleAction } from './RulesManagement.actions';
import { useSelector } from 'react-redux';
import { getRulesManagementData } from './RulesManagement.reducer';
import { Button } from '@dome9/berries/react-components';
import { useHistory } from 'react-router-dom';
import { ColDef } from 'ag-grid-enterprise';

const TABLE_COLUMNS_CONST = {
    TITLE: { field: 'title', tooltipField: 'rule' },
    ID: { field: 'id', tooltipField: 'rule id' },
    SEVERITY: { field: 'severity', tooltipField: 'severity' },
    PLATFORM: { field: 'platform', tooltipField: 'platform' },
};

const columnDefs: ColDef[] =
    [{
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerName: i18n.t('RULES.TABLE.RULES_HEADER'),
        tooltipField: TABLE_COLUMNS_CONST.TITLE.tooltipField,
        field: TABLE_COLUMNS_CONST.TITLE.field,
        flex: 1,
        filter: 'agMultiColumnFilter',
        resizable: true,
        filterValueGetter: (value: any) => value.data.name,
    },
    {
        headerName: i18n.t('RULES.TABLE.RULE_ID_HEADER'),
        tooltipField: TABLE_COLUMNS_CONST.ID.tooltipField,
        field: TABLE_COLUMNS_CONST.ID.field,
        flex: 1,
        resizable: true,
        filter: 'agMultiColumnFilter',
    },
    {
        headerName: i18n.t('RULES.TABLE.SEVERITY_HEADER'),
        tooltipField: TABLE_COLUMNS_CONST.SEVERITY.tooltipField,
        field: TABLE_COLUMNS_CONST.SEVERITY.field,
        flex: 1,
        filter: 'agMultiColumnFilter',
        resizable: true,
        cellRenderer: 'severityRenderer',
    },
    {
        headerName: i18n.t('RULES.TABLE.PLATFORM_HEADER'),
        tooltipField: TABLE_COLUMNS_CONST.PLATFORM.tooltipField,
        field: TABLE_COLUMNS_CONST.PLATFORM.field,
        flex: 1,
        filter: 'agMultiColumnFilter',
        resizable: true,
        cellRenderer: 'platformRenderer',
    }];

const RulesManagement: FC = () => {
    const rules = useSelector(getRulesManagementData);
    const { push } = useHistory();

    const rowChanged = (params: any) => {
        params.api.forEachNode((node: any) => {
            if (node.data) {
                setSelectedRuleAction(node.data);
            }
        });
    };

    const rowSelected = (params: any) => {
        setSelectedRuleAction(params.data);
    };

    return (
        <div className={'rules-management-container'}>
            <div className='action-buttons-container'>
                <div className='flex'>
                    <Button icon='edit'>{i18n.t('RULES.BUTTON.EDIT')}</Button>
                    <Button icon='delete'>{i18n.t('RULES.BUTTON.DELETE')}</Button>
                    <Button icon='clone'>{i18n.t('RULES.BUTTON.CLONE')}</Button>
                    <Button icon='plus'>{i18n.t('RULES.BUTTON.ADD_TO_RULESET')}</Button>
                </div>
                <Button onClick={() => push('/compliance-engine/rules/rule')} icon='plus' className='mr-[65px]'>
                    {i18n.t('RULES.BUTTON.ADD_RULE')}
                </Button>
            </div>
            <div className='flex'>
                <div className={'rules-management-table-container'}>
                    <AgGridReact
                        enableBrowserTooltips={true}
                        components={{ platformRenderer: PlatformRenderer, severityRenderer: SeverityRenderer }}
                        rowSelection='multiple'
                        groupDisplayType='groupRows'
                        groupDefaultExpanded={-1}
                        rowHeight={25}
                        className='ag-theme-alpine rules-rows'
                        onGridReady={rowChanged}
                        onRowSelected={rowSelected}
                        rowData={rules}
                        columnDefs={columnDefs}
                        suppressRowClickSelection>
                    </AgGridReact>
                </div>
                <RulesManagementPreview />
            </div>
        </div>
    );
};

export default RulesManagement;
