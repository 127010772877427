import React, { useMemo, useRef } from 'react';
import { Handle, Position } from 'reactflow';
import { Popover } from '@dome9/berries/react-components';
import { AssetNodeProps } from 'common/components/Graph/Models/AssetNodeProps';
import { ExposureNodeData } from 'common/module_interface/insight/ExposureNodeData';
import ExposurePopoverComponent from './ExposurePopoverComponent';
import { Icon } from 'common/design-system/components-v2';
import { colors, lightPallete } from 'common/design-system/theme/colors';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

export const INTERNET_NODE_TITLE = 'Internet';
export const PUBLIC_IPS_NODE_TITLE = 'Public IPs';

export const BaseInternetNode: React.FC<{ nodeProps?: AssetNodeProps, title: string, iconName: AllIconsName }> = ({ nodeProps, title, iconName }) => {
    const customSize = 86;
    const handleRef = useRef(null);
    const content = useMemo(() => {
        return (
            <div
                className="flex flex-col items-center group *border rounded-full overflow-hidden"
                style={{ background: colors.grey['200'] }}
            >
                <div
                    className="grid place-items-center "
                    style={{
                        width: `${customSize}px`,
                        height: `${customSize}px`,
                    }}>
                    <Icon name={iconName} size={48} customColor={lightPallete.onSurface.reverse} />
                </div>
                <div className="absolute top-[105px] w-[15ch] rounded-full *bg-content font-semibold px-5 *border text-center truncate">
                    {title}
                </div>
            </div>    
        );
    }, [iconName, title]);

    return (
        <>
            {nodeProps ? <Popover content={ExposurePopoverComponent(nodeProps.data as ExposureNodeData)} maxWidth={400}>{content}</Popover> : content }
            <Handle
                ref={handleRef}
                type="source"
                position={Position.Right}
                isConnectable={true}
                style={{
                    top: '50%',
                    background: 'transparent',
                    width: 0,
                    height: 0,
                    border: 'none',
                }}
            />
        </>
    );
};
