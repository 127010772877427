import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { ReportsFilterTypes } from './Types/ReportsFilterTypes';
import { REPORTS_TABLE } from 'common/module_interface/reporting/Reports.const';
import { createFavoritesFilter, createRecentlyUsedFilter } from 'common/utils/filterUtils';

export const getReportsTableFilters = (): IProtectedAssetFilter[] => [
    ReportsFilterTypes.ADD_FILTER,
    ReportsFilterTypes.FREE_TEXT,
    createRecentlyUsedFilter(REPORTS_TABLE),
    createFavoritesFilter(REPORTS_TABLE),
    ReportsFilterTypes.CLEAR_ALL,
    ReportsFilterTypes.TEMPLATE,
    ReportsFilterTypes.GRANULARITY,
];
