import React, { useCallback } from 'react';
import { Command } from 'common/design-system/components-v2';
import { getSafeFindingSeverityInfo, IFindingSeverityInfo } from 'common/consts/FindingSeverity';
import { SeverityChip } from 'common/components/SeverityChip/SeverityChip';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { RemediationBox } from '../RemediationBox/RemediationBox';
import i18n from 'i18next';
import { LevelIconProps } from 'common/design-system/components-v2/LevelIcon/LevelIcon.types';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import EntityCard from 'common/design-system/components-v2/EntityCard';

interface RuleCardProps {
    finding: IFinding;
}

export const FindingRuleCard: React.FC<RuleCardProps> = ({ finding }) => {

    const { t } = useTranslation(i18nIntelligenceNamespace);
    const NA_STRING = i18n.t('COMMON.N_A');

    const getBlocks = useCallback((): BlockInfoProps[] => {
        return [
            {
                title: t('CARD_PROPS.RULESET'),
                info: finding.rulesetName,
                copyable: true,
                maxWidth: 350
            },
            {
                title: t('CARD_PROPS.ID'),
                info: finding.ruleId,
                copyable: true,
                maxWidth: 350
            },
            {
                title: t('CARD_PROPS.DESCRIPTION'),
                info: finding.ruleDescription,
                maxWidth: 800
            },
            {
                title: '',
                info: <RemediationBox title={t('CARD_PROPS.REMEDIATION')}
                    remediation={finding.ruleRemediation || NA_STRING}></RemediationBox>,
                maxWidth: 800
            },
            {
                title: t('CARD_PROPS.GSL'),
                info: <Command text={finding.ruleLogic || NA_STRING}/>,
                maxWidth: 800
            }
        ];
    }, [NA_STRING, finding.ruleDescription, finding.ruleId, finding.ruleLogic, finding.ruleRemediation, finding.rulesetName, t]);

    const getLevelIcon = useCallback((): LevelIconProps => {
        const severityInfo: IFindingSeverityInfo = getSafeFindingSeverityInfo(finding.severityName as string);
        return {
            category: 'severity',
            level: severityInfo.key,
            iconProps: {
                name: 'rule'
            },
        };
    }, [finding.severityName]);

    const getRightTitleElements = useCallback((): JSX.Element => {
        const severityInfo: IFindingSeverityInfo = getSafeFindingSeverityInfo(finding.severityName as string);
        return <SeverityChip severity={severityInfo.key}/>;
    }, [finding.severityName]);

    return (
        <EntityCard
            cardTitle={t('RULE_CARD.TITLE')}
            title={finding.ruleName}
            blocks={getBlocks()}
            levelIcon={getLevelIcon()}
            titleRightElements={getRightTitleElements()}
        />
    );
};
