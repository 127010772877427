import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { ISecurityControl } from '../SecurityControls.interface';
import { IMultiChipCellProps, MultiChipCellContent } from 'common/erm-components/custom/MultiChipCellContent/MultiChipCellContent';
import { IVendor } from 'common/consts/vendors';
import { getVendorsByPlatforms } from '../../../RiskManagement.utils';

const SecurityControlPlatformsCellRenderer: React.FC<ICellRendererParams<ISecurityControl>> = (params) => {
    const platforms: string[] | undefined = params.data?.platforms;
    if (!platforms) {
        return null;
    }
    const vendors: IVendor[] = getVendorsByPlatforms(platforms);
    if (vendors.length === 0) {
        return null;
    }

    const propsList: IMultiChipCellProps[] = vendors.map(vendor => ({
        label: vendor.displayName,
        leadingIconProps: { vendorNameOrPath: vendor.icon },
    }));

    return <MultiChipCellContent propsList={propsList} />;
};

export default SecurityControlPlatformsCellRenderer;