import React from 'react';
import { ToolBarProps } from './Toolbar.types';
import { ActionType } from '../../AzureEnvironment.types';
import { useTranslation } from 'react-i18next';
import { changeUrl } from 'common/utils/http';
import { getAzureEnvNamespace } from '../../initialize.i18n';
import { AzureEnvironmentAction, AzureEnvironmentData } from 'common/module_interface/assets/AzureEnvironment';
import { AzureEnvironmentAddinRegistry } from 'common/helpers/azureEnvironment';
import { TFunction } from 'i18next';
import { Dropdown, List } from 'common/design-system/components-v2';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import AzureCloudAccountsService from '../../services/cloudAccounts/azureCloudAccounts.service';
import { getNotificationsService, getUserService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';

interface Action {
    type: ActionType;
    label: string;
    icon?: AllIconsName;
    isEnabled?: (azureAccount: AzureEnvironmentData) => boolean;
}
interface Actions {
    singleAction?: Action;
    multiActions?: {
        label: string;
        icon?: AllIconsName;
        items: Array<Action>;
    }
}
const organizationOnboarding = 'OrganizationOnboarding';
const getActions = (t: TFunction): Array<Actions> => [
    { singleAction: { type: ActionType.Remove, label: t('azurePage.toolbar.remove'), icon: 'delete' } },
    { singleAction: { type: ActionType.Rename, label: t('azurePage.toolbar.rename'), icon: 'edit' } },
    { singleAction: { type: ActionType.EditCredentials, label: t('azurePage.toolbar.editCredentials'), icon: 'edit', 
        isEnabled: (azureAccount) => (azureAccount?.account?.onboardingManagementMethod !== organizationOnboarding)
    } },
    { singleAction: { type: ActionType.SyncNow, label: t('azurePage.toolbar.syncNow'), icon: 'refresh' } },
    { singleAction: { type: ActionType.AddCloudBots, label: t('azurePage.toolbar.addCloudBots'), icon: 'plus' } },
    { singleAction: { type: ActionType.RemoveIntelligence, label: t('azurePage.toolbar.removeIntelligence'), icon: 'delete' } },
    { multiActions: {
        label: t('azurePage.toolbar.addIntelligence'),
        icon: 'plus',
        items: [ 
            { type: ActionType.AddFlowLogs, label: t('azurePage.toolbar.addFlowLogs') },
            { type: ActionType.AddActivityLogs, label: t('azurePage.toolbar.addActivityLogs') }
        ] }
    },
];

const Toolbar: React.FunctionComponent<ToolBarProps> = ({
    actionClick,
    azureAccount,
}) => {
    const { t } = useTranslation(getAzureEnvNamespace('azure'));

    const [actionsFromAddins, setActionsFromAddins] = React.useState<Array<AzureEnvironmentAction>>([]);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!azureAccount) return;

        const getAndSetActionsFromAddins = () => {
            const newActions: Array<any> = [];
            const actionsFromAddin = AzureEnvironmentAddinRegistry.getActions();
            const actionsPromises = actionsFromAddin.map(action => {
                return action.isRelevant ? action.isRelevant(azureAccount) : true;
            });
            Promise.all(actionsPromises).then(results => {
                actionsFromAddin.forEach((action, index) => {
                    if (results[index]) {
                        newActions.push(action.getValue(azureAccount));
                    }
                });
                setActionsFromAddins(newActions);
            });
        };

        getAndSetActionsFromAddins();
    }, [azureAccount]);

    const handleOnSyncNowSuccess = React.useCallback(() => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('azurePage.syncNow.onSubmit.success'),
            text: '',
        });
    } , [t]);

    const handleOnSyncNowError = React.useCallback((err: string) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('azurePage.syncNow.onSubmit.error'),
            text: err,
        });
    }, [t]);

    const syncNow = React.useCallback(async (accountId: string) => {
        try{
            await AzureCloudAccountsService.cloudAccountsSyncNow(accountId);
            handleOnSyncNowSuccess();
        }
        catch(err: any){
            handleOnSyncNowError(err.message);
        }
    }, [handleOnSyncNowSuccess, handleOnSyncNowError]);

    const handleOnActionClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: ActionType) => {
        setIsDropdownOpen(false);
        switch (type) {
            case ActionType.EditCredentials: {
                const { altKey, shiftKey } = event;
                if (altKey && shiftKey) {
                    handleOnActionClick(event, ActionType.SyncNow);
                    break;
                }
                actionClick(type);
                break;
            }
            case ActionType.SyncNow: {
                syncNow(azureAccount.account.id);
                break;
            }
            case ActionType.AddFlowLogs:
            case ActionType.AddActivityLogs:{
                let urlType = 'FlowLogs';
                if(type === ActionType.AddActivityLogs){
                    urlType = 'ActivityLogs';
                }
                changeUrl(`/intelligence/azure-onboarding/${azureAccount.account.name}/${azureAccount.account.subscriptionId}/${urlType}/${azureAccount.account.id}`);
                break;
            }
            case ActionType.AddCloudBots:{
                changeUrl(`/remediation/azure-cloudbots-onboarding?vendor=azure&account=${azureAccount.account.subscriptionId}&accountName=${azureAccount.account.name}&id=${azureAccount.account.id}`);
                break;
            }
            default:
                actionClick(type);
        }
    }, [actionClick, syncNow, azureAccount]);

    const isActionTypeActive = React.useCallback((type: ActionType) => {
        switch (type) {
            case ActionType.Remove:
            case ActionType.Rename:
            case ActionType.AddFlowLogs:
            case ActionType.AddActivityLogs:
            case ActionType.EditCredentials: {
                return true;
            }
            case ActionType.AddCloudBots: {
                return !azureAccount.account.isCloudbotsOnboarded;
            }
            case ActionType.RemoveIntelligence: {
                return azureAccount.account.magellan;
            }
            case ActionType.SyncNow: {
                return getUserService().hasPermission(['cloud-account-sync-now']);
            }
            default:
                return false;
        }
    }, [azureAccount]);

    const getIsLoadingState = React.useCallback((type: ActionType) => {
        switch (type) {
            default:
                return false;
        }
    }, []);

    const filteredActions = React.useMemo(() => {
        return getActions(t).
            filter(action => {
                if (action.singleAction) {
                    if (action.singleAction.isEnabled && !action.singleAction.isEnabled(azureAccount)) return null;
                    else return isActionTypeActive(action.singleAction.type);
                } else if (action.multiActions) {
                    return action.multiActions.items.some(act => isActionTypeActive(act.type));
                }
                return false;
            });
    }, [t, azureAccount, isActionTypeActive]);

    const dropdownListOptions = React.useMemo<IListItemProps[]>(() => {
        const listItems: IListItemProps[] = [];
        filteredActions.forEach((action, index) => {
            if (action.singleAction) {
                const { label, type } = action.singleAction;
                listItems.push({
                    label,
                    onClick: (e) => handleOnActionClick(e as any, type),
                    disabled: getIsLoadingState(type),
                    value: `${label}-${index}`
                });
            } else if (action.multiActions) {
                const { label, items } = action.multiActions;
                listItems.push({
                    label,
                    value: `${label}-${index}`,
                    itemType: 'seperator',
                });
                items.forEach(act => {
                    listItems.push({
                        label: act.label,
                        onClick: (e) => handleOnActionClick(e as any, act.type),
                        disabled: getIsLoadingState(act.type),
                        value: `${act.label}-${index}`
                    });
                });
            }
        });
        actionsFromAddins.forEach((action, index) => {
            listItems.push({
                label: action.label,
                icon: action.icon,
                onClick: () => action.callback(azureAccount),
                disabled: action.disabled && action.disabled(azureAccount),
                value: `${action.label}-${index}`
            });
        });
        return listItems;
    }, [filteredActions, actionsFromAddins, getIsLoadingState, handleOnActionClick, azureAccount]);

    
    return (
        <Dropdown
            open={isDropdownOpen}
            onStateChange={(state) => setIsDropdownOpen(state)}
            buttonProps={{
                iconButton: true,
                iconProps: { name: 'more' },
            }}
            maxHeight={500}
        >
            <List
                options={dropdownListOptions}
            />
        </Dropdown>
    );
};


export default Toolbar;
