import { IColumnUsageDef } from 'common/interface/general';
import { ReportsActionTypes } from './Types/ReportsActionTypes';

export const getReportsTableActions = (): IColumnUsageDef[] => [
    ReportsActionTypes.CREATE_REPORT,
    ReportsActionTypes.EDIT_REPORT,
    ReportsActionTypes.RUN_REPORTS,
    ReportsActionTypes.DOWNLOAD_REPORTS,
    ReportsActionTypes.DELETE_REPORTS,
];
