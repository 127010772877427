import React, { useMemo } from 'react';
import { ISecurityControl } from './SecurityControls.interface';
import { fetchAllSecurityControls } from './SecurityControls.utils';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../consts';
import { SECURITY_CONTROL_FIELD_NAMES, SECURITY_CONTROLS_PREFIX_ID } from './SecurityControls.consts';
import { getSecurityControlsFilters } from './SecurityControls.filters';
import { ClientFilterPageTable } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable';
import { getSecurityControlsColumns } from './SecurityControls.columns';
import {
    IClientFilterPageTableProps
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';
import { getAllIssueSeverityCondOptions, getAllPlatformCondOptions } from '../../RiskManagement.options';

export const SecurityControlsPage: React.FC = () => {
    const { t } = useTranslation(I18nRiskNamespace);

    const pageProps: IClientFilterPageTableProps<ISecurityControl> = useMemo(() => ({
        fetchAllItems: fetchAllSecurityControls,
        getTableColumnDefs: getSecurityControlsColumns,
        getTableFilterDefs: getSecurityControlsFilters,
        getFooterItemTitle: (count: number) => t('SECURITY_CONTROLS.GENERAL.SECURITY_CONTROL', { count }),
        pageTableId: SECURITY_CONTROLS_PREFIX_ID,
        getFieldOptionsMap: () => Promise.resolve({
            [SECURITY_CONTROL_FIELD_NAMES.SEVERITY]: {
                options: getAllIssueSeverityCondOptions(),
                showAllOptions: true,
            },
            [SECURITY_CONTROL_FIELD_NAMES.PLATFORMS]: {
                options: getAllPlatformCondOptions(),
                showAllOptions: true,
            },
        }),
    }), [t]);
    return <ClientFilterPageTable {...pageProps} />;
};
