import React, { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Tooltip, Switch, Stack } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';
import { IStorageModel } from '../../AzureIntelligenceOnboarding.interface';
import { SwitchButtonContainer } from '../../AzureIntelligenceOnboarding.styled';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { useSelector } from 'react-redux';
import { getAllStorages, setAllStorages } from '../../AzureIntelligenceOnboarding.reducer';
import { getStoreService } from 'common/interface/services';

const AutoOnboardingCellRenderer: React.FC<ICellRendererParams<IStorageModel>> = ({ data }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });
    const allStorages = useSelector(getAllStorages);
    const [isChecked, setIsChecked] = useState<boolean>(data?.isAutoDiscoveryEnabled ?? false);
    const { dispatch } = getStoreService().getReduxTools();
    
    const selectedStorages = allStorages.filter(storage => storage.isSelected);
    const isSelected = !!selectedStorages?.find(row => row.id === data?.id);
    const isConnected = data?.status === ConnectionStatus.connected || data?.status === ConnectionStatus.partiallyConnected;
    const originalStorageItem = allStorages?.find(row => row.id === data?.id);
    const isAutoOnboarded = originalStorageItem?.isAutoDiscoveryEnabled && isConnected;

    const handleToggle = (id: string) => { 
        if (!id || id.length === 0) return;
        const getUpdatedRowsWithAutoDiscovery = (rowData: IStorageModel[]) => {
            return rowData.map(row => row.id === id ?
                { ...row, isAutoDiscoveryEnabled: !row.isAutoDiscoveryEnabled } : row
            );
        };

        dispatch(setAllStorages(getUpdatedRowsWithAutoDiscovery(allStorages)));
    };

    const handleChange = () => {
        setIsChecked(isChecked => !isChecked);
        handleToggle(data?.id ?? '');
    };

    useEffect(() => {
        const checked = isSelected ? !!data?.isAutoDiscoveryEnabled : false;
        setIsChecked(checked);
    }, [data?.isAutoDiscoveryEnabled, isSelected]);

    return (
        <Stack>
            <Tooltip disabled={!isAutoOnboarded}
                content={isAutoOnboarded ? t('STORAGE.AUTO_ONBOARD_SWITCH_TOOLTIP') : null}>
                <SwitchButtonContainer isAutoOnboarded={!!isAutoOnboarded} isSelected={isSelected}>
                    <Switch checked={isChecked} disabled={!isSelected} onChange={handleChange} />
                </SwitchButtonContainer>
            </Tooltip>
        </Stack>

    );
};

export default AutoOnboardingCellRenderer;
