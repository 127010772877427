import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import {
    IARMTemplateResponseModel,
    IAzureIntelligenceOnboardingState,
    IReportingSubscriptionsModel,
    IStorageModel,
    IAzureResourceGroupsModel,
    SelectedTab
} from './AzureIntelligenceOnboarding.interface';
import {
    AssetType,
    AzureCentralizedStepsCount,
    AzureIntelligenceOnboardingType, AzureNSGStepsCount
} from './AzureIntelligenceOnboarding.const';

const initialState: IAzureIntelligenceOnboardingState = {
    step: 0,
    azureOnboardingType: AzureIntelligenceOnboardingType.centralized,
    azureIntelligenceType: AssetType.flowLogs,
    subscriptionId: '',
    cloudAccountId: '',
    ipAddress: '',
    isLoading: false,
    nextButtonDisabled: false,
    finishButtonDisabled: false,
    onboardingStatus: false,
    selectedLogTypes: [AssetType.activityLogs],
    allStorages: [],
    nsgStorages: [],
    allSubscriptions: [],
    selectedSubscriptions: [],
    armTemplateDetails: null,
    selectedStorageTab: SelectedTab.Manual
};

export const azureIntelligenceOnboardingSlice = createSlice({
    name: 'azureIntelligenceOnboarding',
    initialState,
    reducers: {
        goToNextStep: (state: IAzureIntelligenceOnboardingState) => {
            state.step += 1;
        },
        goToBackStep: (state: IAzureIntelligenceOnboardingState) => {
            state.step -= 1;
        },
        goToSpecificStep: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<number>) => {
            state.step = action.payload;
        },
        setNextButtonDisabled: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<boolean>) => {
            state.nextButtonDisabled = action.payload;
        },
        toggleIsLoading: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        clearAzureOnboardingState: () => initialState,
        setAzureOnboardingType: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<AzureIntelligenceOnboardingType>) => {
            state.azureOnboardingType = action.payload;
        },
        setAzureIntelligenceType: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<AssetType>) => {
            state.azureIntelligenceType = action.payload;
        },
        setSubscriptionId: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<string>) => {
            state.subscriptionId = action.payload;
        },
        setCloudAccountId: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<string>) => {
            state.cloudAccountId = action.payload;
        },
        setIpAddress: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<string>) => {
            state.ipAddress = action.payload;
        },
        setSelectedLogTypes: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<AssetType[]>) => {
            state.selectedLogTypes = action.payload;
        },
        setAllStorages: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<IStorageModel[]>) => {
            state.allStorages = action.payload;
        },
        setNSGStorages: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<IAzureResourceGroupsModel[]>) => {
            state.nsgStorages = action.payload;
        },
        setARMTemplateDetails: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<IARMTemplateResponseModel>) => {
            state.armTemplateDetails = action.payload;
        },
        setAllSubscriptions: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<IReportingSubscriptionsModel[]>) => {
            state.allSubscriptions = action.payload;
        },
        setSelectedSubscriptions: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<IReportingSubscriptionsModel[]>) => {
            state.selectedSubscriptions = action.payload;
        },
        setOnboardingStatus: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<boolean>) => {
            state.onboardingStatus = action.payload;
        },
        setSelectedStorageTab: (state: IAzureIntelligenceOnboardingState, action: PayloadAction<SelectedTab>) => {
            state.selectedStorageTab = action.payload;
        }
    }

});

export const {
    toggleIsLoading,
    goToNextStep,
    goToBackStep,
    goToSpecificStep,
    setNextButtonDisabled,
    clearAzureOnboardingState,
    setAzureOnboardingType,
    setAzureIntelligenceType,
    setSubscriptionId,
    setCloudAccountId,
    setIpAddress,
    setSelectedLogTypes,
    setAllStorages,
    setNSGStorages,
    setARMTemplateDetails,
    setAllSubscriptions,
    setSelectedSubscriptions,
    setOnboardingStatus,
    setSelectedStorageTab
} = azureIntelligenceOnboardingSlice.actions;

export default azureIntelligenceOnboardingSlice.reducer;

export const getStep = (state: RootState): number => state.azureIntelligenceOnboarding.step;
export const getIsLoading = (state: RootState): boolean => state.azureIntelligenceOnboarding.isLoading;
export const canGoForward = (state: RootState): boolean => {
    const stepsCount = state.azureIntelligenceOnboarding.azureOnboardingType === AzureIntelligenceOnboardingType.centralized ? AzureCentralizedStepsCount : AzureNSGStepsCount;
    return state.azureIntelligenceOnboarding.step < (stepsCount - 1);
};
export const canGoBack = (state: RootState): boolean => {
    const stepsCount = state.azureIntelligenceOnboarding.azureOnboardingType === AzureIntelligenceOnboardingType.centralized ? AzureCentralizedStepsCount : AzureNSGStepsCount;
    return state.azureIntelligenceOnboarding.step > 0 && state.azureIntelligenceOnboarding.step < (stepsCount - 1);
};
export const getAzureOnboardingNextButtonDisabled = (state: RootState) => state.azureIntelligenceOnboarding.nextButtonDisabled;
export const getAzureOnboardingFinishButtonDisabled = (state: RootState) => state.azureIntelligenceOnboarding.finishButtonDisabled;
export const getAzureOnboardingType = (state: RootState): AzureIntelligenceOnboardingType => state.azureIntelligenceOnboarding.azureOnboardingType;
export const getAzureIntelligenceType = (state: RootState): AssetType => state.azureIntelligenceOnboarding.azureIntelligenceType;
export const getSubscriptionId = (state: RootState): string => state.azureIntelligenceOnboarding.subscriptionId;
export const getIpAddress = (state: RootState): string => state.azureIntelligenceOnboarding.ipAddress;
export const getSelectedLogTypes = (state: RootState): AssetType[] => state.azureIntelligenceOnboarding.selectedLogTypes;
export const getAllStorages = (state: RootState): IStorageModel[] => state.azureIntelligenceOnboarding.allStorages;
export const getARMTemplateDetails = (state: RootState): IARMTemplateResponseModel => state.azureIntelligenceOnboarding.armTemplateDetails;
export const getAllSubscriptions = (state: RootState): IReportingSubscriptionsModel[] => state.azureIntelligenceOnboarding.allSubscriptions;
export const getSelectedSubscriptions = (state: RootState): IReportingSubscriptionsModel[] => state.azureIntelligenceOnboarding.selectedSubscriptions;
export const getOnboardingStatus = (state: RootState): boolean => state.azureIntelligenceOnboarding.onboardingStatus;
export const getSelectedStorageTab = (state: RootState): SelectedTab => state.azureIntelligenceOnboarding.selectedStorageTab;
