import { Button, IconButton } from 'common/design-system/components-v2';
import styled, { css } from 'styled-components/macro';

export const menuResizeAnimationTime = 320;


const MenuWrapper = styled.div<{ isMinimized: boolean; }>`
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--border-color-medium);
    position: relative;
    width: ${({ isMinimized }) => isMinimized ? '44px' : '250px'};
    transition: width ${menuResizeAnimationTime}ms ease-in-out;
`;

const SearchButton = styled(Button) <{ isMinimized: boolean; }>`
    && {
        max-width: unset;
        margin: 0 auto;
        width: calc(100% - 20px);

        ${({ isMinimized }) => isMinimized && css`
            max-width: fit-content;
            width: 100%;
        `}
    }
`;

const MinimizeButton = styled(IconButton) <{ isMinimized: boolean; }>`
    position: absolute;
    right: -10px;
    top: 10px;
    z-index: 200;

    transform: rotate(${({ isMinimized }) => !isMinimized ? '180deg' : '0deg'});
    transition: transform 0.4s ease-in-out;
`;

const Seperator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.palette.border.light};
    margin-top: ${({ theme }) => theme.spacing(2)}${({ theme }) => theme.units};
    margin-bottom: ${({ theme }) => theme.spacing(1)}${({ theme }) => theme.units};
`;

export default {
    MenuWrapper,
    SearchButton,
    MinimizeButton,
    Seperator
};