import {
    DashboardWidgetTypes,
    IDashboard,
} from 'common/module_interface/overview/Interface';
import { v4 } from 'uuid';
import { ICloudAccount } from 'common/interface/data_services';
import { DEFAULT_LOCAL_DASHBOARD_ID } from 'common/module_interface/overview/Consts';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';


export default function initializeHomeDashboard(allCloudAccounts: ICloudAccount[]) {

    const mappedCloudAccountsByExternalId = allCloudAccounts?.map((cloudAccount) => cloudAccount.externalId);

    const newHomeDashboard: IDashboard = {
        id: DEFAULT_LOCAL_DASHBOARD_ID,
        name: 'Home Dashboard',
        isLocalDashboard: true,
        isPinned: true,
        sections: [
            {
                id: 'inventory',
                title: 'INVENTORY',
                dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                position: 1,
                isExpanded: true,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Accounts,
                        'title': 'Monitored environments',
                        'limit': 10,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Environments',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'monitored',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Summary,
                        'title': 'Protected Assets',
                        'limit': 10,
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m',
                            'thresholds': [
                                {
                                    'min': 0,
                                    'max': 0,
                                    'color': '#333',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ]
                                },
                            ]
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Top Protected Assets',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'type',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Pie,
                        'title': 'Protected Assets Per Platform',
                        'limit': 5,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'platform',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    }
                ]
            },
            {
                id: 'highSeverityAlerts',
                title: 'HIGH SEVERITY ALERTS',
                dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                position: 2,
                isExpanded: true,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'highSeverityAlerts',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Top entities by high severity alerts count',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'highSeverityAlerts',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Environments with high severity alerts',
                        'limit': 10,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'cloudAccountId_calc',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'highSeverityAlerts',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Storage with high severity alerts',
                        'limit': 50,
                        'description': 'High risks on storage',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|S3Bucket'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '7|StorageAccount'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|S3Bucket'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|S3'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '10|StorageBucket'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '22|StorageBucket'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'highSeverityAlerts',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Security group with high severity alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|SecurityGroup'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '10|GcpSecurityGroup'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '7|NetworkSecurityGroup'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '18|SecurityGroup'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '22|NetworkSecurityGroup'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'highSeverityAlerts',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'User with high severity alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|IamUser'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '10|GcpIamUser'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '7|IAmUser'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '22|User'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'highSeverityAlerts',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Computing with high severity alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '10|VMInstance'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|instance'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '1|Instance'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '22|ComputeInstance'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    }
                ]
            },
            {
                id: 'intelligence',
                isExpanded: true,
                title: 'INTELLIGENCE',
                dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                position: 3,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'intelligence',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Top Critical Alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': true,
                        'aggregation': 'ruleName',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'origin',
                                'value': '1'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'intelligence',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Top Entities With Critical Alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': true,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'origin',
                                'value': '1'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'intelligence',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Trend,
                        'title': 'Alerts Trendline',
                        'limit': 5,
                        'description': '',
                        'hideOnNoData': true,
                        'aggregation': null,
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            'alertsOptions': [
                                {
                                    'type': 'Organizational Unit',
                                    'title': 'Medium',
                                    'color': '#ffb72d',
                                    'selected': mappedCloudAccountsByExternalId,
                                    'selectedName': DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    'widgetConfigFilterState': [
                                        {
                                            'name': 'organizationalUnitId'
                                        },
                                        {
                                            'name': 'severity',
                                            'value': 'Medium'
                                        },
                                        {
                                            'name': 'origin',
                                            'value': 1
                                        }
                                    ]
                                },
                                {
                                    'type': 'Organizational Unit',
                                    'color': '#ff9126',
                                    'title': 'High',
                                    'selected': mappedCloudAccountsByExternalId,
                                    'selectedName': DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    'widgetConfigFilterState': [
                                        {
                                            'name': 'organizationalUnitId'
                                        },
                                        {
                                            'name': 'severity',
                                            'value': 'High'
                                        },
                                        {
                                            'name': 'origin',
                                            'value': 1
                                        }
                                    ]
                                },
                                {
                                    'type': 'Organizational Unit',
                                    'color': '#f24e4e',
                                    'title': 'Critical',
                                    'selected': mappedCloudAccountsByExternalId,
                                    'selectedName': DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    'widgetConfigFilterState': [
                                        {
                                            'name': 'organizationalUnitId'
                                        },
                                        {
                                            'name': 'severity',
                                            'value': 'Critical'
                                        },
                                        {
                                            'name': 'origin',
                                            'value': 1
                                        }
                                    ]
                                }
                            ],
                            'navigateOption': 'Alerts',
                            'binSize': '10m',
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            }
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'intelligence',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Top Failed Actions',
                        'limit': 10,
                        'description': '',
                        'hideOnNoData': true,
                        'aggregation': 'event.name',
                        'dataSourceName': 'Event Activity (AWS)',
                        'gslFilter': 'cloudtrail where event.status=\'Failure\'',
                        'cloudAccountId': '__ALL_ACCOUNTS__',
                        'timeFrame': 24,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Event Activity (AWS)',
                            'binSize': '10m'
                        }
                    }
                ]
            },
            {
                id: 'postureManagement',
                title: 'POSTURE MANAGEMENT',
                isExpanded: true,
                dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                position: 4,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Gauge,
                        'title': 'AWS | CloudGuard Dashboard Ruleset Score',
                        'limit': 5,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Compliance',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'compliance': [
                                {
                                    'type': 'Organizational Unit',
                                    'selected': DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    'rulesetId': -13
                                }
                            ],
                            'navigateOption': 'Compliance',
                            'binSize': '10m',
                            'thresholds': [
                                {
                                    'min': 0,
                                    'max': 45,
                                    'color': '#F24E4E',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ],
                                    'disableColorPicker': true
                                },
                                {
                                    'min': 46,
                                    'max': 70,
                                    'color': '#FFD546',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ],
                                    'disableColorPicker': true
                                },
                                {
                                    'min': 71,
                                    'max': 0,
                                    'color': '#56B40A',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ],
                                    'disableColorPicker': true
                                }
                            ]
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
                        'type': DashboardWidgetTypes.Trend,
                        'title': 'AWS | Trend of CloudGuard Dashboard Ruleset',
                        'limit': 5,
                        'description': '',
                        'hideOnNoData': true,
                        'aggregation': null,
                        'dataSourceName': 'Compliance',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'compliance': [
                                {
                                    'type': 'Organizational Unit',
                                    'selected': DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    'rulesetId': -13
                                }
                            ],
                            'navigateOption': 'Compliance',
                            'binSize': '10m'
                        }
                    }
                ]
            },
        ]
    };


    return newHomeDashboard;
}
