import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import EntityLink from 'common/components/ProtectedAssets/Renderers/EntityLink';
import { getAssetUrlFromIssue, getEntityNameFromIssue } from '../Issues.utils';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';

export const IssueEntityNameLink: React.FC<{ issue: IIssue, containerName?: string, renderParams?: ICellRendererParams<IIssue> , showFullName: boolean }> = ({ issue, containerName , renderParams, showFullName }) => {
    const fullName = getEntityNameFromIssue(issue, showFullName);
    const url = getAssetUrlFromIssue(issue);
    if (renderParams) {
        CellToClipboardHandler(renderParams, fullName);
    }

    if (!url) {
        const options: IIconLinkCellProps = {
            isLink: false,
            value: fullName,
        };
        return (<IconLinkCellRenderer {...options} />);
    } else {
        return <EntityLink entityName={fullName} url={url} containerName={containerName}/>;
    }
};

export const IssueEntityNameCellRender: React.FC<ICellRendererParams<IIssue>> = (params) => {
    const issue: IIssue | undefined = params.data;
    if (!issue?.id) {
        return null;
    }
    return <IssueEntityNameLink issue={issue} renderParams={params} containerName={'issue-entity-cell'} showFullName={false}/>;
};