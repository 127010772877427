import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { SortOrder } from 'common/interface/general';
import { BusinessStyled } from './BusinessPriorityRulesCellRenderers.styled';
import IssueSeverityBox from '../Issues/General/IssueSeverityBox';
import { getIssueSeverities, IIssueSeverityInfo } from '../Issues/IssueSeverity';
import isNil from 'lodash/isNil';
import { FILTER_PANEL_QUERY_NAMES } from 'common/components/FilterPanel/FilterPanel.consts';
import { ERM_URLS } from '../../consts';
import { changeUrl, toQueryString } from 'common/utils/http';
import {
    IIssueSeverityCounters,
    IssueSeverityEnum,
    IssueStatusEnum,
} from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { ISSUE_FIELD_NAMES } from '../Issues/Issues.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';

const IssueSeverityCountersCellRenderer: React.FC<ICellRendererParams<IProtectedAssetViewModel>> = (params) => {
    const severityCounters: IIssueSeverityCounters | undefined = params.data?.context?.openSecurityIssues;

    if (isNil(severityCounters) || isNil(params.data)) {
        return null;
    }

    const createSeverityBox = (severityInfo: IIssueSeverityInfo, entityId: string) => {
        const count = severityCounters[severityInfo.key] ?? 0;
        let onClick = undefined;
        if (count > 0) {
            const query = {
                [FILTER_PANEL_QUERY_NAMES.FILTER_PANEL]: JSON.stringify({
                    entityId,
                    [ISSUE_FIELD_NAMES.STATUS]: [IssueStatusEnum.OPEN],
                    [ISSUE_FIELD_NAMES.SEVERITY]: [severityInfo.level],
                }),
            };
            const url = `${ERM_URLS.ISSUES}?${toQueryString({ query })}`;
            onClick = () => {
                changeUrl(url);
            };
        }
        return <IssueSeverityBox number={count} severity={severityInfo.key} size={'tiny'} key={severityInfo.key}
            onClick={onClick}></IssueSeverityBox>;
    };


    return (
        <BusinessStyled.SeverityDiv>
            {getIssueSeverities(SortOrder.descending, IssueSeverityEnum.low).map((severityInfo: IIssueSeverityInfo) => createSeverityBox(severityInfo, params.data!.id))}
        </BusinessStyled.SeverityDiv>);
};

export default IssueSeverityCountersCellRenderer;