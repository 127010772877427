import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow: auto;
`;

const SummarySection = styled(Stack)`
    flex-direction: column;
    width: 100%;
`;

export const CdrDashboardStyled = {
    TopDiv,
    SummarySection,
};
