import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { IAutoAction } from '../AutoActions.interface';
import { isNil } from 'common/utils/helpFunctions';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { openAutoActionEditorDrawer } from '../AutoActions.utils';

export const AutoActionNameCellRender: React.FC<ICellRendererParams<IAutoAction>> = (params) => {
    const autoAction: IAutoAction | undefined = params.data;
    if (!autoAction?.name) {
        return '';
    }

    const onClick = () => {
        openAutoActionEditorDrawer(autoAction);
    };

    const options: IIconLinkCellProps = {
        isLink: !isNil(onClick),
        value: params.value,
        onClick,
        levelIconProps: {
            iconProps: { name: 'automatedAction' },
            size: 'md',
        }
    };

    CellToClipboardHandler(params, autoAction.name);
    return (<IconLinkCellRenderer {...options} />);
};
