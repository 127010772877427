import { IColumnUsageDef } from 'common/interface/general';
import { FindingsColumnTypes as ColumnTypes } from '../FindingsTable/Types/FindingsColumnTypes';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { TFunction } from 'i18next';

const getEventTimeColumn = (): IColumnUsageDef => {
    const t: TFunction = i18n.getFixedT(null, i18nIntelligenceNamespace);
    return {
        ...ColumnTypes.CREATED_TIME,
        colDefOverride: {
            ...ColumnTypes.CREATED_TIME.colDefOverride,
            headerName: t('FINDINGS_TABLE.COLUMNS.EVENT_TIME.HEADER'),
            headerTooltip: t('FINDINGS_TABLE.COLUMNS.EVENT_TIME.TOOLTIP'),
        }
    };
};

export const getAllEventsTableColumns = (): IColumnUsageDef[] => [
    ColumnTypes.SELECTION,
    ColumnTypes.RULE_NAME,
    ColumnTypes.SEVERITY,
    getEventTimeColumn(),
    ColumnTypes.ENVIRONMENT,
    ColumnTypes.DESCRIPTION,
    ColumnTypes.RULESET_NAME,
    ColumnTypes.ENTITY_NAME,
    ColumnTypes.ENTITY_TYPE,
    ColumnTypes.ACTION,
    ColumnTypes.STATUS,
    ColumnTypes.ORIGIN,
    ColumnTypes.ASSIGNEE,
    ColumnTypes.FIRST_OPENED_TIME,
];
