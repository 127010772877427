import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';
import { ILanguageName } from 'common/services/translations/translations';

interface GlobalPersistState {
    language: ILanguageName;
}

const initialState: GlobalPersistState = {
    language: 'en',
};

const globalPersistSlice = createSlice({
    name: 'globalPersist',
    initialState,
    reducers: {
        setAppLanguage: (state: GlobalPersistState, action: PayloadAction<ILanguageName>) => {
            state.language = action.payload;
        },
    },
});

export const { setAppLanguage } = globalPersistSlice.actions;

export const getAppLanguage = (state: RootState): ILanguageName => state.globalPersist.language;

export default globalPersistSlice.reducer;
