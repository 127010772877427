import i18n, { validLanguages } from 'common/services/translations/translations';

export const OciEnvNamespacePrefix = 'oci-env';
export type OciEnvTermTypes = 'oci';
const termsFolders: Array<OciEnvTermTypes> = ['oci'];

export const getOciEnvNamespace = (term: OciEnvTermTypes) => `${OciEnvNamespacePrefix}_${term}`;

const jsonFilesPaths = validLanguages.reduce<Array<{ path: string; language: string; term: OciEnvTermTypes; }>>((acc, language) => {
    const filesPAth = termsFolders.map(term => ({ path: `${language}/${term}`, language, term }));
    return [...acc, ...filesPAth];
}, []);

export const initializeOciEnvI18n = async () => {
    await Promise.all(jsonFilesPaths.map(async (file) => {
        try {
            const contents = await require(`./languages/${file.path}.json`);
            i18n.addResourceBundle(file.language, getOciEnvNamespace(file.term), contents);
        // eslint-disable-next-line
        } catch (error) { }
    }));
};