import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Icon, Stack, Typography } from 'common/design-system/components-v2';
import { IEvent } from 'common/module_interface/events/Events';
import { getSafeFindingSeverityInfo } from 'common/consts/FindingSeverity';

const SeverityCellRenderer = React.forwardRef<any, ICellRendererParams<IEvent>>((params, ref) => {
    const { severity } = params.data || {};
    const [displayedSeverity, setDisplayedSeverity] = React.useState<string | undefined>(severity);


    React.useImperativeHandle(ref, () => {
        return {
            refresh(params: IEvent) {
                if (params.severity) {
                    setDisplayedSeverity(params.severity);
                }
                return true;
            },
        };
    });

    if (!severity) return null;

    const severityInfo = getSafeFindingSeverityInfo(displayedSeverity || '');

    return (
        <Stack alignItems='center' spacing={2} direction='row' overflow='hidden'>
            <Icon name={severityInfo.iconProps.name || 'severityUnknown'} context={severityInfo.context} />
            <Typography ellipsis>{severity}</Typography>
        </Stack>
    );
});
SeverityCellRenderer.displayName = 'SeverityCellRenderer';

export default SeverityCellRenderer;