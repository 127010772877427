import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedRule } from './RulesManagement.reducer';
import ExpandButton from 'common/components/ExpandButton/ExpandButton';
import { Icon } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';

const RulesManagementPreview: FC = () => {
    const { t } = useTranslation();
    const selectedRule = useSelector(getSelectedRule);

    return (
        <>
            {selectedRule ? (
                <div className={'rule-preview-container'}>
                    <div className={'rule-preview-title-container'}>
                        <div className={'rule-preview-title'}>{selectedRule.title}</div>
                        <div className={'rule-preview-edit-container'}>
                            <Icon name='edit' className={'rule-preview-edit-container-icon'} />
                            {t('RULES.RULE_PREVIEW.EDIT')}
                        </div>
                    </div>
                    <div className={'rule-preview-id'}>
                        {t('RULES.RULE_PREVIEW.ID')} {selectedRule.id}
                    </div>
                    <div className='ml-5'>
                        <ExpandButton title={t('RULES.RULE_PREVIEW.TITLE.OVERVIEW')}>
                            <div key={selectedRule.id}>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.DESCRIPTION')}
                                    </div>
                                    {' '}
                                    <div>{selectedRule.description}</div>
                                </div>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.SEVERITY')}
                                    </div>
                                    {' '}
                                    <div>{selectedRule.severity}</div>
                                </div>
                                <div className={'rule-preview-container-collapsable-overview-title'}>
                                    {t('RULES.RULE_PREVIEW.LABELS')}
                                </div>
                            </div>
                        </ExpandButton>
                    </div>
                    <div className='ml-5'>
                        <ExpandButton title={t('RULES.RULE_PREVIEW.TITLE.GSL')}>
                            <div key={selectedRule.id}>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.RULE')}
                                    </div>
                                    {' '}
                                    {selectedRule.title}
                                </div>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.ID')}
                                    </div>
                                    {' '}
                                    {selectedRule.id}
                                </div>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.PLATFORM')}
                                    </div>
                                    {' '}
                                    {selectedRule.logics.map((logic) => logic.platform)}
                                </div>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.ENTITY')}
                                    </div>
                                    {' '}
                                    {selectedRule.logics.map((logic) => logic.entityType)}
                                </div>
                            </div>
                        </ExpandButton>
                    </div>
                    <div className='ml-5'>
                        <ExpandButton title={t('RULES.RULE_PREVIEW.TITLE.REMEDIATION')}>
                            <div>
                                <div className={'rule-preview-container-collapsable-overview'} key={selectedRule.id}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.AUTOMATED')}
                                    </div>
                                    {' '}
                                    {selectedRule.logics.map((logic) =>
                                        logic.remediation.autoRemediation.map((automated) => automated.cloudBotName),
                                    )}
                                </div>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.MANUAL')}
                                    </div>
                                    {' '}
                                    {selectedRule.logics.map((logic) => logic.remediation.manualRemediation)}
                                </div>
                            </div>
                        </ExpandButton>
                    </div>
                    <div className='ml-5'>
                        <ExpandButton title={t('RULES.RULE_PREVIEW.TITLE.HISTORY')}>
                            <div key={selectedRule.id}>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.LAST_UPDATED')}
                                    </div>
                                    {' '}
                                    {selectedRule.modified}
                                </div>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.UPDATED_BY')}
                                    </div>
                                    {' '}
                                    {selectedRule.modifiedUser}
                                </div>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.CREATION_DATE')}
                                    </div>
                                    {' '}
                                    {selectedRule.created}
                                </div>
                                <div className={'rule-preview-container-collapsable-overview'}>
                                    <div className={'rule-preview-container-collapsable-overview-title'}>
                                        {t('RULES.RULE_PREVIEW.CREATED_BY')}
                                    </div>
                                    {' '}
                                    {selectedRule.createdUser}
                                </div>
                            </div>
                        </ExpandButton>
                    </div>
                </div>
            ) : (
                <div className={'rule-preview-container'}>Nothing to Show</div>
            )}
        </>
    );
};

export default RulesManagementPreview;
