import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams, WizardStep } from 'common/components/Wizard/Wizard';
import { getNotificationsService } from 'common/interface/services';
import { IPolicy } from 'common/interface/policy';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Done } from 'common/components/policy/Done/Done';
import { useLocation } from 'react-router-dom';
import { NotificationType } from 'common/interface/notifications';
import { getPolicy, updatePolicies } from './utils';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { I18nIdentityNamespace } from '../consts';
import { finishFlow } from 'common/components/policy/utils';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

const CIEM_RULESET_ID = 802;
export const EditCIEMPolicyPage: React.FC = () => {
    const { t } = useTranslation(I18nIdentityNamespace);
    const [policy,setPolicy] = useState<IPolicy>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const location = useLocation();
    const returnUrl = useReturnUrl();
    useBreadcrumbsLastChildren([t('NAVIGATION_MENU.CIEM.TITLE'),t('POLICY.EDIT_POLICY')]);

    const saveChanges = useCallback(async () => {
        if(policy && selectedNotifications?.length){
            setFlowStatusMessage(t('POLICY.PROCESSING'));
            policy.rulesetId = CIEM_RULESET_ID;
            policy.targetId = policy.targetId || policy.targetInternalId;
            policy.notificationIds = selectedNotifications.map((notification:ListItem) => notification.id);
            const result = await updatePolicies([policy]);
            if(result) {
                setFlowStatusOK(true);
                setFlowStatusMessage(t('POLICY.POLICY_ADDED_MESSAGE'));
            }else {
                setFlowStatusOK(false);
                setFlowStatusMessage(t('POLICY.ERROR_OCCURRED'));
            }
        }
    },[policy, selectedNotifications, t]);


    const steps :WizardStep[] = useMemo(() => [
        {
            id: 'notificationSelection',
            name: t('POLICY.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return(
                    <>
                        { selectedNotifications && <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={selectedNotificationsChanged}/>}
                    </>
                );
            },
            onNext: async ()=>{
                saveChanges();
            },
        },{
            id: 'done',
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ],[flowStatusMessage, flowStatusOK, saveChanges, selectedNotifications, t]);

    const raisePolicyNotFoundErrorMessage = () => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('POLICY.ERROR_OCCURRED'),
            text: t('POLICY.POLICY_NOT_FOUND'),
        });
    };

    useEffect(() => {
        const handlePolicyLoad = async (policyId: string) => {
            const _policy = await getPolicy(policyId,false);
            if(_policy){
                setPolicy(_policy);
                setSelectedNotifications(_policy.notificationIds.map((notificationId:string) => ({ id: notificationId })) as ListItem[]);
            }else{
                raisePolicyNotFoundErrorMessage();
            }
        };
        if(!policy){
            const querystring = new URLSearchParams(location.search);
            const policyId = querystring.get('policyId');
            if(!policyId){
                raisePolicyNotFoundErrorMessage();
                return;
            }else{
                handlePolicyLoad(policyId);
            }
        }
    }, [ location, steps.length, policy]);

    const selectedNotificationsChanged = (selectedItems: ListItem[]) => {
        setSelectedNotifications(selectedItems);
    };

    return (
        <div>
            {steps.length ? <Wizard
                steps={steps}
                title={t('POLICY.EDIT_POLICY')}
                doneButtonText={t('POLICY.DONE')}
                onClose={() => finishFlow(returnUrl)}
            /> : null}
        </div>
    );
};