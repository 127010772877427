import React from 'react';
import { useSelector } from 'react-redux';
import AwsActions from '../aws.actions';
import { AwsSelector } from '../aws.selectors';
import { RootState } from 'common/services/store/store';
import { AwsEnvironmentData } from 'common/module_interface/assets/AwsEnvironment';

export interface UseGetAwsPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isDeleted: boolean;
    data?: AwsEnvironmentData;

    reloadData: () => void;
}

export type UseGetAws = (awsId: string) => UseGetAwsPayload;
export const useGetAws: UseGetAws = (awsId) => {
    const { loading, error, data, deleted } = useSelector((state: RootState) => AwsSelector.getAws(state, awsId)) || {};

    const getData = (id: string) => AwsActions.getAwsAccount(id);
 
    React.useEffect(() => {
        if (!loading && !data && !error) {
            getData(awsId);
        }
    }, [awsId, data, loading, error]);

    const reloadData = React.useCallback(() => getData(awsId), [awsId]);

    return ({
        isLoading: !!loading && !data,
        isFetching: !!loading,
        isError: !!error,
        isDeleted: !!deleted,
        data,

        reloadData
    });
};