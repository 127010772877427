import React, { useCallback, useEffect, useState } from 'react';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { ViewerStyled } from '../../IssueViewer.styled';
import { EvidenceStyled } from './IssueEvidencePanel.styled';
import { getCloudEntityDataFromIssue } from '../../../Issues.utils';
import Evidences from '../../Evidences/Evidences';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';

export const IssueEvidencePanel: React.FC<{ issue: IIssue }> = ({ issue }) => {
    const [cloudEntity, setCloudEntity] = useState<ICloudEntityData>();

    const loadCloudEntityInfo = useCallback(async (issue: IIssue) => {
        try {
            const cloudEntityData = await getCloudEntityDataFromIssue(issue);
            if (cloudEntityData) {
                setCloudEntity(cloudEntityData);
            }
        } catch (ex) {
            console.error('Failed to fetch cloudEntityData');
        }
    },[]);

    useEffect(() => {
        void loadCloudEntityInfo(issue);
    }, [loadCloudEntityInfo, issue]);

    return (
        <ViewerStyled.PanelTopDiv>
            <EvidenceStyled.SubPanelsListTopDiv>
                {cloudEntity &&
                    <Evidences
                        asset={cloudEntity.protectedAsset}
                        issue={issue}
                        initiallyOpen={true}
                        cloudEntity={cloudEntity}
                    />
                }
            </EvidenceStyled.SubPanelsListTopDiv>
        </ViewerStyled.PanelTopDiv>
    );
};
