import React, { ChangeEvent, FunctionComponent, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    FirstDataRenderedEvent,
    GetRowIdParams,
    GridApi,
    GridOptions,
    GridReadyEvent, IRowNode,
    RowSelectedEvent
} from 'ag-grid-community';
import { Icon, Input, Stack, Table } from 'common/design-system/components-v2';
import { getStoreService } from 'common/interface/services';
import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';
import { AzureOnboardingStep, AzureOnboardingStepTitle, SearchInputWrapper, StorageTable } from '../../AzureIntelligenceOnboarding.styled';
import { IStorageModel } from '../../AzureIntelligenceOnboarding.interface';
import {
    getAllStorages,
    setAllStorages,
    setNextButtonDisabled,
} from '../../AzureIntelligenceOnboarding.reducer';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import Switch from 'common/design-system/components-v2/Switch';
import { getColumnDefs } from './NSGStoragesColumnDefinitions';

const NSGStoragesTable: FunctionComponent = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });
    const [isHideStorage, setIsHideStorage] = useState(false);
    const dispatch = getStoreService().dispatch;

    const allStorages = useSelector(getAllStorages);

    const apiRef = React.useRef<GridApi>();

    const defaultColDef = useMemo(() => ({
        resizable: true,
        suppressMenu: true,
        suppressMovable: true,
        flex: 1,
    }), []);

    const selectedStorages = useMemo(() => allStorages?.filter(storage => storage.isSelected), [allStorages]);

    const onGridReady = (params: GridReadyEvent) => {
        apiRef.current = params.api;
    };

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        apiRef.current?.setQuickFilter(event.target.value);
    };

    const applyExternalFilter = () => {
        const filterModel = {
            status: {
                type: 'notEqual',
                filter: ConnectionStatus.connected,
            }
        };
        apiRef.current?.setFilterModel(filterModel);
        apiRef.current?.onFilterChanged();
    };

    const clearExternalFilter = () => {
        apiRef.current?.setFilterModel(null);
        apiRef.current?.onFilterChanged();
    };

    const onHideStorage = (event: ChangeEvent<HTMLInputElement>) => {
        setIsHideStorage(!isHideStorage);

        if (event.target.checked) {
            applyExternalFilter();
        } else {
            clearExternalFilter();
        }
    };

    const onSelectionChanged = React.useCallback(() => {
        const selectedRows: IStorageModel[] = apiRef.current?.getSelectedRows() ?? [];
        const updatedStorages: IStorageModel[] = allStorages.map(storage => {
            const isSelected = selectedRows.some(row => row.id === storage.id);
            return { ...storage, isSelected };
        });
    
        dispatch(setAllStorages(updatedStorages));
    
        if (selectedRows.length) {
            dispatch(setNextButtonDisabled(false));
        } else {
            dispatch(setNextButtonDisabled(true));
        }
    }, [apiRef, allStorages, dispatch]);

    const onRowSelected = (event: RowSelectedEvent) => {
        const multipleStorageNames = event.node.parent?.allLeafChildren?.filter(node => 
            event.data.storageExist && 
            (node.data.storageName === event.data.storageName) && 
            (node.data.id !== event.data.id)
        );

        const changeSelection = (selectNode: boolean) => {
            multipleStorageNames?.forEach((node: IRowNode) => {
                if (selectNode && !node.isSelected()) node.setSelected(true);
                else if (!selectNode && node.isSelected()) node.setSelected(false);
            });
        };

        if (multipleStorageNames?.length) {
            if (event.node.isSelected()) {
                changeSelection(true);
            } else {
                changeSelection(false);
            }
        }
    };

    const gridOptions: GridOptions = useMemo(() => ({
        columnDefs: getColumnDefs(),
        rowData: allStorages,
        rowSelection: 'multiple',
        defaultColDef: defaultColDef,
        enableBrowserTooltips: true,
        suppressRowClickSelection: true,
        localeText: { totalRows: t('TABLE_ITEMS.TOTAL') },
        getRowId: (params: GetRowIdParams) => params.data.id,
        onGridReady,
        onSelectionChanged,
        onRowSelected,
        onFirstDataRendered: (params: FirstDataRenderedEvent<IStorageModel>) => {
            const nodesToSelect: IRowNode<IStorageModel>[] = [];

            params.api?.forEachNode(node => {
                if (selectedStorages?.some(storage => storage.id === node.data?.id)) {
                    nodesToSelect.push(node);
                }
            });
            
            if (nodesToSelect?.length) {
                params.api.setNodesSelected({
                    nodes: nodesToSelect,
                    newValue: true,
                });
            }
        }
    }), [allStorages, defaultColDef, onSelectionChanged, selectedStorages, t]);

    return (
        <AzureOnboardingStep>
            <AzureOnboardingStepTitle>{t('STEPS.NETWORK_SECURITY_GROUP')}</AzureOnboardingStepTitle>

            <Stack padding={[4, 0]}>
                {t('NSG.DESCRIPTION')}
            </Stack>

            <Stack direction={'row'} spacing={6} alignItems='center' justifyContent='flex-end' margin={[0, 0, 2]}>
                <Switch checked={isHideStorage} onChange={onHideStorage} label={t('NSG.HIDE_STORAGE')} />
                <SearchInputWrapper>
                    <Input 
                        placeholder={t('NSG.SEARCH_PLACEHOLDER')} 
                        startAdornment={<Icon name='magnify' />} 
                        onChange={onSearchChange}
                        fullWidth
                    />
                </SearchInputWrapper>
            </Stack>

            {allStorages?.length > 0 && (
                <StorageTable fullHeight fullWidth>
                    <Table 
                        gridOptions={gridOptions} 
                        disableColumnMenu
                        disableGrouping
                        disableRowsResetOnFilterChange 
                        disableRowsResetOnRowDataChange
                    />
                </StorageTable>
            )}
        </AzureOnboardingStep>
    );
};

export default NSGStoragesTable;
