import { t } from 'i18next';
import * as yup from 'yup';
import { isChina } from 'common/utils/dataCenter';
import { AWSCloudAccount } from 'common/module_interface/assets/AwsEnvironment';

export const arnRoleRegex = new RegExp(/^arn:aws:iam::\d{12}:role\/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)+$/);
export const arnRoleChinaRegex = new RegExp(/^arn:aws-cn:iam::\d{12}:role\/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)+$/);
export const stacksetArn = new RegExp(/^arn:aws:cloudformation:[a-z0-9-]+:\d{12}:stackset\/[A-Za-z0-9-]+:\S+$/);
export const managemetAccountExternalIdRegex = new RegExp(/^[\w+=,.@:/-]{12,1224}$/);
export const awsAccountRegex = new RegExp(/^\d{12}$/);

export const organizationalOnboardingAccountValidation = {
    roleArn: yup.string().required(t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.ARN_IS_REQUIRED')).test({ 
        name: 'roleArn', 
        message: t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.ARN_NOT_VALID'), 
        test: (value: any)=> { return isChina() ? arnRoleChinaRegex.test(value) : arnRoleRegex.test(value); }
    }),
    externalId: yup.string().required(t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.AWS_EXTERTNAL_ID_IS_REQUIRED')).test({ 
        name: 'extenalId', 
        message: t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.EXTERNAL_ID_IS_NOT_VALID'), 
        test: (value: any)=> managemetAccountExternalIdRegex.test(value)
    }),
};


export const memberAccountValidation = {
    memberRoleArn: yup.string().required(t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.MEMBER_ACCOUNT_ROLE_IS_NOT_VALID')).test({ 
        name: 'memberAccountArn',
        message: t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.ARN_NOT_VALID'), 
        test: (value: any) => stacksetArn.test(value)
    })
};

export const managementAccountIdValidation = {
    managementAccountId: yup.string().required(t('ON_BOARDING.WELCOME.MANAGEMENT_ACCOUNT_ID_REQUIRED'))
        .matches(awsAccountRegex, t('ON_BOARDING.WELCOME.MANAGEMENT_ACCOUNT_ID_NOT_VALID'))
};

export const isOrgOnboardingValidation = (cloudAccount: AWSCloudAccount) => {
    return cloudAccount?.organizationId !== null && cloudAccount.organizationId !== 'NoOrg' && cloudAccount.organizationId !== 'OrgDetached';
};

export const getDisabledKeys = (params: Record<string, string>): string[] => {
    return Object.keys(params)
        .filter(key => params[key] === 'Disabled')
        .map(key => key.replace(/^param_/, ''))
        .map(key => key.replace(/^AwpMode/, 'AWP'));
};
export const extractUrlParams = (url: string): Record<string, string | string[]> => {
    const params: Record<string, string | string[]> = {};
    const regex = /[?&]([^=&]+)=([^&]*)/g;
    let match: RegExpExecArray | null;

    while ((match = regex.exec(url)) !== null) {
        const key = decodeURIComponent(match[1]);
        const value = decodeURIComponent(match[2]);
        if (key in params) {
            // If it's not already an array, convert it into an array
            if (!Array.isArray(params[key])) {
                params[key] = [params[key] as string];
            }
            (params[key] as string[]).push(value);
        } else {
            params[key] = value;
        }
    }

    return params;
};
export const checkForDisabledBlades = (url: string): string[] => {
    const params = extractUrlParams(url);
    return getDisabledKeys(params as Record<string, string>);
};