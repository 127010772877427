import React from 'react';
import { I18nRiskNamespace } from '../../../consts';
import { useTranslation } from 'react-i18next';
import { getRiskLevelColorByScore } from 'common/module_interface/RiskManagement/RiskLevel';

const RiskScoreIndicator: React.FC<{ riskScore: number }> = ({ riskScore }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const backgroundColor = getRiskLevelColorByScore(riskScore) || '';
    return (
        <div className='flex absolute top-0 right-0 '>
            <div
                className='mr-5 self-center font-semibold'>{t('ASSET_DETAILS.PANELS.OVERVIEW.RISK_SCORE_INDICATOR.TITLE')}</div>
            <div className={'flex flex-col text-center flex-1 px-7 py-5'} style={{ backgroundColor: backgroundColor }}>
                <div className={'text-xl -my-2 font-bold text-strong'} style={{ color: 'white' }}>{riskScore}</div>
            </div>
        </div>);
};

export default RiskScoreIndicator;

