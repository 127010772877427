import styled from 'styled-components';


const TopDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 20%) minmax(200px, 0) minmax(100px, 20%) minmax(100px, 20%) minmax(100px, 20%);
  grid-gap: 20px;
  width: 100%;

  @media (min-width: 1440px) {
    grid-template-columns: minmax(0, 20%) minmax(350px, 0) minmax(0, 20%) minmax(0, 20%) minmax(0, 20%);
  }
`;

export const HighRiskStyled = {
    TopDiv,
};