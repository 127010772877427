import styled from 'styled-components/macro';

export const LoaderWrapper = styled.div`
    height: calc(100vh - 71px);
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%; 
    position: absolute;
    z-index: 100000000;
    background-color: white;
`;