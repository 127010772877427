import React from 'react';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { IAutoAction, IAutoActionProps } from './AutoActions.interface';
import { ermTrans } from '../../RiskManagement.utils';
import { duplicateAutoAction, openAutoActionEditorDrawer } from './AutoActions.utils';
import { globalModelUtils } from 'common/components/GlobalModals/GlobalModals';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getAutoActionsService } from 'common/module_interface/RiskManagement/autoActions/AutoActions';
import {
    DUPLICATE_AUTO_ACTION_FEATURE,
} from '../ActiveFeatures/ActiveFeatures.consts';
import { isAfEnabled } from 'common/utils/debugUtils';
import { List } from 'common/design-system/components-v2';

const getActionsListDisplay: React.FC<{ autoActions: IAutoAction[] }> = ({ autoActions }) => {
    return (
        <List options={autoActions.map(autoAction => ({
            label: autoAction.name,
            value: autoAction.name,
            key: autoAction.id,
            labelProps: { leadingIconProps: { name: 'automatedAction' } },
        }))} />
    );
};

export const getAutoActionsTableActions = (onTableDataChange: () => void): ITableAction<IAutoAction>[] => {
    const actions: ITableAction<IAutoAction>[] = [
        {
            id: 'addAutoAction',
            name: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.ADD'),
            callback: () => {
                openAutoActionEditorDrawer();
            },
            isActionDisabled: () => false,
            buttonProps: {
                iconProps: { name: 'plus' }
            },
        },
        {
            id: 'editAutoAction',
            name: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.EDIT'),
            callback: (autoActions) => {
                if (autoActions.length === 1) {
                    openAutoActionEditorDrawer(autoActions[0]);
                }
            },
            isActionDisabled: (autoActions) => {
                return (autoActions.length !== 1);
            },
            buttonProps: {
                iconProps: { name: 'edit' }
            },
        },
        {
            id: 'cloneAutoAction',
            name: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.CLONE'),
            callback: (autoActions) => {
                if (autoActions.length === 1) {
                    const autoActionProps: IAutoActionProps = autoActions[0];
                    autoActionProps.id = undefined;
                    autoActionProps.name = `Clone - ${autoActionProps.name}`;
                    openAutoActionEditorDrawer(autoActionProps);
                }
            },
            isActionDisabled: (autoActions) => {
                return (autoActions.length !== 1);
            },
            buttonProps: {
                iconProps: { name: 'copy' }
            },
        },
    ];
    if (isAfEnabled(DUPLICATE_AUTO_ACTION_FEATURE.key)) {
        actions.push({
            id: 'duplicateAutoAction',
            name: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.DUPLICATE'),
            callback: (autoActions) => {
                const promises: Promise<string>[] = autoActions.map(autoAction => {
                    return duplicateAutoAction(autoAction);
                });
                Promise.all(promises).then(() => {
                    getNotificationsService().addNotification({
                        type: NotificationType.SUCCESS,
                        text: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.AUTO_ACTIONS_DUPLICATED_SUCCESSFULLY'),
                    });
                    onTableDataChange();
                }).catch(() => {
                    getNotificationsService().addNotification({
                        type: NotificationType.ERROR,
                        text: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.FAILED_DUPLICATING_AUTO_ACTIONS'),
                    });
                    onTableDataChange();
                });
            },
            isActionDisabled: (autoActions) => {
                return autoActions.length === 0;
            },
            buttonProps: {
                iconProps: { name: 'copy' }
            },
        });
    }
    actions.push({
        id: 'deleteAutoAction',
        name: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.DELETE'),
        callback: (autoActions) => {
            globalModelUtils.showConfirmationModal({
                title: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.AUTO_ACTIONS_DELETE_CONFIRMATION_TITLE'),
                text: autoActions.length === 1 ?
                    ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.AUTO_ACTIONS_DELETE_ACTION', { name: autoActions[0].name }) :
                    ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.AUTO_ACTIONS_DELETE_ACTIONS'),
                submitBtnText: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.DELETE'),
                children: autoActions.length > 1 ? getActionsListDisplay({ autoActions }) : undefined,
                onConfirm: () => {
                    const ids = autoActions.map(autoAction => autoAction.id);
                    getAutoActionsService().deleteAutoActions(ids).then((failedIds: string[]) => {
                        if (failedIds.length === 0) {
                            getNotificationsService().addNotification({
                                type: NotificationType.SUCCESS,
                                text: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.AUTO_ACTIONS_DELETED_SUCCESSFULLY'),
                            });
                        } else {
                            console.error('Failed deleting autoActions.');
                            getNotificationsService().addNotification({
                                type: NotificationType.ERROR,
                                text: ermTrans('AUTO_ACTIONS.TABLE.ACTIONS.FAILED_DELETING_AUTO_ACTIONS'),
                            });
                        }
                        if (failedIds.length < ids.length) {
                            onTableDataChange();
                        }
                    });
                },
            });
        },
        isActionDisabled: (autoActions) => {
            return (autoActions.length === 0);
        },
        buttonProps: {
            iconProps: { name: 'delete' }
        },
    });
    return actions;
};