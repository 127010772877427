import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { IDashboard } from 'common/module_interface/overview/Interface';
import { ISectionImagesToPrint } from 'modules/overview/Overview.reducer';
import { imagePlaceholder } from './PlaceholderImage';

export type PageOrientation = 'landscape' | 'portrait'
export type PageSize = 'A4' | 'A3'

interface IDocumentProperties {
  pageSize: PageSize;
  orientation: PageOrientation;
  cols: number;
  rows: number;
}


const ExportToPdf: React.FC<{ selectedDashboard: IDashboard | null, documentProperties: IDocumentProperties, widgetImagesToPrint: ISectionImagesToPrint[], fileName?: string }> = (props) => {

    const rowsPerPage = props.documentProperties.rows;
    const numberOfCols = props.documentProperties.cols;
    const pageOrientation = props.documentProperties.orientation;
    const pageSize = props.documentProperties.pageSize;
    const selectedDashboard = props.selectedDashboard;
    const [convertedDataToRows, setConvertedDataToRows] = useState<ISectionImagesToPrint[][]>([]);

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#f0f0f0',
            padding: 10
        },
        row: {
            flexDirection: 'row',
            height: `${100 / rowsPerPage}%`
        },
        col: {
            margin: '1%',
            flexGrow: 1,
            padding: '2%',
            textAlign: 'center',
            backgroundColor: '#FFFFFF',
            maxWidth: `${100 / numberOfCols - 2}%`,
        },
        widgetName: {
            fontSize: 10,
            marginBottom: '10px',
            textAlign: 'left',
        },
        img: {
            width: '100%',
            height: '100%', 
            objectFit: 'contain',
        }
    });


    useEffect(() => {
        const convertData = (dataToPrint?: ISectionImagesToPrint[]) => {
            return dataToPrint?.reduce((result: ISectionImagesToPrint[][], item)=> {
                if(result[result.length - 1]?.length < numberOfCols) {
                    result[result.length - 1].push(item);
                } else {
                    result.push([item]);
                } 
                return result;
            }, []);
        };
    
        const placeholderData = selectedDashboard?.sections?.reduce((result: ISectionImagesToPrint[] , section) => {
            if(section.isExpanded){
                result.push({
                    title: section.title || '',
                    imgData: imagePlaceholder,
                });
            }
            return result;
        }, []);

        setConvertedDataToRows(convertData(placeholderData) || [] );

        if(props.widgetImagesToPrint?.length > 0){
            setConvertedDataToRows(convertData(props.widgetImagesToPrint) || []);
        }

    },[ props.widgetImagesToPrint, selectedDashboard, numberOfCols]);


    return (
        <Document>
            <Page style={styles.page} size={pageSize} orientation={pageOrientation} >
                {convertedDataToRows?.map((row, rowIndex: number) => (
                    <View style={styles.row} key={rowIndex}>
                        {row?.map((col, colIndex) => (
                            <View style={styles.col} key={colIndex}>
                                <Text style={styles.widgetName}>{col.title}</Text>
                                <Image style={styles.img} src={col.imgData} />
                            </View>
                        ))}
                    </View>
                ))}
            </Page>
        </Document>
    );
};

export default ExportToPdf;