import React, { useEffect, useState } from 'react';
import { Modal } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import { IMainNavigationBarModal, ISearcher } from './MainNavigationBar.interface';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { GLOBAL_SEARCH_SEARCHERS } from 'common/extensibility/WellKnownPaths';
import MainNavigationBarModalStyled from './MainNavigationBarModal.styled';
import MainNavigationBar from './MainNavigationBar';

export const MainNavigationBarModal: React.FC<IMainNavigationBarModal> = ({
    isOpen,
    setIsOpen,
}: IMainNavigationBarModal) => {
    const { t } = useTranslation();
    const searchersInitial = globalAddinContainer.get<ISearcher>(GLOBAL_SEARCH_SEARCHERS);
    const [searchers, setSearchers] = useState([] as ISearcher[]);

    const closeModal = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        setSearchers(searchersInitial);
    }, [isOpen]);

    return (
        <Modal.ModalDialog isOpen={isOpen} onRequestClose={closeModal} width={'xl'}>
            <Modal.ModalHeader title={t('MAIN_NAVIGATION_BAR.MODAL.TITLE')} onClose={closeModal}
                dataAid={'search-navigation'} />
            <MainNavigationBarModalStyled.ModalContent>
                <MainNavigationBar searchers={searchers} onSelect={closeModal} />
            </MainNavigationBarModalStyled.ModalContent>
        </Modal.ModalDialog>
    );
};

export default MainNavigationBarModal;
