import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { submitLogin } from 'platform/auth/Auth.actions';
import { REACT_HOOK_FORM_VALIDATIONS } from './Login.consts';
import {
    getIsLoginFormLoading,
    getLoginFormErrors,
    getMfa,
    handleKeyPress,
    setHasError,
    setMfa,
} from './LoginForm.reducer';
import { useTranslation } from 'react-i18next';
import { CpLoadingDots, CpCommonButton } from '@dome9/components/react/components';
import { getStoreService } from '../../../common/interface/services';
import { Pages } from 'common/enum/Pages';

export const LoginMfa: React.FC = () => {
    const errors = useSelector(getLoginFormErrors);
    const isLoading = useSelector(getIsLoginFormLoading);
    const mfaValue = useSelector(getMfa);
    const dispatch = getStoreService().dispatch;
    const { t } = useTranslation();
    const submitForm = async () => {
        await submitLogin();
    };
    return (
        <form className='form login-form__form flex-column' onKeyPress={(e) => handleKeyPress(e)}>
            {isLoading && <CpLoadingDots append-to-body={true}></CpLoadingDots>}
            <input
                className={`form__input  ${
                    errors.mfa?.type === REACT_HOOK_FORM_VALIDATIONS.REQUIRED ? 'form__input-error' : ''
                }`}
                type='mfa'
                name='mfa'
                aria-invalid={errors.mfa ? 'true' : 'false'}
                autoFocus
                placeholder={t('FORMS.LOGIN_FORM.MFA_AUTHENTICATION')}
                value={mfaValue}
                onChange={(ev) => {
                    dispatch(setMfa(ev.target.value));
                }}
            />
            <Link className='margin-bottom-1-rem' to={`/${Pages.Register}`}>
                {t('FORMS.LOGIN_FORM.CREATE_ACCOUNT')}
            </Link>
            <CpCommonButton class='login-form__form__signin' onClick={submitForm}>
                {t('FORMS.LOGIN_FORM.SIGN_IN')}
            </CpCommonButton>

            {errors.mfa && (
                <div className='login-form__error-submit'>
                    {t('FORMS.LOGIN_FORM.INVALID_MFA')}{' '}
                    <i
                        className='login-form__error-submit--x-button'
                        onClick={() => dispatch(setHasError({ hasError: false }))}>
                        x
                    </i>
                </div>
            )}
        </form>
    );
};
