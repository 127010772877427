import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RenderViewParams } from 'common/components/Wizard/Wizard';
import {
    getAzureConnectPageValidations,
    getAzureOnboardingOperationMode,
    getWelcomeForm,
    setWelcomeForm
} from '../../helpers/azure.reducer';
import { getStoreService } from 'common/interface/services';
import InstructionCard from '../../components/InstructionCard';
import { azureSingleConnectManageInstructions } from '../../helpers/azure.consts';
import { Input, Stack } from 'common/design-system/components-v2';

const AzureSingleConnect: React.FC<RenderViewParams> = ({ onValidate }) => {
    const { t } = useTranslation();
    const { dispatch } = getStoreService().getReduxTools();
    const welcomeForm = useSelector(getWelcomeForm);
    const azureOperationMode = useSelector(getAzureOnboardingOperationMode);
    const formValidations = useSelector(getAzureConnectPageValidations);

    const connectInstructionCard = {
        title: 'ON_BOARDING.AZURE.AZURE_INSTRUCTIONS_TITLE',
        subtitle: 'ON_BOARDING.AZURE.AZURE_INSTRUCTIONS_SUBTITLE',
        componentRender: (): ReactNode => {
            return azureSingleConnectManageInstructions(azureOperationMode);
        }
    };
    connectInstructionCard.componentRender();
    useEffect(()=>{
        onValidate(formValidations.valid);
        // adding onValidate() to deps will cause infinite useEffects
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValidations]);

    return (
        <Stack direction="row" data-aid="azure-onboarding-connect-wrapper">
            <Stack direction="row" padding={[0,5,0,0]} style={{ width: '50%' }}>
                <InstructionCard key={connectInstructionCard.title} config={connectInstructionCard}/>
            </Stack>
            <Stack direction="column" spacing={3} padding={[0,5,0,0]} style={{ width: '50%' }}>
                <Input
                    data-aid='azure-connect-tenant-id-input'
                    label={t('ON_BOARDING.AZURE.TENANT_ID')}
                    value={welcomeForm.tenantId}
                    onChange={(ev) => dispatch(setWelcomeForm({ key: 'tenantId', value: ev.target.value }))}
                    isError={formValidations?.errors?.tenantId}
                    helperText={formValidations?.errors?.tenantId ? formValidations.errors.tenantId[0]?.message : undefined}
                    required
                    fullWidth
                    clearable
                />
                <Input
                    data-aid='azure-connect-subscription-id-input'
                    label={t('ON_BOARDING.AZURE.SUBSCRIPTION_ID')}
                    value={welcomeForm.subscriptionId}
                    onChange={(ev) => dispatch(setWelcomeForm({ key: 'subscriptionId', value: ev.target.value }))}
                    isError={formValidations?.errors?.subscriptionId}
                    helperText={formValidations?.errors?.subscriptionId ? formValidations.errors.subscriptionId[0]?.message : undefined}
                    required
                    fullWidth
                    clearable
                />
            </Stack>
        </Stack >
    );
};

export default AzureSingleConnect;
