import WidgetCard from 'common/components/Dashboard/WidgetCard/WidgetCard';
import React, { JSX } from 'react';
import i18n from 'i18next';
import { I18nRiskNamespace } from '../../../consts';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import RiskScoreIndicator from '../Components/RiskScoreIndicator';
import { Loading } from 'common/erm-components/custom/FullSize/Loading';
import RiskData from '../Components/RiskData';
import { RiskManagementPanelRegistry } from 'common/module_interface/RiskManagement/RiskManagementPanelRegistry';


const RisksWidget: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    let content = <Loading />;
    const titleElements = [];

    if (entity.protectedAsset) {
        const innerItems = RiskManagementPanelRegistry.getRisksItemWidgets();
        content = (<>
            <RiskData asset={entity.protectedAsset}></RiskData>
            <div>
                {innerItems.reduce((prev: JSX.Element[], item) => {
                    if (item.isRelevant === undefined || item.isRelevant(entity)) {
                        const InnerWidget = item.component;
                        const element = (<div className={item.wrapperClass ?? ''} key={item.id}>
                            <InnerWidget entity={entity}></InnerWidget>
                        </div>);
                        prev.push(element);
                    }
                    return prev;
                }, [])}
            </div>
        </>);

        titleElements.push(<RiskScoreIndicator riskScore={entity.protectedAsset?.riskScore ?? 0}></RiskScoreIndicator>);
    }

    return (
        <WidgetCard title={i18n.t('ASSET_DETAILS.RISK_DATA.TITLE', { ns: I18nRiskNamespace })} content={content}
            classNames={{
                container: 'relative',
            }}
            titleInfoElements={titleElements} />
    );
};

export default RisksWidget;