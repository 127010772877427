import React, { useCallback, useEffect, useRef, useState } from 'react';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { getCustomizationService } from 'common/interface/services';
import { IFiltersInitialData, IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { Spinner } from 'common/design-system/components-v2';
import { FilterPanelLoaderStyles as Styles, FindingsFilterPanelDiv } from './ReportsFilterPanel.styled';
import { getSavedFilterDefs } from 'common/utils/filterUtils';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { IFilterDetails } from 'common/module_interface/intelligence/Intelligence.interface';
import { fetchReportsAggregations } from './ReportsFilterPanel.utils';
import { ReportsTableRegistry } from 'common/module_interface/reporting/ReportsTableRegistry';
import { ReportsFilterTypes } from '../Types/ReportsFilterTypes';

const emptyFilterBox = (
    <Styles.Wrapper direction='row' alignItems='center'>
        <Styles.FilterIconButton
            iconProps={{
                name: 'filter',
                size: 16
            }} />
        <Spinner size={16} />
    </Styles.Wrapper>
);

interface ReportsFilterPanelProps {
    filters: IProtectedAssetFilter[];
    updateFilterValues: Function;
    disableFilterPanel?: boolean;
}

export const ReportsFilterPanel: React.FC<ReportsFilterPanelProps> = ({
    filters = [ReportsFilterTypes.ADD_FILTER],
    updateFilterValues,
    disableFilterPanel }) => {

    const [filterElement, setFilterElement] = useState(emptyFilterBox);
    const initializedAggregationsRef = useRef<boolean>(false);

    const getAggregations = useCallback(async (filterValues: IFilterDetails): Promise<Aggregations> => {
        updateFilterValues(filterValues);
        return await fetchReportsAggregations(filterValues) ?? {};
    }, [updateFilterValues]);

    const getInitialAggregations = useCallback(async (): Promise<Aggregations> => {
        return await fetchReportsAggregations() ?? {};
    }, []);

    const getFiltersInitialData = useCallback(async () => {
        const recentlyUsedComponentName: string = filters?.find(filterType => (filterType.id === FILTERS_KEYS.RECENTLY_USED_FILTERS))?.filterProps?.componentName;
        const recentlyUsedPromise = getCustomizationService().getCustomization(recentlyUsedComponentName);
        const savedFiltersComponentName: string = filters?.find(filterType => (filterType.id === FILTERS_KEYS.SAVED_FILTERS))?.filterProps?.savedFiltersComponentName;
        const savedFiltersPromise = getCustomizationService().getCustomization(savedFiltersComponentName);
        return Promise.all([recentlyUsedPromise, savedFiltersPromise]).then(
            ([recentlyUsed, savedFilters]) => {
                return {
                    savedFilters,
                    recentlyUsed,
                };
            },
        );
    }, [filters]);

    const renderFilterPanel = useCallback(async (aggregations: Aggregations) => {
        const filtersInitialData: IFiltersInitialData = await getFiltersInitialData();
        const filterPanelElementsList = getSavedFilterDefs(filters, {
            aggregations,
            filtersInitialData,
        }, ReportsTableRegistry.getFilterById);
        const filterPanel = initFilterPanel({
            filterPanelElementsList,
            getAggregations,
            shouldBuildObjectForAPI: true,
        });
        setFilterElement(filterPanel);
    }, [filters, getAggregations, getFiltersInitialData]);

    useEffect(() => {
        if (!initializedAggregationsRef.current) {
            initializedAggregationsRef.current = true;
            getInitialAggregations().then((aggregations: Aggregations) => {
                void renderFilterPanel(aggregations);
            }).catch(() => {
                void renderFilterPanel({});
            });
        }
    }, [getInitialAggregations, renderFilterPanel]);

    return <>
        <FindingsFilterPanelDiv isVisible={!disableFilterPanel}>{filterElement}</FindingsFilterPanelDiv>
    </>;
};
