import { LicenseManager } from 'ag-grid-enterprise';
import './platform/auth/StoreCloudInfraToken';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { applyPolyfills, defineCustomElements } from '@dome9/components/loader';
import 'common/services/translations/translations';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import globalAddinContainer from './common/extensibility/AddinContainer';
import { initialize, initializeI18n } from './initialize';
import { getStoreService } from 'common/interface/services';
import { Router } from 'react-router-dom';
import history from 'common/utils/history';
import ToastStyle from 'platform/main/Notifications/Toast.styled';
import { toastOption } from 'platform/main/Notifications/Toasts';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/design-system/theme/theme';
import { getGlobalPersistor } from 'common/services/store/store';

LicenseManager.setLicenseKey(
    'CompanyName=Check Point Software Technologies Ltd.,LicensedApplication=CloudGuard,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=1,AssetReference=AG-030639,SupportServicesEnd=9_September_2023_[v2]_MTY5NDIxNDAwMDAwMA==c754169b518b09572970722206d12267'
);
await initializeI18n();
initialize();
globalAddinContainer.start();
const storeService = getStoreService();
const store = storeService.store;
const persistor = getGlobalPersistor();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <React.StrictMode>
                <Router history={history}>
                    <ThemeProvider theme={theme('light')}>
                        <App />
                        <ToastStyle.ToastProvider {...toastOption as any} />
                    </ThemeProvider>
                </Router>
            </React.StrictMode>
        </PersistGate>
    </Provider>
);

applyPolyfills().then(() => {
    defineCustomElements();
});
