import { renderMultiSelectFilter } from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { IEnvironment } from 'common/interface/environmentsTable';
import { FeatureStatusRenderer_Type, IEnvironmentsFilterParams } from 'common/module_interface/assets/Environments';
import { ETColDef, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { Vendors } from 'common/consts/vendors';
import i18n from 'common/services/translations/translations';
import CloudAccountsService from '../Services/cloudAccounts/cloudAccounts.service';
import FeatureStatusRenderer from 'common/components/EnvironmentsTable/CellRenderers/FeatureStatusRenderer';
import { i18nIntelligenceNamespace } from '../initialize.i18n';
import { getUserService } from 'common/interface/services';
import { isNotChina } from 'common/utils/dataCenter';
import { CloudAccountIamSafeStatsGet } from '../Services/cloudAccounts/cloudAccounts.interface';

const iamSafetySupportedPlatforms = [Vendors.AWS];

export const initializeEnvironmentsColumnDefs = () => {
    const columnDefs: ETColDef<IEnvironment>[] = [
        {
            id: 'isCloudbotsOnboarded',
            position: 11,
            colId: 'isCloudbotsOnboarded',
            field: 'isCloudbotsOnboarded',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.CLOUDBOTS.HEADER', { ns: i18nIntelligenceNamespace }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.CLOUDBOTS.TOOLTIP', { ns: i18nIntelligenceNamespace }),
            sortable: true,
            width: 125,
            cellRenderer: FeatureStatusRenderer,
            cellRendererParams: {
                type: FeatureStatusRenderer_Type.Cloudbots,
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.CLOUDBOTS.HEADER', { ns: i18nIntelligenceNamespace }),
                supportedVendors: [Vendors.AWS, Vendors.AZURE]
            }
        },
        {
            id: 'isLogicGuardDutyOnboarded',
            colId: 'isLogicGuardDutyOnboarded',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.GUARDDUTY.HEADER', { ns: i18nIntelligenceNamespace }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.GUARDDUTY.TOOLTIP', { ns: i18nIntelligenceNamespace }),
            sortable: true,
            cellRenderer: FeatureStatusRenderer,
            initialHide: true,
            cellRendererParams: {
                type: FeatureStatusRenderer_Type.GuardDuty,
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.GUARDDUTY.HEADER', { ns: i18nIntelligenceNamespace }),
                supportedVendors: [Vendors.AWS],
            },
            permission: () => getUserService().hasPermission(['guardduty'])
        },
        {
            id: 'isLogicEventActivityOnboarded',
            position: 7,
            colId: 'isLogicEventActivityOnboarded',
            field: 'isLogicEventActivityOnboarded',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.ACCOUNT_ACTIVITY.HEADER', { ns: i18nIntelligenceNamespace }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.ACCOUNT_ACTIVITY.TOOLTIP', { ns: i18nIntelligenceNamespace }),
            sortable: true,
            width: 125,
            cellRenderer: FeatureStatusRenderer,
            cellRendererParams: {
                type: FeatureStatusRenderer_Type.AccountActivity,
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.ACCOUNT_ACTIVITY.HEADER', { ns: i18nIntelligenceNamespace }),
                supportedVendors: [Vendors.AZURE, Vendors.AWS, Vendors.KUBERNETES, Vendors.ALIBABA, Vendors.GOOGLE, Vendors.GCP]
            }
        },
        {
            id: 'isLogicTrafficActivityOnboarded',
            position: 10,
            colId: 'isLogicTrafficActivityOnboarded',
            field: 'isLogicTrafficActivityOnboarded',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.TRAFFIC_ACTIVITY.HEADER', { ns: i18nIntelligenceNamespace }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.TRAFFIC_ACTIVITY.TOOLTIP', { ns: i18nIntelligenceNamespace }),
            sortable: true,
            width: 125,
            cellRenderer: FeatureStatusRenderer,
            cellRendererParams: {
                type: FeatureStatusRenderer_Type.TrafficActivity,
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.TRAFFIC_ACTIVITY.HEADER', { ns: i18nIntelligenceNamespace }),
                supportedVendors: [Vendors.AZURE, Vendors.AWS, Vendors.KUBERNETES, Vendors.GOOGLE, Vendors.GCP]
            }
        },
        {
            id: 'iamSafety',
            colId: 'iamSafety',
            field: 'customData|iamSafety',
            headerName: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.IAM_SAFETY.HEADER', { ns: i18nIntelligenceNamespace }),
            headerTooltip: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.IAM_SAFETY.TOOLTIP', { ns: i18nIntelligenceNamespace }),
            sortable: true,
            initialHide: true,
            width: 125,
            cellRenderer: FeatureStatusRenderer,
            cellRendererParams: {
                type: FeatureStatusRenderer_Type.IamSafety,
                loadingField: 'customData|iamSafetyLoading',
                name: i18n.t('ENVIRONMENTS_TABLE.TABLE_HEADERS.IAM_SAFETY.HEADER', { ns: i18nIntelligenceNamespace }),
                supportedVendors: iamSafetySupportedPlatforms,
            },
            permission: () => isNotChina() && !getUserService().hasPermission(['hide-iam-safety-feature'])
        }
    ];

    EnvironmentsTableRegistry.addColumnDefs(columnDefs);
};

export const initializeEnvironmentsFilters = () => {
    const filterDefs = [
        {
            id: 'customData|iamSafety',
            content: function({ aggregations }: IEnvironmentsFilterParams) {
                const displayMapping = {
                    true: { displayText: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.IAM_SAFETY.VALUES.ENABLED', { ns: i18nIntelligenceNamespace }) },
                    false: { displayText: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.IAM_SAFETY.VALUES.DISABLED', { ns: i18nIntelligenceNamespace }) },
                };

                const enabledCount: number = aggregations['customData|iamSafety']?.find(row => row.value === true)?.count || 0;
                const disabledCount: number = aggregations['customData|iamSafety']?.find(row => row.value === true)?.count || 0;
                
                const initialData = [
                    { count: enabledCount, value: true },
                    { count: disabledCount, value: false },
                ];

                return {
                    filterProps: {
                        initialData,
                        key: 'customData|iamSafety',
                        title: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.IAM_SAFETY.TITLE', { ns: i18nIntelligenceNamespace }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'customData|intelligenceEnabled',
            content: function({ aggregations }: IEnvironmentsFilterParams) {
                const displayMapping = {
                    true: { displayText: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.INTELLIGENCE.VALUES.ENABLED', { ns: i18nIntelligenceNamespace }) },
                    false: { displayText: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.INTELLIGENCE.VALUES.DISABLED', { ns: i18nIntelligenceNamespace }) },
                };

                return {
                    filterProps: {
                        initialData: aggregations['customData|intelligenceEnabled'],
                        key: 'customData|intelligenceEnabled',
                        title: i18n.t('ENVIRONMENTS_TABLE.TABLE_FILTERS.INTELLIGENCE.TITLE', { ns: i18nIntelligenceNamespace }),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        }
    ];
    EnvironmentsTableRegistry.addFilterAddins(filterDefs);
};

export const initializeEnvironmentsTableData = () => {
    EnvironmentsTableRegistry.addCustomDataByPlatform([{
        id: 'add-env-table-iam-safety-loader',
        content: {
            customData: async () => ({
                platforms: iamSafetySupportedPlatforms,
                customData: {
                    iamSafetyLoading: true
                }
            })
        }
    }]);
    EnvironmentsTableRegistry.addCustomDataById([{
        id: 'add-env-table-iam-safety-data',
        content: {
            customData: async (allCloudAccounts) => {
                const iamSafetyData = await CloudAccountsService.cloudAccountIamSafeStatsGet().catch(() => {
                    const emptyResponse: CloudAccountIamSafeStatsGet.Response = [];
                    return { data: emptyResponse };
                });
                const indexedIamSafetyData = (iamSafetyData.data || []).reduce<{ [id: string]: boolean; }>((acc, curr) => {
                    acc[curr.id] = true;
                    return acc;
                }, {});
                return allCloudAccounts
                    .filter(cloudAccount => iamSafetySupportedPlatforms.includes(cloudAccount.platform as Vendors))
                    .map(cloudAccount => ({
                        id: cloudAccount.id,
                        customData: {
                            iamSafetyLoading: false,
                            iamSafety: indexedIamSafetyData[cloudAccount.id] || false,
                        }
                    }));
            }
        }
    }]);
    EnvironmentsTableRegistry.addCustomDataById([{
        id: 'add-env-table-intelligence-enabled',
        content: {
            customData: async (allCloudAccounts) => {
                return allCloudAccounts.map(cloudAccount => ({
                    id: cloudAccount.id,
                    customData: {
                        intelligenceEnabled: cloudAccount.isLogicEventActivityOnboarded || cloudAccount.isLogicTrafficActivityOnboarded
                    }
                }));
            }
        }
    }]);
};

export const InitializeEnvironmetsDefs = () => {
    initializeEnvironmentsColumnDefs();
    initializeEnvironmentsFilters();
    initializeEnvironmentsTableData();
};