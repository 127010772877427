import React from 'react';
import { CloudAccountAwsSecretGet } from '../cloudAccounts.interface';
import CloudAcountService from '../cloudAccounts.service';
import { singletonHook } from 'react-singleton-hook';

export interface UseAwsSecretGetPayload {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    data?: CloudAccountAwsSecretGet.Response;

    reloadData: (awsAccountNumber: string) => void;
}

export type UseAwsSecretGet = (awsAccountNumber?: string) => UseAwsSecretGetPayload;

const useAwsSecretGetImpl: UseAwsSecretGet = (awsAccountNumber) => {
    const [{ loading, error, data }, setState] = React.useState<{ loading: boolean; error: boolean; data?: CloudAccountAwsSecretGet.Response }>({
        loading: false,
        error: false
    });

    const getData = async (awsAccountNumber: string) => {
        setState(current => ({ loading: true, error: false, data: current.data }));
        try {
            const secretGetResponse = await CloudAcountService.cloudAccountAwsSecretGet(awsAccountNumber);
            setState(({ loading: true, error: false, data: secretGetResponse.data }));
        } catch (error) {
            setState(({ loading: true, error: true }));
        }
    };
 
    React.useEffect(() => {
        if (!loading && !data && !error && awsAccountNumber) {
            getData(awsAccountNumber);
        }
    }, [awsAccountNumber, data, loading, error]);

    const reloadData = React.useCallback((awsAccountNumber: string) => getData(awsAccountNumber), []);

    return ({
        isLoading: loading && !data,
        isFetching: loading,
        isError: !!error,
        data,

        reloadData
    });
};

export const useAwsSecretGet: UseAwsSecretGet = singletonHook({
    isLoading: false,
    isFetching: false,
    isError: false,
    data: undefined,
    reloadData: () => null
}, useAwsSecretGetImpl);