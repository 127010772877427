import {
    getCustomizationService,
    getHttpService,
    getLocalStorageService,
    getUserService,
} from 'common/interface/services';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { ICloudBotsService } from 'common/module_interface/intelligence/CloudBots/CloudBots.service';
import {
    ICloudBotEndPoint,
    ICloudBotRemediationDetails,
    IRemediationExecutionInfo,
    IRemediationRequest,
    IRemediationResponse,
} from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import {
    CLOUD_BOTS_ENDPOINT_LOCAL_STORAGE_KEY,
    CLOUD_BOTS_PAGE_ID,
    CLOUD_BOTS_SERVICE_ID,
    RemediationResponseFields,
} from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import {
    IGslRunOptions,
    IGslRunRequest,
    IGslRunResponse,
} from 'common/module_interface/intelligence/Intelligence.interface';
import { REMEDIATION, RemediationType } from '../Findings/Findings.const';
import { IUser } from 'common/interface/user';
import { Vendors } from 'common/consts/vendors';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { getCloudAccountsService, ICloudAccount } from 'common/interface/data_services';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import { mapGslResponseToType } from 'common/module_interface/intelligence/Intelligence.utils';


import { GSL_CLAUSES } from 'common/module_interface/intelligence/Gsl/GslService.const';

class CloudBotsService implements ICloudBotsService {

    public saveEndpoint(cloudAccountId: string, endpoint: string) {
        const storedEndpointsString: string = getLocalStorageService().getFromLocalStorage(CLOUD_BOTS_ENDPOINT_LOCAL_STORAGE_KEY, CLOUD_BOTS_PAGE_ID);
        const user: IUser = getUserService().getUser();
        if (!user || !user.accountId) return;

        const accountId: number = user.accountId;
        const cloudBotEndpoints: ICloudBotEndPoint[] = storedEndpointsString ? JSON.parse(storedEndpointsString) : [];
        const existingEndpoint: ICloudBotEndPoint | undefined = cloudBotEndpoints.find((item: ICloudBotEndPoint) => {
            return item.cloudAccountId === cloudAccountId && item.accountId === accountId;
        });
        if (existingEndpoint) {
            existingEndpoint.endpoint = endpoint;
        } else {
            cloudBotEndpoints.push({
                cloudAccountId: cloudAccountId,
                accountId: accountId,
                endpoint: endpoint
            });
        }
        getLocalStorageService().setToLocalStorage(CLOUD_BOTS_ENDPOINT_LOCAL_STORAGE_KEY, CLOUD_BOTS_PAGE_ID, JSON.stringify(cloudBotEndpoints));
    }

    private async getEndpointFromServer(cloudAccountId: string): Promise<string | undefined> {
        try {
            return await getHttpService().get<string>({
                path: `rmdws/azure/onboarding/azure-function-url?cloudAccount=${cloudAccountId}`,
            });
        }
        catch (error: any) {
            return EMPTY_STRING;
        }
    }

    private getEndpointFromLocalStorage(cloudAccountId: string): string | undefined {
        const storedEndpoints: string = getLocalStorageService().getFromLocalStorage(CLOUD_BOTS_ENDPOINT_LOCAL_STORAGE_KEY, CLOUD_BOTS_PAGE_ID);
        const user: IUser = getUserService().getUser();
        if (!user || !user.accountId) return;

        const accountId: number = user.accountId;
        const updatedEndpoints: ICloudBotEndPoint[] = storedEndpoints ? JSON.parse(storedEndpoints) : [];
        const existingEndpoint: ICloudBotEndPoint | undefined = updatedEndpoints.find((item: ICloudBotEndPoint) => {
            return item.cloudAccountId === cloudAccountId && item.accountId === accountId;
        });
        return existingEndpoint?.endpoint;
    }

    public async getEndpoint(cloudAccountId: string, vendor: Vendors): Promise<string | undefined> {
        let cloudAccount: ICloudAccount | null;

        switch (vendor) {
            case Vendors.AZURE:
                cloudAccount= await getCloudAccountsService().getCloudAccountByAccountId(cloudAccountId);
                if (!cloudAccount || !cloudAccount?.externalId) return '';
                return await this.getEndpointFromServer(cloudAccount.externalId);
            default:
                return this.getEndpointFromLocalStorage(cloudAccountId);
        }
    }

    public async executeRemediation(data: IRemediationRequest): Promise<IRemediationResponse> {
        return await getHttpService().post<IRemediationResponse>({
            path: 'rmdws/execute',
            requestObject: {
                data: data
            }
        });
    }

    public async getRemediationDetails(component: string): Promise<ICloudBotRemediationDetails | undefined> {
        return getCustomizationService().getKeyData<ICloudBotRemediationDetails>(component);
    }

    public async setRemediationDetails(component: string, data: ICloudBotRemediationDetails) {
        return getCustomizationService().setKeyData<ICloudBotRemediationDetails>(component, data);
    }

    clearRemediationDetails(component: string): Promise<any> {
        return getCustomizationService().deleteKeyData(component);
    }

    public async pollRemediationExecutionStatus(executionIds: string[] = [], remediationType = RemediationType.Feedback): Promise<IRemediationExecutionInfo[]> {
        if (executionIds.length === 0) return [];

        const gsl = `${REMEDIATION} ${GSL_CLAUSES.WHERE} type='${remediationType}' and executionId in ('${executionIds.join('\',\'')}')`;
        const options: IGslRunOptions = {
            source: REMEDIATION,
            start: 0,
            end: 0,
            limit: 10000
        };
        const data: IGslRunRequest = {
            gsl,
            options
        };
        const response: IGslRunResponse = await getIntelligenceService().getGSLRunData(data);
        return mapGslResponseToType<IRemediationExecutionInfo>(RemediationResponseFields, response);
    }

}

export default function initializeCloudBotsService() {
    globalAddinContainer.addService(CLOUD_BOTS_SERVICE_ID, new CloudBotsService());
}
