import React from 'react';
import { ToolBarProps } from './Toolbar.types';
import { useTranslation } from 'react-i18next';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getAwsEnvNamespace } from '../../initialize.i18n';
import { useSyncNow } from '../../services/cloudAccounts/hooks/useSyncNow';
import { Dropdown, List } from 'common/design-system/components-v2';
import { AwsEnvironmentAddinRegistry } from 'common/helpers/awsEnvironment';
import { ToolbarItems, evaluateToolbarActions, addinActionItem, awsActionItem } from './Toolbar.utils';
import { ActionType } from 'common/module_interface/assets/AwsEnvironment';

const Toolbar: React.FunctionComponent<ToolBarProps> = ({
    actionClick,
    awsAccount,
    extraData,
}) => {
    const { t } = useTranslation(getAwsEnvNamespace('aws'));

    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
    const [addinActions, setAddinActions] = React.useState<ToolbarItems[]>([]);

    const getSetActionsFromAddins = React.useCallback(() => {
        const addinActions = AwsEnvironmentAddinRegistry.getActions();
        evaluateToolbarActions(addinActions, awsAccount, extraData).then(result => {
            setAddinActions(result);
        });
    }, [awsAccount, extraData]);

    React.useEffect(() => {
        if (!awsAccount || !extraData) return;

        getSetActionsFromAddins();
    }, [awsAccount, extraData, getSetActionsFromAddins]);

    const handleOnSyncNowSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('awsPage.modals.syncNow.onSubmit.success'),
            text: '',
        });
    };

    const handleOnSyncNowError = ({ message }: { message: string; }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('awsPage.modals.syncNow.onSubmit.error'),
            text: message,
        });
    };

    const { isLoading: syncNowLoading, reset: resetSyncnNow, syncNow } = useSyncNow({ onSuccess: handleOnSyncNowSuccess, onError: handleOnSyncNowError });

    const handleOnActionClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: ActionType) => {
        setIsDropdownOpen(false);
        switch (type) {
            case ActionType.EditCredentials: {
                const { altKey, shiftKey } = event;
                if (altKey && shiftKey) {
                    handleOnActionClick(event, ActionType.SyncNow);
                    break;
                }
                actionClick(type);
                break;
            }
            case ActionType.SyncNow:
                resetSyncnNow();
                syncNow({ id: awsAccount.account.id });
                break;
            default:
                actionClick(type);
        }
    }, [actionClick, awsAccount, resetSyncnNow, syncNow]);

    const getIsLoadingState = React.useCallback((type: ActionType) => {
        switch (type) {
            case ActionType.SyncNow:
                return syncNowLoading;
            default:
                return false;
        }
    }, [syncNowLoading]);

    const dropdownListOptions = React.useMemo(() => {
        return addinActions.map((current) => {
            if (current.type === 'aws') {
                return awsActionItem(current, getIsLoadingState, handleOnActionClick);
            }
            return addinActionItem(current, awsAccount, extraData, getSetActionsFromAddins, () => setIsDropdownOpen(false));
        }, []);
    }, [addinActions, awsAccount, extraData, getIsLoadingState, getSetActionsFromAddins, handleOnActionClick]);

    return (
        <Dropdown
            open={isDropdownOpen}
            onStateChange={(state) => setIsDropdownOpen(state)}
            buttonProps={{
                iconButton: true,
                iconProps: { name: 'more' },
            }}
            maxHeight={500}
        >
            <List
                options={dropdownListOptions}
            />
        </Dropdown>
    );
};

export default Toolbar;
