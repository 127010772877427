import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    CloudAccountGet,
    CloudAccountNameUpdate,
    CloudAccountsCredentialsUpdate,
    CloudAccountDelete,
    CloudAccountDeleteForce,
} from './ociCloudAccounts.interface';


const cloudAccountGet: CloudAccountGet.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountGet.Response>>(
        `oci-cloud-account/${id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountNameUpdate: CloudAccountNameUpdate.Function = async (request: CloudAccountNameUpdate.Request) => {
    return await getHttpService().request<AxiosResponse<CloudAccountNameUpdate.Response>>(
        `oci-cloud-account/${request.cloudAccountId}/account-name`,
        { method: 'PUT', data: { name: request.name } },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountsCredentialsUpdate: CloudAccountsCredentialsUpdate.Function = async (request: CloudAccountsCredentialsUpdate.Request) => {
    return await getHttpService().request<AxiosResponse<CloudAccountsCredentialsUpdate.Response>>(
        `oci-cloud-account/${request.id}/credentials`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountDelete: CloudAccountDelete.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountDelete.Response>>(
        `oci-cloud-account?id=${id}`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountDeleteForce: CloudAccountDeleteForce.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountDeleteForce.Response>>(
        `oci-cloud-account/${id}/delete-force`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountsSyncNow: any = async (id: string) => {
    return await getHttpService().request<AxiosResponse<any>>(
        `oci-cloud-account/${id}/sync-now`,
        { method: 'POST', data: { id } },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};


const OciCloudAccountsService = {
    cloudAccountGet,
    cloudAccountNameUpdate,
    cloudAccountsCredentialsUpdate,
    cloudAccountDelete,
    cloudAccountDeleteForce,
    cloudAccountsSyncNow
};

export default OciCloudAccountsService;
