import {
    ExceptionPayloadJson,
    ExceptionPayloadsResponse
} from './exclusions.interfaces';
import { ADDITIONAL_FIELDS_NAMES, RULES_AND_EXCLUSIONS, PAYLOAD_PATTERN_TYPES } from './exclusions.serverless.consts';
import { IEvent } from 'common/module_interface/events/Events';
import { IRuntimeRequestPayload } from 'common/interface/exclusion';
import { ExclusionsService } from 'common/interface/services';
import { IFindingModelCloudAccountType } from 'common/components/Findings/Findings.interface';

interface ExceptionPayloads {
    IOFeature: string;
    Signature: string;
    String: string;
    MalwareEvent: string;
}

interface PayloadBody {
    payloadsPatternType: string;
    target?: string;
    payloads: any[]; // Replace `any` with a more specific type if available
    name: string;
    functions?: string[];
    workloadExternalIds?: string[];
}

export const saveExclusionRuntimeServerless = async (exclusionConfig: IRuntimeRequestPayload) => {
    return await ExclusionsService().saveExclusionRuntimeServerless(exclusionConfig);
};


function decodeBase64(encodedString: string): ExceptionPayloadJson | undefined {
    try {
        const decodedString = atob(encodedString);
        return JSON.parse(decodedString) as ExceptionPayloadJson;
    } catch (error) {
        console.error('Failed to decode or parse base64 string:', error);
        return undefined;
    }
}

export const parseExceptionPayloads = (finding: IEvent): ExceptionPayloadsResponse => {
    const fields = finding.additionalFields || [];
    const names = ADDITIONAL_FIELDS_NAMES;
    const payloads: ExceptionPayloads = {
        IOFeature: names.EXCEPTION_PAYLOADS_IO_FEATURE,
        Signature: names.EXCEPTION_PAYLOADS_SIGNATURE,
        String: names.EXCEPTION_PAYLOADS_STRING,
        MalwareEvent: names.EXCEPTION_PAYLOADS_MALWARE_FILE_PATH
    };

    const exceptionDetails: ExceptionPayloadsResponse = {
        exceptionPayloads: undefined,
        isIORule: false,
        isSignatureRule: false,
        isStringRule: false,
        isMalwareEventRule: false,
    };

    Object.entries(payloads).forEach(([key, name]) => {
        if (exceptionDetails.exceptionPayloads) return; // Skip if already found
        const foundField = fields.find((field: any) => field.name === name);
        if (foundField && foundField.value) {
            exceptionDetails.exceptionPayloads = decodeBase64(foundField.value);
            switch (key) {
                case 'IOFeature':
                    exceptionDetails.isIORule = true;
                    break;
                case 'Signature':
                    exceptionDetails.isSignatureRule = true;
                    break;
                case 'String':
                    exceptionDetails.isStringRule = true;
                    break;
                case 'MalwareEvent':
                    exceptionDetails.isMalwareEventRule = true;
                    break;
            }
        }
    });

    return exceptionDetails;
};

export const initPayload = (ruleScope: string, finding: IEvent, exceptionPayloadJson: any) => {
    const { entityExternalId, additionalFields, cloudAccountType } = finding;
    const body: PayloadBody = {
        payloadsPatternType: PAYLOAD_PATTERN_TYPES[exceptionPayloadJson?.PayloadsType.toUpperCase()],
        target: Object.keys(RULES_AND_EXCLUSIONS.TARGETS).find(k => k.toUpperCase() === exceptionPayloadJson?.Target.toUpperCase()),
        payloads: exceptionPayloadJson?.Payloads.map((pattern: string) => ({ pattern: pattern, callStackInfo: '' })) || [],
        name: exceptionPayloadJson?.Name,
    };

    switch (ruleScope) {
        case 'thisFunctionOnly': {
            const azureFunctionName = cloudAccountType === IFindingModelCloudAccountType.Azure && additionalFields.find((field: any) => field.name === ADDITIONAL_FIELDS_NAMES.AZURE_FUNCTION_NAME);
            body.workloadExternalIds = azureFunctionName ? [`${entityExternalId}/functions/${azureFunctionName.value}`] : [entityExternalId];
            break;
        }
        case 'allFunctionsInAccount':
            body.workloadExternalIds = [];
            break;
        case 'thisFunctionAppOnly':
            body.functions = [entityExternalId];
            break;
    }

    return body;
};