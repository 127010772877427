import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input, Stack } from 'common/design-system/components-v2';
import { RenderViewParams } from 'common/components/Wizard/Wizard';
import {
    getAzurePreparePageValidations,
    getClientId, getClientPassword, getCloudShellUrl,
    setClientId, setClientPassword
} from '../../helpers/azure.reducer';
import { getCurrentDataCenter } from 'common/utils/RuntimeEnvironment';
import { getStoreService } from 'common/interface/services';
import InstructionCard from '../../components/InstructionCard';
import { azureSinglePrepareInstructions } from '../../helpers/azure.consts';
import { IDataCenter } from 'common/consts/data.centers';
const AzureSinglePrepare: React.FC<RenderViewParams> = ({ onValidate }) => {
    const { t } = useTranslation();
    const dispatch = getStoreService().dispatch;
    const clientId = useSelector(getClientId);
    const cloudShellUrl = useSelector(getCloudShellUrl);
    const clientPassword = useSelector(getClientPassword);
    const formValidations = useSelector(getAzurePreparePageValidations);
    const dataCenter: IDataCenter | undefined = getCurrentDataCenter() as IDataCenter;
    const prepareInstructionCard = {
        title: 'ON_BOARDING.AZURE.AZURE_INSTRUCTIONS_TITLE',
        subtitle: 'ON_BOARDING.AZURE.AZURE_INSTRUCTIONS_SUBTITLE',
        componentRender: (): ReactNode => {
            return azureSinglePrepareInstructions(cloudShellUrl, dataCenter);
        }
    };
    prepareInstructionCard.componentRender();

    useEffect(()=> {
        const container = document.querySelector('.container');
        if (container) container.scrollTop = 0;
    }, []);

    useEffect(()=> {
        onValidate(formValidations.valid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValidations]);
    
    return (
        <Stack direction="row" data-aid="azure-onboarding-prepare-wrapper">
            <Stack direction="row" padding={[0,5,0,0]} style={{ width: '50%' }}>
                <InstructionCard key={prepareInstructionCard.title} config={prepareInstructionCard}/>
            </Stack>
            <Stack direction="column" spacing={3} padding={[0,5,0,0]} style={{ width: '50%' }}>
                <Input
                    data-aid='azure-prepare-application-id-input'
                    label={t('ON_BOARDING.AZURE.CLIENT_ID')}
                    value={clientId}
                    onChange={(ev) => dispatch(setClientId(ev.target.value))}
                    isError={formValidations?.errors?.clientId}
                    helperText={formValidations?.errors?.clientId ? formValidations.errors.clientId[0]?.message : undefined}
                    required
                    fullWidth
                    clearable
                />
                <Input
                    data-aid='azure-prepare-secret-key-input'
                    label={t('ON_BOARDING.AZURE.CLIENT_PASSWORD')}
                    value={clientPassword}
                    onChange={(ev) => dispatch(setClientPassword(ev.target.value))}
                    isError={formValidations?.errors?.clientPassword}
                    helperText={formValidations?.errors?.clientPassword ? formValidations.errors.clientPassword[0]?.message : undefined}
                    required
                    fullWidth
                    clearable
                />
            </Stack>
        </Stack>
    );
};

export default AzureSinglePrepare;
