import { ALIASES } from './auth.const';

export const getFirstTimeToken = () => {
    const documentLocationSearch = document.location.search;
    if (documentLocationSearch.includes(ALIASES.CLOUD_INFRA_FIRST_TIME_TOKEN)) {
        const firstTimeTokenRegEx = new RegExp(/firstTimeToken=([^&]*)/);
        const firstTimeTokenMatch = documentLocationSearch.match(firstTimeTokenRegEx);
        if (firstTimeTokenMatch && firstTimeTokenMatch.length > 1) {
            return firstTimeTokenMatch[1];
        }
        return '';
    } else {
        return undefined;
    }
};

(() => {
    const token = getFirstTimeToken();
    if (token) {
        localStorage.setItem(ALIASES.CLOUD_INFRA_FIRST_TIME_TOKEN, token);
    }
})();
