import { IVendor, VENDORS } from 'common/consts/vendors';
import { ICloudAccount } from 'common/interface/data_services';
import { getAssets } from 'common/assets/Assets.service';

export const getLatestFindings = (finding: any , aggregation: string, cloudAccounts: ICloudAccount[]) => {
    let displayName;
    let iconClass;
    let environmentId: string;
    let filteredAccount;
    let vendorData;
    const defaultIcon = 'cloud';
    switch (aggregation) {
        case 'entity':
            displayName = finding.entityDisplayName || finding.entityName;
            break;
        case 'cloudAccountId_calc':
            environmentId = finding.cloudAccountId;
            filteredAccount = cloudAccounts.filter((account: any) => account.id === environmentId);
            iconClass = filteredAccount[0].platform || defaultIcon;
            displayName = finding.entityName;
            break;
        case 'organizationalUnit':
            displayName = finding.ouDisplayName;
            break;
        case 'cloudAccountId':
            displayName = finding.acknowledged ? 'Yes' : 'No';
            environmentId = finding.cloudAccountId;
            filteredAccount = cloudAccounts.filter((account: any) => account.id === environmentId);
            iconClass = filteredAccount[0].platform || defaultIcon;
            break;
        case 'isExcluded':
            displayName = finding.isExcluded ? 'Yes' : 'No';
            break;
        case 'cloudAccountType':
            vendorData = VENDORS.find(item=> item.assessmentVendorType == finding[aggregation].toLowerCase() || item.name == finding[aggregation].toLowerCase()) as IVendor;
            iconClass = vendorData.icon;
            displayName = finding[aggregation];
            break;
        case 'entityTypeByEnvironmentType' :
            displayName = finding.entityTypeByEnvironmentType.replace(/([A-Z])/g, ' $1').replace(/^.*?\|/, (string: string)=> string.toUpperCase()).replace(/(\|)/, ' ');
            break;
        default:
            displayName = finding[aggregation] || 'N/A';
    }
    return {
        displayName: displayName,
        iconClass: iconClass || getAssets().find((asset: any) => asset.type === finding.entityType)?.icon || defaultIcon
    };
};

export const getAggregationFromWidgetSelection = (type: string) => {
    switch (type) {
        case 'cloudAccountId_calc':
            return 'cloudAccountId';
        case 'entityTypeByEnvironmentType':
            return 'aggregationEntityTypeByEnvironmentType';
        default:
            return type;
    }
};
