import React, { useCallback, useMemo } from 'react';
import FindingsTableWrapper from '../FindingsTable/FindingsTableWrapper';
import { OriginType } from 'common/module_interface/intelligence/Intelligence.const';
import { IActionUsageDef, IColumnUsageDef } from 'common/interface/general';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { getCdrEventsTableColumns } from './CdrEventsPage.columns';
import { getCdrEventsTableFilters } from './CdrEventsPage.filters';
import { getCdrEventsTableActions } from './CdrEventsPage.actions';
import { CDR_EVENTS_TABLE } from './CdrEventsPage.const';
import { ModalType } from '../Findings.const';
import { EventsTableTabComponentProps } from 'common/interface/events';
import { IStackProps } from 'common/design-system/components-v2/Stack/Stack.types';
import { Stack } from 'common/design-system/components-v2';

const originTypes: string[] = [OriginType.Intelligence];

const CdrEventsPage: React.FC<EventsTableTabComponentProps> = ({ archiveView }) => {
    const columns: IColumnUsageDef[] = useMemo(() => getCdrEventsTableColumns(), []);
    const filters: IProtectedAssetFilter[] = useMemo(() => getCdrEventsTableFilters(), []);
    const actionsCreator = useCallback((openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef[] => {
        return getCdrEventsTableActions(openDialog, archiveView);
    }, [archiveView]);

    const stackProps = React.useMemo<Omit<IStackProps, 'children'>>(() => { // TODO: remove when old events page is removed
        if (archiveView === undefined) {
            return {
                fullHeight: true,
                fullWidth: true,
                overflow: 'auto',
                padding: 5
            };
        }
        return {
            fullHeight: true,
            fullWidth: true,
        };
    }, [archiveView]);

    return (
        <Stack {...stackProps}>
            <FindingsTableWrapper
                tableId={CDR_EVENTS_TABLE}
                filters={filters}
                columns={columns}
                actionsCreator={actionsCreator}
                originTypes={originTypes}
                isArchiveView={archiveView}/>
        </Stack>
    );
};

export default CdrEventsPage;
