import mixpanel from 'mixpanel-browser';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { AFTER_LOGIN_EVENT } from 'common/extensibility/WellKnownPaths';
import { getLoggerService, getStoreService, getUserService } from 'common/interface/services';
import { getIsCloudInfra, getCurrentDataCenter } from 'common/utils/RuntimeEnvironment';

// POC: open only for Singapore
const isMixpanelEnabled = () => {
    return getCurrentDataCenter()?.dataCenter === 'apse1';
};

export const trackEvent = (eventName: string, properties = {}) => {
    isMixpanelEnabled() && mixpanel.track(eventName, properties);
};

export const trackPageView = () => {
    isMixpanelEnabled() && mixpanel.track_pageview();
};

export const initializeMixpanel = () => {
    if (!isMixpanelEnabled()) {
        return;
    }

    try {
        // TODO: key should be different for each data center.
        mixpanel.init('9e41a27cebcdf6bf8a5d1692510d01a1');
    } catch (e: any) {
        getLoggerService().error(`Could not load Mixpanel ${e.message}`);
    }
};
export const setMixpanelOptions = () => {
    if (!isMixpanelEnabled()) {
        return;
    }

    globalAddinContainer.add(AFTER_LOGIN_EVENT, [{
        id: 'Mixpanel',
        content: async () => {
            try {
                const user = getUserService().getUser();
                const { state } = getStoreService().getReduxTools();
                const account = state.user.account;
                mixpanel.identify(`${getCurrentDataCenter()?.dataCenter}_${user.accountId}_${user.id}`);

                // those prperties are been sent with every event
                mixpanel.register({
                    'isCloudInfra': getIsCloudInfra(),
                    'isSuperUser': user.isSuperUser,
                });

                mixpanel.people.set({
                    accountId: user.accountId,
                    userId: user.id,
                    dataCenter: getCurrentDataCenter()?.dataCenter,
                    isSuperUser: user.isSuperUser,
                    plan: account?.plan,
                });
            } catch (e: any) {
                getLoggerService().error(`Could not load Mixpanel ${e.message}`);
            }
        },
    }]);
};