import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import React, { useMemo } from 'react';
import { getWafFilters } from '../WAF.datasource';
import { isEmpty } from 'lodash';
import { WAFFiltersAlertStyled } from './WAFFiltersAlert.styled';
import { Chip } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';

const WAFFiltersAlert: React.FC<{ filterValues?: IFiltersValues }> = ({ filterValues }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const supportedFilters = useMemo(() =>
        filterValues? Object.keys(getWafFilters(filterValues)): [], [filterValues]);

    return ( !isEmpty(filterValues?.fields) &&
        <WAFFiltersAlertStyled.Tip type={'warning'}>
            { t('RISK_MANAGEMENT.DASHBOARD.WAF.FILTERS.NOTE', { count: supportedFilters.length }) }
            <WAFFiltersAlertStyled.ChipsWrapper>
                { supportedFilters?.map((filter: string) => <Chip label={filter} size='sm' key={`chip-${filter}`} />) }
            </WAFFiltersAlertStyled.ChipsWrapper>
        </WAFFiltersAlertStyled.Tip>
    );
};

export default WAFFiltersAlert;
