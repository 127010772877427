import React from 'react';
import { ToastOptions, ToastContentProps } from 'react-toastify';
import ToastStyle from './Toast.styled';
import { IToast } from './Toast.types';
import { NotificationType } from 'common/interface/notifications';
import { Spinner } from 'common/design-system/components-v2';

export const toastOption: ToastOptions = {
    hideProgressBar: true,
    position: 'top-center',
    icon: false
};

export const ToastContent: React.FC<ToastContentProps<{ title: string, type: NotificationType, text: string }>> = ({ data, toastProps, closeToast }) => {
    if (!data) {
        return null;
    }
    const t = { ...toastProps, closeButton: true };
    return <Toasts toastProps={t} data={data} closeToast={closeToast} />;
};

const Toasts: React.FC<IToast> = ({ data: { title, type, text } }) => {
    return (
        <ToastStyle.ContentToast>
            <ToastStyle.StatusBar type={type === NotificationType.PENDING ? NotificationType.INFO : type} />
            {/* @ts-ignore */}
            {type === NotificationType.PENDING ? <Spinner size={20} /> : <ToastStyle.Icon type={type} />}
            <div className='content-text'>
                <div>{title}</div>
                {text && <div>{text}</div>}
            </div>
        </ToastStyle.ContentToast>
    );
};

export default Toasts;
