import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { Card } from 'common/design-system/components-v2';
import { LoadingState } from 'common/interface/general';
import SecurityEventsTable from '../../../../SecurityEvents/SecurityEventsTable/SecurityEventsTable';
import FullSizeError from 'common/erm-components/custom/FullSize/FullSizeError';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import {
    updateSecurityEvents,
    handleSecurityEventsData,
    incSecEventReqCounter
} from '../../../../SecurityEvents/securityEventsUtils';
import { IFindingItem, ISecurityEventsDataState } from '../../../../SecurityEvents/SecurityEvents.interface';


export const MAX_SECURITY_EVENTS_COUNT = 5;
const SECURITY_SENSITIVE_EVENTS_URL = 'erm/security-events/sensitive-data';
const DataAssetSecurityEvents : React.FC<{ filterValues?: IFiltersValues }> = ({ filterValues }) => {
    const [eventsDataState, setEventsDataState] = useState<ISecurityEventsDataState<IFindingItem[]>>({
        loadingState: LoadingState.IS_LOADING,
        isSearching: true,
    });
    const { t } = useTranslation(I18nRiskNamespace);

    useEffect(() => {
        if (filterValues) {
            handleSecurityEventsData(setEventsDataState, LoadingState.IS_LOADING);
            updateSecurityEvents(setEventsDataState, incSecEventReqCounter(), 0, filterValues, MAX_SECURITY_EVENTS_COUNT, SECURITY_SENSITIVE_EVENTS_URL);
        }
    }, [filterValues]);

    return (
        <Card title={t('DATA_SECURITY_DASHBOARD.SECURITY_ISSUES_AND_EVENTS.LATEST_SEVERE_SECURITY_EVENTS')}>
            { eventsDataState?.loadingState === LoadingState.IS_LOADING && <FullSizeSpinner />}
            { eventsDataState?.loadingState === LoadingState.LOADING_FAILED && <FullSizeError />}
            { eventsDataState?.loadingState === LoadingState.LOADING_SUCCEEDED &&
                <SecurityEventsTable
                    items={eventsDataState?.value || []}
                    componentName={t('DATA_SECURITY_DASHBOARD.SECURITY_ISSUES_AND_EVENTS.LATEST_SEVERE_SECURITY_EVENTS')}
                    isSearching={eventsDataState?.isSearching}
                />}
        </Card>
    );
};

export default DataAssetSecurityEvents;