import { IActionUsageDef } from 'common/interface/general';
import {
    createAcknowledgeAction,
    createArchiveAction,
    createAssignUserAction,
    createChangeSeverityAction,
    createCloseAction,
    createCommentAction,
    createFixItAction,
    createRemediateAction,
    createReportIssueAction,
} from '../FindingsTable/Types/FindingsActionsTypes';
import { ALL_EVENTS_TABLE } from './AllEventsPage.const';
import { ModalType } from '../Findings.const';

export const getAllEventsTableActions = (openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef[] => [
    createFixItAction(ALL_EVENTS_TABLE, openDialog),
    createRemediateAction(openDialog),
    createReportIssueAction(openDialog),
    createAcknowledgeAction(openDialog),
    createCommentAction(openDialog),
    createCloseAction(openDialog),
    createChangeSeverityAction(openDialog),
    createAssignUserAction(openDialog),
    createArchiveAction(openDialog),
];
