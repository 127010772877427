import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams } from 'common/components/Wizard/Wizard';
import { getRulesetService } from 'common/interface/services';
import { IPolicy, IShallowRuleset } from 'common/interface/policy';
import { EnvironmentsSelectionComponent, resolveDisplayName } from 'common/components/policy/EnvironmentsSelectionComponent';
import { Vendors } from 'common/consts/vendors';
import { EnvironmentRulesetSelectionComponent } from './EnvironmentRulesetSelectionComponent';
import { i18nPolicyNamespace } from '../initialize.i18n';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { getRulesets, CSPM } from '../initialize';
import { EnvironmentPlatformsSelectionComponent } from './EnvironmentPlatformsSelectionComponent';
import { CardItem } from 'common/components/policy/Card';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Done } from 'common/components/policy/Done/Done';
import { getCloudAccountsService } from 'common/interface/data_services';
import { CloudAccount } from 'common/interface/environments';
import { finishFlow, saveUpdatePolicies, SHIFTLEFT_RELATED_PLATFORMS } from 'common/components/policy/utils';
import { useHistory } from 'react-router-dom';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { useReturnUrl } from 'common/hooks/useReturnUrl';
// eslint-disable-next-line import/no-restricted-paths
import { ImageAdmissionSelectionComponent } from 'modules/workloads/pages/policy/components/ImageAdmissionSelectionComponent/ImageAdmissionSelectionComponent';
// eslint-disable-next-line import/no-restricted-paths
import { ImageAdmissionItem } from 'modules/workloads/pages/policy/helpers/interface';

export interface IEnvironmentPolicy {
    id?: string;
    rulesetId: number;
    targetId: string;
    externalAccountId: string;
    notificationIds: string[];
    targetType: string;
    action: string;
    vendor: string;
    admissionControl: string;
    rulesetPlatform: string;
}

const STEPS_IDS = {
    PLATFORM: 'platform',
    ENVIRONMENT: 'environment',
    RULESET: 'ruleset',
    IMAGE_ADMISSION: 'image_admission',
    NOTIFICATION: 'notification',
    DONE: 'done'
};

const AddEnvironmentPolicyPage: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation(i18nPolicyNamespace);
    const [selectedPlatform,setSelectedPlatform] = useState<Vendors>(Vendors.GENERIC);
    const [selectedEnvironments,setSelectedEnvironments] = useState<ListItem[]>([]);
    const [selectedRulesets,setSelectedRulesets] = useState<ListItem[]>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [selectedNonCompliantAdmission, setSelectedNonCompliantAdmission] = useState<ImageAdmissionItem>();
    const [selectedUnScannedAdmission, setSelectedUnScannedAdmission] = useState<ImageAdmissionItem>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const [stepsToDisplay, setStepsToDisplay] = useState({ [STEPS_IDS.PLATFORM]: true,
        [STEPS_IDS.ENVIRONMENT]: true,
        [STEPS_IDS.RULESET]: true,
        [STEPS_IDS.IMAGE_ADMISSION]: false,
        [STEPS_IDS.NOTIFICATION]: true,
        [STEPS_IDS.DONE]: true
    });
    useBreadcrumbsLastChildren([CSPM,t('GENERAL.ADD_ENVIRONMENT_POLICY')]);
    const returnUrl = useReturnUrl();
    const selectedPlatformChanged = (selectedItem: CardItem) => {
        selectedItem && setSelectedPlatform(selectedItem.id);
        setSelectedRulesets([]);
        setSelectedEnvironments([]);
        setSelectedNotifications([]);
        setStepsToDisplay(prev => ({ ...prev, [STEPS_IDS.IMAGE_ADMISSION]: selectedItem.id === Vendors.KUBERNETES }));
    };

    const selectedRulesetsChanged = (selectedItems: ListItem[]) => {
        setSelectedRulesets(selectedItems);
    };

    const selectedNotificationsChanged = (selectedItems: ListItem[]) => {
        setSelectedNotifications(selectedItems);
    };

    const selectedEnvironmentsChanged = (selectedItems: ListItem[]) => {
        setSelectedEnvironments(selectedItems);
    };

    const handleRulesetPredefined = async (rulesetId: string) => {
        const allRulesets = await getRulesetService().getAllRulesets();
        const ruleset = allRulesets.find(ruleset => ruleset.id === +rulesetId);
        if (ruleset) {
            const rulesetItem: ListItem = {
                id: ruleset.id.toString(),
                name: ruleset.name
            };
            setSelectedRulesets([rulesetItem]);
        }
        setStepsToDisplay(prev => ({ ...prev, [STEPS_IDS.RULESET]: false }));
    };

    const handlePlatformPredefined = (platformId: string) =>{
        if (platformId === Vendors.IMAGE_ASSURANCE) {
            return;
        }
        setSelectedPlatform(platformId as Vendors);
        setStepsToDisplay(prev => ({ ...prev, [STEPS_IDS.PLATFORM]: false, [STEPS_IDS.IMAGE_ADMISSION]: Vendors.KUBERNETES === platformId }));
    };

    const handleEnvironmentPredefined = useCallback((environment: CloudAccount) => {
        setSelectedEnvironments(
            [{
                id: environment.id,
                name: resolveDisplayName(environment),
                icon: selectedPlatform === Vendors.CONTAINER_REGISTRY ? 'containers-registry' : selectedPlatform,
                platform: environment.platform
            }]
        );
        setStepsToDisplay(prev => ({ ...prev, [STEPS_IDS.ENVIRONMENT]: false, [STEPS_IDS.PLATFORM]: false }));
    }, [selectedPlatform]);

    useEffect(() => {
        const setPredefinedFields = async () => {
            const allEnvironments = await getCloudAccountsService().getAllCloudAccounts();
            const querystring = new URLSearchParams(location.search);
            const accountId = querystring.get('accountId');
            const rulesetId = querystring.get('rulesetId');
            const platformId = querystring.get('platformId');
            rulesetId && handleRulesetPredefined(rulesetId);
            platformId && handlePlatformPredefined(platformId);
            if (allEnvironments.length === 1) {
                handleEnvironmentPredefined(allEnvironments[0]);
            }
            if(accountId){
                const environment = allEnvironments.find((env: CloudAccount) => env.id === accountId);
                if(environment) {
                    const _platform = environment.platform === Vendors.GOOGLE ? Vendors.GCP : environment.platform;
                    setSelectedPlatform(_platform as Vendors);
                    handleEnvironmentPredefined(environment);
                }
            }
        };
        setPredefinedFields();
    }, [handleEnvironmentPredefined]);

    const saveChanges = useCallback(async () => {
        if(selectedRulesets?.length && selectedNotifications?.length ){
            const allRulesets = await getRulesets();
            const policies: IPolicy[] = [];
            for (const ruleset of selectedRulesets) {
                for (const environment of selectedEnvironments) {
                    const _ruleset = allRulesets.find((_ruleset:IShallowRuleset) => _ruleset.id === ruleset.id);
                    const rulesetId = +ruleset.id;
                    const newPolicy: IPolicy = {
                        targetId: environment.id,
                        rulesetId: rulesetId,
                        rulesetPlatform: _ruleset?.platform,
                        notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                        admissionControllerAction: selectedNonCompliantAdmission?.type,
                        admissionControlUnScannedAction: selectedUnScannedAdmission?.type,
                        targetType: SHIFTLEFT_RELATED_PLATFORMS.includes(selectedPlatform) ? environment.platform : selectedPlatform || '',
                    };
                    if(rulesetId < 0){
                        newPolicy.rulesetVersion = ruleset.selectedVersion?.version;
                    }
                    policies.push(newPolicy);
                }
            }

            if(policies?.length){
                setFlowStatusMessage(t('GENERAL.PROCESSING'));
                const result = await saveUpdatePolicies(policies);
                if(result) {
                    setFlowStatusOK(true);
                    setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
                }else {
                    setFlowStatusOK(false);
                    setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
                }
            }
        }
    }, [selectedEnvironments, selectedNotifications, selectedPlatform, selectedRulesets, t]);

    const steps = useMemo(()=>{
        return [
            {
                id: STEPS_IDS.PLATFORM,
                name: t('GENERAL.PLATFORM_SELECTION'),
                renderView: (renderViewParams:RenderViewParams) => {
                    return <EnvironmentPlatformsSelectionComponent selectedPlatformId={selectedPlatform} {...renderViewParams} selectedItemChanged={selectedPlatformChanged} />;
                },
                onNext: async ()=>{
                    return ;
                },
            },
            {
                id: STEPS_IDS.ENVIRONMENT,
                name: t('GENERAL.ENVIRONMENTS_SELECTION'),
                renderView: (renderViewParams:RenderViewParams) => {
                    return <EnvironmentsSelectionComponent preSelectedItems={selectedEnvironments} {...renderViewParams } platform={selectedPlatform} selectedEnvironmentsChanged={selectedEnvironmentsChanged} />;
                },
                onNext: async ()=>{
                    return ;
                },
            },
            {
                id: STEPS_IDS.RULESET,
                name: t('GENERAL.RULESET_SELECTION'),
                renderView: (renderViewParams:RenderViewParams) => {
                    return <EnvironmentRulesetSelectionComponent preSelectedItems={selectedRulesets} platform={selectedPlatform} {...renderViewParams } selectedRulesetsChanged={selectedRulesetsChanged}/>;
                },
                onNext: async ()=>{
                    return ;
                },
            },
            {
                id: STEPS_IDS.IMAGE_ADMISSION,
                name: t('GENERAL.IMAGE_ADMISSION_SELECTION'),
                renderView: (renderViewParams:RenderViewParams) => {
                    return (
                        <ImageAdmissionSelectionComponent
                            {...renderViewParams}
                            preSelectedNonCompliant={selectedNonCompliantAdmission}
                            preSelectedUnScanned={selectedUnScannedAdmission}
                            selectedNonCompliantItemChanged={setSelectedNonCompliantAdmission}
                            selectedUnScannedChanged={setSelectedUnScannedAdmission}
                        />
                    );
                },
                onNext: async () => {
                    return;
                },
            },    
            {
                id: STEPS_IDS.NOTIFICATION,
                name: t('GENERAL.NOTIFICATION_SELECTION'),
                renderView: (renderViewParams:RenderViewParams) => {
                    return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={selectedNotificationsChanged}/>;
                },
                onNext: async () => {
                    saveChanges();
                },
            },{
                id: STEPS_IDS.DONE,
                name: t('GENERAL.DONE'),
                renderView: (renderViewParams:RenderViewParams) => {
                    return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
                },
                onNext: async ()=>{
                    finishFlow(returnUrl);
                },
            }
        ];
    },[flowStatusMessage, flowStatusOK, history, saveChanges, selectedEnvironments, selectedNonCompliantAdmission, selectedNotifications, selectedPlatform, selectedRulesets, selectedUnScannedAdmission, t]);

    return (
        <div>
            {steps?.length ? <Wizard
                steps={steps.filter(step => {return stepsToDisplay[step.id];})}
                title={t('GENERAL.ADD_POLICY')}
                doneButtonText={t('GENERAL.DONE')}
                onClose={()=>finishFlow(returnUrl)}
                ShowBackButtonInLastStep={false}
            />: null}
        </div>
    );
};

export default AddEnvironmentPolicyPage;