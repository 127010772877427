import {
    getHttpService,
    IHttpServiceConfig,
    ILoggerService,
    LOG_LEVEL_NAMES,
    LogLevel,
} from 'common/interface/services';
import { isAfShowLoggerEvents } from 'common/utils/debugUtils';

interface ILoggerMessage {
    message?: string;
    messages?: string[];
    action: string;
    tags: string[];
    clientTimeStamp: string;
    browserMetadata: string;
    targetUrl: string;
    screenResolution: string;
    browserResolution: string;
    language: string;
}

function getMessageObj(message: string | string[], action: string, tags: string[]): ILoggerMessage {
    return {
        message: Array.isArray(message) ? undefined : message,
        messages: Array.isArray(message) ? message : undefined,
        action,
        tags,
        browserMetadata: (window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'NA'),
        targetUrl: (window.location && window.location.href ? window.location.href : 'NA'),
        screenResolution: (window.screen && window.screen.width ? window.screen.width : 'NA') + 'x' + (window.screen && window.screen.height ? window.screen.height : 'NA'),
        browserResolution: (window.innerWidth ? window.innerWidth : 'NA') + 'x' + (window.innerHeight ? window.innerHeight : 'NA'),
        language: (window.navigator && window.navigator.language ? window.navigator.language : 'NA'),
        clientTimeStamp: new Date().toUTCString(),
    };
}


class LoggerService implements ILoggerService {
    private readonly logLevel: LogLevel;

    constructor(logLevel: LogLevel) {
        this.logLevel = logLevel;
    }

    getMessageAsString(message: any): string {
        try {
            let messageAsString;
            if (typeof message === 'function') {
                messageAsString = message() as string;
            } else if (message instanceof Array) {
                messageAsString = String(message);
            } else if (message instanceof Error) {
                messageAsString = message.toString();
            } else if (typeof message === 'object' && message !== null) {
                messageAsString = JSON.stringify(message);
            } else {
                messageAsString = String(message);
            }
            return messageAsString;
        } catch {
            return String(message);
        }
    }

    async log(level: LogLevel, message: string | string[], action?: string, tags?: string[]): Promise<void> {
        if (level < this.logLevel) {
            return;
        }
        try {
            let path = 'LoggerService';
            let finalMessage: string | string[] = `[FrontEnd Log: ${LOG_LEVEL_NAMES[level]}] ${message}`;
            if (Array.isArray(message)) {
                path = 'LoggerService/Bulk';
                finalMessage = message.map((msg) => `[FrontEnd Log: ${LOG_LEVEL_NAMES[level]}] ${msg}`);
            }
            const messageObj = getMessageObj(finalMessage, action ?? '', tags ?? []);
            await getHttpService().post({
                path,
                requestObject: { data: messageObj },
                loggingConfig: { useLogging: false }, //don't want to be stuck in a logging loop if logging is slow!
            } as IHttpServiceConfig,
            );
        } catch (e: any) {
            console.error(`Could not send message to server logger ${e.message}`);
        }
    }

    async critical(message: any, action?: string, tags?: string[]): Promise<void> {
        const stringMessage = this.getMessageAsString(message);
        console.error(stringMessage);
        await this.log(LogLevel.Critical, stringMessage, action, tags);
    }

    async debug(message: any, action?: string, tags?: string[]): Promise<void> {
        const stringMessage = this.getMessageAsString(message);
        if (isAfShowLoggerEvents()) {
            console.debug('Debug: ', stringMessage);
        }
        await this.log(LogLevel.Debug, stringMessage, action, tags);
    }

    async error(message: any, action?: string, tags?: string[]): Promise<void> {
        const stringMessage = this.getMessageAsString(message);
        console.error(stringMessage);
        await this.log(LogLevel.Error, stringMessage, action, tags);
    }

    async info(message: any, action?: string, tags?: string[]): Promise<void> {
        const stringMessage = this.getMessageAsString(message);
        if (isAfShowLoggerEvents()) {
            console.info('Info: ', stringMessage);
        }
        await this.log(LogLevel.Info, stringMessage, action, tags);
    }

    async warning(message: any, action?: string, tags?: string[]): Promise<void> {
        const stringMessage = this.getMessageAsString(message);
        console.warn(stringMessage);
        await this.log(LogLevel.Warning, stringMessage, action, tags);
    }

}

export default LoggerService;