import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { IFieldInfo } from 'common/interface/general';
import { convertFilterField, filterFieldsChanges } from 'common/utils/filterUtils';
import { ASSET_FIELD_NAMES } from '../../../consts';
import { FieldEntityKind } from 'common/registries/FieldConvertorsRegistry';
import { assetFilterFieldsToFindings } from '../../../RiskManagement.utils';
import {
    ASSETS_FILTER_TYPES,
    createRecentlyUsedFilter,
    createSavedFiltersFilter
} from '../BaseDashboard/DashboardFilters';

export const EXPOSURE_DASHBOARD_FILTERS: IProtectedAssetFilter[] = [
    ASSETS_FILTER_TYPES.ADD_FILTER,
    ASSETS_FILTER_TYPES.ORGANIZATIONAL_UNIT,
    ASSETS_FILTER_TYPES.PLATFORM,
    ASSETS_FILTER_TYPES.TYPE,
    ASSETS_FILTER_TYPES.ENVIRONMENT,
    ASSETS_FILTER_TYPES.BUSINESS_PRIORITY,
    createRecentlyUsedFilter('erm-exposure-dashboard'),
    createSavedFiltersFilter('erm-exposure-dashboard-saved-filters'),
    ASSETS_FILTER_TYPES.CLEAR_ALL,
];

export const assetFilterFieldsToThirdParty = (fields: IFieldInfo[] = []) => {
    return fields.filter(field => {
        return (field.name !== ASSET_FIELD_NAMES.type);
    });
};

export const isFilterRelevantToSecurityEvents = (oldFields: IFieldInfo[], newFields: IFieldInfo[]) => {
    return filterFieldsChanges(assetFilterFieldsToFindings(oldFields), assetFilterFieldsToFindings(newFields));
};

export const isFilterRelevantToMaliciousAssets = (oldFields: IFieldInfo[], newFields: IFieldInfo[]) => {
    return filterFieldsChanges(assetFilterFieldsToFindings(oldFields), assetFilterFieldsToFindings(newFields));
};

export const isFilterRelevantToThirdParty = (oldFields: IFieldInfo[], newFields: IFieldInfo[]) => {
    return filterFieldsChanges(assetFilterFieldsToThirdParty(oldFields), assetFilterFieldsToThirdParty(newFields));
};

export const isFilterRelevantToNonMfa = (oldFields: IFieldInfo[], newFields: IFieldInfo[]) => {
    return filterFieldsChanges(convertFilterFieldsToNonMfa(oldFields), convertFilterFieldsToNonMfa(newFields));
};

export const convertFilterFieldsToNonMfa = (fields: IFieldInfo[] = []) => {
    const finalFields: IFieldInfo[] = [];
    fields.forEach((field: IFieldInfo) => {
        if (field.name !== ASSET_FIELD_NAMES.type) {
            const targetField = convertFilterField(FieldEntityKind.ASSET, FieldEntityKind.FINDING, field);
            if (targetField) {
                finalFields.push(targetField);
            }
        }
    });
    return finalFields;
};

