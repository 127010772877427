import { useEffect, useMemo, useState } from 'react';
import './BusinessPriorityRules.scss';
import { useTranslation } from 'react-i18next';
import EmptyRulesList from './EmptyRulesList/EmptyRulesList';
import {
    CpCommonButton,
    CpLoadingDots,
    CpModal,
    CpModalBody,
    CpModalFooter,
    CpModalHeader,
    CpTextButton,
    CpTooltip,
} from '@dome9/components/react/components';
import BusinessRuleCard from './BusinessRuleCard/BusinessRuleCard';
import { ERM_CONSTS, I18nRiskNamespace } from '../../consts';
import { getNotificationsService, IUserService, USER_SERVICE_ID } from 'common/interface/services';
import { Environment, Rule } from './BusinessPriority.interface';
import { getService } from 'common/extensibility/AddinContainer';
import { CloudAccount } from 'common/interface/environments';
import {
    getCloudAccountsService,
    getOrganizationalUnitService,
    ICloudAccount,
    IOrganizationalUnit
} from 'common/interface/data_services';
import { RiskManagementRegistry } from '../../RiskManagementRegistry';
import { getVendor } from 'common/consts/vendors';
import { runAll } from 'common/utils/helpFunctions';
import { Button } from 'common/design-system/components-v2';
import { getEnvironmentDisplayNameFromCloudAccount } from 'common/components/ProtectedAssets/AssetUtils';
import i18n from 'i18next';

interface IComponentData {
    organizationalUnitsRoot: IOrganizationalUnit,
    environments: Environment[];
    rules: Rule[];
}

const loadData = (): Promise<IComponentData> => {
    const loadOrganizationalRootPromise = (): Promise<IOrganizationalUnit> => {
        return getOrganizationalUnitService().getOrganizationalUnitsView().then((organizationalUnitsRoot: IOrganizationalUnit) => {
            return organizationalUnitsRoot;
        });
    };

    const loadEnvironmentsPromise = (): Promise<Environment[]> => {
        return getCloudAccountsService().getAllCloudAccounts().then((environments: ICloudAccount[]) => {
            return environments
                .filter((environment: CloudAccount) =>
                    ERM_CONSTS.ERM_RULESET_NAMES.includes(environment.platform.toLocaleUpperCase()),
                )
                .map((environment: ICloudAccount) => {
                    const iconName = getVendor(environment.platform)?.icon;
                    return {
                        cloudAccountId: environment.id,
                        id: environment.id,
                        platform: environment.platform,
                        name: environment.name,
                        value: environment.id,
                        label: getEnvironmentDisplayNameFromCloudAccount(environment, environment.id) || environment.name || environment.id,
                        iconName,
                    };
                });
        });
    };

    const loadRulesPromise = (): Promise<Rule[]> => {
        return Promise.resolve(RiskManagementRegistry.getBusinessPriorityService().getRules());
    };

    const promisesMap: any = {
        organizationalUnitsRoot: loadOrganizationalRootPromise(),
        environments: loadEnvironmentsPromise(),
        rules: loadRulesPromise(),
    };

    return runAll(promisesMap).then(resultsMap => {
        return {
            organizationalUnitsRoot: resultsMap.organizationalUnitsRoot,
            environments: resultsMap.environments || [],
            rules: resultsMap.rules || [],
        };
    });
};

const BusinessPriorityRules: React.FC = () => {
    const { t } = useTranslation(I18nRiskNamespace);
    const isSuperUser = useMemo(() => getService<IUserService>(USER_SERVICE_ID).getIsSuperUser(), []);
    const getDefaultRule = () => {
        return {
            ruleName: null,
            priority: '',
            isEdit: true,
            ruleDefinition: {
                organizationalUnits: null,
                environments: null,
                assetTags: null,
                assetNameContains: null,
            },
        };
    };
    const [rules, setRules] = useState<Rule[] | []>([]);
    const [organizationalUnitsRoot, setOrganizationalUnitsRoot] = useState<IOrganizationalUnit | null>(null);
    const [environments, setEnvironments] = useState<Environment[] | null>(null);
    const [ruleForRemoval, setRuleForRemoval] = useState<Rule | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAddRule, setIsAddRule] = useState<boolean>(false);
    const [isRuleEditMode, setIsRuleEditMode] = useState<boolean>(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
    const addRule = async () => {
        if (!isSuperUser) {
            return;
        }
        setIsAddRule(true);
        const rule: Rule = getDefaultRule();
        const cloneRules: Rule[] = rules.map((rule: Rule) => ({ ...rule, isEdit: false }));
        setRules([rule, ...cloneRules]);
        setIsRuleEditMode(true);
    };

    const cancelNewRule = async () => {
        const existingRules: Rule[] = rules.filter((rule: Rule) => rule.id);
        setIsAddRule(false);
        setRules(existingRules);
        setIsRuleEditMode(false);
    };

    const closeModal = () => {
        setIsRemoveModalOpen(false);
        setRuleForRemoval(null);
    };

    const removeRule = async () => {
        closeModal();
        if (!ruleForRemoval?.id) {
            setIsAddRule(false);
            setIsRuleEditMode(false);
            setRules(rules.filter((_rule: Rule, index: number) => index));
        } else {
            try {
                setIsLoading(true);
                await (RiskManagementRegistry.getBusinessPriorityService().removeRule(ruleForRemoval.id));
                const cloneRules = rules.filter((rule: Rule) => rule.id !== ruleForRemoval.id);
                setIsRuleEditMode(false);
                setRules(cloneRules);
                getNotificationsService().success(
                    t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TOAST.REMOVE_TITLE'),
                    t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TOAST.DELETE_SUCCESS'),
                );
            } finally {
                setIsLoading(false);
            }
        }
    };

    const saveRule = async (rule: Rule, ruleIdx: number) => {
        if (!isSuperUser) {
            return;
        }
        try {
            setIsLoading(true);
            const cloneRules = [...rules];
            if (rule.id) {
                await RiskManagementRegistry.getBusinessPriorityService().updateRule(rule);
                const idx = cloneRules.findIndex((currentRule) => currentRule.id === rule.id);
                cloneRules[idx] = rule;
            } else {
                const unpdateRule = await RiskManagementRegistry.getBusinessPriorityService().addRule(rule);
                cloneRules[ruleIdx] = { ...rule, id: unpdateRule.id };
            }
            setIsAddRule(false);
            setRules(cloneRules);
            getNotificationsService().success(
                t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TOAST.SAVE_TITLE'),
                t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TOAST.SAVE_SUCCESS'),
            );
        } finally {
            setIsLoading(false);
        }
    };

    const isDuplicateName = (name: string, idx: number) => {
        return rules.some((rule: Rule, index: number) => rule.ruleName === name && index !== idx);
    };

    useEffect(() => {
        setIsLoading(true);
        loadData().then(data => {
            setOrganizationalUnitsRoot(data.organizationalUnitsRoot);
            setEnvironments(data.environments);
            setRules(data.rules);
            setIsLoading(false);
        });
    }, []);

    const addRuleButton = () => {
        const disabled = !isSuperUser || isAddRule || isRuleEditMode;
        return (
            <Button
                label={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.ADD_RULE')}
                disabled={disabled}
                onClick={addRule}
                variant='text'
                iconProps={{ name: 'plus', size: 12 }}
            />
        );
    };
    return (
        <div className='business-priority-container'>
            {isLoading ? (
                <CpLoadingDots />
            ) : !rules.length ? (
                <EmptyRulesList isSuperUser={isSuperUser} addRules={addRule} />
            ) : (
                <div className='rules-list-container'>
                    {isSuperUser ? (
                        addRuleButton()
                    ) : (
                        <CpTooltip
                            right
                            className='tooltip'
                            text={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.USER_PERMISSIONS_TOOLTIP')}>
                            {addRuleButton()}
                        </CpTooltip>
                    )}

                    <CpModal onCpClose={closeModal} size={'md'} open={isRemoveModalOpen}>
                        <CpModalHeader>{t('RISK_MANAGEMENT.BUSINESS_PRIORITY.MOADL.TITLE')}</CpModalHeader>
                        <CpModalBody>
                            {t('RISK_MANAGEMENT.BUSINESS_PRIORITY.MODAL.BODY').replace(
                                '{ruleName}',
                                ruleForRemoval?.ruleName || '',
                            )}
                        </CpModalBody>
                        <CpModalFooter>
                            <CpTextButton onClick={closeModal}>{i18n.t('COMMON.CANCEL')}</CpTextButton>
                            <CpCommonButton onClick={removeRule}>
                                {t('RISK_MANAGEMENT.BUSINESS_PRIORITY.MODAL.SUBMIT')}
                            </CpCommonButton>
                        </CpModalFooter>
                    </CpModal>

                    {rules.map((rule: Rule, idx: number) => {
                        return (
                            <BusinessRuleCard
                                removeRule={(rule: Rule) => {
                                    if (!isSuperUser) {
                                        return;
                                    }
                                    setRuleForRemoval(rule);
                                    setIsRemoveModalOpen(true);
                                }}
                                updateSaveRule={saveRule}
                                organizationalUnitsRoot={organizationalUnitsRoot}
                                environments={environments}
                                key={rule.id || idx}
                                ruleIdx={idx}
                                rule={rule}
                                isRuleEditMode={isRuleEditMode}
                                setIsRuleEditMode={setIsRuleEditMode}
                                isDuplicateName={isDuplicateName}
                                isSuperUser={isSuperUser}
                                cancelNewRule={cancelNewRule}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default BusinessPriorityRules;
