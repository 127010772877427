import { Edge, Node } from 'reactflow';
import { Vendors } from 'common/consts/vendors';
import {
    AccountActivityEdgeAdditionalData,
} from '../../AccountActivity/Models/AccountActivityGraphModels';
import { NetworkTrafficEdgeAdditionalData } from '../../NetworkTraffic/Models/NetworkTrafficGraphModels';
import { IFindingSeverityCounters } from 'common/consts/FindingSeverity';

export interface VisualizationProps {
    setNodes: Function,
    setEdges: Function,
    nodes: Node[],
    edges: Edge[],
    onNodeClick: Function,
    intelligenceSource: string | undefined
}

export type IntelligenceEdgeAdditionalData = AccountActivityEdgeAdditionalData & NetworkTrafficEdgeAdditionalData;

export interface EdgeData {
    sourceColor?: string;
    targetColor?: string;
    entity?: {
        name?: string,
        srl: string,
        type: string,
        externalId?: string,
        gotoUrl?: string
    };
    additionalData? : IntelligenceEdgeAdditionalData;
}

export interface Entity {
    graphId: string;
    srl: string;
    gotoSrl?: string;
    externalId: string;
    name: string;
    type: string;
    backgroundColor?: string;
    gotoUrl?: string;
    isInContext?: boolean;
    classification?: string;
    additionalData?: {
        region?: string;
        riskData?: RiskData,
        vendor?: Vendors,
        ip?: string | undefined,
        intelligenceNodeType?: string,
        cloudAccount?: {
            externalId?: string,
            name?: string
        }
    };
}

export interface RiskData {
    riskScore: number | undefined;
    exposureLevel: string | undefined;
    iamExposure: string | undefined;
    iamSensitivity: number | undefined;
    businessPriority: string | undefined;
    cves: IFindingSeverityCounters | undefined;
    postureFindings: IFindingSeverityCounters | undefined;
    securityEvents: IFindingSeverityCounters | undefined;
}

export interface Relationship {
    fromGraphId: string;
    toGraphId: string;
    type: string;
    additionalData?: {
        accountActivity?: AccountActivityEdgeAdditionalData,
        networkActivity?: NetworkTrafficEdgeAdditionalData
    }
}

export class GraphData {
    public constructor(
        public readonly entities: Entity[],
        public readonly relationships: Relationship[],
    ) { }
}
