import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
  min-width: 200px;
`;

const FailureDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.palette.status.error}
`;

export const DataAssetStyled = {
    TopDiv,
    FailureDiv
};