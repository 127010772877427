import {
    IProtectedAssetDetailPanelProvider,
} from 'common/module_interface/assets/ProtectedAssets';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { isNil } from 'common/utils/helpFunctions';
import {
    getMagellanLinkGeneratorService
} from 'common/module_interface/intelligence/intelligence';
import i18next from 'i18next';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';

const accountActivityPanel: IProtectedAssetDetailPanelProvider = {
    isRelevant: (cloudEntityObj: ICloudEntityData) => {
        const asset = cloudEntityObj?.protectedAsset;
        const assetType = asset?.type;
        if (isNil(assetType)) return false;
        if (['awsgov', 'awschina'].includes(cloudEntityObj?.cloudAccount?.platform)) {
            return false; // aws gov and aws china not supported yet
        }
        const magellanLinkGeneratorService = getMagellanLinkGeneratorService();
        const isAccountTabFilter = magellanLinkGeneratorService.generateFilterForProtectedAssets('cloudtrail', asset);
        return !!isAccountTabFilter;
    },
    getValue() {
        return {
            name: 'magellan-account',
            title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.ACCOUNT_ACTIVITY'),
            isReact: false,
        };
    },
};

const trafficActivityPanel: IProtectedAssetDetailPanelProvider = {
    isRelevant: (cloudEntityObj: ICloudEntityData) => {
        const asset = cloudEntityObj?.protectedAsset;
        const assetType = asset?.type;
        if (isNil(assetType)) return false;
        if (['awsgov', 'awschina'].includes(cloudEntityObj?.cloudAccount?.platform)) {
            return false; // aws gov and aws china not supported yet
        }
        const magellanLinkGeneratorService = getMagellanLinkGeneratorService();
        const isNetworkTabFilter = magellanLinkGeneratorService.generateFilterForProtectedAssets('flowlogs', asset);
        return !!isNetworkTabFilter;
    },
    getValue() {
        return {
            name: 'magellan-network',
            title: i18next.t('COMMON.PROTECTED_ASSET.DETAILS_PANE.PANELS.TRAFFIC_ACTIVITY'),
            isReact: false,
        };
    },
};

export default function initializeMagellanPanels() {
    ProtectedAssetsPageRegistry.addPanelAddins([
        {
            id: 'magellan-account',
            position: 96,
            content: accountActivityPanel,
        },
        {
            id: 'magellan-network',
            position: 97,
            content: trafficActivityPanel,
        },
    ]);
}
