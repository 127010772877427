import { getUserAndSaveToState, initLogin } from 'platform/user/User.actions';
import React, { useEffect } from 'react';
import { refreshToken } from 'platform/auth/Auth.actions';
import { Cookies } from 'react-cookie';
import { CpLoadingDots } from '@dome9/components/react/components';
import { CSRF, getCookieOptions, setCsrf } from 'common/utils/http';

export const Sso: React.FC<{}> = () => {
    useEffect(() => {
        const cookies = new Cookies();

        const ssoInit = async () => {
            setCsrf(cookies.get(CSRF),getCookieOptions());
            await refreshToken();
            await getUserAndSaveToState();
            await initLogin();
        };
        ssoInit();
    }, []);

    return <CpLoadingDots />;
};
