import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { ICloudAccount, getCloudAccountsService } from 'common/interface/data_services';
import { changeUrl } from 'common/utils/http';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EnvironmentTab, TabListInterface } from './TabList.interface';
import { getVendor } from 'common/consts/vendors';
import { List, Stack, Tabs } from 'common/design-system/components-v2';
import { ITabItemProps } from 'common/design-system/components-v2/Tabs/Tabs.types';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

export const TabsList: React.FC<TabListInterface> = ({ context, urlPrefix }) => {
    const { t } = useTranslation();
    const { platform, id } = useParams<{ platform: string, id: string }>();
    const [environmentTabs, setEnvironmentTabs] = useLocalStorage<Array<EnvironmentTab>>([], `${context}EnvironmetTabs`);
    const { allCloudAccounts } = useAllCloudAccounts();
    const tabsWrapperRef = React.useRef<HTMLDivElement>(null);
    const [maxTabs, setMaxTabs] = React.useState<number>(5);

    React.useEffect(() => {
        if (!tabsWrapperRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (!tabsWrapperRef.current) return;
            const { width } = tabsWrapperRef.current.getBoundingClientRect();
            setMaxTabs(Math.floor(width / 180));
        });
        resizeObserver.observe(tabsWrapperRef.current);
        return () => resizeObserver.disconnect();
    }, [tabsWrapperRef]);

    React.useEffect(() => {
        const onUrlChanged = async (id: string) => {
            const cloudAccount = await getCloudAccountsService().getCloudAccountByAccountId(id);
            if (!cloudAccount) return;
            setEnvironmentTabs((currentState) => {
                const alreadyExist = currentState.findIndex(tab => tab.id === id);

                if (alreadyExist > -1) {
                    if (alreadyExist >= maxTabs) {
                        const newState = [...currentState];
                        newState.splice(maxTabs - 1, 0, newState.splice(alreadyExist, 1)[0]);
                        return newState;
                    }
                    return currentState;
                }

                const url = `${urlPrefix}/${platform}/${id}`;
                const newState = [...currentState];
                newState.splice(maxTabs - 1, 0, { id, platform, url, icon: getVendor(cloudAccount.platform)?.newIcon || 'genericImage', name: cloudAccount.name });
                return newState;
            });
        };
        if (id && platform) {
            onUrlChanged(id);
        }
    }, [platform, id, urlPrefix, maxTabs, setEnvironmentTabs]);

    React.useEffect(() => {
        if (allCloudAccounts.length === 0) return;
        setEnvironmentTabs((currentState) => {
            const allCloudAccountDictionary = allCloudAccounts.reduce<{ [id: string]: ICloudAccount }>((acc, cloudAccount) => {
                acc[cloudAccount.id] = cloudAccount;
                return acc;
            }, {});
            return currentState.reduce<Array<EnvironmentTab>>((acc, tab) => {
                const cloudAccount = allCloudAccountDictionary[tab.id];
                if (cloudAccount) {
                    acc.push({ ...tab, icon: getVendor(cloudAccount.platform)?.newIcon || 'genericImage', name: cloudAccount.name });
                }
                return acc;
            }, []);
        });
    }, [allCloudAccounts, setEnvironmentTabs]);

    const onTabSelected = React.useCallback((tabIndex: number) => {
        if (tabIndex < 0) return;
        const selectedTab = environmentTabs[tabIndex];
        changeUrl(selectedTab.url);
        if (tabIndex >= maxTabs) {
            setEnvironmentTabs((currentState) => {
                const newState = [...currentState];
                newState.splice(maxTabs - 1, 0, newState.splice(tabIndex, 1)[0]);
                return newState;
            });
        }
    }, [environmentTabs, setEnvironmentTabs, maxTabs]);

    const closeTab = React.useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>, tabId: string) => {
        event.preventDefault();
        event.stopPropagation();
        setEnvironmentTabs(currentState => {
            const tabToBeClosedIndex = currentState.findIndex((tab => tab.id === tabId));
            if (id && tabId === id) {
                const nextSelectedTab = currentState[tabToBeClosedIndex + 1] || currentState[tabToBeClosedIndex - 1];
    
                if (!nextSelectedTab) {
                    changeUrl(urlPrefix);
                } else {
                    changeUrl(nextSelectedTab.url);
                }
            }
            return currentState.filter(tab => tab.id !== tabId);
        });

    }, [id, setEnvironmentTabs, urlPrefix]);

    const closeOtherTabs = React.useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>, tabId: string) => {
        event.preventDefault();
        event.stopPropagation();
        setEnvironmentTabs(currentState => {
            const clickedTabIndex = currentState.findIndex(tab => tab.id === tabId);
            if (id && (tabId !== id)) {
                onTabSelected(clickedTabIndex);
            }
            const newState = currentState.filter(tab => tab.id === tabId);
            return newState;
        });
    }, [setEnvironmentTabs, onTabSelected, id]);

    const closeAllTabs = React.useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        setEnvironmentTabs([]);
        if (id) {
            changeUrl(urlPrefix);
        }
        
    }, [id, setEnvironmentTabs, urlPrefix]);

    const renderTabActions = React.useCallback((tabId: string) => {
        return (
            <List
                options={[
                    { label: t('COMMON.TABS.CLOSE'), value: 'close', onClick: (event) => closeTab(event, tabId) },
                    { label: t('COMMON.TABS.CLOSE_OTHERS'), value: 'closeOthers', onClick: (event) => closeOtherTabs(event, tabId) },
                    { label: t('COMMON.TABS.CLOSE_ALL'), value: 'closeAll', onClick: (event) => closeAllTabs(event) },
                ]}
            />
        );
    }, [closeAllTabs, closeOtherTabs, closeTab, t]);

    const selectedTabId = React.useMemo(() => environmentTabs.find(tab => tab.id === id)?.id, [environmentTabs, id]);

    const tabsOptions = React.useMemo<ITabItemProps[]>(() => {
        return environmentTabs.map((tab) => {
            return { 
                label: tab.name,
                value: tab.id,
                icon: { name: tab.icon as AllIconsName },
                contextMenuElement: renderTabActions(tab.id),
            };
        });
    }, [environmentTabs, renderTabActions]);

    return (
        <>
            {!!tabsOptions.length && 
                <Stack fullWidth padding={[1, 0, 0, 0]} ref={tabsWrapperRef}>
                    <Tabs
                        tabs={tabsOptions}
                        selectedTab={selectedTabId}
                        onClose={(tab, index, event) => closeTab(event, tab.value as any)}
                        onTabSelected={(tab, index) => onTabSelected(index)}
                        showMoreProps={{ maxTabs, label: 'More' }}
                        alignment='start'
                        tabsMaxWidth={200}
                    />
                </Stack>
            }
        </>
    );
};
