import { ICellRendererParams } from 'ag-grid-enterprise';
import { FC } from 'react';
import { IIssueExclusion } from '../IssueExclusions.interface';
import { MultiOptionChipsComponent } from 'common/erm-components/custom/MultiChipCellContent/MultiOptionChipsComponent';
import { getAllEnvironmentOptions } from '../../../RiskManagement.options';

export const IssueExclusionEnvironmentCellRender: FC<ICellRendererParams<IIssueExclusion>> = (params) => {
    const exclusion: IIssueExclusion | undefined = params.data;
    if (!exclusion) {
        return null;
    }

    return <MultiOptionChipsComponent selectedValues={exclusion?.envIds} fetchAllOptions={getAllEnvironmentOptions} />;
};