import { AxiosResponse } from 'axios';
import { AWSCloudAccount } from 'common/module_interface/assets/AwsEnvironment';

export type ProtectionType = 'monitor' | 'fullProtection';

export enum BladeState {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    ERROR = 'ERROR',
    INACTIVE = 'INACTIVE',
}

export type BladeStateTotal = { [key in BladeState]: number };

export enum AwsOnboardingType {
    simple = 'simple',
    advanced = 'advanced',
    organizational = 'organizational',
}

export enum AwsOnboardingAccountType {
    aws = 'aws',
    awsgov = 'awsgov',
    awschina = 'awschina',
}

export enum OnboardingStepType {
    'Welcome',
    'CloudAccount',
    'PostureManagement',
    'GroupProtection',
    'Intelligence',
    'Serverless',
    'Summary',
    'Unknown',
}

export interface BladeStatus {
    environment?: string;
    environmentId: string;
    blade: string;
    feature: string;
    status: BladeState;
    stackStatus: string;
    statusMessage: string;
    remediationRecommendation: string;
}

export enum CftTabType {
    'Posture' = 'Posture',
    'Onboarding' = 'Onboarding',
    'MemberAccount' = 'Member Account'
}

export interface CftTab {
    content: string;
    type: CftTabType;
    repoUrl?: string;
    fileName?: string;
    downloadName?: string;
    url?: string;
}

export interface OnboardingState {
    awsStep: number;
    environmentId: string;
    allowCGUpdateStack: boolean;
    allowChinaPrivacyPolicy: boolean;
    awsOnboardingType: AwsOnboardingType;
    enableServerless: boolean;
    enableIntelligence: boolean;
    awsCftPopupOpen: boolean;
    selectedProtection: ProtectionType;
    awsOnboardingAccountType: AwsOnboardingAccountType;
    awsCfts?: CftTab[] | undefined;
    awsOnboardingUrl?: string;
    bladeStateTotal: BladeStateTotal;
    rulesets: Ruleset[];
    bladesStatuses: BladeStatus[];
    awsOnboardingId?: string;
    selectedRulesets: number[];
    defaultRulesets: number[];
    gotRulesetsFromServer: boolean;
    userClickedLaunchStack: boolean;
    lambdaRepoUrl?: string;
    awsOnboardingFinished: boolean;
    intelligenceRulesets: Ruleset[];
    defaultIntelligenceRulesets: number[];
    selectedIntelligenceRulesets: number[];
    gotIntelligenceRulesetsFromServer: boolean;
    selectedModuleInSummary?: BladeStatus;
    isSelectedModuleByUser?: boolean;
    postureManagementFilterValue?: string;
    intelligenceFilterValue?: string;
    isTakingLongerThanExpectedAccount?: boolean;
    isTakingLongerThanExpectedSummary?: boolean;
    isOrgOnboarding: boolean;
    isWelcomeStepClicked: boolean;
    orgOnboardingArnRole?: string;
    orgOnboardingManagementAccountExternalId?: string;
    orgOnboardingOrganizationName?: string;
    orgOnboardingPostManagementResponse?: PostManagementStackResponse;
    orgOnboardingMemeberAccountConfigurations?: GetMemeberAccountConfigurationsResponse;
    memberAccountArn?: string;
    nextButtonDisabled: boolean;
    orgOnboardingDone: boolean;
    managementAccountId: string;
    accountAlreadyOnboarded: boolean;
    isManagementOnboarded?: boolean;
    isSuccessModalShow: boolean;
    isWizardBusy: boolean;
    managementAccountDetails: AWSCloudAccount | null;
}

export interface WizardBody extends HTMLElement {
    updateSteps: Function;
}

export interface CftModalProps {
    open: boolean;
    onClose:() => void;
    title:string;
    cfts: CftTab[];
}

export type GetCftDataRequest = {
    onboardType: AwsOnboardingType;
    cloudVendor: AwsOnboardingAccountType;
    fullProtection: boolean;
    postureManagementConfiguration: {
        rulesets: number[];
    };
    serverlessConfiguration: {
        enabled: boolean;
    };
    intelligenceConfigurations: {
        enabled: boolean;
        rulesets: number[];
    };
    enableStackModify: boolean;
};

export type GetCftDataResponse = {
    cfts: CftTab[];
    cftUrl: string;
    onboardingId: string;
    lambdaRepoUrl?: string;
    externalId?: string;
    isManagementOnboarded?: boolean;
};

export type Ruleset = {
    name: string;
    description: string;
    icon: string;
    selected?: boolean;
    id: number;
    isTemplate?: boolean;
    cloudVendor?: string;
    category?: string;
};

export type RulesetDTO = {
    name: string;
    description: string;
    icon: string;
    selected?: boolean;
    id: number;
    isTemplate?: boolean;
    cloudVendor?: string;
    common: boolean;
    default: boolean;
};

export type IntelligenceRulesetDTO = {
    name: string;
    description: string;
    icon: string;
    is_preselected?: boolean;
    is_predefined?: boolean;
    id: number;
    vendor?: string;
};

export interface WelcomeCardsProps {
    onCardSelected:(val:AwsOnboardingType) => void;
    awsOnboardingType: AwsOnboardingType,
    awsOnboardingAccountType: AwsOnboardingAccountType;
}

export interface OrgOnboardingConfigurationResponse {
    content: string;
    managementCftUrl: string;
    onboardingId: string;
    externalId?: string;
    isManagementOnboarded?: boolean;
}

export interface ValidateManagementStackArnRequest {
    roleArn: string;
    secret: string;
}

export interface ValidateStackSetArnRequest extends ValidateManagementStackArnRequest {
    stackSetArn: string;
}
export interface PostManagementStackRequest {
    roleArn: string;
    secret: string;
    awsOrganizationName?: string;
    enableStackModify: boolean;
    stackSetArn: string;
}

type ProtectionMode = 'ReadOnly' | 'FullProtection';
type MappingStrategy = 'Flat' | 'Clone';
type AwpOnboardingMode = 'SaaS' | 'InAccount';

type ActivityLog = {
    enabled: boolean;
    bucketArn: string;
    rulesetsIds: number[];
}

type AwpConfiguration = {
    enabled: boolean;
    onboardingMode: AwpOnboardingMode;
}

export interface AwsOrganizationOnboardingConfiguration {
    organizationRootOuId?: string;
    protectionMode: ProtectionMode;
    mappingStrategy: MappingStrategy;
    postureManagement: { rulesetsIds: number[]; };
    activityLog: ActivityLog;
    serverless: { enabled: boolean; };
    awp: AwpConfiguration;
}

export interface PostManagementStackResponse {
    id: string;
    accountId: number;
    creationTime: string | Date;
    updateTime: string | Date;
    enableStackModify: boolean;
    externalManagementAccountId: string;
    onboardingConfiguration: AwsOrganizationOnboardingConfiguration;
    userId: number;
    organizationName: string;
}

export interface GetMemeberAccountConfigurationsResponse {
    content: string;
    onboardingCftUrl?: string;
    externalId: string;
}


// ~~~ CloudAccountGet ~~~
export namespace CloudAccountGet {
    export type Function = (id: string) =>
        Promise<AxiosResponse<CloudAccountGet.Response>>;
    export interface Response extends AWSCloudAccount { }
}
