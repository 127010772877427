import i18n from 'i18next';
import React, { useCallback, useState } from 'react';
import { IAssetWidgetInfo, ITotalSearchResults } from './RiskDashborad.interface';
import { I18nRiskNamespace } from '../../../consts';
import { DashboardFilterPanel } from '../BaseDashboard/DashboardFilterPanel';
import { IValueCount, LoadingState } from 'common/interface/general';
import { createLevelsSummaryData } from 'common/components/AssetWidget/Utils';
import { ILevelsWidgetData } from 'common/components/AssetWidget/AssetWidget.interface';
import { getTotalAssetsFilterFields, getTotalResponse, riskDashboardFilters } from './RiskDashboard.datasource';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { ermPermissionFn, mappingCriticalHigh } from '../../../RiskManagement.utils';
import HighRiskAssets from './HighRiskAssets/HighRiskAssets';
import RiskiestEntities from './RiskiestEntities/RiskiestEntities';
import { RiskDashboardStyled } from './RiskDashboard.styled';
import SecurityIssues from './SecurityIssues/SecurityIssues';
import WAF from './WAF/WAF';
import { WAF_PROTECTION_ACTIVE_FEATURE } from '../../ActiveFeatures/ActiveFeatures.consts';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

const createTotalWidgetData = (riskScore?: IValueCount[] | null, totalAssets?: number | null, filterValues?: IFiltersValues): ILevelsWidgetData => {
    const { criticalCount, highCount } = mappingCriticalHigh(riskScore || null);
    const riskAssetsCount = criticalCount + highCount;
    return {
        title: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.TOTAL_ASSETS.TITLE', { ns: I18nRiskNamespace }),
        icon: 'assets',
        helpInfoText: i18n.t('RISK_MANAGEMENT.DASHBOARD.WIDGETS.TOTAL_ASSETS.HELP', { ns: I18nRiskNamespace }),
        onClick: riskAssetsCount ? () => getErmUrlsService().goToProtectedAssetsTable(getTotalAssetsFilterFields(filterValues)) : null,
        ...createLevelsSummaryData({
            high: highCount,
            critical: criticalCount,
            highCriticalAssetsCount: riskAssetsCount,
            totalAssets: riskAssetsCount ? (totalAssets ?? 0) : undefined,
        }),
    };
};

const EMPTY_TOTAL_WIDGET_INFO = () => {
    return {
        widgetData: createTotalWidgetData(),
        loadingState: LoadingState.IS_LOADING,
    };
};

const EMPTY_TOTAL_SEARCH_RESULTS = () => {
    return {
        response: undefined,
        loadingState: LoadingState.IS_LOADING,
    };
};

const RiskDashboard: React.FC = () => {
    const [totalWidgetInfo, setTotalWidgetInfo] = useState<IAssetWidgetInfo>(EMPTY_TOTAL_WIDGET_INFO());
    const [totalSearchResults, setTotalSearchResults] = useState<ITotalSearchResults>(EMPTY_TOTAL_SEARCH_RESULTS());
    const [filterValues, setFilterValues] = useState<IFiltersValues | undefined>();

    const preLoadingData = useCallback(() => {
        setTotalWidgetInfo(EMPTY_TOTAL_WIDGET_INFO());
        setTotalSearchResults(EMPTY_TOTAL_SEARCH_RESULTS());
    }, []);

    const getDashboardAggregations = useCallback((newFilterValues: IFiltersValues): Promise<Aggregations> => {
        setFilterValues(newFilterValues || []);
        preLoadingData();

        return getTotalResponse(newFilterValues).then(response => {
            setTotalWidgetInfo({
                widgetData: createTotalWidgetData(response?.aggregations?.riskScore, response?.totalCount, newFilterValues),
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
            setTotalSearchResults({
                response,
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
            return response?.aggregations || {};
        }).catch(() => {
            setTotalWidgetInfo({
                widgetData: createTotalWidgetData(),
                loadingState: LoadingState.LOADING_FAILED,
            });
            setTotalSearchResults({
                response: undefined,
                loadingState: LoadingState.LOADING_FAILED,
            });
            return {};
        });
    }, [preLoadingData]);


    return (
        <RiskDashboardStyled.TopDiv data-aid='risk-dashboard'>
            <DashboardFilterPanel filterId={'risk-dashboard-filter-panel'} getAggregations={getDashboardAggregations} filterTypes={riskDashboardFilters} />
            <SecurityIssues filterValues={filterValues} />
            <HighRiskAssets filterValues={filterValues} totalWidgetInfo={totalWidgetInfo} />
            <RiskiestEntities filterValues={filterValues} totalSearchResults={totalSearchResults} />
            {ermPermissionFn(WAF_PROTECTION_ACTIVE_FEATURE.key) && <WAF filterValues={filterValues}/>}
        </RiskDashboardStyled.TopDiv>
    );
};

export default RiskDashboard;
