
import { GenericObject } from 'common/interface/general';
import { isErmRulesetId } from './Ruleset.actions';
import { IERMRuleset } from './Rulesets.interface';


interface IRulesetReducer {
    viewRulesets: IERMRuleset[],
    rulesetsByPlatform: GenericObject<IERMRuleset[]>
}

export const initialState : IRulesetReducer = {
    viewRulesets: [],
    rulesetsByPlatform: {}
};

export const ACTIONS_KEYS = {
    SET_VIEW_RULESETS: 'set-view-ruleset',
    SET_RULESETS_BY_PLATFORM: 'set-ruleset-by-platform',
    
};

export function reducer(state: IRulesetReducer, action: any) {
    switch (action.type) {
        case ACTIONS_KEYS.SET_VIEW_RULESETS:
            return { ...state, viewRulesets: action.payload };

        case ACTIONS_KEYS.SET_RULESETS_BY_PLATFORM: {
            const rulesetsByPlatform = action.payload;
            for ( const platfrom in rulesetsByPlatform) {
                rulesetsByPlatform[platfrom] = rulesetsByPlatform[platfrom].reduce((acc: IERMRuleset[],ruleset: IERMRuleset) => {
                    if(ruleset.isAllOption){
                        acc.unshift(ruleset);
                    } else if(isErmRulesetId(ruleset.id)) {
                        acc.splice(1, 0, ruleset);
                    } else if ( ruleset.isSelected) {
                        acc.splice(2, 0, ruleset);
                    } else {
                        acc.push(ruleset);
                    }
                    return acc;
                },[]); 
            }
            return { ...state, rulesetsByPlatform };
        }
        default:
            return state;
    }
}