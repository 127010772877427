/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclusionsService } from 'common/interface/services';
import { GroupSelection, Input, Stack, SelectAsync } from 'common/design-system/components-v2';
import { entityTypes, I18nExclusion } from '../../helpers/exclusions.consts';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { EntityTypesEnum, ExclusionEntityInputProps } from '../../helpers/exclusions.interfaces';
import { determineSearchEntityOption } from '../../helpers/exclusions.utils';

enum GSL_TEXT {
    NAME = 'name like',
    ID = 'id like'
}

export const ExclusionByEntity: FC<ExclusionEntityInputProps> = ({ isEnabled, selectedRuleset, onChange, selectedOption, formValidations, helperText, filterFields, handleGsl, isSelectionGroup, limitSelection = 10 }) => {
    const { t } = useTranslation(I18nExclusion);
    const [entityType, setEntityType] = useState<EntityTypesEnum>(selectedOption && selectedOption.includes(GSL_TEXT.ID) ? EntityTypesEnum.byID : EntityTypesEnum.byName);
    const extractInitialText = (options?: string[]): string[] => {
        if (!options) return [];
        const extractedTexts: string[] = [];
        for (const option of options) {
            const match = option.match(/(name|id|scannedAsset.entityName) like '([^']+)'/);
            if (match) {
                extractedTexts.push(match[2]);
            }
        }
        return extractedTexts;
    };
    const [inputText, setInputText] = useState<string[]>(() => selectedOption ? extractInitialText(selectedOption) : []);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [selectedOptionsList, setSelectedOptionsList] = useState<SelectOption[]>([]);

    const handleSelectedOptions = (inputText: string[] = []) => {
        const selectedOptions = inputText.map((text) => ({ value: text, label: text }));
        setSelectedOptionsList(selectedOptions);
        setSelectedOptions(inputText);
    };

    useEffect(() => {
        onChange(inputText ? generateGslString(inputText) : null);
        handleSelectedOptions(inputText);
    }, [selectedRuleset, inputText]);

    const translateTextToGSL = (text: string): string => {
        if (handleGsl) return handleGsl(text);
        return entityType === EntityTypesEnum.byName ? `${GSL_TEXT.NAME} '${text}'` : `${GSL_TEXT.ID} '${text}'`;
    };

    const generateGslString = (strings: string[]): string => {
        return strings.map((text) => translateTextToGSL(text)).join(' or ');
    };

    const onInputChange = (strings: string[]) => {
        setInputText(strings);
    };

    const onEntityTypeChange = (newValue: EntityTypesEnum) => {
        setEntityType(newValue);
        onInputChange([]);
    };

    const handleIsOptionDisabled = (option: any, selectedOptions: any[]) => {
        const maxSelectedOption = limitSelection;
        const isOptionSelected = selectedOptions.some(selectedOption => selectedOption.value === option.value);
        if (isOptionSelected) return false;
        return selectedOptions.length >= maxSelectedOption;
    };

    const handleLoadOptions = async (inputValue: string): Promise<SelectOption[]> => {
        let optionResults: SelectOption[] = [];
        if (inputValue && inputValue.includes('%')) {
            const label = determineSearchEntityOption(inputValue);
            label ? optionResults.push({ value: inputValue, label: label || '' }) : optionResults = [];
            return Promise.resolve(optionResults);
        }
        const { assets, totalCount } = await ExclusionsService().getProtectedAssets(inputValue, filterFields);
        const uniqueAssetsMap = new Map(assets.map((current: any) => [current.name, {
            value: current.name,
            label: current.name
        }]));
        optionResults = Array.from(uniqueAssetsMap.values()) as SelectOption[];
        if (totalCount > 100) {
            optionResults.unshift({
                itemType: 'text',
                value: t('MODAL.TOPICS.ENTITY.FIRST_100_RESULTS_ARE_SHOWN'),
                label: t('MODAL.TOPICS.ENTITY.FIRST_100_RESULTS_ARE_SHOWN')
            });
        }
        return optionResults;
    };

    return (
        <Stack>
            { isSelectionGroup && <GroupSelection label={t('MODAL.TOPICS.ENTITY.TITLE')} direction="row" onChange={(value) => onEntityTypeChange(value as EntityTypesEnum)} value={entityType} options={entityTypes(t)}/> }
            { entityType === EntityTypesEnum.byName ? (
                <SelectAsync
                    disabled={!isEnabled}
                    helperText={helperText}
                    label={!isSelectionGroup ? t('MODAL.TOPICS.ENTITY.TITLE') : undefined}
                    placeholder={t('MODAL.TOPICS.ENTITY.SEARCH_ENTITY')}
                    fullWidth
                    clearable
                    creatable
                    options={selectedOptionsList}
                    value={selectedOptions}
                    onChange={onInputChange}
                    isOptionDisabled={handleIsOptionDisabled}
                    loadOptions={handleLoadOptions}
                />
            ) : (
                <Input
                    disabled={!isEnabled}
                    data-aid='exclutsion-id-input'
                    placeholder={t('MODAL.TOPICS.ENTITY.TYPE_AN_ENTITY_ID')}
                    value={inputText[0]}
                    onChange={(event) => onInputChange([event.target.value])}
                    fullWidth
                    clearable
                    isError={!!formValidations}
                    helperText={formValidations?.[0]?.message}
                />
            )}
        </Stack>
    );
};
export default ExclusionByEntity;