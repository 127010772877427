import styled from 'styled-components';

const SpinnerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 60px;
  padding-bottom: 20px;
  width: 100%;
`;

export const PerimeterExpStyled = {
    SpinnerDiv
};
