import styled from 'styled-components';

const Body = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

const Icon = styled.div`
  display: flex;
  align-self: flex-start;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.div`
    display: flex;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 48px;
    margin-right: 10px;
`;

export const IntelligenceNodePopoverStyled = {
    Body,
    Icon,
    Header,
    Title,
    Details
};
