import i18n from 'i18next';
import React, { useState } from 'react';
import { Button, Icon, Popover } from '@dome9/berries/react-components';
import { getUserService } from 'common/interface/services';
import AiDisclaimer from '../../AiDisclaimer/Components/AiDisclaimer';
import { InsightsDisclaimerModal } from './InsightsDisclaimerModal';
import { insightNamespace } from '../../consts/i18n';
import { BorderContainer } from './InsightsDisclaimer.styled';

const InsightsDisclaimer: React.FC<{ setApproved: (approved: boolean) => void }> = (props: {
    setApproved: (approved: boolean) => void
}) => {
    const { setApproved } = props;
    const hiddenSave = localStorage.getItem(`insightshidden.${getUserService().getUser().id}`);
    const [hidden, setHidden] = useState<boolean | undefined>(hiddenSave === 'true');
    const [showModal, setShowModal] = useState(false);

    return hidden ?

        <BorderContainer className={'text-lg px-9 pb-9'}>
            <div className={'fat-border'}>
                <div className={'fat-border-child flex items-center text-lg flex-center-space-between px-8 py-6'}>
                    <div className={'flex items-center gap-6 z-10'}>
                        <Icon name={'shine-32'} size={24} />

                        <span>
                            {i18n.t('INSIGHTS.DISCLAIMER.HIDDEN_TITLE', { ns: insightNamespace })}
                        </span>
                        <Popover content={AiDisclaimer({})} zIndex={99999} maxWidth={600} placement={'right'}>
                            <div className={'flex'}><Icon name={'info'} size={10} /></div>
                        </Popover>
                    </div>

                    <Button
                        className={'btn--integrated p-[0] expander'}
                        iconSize={24}
                        onClick={() => {
                            localStorage.setItem(`insightshidden.${getUserService().getUser().id}`, 'false');
                            setHidden(false);
                        }}>
                        <Icon name={'plus'} size={24} />
                    </Button>
                </div>
            </div>
        </BorderContainer> :

        <BorderContainer className={'px-9 pb-7'}>
            <InsightsDisclaimerModal setApproved={setApproved} isOpen={showModal}
                closeModal={() => setShowModal(false)} />
            <div className='border-solid border-b w-[100%]'></div>

            <div className={'fat-border my-9'}>
                <div className={'flex-column flex-center py-9 fat-border-child'}>
                    <Button
                        className={'btn--integrated p-[0] collapser'}
                        iconSize={20}
                        onClick={() => {
                            localStorage.setItem(`insightshidden.${getUserService().getUser().id}`, 'false');
                            setHidden(true);
                        }}>
                        <Icon name={'minus'} size={20} />
                    </Button>
                    <Icon name={'shine-32'} size={32} />

                    <div className={'text-lg font-bold my-7'}>
                        {i18n.t('INSIGHTS.DISCLAIMER.TITLE', { ns: insightNamespace })}
                    </div>

                    <ul className={'list-disc'}>
                        <li>
                            {i18n.t('INSIGHTS.DISCLAIMER.BULLET_1', { ns: insightNamespace })}
                        </li>
                        <li>
                            {i18n.t('INSIGHTS.DISCLAIMER.BULLET_2', { ns: insightNamespace })}
                        </li>
                    </ul>

                    <div className={'flex gap-7 my-9'}>
                        <Button
                            className='btn--integrated'
                            onClick={() => {
                                localStorage.setItem(`insightshidden.${getUserService().getUser().id}`, 'true');
                                setHidden(true);
                            }}>
                            {i18n.t('INSIGHTS.DISCLAIMER.LATER', { ns: insightNamespace })}
                        </Button>

                        <Button
                            color={'primary'}
                            onClick={() => {
                                setShowModal(true);
                            }}>
                            {i18n.t('INSIGHTS.DISCLAIMER.TURN_ON', { ns: insightNamespace })}
                        </Button>
                    </div>

                    <div>
                        <span className={'link'}>
                            <a target='_blank' rel='noreferrer'
                                href={'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Overview/CloudGuard-CSPM-Introduction.htm'}>
                                {i18n.t('INSIGHTS.DISCLAIMER.DOC', { ns: insightNamespace })}
                            </a>
                        </span>
                    </div>
                </div>
            </div>

            <div className='border-solid border-b w-[100%]'></div>
        </BorderContainer>;
};

export default InsightsDisclaimer;

