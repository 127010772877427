import styled from 'styled-components/macro';
import { Stack } from 'common/design-system/components-v2';

export const TopDiv = styled(Stack)`
  width: 100%;
`;

export const FilterStyled = {
    TopDiv,
};
