import {
    checkConditionsValidation,
    getOtherExclusionNames,
} from './IssueExclusionEditor.validations';
import {
    ISSUE_EXCLUSION_EDITOR_KEY,
    IssueExclusionItemNames,
    SimpleItemName,
} from './IssueExclusionEditor.consts';
import {
    getIssueExclusionSubmitFailedNotification,
    getIssueExclusionSubmitSuccessNotification,
    saveIssueExclusion,
} from './IssueExclusionEditor.datasource';
import { ermTrans } from '../../../RiskManagement.utils';
import { IIssueExclusionProps } from '../IssueExclusions.interface';
import { ISSUE_EXCLUSIONS_PAGE_TABLE_ID } from '../IssueExclusions.consts';
import {
    commonDateRangeFieldItemProps,
    commonDividerItemProps,
    commonInputItemProps, commonLabelItemProps,
    commonLazySelectFieldItemProps,
    commonMultiSelectFieldItemProps,
    commonOrgUnitsFieldItemProps, commonRadioItemProps,
    commonTagsFieldItemProps, commonTextAreaItemProps,
    FormItemType,
} from 'common/erm-components/custom/CustomForm/CustomForm.consts';
import {
    ICustomFormProps,
    IItem,
    IRadioItem,
} from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { severityLevelsToKeys } from './IssueExclusionEditor.values';
import { convertDatesToValues, convertTagsToValues } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import {
    getAllEntityTypeOptions, getAllEnvironmentOptions, getAllRulesOptions,
    getAllSeverityOptions,
    getEntityNamesOptions, getOrgUnitsRoot,
} from '../../../RiskManagement.options';

const getOrgEnvRadio = async (issueExclusion?: IIssueExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: IssueExclusionItemNames.orgEnvRadio,
    state: {},
    options: [
        {
            name: IssueExclusionItemNames.orgUnitsRadioOption,
            subItems: [{
                ...commonOrgUnitsFieldItemProps,
                name: IssueExclusionItemNames.orgUnits,
                state: {
                    label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ORGANIZATIONAL_UNIT'),
                    orgUnitsRoot: await getOrgUnitsRoot(),
                    value: issueExclusion?.orgUnitIds,
                    tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ORG_UNITS'),
                },
            }],
        },
        {
            name: IssueExclusionItemNames.environmentsRadioOption,
            subItems: [{
                ...commonMultiSelectFieldItemProps,
                name: IssueExclusionItemNames.environments,
                state: {
                    label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENVIRONMENT'),
                    options: await getAllEnvironmentOptions(),
                    value: issueExclusion?.envIds,
                    tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ENVIRONMENTS'),
                },
            }],
        },
    ],
});

const getRuleRadio = async (issueExclusion?: IIssueExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: IssueExclusionItemNames.rulesRadio,
    state: {},
    options: [
        {
            name: IssueExclusionItemNames.rulesRadioOption,
            subItems: [{
                ...commonMultiSelectFieldItemProps,
                name: IssueExclusionItemNames.rules,
                state: {
                    label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.RULE'),
                    options: await getAllRulesOptions(),
                    value: issueExclusion?.ruleIds,
                    tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.RULES'),
                },
            }],
        },
        {
            name: IssueExclusionItemNames.severitiesRadioOption,
            subItems: [{
                ...commonMultiSelectFieldItemProps,
                name: IssueExclusionItemNames.severities,
                state: {
                    label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.SEVERITY'),
                    options: getAllSeverityOptions(),
                    value: severityLevelsToKeys(issueExclusion?.severities),
                    tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.SEVERITIES'),
                },
            }],
        },
    ],
});

const getEntityRadio = async (issueExclusion?: IIssueExclusionProps): Promise<IRadioItem> => ({
    ...commonRadioItemProps,
    itemType: FormItemType.radio,
    name: IssueExclusionItemNames.entityRadio,
    state: {},
    options: [
        {
            name: IssueExclusionItemNames.entityNamesRadioOption,
            subItems: [{
                ...commonLazySelectFieldItemProps,
                name: IssueExclusionItemNames.entityNames,
                getLazyOptions: getEntityNamesOptions,
                state: {
                    label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENTITY_NAME'),
                    value: issueExclusion?.entityNames,
                    tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ENTITY_NAMES'),
                },
            }],
        },
        {
            name: IssueExclusionItemNames.entityIdRadioOption,
            subItems: [{
                ...commonInputItemProps,
                name: IssueExclusionItemNames.entityId,
                state: {
                    label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENTITY_ID'),
                    value: issueExclusion?.entityId,
                    tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ENTITY_ID'),
                },
            }],
        },
    ],
});

const getItems = async (issueExclusion?: IIssueExclusionProps): Promise<IItem[]> => [
    {
        ...commonInputItemProps,
        name: IssueExclusionItemNames.name,
        state: {
            isRequired: true,
            charsLimit: 100,
            label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.NAME'),
            forbiddenOtherValues: await getOtherExclusionNames(issueExclusion), // @@@
            value: issueExclusion?.name,
        },
    },
    {
        ...commonTextAreaItemProps,
        name: IssueExclusionItemNames.description,
        state: {
            charsLimit: 200,
            label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.DESCRIPTION'),
            value: issueExclusion?.description,
        },
    },
    {
        ...commonDividerItemProps,
        name: SimpleItemName.conditionsAreaDivider,
        state: {},
    },
    {
        ...commonLabelItemProps,
        name: SimpleItemName.conditionsAreaLabel,
        checkValidation: checkConditionsValidation,
        state: {
            label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.CONDITIONS'),
            isRequired: true,
        },
    },
    await getRuleRadio(issueExclusion),
    await getOrgEnvRadio(issueExclusion),
    await getEntityRadio(issueExclusion),
    {
        ...commonMultiSelectFieldItemProps,
        name: IssueExclusionItemNames.entityTypes,
        state: {
            label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENTITY_TYPE'),
            options: await getAllEntityTypeOptions(),
            value: issueExclusion?.entityTypes,
            tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.ENTITY_TYPE'),
        },
    },
    {
        ...commonDateRangeFieldItemProps,
        name: IssueExclusionItemNames.dateRange,
        state: {
            label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.DATE_RANGE'),
            value: issueExclusion?.dateRange ? convertDatesToValues(issueExclusion.dateRange) : [],
            tooltip: ermTrans('ISSUE_EXCLUSIONS.EDITOR.TOOLTIP.DATE_RANGE'),
        },
    },
    {
        ...commonTagsFieldItemProps,
        name: IssueExclusionItemNames.tags,
        state: {
            label: ermTrans('ISSUE_EXCLUSIONS.EDITOR.ENTITY_TAGS'),
            value: convertTagsToValues(issueExclusion?.tags),
        },
    },
];

export const getCdrExclusionFormProps = (issueExclusion?: IIssueExclusionProps): ICustomFormProps => {
    return {
        getItems,
        submitData: saveIssueExclusion,
        getSubmitSuccessNotification: getIssueExclusionSubmitSuccessNotification,
        getSubmitFailedNotification: getIssueExclusionSubmitFailedNotification,
        title: issueExclusion?.id ? issueExclusion.name : ermTrans('ISSUE_EXCLUSIONS.EDITOR.ADD_ISSUE_EXCLUSION'),
        iconProps: { name: 'exclude' },
        minDialogHeight: 300,
        eventHandlerId: ISSUE_EXCLUSIONS_PAGE_TABLE_ID,
    };
};

export const showIssueExclusionForm = (issueExclusion?: IIssueExclusionProps) => {
    showDrawer(ISSUE_EXCLUSION_EDITOR_KEY, issueExclusion);
};
