import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal } from '@dome9/berries/react-components';
import {
    abortAllRunAssessments,
    getRunAssessmentByRuleAndCloudAccount
} from './AssessmentTableDataFetcher';
import {
    ICloudAccount,
} from 'common/interface/data_services';
import { Rule } from 'common/interface/RulesManagement';
import { getService } from 'common/extensibility/AddinContainer';
import RuleManageService from '../RulesManagement/services/RuleManagement.service';
import { RULES_MANAGEMENT_SERVICE_ID } from 'common/interface/services';
import { VENDORS } from 'common/consts/vendors';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'common/design-system/components-v2';


export interface IRunAssessmentModalProps {
    isOpen: boolean;
    onCancel: () => void;
    runAssessmentData: IRunAssessmentData
}

export interface IRunAssessmentData {
    ouPath?: string[];
    cloudAccountId?: string;
    cloudVendor?: string;
    platform?:string;
    rulesetId: number;
    rulesetName?: string;
    cloudAccounts?: ICloudAccount[];
}

export const RunAssessmentModal: React.FC<IRunAssessmentModalProps> = ({ isOpen, onCancel, runAssessmentData }) => {

    const { t } = useTranslation('posture');
    const [filteredRuleset, setFilteredRuleset] = useState<Rule>({} as Rule);
    const [cloudAccountNames, setCloudAccountNames] = useState<string[]>([]);
    const [successAccountsCount, setSuccessAccountsCount] = useState<number>(0);
    const [successIndex, setSuccessIndex] = useState<number[]>([]);
    const [isAbortButton, setIsAbortButton] = useState<boolean>(false);
    const [hideAbortButton, setHideAbortButton] = useState<boolean>(false);
    const { ouPath, cloudAccountId, platform, rulesetId, rulesetName, cloudAccounts } = runAssessmentData;


    const getFilteredRuleset = async () => {
        const rulesets: Rule[] = await getService<RuleManageService>(RULES_MANAGEMENT_SERVICE_ID).getRulesetsViewsFromComplianceServer();
        const filterRuleset = rulesets.filter(ruleset => ruleset.id === rulesetId)[0];
        setFilteredRuleset(filterRuleset);
    };

    const getRunAssessmentData = async (): Promise<any> => {

        setHideAbortButton(false);
        setIsAbortButton(true);
        setSuccessAccountsCount(0);
        let successAccountsCountLocal = 0;
        const successIndex: number[] = [];
        if(cloudAccountId){
            const vendor = VENDORS.find(vendor => vendor.name === platform);
            return getRunAssessmentByRuleAndCloudAccount(filteredRuleset.id, cloudAccountId, vendor!.assessmentVendorType, filteredRuleset.description, v4(), filteredRuleset.name)
                .then(() => setSuccessAccountsCount(1))
                .catch(error => console.info(error.message));
        } else {
            cloudAccounts?.map(cloudAccount => {
                const vendor = VENDORS.find(vendor => vendor.name === cloudAccount.platform);
                return getRunAssessmentByRuleAndCloudAccount(filteredRuleset.id, cloudAccount.id, vendor!.assessmentVendorType, filteredRuleset.description, v4(), filteredRuleset.name)
                    .then(() => {
                        successAccountsCountLocal = successAccountsCountLocal + 1;
                        setSuccessAccountsCount(successAccountsCountLocal);
                        successIndex.push(cloudAccountNames.indexOf(cloudAccount.name));
                        setSuccessIndex(successIndex);
                        cloudAccounts?.length === successAccountsCountLocal && setHideAbortButton(true);
                    })
                    .catch(error => console.info(error.message));

            });
        }
    };
    
    useEffect(() => {
        getFilteredRuleset();
        setCloudAccountNames(cloudAccounts?.map(cloudAccount => cloudAccount.name) || []);
    }, [rulesetId, cloudAccountId, platform, ouPath]);

    const cancelModal = () => {
        abortAllRunAssessments();
        onCancel();
        setIsAbortButton(false);
        setSuccessIndex([]);
    };

    return (
        <Modal.ModalDialog isOpen={isOpen} width={'lg'}>
            <Modal.ModalHeader title={t('WIDGETS.GRID.RUN_ASSESSMENT.TITLE')} onClose={onCancel}/>
            <Modal.ModalContent>
                {isAbortButton ? <div>
                    <div className={'text-lg'}>
                            ASSESSMENT IN PROGRESS:
                    </div>
                    <div className={'text-[#18ad3f] text-sm'}>{t('WIDGETS.GRID.RUN_ASSESSMENT.SUCCESS', { successCount: successAccountsCount, totalAssessments: cloudAccounts?.length })}</div>
                    <div className={'overflow-x-hidden h-96 mt-6'}>
                        {isAbortButton && cloudAccounts?.map((cloudAccount, index) => {
                            return (
                                <div className={'flex justify-between'} key={cloudAccount.id}>
                                    <div>
                                        {t('WIDGETS.GRID.RUN_ASSESSMENT.RULESET_RUN', { rulesetName: rulesetName, cloudAccountName: cloudAccountNames[index] })}
                                    </div>
                                    {!successIndex.includes(index) &&
                                        <div className={'m-0'}>
                                            <Spinner
                                                key={index}/>
                                        </div>
                                    }
                                    {successIndex.includes(index) &&
                                            <Icon name={'ok'} key={index}/>}
                                </div>
                            );
                        })
                        }
                    </div>
                </div> : <div>{t('WIDGETS.GRID.RUN_ASSESSMENT.CONFIRMATION_MESSAGE', { rulesetName: rulesetName, cloudAccountsCount: cloudAccounts?.length })}</div>}
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <div className='modal__footer-actions'>
                    <Button onClick={() => cancelModal()}>{t('WIDGETS.GRID.RUN_ASSESSMENT.BUTTONS.CANCEL')}</Button>
                    {!isAbortButton && <Button onClick={() => getRunAssessmentData()} color='primary'>{t('WIDGETS.GRID.RUN_ASSESSMENT.BUTTONS.SUBMIT')}</Button>}
                    {isAbortButton && !hideAbortButton && <Button onClick={() => cancelModal()} color='primary'>{t('WIDGETS.GRID.RUN_ASSESSMENT.BUTTONS.ABORT')}</Button>}
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default RunAssessmentModal;