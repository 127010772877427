import i18next, { TFunction } from 'i18next';
import { getLoggerService, getStoreService } from 'common/interface/services';
import { getCloudAccountsService, IIntelligenceCloudAccount } from 'common/interface/data_services';
import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';
import {
    goToBackStep,
    goToNextStep,
    goToSpecificStep,
    setAllStorages,
    setAllSubscriptions,
    setARMTemplateDetails,
    setIpAddress,
    setNextButtonDisabled, setNSGStorages,
    setOnboardingStatus,
    setSelectedSubscriptions,
    toggleIsLoading,
} from './AzureIntelligenceOnboarding.reducer';
import {
    discoverStorages,
    getAccountActivityARMTemplate,
    getAzureResourceGroupsForSubscription,
    getAzureStorageById,
    getCredentialsServicePrincipalId,
    getIntelligenceNATIP,
    getNetworkTrafficARMTemplate, getNSGARMTemplate,
    getOnboardedStorageData,
    getReportingSubscriptionsData,
    onboardAzureAccountActivity,
    onboardAzureNetworkTraffic
} from './Services/AzureIntelligenceOnboardingService';
import {
    IAccountActivityARMTemplateRequestModel,
    IAllStoragesModel, IARMTemplateResponseModel, IAzureAddStorageModel, IAzureAddStorageResponseModel, IAzureIntelligenceOnboardingState, IAzureResourceGroupsModel,
    IJsonData,
    ILogTypesModel,
    INetworkTrafficARMTemplateRequestModel, INsgARMTemplateRequestModel, INsgForArmTemplate, INsgJsonData,
    IOnboardedStoragesModel,
    IOnboardingAccountActivityStorages,
    IOnboardingNetworkTrafficStorages,
    IReportingSubscriptionsModel,
    IRgsFromMagellanModel,
    IStorageLogTypeModel,
    IStorageModel
} from './AzureIntelligenceOnboarding.interface';
import { AssetType, AzureIntelligenceOnboardingType, LogType } from './AzureIntelligenceOnboarding.const';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';

export const goToStep = async (forward: boolean) => {
    const { dispatch, state } = getStoreService().getReduxTools();
    const t = i18next.getFixedT(null, i18nIntelligenceNamespace, 'AZURE_ONBOARDING');
    const { user } = state.user;
    const userAccountId = user?.accountId ?? '';
    const {
        step,
        subscriptionId,
        cloudAccountId,
        azureIntelligenceType,
        allStorages,
        nsgStorages,
        selectedSubscriptions,
        ipAddress,
        azureOnboardingType
    } = state.azureIntelligenceOnboarding as IAzureIntelligenceOnboardingState;

    const selectedStorages = allStorages?.filter(storage => storage.isSelected) ?? [];

    const isAccountActivity = azureIntelligenceType === AssetType.activityLogs;
    const isCentralizedOnboarding = azureOnboardingType === AzureIntelligenceOnboardingType.centralized;
    const skipARMTemplateStep = skipARMTemplate({ isCentralizedOnboarding, isAccountActivity, selectedStorages });

    if (forward) {
        switch (step) {
            case 0: {
                dispatch(setNextButtonDisabled(true));
                if (!isCentralizedOnboarding) {
                    dispatch(toggleIsLoading(true));
                    await fetchStorages();
                    dispatch(toggleIsLoading(false));
                }
                dispatch(goToNextStep());
                break;
            }

            case 1: {
                await handleSubscriptions({ 
                    skipARMTemplateStep, 
                    selectedStorages, 
                    subscriptionId, 
                    isAccountActivity, 
                    isCentralizedOnboarding, 
                    cloudAccountId, 
                    ipAddress, 
                    allStorages, 
                    nsgStorages 
                });
                break;
            }

            case 2: {
                if (!isCentralizedOnboarding) {
                    onboardAzureIntelligence({
                        selectedStorages,
                        allStorages,
                        isAccountActivity,
                        isCentralizedOnboarding,
                        subscriptionId,
                        t,
                        userAccountId,
                        selectedSubscriptions
                    });
                } else {
                    dispatch(setNextButtonDisabled(true));
                }
                dispatch(goToNextStep());
                break;
            }

            case 3: {
                await handleSubscriptionsStep({ 
                    ipAddress, 
                    isCentralizedOnboarding, 
                    isAccountActivity, 
                    skipARMTemplateStep, 
                    selectedStorages, 
                    subscriptionId 
                });
                break;
            }

            case 4: {
                if (isCentralizedOnboarding) {
                    onboardAzureIntelligence({ 
                        selectedStorages, 
                        allStorages, 
                        isAccountActivity, 
                        isCentralizedOnboarding, 
                        subscriptionId, 
                        t, 
                        userAccountId, 
                        selectedSubscriptions 
                    });
                }
                dispatch(goToNextStep());
                break;
            }

        }
    } else {
        if (skipARMTemplateStep && step === 3) {
            dispatch(goToSpecificStep(1));
        } else {
            dispatch(goToBackStep());
        }
        dispatch(setNextButtonDisabled(false));
    }
};

function skipARMTemplate({ 
    isCentralizedOnboarding, 
    isAccountActivity, 
    selectedStorages 
}: { 
    isCentralizedOnboarding: boolean, 
    isAccountActivity: boolean, 
    selectedStorages: IStorageModel[] | null 
}) {
    if (!selectedStorages?.length) return true;

    if (isCentralizedOnboarding) {
        return selectedStorages.every((item: IStorageModel) => item.logTypesFound.every(type => !type.hasStorageKey) && (item.status === ConnectionStatus.connected || (item.status === ConnectionStatus.partiallyConnected && (!item.isCentralized || (isAccountActivity && !item.logsType?.length)))));
    } else {
        return selectedStorages.every((item: IStorageModel) => item.status === ConnectionStatus.connected && !item.hasStorageKey);
    }
}

function getOnboardedStatus(storage: IOnboardedStoragesModel, allStorages: IAllStoragesModel[], isAccountActivity: boolean) {
    const discoveredLogTypesList = allStorages?.find(row => row.name === storage.storageName)?.logTypesFound;
    const isEventSubscriptionEmpty = discoveredLogTypesList?.find(item => (isAccountActivity ? item.logType.toLowerCase() === storage.logsType?.toLowerCase() : true) && !item.eventSubscriptionNames?.length);

    if ((storage.logsType !== LogType.storageAccounts && isEventSubscriptionEmpty) || !storage.status) {
        return ConnectionStatus.notConnected;
    } else if ((!isAccountActivity || storage.logsType === LogType.activityLogs) && !storage.isCentralized) {
        return ConnectionStatus.partiallyConnected;
    } else {
        return ConnectionStatus.connected;
    }
}

async function handleSubscriptionsStep({
    ipAddress,
    isCentralizedOnboarding,
    isAccountActivity,
    skipARMTemplateStep,
    selectedStorages,
    subscriptionId
}: {
    ipAddress: string, 
    isCentralizedOnboarding: boolean, 
    isAccountActivity: boolean, 
    skipARMTemplateStep: boolean, 
    selectedStorages: IStorageModel[] | null, 
    subscriptionId: string, 
}) {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(toggleIsLoading(true));
    try {
        if (!ipAddress) {
            const intelligenceNATIP = await getIntelligenceNATIP();
            if (intelligenceNATIP?.length) dispatch(setIpAddress(intelligenceNATIP.toString()));
        }
        if (isCentralizedOnboarding) {
            const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts(false);
            const magellanCloudAccounts: IIntelligenceCloudAccount[] = await getIntelligenceService().getIntelligenceCloudAccounts(false);
            const storages: IStorageLogTypeModel[] = [];

            selectedStorages?.forEach((storage: IStorageModel) => {
                storages.push({
                    storageName: storage.storageName,
                    logTypes: isAccountActivity ? [LogType.activityLogs] : [LogType.flowLogs]
                });
            });

            const subscriptionsRequest = {
                subscriptionId: subscriptionId,
                storages: storages
            };

            const subscriptionsResponse = await getReportingSubscriptionsData(subscriptionsRequest);
            const subscriptions = subscriptionsResponse?.subscriptionIds ?? [];
            const subscriptionDetails: IReportingSubscriptionsModel[] = [];

            if (!subscriptions.find(subscription => subscription === subscriptionId)) {
                subscriptions.push(subscriptionId);
            }

            subscriptions.forEach(subscription => {
                const account = allCloudAccounts?.find(account => account.externalId === subscription);
                const magellanAccount = magellanCloudAccounts?.find(account => account.cloudAccountId === subscription);

                if (!account) {
                    subscriptionDetails.push({
                        subscriptionId: subscription,
                        name: '',
                        status: ConnectionStatus.canNotConnect
                    });
                } else {
                    let status = ConnectionStatus.notConnected;
                    if (magellanAccount) {
                        const connectedToAccountActivity = isAccountActivity && account.isLogicEventActivityOnboarded && magellanAccount.isAzureActivityEnabled;
                        const connectedToNetworkTraffic = !isAccountActivity && account.isLogicTrafficActivityOnboarded && magellanAccount.isAzureFlEnabled;
                        if (connectedToAccountActivity || connectedToNetworkTraffic) status = ConnectionStatus.connected;
                    }
                    subscriptionDetails.push({
                        subscriptionId: subscription,
                        name: account.name,
                        status: status
                    });
                }
            });

            dispatch(setAllSubscriptions(subscriptionDetails));
            dispatch(setSelectedSubscriptions([]));
            dispatch(setNextButtonDisabled(false));
        } else {
            // If NSG Onboarding skip subscriptions
            dispatch(setNextButtonDisabled(true));
        }
        if (skipARMTemplateStep) {
            // Skip arm if all log types in storage are onboarded & don't have storage key
            dispatch(goToSpecificStep(3));
        } else {
            dispatch(goToNextStep());
        }
    } finally {
        dispatch(toggleIsLoading(false));
    }
}

function onboardAzureIntelligence({
    selectedStorages,
    allStorages,
    isAccountActivity,
    isCentralizedOnboarding,
    subscriptionId,
    t,
    userAccountId,
    selectedSubscriptions = []
}: {
    selectedStorages: IStorageModel[] | null, 
    allStorages: IStorageModel[] | null, 
    isAccountActivity: boolean, 
    isCentralizedOnboarding: boolean, 
    subscriptionId: string, 
    t: TFunction, 
    userAccountId: string, 
    selectedSubscriptions: IReportingSubscriptionsModel[] | null
}) {
    const { dispatch } = getStoreService().getReduxTools();
    const accountId = `${t('ACCOUNT_ID')}: ${userAccountId}`;
    const subscription = `${t('SUBSCRIPTIONS.SUBSCRIPTION_ID')}: ${subscriptionId}`;
    const onboardingType = isAccountActivity ? t('LOGGER_TAGS.ACCOUNT_ACTIVITY') : isCentralizedOnboarding ? t('LOGGER_TAGS.NETWORK_TRAFFIC') : t('LOGGER_TAGS.NSG');

    let apiDetails = `${accountId} ${subscription} ${t('ONBOARDING_TYPE')}: ${onboardingType} ${t('STORAGES')}: `;

    dispatch(toggleIsLoading(true));

    if (isAccountActivity) {
        const accountActivityStorages: IOnboardingAccountActivityStorages[] = [];

        selectedStorages?.forEach((storage: IStorageModel) => {
            const storageItem = allStorages?.find((item: IStorageModel) => item.storageName === storage.storageName);
            const logTypes: string[] = [];

            storageItem?.logTypesFound.forEach((type: ILogTypesModel) => {
                if (type.logTypeStatus === ConnectionStatus.connected) {
                    logTypes.push(type.logType);
                }
            });
            accountActivityStorages.push({
                StorageName: storage.storageName,
                IsAutoDiscoveryEnabled: storage.isAutoDiscoveryEnabled,
                ResourceGroup: storage.resourceGroupName,
                LogTypes: (logTypes.concat(storage.logsType ?? [])),
                StorageKey: ''
            });

        });
        apiDetails = `${apiDetails} ${JSON.stringify(accountActivityStorages)}`;

        onboardAzureAccountActivity({
            SubscriptionId: subscriptionId,
            Storages: accountActivityStorages,
            ReportingSubscriptions: selectedSubscriptions?.map((subscription) => subscription.subscriptionId) ?? []
        }, apiDetails)
            .then(() => {
                dispatch(setOnboardingStatus(true));
                getLoggerService().info(`${t('ACCOUNT_ACTIVITY.SUMMARY.SUCCESS')}. ${apiDetails}`, '', [t('LOGGER_TAGS.AZURE_ONBOARDING')].concat([onboardingType]));
            })
            .catch(() => {
                dispatch(setOnboardingStatus(false));
            })
            .finally(() => {
                dispatch(toggleIsLoading(false));
            });
    } else {
        const networkTrafficStorages: IOnboardingNetworkTrafficStorages[] = [];

        selectedStorages?.forEach((storage: IStorageModel) => {
            const storageItem: IOnboardingNetworkTrafficStorages = { StorageName: storage.storageName };

            if (isCentralizedOnboarding) storageItem.IsAutoDiscoveryEnabled = storage.isAutoDiscoveryEnabled;
            if (!networkTrafficStorages.find(networkTrafficStorage => networkTrafficStorage.StorageName === storage.storageName)) {
                networkTrafficStorages.push(storageItem);
            }
        });
        apiDetails = `${apiDetails}  ${JSON.stringify(networkTrafficStorages)}`;

        onboardAzureNetworkTraffic({
            SubscriptionId: subscriptionId,
            Storages: networkTrafficStorages,
            ReportingSubscriptions: selectedSubscriptions?.map((subscription: IReportingSubscriptionsModel) => subscription.subscriptionId) ?? []
        })
            .then(() => {
                dispatch(setOnboardingStatus(true));
                getLoggerService().info(`${t('NETWORK_TRAFFIC.SUMMARY.SUCCESS')}. ${apiDetails}`, '', [t('LOGGER_TAGS.AZURE_ONBOARDING')].concat([onboardingType]));
            })
            .catch(() => {
                dispatch(setOnboardingStatus(false));
            })
            .finally(() => {
                dispatch(toggleIsLoading(false));
            });
    }
}

const getStorageModel = (storage: IAzureAddStorageResponseModel, isSelected: boolean, id: string): IStorageModel => { 
    return {
        storageName: storage.name,
        status: ConnectionStatus.notConnected,
        isAutoDiscoveryEnabled: false,
        resourceGroupName: storage.resourceGroupName,
        systemTopicName: storage.systemTopicName,
        region: storage.region,
        isSelected: isSelected,
        logTypesFound: storage.logTypesFound,
        logsType: [],
        id
    };
};

export async function addStorageToManualList (storageId: string) {
    const { state, dispatch } = getStoreService().getReduxTools();
    const t = i18next.getFixedT(null, i18nIntelligenceNamespace, 'AZURE_ONBOARDING.STORAGE');

    const { selectedLogTypes, subscriptionId, allStorages, azureIntelligenceType } = state.azureIntelligenceOnboarding as IAzureIntelligenceOnboardingState;
    const isAccountActivity = azureIntelligenceType === AssetType.activityLogs;

    if (allStorages?.find((storage: IStorageModel) => storage.id === storageId)) {
        throw new Error(t('ADD_STORAGE.ERROR.ALREADY_ADDED'));
    }

    const requestData: IAzureAddStorageModel = {
        storagesDetails: [
            {
                storageResourceId: storageId,
                LogTypes: isAccountActivity ? selectedLogTypes as AssetType[] : [AssetType.flowLogs]
            }
        ],
        SubscriptionId: subscriptionId,
    };

    const response: IAzureAddStorageResponseModel[] = await getAzureStorageById(requestData);
    
    if (response && response.length) {
        const storage = response[0];
        const storageModel: IStorageModel = getStorageModel(storage, true, storageId);

        dispatch(setAllStorages([...allStorages ?? [], storageModel]));
    } else {
        throw new Error(t('ADD_STORAGE.ERROR.NOT_FOUND'));
    }
}

export async function fetchStorages() {
    const { dispatch, state } = getStoreService().getReduxTools();
    const t = i18next.getFixedT(null, i18nIntelligenceNamespace, 'AZURE_ONBOARDING.STORAGE');

    const {
        subscriptionId,
        azureIntelligenceType,
        azureOnboardingType,
        selectedLogTypes
    } = state.azureIntelligenceOnboarding as IAzureIntelligenceOnboardingState;

    const isAccountActivity = azureIntelligenceType === AssetType.activityLogs;
    const isCentralizedOnboarding = azureOnboardingType === AzureIntelligenceOnboardingType.centralized;

    const onboardedStorages = await getOnboardedStorageData(subscriptionId, isAccountActivity);
    let tableRows: IStorageModel[] = [];

    if (isCentralizedOnboarding) {
        const discoverStoragesRequest = {
            SubscriptionId: subscriptionId,
            LogTypes: isAccountActivity ? selectedLogTypes as AssetType[] : [AssetType.flowLogs]
        };
        
        const allStorages = await discoverStorages(discoverStoragesRequest);

        if (!allStorages?.length) { 
            throw new Error(t('ERROR.1'));
        }

        tableRows = allStorages?.map(storage => {
            return getStorageModel({ 
                ...storage, 
                systemTopicName: storage.systemTopicName
            }, false, storage.name + storage.resourceGroupName);
        }) ?? [];

        if (onboardedStorages?.storagesDetails?.length && allStorages?.length) {
            const storagesNames = Array.from(new Set(onboardedStorages.storagesDetails.map(storage => storage.storageName)));

            storagesNames.forEach(name => {
                const onboardedStoragesList = (onboardedStorages.storagesDetails.filter(storage => storage.storageName === name));
                const storageRowIndex = tableRows?.findIndex(row => row.storageName === name);

                if (storageRowIndex >= 0) {
                    tableRows[storageRowIndex].logTypesFound.forEach(type => {
                        type.logTypeStatus = ConnectionStatus.notConnected;
                    });

                    onboardedStoragesList.forEach(storage => {
                        const logTypeStatus = getOnboardedStatus(storage, allStorages, isAccountActivity);
                        const logTypesFoundIndex = tableRows[storageRowIndex].logTypesFound.findIndex(type => type.logType.toLowerCase() === storage.logsType?.toLowerCase());

                        if (logTypesFoundIndex >= 0) {
                            if (isAccountActivity) tableRows[storageRowIndex].logTypesFound[logTypesFoundIndex].logTypeStatus = logTypeStatus;
                            tableRows[storageRowIndex].logTypesFound[logTypesFoundIndex].hasStorageKey = storage.hasStorageKey;
                        }
                        if (!isAccountActivity) {
                            tableRows[storageRowIndex].status = logTypeStatus;
                        }
                        if (!isAccountActivity || storage.logsType === LogType.activityLogs) {
                            tableRows[storageRowIndex].isCentralized = storage.isCentralized;
                        }
                        tableRows[storageRowIndex].isAutoDiscoveryEnabled = storage.isAutoDiscoveryEnabled;
                    });

                    if (isAccountActivity) {
                        if (tableRows[storageRowIndex].logTypesFound.every(type => type.logTypeStatus === ConnectionStatus.connected)) {
                            tableRows[storageRowIndex].status = ConnectionStatus.connected; // All log types are onboarded
                        } else if (tableRows[storageRowIndex].logTypesFound.every(type => type.logTypeStatus === ConnectionStatus.notConnected)) {
                            tableRows[storageRowIndex].status = ConnectionStatus.notConnected; // No log types are onboarded
                        } else {
                            tableRows[storageRowIndex].status = ConnectionStatus.partiallyConnected; // Some of the log types are onboarded
                        }
                    }
                }
            });
        }
    } else {
        // If NSG Onboarding
        const rgsFromMagellan: IRgsFromMagellanModel[] = [];
        if (onboardedStorages?.storagesDetails?.length) {
            onboardedStorages?.storagesDetails?.forEach(storage => {
                rgsFromMagellan.push({
                    RgAndNsg: (storage.resourceGroup ?? '') + (storage.nsg ?? ''),
                    StorageName: storage.storageName,
                    Status: storage.status ? 1 : 0,
                    IsStorageManaged: storage.isStorageManaged ? 1 : 0
                });
            });
        }

        const resourceGroupsRequest = {
            RgsFromMagellan: rgsFromMagellan,
            SubscriptionId: subscriptionId
        };
        const allSecurityGroups = await getAzureResourceGroupsForSubscription(resourceGroupsRequest);

        if (allSecurityGroups?.nsgDetails) {
            dispatch(setNSGStorages(allSecurityGroups?.nsgDetails));
            tableRows = allSecurityGroups?.nsgDetails?.map(storage => {
                return {
                    storageName: storage.storageName,
                    status: storage.isConnected ? ConnectionStatus.connected : ConnectionStatus.notConnected,
                    isAutoDiscoveryEnabled: false,
                    resourceGroupName: storage.resourceGroup,
                    systemTopicName: storage.eventTopicName,
                    region: storage.storageLocation,
                    isSelected: false,
                    logTypesFound: [],
                    logsType: [],
                    nsg: storage.networkSecurityGroup,
                    storageRg: storage.storageRg,
                    storageExist: storage.storageExist,
                    id: storage.storageName + storage.resourceGroup + storage.networkSecurityGroup
                };
            }) ?? [];

            onboardedStorages?.storagesDetails?.forEach(storage => {

                const storageIndex = tableRows.findIndex(row => storage.storageName === row.storageName);
                if (storageIndex >= 0) {
                    tableRows[storageIndex].isCentralized = storage.isCentralized;
                    tableRows[storageIndex].hasStorageKey = storage.hasStorageKey;
                }

            });
        }
    }

    dispatch(setAllStorages(tableRows));
}

async function handleSubscriptions ({
    skipARMTemplateStep,
    selectedStorages,
    subscriptionId,
    isAccountActivity,
    isCentralizedOnboarding,
    cloudAccountId,
    ipAddress,
    allStorages,
    nsgStorages
} : {
    skipARMTemplateStep: boolean, 
    selectedStorages: IStorageModel[] | null, 
    subscriptionId: string, 
    isAccountActivity: boolean, 
    isCentralizedOnboarding: boolean,
    cloudAccountId: string,
    ipAddress: string,
    allStorages: IStorageModel[] | null,
    nsgStorages: IAzureResourceGroupsModel[] | null
}) {
    const { dispatch } = getStoreService().getReduxTools();

    dispatch(toggleIsLoading(true));

    if (skipARMTemplateStep && selectedStorages?.length) {
        // send subscriptionsRequest
        await handleSubscriptionsStep({ 
            ipAddress, 
            isCentralizedOnboarding, 
            isAccountActivity, 
            skipARMTemplateStep, 
            selectedStorages, 
            subscriptionId 
        });
    } else {
        if (cloudAccountId) {
            const principalId = await getCredentialsServicePrincipalId(cloudAccountId);
            const jsonData: IJsonData[] = [];
            const nsgJsonData: INsgJsonData[] = [];

            selectedStorages?.forEach((storage: IStorageModel) => {
                if (!(storage.status === ConnectionStatus.connected && ((isCentralizedOnboarding && storage.logTypesFound.every(type => !type.hasStorageKey)) || (!isCentralizedOnboarding && !storage.hasStorageKey)))) {
                    const logTypes: string[] = [];
                    const storageItem = allStorages?.find((item: IStorageModel) => item.storageName === storage.storageName);

                    if (isAccountActivity)
                        storageItem?.logTypesFound.forEach((type: ILogTypesModel) => {
                            if (type.logTypeStatus === ConnectionStatus.connected && type.hasStorageKey) {
                                logTypes.push(type.logType);
                            }
                        });

                    if (isCentralizedOnboarding) {
                        jsonData.push({
                            resourceGroup: isCentralizedOnboarding ? storage.resourceGroupName : (storage.storageRg ?? ''),
                            storageName: storage.storageName,
                            region: storage.region,
                            topic: storage.systemTopicName,
                            logTypes: isAccountActivity ? (logTypes.concat(storage.logsType ?? [])) : [AssetType.flowLogs]
                        });
                    } else {
                        const storagesWithSameRg = selectedStorages.filter((nsg: IStorageModel) => nsg.resourceGroupName === storage.resourceGroupName && !(nsg.status === ConnectionStatus.connected && !nsg.hasStorageKey));
                        const nsgsForArmTemplate: INsgForArmTemplate[] = [];
                        if (!nsgJsonData.find(data => data.rg === storage.resourceGroupName || data.nsgsForArmTemplate.find(nsg => (nsg.storageName === storage.storageName) && storage.storageExist))) {
                            storagesWithSameRg.forEach((nsg: IStorageModel) => {
                                const resourceGroupData = nsgStorages?.find((nsgItem: IAzureResourceGroupsModel) => nsgItem.storageName === nsg.storageName && nsgItem.resourceGroup === nsg.resourceGroupName && nsgItem.networkSecurityGroup === nsg.nsg);

                                if (resourceGroupData)
                                    nsgsForArmTemplate.push({
                                        networkSecurityGroup: resourceGroupData.networkSecurityGroup,
                                        nsgLocation: resourceGroupData.nsgLocation,
                                        resourceGroup: resourceGroupData.resourceGroup,
                                        storageName: resourceGroupData.storageName,
                                        storageExist: resourceGroupData.storageExist,
                                        eventgTopicName: resourceGroupData.eventTopicName,
                                        eventgTopicExist: resourceGroupData.eventTopicExist,
                                        flowLogExist: resourceGroupData.flowLogExist,
                                        containerExist: resourceGroupData.containerExist,
                                        storageLocation: resourceGroupData.storageLocation,
                                        storageRg: resourceGroupData.storageRg,
                                        networkWatcherName: resourceGroupData.networkWatcherName,
                                        networkWatcherRg: resourceGroupData.networkWatcherRg,
                                        networkWatcherLocation: resourceGroupData.networkWatcherLocation,
                                        isStorageManaged: resourceGroupData.isStorageManaged
                                    });
                            });

                            nsgJsonData.push({
                                nsgsForArmTemplate: nsgsForArmTemplate,
                                rg: storage.resourceGroupName
                            });
                        }
                    }
                }
            });

            // get unique array of resourceGroupNames
            let customARMTemplate: IARMTemplateResponseModel | undefined;
            const customARMTemplateRequest = {
                cloudAccount: subscriptionId,
                appRegistrationPrincipalId: principalId ?? '',
                jsonUrl: false,
                isCentralized: true,
                returnRoleAssignments: true
            };

            if (isCentralizedOnboarding) {
                const accountActivityJsonData: {
                    [key: string]: IJsonData[]
                } = {};
                const resourceGroupNames = Array.from(new Set(jsonData.map(res => res.resourceGroup)));

                resourceGroupNames.map(resourceGroupName => {
                    accountActivityJsonData[resourceGroupName] = jsonData.filter(data => data.resourceGroup === resourceGroupName);
                });

                const accountActivityRequest: IAccountActivityARMTemplateRequestModel = {
                    ...customARMTemplateRequest,
                    jsonData: accountActivityJsonData
                };
                const networkTrafficRequest: INetworkTrafficARMTemplateRequestModel = {
                    ...customARMTemplateRequest,
                    jsonData: jsonData
                };

                customARMTemplate = isAccountActivity ? await getAccountActivityARMTemplate(accountActivityRequest) : await getNetworkTrafficARMTemplate(networkTrafficRequest);

            } else {
                const nsgRequest: INsgARMTemplateRequestModel = {
                    ...customARMTemplateRequest,
                    jsonData: nsgJsonData
                };
                customARMTemplate = await getNSGARMTemplate(nsgRequest);
            }
            if (customARMTemplate?.arm) {
                dispatch(setARMTemplateDetails(customARMTemplate));
            }
        }

        dispatch(setNextButtonDisabled(true));
        dispatch(goToNextStep());
    }


    dispatch(toggleIsLoading(false));
}