import { IntelligenceNodeData } from 'common/module_interface/intelligence/intelligenceNodeData';
import NodeDetailsComponent from '../../Common/Components/NodeDetailsComponent';
import { useMemo } from 'react';
import { getNetworkNodePopoverDetailsList } from '../Utils';
import { preventNodeSelection } from '../../Common/Utils';
import styled from 'styled-components';

const NetworkTrafficNodePopoverStyled = styled.div`
  margin: 10px;
`;
const NetworkTrafficNodePopoverComponent = (props: IntelligenceNodeData) => {
    const nodePopoverDetails = useMemo(() => {
        return getNetworkNodePopoverDetailsList(props);
    }, [props]);

    return <NetworkTrafficNodePopoverStyled onClick={preventNodeSelection}>
        <NodeDetailsComponent NodePopoverDetails={nodePopoverDetails}/>
    </NetworkTrafficNodePopoverStyled>;
};

export default NetworkTrafficNodePopoverComponent;
