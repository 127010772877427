import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { ICommonIgnoreItem } from '../CommonIgnoreList.interface';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

export const IgnoreItemNameCellRender: React.FC<ICellRendererParams<ICommonIgnoreItem> & { icon: AllIconsName, openForm: (item: ICommonIgnoreItem) => void }> = (params) => {
    const ignoreItem: ICommonIgnoreItem | undefined = params.data;
    if (!ignoreItem?.name) {
        return '';
    }

    const onClick = () => {
        params.openForm(ignoreItem);
    };

    const options: IIconLinkCellProps = {
        isLink: true,
        value: ignoreItem.name,
        onClick,
        levelIconProps: {
            iconProps: { name: params.icon },
            size: 'md',
        }
    };

    CellToClipboardHandler(params, ignoreItem.name);
    return (<IconLinkCellRenderer {...options} />);
};
