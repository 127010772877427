import React, { useMemo } from 'react';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import PropertyBox from 'common/components/PropertyBox/PropertyBox';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
export const BundleNamePanel: React.FC<{ finding: IFinding }> = ({ finding }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const bundleLink = finding.rulesetId ? `magellan/ruleset/${finding.rulesetId}` : '';
    const bundleName = finding.rulesetName;

    const bundleHref = useMemo(() => {
        return <a href={bundleLink} className={'text-info cursor-pointer link'}>{bundleName}</a>;
    }, [bundleName, bundleLink]);

    return (
        bundleName &&
        <PropertyBoxesRow>
            <PropertyBox title={t('INT_FINDING_OVERVIEW.RULESET')} content={bundleHref} />
        </PropertyBoxesRow>
    );
};
