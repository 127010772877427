import { FC, useEffect, useState } from 'react';
import { SelectV2 } from 'common/design-system/components-v2';
import { I18nExclusion } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { IExclusionOrganizationalUnitProps } from '../../helpers/exclusions.interfaces';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import {
    fetchOrganizationalUnits,
    formatOrganizationalUnits, handleFetchingError,
} from '../../helpers/exclusions.utils';


const ExclusionByOrganizationalUnit: FC<IExclusionOrganizationalUnitProps> = ({ isEnabled, selectedRuleset, onChange, selectedOption, onClose, initialSelectedOption }) => {
    const { t } = useTranslation(I18nExclusion);
    const [organizationalUnitList, setOrganizationalUnitList] = useState<SelectOption[]>([]);

    useEffect(() => {
        const handleRulesetAbsence = () => {
            setOrganizationalUnitList([]);
            onChange([]);
        };
        const updateOrganizationalUnitsList = (rules: SelectOption[]) => {
            setOrganizationalUnitList(rules);
        };
        const handleFetchOrganizationalUnits = async () => {
            if (!selectedRuleset) {
                handleRulesetAbsence();
                return;
            }
            try {
                const organizationalUnitsData = await fetchOrganizationalUnits();
                const formattedOrganizationalUnits = formatOrganizationalUnits(organizationalUnitsData);
                formattedOrganizationalUnits && updateOrganizationalUnitsList(formattedOrganizationalUnits);
            } catch (error) {
                handleFetchingError(t, onClose);
            }
        };
        handleFetchOrganizationalUnits();
    }, [selectedRuleset]);

    useEffect(() => {
        initialSelectedOption ? onChange(initialSelectedOption) : onChange([]);
    }, [organizationalUnitList]);

    const handleOrganizationalUnitChange = (newValue: string[]) => {
        onChange(newValue);
    };

    return (
        <SelectV2
            onChange={(value: string[]) => handleOrganizationalUnitChange(value)}
            isMulti={true}
            options={organizationalUnitList}
            readOnly={!isEnabled }
            placeholder={t('MODAL.GENERAL.SELECT')}
            value={selectedOption}
            data-aid='exclusions-ou-select'
            fullWidth
            clearable
        />
    );
};

export default ExclusionByOrganizationalUnit;