import * as Sentry from '@sentry/react';
import { Replay, BrowserTracing } from '@sentry/react';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { AFTER_LOGIN_EVENT, REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { getLoggerService, getUserService } from 'common/interface/services';
import { getDataCenterDisplayName, getIsCloudInfra, getRuntimeEnvironmentType } from 'common/utils/RuntimeEnvironment';
import { IReactPageRoute } from 'common/interface/routing';
declare let window: any;

let lastNavigationPath = '';
export const initializeSentry = () => {
    // let lastTransactionEvent;
    try {
        let reactPageRoutes:null|IReactPageRoute[] = null;
        const environment = getDataCenterDisplayName() + '_' + getRuntimeEnvironmentType() + '_' + (getIsCloudInfra() ? 'CloudInfra' : 'StandAlone');
        Sentry.init({
            dsn: 'https://bbf5cb6081ca4adeb0d2b40d4c9dc31c@sentry-prod-relay.iaas.checkpoint.com/68',
            beforeSendTransaction: (transactionEvent) => {

                const url = new URL(transactionEvent.request?.url || '');
                const pathname = url.pathname.replace('/v2','');
                const urlQueryParams = url.search;
                function getIsReactRoute() {
                    reactPageRoutes = reactPageRoutes || globalAddinContainer.get(REACT_PAGE_ROUTE) as IReactPageRoute[];
                    return pathname === '/' || !!reactPageRoutes.find((route) => {
                        if (route.path == '/' || route.path == '/v2/') return false;
                        if (Array.isArray(route.path)) {
                            return route.path.includes(pathname);
                        } else {
                            return pathname === route.path;
                        }
                    });
                }

                const isReact = getIsReactRoute();
                if(isReact){
                    transactionEvent.spans = transactionEvent.spans?.filter(span=>!span.op?.includes('resource.iframe'));
                }
                if(((lastNavigationPath === pathname) || (pathname.replace(lastNavigationPath,'') === '/index')) && pathname !== '/'){
                    return null;
                }else {
                    lastNavigationPath = pathname || '';
                    transactionEvent.tags!.LocationPath = pathname+urlQueryParams;
                    ///transaction_info is not supported to our Sentry version
                    delete transactionEvent.transaction_info;
                    return transactionEvent;
                }
            },
            environment: environment,
            release: window.CG?.buildNumber,
            tracesSampleRate: 1,
            integrations: [
                new Replay(),
                new BrowserTracing({
                    idleTimeout: 10000,
                    finalTimeout: 60000,
                    startTransactionOnLocationChange: true
                })
            ],
        });
    } catch (e: any) {
        getLoggerService().error(`Could not load Sentry ${e.message}`);
    }
};
export const setSentryOptions = () => {
    globalAddinContainer.add(AFTER_LOGIN_EVENT, [{
        id: 'Sentry',
        content: async () => {
            try {
                const user = getUserService().getUser();
                Sentry.setUser({ email: user.email });
                Sentry.setTag('account', user.accountId);
            } catch (e: any) {
                getLoggerService().error(`Could not load Sentry ${e.message}`);
            }
        },
    }]);
};
