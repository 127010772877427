import styled from 'styled-components/macro';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 20px 20px 20px;
`;

const BaseLineDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  height: 14px;
`;

const InfoLineDiv = styled(BaseLineDiv)`
  width: 230px;
`;

const InfoLineDivLong = styled(BaseLineDiv)`
  width: 280px;
`;

const SimpleTextDiv = styled(BaseLineDiv)`
`;

const InfoNameDiv = styled.div`
  font-weight: 400;
    white-space: nowrap;
`;

const InfoSepDiv = styled.div`
  display: flex;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
  flex-grow: 1;
  align-self: end;
  height: 8px;
  margin: 0 5px;
    min-width: 20px;
`;

const InfoValueDiv = styled.div`
  font-weight: 500;
  text-align: end;  
`;

export const ChipTooltipStyled = {
    TopDiv,
    InfoLineDiv,
    InfoLineDivLong,
    InfoNameDiv,
    InfoSepDiv,
    InfoValueDiv,
    SimpleTextDiv,
};
