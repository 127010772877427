import React from 'react';
import { ModalType } from '../../AwsEnvironment.types';
import EditAwsModal from './Rename/AwsEnvironmentRename';
import AwsEditCredentials from './AwsEditCredentials/AwsEditCredentials';
import RemoveGuardDuty from './RemoveGuardDuty/RemoveGuardDuty';
import RemoveIntelligence from './RemoveIntelligence/RemoveIntelligence';
import RemoveAccount from './RemoveAccount/RemoveAccount';
import RemoveAccountWithPolicies from './RemoveAccountWithPolicies/RemoveAccountWithPolicies';
import useModalFromUrl from 'common/hooks/useModalFromUrl';

export interface ModalsProps {
    awsId: string;
    modalType: ModalType | null;
    closeModal: () => void
    setModalType: (modalType: ModalType) => void;
}

const Modals: React.FC<ModalsProps> = props => {
    const { setModalType, modalType } = props;


    const { modalTypeFromUrl } = useModalFromUrl();

    React.useEffect(() => {
        if (modalTypeFromUrl && !modalType) {
            setModalType(modalTypeFromUrl as ModalType);
        }
    }, [modalType, modalTypeFromUrl, setModalType]);

    switch (props.modalType) {
        case ModalType.Remove:
            return <RemoveAccount {...props} />;
        case ModalType.Remove_WithPolicies:
            return <RemoveAccountWithPolicies {...props} />;
        case ModalType.Rename:
            return <EditAwsModal {...props} />;
        case ModalType.EditCredentials:
            return <AwsEditCredentials {...props} />;
        case ModalType.RemoveGuardDuty:
            return <RemoveGuardDuty {...props} />;
        case ModalType.RemoveIntelligence:
            return <RemoveIntelligence {...props} />;
        default:
            return null;
    }
};

export default Modals;
