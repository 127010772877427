import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';

const SelectionHeaderComponent: React.FC<ICellRendererParams> = (params) => {

    return (
        <div>
            <input className='ag-input-field-input ag-checkbox-input w-9' type='checkbox' onClick={(event: React.MouseEvent) => toggleSelectAll(event.target)}/>
        </div>
    );

    function toggleSelectAll(target: any) {
        params.api.forEachNode(node => {
            node.setSelected(target.checked || false);
        });
    }

};

export default SelectionHeaderComponent;