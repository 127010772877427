import { alibaba_actions } from '.';
import { batch } from 'react-redux';
import { getStoreService, getWebAppIframeService } from 'common/interface/services';
import AlibabaCloudAccountsService from '../services/cloudAccounts/alibabaCloudAccounts.service';
import { AlibabaEnvironmentData } from 'common/module_interface/assets/AlibabaEnvironment';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import EnvironmentsService from '../../services/environments.service';


const getMessageFromError: (error: any) => string = (error: any) => {
    const responseError = error.response?.data?.errors?.description?.join(', ');
    const responseErrorOpt2 = error.response?.data?.title;
    const responseErrorOpt3 = error.response?.data?.message;
    const responseErrorOpt4 = error.message;
    return responseError || responseErrorOpt2 || responseErrorOpt3 || responseErrorOpt4;
};

type GetAlibabaAccount = (alibabaId: string) => Promise<boolean>;

const getAlibabaAccount: GetAlibabaAccount = async (alibabaId) => {
    const { dispatch } = getStoreService().getReduxTools();
    try {
        dispatch(alibaba_actions.setLoading({ alibabaId, loading: true }));
        const results = await Promise.allSettled([
            AlibabaCloudAccountsService.cloudAccountGet(alibabaId),
            EnvironmentsService.getTotalAssetsNumberOfCloudAccount(alibabaId)
        ]);
        const [accountData, totalAssets] = results;
        if (accountData.status === 'rejected' || !accountData.value.data) throw new Error('');
        const totalAssetsCount = totalAssets.status === 'fulfilled' ? totalAssets.value : 0;
        const alibabaData: AlibabaEnvironmentData = {
            account: { ...accountData.value.data, totalAssets: totalAssetsCount },
        };
        batch(() => {
            dispatch(alibaba_actions.setData({ alibabaId, data: alibabaData }));
            dispatch(alibaba_actions.setError({ alibabaId, error: false }));
            dispatch(alibaba_actions.setLoading({ alibabaId, loading: false }));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(alibaba_actions.setError({ alibabaId, error: true }));
            dispatch(alibaba_actions.setLoading({ alibabaId, loading: false }));
        });
        throw Error(getMessageFromError(error));
    }
};

export interface UpdateAlibabaAccountNameReq {
    name: string;
}

export interface UpdateAlibabaCredentialsReq {
    accessKey: string;
    accessSecret: string;
}

type UpdateAlibabaAccount = (cloudAccountId: string, name: string) => Promise<boolean>;
const updateAlibabaAccountName: UpdateAlibabaAccount = async (cloudAccountId, name) => {
    try {
        const { state } = getStoreService().getReduxTools();
        const { name: currentName } = state.env_alibaba.alibabaEvnironments[cloudAccountId].data?.account || {};

        if (name && name !== currentName) {
            const res = await AlibabaCloudAccountsService.cloudAccountNameUpdate({ cloudAccountId, name });
            if(res){
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.UPDATE_TABPANEL_TABNAME,
                        data: { id: cloudAccountId, newName: name }
                    }),
                );
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                    }),
                );
                getAlibabaAccount(cloudAccountId);
            }
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type UpdateCredentialsAlibabaAccount = (cloudAccountId: string, data: UpdateAlibabaCredentialsReq) => Promise<boolean>;
const updateCredentialsAlibabaAccount: UpdateCredentialsAlibabaAccount = async (cloudAccountId, data) => {
    const { accessKey , accessSecret } = data;
    try {
        if (accessKey && accessSecret) {
            const res = await AlibabaCloudAccountsService.cloudAccountsCredentialsUpdate({ id: cloudAccountId, accessKey, accessSecret });
            if(res){
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                    }),
                );
                getAlibabaAccount(cloudAccountId);
            }
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type DeleteAccount = (alibabaId: string, forceDelete?: boolean) => Promise<boolean>;
const deleteAccount: DeleteAccount = async (alibabaId, forceDelete = false) => {
    try {
        const { dispatch } = getStoreService().getReduxTools();
        if (forceDelete) {
            await AlibabaCloudAccountsService.cloudAccountDeleteForce(alibabaId);
            dispatch(alibaba_actions.setDeleted({ alibabaId }));
        }
        else{
            await AlibabaCloudAccountsService.cloudAccountDelete(alibabaId);
            dispatch(alibaba_actions.setDeleted({ alibabaId }));
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

export interface addGSuiteReq {
    email: string;
    cloudAccountId: string;
    domain: string;
}


const AlibabaActions = {
    getAlibabaAccount,
    updateAlibabaAccountName,
    updateCredentialsAlibabaAccount,
    deleteAccount,
};

export default AlibabaActions;
