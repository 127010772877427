import {
    DashboardWidgetTypes,
    IDashboard,
} from 'common/module_interface/overview/Interface';
import { v4 } from 'uuid';
import { LOCAL_OCI_DEFAULT_DASHBOARD } from '../Consts';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';


export default function initializeOCIDefaultDashboard() {
    const ociDefaultDashboard: IDashboard = {
        id: LOCAL_OCI_DEFAULT_DASHBOARD,
        name: LOCAL_OCI_DEFAULT_DASHBOARD,
        isLocalDashboard: true,
        sections: [
            {
                isExpanded: true,
                id: 'inventory',
                title: 'INVENTORY',
                dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                position: 1,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Summary,
                        'title': 'Inventory',
                        'limit': 10,
                        'description': 'Total Protected Assets',
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'platform',
                                'value': 'oci'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 1
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m',
                            'thresholds': []
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Protected assets by Environment',
                        'limit': 10,
                        'description': 'Total Protected Assets',
                        'hideOnNoData': false,
                        'aggregation': 'cloudAccountId',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'platform',
                                'value': 'oci'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Top Protected Assets by type',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'type',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'platform',
                                'value': 'oci'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'inventory',
                        dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Pie,
                        'title': 'Protected assets by region',
                        'limit': 5,
                        'description': 'Total Protected Assets',
                        'hideOnNoData': false,
                        'aggregation': 'region',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'platform',
                                'value': 'oci'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    }
                ]
            },
            {
                isExpanded: true,
                id: 'postureManagement',
                title: 'POSTURE MANAGEMENT',
                dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                position: 2,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Assets with most high severity alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 22
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Latest,
                        'title': 'Latest high and critical alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'ruleName',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'organizationalUnitId',
                                'value': DEFAULT_ORGANIZATIONAL_UNIT_ID
                            },
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 22
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Environments with high severity alerts',
                        'limit': 10,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'cloudAccountId_calc',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 22
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Compute Alerts',
                        'limit': 50,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '22|ComputeInstance'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 22
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'postureManagement',
                        dashboardId: LOCAL_OCI_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Storage with high severity alerts',
                        'limit': 50,
                        'description': 'High risks on storage',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'High'
                            },
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '22|StorageBucket'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 22
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    }
                ]
            }
        ]
    };

    return ociDefaultDashboard;
}