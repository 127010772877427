import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    IFilterDetails
} from 'common/module_interface/intelligence/Intelligence.interface';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { getReportsAggregations } from '../../services/Reporting.service';
import {
    IFreeTextFilter,
    IReportsAggregation,
    IReportsFieldFilter,
    IReportsRequest
} from '../../interface/Reporting.interface';
import { ReportsTableRegistry } from 'common/module_interface/reporting/ReportsTableRegistry';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import i18n from 'i18next';
import { i18nReportsNamespace } from '../../initialize.i18n';

export const transformFilterValues = (filterValues?: IFilterDetails):
    { fields: IReportsFieldFilter[], freeText: IFreeTextFilter } => {
    const fields: { name: string, value: string[] }[] = [];

    filterValues?.fields?.forEach((field: { name: string, value: string }) => {
        const existingField = fields.find(f => f.name === field.name);
        if (existingField) {
            existingField.value.push(field.value);
        } else {
            fields.push({ name: field.name, value: [field.value] });
        }
    });

    const freeText = {
        phrase: filterValues?.freeTextPhrase ?? EMPTY_STRING,
        fields: ReportsTableRegistry.getFilterById(FILTERS_KEYS.FREE_TEXT)?.filterProps?.freeTextFields ?? []
    };

    return { fields, freeText };
};

export const transformAggregations = (aggregations?: IReportsAggregation[]): Aggregations => {
    const transformedAggregations: Aggregations = {};
    aggregations?.forEach(aggregation => {
        if (aggregation.name in transformedAggregations) {
            transformedAggregations[aggregation.name].push({ value: aggregation.value, count: aggregation.count });
        } else {
            transformedAggregations[aggregation.name] = [{ value: aggregation.value, count: aggregation.count }];
        }
    });
    return transformedAggregations;
};

export const fetchReportsAggregations = async (filterValues?: IFilterDetails): Promise<Aggregations> => {
    try {
        const { fields, freeText } = transformFilterValues(filterValues);
        const request: IReportsRequest = {
            filters: {
                fields,
                freeText,
            },
            skipAggregations: false,
            skipData: true,
        };
        const aggregations = await getReportsAggregations(request);
        return transformAggregations(aggregations);
    } catch (e: any) {
        getNotificationsService().error(
            i18n.t('REPORTS.NOTIFICATIONS.AGGREGATIONS_FETCH_STATUS', { ns: i18nReportsNamespace }),
            i18n.t('REPORTS.NOTIFICATIONS.ERROR.AGGREGATIONS_FETCH_STATUS_DESC', { ns: i18nReportsNamespace }),
        );
        const errorMessage = `${i18n.t('REPORTS.LOGGER.ERROR.AGGREGATIONS_FETCH_ERROR', { ns: i18nReportsNamespace })}: ${e?.message}`;
        await getLoggerService().error(errorMessage);
        return {};
    }
};
