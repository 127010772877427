import React, { ChangeEvent, useState } from 'react';
import { IDashboardWidget, IGenericWidgetSettingsProps } from 'common/module_interface/overview/Interface';
import { deepCloneObject } from 'common/utils/objectUtils';
import { TextField } from '@dome9/berries/react-components';

type ThresholdTypes = 'danger' | 'warning';

export const ComplianceGaugeWidgetSettings: React.FC<IGenericWidgetSettingsProps> = ({ widget, updateWidgetCallback }) => {
    const _widgetClone = deepCloneObject(widget);
    if(!_widgetClone.options.thresholds){
        const thresholds = [
            {
                min: 0,
                max: 33,
            },
            {
                min: 34,
                max: 75,
            },
            {
                min: 76,
            }
        ];
        _widgetClone.options.thresholds = thresholds;
    }
    const thresholds = _widgetClone.options?.thresholds;
    const DEFAULT_THRESHOLD_COLORS = {
        DANGER: '#f25050',
        WARNING: '#ffae00',
        SUCCESS: '#56b30f',
    };

    const fixThresholdMissingData = (thresholds: any) => {
        if(!thresholds) {
            thresholds = [];
        }
        if(thresholds.length === 0){
            thresholds.push({ min: 0, max: 33 }, { min: 34, max: 75 }, { min: 76 });
        }
        if(thresholds.length === 1){
            thresholds.push({ min: thresholds[0].max + 1 , max: thresholds[0].max + 1 });
        }
        if(thresholds.length === 2){
            thresholds.push({ min: thresholds[1].max + 1, max: thresholds[1].max + 1 });
        }
    };

    fixThresholdMissingData(thresholds);
    const _dangerMaxLimit = thresholds?.length && thresholds[0].max || 1;
    const _warningMinLimit = thresholds?.length && thresholds[1].min || _dangerMaxLimit + 1;
    const _warningMaxLimit = thresholds?.length && thresholds[1].max || _warningMinLimit + 1;
    const _successMinLimit = thresholds?.length && thresholds[2].min || _warningMaxLimit + 1;

    const [dangerMaxLimit, setDangerMaxLimit] = useState(_dangerMaxLimit);
    const [warningMinLimit, setWarningMinLimit] = useState(_warningMinLimit);
    const [warningMaxLimit, setWarningMaxLimit] = useState(_warningMaxLimit);
    const [successMinLimit, setSuccessMinLimit] = useState(_successMinLimit);
    const [widgetClone, setWidgetClone] = useState<IDashboardWidget>(_widgetClone);

    const updateWidget =(widgetClone: IDashboardWidget)=>{
        updateWidgetCallback(widgetClone);
        setWidgetClone(widgetClone);
    };

    const setDangerLimits = (valueAsNumber: number, clonedWidget: any) => {
        clonedWidget.options.thresholds[0].max = valueAsNumber;
        clonedWidget.options.thresholds[1].min = valueAsNumber + 1;
        clonedWidget.options.thresholds[1].max = valueAsNumber + 1 >= warningMaxLimit ? valueAsNumber + 2 : warningMaxLimit;
        clonedWidget.options.thresholds[2].min = valueAsNumber + 3 >= successMinLimit ? valueAsNumber + 3 : successMinLimit;
        setDangerMaxLimit(valueAsNumber);
        setWarningMinLimit(valueAsNumber + 1);
        valueAsNumber + 1 >= warningMaxLimit && setWarningMaxLimit(valueAsNumber + 2);
        valueAsNumber + 3 >= successMinLimit && setSuccessMinLimit(valueAsNumber + 3);
        updateWidget(clonedWidget);
    };

    const setWarningLimits = (valueAsNumber: number, clonedWidget: any) => {
        clonedWidget.options.thresholds[1].max = valueAsNumber;
        clonedWidget.options.thresholds[2].min = valueAsNumber + 1;
        setWarningMaxLimit(valueAsNumber);
        setSuccessMinLimit(valueAsNumber + 1);
        updateWidget(clonedWidget);
    };

    const handleChangeMaxLimits = (e: ChangeEvent<HTMLInputElement>, type: ThresholdTypes) =>{
        const valueAsNumber = e.target.valueAsNumber;
        const _widgetClone = deepCloneObject(widgetClone);
        if(type === 'danger'){
            setDangerLimits(valueAsNumber, _widgetClone);
        }
        if(type === 'warning'){
            if(valueAsNumber <= dangerMaxLimit){
                return;
            }
            setWarningLimits(valueAsNumber, _widgetClone);
        }
    };

    return(
        <div>
            <div className="font-semibold mb-5">
                Thresholds
            </div>
            <div className='flex flex-col justify-start items-center mb-5'>
                <div className='w-full mb-5 flex items-center'> {/* Add margin-bottom for spacing */}
                    <div style={{ backgroundColor: DEFAULT_THRESHOLD_COLORS.DANGER }} className='w-8 h-8 rounded-full mr-5'></div>
                    <div className={'flex items-baseline'}>
                        <div className='flex-1 ml-6 mr-4'>
                            0
                        </div>
                        <div className='flex-1 ml-2 mr-4'>
                            -
                        </div>
                        <div className='flex-1 z-0 max-w-[100px]'>
                            <TextField
                                allowClear={false}
                                type='number'
                                onChange={event => handleChangeMaxLimits(event, 'danger')}
                                value={dangerMaxLimit || '0'}
                            />
                        </div>
                    </div>
                </div>

                <div className='w-full mb-5 flex items-center'>
                    <div style={{ backgroundColor: DEFAULT_THRESHOLD_COLORS.WARNING }} className='w-8 h-8 rounded-full mr-5'></div>
                    <div className={'flex items-baseline'}>
                        <div className='flex-1 mx-4'>
                            {warningMinLimit || dangerMaxLimit + 1}
                        </div>
                        <div className='flex-1 ml-2 mr-4'>
                            -
                        </div>
                        <div className='flex-1 z-0 max-w-[100px]'>
                            <TextField
                                allowClear={false}
                                type='number'
                                onChange={event => handleChangeMaxLimits(event, 'warning')}
                                value={warningMinLimit === dangerMaxLimit + 1 ? warningMaxLimit : warningMinLimit}
                            />
                        </div>
                    </div>
                </div>

                <div className='w-full flex items-center'>
                    <div style={{ backgroundColor: DEFAULT_THRESHOLD_COLORS.SUCCESS }} className='w-8 h-8 rounded-full mr-5'></div>
                    <div className={'flex items-baseline'}>
                        <div className='flex-1 mx-4 my-5'>
                            {successMinLimit}
                        </div>
                        <div className='flex-1 ml-2 mr-4'>
                            -
                        </div>
                        <div>
                            100
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};