import React from 'react';
import { ITrendBoxItem } from '../../../RiskManagement.interface';
import { Icon } from 'common/design-system/components-v2';
import { TrendBoxStyled } from './TrendBox.styled';

export const TrendBox: React.FC<{ item: ITrendBoxItem }> = ({ item }) => {
    return (
        <TrendBoxStyled.TopDiv iconSize={0}>
            <TrendBoxStyled.TitleDiv>
                <TrendBoxStyled.TextDiv>{ item.originalColumn.text } </TrendBoxStyled.TextDiv>
                <Icon name={'arrowRight'} size={12}/>
                <TrendBoxStyled.TextDiv>{ item.trendColumn.text } </TrendBoxStyled.TextDiv>
            </TrendBoxStyled.TitleDiv>
            <TrendBoxStyled.ContentDiv>
                <TrendBoxStyled.CounterDiv> { item.originalColumn.count } </TrendBoxStyled.CounterDiv>
                <TrendBoxStyled.TrendDiv percentage={item.trendColumn.count} reverseLogic={true} size={'sm'}/>
            </TrendBoxStyled.ContentDiv>
        </TrendBoxStyled.TopDiv>
    );
};
