import React, { useEffect, useState } from 'react';
import { RiskDashboardStyled } from '../RiskDashboard.styled';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { useTranslation } from 'react-i18next';
import { IssuesStyled } from './SecurityIssues.styled';
import IssuesBySeverity from './IssuesBySeverity/IssuesBySeverity';
import SimpleDottedHeader from '../TopHeaders/SimpleDottedHeader';
import { TopIssues } from './TopIssues/TopIssues';
import { areFiltersEqual, assetFilterFieldsToServerInputFilterDetails } from '../../../Issues/Issues.utils';
import { I18nRiskNamespace } from '../../../../consts';
import { IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';

const SecurityIssues: React.FC<{ filterValues?: IFiltersValues }> = ({ filterValues }) => {
    const [issuesFilterDetails, setIssuesFilterDetails] = useState<IServerInputFilterDetails | undefined>();
    const { t } = useTranslation(I18nRiskNamespace);

    useEffect(() => {
        if (filterValues) {
            const externalFilter = assetFilterFieldsToServerInputFilterDetails(filterValues, true);
            if (!issuesFilterDetails || !areFiltersEqual(issuesFilterDetails, externalFilter)) {
                setIssuesFilterDetails(externalFilter);
            }
        }
    }, [filterValues, issuesFilterDetails]);

    return (
        <RiskDashboardStyled.TopSection>
            <SimpleDottedHeader title={t('RISK_MANAGEMENT.DASHBOARD.SECURITY_ISSUES')} />
            <IssuesStyled.TopDiv>
                <IssuesStyled.IssuesBySeverityDiv>
                    <IssuesBySeverity filterDetails={issuesFilterDetails} />
                </IssuesStyled.IssuesBySeverityDiv>
                <IssuesStyled.TopIssuesDiv>
                    <TopIssues filterDetails={issuesFilterDetails} title={t('RISK_MANAGEMENT.DASHBOARD.TOP_SECURITY_ISSUES')}/>
                </IssuesStyled.TopIssuesDiv>
            </IssuesStyled.TopDiv>
        </RiskDashboardStyled.TopSection>
    );
};

export default SecurityIssues;
