import { WizardStep } from 'common/components/Wizard/Wizard';
import { getStoreService } from 'common/interface/services';
import { t } from 'i18next';
import { AzureCommandCloudShellRequest, AzureOnboardingAccountType, AzureOnboardingScope, AzureOnboardingType } from './azure.interface';
import {
    setIsLoading,
    setIsManagementGroupIdAlreadyOnboarded,
    setIsTenantIdAlreadyOnboarded,
    setAzureActiveBlades,
    setIsSubscriptionIdAlreadyOnboarded } from './azure.reducer';
import { filterActiveAzureConfigurations, getCommandCloudShellFromServer, checkOnboardingStatus, getIsSubscriptionIdOnboarded } from './azure.service';
import AzureWelcome from '../AzureWelcome';
import AzureConfiguration from '../AzureConfiguration';
import AzureSinglePrepare from '../azureSingle/manualSteps/AzureSinglePrepare';
import AzureSingleConnect from '../azureSingle/manualSteps/AzureSingleConnect';
import AzureWelcomeManual from '../azureSingle/manualSteps/AzureWelcome';

export const welcomeStep: WizardStep = {
    name: 'ON_BOARDING.AZURE.WELCOME_STEP',
    renderView: (onValidate) => {
        return <AzureWelcome {...onValidate} />;
    },
    onNext: async () => {
        const { state: { azureOnboarding }, dispatch } = getStoreService().getReduxTools();
        // No validation needed for Azure Single flow on Welcome Step
        if (azureOnboarding.azureOnboardingType === AzureOnboardingType.single) {
            if(azureOnboarding.welcomeForm.subscriptionId) {
                try {
                    const res = await getIsSubscriptionIdOnboarded(azureOnboarding.welcomeForm.subscriptionId);
                    if (res.isOnboarded) {
                        dispatch(setIsSubscriptionIdAlreadyOnboarded(true));
                        throw Error(t('ALREADY_ONBOARDED'));
                    } else {
                        dispatch(setIsSubscriptionIdAlreadyOnboarded(false));
                        return;
                    }
                }
                catch (e) {
                    throw Error('');
                }
            } else {
                throw Error(t('SUBSCRIPTION_ID_REQUIRED'));
            }
        }
        // Validations for Azure Org flow
        if (azureOnboarding.azureOnboardingType === AzureOnboardingType.org) {
            if (azureOnboarding.azureOnboardingScope === AzureOnboardingScope.tenant) {
                dispatch(setIsLoading(true));
                try {
                    const res = await checkOnboardingStatus(azureOnboarding.welcomeForm.tenantId, azureOnboarding.welcomeForm.tenantId);
                    if (res.isOnboarded){
                        const reason = res.reason ? res.reason : true;
                        dispatch(setIsTenantIdAlreadyOnboarded(reason));
                        throw Error(`${t('ALREADY_ONBOARDED')}, ${reason}`);
                    }
                    else {
                        dispatch(setIsTenantIdAlreadyOnboarded(false));
                    }
                } finally {
                    dispatch(setIsLoading(false));
                }
            }
            if (azureOnboarding.azureOnboardingScope === AzureOnboardingScope.managementGroup){
                dispatch(setIsLoading(true));
                try {
                    const res = await checkOnboardingStatus(azureOnboarding.welcomeForm.tenantId, azureOnboarding.welcomeForm.managementGroupId);
                    if(res.isOnboarded){
                        const reason = res.reason ? res.reason : true;
                        dispatch(setIsManagementGroupIdAlreadyOnboarded(reason));
                        throw Error(`${t('ALREADY_ONBOARDED')}, ${reason}`);
                    }
                    else{
                        dispatch(setIsManagementGroupIdAlreadyOnboarded(false));
                    }
                } finally {
                    dispatch(setIsLoading(false));
                }
            }
        }
        return;
    }
};
export const configurationStep: WizardStep = {
    name: 'ON_BOARDING.AZURE.CONFIGURATION_STEP',
    renderView: (onValidate) => {
        return <AzureConfiguration {...onValidate} />;
    },
    onNext: async () => {
        const { state: { azureOnboarding }, dispatch } = getStoreService().getReduxTools();
        if (azureOnboarding.azureOnboardingType === AzureOnboardingType.single) {
            dispatch(setIsLoading(true));
            try {
                const request: AzureCommandCloudShellRequest = {
                    activeBlades: {
                        ...azureOnboarding.activeBlades,
                        postureManagement: {
                            onboardingMode: azureOnboarding.azureOnboardingOperationMode
                        }
                    },
                    subscriptionId: azureOnboarding.welcomeForm.subscriptionId,
                    useCloudGuardManagedApp: false,
                    accountType: azureOnboarding.azureOnboardingAccountType
                };
                await getCommandCloudShellFromServer(request);
            } finally {
                dispatch(setIsLoading(false));
            }
        }
        if (azureOnboarding.azureOnboardingType === AzureOnboardingType.org) {
            dispatch(setIsLoading(true));
            try {
                const request: AzureCommandCloudShellRequest = {
                    useCloudGuardManagedApp: azureOnboarding.azureOnboardingAccountType === AzureOnboardingAccountType.standard,
                    activeBlades: {}
                };
                const { azureOnboardingScope, welcomeForm, azureConfigurations } = azureOnboarding;
                switch (azureOnboardingScope) {
                    case AzureOnboardingScope.tenant:
                        request.managementGroupIdOrTenantId = welcomeForm.tenantId;
                        break;
                    case AzureOnboardingScope.managementGroup:
                        request.managementGroupIdOrTenantId = welcomeForm.managementGroupId;
                        break;
                    default: // Assuming the default case is for subscriptions
                        request.subscriptionId = welcomeForm.subscriptionId;
                }

                const configurations = filterActiveAzureConfigurations(azureConfigurations);
                request.activeBlades = { ...request.activeBlades, ...configurations };
                dispatch(setAzureActiveBlades());
                await getCommandCloudShellFromServer(request);
            } finally {
                dispatch(setIsLoading(false));
            }
        }
    }
};

export const welcomeStepManual: WizardStep = {
    name: 'ON_BOARDING.AZURE.WELCOME_STEP',
    renderView: (onValidate) => {
        return <AzureWelcomeManual {...onValidate} />;
    },
    onNext: async () => {
        return;
    }
};
export const prepareStep: WizardStep = {
    name: 'ON_BOARDING.AZURE.PREPARE_STEP',
    renderView: (onValidate) => {
        return <AzureSinglePrepare {...onValidate} />;
    },
    onNext: async () => {
        return;
    }
};
export const connectStep: WizardStep = {
    name: 'ON_BOARDING.AZURE.CONNECT_STEP',
    renderView: (onValidate) => {
        return <AzureSingleConnect {...onValidate} />;
    },
    onNext: async () => {
        return;
    }
};
