import initializeFilters from './FilterDefinitions';
import { IReactPageRoute } from 'common/interface/routing';
import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import ProtectedAssetsList from './ProtectedAssetsList';
import initializeColumnDefs from './ErmColumnDefinitions';
import { IAssetDetailsPageAliases } from 'common/module_interface/assets/ProtectedAssets';
import { isUrlStartsWith } from 'common/utils/http';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { ermPermissionFn } from '../../RiskManagement.utils';

export const assetTabsMetadata = {
    tabsId: 'ermProtectedAssets',
    closeAllUrl: '/risk-management/protected-assets',
};

function initializeRoutes() {
    const protectedAssetsPageRoute = (): IReactPageRoute => {
        return {
            condition: true,
            component: ProtectedAssetsList,
            path: ['/risk-management/protected-assets'],
            exact: true,
        };
    };

    globalAddinContainer.add(REACT_PAGE_ROUTE, [
        {
            id: 'Risk Management Protected Assets Page', content: protectedAssetsPageRoute, permission: ermPermissionFn,
        },
    ]);

    ProtectedAssetsPageRegistry.addAliasesAddins([
        {
            id: 'erm',
            position: 10,
            content: (): IAssetDetailsPageAliases => {
                return {
                    urlAliases: ['/risk-management/protected-assets/generic', '/risk-management/protected-assets/details'],
                    getTabsMetadata: () => {
                        return assetTabsMetadata;
                    },
                    isRelevant: () => isUrlStartsWith('/risk-management/protected-assets/'),
                };
            },
            permission: ermPermissionFn,
        },
    ]);
}

export function initializeProtectedAssetsPage() {
    initializeFilters();
    initializeColumnDefs();
    initializeRoutes();
}

