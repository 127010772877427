import styled from 'styled-components/macro';

export const SecurityEventsTabledDiv = styled.div`
  height: 100%;
  
  .table-footer {
    height: 38px;
    padding: 10px 0 0 20px;
    background: #EDEDED 0 0 no-repeat padding-box;
    border-radius: 0 0 7px 7px;
    opacity: 1;
  }
`;

