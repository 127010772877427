import React, { useEffect, useState } from 'react';
import AssetDetailsPane from './AssetDetailsPane';
import { changeUrl } from 'common/utils/http';
import { useSelector } from 'react-redux';
import { getSelectedEntity, setSelectedEntity } from './AssetDetails.reducer';
import { getAssetInfoFromLocation, getEntityFromUrlParams } from 'common/components/ProtectedAssets/AssetUtils';
import { useLocation } from 'react-router-dom';
import { PROTECTED_ASSET_PANELS_COMPONENT_ID } from './initialize';
import { getNotificationsService, getSideBarService, getStoreService } from 'common/interface/services';
import { useReactWithAngular } from 'common/hooks/useReactWithAngular';
import { useAssetDetailsSelectedPanel } from './AssetDetailsPanelsHooks';
import AssetDetailsPanelsList from './AssetDetailsPanelsList';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import AssetTabs, { IAssetInfo } from 'common/components/ProtectedAssets/AssetTabs';
import { CpLoadingDots } from '@dome9/components/react/components';
import i18n from 'i18next';
import { ProtectedAssetsPageRegistry } from 'common/module_interface/assets/ProtectedAssetsPageRegistry';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';

export function getTabsMetadata(): { tabsId: string, closeAllUrl: string } {
    const items = ProtectedAssetsPageRegistry.getAliases();
    const relevantItem = items.find(item => item?.isRelevant()) || items.find(item => item?.isDefault);
    const tabsPageMetadata = relevantItem?.getTabsMetadata();
    if (tabsPageMetadata === undefined) {
        throw new Error('Could not find tabs metadata for URL');
    }
    return tabsPageMetadata;
}


const AssetDetails: React.FC = () => {
    const location = useLocation();
    const [assetInfo, setAssetInfo] = useState<IAssetInfo | null>(null);
    const selectedEntity: ICloudEntityData | null = useSelector(getSelectedEntity);
    const selectedPanel = useAssetDetailsSelectedPanel();
    const tabsMetadata = getTabsMetadata();
    const { setBreadCrumbsLastChildren } = useBreadcrumbsLastChildren();

    useEffect(() => {
        if (selectedEntity?.protectedAsset.name) {
            setBreadCrumbsLastChildren([selectedEntity?.protectedAsset.name]);
        }
    }, [selectedEntity, setBreadCrumbsLastChildren]);

    useEffect(() => {
        const newAssetInfo = getAssetInfoFromLocation(location);
        if (assetInfo && newAssetInfo.hash === assetInfo.hash) {
            return;
        }

        getStoreService().dispatch(setSelectedEntity(null));
        setAssetInfo(newAssetInfo);
        getEntityFromUrlParams(newAssetInfo)
            .then((entityData) => {
                if (entityData === null) {
                    getNotificationsService().error('', i18n.t('ASSETS.ASSET_DETAILS.LOAD_ERROR'));
                    changeUrl(tabsMetadata.closeAllUrl);
                }
                getStoreService().dispatch(setSelectedEntity(entityData));
            });

    }, [location, assetInfo]);

    useEffect(() => {
        return () => {
            getStoreService().dispatch(setSelectedEntity(null));
            getSideBarService().setVisibility(false);
        };
    }, []);


    const isReact = Boolean(selectedPanel === null || selectedPanel?.isReact);
    useReactWithAngular(!isReact);

    useEffect(() => {
        if (selectedPanel === null) {
            return;
        }
        if (isReact) {
            getSideBarService().setVisibility(false);
        } else {
            getSideBarService().setVisibility(true);
            getSideBarService().loadComponent(PROTECTED_ASSET_PANELS_COMPONENT_ID);
        }
    }, [isReact, selectedPanel]);

    const querystring = new URLSearchParams(window.location.search);
    if (querystring.get('tabOnly') !== 'true') {
        querystring.set('tabOnly', 'true');
        changeUrl(window.location.pathname + `?${querystring.toString()}`);
    }
    let InnerComponent: React.FC<{ entity: ICloudEntityData }> | null = null;
    if (isReact && selectedPanel && selectedPanel.component) {
        InnerComponent = selectedPanel.component!;
    }
    const isLoading = selectedEntity === null;
    let component = (<CpLoadingDots></CpLoadingDots>);
    if (!isLoading) {
        component = <>
            <AssetDetailsPane entity={selectedEntity}></AssetDetailsPane>
            {isReact && selectedEntity && <div className={'flex flex-row h-full'}>
                <AssetDetailsPanelsList></AssetDetailsPanelsList>
                <div className={'w-full flex flex-col p-[24px]'} data-aid='asset-details-panels-area'>
                    {InnerComponent && <InnerComponent entity={selectedEntity}></InnerComponent>}
                </div>
            </div>}
        </>;
    }
    return (
        <div className={'h-full flex flex-col'} data-aid='asset-details'>
            <AssetTabs pageId={tabsMetadata?.tabsId} closeAllUrl={tabsMetadata?.closeAllUrl}></AssetTabs>
            {component}
        </div>
    );
};

export default AssetDetails;