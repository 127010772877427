import { getHttpService } from 'common/interface/services';
import { ICloudAccount, IGetAccountRequest } from 'common/interface/user';

export const getAccountFromServer = async () => {
    return await getHttpService().request<IGetAccountRequest>('/account', { method: 'GET' });
};

export const getCloudAccount = async (): Promise<ICloudAccount> => {
    return await getHttpService().request<ICloudAccount>('application/d9awsaccount', {
        method: 'GET',
    });
};
