import { Addin } from 'common/extensibility/AddinRegistry';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import i18n from 'common/services/translations/translations';
import { getEventsTableNamespace } from '../initialize.i18n';
import { EventAction, IEvent } from 'common/module_interface/events/Events';
import { changeUrlParam } from 'common/utils/http';
import { IFindingModelOriginString, IFindingModelStatus } from 'common/components/Findings/Findings.interface';
import { hasPermissionToEventActions } from 'common/module_interface/events/utils';
import { EventsDrawerAddingBase, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import { IButtonProps } from 'common/design-system/components-v2/Button/Button.types';

enum ACTION_IDS {
    ACKNOWLEDGE = 'acknowledge',
    REMEDIATE = 'remediate',
    COMMENT = 'comment',
    CLOSE = 'close',
    ARCHIVE = 'archive',
    UNARCHIVE = 'unarchive',
    REPORT_ISSUE = 'reportIssue',
    CHANGE_SEVERITY = 'changeSeverity',
    ASSIGN = 'assign'
}

const canExecute = (selectedRows: IEvent[], actionId: ACTION_IDS): string | true => {
    if (!hasPermissionToEventActions()) return 'You do not have permission to perform this action';
    if (selectedRows.some(row =>
        row.origin === IFindingModelOriginString.Magellan ||
        row.origin === IFindingModelOriginString.Intelligence)
    ) {
        return 'To perform actions on CDR events, please navigate to the CDR tab';
    }
    if (selectedRows.length === 0) return 'Select at least one event to enable this action';
    if (selectedRows.length === 1) {
        const selectedRow = selectedRows[0];
        switch (actionId) {
            case ACTION_IDS.CLOSE: {
                const notRelevantOrigins: IFindingModelOriginString[] = [
                    IFindingModelOriginString.ComplianceEngine,
                    IFindingModelOriginString.ExternalFindingSource,
                    IFindingModelOriginString.Qualys,
                    IFindingModelOriginString.CIEM
                ];
                if (notRelevantOrigins.includes(selectedRow.origin)) return 'This action is not available for this event';
                return true;
            }
            case ACTION_IDS.ACKNOWLEDGE:
            case ACTION_IDS.COMMENT:
            case ACTION_IDS.REPORT_ISSUE:
            case ACTION_IDS.CHANGE_SEVERITY:
            case ACTION_IDS.ASSIGN:
            case ACTION_IDS.ARCHIVE: {
                if (selectedRow.status === IFindingModelStatus.Archived) return 'This action is not available for archived events';
                return true;
            }
            case ACTION_IDS.UNARCHIVE: {
                if (selectedRow.status !== IFindingModelStatus.Archived) return 'This action is not available for non-archived events';
                return true;
            }
            default:
                return true;
        }
    }

    switch (actionId) { // if multiple rows selected
        case ACTION_IDS.CLOSE: {
            const notRelevantOrigins: IFindingModelOriginString[] = [
                IFindingModelOriginString.ComplianceEngine,
                IFindingModelOriginString.ExternalFindingSource,
                IFindingModelOriginString.Qualys,
                IFindingModelOriginString.CIEM
            ];
            if (selectedRows.some(row => notRelevantOrigins.includes(row.origin))) return 'This action is not available for one or more selected events';
            return true;
        }
        case ACTION_IDS.ACKNOWLEDGE:
        case ACTION_IDS.COMMENT:
        case ACTION_IDS.REPORT_ISSUE:
        case ACTION_IDS.CHANGE_SEVERITY:
        case ACTION_IDS.ASSIGN:
        case ACTION_IDS.ARCHIVE: {
            if (selectedRows.some(row => row.status === IFindingModelStatus.Archived)) return 'This action is not available for one or more selected archived events';
            return true;
        }
        case ACTION_IDS.UNARCHIVE: {
            if (selectedRows.some(row => row.status !== IFindingModelStatus.Archived)) return 'This action is not available for one or more selected non-archived events';
            return true;
        }
        default:
            return true;
    }
};


const actionsMenuDefs = () => {
    const actions: Addin<EventsDrawerAddingBase<EventAction>>[] = [
        {
            id: ACTION_IDS.ACKNOWLEDGE,
            content: {
                id: ACTION_IDS.ACKNOWLEDGE,
                isRelevant: (event) => canExecute([event], ACTION_IDS.ACKNOWLEDGE) === true,
                getValue: (event) => {
                    const actionType = event.acknowledged ? 'unacknowledge' : 'acknowledge';
                    return ({
                        label: i18n.t(`ACTIONS.${actionType === 'unacknowledge' ? 'UN' : ''}ACKNOWLEDGE.BUTTON`, { ns: getEventsTableNamespace('table') }),
                        icon: ACTION_IDS.ACKNOWLEDGE,
                        callback: () => changeUrlParam('modalType', ACTION_IDS.ACKNOWLEDGE),
                    });},
            }
        },
        {
            id: ACTION_IDS.COMMENT,
            content: {
                id: 'comment',
                isRelevant: (event) => canExecute([event], ACTION_IDS.COMMENT) === true,
                getValue: () => ({
                    label: i18n.t('ACTIONS.COMMENT.BUTTON', { ns: getEventsTableNamespace('table') }),
                    icon: 'comments',
                    callback: () => changeUrlParam('modalType', ACTION_IDS.COMMENT),
                }),
            }
        },
        {
            id: ACTION_IDS.CLOSE,
            content: {
                id: ACTION_IDS.CLOSE,
                isRelevant: (event) => canExecute([event], ACTION_IDS.CLOSE) === true,
                getValue: () => ({
                    label: i18n.t('ACTIONS.CLOSE.BUTTON', { ns: getEventsTableNamespace('table') }),
                    callback: () => changeUrlParam('modalType', ACTION_IDS.CLOSE),
                    icon: 'remove'
                }),
            }
        },
        {
            id: ACTION_IDS.ARCHIVE,
            content: {
                id: ACTION_IDS.ARCHIVE,
                isRelevant: (event) => canExecute([event], ACTION_IDS.ARCHIVE) === true,
                getValue: () => ({
                    label: i18n.t('ACTIONS.ARCHIVE.BUTTON', { ns: getEventsTableNamespace('table') }),
                    callback: () => changeUrlParam('modalType', ACTION_IDS.ARCHIVE),
                    icon: ACTION_IDS.ARCHIVE
                }),
            }
        },
        {
            id: ACTION_IDS.UNARCHIVE,
            content: {
                id: ACTION_IDS.UNARCHIVE,
                isRelevant: (event) => canExecute([event], ACTION_IDS.UNARCHIVE) === true,
                getValue: () => ({
                    label: i18n.t('ACTIONS.UNARCHIVE.BUTTON', { ns: getEventsTableNamespace('table') }),
                    callback: () => changeUrlParam('modalType', ACTION_IDS.UNARCHIVE),
                    icon: 'archive'
                }),
            }
        },
        {
            id: ACTION_IDS.REPORT_ISSUE,
            content: {
                id: ACTION_IDS.REPORT_ISSUE,
                isRelevant: (event) => canExecute([event], ACTION_IDS.REPORT_ISSUE) === true,
                getValue: () => ({
                    label: i18n.t('ACTIONS.REPORT_AN_ISSUE.BUTTON', { ns: getEventsTableNamespace('table') }),
                    callback: () => changeUrlParam('modalType', ACTION_IDS.REPORT_ISSUE),
                    icon: 'issue'
                })
            },
        },
        {
            id: ACTION_IDS.CHANGE_SEVERITY,
            content: {
                id: ACTION_IDS.CHANGE_SEVERITY,
                isRelevant: (event) => canExecute([event], ACTION_IDS.CHANGE_SEVERITY) === true,
                getValue: () => ({
                    label: i18n.t('ACTIONS.CHANGE_SEVERITY.BUTTON', { ns: getEventsTableNamespace('table') }),
                    callback: () => changeUrlParam('modalType', ACTION_IDS.CHANGE_SEVERITY),
                    icon: 'dataSensitivity'
                }),
            }
        },
        {
            id: ACTION_IDS.ASSIGN,
            content: {
                id: ACTION_IDS.ASSIGN,
                isRelevant: (event) => canExecute([event], ACTION_IDS.ASSIGN) === true,
                getValue: () => ({
                    label: i18n.t('ACTIONS.ASSIGN.BUTTON', { ns: getEventsTableNamespace('table') }),
                    callback: () => changeUrlParam('modalType', ACTION_IDS.ASSIGN),
                    icon: ACTION_IDS.ASSIGN
                }),
            }
        }
    ];
    return actions;
};

const addErrorStateToAction = (selectedRows: IEvent[], actionId: ACTION_IDS, buttonProps: Partial<IButtonProps>): Partial<IButtonProps> => {
    const error = canExecute(selectedRows, actionId);
    const hasError = typeof error === 'string';
    return {
        ...buttonProps,
        tooltip: hasError ? error : undefined,
        disabled: hasError,
    };
};

const actionsDefs = () => {
    const actions: Addin<ITableAction<IEvent>>[] = [
        {
            id: ACTION_IDS.ACKNOWLEDGE,
            content: {
                id: ACTION_IDS.ACKNOWLEDGE,
                position: 50,
                name: i18n.t('ACTIONS.ACKNOWLEDGE.BUTTON', { ns: getEventsTableNamespace('table') }),
                callback: () => null,
                isRelevant: hasPermissionToEventActions,
                buttonProps: (selectedRows) => {
                    const isAllSelectedAcknowledged = selectedRows.every(row => row.acknowledged);
                    const actionType = (isAllSelectedAcknowledged && selectedRows.length > 0) ? 'unacknowledge' : 'acknowledge';
                    return addErrorStateToAction(selectedRows, ACTION_IDS.ACKNOWLEDGE, {
                        iconProps: { name: 'acknowledge' },
                        label: i18n.t(`ACTIONS.${actionType === 'unacknowledge' ? 'UN' : ''}ACKNOWLEDGE.BUTTON`, { ns: getEventsTableNamespace('table') }),
                        onClick: () => changeUrlParam('modalType', 'acknowledge'),
                    });
                }
            }
        },
        {
            id: ACTION_IDS.COMMENT,
            content: {
                id: ACTION_IDS.COMMENT,
                position: 50,
                name: i18n.t('ACTIONS.COMMENT.BUTTON', { ns: getEventsTableNamespace('table') }),
                callback: () => changeUrlParam('modalType', ACTION_IDS.COMMENT),
                isRelevant: hasPermissionToEventActions,
                buttonProps: (selectedRows) => addErrorStateToAction(selectedRows, ACTION_IDS.COMMENT, {
                    iconProps: { name: 'comments' },
                })
            }
        },
        {
            id: ACTION_IDS.CLOSE,
            content: {
                id: ACTION_IDS.CLOSE,
                position: 5,
                name: i18n.t('ACTIONS.CLOSE.BUTTON', { ns: getEventsTableNamespace('table') }),
                callback: () => changeUrlParam('modalType', ACTION_IDS.CLOSE),
                isRelevant: hasPermissionToEventActions,
                buttonProps: (selectedRows) => addErrorStateToAction(selectedRows, ACTION_IDS.CLOSE, {
                    iconProps: { name: 'remove' },
                })
            }
        },
        {
            id: ACTION_IDS.ARCHIVE,
            content: {
                id: ACTION_IDS.ARCHIVE,
                position: 50,
                name: i18n.t('ACTIONS.ARCHIVE.BUTTON', { ns: getEventsTableNamespace('table') }),
                callback: () => changeUrlParam('modalType', ACTION_IDS.ARCHIVE),
                isRelevant: hasPermissionToEventActions,
                buttonProps: (selectedRows) => addErrorStateToAction(selectedRows, ACTION_IDS.ARCHIVE, {
                    iconProps: { name: 'archive' },
                })
            }
        },
        {
            id: ACTION_IDS.UNARCHIVE,
            content: {
                id: ACTION_IDS.UNARCHIVE,
                position: 50,
                name: i18n.t('ACTIONS.UNARCHIVE.BUTTON', { ns: getEventsTableNamespace('table') }),
                callback: () => changeUrlParam('modalType', ACTION_IDS.UNARCHIVE),
                isRelevant: hasPermissionToEventActions,
                buttonProps: (selectedRows) => addErrorStateToAction(selectedRows, ACTION_IDS.UNARCHIVE, {
                    iconProps: { name: 'archive' },
                })
            }
        },
        {
            id: ACTION_IDS.REPORT_ISSUE,
            content: {
                id: ACTION_IDS.REPORT_ISSUE,
                position: 100,
                name: i18n.t('ACTIONS.REPORT_AN_ISSUE.BUTTON', { ns: getEventsTableNamespace('table') }),
                callback: () => changeUrlParam('modalType', ACTION_IDS.REPORT_ISSUE),
                isRelevant: hasPermissionToEventActions,
                buttonProps: (selectedRows) => addErrorStateToAction(selectedRows, ACTION_IDS.REPORT_ISSUE, {
                    iconProps: { name: 'issue' },
                })
            }
        },
        {
            id: ACTION_IDS.CHANGE_SEVERITY,
            content: {
                id: ACTION_IDS.CHANGE_SEVERITY,
                position: 50,
                name: i18n.t('ACTIONS.CHANGE_SEVERITY.BUTTON', { ns: getEventsTableNamespace('table') }),
                callback: () => changeUrlParam('modalType', ACTION_IDS.CHANGE_SEVERITY),
                isRelevant: hasPermissionToEventActions,
                buttonProps: (selectedRows) => addErrorStateToAction(selectedRows, ACTION_IDS.CHANGE_SEVERITY, {
                    iconProps: { name: 'dataSensitivity' },
                })
            }
        },
        {
            id: ACTION_IDS.ASSIGN,
            content: {
                id: ACTION_IDS.ASSIGN,
                position: 50,
                name: i18n.t('ACTIONS.ASSIGN.BUTTON', { ns: getEventsTableNamespace('table') }),
                callback: () => changeUrlParam('modalType', ACTION_IDS.ASSIGN),
                isRelevant: hasPermissionToEventActions,
                buttonProps: (selectedRows) => addErrorStateToAction(selectedRows, ACTION_IDS.ASSIGN, {
                    iconProps: { name: 'assign' },
                })
            }
        }
    ];
    return actions;
};

export default function initializeActionsDefs() {
    EventsTableRegistry.addActions(actionsDefs());
    EventsDrawerRegistry.addActions(actionsMenuDefs());
}