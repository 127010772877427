import styled, { css } from 'styled-components';

const TopDiv = styled.div<{ hideSortIndicators?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${props => props.hideSortIndicators && css`
      .ag-header-cell-sortable {
        cursor: default;
        
        .ag-header-cell-label {
          cursor: default;
        }
        
        .ag-sort-indicator-icon {
          .ag-icon {
            width: 0;
          }
        }
      }
   `};
`;


export const TableStyled = {
    TopDiv,
};
