import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Icon, Spinner, Stack, Tooltip } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { getEnvsNamespace } from '../initialize.i18n';
import { IEnvironment } from 'common/interface/environmentsTable';
import { TFunction } from 'i18next';
import { StatusCellRenderer_Status, StatusCellRenderer_StatusType } from 'common/module_interface/assets/Environments';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';

const getTooltipContent = (status: StatusCellRenderer_Status, statusType: StatusCellRenderer_StatusType, t: TFunction) => {
    switch (statusType) {
        case StatusCellRenderer_StatusType.syncStatus: {
            return status === StatusCellRenderer_Status.warning ? t('CELL_RENDERERS.STATUS.MISSING_PERMISSIONS') : t('CELL_RENDERERS.STATUS.VALID_PERMISSIONS');
        }
        case StatusCellRenderer_StatusType.workloadStatus: {
            return status === StatusCellRenderer_Status.unsecured ? t('CELL_RENDERERS.STATUS.UNSECURED') : undefined;
        }
        default: {
            return undefined;
        }
    }
};

interface IStatusCellRendererProps extends ICellRendererParams<IEnvironment> {}

const StatusCellRenderer = React.forwardRef<any, IStatusCellRendererProps>((params, ref) => {
    const { t } = useTranslation(getEnvsNamespace('table'));
    const [status, setStatus] = React.useState<StatusCellRenderer_Status | undefined>(params.data?.['customData|status']);
    const [statusType, setStatusType] = React.useState<StatusCellRenderer_StatusType | undefined>(params.data?.['customData|statusType']);
    const [statusDetails, setStatusDetails] = React.useState<string | undefined>(params.data?.['customData|statusDetails']);
    const [isLoading, setIsLoading] = React.useState<boolean>(params.data?.['customData|statusLoading'] || false);

    React.useImperativeHandle(ref, () => {
        return {
            refresh(params: IStatusCellRendererProps) {
                setStatus(params.data?.['customData|status']);
                setStatusType(params.data?.['customData|statusType']);
                setStatusDetails(params.data?.['customData|statusDetails']);
                setIsLoading(params.data?.['customData|statusLoading'] || false);
                return true;
            },
        };
    });

    const statusIcon = React.useMemo<AllIconsName>(() => {
        switch (status) {
            case StatusCellRenderer_Status.success:
                return 'statusSuccess';
            case StatusCellRenderer_Status.warning:
                return 'statusWarning';
            case StatusCellRenderer_Status.error:
                return 'statusError';
            case StatusCellRenderer_Status.unsecured:
                return 'statusUnsecured';
            default:
                return 'statusInfo';
        }
    }, [status]);

    if (isLoading) {
        return (
            <Stack justifyContent='center' alignItems='flex-start' fullHeight fullWidth>
                <Spinner size={16} />
            </Stack>
        );
    }

    if (!status || !statusType) {
        return null;
    }
    
    if (
        status === StatusCellRenderer_Status.error ||
        status === StatusCellRenderer_Status.warning ||
        status === StatusCellRenderer_Status.success ||
        status === StatusCellRenderer_Status.unsecured
    ) {
        return (
            <Stack justifyContent='center' alignItems='flex-start' fullHeight fullWidth>
                <Tooltip content={statusDetails || getTooltipContent(status, statusType, t)}>
                    <Stack padding={[2, 4]}>
                        <Icon name={statusIcon} size={16} />
                    </Stack>
                </Tooltip>
            </Stack>
        );
    }

    return null;
});
StatusCellRenderer.displayName = 'StatusCellRenderer';

export default StatusCellRenderer;