import React, { useEffect, useState } from 'react';
import { getIntelligenceData } from '../Services/IntelligenceGraphDataHelpers';
import { ExpandedFindingDetailsProps } from '../Models/IntelligenceRequest';
import RelatedEventsTable from './RelatedEventsTable';
import { mapToGraphEntities } from '../../../Visualizations/Common/Services/GraphEntityMapper';
import { Edge, Node } from 'reactflow';
import { useLocation } from 'react-router-dom';
import { getPropertyFromParams, setEntityInUrl } from '../Services/Utils';
import VisualizationComponent from '../../../Visualizations/Common/Components/VisualizationComponent';
import { GRAPH_CLASSES } from '../../../Visualizations/Common/Consts/GraphProperties';
import { setVisualizationGraphState } from '../../../Visualizations/Common/Services/GraphState.reducer';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';
import { getStoreService } from 'common/interface/services';
import { LoadingState } from 'common/interface/general';
import { Spinner } from 'common/design-system/components-v2';
import { FindingGraphWrapperStyled } from './FindingGraphWrapper.styled';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';

const FindingGraphWrapper: React.FC<{ expandedFindingDetailsProps: ExpandedFindingDetailsProps }> = ({ expandedFindingDetailsProps }) => {
    const [nodes, setNodes] = useState<Node<{ label: string }>[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);
    const [intelligenceSource, setIntelligenceSource] = useState<string | undefined>('');
    const location = useLocation();
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const setGraphDirection = (layout: LayoutDirection) => {
        getStoreService().dispatch(setVisualizationGraphState(layout));
    };

    const setSelectedNodeGraph = (nodeId: string, graphNodes: Node[]) => {
        graphNodes.map((node: Node) => {
            node.data.selected = node.data.id === nodeId;
        });
    };

    const handleNodeClick = async (nodeData: Node) => {
        if (nodeData.selectable) setEntityInUrl(nodeData.data.id, nodeData.data.name);
    };

    useEffect( () => {
        setSelectedNodeGraph(getPropertyFromParams(location, 'entityId'), nodes);
    }, [location, nodes]);

    useEffect(() => {
        setGraphData();

        async function setGraphData(): Promise<void> {
            setNodes([]);
            setEdges([]);

            let graphData;
            try {
                graphData = await getIntelligenceData(expandedFindingDetailsProps, setGraphDirection);
                setIntelligenceSource(expandedFindingDetailsProps.intelligenceSource);
                setLoadingState(LoadingState.LOADING_SUCCEEDED);
            } catch (e) {
                setLoadingState(LoadingState.LOADING_FAILED);
            }

            if (!graphData) return;

            const newGraph = mapToGraphEntities(graphData);

            setEdges(newGraph.edges);
            setNodes(newGraph.nodes);

            const entityName = expandedFindingDetailsProps.finding?.entityName || expandedFindingDetailsProps.finding?.entityExternalId;
            setEntityInUrl(expandedFindingDetailsProps.finding?.entityExternalId, entityName);
        }
    }, [expandedFindingDetailsProps]);

    return (
        <FindingGraphWrapperStyled.TopDiv>
            {(loadingState === LoadingState.IS_LOADING) && <FindingGraphWrapperStyled.SpinnerDiv><Spinner /></FindingGraphWrapperStyled.SpinnerDiv> }
            {(!loadingState || (loadingState === LoadingState.LOADING_SUCCEEDED)) && nodes.length > 0 && (
                <FindingGraphWrapperStyled.ContentDiv>
                    <FindingGraphWrapperStyled.graphDiv className={GRAPH_CLASSES.CONTAINER}>
                        <VisualizationComponent
                            setEdges={ setEdges }
                            setNodes={ setNodes }
                            edges={ edges }
                            nodes={ nodes }
                            onNodeClick={ handleNodeClick }
                            intelligenceSource = { intelligenceSource }
                        />
                    </FindingGraphWrapperStyled.graphDiv>
                    <FindingGraphWrapperStyled.tableDiv>
                        <RelatedEventsTable finding={ expandedFindingDetailsProps.finding }/>
                    </FindingGraphWrapperStyled.tableDiv>
                </FindingGraphWrapperStyled.ContentDiv>
            )}
            {(loadingState === LoadingState.LOADING_FAILED) && nodes.length === 0 &&
                <FindingGraphWrapperStyled.errorDiv>
                    {t('INT_GRAPH.ERROR')}
                </FindingGraphWrapperStyled.errorDiv>
            }
            {(loadingState === LoadingState.LOADING_SUCCEEDED) && nodes.length === 0 &&
                <FindingGraphWrapperStyled.errorDiv>
                    {t('INT_GRAPH.NO_GRAPH')}
                </FindingGraphWrapperStyled.errorDiv>
            }
        </FindingGraphWrapperStyled.TopDiv>
    );
};

export default FindingGraphWrapper;
