import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { isNil } from 'common/utils/helpFunctions';

const BusinessPriorityCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const { businessPriority } = params.data;
    if (isNil(businessPriority)) {
        CellToClipboardHandler(params, '');
        return <></>;
    }
    const options: IIconLinkCellProps = {
        isLink: false,
        value: businessPriority,
    };

    CellToClipboardHandler(params, String(businessPriority));
    return <IconLinkCellRenderer {...options} />;
};

export default BusinessPriorityCellRenderer;