import { Button } from 'common/design-system/components-v2';
import Spinner from 'common/design-system/components-v2/Spinner';
import { FilterStyled } from './DashboardEmptyFilter.styled';

export const DashboardEmptyFilter: React.FC = () => {
    return (
        <FilterStyled.TopDiv>
            <Button
                iconProps={{ name: 'filter' }}
                variant={'text'}>
                <Spinner size={16} />
            </Button>
        </FilterStyled.TopDiv>
    );
};