import React, { useEffect, useState } from 'react';
import OverviewCategory from 'modules/assets/ProtectedAssets/ProtectedAssetsPage/OverviewCategory';
import { ICloudEntityData } from 'common/module_interface/assets/ICloudEntity';
import { OverviewPanelRegistry } from 'common/module_interface/assets/OverviewPanelRegistry';

interface ICategory {
    title: string;
    id: string;
}

const OverviewPanel: React.FC<{ entity: ICloudEntityData }> = ({ entity }) => {
    const [categories, setCategories] = useState<ICategory[]>([]);
    useEffect(() => {
        const registeredCategories = OverviewPanelRegistry.getCategories();
        setCategories(registeredCategories.reduce((prev: ICategory[], category) => {
            if (category.isRelevant === undefined || category.isRelevant(entity)) {
                prev.push({
                    title: category.title,
                    id: category.id,
                });
            }
            return prev;
        }, []));
    }, [entity]);

    return <div data-aid='overview-panel'>
        {categories.map((category) => {
            const innerItems = OverviewPanelRegistry.getCategoryItems(category.id);
            const InnerComponent = (<div>{
                innerItems.reduce((prev: JSX.Element[], item) => {
                    if (item.isRelevant === undefined || item.isRelevant(entity)) {
                        const CategoryItem = item.component;
                        const element = (<div key={item.id}>
                            <CategoryItem entity={entity}></CategoryItem>
                        </div>);
                        prev.push(element);
                    }
                    return prev;
                }, [])}
            </div>);
            return (
                <div key={category.id} className='mb-9' data-aid={category.id}>
                    <OverviewCategory title={category.title} />
                    {InnerComponent}
                </div>
            );
        })}
    </div>;
};

export default OverviewPanel;