import styled from 'styled-components/macro';

export const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 31px;
  align-items: stretch;
`;

export const BodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 26px;
  height: 100%;
  margin-top: 30px;
`;


export const SectionContentDiv = styled.div<{ height: number }>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  align-items: flex-start;
  height: ${props => props.height}px;
  width: 100%;
`;


export const WidgetCardDiv = styled.div<{ width: number; }>`
  height: 100%;
  width: ${props => props.width}%;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
  opacity: 1;
  overflow: hidden;

  .widget-card-title {
    height: 40px;  
  }
  
  .widget-card-body {
    height: 100%;
    .total-widget {
      a {
        text-align: center;
        font: normal normal normal 36px/49px Open Sans;
        letter-spacing: 0;
        color: #000000CC;
        opacity: 1;
      }

      .svg-icon-wrapper {
        height: 32px;
        width: 32px;
      }
    }
  }
  
  .list-widget {
    .ag-cell {
      div {
        overflow: hidden;
      }
    }
  }
`;

export const ExposureStyled = {
    TopDiv,
    BodyDiv,
    WidgetCardDiv,
    SectionContentDiv,
};
