import { changeUrl } from 'common/utils/http';
import { getHttpService, getStoreService } from 'common/interface/services';
import { ociAccountRequest, ociTerraformRequest, terraformTextResponse } from './OciOnboarding.interface';
import fileDownload from 'js-file-download';
import { clearOciOnboardingState } from './OciOnboarding.reducer';
import { apiMessageError } from 'platform/helpers/ApiErrorHandler';

export const createTerraform = async (data: ociTerraformRequest): Promise<void> => {
    return await getHttpService().request(
        'oci-cloud-account/create-terraform',
        { data, method: 'POST' },
        {},
        apiMessageError
    );
};

export const createTerraformAndDowloadZip = async (data: ociTerraformRequest): Promise<void> => {
    return await getHttpService().request(
        'oci-cloud-account/create-terraform',
        { data, responseType: 'blob', method: 'POST' },
        {},
        apiMessageError
    )
        .then((res: any) =>{
            fileDownload(res, 'terraform.zip');
        })
        .catch((err)=>{
            throw err;
        });
};

export const createOciAccont = async (data: ociAccountRequest): Promise<void> => {
    return await getHttpService().request(
        'oci-cloud-account',
        { data, method: 'POST' },
        {},
        apiMessageError);
};

export const getTerraformText = async (): Promise<terraformTextResponse> => {
    return await getHttpService().get('oci-cloud-account/terraform-text');
};

export const exitWizard = () => {
    const { dispatch } = getStoreService().getReduxTools();
    dispatch(clearOciOnboardingState());
    changeUrl('/cloud-account');
};
