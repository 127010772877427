import styled from 'styled-components';


const AzureDefenderForCloudStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AzureDefenderForCloudNameWrapper = styled.div`
  width: 50%;
  margin: 24px ;
`;

const CloudAccountSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 24px;
`;

export {
    AzureDefenderForCloudStyled,
    AzureDefenderForCloudNameWrapper,
    CloudAccountSelectionWrapper
};