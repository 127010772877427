import { ColDef } from 'ag-grid-community';
import { Addin } from 'common/extensibility/AddinRegistry';
import { buildPath, globalAddinContainer } from 'common/extensibility/AddinContainer';
import { IEnvironmentsFilter } from 'common/module_interface/assets/Environments';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import { IRemediationFilterParams } from '../helpers/remediation.interfaces';

const PATH_REMEDIATION_PREFIX = buildPath('remediation', 'remediation_settings');
export const PATH_REMEDIATION_PAGE = buildPath(PATH_REMEDIATION_PREFIX, 'page');
export const PATH_REMEDIATION_PAGE_TABLE_COLUMNS = buildPath(PATH_REMEDIATION_PAGE, 'columns');
export const PATH_REMEDIATION_PAGE_TABLE_FILTERS = buildPath(PATH_REMEDIATION_PAGE, 'filters');
export const RemediationTableRegistry = {
    addColumnDefAddins: (columnDefAddins: Addin<ColDef>[]) => {
        globalAddinContainer.add<ColDef>(PATH_REMEDIATION_PAGE_TABLE_COLUMNS, columnDefAddins);
    },

    addColumnDefs: (columnDefs: ColDef[]) => {
        globalAddinContainer.addMap(PATH_REMEDIATION_PAGE_TABLE_COLUMNS, columnDefs, 'colId');
    },

    getColumnDefs: (): ColDef[] => {
        return globalAddinContainer.get<ColDef>(PATH_REMEDIATION_PAGE_TABLE_COLUMNS);
    },

    addFilterAddins: (filterAddins: Addin<IFilterProps>[]) => {
        globalAddinContainer.add<IFilterProps>(PATH_REMEDIATION_PAGE_TABLE_FILTERS, filterAddins);
    },

    getFilter: (filterId: string, filterParams: IRemediationFilterParams[]): IFilterProps | undefined => {
        return globalAddinContainer.getById<IFilterProps>(PATH_REMEDIATION_PAGE_TABLE_FILTERS, filterId, filterParams);
    },

    // SHOULD BE MOVED TO COMMON
    getFilterDefs(filters: IEnvironmentsFilter[], params: IRemediationFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = RemediationTableRegistry.getFilter(filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    },
};