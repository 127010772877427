import { A } from 'common/components/Anchor/Anchor';
import { Stack, Typography } from 'common/design-system/components-v2';
import styled from 'styled-components/macro';

export const BreadcrumbTypography = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const BreadcrumbsWrapper = styled(Stack)`
    :last-child {
        font-weight: 500;
    }
`;

export const SeperatorWrapper = styled.span`
    padding: 0 4px;
`;

export const UnderlinedA = styled(A)`
    color: ${({ theme }) => theme.palette.status.info};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    :visited, :hover {
        color: ${({ theme }) => theme.palette.status.info};
    }

    :hover {
        cursor: pointer;
        text-decoration: underline;
    }  
`;