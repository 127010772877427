import { RiskLevelServerEnum } from 'common/module_interface/RiskManagement/RiskLevel';

export const MALWARE_LIST = [
    {
        malwareId: 'malware:Trojan.Win32.EICAR.TC.b16cpIiR',
        severity: RiskLevelServerEnum.Critical,
        family: 'Trojan',
        hash: '1234567890',
        scanData: {
            filePath: '/tmp/eicarcom2.zip',
        }
    },
    {
        malwareId: 'malware:Trojan.Win32.EICAR.TC.b16cpAAA',
        severity: RiskLevelServerEnum.High,
        family: 'Virus',
        hash: '1234567888',
        scanData: {
            filePath: '/usr/share/nginx/html/Walke'
        }
    },
    {
        malwareId: 'malware:Trojan.Win32.TOP.TC.b16cpAAA',
        severity: RiskLevelServerEnum.High,
        family: 'Trojan',
        hash: 'q1w2e3r4t5y67uu7i8',
        scanData: {
            filePath: '/usr/share/nginx/html/Walke'
        }
    },
    {
        malwareId: 'malware:Trojan.Win32.EICAR.TC.b16cpIiR',
        severity: RiskLevelServerEnum.Critical,
        family: 'Trojan',
        hash: '1234567890',
        scanData: {
            filePath: '/tmp/eicarcom2.zip',
        }
    },
    {
        malwareId: 'malware:Trojan.Win32.EICAR.TC.b16cpAAA',
        severity: RiskLevelServerEnum.High,
        family: 'Virus',
        hash: '1234567888',
        scanData: {
            filePath: '/usr/share/nginx/html/Walke'
        }
    },
    {
        malwareId: 'malware:Trojan.Win32.TOP.TC.b16cpAAA',
        severity: RiskLevelServerEnum.Medium,
        family: 'Trojan',
        hash: 'q1w2e3r4t5y67uu7i8',
        scanData: {
            filePath: '/usr/share/nginx/html/Walke'
        }
    },
];