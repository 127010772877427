import { APPLICATION_REDUCERS } from 'common/extensibility/WellKnownPaths';
import { IApplicationReducer } from 'common/interface/redux';
import azureReducer from './reducer';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { initialAzureEnvironmentData } from './AzureEnvironment.consts';


const initializeAzureEnvRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'env_azure',
            reducer: azureReducer,
            isBlackList: true,
        },
    ];
    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');
};


export const initializeAzureEnv = () => {
    initializeAzureEnvRedux();
    initialAzureEnvironmentData();
};