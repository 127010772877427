export enum IGNORE_LIST_MODEL_FIELDS {
    ID = '_id',
    NAME = 'name',
    DESCRIPTION = 'description',
    TIMESTAMP = 'timestamp',
    TARGET_EXTERNAL_ID = 'targetExternalId',
    PACKAGE_NAME = 'packageName',
    PACKAGE_PATH = 'packagePath',
    SOURCE_EXTERNAL_ID = 'sourceExternalId',
    SOURCE_ENVIRONMENT_ID = 'sourceEnvironmentId',
    SOURCE_NAME = 'sourceName',
}

export enum IGNORE_LIST_ITEM_FIELDS {
    ID = 'id',
    NAME = 'name',
    DESCRIPTION = 'description',
    EXPIRATION_DATE = 'expirationDate',
    CVE_IDS = 'cveIds',
    PACKAGE_NAMES = 'packageNames',
    PACKAGE_PATHS = 'packagePaths',
    ENTITY_ID = 'entityId',
    ENV_IDS = 'envIds',
    ORG_UNIT_IDS = 'orgUnitIds',
    ENTITY_NAMES = 'entityNames',
}

export enum CommonIgnoreNames {
    name = 'name',
    description = 'description',
    expirationDate = 'expirationDate',
    cveBox = 'cveBox',
    vulBox = 'vulBox',
}

export enum CommonIgnoreInnerNames {
    envIds = 'envIds',
    orgUnitIds = 'orgUnitIds',
    entityId = 'entityId',
    entityNames = 'entityNames',
    orgUnitsRadioOption = 'orgUnitsRadioOption',
    environmentsRadioOption = 'environmentsRadioOption',
    entityNamesRadioOption = 'entityNamesRadioOption',
    entityIdRadioOption = 'entityIdRadioOption',
    cveIds = 'cveIds',
    packageNames = 'packageNames',
    packagePaths = 'packagePaths',
    orgEnvRadio = 'orgEnvRadio',
    entityRadio = 'entityRadio',
}

export const RequiredOneOfCveFields: string[] = [
    CommonIgnoreInnerNames.cveIds,
    CommonIgnoreInnerNames.packageNames,
    CommonIgnoreInnerNames.packagePaths,
];

export const RequiredOneOfVulnerableFields: string[] = [
    CommonIgnoreInnerNames.orgUnitIds,
    CommonIgnoreInnerNames.envIds,
    CommonIgnoreInnerNames.entityNames,
    CommonIgnoreInnerNames.entityId,
];