import { useState, useEffect, useCallback } from 'react';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { IIssueCve } from '../../../../Issues.interface';
import { LoadingState } from 'common/interface/general';
import { getIssueCves } from './IssueVulnerabilities.datasource';
import { FindingSeveritiesMap } from 'common/consts/FindingSeverity';

interface ICveItemsInfo {
    cveItems: IIssueCve[],
    criticalCount: number,
    highCount: number,
    loadingState: LoadingState;
}

const EMPTY_CVES_ITEMS_INFO = () => {
    return {
        cveItems: [],
        criticalCount: 0,
        highCount: 0,
        loadingState: LoadingState.IS_LOADING,
    };
};

const useVulnerabilities = (issue: IIssue, asset: IProtectedAssetViewModel) => {
    const [itemsInfo, setItemsInfo] = useState<ICveItemsInfo>(EMPTY_CVES_ITEMS_INFO());

    const updateData = useCallback((myIssue: IIssue, asset: IProtectedAssetViewModel) => {
        setItemsInfo(EMPTY_CVES_ITEMS_INFO());
        getIssueCves(myIssue, asset).then((cves: IIssueCve[]) => {
            const cveItems = cves.filter(cve => cve.severityLevel >= FindingSeveritiesMap.high.level).sort((c1, c2) => {
                if (c2.severityLevel !== c1.severityLevel) {
                    return c2.severityLevel - c1.severityLevel;
                }
                return (c2.baseScore ?? 0) - (c1.baseScore ?? 0);
            });
            setItemsInfo({
                cveItems,
                criticalCount: cveItems.filter(cve => cve.severityLevel === FindingSeveritiesMap.critical.level).length,
                highCount: cveItems.filter(cve => cve.severityLevel === FindingSeveritiesMap.high.level).length,
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
        }).catch(() => {
            setItemsInfo({
                cveItems: [],
                criticalCount: 0,
                highCount: 0,
                loadingState: LoadingState.LOADING_FAILED,
            });
        });
    }, []);

    useEffect(() => {
        updateData(issue, asset);
    }, [issue, asset, updateData]);

    return { itemsInfo };
};

export default useVulnerabilities;
