import { IRuleset } from 'common/interface/ruleset';
import { getHttpService, IRulesetService } from 'common/interface/services';

const cachingConfig = { useCache: true };
const BASE_URL = '/Compliance/Ruleset';
const APP_LANGUAGE = window.navigator && window.navigator.language; 
export class RulesetService implements IRulesetService {

    async getAllRulesets() {
        try {
            const rulesets = await getHttpService().get<IRuleset[]>({ path: `${BASE_URL}/view`, cachingConfig });
            //if the selected language isn't japanese, remove the japanese rulesets
            const filterRulesets = rulesets.filter((ruleset: IRuleset)=>{
                const isLanguageJapanese = APP_LANGUAGE === 'ja';
                const isIncludedJaRuleset = isLanguageJapanese && ruleset.language === 'ja';
                return (isIncludedJaRuleset || ruleset.language !== 'ja' ) && ruleset.showBundle;
            });

            //remove duplicate rulesets
            const uniqueRulesets = filterRulesets.reduce((unique:IRuleset[], ruleset: IRuleset) => {
                if(!unique.some(obj => obj.id === ruleset.id )) {
                    unique.push(ruleset);
                }
                return unique;
            },[]);

            return uniqueRulesets;
        }catch{
            return [];
        }
         
    }

}
