import {
    DashboardWidgetTypes,
    IDashboard,
} from 'common/module_interface/overview/Interface';
import { v4 } from 'uuid';
import { LOCAL_KUBERNETES_DEFAULT_DASHBOARD } from '../Consts';
import { DEFAULT_ORGANIZATIONAL_UNIT_ID } from 'common/consts/DataConsts';


export default function initializeKubernetesDefaultDashboard() {
    const kubernetesDefaultDashboard: IDashboard = {
        id: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
        name: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
        isLocalDashboard: true,
        sections: [
            {
                isExpanded: true,
                id: 'kubernetesDefault',
                title: 'KUBERNETES DEFAULT',
                dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                position: 1,
                widgets: [
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Accounts,
                        'title': 'Total Environments',
                        'limit': 10,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Environments',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'monitored',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Vulnerabilities - Critical Alerts',
                        'limit': 15,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'ruleName',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'origin',
                                'value': '7'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 11
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Images - Critical Alerts',
                        'limit': 10,
                        'description': 'Images that have Critical Alerts',
                        'hideOnNoData': false,
                        'aggregation': 'entity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'origin',
                                'value': '7'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 11
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Top,
                        'title': 'Clusters - Critical Alerts',
                        'limit': 5,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'cloudAccountId_calc',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'origin',
                                'value': '7'
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 11
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 2,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Summary,
                        'title': 'Critical alerts',
                        'limit': 10,
                        'description': ' Number of critical findings',
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'severity',
                                'value': 'Critical'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 11
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            },
                            {
                                'name': 'free-text',
                                'value': 'CVE'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'thresholds': [
                                {
                                    'min': 0,
                                    'max': 1,
                                    'color': '#56BA0A',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ]
                                },
                                {
                                    'min': 4,
                                    'max': 0,
                                    'color': '#F24E4E',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ]
                                }
                            ],
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Pie,
                        'title': 'Alerts Distribution',
                        'limit': 5,
                        'description': 'Show the distribution of Image Scanning Alerts',
                        'hideOnNoData': false,
                        'aggregation': 'severity',
                        'dataSourceName': 'Alerts',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'entityTypeByEnvironmentType',
                                'value': '11|KubernetesImage'
                            },
                            {
                                'name': 'cloudAccountType',
                                'value': 11
                            },
                            {
                                'name': 'acknowledged',
                                'value': 'false'
                            },
                            {
                                'name': 'isExcluded',
                                'value': 'false'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Alerts',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Gauge,
                        'title': 'Compliance  Gauge - Best Practice',
                        'limit': 5,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Compliance',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'compliance': [
                                {
                                    'type': 'Organizational Unit',
                                    'selected': DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    'rulesetId': -51
                                }
                            ],
                            'thresholds': [
                                {
                                    'min': 0,
                                    'max': 45,
                                    'color': '#F24E4E',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ]
                                },
                                {
                                    'min': 46,
                                    'max': 70,
                                    'color': '#FFD546',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ]
                                },
                                {
                                    'min': 71,
                                    'max': 0,
                                    'color': '#56B40A',
                                    'colorPlate': [
                                        '#333',
                                        '#56B40A',
                                        '#B1DBFF',
                                        '#FFD546',
                                        '#FFB72D',
                                        '#FF9126',
                                        '#F24E4E',
                                        '#E45785'
                                    ]
                                }
                            ],
                            'navigateOption': 'Compliance',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.TrendLineChangeSummary,
                        'title': 'Compliance Trends  - Best Practice',
                        'limit': 5,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': null,
                        'dataSourceName': 'Compliance',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'compliance': [
                                {
                                    'type': 'Organizational Unit',
                                    'selected': DEFAULT_ORGANIZATIONAL_UNIT_ID,
                                    'rulesetId': -51
                                }
                            ],
                            'navigateOption': 'Compliance',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Column,
                        'title': 'Clusters - By Nodes',
                        'limit': 15,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'cloudAccountId',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'platform',
                                'value': 'kubernetes'
                            },
                            {
                                'name': 'type',
                                'value': 'kubernetes|KubernetesNode'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Column,
                        'title': 'Clusters - By Pods',
                        'limit': 15,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'cloudAccountId',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'platform',
                                'value': 'kubernetes'
                            },
                            {
                                'name': 'type',
                                'value': 'kubernetes|KubernetesPod'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    },
                    {
                        id: v4(),
                        sectionId: 'kubernetesDefault',
                        dashboardId: LOCAL_KUBERNETES_DEFAULT_DASHBOARD,
                        'type': DashboardWidgetTypes.Pie,
                        'title': 'Clusters - Asset Distribution',
                        'limit': 5,
                        'description': '',
                        'hideOnNoData': false,
                        'aggregation': 'type',
                        'dataSourceName': 'Protected Assets',
                        'gslFilter': '',
                        'cloudAccountId': null,
                        'timeFrame': 1,
                        'filterState': [
                            {
                                'name': 'platform',
                                'value': 'kubernetes'
                            }
                        ],
                        'options': {
                            id: v4(),
                            'sizes': {
                                'height': 1,
                                'width': 2
                            },
                            'navigateOption': 'Protected Assets',
                            'binSize': '10m'
                        }
                    }
                ]
            },
        ]
    };

    return kubernetesDefaultDashboard;
}