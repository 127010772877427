import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EntityViewer from 'common/components/EntityViewer/EntityViewer';
import Toolbar from './components/Toolbar/Toolbar';
import { EntityViewerProps, Tab } from 'common/components/EntityViewer/EntityViewer.interface';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { LoaderWrapper } from './GcpEnvironment.styled';
import { CloudAnimationLoader } from '@dome9/cloudguard-widgets-components';
import { useGetGcp } from './reducer/hooks/useGetGcp';
import EmptyState from 'common/components/EmptyState/EmptyState';
import { getGcpEnvNamespace } from './initialize.i18n';
import { BlockInfoProps } from 'common/design-system/components-v2/BlockInfo/BlockInfo.types';
import { GcpEnvironmentAddinRegistry } from 'common/helpers/gcpEnvironment';
import { ModalType } from './GcpEnvironment.types';
import Modals from './components/Modals';
import { EnvironmentChip } from 'common/module_interface/assets/Environments';
import useAllCloudAccounts from 'common/hooks/useAllCloudAccounts';
import { IChipProps } from 'common/design-system/components-v2/Chip/Chip.types';

const getAndSetDataFromGcpRegistryController = new AbortController();

const GcpEnvironment: React.FunctionComponent = () => {
    const [modalType, setModalType] = useState<ModalType | null>(null);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation(getGcpEnvNamespace('gcp'));
    const { isLoading, data: gcpData, isError, isDeleted } = useGetGcp(id);
    const [tabsWithData, setTabsWithData] = React.useState<{ [tabId: string]: Tab; }>({});
    const [detailsItems, setDetailsItems] = React.useState<{ [detailId: string]: BlockInfoProps; }>({});
    const [chips, setChips] = React.useState<{ [chipId: string]: IChipProps; }>({});
    const { allCloudAccounts } = useAllCloudAccounts();
    const { setBreadCrumbsLastChildren } = useBreadcrumbsLastChildren();

    React.useEffect(() => {
        if (gcpData?.account.name) {
            setBreadCrumbsLastChildren([gcpData?.account.name]);
        }
    }, [gcpData, setBreadCrumbsLastChildren]);

    React.useEffect(() => {
        if (!gcpData) return;

        const getAndSetTabsWithData = () => {
            const tabs = GcpEnvironmentAddinRegistry.getTabs();
            const tabsFiltered = tabs.filter(tab => {
                return tab.isRelevant ? tab.isRelevant(gcpData) : true;
            });
            tabsFiltered.forEach(tab => {
                new Promise<Tab>((resolve, reject) => {
                    getAndSetDataFromGcpRegistryController.signal.addEventListener('abort', () => reject());
                    Promise.resolve(tab.getValue(gcpData)).then((tabResponse) => {
                        resolve(tabResponse);
                    });
                }).then((tabResponse) => {
                    setTabsWithData(prevState => ({ ...prevState, [tab.id]: tabResponse }));
                }).catch(() => { /* Aborted */ });
            });
        };

        const getAndSetDetails = () => {
            const detailsItems = GcpEnvironmentAddinRegistry.getDetailsPanelItems();
            const detailsFiltered = detailsItems.filter(detail => {
                return detail.isRelevant ? detail.isRelevant(gcpData) : true;
            });
            detailsFiltered.forEach(detail => {
                new Promise<BlockInfoProps>((resolve, reject) => {
                    getAndSetDataFromGcpRegistryController.signal.addEventListener('abort', () => reject());
                    Promise.resolve(detail.getValue(gcpData)).then((detailResponse) => {
                        resolve(detailResponse);
                    });
                }).then((detailResponse) => {
                    setDetailsItems(prevState => ({ ...prevState, [detail.id]: detailResponse }));
                }).catch(() => { /* Aborted */ });
            });
        };

        const getAndSetChips = () => {
            const chipsFromRegistry = GcpEnvironmentAddinRegistry.getChips();
            const chipsFiltered = chipsFromRegistry.filter(detail => {
                return detail.isRelevant ? detail.isRelevant(gcpData) : true;
            });
            chipsFiltered.forEach(chip => {
                new Promise<EnvironmentChip>((resolve, reject) => {
                    getAndSetDataFromGcpRegistryController.signal.addEventListener('abort', () => reject());
                    setChips(prevState => ({ ...prevState, [chip.id]: { label: chip.customLoadingLabel || 'Loading', disabled: true, loading: true } }));
                    Promise.resolve(chip.getValue(gcpData)).then((chipResponse) => {
                        resolve(chipResponse);
                    });
                }).then((chipResponse) => {
                    setChips(prevState => ({ ...prevState, [chip.id]: chipResponse }));
                }).catch(() => { /* Aborted */ });
            });
        };

        getAndSetTabsWithData();
        getAndSetDetails();
        getAndSetChips();

        return () => {
            getAndSetDataFromGcpRegistryController.abort();
            setTabsWithData({});
            setDetailsItems({});
            setChips({});
        };
    }, [id, t, gcpData, allCloudAccounts]);

    const gcpInfoProps: EntityViewerProps | undefined = React.useMemo(() => {
        if (!gcpData) return undefined;
        return ({
            title: gcpData.account.name,
            levelIcon: {
                iconProps: {
                    name: 'gcp'
                },
            },
            titleRightElements:
            <Toolbar
                gcpAccount={gcpData}
                actionClick={(type) => setModalType(type as any)}
            />,
            details: Object.values(detailsItems),
            chips: Object.values(chips),
            tabs: Object.values(tabsWithData),
            isLoading: false,
        });}, [gcpData, detailsItems, chips, tabsWithData]);

    if (isLoading) {
        return (
            <LoaderWrapper>
                <CloudAnimationLoader size='medium' />
            </LoaderWrapper>
        );
    }

    if (isError || isDeleted || !gcpData || !gcpInfoProps) {
        return (
            <EmptyState
                label={t('gcpPage.notFound.title')}
                description={t('gcpPage.notFound.description')}
                links={
                    [{ label: t('gcpPage.notFound.backLink'), url: '/cloud-account', icon: { name: 'arrowLeft' } }]
                }
            />
        );
    }

    return (
        <>
            <EntityViewer {...gcpInfoProps} />
            <Modals
                modalType={modalType}
                closeModal={() => setModalType(null)}
                setModalType={setModalType}
                gcpId={id}
            />
        </>
    );
};

export default GcpEnvironment;
