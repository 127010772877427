import React from 'react';
import { ITrendBoxItem } from '../../../RiskManagement.interface';
import { TrendBoxesStyled } from './TrendBoxes.styled';
import { TrendBox } from './TrendBox';

export const TrendBoxes: React.FC<{ items: ITrendBoxItem[] }> = ({ items }) => {
    return (
        <TrendBoxesStyled.TopDiv>
            { items.map((item: ITrendBoxItem) => <TrendBox item={item} key={`${item.originalColumn.text}-${item.trendColumn.text}`} />) }
        </TrendBoxesStyled.TopDiv>
    );
};
