import { DrawerRegistry } from 'common/components/DrawerInfra/Drawer/DrawerRegistry';
import { IDrawerContent } from 'common/components/DrawerInfra/Drawer/Drawer.interface';
import { SecurityControlViewer } from './SecurityControlViewer';
import { LevelIcon } from 'common/design-system/components-v2';
import { ISecurityControl } from '../SecurityControls.interface';

export const SECURITY_CONTROL_VIEWER_KEY = 'SECURITY_CONTROL_VIEWER';

function initializeDrawerContent() {
    DrawerRegistry.addContentProvider({
        id: SECURITY_CONTROL_VIEWER_KEY,
        getDrawerContent: async ( securityControl: ISecurityControl): Promise<IDrawerContent | undefined> => {
            const result: IDrawerContent = {
                title: securityControl.name,
                icon: <LevelIcon iconProps={{ name: 'rule' }}/>,
                component: SecurityControlViewer,
                componentProps: { securityControl },
            };
            return Promise.resolve(result);
        },
        options: {
            width: 'xl',
        }
    });
}

export function initializeSecurityControlViewer() {
    initializeDrawerContent();
}

