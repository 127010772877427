import React, { useEffect, useState } from 'react';
import { getHttpService, getStoreService } from 'common/interface/services';
import './SpectralPage.scss';
import { changeUrl, toQueryString } from 'common/utils/http';
import EnvironmentVariables from 'common/services/configuration/EnvironmentVariables';
import { t } from 'i18next';
import { getDataCenterDisplayName, getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import cloudGuardLogo from '../../assets/images/cloud-guard.svg';
import { useTranslation } from 'react-i18next';
import { A } from 'common/components/Anchor/Anchor';
import { SPECTRAL_DEFAULT_PATH } from './initialize';

const CLOUD_GUARD_SPECTRAL_NONCE_API = 'user/nonce/spectral';

const SPECTRAL_IFRAME_ID = 'spectral-iframe';
const SPECTRAL_IFRAME_CLASS = 'SpectralContainer';
const SPECTRAL_HIDDEN_CLASS = 'HideSpectralContainer';

const DOME9_LOADING_ELEMENT_TAG = 'cp-loading-dots';
const SPECTRAL_LOADING_ID = 'spectral-loading';
const DOME9_MAIN_CONTENT_SELECTOR = '.routed-page-layout';

const spectralDefaultPageTitleKey = 'NAVIGATION_MENU.SPECTRAL.DEFAULT.TITLE';


export const SpectralPage: React.FC = () => {
    const [showSpectralNotSupportedMessage, setShowSpectralNotSupportedMessage] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        changeUrl(SPECTRAL_DEFAULT_PATH);
        overridePageTitle(spectralDefaultPageTitleKey);
        if (showExistingSpectralIframe()) {
            return;
        }
        const spectralUrl = getSpectralIframeUrl();
        if (spectralUrl) {
            loadNewSpectralIframe();
        } else {
            loadSpectralNotSupportedMessage();
        }

    }, []);

    useEffect(() => hideSpectralIframe, []);


    const loadSpectralNotSupportedMessage = () => {
        setShowSpectralNotSupportedMessage(true);
    };

    const renderNotSupportedMessage = () => {
        const environmentDisplayName = getDataCenterDisplayName() || t('NAVIGATION_MENU.SPECTRAL.THIS_DATACENTER');
        return (
            <div className='flex-center flex-column mt-32'>
                <img className='h-[100px] w-[200px]' src={cloudGuardLogo} />
                <h2 className='font-thin'>{t('NAVIGATION_MENU.SPECTRAL.CLOUDGUARD')}</h2>
                <h5 className='font-thin'>{t('NAVIGATION_MENU.SPECTRAL.SL_NOT_SUPPORTED_MESSAGE', { dataCenter: environmentDisplayName })}</h5>
                <span className='font-thin'>{t('NAVIGATION_MENU.SPECTRAL.SUGGEST_CI_CD_INSTALLATION')}
                    {' '}
                    <A href='/workload/onboarding-shiftleft' className='linkColor'>
                        {t('NAVIGATION_MENU.SPECTRAL.CLICK_HERE')}
                    </A>
                </span>
            </div>
        );
    };

    return (
        <div>
            {showSpectralNotSupportedMessage ? renderNotSupportedMessage() : null}
        </div>
    );
};


const overridePageTitle = (titleKey: string) => {
    document.title = t(titleKey);
};

const showExistingSpectralIframe = () => {

    const exitingSpectralIframe = document.getElementById(SPECTRAL_IFRAME_ID);
    if (!exitingSpectralIframe) {
        return false;
    }

    exitingSpectralIframe.classList.remove(SPECTRAL_HIDDEN_CLASS);
    return true;
};

function getSpectralIframeUrl() {
    return getIsCloudInfra() ?
        EnvironmentVariables.app.REACT_APP_CLOUD_INFRA_SPECTRAL_URL :
        EnvironmentVariables.app.REACT_APP_SPECTRAL_URL;
}

const loadNewSpectralIframe = () => {
    mimicLoading();
    getSpectralNonce().then(nonce => {
        const url = getSpectralIframeUrl();
        const iframeSrc = formatSpectralIframeSrc(url, nonce);
        const iframe = generateSpectralIframe(iframeSrc, stopMimicLoading);

        addSpectralIframe(iframe);
    });
};

const mimicLoading = () => {
    const isAlreadyLoading = document.querySelector(DOME9_LOADING_ELEMENT_TAG + '#' + SPECTRAL_LOADING_ID);
    if (isAlreadyLoading) {
        return;
    }
    const loadingDots = document.createElement(DOME9_LOADING_ELEMENT_TAG);
    loadingDots.id = SPECTRAL_LOADING_ID;
    (document.querySelector(DOME9_MAIN_CONTENT_SELECTOR) as any).appendChild(loadingDots);
};

function getSpectralNonceUrl() {
    const { state } = getStoreService().getReduxTools();

    if (state.user.firstName && state.user.lastName) {
        return CLOUD_GUARD_SPECTRAL_NONCE_API +
            '?' +
            toQueryString({
                query: {
                    firstname: state.user.firstName,
                    lastname: state.user.lastName,
                },
            });
    }
    return CLOUD_GUARD_SPECTRAL_NONCE_API;
}

const getSpectralNonce = () => {
    const spectralNonceUrl = getSpectralNonceUrl();
    return getHttpService().get<string>(spectralNonceUrl);
};

const formatSpectralIframeSrc = (url: string, nonce: string) => {
    return url + '?' + toQueryString({ query: { nonce: nonce } });
};

const stopMimicLoading = () => {
    const existingLoadingDots = document.querySelector(DOME9_LOADING_ELEMENT_TAG + '#' + SPECTRAL_LOADING_ID);
    if (existingLoadingDots) {
        existingLoadingDots.remove();
    }
};

const generateSpectralIframe = (url: string, onIframeLoad: () => void): HTMLIFrameElement => {
    const spectralIframe = document.createElement('iframe');
    spectralIframe.id = SPECTRAL_IFRAME_ID;
    spectralIframe.src = url;
    spectralIframe.className = SPECTRAL_IFRAME_CLASS;
    spectralIframe.addEventListener('load', () => onIframeLoad());

    return spectralIframe;
};

const addSpectralIframe = (spectralIframe: HTMLIFrameElement) => {
    const mainContentContainer = document.querySelector(DOME9_MAIN_CONTENT_SELECTOR);
    if (mainContentContainer) {
        mainContentContainer.appendChild(spectralIframe);
    }
};

const hideSpectralIframe = () => {
    const iframe = document.querySelector(DOME9_MAIN_CONTENT_SELECTOR + ' #' + SPECTRAL_IFRAME_ID);
    if (!iframe) {
        return;
    }

    (iframe as HTMLIFrameElement).classList.add(SPECTRAL_HIDDEN_CLASS);
    stopMimicLoading();
};


export default SpectralPage;

