import { IExternalMenuItem, IMenuSectionItem } from 'common/interface/menu';
import { IMenuService, getStoreService } from 'common/interface/services';
import { getExternalMenuItems, setExternalMenuItems } from './Menus.reducer';

export class MenuService implements IMenuService {
    addMenuItems(mainMenuItemId:string, sectionId: string, items: IMenuSectionItem[]): void {
        const { dispatch } = getStoreService().getReduxTools();
        const externalItem: IExternalMenuItem = {
            mainMenuId: mainMenuItemId,
            sectionId: sectionId,
            items: items,
        };
        dispatch(setExternalMenuItems(externalItem));
    }
    removeExternalMenuItems(itemsIds: string[]): void {
        const { dispatch } = getStoreService().getReduxTools();
        const externalMenuItems = getExternalMenuItems();
        const filterExternalMenuItems = externalMenuItems.reduce((result:IExternalMenuItem[] , externalMenuItem) => {
            const filteredItems = externalMenuItem.items.filter(item=> !itemsIds.includes(item.id));
            const newExternalItem: IExternalMenuItem = {
                mainMenuId: externalMenuItem.mainMenuId,
                sectionId: externalMenuItem.sectionId,
                items: filteredItems,
            };
            result.push(newExternalItem);
            return result;
        }, []);
        filterExternalMenuItems.forEach(externalItem=> {
            dispatch(setExternalMenuItems(externalItem));
        });
    }
}