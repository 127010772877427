import React from 'react';
import './KeyValuePairs.scss';
import { KeyValue } from '../../BusinessPriority.interface';
import { useTranslation } from 'react-i18next';
import { TextField } from '@dome9/berries/react-components';
import { Icon } from 'common/design-system/components-v2';
import { I18nRiskNamespace } from 'modules/riskManagement/consts';

const KeyValuePairs: React.FC<{
    handleInput: Function;
    deleteItem: Function;
    addItem: Function;
    pairs: KeyValue[] | null;
    disabled: boolean;
    className: string;
}> = ({ pairs, disabled, className, handleInput, deleteItem, addItem }) => {
    const { t } = useTranslation();
    return (
        <div className={'key-value-pairs-container ' + className}>
            <div className='items'>
                {pairs?.map((keyValuePair: KeyValue, index: number) => (
                    <div className='item flex items-center' key={keyValuePair.id}>
                        <TextField
                            disabled={disabled}
                            value={keyValuePair.key || ''}
                            placeholder={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.KEY_TAG', { ns: I18nRiskNamespace })}
                            onChange={(e: any) => handleInput('key', e.target.value, index)}
                            maxLength={127}
                            className='key-item'
                            onClear={()=> handleInput('key', null, index)}
                        />
                        <TextField
                            disabled={disabled}
                            value={keyValuePair.value || ''}
                            placeholder={t('RISK_MANAGEMENT.BUSINESS_PRIORITY.RULE.VALUE_TAG', { ns: I18nRiskNamespace })}
                            onChange={(e: any) => handleInput('value', e.target.value, index)}
                            maxLength={256}
                            className='value-item'
                            onClear={()=> handleInput('value', null, index)}
                        />
                        {index && !disabled ? (
                            <span className='cursor-pointer' onClick={() => deleteItem(keyValuePair.id)}>
                                <Icon name='remove' size={16}/>
                            </span>
                        ) : (
                            pairs.length > 1 && !disabled && <div className='w-[16px]'/>
                        )}
                    </div>
                ))}
            </div>
            {!disabled && (
                <div title='Add key value' onClick={() => addItem()} className='rounded-full cursor-pointer border p-[4px] bg-canvas self-start mt-6 mb-5 pb-0 h-[26px] w-[26px]'>
                    <Icon name='plus' />
                </div>
            )}
        </div>
    );
};

export default KeyValuePairs;
