import { IEnvironmentsServices } from './types';
import { IFilterProps } from 'common/components/FilterPanel/FilterPanelManager';
import {
    IEnvironmentsFilter,
    IEnvironmentsFilterParams,
    PATH_ENVIRONMENTS_FILTERS_DEFINITIONS
} from 'common/module_interface/assets/Environments';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

export const EnvironmentsServices: IEnvironmentsServices = {

    getFilterDefs(filters: IEnvironmentsFilter[], params: IEnvironmentsFilterParams): IFilterProps[] {
        const result: IFilterProps[] = [];
        filters.forEach((filter) => {
            const matchedFilterDef = globalAddinContainer.getById<IFilterProps>(PATH_ENVIRONMENTS_FILTERS_DEFINITIONS, filter.id, [params]);
            if (matchedFilterDef) {
                const filterDef = { ...matchedFilterDef };
                filterDef.filterProps = { ...filterDef.filterProps, ...filter.filterProps };
                result.push(filterDef);
            }
        });
        return result;
    }
};