import { initializeEventsActions } from './initialize.events';
import { ExclusionModuleType } from 'common/interface/exclusion';
import ExclusionsModalCspm from './Modals/ExclusionsModalCspm';
import ExclusionsModalCiem from './Modals/ExclusionsModalCiem';
import ExclusionsModalVulnerabilities from './Modals/ExclusionsModalVulnerabilities';
import ExclusionsModalRuntimeProtection from './Modals/ExclusionsModalRuntimeProtection';
import ExclusionsModalServerless from './Modals/ExclusionsModalServerless';
import { ExclusionRegistry } from 'common/module_interface/exclusion/exclusionRegistry';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { initializeI18nExclusion } from './initialize.i18n';
import { EXCLUSIONS_SERVICE_ID } from 'common/interface/services';
import { ExclusionsService } from './services/ExclusionsServices';

const initializeModal = () => {
    ExclusionRegistry.addExcludeModals([
        {
            id: ExclusionModuleType.CSPM,
            content: {
                id: ExclusionModuleType.CSPM,
                component: ExclusionsModalCspm
            }
        },
        {
            id: ExclusionModuleType.CIEM,
            content: {
                id: ExclusionModuleType.CIEM,
                component: ExclusionsModalCiem
            }
        },
        {
            id: ExclusionModuleType.ImageAssurance,
            content: {
                id: ExclusionModuleType.ImageAssurance,
                component: ExclusionsModalVulnerabilities
            }
        },
        {
            id: ExclusionModuleType.ContainersRuntimeProtection,
            content: {
                id: ExclusionModuleType.ContainersRuntimeProtection,
                component: ExclusionsModalRuntimeProtection
            }
        },
        {
            id: ExclusionModuleType.Serverless,
            content: {
                id: ExclusionModuleType.Serverless,
                component: ExclusionsModalServerless
            }
        }
    ]);
};

const initializeExclusionsServices = () => {
    globalAddinContainer.addService(EXCLUSIONS_SERVICE_ID, new ExclusionsService());
};

export default function initialize() {
    initializeEventsActions();
    initializeModal();
    initializeI18nExclusion();
    initializeExclusionsServices();
}
