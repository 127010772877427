import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

const PanelTopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const PanelTitleDiv = styled.div`
  display: flex;
  align-items: center;
  color: #354764;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;  
`;

export const ViewerStyled = {
    TopDiv,
    PanelTopDiv,
    PanelTitleDiv,
};