

import FilterPanel from 'common/components/FilterPanel/FilterPanel';
import { FILTER_EVENTS } from 'common/components/FilterPanel/FilterPanel.consts';
import { IFiltersValues, ILastEvent } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    IFilterProps,
    renderFilters,
    setFiltersInitialValues
} from 'common/components/FilterPanel/FilterPanelManager';
import { getCloudAccountsService, getOrganizationalUnitService } from 'common/interface/data_services';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IFiltersInitialData, RulesetDTO, complianceFilterDefsAddins } from '../FilterDefinitions';
import { TextField } from '@dome9/berries/react-components';
import { IDashboardWidget } from 'common/module_interface/overview/Interface';
import { getHttpService } from 'common/interface/services';


const ComplianceGridWidgetsSettings: React.FC<{ widget: IDashboardWidget, updateWidgetCallback: Function, onFilterPanelAsyncChangeFinished?: Function, onFilterPanelAsyncChangedStarted?: Function }> = ({ widget, updateWidgetCallback, onFilterPanelAsyncChangeFinished, onFilterPanelAsyncChangedStarted }) => {

    const defaultThresholds = [
        { min: 0, max: 10, color: '#F24E4E' },
        { min: 11, max: 85, color: '#FFD546' },
        { min: 86, max: 100, color: '#56B40A' },
    ];
    if(!widget.options.thresholds) {
        widget.options.thresholds = defaultThresholds;
    }
    const [filterPanelComponent, setFilterPanelComponent] = useState(<div></div>);
    const [isLoading, setIsLoading] = useState(true);
    const [thresholds, setThresholds] = useState(widget.options.thresholds);
    const [filtersInitialDataState, setFiltersInitialDataState] = useState<IFiltersInitialData | null>(null);
    const { t } = useTranslation();

    const getFiltersInitialData = async () => {
        const organizationalUnitsPromise = getOrganizationalUnitService().getOrganizationalUnitsView();
        const allCloudAccountsPromise = getCloudAccountsService().getAllCloudAccounts();
        const rulsetsPromise: Promise<RulesetDTO[]> = getHttpService().get({ path: 'Compliance/Ruleset/view', cachingConfig: { useCache: true } });

        return Promise.all([ organizationalUnitsPromise, allCloudAccountsPromise, rulsetsPromise]).then(
            ([ organizationalUnits, allCloudAccounts, rulsets]) => {
                return {
                    organizationalUnits,
                    allCloudAccounts,
                    rulsets,
                };
            },
        );
    };

    const extractNewFilters = (filtersValues: IFiltersValues)=>{
        return Object.keys(filtersValues).reduce((result: { name: string, value: any }[], key: string) => {
            if(Array.isArray(filtersValues[key])) {
                filtersValues[key].forEach((valueItem: string) => {
                    result.push({
                        name: key, value: valueItem
                    });
                });
            } else {
                result.push({
                    name: key, value: filtersValues[key]
                });
            }
            return result;
        }, []);
    };


    const initFilterPanelItem = useCallback((filterPanelElementsList: IFilterProps[] ): JSX.Element => {
        let firstRender = true;
        const allFiltersList = renderFilters(filterPanelElementsList);

        const filtersFromWidget = widget.filterState?.reduce((result: any, filterItem: any) => {
            filterItem.name === 'account' && (filterItem.name = 'cloudAccountId');
            if(result[filterItem.name]) {
                result[filterItem.name].push(filterItem.value);
            } else {
                result[filterItem.name] = (filterItem.value || filterItem.value === 0) && filterItem.name !== 'organizationalUnitId' ? [filterItem.value] : filterItem.value;
            }
            return result;
        }, {});
        const initialFiltersValues = setFiltersInitialValues(filtersFromWidget || {}, allFiltersList);


        const handleFilterChanged = async ({ action, filtersValues }: ILastEvent) => {
            switch (action) {
                case FILTER_EVENTS.FILTER_CHANGED: {
                    if(!firstRender) {
                        widget = { ...widget,filterState: filtersValues && extractNewFilters(filtersValues) } ;
                        updateWidgetCallback(widget);
                    }
                    firstRender = false;

                    return filtersInitialDataState;
                }

                default:
                    return false;
            }

        };

        return <FilterPanel filterPanelProps={{ filters: initialFiltersValues, onFilterPanelChange: handleFilterChanged, onFilterPanelAsyncChangeFinished: onFilterPanelAsyncChangeFinished, onFilterPanelAsyncChangedStarted }} />;
    }, [updateWidgetCallback, widget, filtersInitialDataState]);

    const renderFilterPanel = useCallback(async () => {
        const complianceGridFilters: string[] = [ 'add-filter', 'organizationalUnitId', 'platform', 'cloudAccountId', 'clear-button' ];
        if(!filtersInitialDataState){
            const _filtersInitialData = await getFiltersInitialData();
            setFiltersInitialDataState(_filtersInitialData);
            setIsLoading(false);
        }

        const allFilters = filtersInitialDataState && complianceFilterDefsAddins.map(addin => {
            if(typeof addin.content === 'function'){
                return addin.content(filtersInitialDataState);
            } else {
                return addin.content;
            }
        });

        const filterPanelElementsList = allFilters?.filter(filter => complianceGridFilters.includes(filter.filterProps?.key));
        const filterPanel = filterPanelElementsList && initFilterPanelItem( filterPanelElementsList );

        filterPanel && setFilterPanelComponent(filterPanel);
    }, [ initFilterPanelItem, filtersInitialDataState]);


    useEffect(() => {
        renderFilterPanel();
    },[renderFilterPanel]);

    const changeThresholdsValue = (e: ChangeEvent<HTMLInputElement>, index: number)=> {
        const newThresholds = widget.options.thresholds ? [...widget.options.thresholds]: thresholds;
        newThresholds[index].max = e.target.valueAsNumber;
        widget.options.thresholds = newThresholds;
        setThresholds(newThresholds);
        updateWidgetCallback(widget);
    };

    return (
        <div className='mt-8'>
            <div className="font-semibold mb-5">{t('GENERAL.THRESHOLDS')}:</div>
            <div className='flex flex-col justify-start items-center mb-5'>
                <div className='w-full mb-5 flex items-center'>
                    <div style={{ backgroundColor: thresholds[0].color }} className='w-8 h-8 rounded-full mr-5'></div>
                    <div className={'flex items-baseline'}>
                        <div className='flex-1 ml-6 mr-4'>
                            0
                        </div>
                        <div className='flex-1 ml-2 mr-4'>
                            -
                        </div>
                        <div className='flex-1 z-0 max-w-[100px]'>
                            <TextField
                                value={thresholds[0].max}
                                type='number'
                                allowClear={false}
                                onChange={(e: ChangeEvent<HTMLInputElement>)=> changeThresholdsValue(e, 0)} />
                        </div>
                    </div>
                </div>
                <div className='w-full mb-5 flex items-center'>
                    <div style={{ backgroundColor: thresholds[1].color }} className='w-8 h-8 rounded-full mr-5'></div>
                    <div className={'flex items-baseline'}>
                        <div className='flex-1 mx-4'>
                            {thresholds[0].max + 1}
                        </div>
                        <div className='flex-1 ml-2 mr-4'>
                            -
                        </div>
                        <div className='flex-1 z-0 max-w-[100px]'>
                            <TextField
                                type='number'
                                onChange={(e: ChangeEvent<HTMLInputElement>)=> changeThresholdsValue(e, 1)}
                                value={thresholds[1].max} allowClear={false} />
                        </div>
                    </div>
                </div>
                <div className='w-full flex items-center'>
                    <div style={{ backgroundColor: thresholds[2].color }} className='w-8 h-8 rounded-full mr-5'></div>
                    <div className={'flex items-baseline'}>
                        <div className='flex-1 mx-4 my-5'>
                            {thresholds[1].max + 1}
                        </div>
                        <div className='flex-1 ml-2 mr-4'>
                            -
                        </div>
                        <div>
                            100
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ?
                <span>{t('GENERAL.LOADING_FILTERS')}</span>
                :
                <div>
                    <div className="font-semibold mb-2">{t('GENERAL.FILTERS')}:</div>
                    {
                        filterPanelComponent
                    }
                </div>
            }
        </div>
    );
};


export default ComplianceGridWidgetsSettings;
