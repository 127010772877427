import React from 'react';
import { RiskDashboardStyled } from '../RiskDashboard.styled';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { ITotalSearchResults } from '../RiskDashborad.interface';
import SimpleDottedHeader from '../TopHeaders/SimpleDottedHeader';
import { useTranslation } from 'react-i18next';
import { RiskiestEntitiesStyled } from './RiskiestEntities.styled';
import RiskiestEnvironments from './RiskiestEnvironments/RiskiestEnvironments';
import { RiskiestAssets } from './RiskiestAssets/RiskiestAssets';
import { RiskiestAssetTypes } from './RiskiestAssetTypes/RiskiestAssetTypes';
import { I18nRiskNamespace } from '../../../../consts';

const RiskiestEntities: React.FC<{ filterValues?: IFiltersValues, totalSearchResults: ITotalSearchResults }> = ({ filterValues, totalSearchResults }) => {
    const { t } = useTranslation();
    return (
        <RiskDashboardStyled.TopSection>
            <SimpleDottedHeader title={t('RISK_MANAGEMENT.DASHBOARD.RISKIEST_ENTITIES',{ ns: I18nRiskNamespace })} />
            <RiskiestEntitiesStyled.TopDiv>
                <RiskiestEntitiesStyled.RiskiestAsseTypesDiv>
                    <RiskiestAssetTypes filterValues={filterValues} ></RiskiestAssetTypes>
                </RiskiestEntitiesStyled.RiskiestAsseTypesDiv>
                <RiskiestEntitiesStyled.RiskiestAssetsDiv>
                    <RiskiestAssets totalSearchResults={totalSearchResults} filterValues={filterValues} />
                </RiskiestEntitiesStyled.RiskiestAssetsDiv>
                <RiskiestEntitiesStyled.TopEnvironmentsDiv>
                    <RiskiestEnvironments filterValues={filterValues} />
                </RiskiestEntitiesStyled.TopEnvironmentsDiv>
            </RiskiestEntitiesStyled.TopDiv>
        </RiskDashboardStyled.TopSection>
    );
};

export default RiskiestEntities;
