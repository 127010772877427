import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { SeverityLabel } from 'common/components/SeverityLabel/SeverityLabel';
import { ISeverityContainer } from '../RiskManagement.interface';
import { getIssueSeverityInfo } from '../components/Issues/IssueSeverity';

export const IssueSeverityCellRender: React.FC<ICellRendererParams<ISeverityContainer>> = (params) => {
    const severity: string | number | undefined = params.data?.severity;
    if (severity === undefined) {
        return null;
    }
    const severityInfo = getIssueSeverityInfo(severity);
    if (!severityInfo) {
        return null;
    }

    return <SeverityLabel severityInfo={severityInfo} />;
};