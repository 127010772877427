import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
`;

const SepDiv = styled.div`
  width: 1px;
  height: 100%;
  border-left: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
  margin: 0 80px;
`;

export const EmptyStyle = {
    TopDiv,
    SepDiv,
};
