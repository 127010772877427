import { CpIcon, CpSecondaryMessage, CpSpinner } from '@dome9/components/react/components';
import 'ag-grid-enterprise/styles/ag-grid.css';
import { AgGridReact } from 'ag-grid-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './AWSOnboarding.scss';
import { getIcon, StatusRenderer } from './components/Components';
import { getBladeStatusesAndSaveItToState } from './helpers/aws.actions';
import { BladeState, BladeStateTotal, BladeStatus } from './helpers/aws.interface';
import {
    getAwsOnboardingId,
    getBladeStateTotals,
    getBladeStatuses,
    getIsTakingLongerThanExpectedSummary,
    getSelectedModuleByUser,
    getSelectedModuleInSummary,
    setSelectedModuleByUser,
    setSelectedModuleInSummary,
} from './helpers/aws.reducer';
import { ColDef, GridApi, IRowNode } from 'ag-grid-enterprise';
import { GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { TECHNICAL_SUPPORT } from 'common/urls';

const SUCCESS = 'success';
const ERROR = 'error';
const AWSOnboardingSummary: FC = () => {
    const [gridApi, setGridApi] = useState<GridApi>();
    const [selectedNode, setSelectedNode] = useState<IRowNode>();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const totals: BladeStateTotal = useSelector(getBladeStateTotals);
    const awsOnboardingId = useSelector(getAwsOnboardingId);
    const selectedModule = useSelector(getSelectedModuleInSummary);
    const selectedModuleByUser = useSelector(getSelectedModuleByUser);
    const isTakingLongerThanExpectedSummary = useSelector(getIsTakingLongerThanExpectedSummary);

    const blades = useSelector(getBladeStatuses);

    useEffect(() => {
        getBladeStatusesAndSaveItToState();
    }, []);

    const columnDefs: ColDef[] = useMemo( () => {
        return [{
            tooltipField: 'environment',
            flex: 1,
            field: 'environment',
            headerName: t('ON_BOARDING.SUMMARY.ENVIRONMENT'),
        },
        {
            tooltipField: 'blade',
            flex: 1,
            field: 'blade',
            headerName: t('ON_BOARDING.SUMMARY.MODULE'),
        },
        {
            tooltipField: 'feature',
            flex: 1,
            field: 'feature',
            headerName: t('ON_BOARDING.SUMMARY.FEATURE'),
        },
        {
            flex: 3,
            tooltipField: 'statusMessage',
            field: 'status',
            headerName: t('ON_BOARDING.SUMMARY.STATUS'),
            cellRenderer: 'StatusRenderer',
        }];
    }, [t]);

    useEffect(() => {
        if (gridApi) {
            gridApi.forEachNode((node: IRowNode, index) => {
                if (index === 0 && !selectedModuleByUser) {
                    dispatch(setSelectedModuleInSummary(node.data));
                    node.setSelected(true);
                } else {
                    selectedNode?.rowIndex === index && node.setSelected(true);
                }
            });
        }
    }, [selectedNode, blades, gridApi, dispatch, selectedModuleByUser]);

    const defaultRow = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    const generalModule: BladeStatus | undefined = blades?.find((blade) => blade.feature === 'General');

    const getOneStatusError = () => blades.some((blade) => blade.status === 'ERROR');

    function getGeneralOnboardingStatus(status: BladeState) {
        switch (status) {
            case BladeState.ACTIVE:
                return SUCCESS;
            case BladeState.ERROR:
                return ERROR;
        }
        return null;
    }

    const generalOnboardingStatus = getGeneralOnboardingStatus(BladeState[generalModule?.status!]);

    const getModuleStatusMessage: () => string | undefined = (): string | undefined =>
        generalModule?.environment && generalOnboardingStatus === SUCCESS
            ? getOneStatusError()
                ? t('ON_BOARDING.SUMMARY.SOME_MODULES_FAILED_TO_DEPLOY', {
                    environmentName: generalModule?.environment,
                })
                : t('ON_BOARDING.SUMMARY.SUCCESS_AWS', { environmentName: generalModule?.environment })
            : generalModule?.statusMessage;

    //
    return (
        <div className='aws-onboarding-summary'>
            <div className='title-large'>{t('ON_BOARDING.SUMMARY.TITLE')}</div>
            {!generalOnboardingStatus && isTakingLongerThanExpectedSummary && (
                <CpSecondaryMessage className='process-status-header' centerMessage={false} type={'warning'}>
                    <span>{t('ON_BOARDING.ACCOUNT.TAKING_LONGER_THAN_EXPECTED')}</span>
                </CpSecondaryMessage>
            )}
            {generalOnboardingStatus && (
                <CpSecondaryMessage
                    className='process-status-header'
                    centerMessage={false}
                    type={generalOnboardingStatus}>
                    <span>{getModuleStatusMessage()}</span>
                </CpSecondaryMessage>
            )}
            <div className='aws-onboarding-summary-status-wrapper'>
                {totals &&
                    Object.entries(totals).map((total) => {
                        return (
                            <div key={total.toString()} className='aws-onboarding-summary-status'>
                                {total[0] === 'PENDING' && total[1] > 0 ? (
                                    <div className='aws-onboarding-summary-status flex-column-center'>
                                        <div className='flex'>
                                            <CpSpinner />
                                            <div className='aws-onboarding-summary-status-number title-large'>
                                                {total[1]}
                                            </div>
                                        </div>
                                        <div className='text-body'>{total[0]}</div>
                                    </div>
                                ) : total[0] !== 'PENDING' ? (
                                    <div className='aws-onboarding-summary-status flex-column-center'>
                                        <div className='flex'>
                                            <CpIcon
                                                className={`aws-onboarding-summary-status-icon aws-onboarding-summary-status-icon-${total[0]}`}
                                                icon={getIcon(total[0] as BladeState)}
                                            />
                                            <div className='aws-onboarding-summary-status-number title-large'>
                                                {total[1]}
                                            </div>
                                        </div>
                                        <div className='text-body'>{total[0]}</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        );
                    })}
            </div>
            <div className='aws-onboarding-summary-wrapper'>
                <div className='table-wrapper'>
                    <AgGridReact
                        rowSelection='single'
                        onRowClicked={(row) => {
                            setSelectedNode(row.api.getSelectedNodes()[0]);
                            row.node.setSelected(true);
                            dispatch(setSelectedModuleByUser(true));
                        }}
                        onRowSelected={(row: RowSelectedEvent) => {
                            if (row.node.isSelected()) {
                                dispatch(setSelectedModuleInSummary(row.data as BladeStatus));
                            }
                        }}
                        onGridReady={defaultRow}
                        enableBrowserTooltips={true}
                        className='ag-theme-alpine'
                        rowData={blades}
                        columnDefs={columnDefs}
                        components={{ StatusRenderer: StatusRenderer }}>
                    </AgGridReact>
                </div>
                <div className='status-details'>
                    {selectedModule && (
                        <>
                            <div className='status-section'>
                                <p>{t('ON_BOARDING.SUMMARY.MODULE')}</p>
                                <p>{selectedModule.blade}</p>
                            </div>
                            <div className='status-section'>
                                <p>{t('ON_BOARDING.SUMMARY.STATUS')}</p>

                                <div className='status'>
                                    {selectedModule.status !== 'PENDING' && (
                                        <CpIcon
                                            icon={getIcon(selectedModule.status)}
                                            className={`aws-onboarding-summary-status-icon-${selectedModule.status} aws-onboarding-welcome-info-content aws-onboarding-summary-info-icon`}
                                        />
                                    )}
                                    {selectedModule.status === 'PENDING' && (
                                        <CpSpinner
                                            className='aws-onboarding-welcome-info-content aws-onboarding-summary-info-icon' />
                                    )}
                                    <div
                                        className='aws-onboarding-welcome-info-content aws-onboarding-summary-info-content'>
                                        {selectedModule.status}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {selectedModule?.status === 'ERROR' && (
                        <>
                            <div className='status-section'>
                                <p>{t('ON_BOARDING.SUMMARY.ERROR_DETAILS')}</p>
                                <strong>{selectedModule.statusMessage}</strong>
                            </div>
                            <div className='status-section'>
                                <p>{t('ON_BOARDING.SUMMARY.HOW_TO_RESOLVE')}</p>
                                <a rel='noreferrer' target='_blank' href={TECHNICAL_SUPPORT}>
                                    {t('ON_BOARDING.SUMMARY.CLICK_HERE_TO_TECH_SUPPORT')}{' '}
                                </a>
                                <p>Onboarding Id: {awsOnboardingId}</p>
                                <p>{selectedModule.remediationRecommendation}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default AWSOnboardingSummary;
