import { ChangeEvent, FC, useEffect, useState } from 'react';
import { I18nExclusion, TagsEnum } from '../../helpers/exclusions.consts';
import { useTranslation } from 'react-i18next';
import { Stack, Button, Label, Input } from 'common/design-system/components-v2';
import { IExclusionTagsProps, ITags } from '../../helpers/exclusions.interfaces';
import { formatTags } from '../../helpers/exclusions.utils';


const ExclusionByTags: FC<IExclusionTagsProps> = ({ isEnabled, onChange, selectedOption }) => {
    const { t } = useTranslation(I18nExclusion);
    const [tagList, setTagList] = useState<ITags[]>([]);

    useEffect(() => {
        if(Array.isArray(selectedOption) && selectedOption?.length){
            const formattedTags = formatTags(selectedOption as string[]);
            setTagList(formattedTags);
        }
    }, []);

    useEffect(() => {
        tagList?.length && onChange(tagList);
    }, [tagList]);

    const handleAddTagClick = () => {
        setTagList([...tagList, { key: '', value: '' }]);
    };

    const handleRemoveTag = (index: number) => {
        const updatedTagList = tagList.filter((tag, i) => i !== index);
        setTagList(updatedTagList);
    };

    const handleTagsChange = (index: number, TagsEnum: string, e: ChangeEvent<HTMLInputElement> ) => {
        const updatedTagList = tagList.map((tag, i) => {
            if(i === index){
                return { ...tag, [TagsEnum]: e.target.value };
            }
            return tag;
        });
        setTagList(updatedTagList);
    };

    const tagListItems = tagList?.length>0 && tagList.map((tag, index) => {
        return (
            <Stack key={index} direction={'row'} spacing={3} padding={[0,0,0,6]} alignItems={'flex-end'} fullWidth>
                <Input value={tag.key} onChange={(e)=>handleTagsChange(index,TagsEnum.KEY, e)} label={index===0 ? t('MODAL.TOPICS.TAGS.KEY'): undefined}
                    placeholder={t('MODAL.TOPICS.TAGS.ENTER_KEY')} fullWidth />
                <Input value={tag.value} onChange={(e)=>handleTagsChange(index,TagsEnum.VALUE, e)} label={index===0 ? t('MODAL.TOPICS.TAGS.VALUE'): undefined}
                    placeholder={t('MODAL.TOPICS.TAGS.ENTER_KEY')} fullWidth />
                <Button variant={'outlined'} onClick={()=>handleRemoveTag(index)} iconProps={{ name: 'remove' }}
                    fullWidth={false} style={{ height: '32px', width: '32px' }} />
            </Stack>
        );
    });

    return (
        <Stack spacing={2}>
            <Label text={t('MODAL.TOPICS.TAGS.TITLE')} />
            {tagListItems}
            <Stack padding={[0,0,0,6]}>
                <Button variant={'outlined'} onClick={()=>handleAddTagClick()} label={t('MODAL.TOPICS.TAGS.ADD_TAG')}
                    disabled={!isEnabled} iconProps={{ name: 'plus' }} />
            </Stack>
        </Stack>
    );
};
export default ExclusionByTags;