import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Modal, SelectV2, Stack, Typography } from 'common/design-system/components-v2';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { IFieldError } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { FormFields } from './ChangeSeverityModal.const';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import {
    IChangeSeverityRequest,
    IFindingKeysByCloudAccountId
} from 'common/module_interface/intelligence/Intelligence.interface';
import { IChangeSeverityModalEditableProps, IChangeSeverityModalProps } from './ChangeSeverityModal.types';
import { getFindingKeysByCloudAccountId } from '../../../Findings.utils';
import {
    FindingSeverityServerEnum,
    getSafeFindingSeverityInfo, getSeveritiesSelectOptions
} from 'common/consts/FindingSeverity';
import { isEmpty } from 'lodash';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';

export const ChangeSeverityModal: React.FC<IChangeSeverityModalProps> = (props: IChangeSeverityModalProps) => {
    const { findings, closeModal, fieldErrorsProps } = props;
    const { addFieldErrors, clearFieldErrors, getFieldErrorText } = fieldErrorsProps;
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const initSeverity: string | undefined = useMemo(() => {
        if (isEmpty(findings)) return;
        const firstFindingSeverityName: string = findings[0].severityName;
        if (findings.length === 1) {
            return firstFindingSeverityName;
        }
        return findings.every((finding: IFinding) => finding.severityName === firstFindingSeverityName) ? firstFindingSeverityName : undefined;
    }, [findings]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const [severity, setSeverity] = useState(initSeverity);

    const getSelectedSeverity = useCallback((): FindingSeverityServerEnum => {
        const severityString: string = severity || EMPTY_STRING;
        return getSafeFindingSeverityInfo(severityString).serverKey;
    }, [severity]);

    const severityFieldErrorText: string | undefined = useMemo(() => {
        return getFieldErrorText(FormFields.SEVERITY);
    }, [getFieldErrorText]);

    const updateSeverityFieldError = (errors: IFieldError[]) => {
        const severity: FindingSeverityServerEnum = getSelectedSeverity();
        if (isEmpty(severity) || severity === FindingSeverityServerEnum.Unknown) {
            errors.push({
                field: FormFields.SEVERITY,
                label: t('CHANGE_SEVERITY_MODAL.INPUT_FIELDS.SEVERITY.ERROR')
            });
        }
    };

    const updateFieldsErrorState = (): boolean => {
        const errors: IFieldError[] = [];
        updateSeverityFieldError(errors);
        addFieldErrors(errors);
        return errors.length > 0;
    };

    const onSeverityChange = (value: string | undefined) => {
        clearFieldErrors([FormFields.SEVERITY]);
        setSeverity(value);
    };

    const onConfirmationModalCancel = () => {
        setIsConfirmationModalOpen(false);
    };

    const onDiscardChanges = () => {
        closeModal();
    };

    const onChangeSeverityModalCancel = () => {
        if (hasModalPropsChanged()) {
            return setIsConfirmationModalOpen(true);
        } else {
            closeModal();
        }
    };

    const prepareChangeSeverityRequest = (): IChangeSeverityRequest => {
        const findingsKeysByCloudAccountId: IFindingKeysByCloudAccountId[] = getFindingKeysByCloudAccountId(findings);
        return { findings: findingsKeysByCloudAccountId, severity: getSelectedSeverity() };
    };

    const onChangeSeverityButtonClick = async () => {
        const isAnyFieldError: boolean = updateFieldsErrorState();
        if (isAnyFieldError) return;

        const request: IChangeSeverityRequest = prepareChangeSeverityRequest();
        try {
            setIsLoading(true);
            await getIntelligenceService().changeSeverity(request);
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                text: t('CHANGE_SEVERITY_MODAL.CHANGE_SEVERITY_SUCCESS')
            });
            closeModal();
        } catch (message: unknown) {
            const errorTitle = t('CHANGE_SEVERITY_MODAL.CHANGE_SEVERITY_FAILED');
            getNotificationsService().addNotification({ type: NotificationType.ERROR, text: errorTitle });
            await getLoggerService().error(`${errorTitle} ${message as string}`);
        } finally {
            setIsLoading(false);
            props.refreshTableData();
        }
    };

    const prepareModalEditableProps = useCallback((): IChangeSeverityModalEditableProps => {
        return {
            severity: getSelectedSeverity(),
        } as IChangeSeverityModalEditableProps;
    }, [getSelectedSeverity]);
    const initialModalEditablePropsRef: React.MutableRefObject<IChangeSeverityModalEditableProps> = useRef<IChangeSeverityModalEditableProps>(prepareModalEditableProps());

    const hasModalPropsChanged = useCallback(() => {
        const currModalProps: IChangeSeverityModalEditableProps = prepareModalEditableProps();
        return (JSON.stringify(initialModalEditablePropsRef.current) !== JSON.stringify(currModalProps));
    }, [prepareModalEditableProps]);

    return (
        <Fragment>
            <Modal.ModalDialog onRequestClose={onChangeSeverityModalCancel} isOpen={true} width={'md'}>
                <Modal.ModalHeader title={t('CHANGE_SEVERITY_MODAL.TITLE')}
                    onClose={onChangeSeverityModalCancel}/>
                <Modal.ModalContent fullHeight={true}>
                    <SelectV2
                        options={getSeveritiesSelectOptions()}
                        isMulti={false}
                        required={true}
                        clearable={true}
                        label={t('CHANGE_SEVERITY_MODAL.INPUT_FIELDS.SEVERITY.TITLE')}
                        placeholder={t('CHANGE_SEVERITY_MODAL.INPUT_FIELDS.SEVERITY.PLACEHOLDER')}
                        isError={!!severityFieldErrorText}
                        helperText={severityFieldErrorText}
                        onChange={onSeverityChange}
                        value={severity}
                    />
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        <Button key={'cancel-button-action'} variant='text'
                            onClick={onChangeSeverityModalCancel}>{i18n.t('COMMON.CANCEL')}</Button>
                        <Button key={'change-severity-button-action'} color='brandPrimary' loading={isLoading}
                            disabled={isLoading}
                            onClick={onChangeSeverityButtonClick}>{t('CHANGE_SEVERITY_MODAL.ACTIONS.CHANGE_SEVERITY')}</Button>
                    </Stack>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
            {isConfirmationModalOpen &&
                <ConfirmationModal width={'md'}
                    isOpen={isConfirmationModalOpen}
                    title={t('ACTIONS.CONFIRMATION_MODAL.TITLE')}
                    submitBtnText={i18n.t('COMMON.DISCARD')}
                    onConfirm={onDiscardChanges}
                    onClose={onConfirmationModalCancel}
                    onCancel={onConfirmationModalCancel}>
                    <Typography>{t('ACTIONS.CONFIRMATION_MODAL.DESCRIPTION')}</Typography>
                </ConfirmationModal>}
        </Fragment>
    );
};
