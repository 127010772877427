import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';
import dayjs from 'dayjs';
import { refreshToken } from 'platform/auth/Auth.actions';
import {
    getStoreService,
    getWebAppIframeService,
    IHttpService,
    IHttpServiceConfig,
} from 'common/interface/services';
import ApiCaching from '../../common/utils/apiCaching';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import { getCsrf } from 'common/utils/http';
import { getRouteFromPath, runRequest } from 'platform/services/httpServiceUtils';
import { addGAEvent } from '../3rdParty/googleAnalytics/googleAnalyticsInitialize';

const API_HEADERS = {
    'Content-Type': 'application/json',
    'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
    'Cache-Control': 'no-cache',
    reactHeader: 'x',
    Pragma: 'no-cache',
};

class HttpService implements IHttpService {
    refreshTokenPromise?: Promise<any>;
    defaultErrorHandler: <T> (error: AxiosError<T>) => void;
    apiPrefix: string;
    apiCaching: ApiCaching;

    constructor(apiPrefix: string, errorHandler: <T> (error: AxiosError<T>) => void) {
        this.defaultErrorHandler = errorHandler;
        this.apiPrefix = apiPrefix;
        this.apiCaching = new ApiCaching();
    }

    private async method<T>(
        method: Method,
        path: string | IHttpServiceConfig, requestObject?: AxiosRequestConfig, serviceConfig?: IHttpServiceConfig,
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T> {
        if (typeof path !== 'string') {
            serviceConfig = path as IHttpServiceConfig;
            path = serviceConfig.path as string;
            requestObject = serviceConfig.requestObject;
            if (path === undefined) {
                throw new Error(`The path is mandatory when making a request with a service config object ${serviceConfig}`);
            }
        }

        return this.request(
            path,
            { ...(requestObject ?? {}), method },
            serviceConfig,
            customHandleError,
        );
    }

    public async post<T>(path: string | IHttpServiceConfig, requestObject?: AxiosRequestConfig, serviceConfig?: IHttpServiceConfig, customHandleError?: (error: AxiosError<T>) => T) {
        if (typeof path !== 'string') {
            serviceConfig = path as IHttpServiceConfig;
            path = serviceConfig.path as string;
            requestObject = serviceConfig.requestObject;
            if (path === undefined) {
                throw new Error(`The path is mandatory when making a request with a service config object ${serviceConfig}`);
            }
        }
        return this.request<T>(
            path,
            { ...(requestObject ?? { data: {} }), method: 'POST' },
            serviceConfig,
            customHandleError,
        );
    }

    public async delete<T>(path: string | IHttpServiceConfig, requestObject?: AxiosRequestConfig, serviceConfig?: IHttpServiceConfig, customHandleError?: (error: AxiosError<T>) => T): Promise<T> {
        return this.method<T>('DELETE', path, requestObject, serviceConfig, customHandleError);
    }

    public async put<T>(path: string | IHttpServiceConfig, requestObject?: AxiosRequestConfig, serviceConfig?: IHttpServiceConfig, customHandleError?: (error: AxiosError<T>) => T): Promise<T> {
        return this.method<T>('PUT', path, requestObject, serviceConfig, customHandleError);
    }

    public async get<T>(path: string | IHttpServiceConfig, requestObject?: AxiosRequestConfig, serviceConfig?: IHttpServiceConfig, customHandleError?: (error: AxiosError<T>) => T): Promise<T> {
        return this.method<T>('GET', path, requestObject, serviceConfig, customHandleError);
    }

    public async request<T,P=any>(
        path: string,
        request: AxiosRequestConfig<P> = {},
        serviceConfig: IHttpServiceConfig = {},
        customHandleError?: (error: AxiosError<T>) => T,
    ): Promise<T> {
        const { publicMode, returnAsAxiosResponse, cachingConfig } = serviceConfig;
        const state = getStoreService().state;
        const headers: AxiosRequestConfig['headers'] = this.buildHeaders(
            path,
            request,
        );
        if (!publicMode) {
            headers.csrf = getCsrf();
            const tokenValid =
                state.auth.tokenLocalExpirationTime &&
                dayjs(new Date(state.auth.tokenLocalExpirationTime as string))
                    .subtract(90, 'seconds')
                    .isAfter(dayjs());
            if (headers.csrf && !tokenValid) {
                if (this.refreshTokenPromise) {
                    await this.refreshTokenPromise;
                } else {
                    this.refreshTokenPromise = refreshToken();
                    await this.refreshTokenPromise;
                    this.refreshTokenPromise = undefined;
                }
            }
        }

        const route = getRouteFromPath(path, this.apiPrefix, request.baseURL);
        const method = request.method?.toUpperCase() ?? 'GET';
        const promiseCreator = () => {
            return axios(route, { withCredentials: true, headers, ...request });
        };

        try {
            const response = await runRequest<T,P>(this.apiCaching, route, method, promiseCreator, request.data, cachingConfig);
            addGAEvent('API', path);
            return returnAsAxiosResponse ? response as any : response.data;
        } catch (error: any) {
            if (customHandleError) {
                return customHandleError(error);
            } else {
                this.defaultErrorHandler(error);
                throw error;
            }
        }
    }

    private buildHeaders(path: string, requestObject: AxiosRequestConfig) {
        const headers = requestObject.headers ?? Object.assign({}, API_HEADERS);
        if (path.startsWith('http')) {
            delete headers.reactHeader;
        }
        return headers;
    }

    public async getIsSessionAlive(): Promise<boolean> {
        try {
            await this.request('settings', {}, {}, (error) => {
                const errorCode = error?.response?.status || -1;
                if (errorCode === 401 || errorCode === 403) {
                    throw new Error(
                        'Authentication error while testing if the session is alive',
                    );
                }
            });
            return true;
        } catch (err) {
            return false;
        }
    }

    public clearCacheByTag(tag: string, method?: string, skipSendToWebapp?: boolean) {
        this.apiCaching.clearCacheByTag(tag, method);
        if (!skipSendToWebapp) {
            getWebAppIframeService().emitMessage(new IframeMessageModel({ action: IFRAME_MESSAGE_ACTIONS.CLEAR_CACHE, data: { tag } }));
        }
    }

    public clearCacheByPath(path: string, method = 'GET', baseUrl?: string) {
        const route = getRouteFromPath(path, this.apiPrefix, baseUrl);
        this.apiCaching.clearCacheByRoute(route, method);
    }
}

export default HttpService;
