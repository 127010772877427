import React, { useMemo } from 'react';
import { Stack } from 'common/design-system/components-v2';
import {
    ClientFilterPageTable,

} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable';
import { getCdrExclusionsColumns } from './CdrExclusionsTable.columns';
import { getCdrExclusionsService } from '../FindingsTable/Modals/CdrExclusion/CdrExclusionService';
import { ICdrExclusion } from '../FindingsTable/Modals/CdrExclusion/CdrExclusion.interface';
import { getCdrExclusionsTableActions } from './CdrExclusionsTable.actions';
import { CDR_EXCLUSIONS_PAGE_TABLE_ID } from '../FindingsTable/Modals/CdrExclusion/CdrExclusion.consts';
import {
    IClientFilterPageTableProps
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';

export const CdrExclusionsPage: React.FC = () => {
    const pageProps: IClientFilterPageTableProps<ICdrExclusion> = useMemo(() => ({
        fetchAllItems: () => getCdrExclusionsService().getAllCdrExclusions(),
        getTableColumnDefs: () => getCdrExclusionsColumns(),
        getTableActions: (onTableDataChange: () => void) => getCdrExclusionsTableActions(onTableDataChange),
        pageTableId: CDR_EXCLUSIONS_PAGE_TABLE_ID,
    }), []);

    return (
        <Stack direction={'column'} padding={5} fullHeight fullWidth>
            <ClientFilterPageTable {...pageProps} />;
        </Stack>
    );
};
