import { useCallback, useEffect, useState } from 'react';
import { IFieldInfo, IValueCount, LoadingState } from 'common/interface/general';
import { DASHBOARD_CACHE_CONFIG } from '../../../../RiskManagement.utils';
import { ASSET_FIELD_NAMES } from '../../../../consts';
import { getDataFromServer } from 'common/components/ProtectedAssets/datasource';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    DataAssetsCategory,
    getAllCategoriesSortedMap,
    getCategoryIdByType,
} from '../../../../DataAssetCategories';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

interface DataAssetsState {
    loadingState: LoadingState;
    categories: { [p: string]: number } | undefined;
    onCategoryClicked: (cat: DataAssetsCategory) => void;
}

const useDataAssets = (filterValues?: IFiltersValues): DataAssetsState => {

    const [categories, setCategories] = useState<{ [p: string]: number }>();
    const [loadingState, setLoadingState] = useState(LoadingState.IS_LOADING);

    const onCategoryClicked = useCallback((categoryId: DataAssetsCategory) => {
        const currentFilterFields = [...(filterValues?.fields || [])];
        const newFilterFields: IFieldInfo[] = [];

        currentFilterFields.forEach(filter => {
            if (filter.name !== ASSET_FIELD_NAMES.type) {
                newFilterFields.push(filter);
            } else if (getCategoryIdByType(filter.value) === categoryId) {
                newFilterFields.push(filter);
            }
        });
        getErmUrlsService().goToProtectedAssetsTable(newFilterFields.length > 0 ? newFilterFields : undefined);
    },[filterValues]);

    const groupByCategory = useCallback((aggregations: IValueCount[]):{ [p: string]: number } => {
        const result = { ...getAllCategoriesSortedMap() };
        aggregations.forEach((agg) => {
            const categoryId = getCategoryIdByType(agg.value);
            if (categoryId) {
                result[categoryId as DataAssetsCategory] += agg.count;
            } else {
                console.error(`No Data Asset category found for type: ${agg.value}`);
            }
        });
        return result;
    },[]);

    const loadDataAssetsAggregations = useCallback(async () => {
        try {
            const response = await getDataFromServer(
                {
                    filter: {
                        fields: [... (filterValues?.fields || [])],
                    },
                    pageSize: 0,
                    aggregations: [ASSET_FIELD_NAMES.type]
                },
                DASHBOARD_CACHE_CONFIG
            );
            const typeAggregations: IValueCount[] = response.aggregations[ASSET_FIELD_NAMES.type] || [];
            const widgetData = groupByCategory(typeAggregations);
            setCategories(widgetData);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        } catch (error) {
            setLoadingState(LoadingState.LOADING_FAILED);
            setCategories(getAllCategoriesSortedMap());
        }
    },[groupByCategory, filterValues]);

    useEffect(() => {
        setLoadingState(LoadingState.IS_LOADING);
        if (filterValues) {
            void loadDataAssetsAggregations();
        }
    }, [filterValues, loadDataAssetsAggregations]);

    return { categories, loadingState, onCategoryClicked };
};

export default useDataAssets;