import React, { useEffect, useMemo, useState } from 'react';
import { SummaryStatus } from 'common/erm-components/custom/SummaryStatus/SummaryStatus';
import { useTranslation } from 'react-i18next';
import { LoadingState } from 'common/interface/general';
import { fetchSummaryStatusData } from '../CdrDashboard.datasource';
import { ISummaryStatusData } from '../CdrDashboard.interface';
import CoverageStatus from './CoverageStatus/CoverageStatus';
import SecurityEventsStatus from './SecurityEventsStatus/SecurityEventsStatus';
import { i18nIntelligenceNamespace } from '../../initialize.i18n';

const CdrSummaryStatus: React.FC = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.IS_LOADING);
    const [statusData, setStatusData] = useState<ISummaryStatusData>();

    const loadData = async () => {
        fetchSummaryStatusData().then((data: ISummaryStatusData) => {
            setStatusData(data);
            setLoadingState(LoadingState.LOADING_SUCCEEDED);
        }).catch(() => {
            setStatusData(undefined);
            setLoadingState(LoadingState.LOADING_FAILED);
        });
    };

    useEffect(() => {
        void loadData();
    }, []);

    const summaryComponents = useMemo(() => {
        if (!statusData) {
            return [];
        }
        return [<CoverageStatus data={statusData.coverageData} key={'coverage'}/>,
            <SecurityEventsStatus items={statusData.securityEventsData} key={'securityEvents'}/>
        ];
    }, [statusData]);


    return <SummaryStatus components={summaryComponents} title={t('CDR_DASHBOARD.SUMMARY_STATUS.TITLE')} loadingState={loadingState} />;
};

export default CdrSummaryStatus;
