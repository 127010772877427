import styled from 'styled-components/macro';

const List = styled.ol`
    list-style-type: decimal;
`;

const AsComponent = styled.span`
    ::before {
        content: '<';
    }
    ::after {
        content: '>';
    }
`;

export default {
    List,
    AsComponent
};
