import styled from 'styled-components';


const SnsSystemComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleButtonWrapper = styled.div`
  display: flex; 
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;

const TopicWrapper = styled.div`
  display: flex; 
  justify-content: space-between;
  align-items: center;
`;

const SnsCancelSubmitButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px;   
`;

export {
    SnsSystemComponentStyled, ToggleButtonWrapper, TopicWrapper, SnsCancelSubmitButtonWrapper
};