import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import PropertyBoxesRow from 'common/components/PropertyBox/PropertyBoxesRow';
import PropertyBox from 'common/components/PropertyBox/PropertyBox';
import TextPropertyBox from 'common/components/PropertyBox/TextPropertyBox';
import { useTranslation } from 'react-i18next';
import { IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import i18n from 'i18next';
import { I18nRiskNamespace } from 'modules/riskManagement/consts';
import { BaseStyled } from './IssueBasicPropertiesPanel.styled';
import { getFullIssueStatus } from 'modules/riskManagement/components/Issues/Issues.utils';
import { isNil } from 'common/utils/helpFunctions';
import { SeverityLabel } from 'common/components/SeverityLabel/SeverityLabel';
import { getSafeIssueSeverityInfo, IIssueSeverityInfo } from 'modules/riskManagement/components/Issues/IssueSeverity';

export const IssueBasicPropertiesPanel: React.FC<{ issue: IIssue }> = ({ issue }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const severityLabel = useMemo(() => {
        const severityInfo: IIssueSeverityInfo | undefined = issue.severity ? getSafeIssueSeverityInfo(issue.severity) : undefined;
        return severityInfo ? <SeverityLabel severityInfo={severityInfo} /> : undefined;
    }, [issue]);
    const createdDateText = useMemo(() => dayjs(issue.createdTime).format(i18n.t('COMMON.DATE_FORMATS.LONG_FORMAT')), [issue]);
    const updatedDateText = useMemo(() => dayjs(issue.updatedTime).format(i18n.t('COMMON.DATE_FORMATS.LONG_FORMAT')), [issue]);

    return (
        <BaseStyled.TopDiv>
            <PropertyBoxesRow>
                {!isNil(severityLabel) && <PropertyBox title={t('ISSUES.ISSUE_VIEWER.PROPERTIES.SEVERITY')} content={severityLabel} />}
                <TextPropertyBox title={t('ISSUES.ISSUE_VIEWER.PROPERTIES.ID')} value={issue.id} />
                <TextPropertyBox title={t('ISSUES.ISSUE_VIEWER.PROPERTIES.CREATED')} value={createdDateText} />
                <TextPropertyBox title={t('ISSUES.ISSUE_VIEWER.PROPERTIES.STATUS')} value={getFullIssueStatus(issue)} />
                <TextPropertyBox title={t('ISSUES.ISSUE_VIEWER.PROPERTIES.UPDATED')} value={updatedDateText} />
            </PropertyBoxesRow>
            <BaseStyled.SepDiv>
                <BaseStyled.SepLine />
            </BaseStyled.SepDiv>
        </BaseStyled.TopDiv>
    );
};
