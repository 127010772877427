import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { insightNamespace } from '../../consts/i18n';
import { DisclaimerWrapperProps } from '../Disclaimer.interface';
import AiDisclaimer from './AiDisclaimer';
import { Button } from '@dome9/berries/react-components';

const AiDisclaimerWrapper: React.FC<DisclaimerWrapperProps> = (props: DisclaimerWrapperProps) => {
    const { user, onDisapproval, children } = props;
    const [approved, setApproved] = useState<boolean | undefined>();

    useEffect(() => {
        const approved = localStorage.getItem(`aidisclaimer.${user}`);
        if (approved === 'true') setApproved(true);
        if (approved === 'false') setApproved(false);
    }, [user]);

    return approved ? <>{ children }</> :
        <div className={'px-9 pb-9'}>
            <AiDisclaimer />

            <div className={'flex flex-center'}>
                <span>{`${i18n.t('AI.DISCLAIMER.APPROVAL_MESSAGE', { ns: insightNamespace })}`}</span>
                <Button className={'ml-6'} onClick={() => {
                    localStorage.setItem(`aidisclaimer.${user}`, 'true');
                    setApproved(true);
                }}>{i18n.t('AI.DISCLAIMER.APPROVE', { ns: insightNamespace })}</Button>
                <Button className={'ml-6 hidden'} onClick={() => {
                    localStorage.setItem(`aidisclaimer.${user}`, 'false');
                    setApproved(true);
                    if (onDisapproval) onDisapproval();
                }}>{i18n.t('AI.DISCLAIMER.DISAPPROVE', { ns: insightNamespace })}</Button>
            </div>
        </div>;
};

export default AiDisclaimerWrapper;