import React from 'react';
import { IHeaderParams } from 'ag-grid-community';
import { IRiskLevelInfo } from 'common/module_interface/RiskManagement/RiskLevel';
import { SeverityLabel } from 'common/components/SeverityLabel/SeverityLabel';

export const RiskLevelHeaderRenderer: React.FC<IHeaderParams & { riskLevelInfo: IRiskLevelInfo }> = (params) => {
    if (!params.riskLevelInfo) {
        return null;
    }

    return <SeverityLabel severityInfo={params.riskLevelInfo} />;
};
