import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Select, Tooltip } from '@dome9/berries/react-components';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { changeUrl } from 'common/utils/http';
import { PATHS } from '../../paths.const';
import { PerimeterExposureHeaderProps } from '../Models/PerimeterExposureHeaderProps';
import { SelectOption } from '../Models/SelectOption';
import { useParams } from 'react-router-dom';
import { getCloudAccountsService, ICloudAccount } from 'common/interface/data_services';
import { getVendor, Vendors } from 'common/consts/vendors';
import { getAssets } from '../Services/HttpService';
import { getAssetExposure, getAttackVector } from '../../PerimeterExposure/Services/PerimeterExposureHttpService';
import { GraphData } from '../../PerimeterExposure/Models/GraphData';
import { SUPPORTED } from '../../SupportedAssets';
import { SingleValue } from 'react-select';

const Ec2PerimeterExposureHeaderComponent: React.FC<PerimeterExposureHeaderProps> = (props: PerimeterExposureHeaderProps) => {
    const { setSelectedEc2Srl, setGraphGetter } = props;

    const contextGraphModeOption = { value: 'context', label: 'Context Graph' };
    const attackVectorModeOption = { value: 'attackVector', label: 'Attack Vector' };
    const [graphModeOptions] = useState<SelectOption[]>([contextGraphModeOption, attackVectorModeOption]);
    const [selectedGraphMode, setSelectedGraphMode] = useState<SelectOption>(contextGraphModeOption);
    const [environmentOptions, setEnvironmentOptions] = useState<SelectOption[]>([]);
    const [selectedEnvironment, setSelectedEnvironment] = useState<SelectOption>({ value: 'env', label: t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENVIRONMENT.HEADER') });
    const [assetOptions, setAssetOptions] = useState<SelectOption[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<SelectOption>({ value: 'asset', label: 'Asset' });

    const { urlGraphMode, urlEnvironmentId, urlAssetSrl } = useParams<{ urlGraphMode: string, urlEnvironmentId: string, urlAssetSrl: string }>();

    useEffect(() => { // on screen load, set environment dropdown with all environments
        getEnvironmentOptions().then(setEnvironmentOptions);

        async function getEnvironmentOptions(): Promise<SelectOption[]> {
            const allEnvironments = await getCloudAccountsService().getAllCloudAccounts();
            const awsCloudAccounts = allEnvironments
                .filter(environment => SUPPORTED.VENDORS.includes(environment.platform as Vendors))
                .map(e => {
                    const platform = getVendor(e.platform);
                    const value = e.id;
                    const label = `${platform?.displayName} - ${getEnvironmentLabel(e)}`;

                    return { value: value, label: label };
                });

            sortSelectOptionsAlphabetically(awsCloudAccounts);
            return awsCloudAccounts;
        }
    }, []);

    useEffect(() => { // if graph mode supplied in url, choose it
        if (!urlGraphMode) return;

        const graphModeOption = graphModeOptions.find(e => e.value === urlGraphMode);
        if (!graphModeOption) return;

        const graphGetter = graphModeOption.value === 'context' ? getAssetExposure : getAttackVector;
        setGraphGetter((() => graphGetter) as unknown as ((srl: string) => Promise<GraphData>));
        setSelectedGraphMode(graphModeOption);
    }, [setGraphGetter, graphModeOptions, urlGraphMode]);

    useEffect(() => { // if environment id supplied in url, choose it
        if (!urlEnvironmentId) return;

        const environmentOption = environmentOptions.find(e => e.value === urlEnvironmentId);
        if (!environmentOption) return;

        setSelectedEnvironment(environmentOption);
    }, [environmentOptions, urlEnvironmentId]);

    useEffect(() => { // if asset srl is supplied in url, choose it
        if (!urlAssetSrl) return;

        const ec2Option = assetOptions.find(e => e.value === urlAssetSrl);
        if (!ec2Option) return;

        setSelectedEc2Srl(urlAssetSrl);
        setSelectedAsset(ec2Option);
    }, [assetOptions, urlAssetSrl, setSelectedEc2Srl]);

    useEffect(() => { // on environment selection, update ec2 dropdown
        getEc2Options(selectedEnvironment?.value).then(setAssetOptions);

        async function getEc2Options(environmentId?: string): Promise<SelectOption[]> {
            if (!environmentId) return [];

            const ec2s = await getAssets(environmentId);
            const options = ec2s.map(e => {
                return { value: e.srl!, label: `${e.name} (${e.type})` };
            });

            sortSelectOptionsAlphabetically(options);
            return options;
        }
    }, [selectedEnvironment]);

    return <div style={{ display: 'flex' }}>
        <div style={{ width: '150px', display: 'inline-block', marginTop: '-3px' }}>
            <Select
                required={false}
                closeMenuOnSelect
                isSearchable
                options={ graphModeOptions }
                onChange={ (option: SingleValue<SelectOption>) => {
                    if (!option) return;
                    setSelectedGraphMode(option);
                    const graphGetter = option.value === 'context' ? getAssetExposure : getAttackVector;
                    setGraphGetter((() => graphGetter) as unknown as ((srl: string) => Promise<GraphData>));
                    setNewUrl(
                        option.value,
                        selectedEnvironment.value === 'env' ? undefined : selectedEnvironment.value,
                        selectedAsset.value === 'asset' ? undefined : selectedAsset.value
                    );
                } }
                defaultValue={contextGraphModeOption}
                value={ selectedGraphMode }
            />
        </div>
        <div style={{ width: '450px', display: 'inline-block', marginLeft: '20px', marginTop: '-3px' }}>
            <Select
                required={false}
                closeMenuOnSelect
                isSearchable
                options={ environmentOptions }
                onChange={ async (option: SingleValue<SelectOption>) => {
                    if (!option) return;
                    setSelectedEnvironment(option);
                    setSelectedAsset({ value: 'asset', label: 'Asset' });
                    setSelectedEc2Srl(undefined);
                    setNewUrl(selectedGraphMode.value, option.value);
                } }
                placeholder={ t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENVIRONMENT.HEADER') }
                value={ selectedEnvironment }
            />
        </div>

        <div style={{ width: '450px', display: 'inline-block', marginLeft: '20px', marginTop: '-3px' }}>
            <Select
                required={false}
                closeMenuOnSelect
                isSearchable
                options={ assetOptions ?? [] }
                onChange={ (option: SingleValue<SelectOption>) => {
                    if (!option) return;
                    setSelectedEc2Srl(option.value);
                    setSelectedAsset(option);
                    setNewUrl(selectedGraphMode.value, selectedEnvironment?.value, option?.value);
                } }
                placeholder={ 'Asset' }
                value={ selectedAsset }
            />
        </div>

        <div style={{ width: '150px', display: 'inline-block', marginLeft: '20px' }}>
            <Tooltip
                content='Clearing the displayed entities from the graph'
                aria-label='add'>
                <Button onClick={ () => {
                    setSelectedEnvironment({ value: 'env', label: t('ASSETS.PROTECTED_ASSETS.COLUMNS.ENVIRONMENT.HEADER') });
                    setSelectedAsset({ value: 'ec2', label: getProtectedAssetsService().getAssetByType('Instance')?.displayName ?? '' });
                    setSelectedEc2Srl(undefined);
                    setSelectedGraphMode(contextGraphModeOption);
                    setNewUrl(contextGraphModeOption.value);
                } }>Clear Graph</Button>
            </Tooltip>
        </div>
    </div>;

    function setNewUrl(graphMode: string, environmentId?: string, ec2Srl?: string): void {
        let newUrl = `/${PATHS.INSIGHT}/${PATHS.PERIMETER_EXPOSURE}`;
        newUrl += `/${graphMode}`;
        if (environmentId) {
            newUrl += `/${environmentId}`;
        }
        if (ec2Srl) {
            newUrl += `/${ec2Srl}`;
        }

        changeUrl(newUrl);
    }

    function getEnvironmentLabel(environment: ICloudAccount): string {
        return environment.name && environment.externalId && environment.name !== environment.externalId ?
            `${environment.name} (${environment.externalId})` :
            environment. name ?
                environment.name :
                environment.externalId;
    }

    function sortSelectOptionsAlphabetically(selectOptions: SelectOption[]): void {
        selectOptions.sort((selectOptionA, selectOptionB) => selectOptionA.label.toLowerCase() > selectOptionB.label.toLowerCase() ? 1 : -1);
    }
};

export default Ec2PerimeterExposureHeaderComponent;