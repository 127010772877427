import { FC, useCallback } from 'react';
import { AlertIcon, IssueAlertContainer } from './IntegrationIssueAlertComponent.styled';
import { Button, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nNotifications } from '../../NotificationsPage.consts';
import Stack from 'common/design-system/components-v2/Stack';
import Link from 'common/design-system/components-v2/Link';
import { useTheme } from 'styled-components';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { INTEGRATION_DRAWER, IntegrationsDefinition } from 'common/module_interface/settings/integrations/consts';
import { INTEGRATIONS_DRAWER_VIEWS } from '../../../Integrations/Configurations/ConfigurationDrawerViews';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { getIntegrationsService, getNotificationPageService } from 'common/interface/services';
const misconfiguredConfigurationDocs = 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=misconfigured_notification';
export interface IntegrationIssueAlertProps{
    integrationIssueCleared:()=>void,
    notificationId:string,
    integrationId:string,
    integrationType:string
}
export const IntegrationIssueAlertComponent: FC<IntegrationIssueAlertProps> = ({ integrationIssueCleared, notificationId, integrationId, integrationType }) => {
    const { t } = useTranslation(I18nNotifications);
    const theme = useTheme();
    const getIntegration = (integrationId: string) : IntegrationsDefinition | undefined => {
        const allIntegrations = IntegrationsRegistry.getIntegrationsDefinitions();
        return allIntegrations.find(integration => {
            return integration.id === integrationId;
        });
    };
    const integration = getIntegration(integrationId);

    const openIntegrationDrawer = useCallback(async () => {
        const allConfigurations = await getIntegrationsService().getAllConfigurations(false);
        if(integration){
            showDrawer(INTEGRATION_DRAWER.key, {
                integrationID: integration?.id,
                view: INTEGRATIONS_DRAWER_VIEWS.LIST_VIEW,
                title: integration?.title,
                icon: integration?.icon,
                componentProps: { configurations: allConfigurations[integration.id || ''] }
            });
        }
    },[integration]);

    const validateIntegration = useCallback(async () => {
        await getNotificationPageService().clearIntegrationIssueInCircuitBreaker(notificationId, integrationType);
        integrationIssueCleared && integrationIssueCleared();
    },[integrationType, integrationIssueCleared, notificationId]);
    return(<IssueAlertContainer>
        <Stack direction={'row'} spacing={1}>
            <AlertIcon name={'remove'} customColor={theme.palette.severity.criticalFg} />
            <Typography variant={'body500'} >{integrationType}</Typography>
            <Typography variant={'body500'} >{t('MODALS.ADD_EDIT_MODAL.ISSUE')}:</Typography>
        </Stack>
        <Typography >{t('MODALS.ADD_EDIT_MODAL.ISSUE_MESSAGE')}</Typography>
        <Stack direction={'row'} spacing={2}>
            <Button onClick={openIntegrationDrawer} variant={'outlined'}>{t('MODALS.ADD_EDIT_MODAL.OPEN_CONFIGURATION')}</Button>
            <Button onClick={validateIntegration} variant={'outlined'}>{t('MODALS.ADD_EDIT_MODAL.VALIDATE')}</Button>
        </Stack>
        <Link externalUrl={misconfiguredConfigurationDocs} ><Typography color={'link'} >{t('MODALS.ADD_EDIT_MODAL.LEARN_MORE')}</Typography></Link>
    </IssueAlertContainer>);
};