import { getDataAssetsFilterFields } from './DataSecurityFilterPanel/DataSecurityFilterPanel';
import { ASSET_FIELD_NAMES, CONTEXT_FIELD_FULL_NAMES, DATA_SENSITIVITY_VALUES } from '../../../consts';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IEnvironmentsEmptyStateInfo } from '../../../RiskManagement.interface';
import { IFieldInfo, IValueCount } from 'common/interface/general';
import { fetchErmAggregations } from '../../../RiskManagement.utils';


export const fetchHasSensitivityInfo = async (filterFields?: IFieldInfo[]): Promise<boolean> => {
    const aggregations: Aggregations = await fetchErmAggregations(
        [CONTEXT_FIELD_FULL_NAMES.dataSensitivity],
        {
            fields: [
                ...(filterFields ?? []),
                ...getDataAssetsFilterFields(),
            ],
        }
    );

    const dataSensitivityCounters: IValueCount[] | undefined = aggregations[CONTEXT_FIELD_FULL_NAMES.dataSensitivity];
    return dataSensitivityCounters?.some(counter => {
        return (counter.value === DATA_SENSITIVITY_VALUES.NOT_SENSITIVE) || (counter.value === DATA_SENSITIVITY_VALUES.SENSITIVE);
    });
};

export const fetchIsFullEmptyState = async (): Promise<boolean> => {
    return !await fetchHasSensitivityInfo();
};

export const fetchEmptyEnvironmentsStateInfo = async (filterValues?: IFiltersValues): Promise<IEnvironmentsEmptyStateInfo> => {
    const fields: IFieldInfo[] = filterValues?.fields || [];
    const environmentFields: IFieldInfo[] = fields.filter(field => field.name === ASSET_FIELD_NAMES.cloudAccountId);
    if (environmentFields.length === 0) {
        return {
            environmentsCount: 0,
            isEmpty: false,
        };
    }

    const hasSensitivity = await fetchHasSensitivityInfo(environmentFields);
    return {
        environmentsCount: environmentFields.length,
        isEmpty: !hasSensitivity,
    };
};