import { IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { ICveIgnoreUpdate } from './CveIgnoreList.interface';
import { ICommonIgnoreItemUpdate } from '../CommonIgnoreList/CommonIgnoreList.interface';
import { getCommonIgnoreItemFromValues } from '../CommonIgnoreList/CommonIgnoreList.items';

export const getCveIgnoreItemFromValues = (itemsMap: IItemsMap): ICveIgnoreUpdate => {
    const commonIgnoreItemUpdate: ICommonIgnoreItemUpdate = getCommonIgnoreItemFromValues(itemsMap);

    return {
        ...commonIgnoreItemUpdate,
    };
};
