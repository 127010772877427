import { IColumnUsageDef } from 'common/interface/general';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { SortingModel } from 'common/components/Findings/Findings.interface';

export const getDefaultFilters = (cloudAccountId: string): IFiltersValues => {
    return {
        fields: [
            { name: 'cloudAccountId', value: cloudAccountId },
        ],
    };
};

export const defaultSortModel: SortingModel = {
    direction: -1,
    fieldName: 'riskScore',
};

export const defaultProtectedAssetColumns: (IColumnUsageDef & { order?: number; })[] = [
    {
        id: 'entity',
        order: 0,
    },
    {
        id: 'ermRiskScore',
        order: 0.5,
    },
    {
        id: 'type',
        order: 1,
        colDefOverride: {
            initialHide: true,
        },
    },
    {
        id: 'region',
        order: 2,
    },
    {
        id: 'runtimeEnvironment',
        colDefOverride: {
            initialHide: true,
        },
    },
    {
        id: 'name',
        colDefOverride: {
            initialHide: true,
        },
    },
    {
        id: 'ermCVEs',
    },
    {
        id: 'ermMisconfigurations',
    },
    {
        id: 'ermIamSensitivity',
        colDefOverride: {
            initialHide: true,
        },
    },
    {
        id: 'ermBusinessPriority',
        colDefOverride: {
            initialHide: true,
        },
    },
    {
        id: 'network',
        colDefOverride: {
            initialHide: true,
        },
    },
    {
        id: 'isPublic',
        colDefOverride: {
            initialHide: true,
        },
    },
    {
        id: 'isRunning',
        colDefOverride: {
            initialHide: true,
        },
    },

];