import React, { useMemo } from 'react';
import {
    IClientFilterPageTableProps, IClientFilterType,
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';
import { ClientFilterPageTable } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable';
import { ICommonIgnoreItem } from './CommonIgnoreList.interface';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { getIgnoreListTableActions } from './CommonIgnoreList.actions';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { IFilterFieldOptionsMap } from 'common/module_interface/assets/ProtectedAssets';
import {
    getAllEnvironmentCondOptions,
} from '../../../RiskManagement.options';
import { IGNORE_LIST_ITEM_FIELDS } from './CommonIgnoreList.consts';

export interface ICommonIgnoreListProps<T extends ICommonIgnoreItem> {
    pageTableId: string;
    fetchAllItems: () => Promise<T[]>;
    getTableColumnDefs: () => ColDef[];
    getTableFilterDefs?: () => IClientFilterType<T, IFilterConditionsContainer>[];
    getFooterItemTitle: (count: number) => string;
    openForm: (item?: T) => void,
    deleteItems: (items: T[]) => Promise<void>,
    iconName: AllIconsName;
}

const getFieldOptionsMap = async (): Promise<IFilterFieldOptionsMap> => {
    return {
        [IGNORE_LIST_ITEM_FIELDS.ENV_IDS]: {
            options: await getAllEnvironmentCondOptions(),
        },
    };
};

export const CommonIgnoreListPage: React.FC<ICommonIgnoreListProps<any>> = <T extends ICommonIgnoreItem>(props: ICommonIgnoreListProps<T>) => {
    const { pageTableId, fetchAllItems, getTableColumnDefs, getTableFilterDefs, getFooterItemTitle, openForm, deleteItems, iconName } = props;

    const pageProps: IClientFilterPageTableProps<T> = useMemo(() => ({
        fetchAllItems,
        getTableColumnDefs,
        getTableFilterDefs,
        getFieldOptionsMap,
        getFooterItemTitle,
        pageTableId,
        getTableActions: (onTableDataChange: () => void) => getIgnoreListTableActions(
            onTableDataChange, pageTableId, openForm, deleteItems, iconName),
    }), [deleteItems, fetchAllItems, getFooterItemTitle, getTableColumnDefs, getTableFilterDefs, iconName, openForm, pageTableId]);
    return <ClientFilterPageTable {...pageProps} />;
};
