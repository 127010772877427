import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TabsList } from './TabsList/TabsList';

export const EnvironmentTabs: React.FC = () => {
   
    return (
        <Switch>
            <Route path={['/cloud-account/:platform?/:id?']}>
                <TabsList key='environments' context={'environments'} urlPrefix={'/cloud-account'} />
            </Route>
            <Route path={['/workload/environments/:platform?/:id?']}>
                <TabsList key='workloads' context={'workloads'} urlPrefix={'/workload/environments'} />
            </Route>
        </Switch>
    );
};
