import { getHttpService } from 'common/interface/services';
import { ICloudAccount, ICloudAccountStateV2, ICloudAccountSyncStatus, ICloudAccountsService } from 'common/interface/data_services';
import { IFRAME_TAGS } from 'common/interface/IFrame.message.model';
import { IAssignUser } from 'common/interface/user';
import { sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';

const cachingConfig = (useCache: boolean) => ({ useCache, tags: [IFRAME_TAGS.CLEAR_CACHE.ENVIRONMENTS] });

export class CloudAccountsService implements ICloudAccountsService {
    private cloudAccountByIdPromise: Promise<{ [cloudAccountId: string]: ICloudAccount }> | undefined;
    private subscribeList: { [key: string]: (allCloudAccounts: ICloudAccount[]) => void } = {};

    private async initCloudAccountById() {
        try {
            const allCloudAccounts = await this.getAllCloudAccounts();
            return allCloudAccounts.reduce<{ [cloudAccountId: string]: ICloudAccount; }>((acc, val) => {
                acc[val.id] = val;
                return acc;
            }, {});
        } catch (error) {
            return {};
        }
    }

    async getAllCloudAccounts(useCache = true) {
        return await getHttpService().get<ICloudAccount[]>({ path: 'view/common/all-cloud-accounts', cachingConfig: cachingConfig(useCache) });
    }

    async getAllPlatforms(useCache = true) {
        return getHttpService().get({ path: 'view/common/all-platforms',cachingConfig: cachingConfig(useCache) });
    }

    async getAllCloudAccountsCount(useCache = true) {
        return getHttpService().get({ path: 'view/common/all-cloud-accounts-count',cachingConfig: cachingConfig(useCache) });
    }

    getUnmonitoredCloudAccountsCount(useCache = true) {
        return getHttpService().get({ path: 'view/common/all-cloud-accounts-count/unmonitored',cachingConfig: cachingConfig(useCache) });
    }

    async getEnvironmentSyncStatus(useCache = true, customHandleError = undefined) {
        return getHttpService().request<ICloudAccountSyncStatus[]>(
            'view/common/sync-status',
            { method: 'GET' },
            { cachingConfig: cachingConfig(useCache) },
            customHandleError
        );
    }

    async getStatsV2(useCache = true) {
        return getHttpService().get<ICloudAccountStateV2>({ path: 'cloudaccounts/stats-V2',cachingConfig: cachingConfig(useCache) });
    }

    async getCloudAccountByAccountId(cloudAccountId: string) {
        // first time we call this function we initialize the cloudAccountByIdPromise
        if (!this.cloudAccountByIdPromise) {
            this.cloudAccountByIdPromise = this.initCloudAccountById();
        }
        const cloudAccountById = await this.cloudAccountByIdPromise;
        return cloudAccountById[cloudAccountId] || null;
    }

    async clearCache() {
        getHttpService().clearCacheByTag(IFRAME_TAGS.CLEAR_CACHE.ENVIRONMENTS);
        const allCloudAccounts = await this.getAllCloudAccounts(false);
        this.cloudAccountByIdPromise = this.initCloudAccountById();

        Object.values(this.subscribeList).forEach((callback) => callback(allCloudAccounts));
    }

    subscribe(callback: (allCloudAccounts: ICloudAccount[]) => void): string {
        const subscribeKey = Math.floor(Math.random() * (10000000 - 1000000 + 1) + 1000000).toString();
        this.subscribeList[subscribeKey] = callback;
        this.getAllCloudAccounts().then((allCloudAccounts) => callback(allCloudAccounts));
        return subscribeKey;
    }

    unsubscribe(subscribeKey: string): void {
        delete this.subscribeList[subscribeKey];
    }

    async getAllUsers(): Promise<IAssignUser[]> {
        return await sendHttpRequest<IAssignUser[]>('view/common/all-users', { method: 'GET' });
    }
}
