import React from 'react';
import { useTranslation } from 'react-i18next';
import { IInternalMenuBase, IInternalUrlMenuItem } from '../Interface';

export interface IUrlMenuItemRendererProps {
    menuItem: IInternalMenuBase;
}

const UrlMenuItemRenderer: React.FC<IUrlMenuItemRendererProps> = ({ menuItem }) => {
    const { t } = useTranslation();
    const urlMenuItem = menuItem as IInternalUrlMenuItem;
    const value = t(urlMenuItem.label);
    const externalHref = urlMenuItem.url;
    return (
        <a className={'flex items-center flex-1 h-full'} href={externalHref} target={externalHref && '_blank'}>
            <div className={'pl-6'} title={value} data-aid={value}>
                {value}
            </div>
        </a>
    );
};

export default UrlMenuItemRenderer;
