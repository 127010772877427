import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import {
    CloudAccountGet,
    CloudAccountStatsGet,
    CloudAccountMissingPermissionsGet,
    CloudAccountNameUpdate,
    CloudAccountAwsSecretGet,
    CloudAccountsCredentialsUpdate,
    CloudAccountsSyncNow,
    CloudAccountDelete,
    CloudAccountDeleteForce
} from './cloudAccounts.interface';

const cloudAccountGet: CloudAccountGet.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountGet.Response>>(
        `cloudaccounts/${id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountDelete: CloudAccountDelete.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountDelete.Response>>(
        `cloudaccounts/${id}`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountDeleteForce: CloudAccountDeleteForce.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountDeleteForce.Response>>(
        `cloudaccounts/${id}/deleteforce`,
        { method: 'DELETE' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountStatsGet: CloudAccountStatsGet.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountStatsGet.Response>>(
        `cloudaccounts/stats?id=${id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountMissingPermissionsGet: CloudAccountMissingPermissionsGet.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountMissingPermissionsGet.Response>>(
        `cloudaccounts/${id}/MissingPermissions`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountNameUpdate: CloudAccountNameUpdate.Function = async (request: CloudAccountNameUpdate.Request) => {
    return await getHttpService().request<AxiosResponse<CloudAccountNameUpdate.Response>>(
        'cloudaccounts/name',
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountAwsSecretGet: CloudAccountAwsSecretGet.Function = async (awsAccountNumber) => {
    return await getHttpService().request<AxiosResponse<CloudAccountAwsSecretGet.Response>>(
        `cloudaccounts/awssecret?awsAccountNumber=${awsAccountNumber}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountsCredentialsUpdate: CloudAccountsCredentialsUpdate.Function = async (request: CloudAccountsCredentialsUpdate.Request) => {
    return await getHttpService().request<AxiosResponse<CloudAccountsCredentialsUpdate.Response>>(
        'cloudaccounts/credentials',
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const cloudAccountsSyncNow: CloudAccountsSyncNow.Function = async (request: CloudAccountsSyncNow.Request) => {
    return await getHttpService().request<AxiosResponse<CloudAccountsSyncNow.Response>>(
        `cloudaccounts/${request.id}/syncnow`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const CloudAccountsService = {
    cloudAccountGet,
    cloudAccountDelete,
    cloudAccountDeleteForce,
    cloudAccountStatsGet,
    cloudAccountMissingPermissionsGet,
    cloudAccountNameUpdate,
    cloudAccountAwsSecretGet,
    cloudAccountsCredentialsUpdate,
    cloudAccountsSyncNow
};

export default CloudAccountsService;
