import React from 'react';
import { useSelector } from 'react-redux';
import { setIsUserHideCGSystemAlertBar, getIsUserHideCGSystemAlertBar } from 'App.reducer';
import { getStoreService } from 'common/interface/services';
import useUnresolvedIncidents from './IncidentMessage.hook';
import { Alert, AlertType } from '@dome9/berries/react-components';
import { Trans } from 'react-i18next';
import { ALink } from 'common/components/ALink';
import { Typography } from 'common/design-system/components-v2';

const statusPageUrl = 'https://status.dome9.com/';

const IncidentMessage: React.FC = () => {
    const hasUnresolvedIncidents = useUnresolvedIncidents();
    const isUserHideCGSystemAlertBar = useSelector(getIsUserHideCGSystemAlertBar);
    const dispatch = getStoreService().dispatch;

    if (!hasUnresolvedIncidents || isUserHideCGSystemAlertBar) {
        return null;
    }

    return (
        <Alert type={AlertType.WARNING} onClose={() => dispatch(setIsUserHideCGSystemAlertBar(true))}>
            <Typography color='strong'>
                <Trans i18nKey={'GENERAL.INCIDENT_MESSAGE'} components={{ aLink: <ALink rel='noreferrer' target='_blank' href={statusPageUrl} /> }} />
            </Typography>
        </Alert>
    );
};

export default IncidentMessage;
