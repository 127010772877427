import React from 'react';
import { CdrDashboardStyled } from './CdrDashboard.styled';
import CdrSummaryStatus from './CdrSummaryStatus/CdrSummaryStatus';

const CdrDashboard: React.FC = () => {
    return <CdrDashboardStyled.TopDiv data-aid='cdr-dashboard'>
        <CdrDashboardStyled.SummarySection>
            <CdrSummaryStatus />
        </CdrDashboardStyled.SummarySection>
    </CdrDashboardStyled.TopDiv>;
};

export default CdrDashboard;