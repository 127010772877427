import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const FormFieldsWrapper = styled(Stack)``;
const RemediateByEntityWrapper = styled(Stack)``;
const EntityInputWrapper = styled(Stack)``;

const CardWrapper = styled(Stack)`
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.light}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
`;

export const RemediateModalStyles = {
    FormFieldsWrapper,
    RemediateByEntityWrapper,
    EntityInputWrapper,
    CardWrapper,
};
