import { batch } from 'react-redux';
import { getStoreService } from 'common/interface/services';
import { RemediationModuleType } from 'common/interface/remediation';
import RemediationApi from '../services/remediationApi.services';
import {
    setAllRemediation,
    setCloudAccountsList,
    setLicenseList,
    setOrganizationalUnits,
    setRulesetList
} from './remediation.slice';

const getMessageFromError: (error: any) => string = (error: any) => {
    const responseError = error.response?.data?.errors?.description?.join(', ');
    const responseErrorOpt2 = error.response?.data?.title;
    const responseErrorOpt3 = error.response?.data?.message;
    const responseErrorOpt4 = error.message;
    return responseError || responseErrorOpt2 || responseErrorOpt3 || responseErrorOpt4;
};
export const getRemediationListBySourceType = async (useCache = true, sourceType: RemediationModuleType) => {
    try {
        const { dispatch } = getStoreService().getReduxTools();
        const results = await RemediationApi.getList(useCache, sourceType);
        dispatch(setAllRemediation(results));
        return results;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};
export const setRemediationPageData = async (data: any) => {
    try {
        const { dispatch } = getStoreService().getReduxTools();
        const { organizationalUnits, rulesets, cloudAccounts, license } = data;
        batch(() => {
            dispatch(setRulesetList(rulesets));
            dispatch(setLicenseList(license));
            dispatch(setOrganizationalUnits(organizationalUnits));
            dispatch(setCloudAccountsList(cloudAccounts));
        });
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

export const getComplianceRuleByRuleset = async (id: string) => {
    try {
        return await RemediationApi.getComplianceRuleByRuleset(id);
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

export const deleteRemediations = async (ids: string[], source: RemediationModuleType) => {
    try {
        const promises = ids.map(id => RemediationApi.deleteRemediation(id, source));
        return await Promise.all(promises);
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};