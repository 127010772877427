import React, { useRef } from 'react';
import { Handle, Position } from 'reactflow';

const DummyNode: React.FC = () => {
    const handleRef = useRef(null);
    return <>
        <Handle
            type="target"
            position={Position.Left}
            style={{
                top: '50.1%',
                background: 'transparent',
                width: 0,
                height: 0,
                border: 'none',
            }}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={false}
        />

        <div
            className={'flex flex-col items-center asset-node'}
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0)',
                border: '1px solid rgba(0, 0, 0, 0)'
            }}>

        </div>

        <Handle
            ref={handleRef}
            type="source"
            position={Position.Right}
            isConnectable={true}
            style={{
                top: '50%',
                background: 'transparent',
                width: 0,
                height: 0,
                border: 'none',
            }}
        />
    </>;
};

export default DummyNode;