import React, { useEffect, useMemo, useState } from 'react';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';

import { FILTER_EVENTS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import {
    renderAddFilter, renderAddItemFilter, renderClearAll,
    renderMultiSelectFilter, renderTagFilter,
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';

interface IAddEditModalFilterPanel {
    onFilterChange: (filterValues: IFiltersValues) => void
    initialFilter: any;
}

const AddEditModalFilterPanel: React.FC<IAddEditModalFilterPanel> = ({ onFilterChange, initialFilter }) => {
    const [filterElement, setFilterElement] = useState(<div></div>);

    const filterDefs= useMemo( ()=>[
        {
            filterProps: { key: FILTERS_KEYS.ADD_FILTER },
            renderFunction: renderAddFilter,
        },

        {
            filterProps: {
                initialData: [{ value: 'Critical' },{ value: 'High' },{ value: 'Medium' },{ value: 'Low' }, { value: 'Informational' }],
                key: 'severities',
                title: 'Severity',
                defaultValue: [],
                value: initialFilter['severities'] || []
            },
            renderFunction: renderMultiSelectFilter,
        },
        {
            filterProps: {
                initialData: [],
                key: 'entityNames',
                title: 'Entity Name',
                value: initialFilter['entityNames'] || []
            },
            renderFunction: renderAddItemFilter,
        },
        {
            filterProps: {
                initialData: [],
                key: 'entityCategories',
                title: 'Category',
                value: initialFilter['entityCategories'] || []
            },
            renderFunction: renderAddItemFilter,
        },
        {
            filterProps: {
                initialData: [],
                key: 'entityIds',
                title: 'Entity IDs',
                value: initialFilter['entityIds'] || []
            },
            renderFunction: renderAddItemFilter,
        },
        {
            filterProps: {
                initialData: [],
                key: 'entityTags',
                title: 'Tags',
                value: initialFilter['entityTags'] || [{ key: '', value: '' }]
            },
            renderFunction: renderTagFilter,
        },
        {
            filterProps: {
                key: FILTERS_KEYS.CLEAR_BUTTON,
            },
            renderFunction: renderClearAll,

        },
    ], [initialFilter]);

    const getAggregations = async (filtersValues: IFiltersValues) : Promise<any> => {
        return filtersValues;
    };
    const onFilterEvent = (action: string, filtersValues: IFiltersValues) => {
        if( action === FILTER_EVENTS.FILTER_CHANGED) {
            onFilterChange(filtersValues);
        }
    };


    useEffect(() => {

        async function renderFilterPanel() {
            const filterPanelElementsList = filterDefs;
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: false,
                shouldUseQueryParams: false,
                onFilterChangeCallBack: onFilterEvent
            });
            setFilterElement(filterPanel);
        }


        renderFilterPanel();


    },[]);


    return <div>
        {filterElement}
    </div>;
};

export default AddEditModalFilterPanel;