import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AwsInitialState, AwsState } from './aws.interface';
import { AwsEnvironmentData } from 'common/module_interface/assets/AwsEnvironment';

const AwsSlice = createSlice({
    name: 'env_aws',
    initialState: AwsInitialState,
    reducers: {
        setLoading: (state: AwsState, action: PayloadAction<{ awsId: string; loading: boolean; }>) => {
            const { awsId, loading } = action.payload;
            if (!state.awsEvnironments[awsId]) {
                state.awsEvnironments[awsId] = {};
            }
            state.awsEvnironments[awsId].loading = loading;
        },
        setError: (state: AwsState, action: PayloadAction<{ awsId: string; error: boolean; }>) => {
            const { awsId, error } = action.payload;
            if (!state.awsEvnironments[awsId]) {
                state.awsEvnironments[awsId] = {};
            }
            state.awsEvnironments[awsId].error = error;
        },
        setData: (state: AwsState, action: PayloadAction<{ awsId: string; data: AwsEnvironmentData; }>) => {
            const { awsId, data } = action.payload;
            if (!state.awsEvnironments[awsId]) {
                state.awsEvnironments[awsId] = {};
            }
            state.awsEvnironments[awsId].data = data;
        },
        setDeleted: (state: AwsState, action: PayloadAction<{ awsId: string; }>) => {
            const { awsId } = action.payload;
            if (!state.awsEvnironments[awsId]) {
                state.awsEvnironments[awsId] = { deleted: true };
            }
            state.awsEvnironments[awsId].deleted = true;
        },
        setNotificationCountWarning: (state: AwsState, action: PayloadAction<{ awsId: string; count?: number; }>) => {
            const { awsId, count } = action.payload;
            if (!state.awsEvnironments[awsId]) {
                state.awsEvnironments[awsId] = { notificationCountWarning: count };
            }
            state.awsEvnironments[awsId].notificationCountWarning = count;
        }
    }
});

export default AwsSlice;
