import {
    FilterConditionOperator,
    IFilterCondition,
    IFilterConditionValue,
} from 'common/erm-components/custom/FilterTree/FilterCondition';
import { ICompoundFilterNode } from 'common/erm-components/custom/FilterTree/CompoundFilter';

export const addConditionIfExists = (conditions: IFilterCondition[], name: string, values: IFilterConditionValue[] | undefined, operator?: FilterConditionOperator) => {
    if (values && (values.length > 0)) {
        conditions.push({
            name,
            values,
            operator: operator ?? FilterConditionOperator.In,
        });
    }
};

export const addSingleValueConditionIfExists = (conditions: IFilterCondition[], name: string, value: IFilterConditionValue | undefined, operator?: FilterConditionOperator) => {
    if (value) {
        addConditionIfExists(conditions, name, [value], operator);
    }
};

export const addDateConditionIfExists = (conditions: IFilterCondition[], name: string, value: Date | undefined, operator?: FilterConditionOperator) => {
    if (value) {
        addConditionIfExists(conditions, name, [String(value)], operator);
    }
};

export type IConditionsMap = { [key: string]: IFilterConditionValue[] };
export const getConditionsMap = (filter?: ICompoundFilterNode): IConditionsMap => {
    const map: IConditionsMap = {};
    if (!filter) {
        return map;
    }
    const conditions: IFilterCondition[] = filter.operands?.filter(item => !!(item as IFilterCondition).name) as IFilterCondition[];
    conditions.forEach(cond => {
        map[cond.name] = cond.values;
    });
    return map;
};

export const getConditionValues = <T=string>(fieldName: string, condMap: IConditionsMap): T[] | undefined => {
    const values: IFilterConditionValue[] | undefined = condMap[fieldName];
    return values as T[];
};

export const convertMultiValueToSingle = <T>(values: T[]): T | undefined => {
    return (values && (values.length > 0)) ? values[0] : undefined;
};

export const getConditionValueAsSingle = <T=string>(fieldName: string, condMap: IConditionsMap): T | undefined => {
    const values: T[] | undefined = getConditionValues<T>(fieldName, condMap);
    if (!values) {
        return;
    }

    return convertMultiValueToSingle<T>(values);
};

export const getConditionValueAsDate = (fieldName: string, condMap: IConditionsMap): Date | undefined => {
    const value: string | undefined = getConditionValueAsSingle(fieldName, condMap);
    if (!value) {
        return;
    }

    return new Date(value);
};
