import { t } from 'i18next';
import { ISearcher, ISearchResultItem } from '../MainNavigationBar.interface';
import { getHttpService, getStoreService } from 'common/interface/services';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { changeUrl } from 'common/utils/http';
import { boldSearchResult } from '../MainNavigationBarUtils';
import { Icon, List } from '@dome9/berries/react-components';
import CollapseSection from 'common/components/CollapseSection/CollapseSection';
import {
    ListItem,
} from 'common/components/FilterPanel/DefaultHandlers/SavedFilters/SavedFilterList/SavedFiltersList.interface';
import './globalSearcher.scss';
import { IFreeSearch, IFreeSearchItem } from './globalSearcehr.interface';
import { FC, useEffect, useState } from 'react';
import { Spinner, Stack } from 'common/design-system/components-v2';

const groupBy = function(arr: any, key: any, fallback: string) {
    return arr.reduce(function(reducedValue: any, value: any) {
        const el = value[key] ? value[key] : value[fallback];
        (reducedValue[el] = reducedValue[el] || []).push(value);
        return reducedValue;
    }, {});
};


export const assetsResultsDisplayFunction = (results: ISearchResultItem[], searchTerm: string, resultItemClicked: Function, dataAid?: string) => {
    const groupedResult = groupBy(results, 'subType', '');
    const categoriesList: ListItem[] = [];
    for (const key in groupedResult) {
        const assetsResultsList: ListItem[] = [];
        for (const result of groupedResult[key]) {
            //continue here
            assetsResultsList.push({
                startElements: [<div key={result.id} className={'flex mb-3 space-x-4 flex-center'}>
                    <div className='ellipsis-wrapped-container flex space-x-4 flex-center'>
                        {result.parentMenuTitle &&
                            <>
                                <div
                                    className={'ellipsis-wrapped-menu-title-container'}>{boldSearchResult(t(result.parentMenuTitle), searchTerm)}</div>
                                <Icon name='chevron-right' size={8} />
                            </>
                        }
                        {result.parentSectionTitle &&
                            <>
                                <div
                                    className={'ellipsis-wrapped-section-title-container'}>{boldSearchResult(t(result.parentSectionTitle), searchTerm)}</div>
                                <Icon name='chevron-right' size={8} />
                            </>
                        }
                        {boldSearchResult(t(result.name), searchTerm)}
                    </div>
                </div>],
                title: '',
                removable: false,
                onSelect: async () => {
                    if (result.subType === 'Assets') {
                        const asset = await getProtectedAssetsService().getProtectedAssetBySrl(result.id);
                        if (asset) {
                            const urlRes = getProtectedAssetsService().getProtectedAssetUrl(asset);
                            if (urlRes) {
                                changeUrl(urlRes);
                                resultItemClicked(result);
                            }
                        }
                    } else {
                        changeUrl(`/alerts/findings?query=%7B"sorting":%7B"fieldName":"createdTime","direction":-1%7D,"filter":%7B"fields":%5B%7B"name":"organizationalUnitId","value":"00000000-0000-0000-0000-000000000000"%7D%5D,"freeTextPhrase":"${encodeURIComponent(result.id)}"%7D%7D&time=%7B"time":"All"%7D`);
                        resultItemClicked(result);
                    }
                },
            });
        }
        categoriesList.push({
            startElements: [
                <CollapseSection key={key} title={`${key} (${assetsResultsList.length}+)`}>
                    <List items={assetsResultsList} dataAid={dataAid || 'searchBar-results'} />
                </CollapseSection>],
            title: '',
            sortedKey: key,
            removable: false,
        });
        categoriesList.sort((a: any, b: any) => a.sortedKey > b.sortedKey ? 1 : -1);
    }
    return <div>
        <div className='items-center flex-1 font-medium mb-5 text-lg'>Results</div>
        <List items={categoriesList} dataAid={dataAid || 'searchBar-results'} />
    </div>;
};

export const FreeSearcher: FC<{ searchStr: string, onResultClicked: Function }> = ({ searchStr, onResultClicked }) => {

    const [searchResult, setSearchResult] = useState<ISearchResultItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (searchStr === '') {
            return;
        }
        const getMagellanUrl = () => {
            const state = getStoreService().state;
            return state.app?.magellanUrl;
        };
        setIsLoading(true);

        async function fetchData() {
            const url = `${getMagellanUrl()}/search`;
            const res = await getHttpService().post<IFreeSearch>(
                url,
                {
                    data: {
                        freeText: searchStr,
                    },
                },
                {
                    cachingConfig: {
                        useCache: true,
                        dataAgeLimit: 5 * 60,
                    },
                },
            );
            res.queryResult.data.sort((a: IFreeSearchItem, b: IFreeSearchItem) => {
                return a.origin_resolved || a.src < b.origin_resolved || b.src ? 1 : -1;
            });
            const orgRes = groupBy(res.queryResult.data, 'origin_resolved', 'src');
            const results = [] as ISearchResultItem[];
            for (const key in orgRes) {
                for (const item of orgRes[key]) {
                    const isAsset = key === 'assets';
                    if (isAsset && !item.asset_json?.Srl) continue;
                    results.push(
                        {
                            id: isAsset ? item.asset_json.Srl.Address : item.findingKey,
                            name: isAsset ? `${item.asset_json.Name} (${item.asset_json.ExternalId})` : item.rule_name,
                            state: 'state',
                            url: 'url',
                            subType: key === 'assets' ? 'Assets' : `${key} Findings`,
                            parentMenuTitle: !isAsset ? `${item.env_cloudAccountName || item.env_externalAccountId}` : '',
                            parentSectionTitle: !isAsset ? `${item.entity_name || item.entity_id}` : '',
                            displayFunction: assetsResultsDisplayFunction,
                        },
                    );
                }
            }
            return results;
        }

        fetchData().then((res) => {
            setSearchResult(res);
        }).catch((e) => {
            setSearchResult([]);
            console.error(e.message);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [searchStr]);

    if (searchStr === '') {
        return <>{t('MAIN_NAVIGATION_BAR.RESULTS_LIST.NO_SEARCH_TEXT')}</>;
    }

    if (isLoading) {
        return <Stack direction={'row'} spacing={2}><Spinner size={15}></Spinner>
            <div>{t('MAIN_NAVIGATION_BAR.RESULTS_LIST.SEARCHING')}</div>
        </Stack>;
    }

    if (searchResult.length === 0) {
        return <div>{t('MAIN_NAVIGATION_BAR.RESULTS_LIST.NO_RESULTS')}</div>;
    }
    return assetsResultsDisplayFunction(searchResult, searchStr, onResultClicked, 'search-navigation-menu');
};

export const globalSearcher: ISearcher = {
    title: t('MAIN_NAVIGATION_BAR.TABS.ASSETS'),
    id: 'Global Searcher',
    component: FreeSearcher,
};