import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CpToggleButton } from '@dome9/components/react/components';
import './AWSOnboarding.scss';
import { useSelector } from 'react-redux';
import { getEnableServerless, setEnableServerless } from './helpers/aws.reducer';
import { getStoreService } from 'common/interface/services';

const AWSOnboardingServerless: FC = () => {
    const { t } = useTranslation();
    const dispatch = getStoreService().dispatch;
    const enableServerless = useSelector(getEnableServerless);
    function onEnableServerlessToggle(eventData: any) {
        dispatch(setEnableServerless(eventData.isOn));
    }

    return (
        <div className='aws-onboarding-serverless'>
            <div>
                <div className='title-large'>{t('ON_BOARDING.SERVERLESS.HEADER')}</div>
                <div className='title'>{t('ON_BOARDING.SERVERLESS.DESCRIPTION')}</div>
            </div>
            <CpToggleButton
                isOn={enableServerless}
                className='toggle-button'
                onClick={(event: any) => onEnableServerlessToggle(event.target)}
            />
        </div>
    );
};

export default AWSOnboardingServerless;
