import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  width: 100%;
  height: 340px;
  grid-gap: 20px;
`;

const RiskiestAssetsDiv = styled.div`
  display: flex;
  width: 40%;
  height: 100%;
  flex-grow: 1;
  min-width: 100px;
`;

const RiskiestAsseTypesDiv = styled.div`
  display: flex;
  width: 40%;
  height: 100%;
  flex-grow: 1;
  min-width: 100px;
`;

const TopEnvironmentsDiv = styled.div`
  display: flex;
  width: 210px;
  min-width: 210px;
  max-width: 210px;
  height: 100%;
  flex-grow: 1;
  min-width: 100px;
`;

export const RiskiestEntitiesStyled = {
    TopDiv,
    RiskiestAssetsDiv,
    RiskiestAsseTypesDiv,
    TopEnvironmentsDiv,
};