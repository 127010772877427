import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { KubernetesAttachOrganizationalUnit, KubernetesDeleteMultiple } from './kubernetesApi.interface';

const apiBaseUrl = 'kubernetes';

const attachOrganizationalUnit: KubernetesAttachOrganizationalUnit.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<KubernetesAttachOrganizationalUnit.Response>>(
        `${apiBaseUrl}/account/organizationalUnit/attach`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const deleteMultiple: KubernetesDeleteMultiple.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<KubernetesDeleteMultiple.Response>>(
        `${apiBaseUrl}/account/deleteMultiple`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const KubernetesApi = {
    attachOrganizationalUnit,
    deleteMultiple,
};

export default KubernetesApi;
