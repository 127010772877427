import { EventModal, EventsModalsRegistry } from 'common/module_interface/events/EventsModalsRegistry';
import { Addin } from 'common/extensibility/AddinRegistry';
import CommentAction from './CommentAction/CommentAction';
import ChangeSeverityAction from './ChangeSeverityAction/ChangeSeverityAction';
import AcknowledgeAction from './AcknowledgeAction/AcknowledgeAction';
import CloseAction from './CloseAction/CloseAction';
import ArchiveAction from './ArchiveAction/ArchiveAction';
import AssignAction from './AssignAction/AssignAction';
import ReportAnIssueAction from './ReportAnIssueAction/ReportAnIssueAction';
import EventsRemediationModal from './EventsRemediationModal';
import EventsExclusionsModal from './ExclusionsAction';

const modalsDefs: () => Addin<EventModal>[] = () => {
    return [
        {
            id: 'comment',
            content: {
                id: 'comment',
                modalComponent: CommentAction
            }
        },
        {
            id: 'changeSeverity',
            content: {
                id: 'changeSeverity',
                modalComponent: ChangeSeverityAction
            }
        },
        {
            id: 'acknowledge',
            content: {
                id: 'acknowledge',
                modalComponent: AcknowledgeAction
            }
        },
        {
            id: 'close',
            content: {
                id: 'close',
                modalComponent: CloseAction
            }
        },
        {
            id: 'assign',
            content: {
                id: 'assign',
                modalComponent: AssignAction
            }
        },
        {
            id: 'archive',
            content: {
                id: 'archive',
                modalComponent: ArchiveAction,
                additionalData: { isArchive: true }
            }
        },
        {
            id: 'unarchive',
            content: {
                id: 'unarchive',
                modalComponent: ArchiveAction,
                additionalData: { isArchive: false }
            }
        },
        {
            id: 'reportIssue',
            content: {
                id: 'reportIssue',
                modalComponent: ReportAnIssueAction
            }
        },
        {
            id: 'remediate',
            content: {
                id: 'remediate',
                modalComponent: EventsRemediationModal
            }
        },
        {
            id: 'exclusions',
            content: {
                id: 'exclusions',
                modalComponent: EventsExclusionsModal
            }
        }
    ];
};

export default function initializeEventsModalsDefs() {
    EventsModalsRegistry.addModals(modalsDefs());
}