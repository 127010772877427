import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { Typography } from 'common/design-system/components-v2';
import { IFieldError } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { ModalType } from '../../Findings.const';
import { IFieldErrorsProps, IModalsProps } from './Modals.types';
import { CloudBotStatusModal } from './CloudBotStatusModal/CloudBotStatusModal';
import { FixItModal } from './FixItModal/FixItModal';
import { RemediateModal } from './RemediateModal/RemediateModal';
import { FIX_IT_MAX_CLOUD_BOTS_ALLOWED } from './FixItModal/FixItModal.const';
import { REMEDIATE_MAX_CLOUD_BOTS_ALLOWED } from './RemediateModal/RemediateModal.const';
import { ReportIssueModal } from './ReportIssueModal/ReportIssueModal';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { AcknowledgeModal } from './AcknowledgeModal/AcknowledgeModal';
import { CommentModal } from './CommentModal/CommentModal';
import { CloseModal } from './CloseModal/CloseModal';
import { ChangeSeverityModal } from './ChangeSeverityModal/ChangeSeverityModal';
import { AssignUserModal } from './AssignUserModal/AssignUserModal';
import { ArchiveModal } from './ArchiveModal/ArchiveModal';

const Modals: React.FC<IModalsProps> = (props: IModalsProps) => {
    const { modalType, closeModal, selectedRows } = props;
    const [fieldErrors, setFieldErrors] = useState<IFieldError[]>([]);

    /**
     * Adds field errors to the fieldErrors state
     * @param {IFieldError[]} fieldErrorsToAdd - The field errors to add
     * @returns {void}
     */
    const addFieldErrors = useCallback((fieldErrorsToAdd: IFieldError[]) => {
        setFieldErrors((prevErrors: IFieldError[]) => {
            const errors: IFieldError[] = prevErrors.filter((error: IFieldError) => {
                return !fieldErrorsToAdd.some((fieldError: IFieldError) => fieldError.field === error.field);
            });
            errors.push(...fieldErrorsToAdd);
            return errors;
        });
    }, []);

    /**
     * Checks if a field has an error
     * @param {string} field - The field to check
     * @returns {boolean} - Returns true if the field has an error, false otherwise
     */
    const isFieldError = useCallback((field: string): boolean => {
        return fieldErrors.some((error: IFieldError) => error.field === field);
    }, [fieldErrors]);

    /**
     * Returns a Typography component with the error label if the field has an error
     * @param {string} field - The field to check
     * @returns {React.ReactNode} - Returns a Typography component if the field has an error, null otherwise
     */
    const getFieldErrorElement = useCallback((field: string) => {
        const errorLabel = fieldErrors.find((error: IFieldError) => error.field === field)?.label;
        return errorLabel ? <Typography variant={'bodyXs'} color={'alert'}>{errorLabel}</Typography> : null;
    }, [fieldErrors]);

    /**
     * Returns the error text of the specified field
     * @param {string} field - The field to get the error text of
     * @returns {string} - The error text of the specified field
     */
    const getFieldErrorText = useCallback((field: string): string | undefined => {
        return fieldErrors.find((error: IFieldError) => error.field === field)?.label;
    }, [fieldErrors]);

    /**
     * Clears the errors of the specified fields
     * @param {string[]} fields - The fields to clear the errors of
     */
    const clearFieldErrors = useCallback((fields: string[] = []) => {
        setFieldErrors((prevErrors: IFieldError[]) => {
            let errors: IFieldError[] = [];
            if (!isEmpty(fields)) {
                errors = prevErrors.filter((error: IFieldError) => {
                    return !fields.some((field: string) => field === error.field);
                });
            }
            return errors;
        });
    }, [setFieldErrors]);

    /**
     * Clears the field errors and closes modal
     */
    const handleCloseModal = useCallback(() => {
        clearFieldErrors();
        closeModal();
    }, [clearFieldErrors, closeModal]);

    // Field errors props to pass to required Modal component
    const fieldErrorsProps: IFieldErrorsProps = {
        fieldErrors,
        addFieldErrors,
        isFieldError,
        getFieldErrorText,
        getFieldErrorElement,
        clearFieldErrors
    };

    // Render the appropriate modal based on the modalType prop
    switch (modalType) {
        case ModalType.FixIt:
            return <FixItModal {...props} closeModal={handleCloseModal} fieldErrorsProps={fieldErrorsProps}
                maxCloudBotsAllowed={FIX_IT_MAX_CLOUD_BOTS_ALLOWED}/>;
        case ModalType.BotStatusCheck:
            return <CloudBotStatusModal {...props} />;
        case ModalType.Remediate: {
            const finding: IFinding | undefined = isEmpty(selectedRows) ? undefined : selectedRows[0];
            if (!finding) return null;
            return <RemediateModal {...props} finding={finding} closeModal={handleCloseModal}
                fieldErrorsProps={fieldErrorsProps}
                maxCloudBotsAllowed={REMEDIATE_MAX_CLOUD_BOTS_ALLOWED}/>;
        }
        case ModalType.ReportIssue:
            return <ReportIssueModal {...props} closeModal={handleCloseModal} fieldErrorsProps={fieldErrorsProps}/>;
        case ModalType.Acknowledge: {
            const findings: IFinding[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <AcknowledgeModal {...props} findings={findings} closeModal={handleCloseModal}
                fieldErrorsProps={fieldErrorsProps}/>;
        }
        case ModalType.Comment: {
            const findings: IFinding[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <CommentModal {...props} findings={findings} closeModal={handleCloseModal}
                fieldErrorsProps={fieldErrorsProps}/>;
        }
        case ModalType.Close: {
            const findings: IFinding[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <CloseModal {...props} findings={findings} closeModal={handleCloseModal}/>;
        }
        case ModalType.ChangeSeverity: {
            const findings: IFinding[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <ChangeSeverityModal {...props} findings={findings} closeModal={handleCloseModal}
                fieldErrorsProps={fieldErrorsProps}/>;
        }
        case ModalType.AssignUser: {
            const findings: IFinding[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <AssignUserModal {...props} findings={findings} closeModal={handleCloseModal}
                fieldErrorsProps={fieldErrorsProps}/>;
        }
        case ModalType.Archive: {
            const findings: IFinding[] = selectedRows;
            if (isEmpty(findings)) return null;
            return <ArchiveModal {...props} findings={findings} closeModal={handleCloseModal}/>;
        }
        default:
            return null;
    }
};

export default Modals;
