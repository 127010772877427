import { CloudAccount } from 'common/interface/environments';
export const resolveEnvironmentDisplayName = (account:CloudAccount) => {
    if (account.name && !account.externalId) {
        return `${account.name} (${account.id})`;

    } else if (!account.name && !account.externalId) {
        return account.id;

    } else if (account.externalId === account.name) {
        return account.externalId;

    } else {
        return `${account.name} (${account.externalId})`;
    }
};