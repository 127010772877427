import React, { useEffect, useState } from 'react';
import { Stack } from 'common/design-system/components-v2';
import { RemediationRegistry } from 'common/module_interface/remediation/RemediationRegistry';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import RemediationTable from './Components/RemediationTable';
import RemediationFilterPanel from './Components/RemediationFilterPanel';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { IRemediation, RemediationModuleType } from 'common/interface/remediation';
import { deleteRemediations, getRemediationListBySourceType } from './reducer/remediation.actions';
import RemediationService from './services/remediation.services';
import { ENVIRONMENTS, PLATFORM, RULESETS } from './helpers/remediation.consts';
import { getPageRemediationByModule } from './helpers/remediation.utils';

const RemediationPage: React.FunctionComponent = () => {
    const sourceType: RemediationModuleType = getPageRemediationByModule();
    const [remediationList, setRemediationList] = useState([] as IRemediation[]);
    const [filteredData, setFilteredData] = useState([] as IRemediation[]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
    const [currentFilters, setCurrentFilter] = useState({} as IFiltersValues);
    const [selectedRemediation, setSelectedRemediation] = useState({} as IRemediation);
    const { params } = useReactRouterQuery();
    const RemediationModal = RemediationRegistry.getRemediationModals().find(item => item.id === sourceType)?.component;
    
    useEffect(() => {
        const remediationId = params.remediationId;
        if (remediationList && remediationId) {
            const remediation = remediationList.find(remediation => remediation.id === remediationId);
            remediation && editRemediation(remediation);
        }
    }, [params, remediationList]);

    const initRemediation = async () => {
        await RemediationService.initRemediationPageData(sourceType);
        const list = await getRemediationListBySourceType(true, sourceType);
        setRemediationList(list);
        setIsLoading(false);
    };

    useEffect(() => {
        initRemediation();
    }, []);

    useEffect(() => {
        onFilterChangeCallBack(currentFilters);
    }, [remediationList]);

    const editRemediation = (remediation: IRemediation) => {
        setSelectedRemediation(remediation);
        setIsAddEditModalOpen(true);
    };

    const closeAddEditRemediationModal = () => {
        setIsAddEditModalOpen(false);
        setSelectedRemediation({} as IRemediation);
    };

    const deleteRemediation = async (remediations: IRemediation[]) => {
        const idsForDelete = remediations.map(remediation => remediation.id);
        await deleteRemediations(idsForDelete, sourceType);
        const rawDataAfterDelete = remediationList.filter(r => !idsForDelete.includes(r.id));
        const filteredDataAfterDelete = filteredData.filter(r => !idsForDelete.includes(r.id));
        setRemediationList(rawDataAfterDelete);
        setFilteredData(filteredDataAfterDelete);
    };

    const isFilterEmpty = (filtersValues:IFiltersValues) => {
        return Object.keys(filtersValues).find(filterKey => filtersValues[filterKey] !== '') === undefined;
    };

    const onFilterChangeCallBack = React.useCallback((filtersValues: IFiltersValues) => {
        setCurrentFilter(filtersValues);
        if(isFilterEmpty(filtersValues)){
            setFilteredData(remediationList);
            return;
        }
        let newFilteredData = [] as IRemediation[];
        const filterDataForFreeText = (remediation: IRemediation, text: string) => {
            return remediation.ruleName ? remediation.ruleName.toLowerCase().includes(text.toLowerCase()) : false;
        };

        const filterDataForMultiSelect = (options: any, fieldInRemediation: any) => {
            if (options === '' || options.length === 0) {
                return true;
            }
            for (const option of options) {
                if (Array.isArray(fieldInRemediation)) {
                    if (fieldInRemediation.includes(option)) {
                        return true;
                    }
                } else {
                    if (fieldInRemediation === option) {
                        return true;
                    }
                }
            }
            return false;
        };

        newFilteredData = remediationList.filter(data =>
            filterDataForFreeText(data, filtersValues[FILTERS_KEYS.FREE_TEXT]) && 
        filterDataForMultiSelect(filtersValues[PLATFORM], data[PLATFORM]) &&
        filterDataForMultiSelect(filtersValues[RULESETS], data[RULESETS]) &&
        filterDataForMultiSelect(filtersValues[ENVIRONMENTS], data[ENVIRONMENTS]));
        if(JSON.stringify(filteredData) !== JSON.stringify(newFilteredData)){
            setFilteredData(newFilteredData);
        }
    },[remediationList, filteredData]);

    const onRemediationSaved = async ()=> {
        const list = await getRemediationListBySourceType(false, sourceType);
        setRemediationList(list);
        setSelectedRemediation({} as IRemediation);
        closeAddEditRemediationModal();
    };

    return <Stack fullHeight fullWidth padding={4}>
        <Stack fullHeight>
            <Stack>
                <RemediationFilterPanel
                    rawData={remediationList}
                    tableData={filteredData} 
                    isLoading={isLoading} 
                    onFilterChange={onFilterChangeCallBack}
                />
            </Stack>
            <RemediationTable
                data={filteredData}
                isLoading={isLoading}
                totalCount={remediationList.length}
                pageSize={20}
                onDelete={deleteRemediation}
                onRowClicked={editRemediation}
                onAdd={() => setIsAddEditModalOpen(true)}
            />
        </Stack>
        {(isAddEditModalOpen && RemediationModal) && <RemediationModal
            remediation={selectedRemediation}
            isOpen={isAddEditModalOpen}
            onClose={()=> closeAddEditRemediationModal()}
            onSave={()=> onRemediationSaved()}
            sourceType={sourceType}
        />}
    </Stack>;
};

export default RemediationPage;