import { IGenericWidgetDataItem } from 'common/module_interface/overview/Interface';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { AssetUrlParams, IAsset } from 'common/assets/common.assets';
import { IAggregationDataItem } from 'common/interface/data_services';
import { ASSET_FIELD_NAMES, CONTEXT_FIELD_FULL_NAMES, NETWORK_EXPOSURE_VALUES } from '../../../consts';
import { changeUrl, toQueryString } from 'common/utils/http';
import { getAssetUrl } from 'common/assets/assetUrls';
import { getTabProps, TabTimePeriodIndex } from 'common/module_interface/assets/utils';
import { IMaliciousAssetInfo } from './ExposureDashboard.interface';
import { FilterField } from 'common/components/Findings/Findings.interface';
import { logComponentClickedEvent } from 'common/utils/logUtils';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export const typesToPieItems = (types: IAggregationDataItem[], componentName?: string): IGenericWidgetDataItem[] => {
    const colorsByOrder = ['#D47192', '#D8765E', '#C7862D', '#A19929', '#68A85A', '#05AE8E', '#01A4D1', '#6794E3', '#8484DB', '#BA84DB',];
    return types.map((type, index) => {
        const iAsset = getProtectedAssetsService().getAssetByType(type.value) as IAsset;
        return {
            key: iAsset.displayName,
            value: type.count,
            color: colorsByOrder[index % colorsByOrder.length],
            onclick: () => {
                if (componentName) {
                    logComponentClickedEvent(componentName, { type: type.value });
                }
                getErmUrlsService().goToProtectedAssetsTable([
                    {
                        name: ASSET_FIELD_NAMES.type,
                        value: [iAsset.typeByPlatform],
                    },
                    {
                        name: CONTEXT_FIELD_FULL_NAMES.networkExposure,
                        value: [NETWORK_EXPOSURE_VALUES.PUBLIC],
                    },
                ]);
            },
        };
    });
};

export const assetsToListItems = (assets: IMaliciousAssetInfo[], componentName?: string): IGenericWidgetDataItem[] => {
    return assets.map(asset => {
        const iAsset = getProtectedAssetsService().getAssetByType(asset.type) as IAsset;
        return {
            key: asset.name,
            icon: iAsset?.icon,
            value: '',
            onclick: () => {
                const assetParams: AssetUrlParams = {
                    asset: iAsset,
                    platform: asset.platform,
                    cloudAccountId: asset.cloudAccountId,
                    entityType: asset.entityType,
                    entityId: asset.entityId,
                    tabName: 'magellan-network',
                    typeNumber: iAsset.typeNumber,
                };
                const urlInfo = getAssetUrl(assetParams);
                if (!urlInfo) {
                    return;
                }
                const filterFieldsArr: FilterField[] = [
                    {
                        name: 'ismalicious',
                        value: 'true',
                    },
                    {
                        name: 'action',
                        value: 'ACCEPT',
                    },
                    {
                        name: 'assetid',
                        value: asset.entityId,
                    },
                ];
                const tabProps = getTabProps('magellan-network', filterFieldsArr, -1, TabTimePeriodIndex.DAYS_7);
                const query = {
                    ...urlInfo.query,
                    ...tabProps,
                    tabOnly: true,
                };
                const queryString = toQueryString({ query });
                const finalUrl = `${urlInfo.url}?${queryString}`;
                if (componentName) {
                    logComponentClickedEvent(componentName, { type: asset.type });
                }
                changeUrl(finalUrl);
            },
        };
    });
};

