import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FindingsFilterPanel } from './Components/FilterPanel/FindingsFilterPanel';
import FindingsTable from '../FindingsTable/FindingsTable';
import {
    IFilterDetails,
    IFindingsExportButtonKey,
    ITableApis
} from 'common/module_interface/intelligence/Intelligence.interface';
import { FindingsTableDatasource, IDataSourceConfig } from './FindingsTableDatasource';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { FindingsFilterTypes as FilterTypes } from './Types/FindingsFilterTypes';
import { IActionUsageDef, IColumnUsageDef } from 'common/interface/general';
import { GridApi } from 'ag-grid-enterprise';
import { ColumnApi } from 'ag-grid-community';
import { ModalType } from '../Findings.const';
import { getMitreFilterFacetFields } from './Components/FilterPanel/FindingsFilterPanel.utils';
import { FindingFields } from 'common/module_interface/intelligence/Findings/Findings.const';
import { Aggregations } from 'common/components/FilterPanel/FilterPanel.interface';
import { Spinner, Table } from 'common/design-system/components-v2';
import { mergeActionDefs, mergeColumnDefs } from 'common/utils/tableUtils';
import { FindingsTableRegistry } from 'common/module_interface/intelligence/Findings/FindingsTableRegistry';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';

interface FindingsTableWrapperProps {
    tableId: string,
    columns: IColumnUsageDef[],
    filters?: IProtectedAssetFilter[],
    actionsCreator?: (openDialog: (dialogType: (ModalType | null)) => void) => IActionUsageDef[],
    originTypes?: string[],
    isRowSelectable?: (node: any) => boolean,
    disableFilterPanel?: boolean,
    disableColumnMenu?: boolean,
    disableGrouping?: boolean
    exportButtons?: IFindingsExportButtonKey[],
    hideExport?: boolean;
    isArchiveView?: boolean;
}

const FindingsTableWrapper: React.FC<FindingsTableWrapperProps> = ({
    tableId,
    columns,
    filters = [FilterTypes.ADD_FILTER],
    actionsCreator,
    originTypes = [],
    disableFilterPanel,
    disableColumnMenu,
    disableGrouping,
    isArchiveView
}) => {

    const FILTER_PANEL_ID: string = useMemo(() => `findings-filter-panel-id_${tableId}`, [tableId]);
    const mitreFacetFields: string[] = useMemo(() => getMitreFilterFacetFields(filters), [filters]);
    const isMitreColumnPresent: boolean = useMemo(() => columns?.some((col: IColumnUsageDef) => col.id === FindingFields.mitreDict), [columns]);
    const mitreInfo = useMemo(() => {
        return { mitreFacetFields, isMitreColumnPresent };
    }, [mitreFacetFields, isMitreColumnPresent]);
    const initDatasource = useMemo(() => {
        const defaultDatasourceConfig: IDataSourceConfig = {
            originTypes,
            filters,
            mitreInfo,
            isArchiveView
        };
        return new FindingsTableDatasource(defaultDatasourceConfig);
    }, [originTypes, filters, mitreInfo, isArchiveView]);
    const [filterValues, setFilterValues] = useState<IFilterDetails | undefined>(undefined);
    const [datasource, setDatasource] = useState<FindingsTableDatasource | undefined>(initDatasource);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const updateDatasource = useCallback((_datasource: FindingsTableDatasource) => {
        const apis: ITableApis | undefined = _datasource.getApis();
        datasource?.setApis(apis?.gridApi as GridApi, apis?.columnApi as ColumnApi);
        setDatasource(() => _datasource);
    }, [datasource]);

    const updateFilterValues = useCallback((_filterValues: IFilterDetails) => {
        setFilterValues(() => _filterValues);
    }, []);

    const getAggregations = useCallback(async (filterValues: IFilterDetails): Promise<Aggregations> => {
        updateFilterValues(filterValues);
        return datasource?.fetchIntelligenceFindingsAggregations(filterValues) ?? {};
    }, [datasource, updateFilterValues]);

    const getInitialAggregations = useCallback(async (): Promise<Aggregations> => {
        return datasource?.fetchIntelligenceFindingsAggregations() ?? {};
    }, [datasource]);

    const onChangeLoadingState = useCallback((loadingState: boolean) => {
        setIsLoading(loadingState);
    }, []);

    const getPlaceholderTableColumnDefs = useCallback(() => {
        return mergeColumnDefs(columns, FindingsTableRegistry.getColumnDefs());
    }, [columns]);

    const getPlaceholderTableActionsDefs: ITableAction[] = useMemo<ITableAction[]>(() => {
        if (!actionsCreator) return [];
        const actions: IActionUsageDef[] = actionsCreator(() => null);
        return mergeActionDefs(actions, FindingsTableRegistry.getActions());
    }, [actionsCreator]);

    const TablePlaceholder = () => (
        <Table
            gridOptions={{
                columnDefs: getPlaceholderTableColumnDefs(),
            }}
            actions={getPlaceholderTableActionsDefs}
            footer={(
                <Spinner size={12} />
            )}
        />
    );

    useEffect(() => {
        setIsLoading(true);
    }, [isArchiveView]);

    return (
        <>
            <FindingsFilterPanel
                filters={filters}
                disableFilterPanel={disableFilterPanel}
                getAggregations={getAggregations}
                getInitialAggregations={getInitialAggregations}
                filterId={FILTER_PANEL_ID}
                isArchiveView={isArchiveView}
                isLoading={isLoading}
                onChangeLoadingState={onChangeLoadingState}
            />
            {isLoading && <TablePlaceholder/>}
            {!isLoading && filterValues && <FindingsTable
                columns={columns}
                filters={filters}
                actionsCreator={actionsCreator}
                filterValues={filterValues}
                tableId={tableId}
                datasource={datasource}
                originTypes={originTypes}
                mitreInfo={mitreInfo}
                updateDatasource={updateDatasource}
                disableColumnMenu={disableColumnMenu}
                disableGrouping={disableGrouping}
                isArchiveView={isArchiveView}/>
            }
        </>
    );
};
export default FindingsTableWrapper;
