import { azure_actions } from '.';
import { batch } from 'react-redux';
import { getStoreService, getWebAppIframeService } from 'common/interface/services';
import AzureCloudAccountsService from '../services/cloudAccounts/azureCloudAccounts.service';
import ServerlessService from '../services/serverless/serverless.service';
import { AzureEnvironmentData } from 'common/module_interface/assets/AzureEnvironment';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import EnvironmentsService from '../../services/environments.service';
import { getCloudAccountsService } from 'common/interface/data_services';


const getMessageFromError: (error: any) => string = (error: any) => {
    const responseError = error.response?.data?.errors?.description?.join(', ');
    const responseErrorOpt1 = error.response?.data;
    const responseErrorOpt2 = error.response?.data?.title;
    const responseErrorOpt3 = error.response?.data?.message;
    const responseErrorOpt4 = error.message;
    return responseError || responseErrorOpt1 || responseErrorOpt2 || responseErrorOpt3 || responseErrorOpt4;
};

type GetAzureAccount = (azureId: string) => Promise<boolean>;

const getAzureAccount: GetAzureAccount = async (azureId) => {
    const { dispatch } = getStoreService().getReduxTools();
    try {
        dispatch(azure_actions.setLoading({ azureId, loading: true }));
        const results = await Promise.allSettled([
            AzureCloudAccountsService.cloudAccountGet(azureId),
            EnvironmentsService.getTotalAssetsNumberOfCloudAccount(azureId),
            ServerlessService.serverlessAzureAccountStatesGet(azureId),
            getCloudAccountsService().getCloudAccountByAccountId(azureId),
        ]);
        const [accountData, totalAssets, serverlessData, extraData] = results;
        if (accountData.status === 'rejected' || !accountData.value.data) throw new Error('');
        const isServerlessActive = serverlessData.status === 'fulfilled' && serverlessData?.value?.data?.state === 'active';
        const totalAssetsCount = totalAssets.status === 'fulfilled' ? totalAssets.value : 0;
        const isCloudbotsOnboarded = (extraData.status === 'fulfilled' && extraData.value?.isCloudbotsOnboarded) || false;
        const azureData: AzureEnvironmentData = {
            account: { ...accountData.value.data, totalAssets: totalAssetsCount, isServerlessActive: isServerlessActive, isCloudbotsOnboarded },
        };
        batch(() => {
            dispatch(azure_actions.setData({ azureId, data: azureData }));
            dispatch(azure_actions.setError({ azureId, error: false }));
            dispatch(azure_actions.setLoading({ azureId, loading: false }));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(azure_actions.setError({ azureId, error: true }));
            dispatch(azure_actions.setLoading({ azureId, loading: false }));
        });
        throw Error(getMessageFromError(error));
    }
};

export interface UpdateAzureAccountNameReq {
    name: string;
}

export interface UpdateAzureCredentialsReq {
    applicationId: string;
    applicationKey: string;
}

type UpdateAzureAccount = (cloudAccountId: string, name: string) => Promise<boolean>;
const updateAzureAccountName: UpdateAzureAccount = async (cloudAccountId, name) => {
    try {
        const { state } = getStoreService().getReduxTools();
        const { name: currentName } = state.env_azure.azureEvnironments[cloudAccountId].data?.account || {};

        if (name && name !== currentName) {
            const res = await AzureCloudAccountsService.cloudAccountNameUpdate({ cloudAccountId, name });
            if(res){
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.UPDATE_TABPANEL_TABNAME,
                        data: { id: cloudAccountId, newName: name }
                    }),
                );
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                    }),
                );
                getAzureAccount(cloudAccountId);
            }
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type UpdateCredentialsAzureAccount = (cloudAccountId: string, data: UpdateAzureCredentialsReq, updateState?: boolean) => Promise<boolean>;
const updateCredentialsAzureAccount: UpdateCredentialsAzureAccount = async (cloudAccountId, data, updateState) => {
    const { applicationId, applicationKey } = data;
    try {
        if (data.applicationId && data.applicationKey) {
            const res = await AzureCloudAccountsService.cloudAccountsCredentialsUpdate({ cloudAccountId, data: { applicationId, applicationKey } });
            if (res) {
                if (updateState) {
                    getWebAppIframeService().emitMessage(
                        new IframeMessageModel({
                            action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                        }),
                    );
                }
                getAzureAccount(cloudAccountId);
            }
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type DeleteAccount = (azureId: string, forceDelete?: boolean) => Promise<boolean>;
const deleteAccount: DeleteAccount = async (azureId, forceDelete = false) => {
    try {
        const { dispatch } = getStoreService().getReduxTools();
        if (forceDelete) {
            await AzureCloudAccountsService.cloudAccountDeleteForce(azureId);
            dispatch(azure_actions.setDeleted({ azureId }));
        }
        else{
            await AzureCloudAccountsService.cloudAccountDelete(azureId);
            dispatch(azure_actions.setDeleted({ azureId }));
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};


const AzureActions = {
    getAzureAccount,
    updateAzureAccountName,
    updateCredentialsAzureAccount,
    deleteAccount
};

export default AzureActions;
