import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import {
    IProtectedAssetFilter,
} from 'common/module_interface/assets/ProtectedAssets';
import { PATH_EVENTS_TREND_WIDGETS_FILTERS } from 'common/module_interface/events/Events';

const filters: IProtectedAssetFilter[] = [
    {
        id: 'add filter',
        position: 10,
    },
    {
        id: 'severity'
    },
    {
        id: 'origin'
    },
    {
        id: 'bundleName'
    },
    {
        id: 'cloudAccountType'
    },

];

export default function initializeEventsTrendWidgetsFilters() {
    globalAddinContainer.addMap(PATH_EVENTS_TREND_WIDGETS_FILTERS, filters);
}