import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { Datasource } from 'common/components/ProtectedAssets/datasource';
import { createErmDataSourceConfig } from '../../../../ProtectedAsset/ErmDataUtils';
import { ASSET_FIELD_NAMES } from '../../../../../consts';
import {
    GroupByResponseItem,
    ProtectedAssetsResponse
} from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { DASHBOARD_CACHE_CONFIG } from '../../../../../RiskManagement.utils';
import { IScoreData } from './RiskiestAssetTypes';

export interface IAssetTypesScoreCounters {
    scoreByType: IScoreData[];
    groupedItems: GroupByResponseItem[];
}

export const getAssetTypesScoreCounters = async (filterValues: IFiltersValues): Promise<IAssetTypesScoreCounters> => {
    const datasource = new Datasource(createErmDataSourceConfig(filterValues, 5, undefined, DASHBOARD_CACHE_CONFIG));
    return datasource.getAdHokGroupDataFromServer([{
        property: 'typeByPlatform',
        direction: 0,
        maxSize: 5,
        sortOption: 'BucketsCount',
        aggregateMaxRiskScore: true,
    }]).then((groupedItems: GroupByResponseItem[]) => {
        const types: string[] = groupedItems.map(item => item.fieldValue);
        const dataSourceConfig = createErmDataSourceConfig(filterValues, 0, undefined, DASHBOARD_CACHE_CONFIG);
        dataSourceConfig.filters.includedEntityTypes = types;
        const datasource = new Datasource(dataSourceConfig);
        return datasource.getAdHokDataFromServer({
            optionalAggregationFields: [ASSET_FIELD_NAMES.riskScoreByType],
            aggregationFields: [],
        }).then((countersResponse: ProtectedAssetsResponse) => {
            return {
                groupedItems,
                scoreByType: countersResponse.aggregations?.riskScoreByType ?? [],
            };
        });
    });
};