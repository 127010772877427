import { DetailsComponent } from './DetailsComponent';
import { NetworkGraphComponent } from './NetworkGraphComponent';
import { HeaderComponent } from './HeaderComponent';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EMPTY_RESULT, queriesMap } from '../Constants';
import { GraphData } from '../Services/GraphData';
import { getEntitiesGraphEngineService } from '../Services/EntitiesGraphEngineService';
import { SelectedItem, SelectedItemType } from '../Models/SelectedItem';
import { getCloudAccountsService } from 'common/interface/data_services';
import '../../Insight.scss';
import { useTranslation } from 'react-i18next';

const EntitiesGraphExplorer: React.FC = () => {
    const graphDataRef = useRef<GraphData>();
    const { t } = useTranslation();
    const runBtnStaticName = t('INSIGHT.ENTITIES_GRAPH_EXPLORER.RUN_BTN.STATIC');
    const runBtnWorkingName = t('INSIGHT.ENTITIES_GRAPH_EXPLORER.RUN_BTN.RUNNING');

    const [env, setEnv] = useState('');
    const [search, setSearch] = useState('');
    const [query, setQuery] = useState('');
    const [runBtnText, setRunBtnText] = useState(runBtnStaticName);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [nodesLimit, setNodesLimit] = useState(10);
    const [enablePhysics, setEnablePhysics] = useState(true);
    const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null);
    const [awsCloudAccounts, setAwsCloudAccounts] = useState<{ value: string, label: string }[] | null>(null);
    const runBtnDisable = runBtnDisableCheck();
    const [disableDetailsView,setDisableDetailsView]= useState(false);

    function runBtnDisableCheck(): boolean{
        return !(query !== '' && env !== '') || runBtnText === runBtnWorkingName;
    }

    useEffect(()=>{
        graphDataRef.current = graphDataRef.current || new GraphData();
        async function updateDisplayValue() {
            const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            const awsCloudAccounts = allCloudAccounts.filter(cloudAccount => cloudAccount.platform === 'aws').map(cloudAccountAws=> {return { value: cloudAccountAws.id, label: cloudAccountAws.name };});
            setAwsCloudAccounts(awsCloudAccounts);
        }
        updateDisplayValue();
    }, [graphDataRef]);

    const submitQuery = async () => {
        try {
            setRunBtnText(runBtnWorkingName);
            const result = await getEntitiesGraphEngineService().query(env, query, search, nodesLimit);
            setErrorMsg(result.entities.length ? null : EMPTY_RESULT);
            graphDataRef.current?.addToGraph(result);
        } catch (e: any) {
            setErrorMsg(`Failed to execute query. Please check your network and verify your session is still active. ${e.toString()}`);
        }
        setRunBtnText(runBtnStaticName);
    };
    const setSelectedNodeCallback = useCallback((nodeId: string) => {
        const selectedNode = graphDataRef.current?.nodes.get(nodeId);
        if (selectedNode) {
            setSelectedItem(new SelectedItem(
                selectedNode.id,
                SelectedItemType.NODE,
                selectedNode.type,
                selectedNode.label,
                selectedNode.properties,
                selectedNode.updateTimestamp,
                selectedNode.networkExposure,
                selectedNode.exposurePath));
        }
    }, [graphDataRef]);
    const removeNodeCallback = useCallback((nodeId: string) => {
        graphDataRef.current?.nodes.remove(nodeId);
    }, [graphDataRef]);

    const setSelectedEdgeCallback = useCallback((edgeId: string)=> {
        const selectedEdge = graphDataRef.current?.edges.get(edgeId);
        if (selectedEdge) {
            setSelectedItem(new SelectedItem(
                selectedEdge.id,
                SelectedItemType.EDGE,
                selectedEdge.type,
                selectedEdge.label, null, null, null, null));
        }
    }, [graphDataRef]);

    const resetGraph = useCallback(() => {
        graphDataRef.current?.reset();
        setErrorMsg(null);
        setSelectedItem(null);
    }, [graphDataRef]);

    const fetchNodeRelationshipsCallback = useCallback(async (nodeId: string) => {
        const result = await getEntitiesGraphEngineService().fetchEntityRelationships(env, nodeId);
        graphDataRef.current?.addToGraph(result);
    },[env]);
    const setErrorMsgCallback = useCallback(setErrorMsg, [setErrorMsg]);

    return (
        <div className={'insight-container'}>
            <div className={'insight-header'}>
                <HeaderComponent
                    clearGraphCallback={resetGraph}
                    submitQueryCallback={submitQuery}
                    setSearchValueCallback={setSearch}
                    setQueryValueCallback={setQuery}
                    setErrorMsgValueCallback={setErrorMsg}
                    setEnvValueCallback={setEnv}
                    setQueryNodeLimitCallback={setNodesLimit}
                    nodesLimit={nodesLimit}
                    errorMsg={errorMsg}
                    search={search}
                    queries={queriesMap}
                    cloudAccounts = {awsCloudAccounts}
                    runBtnDisable = {runBtnDisable}
                    runBtnText = {runBtnText}
                />
            </div>
            <div className={'insight-graph'}>
                <>
                    <ul className={'graph-legend'}>
                        <li>Click an entity to view its details</li>
                        <li>Double-click an entity to view add its relationships to the graph</li>
                    </ul>

                    <NetworkGraphComponent
                        data = { graphDataRef.current as GraphData }
                        setSelectedNodeCallback = { setSelectedNodeCallback }
                        setSelectedEdgeCallback = { setSelectedEdgeCallback }
                        fetchNodeRelationshipsCallback = { fetchNodeRelationshipsCallback }
                        setErrorMsgCallback = { setErrorMsgCallback }
                        enablePhysics={ enablePhysics }
                        setDisableDetailsView={setDisableDetailsView}
                    />
                </>
            </div>
            <div className={'insight-details'}>
                <DetailsComponent
                    physicsEnabled={ enablePhysics }
                    selectedItem={ selectedItem }
                    setEnablePhysics={ setEnablePhysics }
                    removeEntityFromGraphCallback={ removeNodeCallback }
                    disabledView = {disableDetailsView}
                />
            </div>
        </div>);
};

export default EntitiesGraphExplorer;