import { DASHBOARD_DYNAMIC_WIDGETS } from 'common/module_interface/overview/Consts';
import {
    DashboardWidgetTypes,
    IAddinWidgetsDataSource,
    IDashboardWidget,
    IWidgetDataConfiguration
} from 'common/module_interface/overview/Interface';
import { ProtectedAssetsSettings } from './Components/AssetsWidgetsSettings';
import { aggregationTypesOptions, widgetsTypesOptions } from './ProtectedAssetsWidgetsSettings';
import {
    getAssetsSummaryData,
    protectedAssetsDataGetter
} from './WidgetsData/ProtectedAssetsDataGetter';
import initializeProtectedAssetWidgetsFilters from './filters';
import AssetsIconAndNumberWidget from './Components/AssetsIconAndNumberWidget';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';

export const initializeProtectedAssetsWidgets = () => {
    const widgetsTypes = ['pie', 'column', 'top'];
    const aggregationsTypes = ['resourceGroup', 'cloudAccountId', 'platform', 'network', 'compartment', 'region', 'type'];
    const getAllWidgets = (): IWidgetDataConfiguration[] => {
        const widgets = [] as IWidgetDataConfiguration[];
        aggregationsTypes.map(type => {
            widgetsTypes.map(widgetType => {
                widgets.push({
                    dataId: aggregationTypesOptions[type].dataId,
                    displayName: aggregationTypesOptions[type].displayName,
                    type: widgetType as DashboardWidgetTypes,
                    getData: (widget: IDashboardWidget) => protectedAssetsDataGetter(widget, aggregationTypesOptions[type].dataGetter, type),
                    widgetComponent: widgetsTypesOptions[widgetType].component,
                    settingsComponent: ProtectedAssetsSettings
                });
            });
        });
        return widgets;
    };


    initializeProtectedAssetWidgetsFilters();

    const protectedAssetsWidgets: IAddinWidgetsDataSource = {
        dataSourceName: 'Protected Assets',
        dataField: { displayName: 'Aggregation', path: 'aggregation' },
        widgets: [
            ...getAllWidgets(),
            {
                dataId: 'summary',
                displayName: 'Summary',
                type: DashboardWidgetTypes.Summary,
                getData: (widget: IDashboardWidget) => protectedAssetsDataGetter(widget, getAssetsSummaryData),
                widgetComponent: AssetsIconAndNumberWidget,
                settingsComponent: ProtectedAssetsSettings
            }
        ]
    };

    globalAddinContainer.addMap(DASHBOARD_DYNAMIC_WIDGETS, [protectedAssetsWidgets], 'dataSourceName');
};
