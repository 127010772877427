import { IFindingModel } from 'common/components/Findings/Findings.interface';
import { LayoutDirection } from 'common/components/Graph/Models/LayoutDirection';
import { FindingSeverityServerEnum } from 'common/consts/FindingSeverity';

export interface IntelligenceFindingResult {
    cols : {
        action: number,
        findingKey: number,
        entity_name: number,
        entity_type: number,
        entity_id: number,
        entity_dome9Id: number,
        rule_name: number,
        rule_severity: number,
        env_cloudAccountId: number,
        assessmentRunTime: number,
        additionalField: number,
        assessmentHistoryId: number,
        alertType: number,
        alertId: number,
        bundle_id: number,
        bundle_name: number,
        category: number,
        env_externalAccountId: number,
        env_cloudAccountType: number,
        comments: number,
        rule_description: number,
        entity_fullEntityData: number,
        entity_network: number,
        entity_tags: number,
        labels: number,
        id: number,
        isExcluded: number,
        env_organizationalUnitId: number,
        env_organizationalUnitPath: number,
        stream_owner: number,
        remediationActions: number,
        rule_logic: number,
        rule_remediation: number,
        status: number,
        scanId: number,
        updateTime: number,
        magellan_alertWindowEndTime: number,
        magellan_alertWindowStartTime: number,
        rule_complianceTags: number,
        mitre_dict: number,
    },
    data: any[][]
}

export interface IntelligenceFinding {
    action: string,
    findingKey: string,
    entity_name: string,
    entity_type: string,
    entity_id: string,
    entity_dome9Id: string,
    rule_name: string,
    rule_severity: number,
    env_cloudAccountId: string,
    assessmentRunTime: string,
    additionalField?: any,
    assessmentHistoryId: string,
    alertType: string,
    alertId?: string,
    bundle_id?: string,
    bundle_name?: string,
    category?: string,
    env_externalAccountId: string,
    env_cloudAccountType: string,
    comments?: string[],
    rule_description?: string,
    entity_fullEntityData?: any,
    entity_network?: string,
    entity_tags?: string[],
    labels?: string[],
    id?: string,
    isExcluded?: boolean,
    env_organizationalUnitId: string,
    env_organizationalUnitPath: string,
    stream_owner: string,
    remediationActions: string[],
    rule_logic: string,
    rule_remediation?: string,
    status?: string,
    scanId: string | undefined,
    updateTime: string,
    magellan_alertWindowEndTime: string,
    magellan_alertWindowStartTime: string,
    rule_complianceTags?: string,
}

export interface IntelligenceTransformedFindingResult extends IFindingModel{
    id: string,
    entityName: string,
    entityType: string,
    entityExternalId: string,
    entityDome9Id: string | null,
    ruleName: string,
    severity: FindingSeverityServerEnum,
    cloudAccountId: string,
    createdTime: string
}

export const graphDirection: Record<string, LayoutDirection> = {
    'RTL': LayoutDirection.RightToLeft,
    'LTR': LayoutDirection.LeftToRight
};
