import { ColDef } from 'ag-grid-community';
import { INotification } from 'common/interface/notificationPage';
import { NotificationsTableRegistry } from '../NotificationsTableRegestries';
import i18n from 'common/services/translations/translations';
import { I18nNotifications } from '../NotificationsPage.consts';
import LinkStyleCellRenderer from '../Components/CellRenderers/LinkStyleCellRenderer';
import StatusCellRenderer from '../Components/CellRenderers/statusCellRenderer';
import { AssociationsCellRenderer } from '../Components/CellRenderers/AssociationsCellRenderer';
import { genericStringComparator } from '../Components/Common/notifications.utils';

const columnDefs: () => ColDef<INotification>[] = () => [
    {
        colId: 'checkbox',
        headerCheckboxSelection: true,
        width: 40,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
        lockPosition: 'left',
        checkboxSelection: () => {
            return true;
        },
        showDisabledCheckboxes: true,
    },
    {
        colId: 'name',
        field: 'name',
        headerName: i18n.t('TABLE.HEADERS.NAME', { ns: I18nNotifications }),
        width: 400,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.name}`,
        cellRenderer: LinkStyleCellRenderer,
    },
    {
        colId: 'status',
        field: 'status',
        headerName: i18n.t('TABLE.HEADERS.STATUS', { ns: I18nNotifications }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.status || '',
        comparator: genericStringComparator,
        valueFormatter: (params) => `${params.value}` || '',
        cellRendererSelector: params => ({ component: params.node.group ? 'agGroupCellRenderer' : StatusCellRenderer }),

    },
    {
        colId: 'associations',
        field: 'associations',
        headerName: i18n.t('TABLE.HEADERS.ASSOCIATIONS', { ns: I18nNotifications }),
        width: 200,
        suppressSizeToFit: true,
        sortable: true,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.associations || '',
        comparator: genericStringComparator,
        valueFormatter: (params) => `${params.value}` || '',
        cellRendererSelector: params => ({ component: params.node.group ? 'agGroupCellRenderer' : AssociationsCellRenderer }),

    }
];


export default function initializeColumnDefs() {
    NotificationsTableRegistry.addColumnDefs(columnDefs());
}
