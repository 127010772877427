import styled from 'styled-components';


const JiraStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const JiraNameWrapper = styled.div`
  width: 50%;
  margin: 24px ;
`;

const JiraEndpointUrlWrapper = styled.div`
  display: flex;
  margin: 0 24px ;
  align-items: flex-end;
`;

const JiraEndpointUrlInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const JiraAuthenticationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 24px ;
`;

const BasicAuthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
`;

const BasicAuthCredentialsInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const IgnoreCertificateValidationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 0 24px;
`;

const AdvanceUrlWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 24px 24px;
`;


export {
    JiraStyled,
    JiraNameWrapper,
    JiraEndpointUrlWrapper,
    JiraEndpointUrlInputWrapper,
    JiraAuthenticationWrapper,
    BasicAuthWrapper,
    BasicAuthCredentialsInputWrapper,
    IgnoreCertificateValidationWrapper,
    AdvanceUrlWrapper
};