import React from 'react';
import WidgetCard from 'common/components/Dashboard/WidgetCard/WidgetCard';
import '../../../riskManagement.scss';
import { IExposureSectionWidgetInfo } from './ExposureDashboard.interface';
import { ExposureStyled } from './ExposureDashboard.styled';

export const ExposureSectionWidgetCard: React.FC<{ widgetInfo: IExposureSectionWidgetInfo }> = ({ widgetInfo }) => {
    const { title, content, width = 100, loadingState, tooltip } = widgetInfo;
    return (
        <ExposureStyled.WidgetCardDiv width={width}>
            <WidgetCard
                classNames={{
                    title: 'widget-card-title',
                    container: 'widget-card-body',
                }}
                title={title}
                content={content}
                loadingState={loadingState}
                tooltip={tooltip}
            />
        </ExposureStyled.WidgetCardDiv>
    );
};
