import { createSelector } from '@reduxjs/toolkit';
import { AlibabaState } from './alibaba.interface';
import { RootState } from 'common/services/store/store';

const alibabaReducer = (state: RootState) => state.env_alibaba as AlibabaState;

const getAlibaba = createSelector([alibabaReducer, (_state: RootState, id: string) => id], (state, id) => state.alibabaEvnironments[id]);

export const AlibabaSelector = {
    getAlibaba,
};