export enum ORIGIN {
    'EVENTS.SOURCE_TYPE.COMPLIANCE_ENGINE' =0 ,
    'EVENTS.SOURCE_TYPE.INTELLIGENCE'=1 ,
    'EVENTS.SOURCE_TYPE.SERVERLESS' =2,
    'EVENTS.SOURCE_TYPE.CONTAINER_VULNERABILITY_SCANNER' =7,
    'EVENTS.SOURCE_TYPE.SOURCE_CODE_ASSURANCE' =8,
    'EVENTS.SOURCE_TYPE.INFRASTRUCTURE_CODE' =9,
    'EVENTS.SOURCE_TYPE.CIEM' =10,
    'EVENTS.SOURCE_TYPE.QUALYS' =101
}

export const EVENT_DETAILS_DRAWER_KEY = {
    key: 'EVENT_DETAILS_DRAWER_KEY',
    eventTypes: {
        delete: 'delete',
    },
};
