import React from 'react';
import { Ifield } from './ComplianceWidgetsSettings';
import { Button, Select } from '@dome9/berries/react-components';
import { ISelectOption } from '../Const/const';
import i18n from 'common/services/translations/translations';
import { SingleValue } from 'react-select';

export const ComplianceSelectionsSet:React.FC<{ set: Ifield[], index: number, onClear: Function }> = ({ set, index , onClear }) =>{
    return (
        <div className='flex flex-col py-6 justify-between'>
            { set.map((field, fieldIndex) =>{ 
                return field.displayCondition &&
                  <div className='w-full mb-6' key={fieldIndex}>
                      <div className='font-semibold mb-2'>
                          { field.title }
                      </div>
                      <div className='flex items-center justify-between'>
                          <div className='flex-grow'>
                              <Select
                                  required={false}
                                  value={ field.options?.filter(option => field.checkSelected(index,option.value)) }
                                  onChange={ (option: SingleValue<ISelectOption>) => field.onChange(index,option) }
                                  closeMenuOnSelect={ true }
                                  placeholder={ i18n.t('GENERAL.SELECT') }
                                  isSearchable={ true }
                                  menuPlacement="auto"
                                  options={ field.options }
                              />
                          </div>
                          <>{
                              onClear && fieldIndex === 0 && <div className={ 'flex flex-col-reverse' }>
                                  <div className="mx-4">
                                      <Button onClick={ ()=>onClear() } icon={ 'remove' } size={'sm'} className='btn--integrated'/>
                                  </div>
                              </div>
                          }</>
                      </div>
                  </div>;
            })
            }
        </div>
    );
};