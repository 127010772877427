import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Stack } from 'common/design-system/components-v2';
import NotificationsTable from './Components/NotificationsTable';
import { getNotificationPageService } from 'common/interface/services';
import NotificationsFilterPanel from './Components/NotificationsFilterPanel';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import {
    ASSOCIATIONS,
    TYPES, I18nNotifications
} from './NotificationsPage.consts';
import { useTranslation } from 'react-i18next';
import NotificationAddEditModal from './Components/AddEditNotificationModal/NotificationAddEditModal';
import { INotification } from './Interfaces/NotificationPageInterfaces';
import { useHistory, useLocation } from 'react-router-dom';
import { cleanQueryParams } from 'common/utils/http';
import {
    enrichNotificationsWithAssociations, enrichNotificationsWithCircuitBreakerData,
    getBasicNotificationData,
} from './NotificationsUtilsFunctions';
import { GridApi } from 'ag-grid-community';

const NotificationsPage: React.FunctionComponent = () => {
    const { t } = useTranslation( I18nNotifications );
    const [rawData, setRawData] = useState([] as INotification[]);
    const [filteredData, setFilteredData] = useState([] as INotification[]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
    const [currentFilters, setCurrentFilter] = useState({} as IFiltersValues);
    const [selectedNotification, setSelectedNotification] = useState({} as INotification);
    const allNotifications = useRef<INotification[]>([] as INotification[]);
    const location = useLocation();
    const history = useHistory();
    const apiRef = useRef<GridApi>();
    const onGridReady = useCallback(
        (params: any) => {
            apiRef.current = params.api;
        }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const notificationId = params.get('notificationId');
        if(rawData && notificationId){
            const notification = rawData.find(notification => notification.id === notificationId);
            notification && editNotification(notification);
        }
    }, [location,rawData]);

    const init = useCallback(async () => {
        const notifications = await getBasicNotificationData();
        allNotifications.current = notifications;
        setRawData(notifications);
        setIsLoading(false);
    },[]);

    const enrichNotificationData = useCallback(async () => {
        await enrichNotificationsWithAssociations(rawData,t);
        await enrichNotificationsWithCircuitBreakerData(rawData);
        if(apiRef?.current){
            apiRef.current?.refreshCells({ force: true });
        }
    },[rawData, t]);

    useEffect(() => {
        void enrichNotificationData();
    }, [enrichNotificationData]);

    useEffect(() => {
        void init();
    }, [init]);

    useEffect(() => {
        onFilterChangeCallBack(currentFilters);
    }, [currentFilters, rawData]);

    const editNotification = async (notification: INotification) => {
        const _notification = await getNotificationPageService().getNotificationById(notification?.id);
        _notification.statusDetails = allNotifications.current.find((item:any) => item.id === _notification.id)?.statusDetails;
        setSelectedNotification(_notification);
        setIsAddEditModalOpen(true);
    };

    const closeAddEditNotificationModal = async () => {
        const cleanedParamsURL = cleanQueryParams(window.location.href, ['notificationId']);
        setIsAddEditModalOpen(false);
        setSelectedNotification({} as INotification);
        history.replace('/notifications-list'+cleanedParamsURL.search);
    };

    const deleteNotification = (notifications: INotification[]) => {
        const rawDataAfterDelete = [] as INotification[];
        const filteredDataAfterDelete = [] as INotification[];
        const idsForDelete = [];
        for (const notification of notifications) {
            getNotificationPageService().deleteNotification(notification.id!);
            idsForDelete.push(notification.id);
        }
        for (const notification of rawData) {
            !idsForDelete.includes(notification.id) && rawDataAfterDelete.push(notification);
        }
        for (const notification of filteredData) {
            !idsForDelete.includes(notification.id) && filteredDataAfterDelete.push(notification);
        }

        setRawData(rawDataAfterDelete);
        setFilteredData(filteredDataAfterDelete);
    };

    const isFilterEmpty = (filtersValues:IFiltersValues) => {
        return Object.keys(filtersValues).find(filterKey => filtersValues[filterKey] !== '') === undefined;
    };

    const onFilterChangeCallBack = React.useCallback((filtersValues: IFiltersValues) => {
        setCurrentFilter(filtersValues);
        if(isFilterEmpty(filtersValues)){
            setFilteredData(rawData);
            setIsLoading(false);
            return;
        }
        let newFilteredData = [] as INotification[];
        const filterDataForFreeText = (notification: INotification, text: string) => {
            return notification.name.toLowerCase().includes(text.toLowerCase());
        };

        const filterDataForMultiSelect = (options: any, fieldInNotification: any) => {
            if (options === '' || options.length === 0) {
                return true;
            }
            for (const option of options) {
                if (Array.isArray(fieldInNotification)) {
                    if (fieldInNotification.includes(option)) {
                        return true;
                    }
                } else {
                    if (fieldInNotification === option) {
                        return true;
                    }
                }
            }
            return false;
        };

        newFilteredData = rawData.filter(data =>
            filterDataForFreeText(data, filtersValues[FILTERS_KEYS.FREE_TEXT]) &&
                //filterDataForMultiSelect(filtersValues[STATUS], data[STATUS]) &&
                filterDataForMultiSelect(filtersValues[ASSOCIATIONS], data[ASSOCIATIONS]) &&
                filterDataForMultiSelect(filtersValues[TYPES], data[TYPES]));

        if(JSON.stringify(filteredData) !== JSON.stringify(newFilteredData)){
            setFilteredData(newFilteredData);
        }
    },[rawData,filteredData]);

    const onNotificationSaved = async ()=> {
        setSelectedNotification({} as INotification);
        setIsLoading(true);
        await init();
        setIsLoading(false);
        closeAddEditNotificationModal();
    };

    return <Stack fullHeight fullWidth padding={4}>
        <Stack fullHeight>
            <Stack spacing={5} style={{ height: '50px' }}>
                <NotificationsFilterPanel rawData={rawData} tableData={filteredData} isLoading={isLoading} onFilterChange={onFilterChangeCallBack}/>
            </Stack>
            <Stack style={{ flexGrow: 1 }}>
                <NotificationsTable
                    onInnerGridReady={onGridReady}
                    data={filteredData}
                    allNotificationCount={rawData.length}
                    pageSize={20}
                    onDelete={deleteNotification}
                    onNotificationClicked={editNotification}
                    onAdd={() => setIsAddEditModalOpen(true)}
                />
            </Stack>
        </Stack>
        {isAddEditModalOpen && <NotificationAddEditModal notification={selectedNotification} isOpen={isAddEditModalOpen} onClose={()=>closeAddEditNotificationModal()} onSave={()=> onNotificationSaved()} />}
    </Stack>;
};

export default NotificationsPage;