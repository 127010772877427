import { useEffect, useState } from 'react';
import './App.scss';
import { setIframeIncomingMessageListener } from './platform/iframe.messaging';
import { useSelector } from 'react-redux';
import { initApp } from 'App.actions';
import { getAppLoaded, setAppLoaded, setHideAngularUntilNavigated } from 'App.reducer';
import { CpLoadingDots } from '@dome9/components/react/components';
import AppRouter from 'AppRouter';
import { getLoggerService, getStoreService } from 'common/interface/services';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { initializeProfiler } from './platform/3rdParty/profiler/profiler';
import { profileComponent } from 'common/components/profileComponent';
import { initializeMixpanel, trackPageView } from 'platform/3rdParty/mixpanel/mixpanel';
import { ReactComponent as SpriteIcon } from '@dome9/berries/icons/dist/sprite_system-icons.svg';
import { ReactComponent as SpriteVendorIcons } from '@dome9/berries/icons/dist/sprite_vendor-icons.svg';
import { ReactComponent as SpriteLogos } from '@dome9/berries/icons/dist/sprite_logos.svg';
import { Cookies } from 'react-cookie';
import { getBasicUserName } from 'platform/user/User.reducer';
import ModalDialog from 'common/design-system/components-v2/Modal/ModalDialog';
import ModalHeader from 'common/design-system/components-v2/Modal/ModalHeader';
import ModalContent from 'common/design-system/components-v2/Modal/ModalContent';
import { getIsCloudInfra } from 'common/utils/RuntimeEnvironment';
import setupLocatorUI from '@locator/runtime';

// Setup Locator UI in development mode
if (process.env.NODE_ENV === 'development') {
    setupLocatorUI();
}

initializeProfiler();
initializeMixpanel();
setIframeIncomingMessageListener();

const App = () => {
    const appLoaded = useSelector(getAppLoaded);
    const location = useLocation();
    let userName = useSelector(getBasicUserName);
    const [showDifferentUserModal] = useState<boolean>(false);
    useEffect(() => {
        Sentry.setTag('LocationPath', location.pathname + location.search);
        trackPageView();
        if (location.pathname !== decodeURI(window.location.pathname.replace('/v2', ''))) {
            getStoreService().dispatch(setHideAngularUntilNavigated(true));
        }
    }, [location]);

    useEffect(() => {
        window.addEventListener('focus', sessionCheck);
        initApp().then(() => {
            getStoreService().dispatch(setAppLoaded(true));
        });
        return () => {
            window.removeEventListener('focus', sessionCheck);
        };
    }, [userName]);

    const sessionCheck = () => {
        const cookies = new Cookies();
        const cookieUserName = cookies.get('username')?.toLowerCase();
        userName = userName?.toLowerCase();
        if(!getIsCloudInfra() && userName && cookieUserName && cookieUserName !== userName){
            console.error('Your were signed in with a different user. Please refresh this page');
        }
    };

    if (!appLoaded) {
        return (
            <div className='App'>
                <CpLoadingDots />
            </div>
        );
    }

    return (
        <Sentry.ErrorBoundary
            fallback={<p>An error has occurred</p>}
            onError={(error) => {
                getLoggerService().error(error);
            }}
        >
            <div className='App' >
                <div className='App__main'>
                    <div className='App__main--content'>
                        <AppRouter />
                    </div>
                    <ModalDialog width={'lg'} isOpen={showDifferentUserModal}>
                        <ModalHeader title={'Action Required'} onClose={()=> window.location.reload()} />
                        <ModalContent>
                            <h3>{'Your were signed in with a different user. Please refresh this page'}</h3>
                        </ModalContent>
                    </ModalDialog>
                </div>
                <SpriteIcon className='h-0' style={{ display: 'block', position: 'absolute' }} />
                <SpriteVendorIcons className='h-0' style={{ display: 'block', position: 'absolute' }} />
                <SpriteLogos className='h-0' style={{ display: 'block', position: 'absolute' }} />
            </div>
        </Sentry.ErrorBoundary>
    );
};

export default profileComponent(App);
