import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Icon } from '@dome9/berries/react-components';
import { A } from 'common/components/Anchor/Anchor';
import { IGroupMainMenuItem, IInternalMenuBase } from '../Interface';

export interface IMainMenuItemRendererProps {
    menuItem: IInternalMenuBase;
}

const GroupMenuItemRenderer: React.FC<IMainMenuItemRendererProps> = ({ menuItem }) => {
    const { t } = useTranslation();
    const mainMenuItem = menuItem as IGroupMainMenuItem;
    const value = t(mainMenuItem.label);
    return (
        <A className="flex items-center flex-1 h-full"
            href={mainMenuItem.groupState || mainMenuItem.state}>
            {mainMenuItem.icon &&
                <Icon name={mainMenuItem.icon} size={20} />
            }
            <div className="pl-6" data-aid={value}>
                {value}
            </div>
            {mainMenuItem.isPreview &&
                <Chip className='ml-5 mt-4' color='info' clickable={false} size='xs' label={t('GENERAL.PREVIEW')} />
            }
            {mainMenuItem.mainMenuChipTitle &&
                <Chip className='ml-5 mt-4' color='info' clickable={false} size='xs' label={mainMenuItem.mainMenuChipTitle} />
            }
        </A>
    );
};

export default GroupMenuItemRenderer;
