import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { AlibabaCloudAccountResetMissingPermissions, AlibabaCloudAccountAttachOrganizationalUnit } from './alibabaCloudAccountApi.interface';

const apiBaseUrl = 'AlibabaCloudAccount';

const attachOrganizationalUnit: AlibabaCloudAccountAttachOrganizationalUnit.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<AlibabaCloudAccountAttachOrganizationalUnit.Response>>(
        `${apiBaseUrl}/organizationalUnit/attach`,
        { method: 'POST', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const resetMissingPermissions: AlibabaCloudAccountResetMissingPermissions.Function = async (request) => {
    return await getHttpService().request<AxiosResponse<AlibabaCloudAccountResetMissingPermissions.Response>>(
        `${apiBaseUrl}/${request.id}/MissingPermissions/Reset`,
        { method: 'PUT', data: request },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};


const AlibabaCloudAccountApi = {
    attachOrganizationalUnit,
    resetMissingPermissions,
};

export default AlibabaCloudAccountApi;
