import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';

export const CveIdCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const options: IIconLinkCellProps = {
        isLink: false,
        value: params.data.id,
        levelIconProps: {
            category: 'severity',
            iconProps: { name: 'cve' },
            size: 'md',
            level: params.data.severity,
        }
    };
    return <IconLinkCellRenderer {...options} />;
};
