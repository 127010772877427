import { IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { ICveIgnoreItem, ICveIgnoreListService, ICveIgnorePartial, ICveIgnoreUpdate } from './CveIgnoreList.interface';
import { getCveIgnoreItemFromValues } from './CveIgnoreList.values';
import { getService } from 'common/extensibility/AddinContainer';
import { CVE_IGNORE_LIST_SERVICE_ID } from './CveIgnoreList.consts';

export const saveIgnoreItem = async (itemsMap: IItemsMap, origIgnoreItem?: ICveIgnorePartial): Promise<void> => {
    const ignoreItem: ICveIgnoreUpdate = getCveIgnoreItemFromValues(itemsMap);
    if (origIgnoreItem?.id) {
        await getCveIgnoreListService().updateIgnoreItem(origIgnoreItem.id, ignoreItem);
    } else {
        await getCveIgnoreListService().createIgnoreItem(ignoreItem);
    }
};

export function getCveIgnoreListService(): ICveIgnoreListService {
    return getService<ICveIgnoreListService>(CVE_IGNORE_LIST_SERVICE_ID);
}

export const fetchAllCveIgnoreItems = async (): Promise<ICveIgnoreItem[]> => {
    return getCveIgnoreListService().getAllIgnoreItems();
};

export const deleteCveIgnoreListItems = async (items: ICveIgnoreItem[]): Promise<void> => {
    const ids = items.map(item => item.id);
    await getCveIgnoreListService().deleteIgnoreItems(ids);
};