import { AxiosResponse } from 'axios';
import {
    BladeStatus,
    CftTabType,
    GetCftDataRequest,
    GetCftDataResponse,
    GetMemeberAccountConfigurationsResponse,
    IntelligenceRulesetDTO,
    OrgOnboardingConfigurationResponse,
    PostManagementStackRequest,
    PostManagementStackResponse,
    RulesetDTO,
    ValidateManagementStackArnRequest,
    ValidateStackSetArnRequest,
    CloudAccountGet
} from './aws.interface';
import { changeUrl, generalApiError } from 'common/utils/http';
import { setHideAngularUntilNavigated } from '../../../../App.reducer';
import { getHttpService, getStoreService } from 'common/interface/services';
import { setOrgOnboardingDone } from './aws.reducer';

export const getCftData = async (data: GetCftDataRequest): Promise<GetCftDataResponse> => {
    return await getHttpService().post('UnifiedOnboarding/Configuration', { data });
};

export const getOrganizationalOnboardingData = async (data: GetCftDataRequest): Promise<GetCftDataResponse> => {
    const { state } = getStoreService().getReduxTools();
    const managementAccountId = state.onboarding.managementAccountId;
    const configurationData = await getHttpService().get<OrgOnboardingConfigurationResponse>(`aws-organization-management-onboarding/management-stack${managementAccountId?.length ? `?awsAccountId=${managementAccountId}` : '' }`, { data });
    return {
        cfts: [{
            content: configurationData.content,
            type: CftTabType.Onboarding,
            url: configurationData.managementCftUrl
        }],
        cftUrl: configurationData.managementCftUrl,
        onboardingId: configurationData.onboardingId,
        externalId: configurationData.externalId,
        isManagementOnboarded: configurationData.isManagementOnboarded
    };
};

export const getIntelligenceRulesetsFromServer = async (): Promise<IntelligenceRulesetDTO[]> => {
    const state = getStoreService().state;
    const magellanBaseUrl = state.app.magellanUrl;
    return await getHttpService().get(`${magellanBaseUrl}/rulesets/all-metadata`, {});
};

export const getRulesetsFromServer = async (): Promise<RulesetDTO[]> => {
    return await getHttpService().get({ path: 'Compliance/Ruleset/view', cachingConfig: { useCache: true } });
};

export const getBladeStatusesFromServer = async (onboardingId: string): Promise<BladeStatus[]> => {
    return await getHttpService().get<BladeStatus[]>(`UnifiedOnboarding/GetStatus/${onboardingId}`);
};

export const exitOnBoardingWizard = () => {
    const dispatch = getStoreService().dispatch;
    dispatch(setHideAngularUntilNavigated(true));
    dispatch(setOrgOnboardingDone(false));
    const navigationUrl = '/cloud-account';
    changeUrl(navigationUrl);
};

export const postManagementStack = async (data: PostManagementStackRequest) => {
    return await getHttpService().request<PostManagementStackResponse>(
        'aws-organization-management',
        { data, method: 'POST' },
        {},
        generalApiError
    );
};

export const getMemeberAccountConfigurations = async () => {
    return await getHttpService().get<GetMemeberAccountConfigurationsResponse>('aws-organization-management-onboarding/member-account-configuration');
};

export const validateManagementStackArn = async (data: ValidateManagementStackArnRequest) => {
    return await getHttpService().request(
        'aws-organization-management/validate/credentials',
        { data , method: 'POST' },
        {},
        generalApiError
    );
};

export const validateStackSetArn = async (data: ValidateStackSetArnRequest) => {
    return await getHttpService().request(
        'aws-organization-management/validate/stackset-arn',
        { data , method: 'POST' },
        {},
        generalApiError
    );
};

export const getCloudAccountById: CloudAccountGet.Function = async (id) => {
    return await getHttpService().request<AxiosResponse<CloudAccountGet.Response>>(
        `cloudaccounts/${id}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};