import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { round } from 'lodash';
import { Stack } from 'common/design-system/components-v2';
import isNil from 'lodash/isNil';

const floatToPercentage = (value: number): string => {
    if (value === 0) {
        return '0';
    }
    const rounded = round(value * 100);
    if (rounded === 0) {
        return '< 1' ;
    }
    return rounded.toString();
};

export const IssueEPSSCellRenderer: React.FC<ICellRendererParams> = (params: { value: number }) =>
    <Stack justifyContent={'center'} alignItems={'center'}>
        {isNil(params.value) ? '' : `${floatToPercentage(params.value)}%`}
    </Stack>;
 