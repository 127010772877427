import { AxiosResponse } from 'axios';
import { getHttpService } from 'common/interface/services';
import { Permission } from './permission.interface';

// srl format "resourceType|cloudAccountId"
const permission: Permission.Function = async (srl) => {
    return await getHttpService().request<AxiosResponse<Permission.Response>>(
        `permission?srl=${srl}`,
        { method: 'GET' },
        { returnAsAxiosResponse: true },
        err => { throw err; }
    );
};

const PermissionService = {
    permission
};

export default PermissionService;
