import React, { useMemo } from 'react';
import { AssetsStyled } from './RiskiestAssets.styled';
import { IFieldInfo, LoadingState } from 'common/interface/general';
import { Spinner, Table } from 'common/design-system/components-v2';
import { ITotalSearchResults } from '../../RiskDashborad.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { GridOptions } from 'ag-grid-community';
import { getTopAssetsColumnDefs } from './RiskiestAssetsColumns';
import { ermTrans } from '../../../../../RiskManagement.utils';
import { RiskStyled } from '../../../../../RiskManagement.styled';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';

export const RiskiestAssets: React.FC<{ totalSearchResults: ITotalSearchResults, filterValues?: IFiltersValues }> = ({ totalSearchResults, filterValues, }) => {
    const assets: IProtectedAssetViewModel[] = useMemo(() => (totalSearchResults.response?.assets || []).slice(0, 5), [totalSearchResults.response?.assets]);
    const loadingState: LoadingState = useMemo(() => totalSearchResults.loadingState, [totalSearchResults.loadingState]);
    const routeFields: IFieldInfo[] | undefined = useMemo(() => filterValues?.fields, [filterValues?.fields]);

    const gridOptions: GridOptions = useMemo(() => {
        return {
            columnDefs: getTopAssetsColumnDefs(routeFields),
            defaultColDef: { sortable: false, resizable: true, suppressMenu: true, suppressMovable: true, },
            rowModelType: 'clientSide',
            enableRangeSelection: false,
            suppressContextMenu: true,
            suppressCellFocus: true,
            suppressRowDrag: true,
            suppressDragLeaveHidesColumns: true,
            rowData: assets,
        };
    }, [assets, routeFields]);

    if (loadingState === LoadingState.IS_LOADING) {
        return <AssetsStyled.TopDiv><RiskStyled.SpinnerDiv><Spinner /></RiskStyled.SpinnerDiv></AssetsStyled.TopDiv>;
    }

    return (
        <AssetsStyled.TopDiv>
            <AssetsStyled.HeaderDiv>{ermTrans('RISK_DASHBOARD.RISKIEST_ASSETS.TITLE')}</AssetsStyled.HeaderDiv>
            <AssetsStyled.TopTableDiv>
                <Table
                    gridOptions={gridOptions}
                    disableColumnMenu={true}
                    disableGrouping={true}
                />
            </AssetsStyled.TopTableDiv>
        </AssetsStyled.TopDiv>
    );
};