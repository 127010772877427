import { UserRolesTypes } from 'common/enum/UserRoles';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { AFTER_LOGIN_EVENT, APPLICATION_REDUCERS, REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { addMainMenuItem } from 'common/helpers/menu';
import { IMainMenuItem, IMenuSectionItem } from 'common/interface/menu';
import { IApplicationReducer } from 'common/interface/redux';
import { getMenuService, getStoreService } from 'common/interface/services';
import {
    DEFAULT_GLOBAL_HOME_PAGE_ID,
    DEFAULT_LOCAL_DASHBOARD_ID,
    FAVORITES_MENU_SECTION,
    OVERVIEW_MENU_ITEM,
} from 'common/module_interface/overview/Consts';
import {
    getAllDashboardsFromServer,
    getDefaultDashboardFromServer,
    getFavoritesDashboardsFromServer,
} from './Api';
import { DashboardPageContainer } from './Components/Dashboard/DashboardPageContainer';
import initializeAWSDefaultDashboard from './LocalDashboards/InitializeAWSDefaultDashboard';
import initializeAzureDefaultDashboard from './LocalDashboards/InitializeAzureDefaultDashboard';
import initializeGCPDefaultDashboard from './LocalDashboards/InitializeGCPDefaultDashboard';
import initializeHomeDashboard from './LocalDashboards/InitializeHomeDashboard';
import initializeKubernetesDefaultDashboard from './LocalDashboards/InitializeKubernetesDefaultDashboard';
import initializeOCIDefaultDashboard from './LocalDashboards/InitializeOCIDefaultDashboard';
import initializeServerlessDefaultDashboard from './LocalDashboards/InitializeServerlessDefaultDashboard';
import overviewReducer, { setAllDashboards, setDefaultDashboardId, setFavoriteDashboardsIds } from './Overview.reducer';
import { createPinnedMenuItems } from './Utils';
import { i18nOverviewNamespace } from './initialize.i18n';
import { getCloudAccountsService } from 'common/interface/data_services';
import { v4 } from 'uuid';
import i18next from 'i18next';
import { OverviewDashboardsRegistry } from 'common/module_interface/overview/OverviewDashboardsRegistry';

const HOME_DASHBOARD_ROUTE = `/?name=${DEFAULT_LOCAL_DASHBOARD_ID}`;

export default function initialize() {
    function initializeReducers() {
        const reducers: IApplicationReducer[] = [
            { name: 'overviewSlice', reducer: overviewReducer, isBlackList: true },
        ];
        globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');
    }

    const overviewMenuItem: IMainMenuItem = {
        id: OVERVIEW_MENU_ITEM,
        position: 10,
        icon: 'overview',
        label: 'NAVIGATION_MENU.DASHBOARD.OVERVIEW',
        permission: [UserRolesTypes.ALL],
        state: '/',
        urlAliases: ['/'],
        exact: true,
        sections: [
            {
                id: 'default',
                position: 1,
                items: [{
                    id: DEFAULT_LOCAL_DASHBOARD_ID,
                    name: DEFAULT_LOCAL_DASHBOARD_ID,
                    label: 'NAVIGATION_MENU.DASHBOARD.DEFAULTS.HOME',
                    position: 5,
                    permission: [UserRolesTypes.ALL],
                    state: HOME_DASHBOARD_ROUTE,
                },
                ],
            },
            {
                id: FAVORITES_MENU_SECTION,
                position: 50,
                items: [],
            },
        ],
    };


    const overviewPath = () => {
        return {
            condition: true,
            component: DashboardPageContainer,
            path: '/',
            exact: true,
        };
    };


    function addLoadingIndicatorToDashboardsMenu() {
        const menuItem: IMenuSectionItem = {
            id: v4(),
            position: 0,
            label: i18next.t('NAVIGATION_MENU.DASHBOARD.LOADING_FAVORITES', { ns: i18nOverviewNamespace }),
            state: '',
            permission: [UserRolesTypes.ALL],
            loadingItem: true,
        };
        getMenuService().addMenuItems(OVERVIEW_MENU_ITEM, FAVORITES_MENU_SECTION, [menuItem]);
    }

    const initDashboardData = async () => {
        try {
            const { dispatch } = getStoreService().getReduxTools();
            const allDashboardsFromServer = await getAllDashboardsFromServer();
            const favoriteDashboardsResponse = await getFavoritesDashboardsFromServer();
            const defaultDashboardResponse = await getDefaultDashboardFromServer();
            const allCloudAccounts = await getCloudAccountsService().getAllCloudAccounts();
            const registeredLocalDashboards = [
                initializeHomeDashboard(allCloudAccounts),
                initializeAWSDefaultDashboard(allCloudAccounts),
                initializeAzureDefaultDashboard(),
                initializeGCPDefaultDashboard(),
                initializeKubernetesDefaultDashboard(),
                initializeOCIDefaultDashboard(),
                initializeServerlessDefaultDashboard(),
            ];
            const _allDashboards = [...registeredLocalDashboards, ...allDashboardsFromServer];
            const defaultDashboardId = defaultDashboardResponse?.data || DEFAULT_GLOBAL_HOME_PAGE_ID;
            const favoriteDashboardsIds = favoriteDashboardsResponse?.data || [];
            const pinnedDashboards = createPinnedMenuItems(_allDashboards, defaultDashboardId, [...favoriteDashboardsIds]);

            getMenuService().addMenuItems(OVERVIEW_MENU_ITEM, FAVORITES_MENU_SECTION, pinnedDashboards);
            dispatch(setFavoriteDashboardsIds(favoriteDashboardsIds));
            dispatch(setAllDashboards(_allDashboards));
            dispatch(setDefaultDashboardId(defaultDashboardId));
        } catch (error) {
            console.error('error while setting favorite dashboards to menu', error);
        }
    };

    const addFavoriteDashboardsToMenu = () => {
        globalAddinContainer.add(AFTER_LOGIN_EVENT, [{
            id: 'overview-favorites',
            content: async () => {
                addLoadingIndicatorToDashboardsMenu();
                initDashboardData();
            },
        }]);
    };
    initializeReducers();
    addMainMenuItem(overviewMenuItem);
    addFavoriteDashboardsToMenu();
    OverviewDashboardsRegistry.addDefaultGlobalDashboardRoute(HOME_DASHBOARD_ROUTE, { route: HOME_DASHBOARD_ROUTE }, 100000);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'NEW_HOME_DASHBOARD_PAGE', content: overviewPath }]);
}
