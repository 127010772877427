import { IColumnUsageDef } from 'common/interface/general';
import { ColDef } from 'ag-grid-enterprise';
import { IssuesRegistry } from 'common/module_interface/RiskManagement/issues/IssuesRegistry';
import {
    TOP_ISSUE_FIELD_NAMES
} from 'modules/riskManagement/components/Issues/Issues.interface';
import { mergeColumnDefs } from 'common/utils/tableUtils';

const getColumnDefs = (onClickRuleTitle: (ruleTitle: string, severityLevel: number) => void): IColumnUsageDef[] => {
    return [
        {
            id: TOP_ISSUE_FIELD_NAMES.RULE_TITLE,
            colDefOverride: {
                flex: 1,
                minWidth: 150,
                cellRendererParams: {
                    customOnClick: onClickRuleTitle,
                },
            },
        },
        {
            id: TOP_ISSUE_FIELD_NAMES.SEVERITY,
            colDefOverride: {
                width: 115,
                minWidth: 115,
            },
        },
        {
            id: TOP_ISSUE_FIELD_NAMES.OCCURRENCES,
            colDefOverride: {
                width: 200,
                minWidth: 150,
            },
        },
    ];
};

export const getTopIssuesDashboardColumnDefs = (onClickRuleTitle: (ruleTitle: string, severityLevel: number) => void): ColDef[] => {
    return mergeColumnDefs(getColumnDefs(onClickRuleTitle), IssuesRegistry.getTopIssuesColumnDefs());
};