import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams } from 'common/components/Wizard/Wizard';
import { CardItem } from 'common/components/policy/Card';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Vendors } from 'common/consts/vendors';
import { Done } from 'common/components/policy/Done/Done';
import { IntelligencePlatformsSelectionComponent } from './IntelligencePlatformsSelectionComponent';
import { IntelligenceEnvironmentsSelectionComponent } from './IntelligenceEnvironmentsSelectionComponent';
import { IntelligencePlatformRulesetSelectionComponent } from './IntelligencePlatformRulesetSelectionComponent';
import { i18nIntelligenceNamespace } from '../initialize.i18n';
import { getAccountTypeNumberById } from 'common/utils/environments';
import { getIntelligenceService, INTELLIGENCE } from 'common/module_interface/intelligence/intelligence';
import { IIntelligencePolicy, IPolicyDto } from 'common/module_interface/intelligence/Intelligence.interface';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { finishFlow } from 'common/components/policy/utils';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

const STEPS_IDS = {
    PLATFORM: 'platform',
    ENVIRONMENT: 'environment',
    RULESET: 'ruleset',
    NOTIFICATION: 'notification',
    DONE: 'done',
};

const shouldDisplayStepsInitialState = {
    [STEPS_IDS.PLATFORM]: false,
    [STEPS_IDS.ENVIRONMENT]: false,
    [STEPS_IDS.RULESET]: false,
    [STEPS_IDS.NOTIFICATION]: false,
    [STEPS_IDS.DONE]: true,
};
export const AddIntelligencePolicyPage: React.FC = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const [selectedPlatform, setSelectedPlatform] = useState<Vendors>();
    const [selectedRulesets, setSelectedRulesets] = useState<ListItem[]>();
    const [flowStatusMessage, setFlowStatusMessage] = useState<string>();
    const [selectedNotifications, setSelectedNotifications] = useState<ListItem[]>();
    const [selectedEnvironments, setSelectedEnvironments] = useState<ListItem[]>([]);
    const returnUrl = useReturnUrl();
    const [stepsToDisplay, setStepsToDisplay] = useState(shouldDisplayStepsInitialState);
    useBreadcrumbsLastChildren([INTELLIGENCE, t('POLICY.ADD_VENDOR_POLICY')]);

    useEffect(() => {
        const shouldDisplaySteps = {
            [STEPS_IDS.PLATFORM]: true,
            [STEPS_IDS.ENVIRONMENT]: true,
            [STEPS_IDS.RULESET]: true,
            [STEPS_IDS.NOTIFICATION]: true,
            [STEPS_IDS.DONE]: true,
        };
        const setPredefinedFields = async () => {
            const querystring = new URLSearchParams(location.search);
            const rulesetId = querystring.get('rulesetId');
            const platformId = querystring.get('platformId');
            if (rulesetId) {
                await handleRulsetPredefined(rulesetId, shouldDisplaySteps);
            }
            if (platformId) {
                handlePlatformPredefined(platformId, shouldDisplaySteps);
            }
        };
        setPredefinedFields();
        setStepsToDisplay(shouldDisplaySteps);

    }, [location]);

    const handleRulsetPredefined = async (rulesetId: string, shouldDisplaySteps: { [p: string]: boolean }) => {
        shouldDisplaySteps[STEPS_IDS.RULESET] = false;
        const allRulesets = await getIntelligenceService().getIntelligenceRulesets();
        const ruleset = allRulesets.find(ruleset => ruleset.id === rulesetId);
        if (ruleset) {
            const rulesetItem: ListItem = {
                id: ruleset.id.toString(),
                name: ruleset.name,
            };
            setSelectedRulesets([rulesetItem]);
        }
    };

    const handlePlatformPredefined = (platformId: string, shouldDisplaySteps: { [p: string]: boolean }) => {
        setSelectedPlatform(platformId as Vendors);
        shouldDisplaySteps[STEPS_IDS.PLATFORM] = false;
    };

    const selectedPlatformChanged = (selectedItem: CardItem) => {
        selectedItem && setSelectedPlatform(selectedItem.id);
        setSelectedEnvironments([]);
        setSelectedRulesets([]);
        setSelectedNotifications([]);
    };

    const steps = [
        {
            id: STEPS_IDS.PLATFORM,
            name: t('POLICY.PLATFORM_SELECTION'),
            renderView: (renderViewParams: RenderViewParams) => {
                return <IntelligencePlatformsSelectionComponent
                    selectedPlatformId={selectedPlatform} {...renderViewParams}
                    selectedItemChanged={selectedPlatformChanged} />;
            },
            onNext: async () => {
                return;
            },
        }, {
            id: STEPS_IDS.ENVIRONMENT,
            name: t('POLICY.ENVIRONMENTS_SELECTION'),
            renderView: (renderViewParams: RenderViewParams) => {
                return <IntelligenceEnvironmentsSelectionComponent
                    preSelectedItems={selectedEnvironments} {...renderViewParams} platform={selectedPlatform}
                    selectedEnvironmentsChanged={setSelectedEnvironments} />;
            },
            onNext: async () => {
                return;
            },
        },
        {
            id: STEPS_IDS.RULESET,
            name: t('POLICY.RULESET_SELECTION'),
            renderView: (renderViewParams: RenderViewParams) => {
                return <IntelligencePlatformRulesetSelectionComponent preSelectedItems={selectedRulesets}
                    platform={selectedPlatform} {...renderViewParams}
                    selectedRulesetsChanged={setSelectedRulesets} />;
            },
            onNext: async () => {
                return;
            },
        },
        {
            id: STEPS_IDS.NOTIFICATION,
            name: t('POLICY.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams: RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams}
                    selectedNotificationsChanged={setSelectedNotifications} />;
            },
            onNext: async () => {
                saveChanges();
            },
        }, {
            id: STEPS_IDS.DONE,
            name: t('POLICY.DONE'),
            renderView: (renderViewParams: RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams}></Done>;
            },
            onNext: async () => {
                finishFlow(returnUrl);
            },
        },
    ];

    const saveChanges = async () => {
        if (selectedPlatform && selectedRulesets?.length && selectedNotifications?.length) {
            const policies: IIntelligencePolicy[] = [];
            for (const ruleset of selectedRulesets) {
                for (const environment of selectedEnvironments) {
                    const newPolicy: IIntelligencePolicy = {
                        cloudAccountId: environment.id,
                        bundleId: ruleset.id,
                        externalAccountId: environment.externalId || '',
                        cloudAccountType: getAccountTypeNumberById(selectedPlatform),
                        notificationIds: selectedNotifications.map((notification: ListItem) => notification.id),
                    };
                    policies.push(newPolicy);
                }
            }

            if (policies?.length) {
                try {
                    const policyDto: IPolicyDto = {
                        policies: policies,
                        logType: '',
                        cloudAccountIds: selectedEnvironments.map((environment) => environment.externalId || ''),
                    };

                    await getIntelligenceService().savePolicies(policyDto);
                    setFlowStatusMessage(t('POLICY.POLICY_ADDED_MESSAGE'));
                } catch {
                    setFlowStatusMessage(t('POLICY.ERROR_OCCURRED'));
                }
            }

        }

    };

    return (
        <div>
            {steps?.length ?
                <Wizard
                    steps={steps.filter(step => {
                        return stepsToDisplay[step.id] === true;
                    })}
                    title={t('POLICY.ADD_POLICY')}
                    doneButtonText={t('POLICY.DONE')}
                    onClose={() => finishFlow(returnUrl)} />
                : null}
        </div>
    );
};