import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AzureConfigurationObjectType,
    AzureOnboardingAWPScanType,
} from '../helpers/azure.interface';
import { Checkbox, Stack, Typography, Input, GroupSelection } from 'common/design-system/components-v2';
import { azureAwpConfigurationsRadioBtns } from '../helpers/azure.consts';
import { Icon } from '@dome9/berries/react-components';

type AWPConfigSectionProps = {
    title?: string,
    initialSubscriptionId?: string,
    onChange: (payload: AzureConfigurationObjectType) => void;
    formValidations?: { valid: boolean, errors?: any };
    initialValue?: AzureConfigurationObjectType;
}
const AWPConfigSection: React.FC<AWPConfigSectionProps> = ({ title, onChange, formValidations, initialValue })=>{
    const { t } = useTranslation();
    const [onboardingMode, setOnboardingMode] = useState(initialValue?.onboardingMode || AzureOnboardingAWPScanType.inAccountHub);
    const [isCentralizedConfig, setIsCentralizedConfig] = useState(initialValue?.onboardingMode === AzureOnboardingAWPScanType.inAccountHub || false);
    const [withFunctionAppsScan, setWithFunctionAppsScan] = useState(initialValue?.withFunctionAppsScan !== false);
    const [subscriptionId, setSubscriptionId] = useState(initialValue?.centralizedSubscriptionId || '');
    useEffect(() => {
        if (onChange) {
            const payload: AzureConfigurationObjectType = {
                isEnabled: true,
                onboardingMode,
            };
            if(onboardingMode === AzureOnboardingAWPScanType.inAccountHub){
                payload.centralizedSubscriptionId = subscriptionId;
                payload.withFunctionAppsScan = withFunctionAppsScan;
            }
            onChange(payload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onboardingMode, subscriptionId, withFunctionAppsScan]);

    useEffect(() => {
        if (onboardingMode === AzureOnboardingAWPScanType.inAccountHub) setIsCentralizedConfig(true);
        else setIsCentralizedConfig(false);
    }, [onboardingMode]);
    const handleScanMode = (value: AzureOnboardingAWPScanType) => {
        setOnboardingMode(value);
    };
    return (
        <>
            { title && <Typography variant='body500'>{t(title)}</Typography>}
            <Stack direction="column" spacing={1} padding={[2,0,1,0]} style={{ maxWidth: '600px' }}>
                <Stack direction="row">
                    <GroupSelection
                        options={azureAwpConfigurationsRadioBtns}
                        onChange={(value) => handleScanMode(value as AzureOnboardingAWPScanType)}
                        value={onboardingMode}
                        direction="row"
                    />
                </Stack>
                {isCentralizedConfig && (
                    <Stack direction="row" flexWrap padding={[1,0,0]} spacing={2}>
                        <Input
                            id="azure-awp-subscription-id-input"
                            data-aid='azure-awp-subscription-id-input'
                            label={t('ON_BOARDING.AZURE.AWP_SUBSCRIPTION_ID')}
                            required
                            value={subscriptionId}
                            onChange={(event) => setSubscriptionId(event.target.value)}
                            isError={formValidations?.errors?.['awp.centralizedSubscriptionId']}
                            helperText={formValidations?.errors?.['awp.centralizedSubscriptionId'] ? formValidations.errors['awp.centralizedSubscriptionId'][0]?.message : undefined}
                            fullWidth
                            clearable
                        />
                        <Stack direction="row" alignItems="center" spacing={2} margin={[0,0,0,0.5]} fullWidth>
                            <Icon color='primary' name='info' size={14}/>
                            <Typography variant='body'>{t('ON_BOARDING.AZURE.AWP_INFO')}</Typography>
                        </Stack>
                        <Checkbox
                            id="azure-awp-skip-scan-checkbox"
                            checked={withFunctionAppsScan} onChange={(ev: any) => setWithFunctionAppsScan(ev.target.checked)}
                            label={t('ON_BOARDING.AZURE.AWP_WITH_FUNCTION_APP')}/>
                    </Stack>
                )}
            </Stack>
        </>
    );
};

export default React.memo(AWPConfigSection);