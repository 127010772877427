import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { AutoActionsService } from '../../services/AutoActionsService';
import { AUTO_ACTIONS_SERVICE_ID } from 'common/module_interface/RiskManagement/autoActions/AutoActions';
import { initializeAutoActionEditor } from './AutoActionEditor/AutoActionEditor.initialize';

const initializeAutoActionService = () => {
    globalAddinContainer.addService(AUTO_ACTIONS_SERVICE_ID, new AutoActionsService());
};

export default function initializeAutoActions() {
    initializeAutoActionEditor();
    initializeAutoActionService();
}
