import { Datasource } from 'common/components/ProtectedAssets/datasource';
import {
    ALL_FILTER_FIELDS_NAMES,
    ASSET_FIELD_NAMES,
    CONTEXT_FIELD_FULL_NAMES,
    ERM_CONSTS,
    FULL_ERM_AGGREGATION,
} from '../../../consts';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { createErmDataSourceConfig } from '../../ProtectedAsset/ErmDataUtils';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { DASHBOARD_CACHE_CONFIG, removeFilterFields } from '../../../RiskManagement.utils';
import {
    ASSETS_FILTER_TYPES,
    createRecentlyUsedFilter,
    createSavedFiltersFilter
} from '../BaseDashboard/DashboardFilters';
import { IFieldInfo } from 'common/interface/general';
import { wafDashboardSupportedFilterTypes } from '../../WafProtection/WafProtection.consts';

export const riskDashboardFilters: IProtectedAssetFilter[] = [
    ASSETS_FILTER_TYPES.ADD_FILTER,
    ASSETS_FILTER_TYPES.ORGANIZATIONAL_UNIT,
    ASSETS_FILTER_TYPES.PLATFORM,
    ASSETS_FILTER_TYPES.TYPE,
    ASSETS_FILTER_TYPES.ENVIRONMENT,
    ASSETS_FILTER_TYPES.BUSINESS_PRIORITY,
    ASSETS_FILTER_TYPES.NETWORK_EXPOSURE,
    ASSETS_FILTER_TYPES.IAM_EXPOSURE,
    ASSETS_FILTER_TYPES.DATA_SENSITIVITY,
    createRecentlyUsedFilter('erm-recent-dashboard'),
    createSavedFiltersFilter('erm-risk-dashboard-saved-filters'),
    ASSETS_FILTER_TYPES.CLEAR_ALL,
];

export const getTotalResponse = (filterValues: IFiltersValues) => {
    const datasource = new Datasource(createErmDataSourceConfig(filterValues, 10, undefined, DASHBOARD_CACHE_CONFIG));
    return datasource.getAdHokDataFromServer(FULL_ERM_AGGREGATION, 10);
};

export const { DASHBOARD_DEFAULT_FILTERS_FIELDS } = ERM_CONSTS;

export const getCvesFilterFields = (filterValues?: IFiltersValues): IFieldInfo[] => {
    return [
        ...(removeFilterFields([ASSET_FIELD_NAMES.riskScore, ASSET_FIELD_NAMES.cves], filterValues?.fields) || []),
        ...DASHBOARD_DEFAULT_FILTERS_FIELDS.CVES,
    ];
};

export const getSecretsFilterFields = (filterValues?: IFiltersValues): IFieldInfo[] => {
    return [
        ...(removeFilterFields([ASSET_FIELD_NAMES.riskScore, ASSET_FIELD_NAMES.secrets], filterValues?.fields) || []),
        ...DASHBOARD_DEFAULT_FILTERS_FIELDS.SECRETS,
    ];
};

export const getNetworkExposureFilterFields = (filterValues?: IFiltersValues): IFieldInfo[] => {
    return [
        ...(removeFilterFields([ASSET_FIELD_NAMES.riskScore, CONTEXT_FIELD_FULL_NAMES.networkExposure], filterValues?.fields) || []),
        ...DASHBOARD_DEFAULT_FILTERS_FIELDS.NETWORK_EXPOSURE,
    ];
};

export const getIamExposureFilterFields = (filterValues?: IFiltersValues): IFieldInfo[] => {
    return [
        ...(removeFilterFields([ASSET_FIELD_NAMES.riskScore, CONTEXT_FIELD_FULL_NAMES.iamExposure], filterValues?.fields) || []),
        ...DASHBOARD_DEFAULT_FILTERS_FIELDS.IAM_EXPOSURE,
    ];
};

export const getDataSensitivityFilterFields = (filterValues?: IFiltersValues): IFieldInfo[] => {
    return [
        ...(removeFilterFields([ASSET_FIELD_NAMES.riskScore, CONTEXT_FIELD_FULL_NAMES.dataSensitivity], filterValues?.fields) || []),
        ...DASHBOARD_DEFAULT_FILTERS_FIELDS.DATA_SENSITIVITY,
    ];
};

export const getTotalAssetsFilterFields = (filterValues?: IFiltersValues): IFieldInfo[] => {
    return [
        ...(removeFilterFields([ASSET_FIELD_NAMES.riskScore], filterValues?.fields) || []),
        ...DASHBOARD_DEFAULT_FILTERS_FIELDS.TOTAL_ASSETS,
    ];
};

export const getWAFFilterFields = (filterValues?: IFiltersValues): IFieldInfo[] => {
    const wafNotSupported = Object.values(ALL_FILTER_FIELDS_NAMES).filter((fieldName: string) =>
        !wafDashboardSupportedFilterTypes.includes(fieldName));
    return [
        ...(removeFilterFields(wafNotSupported, filterValues?.fields) || []),
    ];
};
