import { gcp_actions } from '.';
import { batch } from 'react-redux';
import { getStoreService, getWebAppIframeService } from 'common/interface/services';
import GcpCloudAccountsService from '../services/cloudAccounts/gcpCloudAccounts.service';
import { GcpEnvironmentData } from 'common/module_interface/assets/GcpEnvironment';
import IframeMessageModel, { IFRAME_MESSAGE_ACTIONS } from 'common/interface/IFrame.message.model';
import EnvironmentsService from '../../services/environments.service';


const getMessageFromError: (error: any) => string = (error: any) => {
    const responseError = error.response?.data?.errors?.description?.join(', ');
    const responseErrorOpt2 = error.response?.data?.title;
    const responseErrorOpt3 = error.response?.data?.message;
    const responseErrorOpt4 = error.message;
    return responseError || responseErrorOpt2 || responseErrorOpt3 || responseErrorOpt4;
};

type GetGcpAccount = (gcpId: string) => Promise<boolean>;

const getGcpAccount: GetGcpAccount = async (gcpId) => {
    const { dispatch } = getStoreService().getReduxTools();
    try {
        dispatch(gcp_actions.setLoading({ gcpId, loading: true }));
        const results = await Promise.allSettled([
            GcpCloudAccountsService.cloudAccountGet(gcpId),
            EnvironmentsService.getTotalAssetsNumberOfCloudAccount(gcpId)
        ]);
        const [accountData, totalAssets] = results;
        if (accountData.status === 'rejected' || !accountData.value.data) throw new Error('');
        const totalAssetsCount = totalAssets.status === 'fulfilled' ? totalAssets.value : 0;
        const gcpData: GcpEnvironmentData = {
            account: { ...accountData.value.data, totalAssets: totalAssetsCount },
        };
        batch(() => {
            dispatch(gcp_actions.setData({ gcpId, data: gcpData }));
            dispatch(gcp_actions.setError({ gcpId, error: false }));
            dispatch(gcp_actions.setLoading({ gcpId, loading: false }));
        });
        return true;
    } catch (error) {
        batch(() => {
            dispatch(gcp_actions.setError({ gcpId, error: true }));
            dispatch(gcp_actions.setLoading({ gcpId, loading: false }));
        });
        throw Error(getMessageFromError(error));
    }
};

export interface UpdateGcpAccountNameReq {
    name: string;
}

export interface UpdateGcpCredentialsReq {
    name: string;
    serviceAccountCredentials: Object;
}

type UpdateGcpAccount = (cloudAccountId: string, name: string) => Promise<boolean>;
const updateGcpAccountName: UpdateGcpAccount = async (cloudAccountId, name) => {
    try {
        const { state } = getStoreService().getReduxTools();
        const { name: currentName } = state.env_gcp.gcpEvnironments[cloudAccountId].data?.account || {};

        if (name && name !== currentName) {
            const res = await GcpCloudAccountsService.cloudAccountNameUpdate({ cloudAccountId, name });
            if(res){
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.UPDATE_TABPANEL_TABNAME,
                        data: { id: cloudAccountId, newName: name }
                    }),
                );
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                    }),
                );
                getGcpAccount(cloudAccountId);
            }
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type UpdateCredentialsGcpAccount = (cloudAccountId: string, data: UpdateGcpCredentialsReq) => Promise<boolean>;
const updateCredentialsGcpAccount: UpdateCredentialsGcpAccount = async (cloudAccountId, data) => {
    const { name, serviceAccountCredentials } = data;
    try {
        if (name && serviceAccountCredentials) {
            const res = await GcpCloudAccountsService.cloudAccountsCredentialsUpdate({ cloudAccountId, data: { name, serviceAccountCredentials } });
            if(res){
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.UPDATE_TABPANEL_TABNAME,
                        data: { id: cloudAccountId, newName: name }
                    }),
                );
                getWebAppIframeService().emitMessage(
                    new IframeMessageModel({
                        action: IFRAME_MESSAGE_ACTIONS.RELOAD_STATE,
                    }),
                );
                getGcpAccount(cloudAccountId);
            }
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

type DeleteAccount = (gcpId: string, forceDelete?: boolean) => Promise<boolean>;
const deleteAccount: DeleteAccount = async (gcpId, forceDelete = false) => {
    try {
        const { dispatch } = getStoreService().getReduxTools();
        if (forceDelete) {
            await GcpCloudAccountsService.cloudAccountDeleteForce(gcpId);
            dispatch(gcp_actions.setDeleted({ gcpId }));
        }
        else{
            await GcpCloudAccountsService.cloudAccountDelete(gcpId);
            dispatch(gcp_actions.setDeleted({ gcpId }));
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};

export interface addGSuiteReq {
    email: string;
    cloudAccountId: string;
    domain: string;
}

type AddGSuite = (cloudAccountId: string, email: string, domain: string) => Promise<boolean>;
const addGSuite: AddGSuite = async (cloudAccountId, email, domain) => {
    try {
        const res = await GcpCloudAccountsService.cloudAccountAddGSuite({ cloudAccountId, email, domain });
        if(res){
            getGcpAccount(cloudAccountId);
        }
        return true;
    } catch (error) {
        throw Error(getMessageFromError(error));
    }
};


const GcpActions = {
    getGcpAccount,
    updateGcpAccountName,
    updateCredentialsGcpAccount,
    deleteAccount,
    addGSuite
};

export default GcpActions;
