import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { REACT_PAGE_ROUTE } from 'common/extensibility/WellKnownPaths';
import { AddCiemPlatformPolicyPage } from './AddCiemPlatformPolicyPage';
import { AddCiemEnvironmentPolicyPage } from './AddCiemEnvironmentPolicyPage';
import { AddCiemOUPolicyPage } from './AddCiemOUPolicyPage';
import { EditCIEMPolicyPage } from './EditCIEMPolicyPage';
export const initializePolicies = () => {
    const policyPageRoute = {
        condition: true,
        component: AddCiemPlatformPolicyPage,
        path: '/ciem/policy/add',
    };

    const policyEnvironmentPageRoute = {
        condition: true,
        component: AddCiemEnvironmentPolicyPage,
        path: '/ciem/environment/add',
    };

    const policyOUPageRoute = {
        condition: true,
        component: AddCiemOUPolicyPage,
        path: '/ciem/ou/add',
    };

    const policyEditPageRoute = {
        condition: true,
        component: EditCIEMPolicyPage,
        path: '/ciem/policy/edit',
    };


    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'ciem-vendor-policy', content: policyPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'ciem-environment-policy', content: policyEnvironmentPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'ciem-ou-policy', content: policyOUPageRoute }]);
    globalAddinContainer.add(REACT_PAGE_ROUTE, [{ id: 'ciem-edit-policy', content: policyEditPageRoute }]);

};