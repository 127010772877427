import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const CloudBotParametersWrapper = styled(Stack)`
    min-width: 200px;
`;

const CloudBotParameter = styled(Stack)``;

export const CloudBotCardStyles = {
    CloudBotParametersWrapper,
    CloudBotParameter,
};
