import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams } from 'common/components/Wizard/Wizard';
import { getRulesetService } from 'common/interface/services';
import { IPolicy, IVendorRuleset, OU_POLICY_RULESETS } from 'common/interface/policy';
import { i18nPolicyNamespace } from '../initialize.i18n';
import { CSPM } from '../initialize';
import { getShortOUPath, OUSelectionComponent } from 'common/components/policy/OUSelectionComponent';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { RulesetMultiSelectionComponent } from 'common/components/policy/RulesetMultiSelectionComponent';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { finishFlow, saveUpdatePolicies } from 'common/components/policy/utils';
import { Done } from 'common/components/policy/Done/Done';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { getOrganizationalUnitService, IOrganizationalUnit } from 'common/interface/data_services';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

const STEPS_IDS = {
    OUS: 'organizations units',
    RULESET: 'ruleset',
    NOTIFICATION: 'notification',
    DONE: 'done'
};

const AddOUPolicyPage: React.FC = () => {
    const { t } = useTranslation(i18nPolicyNamespace);
    const [selectedOUs,setSelectedOUs] = useState<ListItem[]>();
    const [selectedRulesets,setSelectedRulesets] = useState<ListItem[]>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const returnUrl = useReturnUrl();
    const [stepsToDisplay, setStepsToDisplay] = useState({
        [STEPS_IDS.OUS]: true,
        [STEPS_IDS.RULESET]: true,
        [STEPS_IDS.NOTIFICATION]: true,
        [STEPS_IDS.DONE]: true
    });
    useBreadcrumbsLastChildren([CSPM,t('GENERAL.ADD_OU_POLICY')]);

    useEffect(() => {
        const setPredefinedFields = async () => {
            const shouldDisplaySteps = { ...stepsToDisplay };
            const allOrganizationalUnits = await getOrganizationalUnitService().getAllOrganizationalUnitsFlatWithPath();
            const querystring = new URLSearchParams(location.search);
            const rulesetId = querystring.get('rulesetId');
            rulesetId && handleRulesetPredefined(rulesetId, shouldDisplaySteps);
            if(allOrganizationalUnits.length === 1){
                handleOUPredefined(allOrganizationalUnits,shouldDisplaySteps);
            }

            setStepsToDisplay(shouldDisplaySteps);
        };
        setPredefinedFields();

    }, [location]);

    const handleRulesetPredefined = async (rulesetId: string, shouldDisplaySteps: { [p:string]: boolean }) => {
        shouldDisplaySteps[STEPS_IDS.RULESET] = false;
        const allRulesets = await getRulesetService().getAllRulesets();
        const ruleset = allRulesets.find(ruleset => ruleset.id === +rulesetId);
        if (ruleset) {
            const rulesetItem: ListItem = {
                id: ruleset.id.toString(),
                name: ruleset.name
            };
            setSelectedRulesets([rulesetItem]);
        }
    };

    const handleOUPredefined = async (ous: IOrganizationalUnit[], shouldDisplaySteps: { [p:string]: boolean }) => {
        shouldDisplaySteps[STEPS_IDS.OUS] = false;
        const items : ListItem[] = ous?.map((ou) => {
            return {
                id: ou.id,
                name: getShortOUPath(ou.path) || '',
                icon: '',
            };
        }) || [];
        setSelectedOUs(items);

    };

    const checkIfShouldResetRulesets = () => {
        return stepsToDisplay[STEPS_IDS.RULESET];
    };

    const selectedOUsChanged = (selectedItems: ListItem[]) => {
        selectedItems && setSelectedOUs(selectedItems);
        checkIfShouldResetRulesets() && setSelectedRulesets([]);
        setSelectedNotifications([]);
    };

    const steps = [
        {
            id: STEPS_IDS.OUS,
            name: t('GENERAL.OU_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <OUSelectionComponent preSelectedItems={selectedOUs} selectedOUsChanged={selectedOUsChanged} {...renderViewParams } />;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            id: STEPS_IDS.RULESET,
            name: t('GENERAL.RULESET_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                const vendorsRulesetList = globalAddinContainer.get<IVendorRuleset>(OU_POLICY_RULESETS);
                return <RulesetMultiSelectionComponent vendorRulesets={vendorsRulesetList} preSelectedItems={selectedRulesets} {...renderViewParams } selectedRulesetsChanged={setSelectedRulesets}/>;
            },
            onNext: async ()=>{
                return ;
            },
        },
        {
            id: STEPS_IDS.NOTIFICATION,
            name: t('GENERAL.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={setSelectedNotifications}/>;
            },
            onNext: async ()=>{
                saveChanges();
            },
        },{
            id: STEPS_IDS.DONE,
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ];

    const saveChanges = async () => {
        const policies:IPolicy[] = [];
        const allRulesets = await getRulesetService().getAllRulesets();
        if(selectedOUs?.length && selectedRulesets?.length && selectedNotifications?.length ){
            for (const ou of selectedOUs) {
                for (const ruleset of selectedRulesets) {

                    const rulesetId = +ruleset.id;
                    const _ruleset = allRulesets.find((_ruleset) => _ruleset.id === rulesetId);
                    const policy:IPolicy = {
                        targetId: ou.id,
                        rulesetPlatform: _ruleset?.cloudVendor,
                        targetType: 'organizationalUnit',
                        rulesetId: rulesetId,
                        notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                    };

                    if(rulesetId < 0) {
                        policy.rulesetVersion = ruleset.selectedVersion?.version;
                    }
                    policies.push(policy);
                }
            }

            if(policies?.length){
                setFlowStatusMessage(t('GENERAL.PROCESSING'));
                const result = await saveUpdatePolicies(policies);
                if(result) {
                    setFlowStatusOK(true);
                    setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
                }else {
                    setFlowStatusOK(false);
                    setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));
                }
            }
        }
    };

    return (
        <div>
            {steps?.length ? <Wizard
                steps={steps.filter(step => {return stepsToDisplay[step.id];})}
                title={t('GENERAL.ADD_POLICY')}
                doneButtonText={t('GENERAL.DONE')}
                onClose={()=>finishFlow(returnUrl)}
                ShowBackButtonInLastStep={false}
            /> : null}
        </div>
    );
};

export default AddOUPolicyPage;