import styled from 'styled-components';


const GenericWebhookStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GenericWebhookNameWrapper = styled.div`
  width: 50%;
  margin: 24px ;
`;

const GenericWebhookEndpointUrlWrapper = styled.div`
  display: flex;
  margin: 0 24px ;
  align-items: flex-end;
`;

const GenericWebhookEndpointUrlInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GenericWebhookAuthenticationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 24px ;
`;

const BasicAuthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
`;

const BasicAuthCredentialsInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const IgnoreCertificateValidationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px;
`;


export {
    GenericWebhookStyled,
    GenericWebhookNameWrapper,
    GenericWebhookEndpointUrlWrapper,
    GenericWebhookEndpointUrlInputWrapper,
    GenericWebhookAuthenticationWrapper,
    BasicAuthWrapper,
    BasicAuthCredentialsInputWrapper,
    IgnoreCertificateValidationWrapper,
};