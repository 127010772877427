import { t } from 'i18next';
import * as yup from 'yup';
import { getStoreService } from 'common/interface/services';
import { getAllSubscriptionIds } from './azure.reducer';
import { AzureOnboardingAWPScanType } from './azure.interface';

const azureTenantIdRegex = /^[0-9a-zA-Z-]{36}$/;

const azureSubscriptionIdRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const azureMgIdRegex = /^[A-Za-z0-9\-_().]+(?<!\.)$/;

const applicationIdRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const storageIdCDRRegex = /^\/subscriptions\/[0-9a-fA-F-]+\/resourceGroups\/[^/]+\/providers\/Microsoft\.Storage\/storageAccounts\/[^/]+$/;

export const azureSingleOnboardingValidationsSubscription = {
    subscriptionId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_REQUIRED'))
        .matches(azureSubscriptionIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_NOT_VALID'))
};

export const azureOrgOnboardingWelcomeValidationsTenant = {
    tenantId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_REQUIRED'))
        .matches(azureTenantIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_INVALID')),
};

export const azureOrgOnboardingWelcomeValidationsSubscription = {
    tenantId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_REQUIRED'))
        .matches(azureTenantIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_INVALID')),

    subscriptionId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_REQUIRED'))
        .matches(azureSubscriptionIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_NOT_VALID'))
        .test('subscriptionAlreadyOnboarded', t('ON_BOARDING.AZURE.VALIDATIONS.ALREADY_ONBOARDED'), (value) => {
            const { state } = getStoreService();
            return !getAllSubscriptionIds(state).includes(value as string);
        }),
};
export const azureOrgOnboardingWelcomeValidationsManagementGroup = {
    tenantId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_REQUIRED'))
        .matches(azureTenantIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_INVALID')),
    managementGroupId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.MG_ID_REQUIRED'))
        .matches(azureMgIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.MG_ID_INVALID')),
};

export const azureOnboardingWelcomeValidationsChina = {
    isAllowAzureChinaPrivacyPolicy: yup.bool().oneOf([true], 'Field must be checked'),
    subscriptionId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_REQUIRED'))
        .matches(azureSubscriptionIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_NOT_VALID'))
};

export const azureConnectSubscriptionValidation = {
    tenantId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_REQUIRED'))
        .matches(azureTenantIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_INVALID')),
    clientId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.CLIENT_ID_REQUIRED'))
        .matches(applicationIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.CLIENT_ID_INVALID')),
    clientPassword: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.CLIENT_PASSWORD_REQUIRED')),
    isMarkRunningCommand: yup.bool().oneOf([true], 'The terms and conditions must be accepted.'),
};

export const azureConnectMultiTenantValidation = {
    isOnBoardingScriptCompleted: yup.bool().oneOf([true], 'ON_BOARDING.AZURE.VALIDATIONS.MULTI_TENANT_SCRIPT_COMPLETION_CHECK')
};

export const azurePreparePageValidation = {
    clientId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.CLIENT_ID_REQUIRED')),
    clientPassword: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.CLIENT_PASSWORD_REQUIRED'))
};

export const azureConnectPageValidation = {
    tenantId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_REQUIRED'))
        .matches(azureTenantIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.TENANT_ID_INVALID')),

    subscriptionId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_REQUIRED'))
        .matches(azureSubscriptionIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_NOT_VALID'))
        .test('subscriptionAlreadyOnboarded', t('ON_BOARDING.AZURE.VALIDATIONS.ALREADY_ONBOARDED'), (value) => {
            const { state } = getStoreService();
            return !getAllSubscriptionIds(state).includes(value as string);
        }),
};

const CDRLogTypesEnum = ['ActivityLogs', 'SignIns', 'AuditLogs', 'StorageAccounts'];
export const azureConfigurationPageValidation = {
    awp: yup.object().shape({
        isEnabled: yup.boolean().required(),
        onboardingMode: yup.string().when('isEnabled', {
            is: true, // Only when isEnabled is true
            then: yup.string().required(),
            otherwise: yup.string().notRequired(),
        }),
        centralizedSubscriptionId: yup.string().when(['isEnabled', 'onboardingMode'], {
            is: (isEnabled: boolean, onboardingMode: AzureOnboardingAWPScanType) => isEnabled && onboardingMode === AzureOnboardingAWPScanType.inAccountHub,
            then: yup.string()
                .required(t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_REQUIRED'))
                .matches(azureSubscriptionIdRegex, t('ON_BOARDING.AZURE.VALIDATIONS.SUBSCRIPTION_ID_NOT_VALID')),
            otherwise: yup.string().nullable(true).notRequired(),
        }),
    }),
    cdr: yup.object().shape({
        isEnabled: yup.boolean().required(),
        accounts: yup.array().when('isEnabled', {
            is: true,
            then: yup.array().of(
                yup.object().shape({
                    storageId: yup.string().required().matches(storageIdCDRRegex),
                    logTypes: yup.array().of(
                        yup.string().required().oneOf(CDRLogTypesEnum)
                    ).required().min(1),
                })
            ).required().min(1),
            otherwise: yup.array().notRequired(),
        }),
    }),
};

export const azureCDRAccountValidationSchema = {
    storageId: yup.string().required(t('ON_BOARDING.AZURE.VALIDATIONS.STORAGE_ACCOUNT_ID_REQUIRED'))
        .matches(storageIdCDRRegex, t('ON_BOARDING.AZURE.VALIDATIONS.STORAGE_ACCOUNT_ID_NOT_VALID'))
};
/* manual azure validations */
export const azureManualOnboardingWelcomeValidationsChina = {
    isAllowAzureChinaPrivacyPolicy: yup.bool().oneOf([true], 'Field must be checked'),
};
