import React from 'react';
import { getThirdParty } from '../../../ProtectedAsset/Components/CiemDataUtils';
import { Chip } from 'common/design-system/components-v2';

export const ThirdPartyChipWidget: React.FC<{ assetLabels?: string[] }> = ({ assetLabels }) => {
    const thirdPartyModel = getThirdParty(assetLabels);
    if (!thirdPartyModel) return <></>;
    return <Chip
        label={thirdPartyModel.title}
        leadingIconProps={{ name: thirdPartyModel.iconName }}
    />;
};
