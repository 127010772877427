import React from 'react';
import { Chip, Icon } from '@dome9/berries/react-components';
import { useTranslation } from 'react-i18next';
import { A } from 'common/components/Anchor/Anchor';
import { IInternalLinkMenuItem, IInternalMenuBase } from '../Interface';
import { Spinner } from 'common/design-system/components-v2';

export interface ILinkMenuItemRendererProps {
    menuItem: IInternalMenuBase;
}

const LinkMenuItemRenderer: React.FC<ILinkMenuItemRendererProps> = ({ menuItem }) => {
    const { t } = useTranslation();
    const linkMenuItem = menuItem as IInternalLinkMenuItem;
    const value = t(linkMenuItem.label);
    const state = linkMenuItem.state || linkMenuItem.groupState;
    const href = state || '/';

    return (
        <A className={`flex items-center flex-1 h-full ${linkMenuItem.loadingItem ? 'pointer-events-none':''}`} href={href} >
            {linkMenuItem.icon &&
                <Icon name={linkMenuItem.icon} size={20} />
            }
            {linkMenuItem.loadingItem ? <Spinner size={18} /> : null}
            <div className={'pl-6'} title={value} data-aid={value}>
                {value}
            </div>
            {linkMenuItem.chip &&
                <Chip className='ml-5 mt-4' color='info' clickable={false} size='xs' label={linkMenuItem.chip.label} />
            }
        </A>
    );
};

export default LinkMenuItemRenderer;
