import React, { useCallback, useMemo } from 'react';
import { StatusGauge } from 'common/erm-components/custom/SummaryStatus/StatusGauge/StatusGauge';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../../../consts';
import { IEnvironmentsStatusData } from '../DataSecuritySummaryStatus.interface';
import { IssueSeveritiesMap } from '../../../../Issues/IssueSeverity';
import { Button, Stack } from 'common/design-system/components-v2';
import { EnvStyle } from './EnvironmentsStatus.styled';
import { IFieldInfo } from 'common/interface/general';
import { ISSUE_FIELD_NAMES } from '../../../../Issues/Issues.interface';
import { getAllDataAssetsTypes } from '../../../../ProtectedAsset/ErmDataUtils';
import { TitleDisplayType } from 'common/components/Highcharts/ActivityGaugeChart';
import { StatusBox } from 'common/erm-components/custom/SummaryStatus/StatusBox/StatusBox';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

export const EnvironmentsStatus: React.FC<{ data: IEnvironmentsStatusData }> = ({ data }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const { riskySensitiveEnvsCount, allSensitiveEnvsCount } = data;

    const text: string = useMemo(() => {
        if (riskySensitiveEnvsCount > 0) {
            return t('DATA_SECURITY_DASHBOARD.SUMMARY.ENVIRONMENTS.MESSAGE.HAVE_RISKS', { riskyEnvsCount: riskySensitiveEnvsCount });
        }
        return t('DATA_SECURITY_DASHBOARD.SUMMARY.ENVIRONMENTS.MESSAGE.NO_RISKS');
    }, [riskySensitiveEnvsCount, t]);

    const color: string = useMemo(() => {
        if (riskySensitiveEnvsCount === 0) {
            return IssueSeveritiesMap.unknown.color;
        }

        return IssueSeveritiesMap.critical.color;
    }, [riskySensitiveEnvsCount]);

    const gotoIssuesPage = useCallback(() => {
        const fields: IFieldInfo[] = [
            {
                name: ISSUE_FIELD_NAMES.SEVERITY,
                value: [IssueSeveritiesMap.high.level, IssueSeveritiesMap.critical.level],
            },
            {
                name: ISSUE_FIELD_NAMES.ENTITY_TYPE_BY_PLATFORM,
                value: getAllDataAssetsTypes(),
            }
        ];
        getErmUrlsService().gotoSecurityIssuesTable(fields);
    }, []);

    return (
        <StatusBox text={t('DATA_SECURITY_DASHBOARD.SUMMARY.ENVIRONMENTS.TITLE')}>
            <Stack justifyContent={'center'}>
                <StatusGauge
                    value={riskySensitiveEnvsCount}
                    outOf={allSensitiveEnvsCount}
                    color={color}
                    messages={[text]}
                    titleDisplayType={TitleDisplayType.COUNTERS_ONLY} />
                <EnvStyle.ButtonBar>
                    <Button variant={'outlined'} color={'brandPrimary'} label={t('DATA_SECURITY_DASHBOARD.SUMMARY.ENVIRONMENTS.SEE_SECURITY_ISSUES')} onClick={() => gotoIssuesPage()}></Button>
                </EnvStyle.ButtonBar>
            </Stack>
        </StatusBox>
    );
};
