import React from 'react';
import { ResetPasswordFrom } from './ResetPasswordFrom';
import { createUseStyles } from 'react-jss';
import { LegalNotesFooter } from '../../../common/components/Forms/LegalNotesFooter';
import CheckpointLogoHeader from 'common/components/Forms/CheckpointLogoHeader';

export const ResetPassword: React.FC<{}> = () => {
    const useStyles = createUseStyles({
        resetPassword: {
            'max-width': '700px',
            overflow: 'auto',
            height: '100%',
            width: '60%',
            margin: 'auto',
            '& .mainContent': {
                margin: '1rem auto',
                border: '1px solid #e0e4e8',
                display: 'flex',
                alignItems: 'stretch',
                background: '#fff',
                color: '#777',
            },
        },
        '@media screen and (max-width: 1024px)': {
            signupPage: {
                '& .mainContent': {
                    margin: '1rem  2rem',
                    width: 'auto',
                    flexDirection: 'column',
                },
            },
        },
    });

    const classes = useStyles();
    return (
        <div className={classes.resetPassword}>
            <CheckpointLogoHeader />
            <div className='mainContent'>
                <ResetPasswordFrom></ResetPasswordFrom>
            </div>
            <LegalNotesFooter />
        </div>
    );
};
