import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { CdrExclusionsService } from './CdrExclusionService';
import { CDR_EXCLUSIONS_SERVICE_ID } from './CdrExclusion.consts';

const initializeCdrExclusionService = () => {
    globalAddinContainer.addService(CDR_EXCLUSIONS_SERVICE_ID, new CdrExclusionsService());
};

export default function initializeCdrExclusion() {
    initializeCdrExclusionService();
}
