import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_LOCAL_DASHBOARD_ID } from 'common/module_interface/overview/Consts';
import { IDashboard, IDashboardSection, IDashboardWidget } from 'common/module_interface/overview/Interface';
import { RootState } from 'common/services/store/store';
import { sortWidgetsByView } from './Utils';

export interface ISectionImagesToPrint { title: string, imgData: string }

interface OverviewState {
    allDashboardsFromServer: IDashboard[];
    allDashboards: IDashboard[];
    favoritesDashboardsIds: string[];
    defaultDashboardId: string;
    selectedDashboard: IDashboard | null;
    registeredLocalDashboards: IDashboard[];
    isPrinting: boolean;
    sectionImagesToPrint: { title: string, imgData: string }[];
    selectedDashboardAsInServer: IDashboard | null;
    hiddenWidgetsWithNoData: string[];
}

const initialState: OverviewState = {
    allDashboardsFromServer: [],
    allDashboards: [],
    favoritesDashboardsIds: [],
    defaultDashboardId: DEFAULT_LOCAL_DASHBOARD_ID,
    selectedDashboard: null as IDashboard | null,
    registeredLocalDashboards: [],
    isPrinting: false,
    sectionImagesToPrint: [],
    selectedDashboardAsInServer: null,
    hiddenWidgetsWithNoData: [],
};

export const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers: {
        setSelectedDashboardAsInServer: (state: OverviewState, action: PayloadAction<IDashboard>) => {
            state.selectedDashboardAsInServer = action.payload;
        },
        setAllDashboardsFromServer: (state: OverviewState, action: PayloadAction<IDashboard[]>) => {
            state.allDashboardsFromServer = action.payload;
        },
        setAllDashboards: (state: OverviewState, action: PayloadAction<IDashboard[]>) => {
            state.allDashboards = action.payload;
        },
        setFavoriteDashboardsIds: (state: OverviewState, action: PayloadAction<string[]>) => {
            state.favoritesDashboardsIds = action.payload;
        },
        setDefaultDashboardId: (state: OverviewState, action: PayloadAction<any>) => {
            state.defaultDashboardId = action.payload;
        },
        setSelectedDashboard: (state: OverviewState, action: PayloadAction<any>) => {
            state.selectedDashboard = action.payload;
        },
        setRegisteredLocalDashboards: (state: OverviewState, action: PayloadAction<IDashboard[]>) => {
            state.registeredLocalDashboards = action.payload;
        },
        setIsPrinting: (state: OverviewState, action: PayloadAction<boolean>) => {
            state.isPrinting = action.payload;
        },
        setSectionsToPrint: (state: OverviewState, action: PayloadAction<ISectionImagesToPrint>) => {
            state.sectionImagesToPrint = [...state.sectionImagesToPrint, action.payload];
        },
        clearSectionImagesToPrint: (state: OverviewState,) => {
            state.sectionImagesToPrint = [];
        },
        deleteWidgetInStore: (state: OverviewState, action: PayloadAction<string>) => {
            if (state.selectedDashboard?.sections) {
                state.selectedDashboard?.sections.forEach((section) => {
                    if (section?.widgets && section?.widgets.length > 0) {
                        section.widgets = section.widgets.filter((widget) => widget.id !== action.payload);
                    }
                });
            }
        },
        addWidgetInStore: (state: OverviewState, action: PayloadAction<{ widget: IDashboardWidget, sectionTitle: string }>) => {
            if (state.selectedDashboard?.sections) {
                state.selectedDashboard?.sections.forEach((section) => {
                    section.widgets = sortWidgetsByView(section.widgets);

                    if (!section.widgets) {
                        section.widgets = [];
                    }
                    if (section && section.title === action.payload.sectionTitle) {
                        section.widgets.push(action.payload.widget);
                    }
                });
            }
        },
        updateWidgetInStore: (state: OverviewState, action: PayloadAction<IDashboardWidget>) => {
            if (state.selectedDashboard?.sections) {
                state.selectedDashboard?.sections.forEach((section) => {
                    if (section?.widgets && section?.widgets.length > 0) {

                        const widgets = [] as IDashboardWidget[];
                        section.widgets?.forEach((widgetItem: any) => {
                            if (widgetItem.options.id === action.payload.options.id) {
                                widgets.push(action.payload);
                            } else {
                                widgets.push(widgetItem);
                            }
                        });
                        if (section.widgets) {
                            section.widgets = sortWidgetsByView(widgets);
                        }
                    }
                });
            }
        },
        moveWidgetToSectionInStore: (state: OverviewState, action: PayloadAction<{ widget: IDashboardWidget, sectionId: string }>) => {
            if (state.selectedDashboard?.sections) {
                state.selectedDashboard?.sections.forEach((section) => {
                    if (!section.widgets) {
                        section.widgets = [];
                    }
                    const _widgets = [] as IDashboardWidget[];

                    if (section.id === action.payload.sectionId) {
                        _widgets.push(...section.widgets, action.payload.widget);
                    } else {
                        section.widgets?.forEach((widgetItem: any) => {
                            if (widgetItem.options.id !== action.payload.widget.options.id) {
                                _widgets.push(widgetItem);
                            }
                        });
                    }
                    section.widgets = _widgets;
                });
            }
        },
        renameSectionInStore: (state: OverviewState, action: PayloadAction<{ newName: string, sectionId: string }>) => {
            if (state.selectedDashboard?.sections) {
                state.selectedDashboard?.sections.forEach((section) => {
                    if (section?.id === action.payload.sectionId) {
                        section.title = action.payload.newName;
                    }
                });
            }
        },
        renameSelectedDashboardInStore: (state: OverviewState, action: PayloadAction<string>) => {
            if (state.selectedDashboard?.name) {
                state.selectedDashboard.name = action.payload;
            }
        },
        addSectionInStore: (state: OverviewState, action: PayloadAction<IDashboardSection>) => {
            state.selectedDashboard?.sections?.push(action.payload);
        },
        deleteSectionInStore: (state: OverviewState, action: PayloadAction<string>) => {
            const sections: IDashboardSection[] = [];
            if (state.selectedDashboard) {
                state.selectedDashboard?.sections?.forEach((section) => {
                    if (section.id !== action.payload) {
                        sections.push(section);
                    }
                });
                state.selectedDashboard.sections = sections;
            }
        },
        addItemToHiddenWidgetsWithNoData: (state: OverviewState, action: PayloadAction<string>) => {
            if (!state.hiddenWidgetsWithNoData.includes(action.payload)) {
                state.hiddenWidgetsWithNoData.push(action.payload);
            }
        },
        removeItemFromHiddenWidgetsWithNoData: (state: OverviewState, action: PayloadAction<string>) => {
            state.hiddenWidgetsWithNoData = state.hiddenWidgetsWithNoData.filter(widgetId => widgetId !== action.payload);
        },
        clearDashboards: () => initialState,
    },
});

export const {
    setAllDashboardsFromServer,
    setAllDashboards,
    setFavoriteDashboardsIds,
    clearDashboards,
    setDefaultDashboardId,
    setSelectedDashboard,
    setRegisteredLocalDashboards,
    setIsPrinting,
    setSectionsToPrint,
    clearSectionImagesToPrint,
    deleteWidgetInStore,
    addWidgetInStore,
    updateWidgetInStore,
    renameSelectedDashboardInStore,
    moveWidgetToSectionInStore,
    renameSectionInStore,
    addSectionInStore,
    deleteSectionInStore,
    setSelectedDashboardAsInServer,
    addItemToHiddenWidgetsWithNoData,
    removeItemFromHiddenWidgetsWithNoData,
} = overviewSlice.actions;

export const getAllDashboards = (state: RootState): IDashboard[] => state.overviewSlice.allDashboards;
export const getSelectedDashboard = (state: RootState): IDashboard => state.overviewSlice.selectedDashboard;
export const getDefaultDashboardId = (state: RootState): string => state.overviewSlice.defaultDashboardId;
export const getFavoriteDashboardsIds = (state: RootState): string[] => state.overviewSlice.favoritesDashboardsIds;
export const getRegisteredLocalDashboards = (state: RootState): string[] => state.overviewSlice.registeredLocalDashboards;
export const getIsPrinting = (state: RootState): boolean => state.overviewSlice.isPrinting;
export const getSectionImagesToPrint = (state: RootState): ISectionImagesToPrint[] => state.overviewSlice.sectionImagesToPrint;
export const getSelectedDashboardAsInServer = (state: RootState): IDashboard => state.overviewSlice.selectedDashboardAsInServer;
export const getHiddenWidgetsWithNoData = (state: RootState): string[] => state.overviewSlice.hiddenWidgetsWithNoData;

export default overviewSlice.reducer;
