import React, { useEffect, useState } from 'react';
import {
    SentraAccountNameWrapper,
    SentraApiKeyWrapper,
    SentraComponentStyled, SentraNameWrapper,
} from './SentraComponent.styled';
import { Typography, Input } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { I18nRiskNamespace } from '../../../consts';
import { IGenericConfigurationProps } from 'common/module_interface/RiskManagement/integrations/sentraIntegration/SentraIntegration.consts';
import { SUBMIT_STATUS_RESPONSE } from 'common/module_interface/settings/integrations/consts';

const SentraComponent : React.FC<IGenericConfigurationProps> = ({ onConfigurationChangeCallBack ,configuration, onConfigurationSaved, viewMode }) => {
    const { t } = useTranslation(I18nRiskNamespace);

    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [apiKeyInputConfig, setApiKeyInputConfig] = useState<string>(configuration?.configurationObj.accountCredentials?.apiKey || '');
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [apiKeyError, setApiKeyError] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);
    const discardChangesButtonHidden = Boolean(idConfiguration);
    const viewOnly = Boolean(viewMode);

    useEffect(() => {
        const changesDetected = (
            apiKeyInputConfig !== configuration?.configurationObj.accountCredentials?.apiKey ||
            nameInputConfig !== configuration?.name
        );
        setIsButtonDisabled(!changesDetected);
    }, [nameInputConfig, apiKeyInputConfig, idConfiguration, configuration?.configurationObj.accountCredentials?.apiKey, configuration?.name]);

    const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApiKeyInputConfig(event.target.value);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameInputConfig(event.target.value);
    };

    const handleDiscardChanges = () => {
        setApiKeyInputConfig(configuration?.configurationObj.accountCredentials?.apiKey || '');
        setNameInputConfig(configuration?.name || '');
    };

    const checkValidationsErrors = () => {
        setApiKeyError(!apiKeyInputConfig ? t('SENTRA_INTEGRATION.CONFIGURATIONS.MISSING_API_KEY') : '');
        setNameError(!nameInputConfig ? t('SENTRA_INTEGRATION.CONFIGURATIONS.MISSING_NAME') : '');
        return !apiKeyInputConfig || !nameInputConfig;
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        try {
            if (checkValidationsErrors()) {
                setResponseMessageSuccess(false);
                return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE };
            }
            const resp = onConfigurationSaved && await onConfigurationSaved(nameInputConfig, { apiKey: apiKeyInputConfig } , idConfiguration);
            resp?.id && setIdConfiguration(resp.id);
            onConfigurationChangeCallBack && onConfigurationChangeCallBack();
            setResponseMessageSuccess(true);
            return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
        } catch (error:any) {
            let errorMessage;
            const nameError = error.response?.data?.errors?.Name?.[0];
            const apiKeyError = error.message || error.title || t('SENTRA_INTEGRATION.CONFIGURATIONS.SAVED_FAILED');
            if(nameError) {
                setNameError(nameError);
                errorMessage = nameError;
            }
            else {
                setApiKeyError(apiKeyError);
                errorMessage = apiKeyError;
            }
            setResponseMessageSuccess(false);
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: errorMessage };
        }
    };

    return <SentraComponentStyled>
        <SentraComponentStyled>
            <SentraNameWrapper>
                <Typography variant='subtitleLg'>{t('SENTRA_INTEGRATION.CONFIGURATIONS.NAME')}:</Typography>
                <Input type='text' data-aid='nameInput' value={nameInputConfig}
                    onChange={handleNameChange} disabled={viewOnly} isError={!!nameError} helperText={nameError}/>
            </SentraNameWrapper>
            <SentraApiKeyWrapper>
                <Typography variant='subtitleLg'>{t('SENTRA_INTEGRATION.CONFIGURATIONS.API_KEY')}:</Typography>
                <Input type='text' data-aid='apiInput' value={apiKeyInputConfig}
                    onChange={handleApiKeyChange} disabled={viewOnly} isError={!!apiKeyError} helperText={apiKeyError}/>
            </SentraApiKeyWrapper>
            {configuration.configurationObj.sentraOrganizationName && <SentraAccountNameWrapper>
                <Typography variant='subtitleLg'>{t('SENTRA_INTEGRATION.CONFIGURATIONS.ACCOUNT')}:</Typography>
                <Typography variant='bodyLg'>{configuration.configurationObj.sentraOrganizationName}</Typography>
            </SentraAccountNameWrapper> }
        </SentraComponentStyled>
        { GenericCancelSubmitButtonWrapper({ handleDiscardChanges ,discardChangesButtonHidden, isButtonDisabled, submitForm, viewOnly, responseMessageSuccess }) }
    </SentraComponentStyled>;
};

export default SentraComponent;
