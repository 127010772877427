/* This is an experimental fix for edges overlapping with each other */

import { ExposureNodeType } from './NodeTypeHandler';
import { ExposureEdgeType } from './EdgeTypeHandler';
import { EdgeCSSProperties, GraphModel } from 'common/components/Graph/Models/GraphModel';

export function spreadEdgesForNodesAccessibleBy2OrMoreNodesWithOneOfThemBeingTheFirstNode(graphModel: GraphModel): void {
    const firstNode = graphModel.nodes.find(e => !graphModel.edges.map(e => e.target).includes(e.id));
    if (!firstNode) return;

    for (const node of graphModel.nodes) {
        const leadingEdges = graphModel.edges.filter(r => r.target === node.id);
        if (!leadingEdges.length) continue;

        const hasStraightLine = leadingEdges.some(e => e.source === firstNode?.id);
        if (!hasStraightLine || leadingEdges.length < 2) continue;

        pushInvisibleNodeAndEdge(graphModel, node.id);
    }
}

function pushInvisibleNodeAndEdge(graphModel: GraphModel, nodeId: string): void {
    graphModel.nodes.push({
        id: `dummyConnector-${nodeId}`,
        position: { x: 0, y: 0 },
        data: {},
        type: ExposureNodeType.Dummy,
        style: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            border: '0 solid rgba(0, 0, 0, 0)',
        },
    });

    const edgeStyle: EdgeCSSProperties = {
        '--stopColorStart': 'rgba(0, 0, 0, 0)',
        '--stopColorEnd': 'rgba(0, 0, 0, 0)',
    };

    graphModel.edges.push({
        id: `dummyEdge-${nodeId}`,
        source: `dummyConnector-${nodeId}`,
        target: nodeId,
        type: ExposureEdgeType.ExposureEdge,
        data: {},
        style: edgeStyle,
        markerEnd: `marker-end-dummyEdge-${nodeId}`,
    });
}