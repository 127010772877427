import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CaptchaValidationForm, CaptchaValidationFormContentType } from 'common/components/Forms/CaptchaValidationForm';
import { Signup } from 'platform/account/Signup/Signup';
import { ResetPassword } from 'platform/account/ResetPassword/ResetPassword';
import Main from './platform/main/Main';
import { Login } from 'platform/account/Login/Login';
import { Sso } from 'platform/account/Login/Sso';
import { ErrorPage } from 'platform/Error/ErrorPage';
import { SsoFailedLogin } from 'platform/account/Login/SsoFailedLogin';
import { StoredReport } from 'platform/Reports/StoredReport';
import { ExecutiveReport } from 'platform/Reports/ExecutiveReport/ExecutiveReport';
import AuthenticatedPage from './platform/main/AuthenticatedPage';
import { getCloudGuardRegistrationRoute, getIsCloudInfraAvailable } from 'common/utils/RuntimeEnvironment';
import { Pages } from 'common/enum/Pages';

interface route {
    exact: boolean;
    path: string;
    authPage?: boolean;
    recaptchaPage?: boolean;
    recaptchaProps?: any;
    component?: React.FC;
    deprecated?: boolean;
}

const AppRouter: React.FunctionComponent = () => {
    const isCloudInfraAvailable = getIsCloudInfraAvailable();
    const registerRoute = getCloudGuardRegistrationRoute(isCloudInfraAvailable);

    const routes: route[] = React.useMemo(
        () => [
            {
                exact: true,
                path: `/${Pages.Report}`,
                authPage: true,
                component: StoredReport,
            },
            {
                exact: true,
                path: `/${Pages.BigReport}`,
                authPage: true,
                component: StoredReport,
                deprecated: true,
            },
            {
                exact: true,
                path: `/${Pages.AssessmentsExecutiveReport}`,
                authPage: true,
                component: ExecutiveReport,
            },
            {
                exact: true,
                path: `/${Pages.Login}`,
                component: Login,
            },
            {
                exact: true,
                path: `/${Pages.Error}`,
                component: ErrorPage,
            },
            {
                exact: true,
                path: `/${Pages.ResetPassword}`,
                component: ResetPassword,
            },
            {
                exact: true,
                path: `/${Pages.Register}/${Pages.Register__SignUp}`,
                component: Signup,
            },
            {
                exact: true,
                path: `/${Pages.ForgotPassword}`,
                recaptchaPage: true,
                recaptchaProps: CaptchaValidationFormContentType.FORGOT_PASSWORD,
            },
            {
                exact: true,
                path: registerRoute,
                recaptchaPage: true,
                recaptchaProps: CaptchaValidationFormContentType.REGISTER,
            },
            {
                exact: true,
                path: `/${Pages.Sso}`,
                component: Sso,
            },
            {
                exact: true,
                path: `/${Pages.Sso}/${Pages.Sso__FailedLogin}`,
                component: SsoFailedLogin,
            },
            {
                exact: false,
                path: '/',
                authPage: true,
                component: Main,
            },
        ],
        [registerRoute],
    );

    return (
        <Switch>
            <Redirect from={`/${Pages.Register}/${Pages.Register__Invite}`} to={`/${Pages.Register}`} />
            {routes.map((route, index) => {
                const RouteComp = route.component;
                return (
                    <Route
                        key={`AppRouterRoute-${index}`}
                        exact={route.exact}
                        path={route.path}
                        render={(routeProps) => {
                            if (route.recaptchaPage) {
                                return <CaptchaValidationForm {...routeProps} {...(route.recaptchaProps || {})} />;
                            }
                            if (route.authPage) {
                                return <AuthenticatedPage>{RouteComp && <RouteComp />}</AuthenticatedPage>;
                            }
                            return RouteComp && <RouteComp />;
                        }}
                    />
                );
            })}
        </Switch>
    );
};

export default AppRouter;
