import React, { useMemo } from 'react';
import { IActionUsageDef, IColumnUsageDef } from 'common/interface/general';
import { IProtectedAssetFilter } from 'common/module_interface/assets/ProtectedAssets';
import { AllEventsPageStyled } from './AllEventsPage.styled';
import { ALL_EVENTS_TABLE } from './AllEventsPage.const';
import { getAllEventsTableColumns } from './AllEventsPage.columns';
import { getAllEventsTableFilters } from './AllEventsPage.filters';
import { getAllEventsTableActions } from './AllEventsPage.actions';
import FindingsTableWrapper from '../FindingsTable/FindingsTableWrapper';
import { ModalType } from '../Findings.const';

const sourceTypes: string[] = [];

const AllEventsPage: React.FC = () => {
    const columns: IColumnUsageDef[] = useMemo(() => getAllEventsTableColumns(), []);
    const filters: IProtectedAssetFilter[] = useMemo(() => getAllEventsTableFilters(), []);
    const actionsCreator = (openDialog: (dialogType: (ModalType | null)) => void): IActionUsageDef[] => {
        return getAllEventsTableActions(openDialog);
    };
    return (
        <AllEventsPageStyled.Body>
            <FindingsTableWrapper
                tableId={ALL_EVENTS_TABLE}
                filters={filters}
                columns={columns}
                actionsCreator={actionsCreator}
                originTypes={sourceTypes}/>
        </AllEventsPageStyled.Body>
    );
};
export default AllEventsPage;
