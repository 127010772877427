import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FirstDataRenderedEvent, GetRowIdParams, GridApi, GridOptions, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { Stack, ButtonGroup } from 'common/design-system/components-v2';
import { getStoreService } from 'common/interface/services';
import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';
import { AzureOnboardingStep, AzureOnboardingStepTitle } from '../../AzureIntelligenceOnboarding.styled';
import { IStorageModel, SelectedTab } from '../../AzureIntelligenceOnboarding.interface';
import { fetchStorages } from '../../AzureIntelligenceOnboarding.actions';
import {
    getAllStorages,
    getSelectedStorageTab,
    setAllStorages,
    setNextButtonDisabled,
    setSelectedStorageTab,
} from '../../AzureIntelligenceOnboarding.reducer';
import { AssetType } from '../../AzureIntelligenceOnboarding.const';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import ConfirmModal from './ConfirmModal';
import { getColumnDefs } from './StorageColumnDefinitions';
import AddStorageModal from './AddStorageModal';
import { useSelector } from 'react-redux';
import SpinnerWithText from '../SpinnerWithText/SpinnerWithText';
import StorageErrorWrapper from './components/StorageErrorWrapper';
import Content from './components/Content';
import { checkStorageLogTypes } from '../../AzureIntelligenceOnboarding.helpers';

const StoragesTable: FunctionComponent = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });
    
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isAddStorageModalOpen, setIsAddStorageModalOpen] = useState<boolean>(false);
    const [isFetchingStorages, setIsFetchingStorages] = useState<boolean>(false);
    const [isFetchingStoragesError, setIsFetchingStoragesError] = useState<boolean>(false);
    const { dispatch } = getStoreService().getReduxTools();

    const [isHideStorage, setIsHideStorage] = useState(false);

    const { type } = useParams<{ type: AssetType }>();
    const isAccountActivity = type === AssetType.activityLogs;
    const apiRef = React.useRef<GridApi>();

    const selectedStorageTab = useSelector(getSelectedStorageTab);
    const allStorages = useSelector(getAllStorages);

    const selectedStorages = useMemo(() => allStorages.filter(storage => storage.isSelected), [allStorages]);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        suppressMenu: true,
        suppressMovable: true,
        flex: 1,
    }), []);

    const fetchData = useCallback(async () => {
        try {
            setIsFetchingStorages(true);
            await fetchStorages();
        } catch (err: Error | unknown) {
            setIsFetchingStoragesError(true);
        } finally {
            setIsFetchingStorages(false);
        }
    }, []);
    
    useEffect(() => {
        if (selectedStorages?.length) {
            if (!isAccountActivity || (isAccountActivity && checkStorageLogTypes(selectedStorages))) {
                dispatch(setNextButtonDisabled(false));
            } else {
                dispatch(setNextButtonDisabled(true));
            }
        } else {
            dispatch(setNextButtonDisabled(true));
        }

        // If selectedStorages is empty, deselect all rows
        if (!selectedStorages?.length) {
            apiRef?.current?.deselectAll();
        }
    }, [selectedStorages, dispatch, isAccountActivity]);

    useEffect(() => { 
        if (selectedStorageTab === SelectedTab.List && !allStorages?.length) {
            fetchData();
        }
    }, [allStorages?.length, fetchData, selectedStorageTab]);

    const switchTab = (tab: SelectedTab) => {
        if (selectedStorages.length) {
            setIsModalOpen(true);
        } else {
            setIsFetchingStoragesError(false);
            dispatch(setAllStorages([]));
            dispatch(setSelectedStorageTab(tab));
        }
    };

    const onConfirmSwitch = () => {
        setIsModalOpen(false);
        setIsFetchingStoragesError(false);
        dispatch(setSelectedStorageTab(selectedStorageTab === SelectedTab.List ? SelectedTab.Manual : SelectedTab.List));
        dispatch(setAllStorages([]));
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    };

    const onGridReady = (params: GridReadyEvent) => {
        apiRef.current = params.api;
    };

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        apiRef.current?.setQuickFilter(event.target.value);
    };

    const applyExternalFilter = () => {
        const filterModel = {
            status: {
                type: 'notEqual',
                filter: ConnectionStatus.connected,
            }
        };
        apiRef.current?.setFilterModel(filterModel);
        apiRef.current?.onFilterChanged();
    };

    const clearExternalFilter = () => {
        apiRef.current?.setFilterModel(null);
        apiRef.current?.onFilterChanged();
    };

    const onHideStorage = (event: ChangeEvent<HTMLInputElement>) => {
        setIsHideStorage(isHideStorage => !isHideStorage);

        if (event.target.checked) {
            applyExternalFilter();
        } else {
            clearExternalFilter();
        }
    };

    const onSelectionChanged = useCallback(() => {
        const selectedRows: IStorageModel[] = apiRef.current?.getSelectedRows() ?? [];
        const updatedStorages: IStorageModel[] = allStorages.map(storage => {
            const isSelected = selectedRows.some(row => row.id === storage.id);
            return { 
                ...storage, 
                isSelected, 
                isAutoDiscoveryEnabled: isSelected ? storage.isAutoDiscoveryEnabled : false 
            };
        });

        dispatch(setAllStorages(updatedStorages));

        if (selectedRows.length) {
            if (!isAccountActivity || (isAccountActivity && checkStorageLogTypes(selectedRows))) {
                dispatch(setNextButtonDisabled(false));
            } else {
                dispatch(setNextButtonDisabled(true));
            }
        } else {
            dispatch(setNextButtonDisabled(true));
        }
    }, [apiRef, allStorages, dispatch, isAccountActivity]);

    const columnDefs = useMemo(() => getColumnDefs({
        isAccountActivity,
        selectedTab: selectedStorageTab,
    }), [isAccountActivity, selectedStorageTab]);

    const gridOptions: GridOptions = useMemo(() => ({
        columnDefs,
        rowData: allStorages,
        rowSelection: 'multiple',
        defaultColDef: defaultColDef,
        enableBrowserTooltips: true,
        suppressRowClickSelection: true,
        localeText: { totalRows: t('TABLE_ITEMS.TOTAL') },
        getRowId: (params: GetRowIdParams) => params.data.id,
        onGridReady,
        onSelectionChanged,
        onFirstDataRendered: (params: FirstDataRenderedEvent<IStorageModel>) => {
            const nodesToSelect: IRowNode<IStorageModel>[] = [];

            params.api?.forEachNode(node => {
                if (selectedStorages?.some(storage => storage.id === node.data?.id)) {
                    nodesToSelect.push(node);
                }
            });
            
            if (nodesToSelect?.length) {
                params.api.setNodesSelected({
                    nodes: nodesToSelect,
                    newValue: true,
                });
            }
        }
    }), [allStorages, columnDefs, defaultColDef, onSelectionChanged, selectedStorages, t]);

    let content;

    if (isFetchingStorages && !isFetchingStoragesError) {
        content = <SpinnerWithText text={t('STORAGE.LOADING')} />;
    }

    if (isFetchingStoragesError) {
        content = <StorageErrorWrapper />;
    }

    if (!isFetchingStorages && !isFetchingStoragesError) { 
        content = <Content 
            selectedStorageTab={selectedStorageTab}
            isHideStorage={isHideStorage}
            rowData={allStorages}
            gridOptions={gridOptions}
            onHideStorage={onHideStorage}
            onSearchChange={onSearchChange}
            setIsAddStorageModalOpen={setIsAddStorageModalOpen}
        />;
    }

    return (
        <> 
            <ConfirmModal 
                isModalOpen={isModalOpen}
                onClose={onCloseModal}
                onConfirm={onConfirmSwitch}
                tab={selectedStorageTab}
            />
            <AddStorageModal 
                isModalOpen={isAddStorageModalOpen}
                onClose={() => setIsAddStorageModalOpen(false)}
            />
            <AzureOnboardingStep>
                <Stack direction='row' justifyContent='space-between' padding={[0, 0, 4, 0]} alignItems='center'>
                    <AzureOnboardingStepTitle>{t('STEPS.STORAGE')}</AzureOnboardingStepTitle>
                    <ButtonGroup 
                        options={
                            [
                                {
                                    label: t('STORAGE.TABS.ENTER_MANUALLY'),
                                    value: SelectedTab.Manual,
                                },
                                {
                                    label: t('STORAGE.TABS.SELECT_FROM_LIST'),
                                    value: SelectedTab.List,
                                },
                            ]
                        }
                        value={selectedStorageTab}
                        onChange={(value: string) => switchTab(value as SelectedTab)}
                        disabled={isFetchingStorages}
                    />
                </Stack>
                {content}
            </AzureOnboardingStep>
        </>
    );
};

export default StoragesTable;
