import i18n from 'common/services/translations/translations';
import { ETColDef, EnvironmentsTableRegistry } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import { formatDate } from 'common/utils/helpFunctions';
import { getVendorDisplayName } from 'common/consts/vendors';
import EnvironmentCellRenderer from '../CellRenderers/EnvironmentCellRenderer';
import StatusCellRenderer from '../CellRenderers/StatusCellRenderer';
import { IEnvironment } from 'common/interface/environmentsTable';
import { getEnvsNamespace } from '../initialize.i18n';
import { StatusCellRenderer_Status } from 'common/module_interface/assets/Environments';

const columnDefs: () => ETColDef<IEnvironment>[] = () => [
    {
        id: 'checkbox',
        position: -1,
        colId: 'checkbox',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 40,
        maxWidth: 40,
        minWidth: 40,
        resizable: false,
        lockPosition: 'left',
    },
    {
        id: 'name',
        position: 1,
        colId: 'name',
        field: 'name',
        headerName: i18n.t('TABLE_HEADERS.NAME.HEADER', { ns: getEnvsNamespace('table') }),
        headerTooltip: i18n.t('TABLE_HEADERS.NAME.TOOLTIP', { ns: getEnvsNamespace('table') }),
        sortable: true,
        flex: 1,
        minWidth: 150,
        cellRenderer: EnvironmentCellRenderer,
        valueFormatter: (params) => `${params.data?.name} (${params.data?.id})`,
    },
    {
        id: 'description',
        position: 2,
        colId: 'description',
        field: 'description',
        headerName: i18n.t('TABLE_HEADERS.DESCRIPTION.HEADER', { ns: getEnvsNamespace('table') }),
        headerTooltip: i18n.t('TABLE_HEADERS.DESCRIPTION.TOOLTIP', { ns: getEnvsNamespace('table') }),
        sortable: true,
        initialHide: true,
    },
    {
        id: 'organizationalUnit',
        position: 3,
        colId: 'customData|organizationalUnitPath',
        field: 'customData|organizationalUnitPath',
        headerName: i18n.t('TABLE_HEADERS.ORGANIZATIONAL_UNIT.HEADER', { ns: getEnvsNamespace('table') }),
        headerTooltip: i18n.t('TABLE_HEADERS.ORGANIZATIONAL_UNIT.TOOLTIP', { ns: getEnvsNamespace('table') }),
        sortable: true,
    },
    {
        id: 'platform',
        position: 4,
        colId: 'platform',
        field: 'platform',
        headerName: i18n.t('TABLE_HEADERS.PLATFORM.HEADER', { ns: getEnvsNamespace('table') }),
        headerTooltip: i18n.t('TABLE_HEADERS.PLATFORM.TOOLTIP', { ns: getEnvsNamespace('table') }),
        sortable: true,
        initialHide: true,
        valueFormatter: (params) => getVendorDisplayName(params.value)
    },
    {
        id: 'status',
        position: 5,
        colId: 'customData|status',
        field: 'customData|status',
        headerName: i18n.t('TABLE_HEADERS.STATUS.HEADER', { ns: getEnvsNamespace('table') }),
        headerTooltip: i18n.t('TABLE_HEADERS.STATUS.TOOLTIP', { ns: getEnvsNamespace('table') }),
        sortable: true,
        width: 80,
        cellRenderer: StatusCellRenderer,
        comparator: (valueA: StatusCellRenderer_Status | undefined, valueB: StatusCellRenderer_Status | undefined, a, b, isDescending: boolean) => {
            const order: (StatusCellRenderer_Status | undefined)[] = [StatusCellRenderer_Status.error, StatusCellRenderer_Status.warning, StatusCellRenderer_Status.success];
            if (isDescending) {
                order.push(StatusCellRenderer_Status.none, undefined);
            } else {
                order.unshift(StatusCellRenderer_Status.none, undefined);
            }
            return order.indexOf(valueB) - order.indexOf(valueA);
        }
    },
    {
        id: 'onboardingTime',
        position: 6,
        colId: 'onboardingTime',
        field: 'creationDate',
        headerName: i18n.t('TABLE_HEADERS.ONBOARDING_TIME.HEADER', { ns: getEnvsNamespace('table') }),
        headerTooltip: i18n.t('TABLE_HEADERS.ONBOARDING_TIME.TOOLTIP', { ns: getEnvsNamespace('table') }),
        sortable: true,
        initialHide: true,
        valueFormatter: (params) => formatDate(params.value)
    },
];

export default function initializeColumnDefs() {
    EnvironmentsTableRegistry.addColumnDefs(columnDefs());
}
