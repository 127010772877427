/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Modal } from '@dome9/berries/react-components';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IFilterProps, initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColDef, IRowNode } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { IERMRuleset } from './Rulesets.interface';
import { CpLoadingDots } from '@dome9/components/react/components';
import { ACTIONS_KEYS } from './Ruleset.Reducer';
import { getRulesetVendorName, saveAllOptionRuleset, saveSelectedRuleset, createPolicy } from './Ruleset.actions';
import { RiskManagementRegistry } from '../../RiskManagementRegistry';
import { Button, Checkbox, Icon, Tooltip } from 'common/design-system/components-v2';
import { I18nRiskNamespace } from '../../consts';


const RulesetModal:React.FC<any> = ({ setModalOpen, isModalOpen, ruleset, saveRuleset, state, dispatch }) => {
    const { t } = useTranslation();
    const columnDefs: ColDef[] = RiskManagementRegistry.getRulesetsTableColumnDefs();
    const gridRef = useRef<any>(null);
    const defaultColDef = { sortable: true, resizable: true, suppressMenu: true };
    const vendor = useMemo(()=> getRulesetVendorName(ruleset?.cloudVendor ?? ''),[ruleset]);
    const modalTitle = useMemo(()=>`${t('RISK_MANAGEMENT.RULESETS.MODAL.TITLE',{ ns: I18nRiskNamespace })} ${vendor}`,[vendor]);
    const [filterElement, setFilterElement] = useState(<div></div>);
    const [rulesetToDisplay, setRulesetToDisplay] = useState<IERMRuleset>(ruleset);
    const [isShouldCreatePolicy, setIsShouldCreatePolicy] = useState(true);
    const [isPolicyCheckboxDisabled, setIsPolicyCheckboxDisabled] = useState(true);
    const [isSetRulesetLoading, setIsSetRulesetLoading] = useState(false);

    const handleCloseModal = () => {
        setRulesetToDisplay(ruleset);
        setModalOpen(false);
    };

    const confirmAllOption = async () => {
        try {
            await saveAllOptionRuleset(rulesetToDisplay);
            if (vendor) {
                const cloneRulesetsByPlatform = { ...state.rulesetsByPlatform };
                cloneRulesetsByPlatform[vendor] = cloneRulesetsByPlatform[vendor].map((currRuleset: IERMRuleset) => (
                    {
                        ...currRuleset,
                        isSelected: currRuleset.isAllOption && currRuleset.cloudVendor === ruleset.cloudVendor
                    }));
                dispatch({ type: ACTIONS_KEYS.SET_RULESETS_BY_PLATFORM, payload: cloneRulesetsByPlatform });
            }
            saveRuleset(rulesetToDisplay);
            handleCloseModal();
        } finally {
            setIsSetRulesetLoading(false);
        }
    };

    const confirmRuleset = async ()=> {
        const cloneRulesetsByPlatform = { ...state.rulesetsByPlatform };
        try {
            const rulesetToSave = { ...rulesetToDisplay, name: rulesetToDisplay.name.replace(t('RISK_MANAGEMENT.RULESETS.RECOMMENDED', { ns: I18nRiskNamespace }),'') };
            await saveSelectedRuleset(rulesetToSave);
            const isCreatePolicy = !isPolicyCheckboxDisabled && isShouldCreatePolicy;
            if (isCreatePolicy) {
                await createPolicy(rulesetToSave);
            }
            if (vendor) {
                cloneRulesetsByPlatform[vendor] = cloneRulesetsByPlatform[vendor].map((currRuleset: IERMRuleset) => {
                    if (rulesetToSave.id === currRuleset.id) {
                        return ({
                            ...currRuleset,
                            isSelected: true,
                            policies: isCreatePolicy ? 1 : rulesetToSave.policies
                        });
                    } else {
                        return { ...currRuleset, isSelected: false };
                    }
                });
                dispatch({ type: ACTIONS_KEYS.SET_RULESETS_BY_PLATFORM, payload: cloneRulesetsByPlatform });
            }
            saveRuleset(rulesetToSave);
            handleCloseModal();
            
        } finally {
            setIsSetRulesetLoading(false);
        }
    };

    const onConfirm = () => {
        if (rulesetToDisplay?.id === ruleset?.id) { 
            return;
        }
        setIsSetRulesetLoading(true);
        if(rulesetToDisplay.isAllOption) {
            confirmAllOption();
        } else{
            confirmRuleset();
        }
    };

    const getAggregations = (filtersValues: IFiltersValues): Promise<Aggregations>=>{
        const dataTable = getDataTable(vendor ?? '', filtersValues);
        
        const cloudGuardCount = dataTable?.filter((data: IERMRuleset) => data.isTemplate)?.length;
        const userCount = dataTable?.filter((data: IERMRuleset) => !data.isTemplate)?.length;

        gridRef?.current?.api?.setRowData(dataTable ?? null);
        return Promise.resolve({
            type: [{ value: t('RISK_MANAGEMENT.RULESETS.FILTER.CLOUD_GUARD', { ns: I18nRiskNamespace }) , count: cloudGuardCount },
                { value: t('RISK_MANAGEMENT.RULESETS.FILTER.USER', { ns: I18nRiskNamespace }), count: userCount }]
        });
    };

    const getDataTable = (platform: string, filters?: IFiltersValues) => {
        let filterRulesets: IERMRuleset[] = state.rulesetsByPlatform[platform];

        if(filters?.freeTextPhrase || filters?.['free-text']) {
            filterRulesets = filterRulesets.filter((ruleset: IERMRuleset) => {
                const text = filters?.freeTextPhrase || filters?.['free-text'];
                return ruleset.name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
            });
        }

        if(filters?.type) {
            filters?.type.forEach((field: string) => {
                filterRulesets = filterRulesets.filter((ruleset: IERMRuleset) => field.toLocaleLowerCase() === ruleset.managedBy?.toLocaleLowerCase());
            });
        }
     
        return filterRulesets;
    };


    function renderFilterPanel(aggregations: Aggregations) {
        const filterPanelElementsList: IFilterProps[] = RiskManagementRegistry.getRulesetsTableFilters(aggregations);

        const filterPanel = initFilterPanel({
            filterPanelElementsList,
            getAggregations,
            filterId: 'ERM-rulesets-page',
            webAppQueryParams: { type: [] }
        });
        setFilterElement(filterPanel);
    }

    useEffect(() => {
        renderFilterPanel({ type: 
            [{ value: t('RISK_MANAGEMENT.RULESETS.FILTER.CLOUD_GUARD', { ns: I18nRiskNamespace }) , count: 0 },
                { value: t('RISK_MANAGEMENT.RULESETS.FILTER.USER', { ns: I18nRiskNamespace }), count: 0 }] });
        setRulesetToDisplay(ruleset);
        setIsPolicyCheckboxDisabled(Boolean(ruleset?.isAllOption || ruleset?.policies));
    }, [ruleset]);

    const onSelectionChanged = () => {
        gridRef.current!.api.forEachNode((node: IRowNode) => {
            const nodeRuleset = node.data;

            if(node.isSelected()) {
                setRulesetToDisplay(nodeRuleset);
                setIsPolicyCheckboxDisabled(Boolean(nodeRuleset?.isAllOption || nodeRuleset?.policies));
            } 
            node.setData({ ...nodeRuleset, isSelected: node.isSelected() });
        });
    };

    const ModalCheckbox = ()=>{
        const tooltipText = rulesetToDisplay && (rulesetToDisplay?.isAllOption ? t('RISK_MANAGEMENT.RULESETS.MODAL.CHECKBOX.TOOLTIP.FICTIVE', { ns: I18nRiskNamespace }) : t('RISK_MANAGEMENT.RULESETS.MODAL.CHECKBOX.TOOLTIP.EXIST', { ns: I18nRiskNamespace }));
        return isPolicyCheckboxDisabled?
            <Tooltip content={tooltipText}>
                <div>
                    <Checkbox
                        readOnly
                        checked={false}
                        label={t('RISK_MANAGEMENT.RULESETS.MODAL.CHECKBOX.LABEL', { ns: I18nRiskNamespace })}
                        className="ml-6"
                    ></Checkbox>
                </div>
            </Tooltip> :
            <Checkbox
                checked={isShouldCreatePolicy}
                onChange={() => setIsShouldCreatePolicy((prevState)=> !prevState)}
                label={t('RISK_MANAGEMENT.RULESETS.MODAL.CHECKBOX.LABEL', { ns: I18nRiskNamespace })}
                className="ml-6"
            ></Checkbox>;
        
    };


    return (

        <Modal.ModalDialog isOpen={isModalOpen} onRequestClose={handleCloseModal} shouldCloseOnOverlayClick={false}>
            <Modal.ModalHeader title={modalTitle} onClose={handleCloseModal} />
            {Boolean(isSetRulesetLoading) && <CpLoadingDots/>}
            <Modal.ModalContent>
                <div className="mb-9" >
                    {filterElement}
                </div>
                <div className="flex flex-1 gap-8 h-[300px]">
                    <AgGridReact
                        rowModelType='clientSide'
                        className='ag-theme-alpine relative h-full flex-1'
                        ref={gridRef}
                        enableRangeSelection
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        headerHeight={30}
                        rowSelection={'single'}
                        onSelectionChanged={onSelectionChanged}
                        suppressContextMenu
                    />
                    {Boolean(rulesetToDisplay) && <div className="w-[250px] space-y-7 flex flex-col">
                        <div className="text-xl text-primary">{rulesetToDisplay?.name}</div>
                        {Boolean(rulesetToDisplay.id) && <div className="flex items-center gap-6">
                            <div className="label">{t('RISK_MANAGEMENT.RULESETS.ID', { ns: I18nRiskNamespace })}:</div>
                            <div >{rulesetToDisplay.id}</div>
                        </div>}
                        {Boolean(rulesetToDisplay.icon) && <div className="flex items-center gap-6">
                            {rulesetToDisplay.icon && <Icon name={rulesetToDisplay.icon}/>}
                            <div >{rulesetToDisplay.managedBy}</div>
                        </div>}
                        <p className='break-all overflow-auto'>
                            {rulesetToDisplay?.description}
                        </p>
                        <div className="flex">
                            {Boolean(rulesetToDisplay?.rulesCount) && <>
                                <div>
                                    <div className="label">{t('RISK_MANAGEMENT.RULESETS.RULES', { ns: I18nRiskNamespace })}</div>
                                    <div >{rulesetToDisplay?.rulesCount}</div>
                                </div>
                                <div className="border-r mx-6 border-dashed"/>
                            </>}
                            {Boolean(rulesetToDisplay?.policies) && <div >
                                <div className="label">{t('RISK_MANAGEMENT.RULESETS.POLICIES', { ns: I18nRiskNamespace })}</div>
                                <div>{rulesetToDisplay?.policies}</div>
                            </div>}
                        </div>
                    </div>}
                </div>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <ModalCheckbox/>
                <div className="modal__footer-actions">
                    <Button onClick={handleCloseModal} color='brandPrimary' variant='outlined'>{t('RISK_MANAGEMENT.RULESETS.MODAL.CANCEL', { ns: I18nRiskNamespace })}</Button>
                    <Button disabled={rulesetToDisplay?.id === ruleset?.id} onClick={onConfirm} color='brandPrimary'>
                        {t('RISK_MANAGEMENT.RULESETS.MODAL.SAVE', { ns: I18nRiskNamespace })}
                    </Button>
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>

    );
};
export default RulesetModal;