/* eslint-disable-next-line react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { PropsValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Vendors } from 'common/consts/vendors';
import Modal from 'common/design-system/components-v2/Modal';
import useCustomValidation from 'common/hooks/useCustomValidation';
import { IRuleset } from 'common/interface/ruleset';
import { OptionType } from 'common/design-system/components-v2/Select';
import { IconProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { Button, Label, Select, Spinner, Stack, TextArea, Typography } from 'common/design-system/components-v2';
import { IRemediationModalProps, RuleOption, RulesetOption } from '../helpers/remediation.interfaces';
import { IRemediation, RemediationModuleType, IRemediationCreation } from 'common/interface/remediation';
import { I18nRemediation } from '../helpers/remediation.consts';
import {
    remediationCSPMValidation,
    sortRuleSetsSelectOptions
} from '../helpers/remediation.utils';
import RemediationApi from 'modules/remediation/services/remediationApi.services';
import RemediateByRuleOption from '../Components/RemidiationInputs/RemediateByRule';
import RemediateByEntityOption from '../Components/RemidiationInputs/RemediateByEntity';
import RemediateByEnvironmentOption from '../Components/RemidiationInputs/RemediateByEnvironment';
import CloudBotsSelect from '../Components/CloudBotsSelect/CloudBotsSelect';
const RemediationModalCspm: FC<IRemediationModalProps> = ({ isOpen,onClose, onSave, remediation }) => {
    const { t } = useTranslation(I18nRemediation);
    const [rulesetList, setRulesetList] = useState<IRuleset[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [comment, setComment] = useState(remediation?.comment || '');
    const [isEditMode, setIsEditMode] = useState<boolean>(!!remediation?.id);
    const [selectedRuleSet, setSelectedRuleSet] = useState<RulesetOption | null>(null);
    const [rulesetListSelect, setRulesetListSelect] = useState<{ label:string, value: string, iconProps?: IconProps }[]>([]);
    const [selectedRule, setSelectedRule] = useState<RuleOption | null>(null);
    const [selectedEnvironment, setSelectedEnvironment] = useState<OptionType | null>(null);
    const [selectedCloudBots, setSelectedCloudBots] = useState<string[] | null>(null);
    const [selectedEntity, setSelectedEntity] = useState<string | null>(null);
    const configuredRemediationObject = (): IRemediationCreation => {
        return {
            cloudAccountId: selectedEnvironment?.value,
            cloudAccountType: selectedRuleSet?.platform,
            cloudBots: selectedCloudBots || [],
            comment: comment,
            logic: (selectedEntity || undefined),
            platform: selectedRuleSet?.platform,
            ruleLogicHash: selectedRule?.value,
            ruleName: selectedRule?.label,
            rulesetId: parseInt(selectedRuleSet?.value || ''),
        };
    };
    const requestPayload = configuredRemediationObject();
    const formValidations = useCustomValidation({ yupValidationObject: remediationCSPMValidation(t), payload: { ...requestPayload } });
    const configureRemediationForm = (remediation: IRemediation) => {
        const selectedRuleset = rulesetList.find((ruleset: IRuleset) => ruleset.id === remediation.rulesetId);
        if (selectedRuleset) {
            setSelectedRuleSet({
                label: selectedRuleset.name,
                value: selectedRuleset.id.toString(),
                platform: selectedRuleset.cloudVendor,
                iconProps: { vendorNameOrPath: selectedRuleset.cloudVendor }
            });
        }
    };

    useEffect(() => {
        RemediationApi.getComplianceRuleset().then((data) => {
            setRulesetList(data.data);
        });
    }, []);

    useEffect(() => {
        if (rulesetList.length && (remediation?.id || remediation?.rulesetId)) configureRemediationForm(remediation);
    }, [remediation, rulesetList]);

    useEffect(() => {
        const rulesetListSelectArr = rulesetList
            .filter((ruleset: IRuleset) => ruleset.language !== 'ja')
            .filter((ruleset: IRuleset) => ruleset.cloudVendor !== Vendors.OCI) // Filter out OCI rulesets
            .sort((a, b) => sortRuleSetsSelectOptions(a, b))
            .map((ruleset: IRuleset): { label:string, value: string, iconProps?: IconProps } => {
                return {
                    label: ruleset.name,
                    value: ruleset.id.toString(),
                    iconProps: { vendorNameOrPath: ruleset.cloudVendor === 'gcp' ? 'google' : ruleset.cloudVendor }
                };
            });
        setRulesetListSelect(rulesetListSelectArr);
    }, [rulesetList]);

    const onSelectedRuleSet = (newValue: PropsValue<OptionType>) => {
        setIsEditMode(false);
        setSelectedRuleSet(newValue as RulesetOption);
        setSelectedRule(null);
    };
    const onRuleOptionChange = (value: RuleOption) => {
        setSelectedRule(value);
    };
    const onEntityOptionChange = (value: string) => {
        setSelectedEntity(value);
    };
    const onEnvOptionChange = (value: OptionType) => {
        setSelectedEnvironment(value);
    };
    const onAddCloudBots = (value: string[]) => {
        setSelectedCloudBots(value);
    };

    const saveRemediation = async () => {
        setIsLoading(true);
        try {
            setIsSaveClicked(true);
            if ((!selectedRule && !selectedEntity) || !selectedRuleSet?.value || !selectedCloudBots?.length || !!formValidations.errors) {
                setIsLoading(false);
                return;
            }
            if (remediation?.id) {
                const updatedRemediation: IRemediation = { ...remediation, ...requestPayload };
                await RemediationApi.update(remediation?.id, updatedRemediation, RemediationModuleType.CSPM);
            } else await RemediationApi.create({ ...requestPayload }, RemediationModuleType.CSPM);
            onSave && onSave();
        } catch (error) {
            console.error('Error saving remediation: ', error);
            setIsLoading(false);
        }
    };

    return (
        <Modal.ModalDialog isOpen={isOpen} width='lg' shouldCloseOnOverlayClick onRequestClose={onClose}>
            <Modal.ModalHeader hasSeparator={true} title={remediation?.id ? t('REMEDIATION.MODALS.ADD_EDIT_MODAL.EDIT_TITLE') : t('REMEDIATION.MODALS.ADD_EDIT_MODAL.CREATE_TITLE')} onClose={onClose} />
            <Modal.ModalContent>
                <Stack flexWrap={true} spacing={3}>
                    <Stack spacing={1} >
                        <Label text={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.SELECT_RULESET')} required />
                        <Select options={rulesetListSelect} isMulti={false} value={selectedRuleSet} onChange={onSelectedRuleSet}/>
                        <Typography color="normal" variant="bodyXs">{t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.REMEDIATION_MIN_REQUIREMENTS')}</Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <RemediateByRuleOption
                            formValidations={isSaveClicked ? formValidations.errors?.ruleLogicHash : undefined}
                            isEnabled={!!selectedRuleSet}
                            selectedRuleSet={selectedRuleSet}
                            selectedOption={remediation?.ruleLogicHash || remediation?.ruleId}
                            onChange={(value) => onRuleOptionChange(value as RuleOption)}
                        />
                        {isSaveClicked && (!selectedRule && !selectedEntity) && <Typography color="alert" variant="bodyXs">{t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.REMEDIATION_REQUIREMENTS')}</Typography>}
                    </Stack>
                    <RemediateByEnvironmentOption
                        formValidations={isSaveClicked ? formValidations.errors?.cloudAccountId : undefined}
                        isEnabled={!!selectedRuleSet}
                        selectedRuleSet={selectedRuleSet}
                        selectedOption={remediation?.cloudAccountId as string}
                        onChange={(value) => onEnvOptionChange(value as OptionType)}
                    />
                    <Stack spacing={1}>
                        <RemediateByEntityOption
                            formValidations={isSaveClicked ? formValidations.errors?.logic : undefined}
                            isEnabled={!!selectedRuleSet}
                            selectedRuleSet={selectedRuleSet}
                            selectedOption={remediation?.logic || remediation?.logicExpressions?.[0]}
                            onChange={(value) => onEntityOptionChange(value as string)}
                        />
                        {isSaveClicked && (!selectedRule && !selectedEntity) && <Typography color="alert" variant="bodyXs">{t('REMEDIATION.MODALS.ADD_EDIT_MODAL.VALIDATIONS.REMEDIATION_REQUIREMENTS')}</Typography>}
                    </Stack>
                    <Stack spacing={1}>
                        <CloudBotsSelect
                            formValidations={isSaveClicked ? formValidations.errors?.cloudBots : undefined}
                            isEnabled
                            selectedRuleSet={selectedRuleSet}
                            selectedOption={remediation?.cloudBots}
                            onChange={(value) => onAddCloudBots(value)}
                            isEditMode={isEditMode}
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <TextArea
                            data-aid='remediation-name-input'
                            label={t('REMEDIATION.MODALS.ADD_EDIT_MODAL.COMMENT')}
                            required
                            value={comment}
                            onChange={(ev) => setComment(ev.target.value)}
                            fullWidth
                            maxLength={200}
                            clearable
                            rows={4}
                            isError={isSaveClicked && !!formValidations.errors?.comment}
                            helperText={isSaveClicked && formValidations.errors?.comment ? formValidations.errors?.comment?.[0]?.message : undefined}
                        />
                    </Stack>
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter hasSeparator={true}>
                <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                    <Button variant='text' onClick={onClose}>
                        {t('REMEDIATION.MODALS.ADD_EDIT_MODAL.BUTTONS.CANCEL')}
                    </Button>
                    <Button color='brandPrimary' onClick={()=> saveRemediation()}>
                        { t('REMEDIATION.MODALS.ADD_EDIT_MODAL.BUTTONS.SAVE') }
                        { isLoading && <Spinner size={12}/> }
                    </Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default RemediationModalCspm;
