import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLoadedComponentId, getSidebarIsVisible } from './SideBar.reducer';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { SIDEBAR_COMPONENTS } from 'common/extensibility/WellKnownPaths';
import { IComponentData } from 'common/interface/general';

export const SideBar: React.FC = () => {
    const isVisible = useSelector(getSidebarIsVisible);
    const loadedComponentId = useSelector(getLoadedComponentId);
    const InnerComponent = useMemo(() => {
        if (loadedComponentId === '') {
            return undefined;
        }
        const componentData = globalAddinContainer.getById<IComponentData>(SIDEBAR_COMPONENTS, loadedComponentId);
        if (componentData) {
            return componentData.component;
        }
    }, [loadedComponentId]);
    const isVisibleInner = isVisible && InnerComponent !== undefined;
    return (
        <>
            {isVisibleInner && <InnerComponent></InnerComponent>}
        </>
    );
};
