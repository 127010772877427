import React, { useCallback } from 'react';
import { LoaderWrapper } from '../helpers/Events.styled';
import { ButtonGroup, Spinner, Stack, Switch } from 'common/design-system/components-v2';
import EventsTable from './components/EventsTable';
import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { EventsTableTab, EventsTableTabComponent } from 'common/interface/events';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import { Filters } from 'common/module_interface/assets/EnvironmentsTableRegistry';
import useEventsTable from './hooks/useEventsTable';
import EventsFilterPanel from './components/EventsFilterPanel';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { IEvent } from 'common/module_interface/events/Events';
import { EVENT_DETAILS_DRAWER_KEY } from '../Consts';
import { ErrorBoundary } from 'react-error-boundary';

export const eventsTypeParam = 'eventType';
const lastVisitedTabKey = 'events-table_last_visited_tab';

const Events: React.FunctionComponent = () => {
    const { params, changeParams } = useReactRouterQuery();

    const [archiveView, setArchiveView] = React.useState<boolean>(false);

    const tabsData = React.useMemo(() => {
        return EventsTableRegistry.getTabs();
    } , []);

    const [tabData, setTabData] = React.useState<EventsTableTab | EventsTableTabComponent>(() => {
        const lastVisitedTab = localStorage.getItem(lastVisitedTabKey);
        const selectedTabName = params[eventsTypeParam] || lastVisitedTab;
        const selectedTab = tabsData.find((tab) => tab.id === selectedTabName);
        return selectedTab || tabsData[0];
    });
    
    const { isLoading, dataSource, activeFilters, setSelectedTab, setArchiveView: setArchiveViewToDataSource } = useEventsTable();

    React.useEffect(() => {
        if (tabData.type === 'datasourceTab') {
            setSelectedTab(tabData);
            setArchiveViewToDataSource(archiveView);
        }
    }, [setSelectedTab, setArchiveViewToDataSource, tabData, archiveView]);

    const onFilterChange = React.useCallback(async (filters: Filters) => {
        dataSource?.updateFilters(filters);
    }, [dataSource]);

    const tableId = `events_main-table_tab-${tabData.id}-${dataSource?.initialParams?.id}`;

    const onEnvTypeChange = (value: string) => {
        const selectedTab = tabsData.find((tab) => tab.id === value);
        if (selectedTab) {
            localStorage.setItem(lastVisitedTabKey, value);
            changeParams([
                { paramKey: eventsTypeParam, newValue: value },
                { paramKey: 'filterPanel', newValue: '' },
            ]);
            setTabData(selectedTab);
        }
    };

    const onEventSelected = useCallback((event: IEvent) => {
        showDrawer(EVENT_DETAILS_DRAWER_KEY.key, event.id);
    }, []);

    if (tabData.type === 'componentTab') {
        return (
            <Stack fullHeight fullWidth padding={4} spacing={5} key={`${tableId}-filter-panel`}>
                <Stack fullWidth direction='row' justifyContent='space-between' alignItems='center'>
                    <ButtonGroup
                        options={tabsData.map((tab) => ({ label: tab.label, value: tab.id }))}
                        value={tabData.id}
                        onChange={onEnvTypeChange}
                    />
                    <Switch label='Archive View' checked={archiveView} onChange={(e) => setArchiveView(e.target.checked)} />
                </Stack>
                <ErrorBoundary
                    fallbackRender={() => null}
                    onError={() => onEnvTypeChange(tabsData[0].id)}
                >
                    <tabData.component archiveView={archiveView} />
                </ErrorBoundary>
            </Stack>
        );
    }

    if (!dataSource) {
        return (
            <LoaderWrapper>
                <Spinner size={64} context='info' saturation />
            </LoaderWrapper>
        );
    }

    return (
        <Stack fullHeight fullWidth padding={4} key={`${tableId}-filter-panel`}>
            <Stack fullHeight>
                <Stack spacing={5}>
                    <Stack fullWidth direction='row' justifyContent='space-between' alignItems='center'>
                        <ButtonGroup
                            options={tabsData.map((tab) => ({ label: tab.label, value: tab.id }))}
                            value={tabData.id}
                            onChange={onEnvTypeChange}
                        />
                        <Switch label='Archive View' checked={archiveView} onChange={(e) => setArchiveView(e.target.checked)} />
                    </Stack>
                    <EventsFilterPanel
                        tableId={tableId}
                        onFilterChange={onFilterChange}
                        tabData={tabData}
                        dataSource={dataSource}
                    />
                </Stack>
                <EventsTable
                    isLoading={isLoading}
                    pageSize={50}
                    tableId={tableId}
                    tabData={tabData}
                    activeFilters={activeFilters}
                    dataSource={dataSource}
                    archiveView={archiveView}
                    onEventClicked={onEventSelected}
                />
            </Stack>
        </Stack>
    );
};

export default Events;
