import i18n from 'i18next';
import { CpRadioButton } from '@dome9/components/react/components';
import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { RiskManagementRegistry } from '../../../RiskManagementRegistry';
import { I18nRiskNamespace } from 'modules/riskManagement/consts';
import { Addin } from 'common/extensibility/AddinRegistry';
import { ermPermissionFn } from '../../../RiskManagement.utils';

const columnDefs: Addin<CGColDef>[] = [
    {
        id: 'checkbox',
        content: () => {
            return {
                cellRenderer: (row:any) => {
                    return <CpRadioButton onClick={(event:any) => event.target.checked = row.data.isSelected} checked={row.data.isSelected}/>;
                },
                maxWidth: 57,
                minWidth: 57
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'name',
        content: () => {
            return {
                colId: 'name',
                field: 'name',
                headerName: i18n.t('RISK_MANAGEMENT.RULESETS.COLUMNS.NAME.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('RISK_MANAGEMENT.RULESETS.COLUMNS.NAME.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'managedBy',
        content: () => {
            return {
                colId: 'managedBy',
                field: 'managedBy',
                headerName: i18n.t('RISK_MANAGEMENT.RULESETS.COLUMNS.MANAGED_BY.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('RISK_MANAGEMENT.RULESETS.COLUMNS.MANAGED_BY.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true,
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'rules',
        content: () => {
            return {
                colId: 'rules',
                field: 'rulesCount',
                headerName: i18n.t('RISK_MANAGEMENT.RULESETS.COLUMNS.RULES.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('RISK_MANAGEMENT.RULESETS.COLUMNS.RULES.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true,
            };
        },
        permission: ermPermissionFn,
    },
    {
        id: 'policies',
        content: () => {
            return {
                colId: 'policies',
                field: 'policies',
                headerName: i18n.t('RISK_MANAGEMENT.RULESETS.COLUMNS.POLICIES.HEADER', { ns: I18nRiskNamespace }),
                headerTooltip: i18n.t('RISK_MANAGEMENT.RULESETS.COLUMNS.POLICIES.TOOLTIP', { ns: I18nRiskNamespace }),
                sortable: true,
            };
        },
        permission: ermPermissionFn,
    }
];

export default function initializeRulesetsColumnDefs() {
    RiskManagementRegistry.addRulesetsTableColumnDefs(columnDefs);
}
