import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GcpInitialState, GcpState } from './gcp.interface';
import { GcpEnvironmentData } from 'common/module_interface/assets/GcpEnvironment';

const GcpSlice = createSlice({
    name: 'env_gcp',
    initialState: GcpInitialState,
    reducers: {
        setLoading: (state: GcpState, action: PayloadAction<{ gcpId: string; loading: boolean; }>) => {
            const { gcpId, loading } = action.payload;
            if (!state.gcpEvnironments[gcpId]) {
                state.gcpEvnironments[gcpId] = {};
            }
            state.gcpEvnironments[gcpId].loading = loading;
        },
        setError: (state: GcpState, action: PayloadAction<{ gcpId: string; error: boolean; }>) => {
            const { gcpId, error } = action.payload;
            if (!state.gcpEvnironments[gcpId]) {
                state.gcpEvnironments[gcpId] = {};
            }
            state.gcpEvnironments[gcpId].error = error;
        },
        setData: (state: GcpState, action: PayloadAction<{ gcpId: string; data: GcpEnvironmentData; }>) => {
            const { gcpId, data } = action.payload;
            if (!state.gcpEvnironments[gcpId]) {
                state.gcpEvnironments[gcpId] = {};
            }
            state.gcpEvnironments[gcpId].data = data;
        },
        setDeleted: (state: GcpState, action: PayloadAction<{ gcpId: string; }>) => {
            const { gcpId } = action.payload;
            if (!state.gcpEvnironments[gcpId]) {
                state.gcpEvnironments[gcpId] = { deleted: true };
            }
            state.gcpEvnironments[gcpId].deleted = true;
        }
    }
});

export default GcpSlice;
