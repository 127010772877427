import React, { ChangeEvent, KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nReportsNamespace } from 'modules/reporting/initialize.i18n';
import {
    MultiEmailInputWrapper,
    MultiEmailChipsContainer,
    MultiEmailChip,
} from './ReportingModal/ReportingModal.styled';
import { EMAIL_REGEX } from 'common/utils/http';

export const MultiEmailInput: React.FC<{
    onEmailChange: (emails: string[]) => void;
    emailsList: string[];
    isError: boolean;
}> = ({ onEmailChange, emailsList, isError }) => {
    const { t } = useTranslation(i18nReportsNamespace);
    const [emails, setEmails] = useState<string[]>(emailsList || []);
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
            event.preventDefault();

            const newEmail = inputValue.trim();
            if (
                newEmail &&
                isValidEmail(newEmail) &&
                !emails.includes(newEmail)
            ) {
                const updatedEmails = [...emails, newEmail];
                onEmailChange(updatedEmails);
                setEmails([...updatedEmails]);
                setInputValue('');
            }
        }
    };

    const removeEmail = (emailToRemove: string) => {
        const updatedEmails = [
            ...emails.filter((email) => email !== emailToRemove),
        ];
        setEmails([...updatedEmails]);
        onEmailChange(updatedEmails);
    };

    const isValidEmail = (email: string) => {
        const regex = new RegExp(EMAIL_REGEX);
        return regex.test(email);
    };

    return (
        <MultiEmailInputWrapper>
            <MultiEmailChipsContainer isError={isError}>
                {emails.map((email) => (
                    <MultiEmailChip key={email}>
                        {email}
                        <button onClick={() => removeEmail(email)}>
                            &times;
                        </button>
                    </MultiEmailChip>
                ))}
                <input
                    type='text'
                    placeholder={t('REPORTS.MODAL.EMAIL.PLACEHOLDER')}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                />
            </MultiEmailChipsContainer>
        </MultiEmailInputWrapper>
    );
};
