
export const FindingToKustoMapping:Record<string, string>= {
    action: 'action',
    additionalField: 'additionalField',
    alertType: 'alertType',
    assignee: 'assignee',
    bundleId: 'bundle_id',
    bundleName: 'bundle_name',
    category: 'category',
    cloudAccountExternalId: 'env_externalAccountId',
    cloudAccountId: 'env_cloudAccountId',
    cloudAccountNumberType: 'env_cloudAccountType',
    cloudAccountType: 'env_cloudAccountId_name',
    comments: 'comments',
    createdTime: 'starttime',
    description: 'rule_description',
    entityDome9Id: 'entity_dome9Id',
    entityExternalId: 'entity_id',
    entityName: 'entity_name',
    entityRegion: 'entity_region',
    entityType: 'entity_type',
    findingKey: 'findingKey',
    firstOpenedTime: 'min_starttime',
    isExcluded: 'isExcluded',
    labels: 'labels',
    mitre: 'mitre_dict',
    mitreTactic: 'mitre_tactic',
    mitreTechnique: 'mitre_technique',
    organizationalUnitId: 'env_organizationalUnitId',
    organizationalUnitPath: 'env_organizationalUnitPath',
    remediation: 'rule_remediation',
    remediationActions: 'remediationActions',
    ruleLogic: 'ruleLogic',
    ruleName: 'rule_name',
    severity: 'rule_severity_name',
    severityLevel: 'rule_severity',
    origin: 'origin_resolved',
    status: 'status',
    statusName: 'status_name',
    tag: 'entity_tags'
};

export const KustoToFindingMapping:Record<string, string>= {
    action: 'action',
    additionalField: 'additionalField',
    alertType: 'alertType',
    assignee: 'assignee',
    bundle_id: 'bundleId',
    bundle_name: 'bundleName',
    category: 'category',
    comments: 'comments',
    entity_dome9Id: 'entityDome9Id',
    entity_externalId: 'entityExternalId',
    entity_id: 'entityExternalId',
    entity_name: 'entityName',
    entity_tags: 'tag',
    entity_type: 'entityType',
    env_cloudAccountId: 'cloudAccountId',
    env_cloudAccountId_name: 'cloudAccountType',
    env_cloudAccountType: 'cloudAccountNumberType',
    env_externalAccountId: 'cloudAccountExternalId',
    env_organizationalUnitId: 'organizationalUnitId',
    env_organizationalUnitPath: 'organizationalUnitPath',
    findingKey: 'findingKey',
    isExcluded: 'isExcluded',
    labels: 'labels',
    min_starttime: 'firstOpenedTime',
    mitre_dict: 'mitre',
    mitre_tactic: 'mitreTactic',
    mitre_technique: 'mitreTechnique',
    origin_resolved: 'origin',
    remediationActions: 'remediationActions',
    ruleLogic: 'ruleLogic',
    rule_description: 'description',
    rule_name: 'ruleName',
    rule_remediation: 'remediation',
    rule_severity_name: 'severity',
    rule_severity: 'severityLevel',
    starttime: 'createdTime',
    status: 'status',
    status_name: 'statusName',
};
