export const EMPTY_RESULT = 'Your query didn\'t return any results';
export const PLEASE_SELECT_ENTITY_ERROR = 'Please double click on entities to expand their relationships';
export const NET_EXPOSURE = {
    INTERNET: 'Internet',
    PARTIAL: 'Internet IPs',
    PRIVATE: 'Private',
};

const unsortedQueries : { [key: string]: string } = {
    'Application load balancers': 'AwsAppLoadBalancerBase',
    'Load balancer target groups': 'AwsAppLoadBalancerTargetGroup',
    'Accounts with access to roles': 'AwsAssumeRoleToAccount',
    'Roles open to everyone': 'AwsAssumeRoleToAll',
    'Roles assumable by users': 'AwsAssumeRoleToCanonicalUser',
    'SAML providers': 'AwsAssumeRoleToSamlProvider',
    'AWS Services with access to roles': 'AwsAssumeRoleToService',
    'Session roles': 'AwsAssumeRoleToSessionRole',
    'User session': 'AwsAssumeRoleToSessionUser',
    'Roles assumable by WEB Identities': 'AwsAssumeRoleToWebIdentity',
    'Auto Scaling groups': 'AwsAutoScalingGroup',
    'EC2 Instances': 'AwsEC2Instance',
    'EC2 Instances exposed to the internet through ALB': 'AlbInternetExposedEc2Instances',
    'EC2 Instances exposed to the internet by public IP': 'IpInternetExposedEc2Instances',
    'ECS Clusters': 'AwsEcsCluster',
    'ECS Tasks': 'AwsEcsTask',
    'ECS Task definitions': 'AwsEcsTaskDefinition',
    'Elastic load balancers': 'AwsElasticLoadBalancer',
    'IAM Group': 'AwsIamGroup',
    'EC2 Instance profiles': 'AwsIamInstanceProfile',
    'IAM policies': 'AwsIamPolicy',
    'IAM Roles': 'AwsIamRole',
    'IAM Users': 'AwsIamUser',
    'IAM Users groups': 'AwsIamUserGroup',
    'Lambda functions': 'AwsLambdaFunction',
    'EC2 NACL': 'AwsNacl',
    'EC2 NAT gateways': 'AwsNatGateway',
    'IAM Organization accounts': 'AwsOrganizationAccount',
    'IAM Organizations': 'AwsOrganization',
    'IAM Organization units': 'AwsOrganizationUnit',
    'Redshift Cluster': 'AwsRedshiftCluster',
    'Route table': 'AwsRouteTable',
    'S3 buckets': 'AwsS3Bucket',
    'Security groups': 'AwsSecurityGroup'
};

export const queriesMap : { label: string, value: string }[] = Object.entries(unsortedQueries).sort().reduce(
    (acc,[key,value]) => {
        acc.push({
            value, label: key
        });
        return acc;
    },
    [] as { label: string, value: string }[]
);