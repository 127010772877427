import * as vis from 'vis-network/standalone';
import { GraphNode } from '../Models/GraphNode';
import { GraphEdge } from '../Models/GraphEdge';
import { IQueryGraphResult } from './EntitiesGraphEngineService';

class Data {
    nodes: GraphNode[] = [];
    edges: GraphEdge[] = [];
}

const groupMap: { [key: string]: { [key: string]: string } } = {
    AwsEC2Instance: {
        Internet: 'AwsEC2InstancePublic',
        Private: 'AwsEC2InstancePrivate',
        'Internet IPs': 'AwsEC2InstancePartialPublic'
    }, AwsSecurityGroup: {
        Internet: 'AwsSecurityGroupPublic',
        Private: 'AwsSecurityGroupPrivate',
        'Internet IPs': 'AwsSecurityGroupPartialPublic'
    }
};

export class GraphData {
    nodes = new vis.DataSet<GraphNode>([]);
    edges = new vis.DataSet<GraphEdge>([]);

    addToGraph(data: IQueryGraphResult) {
        this.addNewItems(GraphData.extractEdgesAndNodes(data));
    }

    reset() {
        this.edges.clear();
        this.nodes.clear();
    }

    private addNewItems(data: Data) : void {
        const newItems = new Data();
        for (const node of data.nodes) {
            if (!this.nodes.get(node.id)) {
                newItems.nodes.push(node);
            }
        }
        for (const edge of data.edges) {
            if (!this.edges.get(edge.id)) {
                newItems.edges.push(edge);
            }
        }
        this.nodes.add(newItems.nodes);
        this.edges.add(newItems.edges);
    }

    private static extractEdgesAndNodes(data: IQueryGraphResult) : Data {
        const result = new Data();

        if (data.entities) {
            for (const node of data.entities) {
                let group = node.type;
                const netExposure = node.properties?.CloudGuardInfo?.networkExposure;

                const exposureGroup = groupMap[group]?.[netExposure];
                if (exposureGroup) {
                    group = exposureGroup;
                }

                result.nodes.push({
                    id: node.nodeId,
                    label: node.id,
                    group: group,
                    properties: node.properties,
                    type: node.type,
                    updateTimestamp: node.updateTimestamp,
                    shapeProperties: { useImageSize: true },
                    networkExposure: node.properties?.CloudGuardInfo?.networkExposure,
                    exposurePath: node.properties?.CloudGuardInfo?.exposurePath,
                });
            }
        }
        if (data.relationships) {
            for (const edge of data.relationships) {
                result.edges.push({
                    label: edge.name,
                    from: edge.from,
                    to: edge.to,
                    type: edge.name,
                    group: edge.type,
                    id: edge.id,
                    arrows: { to: { enabled: true, scaleFactor: 0.5 } },
                });
            }
        }
        return result;
    }
}