import { ermTrans } from '../../../RiskManagement.utils';
import { IIssueExclusion, IIssueExclusionProps } from '../IssueExclusions.interface';
import { fetchAllIssueExclusions } from '../IssueExclusions.utils';
import { isEmptyItemValue } from 'common/erm-components/custom/CustomForm/CustomForm.values';
import { IItem, IItemsMap } from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import { IssueExclusionItemNames } from './IssueExclusionEditor.consts';

export const RequiredConditionFieldNames: IssueExclusionItemNames[] = [
    IssueExclusionItemNames.severities,
    IssueExclusionItemNames.rules,
    IssueExclusionItemNames.entityTypes,
    IssueExclusionItemNames.environments,
    IssueExclusionItemNames.orgUnits,
    IssueExclusionItemNames.entityNames,
    IssueExclusionItemNames.entityId,
    IssueExclusionItemNames.dateRange,
    IssueExclusionItemNames.tags,
];

export const checkConditionsValidation = async (item: IItem, itemsMap: IItemsMap): Promise<string | undefined> => {
    if (RequiredConditionFieldNames.some(name => !isEmptyItemValue(itemsMap[name]))) {
        return;
    }

    return ermTrans('ISSUE_EXCLUSIONS.EDITOR.CONDITIONS_ERROR');
};

export const getOtherExclusionNames = async (issueExclusion?: IIssueExclusionProps): Promise<string[]> => {
    const allExclusions: IIssueExclusion[] = await fetchAllIssueExclusions();
    const otherExclusions: IIssueExclusion[] = issueExclusion?.id ? allExclusions.filter(exclusion => exclusion.id !== issueExclusion.id) : allExclusions;
    return otherExclusions.map(exclusion => exclusion.name);
};
