import styled from 'styled-components/macro';

export const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 10px;
  min-height: 40px;
`;

export const HeaderArrowTextDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  gap: 8px;
`;

export const HeaderTextDiv = styled.div<{ isLargeTitle?: boolean, isLink?: boolean }>`
  text-align: left;
  font: normal normal normal 12px/12px Open Sans;
  font-size: ${props => props.isLargeTitle ? '15' : '12'}px;
  font-weight: 500;
  letter-spacing: 0;
  color: #2E3F58;
  cursor: ${props => props.isLink ? 'pointer' : 'default'};
  
  &:hover {
    text-decoration: ${props => props.isLink ? 'underline' : 'none'};
  }  
`;

export const HeaderExtraComponentDiv = styled.div`
  display: flex;
  padding-top: 1px;
  flex-grow: 1;
`;

export const ArrowDiv = styled.div<{ isExpanded: boolean }>`
  display: flex;  
  align-items: center;
  transition: transform 0.2s linear;
  transform: rotate(${props => props.isExpanded ? '90deg' : '0deg'});
`;

export const ContentDiv = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  height: ${props => props.isExpanded ? '100%' : '0'};
  width: 100%;
  opacity: ${props => props.isExpanded ? '1' : '0'};
  transition: opacity 0.4s linear, height 0.4s linear;
  overflow: hidden;
`;

export const SectionStyled = {
    TopDiv,
    HeaderDiv,
    HeaderArrowTextDiv,
    HeaderTextDiv,
    HeaderExtraComponentDiv,
    ArrowDiv,
    ContentDiv,
};
