import styled from 'styled-components';

export const LoadingOverlayStyled = styled.div<{ isLoading: boolean; }>`
            display:  ${({ isLoading }) => isLoading ? 'flex' : 'none'};;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;     
            background-color: rgba(0, 0, 0, 0.3);         
            justify-content: center;
            align-items: center;
            z-index: 999;
        .loading-panel{
            border-radius: 8px;
            background-color: white;
            padding: 20px;
        }
    `;