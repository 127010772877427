import styled, { css } from 'styled-components';

export const TotalCounterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .total-counter-image {
    width: 42px;
    height: 42px;
  }
`;

export const TotalCounterHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
`;

export const TotalCounterIconDiv = styled.div`
  align-self: center;
  height: 42px;
  margin-right: 10px;
`;

export const TotalCounterTextDiv = styled.div<{ isClickable: boolean }>`
  text-align: center;
  font: normal normal normal 46px/62px Open Sans;
  letter-spacing: 0;
  color: #000000CC;
  opacity: 1;

  ${props => props.isClickable && css`
    &:hover {
      color: #3594FF;
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      cursor: pointer;
    }
  `};
`;

export const TotalCounterSubtitleDiv = styled.div`
  text-align: center;
  font: normal normal normal 13px/18px Open Sans;
  letter-spacing: 0;
  color: #000000CC;
  opacity: 1;
  margin-top: 8px;
`;
