import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PlainLink = styled.a`
  width: fit-content;
  cursor: pointer;
  color: ${({ theme }) => `${theme.palette.onSurface.brandPrimary}`};
  text-decoration: underline;
`;

export const RiskDashboardStyled = {
    TopDiv,
    TopSection,
    PlainLink,
};

