import React, { useCallback, useEffect, useState } from 'react';
import { getNotificationPageService } from 'common/interface/services';
import { Button, CopiableLine, Icon, Stack, Tooltip, Typography ,Spinner } from 'common/design-system/components-v2';
import { JsonPayloadWrapper } from './JsonPayloadEditorComponent.styled';
import { useTranslation } from 'react-i18next';
import { I18nNotifications } from '../../NotificationsPage.consts';
import { IJiraConfigurationObj } from '../../../Integrations/Configurations/Componenets/Jira/JiraComponent';
import { INTEGRATIONS_IDS } from 'common/module_interface/settings/integrations/consts';

interface JsonPayloadEditorProps {
    jsonPayload?: string;
    payLoadUpdated: (payload: string) => void;
    integrationConfiguration: IJiraConfigurationObj;
}

const defaultJsonPayload = JSON.stringify({
    'fields': {
        'project':
            {
                'key': 'TEST'
            },
        'summary': '${Title}',
        'description': 'AccountId:${Environment_ID} \n Severity:${Severity} \n Description:${Description} \n Remediation:${Remediation}',
        'issuetype': {
            'name': 'TASK'
        }
    }
},null, 4);
const JsonPayloadEditorComponent: React.FC<JsonPayloadEditorProps> = ({ payLoadUpdated, jsonPayload= defaultJsonPayload, integrationConfiguration }) => {
    const { t } = useTranslation( I18nNotifications );
    const [jsonInput, setJsonInput] = useState<string>(jsonPayload);
    const [isSyntaxValid, setIsSyntaxValid] = useState<boolean | null>(null);
    const [testPassed, setTestedPassed] = useState<boolean | null>(null);
    const [templateLiterals, setTemplateLiterals] = useState<string[]>([]);
    const [statusValid, setStatusValid] = useState<boolean | null>(null);
    const [statusMessage, setStatusMessage] = useState<string>('');
    const [spinner, setSpinner] = useState<boolean>(false);
    const initTemplateLiterals = useCallback(async () => {
        const webhookJiraTokens = await getNotificationPageService().getWebhookJiraTokens();
        setTemplateLiterals(webhookJiraTokens);
    },[]);

    useEffect(() => {
        if(isSyntaxValid !== null) {
            setStatusMessage(isSyntaxValid ? t('GENERAL.SYNTAX_VERIFIED') : t('GENERAL.SYNTAX_ERROR'));
        }
        if(testPassed !== null) {
            setStatusMessage(testPassed ? t('GENERAL.TEST_PASSED') : t('GENERAL.TEST_FAILED'));
        }
    }, [isSyntaxValid, testPassed, t]);

    useEffect(() => {
        initTemplateLiterals();
    }, [initTemplateLiterals]);

    useEffect(() => {
        payLoadUpdated(jsonInput);
    }, [jsonInput]);

    const resetComponents = () => {
        setTestedPassed(null);
        setIsSyntaxValid(null);
        setStatusValid(null);
    };

    const handleSyntaxValidation = () => {
        resetComponents();
        try {
            JSON.parse(jsonInput);
            setIsSyntaxValid(true);
            setStatusValid(true);
        } catch (error) {
            setIsSyntaxValid(false);
            setStatusValid(false);
        }
    };

    const handleTestConfiguration = async () => {
        resetComponents();
        try {
            JSON.parse(jsonInput);
            const configurations = { payloadFormat: JSON.parse(jsonInput), advancedUrl: integrationConfiguration?.AdvancedUrl,
                authMethod: integrationConfiguration?.AuthType, formatType: INTEGRATIONS_IDS.JIRA, httpMethod: integrationConfiguration?.MethodType, password: integrationConfiguration?.Password,
                url: integrationConfiguration?.Url, username: integrationConfiguration?.Username, ignoreCertificate: integrationConfiguration?.IgnoreCertificate };
            setSpinner(true);
            await getNotificationPageService().testJiraNotification(configurations);
            setSpinner(false);
            setTestedPassed(true);
            setStatusValid(true);
        } catch (error) {
            setSpinner(false);
            setTestedPassed(false);
            setStatusValid(false);
        }
    };

    function handlePayloadUpdated(value: string) {
        setJsonInput(value);
        payLoadUpdated(value);
    }

    return (
        <JsonPayloadWrapper direction={'row'} isPayloadValid={Boolean(statusValid)} >
            <Stack className={'jsonPayloadEditor'}>
                <textarea
                    value={jsonInput}
                    onChange={(e) => handlePayloadUpdated(e.target.value)}
                    rows={10}
                    cols={50}
                />
                <Stack direction={'row'} padding={4} justifyContent={'space-between'}>
                    {statusValid !== null ? <Stack justifyContent={'flex-start'} direction={'row'} spacing={3} className={'json-valid-message'}>
                        <Icon color={statusValid ? 'success' : 'error' as any} name={statusValid ? 'checkCircle' : 'error'}/>
                        <Typography>{statusMessage}</Typography>
                    </Stack> : <div/>}
                    {}
                    <Stack direction={'row'} spacing={3}>
                        <Button color={'brandPrimary'} onClick={handleSyntaxValidation} variant={'outlined'} >
                            <Typography color={'brandPrimary'}>{t('MODALS.ADD_EDIT_MODAL.VERIFY_SYNTAX')}</Typography>
                        </Button>
                        { integrationConfiguration ?
                            <Button color={'brandPrimary'} onClick={handleTestConfiguration} variant={'outlined'} disabled={!integrationConfiguration}>
                                {spinner ? <Spinner size={12}/> : <Typography color={'brandPrimary'}>{t('GENERAL.TEST')}</Typography>}
                            </Button>
                            : <Tooltip content={t('MODALS.ADD_EDIT_MODAL.SELECT_CONFIGURATION_FOR_TEST')} placement={'top'}>
                                <Button color={'brandPrimary'} onClick={handleTestConfiguration} variant={'outlined'} disabled={!integrationConfiguration}>
                                    {spinner ? <Spinner size={12}/> : <Typography color={'brandPrimary'}>{t('GENERAL.TEST')}</Typography>}
                                </Button>
                            </Tooltip>
                        }
                    </Stack>
                </Stack>
            </Stack>
            <Stack>
                <ul>
                    {templateLiterals.map((template, index) => (
                        <li key={index}>
                            <div>
                                <CopiableLine value={template} >
                                    <Typography>{template}</Typography>
                                </CopiableLine>
                            </div>
                        </li>
                    ))}
                </ul>
            </Stack>
        </JsonPayloadWrapper>
    );
};

export default JsonPayloadEditorComponent;
