import React, { useEffect, useState } from 'react';
import {
    QRadarStyled,
    QRadarNameWrapper,
    QRadarEndpointUrlWrapper,
    QRadarEndpointUrlInputWrapper,
    QRadarAuthenticationWrapper,
    BasicAuthCredentialsInputWrapper,
    IgnoreCertificateValidationWrapper,
} from './Qradar.styled';
import { Stack, Button, Typography, Dropdown, Input, List, Checkbox, Icon, Tooltip } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import {
    I18nIntegrations,
    URL_ENDPOINT_PROTOCOL,
    AUTHENTICATION_TYPE,
    SUBMIT_STATUS_RESPONSE, COMPONENT_TEXT_COLOR
} from 'common/module_interface/settings/integrations/consts';
import { getIntegrationsService } from 'common/interface/services';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';
import { isUrlValid } from 'common/utils/http';


interface IGenericConfigurationProps extends IConfigurationContainerProps{
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration{
    configurationObj: IQradarConfigurationObj
}

export interface IQradarConfigurationObj {
    Url: string,
    type: string,
    username: string,
    Password: string,
    IgnoreCertificate: boolean
}

const QRadarComponent : React.FC<IGenericConfigurationProps> = ({ onConfigurationChangeCallBack ,configuration,
    onConfigurationSaved, viewMode, testFormatType }) => {
    const { t } = useTranslation( I18nIntegrations );
    const urlFromConfig = configuration?.configurationObj?.Url?.replace(/^https?:\/\//, '') || '';

    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [endpointUrlInputConfig, SetEndpointUrlInputConfig] = useState<string>(urlFromConfig);
    const [authenticationTypeInputConfig, setAuthenticationTypeInputConfig] = useState<string>(configuration?.configurationObj.type || AUTHENTICATION_TYPE.QRADAR);
    const [endpointUrlTestButtonError, setEndpointUrlTestButtonError] = useState<string>('');
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [basicAuthUsernameInputConfig, setBasicAuthUsernameInputConfig] = useState<string>(configuration?.configurationObj.username || '');
    const [basicAuthPasswordInputConfig, setBasicAuthPasswordInputConfig] = useState<string>(configuration?.configurationObj.Password || '');
    const [ignoreCertificateValidation, SetIgnoreCertificateValidation] = useState<boolean>(configuration?.configurationObj.IgnoreCertificate || false);
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);
    const [isTestButtonDisabled, setTestIsButtonDisabled] = useState<boolean>(false);


    const discardChangesButtonHidden = Boolean(idConfiguration);
    const authenticationTypes = [
        { label: AUTHENTICATION_TYPE.QRADAR, value: AUTHENTICATION_TYPE.QRADAR, onClick: ()=>{handleAuthenticationTypeChange(AUTHENTICATION_TYPE.QRADAR);} },
    ];
    const viewOnly = Boolean(viewMode);

    useEffect(() => {
        checkChangesMade();
        checkTestButtonDisabled();
    }, [nameInputConfig,
        endpointUrlInputConfig,
        ignoreCertificateValidation,
        basicAuthPasswordInputConfig,
        idConfiguration]);

    const checkMissingRequiredInput = () => {
        const variableNotChanged = (
            nameInputConfig === '' ||
            endpointUrlInputConfig === '' ||
            basicAuthPasswordInputConfig === ''
        );
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected = (
            nameInputConfig !== configuration?.name ||
            endpointUrlInputConfig !== urlFromConfig ||
            basicAuthPasswordInputConfig !== configuration?.configurationObj.Password ||
            ignoreCertificateValidation !== configuration?.configurationObj.IgnoreCertificate ||
            idConfiguration !== configuration?.id
        );
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const checkTestButtonDisabled = () => {
        const testButtonDisabled = (
            !endpointUrlInputConfig ||
            (Boolean(endpointUrlInputConfig) && !isUrlValid(endpointUrlInputConfig))
        );
        setTestIsButtonDisabled(testButtonDisabled);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setNameInputConfig(textInput);
    };

    const handleEndpointUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndpointUrlTestButtonError('');
        const textInput = event.target.value;
        const domain = textInput.replace(/^https?:\/\//, '');
        SetEndpointUrlInputConfig(domain);
    };

    const testUrlEndpoint = async () => {
        setEndpointUrlTestButtonError('');
        try {
            const testButtonResponse = await getIntegrationsService().testGenericWebhookService(URL_ENDPOINT_PROTOCOL + endpointUrlInputConfig,
                authenticationTypeInputConfig, basicAuthUsernameInputConfig, basicAuthPasswordInputConfig, ignoreCertificateValidation, testFormatType);
            setEndpointUrlTestButtonError( testButtonResponse || t('CONFIGURATIONS.GENERIC_WEBHOOK.NETWORK_ERROR'));
            setResponseMessageSuccess(true);
            return true;
        } catch (error:any) {
            setEndpointUrlTestButtonError( error || t('CONFIGURATIONS.GENERIC_WEBHOOK.NETWORK_ERROR'));
            setResponseMessageSuccess(false);
            return false;
        }
    };

    const closeDropbox = () => {
        setIsDropdownOpen(false);
    };

    const handleAuthenticationTypeChange = (typeSelected: string) => {
        setBasicAuthUsernameInputConfig('');
        setAuthenticationTypeInputConfig(typeSelected);
        closeDropbox();
    };

    const handleBasicAuthPasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndpointUrlTestButtonError('');
        const textInput = event.target.value;
        setBasicAuthPasswordInputConfig(textInput);
    };

    const handleIgnoreCertificateValidationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndpointUrlTestButtonError('');
        const textInput = event.target.checked;
        SetIgnoreCertificateValidation(textInput);
    };

    const handleDiscardChanges = () => {
        setNameInputConfig(configuration?.name || '');
        SetEndpointUrlInputConfig(urlFromConfig);
        setAuthenticationTypeInputConfig(configuration?.configurationObj.type || AUTHENTICATION_TYPE.QRADAR);
        setBasicAuthPasswordInputConfig(configuration?.configurationObj.Password || '');
        SetIgnoreCertificateValidation(configuration?.configurationObj.IgnoreCertificate || false);
    };

    const testConfigurationData = async () => {
        return await testUrlEndpoint();
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        const testPassed = await testConfigurationData();
        if (testPassed) {
            try {
                setEndpointUrlTestButtonError('');
                const resp = onConfigurationSaved && await onConfigurationSaved(nameInputConfig,
                    { Url: URL_ENDPOINT_PROTOCOL + endpointUrlInputConfig, type: authenticationTypeInputConfig, username: basicAuthUsernameInputConfig, password: basicAuthPasswordInputConfig, ignoreCertificate: ignoreCertificateValidation },
                    idConfiguration);
                resp?.id && setIdConfiguration(resp.id);
                onConfigurationChangeCallBack && onConfigurationChangeCallBack();
                setIsButtonDisabled(true);
                return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
            } catch (error:any) {
                setResponseMessageSuccess(false);
                return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
            }
        } else {
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_TEST };
        }
    };

    return (
        <QRadarStyled data-aid={'qradar-component'}>
            <QRadarNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input type="text" data-aid='nameInput' value={nameInputConfig} placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange} autoFocus={true} disabled={viewOnly}/>
            </QRadarNameWrapper>
            <QRadarEndpointUrlWrapper style={{ alignItems: endpointUrlTestButtonError ? 'center' : 'flex-end' }}>
                <QRadarEndpointUrlInputWrapper>
                    <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GENERIC_WEBHOOK.ENDPOINT_URL')}</Typography>
                    <Input type="text" data-aid='urlInput' value={endpointUrlInputConfig} fullWidth={true} onChange={handleEndpointUrlChange}
                        startAdornment={<div>{URL_ENDPOINT_PROTOCOL}</div>} disabled={viewOnly}/>
                </QRadarEndpointUrlInputWrapper>
                <Button style={{ height: 32, marginLeft: 10 }} size='medium' color='normal'
                    onClick={testConfigurationData} active={true} loading={false} data-aid='testButton'
                    disabled={ viewOnly || isTestButtonDisabled }
                >{t('GENERAL.TEST')}
                </Button>
            </QRadarEndpointUrlWrapper>
            <Stack margin={[0,6]}>
                <Typography elementType='h5' color={responseMessageSuccess ? COMPONENT_TEXT_COLOR.SUCCESS : COMPONENT_TEXT_COLOR.ALERT}>{endpointUrlTestButtonError}</Typography>
            </Stack>
            <QRadarAuthenticationWrapper>
                <BasicAuthCredentialsInputWrapper>
                    <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GENERIC_WEBHOOK.AUTHENTICATION_TYPE')}</Typography>
                    <Dropdown
                        label={authenticationTypeInputConfig}
                        open={isDropdownOpen}
                        onStateChange={setIsDropdownOpen}
                        buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { maxWidth: 'none', display: 'flex' } }}
                        maxHeight={500}
                        placement="bottom-start"
                    >
                        <List
                            options={authenticationTypes}
                            disabled={viewOnly}
                        />
                    </Dropdown>
                </BasicAuthCredentialsInputWrapper>
                <BasicAuthCredentialsInputWrapper>
                    <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GENERIC_WEBHOOK.PASSWORD')}</Typography>
                    <Input type="password" placeholder={t('GENERAL.PASSWORD_PLACEHOLDER')} fullWidth={true}
                        value={basicAuthPasswordInputConfig} onChange={handleBasicAuthPasswordInput} disabled={viewOnly} />
                </BasicAuthCredentialsInputWrapper>
            </QRadarAuthenticationWrapper>
            <IgnoreCertificateValidationWrapper>
                <Checkbox
                    checked={ignoreCertificateValidation}
                    labelProps={{ color: 'strong' }}
                    label={t('CONFIGURATIONS.GENERIC_WEBHOOK.IGNORE_CERTIFICATE_VALIDATION')}
                    onChange={handleIgnoreCertificateValidationChange}
                    disabled={viewOnly}
                />
                <Tooltip content={<Stack spacing={2}><div>{t('CONFIGURATIONS.GENERIC_WEBHOOK.IGNORE_CERTIFICATE_VALIDATION_TOOLTIP')}</div></Stack>} placement="top">
                    <Icon color="normal" name="info" size={12} />
                </Tooltip>
            </IgnoreCertificateValidationWrapper>
            { GenericCancelSubmitButtonWrapper({ handleDiscardChanges ,discardChangesButtonHidden, isButtonDisabled, submitForm, viewOnly, responseMessageSuccess }) }

        </QRadarStyled>
    );
};

export default QRadarComponent;