import React from 'react';
import { ModalType } from '../../AlibabaEnvironment.types';
import EditAlibabaNameModal from './Rename/EditAlibabaNameModal';
import EditAlibabaCredentialsModal from './Credentials/EditAlibabaCredentialsModal';
import RemoveAlibabaModal from './RemoveAlibabaModal/RemoveAlibabaModal';
import useModalFromUrl from 'common/hooks/useModalFromUrl';
import RemoveIntelligence from './RemoveIntelligence/RemoveIntelligence';

export interface ModalsProps {
    alibabaId: string;
    modalType: ModalType | null;
    closeModal: () => void
    setModalType: (modalType: ModalType) => void;
}

export interface DeleteModalProps extends ModalsProps{
    forceDelete?: boolean;
}

const Modals: React.FC<ModalsProps> = props => {
    const { setModalType, modalType } = props;
    const { modalTypeFromUrl } = useModalFromUrl();

    React.useEffect(() => {
        if (modalTypeFromUrl && !modalType) {
            setModalType(modalTypeFromUrl as ModalType);
        }
    }, [modalType, modalTypeFromUrl, setModalType]);

    switch (props.modalType) {
        case ModalType.Rename:
            return <EditAlibabaNameModal {...props} />;
        case ModalType.EditCredentials:
            return <EditAlibabaCredentialsModal {...props} />;
        case ModalType.Remove:
            return <RemoveAlibabaModal {...props} />;
        case ModalType.RemoveWithPolicies:
            return <RemoveAlibabaModal forceDelete={true} {...props} />;
        case ModalType.RemoveIntelligence:
            return <RemoveIntelligence {...props} />;
        default:
            return null;
    }
};

export default Modals;