import React, { useEffect, useState } from 'react';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import { RemediationTableRegistry } from 'modules/postureManagement/remediation/services/RemediationTableRegestries';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import {
    Aggregations,
    AggregationOption,
    IRemediationFilterPanelProps,
} from '../helpers/remediation.interfaces';
import { IRemediation } from 'common/interface/remediation';
import { FILTER_EVENTS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { PLATFORM, RULESETS, savedFiltersComponentName, ENVIRONMENTS } from '../helpers/remediation.consts';
// import { getHttpService, ICustomzationResponse } from 'common/interface/services';

const RemediationFilterPanel: React.FC<IRemediationFilterPanelProps> = ({ tableData, rawData, isLoading, onFilterChange }) => {
    const [filterElement, setFilterElement] = useState(<div></div>);

    const onFilterEvent = (action: string, filtersValues: IFiltersValues) => {
        if( action === FILTER_EVENTS.FILTER_CHANGED) {
            onFilterChange(filtersValues);
        }
    };

    // const getFiltersInitialData = async () => {
    //     const savedFilters = await getHttpService().request<ICustomzationResponse<any>>(`customization?component=${savedFiltersComponentName}`, {
    //         method: 'GET',
    //     });

    //     return { savedFilters };
    // };

    useEffect(() => {

        async function getAggregations(filtersValues: IFiltersValues, data = tableData): Promise<Aggregations> {
            const rulesetIdFilter = { key: [RULESETS], options: [] as AggregationOption[] , getAllOptionsFromRemediation: (remediation: IRemediation) => {
                return remediation.rulesetId || '-';
            } };
            const environmentsFilter = { key: [ENVIRONMENTS], options: [] as AggregationOption[] , getAllOptionsFromRemediation: (remediation: IRemediation) => {
                return remediation.cloudAccountId || '-';
            } };
            const platformsFilter = { key: [PLATFORM], options: [] as AggregationOption[] , getAllOptionsFromRemediation: (remediation: IRemediation) => { return remediation.platform; } };
            const aggregations = [rulesetIdFilter, environmentsFilter, platformsFilter];
            for (const remediation of data) {
                for (const aggregation of aggregations) {
                    const optionFromRemediation = aggregation.getAllOptionsFromRemediation(remediation);
                    let isOptionExist = false;
                    if (optionFromRemediation !== '-') {
                        aggregation.options.forEach(filterOption => {
                            if (optionFromRemediation === filterOption.value) {
                                filterOption.count++;
                                isOptionExist = true;
                            }
                        });
                        !isOptionExist && aggregation.options.push({ value: optionFromRemediation, count: 1 });
                    }
                }
            }
            return { [PLATFORM]: platformsFilter.options, [RULESETS]: rulesetIdFilter.options, [ENVIRONMENTS]: environmentsFilter.options };
        }

        async function renderFilterPanel() {
            // const filtersInitialData = await getFiltersInitialData();
            const aggregations = await getAggregations({}, rawData);
            const filterPanelElementsList = RemediationTableRegistry.getFilterDefs([
                { id: FILTERS_KEYS.ADD_FILTER },
                { id: FILTERS_KEYS.FREE_TEXT },
                { id: RULESETS },
                { id: ENVIRONMENTS },
                { id: PLATFORM },
                { id: FILTERS_KEYS.SAVED_FILTERS },
                { id: FILTERS_KEYS.CLEAR_BUTTON },
            ], {
                aggregations: aggregations || {},
                // filtersInitialData: filtersInitialData || {},
                savedFiltersComponentName,
            });
            const filterPanel = initFilterPanel({
                filterPanelElementsList,
                getAggregations: getAggregations,
                shouldBuildObjectForAPI: false,
                filterId: 'RemediationFilter',
                onFilterChangeCallBack: onFilterEvent
            });
            setFilterElement(filterPanel);
        }

        if(!isLoading) {
            renderFilterPanel();
        }

    },[tableData, isLoading]);

    
    return <div>
        {filterElement}
    </div>;
};

export default RemediationFilterPanel;