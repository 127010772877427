import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Button, Link, Typography, Icon } from 'common/design-system/components-v2';
import StatusMessagePanel from 'common/components/StatusMessagePanel/StatusMessagePanel';
import { getStoreService } from 'common/interface/services';
import { StatusType } from 'common/components/StatusMessagePanel/StatusMessagePanel.types';
import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';
import {
    getAllStorages,
    getARMTemplateDetails,
    getAzureIntelligenceType, getAzureOnboardingType,
    getSubscriptionId,
    setNextButtonDisabled
} from '../../AzureIntelligenceOnboarding.reducer';
import {
    AzureConfigurationPanel,
    AzureOnboardingStep,
    AzureOnboardingStepTitle,
} from '../../AzureIntelligenceOnboarding.styled';
import {
    ICheckARMTemplateStatus,
    ICheckARMTemplateStatusRequest,
    ICheckARMTemplateStatusResponse, ILogTypesModel,
    IStorageDataForRMTemplate, IStorageModel
} from '../../AzureIntelligenceOnboarding.interface';
import { AssetType, AzureIntelligenceOnboardingType } from '../../AzureIntelligenceOnboarding.const';
import { checkARMTemplateStatus } from '../../Services/AzureIntelligenceOnboardingService';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { getStatusesMap } from '../../AzureIntelligenceOnboarding.helpers';

const ARMTemplate: FunctionComponent = () => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });
    const dispatch = getStoreService().dispatch;

    const armTemplateDetails = useSelector(getARMTemplateDetails);
    const allStorages = useSelector(getAllStorages);
    const subscriptionId = useSelector(getSubscriptionId);
    const templateDetails = useSelector(getARMTemplateDetails);
    const isAccountActivity = useSelector(getAzureIntelligenceType) === AssetType.activityLogs;
    const isCentralizedOnboarding = useSelector(getAzureOnboardingType) === AzureIntelligenceOnboardingType.centralized;

    const [showTemplateStatus, setShowTemplateStatus] = useState(false);
    const [templateStatus, setTemplateStatus] = useState(StatusType.INFO);

    const customARMTemplateLink = armTemplateDetails?.arm;
    const getRoleAssignments = (storageName: string) => armTemplateDetails?.roleAssignments[storageName];

    const selectedStorages = useMemo(() => allStorages.filter(storage => storage.isSelected), [allStorages]);

    useEffect(() => {
        setShowTemplateStatus(false);
    }, [templateDetails]);

    const checkStatus = () => {
        const storageData: IStorageDataForRMTemplate[] = [];

        selectedStorages.forEach(storage => {
            if (isCentralizedOnboarding || (!isCentralizedOnboarding && !storageData.find(data => data.StorageAccountName === storage.storageName))) {
                let logTypes: string[] = [];
                const storageItem = allStorages.find((item: IStorageModel) => item.storageName === storage.storageName);

                if (isAccountActivity) {
                    storageItem?.logTypesFound.forEach((type: ILogTypesModel) => {
                        if (type.logTypeStatus === ConnectionStatus.connected && type.hasStorageKey) {
                            logTypes.push(type.logType);
                        }
                    });
                }
                logTypes = isAccountActivity ? (logTypes.concat(storage.logsType ?? [])) : [AssetType.flowLogs];

                if (logTypes.length)
                    storageData.push({
                        StorageAccountName: storage.storageName,
                        LogTypes: logTypes,
                        StorageAccountResourceGroup: isCentralizedOnboarding ? storage.resourceGroupName : (storage.storageRg ?? ''),
                        StorageRoleAssignmentId: getRoleAssignments(storage.storageName)?.storageBlob,
                        EventGridRoleAssignmentId: getRoleAssignments(storage.storageName)?.eventGrid
                    });
            }
        });

        const request: ICheckARMTemplateStatusRequest = {
            StorageAccountsForSubscriptions: {
                [subscriptionId]: storageData
            },
            StopOnFirstIncompleteConfiguration: true
        };

        setTemplateStatus(StatusType.INFO);
        setShowTemplateStatus(true);

        checkARMTemplateStatus(request).then((res: ICheckARMTemplateStatusResponse | undefined) => {
            if (res && res[subscriptionId]?.length) {
                const incompleteStatuses: ICheckARMTemplateStatus[] = res[subscriptionId].filter(status => !status.isCompleted);

                if (incompleteStatuses.length === 0) {
                    setTemplateStatus(StatusType.SUCCESS);
                    dispatch(setNextButtonDisabled(false));
                } else {
                    setTemplateStatus(StatusType.ERROR);
                    dispatch(setNextButtonDisabled(true));
                }
            }
        })
            .catch(() => {
                setTemplateStatus(StatusType.ERROR);
                dispatch(setNextButtonDisabled(true));
            });
    };

    const getStatusText: string = useMemo(() => getStatusesMap()[templateStatus], [templateStatus]);
    const isLoading: boolean = useMemo(() => templateStatus === StatusType.INFO && showTemplateStatus, [templateStatus, showTemplateStatus]);

    const LinkText = (props: { children?: React.ReactElement }) => {
        return (
            <Link externalUrl={customARMTemplateLink}>
                <Typography context='info' saturation>
                    {props.children}
                </Typography>
            </Link>
        );
    };

    return (
        <AzureOnboardingStep>
            <AzureOnboardingStepTitle>{t('STEPS.ARM_TEMPLATE')}</AzureOnboardingStepTitle>

            <AzureConfigurationPanel direction='row' padding={6} margin={[5, 0]} alignItems='flex-start'>
                <Icon name="azure" size={32}/>
                <Stack padding={[2, 0, 0 , 5]} spacing={8}>
                    <Typography type='key' variant='xl'>{t('ARM_TEMPLATE.TITLE')}</Typography>
                    <ol>
                        <li>{t('ARM_TEMPLATE.STEPS.1')}</li>
                        <li><Trans i18nKey={t('ARM_TEMPLATE.STEPS.2')}
                            components={{
                                link1: <LinkText />
                            }}/>
                        </li>
                        <li><Trans>{t('ARM_TEMPLATE.STEPS.3')}</Trans></li>
                        <li>
                            <Trans>{t('ARM_TEMPLATE.STEPS.4')}</Trans>
                            <Stack direction='column' spacing={4} margin={[1, 0, 0, 0]}>
                                <Typography elementType='span'>{t('ARM_TEMPLATE.STEPS.5')}</Typography>
                                <Button 
                                    loading={isLoading} 
                                    onClick={checkStatus} 
                                    variant='outlined' 
                                    color='brandPrimary'
                                    disabled={isLoading}
                                >
                                    {t('BUTTONS.CHECK_NOW')}
                                </Button>
                            </Stack>
                        </li>
                    </ol>
                </Stack>
            </AzureConfigurationPanel>

            {showTemplateStatus && <StatusMessagePanel type={templateStatus} text={getStatusText}/>}
        </AzureOnboardingStep>
    );
};

export default ARMTemplate;
