import { getHttpService } from 'common/interface/services';
import { InsightResponse } from '../Insights.intertface';

const INSIGHTS_PATH = 'content/ai/insights';

export async function getInsights(asstSrl: string): Promise<InsightResponse[]> {
    const params = `srl=${asstSrl}`;
    const serviceConfig = {
        cachingConfig: { useCache: true },
        path: `${INSIGHTS_PATH}?${params}`
    };

    return await getHttpService().get<InsightResponse[]>(serviceConfig);
}