import styled from 'styled-components/macro';
import { Stack } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TrendBoxesStyled = {
    TopDiv
};
