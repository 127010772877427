import React, { useEffect } from 'react';
import { Vendors } from 'common/consts/vendors';
import { useTranslation } from 'react-i18next';
import { getCloudAccountsService } from 'common/interface/data_services';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { TextIconItem } from 'common/components/policy/MultiSelectList/ListItemTypes/TextIconItem';
import { MultiSelectListComponent } from 'common/components/policy/MultiSelectList';
import { resolveEnvironmentDisplayName } from './utils';
import { i18nIntelligenceNamespace } from '../initialize.i18n';


export interface PlatformEnvironmentsSelectionProps{
    onValidate:Function;
    platform?: Vendors;
    selectedEnvironmentsChanged:Function;
    preSelectedItems?: ListItem[];
}
export const IntelligenceEnvironmentsSelectionComponent: React.FC<PlatformEnvironmentsSelectionProps> = ({ preSelectedItems, onValidate,platform,selectedEnvironmentsChanged }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const[environments, setEnvironments] = React.useState<ListItem[]|null>(null);

    const init = async () => {

        const allEnvironments = await getCloudAccountsService().getAllCloudAccounts();
        const _platform = platform === Vendors.GCP ? Vendors.GOOGLE : platform;
        const matchedEnvironments = allEnvironments.filter((environment) => (environment.isLogicTrafficActivityOnboarded || environment.isLogicEventActivityOnboarded) && (environment.platform === _platform));
        const items : ListItem[] = matchedEnvironments?.map((environment) => {
            return {
                id: environment.id,
                externalId: environment.externalId,
                name: resolveEnvironmentDisplayName(environment),
                icon: (() => {
                    switch (platform) {
                        case Vendors.CONTAINER_REGISTRY:
                            return 'containers-registry';
                        case Vendors.IMAGE_ASSURANCE:
                            return 'imageProtection';
                        default:
                            return platform;
                    }
                })()
            };
        }) || [];
        setEnvironments(items);
    };
    useEffect(() => {
        init();
    }, []);


    const selectedItemsChanged = (selectedItems: ListItem[]) => {
        onValidate(selectedItems?.length);
        selectedEnvironmentsChanged(selectedItems);
    };


    return (
        <span data-aid={'environments-selection'}>
            <MultiSelectListComponent preSelectedItems={preSelectedItems} itemTemplate={TextIconItem}
                header={t('POLICY.SELECT_ENVIRONMENTS')} items={environments} onSelectedItemsChanged={selectedItemsChanged}/>
        </span>
    );

};