import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { EventsTableTab } from 'common/interface/events';
import { EventsElasticDatasource } from '../DataSource/EventsElasticDatasource';
import { FindingsDataSource, IFindingModelAlertTypeCode, IFindingModelOriginCode } from 'common/components/Findings/Findings.interface';
import i18n from 'common/services/translations/translations';
import { getEventsTableNamespace } from '../initialize.i18n';
import { Addin } from 'common/extensibility/AddinRegistry';

const tabsDefs: () => Addin<EventsTableTab>[] = () => {

    const elasticBase: EventsTableTab = {
        id: '',
        label: '',
        type: 'datasourceTab',
        actions: [
            'exclude',
            'acknowledge',
            'remediate',
            'comment',
            'close',
            'archive',
            'reportIssue',
            'changeSeverity',
            'assign',
        ],
        archiveActions: [
            'close',
            'unarchive',
        ],
        columns: [
            { id: 'checkbox' },
            { id: 'ruleName' },
            { id: 'severity' },
            { id: 'entityName' },
            { id: 'createdTime' },
            { id: 'cloudAccountId' },
            { id: 'cloudAccountType' },
            { id: 'origin' },
            { id: 'remediation' },
            { id: 'bundleName' },
            { id: 'description' },
            { id: 'entityType' },
            { id: 'ownerUserName' },
            { id: 'category' },
            { id: 'action' },
        ],
        filters: [
            'organizational unit',
            'severity',
            'cloudAccountType',
            'cloudAccountId',
            'showExcluded',
            'showAcknowledged',
            'entityType',
            'origin',
            'action',
            'entityExternalId',
            'region',
            'category',
            'ownerUserName',
            'bundleName',
            'alertType',
            'date picker'
        ],
        exportOptions: [
            'export-download',
            'export-email'
        ],
        createDataSource: (archiveView) => new EventsElasticDatasource({
            id: 'all',
            findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
        })
    };

    const tabsArr: Addin<EventsTableTab>[] = [
        {
            id: 'all',
            position: 0,
            content: {
                ...elasticBase,
                id: 'all',
                label: i18n.t('TABLE_TABS.ALL', { ns: getEventsTableNamespace('table') }),
            }
        },
        {
            id: 'postureFindings',
            position: 10,
            content: {
                ...elasticBase,
                id: 'postureFindings',
                label: i18n.t('TABLE_TABS.POSTURE_FINDINGS', { ns: getEventsTableNamespace('table') }),
                createDataSource: (archiveView) => new EventsElasticDatasource({
                    id: 'postureFindings',
                    findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
                    defaultFilters: {
                        fields: [
                            { name: 'origin', value: IFindingModelOriginCode.ComplianceEngine },
                            { name: 'origin', value: IFindingModelOriginCode.Serverless },
                            { name: 'alertType', value: IFindingModelAlertTypeCode.Task },
                        ]
                    },
                })
            },
        },
        {
            id: 'ciem',
            position: 10,
            content: {
                ...elasticBase,
                id: 'ciem',
                label: i18n.t('TABLE_TABS.CIEM', { ns: getEventsTableNamespace('table') }),
                createDataSource: (archiveView) => new EventsElasticDatasource({
                    id: 'ciem',
                    findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
                    defaultFilters: {
                        fields: [
                            { name: 'origin', value: IFindingModelOriginCode.CIEM }
                        ]
                    },
                })
            },
        },
        // CDR here with position 20 (Pushed from Intelligence module)
        {
            id: 'threatAndSecurity',
            position: 30,
            content: {
                ...elasticBase,
                actions: [
                    ...elasticBase.actions,
                    'addDenyRule'
                ],
                id: 'threatAndSecurity',
                label: i18n.t('TABLE_TABS.THREAT_AND_SECURITY', { ns: getEventsTableNamespace('table') }),
                createDataSource: (archiveView) => new EventsElasticDatasource({
                    id: 'threatAndSecurity',
                    findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
                    defaultFilters: {
                        fields: [
                            { name: 'origin', value: IFindingModelOriginCode.ServerlessSecurityAnalyzer },
                            { name: 'origin', value: IFindingModelOriginCode.KubernetesRuntimeAssurance },
                            { name: 'origin', value: IFindingModelOriginCode.ContainersRuntimeProtection },
                            { name: 'origin', value: IFindingModelOriginCode.Serverless },
                            { name: 'alertType', value: IFindingModelAlertTypeCode.SecurityEvent },
                        ]
                    }
                })
            },
        },
        {
            id: 'vulnerabilities',
            position: 40,
            content: {
                ...elasticBase,
                filters: [
                    ...elasticBase.filters,
                    'showFixable'
                ],
                id: 'vulnerabilities',
                label: i18n.t('TABLE_TABS.VULNERABILITIES', { ns: getEventsTableNamespace('table') }),
                createDataSource: (archiveView) => new EventsElasticDatasource({
                    id: 'vulnerabilities',
                    findingsDataSource: archiveView ? FindingsDataSource.ARCHIVE : FindingsDataSource.FINDING,
                    defaultFilters: {
                        fields: [
                            { name: 'origin', value: IFindingModelOriginCode.KubernetesImageScanning },
                            { name: 'origin', value: IFindingModelOriginCode.ImageAssurance },
                        ]
                    }
                })
            },
        },
    ];

    return tabsArr;
};

export default function initializeTabsDefs() {
    EventsTableRegistry.addTabs(tabsDefs());
}