import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { ModalsProps } from '..';
import { Stack } from 'common/design-system/components-v2';
import { useGetGcp } from '../../../reducer/hooks/useGetGcp';
import { getGcpEnvNamespace } from '../../../initialize.i18n';
import { useUpdateCredentialsGcp } from '../../../reducer/hooks/useUpdateCredentialsGcp';
import ConfirmationModal from 'common/components/ConfirmationModal/ConfirmationModal';
import Input from 'common/design-system/components-v2/Input';

interface FormValues {
    name: string;
    serviceAccountCredentials: Object | null;
}

const EditGcpCredentialsModal: React.FC<ModalsProps> = ({ closeModal, gcpId }) => {
    const { data: gcpData } = useGetGcp(gcpId);
    const { t } = useTranslation(getGcpEnvNamespace('gcp'));

    const inputValidations: { [input: string]: RegisterOptions } = React.useMemo(() => ({
        name: {
            required: t('gcpPage.modals.editCredentials.fields.name.validation.required'),
        },
        serviceAccountCredentials: {
            required: t('gcpPage.modals.editCredentials.fields.serviceAccountCredentials.validation.required'),
        }
    }), [t]);

    const handleOnUpdateSuccess = () => {
        getNotificationsService().addNotification({
            type: NotificationType.SUCCESS,
            title: t('gcpPage.modals.edit.onSubmit.success'),
            text: '',
        });
        onClose();
    };
    const handleOnUpdateError = (error: { message: string }) => {
        getNotificationsService().addNotification({
            type: NotificationType.ERROR,
            title: t('gcpPage.modals.edit.onSubmit.error'),
            text: error.message,
        });
    };

    const { updateCredential, isLoading: isUpdateLoading } = useUpdateCredentialsGcp({ onError: handleOnUpdateError, onSuccess: handleOnUpdateSuccess });
    const isLoading = isUpdateLoading;

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm<FormValues>({
        defaultValues: {
            name: gcpData?.account.name,
            serviceAccountCredentials: null,
        }
    });
    
    const submitUpdate = async (data: FormValues) => {
        const { name, serviceAccountCredentials } = data;
        if(name && serviceAccountCredentials){
            updateCredential(gcpId, { name: name, serviceAccountCredentials: serviceAccountCredentials });
        }
    };

    const onClose = () => {
        closeModal();
    };

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function(e) {
                try{
                    const contents = e.target?.result;
                    if (typeof contents === 'string') {
                        const jsonContents = JSON.parse(contents);
                        setValue('serviceAccountCredentials', jsonContents);
                    }
                    errors['serviceAccountCredentials'] = undefined;
                }
                catch(err: any){
                    getNotificationsService().addNotification({
                        type: NotificationType.ERROR,
                        title: t('gcpPage.modals.editCredentials.fields.serviceAccountCredentials.validation.validate'),
                        text: err.message,
                    });
                    setValue('serviceAccountCredentials', null);
                    console.log('errors[\'serviceAccountCredentials\']', errors['serviceAccountCredentials']);
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <ConfirmationModal
            submitBtnText={t('gcpPage.modals.editCredentials.submit')}
            title={t('gcpPage.modals.editCredentials.header')}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={handleSubmit((data) => submitUpdate(data))}
            isLoading={isLoading}
            width='md'
        >
            <Stack spacing={2} margin={2}>
                <Controller
                    name='name'
                    rules={inputValidations['name']}
                    control={control}
                    render={({ field }) => (
                        <Stack>
                            <Input
                                label={t('gcpPage.modals.editCredentials.fields.name.label')}
                                readOnly={true}
                                {...field}
                                placeholder={t('gcpPage.modals.editCredentials.fields.key.placeholder')}
                            />
                        </Stack>
                    )}
                />
            </Stack>
            <Stack spacing={1} margin={2}>
                <Controller
                    name='serviceAccountCredentials'
                    rules={inputValidations['serviceAccountCredentials']}
                    control={control}
                    render={() => (
                        <Stack>
                            <Input
                                label={t('gcpPage.modals.editCredentials.fields.serviceAccountCredentials.label')}
                                type="file"
                                onChange={onFileChange}
                                helperText={errors['serviceAccountCredentials']? errors['serviceAccountCredentials']['message'] : ''}
                                isError={errors['serviceAccountCredentials'] ? true : false}
                            />
                        </Stack>
                    )}
                />
            </Stack>
        </ConfirmationModal>
    );
};

export default EditGcpCredentialsModal;
