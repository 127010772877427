import { EventsTableRegistry } from 'common/module_interface/events/EventsTableRegistry';
import { Addin } from 'common/extensibility/AddinRegistry';
import { ITableAction } from 'common/design-system/components-v2/Table/Table.types';
import { EventAction, IEvent } from 'common/module_interface/events/Events';
import i18n from 'common/services/translations/translations';
import { IFindingModelOriginString, IFindingModelStatus } from 'common/components/Findings/Findings.interface';
import { EventsDrawerAddingBase, EventsDrawerRegistry } from 'common/module_interface/events/EventsDrawerRegistry';
import { RemediationRegistry } from 'common/module_interface/remediation/RemediationRegistry';
import { IActionEnabler } from 'common/interface/exclusion';
import { hasPermissionToEventActions } from 'common/module_interface/events/utils';
import { changeUrlParam } from 'common/utils/http';

const REMEDIATE = 'remediate';
export type EventsTableTermTypes = 'table';
export const EventsTableNamespacePrefix = 'eventsTable';

export const getEventsTableNamespace = (term: EventsTableTermTypes) => `${EventsTableNamespacePrefix}_${term}`;

const canExecuteAction = (selectedRows: IEvent[]) : boolean => {
    if(!hasPermissionToEventActions()){
        return false;
    }
    const enablers = RemediationRegistry.getRemediateEventEnablers();
    if(selectedRows.length === 1) {
        const selectedRow = selectedRows[0];
        return enablers.some((action : IActionEnabler ) => action.enabler && action.enabler(selectedRow)) ||
            [IFindingModelOriginString.ComplianceEngine, IFindingModelOriginString.CIEM].includes(selectedRow.origin);
    }
    return false;
};
const actionsDefs = () => {
    const actions: Addin<ITableAction<IEvent>>[] = [
        {
            id: REMEDIATE,
            content: {
                id: REMEDIATE,
                name: i18n.t('ACTIONS.REMEDIATE.BUTTON', { ns: getEventsTableNamespace('table') }),
                position: 100,
                callback: () => changeUrlParam('modalType', REMEDIATE),
                buttonProps: (selectedRows) => ({
                    iconProps: { name: 'remedy' },
                    disabled: !canExecuteAction(selectedRows),
                    tooltip: selectedRows.length !== 1 ? 'Select only one event to enable this action' : undefined,
                }),
            }
        },
    ];
    return actions;
};

const actionsMenuDefs = () => {
    const actions: Addin<EventsDrawerAddingBase<EventAction>>[] = [
        {
            id: REMEDIATE,
            content: {
                id: REMEDIATE,
                isRelevant: (event: IEvent) => event.status !== IFindingModelStatus.Archived && canExecuteAction([event]),
                getValue: () => ({
                    label: i18n.t('ACTIONS.REMEDIATE.BUTTON', { ns: getEventsTableNamespace('table') }),
                    icon: 'remedy',
                    callback: () => changeUrlParam('modalType', REMEDIATE),
                }),
            },
        },
    ];
    return actions;
};


export function initializeEventsActions() {
    EventsTableRegistry.addActions(actionsDefs());
    EventsDrawerRegistry.addActions(actionsMenuDefs());
}
