// ??????
import React, { useEffect } from 'react';
import { Button, Tooltip } from '@dome9/berries/react-components';
import { NET_EXPOSURE } from '../Constants';
import { SelectedItemType } from '../Models/SelectedItem';
import { DetailsProps } from '../Models/DetailsProps';
import { getHttpService } from 'common/interface/services';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { ProtectedAssetsResponse } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { A } from 'common/components/Anchor/Anchor';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';

export const DetailsComponent: React.FC<DetailsProps> = (props: DetailsProps) => {

    const [url,setUrl] = React.useState<string>('');
    useEffect(() => {
        if(props.selectedItem === null){
            return;
        }
        const srl = props.selectedItem?.properties?.Srl;
        getHttpService().post<ProtectedAssetsResponse>(
            ASSETS_SEARCH_URL,
            {
                data: {
                    pageSize: 1,
                    filter: {
                        fields: [{
                            Name: 'srl',
                            Value: srl
                        }]
                    }
                }
            }).then((data : ProtectedAssetsResponse) => {
            const asset = data.assets[0];
            const url = getProtectedAssetsService().getProtectedAssetUrl(asset);
            if(url !== null) {
                setUrl(url);
            } else {
                setUrl('');
            }
        });
    },[props.selectedItem]);

    return (
        <>
            <div className={'details'} >
                {props.selectedItem && props.disabledView &&
                    <div>
                        <A href={url}><div className={'entity-title'}>Entity: {String(props.selectedItem.label)}</div></A>
                        <div className={'entity-title-container'}>
                            <span className={'type-title'}>Type: {String(props.selectedItem.type)}</span>
                            {props.selectedItem.itemType === SelectedItemType.NODE &&
                                <Tooltip
                                    content='Removing this entity from the graph display'
                                    aria-label='add'>
                                    <Button onClick={() => props.selectedItem && props.removeEntityFromGraphCallback(props.selectedItem.graphId)}>
                                        Hide
                                    </Button>
                                </Tooltip>
                            }
                        </div>

                        <div>
                            <div>
                                <div aria-label='simple table'>
                                    {props.selectedItem.updateTimestamp &&
                                        <div key={'updateTime'}>
                                            <div>Update Time: {String(props.selectedItem.updateTimestamp)}</div>
                                        </div>}
                                    {props.selectedItem.networkExposure &&
                                        <div className={'exposure-level'} key={'networkExposure'} style={{
                                            fontWeight: 700,
                                            color: props.selectedItem.networkExposure === NET_EXPOSURE.INTERNET ?
                                                '#dc3545' :
                                                (props.selectedItem.networkExposure === NET_EXPOSURE.PARTIAL ? '#ffb007' : '#28a745'),
                                        }}>
                                            <div>Network Exposure: {String(props.selectedItem.networkExposure)}</div>
                                        </div>}
                                    {props.selectedItem.exposurePath &&
                                        <div key={'exposureBy'}>
                                            <div>Exposed by: {String(props.selectedItem.exposurePath)}</div>
                                        </div>}
                                </div>
                                {props.selectedItem.properties &&
                                    <textarea value={ JSON.stringify(props.selectedItem.properties,null,4) } readOnly wrap={'off'}/>}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};