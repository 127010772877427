import { isChina } from 'common/utils/dataCenter';
import {
    AzureOnboardingAccountType, AzureOnboardingOperationMode,
    AzureOnboardingScope,
    AzureOnboardingType,
    IInstructionTypeCardContent,
    IInstructionsTextList,
    AzureOnboardingAWPScanType, CDRAssetType, ICollapseList,
    LogTypesInstructionsType,
} from './azure.interface';
import { getStoreService } from 'common/interface/services';
import { Trans } from 'react-i18next';
import { TFunction, t } from 'i18next';
import { ALink, ShellUrl } from './azure.styled';
import { IDataCenter } from 'common/consts/data.centers';
import { CpCopyToClipboard } from '@dome9/components/react/components';
import { Stack, Tooltip, Icon } from 'common/design-system/components-v2';
import { azureManualOnboardingAssets } from '../../assets/azureOnboardingAssets';
import { InputTypeConfig } from 'common/design-system/components-v2/GroupSelection/GroupSelection.types';
export const azureOnboardingRadioBtns: (t: TFunction) => InputTypeConfig[] = (t) => ([
    {
        label: t('ON_BOARDING.WELCOME.ORGANIZATION'),
        value: AzureOnboardingType.org,
        name: 'onboardingType',
        dataAid: 'azure-onboarding-org-radio',
        isHidden: isChina(),
    },
    {
        label: t('ON_BOARDING.WELCOME.SINGLE_ACCOUNT'),
        value: AzureOnboardingType.single,
        name: 'onboardingType',
        dataAid: 'azure-onboarding-single-radio',
    }
]);
export const azureOnboardingAccountTypeRadioBtns = (t: TFunction): InputTypeConfig[] => {
    const { state } = getStoreService().getReduxTools();
    return [
        {
            label: t('ON_BOARDING.AZURE.STANDARD'),
            value: AzureOnboardingAccountType.standard,
            name: 'accountType',
            dataAid: 'azure-onboarding-radio-standard',
            isHidden: isChina(),
        },
        {
            label: t('ON_BOARDING.AZURE.GOV_CLOUD'),
            value: AzureOnboardingAccountType.gov,
            name: 'accountType',
            dataAid: 'azure-onboarding-radio-gov',
            isHidden: isChina() || (!isChina() && state.azureOnboarding.azureOnboardingType === AzureOnboardingType.org),
        },
        {
            label: t('ON_BOARDING.AZURE.CHINA_CLOUD'),
            value: AzureOnboardingAccountType.china,
            name: 'accountType',
            dataAid: 'single-onboarding-radio-china',
            isHidden: state.azureOnboarding.azureOnboardingType === AzureOnboardingType.org,
        },
    ];
};
export const azureOnboardingOrgRadioBtns: (t: TFunction) => InputTypeConfig[] = (t) => ([
    {
        label: t('ON_BOARDING.AZURE.TENANT'),
        value: AzureOnboardingScope.tenant,
        name: 'onboardingScope',
        dataAid: 'org-onboarding-radio-tenant',
    },
    {
        label: t('ON_BOARDING.AZURE.MANAGEMENT_GROUP'),
        value: AzureOnboardingScope.managementGroup,
        name: 'onboardingScope',
        dataAid: 'org-onboarding-radio-managementGroup',
    },
    {
        label: t('ON_BOARDING.AZURE.SUBSCRIPTION'),
        value: AzureOnboardingScope.subscription,
        name: 'onboardingScope',
        dataAid: 'org-onboarding-radio-subscription',
        isHidden: true,
    },
]);
export const azureSingleOperationsCards = (): IInstructionTypeCardContent[] => [
    {
        title: t('ON_BOARDING.AZURE.OPERATION_TEXT.READ_ONLY_TITLE'),
        subtitle: 'ON_BOARDING.AZURE.OPERATION_TEXT.READ_ONLY_SUBTITLE',
        content: [
            t('ON_BOARDING.AZURE.OPERATION_TEXT.READ_ONLY_CONTENT'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.READ_ONLY_CONTENT_2')
        ],
        list: [
            t('ON_BOARDING.AZURE.OPERATION_TEXT.CLOUDGUARD_CLARITY_FOR_VISUALIZATION_OF_NETWORK_SECURITY'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.CHANGE_NOTIFICATIONS'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.AUDIT_TRAIL'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.COMPLIANCE_REPORTS'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.POLICY_REPORTS'),
        ],
        className: 'left',
        value: AzureOnboardingOperationMode.read,
        dataAid: 'azure-single-read-onboarding',
    },
    {
        title: t('ON_BOARDING.AZURE.OPERATION_TEXT.MANAGED_TITLE'),
        subtitle: 'ON_BOARDING.AZURE.OPERATION_TEXT.MANAGED_SUBTITLE',
        content: [
            t('ON_BOARDING.AZURE.OPERATION_TEXT.MANAGED_CONTENT'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.MANAGED_CONTENT_2')
        ],
        list: [
            t('ON_BOARDING.AZURE.OPERATION_TEXT.SECURITY_GROUP_MANAGEMENT'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.CLOUDGUARD_CLARITY_FOR_VISUALIZATION_OF_NETWORK_SECURITY'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.CHANGE_NOTIFICATIONS'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.AUDIT_TRAIL'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.COMPLIANCE_REPORTS'),
            t('ON_BOARDING.AZURE.OPERATION_TEXT.POLICY_REPORTS'),
        ],
        className: 'left',
        value: AzureOnboardingOperationMode.manage,
        dataAid: 'azure-single-manage-onboarding',
    },
];
export const listComponent = (listArray: IInstructionsTextList[], className: string) => {
    return (
        <ul className={className}>
            { listArray.map((obj: IInstructionsTextList) => {
                if(obj.render) return obj.render();
                return (
                    <li key={obj.label} className={'mt-6'}>
                        {obj.label && (
                            <div style={{ display: 'block' }}>
                                <Trans i18nKey={obj.label}/>
                                <div style={{ display: 'inline-block', paddingLeft: '5px' }}> { obj.tooltip && (
                                    <Tooltip className={'tooltip'} placement={'top'} content={obj.tooltip}>
                                        <Icon name='info' size={14} />
                                    </Tooltip>
                                )}</div>
                            </div>
                        )}
                        {obj?.textList?.length && (listComponent(obj.textList, obj.className || ''))}
                    </li>
                );
            })}
        </ul>
    );
};
export const azureSinglePrepareInstructions = (cloudShellUrl: string, dataCenter: IDataCenter | undefined) => {
    const prepareInstructionList = [
        {
            label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_1',
            render: () => (
                <li key="LINE_1" className='mt-6'>
                    <Trans i18nKey="ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_1"/>
                    <ShellUrl
                        className={'text-[#3594ff]'}
                        href={cloudShellUrl}
                        target='_blank'
                        rel='noreferrer'
                        data-aid='azure-portal-url'
                    ><Trans i18nKey="ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_1_B"/>
                    </ShellUrl>
                </li>
            )
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_2' },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_3',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.menuAppRegistration} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_4',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.appRegistrationAdd} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_5',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.appRegistrationAppInfo} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_6',
            className: 'list-disc ml-7',
            textList: [
                { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_6_A',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.redirectUri} width='500' />,
                },
                {
                    label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_6_B',
                    render: () => (
                        <li key="LINE_1" className='mt-6'>
                            <Stack direction="column">
                                <Trans i18nKey="ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_6_B"/>
                                <strong>{`${dataCenter?.label || 'None'} ${dataCenter?.dataCenter ? `- (${dataCenter?.dataCenter})` : ''}`}</strong>
                            </Stack>
                        </li>
                    )
                },
                {
                    label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_6_C',
                    render: () => (
                        <li key="LINE_1" className='mt-6' style={{ display: (dataCenter ? 'inherit' : 'none') }}>
                            <Stack direction="column">
                                <div style={{ display: 'block' }}>
                                    <Trans i18nKey="ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_6_C"/>
                                    <div style={{ display: 'inline-block', paddingLeft: '5px' }}>
                                        <Tooltip className={'tooltip'} placement={'top'} content={<img alt="image" src={azureManualOnboardingAssets.urlCloudguardGenerate} width='500' />}>
                                            <Icon name='info' size={14} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Stack direction="row" alignItems="center">
                                    <strong>{dataCenter?.externalApi || '-'}</strong>
                                    <CpCopyToClipboard text={dataCenter?.externalApi || '-'} />
                                </Stack>
                            </Stack>
                        </li>
                    )
                },
                { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_6_D' },
            ]
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_7',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.registerBtn} width='500' />
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_8',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.dome9ConnectAppId} width='500' />
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_9',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.certificatesSecrets} width='300' />
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_10',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.clientSecret} width='300' />
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_11',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.descriptionExpirationPeriod} width='300' />,
            className: 'list-disc ml-7',
            textList: [
                { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_11_A' },
                { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_11_B' },
                { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_11_C' },
            ]
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_12',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.revealedKey} width='300' />,
        },
        { label: 'ON_BOARDING.AZURE.PREPARE_TEXTS.LINE_13' },
    ];
    return (
        <div data-aid="azure-onboarding-prepare-wrapper">
            {listComponent(prepareInstructionList, 'list-decimal ml-8')}
        </div>
    );
};
export const azureSingleConnectManageInstructions = (azureOperationMode: string) => {
    const additionalPermissionList = [
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_A' },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_B' },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_C' },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_D' },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_E',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.addButton} width='400' />, },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_F',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.addCustomRole} width='300' />, },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_G',
            className: 'list-disc ml-7',
            textList: [
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_G_1',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.basicTab} width='400' />, },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_G_2' },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_G_3' },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_G_4' },
            ]
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_H',
            className: 'list-disc ml-7',
            textList: [
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_H_1',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.addPermissions2} width='500' /> },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_H_2',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.microConfigActions} width='500' /> },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_H_3' },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_H_4',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.microConfigActions2} width='500' /> },
            ]
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_I' },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14_J' },
    ];
    const apiPermissionsList = [
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_1',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.apiPermissions1} width='400' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_2',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.appRegistration} width='300' />, },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_3',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.apiPermissions2} width='300' />, },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_4',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.addPermissionButton} width='300' />, },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_5',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.microsoftApis} width='400' />, },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_6',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.appPermissions} width='400' />, },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_7',
            textList: [
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_7_A',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.directoryReadAll} width='400' />, },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_7_B',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.reportsReadAll} width='400' />, },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_7_C',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.policyReadAll} width='400' />, },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_7_D',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.accessReviewReadAll} width='400' />, },
                { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13_7_E',
                    tooltip: <img alt="image" src={azureManualOnboardingAssets.addPermissions} width='400' />, },
            ] },
    ];
    const instructionsReadOnly = [
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_13',
            className: 'list-disc ml-7',
            textList: apiPermissionsList
        },
    ];
    const instructionsManage = [
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.MANAGE.LINE_13',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.reviewAssign} width='500' />
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.MANAGE.LINE_14',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.addAgain} width='500' /> },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.MANAGE.LINE_15',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.addRoleAssignmentA1} width='500' /> },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.MANAGE.LINE_16',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.networkContributor} width='500' /> },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.MANAGE.LINE_17',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.previouslyCreatedAppA1} width='500' /> },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.MANAGE.LINE_18',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.previouslyCreatedApp} width='300' /> },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.MANAGE.LINE_19',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.reviewAssign} width='300' /> },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.MANAGE.LINE_20',
            className: 'list-disc ml-7',
            textList: apiPermissionsList
        },
    ];
    const instructionsByOperation = azureOperationMode === AzureOnboardingOperationMode.manage ? [...instructionsManage] : [...instructionsReadOnly];
    const instructionsArray = [
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_1',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.menuActiveDirectory} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_2',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.activeDirectoryPropertiesTab} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_3',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.activeDirectoryCopyId} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_4',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.menuSubscription} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_5' },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_6',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.subscriptionId} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_7',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.subscriptionUsers} width='200' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_8',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.usersAddAccess} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_9',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.addRoleAssignment1} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_10',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.selectRoleRender} width='500' />,
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_11' },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_12',
            tooltip: <img alt="image" src={azureManualOnboardingAssets.previouslyCreatedApp} width='300' />,
        },
        ...instructionsByOperation,
        {
            label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14',
            render: () => (
                <li key="LINE_14" className='mt-6'>
                    <div className='pointer'>
                        <Trans i18nKey="ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_14"/>
                    </div>
                    {listComponent(additionalPermissionList, 'ml-4 list-disc')}
                </li>
            )
        },
        { label: 'ON_BOARDING.AZURE.CONNECT_TEXTS.LINE_15' },
    ];
    return (
        <div data-aid="azure-onboarding-connect-wrapper">
            {listComponent(instructionsArray, 'list-decimal ml-8')}
        </div>
    );
};
export const azureAwpConfigurationsRadioBtns : InputTypeConfig[] = [
    {
        label: t('ON_BOARDING.AZURE.AWP_CENTRALIZED'),
        value: AzureOnboardingAWPScanType.inAccountHub,
        name: 'awpScanMode',
        dataAid: 'azure-awp-mode-in-account-sub-radio',
        labelProps: {
            tooltip: t('ON_BOARDING.AZURE.AWP_CENTRALIZED_TOOLTIP'),
            tooltipIconSize: 12,
        }
    },
    {
        label: t('ON_BOARDING.AZURE.AWP_IN_ACCOUNT'),
        value: AzureOnboardingAWPScanType.inAccount,
        name: 'awpScanMode',
        dataAid: 'azure-awp-mode-in-account-radio',
        labelProps: {
            tooltip: t('ON_BOARDING.AZURE.AWP_IN_ACCOUNT_TOOLTIP'),
            tooltipIconSize: 12,
        }
    },
    {
        label: t('ON_BOARDING.AZURE.AWP_SAAS'),
        value: AzureOnboardingAWPScanType.saas,
        name: 'awpScanMode',
        dataAid: 'azure-awp-mode-saas-radio',
        labelProps: {
            tooltip: t('ON_BOARDING.AZURE.AWP_SAAS_TOOLTIP'),
            tooltipIconSize: 12,
        }
    }
];
export const AzureStorageAnalyticsLink = 'https://docs.microsoft.com/en-us/azure/storage/common/storage-analytics-logging?tabs=dotnet';
export const logTypesInstructions = (): LogTypesInstructionsType => {
    const activityLogsInstruction: ICollapseList = {
        collapseTitle: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ACTIVITY_LOGS.ENABLE_ACTIVITY_LOGS',
        label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ACTIVITY_LOGS.TITLE',
        className: 'list-disc ml-9',
        textList: [
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ACTIVITY_LOGS.1' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ACTIVITY_LOGS.2' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ACTIVITY_LOGS.3' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ACTIVITY_LOGS.4', tooltip: t('ON_BOARDING.AZURE.CDR_TEXT.CREATE_ACTIVITY_LOGS.TOOLTIP') },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ACTIVITY_LOGS.5' }
        ]
    };

    const auditLogsInstruction: ICollapseList = {
        collapseTitle: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.ENABLE_AUDIT_LOGS',
        label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.AUDIT',
        className: 'list-disc ml-9',
        textList: [
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.1' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.2' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.3' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.4' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.5_AUDIT_LOGS' }
        ]
    };
    const signInsInstruction: ICollapseList = {
        collapseTitle: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.ENABLE_SIGN_IN_LOGS',
        label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.SIGN_IN',
        className: 'list-disc ml-9',
        textList: [
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.1' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.2' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.3' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.4' },
            { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_AUDIT_SIGN_IN_LOGS.5_SIGN_IN_LOGS' }
        ]
    };

    const storageAccountsInstruction: ICollapseList = {
        collapseTitle: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ANALYTICS_LOGS.ENABLE_ANALYTICS_LOGS',
        label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ANALYTICS_LOGS.TITLE',
        render: () => (
            <li key="LINE_14" className='mt-6'>
                <div style={{ display: 'block' }}><Trans i18nKey="ON_BOARDING.AZURE.CDR_TEXT.CREATE_ANALYTICS_LOGS.TITLE"/></div>
                {listComponent([
                    { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ANALYTICS_LOGS.1' },
                    { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ANALYTICS_LOGS.2' },
                    { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ANALYTICS_LOGS.3' },
                    { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ANALYTICS_LOGS.4' },
                    { label: 'ON_BOARDING.AZURE.CDR_TEXT.CREATE_ANALYTICS_LOGS.5' }
                ], 'list-disc ml-9')}
                <Stack direction="row" margin={[2,0,0,0]}>
                    <ALink href={AzureStorageAnalyticsLink} target='_blank' rel='noreferrer'>
                        <Trans i18nKey="ON_BOARDING.AZURE.CDR_TEXT.CREATE_ANALYTICS_LOGS.READ_MORE"/>
                    </ALink>
                </Stack>
            </li>
        )
    };

    return {
        [CDRAssetType.activityLogs]: activityLogsInstruction,
        [CDRAssetType.auditLogs]: auditLogsInstruction,
        [CDRAssetType.signIns]: signInsInstruction,
        [CDRAssetType.storageAccounts]: storageAccountsInstruction,
    };
};
