import {
    ASSET_FIELD_NAMES,
    CONTEXT_FIELD_FULL_NAMES, ERM_CONSTS,
} from '../../../../../consts';
import { fetchErmAggregations } from '../../../../../RiskManagement.utils';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IFieldInfo, IValueCount } from 'common/interface/general';
import {
    IDataAssetCategoryInfo,
    IDataAssetCategoryCounter,
    getAllSortedCategories,
} from '../../../../../DataAssetCategories';
import {
    getAllSortedClassifications,
    IClassificationCountersByCategories,
    IDataClassificationInfo
} from '../../../../../dataClassification';

interface ICategoryClassificationsAggregations {
    categoryInfo: IDataAssetCategoryInfo;
    classificationCounters: IValueCount[];
}

export const getDataClassificationFilterFields = (classificationInfo?: IDataClassificationInfo, categoryInfo?: IDataAssetCategoryInfo, filterValues?: IFiltersValues): IFieldInfo[] => {
    let fields: IFieldInfo[] = filterValues?.fields || [];
    if (categoryInfo) {
        fields = fields.filter((field: IFieldInfo) => {
            return (field.name !== ASSET_FIELD_NAMES.type) || (categoryInfo.assetTypes.includes(field.value));
        });
    }
    fields = fields.filter((field: IFieldInfo) => {
        return (field.name !== CONTEXT_FIELD_FULL_NAMES.dataClassifications);
    });
    if (classificationInfo) {
        fields.push({
            name: CONTEXT_FIELD_FULL_NAMES.dataClassifications,
            value: classificationInfo.name,
        });
    } else {
        getAllSortedClassifications().forEach(info => {
            fields.push({
                name: CONTEXT_FIELD_FULL_NAMES.dataClassifications,
                value: info.name,
            });
        });
    }
    return [
        ...ERM_CONSTS.DATA_SENSITIVITY_FIELDS,
        ...fields,
    ];
};

export const fetchClassificationsAggregationByCategory = async (categoryInfo: IDataAssetCategoryInfo, filterValues?: IFiltersValues): Promise<ICategoryClassificationsAggregations> => {
    const fields: IFieldInfo[] = getDataClassificationFilterFields(undefined, categoryInfo, filterValues);
    if (!fields.some(field => (field.name === ASSET_FIELD_NAMES.type))) {
        return {
            categoryInfo,
            classificationCounters: [],
        };
    }
    return fetchErmAggregations(
        [CONTEXT_FIELD_FULL_NAMES.dataClassifications],
        {
            fields: getDataClassificationFilterFields(undefined, categoryInfo, filterValues),
        }
    ).then((aggregations: Aggregations) => {
        return {
            categoryInfo,
            classificationCounters: aggregations[CONTEXT_FIELD_FULL_NAMES.dataClassifications] || [],
        };
    });
};

export const fetchClassificationsAggregationByCategories = async (filterValues?: IFiltersValues): Promise<IClassificationCountersByCategories[]> => {
    const categoryInfos: IDataAssetCategoryInfo[] = getAllSortedCategories();
    const classificationInfos: IDataClassificationInfo[] = getAllSortedClassifications();
    const promises = categoryInfos.map(categoryInfo => fetchClassificationsAggregationByCategory(categoryInfo, filterValues));
    const categoryClassificationsCounters: ICategoryClassificationsAggregations[] = await Promise.all(promises);

    return classificationInfos.map(classificationInfo => {
        const categoryCounters: IDataAssetCategoryCounter[] = categoryClassificationsCounters.map(categoryCounter => {
            return {
                categoryInfo: categoryCounter.categoryInfo,
                count: categoryCounter.classificationCounters.find(counter => counter.value === classificationInfo.name)?.count ?? 0,
            };
        });
        return {
            classificationInfo,
            categoryCounters,
        };
    });
};
