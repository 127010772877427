import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Modal, Stack, TextArea, Typography } from 'common/design-system/components-v2';
import i18n from 'common/services/translations/translations';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'common/components/ConfirmationModal';
import { IFieldError } from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { IAcknowledgeModalEditableProps, IAcknowledgeModalProps, } from './AcknowledgeModal.types';
import { ACKNOWLEDGE_TEXT_MAX_LENGTH, FormFields } from './AcknowledgeModal.const';
import { AcknowledgeModalStyles } from './AcknowledgeModal.styled';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { getLoggerService, getNotificationsService } from 'common/interface/services';
import { NotificationType } from 'common/interface/notifications';
import { getIntelligenceService } from 'common/module_interface/intelligence/intelligence';
import {
    IAcknowledgeRequest,
    IFindingKeysByCloudAccountId
} from 'common/module_interface/intelligence/Intelligence.interface';
import { getFindingKeysByCloudAccountId } from '../../../Findings.utils';

export const AcknowledgeModal: React.FC<IAcknowledgeModalProps> = (props: IAcknowledgeModalProps) => {
    const { findings, closeModal, fieldErrorsProps } = props;
    const { addFieldErrors, getFieldErrorText, clearFieldErrors } = fieldErrorsProps;
    const { t } = useTranslation(i18nIntelligenceNamespace);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const acknowledgeTextRef: React.RefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>(null);

    const shouldAcknowledge: boolean = useMemo(() => findings.some((finding: IFinding) => !finding.isAcknowledged), [findings]);
    const termPrefix: string = shouldAcknowledge ? 'ACKNOWLEDGE' : 'UN_ACKNOWLEDGE';

    const acknowledgeTextFieldErrorText: string | undefined = useMemo(() => {
        return getFieldErrorText(FormFields.ACKNOWLEDGE_TEXT);
    }, [getFieldErrorText]);

    const getAcknowledgeText = useCallback((): string => {
        return acknowledgeTextRef.current?.value?.trim() ?? EMPTY_STRING;
    }, []);

    const updateAcknowledgeTextFieldError = (errors: IFieldError[]) => {
        const acknowledgeText: string = getAcknowledgeText();
        if (acknowledgeText.length > ACKNOWLEDGE_TEXT_MAX_LENGTH) {
            errors.push({
                field: FormFields.ACKNOWLEDGE_TEXT,
                label: t('ACKNOWLEDGE_MODAL.INPUT_FIELDS.ACKNOWLEDGE_TEXT.ERRORS.MAX_LENGTH')
            });
        }
    };

    const updateFieldsErrorState = (): boolean => {
        const errors: IFieldError[] = [];
        updateAcknowledgeTextFieldError(errors);
        addFieldErrors(errors);
        return errors.length > 0;
    };

    const onAcknowledgeTextChange = () => {
        clearFieldErrors([FormFields.ACKNOWLEDGE_TEXT]);
    };

    const onConfirmationModalCancel = () => {
        setIsConfirmationModalOpen(false);
    };

    const onDiscardChanges = () => {
        closeModal();
    };

    const onAcknowledgeModalCancel = () => {
        if (hasModalPropsChanged()) {
            return setIsConfirmationModalOpen(true);
        } else {
            closeModal();
        }
    };

    const prepareAcknowledgeRequest = (): IAcknowledgeRequest => {
        const findingsKeysByCloudAccountId: IFindingKeysByCloudAccountId[] = getFindingKeysByCloudAccountId(findings);
        const acknowledgeText: string = getAcknowledgeText();
        return { findings: findingsKeysByCloudAccountId, comment: acknowledgeText, acknowledge: shouldAcknowledge };
    };

    const onAcknowledge = async () => {
        const isAnyFieldError: boolean = updateFieldsErrorState();
        if (isAnyFieldError) return;

        const request: IAcknowledgeRequest = prepareAcknowledgeRequest();
        try {
            setIsLoading(true);
            await getIntelligenceService().acknowledgeFindings(request);
            getNotificationsService().addNotification({
                type: NotificationType.SUCCESS,
                text: t(`ACKNOWLEDGE_MODAL.${termPrefix}_SUCCESS`)
            });
            closeModal();
        } catch (message: unknown) {
            const errorTitle = t(`ACKNOWLEDGE_MODAL.${termPrefix}_FAILED`);
            getNotificationsService().addNotification({ type: NotificationType.ERROR, text: errorTitle });
            await getLoggerService().error(`${errorTitle} ${message as string}`);
        } finally {
            setIsLoading(false);
            props.refreshTableData();
        }
    };

    const prepareModalEditableProps = useCallback((): IAcknowledgeModalEditableProps => {
        return {
            acknowledgementText: getAcknowledgeText(),
        };
    }, [getAcknowledgeText]);
    const initialModalEditablePropsRef: React.MutableRefObject<IAcknowledgeModalEditableProps> = useRef<IAcknowledgeModalEditableProps>(prepareModalEditableProps());

    const hasModalPropsChanged = useCallback(() => {
        const currModalProps: IAcknowledgeModalEditableProps = prepareModalEditableProps();
        return (JSON.stringify(initialModalEditablePropsRef.current) !== JSON.stringify(currModalProps));
    }, [prepareModalEditableProps]);

    return (
        <Fragment>
            <Modal.ModalDialog onRequestClose={onAcknowledgeModalCancel} isOpen={true} width={'md'}>
                <Modal.ModalHeader
                    title={t(`ACKNOWLEDGE_MODAL.TITLE_${termPrefix}`)}
                    onClose={onAcknowledgeModalCancel}/>
                <Modal.ModalContent fullHeight={true}>
                    <AcknowledgeModalStyles.FormFieldsWrapper direction={'column'} spacing={6}>
                        <TextArea
                            key={'acknowledge-text-field'}
                            ref={acknowledgeTextRef}
                            required={true}
                            clearable={true}
                            fullWidth={true}
                            inputSize="large"
                            maxLength={ACKNOWLEDGE_TEXT_MAX_LENGTH}
                            rows={4}
                            onChange={onAcknowledgeTextChange}
                            isError={!!acknowledgeTextFieldErrorText}
                            helperText={acknowledgeTextFieldErrorText}
                            placeholder={t('ACKNOWLEDGE_MODAL.INPUT_FIELDS.ACKNOWLEDGE_TEXT.PLACEHOLDER')}
                        />
                    </AcknowledgeModalStyles.FormFieldsWrapper>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                        <Button key={'cancel-button-action'} variant='text'
                            onClick={onAcknowledgeModalCancel}>{i18n.t('COMMON.CANCEL')}</Button>
                        <Button key={'acknowledge-button-action'} color='brandPrimary' loading={isLoading}
                            disabled={isLoading}
                            onClick={onAcknowledge}>{t(`ACKNOWLEDGE_MODAL.ACTIONS.${termPrefix}`)}</Button>
                    </Stack>
                </Modal.ModalFooter>
            </Modal.ModalDialog>
            {isConfirmationModalOpen &&
                <ConfirmationModal width={'md'}
                    isOpen={isConfirmationModalOpen}
                    title={t('ACTIONS.CONFIRMATION_MODAL.TITLE')}
                    submitBtnText={i18n.t('COMMON.DISCARD')}
                    onConfirm={onDiscardChanges}
                    onClose={onConfirmationModalCancel}
                    onCancel={onConfirmationModalCancel}>
                    <Typography>{t('ACTIONS.CONFIRMATION_MODAL.DESCRIPTION')}</Typography>
                </ConfirmationModal>}
        </Fragment>
    );
};
