import { CpLoadingDots } from '@dome9/components/react/components';
import { Wizard, WizardStep } from 'common/components/Wizard/Wizard';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    getAzureOnboardingAccountType,
    getAzureOnboardingScope,
    getAzureOnboardingType,
    getIsLoading, getManualOnBoardingFlow,
    getSuccessText,
    setIsLoading,
} from './helpers/azure.reducer';
import {
    createAzureCloudAccount,
    exitWizard,
    getAllSubscriptionIdsFromServer,
    getOnboardingScriptFromServer,
    onboardAzureOrg,
} from './helpers/azure.service';
import {
    azureCreateAccountRequest,
    AzureOnboardingManagementMethod,
    AzureOnboardingScope,
    AzureOnboardingType,
    azureOrgOnboardingRequest
} from './helpers/azure.interface';
import { getStoreService } from 'common/interface/services';
import AzureSingleConnect from './azureSingle/AzureSingleConnect';
import AzureConfiguration from './AzureConfiguration';
import AzureOrgConnect from './azureOrg/AzureOrgConnect';
import SuccessModal from '../components/SuccessModal';
import ErrorModal from '../components/ErrorModal';
import { getCloudAccountsService } from 'common/interface/data_services';
import { configurationStep, welcomeStep, prepareStep, connectStep, welcomeStepManual } from './helpers/azure.steps';

const AzureOnboarding: React.FC<any> = () => {
    const isLoading = useSelector(getIsLoading);
    const { t } = useTranslation();
    const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [steps, setSteps] = useState<WizardStep[] | null>(null);
    const successText = useSelector(getSuccessText);
    const azureOnboardingType = useSelector(getAzureOnboardingType);
    const accountType = useSelector(getAzureOnboardingAccountType);
    const onboardingScope = useSelector(getAzureOnboardingScope);
    const isManualOnBoardingFlow = useSelector(getManualOnBoardingFlow);
    const message = t('HTTP.UNEXPECTED_ERROR');

    const onWizardClose = () => {
        exitWizard();
    };

    useEffect(() => {
        getAllSubscriptionIdsFromServer();
        getOnboardingScriptFromServer();
    }, []);

    useEffect(()=>{
        setRelevantSteps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [azureOnboardingType, accountType, onboardingScope, isManualOnBoardingFlow]);

    const getOnboardingScopeName = () => {
        switch (onboardingScope) {
            case AzureOnboardingScope.managementGroup:
                return 'ON_BOARDING.AZURE.CONNECT_MG_STEP';
            case AzureOnboardingScope.tenant:
                return 'ON_BOARDING.AZURE.CONNECT_TENANT_STEP';
            default:
                return 'ON_BOARDING.AZURE.CONNECT_SUBSCRIPTION_STEP';
        }
    };

    const azureManualSingleStepsFlow = (): WizardStep[] => {
        const steps = [
            welcomeStepManual,
            prepareStep,
            connectStep,
            {
                name: 'ON_BOARDING.AZURE.CONFIGURATION_STEP',
                renderView: (onValidate: any) => {
                    return <AzureConfiguration {...onValidate} />;
                },
                onNext: async () => {
                    await createAzureAccount();
                }
            },
        ];
        steps.forEach((step) => step.name = t(step.name));
        return steps;
    };
    const azureSingleStepsFlow = (): WizardStep[] => {
        const steps = [
            welcomeStep,
            configurationStep,
            {
                name: 'ON_BOARDING.AZURE.CONNECT_STEP',
                renderView: (onValidate: any) => {
                    return <AzureSingleConnect {...onValidate} />;
                },
                onNext: async () => {
                    await createAzureAccount();
                }
            }
        ];
        steps.forEach((step) => step.name = t(step.name));
        return steps;
    };
    const azureOrgOnboardingStepsFlow = (): WizardStep[] => {
        const connectStep = getOnboardingScopeName();
        const steps = [
            welcomeStep,
            configurationStep,
            {
                name: connectStep,
                renderView: (onValidate: any) => {
                    return <AzureOrgConnect {...onValidate} />;
                },
                onNext: async () => {
                    if (azureOnboardingType === AzureOnboardingType.org) await createAzureOrgAccount();
                    else await createAzureAccount();
                }
            },
        ];
        steps.forEach((step) => step.name = t(step.name));
        return steps;
    };

    function setRelevantSteps(){
        if (azureOnboardingType === AzureOnboardingType.org) setSteps(azureOrgOnboardingStepsFlow());
        else if (isManualOnBoardingFlow) setSteps(azureManualSingleStepsFlow());
        else setSteps(azureSingleStepsFlow());
    }
    const configAzureOrgPayload = (isSubscriptionScope = false): azureOrgOnboardingRequest => {
        const { state: { azureOnboarding } } = getStoreService().getReduxTools();
        let payload: azureOrgOnboardingRequest = {
            organizationName: azureOnboarding.name,
            tenantId: azureOnboarding.welcomeForm.tenantId,
            managementGroupId: azureOnboarding.azureOnboardingScope === AzureOnboardingScope.tenant ? azureOnboarding.welcomeForm.tenantId : azureOnboarding.welcomeForm.managementGroupId,
            useCloudGuardManagedApp: true,
            activeBlades: azureOnboarding.activeBlades,
            vendor: azureOnboarding.azureOnboardingAccountType
        };
        if (isSubscriptionScope) {
            payload = {
                ...payload,
                appRegistrationName: 'Automations-no-script',
                clientId: azureOnboarding.credentials.clientId,
                clientSecret: azureOnboarding.credentials.clientPassword,
            };
        }
        return payload;
    };
    async function createAzureOrgAccount() {
        const { state: { azureOnboarding }, dispatch } = getStoreService().getReduxTools();
        dispatch(setIsLoading(true));
        try {
            const isSubscriptionScope = azureOnboarding.azureOnboardingScope === AzureOnboardingScope.subscription;
            const azureOrgRequest: azureOrgOnboardingRequest = { ...configAzureOrgPayload(isSubscriptionScope) };
            await onboardAzureOrg(azureOrgRequest);
            setSuccessModalOpen(true);
            getCloudAccountsService().clearCache();
        } catch (error: any) {
            if (typeof error === 'object' && !error.message) setErrorMsg(message);
            else setErrorMsg(error);
            setErrorModalOpen(true);
            throw (error);
        } finally {
            dispatch(setIsLoading(false));
        }
    }

    async function createAzureAccount(){
        const { state: { azureOnboarding }, dispatch } = getStoreService().getReduxTools();
        dispatch(setIsLoading(true));
        const azureAccount: azureCreateAccountRequest = {
            name: azureOnboarding.name,
            subscriptionId: azureOnboarding.welcomeForm.subscriptionId,
            tenantId: azureOnboarding.welcomeForm.tenantId,
            operationMode: azureOnboarding.azureOnboardingOperationMode,
            vendor: azureOnboarding.azureOnboardingAccountType,
            credentials: azureOnboarding.credentials,
            OrganizationalUnitId: azureOnboarding.organizationalUnitId,
            // The onboarding management method should be different for each onboarding type
            // e.g. organizational, simplifiedOnboarding, advancedOnboarding
            onboardingManagementMethod: AzureOnboardingManagementMethod.simplifiedOnboarding
        };
        try {
            await createAzureCloudAccount(azureAccount);
            setSuccessModalOpen(true);
            getCloudAccountsService().clearCache();
        } catch (error:any) {
            if (typeof error === 'object' && !error.message) setErrorMsg(message);
            else setErrorMsg(error);
            setErrorMsg(error);
            setErrorModalOpen(true);
            throw(error);
        } finally {
            dispatch(setIsLoading(false));
        }
    }

    return (
        <div data-aid="azure-onboarding-wizard-wrapper">
            { steps &&
                <Wizard
                    steps={steps}
                    onClose={onWizardClose}
                    title={t('ON_BOARDING.AZURE.WIZARD_TITLE')}
                    doneButtonText={t('ON_BOARDING.AZURE.ONBOARD')}
                />
            }
            {isLoading && <CpLoadingDots></CpLoadingDots>}
            <SuccessModal
                open={successModalOpen}
                text={successText}
                isOrgOnboarding={azureOnboardingType === AzureOnboardingType.org}
                onClose={()=>{
                    setSuccessModalOpen(false);
                    exitWizard();
                }}
            />
            <ErrorModal
                open={errorModalOpen}
                text={errorMsg || t('ON_BOARDING.AZURE.ACCOUNT_ONBOARDED_FAILED')}
                onClose={()=>{
                    setErrorModalOpen(false);
                }}
            />
        </div>
    );
};

export default AzureOnboarding;