import React from 'react';
import { Icon } from '@dome9/berries/react-components';
import { EdgeData } from '../../Common/Models/GraphModels';

const AccountActivityEdgePopoverComponent: React.FC<EdgeData> = (edgeData: EdgeData) => {

    return <div className="-mx-6 -mt-5 -mb-5">
        <div className="p-7 break-all flex-0 bg-content">
            <div className="font-bold mb-4" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}><Icon name={'events'}></Icon>Action</div>
            { edgeData.additionalData?.events && edgeData.additionalData.events.map((event) => {
                return ( <div className="text-xs mb-2" key={event}>
                    {event}
                </div> );
            })}
        </div>
    </div>;
};

export default AccountActivityEdgePopoverComponent;
