
import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IconButton, Stack } from 'common/design-system/components-v2';
import { useSelector } from 'react-redux';
import { getAllStorages, setAllStorages } from '../../AzureIntelligenceOnboarding.reducer';
import { getStoreService } from 'common/interface/services';

const RemoveRowCellRenderer: React.FC<ICellRendererParams> = ({ data }) => {
    const allStorages = useSelector(getAllStorages);
    const { dispatch } = getStoreService().getReduxTools();

    const handleOnClick = () => {
        const updatedRows = allStorages.filter(row => row.id !== data?.id);
        dispatch(setAllStorages(updatedRows));
    };

    return (
        <Stack 
            justifyContent='center' 
            alignItems='center' 
            className='remove-cell'
        >
            <IconButton
                iconProps={{ name: 'remove' }}
                onClick={handleOnClick}
            />
        </Stack>
    );
};

export default RemoveRowCellRenderer;
