import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IFieldInfo, LoadingState } from 'common/interface/general';
import { assetFilterFieldsToFindings } from '../../RiskManagement.utils';
import {
    IFindingItem,
    ISecurityEventResponse,
    ISecurityEventsDataState,
    ISecurityEventsSearchRequest
} from './SecurityEvents.interface';
import { CACHE_TIMOUTS, sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';

let globalSecEventReqCounter = 1;
export const getSecEventReqCounter = (): number => globalSecEventReqCounter;
export const incSecEventReqCounter = (): number => {
    globalSecEventReqCounter++;
    return globalSecEventReqCounter;
};

export const getLatestSecurityEvents = async (url: string, filterValues: IFiltersValues, maxItemsCount: number, searchAfter?: string[]): Promise<ISecurityEventResponse | undefined> => {
    const fields: IFieldInfo[] = assetFilterFieldsToFindings(filterValues?.fields);
    const request: ISecurityEventsSearchRequest = {
        fields: fields,
        searchAfter,
        topN: maxItemsCount,
    };
    return sendHttpRequest<ISecurityEventResponse>(url, {
        data: request,
        method: 'POST',
    }, CACHE_TIMOUTS.LONG);
};

export const handleSecurityEventsData = (setStatus: (status: any) => void, loadingState: LoadingState, securityEvents?: IFindingItem[], isSearching = false) => {
    const newRealtimeSecurityEvents: ISecurityEventsDataState<IFindingItem[]> = {
        loadingState,
    };
    if (loadingState === LoadingState.LOADING_SUCCEEDED) {
        newRealtimeSecurityEvents.value = (securityEvents || []);
        newRealtimeSecurityEvents.isSearching = isSearching;
    }
    setStatus(newRealtimeSecurityEvents);
};

export const updateSecurityEvents = (
    setItemsStatus: (value: any) => void,
    requestCounter: number,
    iteration: number,
    filterValues: IFiltersValues,
    maxItemsNumber: number,
    url: string,
    prevItems?: IFindingItem[],
    searchAfter?: string[]) => {

    const allItems = [...(prevItems || [])];
    if (requestCounter !== getSecEventReqCounter()) {
        return;
    }
    getLatestSecurityEvents(url, filterValues, maxItemsNumber, searchAfter).then(eventsInfo => {
        if (requestCounter !== getSecEventReqCounter()) {
            return;
        }
        const missing = maxItemsNumber - allItems.length;
        const newSlice = (eventsInfo?.findingSecurityEvents || []).slice(0, missing);
        newSlice.forEach(item => allItems.push(item));
        const isSearching = ((allItems.length < maxItemsNumber) && !!eventsInfo?.searchAfter);
        handleSecurityEventsData(setItemsStatus, LoadingState.LOADING_SUCCEEDED, allItems, isSearching);
        if (isSearching) {
            updateSecurityEvents(setItemsStatus, requestCounter, iteration, filterValues, maxItemsNumber, url, allItems, eventsInfo?.searchAfter);
        }
    }).catch(() => {
        handleSecurityEventsData(setItemsStatus, LoadingState.LOADING_FAILED);
    });
};