/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Stack, Label, Typography, SelectV2 } from 'common/design-system/components-v2';
import { CDRContainer, Line } from '../helpers/azure.styled';
import { CDRConfigSectionProps, CDRAccountProps, CDRAssetType, ICollapseList, AzureCDRConfigurationAccountType, LogTypesInstructionsType } from '../helpers/azure.interface';
import { logTypesInstructions, listComponent } from '../helpers/azure.consts';
import { azureCDRAccountValidationSchema } from '../helpers/azure.validations';
import useCustomValidation from 'common/hooks/useCustomValidation';
import CollapseSection from 'modules/workloads/components/CollapseSection/CollapseSection';
import { Icon } from '@dome9/berries/react-components';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';

const CDRAccount: React.FC<CDRAccountProps> = ({ index, onChange, onRemove, initialValue })=>{
    const { t } = useTranslation();
    const [storageId, setStorageId] = useState(initialValue?.storageId || '');
    const [account, setAccount] = useState({});
    const [logTypes, setLogTypes] = useState<CDRAssetType[]>( []);
    const formValidations = useCustomValidation({ yupValidationObject: azureCDRAccountValidationSchema, payload: account });
    const logTypesOptions: SelectOption[] = [
        { label: t('ON_BOARDING.AZURE.CDR_TEXT.AZURE_ACTIVITY_LOGS'), value: CDRAssetType.activityLogs },
        { label: `${t('ON_BOARDING.AZURE.CDR_TEXT.ENTRA_ID')} - ${t('ON_BOARDING.AZURE.CDR_TEXT.SIGN_IN_LOGS')}`, value: CDRAssetType.signIns },
        { label: `${t('ON_BOARDING.AZURE.CDR_TEXT.ENTRA_ID')} - ${t('ON_BOARDING.AZURE.CDR_TEXT.AUDIT_LOGS')}`, value: CDRAssetType.auditLogs },
        { label: t('ON_BOARDING.AZURE.CDR_TEXT.ANALYTICS_LOGS'), value: CDRAssetType.storageAccounts },
    ];

    useEffect(() => {
        if (initialValue && initialValue.logTypes?.length > 0) {
            const initialLogTypes = logTypesOptions
                .filter(option => initialValue?.logTypes.includes(option.value))
                .map(option => option.value as CDRAssetType);
            setLogTypes(initialLogTypes);
        }
    }, []);

    useEffect(() => {
        if (onChange) {
            const payload = { storageId, logTypes };
            setAccount(payload);
            const accounts = { storageId, logTypes };
            onChange(accounts);
        }
    }, [storageId, logTypes]);

    const handleSelectedLogTypes = (selectedTypes: string[]) => {
        setLogTypes(selectedTypes as CDRAssetType[]);
    };
    
    const handleSelectedInstructions = () => {
        const instructions: LogTypesInstructionsType = logTypesInstructions();
        const selectedInstructions: ICollapseList[] = []; // Assuming instructions[logType.value] returns a string
        if (Array.isArray(logTypes)) {
            logTypes.forEach((logType) => {
                if (logType && instructions[logType]) { // Check if logType is not null and value exists in instructions
                    selectedInstructions.push(instructions[logType]);
                }
            });
        } return (
            <>
                {selectedInstructions.map((instructions: ICollapseList) => (
                    <Stack key={instructions.label} direction="row" fullWidth margin={[3, 0, 0, 0]}>
                        <CollapseSection title={t(instructions.collapseTitle as string)}>
                            <Stack direction="row" fullWidth spacing={3} padding={[0, 1, 1, 1]}>
                                { listComponent([instructions], '') }
                            </Stack>
                        </CollapseSection>
                    </Stack>
                ))}
            </>
        );
    };
    

    return (
        <CDRContainer>
            <Stack direction="row" fullWidth spacing={4} alignItems={(formValidations?.errors?.storageId) ? 'center' : 'flex-end'}>
                <Input
                    data-aid='azure-cdr-storage-account-id-input'
                    label={t('ON_BOARDING.AZURE.CDR_TEXT.STORAGE_DETAILS')}
                    tooltip={t('ON_BOARDING.AZURE.CDR_TEXT.STORAGE_DETAILS_TOOLTIP')}
                    value={storageId}
                    onChange={(event) => setStorageId(event.target.value)}
                    isError={!!(formValidations?.errors?.storageId)}
                    helperText={formValidations?.errors?.storageId ? formValidations.errors.storageId[0]?.message : undefined}
                    required
                    fullWidth
                    clearable
                />
                { index > 0 && <Button onClick={onRemove} color='normal' iconProps={{ name: 'delete' }} /> }
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2} margin={[1,0,0,0.5]} fullWidth>
                <Icon color='primary' name='info' size={14}/>
                <Typography variant='body'>{t('ON_BOARDING.AZURE.CDR_INFO')}</Typography>
            </Stack>
            <Stack fullWidth margin={[3,0]}>
                <Line></Line>
            </Stack>
            <Stack direction="row" fullWidth spacing={4}>
                <Stack direction="column" flexWrap fullWidth>
                    <Stack direction="column" fullWidth margin={[0,0,1,0]}>
                        <Label text={t('ON_BOARDING.AZURE.CDR_TEXT.SELECT_LOG_TYPE')}/>
                    </Stack>
                    <SelectV2 
                        options={logTypesOptions} 
                        value={logTypes}
                        isMulti 
                        onChange={handleSelectedLogTypes} 
                        fullWidth 
                        id='azure-cdr-log-types-select' />
                </Stack>
            </Stack>
            { Array.isArray(logTypes) && logTypes.length ? (
                <Stack direction="row" flexWrap margin={[4,0,0,0]}>
                    <Stack direction="row" fullWidth margin={[0,0,1,0]}>
                        <Label text={t('ON_BOARDING.AZURE.CDR_TEXT.LOG_TYPE_INSTRUCTIONS')}/>
                    </Stack>
                    <Stack direction="row" flexWrap fullWidth>
                        { handleSelectedInstructions() }
                    </Stack>
                </Stack>
            ) : null }
        </CDRContainer>
    );
};

const CDRConfigSection: React.FC<CDRConfigSectionProps> = ({ onChange, initialValue })=>{
    const newCdrAccount: AzureCDRConfigurationAccountType = { key: `account-${Date.now()}`, storageId: '', logTypes: [] };
    const initialAccountsData: AzureCDRConfigurationAccountType[] = initialValue?.accounts?.length ? initialValue.accounts : [{ ...newCdrAccount }];
    const [accounts, setAccounts] = useState<AzureCDRConfigurationAccountType[]>(initialAccountsData);
    const maxAccountsCount = 3;

    const handleAccountChange = (index: number, accountDetails: AzureCDRConfigurationAccountType) => {
        const updatedAccounts = accounts.map((account, i) =>
            i === index ? { ...account, ...accountDetails } : account
        );
        setAccounts(updatedAccounts);
        const cdrAccounts = updatedAccounts.map((account) => ({ storageId: account.storageId, logTypes: account.logTypes }));
        onChange({ isEnabled: true, accounts: cdrAccounts });
    };
    const handleRemoveAccount = (key: string) => {
        if (accounts.length <= 1) return;
        const updatedAccounts = accounts.filter((obj) => obj.key !== key);
        setAccounts(updatedAccounts);
        onChange({ isEnabled: true, accounts: updatedAccounts });
    };
    const handleAddAccount = () => {
        if (accounts.length >= 3) return;
        setAccounts([...accounts, { key: `account-${Date.now()}`, storageId: '', logTypes: [] }]);
    };

    return (
        <>
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" fullWidth spacing={0} padding={[3,0,1,0]} style={{ maxWidth: '600px' }}>
                { accounts.map((account, index) => (
                    <CDRAccount key={account.key}
                        index={index}
                        initialValue={account}
                        onChange={(details) => handleAccountChange(index, details)}
                        onRemove={() => handleRemoveAccount(account.key as string)}
                    />
                ))}
                <Button onClick={handleAddAccount} color='normal' disabled={accounts.length >= maxAccountsCount}>Add Account</Button>
            </Stack>
        </>
    );
};

export default React.memo(CDRConfigSection);