import React from 'react';
import { EmptyStyled } from './IssueExclusionsEmptyState.styled';
import { Button, Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nRiskNamespace } from '../../../consts';
import emptyStateIcon from '../../../assets/logos/exclude-empty-state.svg';
import { openIssueExclusionEditorDrawer } from '../IssueExclusions.utils';

export const IssueExclusionsEmptyState: React.FC = () => {
    const { t } = useTranslation(I18nRiskNamespace);
    return (
        <EmptyStyled.TopDiv>
            <EmptyStyled.BodyDiv>
                <img src={emptyStateIcon} height='90px' width='90px' alt={''} />
                <Stack direction={'column'} spacing={2} alignItems={'center'} >
                    <Typography variant={'h2'}>{t('ISSUE_EXCLUSIONS.EMPTY_STATE.SECURITY_ISSUES_EXCLUSIONS')}</Typography>
                    <Typography variant={'body500'}>{t('ISSUE_EXCLUSIONS.EMPTY_STATE.ADD_EXCLUSIONS')}</Typography>
                </Stack>
                <Stack direction={'column'} spacing={12} alignItems={'center'}>
                    <Button label={t('ISSUE_EXCLUSIONS.EMPTY_STATE.CREATE')} onClick={() => openIssueExclusionEditorDrawer()} color={'brandPrimary'} iconProps={{ name: 'plus' }} />
                </Stack>
            </EmptyStyled.BodyDiv>
        </EmptyStyled.TopDiv>
    );
};
