import { ICellRendererParams } from 'ag-grid-enterprise';
import { FC } from 'react';
import { IIssueExclusion } from '../IssueExclusions.interface';
import { MultiOptionChipsComponent } from 'common/erm-components/custom/MultiChipCellContent/MultiOptionChipsComponent';

import { getAllEntityTypeOptions } from '../../../RiskManagement.options';

export const IssueExclusionEntityTypeCellRender: FC<ICellRendererParams<IIssueExclusion>> = (params) => {
    const exclusion: IIssueExclusion | undefined = params.data;
    if (!exclusion) {
        return null;
    }

    return <MultiOptionChipsComponent selectedValues={exclusion?.entityTypes} fetchAllOptions={getAllEntityTypeOptions} />;
};
