import { initializeSentry, setSentryOptions } from './sentry';
import { isLocalhost, isPreQA } from 'common/utils/RuntimeEnvironment';
import { isNotChina } from 'common/utils/dataCenter';

export const initializeProfiler = () => {
    !isPreQA && !isLocalhost && isNotChina() && initializeSentry();
};
export const setProfilerOptions = () => {
    !isPreQA && !isLocalhost && isNotChina() && setSentryOptions();
};
