import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import IconLinkCellRenderer, { IIconLinkCellProps } from 'common/components/ag-grid/Renderers/IconLinkCellRenderer';
import { isNil } from 'common/utils/helpFunctions';
import { CellToClipboardHandler } from 'common/components/ProtectedAssets/ProtectedAssetsTable';
import { showCdrExclusionForm } from '../../FindingsTable/Modals/CdrExclusion/CdrExclusion.items';
import { ICdrExclusion } from '../../FindingsTable/Modals/CdrExclusion/CdrExclusion.interface';

export const CdrExclusionRulesetNameCellRender: React.FC<ICellRendererParams<ICdrExclusion>> = (params) => {
    const cdrExclusion: ICdrExclusion | undefined = params.data;
    if (!cdrExclusion?.rulesetName) {
        return null;
    }
    const name = cdrExclusion.rulesetName;

    const onClick = () => {
        showCdrExclusionForm(cdrExclusion);
    };

    const options: IIconLinkCellProps = {
        isLink: !isNil(onClick),
        value: name,
        onClick,
        levelIconProps: {
            iconProps: { name: 'exclude' },
            size: 'md',
        }
    };

    CellToClipboardHandler(params, name);
    return (<IconLinkCellRenderer {...options} />);
};
