import React from 'react';
import AlibabaActions, { UpdateAlibabaCredentialsReq } from '../alibaba.actions';

export interface UseUpdateAlibabaReq {
    onError?: (error: { message: string; }) => void;
    onSuccess?: () => void;
}

export interface UseUpdateCredentialsAlibabaPayload {
    isLoading: boolean;
    isSuccess: boolean;
    isError?: { message: string; };
    updateCredential: (cloudAccountId: string, updatePayload: UpdateAlibabaCredentialsReq) => Promise<void>;
    reset?: () => void;
}

export type UseUpdateCredentialsAlibaba = (req?: UseUpdateAlibabaReq) => UseUpdateCredentialsAlibabaPayload;

export const useUpdateCredentialsAlibaba: UseUpdateCredentialsAlibaba = ({ onSuccess, onError } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState<{ message: string; }>();

    const updateCredential = React.useCallback(async (cloudAccountId: string, updatePayload: UpdateAlibabaCredentialsReq) => {
        setIsLoading(true);
        try {
            const result = await AlibabaActions.updateCredentialsAlibabaAccount(cloudAccountId, updatePayload);
            setIsSuccess(result);
            onSuccess?.();
        } catch (error: any) {
            setIsError({ message: error.message });
            onError?.({ message: error.message });
        }
        setIsLoading(false);
    }, [onSuccess, onError]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(undefined);
    }, []);

    return ({
        isLoading,
        isSuccess,
        isError,
        updateCredential,
        reset
    });
};