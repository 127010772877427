import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import RiskScoreCellRenderer from 'common/components/ProtectedAssets/Renderers/RiskScoreCellRenderer';
import { RiskManagementRegistry } from '../../../../../RiskManagementRegistry';
import { ermTrans } from '../../../../../RiskManagement.utils';
import { Addin } from 'common/extensibility/AddinRegistry';
import { RiskLevelCellRenderer } from './renderers/RiskLevelCellRenderer';
import TypeCellRenderer from 'common/components/ProtectedAssets/Renderers/TypeCellRenderer';
import { RiskLevelHeaderRenderer } from './renderers/RiskLevelHeaderRenderer';
import { RiskLevelsMap } from 'common/module_interface/RiskManagement/RiskLevel';

const columnDefAddins: Addin<CGColDef>[] = [
    {
        id: 'ermRiskScore',
        content: () => {
            return {
                colId: 'ermRiskScore',
                field: 'riskScore',
                headerName: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.HIGHEST_RISK_SCORE.HEADER'),
                headerTooltip: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.HIGHEST_RISK_SCORE.TOOLTIP'),
                resizeable: false,
                cellRenderer: RiskScoreCellRenderer,
                width: 80,
                minWidth: 80,
            };
        },
    },
    {
        id: 'type',
        content: () => {
            return {
                colId: 'type',
                field: 'typeByPlatform',
                sortField: 'type',
                headerName: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.TYPE.HEADER'),
                headerTooltip: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.TYPE.TOOLTIP'),
                cellRenderer: TypeCellRenderer,
            };
        },
    },
    {
        id: 'critical',
        content: () => {
            return {
                colId: 'critical',
                field: 'critical',
                headerName: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.CRITICAL.HEADER'),
                headerTooltip: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.CRITICAL.TOOLTIP'),
                cellRenderer: RiskLevelCellRenderer,
                headerComponent: RiskLevelHeaderRenderer,
                headerComponentParams: { riskLevelInfo: RiskLevelsMap.critical },
            };
        },
    },
    {
        id: 'high',
        content: () => {
            return {
                colId: 'high',
                field: 'high',
                headerName: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.HIGH.HEADER'),
                headerTooltip: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.HIGH.TOOLTIP'),
                cellRenderer: RiskLevelCellRenderer,
                headerComponent: RiskLevelHeaderRenderer,
                headerComponentParams: { riskLevelInfo: RiskLevelsMap.high },
            };
        },
    },
    {
        id: 'total',
        content: () => {
            return {
                colId: 'total',
                field: 'total',
                headerName: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.TOTAL.HEADER'),
                headerTooltip: ermTrans('RISK_DASHBOARD.RISKIEST_ASSET_TYPES.COLUMNS.TOTAL.TOOLTIP'),
                cellRenderer: RiskLevelCellRenderer,
            };
        },
    },
];

export const initializeAssetTypesColumnDefs = () => {
    RiskManagementRegistry.addAssetTypesTableColumnDefAddins(columnDefAddins);
};