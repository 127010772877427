import { Button, Modal } from '@dome9/berries/react-components';
import { CpIcon } from '@dome9/components/react/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Typography, Stack } from 'common/design-system/components-v2';

const ErrorIcon = styled(CpIcon).attrs(() => ({
    icon: 'error'
}))`
    font-size: 40px;
    fill: #F25050;
    margin: auto;
    display: inline-block;
`;

interface ErrorModalProps {
    open: boolean; 
    onClose: () => void; 
    text: string;
}

export const ErrorModal: FC<ErrorModalProps> = ({ open, onClose, text }) => {
    const { t } = useTranslation();
    return <div>
        <Modal.ModalDialog 
            isOpen={open} 
            onRequestClose={onClose} 
            width='md' 
            shouldCloseOnOverlayClick>
            <Modal.ModalHeader title={''} onClose={onClose} dataAid="error"/>
            <Modal.ModalContent>
                <Stack justifyContent='center' alignItems='center' fullWidth padding={[2,0]}>
                    <Stack justifyContent='center' alignItems='center' fullWidth >
                        <ErrorIcon />
                    </Stack>
                    <Stack justifyContent='center' alignItems='center' fullWidth padding={[5,0,0,0]} >
                        <Typography variant="subtitleLg">{text}</Typography>
                    </Stack>
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <div className='modal__footer-actions'>
                    <Button 
                        className='btn--min-width' 
                        onClick={onClose}
                    >
                        {t('COMMON.CLOSE')}
                    </Button>
                </div>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    </div>;
};

export default ErrorModal;