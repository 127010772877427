import React, { useMemo } from 'react';
import { CommonIgnoreListPage, ICommonIgnoreListProps } from '../CommonIgnoreList/CommonIgnoreListPage';
import { CVE_IGNORE_LIST_PAGE_ID } from './CveIgnoreList.consts';
import { ICveIgnoreItem } from './CveIgnoreList.interface';
import { ermTrans } from '../../../RiskManagement.utils';
import { getCveIgnoreListColumns } from './CveIgnoreList.columns';
import { showCveIgnoreForm } from './CveIgnoreList.items';
import { deleteCveIgnoreListItems, fetchAllCveIgnoreItems } from './CveIgnoreList.datasource';
import { getCveIgnoreListFilters } from './CveIgnoreList.filters';

const getFooterItemTitle = (count: number) => ermTrans('IGNORE_LIST.CVE_IGNORE_LIST.GENERAL.CVE_IGNORE', { count });

export const CveIgnoreListPage: React.FC = () => {
    const pageProps: ICommonIgnoreListProps<ICveIgnoreItem> = useMemo(() => ({
        pageTableId: CVE_IGNORE_LIST_PAGE_ID,
        fetchAllItems: fetchAllCveIgnoreItems,
        getFooterItemTitle,
        getTableColumnDefs: getCveIgnoreListColumns,
        getTableFilterDefs: getCveIgnoreListFilters,
        openForm: showCveIgnoreForm,
        deleteItems: deleteCveIgnoreListItems,
        iconName: 'web',
    }), []);

    return (
        <CommonIgnoreListPage {...pageProps} />
    );
};
