import React from 'react';
import { formatDate } from 'common/utils/helpFunctions';
import { Stack, Typography } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { IFindingComment } from 'common/components/Findings/Findings.interface';
import { isEmpty } from 'lodash';

export const FindingComments: React.FC<{ finding: IFinding }> = ({ finding }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const { comments } = finding;
    if (isEmpty(comments)) {
        return null;
    }
    return (
        <Stack spacing={2}>
            <Typography type='key'>{t('INT_FINDING_OVERVIEW.COMMENTS')} ({comments.length})</Typography>
            <Stack spacing={4} padding={[0, 2]}>
                {
                    comments.map((comment: IFindingComment, index: number) => {
                        const { userName, action, text } = comment;
                        const timestamp: string = formatDate(comment.timestamp);
                        const formattedComment: string = [userName, timestamp, action, text].filter(x => x).join(' | ');
                        return <Stack key={index}>
                            <Typography>{formattedComment}</Typography>
                        </Stack>;
                    })
                }
            </Stack>
        </Stack>
    );
};

export default FindingComments;