import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import Chip from 'common/design-system/components-v2/Chip';
import { Tooltip, ComponentOverflow } from 'common/design-system/components-v2';
import Stack from 'common/design-system/components-v2/Stack';
import Typography from 'common/design-system/components-v2/Typography';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { GenericObject } from 'common/interface/general';

const MitreCellTooltip: React.FC<{ techniques: string[] }> = ({ techniques }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);

    return (
        <Stack margin={2} spacing={2}>
            <Typography color={'strong'} variant={'body500'}>{t('INT_FINDING_OVERVIEW.MITRE.TECHNIQUES')}</Typography>
            <Stack spacing={1} >
                { techniques?.map((technique: string) => (
                    <Chip
                        label={technique}
                        key={technique}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

export const MitreCellRender: React.FC<ICellRendererParams<IFinding>> = (params: ICellRendererParams<IFinding>) => {
    const mitre: GenericObject<string[]> | undefined = params.value ?? params.data?.mitreDict;
    if(!mitre) return;

    return (
        <ComponentOverflow
            overflowCountBorder
            components={Object.keys(mitre).map((tactic: string) => (
                <Tooltip key={tactic} content={ <MitreCellTooltip techniques={mitre[tactic]} />}>
                    <Chip
                        withBorder
                        label={tactic}
                        key={tactic}
                    />
                </Tooltip>
            ))}
        />
    );
};
