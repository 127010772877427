import React, { useEffect } from 'react';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { getCloudAccountsService, ICloudAccount } from 'common/interface/data_services';
import { getUniqueValues } from 'common/utils/objectUtils';
import { useTranslation } from 'react-i18next';
import { i18nPolicyNamespace } from '../initialize.i18n';
import { POLICY_ENVIRONMENT_VENDORS } from 'common/interface/policy';
import { CardItem } from 'common/components/policy/Card';
import CardsSelectComponent from 'common/components/policy/CardsSelectComponent';
import { OnValidate } from 'common/components/Wizard/Wizard';
import useReactRouterQuery from 'common/hooks/useReactRouterQuery';
import { WorkloadSupportedPlatforms } from 'common/components/policy/utils';
import { Vendors } from 'common/consts/vendors';

export interface EnvironmentPlatformsSelectionProps{
    selectedPlatformId?: string;
    onValidate:OnValidate;
    selectedItemChanged:Function;
}
export const EnvironmentPlatformsSelectionComponent: React.FC<EnvironmentPlatformsSelectionProps> = ({ selectedPlatformId, onValidate, selectedItemChanged }) => {
    const { t } = useTranslation(i18nPolicyNamespace);
    const { params } = useReactRouterQuery();

    const init = async () => {
        onValidate(true);
        let _vendors = globalAddinContainer.get<CardItem>(POLICY_ENVIRONMENT_VENDORS);
        const allCloudAccounts:ICloudAccount[] = await getCloudAccountsService().getAllCloudAccounts();
        const allPlatforms = allCloudAccounts.map((cloudAccount:ICloudAccount) => cloudAccount.platform);
        const platformsInUse = getUniqueValues(allPlatforms);
        if(platformsInUse.includes('google')){platformsInUse.push('gcp');}
        _vendors = _vendors.filter((vendor: any) => platformsInUse.includes(vendor.name));
        // Vulnerabilities / Image Assurance supports only specific vendors
        if (params['platformId'] === Vendors.IMAGE_ASSURANCE) {
            _vendors = WorkloadSupportedPlatforms.filter((vendor: any) => platformsInUse.includes(vendor.id));
        }
        setVendors(_vendors);
        selectedItemChanged(_vendors[0]);
    };

    const [vendors, setVendors] = React.useState<CardItem[]>([]);
    useEffect(() => {
        init();
    }, []);

    return(
        <span data-aid={'platform-selection'} >
            <CardsSelectComponent selectedItemId={selectedPlatformId} header={t('GENERAL.SELECT_PLATFORM')} items={vendors} onSelectedItemChanged={selectedItemChanged}/>
        </span>
    );
};
