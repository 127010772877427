import React from 'react';
import { ExposureNodeData } from 'common/module_interface/insight/ExposureNodeData';
import { isNil } from 'common/utils/helpFunctions';
import FindingSeverityList from '../ProtectedAsset/Components/FindingSeverityList';
import i18n from 'i18next';
import { I18nRiskNamespace } from '../../consts';
import '../../riskManagement.scss';
import { IFindingSeverityCounters } from 'common/consts/FindingSeverity';

const t = (str: string) => {
    return i18n.t(str, { ns: I18nRiskNamespace });
};

const FindingSeverityPopoverItem: React.FC<{ title: React.ReactNode, severityCounters?: IFindingSeverityCounters, className?: string }> = ({
    title,
    severityCounters,
    className,
}) => {
    return isNil(severityCounters) ? null :
        <div className={`exposure-severity-list flex items-center py-6 ${className}`}>
            <FindingSeverityList severities={[{
                title: title,
                criticalCount: severityCounters.critical,
                highCount: severityCounters.high,
                mediumCount: severityCounters.medium,
                lowCount: severityCounters.low,
            }]} />
        </div>;
};

export const Cves: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    return <FindingSeverityPopoverItem title={t('BADGES.CVEs')} severityCounters={data.cves} className={data.className} />;
};

export const SecurityEvents: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    const titleComponent = (<div><div className='top-title'>{t('BADGES.SECURITY_EVENTS')}</div><div>{t('BADGES.LAST_30_DAYS')}</div></div>);
    return <FindingSeverityPopoverItem title={titleComponent} severityCounters={data.securityEvents} className={data.className} />;
};

export const PostureFindings: React.FC<{ data: ExposureNodeData }> = ({ data }) => {
    return <FindingSeverityPopoverItem title='Posture findings:' severityCounters={data.postureFindings} className={data.className} />;
};