import i18n, { validLanguages } from 'common/services/translations/translations';
import { I18nIntegrations } from 'common/module_interface/settings/integrations/consts';

export const initializeI18nIntegrations = async () => {
    return Promise.all(validLanguages.map(async (language) => {
        try {
            const contents = await require(`./languages/${language}/translation.json`);
            i18n.addResourceBundle(language, `${I18nIntegrations}`, contents);
        } catch {
            //Nothing to do with missing translation files
        }
    }));
};