import { IFlatMenuItem, ISearcher, ISearchResultItem } from '../../MainNavigationBar/MainNavigationBar.interface';
import { t } from 'i18next';
import { ListItem } from '@dome9/berries/react-components/dist/types/components/List';
import { boldSearchResult } from '../../MainNavigationBar/MainNavigationBarUtils';
import { Icon, List } from '@dome9/berries/react-components';
import { isIncludeSearchTerm } from 'common/components/FilterPanel/filterUtils';
import { rebuildMenu } from './Menus.reducer';
import { IMainMenuItem, IMenuSection } from 'common/interface/menu';
import { changeUrl } from 'common/utils/http';
import { FC, useMemo } from 'react';

export interface IMenuSearchResultItem extends ISearchResultItem {
    parentMenuTitle: string;
    parentSectionTitle: string;
    url: string | undefined;
    state: string | undefined;
    icon?: string;
}

const getElementFromSearchResults = (results: IMenuSearchResultItem[], searchTerm: string, resultItemClicked: Function, dataAid?: string): JSX.Element => {
    const resultsList: ListItem[] = [];
    results.map(result => {
        result.parentMenuTitle && resultsList.push({
            startElements: [<div key={result.id} className={'flex mb-3 space-x-4 flex-center'}>
                <div className='flex space-x-4 flex-center'>
                    {result.parentMenuTitle &&
                        <>
                            <div>{boldSearchResult(t(result.parentMenuTitle), searchTerm)}</div>
                            <Icon name='chevron-right' size={8} />
                        </>
                    }
                    {result.parentSectionTitle &&
                        <>
                            <div>{boldSearchResult(t(result.parentSectionTitle), searchTerm)}</div>
                            <Icon name='chevron-right' size={8} />
                        </>
                    }
                    {boldSearchResult(t(result.name), searchTerm)}
                </div>
            </div>],
            title: '',
            removable: false,
            onSelect: () => {
                onMenuItemSelect(result.state, result.url);
                resultItemClicked(result);
            },
        });
    });
    return <List items={resultsList} dataAid={dataAid || 'searchBar-results'} />;
};

const onMenuItemSelect = (state: string | undefined, url: string | undefined) => {
    state ? changeUrl(state) : window.open(url, '_blank');
};

export const rebuildMenusForSearch = (): IFlatMenuItem[] => {
    const menus = rebuildMenu();
    const flatMenus = menus.reduce((preMenu: IFlatMenuItem[], currMenu: IMainMenuItem) => {
        if (currMenu.sections) {
            preMenu.push(...currMenu.sections.reduce((prevSection: IFlatMenuItem[], currSection: IMenuSection) => {
                if (currSection.items) {
                    const enrichedItems = currSection.items.map((item) => {
                        return {
                            ...item,
                            parentSectionTitle: currSection.title || '',
                            parentMenuTitle: currMenu.label,
                        };
                    });
                    prevSection.push(...enrichedItems);
                }
                return prevSection;
            }, []));
        }
        return preMenu;
    }, []);
    return flatMenus;
};

export const MenuSearcher: FC<{ searchStr: string, onResultClicked: Function }> = ({ searchStr, onResultClicked }) => {
    const menuResults = useMemo(() => {
        if (searchStr === '') {
            return [];
        }

        const flatMenus = rebuildMenusForSearch();
        const results = [] as IMenuSearchResultItem[];
        for (const menuItem of flatMenus) {
            if (isIncludeSearchTerm(searchStr, [t(menuItem.label), t(menuItem.parentMenuTitle), t(menuItem.parentSectionTitle)])) {
                results.push(
                    {
                        id: menuItem.id,
                        name: menuItem.label,
                        state: menuItem.state,
                        url: menuItem.url,
                        parentMenuTitle: menuItem.parentMenuTitle,
                        parentSectionTitle: menuItem.parentSectionTitle,
                        icon: menuItem.icon,
                    },
                );
            }
        }
        return results;
    }, [searchStr]);

    if (searchStr === '') {
        return <>{t('MAIN_NAVIGATION_BAR.RESULTS_LIST.NO_SEARCH_TEXT')}</>;
    }

    if (menuResults.length === 0) {
        return <div>{t('MAIN_NAVIGATION_BAR.RESULTS_LIST.NO_RESULTS')}</div>;
    }

    return getElementFromSearchResults(menuResults, searchStr, onResultClicked, 'search-navigation-menu');
};


export const menuSearcher: ISearcher = {
    title: t('MAIN_NAVIGATION_BAR.TABS.PAGES'),
    id: 'Menu Searcher',
    component: MenuSearcher,
};

