import React from 'react';
import { changeUrl } from 'common/utils/http';
import { isNewEventsViewEnabled } from '../initialize.pageAndMenu';
import { eventsTypeParam } from '../EventsTable';

const RedirectToNewEventsTable: React.FC = () => { // TODO: remove when old events page is removed
    React.useEffect(() => {
        if (!isNewEventsViewEnabled()) return;
        const searchParams = new URLSearchParams(window.location.search);
        changeUrl(`/alerts/findings-new?${eventsTypeParam}=all&${searchParams.toString()}`);
    }, []);
    return null;
};

export default RedirectToNewEventsTable;
