import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Button, Chip, Link, SelectV2 } from 'common/design-system/components-v2';
import { CLOUD_BOTS_INFO_URL } from '../../FindingsTable/Modals/FixItModal/FixItModal.const';
import { isEmpty } from 'lodash';
import { CloudBotCardList } from '../../FindingsTable/Components/CloudBotCardList';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';
import { EMPTY_STRING } from 'common/consts/GeneralConsts';
import {
    ICloudBot,
    ICloudBotParameter,
    IFieldError,
} from 'common/module_interface/intelligence/CloudBots/CloudBots.interface';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { getVendor, IVendor, Vendors } from 'common/consts/vendors';
import { CloudBotsRegistry } from 'common/module_interface/intelligence/CloudBots/CloudBotsRegistry';
import { CloudBotParameterType } from 'common/module_interface/intelligence/CloudBots/CloudBots.const';
import { isEmailValid } from 'common/utils/http';
import { ICloudBotsInputProps, ICloudBotsInputRef } from './CloudBotsInput.types';
import { CloudBotsStyles as Styles } from './CloudBotsInput.styled';
import { FormFields } from './CloudBotsInput.const';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';

export const CloudBotsInput = forwardRef<ICloudBotsInputRef, ICloudBotsInputProps>((props: ICloudBotsInputProps, ref: React.ForwardedRef<ICloudBotsInputRef>) => {

    const { selectedRows, maxCloudBotsAllowed, fieldErrorsProps } = props;
    const { addFieldErrors, getFieldErrorText, clearFieldErrors } = fieldErrorsProps;
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const [selectedCloudBotId, setSelectedCloudBotId] = useState<string>(EMPTY_STRING);
    const [selectedCloudBots, setSelectedCloudBots] = useState<ICloudBot[]>([]);
    const [cloudBotsOptions, setCloudBotsOptions] = useState<SelectOption[]>([]);
    const [platform, setPlatform] = useState<Vendors>();
    const [isDirty, setIsDirty] = useState(false);
    const isMaxCloudBotsLimitReached: boolean = selectedCloudBots.length >= maxCloudBotsAllowed;
    const isAddBtnDisabled: boolean = !selectedCloudBotId || isMaxCloudBotsLimitReached;

    const finding: IFinding | undefined = useMemo(() => {
        return !isEmpty(selectedRows) ? selectedRows[0] : undefined;
    }, [selectedRows]);

    const addCloudBotFieldErrorText: string | undefined = useMemo(() => {
        return getFieldErrorText(FormFields.ADD_CLOUD_BOTS);
    }, [getFieldErrorText]);

    useImperativeHandle(ref, (): ICloudBotsInputRef => {
        return {
            isValidCloudBots,
            getSelectedCloudBots: () => [...selectedCloudBots],
            isFieldDirty: () => isDirty
        };
    });

    useEffect(() => {
        if (!finding) return;
        const _platform: string = finding.cloudAccountType;
        if (_platform) {
            const vendor: IVendor | null = getVendor(_platform);
            if (!vendor) return;
            setPlatform(vendor.name);
        }
    }, [finding]);

    useEffect(() => {
        if (!platform) return;
        const cloudBotsByPlatform: ICloudBot[] = CloudBotsRegistry.getCloudBotsByPlatform(platform);
        const updatedCloudBotsOptions: SelectOption[] = cloudBotsByPlatform.map((cloudBot: ICloudBot) => {
            return {
                label: cloudBot.name,
                value: cloudBot.id,
                labelProps: { leadingIconProps: { name: 'robot' } },
                disabled: selectedCloudBots.some((selectedCloudBot: ICloudBot) => selectedCloudBot.id === cloudBot.id)
            };
        });
        setCloudBotsOptions(updatedCloudBotsOptions);
    }, [platform, selectedCloudBots, setCloudBotsOptions]);

    const handleAddCloudBot = () => {
        if (!selectedCloudBotId) return;
        clearFieldErrors([FormFields.ADD_CLOUD_BOTS]);
        const selectedBot: ICloudBot | undefined = CloudBotsRegistry.getCloudBotById(selectedCloudBotId);
        if (!selectedBot) return;
        setSelectedCloudBots((prevBots: ICloudBot[]) => [...prevBots, selectedBot]);
        setSelectedCloudBotId(EMPTY_STRING);
        setIsDirty(true);
    };

    const handleRemoveCloudBot = (cloudBot: ICloudBot) => {
        const remainingCloudBots: ICloudBot[] = selectedCloudBots.filter((bot: ICloudBot) => bot.id !== cloudBot.id);
        setSelectedCloudBots(() => remainingCloudBots);
    };

    const handleCloudBotSelectionChange = (newValue: string | undefined) => {
        clearFieldErrors([FormFields.ADD_CLOUD_BOTS]);
        setSelectedCloudBotId(newValue || EMPTY_STRING);
    };

    const handleOnParameterInput = (event: React.FormEvent<HTMLInputElement>, selectedCloudBot: ICloudBot, parameterOrder: number) => {
        const input: HTMLInputElement = event.target as HTMLInputElement;
        setSelectedCloudBots((prevBots: ICloudBot[]) =>
            prevBots.map((bot: ICloudBot): ICloudBot => {
                if (bot.id !== selectedCloudBot.id) return bot;
                return {
                    ...bot,
                    parameters: bot.parameters.map((parameter: ICloudBotParameter): ICloudBotParameter => {
                        if (parameter.order !== parameterOrder) return parameter;
                        return {
                            ...parameter,
                            value: input.value,
                            isError: false,
                            errorText: undefined
                        };
                    })
                };
            })
        );
    };

    const updateCloudBotFieldsErrorState = () => {
        const errors: IFieldError[] = [];
        if (selectedCloudBots.length === 0) {
            errors.push({
                field: FormFields.ADD_CLOUD_BOTS,
                label: t('CLOUD_BOTS.ERRORS.NO_CLOUD_BOTS_ADDED')
            });
        }
        addFieldErrors(errors);
        return errors.length > 0;
    };

    const updateCloudBotParametersErrorState = () => {
        const updatedSelectedCloudBots: ICloudBot[] = selectedCloudBots.map((bot: ICloudBot): ICloudBot => {
            if (isEmpty(bot.parameters)) return bot;
            return {
                ...bot,
                parameters: bot.parameters.map((param: ICloudBotParameter): ICloudBotParameter => {
                    if (param.suppressValidations) return param;
                    let isError = false;
                    let errorText;
                    if (param.required && !param.value) {
                        isError = true;
                        errorText = t('CLOUD_BOTS.ERRORS.PARAMETER_REQUIRED', {
                            parameter: param.name,
                            ns: i18nIntelligenceNamespace
                        });
                    } else if (param.type === CloudBotParameterType.NUMBER && (param.value && isNaN(Number(param.value)))) {
                        isError = true;
                        errorText = t('CLOUD_BOTS.ERRORS.INVALID_NUMBER');
                    } else if (param.type === CloudBotParameterType.EMAIL && (param.value && !isEmailValid(param.value))) {
                        isError = true;
                        errorText = t('CLOUD_BOTS.ERRORS.INVALID_EMAIL');
                    }
                    return { ...param, isError, errorText };
                })
            };
        });
        if (!isEmpty(selectedCloudBots)) {
            setSelectedCloudBots(updatedSelectedCloudBots);
        }
        return updatedSelectedCloudBots.some((bot: ICloudBot) => bot.parameters.some((param: ICloudBotParameter) => param.isError));
    };

    const isValidCloudBots = () => {
        const isFormFieldError: boolean = updateCloudBotFieldsErrorState();
        const isCloudBotsParameterError: boolean = updateCloudBotParametersErrorState();
        return !(isFormFieldError || isCloudBotsParameterError);
    };

    return (
        <Styles.AddCloudBotsWrapper spacing={2}>
            <Styles.CloudBotsSelectWrapper direction={'row'} spacing={2} fullWidth
                justifyContent={'space-between'}>
                <SelectV2
                    value={selectedCloudBotId}
                    label={t('CLOUD_BOTS.ADD_CLOUD_BOT')}
                    isMulti={false}
                    clearable={true}
                    required={true}
                    fullWidth={true}
                    disabled={isMaxCloudBotsLimitReached}
                    placeholder={t('CLOUD_BOTS.SEARCH')}
                    options={cloudBotsOptions}
                    onChange={handleCloudBotSelectionChange}
                    isError={!!addCloudBotFieldErrorText}
                    helperText={addCloudBotFieldErrorText}
                    labelProps={{
                        tooltip: <Link externalUrl={CLOUD_BOTS_INFO_URL}>{t('CLOUD_BOTS.LEARN_MORE')}</Link>,
                        interactiveTooltip: true
                    }}
                />
                <Styles.AddButtonWrapper isHelperTextVisible={!!addCloudBotFieldErrorText}>
                    <Button iconProps={{ name: 'plus' }} onClick={handleAddCloudBot}
                        disabled={isAddBtnDisabled}>
                        {t('CLOUD_BOTS.ADD')}
                    </Button>
                </Styles.AddButtonWrapper>
            </Styles.CloudBotsSelectWrapper>
            {!isEmpty(selectedCloudBots) &&
                <Styles.CloudBotCardListWrapper spacing={2} fullWidth>
                    <CloudBotCardList cloudBots={selectedCloudBots} onDelete={handleRemoveCloudBot}
                        onParameterInput={handleOnParameterInput}
                        enableScrollIntoLastCloudBot={true}/>
                </Styles.CloudBotCardListWrapper>
            }
            {!isEmpty(selectedCloudBots) &&
                <Styles.CloudBotsCounterWrapper spacing={2} fullWidth direction={'column'}
                    alignItems={'flex-end'}>
                    <Chip label={`${selectedCloudBots.length}/${maxCloudBotsAllowed}`}
                        color={'normal'}/>
                </Styles.CloudBotsCounterWrapper>
            }
        </Styles.AddCloudBotsWrapper>
    );
});
CloudBotsInput.displayName = 'CloudBotsInput';
