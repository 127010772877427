import { IDashboard } from 'common/module_interface/overview/Interface';
import React from 'react';
import { GenericDashboardSection } from '../Section/GenericDashboardSection';
import { useSelector } from 'react-redux';
import { getSelectedDashboard } from 'modules/overview/Overview.reducer';


export const GenericDashboardPage: React.FC<{ selectedDashboard:IDashboard }> = () => {
    const selectedDashboard = useSelector(getSelectedDashboard);
    return (
        <>
            {selectedDashboard.sections?.map(section => {
                return <GenericDashboardSection
                    key={section.id || section.title}
                    section={section}
                />;
            })}
        </>
    );
     
};
