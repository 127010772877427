import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'common/design-system/components-v2';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import i18n from 'common/services/translations/translations';
import { I18nIntegrations } from 'common/module_interface/settings/integrations/consts';
import { FILTER_EVENTS, FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import { initFilterPanel } from 'common/components/FilterPanel/FilterPanelManager';
import {
    renderAddFilter, renderClearAll,
    renderDefaultFreeTextFilter,
    renderMultiSelectFilter
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';


interface IInvalidRegionsProps {
    invalidRegionsAccounts?: any;
}

const columnDefConst: () => ColDef<any>[] = () => [
    {
        colId: 'accounts',
        headerName: i18n.t('GENERAL.ACCOUNTS', { ns: I18nIntegrations }),
        width: 400,
        suppressSizeToFit: true,
        sortable: true,
        valueFormatter: (params) => `${params.data?.cloudAccountName} (${params.data?.externalAccountId})`,
    },
    {
        colId: 'regions',
        field: 'region',
        headerName: i18n.t('GENERAL.REGION', { ns: I18nIntegrations }),
        width: 200,
        suppressSizeToFit: true,
        valueFormatter: (params) => `${params.data?.region}`,
    },
];

const filterPanelElementsList = (data:any) => { return [
    {
        filterProps: { key: FILTERS_KEYS.ADD_FILTER },
        renderFunction: renderAddFilter,
    },
    {
        filterProps: {
            key: FILTERS_KEYS.FREE_TEXT,
            title: i18n.t('GENERAL.FREE_TEXT', { ns: I18nIntegrations }),
        },
        renderFunction: renderDefaultFreeTextFilter,
    },
    {
        filterProps: {
            initialData: data.map((elem:any) => {return { value: elem.region, count: 0 };}),
            key: 'regions',
            title: i18n.t('GENERAL.REGION', { ns: I18nIntegrations }),
            value: [],
        },
        renderFunction: renderMultiSelectFilter,
    },
    {
        filterProps: {
            key: FILTERS_KEYS.CLEAR_BUTTON,
        },
        renderFunction: renderClearAll,
    }
];};

const AwsInvalidRegions: React.FC<IInvalidRegionsProps> = ({ invalidRegionsAccounts }) => {
    const [data, setData] = useState<any[]>(invalidRegionsAccounts);
    const [filterElement, setFilterElement] = useState(<div></div>);

    const pageSize = 1000;
    const apiRef = useRef<GridApi>();

    const columnDefs = useMemo<ColDef[]>(() => {
        return columnDefConst();
    } , []);

    const onGridReady = useCallback(
        (params: GridReadyEvent<any, any>) => {
            apiRef.current = params.api;
            apiRef.current.setRowData(data);
        },
        [data]);

    const gridOptions: GridOptions<any> = {
        columnDefs,
        onGridReady,
        getRowId: (params) => params.data.region!,
    };

    const onFilterEvent = (action: string, filtersValues: IFiltersValues) => {
        if ( action === FILTER_EVENTS.FILTER_CHANGED ) {
            if ( filtersValues?.regions.length === 0 && !filtersValues['free-text'] ) {
                setData(invalidRegionsAccounts);
                return;
            }

            if ( filtersValues?.regions.length === 0 && filtersValues['free-text'] ) {
                const filteredData = invalidRegionsAccounts.filter((elem:{}) => {return Object.values(elem).some(value => typeof value === 'string' && value.toLowerCase().includes(filtersValues['free-text'].toLowerCase()));});
                setData(filteredData);
                return;
            }

            if ( filtersValues?.regions.length !== 0 && !filtersValues['free-text'] ) {
                const filteredData = invalidRegionsAccounts.filter((elem:{ region:string }) => { return filtersValues?.regions.includes(elem.region);});
                setData(filteredData);
                return;
            }

            if ( filtersValues?.regions.length !== 0 && filtersValues['free-text'] ) {
                const filteredData = invalidRegionsAccounts.filter( (elem:{ region:string }) => { return elem.region.includes(filtersValues['free-text']) && filtersValues?.regions.includes(elem.region);});
                setData(filteredData);
                return;
            }
        }
    };

    const getAggregations = async (filtersValues: IFiltersValues) : Promise<any> => {
        return filtersValues;
    };

    useEffect(() => {
        if (apiRef.current) {
            apiRef.current.setRowData(data);
        }
    }, [data]);

    useEffect(() => {

        const filterPanel = initFilterPanel({
            filterPanelElementsList: filterPanelElementsList(invalidRegionsAccounts),
            getAggregations: getAggregations,
            onFilterChangeCallBack: onFilterEvent,
            shouldUseQueryParams: false,
        });
        setFilterElement(filterPanel);

    },[]);

    return (
        <>
            <div>
                {filterElement}
            </div>
            <div style={{ height: '500px' }}>
                <Table
                    pageSize={pageSize}
                    gridOptions={gridOptions}
                    disableGrouping
                />
            </div>
        </>
    );
};

export default AwsInvalidRegions;