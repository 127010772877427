import React, { useState } from 'react';
import { Button, Input, Modal, Stack } from 'common/design-system/components-v2';
import { storageIdCDRRegex } from '../../AzureIntelligenceOnboarding.const';
import { addStorageToManualList } from '../../AzureIntelligenceOnboarding.actions';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';

interface IAddStorageModalProps {
    isModalOpen: boolean;
    onClose: () => void;
}

const AddStorageModal: React.FC<IAddStorageModalProps> = (props) => {
    const { isModalOpen, onClose } = props;
    const [storageId, setStorageId] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING.STORAGE.ADD_STORAGE' });

    const validateStorageId = (id: string): string | undefined => {
        if (!id) {
            return 'ERROR.REQUIRED';
        }

        if (!storageIdCDRRegex.test(id)) {
            return 'ERROR.WRONG_FORMAT';
        }

        return;
    };

    const handleAddStorage = async () => {
        if (isLoading) {
            return;
        }

        const validationError = validateStorageId(storageId);
        if (validationError) {
            setError(t(validationError));
            return;
        }

        try {
            setIsLoading(true);
            await addStorageToManualList(storageId);
            setStorageId('');
            onClose();
        } catch (err: Error | unknown) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError(t('ERROR.SERVER'));
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStorageId(e.target.value);
        setError(undefined);
    };

    const handleCloseModal = () => {
        setStorageId('');
        setError(undefined);
        onClose();
    };

    const instructions = `${t('SUB_LABEL.1')}\n${t('SUB_LABEL.2')}`;

    return (
        <Modal.ModalDialog isOpen={isModalOpen} width='lg'>
            <Modal.ModalHeader onClose={handleCloseModal} title={t('MODAL_TITLE')} />
            <Modal.ModalContent>
                <Stack>
                    <Input 
                        label={t('LABEL')}
                        type='text' 
                        fullWidth 
                        value={storageId} 
                        onChange={handleOnChange}
                        isError={!!error}
                        helperText={error}
                        disabled={isLoading}
                        required
                        labelProps={{
                            subText: instructions
                        }}
                        onKeyDown={(e) => e.key === 'Enter' && handleAddStorage()}
                    />
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter>
                <Stack direction='row' spacing={1} alignItems='center' fullWidth justifyContent='flex-end'>
                    <Button variant='text' onClick={handleCloseModal} disabled={isLoading}>{t('CANCEL')}</Button>
                    <Button variant='contained' color='brandPrimary' onClick={handleAddStorage} loading={isLoading}>{t('CTA')}</Button>
                </Stack>
            </Modal.ModalFooter>
        </Modal.ModalDialog>
    );
};

export default AddStorageModal;