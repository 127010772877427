import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingState } from 'common/interface/general';
import { Card } from 'common/design-system/components-v2';
import { ITrendBoxItem } from '../../../../../RiskManagement.interface';
import { LevelBoxes } from '../../../../../../../common/erm-components/custom/LevelBoxes/LevelBoxes';
import { I18nRiskNamespace } from '../../../../../consts';
import { WAFAssetsByRiskStyled } from './WAFAssetsByRisk.styled';
import i18n from 'i18next';
import FullSizeSpinner from 'common/erm-components/custom/FullSize/FullSizeSpinner';
import { TrendBoxes } from '../../../TrendBoxes/TrendBoxes';
import WAFFiltersAlert from '../WAFFiltersAlert/WAFFiltersAlert';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { ILevelBoxItem } from '../../../../../../../common/erm-components/custom/LevelBoxes/LevelBox.interface';

const WAFAssetsByRisk: React.FC<{ loadingState: LoadingState, riskDistribution?: ILevelBoxItem[], riskChange?: ITrendBoxItem[], filterValues?: IFiltersValues }> =
    ({ loadingState, riskDistribution, riskChange, filterValues }) => {
        const { t } = useTranslation(I18nRiskNamespace);

        return ( riskDistribution && riskChange &&
        <Card title={t('RISK_MANAGEMENT.DASHBOARD.WAF.ASSETS_BY_RISK.TITLE')}>
            <WAFAssetsByRiskStyled.TopDiv>
                <WAFAssetsByRiskStyled.AlertDiv>
                    <WAFFiltersAlert filterValues={filterValues} />
                </WAFAssetsByRiskStyled.AlertDiv>
                {loadingState === LoadingState.IS_LOADING && <FullSizeSpinner />}
                {loadingState === LoadingState.LOADING_SUCCEEDED &&
                <WAFAssetsByRiskStyled.ContentDiv>
                    <WAFAssetsByRiskStyled.WidgetsWrapperDiv>
                        <LevelBoxes items={riskDistribution} />
                        <TrendBoxes items={riskChange} />
                    </WAFAssetsByRiskStyled.WidgetsWrapperDiv>
                </WAFAssetsByRiskStyled.ContentDiv> }
                {loadingState === LoadingState.LOADING_FAILED &&
                    <WAFAssetsByRiskStyled.FailureDiv fullWidth fullHeight>
                        {i18n.t('COMMON.ERROR_OCCURRED')}
                    </WAFAssetsByRiskStyled.FailureDiv>
                }
            </WAFAssetsByRiskStyled.TopDiv>
        </Card>
        );
    };

export default WAFAssetsByRisk;
