import styled from 'styled-components';
import { Stack, Typography } from 'common/design-system/components-v2';

const TopDiv = styled(Stack)`
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  height: 100%;
  padding-bottom: 16px;
`;

const AlertDiv = styled(Stack)`
  width: 100%;
  margin-bottom: 10px;
`;

const GaugeDiv = styled(Stack)`
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Tip = styled(Typography)`
    border-radius: 8px;
    padding: 12px 16px;
    background: ${({ theme }) => `${theme.palette.surface.brandLight}`};
`;

const FailureDiv = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.status.error};
`;

export const WAFGaugeStyled = {
    TopDiv,
    AlertDiv,
    GaugeDiv,
    Tip,
    FailureDiv
};