import { TFunction } from 'i18next';
import {
    addEditNotificationsModalSections,
    NOTIFICATION_LIST_NAME_IN_REQUEST,
    NOTIFICATIONS_CATEGORIES_IDS,
} from './NotificationsPage.consts';
import {
    FilterSeverityEnum,
    IIntegrationSettings,
    INotification,
    ISelectedCategoriesList,
    ISelectedCategoryObject
} from './Interfaces/NotificationPageInterfaces';
import { IntegrationsDefinition } from 'common/module_interface/settings/integrations/consts';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { getNotificationPageService } from 'common/interface/services';
import { getAllPolicies, PoliciesModulesMap } from 'common/components/policy/utils';
import { IPolicy } from 'common/interface/policy';
import { NotificationStatus } from 'common/interface/notificationPage';
import { getNotificationTypes } from './Components/utils';
import { NOTIFICATIONS_TARGET_IDS_DTO_MAPPER } from 'common/interface/notifications';


export const getSelectedIntegrationsWithMatchListType = (notification: INotification,integrationsDefinitions: IntegrationsDefinition[]) : IIntegrationSettings[] => {
    const selectedIntegrations: IIntegrationSettings[] = [];
    Object.values(NOTIFICATION_LIST_NAME_IN_REQUEST).forEach(notificationType => {
        notification.integrations?.length && notification.integrations.forEach(notificationIntegration => {
            const integration= notification.integrations?.find(integration => integration.id === notificationIntegration.id);
            if(notification.integrationSettingsModel){
                const integrationSettings = notification.integrationSettingsModel[notificationType].find((integration:any) => integration.integrationId === notificationIntegration.id);
                if(integrationSettings && integration){
                    integrationSettings.hasIntegrationPayload = integrationsDefinitions.find(integration => integration.id === notificationIntegration.type)?.hasIntegrationPayload;
                    integrationSettings.hasOutputTypeSelection = integrationsDefinitions.find(integration => integration.id === notificationIntegration.type)?.hasOutputTypeSelection;
                    selectedIntegrations.push({ matchingList: notificationType, integration: integration,integrationSettings: integrationSettings });
                }
            }

        });
    });
    return selectedIntegrations;
};


export const initializeSelectedNotificationCategories = (selectedCategoriesObject: ISelectedCategoriesList, notification?:INotification, initialSelections?: IIntegrationSettings[]) => {
    const integrationsWithErrors : string[] = [];
    if(notification?.statusDetails?.integrationsIssues){
        notification.statusDetails.integrationsIssues.forEach((integrationIssue:any) => {
            integrationsWithErrors.push(integrationIssue.intgrationType);
        });
    }


    addEditNotificationsModalSections.forEach(section => {
        section.categoryList?.forEach(categoryProperties => {
            selectedCategoriesObject[categoryProperties.id] = {} as ISelectedCategoryObject;
            selectedCategoriesObject[categoryProperties.id].notificationType = categoryProperties.type;
            selectedCategoriesObject[categoryProperties.id].hasOutputTypeSelection = categoryProperties.hasOutputTypeSelection;
            if (categoryProperties.integrationsIDs.length === 1) {
                selectedCategoriesObject[categoryProperties.id].selectedIntegration = categoryProperties.integrationsIDs[0];
            }
            if(notification && categoryProperties.id === NOTIFICATIONS_CATEGORIES_IDS.GENERAL_SECURITY_EVENTS_PER_OCCURRENCE){
                selectedCategoriesObject[categoryProperties.id].isChecked = !!notification.sendOnEachOccurrence;
            }
            const matchedIntegrationsForCategory = initialSelections?.find(selection =>
                categoryProperties.integrationsIDs.includes(selection.integration.type) && categoryProperties.type === selection.matchingList);
            if (matchedIntegrationsForCategory){
                selectedCategoriesObject[categoryProperties.id].isChecked = true;
                selectedCategoriesObject[categoryProperties.id].selectedIntegration = matchedIntegrationsForCategory.integration.type;
                selectedCategoriesObject[categoryProperties.id].selectedConfiguration = matchedIntegrationsForCategory.integration.configuration;
                if(selectedCategoriesObject[categoryProperties.id].selectedConfiguration){
                    selectedCategoriesObject[categoryProperties.id].selectedConfiguration!.name = matchedIntegrationsForCategory.integration.name;
                    selectedCategoriesObject[categoryProperties.id].selectedConfiguration!.id = matchedIntegrationsForCategory.integration.id;
                    const integrationName = NOTIFICATIONS_TARGET_IDS_DTO_MAPPER[categoryProperties.id];
                    if(integrationsWithErrors.includes(integrationName)){
                        selectedCategoriesObject[categoryProperties.id].hasError = true;
                    }
                }
                selectedCategoriesObject[categoryProperties.id].hasIntegrationPayload = matchedIntegrationsForCategory.integrationSettings.hasIntegrationPayload;
                selectedCategoriesObject[categoryProperties.id].integrationPayload = matchedIntegrationsForCategory.integrationSettings.payload;
                selectedCategoriesObject[categoryProperties.id].hasOutputTypeSelection = selectedCategoriesObject[categoryProperties.id].hasOutputTypeSelection ||
                                                                                         matchedIntegrationsForCategory.integrationSettings.hasOutputTypeSelection;
                selectedCategoriesObject[categoryProperties.id].outputType = matchedIntegrationsForCategory.integrationSettings.outputType;
            }

        });
    });
};

export const showAlertCriticalEventsOnly = (filter: IFiltersValues)=> {
    return (filter?.severities?.length > 0 && !filter?.severities.includes(FilterSeverityEnum.CRITICAL));
};

export const getBtnStatusByCategoryCondition = (t: TFunction, categories: ISelectedCategoriesList, filter: IFiltersValues)=> {
    const checkCategoryIsValid = (category: string) => (!!categories?.[category]?.isChecked && categories?.[category]?.selectedConfiguration);
    const checkSeveritiesIsEmptyOrCritical = () => !!(filter?.severities?.length === 0 || filter?.severities?.includes(FilterSeverityEnum.CRITICAL));

    return [{
        enabled: categories?.[NOTIFICATIONS_CATEGORIES_IDS.SLACK_CRITICAL_EVENTS]?.isChecked ? checkCategoryIsValid(NOTIFICATIONS_CATEGORIES_IDS.SLACK_CRITICAL_EVENTS) && checkSeveritiesIsEmptyOrCritical(): true,
        tooltip: checkSeveritiesIsEmptyOrCritical() ? t('MODALS.ADD_EDIT_MODAL.TOOLTIPS.SAVE_BUTTON.SELECT_INTEGRATIONS_WITHOUT_CONFIGURATION') : t('MODALS.ADD_EDIT_MODAL.TOOLTIPS.SAVE_BUTTON.FILTER_CRITICAL_EVENTS')
    },{
        enabled: categories?.[NOTIFICATIONS_CATEGORIES_IDS.TEAMS_CRITICAL_EVENTS]?.isChecked ? checkCategoryIsValid(NOTIFICATIONS_CATEGORIES_IDS.TEAMS_CRITICAL_EVENTS) && checkSeveritiesIsEmptyOrCritical(): true,
        tooltip: checkSeveritiesIsEmptyOrCritical() ? t('MODALS.ADD_EDIT_MODAL.TOOLTIPS.SAVE_BUTTON.SELECT_INTEGRATIONS_WITHOUT_CONFIGURATION') : t('MODALS.ADD_EDIT_MODAL.TOOLTIPS.SAVE_BUTTON.FILTER_CRITICAL_EVENTS')
    }];
};

export const getBasicNotificationData = async ()=>{
    return await getNotificationPageService().getAllNotificationsSlim(); //TODO : change to slim and fix the open notification func to get notification data
};

export const enrichNotificationsWithCircuitBreakerData = async (notifications:any[])=>{
    const circuitBreakers = await getNotificationPageService().getAllNotificationsCircuitBreakers(false);
    for (const notification of notifications) {
        if(!circuitBreakers){
            notification.status = NotificationStatus.NA;
        }else{
            notification.status = circuitBreakers.find(circuitBreaker => circuitBreaker.notificationId === notification.id)?.integrationsIssues?.length ? NotificationStatus.ERROR : NotificationStatus.OK;
            notification.statusDetails = circuitBreakers.find(circuitBreaker => circuitBreaker.notificationId === notification.id);
        }

    }
};
export const enrichNotificationsWithAssociations = async (notifications:any[],t:any) => {
    const results: PoliciesModulesMap[] = await getAllPolicies();
    const policiesToCheck = results.map((result) => {
        return{
            policies: result.policies,
            associationString: t(`ASSOCIATIONS.${result.moduleName.toUpperCase()}`)
        };
    });
    const getAssociationsFromPolicies = (notificationId: string) => {

        const associationArray = [];

        const isAssociate = (policies: IPolicy[]) => {
            const associtedPolicies = policies.filter(policy => policy.notificationIds.includes(notificationId));
            return associtedPolicies.length > 0;
        };

        for (const policiesObject of policiesToCheck) {
            if (isAssociate(policiesObject.policies)){
                associationArray.push(policiesObject.associationString);
            }
        }

        return associationArray.length > 0 ? associationArray : [t('ASSOCIATIONS.NONE')];
    };

    for (const notification of notifications) {
        notification.associations = getAssociationsFromPolicies(notification.id!);
        notification.types = getNotificationTypes(notification,t);
    }
};