import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    I18nRiskNamespace
} from '../../../../../consts';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { Card, Spinner, Stack } from 'common/design-system/components-v2';
import { DataAssetStyled } from './DataAssetsCategories.styled';
import i18n from 'i18next';
import useDataAssets from '../useDataAssets';
import DataAssetCard from '../../../../DataAssetCard/DataAssetCard';
import { LoadingState } from 'common/interface/general';
import { DataAssetCategoriesMap, getAllSortedCategories } from '../../../../../DataAssetCategories';

const DataAssetsCategories : React.FC<{ filterValues?: IFiltersValues }> = ({ filterValues }) => {
    const { t } = useTranslation(I18nRiskNamespace);
    const { categories, loadingState, onCategoryClicked } = useDataAssets(filterValues);
    const categoriesArray = getAllSortedCategories();

    return (
        <Card title={t('DATA_SECURITY_DASHBOARD.DATA_INVENTORY.DATA_ASSETS_BY_CATEGORY')}>
            <DataAssetStyled.TopDiv fullHeight>
                {loadingState === LoadingState.IS_LOADING &&
                    <Stack alignItems='center' justifyContent='center' fullWidth fullHeight>
                        <Spinner />
                    </Stack>
                }

                {categories && loadingState === LoadingState.LOADING_SUCCEEDED &&
                    <Stack direction="column" spacing={6} fullWidth fullHeight>
                        {
                            categoriesArray.map((category) =>
                                <DataAssetCard
                                    key={category.id}
                                    cardInfo= {{ ...DataAssetCategoriesMap[category.id], value: categories[category.id] }}
                                    onClick = {onCategoryClicked}
                                />
                            )
                        }
                    </Stack>
                }

                {loadingState === LoadingState.LOADING_FAILED &&
                    <DataAssetStyled.FailureDiv>{i18n.t('COMMON.ERROR_OCCURRED')}</DataAssetStyled.FailureDiv>}
            </DataAssetStyled.TopDiv>
        </Card>
    );
};

export default DataAssetsCategories;