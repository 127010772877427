import React, { useEffect, useState } from 'react';
import {
    AwsSecurityHubStyled,
    AwsSecurityHubNameWrapper,
    AwsSecurityHubAccountSelectionWrapper,
    AwsSecurityHubSettingsAreInvalidInSomeRegions,
    AwsSecurityHubModalFooter,
    ToolTipWrapper
} from './AwsSecurityHub.styled';
import {
    Typography, Dropdown, Input, List, Stack, Tooltip, Icon, Button, Modal, Link
} from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { I18nIntegrations, SUBMIT_STATUS_RESPONSE } from 'common/module_interface/settings/integrations/consts';
import { getIntegrationsService } from 'common/interface/services';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import AwsInvalidRegions from './AwsInvalidRegions';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';


interface IGenericConfigurationProps extends IConfigurationContainerProps{
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration{
    configurationObj: IAzureDefenderForCloudConfigurationObj
}

export interface IAzureDefenderForCloudConfigurationObj {
    ExternalAccountId: string,
    Region: string
    DefaultRegion?: string
    IncludeAllAccounts?: boolean
    IncludeAllRegions?: boolean
}

const AwsSecurityHubComponent : React.FC<IGenericConfigurationProps> = ({ onConfigurationChangeCallBack ,configuration, onConfigurationSaved, viewMode }) => {
    const { t } = useTranslation( I18nIntegrations );

    const selectCloudAccount = t('CONFIGURATIONS.AWS_SECURITY_HUB.SELECT_THE_AWS_ACCOUNT_TO_RECEIVE_THE_ALERTS');
    const selectRegion = t('CONFIGURATIONS.AWS_SECURITY_HUB.SELECT_THE_AWS_REGION_TO_RECEIVE_THE_ALERTS');
    const selectDefaultRegion = t('CONFIGURATIONS.AWS_SECURITY_HUB.SELECT_DEFAULT_REGION');
    const useAccountOriginatedText = t('CONFIGURATIONS.AWS_SECURITY_HUB.USE_THE_AWS_ACCOUNT_FROM_WHICH_THE_ALERT_ORIGINATED');
    const useRegionOriginatedText = t('CONFIGURATIONS.AWS_SECURITY_HUB.USE_THE_AWS_ACCOUNTS_REGION_FROM_WHICH_THE_ALERT_ORIGINATED');
    const configurationInstructionsURL = 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Documentation/Integrations/Integrations.htm?Highlight=security%20hub#AWS_Hub';

    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [cloudAccountInputConfig, SetCloudAccountInputConfig] = useState<string>(selectCloudAccount);
    const [cloudAccountObject, setCloudAccountObject] = useState<any>({});
    const [accountRegionInputConfig, SetAccountRegionInputConfig] = useState<string>(selectRegion);
    const [accountRegionObject, setAccountRegionObject] = useState<any>({});
    const [defaultRegionInputConfig, SetDefaultRegionInputConfig] = useState<string>(configuration?.configurationObj.DefaultRegion || selectDefaultRegion);
    const [cloudAccountList, setCloudAccountList] = useState<any[]>([]);
    const [accountRegionsList, setAccountRegionsList] = useState<any[]>([]);
    const [regionsDefaultList, setRegionsDefaultList] = useState<any[]>([]);
    const [isDropdownOpenAccount, setIsDropdownOpenAccount] = useState<boolean>(false);
    const [isDropdownOpenRegion, setIsDropdownOpenRegion] = useState<boolean>(false);
    const [isDropdownOpenDefaultRegion, setIsDropdownOpenDefaultRegion] = useState<boolean>(false);
    const [accountRegionsApiCallResp, setAccountRegionsApiCallResp] = useState<any[]>([]);
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [getAccountsMessage, setGetAccountsMessage] = useState<string>('');
    const [showInvalidRegionsWarning, setShowInvalidRegionsWarning] = useState<boolean>(false);
    const [invalidRegionsAccounts, setInvalidRegionsAccounts] = useState<any[]>([]);
    const [invalidRegionsAccountsModalOpen, setInvalidRegionsAccountsModalOpen] = useState(false);
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);
    const [showTestPassedMessage, setShowTestPassedMessage] = useState<boolean>(false);

    const viewOnly = Boolean(viewMode);
    const discardChangesButtonHidden = Boolean(idConfiguration);

    const useAccountOriginated = {
        label: useAccountOriginatedText,
        value: useAccountOriginatedText,
        onClick: () => handleCloudAccountChangeDefault()
    };
    const useRegionOriginated = {
        label: useRegionOriginatedText,
        value: useRegionOriginatedText,
        onClick: () => handleAccountRegionChangeDefault()
    };

    const setAccountInit = (awsCloudAccount:any[]) => {
        if (configuration?.configurationObj.ExternalAccountId) {
            const accountDescription = awsCloudAccount && awsCloudAccount.find((account) => account.externalAccountNumber === configuration.configurationObj.ExternalAccountId);
            setCloudAccountObject(accountDescription);
            SetCloudAccountInputConfig(`${accountDescription.name} (${accountDescription.externalAccountNumber})`);
        }
        if (configuration?.configurationObj.IncludeAllAccounts) {
            SetCloudAccountInputConfig(useAccountOriginatedText);
        }
    };
    const setRegionInit = (awsCloudRegions:any[]) => {
        if (configuration?.configurationObj.Region) {
            const regionDescription = awsCloudRegions.find((region:{ region:string }) => region.region === configuration?.configurationObj.Region);
            configuration?.configurationObj.Region && regionDescription && handleAccountRegionChange(regionDescription);
        }
        if (configuration?.configurationObj.IncludeAllRegions) {
            SetAccountRegionInputConfig(useRegionOriginatedText);
        }
    };

    const getAwsCloudAccounts = async () => {
        try {
            const awsCloudAccount = await getIntegrationsService().getAwsCloudAccount();
            const apiAccountList = awsCloudAccount && awsCloudAccount.map((account: { name: string; externalAccountNumber: string }) => {
                const selectedAccount = account.name ? `${account.name} (${account.externalAccountNumber})` : account.externalAccountNumber;
                return { label: selectedAccount, value: selectedAccount, onClick: () => {handleCloudAccountChange(account);} };
            });
            setAccountInit(awsCloudAccount);
            apiAccountList.unshift(useAccountOriginated);
            setCloudAccountList(apiAccountList);
        } catch (error:any) {
            setGetAccountsMessage(error?.message || error?.response?.data || t('GENERAL.NETWORK_ERROR'));
        }
    };

    const awsAccountRegion = async () => {
        const awsVendor = 'aws';
        try {
            const allCloudRegions = await getIntegrationsService().awsAccountRegion();
            const awsCloudRegions = allCloudRegions.filter((elem: any) => elem.vendor === awsVendor)[0].supportedRegions;
            setAccountRegionsApiCallResp(awsCloudRegions);
            const apiRegionList = awsCloudRegions && awsCloudRegions.map((region: { description: string }) => {
                return { label: region?.description, value: region?.description, onClick: () => region.description && handleAccountRegionChange(region) };
            });
            const defaultRegionList = awsCloudRegions && awsCloudRegions.map((region: { description: string }) => {
                return { label: region?.description, value: region?.description, onClick: () => region.description && handleDefaultRegionChange(region) };
            });
            apiRegionList.unshift(useRegionOriginated);
            setAccountRegionsList(apiRegionList);
            setRegionsDefaultList(defaultRegionList);
            setRegionInit(awsCloudRegions);
        } catch (error:any) {
            setGetAccountsMessage(error.message || error?.response?.data || t('GENERAL.NETWORK_ERROR'));
        }
    };

    useEffect(() => {
        checkChangesMade();
    }, [nameInputConfig,
        cloudAccountInputConfig,
        accountRegionInputConfig,
        defaultRegionInputConfig,
        idConfiguration]);

    useEffect(() => {
        getAwsCloudAccounts();
        awsAccountRegion();
    }, []);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setNameInputConfig(textInput);
    };

    const closeDropboxAccount = () => {
        setShowInvalidRegionsWarning(false);
        setIsDropdownOpenAccount(false);
    };

    const closeDropboxRegion = () => {
        setShowInvalidRegionsWarning(false);
        setIsDropdownOpenRegion(false);
    };

    const closeDropboxDefaultRegion = () => {
        setShowInvalidRegionsWarning(false);
        setIsDropdownOpenDefaultRegion(false);
    };

    const handleCloudAccountChange = (account: { name:string, externalAccountNumber:string }) => {
        setCloudAccountObject(account);
        SetCloudAccountInputConfig(`${account.name} (${account.externalAccountNumber})`);
        closeDropboxAccount();
    };

    const handleCloudAccountChangeDefault = () => {
        SetCloudAccountInputConfig(useAccountOriginatedText);
        closeDropboxAccount();
    };

    const handleAccountRegionChange = (region: { description:string }) => {
        setAccountRegionObject(region);
        SetAccountRegionInputConfig(region.description);
        closeDropboxRegion();
    };

    const handleAccountRegionChangeDefault = () => {
        SetAccountRegionInputConfig(useRegionOriginatedText);
        closeDropboxRegion();
    };

    const handleDefaultRegionChange = (region: { description:string }) => {
        SetDefaultRegionInputConfig(region.description);
        closeDropboxDefaultRegion();
    };

    const regionDescriptionToRegionCode = (regionDescription: string) => {
        const regionCode = accountRegionsApiCallResp.find((region) => region.description === regionDescription)?.region;
        return regionCode;
    };
    const regionCodeToRegionDescription = (regionCode?: string) => {
        const regionDescription = regionCode && accountRegionsApiCallResp.find((region) => region.region === regionCode)?.description;
        return regionDescription;
    };

    const testEnvironments = async () => {
        setShowInvalidRegionsWarning(false);
        setShowTestPassedMessage(false);
        try {
            const resp = await getIntegrationsService().testSecurityHub(cloudAccountObject.id, accountRegionObject.region);
            if (resp && resp?.allEnabled) {
                setShowTestPassedMessage(true);
                return true;
            } else {
                setShowInvalidRegionsWarning(true);
                resp && resp?.testResults.map((result: { cloudAccountName: string; externalAccountId: string; region: string; }) => {
                    result.region = accountRegionsApiCallResp.find((region) => region.region === result.region)?.description;
                    setInvalidRegionsAccounts(resp && resp?.testResults);
                    return false;
                });
            }
        } catch (error:any) {
            setGetAccountsMessage(error.message || error?.response?.data || t('GENERAL.NETWORK_ERROR'));
            return false;
        }
    };

    const checkMissingRequiredInput = () => {
        const variableNotChanged = (
            nameInputConfig === '' ||
            cloudAccountInputConfig === selectCloudAccount ||
            accountRegionInputConfig === selectRegion ||
            (accountRegionInputConfig === useRegionOriginatedText && defaultRegionInputConfig === selectDefaultRegion)
        );
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected = (
            nameInputConfig !== configuration?.name ||
            cloudAccountInputConfig !== configuration?.configurationObj.ExternalAccountId ||
            accountRegionInputConfig !== regionCodeToRegionDescription(configuration?.configurationObj.Region)
        );
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const handleDiscardChanges = () => {
        setNameInputConfig(configuration?.name || '');
        SetCloudAccountInputConfig(configuration?.configurationObj.ExternalAccountId || selectCloudAccount);
        SetAccountRegionInputConfig(configuration?.configurationObj.Region ? regionCodeToRegionDescription(configuration.configurationObj.Region) || selectRegion: selectRegion);
    };

    const changeInvalidRegionAccountModal = (state:boolean) => {
        setInvalidRegionsAccountsModalOpen(state);
    };

    const testConfigurationData = async () => {
        return await testEnvironments();
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        const testPassed = await testConfigurationData();
        const configurationObj : { externalAccountId?: string, region?: string, defaultRegion?: string|null, includeAllAccounts?: boolean, includeAllRegions?: boolean } = { defaultRegion: null, includeAllAccounts: false, includeAllRegions: false };
        cloudAccountInputConfig === useAccountOriginatedText ? configurationObj.includeAllAccounts = true : configurationObj.externalAccountId = cloudAccountObject.externalAccountNumber;
        accountRegionInputConfig === useRegionOriginatedText ? configurationObj.includeAllRegions = true : configurationObj.region = regionDescriptionToRegionCode(accountRegionInputConfig);
        defaultRegionInputConfig && (defaultRegionInputConfig !== selectDefaultRegion ) && (configurationObj.defaultRegion = regionDescriptionToRegionCode(defaultRegionInputConfig));
        if (testPassed) {
            try {
                const resp = onConfigurationSaved && await onConfigurationSaved(nameInputConfig, configurationObj, idConfiguration);
                resp?.id && setIdConfiguration(resp.id);
                setResponseMessageSuccess(true);
                onConfigurationChangeCallBack && onConfigurationChangeCallBack();
                setIsButtonDisabled(true);
                return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
            } catch (error:any) {
                setResponseMessageSuccess(false);
                return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
            }
        } else {
            setResponseMessageSuccess(false);
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_TEST };
        }
    };

    return (
        <AwsSecurityHubStyled data-aid={'aws-security-hub'}>
            <AwsSecurityHubNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input type="text" data-aid='nameInput' value={nameInputConfig} placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange} autoFocus={true} disabled={viewOnly}/>
            </AwsSecurityHubNameWrapper>
            <AwsSecurityHubAccountSelectionWrapper>
                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.AWS_SECURITY_HUB.SELECT_THE_AWS_ACCOUNT_TO_RECEIVE_THE_ALERTS')}</Typography>
                <Dropdown
                    label={cloudAccountInputConfig}
                    open={isDropdownOpenAccount}
                    onStateChange={setIsDropdownOpenAccount}
                    buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { maxWidth: 'none', display: 'flex' } }}
                    maxHeight={200}
                    placement="bottom-start"
                >
                    <List
                        options={cloudAccountList}
                        disabled={viewOnly}
                    />
                </Dropdown>
            </AwsSecurityHubAccountSelectionWrapper>
            <AwsSecurityHubAccountSelectionWrapper>
                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.AWS_SECURITY_HUB.SELECT_THE_AWS_REGION_TO_RECEIVE_THE_ALERTS')}</Typography>
                <Dropdown
                    label={accountRegionInputConfig}
                    open={isDropdownOpenRegion}
                    onStateChange={setIsDropdownOpenRegion}
                    buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { maxWidth: 'none', display: 'flex' } }}
                    maxHeight={200}
                    placement="bottom-start"
                >
                    <List
                        options={accountRegionsList}
                        disabled={viewOnly}
                    />
                </Dropdown>
            </AwsSecurityHubAccountSelectionWrapper>
            { (accountRegionInputConfig === useRegionOriginatedText) && <AwsSecurityHubAccountSelectionWrapper>
                <Stack direction={'row'} alignItems={'center'}>
                    <Typography variant='subtitleLg'>{selectDefaultRegion}</Typography>
                    <ToolTipWrapper>
                        <Tooltip content={t('CONFIGURATIONS.AWS_SECURITY_HUB.THE_SELECTED_REGION_WILL_BE_USED_IN_CASE_OF_A_FINDING_WHICH_DOES_NOT_BELONG_TO_AN_ASSET_WITH_A_SPECIFIC_REGION')} placement="top">
                            <Icon color="alert" name="info" size={12}/>
                        </Tooltip>
                    </ToolTipWrapper>
                </Stack>

                <Dropdown
                    label={defaultRegionInputConfig}
                    open={isDropdownOpenDefaultRegion}
                    onStateChange={setIsDropdownOpenDefaultRegion}
                    buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { maxWidth: 'none', display: 'flex' } }}
                    maxHeight={200}
                    placement="bottom-start"
                >
                    <List
                        options={regionsDefaultList}
                        disabled={viewOnly}
                    />
                </Dropdown>
            </AwsSecurityHubAccountSelectionWrapper>}
            <Stack margin={[0,0,0,5]}>
                <Typography elementType='h5' color={'alert'}>{getAccountsMessage}</Typography>
            </Stack>
            <Stack direction={'row'} margin={[0,0,2,5]} >
                <Button size='medium' color='normal' data-aid='testButton'
                    onClick={testConfigurationData} active={true} loading={false}
                    disabled={viewOnly || (cloudAccountInputConfig===selectCloudAccount) || (accountRegionInputConfig===selectRegion) }
                >{t('GENERAL.TEST')}
                </Button>
                { showTestPassedMessage &&
                    <Stack margin={[2,6]} >
                        <Typography variant='body' color={'success'}>{t('CONFIGURATIONS.AWS_SECURITY_HUB.YOUR_AWS_SECURITY_HUB_SETTINGS_ARE_VALID')}</Typography>
                    </Stack>
                }
            </Stack>
            { showInvalidRegionsWarning &&
                <AwsSecurityHubSettingsAreInvalidInSomeRegions>
                    <Stack direction={'row'} alignItems={'center'} padding={2} margin={[2,0]} className={'warningMessage'}>
                        <Icon name="info" size={20} />
                        <Typography variant='bodyLg' color={'alert'} className={'marginLeftSpace'}>{t('CONFIGURATIONS.AWS_SECURITY_HUB.AWS_SECURITY_HUB_ARE_INVALID_IN_SOM_REGIONS_OR_ACCOUNTS')}</Typography>
                    </Stack>
                    <Typography variant='bodyLg' color={'strong'}>{t('GENERAL.DETAILS')}:</Typography>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Typography variant='bodyLg'>{t('CONFIGURATIONS.AWS_SECURITY_HUB.LIST_OF')}</Typography>
                        <Button size='medium' color='normal' className={'marginLeftSpace'} onClick={()=> changeInvalidRegionAccountModal(true)}>
                            {t('CONFIGURATIONS.AWS_SECURITY_HUB.INVALID_REGIONS OR ACCOUNTS')}
                        </Button>
                    </Stack>
                </AwsSecurityHubSettingsAreInvalidInSomeRegions>
            }
            <Modal.ModalDialog width={'lg'} isOpen={invalidRegionsAccountsModalOpen} >
                <Modal.ModalHeader title={t('CONFIGURATIONS.AWS_SECURITY_HUB.REGIONS_AND_ACCOUNTS_WITH_INVALID_AWS_SECURITY_HUB_CONFIGURATIONS')}
                    onClose={()=> changeInvalidRegionAccountModal(false)} />
                <Modal.ModalContent>
                    <AwsInvalidRegions invalidRegionsAccounts={invalidRegionsAccounts}/>
                </Modal.ModalContent>
                <Modal.ModalFooter>
                    <AwsSecurityHubModalFooter>
                        <Link externalUrl={configurationInstructionsURL}>
                            <Typography variant='bodyLg' color='link'>{t('CONFIGURATIONS.AWS_SECURITY_HUB.CONFIGURATION_INSTRUCTIONS')}</Typography>
                        </Link>
                    </AwsSecurityHubModalFooter>
                </Modal.ModalFooter>
            </Modal.ModalDialog>

            { GenericCancelSubmitButtonWrapper({ handleDiscardChanges ,discardChangesButtonHidden, isButtonDisabled, submitForm, viewOnly, responseMessageSuccess }) }

        </AwsSecurityHubStyled>
    );
};

export default AwsSecurityHubComponent;