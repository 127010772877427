import React, { useEffect, useState } from 'react';
import {
    GcpSecurityCommandCenterStyled,
    GcpSecurityCommandCenterNameWrapper,
    CloudAccountSelectionWrapper,
    EnableCloudWrapper,
    GcpSecurityCommandCenterSourceWrapper,
    GcpCloudSecuritySource
} from './GcpSecurityCommandCenter.styled';
import { Stack, Typography, Dropdown, Input, List, Button } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { COMPONENT_TEXT_COLOR, I18nIntegrations, SUBMIT_STATUS_RESPONSE } from 'common/module_interface/settings/integrations/consts';
import { getIntegrationsService } from 'common/interface/services';
import { IConfigurationContainerProps } from 'common/module_interface/settings/integrations/configurations.interface';
import GenericCancelSubmitButtonWrapper from 'common/components/SubmitButtonWrapper/SubmitButtonWrapper';
import { IConfiguration } from 'common/module_interface/settings/integrations/Integrations';


interface IGenericConfigurationProps extends IConfigurationContainerProps{
    configuration?: IGenericConfiguration;
}

export interface IGenericConfiguration extends IConfiguration{
    configurationObj: IAzureDefenderForCloudConfigurationObj
}

export interface IAzureDefenderForCloudConfigurationObj {
    ProjectId?: string,
    SourceId?: string
}

interface IGcpAccount {
    name: string,
    projectId:string,
    id:string
}
interface IGcpAccountDetails {
    clientEmail?: string,
    id?: string,
    name?: string,
    projectId?: string
}

const GcpSecurityCommandCenterComponent : React.FC<IGenericConfigurationProps> = ({ onConfigurationChangeCallBack ,configuration, onConfigurationSaved, viewMode }) => {
    const { t } = useTranslation( I18nIntegrations );

    const [nameInputConfig, setNameInputConfig] = useState<string>(configuration?.name || '');
    const [cloudAccountInputConfig, SetCloudAccountInputConfig] = useState<string>( t('GENERAL.SELECT'));
    const [selectedCloudAccount, setSelectedCloudAccount] = useState< IGcpAccount >();
    const [cloudSecurityObjects, setCloudSecurityObjects] = useState<any[]>([]);
    const [accountDetails, setAccountDetails] = useState< IGcpAccountDetails >();
    const [cloudAccountDropdownList, setCloudAccountDropdownList] = useState<any[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [idConfiguration, setIdConfiguration] = useState<string>(configuration?.id || '');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [cloudSecuritySourceConfig, setCloudSecuritySourceConfig] = useState<string>(configuration?.configurationObj?.SourceId || '');
    const [responseMessageSuccess, setResponseMessageSuccess] = useState<boolean>(true);
    const [cloudSecuritySourceTestButtonError, setCloudSecuritySourceTestButtonError] = useState<string>('');

    const viewOnly = Boolean(viewMode);
    const discardChangesButtonHidden = Boolean(idConfiguration);

    useEffect(() => {
        checkChangesMade();
    }, [nameInputConfig,
        cloudAccountInputConfig,
        cloudSecuritySourceConfig,
        idConfiguration]);

    useEffect(() => {
        getAzureCloudAccounts();
    }, []);

    const checkMissingRequiredInput = () => {
        const variableNotChanged = (
            nameInputConfig === '' ||
            cloudAccountInputConfig === t('GENERAL.SELECT') ||
            cloudSecuritySourceConfig === ''
        );
        setIsButtonDisabled(variableNotChanged);
    };

    const checkChangesMade = () => {
        const changesDetected = (
            nameInputConfig !== configuration?.name ||
            cloudSecuritySourceConfig !== configuration?.configurationObj.SourceId ||
            selectedCloudAccount?.id !== configuration?.configurationObj.ProjectId
        );
        setIsButtonDisabled(!changesDetected);
        checkMissingRequiredInput();
    };

    const updateAccountDetails = async (configurationAccountId:string) => {
        const selectedAccountDetails = await getIntegrationsService().getSingleGcpCloudAccount(configurationAccountId);
        setAccountDetails(selectedAccountDetails);
    };

    const setGcpAccountInitialValue = async (accounts: IGcpAccount[]) => {
        if (accounts && accounts.length > 0 && configuration?.configurationObj.ProjectId) {
            const configurationAccount = accounts.find((account: any) => account.id === configuration?.configurationObj.ProjectId);
            if (configurationAccount) {
                setSelectedCloudAccount(configurationAccount);
                SetCloudAccountInputConfig(`${configurationAccount.name} (${configurationAccount.projectId})`);
                await updateAccountDetails(configurationAccount.id);
            }
        }
    };

    const getAzureCloudAccounts = async () => {
        try {
            const gcpCloudAccounts = await getIntegrationsService().getGcpCloudAccounts(false);
            setCloudSecurityObjects(gcpCloudAccounts);
            setCloudAccountDropdownList(gcpCloudAccounts && gcpCloudAccounts.map((account: IGcpAccount) => {
                return { label: `${account.name} (${account.projectId})`, value: `${account.name} (${account.projectId})`, onClick: () => {handleCloudAccountChange(account);} };
            }));
            await setGcpAccountInitialValue(gcpCloudAccounts);
        } catch (error:any) {
            console.log('error', error);
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const textInput = event.target.value;
        setNameInputConfig(textInput);
    };

    const closeDropbox = () => {
        setIsDropdownOpen(false);
    };

    const handleCloudAccountChange = async (account: IGcpAccount) => {
        setCloudSecuritySourceConfig('');
        setSelectedCloudAccount(account);
        SetCloudAccountInputConfig(`${account.name} (${account.projectId})`);
        try {
            await updateAccountDetails(account.id);
        } catch (error:any) {
            console.log('error', error);
        }
        closeDropbox();
    };

    const handleDiscardChanges = async () => {
        setNameInputConfig(configuration?.name || '');
        setCloudSecuritySourceConfig(configuration?.configurationObj.SourceId || '');
        const initialAccount = cloudSecurityObjects.find((account: { id?: string }) => account?.id === configuration?.configurationObj.ProjectId);
        setSelectedCloudAccount(initialAccount);
        SetCloudAccountInputConfig(`${initialAccount?.name} (${initialAccount?.projectId})`);
        await updateAccountDetails(initialAccount?.id);
    };

    const handleCloudSecuritySourceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCloudSecuritySourceTestButtonError('');
        const textInput = event.target.value;
        setCloudSecuritySourceConfig(textInput);
    };

    const testSource = async () => {
        setCloudSecuritySourceTestButtonError('');
        try {
            const testButtonResponse = await getIntegrationsService().testGcpCloudSecuritySource(cloudSecuritySourceConfig, accountDetails?.id);
            setCloudSecuritySourceTestButtonError( testButtonResponse || t('GENERAL.NETWORK_ERROR'));
            setResponseMessageSuccess(true);
            return true;
        } catch (error:any) {
            setCloudSecuritySourceTestButtonError( error || t('GENERAL.NETWORK_ERROR'));
            setResponseMessageSuccess(false);
            return false;
        }
    };

    const testConfigurationData = async () => {
        return await testSource();
    };

    const submitForm = async (): Promise<{ Status: SUBMIT_STATUS_RESPONSE; Message?: string }> => {
        const testPassed = await testConfigurationData();
        if (testPassed) {
            try {
                const resp = onConfigurationSaved && await onConfigurationSaved(nameInputConfig,
                    { ProjectId: accountDetails?.id, SourceId: cloudSecuritySourceConfig },
                    idConfiguration);
                resp?.id && setIdConfiguration(resp.id);
                onConfigurationChangeCallBack && onConfigurationChangeCallBack();
                setIsButtonDisabled(true);
                return { Status: SUBMIT_STATUS_RESPONSE.SUCCESS };
            } catch (error:any) {
                setResponseMessageSuccess(false);
                return { Status: SUBMIT_STATUS_RESPONSE.FAIL_SAVE, Message: error.message || error.title };
            }
        } else {
            return { Status: SUBMIT_STATUS_RESPONSE.FAIL_TEST };
        }
    };

    return (
        <GcpSecurityCommandCenterStyled data-aid={'gcp-security-command-center-component'}>
            <GcpSecurityCommandCenterNameWrapper>
                <Typography variant='subtitleLg'>{t('GENERAL.NAME')}</Typography>
                <Input type="text" data-aid='nameInput' value={nameInputConfig} placeholder={t('GENERAL.TYPE_HERE')}
                    onChange={handleNameChange} autoFocus={true} disabled={viewOnly}/>
            </GcpSecurityCommandCenterNameWrapper>
            <CloudAccountSelectionWrapper>
                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT')}</Typography>
                <Dropdown
                    label={cloudAccountInputConfig}
                    open={isDropdownOpen}
                    onStateChange={setIsDropdownOpen}
                    buttonProps={{ color: 'normal', variant: 'outlined', size: 'medium', style: { maxWidth: 'none', display: 'flex' } }}
                    placement="bottom-start"
                >
                    <List
                        options={cloudAccountDropdownList}
                        disabled={viewOnly}
                    />
                </Dropdown>
            </CloudAccountSelectionWrapper>
            {
                selectedCloudAccount && accountDetails &&
                <Stack>
                    <EnableCloudWrapper>
                        <Typography variant='bodyLg'>{t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_1')}</Typography>
                        <Typography variant='bodyLg'>{t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_2')}</Typography>
                        <Typography variant='bodyLg'>{t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_3')}</Typography>
                        <Typography variant='bodyLg'>{t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_4')}</Typography>
                        <Typography variant='bodyLg'>{t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_5')}<i>{selectedCloudAccount.name}</i></Typography>
                        <Typography variant='bodyLg'>{t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.PROJECT_STEP_6')}<i>{accountDetails.clientEmail}</i></Typography>
                    </EnableCloudWrapper>

                    <Stack>
                        <GcpSecurityCommandCenterSourceWrapper>
                            <GcpCloudSecuritySource>
                                <Typography variant='subtitleLg'>{t('CONFIGURATIONS.GCP_SECURITY_COMMAND_CENTER.CLOUD_SECURITY_SOURCE')}</Typography>
                                <Input type="text" value={cloudSecuritySourceConfig} fullWidth={true} placeholder={t('GENERAL.TYPE_HERE')}
                                    onChange={handleCloudSecuritySourceChange} disabled={viewOnly}/>
                            </GcpCloudSecuritySource>
                            <Stack margin={[0,0,0,2]} >
                                <Button
                                    size='medium' color='normal' data-aid='testButton' active={true} loading={false}
                                    disabled={viewOnly || !cloudSecuritySourceConfig} onClick={testConfigurationData}>{t('GENERAL.TEST')}
                                </Button>
                            </Stack>
                        </GcpSecurityCommandCenterSourceWrapper>
                        <Stack margin={[0,6]}>
                            <Typography elementType='h5' color={responseMessageSuccess ? COMPONENT_TEXT_COLOR.SUCCESS : COMPONENT_TEXT_COLOR.ALERT}>{cloudSecuritySourceTestButtonError}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            }
            { GenericCancelSubmitButtonWrapper({ handleDiscardChanges ,discardChangesButtonHidden, isButtonDisabled, submitForm, viewOnly, responseMessageSuccess }) }

        </GcpSecurityCommandCenterStyled>
    );
};

export default GcpSecurityCommandCenterComponent;