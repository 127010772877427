import { FC } from 'react';
import { CpIcon, CpSpinner } from '@dome9/components/react/components';
import { BladeState, Ruleset } from '../helpers/aws.interface';
import { COMPLIANCE_ICONS_MAPPING } from '../helpers/aws.const';
import { TextField } from '@dome9/berries/react-components';

const getGeneralStatusError = (props: any) => {
    const agGridAllRowControl = props?.api?.rowRenderer?.allRowCtrls;

    const filteredGeneralStatus = agGridAllRowControl.filter((row: any) => {
        return row?.rowNode?.data?.feature.toLowerCase() === 'general';
    });

    return filteredGeneralStatus[0]?.rowNode?.data?.status === 'ERROR';
};
export const getIcon = (type: BladeState) => {
    switch (type) {
        case 'ERROR':
            return 'error';
        case 'ACTIVE':
            return 'success';
        case 'PENDING':
            return 'loader';
        case 'INACTIVE':
            return 'inactive';
        default:
            return 'error';
    }
};

export const getComplianceIcon = (ruleset: Ruleset) => {
    return COMPLIANCE_ICONS_MAPPING[String(ruleset.icon)] || 'cloudGuard';
};

export const CheckedCorner: FC = () => (
    <div className='aws-onboarding-welcome-card-checked'>
        <CpIcon icon='check' className='aws-onboarding-welcome-card-checked-icon' />
    </div>
);

export const StatusRenderer: FC = (props: any) => {
    return (
        <div className='aws-onboarding-content-wrapper-summary'>
            {props.value !== 'PENDING' && (
                <CpIcon
                    icon={getIcon(props.value)}
                    className={`aws-onboarding-summary-status-icon-${props.value} aws-onboarding-welcome-info-content aws-onboarding-summary-info-icon`}
                />
            )}
            {props.value === 'PENDING' && getGeneralStatusError(props) && (
                <CpIcon
                    icon={'error'}
                    className={'aws-onboarding-summary-status-icon-ERROR aws-onboarding-welcome-info-content aws-onboarding-summary-info-icon'}
                />
            )}
            {props.value === 'PENDING' && !getGeneralStatusError(props) && (
                <CpSpinner className='aws-onboarding-welcome-info-content aws-onboarding-summary-info-icon' />
            )}
            <div className='aws-onboarding-welcome-info-content aws-onboarding-summary-info-content'>
                {props.data.statusMessage}
            </div>
        </div>
    );
};

export const RulesetRenderer: FC = (props: any) => {
    return (
        <div className='aws-onboarding-posture-management-ruleset-renderer-wrapper'>
            <CpIcon
                icon={getComplianceIcon(props.data)}
                className={'aws-onboarding-posture-management-ruleset-renderer-wrapper-info-icon'}
            />

            <div
                className='aws-onboarding-welcome-info-content aws-onboarding-summary-info-content aws-onboarding-posture-management-ruleset-renderer-wrapper-cell-text'>
                {props.data.name}
            </div>
        </div>
    );
};

export const ParameterDisplay: FC<{ title: string, value: string | number }> = ({ title, value }) => {
    return <div className='flex flex-row justify-between my-8'>
        <div className='label font-semibold'>
            {title}
        </div>
        <TextField
            value={value}
            disabled
            className='w-[70ch]'
        />
    </div>;
};