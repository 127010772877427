import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { IAutoAction } from '../AutoActions.interface';
import { IMultiChipCellProps, MultiChipCellContent } from 'common/erm-components/custom/MultiChipCellContent/MultiChipCellContent';

export const AutoActionIntegrationsCellRender: React.FC<ICellRendererParams<IAutoAction>> = (params) => {
    const autoAction: IAutoAction | undefined = params.data;
    if (!autoAction) {
        return '';
    }

    const propsList: IMultiChipCellProps[] = autoAction.integrations.map(integration => {
        return {
            label: integration.name,
            leadingIconProps: integration.icon ? { vendorNameOrPath: integration.icon } : undefined,
        };
    });
    return <MultiChipCellContent propsList={propsList} />;
};
