import { FC, useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import {
    INotification,
    ISelectedCategoriesList, ISelectedCategoryObject,
} from '../../Interfaces/NotificationPageInterfaces';
import Modal from 'common/design-system/components-v2/Modal';
import {
    Button,
    Stack,
    Checkbox,
    Icon,
    Tooltip,
    Spinner,
    Typography,
    Link,
    TextArea
} from 'common/design-system/components-v2';
import Input from 'common/design-system/components-v2/Input';
import { getIntegrationsService, getNotificationPageService } from 'common/interface/services';
import { ModalSectionTitle } from '../../notificationPage.styled';
import {
    I18nNotifications,
    CRON_EXPRESSION_DOCUMENTATION_LINK,
    addEditNotificationsModalSections,
    BUTTON_STATE,
    defaultNotificationId
} from '../../NotificationsPage.consts';
import { useTranslation } from 'react-i18next';
import { useDrawerHandler } from 'common/components/DrawerInfra/Drawer/UseDrawerHandler';
import { showDrawer } from 'common/components/DrawerInfra/Drawer/Drawer.utils';
import { INTEGRATION_DRAWER, INTEGRATIONS_IDS } from 'common/module_interface/settings/integrations/consts';
import { INTEGRATIONS_DRAWER_VIEWS } from '../../../Integrations/Configurations/ConfigurationDrawerViews';
import {
    IConfiguration,
    IGetAllConfigurationsModel,
} from 'common/module_interface/settings/integrations/Integrations';
import AddEditModalFilterPanel from './AddEditModalFilterPanel';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import ScheduleReport, { IScheduleReportObject } from './ScheduleReport/ScheduleReport';
import {
    initializeSelectedNotificationCategories,
    getSelectedIntegrationsWithMatchListType,
    getBtnStatusByCategoryCondition
} from '../../NotificationsUtilsFunctions';
import { IntegrationsCategoriesComponent } from './IntegrationsCategoriesComponent';
import { IntegrationsRegistry } from 'common/module_interface/settings/integrations/IntegrationsRegistry';
import { integrationNotificationReadOnly } from 'common/utils/userPermissionUtils';

interface INotificationAddEditModalProps{
    isOpen: boolean,
    onClose: () => void,
    onSave: () => Promise<void>,
    notification?: INotification,
    isReadOnly?: boolean,
}

export interface INewRawNotification{
    name: string,
    id?: string,
    filter?: any,
    description: string,
    alertConsole: boolean,
    selectedCategories: ISelectedCategoriesList,
    scheduledReport: IScheduleReportObject,
}
const CONFIGURATION_ADDED_HANDLER_ID = 'CONFIGURATION_ADDED_HANDLER';

const getNormalizedErrorMessage = (error:{ statusCode:number, message:string },t:any) :string => {
    switch (error.statusCode) {
        case 400:
            return error.message || t('MODALS.ADD_EDIT_MODAL.ERRORS.BAD_REQUEST');
        default:
            return t('MODALS.ADD_EDIT_MODAL.ERRORS.GENERAL');
    }
};
const NotificationAddEditModal: FC<INotificationAddEditModalProps> = ({ isOpen,onClose, onSave, notification, isReadOnly= false }) => {
    const integrationsDefinitions = IntegrationsRegistry.getIntegrationsDefinitions();
    const [configurations, setConfigurations] = useState({} as IGetAllConfigurationsModel);
    const [notificationName, setNotificationName] = useState(notification?.name || '');
    const [notificationDescription, setNotificationDescription] = useState(notification?.description || '');
    const [isAlertConsoleSelected, setIsAlertConsoleSelected] = useState(notification?.id ? notification.alertsConsole : true);
    const [selectedCategories, setSelectedCategories] = useState({} as ISelectedCategoriesList);
    const scheduleReport = notification?.integrationSettingsModel?.scheduledIntegrationSettings?.length ? notification?.integrationSettingsModel?.scheduledIntegrationSettings[0] : {} as IScheduleReportObject ;
    const [isScheduleReportSelected, setIsScheduleReportSelected] = useState(!!scheduleReport.integrationId);
    const [scheduledReportSelections, setScheduledReportSelections] = useState(scheduleReport.integrationId ? { configurationId: scheduleReport.integrationId, outputType: scheduleReport.outputType, cronExpression: scheduleReport.cronExpression } : {} as IScheduleReportObject);
    const [isModalBusy, setIsModalBusy] = useState(false);
    const [saveFailedErrorMessage, setSaveFailedErrorMessage] = useState('');
    const [notificationsModalSections,setNotificationsModalSections] = useState(addEditNotificationsModalSections);
    const theme = useTheme();
    const isDefaultNotification = notification?.id === defaultNotificationId;
    const viewMode = integrationNotificationReadOnly();

    const getInitialFilterState= () => {
        return notification?.integrationSettingsModel?.singleNotificationIntegrationSettings?.[0]?.filter ||
            notification?.integrationSettingsModel?.reportsIntegrationSettings?.[0]?.filter || {} ;
    };

    const [immediateNotificationsFilter, setImmediateNotificationsFilter] = useState(notification?.id ? getInitialFilterState() : {} as IFiltersValues);
    const { t } = useTranslation( I18nNotifications );

    const isSelectionExist = useCallback(()=>{
        const selectedConfigurations = Object.values(selectedCategories).filter(category => category.isChecked && Boolean(category.selectedConfiguration));
        return isAlertConsoleSelected ||
                (!!scheduledReportSelections.configurationId && !!scheduledReportSelections.outputType) ||
                selectedConfigurations.length > 0;
    },[selectedCategories,isAlertConsoleSelected,scheduledReportSelections]);

    const getSaveButtonStatusObject = () => {
        const statusByCategoryCondition = isModalBusy ? [] : getBtnStatusByCategoryCondition(t, selectedCategories,immediateNotificationsFilter);
        const conditions = [{
            enabled: notificationName !== '',
            tooltip: t('MODALS.ADD_EDIT_MODAL.TOOLTIPS.SAVE_BUTTON.NAME_REQUIRED')
        },{
            enabled: isSelectionExist(),
            tooltip: t('MODALS.ADD_EDIT_MODAL.TOOLTIPS.SAVE_BUTTON.SELECT_CONFIGURATION')
        },{
            enabled: !isDefaultNotification,
            tooltip: t('MODALS.ADD_EDIT_MODAL.TOOLTIPS.SAVE_BUTTON.DEFAULT_NOTIFICATION')
        },{
            enabled: !isModalBusy,
            tooltip: t('MODALS.ADD_EDIT_MODAL.TOOLTIPS.SAVE_BUTTON.SAVING')
        }, ...statusByCategoryCondition];
        return isReadOnly ? { isDisabled: true } :{
            isDisabled: conditions.some(condition => !condition.enabled),
            tooltip: conditions.map((condition, index) => {
                return !condition.enabled ? <li key={`${condition.tooltip}_${index}`}>{condition.tooltip}</li> : '';
            })
        };
    };

    const saveButtonStatusObject = getSaveButtonStatusObject();


    const getAllConfigurations = async () =>{
        const allConfigurations : IGetAllConfigurationsModel = await getIntegrationsService().getAllConfigurationsSlim(true);
        setConfigurations(allConfigurations);
    };


    useEffect(() => {
        getAllConfigurations();
        const initialSelectedCheckboxes = {} as ISelectedCategoriesList;
        const initialSelections = notification?.id ? getSelectedIntegrationsWithMatchListType(notification,integrationsDefinitions) : undefined;
        initializeSelectedNotificationCategories(initialSelectedCheckboxes, notification, initialSelections);
        setSelectedCategories(initialSelectedCheckboxes);

    }, []);

    const handleConfigurationAdded = () => {
        getAllConfigurations();
    };

    const openNewConfigurationDrawer = (integrationId: string) => {
        const selectedIntegration = integrationsDefinitions.filter(integration => integration.id === integrationId)[0];
        showDrawer(INTEGRATION_DRAWER.key, {
            integrationID: integrationId,
            view: INTEGRATIONS_DRAWER_VIEWS.LIST_VIEW,
            title: selectedIntegration.title,
            icon: selectedIntegration.icon,
            componentProps: { mode: 'new' }
        },{ [INTEGRATION_DRAWER.eventTypes.configurationChanged]: CONFIGURATION_ADDED_HANDLER_ID, });
    };

    useDrawerHandler(CONFIGURATION_ADDED_HANDLER_ID, handleConfigurationAdded);

    const handleIntegrationsChanged = (selectedCheckbox: string,selectedIntegration: string) => {
        selectedCategories[selectedCheckbox].selectedConfiguration = undefined;
        const currentSelectedIntegration = selectedCategories[selectedCheckbox].selectedIntegration;
        selectedCategories[selectedCheckbox].selectedIntegration = currentSelectedIntegration === selectedIntegration ? '' : selectedIntegration;
        selectedCategories[selectedCheckbox].integrationPayload = undefined;
        selectedCategories[selectedCheckbox].hasIntegrationPayload =
            integrationsDefinitions.find(integration => integration.id === selectedIntegration)?.hasIntegrationPayload;
        selectedCategories[selectedCheckbox].hasOutputTypeSelection =
            integrationsDefinitions.find(integration => integration.id === selectedIntegration)?.hasOutputTypeSelection;
        setSelectedCategories({ ...selectedCategories });
    };

    const handleConfigurationsChanged = (selectedCheckBox: string, selectedConfiguration: IConfiguration) => {
        selectedCategories[selectedCheckBox].selectedConfiguration = {
            ...selectedConfiguration,
        };
        setSelectedCategories({ ...selectedCategories });
    };

    const handleImmediateFilterChanged = (filterValues: IFiltersValues) => {
        setImmediateNotificationsFilter(filterValues);
    };

    const handleScheduledReportSelected = () => {
        if (isScheduleReportSelected){
            setScheduledReportSelections({} as IScheduleReportObject);
        }
        setIsScheduleReportSelected(!isScheduleReportSelected);

    };


    const handleCategoryClicked = (selectedCategoryID: string)=> {
        selectedCategories[selectedCategoryID].isChecked = !selectedCategories[selectedCategoryID].isChecked;
        if(!selectedCategories[selectedCategoryID].isChecked){
            selectedCategories[selectedCategoryID].selectedConfiguration = undefined;
            selectedCategories[selectedCategoryID].integrationPayload = undefined;
        }
        const integrationsDefinition = integrationsDefinitions.find(integration => integration.id === selectedCategoryID);
        if(integrationsDefinition){
            selectedCategories[selectedCategoryID].hasIntegrationPayload = integrationsDefinition.hasIntegrationPayload;
            selectedCategories[selectedCategoryID].hasOutputTypeSelection = integrationsDefinition.hasOutputTypeSelection;
        }
        setSelectedCategories({ ...selectedCategories });
    };

    const payloadUpdated = (selectedCategory:ISelectedCategoryObject,payload: string) => {
        if(selectedCategory){
            selectedCategory.integrationPayload = payload;
            setSelectedCategories({ ...selectedCategories });
        }
    };
    const onOutputTypeUpdated = (selectedCategory:ISelectedCategoryObject,outputType: string | undefined) => {
        if(selectedCategory){
            selectedCategory.outputType = outputType;
            setSelectedCategories({ ...selectedCategories });
        }
    };

    const saveNotification = async () => {
        setSaveFailedErrorMessage('');
        setIsModalBusy(true);
        const notificationToSave: INewRawNotification = {
            name: notificationName,
            id: notification?.id,
            filter: immediateNotificationsFilter,
            description: notificationDescription,
            alertConsole: !!isAlertConsoleSelected,
            selectedCategories: selectedCategories,
            scheduledReport: scheduledReportSelections
        };
        try {
            await getNotificationPageService().saveNotification(notificationToSave);
            onSave && await onSave();
        }catch (error :{ status:number } | any) {
            setSaveFailedErrorMessage(getNormalizedErrorMessage(error,t));
        }finally {
            setIsModalBusy(false);
        }
    };

    const integrationIssueClearedEventHandler = (integrationType:string) => {
        notificationsModalSections.forEach(section => {
            section.categoryList?.forEach(categoryProperties => {
                if(categoryProperties.integrationsIDs.includes(integrationType)){
                    selectedCategories[categoryProperties.id].hasError = false;
                }
            });
        });
        setNotificationsModalSections([...notificationsModalSections]);
    };

    return (
        <Modal.ModalDialog isOpen={isOpen} width='lg' shouldCloseOnOverlayClick onRequestClose={onClose} customZIndex={500}>
            <Modal.ModalHeader hasSeparator={true} title={notificationName || t('MODALS.ADD_EDIT_MODAL.TITLE')} onClose={onClose} />
            <Modal.ModalContent>
                <Stack flexWrap={true} spacing={5} data-aid={'notification-modal'}>
                    <Stack spacing={2} >

                        <Stack spacing={2} alignItems="center" direction="row" fullWidth>
                            <ModalSectionTitle variant="body500">{t('MODALS.ADD_EDIT_MODAL.SECTIONS.NAME')}</ModalSectionTitle>
                        </Stack>
                        <Input fullWidth={true} data-aid={'notification-name-input'} disabled={isReadOnly || isDefaultNotification} value={notificationName} required={true} onChange={(e)=> setNotificationName(e.target.value)} />
                    </Stack>
                    <Stack spacing={2}>
                        <Stack spacing={2} alignItems="center" direction="row" fullWidth>
                            <ModalSectionTitle variant="body500">{t('MODALS.ADD_EDIT_MODAL.SECTIONS.DESCRIPTION')}</ModalSectionTitle>
                        </Stack>
                        <TextArea maxLength={200} clearable rows={4} fullWidth={true} value={notificationDescription} disabled={isReadOnly || isDefaultNotification} onChange={(e)=> setNotificationDescription(e.target.value)} />
                    </Stack>
                    <Stack spacing={1}>
                        <Stack spacing={2} alignItems="center" direction="row" fullWidth>
                            <ModalSectionTitle variant="body500">{t('MODALS.ADD_EDIT_MODAL.SECTIONS.ALERT_CONSOLE')}</ModalSectionTitle>
                        </Stack>
                        <Checkbox label={t('MODALS.ADD_EDIT_MODAL.CATEGORIES.ALERT_CONSOLE')} checked={isAlertConsoleSelected} disabled={isReadOnly || isDefaultNotification} onChange={() => setIsAlertConsoleSelected(!isAlertConsoleSelected)}/>
                    </Stack>
                    { !isDefaultNotification &&
                        <>
                            <Stack spacing={1}>
                                <Stack spacing={2} alignItems="center" direction="row" fullWidth>
                                    <ModalSectionTitle variant="body500">{t('MODALS.ADD_EDIT_MODAL.SECTIONS.SCHEDULE_REPORT')}
                                        <Tooltip interactive={true} placement="right" content={<>{t('MODALS.ADD_EDIT_MODAL.TOOLTIPS.CATEGORIES.SCHEDULE_REPORT')}<Link externalUrl={CRON_EXPRESSION_DOCUMENTATION_LINK}>{t('GENERAL.HERE')} </Link></>}><Icon name={'info'} size={12} /></Tooltip>
                                    </ModalSectionTitle>
                                </Stack>
                                <Checkbox label={t('MODALS.ADD_EDIT_MODAL.CATEGORIES.SCHEDULE_REPORT')} checked={isScheduleReportSelected} disabled={isReadOnly} onChange={() =>handleScheduledReportSelected()}/>
                                {
                                    isScheduleReportSelected && <Stack margin={[0,0,0,5]}>
                                        <ScheduleReport configurationsList={configurations[INTEGRATIONS_IDS.EMAIL]} isReadOnly={isReadOnly} scheduleReportSelections={scheduledReportSelections} onScheduleReportChanged={setScheduledReportSelections} onAddEmailConfigurationClicked={()=> openNewConfigurationDrawer(INTEGRATIONS_IDS.EMAIL)}></ScheduleReport>
                                    </Stack>
                                }
                            </Stack>
                            <Stack spacing={5}>
                                {
                                    notificationsModalSections.map(modalSection => {
                                        return <Stack spacing={2} key={modalSection.title}>
                                            <Stack spacing={2} alignItems="center" direction="row" fullWidth>
                                                <ModalSectionTitle variant="body500">{t(modalSection.title)}</ModalSectionTitle>
                                            </Stack>
                                            <>{modalSection.filter && <Stack margin={[2,0]} spacing={2}>
                                                <ModalSectionTitle variant="body">{t('GENERAL.FILTER')}</ModalSectionTitle>
                                                <AddEditModalFilterPanel onFilterChange={handleImmediateFilterChanged} initialFilter={immediateNotificationsFilter} />
                                            </Stack>}</>
                                            {
                                                Object.keys(selectedCategories).length > 0 && modalSection.categoryList?.map((category) => {
                                                    return <IntegrationsCategoriesComponent
                                                        key={category.id}
                                                        categorySelections={selectedCategories[category.id]}
                                                        categoryMappingObject={category}
                                                        onCategoryClicked={handleCategoryClicked}
                                                        onIntegrationSelected={handleIntegrationsChanged}
                                                        onPayloadUpdated={payloadUpdated}
                                                        onOutputTypeUpdated={onOutputTypeUpdated}
                                                        onConfigurationSelected={handleConfigurationsChanged}
                                                        configurationsList={configurations[selectedCategories[category.id].selectedIntegration]}
                                                        onAddConfigurationsClicked={openNewConfigurationDrawer} isReadOnly={isReadOnly}
                                                        immediateNotificationsFilter={immediateNotificationsFilter}
                                                        notificationId={notification?.id || ''}
                                                        integrationIssueCleared={integrationIssueClearedEventHandler}
                                                    />;
                                                })
                                            }
                                        </Stack>;
                                    })
                                }
                            </Stack>
                        </>
                    }
                </Stack>
            </Modal.ModalContent>
            <Modal.ModalFooter hasSeparator={true}>
                <Stack direction='row' justifyContent='flex-end' fullWidth spacing={2}>
                    { saveFailedErrorMessage &&
                        <Stack style={{ backgroundColor: theme.palette.surface.alertLight }} padding={2} spacing={6} alignItems={'center'}>
                            <Typography color={'normal'}>{saveFailedErrorMessage}</Typography>
                        </Stack>}
                    <Button variant='text' onClick={onClose} data-aid={'cancelButton'}>
                        {t('MODALS.ADD_EDIT_MODAL.BUTTONS.CANCEL')}
                    </Button>
                    <Button color='brandPrimary' disabled={saveButtonStatusObject.isDisabled || viewMode} onClick={()=>saveNotification()}
                        data-aid={'saveButton'} tooltip={saveButtonStatusObject.isDisabled && saveButtonStatusObject.tooltip}
                        className={saveButtonStatusObject.isDisabled? BUTTON_STATE.DISABLED : BUTTON_STATE.ACTIVE}>
                        {isModalBusy && <Spinner size={12}/>}
                        {t('MODALS.ADD_EDIT_MODAL.BUTTONS.SAVE')}
                    </Button>
                </Stack>
            </Modal.ModalFooter>

        </Modal.ModalDialog>

    );
};

export default NotificationAddEditModal;
