import { CGColDef } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { IGNORE_LIST_ITEM_FIELDS } from './CommonIgnoreList.consts';
import { ermTrans } from '../../../RiskManagement.utils';
import { SELECTION_GRID_COLUMN } from 'common/utils/tableUtils';
import { ICommonIgnoreItem } from './CommonIgnoreList.interface';
import { AllIconsName } from 'common/design-system/components-v2/Icon/Icon.types';
import { IgnoreItemNameCellRender } from './renderers/IgnoreItemNameCellRender';
import { MultiValueChipsCellRender } from './renderers/MultiValueChipsCellRender';
import { getAllEnvironmentOptions, getAllOrgUnitOptions } from '../../../RiskManagement.options';
import { MultiOptionChipsCellRender } from './renderers/MultiOptionChipsCellRender';
import { ExpirationDateCellRender } from './renderers/ExpirationDateCellRender';

export const getCommonIgnoreListColumns = (openForm: (item: ICommonIgnoreItem) => void, icon: AllIconsName): CGColDef[] => {
    return [
        SELECTION_GRID_COLUMN,
        {
            colId: IGNORE_LIST_ITEM_FIELDS.NAME,
            field: IGNORE_LIST_ITEM_FIELDS.NAME,
            sortable: true,
            headerName: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.NAME.HEADER'),
            minWidth: 130,
            flex: 6,
            sort: 'asc',
            cellRenderer: IgnoreItemNameCellRender,
            cellRendererParams: { openForm, icon },
        },
        {
            colId: IGNORE_LIST_ITEM_FIELDS.CVE_IDS,
            field: IGNORE_LIST_ITEM_FIELDS.CVE_IDS,
            headerName: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.CVE_IDS.HEADER'),
            minWidth: 130,
            flex: 6,
            sort: 'asc',
            cellRenderer: MultiValueChipsCellRender,
        },
        {
            colId: IGNORE_LIST_ITEM_FIELDS.PACKAGE_NAMES,
            field: IGNORE_LIST_ITEM_FIELDS.PACKAGE_NAMES,
            headerName: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.PACKAGE_NAMES.HEADER'),
            minWidth: 130,
            flex: 6,
            sort: 'asc',
            cellRenderer: MultiValueChipsCellRender,
        },
        {
            colId: IGNORE_LIST_ITEM_FIELDS.PACKAGE_PATHS,
            field: IGNORE_LIST_ITEM_FIELDS.PACKAGE_PATHS,
            headerName: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.PACKAGE_PATHS.HEADER'),
            minWidth: 130,
            flex: 6,
            sort: 'asc',
            cellRenderer: MultiValueChipsCellRender,
        },
        {
            colId: IGNORE_LIST_ITEM_FIELDS.ORG_UNIT_IDS,
            field: IGNORE_LIST_ITEM_FIELDS.ORG_UNIT_IDS,
            headerName: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ORG_UNIT_IDS.HEADER'),
            minWidth: 130,
            flex: 6,
            sort: 'asc',
            cellRenderer: MultiOptionChipsCellRender,
            cellRendererParams: {
                getOptions: getAllOrgUnitOptions,
            },
        },
        {
            colId: IGNORE_LIST_ITEM_FIELDS.ENV_IDS,
            field: IGNORE_LIST_ITEM_FIELDS.ENV_IDS,
            headerName: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ENV_IDS.HEADER'),
            minWidth: 130,
            flex: 6,
            sort: 'asc',
            cellRenderer: MultiOptionChipsCellRender,
            cellRendererParams: {
                getOptions: getAllEnvironmentOptions,
            },
        },
        {
            colId: IGNORE_LIST_ITEM_FIELDS.ENTITY_NAMES,
            field: IGNORE_LIST_ITEM_FIELDS.ENTITY_NAMES,
            headerName: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ENTITY_NAMES.HEADER'),
            minWidth: 130,
            flex: 6,
            sort: 'asc',
            cellRenderer: MultiValueChipsCellRender,
        },
        {
            colId: IGNORE_LIST_ITEM_FIELDS.ENTITY_ID,
            field: IGNORE_LIST_ITEM_FIELDS.ENTITY_ID,
            headerName: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.ENTITY_ID.HEADER'),
            minWidth: 130,
            flex: 6,
            sort: 'asc',
        },
        {
            colId: IGNORE_LIST_ITEM_FIELDS.EXPIRATION_DATE,
            field: IGNORE_LIST_ITEM_FIELDS.EXPIRATION_DATE,
            sortable: true,
            headerName: ermTrans('IGNORE_LIST.COMMON.TABLE.COLUMNS.EXPIRATION_DATE.HEADER'),
            minWidth: 130,
            flex: 6,
            sort: 'asc',
            cellRenderer: ExpirationDateCellRender,
        },
    ];
};
