import {
    IItemChanges, IItemsMap, ISingleDateItem, ISingleDateState,
} from 'common/erm-components/custom/CustomForm/CustomForm.interface';
import {
    convertValueToDate,
    getTrimmedSingleValue,
} from 'common/erm-components/custom/CustomForm/CustomForm.values';
import dayjs from 'dayjs';
import { getExpirationDateLabel } from './CommonIgnoreList.items';
import i18n from 'i18next';
import { CommonIgnoreNames } from './CommonIgnoreList.consts';

export const getFormattedExpirationDate = (date: Date): string => {
    return dayjs(date).format(i18n.t('COMMON.DATE_FORMATS.DATE_ONLY_FORMAT'));
};

export const isPassedDate = (date?: Date): boolean => {
    if (!date) {
        return false;
    }

    const endOfDay = new Date(date);
    endOfDay.setHours(23);
    endOfDay.setMinutes(59);
    endOfDay.setSeconds(59);
    const now = dayjs();
    const expirationDate = dayjs(endOfDay);
    return now.isAfter(expirationDate);
};

export const getNewExpirationDateState = (item: ISingleDateItem, changeDetails: IItemChanges, itemsMap: IItemsMap): Promise<ISingleDateState | undefined> => {
    if (changeDetails.newItem.name !== CommonIgnoreNames.expirationDate) {
        return Promise.resolve(undefined);
    }

    const date: Date | undefined = convertValueToDate(getTrimmedSingleValue(itemsMap[CommonIgnoreNames.expirationDate]));
    const expired = isPassedDate(date);

    return Promise.resolve({
        ...item.state,
        label: getExpirationDateLabel(expired),
        labelProps: { color: expired ? 'alert' : undefined },
    });
};