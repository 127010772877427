import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { AggregationLevel, Datasource } from 'common/components/ProtectedAssets/datasource';
import {
    IMaliciousAssetInfo,
} from './ExposureDashboard.interface';
import { ASSET_FIELD_NAMES, PREDEFINED_FILTERS, } from '../../../consts';
import {
    FindingsDataSource,
    IFindingPaganationViewModel, ISearchFilterViewModel,
    ISearchRequestViewModel,
} from 'common/components/Findings/Findings.interface';
import dayjs from 'dayjs';
import { assetFilterFieldsToThirdParty, convertFilterFieldsToNonMfa } from './ExposureDashboardFilterData';
import { IFieldInfo } from 'common/interface/general';
import { FINDINGS_SEARCH_URL } from 'common/module_interface/events/EventsConsts';
import { createErmDataSourceConfig, getErmAggregation } from '../../ProtectedAsset/ErmDataUtils';
import {
    assetFilterFieldsToFindings,
    DASHBOARD_CACHE_CONFIG
} from '../../../RiskManagement.utils';
import { IFindingItem } from '../../SecurityEvents/SecurityEvents.interface';
import { CACHE_TIMOUTS, sendHttpRequest } from 'common/erm-components/utils/ermComponents.http';

export const NON_MFA_FREE_TEXT = 'Ensure that Multi-Factor';
export const NON_MFA_FILTER_FIELDS = [
    {
        name: 'entityTypeByEnvironmentType',
        value: '1|IamUser',
    },
    {
        name: 'entityTypeByEnvironmentType',
        value: '10|GcpIamUser',
    },
    {
        name: 'entityTypeByEnvironmentType',
        value: '7|User',
    }];

export const MAX_SECURITY_EVENTS_COUNT = 10;
const MAX_MALICIOUS_ASSETS = 5;

type MaliciousAssetMap = { [key: string]: IMaliciousAssetInfo };
export const getTotalAggregations = (filterValues: IFiltersValues): Promise<Aggregations> => {
    const datasource = new Datasource(createErmDataSourceConfig(filterValues, 0, undefined, DASHBOARD_CACHE_CONFIG));
    return getErmAggregation(datasource);
};

export const getPublicNetworkTop10Types = (filterValues: IFiltersValues): Promise<Aggregations> => {
    const datasource = new Datasource(createErmDataSourceConfig(
        { fields: [PREDEFINED_FILTERS.NETWORK_EXPOSURE_PUBLIC, ...(filterValues?.fields || [])] },
        0,
        undefined,
        DASHBOARD_CACHE_CONFIG,
    ));
    return getErmAggregation(datasource, {
        aggregationFields: [ASSET_FIELD_NAMES.type],
    });
};

export const getRoleThirdPartyCount = (filterValues: IFiltersValues): Promise<number> => {
    const fields = assetFilterFieldsToThirdParty(filterValues?.fields);
    const datasource = new Datasource(createErmDataSourceConfig({
        fields: [
            {
                name: 'type',
                value: 'aws|IamRole',
            },
            {
                name: 'assetLabels',
                value: 'ThirdParty',
            },
            ...(fields || []),
        ],
    }, 0, undefined, DASHBOARD_CACHE_CONFIG));
    return datasource.getAdHokDataFromServer(AggregationLevel.NONE).then(response => {
        return response?.totalCount || 0;
    });
};

const getMaliciousFindingsChunk = (filterValues: IFiltersValues, searchAfter?: string[]): Promise<IFindingPaganationViewModel> => {
    const fields: IFieldInfo[] = [
        ...assetFilterFieldsToFindings(filterValues?.fields),
        {
            name: 'ruleName',
            value: 'INBOUND ACCEPTED TRAFFIC FROM A MALICIOUS IP ADDRESS',
        },
    ];

    const request: ISearchRequestViewModel = {
        filter: {
            fields,
            creationTime: {
                from: dayjs().subtract(7, 'day').toISOString(),
                to: dayjs().toISOString(),
            },
        },
        pageSize: searchAfter ? 500 : 50,
        dataSource: FindingsDataSource.FINDING,
        sorting: {
            direction: -1,
            fieldName: 'createdTime',
        },
        searchAfter,
        skipAggregations: true,
    };
    return sendHttpRequest<IFindingPaganationViewModel>(FINDINGS_SEARCH_URL, {
        data: request,
        method: 'POST',
    }, CACHE_TIMOUTS.LONG);
};

const updateAssetsFromFindings = (findings: IFindingItem[] = [], assetsMap: MaliciousAssetMap) => {
    for (let i = 0; i < findings.length; i++) {
        const finding = findings[i];
        if (!assetsMap[finding.entityName]) {
            assetsMap[finding.entityName] = {
                name: finding.entityName,
                type: finding.entityTypeByEnvironmentType,
                createdTime: finding.createdTime,
                entityId: finding.entityExternalId,
                entityType: finding.entityType,
                cloudAccountId: finding.cloudAccountId,
                platform: finding.cloudAccountType,
            };
            if (Object.keys(assetsMap).length >= MAX_MALICIOUS_ASSETS) {
                return;
            }
        }
    }
};

const getMaliciousAssetsMap = async (filterValues: IFiltersValues): Promise<MaliciousAssetMap> => {
    let lastResponse: IFindingPaganationViewModel | undefined;
    const assetsMap: MaliciousAssetMap = {};
    const MAX_ITERATIONS = 20;
    for (let i = 0; i < MAX_ITERATIONS; i++) {
        lastResponse = await getMaliciousFindingsChunk(filterValues, lastResponse?.searchAfter);
        if (!lastResponse.findings?.length) {
            return assetsMap;
        }
        updateAssetsFromFindings(lastResponse.findings, assetsMap);
        if ((Object.keys(assetsMap).length >= MAX_MALICIOUS_ASSETS) || !lastResponse.searchAfter) {
            return assetsMap;
        }
    }
    return assetsMap;
};

export const getMaliciousAssets = async (filterValues: IFiltersValues): Promise<IMaliciousAssetInfo[]> => {
    const assetsMap = await getMaliciousAssetsMap(filterValues);
    return Object.values(assetsMap).sort((a1: IMaliciousAssetInfo, a2: IMaliciousAssetInfo) => {
        return a2.createdTime.localeCompare(a1.createdTime);
    });
};

export const getNonMfaFindingsCount = (filterValues: IFiltersValues): Promise<number> => {
    const fields: IFieldInfo[] = convertFilterFieldsToNonMfa(filterValues?.fields);
    const filter: ISearchFilterViewModel = {
        fields: [
            {
                name: 'alertType',
                value: '1',
            },
            {
                name: 'status',
                value: '0',
            },
            ...NON_MFA_FILTER_FIELDS,
            ...fields,
        ],
        creationTime: {
            from: new Date(0).toISOString(),
            to: dayjs().startOf('minute').toISOString(),
        },
        freeTextPhrase: NON_MFA_FREE_TEXT,
    };

    const requestData: ISearchRequestViewModel = {
        filter,
        pageSize: 0,
        dataSource: FindingsDataSource.FINDING,
        skipAggregations: true,
    };
    return sendHttpRequest<IFindingPaganationViewModel>(FINDINGS_SEARCH_URL, {
        data: requestData,
        method: 'POST',
    }, CACHE_TIMOUTS.LONG).then(result => {
        return result.totalFindingsCount;
    }).catch(error => {
        console.error('Failed fetching findings count details', error);
        return 0;
    });
};
