import React, { useCallback, useEffect, useState } from 'react';
import { BySeverityStyled } from './IssuesBySeverity.styled';
import { useTranslation } from 'react-i18next';
import { LoadingState } from 'common/interface/general';
import { getSeveritiesLevelBoxItems } from './IssuesBySeverity.datasource';
import { Spinner } from 'common/design-system/components-v2';
import { LevelBoxes } from 'common/erm-components/custom/LevelBoxes/LevelBoxes';
import { RiskStyled } from '../../../../../RiskManagement.styled';
import { I18nRiskNamespace } from '../../../../../consts';
import { IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { ILevelBoxesItemsInfo, ILevelBoxItem } from 'common/erm-components/custom/LevelBoxes/LevelBox.interface';

const EMPTY_ITEMS_INFO = (): ILevelBoxesItemsInfo => {
    return {
        items: [],
        loadingState: LoadingState.IS_LOADING,
    };
};

const IssuesBySeverity: React.FC<{ filterDetails?: IServerInputFilterDetails }> = ({ filterDetails }) => {
    const [itemsInfo, setItemsInfo] = useState<ILevelBoxesItemsInfo>(EMPTY_ITEMS_INFO());
    const { t } = useTranslation();
    const updateData = useCallback(() => {
        setItemsInfo(EMPTY_ITEMS_INFO());
        getSeveritiesLevelBoxItems(filterDetails).then((items: ILevelBoxItem[]) => {
            setItemsInfo({
                items,
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
        }).catch(() => {
            setItemsInfo({
                items: [],
                loadingState: LoadingState.LOADING_FAILED,
            });
        });
    }, [filterDetails]);

    useEffect(() => {
        if (filterDetails) {
            updateData();
        }
    }, [filterDetails, updateData]);

    if (itemsInfo.loadingState === LoadingState.IS_LOADING) {
        return <BySeverityStyled.TopDiv><RiskStyled.SpinnerDiv><Spinner /></RiskStyled.SpinnerDiv></BySeverityStyled.TopDiv>;
    }

    return (
        <BySeverityStyled.TopDiv>
            <BySeverityStyled.HeaderDiv>{t('RISK_MANAGEMENT.DASHBOARD.SECURITY_ISSUES_BY_SEVERITY', { ns: I18nRiskNamespace })}</BySeverityStyled.HeaderDiv>
            <BySeverityStyled.ContentDiv>
                <LevelBoxes items={itemsInfo.items} />
            </BySeverityStyled.ContentDiv>
        </BySeverityStyled.TopDiv>
    );
};

export default IssuesBySeverity;
