export enum SelectedItemType {NODE, EDGE}
export class SelectedItem {
    graphId: string;
    itemType: SelectedItemType;
    type: string;
    label: string;
    properties: { [key: string]: unknown; } | null;
    updateTimestamp: string | null;
    networkExposure: string | null;
    exposurePath: string | null;

    constructor(
        graphId: string,
        itemType: SelectedItemType,
        type: string,
        label: string,
        properties: { [p: string]: unknown } | null,
        updateTimestamp: string | null,
        networkExposure: string | null,
        exposurePath: string | null) {
        this.graphId = graphId;
        this.itemType = itemType;
        this.type = type;
        this.label = label;
        this.properties = properties;
        this.updateTimestamp = updateTimestamp;
        this.networkExposure = networkExposure;
        this.exposurePath = exposurePath;
    }
}