import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(11, 42, 103, 0.18);
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px 0 16px;
  color: #15294B;
  font-weight: 500;
`;

const TopTableDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px 15px;
`;

export const AssetsStyled = {
    TopDiv,
    HeaderDiv,
    TopTableDiv,
};

