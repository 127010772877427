import {
    IProtectedAssetViewModel,
    ProtectedAssetsResponse,
} from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { getHttpService } from 'common/interface/services';
import { getProtectedAssetsService } from 'common/module_interface/assets/ProtectedAssets';
import { GraphData } from '../Models/GraphData';
import { SchemaType } from '../Consts/SchemaType';
import { ASSETS_SEARCH_URL } from 'common/module_interface/assets/AssetsConsts';

async function getProtectedAsset(assetSrl: string): Promise<IProtectedAssetViewModel> {
    const response = await getHttpService().post<ProtectedAssetsResponse>(
        ASSETS_SEARCH_URL,
        {
            data: {
                pageSize: 1,
                filter: {
                    fields: [{
                        Name: 'srl',
                        Value: assetSrl,
                    }],
                },
            },
        },
    );

    return response.assets[0];
}

export async function getProtectedAssetsEntityUrl(assetSrl: string): Promise<string | null> {
    const asset = await getProtectedAsset(assetSrl);
    return getProtectedAssetsService().getProtectedAssetUrl(asset);
}

export async function getCiemEntityUrl(assetSrl: string): Promise<string | null> {
    const asset = await getProtectedAsset(assetSrl);
    if (!asset?.cloudAccountId || !asset?.entityId) return null;

    return `/v2/protected-asset/generic?cloudAccountId=${asset.cloudAccountId}&assetType=IamRole&assetId=${asset.entityId}&tabName=permissions&platform=aws`;
}

async function getEntityUrls(assetSrls: string[]): Promise<Record<string, string>> {
    const fields = assetSrls.map(s => {
        return {
            Name: 'srl',
            Value: s,
        };
    });

    const response = await getHttpService().post<ProtectedAssetsResponse>(
        ASSETS_SEARCH_URL,
        {
            data: {
                pageSize: 1000,
                filter: {
                    fields: fields,
                },
            },
        },
    );

    const srlUrls: Record<string, string> = {};
    response.assets.forEach(asset => {
        if (!asset?.srl) return;

        const url = getProtectedAssetsService().getProtectedAssetUrl(asset);
        if (!url) return;

        srlUrls[asset.srl] = url;
    });

    return srlUrls;
}

async function graphDataHandler(graphData: GraphData, assetSrl: string): Promise<GraphData> {
    const customUrlTypes = ['AwsSecurityGroup', 'NetworkSecurityGroup'];
    const srlsForLinks = graphData.entities.filter(e =>
        !customUrlTypes.includes(e.type) && // We get SG URL differently
        (e.gotoSrl || e.srl),
    ).map(e => <string>e?.gotoSrl ?? e.srl);

    const distinctSrls = [...new Set<string>(srlsForLinks)];
    const srlUrls = await getEntityUrls(distinctSrls);

    graphData.entities.forEach(e => {
        e.isInContext = e.srl === assetSrl;

        if (e.type === SchemaType.AwsSecurityGroup && e.srl) {
            const splitSrl = e.srl.split('|');
            const cloudAccountId = splitSrl[1];
            const sgExternalId = splitSrl[5];
            if (!cloudAccountId || !sgExternalId) return;

            e.gotoUrl = `/v2/security-group/aws/${sgExternalId}/${cloudAccountId}`;
        }

        if (e.type === SchemaType.NetworkSecurityGroup && e.srl) {
            e.gotoUrl = `/v2/security-group/azure/${e.srl}/details`;
        }

        const srlToGoTo = <string>e.gotoSrl || e.srl;
        if (!srlToGoTo) return;

        const url = srlUrls[srlToGoTo];
        if (!url) return;

        e.gotoUrl = url;
    });

    return graphData;
}

export async function getAssetExposure(assetSrl: string): Promise<GraphData> {
    const graphData = await getHttpService().post<GraphData>(
        'content/assets/graph/network-exposure/asset', {
            data: {
                srl: assetSrl,
            },
        },
    );
    return await graphDataHandler(graphData, assetSrl);
}

export async function getAttackVector(assetSrl: string): Promise<GraphData> {
    const graphData = await getHttpService().post<GraphData>(
        'content/assets/graph/network-exposure/attack-vector', {
            data: {
                srl: assetSrl,
            },
        },
    );
    return await graphDataHandler(graphData, assetSrl);
}