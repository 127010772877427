import initializeColumnDefs from './Definitions/ColumnDefinitions';
import initializeFilters from './Definitions/FilterDefinitions';
import initializeTabsDefs from './Definitions/TabsDefinitions';
import initializeActionsDefs from './Definitions/ActionsDefinitions';
import initializeExportsDefs from './Definitions/ExportsDefinitions';

export const initializeEventsTable = () => {
    initializeColumnDefs();
    initializeFilters();
    initializeTabsDefs();
    initializeActionsDefs();
    initializeExportsDefs();
};