import React from 'react';
import { RenderViewParams, Wizard, WizardStep } from 'common/components/Wizard/Wizard';
import OciOnboardingGetStarted from './OciOnboardingGetStarted';
import OciOnboardingAccount from './OciOnboardingAccount';
import OciOnboardingOU from './OciOnboardingOU';
import { createOciAccont, createTerraform, exitWizard } from './OciOnboarding.service';
import { getStoreService } from 'common/interface/services';
import { OciOnboardingState, ociTerraformRequest } from './OciOnboarding.interface';
import { CpLoadingDots } from '@dome9/components/react/components';
import { useSelector } from 'react-redux';
import { clearOciOnboardingState, getIsLoading, setIsLoading } from './OciOnboarding.reducer';
import { useTranslation } from 'react-i18next';
import { addNotification } from '../../main/Notifications/Notifications.reducer';
import { NotificationType } from 'common/interface/notifications';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { getCloudAccountsService } from 'common/interface/data_services';

const OciOnboarding: React.FC<any> = () => {
    const isLoading = useSelector(getIsLoading);
    const { t } = useTranslation();
    useBreadcrumbsLastChildren([t('ON_BOARDING.OCI.WIZARD_TITLE')]);

    const onWizardClose =()=>{
        exitWizard();
    };

    const extraButton = {
        text: t('ON_BOARDING.OCI.ONBOARD_ANOTHER_ENVIRONMENT'),
        index: 2,
        parameter: true
    };

    const steps : WizardStep[] = [
        {
            name: t('ON_BOARDING.OCI.WELCOME_STEP'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <OciOnboardingGetStarted {...renderViewParams } />;
            },
            onNext: async ()=>{
                const { state, dispatch } = getStoreService().getReduxTools();
                dispatch(setIsLoading(true));
                const terraformRequest: ociTerraformRequest = {
                    name: state.ociOnboarding.name,
                    tenancyId: state.ociOnboarding.tenancyId,
                    homeRegion: state.ociOnboarding.homeRegion,
                    TenantAdministratorEmailAddress: state.ociOnboarding.TenantAdministratorEmailAddress,
                    userName: state.ociOnboarding.userName,
                    groupName: state.ociOnboarding.groupName,
                    policyName: state.ociOnboarding.policyName,
                };
                await createTerraform(terraformRequest).finally(()=> dispatch(setIsLoading(false)));
            }
        },
        {
            name: t('ON_BOARDING.OCI.OU_STEP'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <OciOnboardingOU {...renderViewParams } />;
            },
            onNext: async ()=>{
                return true;
            },
        },
        {
            name: t('ON_BOARDING.OCI.ACCOUNT_STEP'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <OciOnboardingAccount {...renderViewParams } />;
            },
            onNext: async (withOnboardAnotherAccount: boolean)=>{
                const { state, dispatch } = getStoreService().getReduxTools();
                const onboardingState = state.ociOnboarding as OciOnboardingState;
                dispatch(setIsLoading(true));
                await createOciAccont({
                    organizationalUnitId: onboardingState.organizationalUnitId,
                    tenancyId: onboardingState.tenancyId,
                    userOcid: onboardingState.userOcid,
                }).finally(()=> dispatch(setIsLoading(false)));
                dispatch(addNotification({ type: NotificationType.SUCCESS, text: t('ON_BOARDING.OCI.ACCOUNT_ONBOARDED_SUCCESSFULLY') }));
                getCloudAccountsService().clearCache();
                if(withOnboardAnotherAccount){
                    dispatch(clearOciOnboardingState());
                }
                else{
                    exitWizard();
                }
            }
        }
    ];

    return (
        <div>
            <Wizard
                steps={steps}
                onClose={onWizardClose}
                title={t('ON_BOARDING.OCI.WIZARD_TITLE')}
                doneButtonText={t('ON_BOARDING.OCI.FINISH')}
                extraButton={extraButton}
            />
            {isLoading && <CpLoadingDots></CpLoadingDots> }
        </div>
    );
};

export default OciOnboarding;