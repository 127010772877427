import React from 'react';
import { ModalType } from '../../GcpEnvironment.types';
import EditGcpNameModal from './Rename/EditGcpNameModal';
import EditGcpCredentialsModal from './Credentials/EditGcpCredentialsModal';
import RemoveGcpModal from './RemoveGcpModal/RemoveGcpModal';
import useModalFromUrl from 'common/hooks/useModalFromUrl';
import GSuiteModal from './GSuite/GSuiteModal';

export interface ModalsProps {
    gcpId: string;
    modalType: ModalType | null;
    closeModal: () => void
    setModalType: (modalType: ModalType) => void;
}

export interface DeleteModalProps extends ModalsProps{
    forceDelete?: boolean;
}

const Modals: React.FC<ModalsProps> = props => {
    const { setModalType, modalType } = props;
    const { modalTypeFromUrl } = useModalFromUrl();

    React.useEffect(() => {
        if (modalTypeFromUrl && !modalType) {
            setModalType(modalTypeFromUrl as ModalType);
        }
    }, [modalType, modalTypeFromUrl, setModalType]);

    switch (props.modalType) {
        case ModalType.Rename:
            return <EditGcpNameModal {...props} />;
        case ModalType.EditCredentials:
            return <EditGcpCredentialsModal {...props} />;
        case ModalType.Remove:
            return <RemoveGcpModal {...props} />;
        case ModalType.RemoveWithPolicies:
            return <RemoveGcpModal forceDelete={true} {...props} />;
        case ModalType.AddGSuite:
            return <GSuiteModal {...props} />;
        default:
            return null;
    }
};

export default Modals;