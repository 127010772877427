import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import SimpleText from 'common/components/ProtectedAssets/Renderers/SimpleText';
import { IFinding } from 'common/module_interface/intelligence/Findings/Findings.interface';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from '../../../initialize.i18n';

const AssigneeCellRenderer: React.FC<ICellRendererParams<IFinding>> = (params) => {
    const { t } = useTranslation(i18nIntelligenceNamespace);
    const assignee: string = params.value || t('FINDINGS_TABLE.CELL_RENDERERS.ASSIGNEE.UNASSIGNED');
    return <SimpleText value={assignee}/>;
};

export default AssigneeCellRenderer;
