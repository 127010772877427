import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon, RadioButton, Stack, Tooltip, Typography } from 'common/design-system/components-v2';
import ExpandablePanel from 'common/components/ExpandablePanel/ExpandablePanel';
import { getStoreService } from 'common/interface/services';
import {
    AzureOnboardingStep,
    AzureOnboardingStepTitle
} from '../../AzureIntelligenceOnboarding.styled';
import {
    getAzureOnboardingType,
    setAllStorages,
    setAzureOnboardingType
} from '../../AzureIntelligenceOnboarding.reducer';
import { AssetType, AzureIntelligenceOnboardingType } from '../../AzureIntelligenceOnboarding.const';
import { i18nIntelligenceNamespace } from '../../../../initialize.i18n';
import { AzureWelcomePageProps } from '../../AzureIntelligenceOnboarding.interface';

const WelcomeNetworkTraffic: FC<AzureWelcomePageProps> = (props: AzureWelcomePageProps) => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING.NETWORK_TRAFFIC.WELCOME' });
    const dispatch = getStoreService().dispatch;

    const isCentralized = useSelector(getAzureOnboardingType) === AzureIntelligenceOnboardingType.centralized;

    const changeAzureOnboardingType = (type: AzureIntelligenceOnboardingType) => {
        dispatch(setAzureOnboardingType(type));
        dispatch(setAllStorages([]));
        props.onChangeType();
    };

    return (
        <AzureOnboardingStep>
            <AzureOnboardingStepTitle>{t('TITLE')}</AzureOnboardingStepTitle>

            <Stack padding={[5, 0]}>
                <Typography>{t('METHODS.TITLE')}</Typography>

                <ul>
                    <li>{t('METHODS.1')}</li>
                    <li>{t('METHODS.2')}</li>
                </ul>

                <Typography>{t('DESCRIPTION')}</Typography>
            </Stack>

            <ExpandablePanel title={t('PREREQUISITES.TITLE')} isHighlighted={true}>
                <Stack padding={[0, 0, 0, 3]}>
                    <Stack margin={[0, 0, 0, 1]}>
                        {t('PREREQUISITES.DESCRIPTION')}
                    </Stack>

                    <ol>
                        <li><Trans>{t('PREREQUISITES.1')}</Trans></li>
                        <li>{t('PREREQUISITES.2')}</li>
                        <li><Trans>{t('PREREQUISITES.3')}</Trans></li>
                        <li>{t('PREREQUISITES.4')}</li>
                        <li><Trans>{t('PREREQUISITES.5')}</Trans></li>
                        <li>
                            {t('PREREQUISITES.6.TITLE')}
                            <ol>
                                <li><Trans>{t('PREREQUISITES.6.1')}</Trans></li>
                                <li><Trans>{t('PREREQUISITES.6.2')}</Trans></li>
                            </ol>
                        </li>
                        <li><Trans>{t('PREREQUISITES.7')}</Trans></li>
                    </ol>
                </Stack>
            </ExpandablePanel>

            <Stack>
                <Typography variant='sm' type='key'>{t('SCOPE.TITLE')}</Typography>
                <Stack direction='row' spacing={4}>
                    <RadioButton 
                        name={AssetType.flowLogs}
                        label={t('SCOPE.1')}
                        checked={!isCentralized}
                        onChange={() => changeAzureOnboardingType(AzureIntelligenceOnboardingType.nsg)}
                    />

                    <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                        <RadioButton 
                            name={AssetType.flowLogs}
                            label={t('SCOPE.2')}
                            checked={isCentralized}
                            onChange={() => changeAzureOnboardingType(AzureIntelligenceOnboardingType.centralized)}
                        />
                        <Tooltip content={t('SCOPE.TOOLTIP')}>
                            <Icon size={12} name='info' />
                        </Tooltip>
                    </Stack>
                </Stack>
            </Stack>
        </AzureOnboardingStep>
    );
};

export default WelcomeNetworkTraffic;
