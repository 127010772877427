import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REACT_HOOK_FORM_VALIDATIONS } from './Login.consts';
import { submitLogin } from 'platform/auth/Auth.actions';
import { RootState } from 'common/services/store/store';
import { isEmailValid, isRequired } from 'common/utils/http';
import { IFormError, LoginFormState } from './LoginForm.interface';
import { getStoreService } from 'common/interface/services';

const initialState: LoginFormState = {
    username: '',
    password: '',
    mfa: '',
    mfaRequired: false,
    isLoading: false,
    error: false,
    errors: {},
};

const loginFromSlice = createSlice({
    name: 'loginForm',
    initialState,
    reducers: {
        setUsername: (state: LoginFormState, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setPassword: (state: LoginFormState, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setMfa: (state: LoginFormState, action: PayloadAction<string>) => {
            state.mfa = action.payload;
        },
        setMfaRequired: (state: LoginFormState, action: PayloadAction<boolean>) => {
            state.mfaRequired = action.payload;
        },
        setLoginFormIsLoading: (state: LoginFormState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setHasError: (state: LoginFormState, action: PayloadAction<{ hasError: boolean; errorText?: string }>) => {
            state.error = action.payload.hasError;
            state.errorText = action.payload.errorText;
        },
        setUsernameError: (state: LoginFormState, action: PayloadAction<IFormError>) => {
            state.errors.username = action.payload;
        },
        setPasswordError: (state: LoginFormState, action: PayloadAction<IFormError>) => {
            state.errors.password = action.payload;
        },
        setMfaError: (state: LoginFormState, action: PayloadAction<IFormError>) => {
            state.errors.mfa = action.payload;
        },
        setMfaEnforcedError: (state: LoginFormState, action: PayloadAction<IFormError>) => {
            state.errors.mfaEnforced = action.payload;
        },
        clearErrors: (state: LoginFormState) => {
            state.errors = {};
            state.error = false;
        },
        clearLoginForm: () => initialState,
    },
});

export const {
    setUsername,
    setPassword,
    setMfaRequired,
    setLoginFormIsLoading,
    setHasError,
    setUsernameError,
    setPasswordError,
    setMfaError,
    setMfaEnforcedError,
    clearErrors,
    setMfa,
    clearLoginForm,
} = loginFromSlice.actions;

export const getIsLoginFormLoading = (state: RootState): boolean => state.loginForm.isLoading;
export const getIsLoginFormHasErrors = (state: RootState): boolean => state.loginForm.error;
export const getLoginFormErrors = (state: RootState): typeof state.loginForm.errors => state.loginForm.errors;
export const getMfa = (state: RootState) => state.loginForm.mfa;
export const getMfaRequired = (state: RootState): boolean => state.loginForm.mfaRequired;
export const getLoginFormUsername = (state: RootState): string => state.loginForm.username;
export const getLoginFormPassword = (state: RootState): string => state.loginForm.password;

export const validateLogin = async () => {
    const { state, dispatch } = getStoreService().getReduxTools();
    if (state.loginForm.mfaRequired && !isRequired(state.loginForm.mfa)) {
        dispatch(setMfaError({ type: REACT_HOOK_FORM_VALIDATIONS.REQUIRED }));
        return;
    }
    if (!isRequired(state.loginForm.username) && !isRequired(state.loginForm.password)) {
        dispatch(setPasswordError({ type: REACT_HOOK_FORM_VALIDATIONS.REQUIRED }));
        return;
    }
    if (!isEmailValid(state.loginForm.username)) {
        dispatch(setUsernameError({ type: REACT_HOOK_FORM_VALIDATIONS.VALIDATE }));
        return;
    }
    return dispatch(clearErrors());
};

export const handleKeyPress = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
        e.preventDefault();
        await submitLogin();
    }
};

export default loginFromSlice.reducer;
