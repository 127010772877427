import { CpCheckbox, CpRadioButton } from '@dome9/components/react/components';
import styled from 'styled-components/macro';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getStoreService, getUserService } from 'common/interface/services';
import { AwsOnboardingAccountType, AwsOnboardingType } from './helpers/aws.interface';
import { Stack, Input } from 'common/design-system/components-v2';
import {
    getAllowChinaPrivacyPolicy,
    getAwsOnboadingAccountType,
    getAwsOnboadingType,
    getChinaPolicyNotValid,
    getIsAccountAlreadyOnboarded,
    getIsOrgSelected,
    getWelcomeStepClicked,
    setAllowCGUpdateStack,
    setAllowChinaPrivacyPolicy,
    setAwsOnboardingAccountType,
    setAwsOnboardingType,
    setIsOrgOnboarding,
    setManagementAccountId,
} from './helpers/aws.reducer';
import WelcomeCards from './components/WelcomeCards';
import { Checkbox, Icon } from '@dome9/berries/react-components';
import { logEvent } from 'common/utils/logUtils';
import { isChina, isNotChina } from 'common/utils/dataCenter';
import useCustomValidation from 'common/hooks/useCustomValidation';
import { managementAccountIdValidation } from './helpers/aws.validations';
import { InputWrapper } from './helpers/aws.styled';

interface AWSWelcomeProps {
    onChangeType: () => void;
}

const CustomCheckbox = styled(Checkbox)`
  i.svg-icon-wrapper svg {
    fill: #79c353; {
`;

const AWSOnboardingWelcome: FC<AWSWelcomeProps> = (props: AWSWelcomeProps) => {
    const { state, dispatch } = getStoreService().getReduxTools();
    const { t } = useTranslation();
    const [accountId, setAccountId] = useState(state?.onBoarding?.managementAccountId || '');
    const awsOnboardingType = useSelector(getAwsOnboadingType);
    const awsOnboardingAccountType = useSelector(getAwsOnboadingAccountType);
    const isAllowChinaPrivacyPolicy = useSelector(getAllowChinaPrivacyPolicy);
    const isChinaPolicyNotValid = useSelector(getChinaPolicyNotValid);
    const isWelcomeStepClicked = useSelector(getWelcomeStepClicked);
    const isOrgSelected = useSelector(getIsOrgSelected);
    const formValidations = useCustomValidation({ yupValidationObject: managementAccountIdValidation, payload: { managementAccountId: accountId } });
    const isAccountAlreadyOnboarded = useSelector(getIsAccountAlreadyOnboarded);

    useEffect(() => {
        if (isChina()) {
            toggleChinaPolicyWhenInChina(true);
            accountTypeSelected(AwsOnboardingAccountType.awschina);
        }
    },[]); // eslint-disable-line

    const dispatchAndRerender = (type: AwsOnboardingType) => {
        dispatch(setAwsOnboardingType(type));
        props.onChangeType();
    };

    const setIsOrgSelected = (isSelected: boolean) => {
        dispatch(setIsOrgOnboarding(isSelected));
        dispatchAndRerender(isSelected ? AwsOnboardingType.organizational : AwsOnboardingType.simple);
    };

    const accountTypeSelected = (accountType: AwsOnboardingAccountType) => {
        dispatch(setAwsOnboardingAccountType(accountType));
        props.onChangeType();
    };

    const toggleAllowCGUpdateStack = (allow: boolean) => {
        dispatch(setAllowCGUpdateStack(allow));
    };

    const toggleChinaPolicy = (allow: boolean) => {
        const user = getUserService().getUser();
        dispatch(setAllowChinaPrivacyPolicy(allow));
        if (allow) {
            logEvent('policyAllowed', {
                message: t('ON_BOARDING.WELCOME.CHINA_PRIVACY_POLICY_ACKED', { userEmail: user.email }),
                userEmail: user.email,
            });
        }
    };

    const toggleChinaPolicyWhenInChina = (allow: boolean) => {
        dispatch(setAllowChinaPrivacyPolicy(allow));
        return true;
    };
    
    const onManagementAccountIdChange = (ev: any) => {
        setAccountId(ev.target.value);
        dispatch(setManagementAccountId(ev.target.value));
    };

    return (
        <div className='aws-onboarding-welcome-wrapper'>
            <div className='title-large'>{t('ON_BOARDING.WELCOME.WELCOME_TITLE')}</div>
            <div className='text-body aws-onboarding-welcome-description'>
                {t('ON_BOARDING.WELCOME.WELCOME_DESCRIPTION')}
            </div>
            <div className='flex-column'>
                <div className='aws-onboarding-welcome-actions  flex-column'>
                    <div className='select-ou-onboarding'>
                        <p className='sub-header'>{t('ON_BOARDING.WELCOME.SELECT_WHAT_TO_ONBOARD')}</p>
                        {isNotChina() ?
                            <div>
                                <CpRadioButton
                                    className='aws-onboarding-welcome-wrapper-account-type-selection-radio-button'
                                    checked={isOrgSelected}
                                    value={'organiztion'}
                                    name={'onboardingType'}
                                    data-aid='org-onboarding-radio'
                                    onClick={() => setIsOrgSelected(true)}>
                                    {t('ON_BOARDING.WELCOME.ORGANIZATION')}
                                </CpRadioButton>
                                <CpRadioButton
                                    className='aws-onboarding-welcome-wrapper-account-type-selection-radio-button'
                                    checked={!isOrgSelected}
                                    value={'single-account'}
                                    name={'onboardingType'}
                                    onClick={() => setIsOrgSelected(false)}>
                                    {t('ON_BOARDING.WELCOME.SINGLE_ACCOUNT')}
                                </CpRadioButton>
                            </div> :
                            <div>
                                <CpRadioButton
                                    className='aws-onboarding-welcome-wrapper-account-type-selection-radio-button'
                                    checked={true}
                                    value={'single-account'}
                                    name={'onboardingType'}
                                    onClick={() => setIsOrgSelected(false)}>
                                    {t('ON_BOARDING.WELCOME.SINGLE_ACCOUNT')}
                                </CpRadioButton>
                            </div>
                        }
                    </div>
                    {
                        !isOrgSelected &&
                        <>
                            <p className='sub-header'> {t('ON_BOARDING.WELCOME.SELECT_TYPE')}</p>
                            { isNotChina() ?
                                <div className='text-body aws-onboarding-welcome-wrapper-account-type-selection'>
                                    <CpRadioButton
                                        className='aws-onboarding-welcome-wrapper-account-type-selection-radio-button'
                                        checked={awsOnboardingAccountType === AwsOnboardingAccountType.aws}
                                        value={'aws'}
                                        name={'accountType'}
                                        readOnly={awsOnboardingAccountType === AwsOnboardingAccountType.aws}
                                        onToggleRadioButton={() => accountTypeSelected(AwsOnboardingAccountType.aws)}>
                                        {t('ON_BOARDING.ACCOUNT.STANDARD')}
                                    </CpRadioButton>
                                    <CpRadioButton
                                        className='aws-onboarding-welcome-wrapper-account-type-selection-radio-button'
                                        checked={awsOnboardingAccountType === AwsOnboardingAccountType.awsgov}
                                        value={'awsgov'}
                                        name={'accountType'}
                                        readOnly={awsOnboardingAccountType === AwsOnboardingAccountType.awsgov}
                                        onToggleRadioButton={() => accountTypeSelected(AwsOnboardingAccountType.awsgov)}>
                                        {t('ON_BOARDING.ACCOUNT.GOV_CLOUD')}
                                    </CpRadioButton>
                                    <CpRadioButton
                                        className='aws-onboarding-welcome-wrapper-account-type-selection-radio-button'
                                        checked={awsOnboardingAccountType === AwsOnboardingAccountType.awschina}
                                        value={'awschina'}
                                        name={'accountType'}
                                        readOnly={awsOnboardingAccountType === AwsOnboardingAccountType.awschina}
                                        onToggleRadioButton={() => accountTypeSelected(AwsOnboardingAccountType.awschina)}>
                                        {t('ON_BOARDING.ACCOUNT.CHINA_CLOUD')}
                                    </CpRadioButton>
                                </div> :
                                <div className='text-body aws-onboarding-welcome-wrapper-account-type-selection'>
                                    <CpRadioButton
                                        className='aws-onboarding-welcome-wrapper-account-type-selection-radio-button'
                                        checked={true}
                                        value={'awschina'}
                                        name={'accountType'}
                                        readOnly={awsOnboardingAccountType === AwsOnboardingAccountType.awschina}
                                        onToggleRadioButton={() => accountTypeSelected(AwsOnboardingAccountType.awschina)}>
                                        {t('ON_BOARDING.ACCOUNT.CHINA_CLOUD')}
                                    </CpRadioButton>
                                </div>
                            }
                            {
                                awsOnboardingAccountType === AwsOnboardingAccountType.awschina && isNotChina() &&
                                <div>
                                    <div
                                        className={`china-checkbox-wrapper ${isWelcomeStepClicked && isChinaPolicyNotValid ? 'error-state' : ''}`}>
                                        <CpCheckbox
                                            checked={isAllowChinaPrivacyPolicy}
                                            onCpChange={(ev: any) => toggleChinaPolicy(ev.detail.value)}
                                            className='text-body'>
                                            <span>
                                                {t('ON_BOARDING.WELCOME.CHINA_PRIVACY_POLICY_AGREEMENT')}
                                            </span>
                                        </CpCheckbox>
                                    </div>
                                    {isWelcomeStepClicked && isChinaPolicyNotValid &&
                                        <div className={'mb-8 mt-4'}>
                                            <Icon className='mr-5 relative top-2' size={12} color='danger'
                                                name='error'></Icon>
                                            {t('ON_BOARDING.WELCOME.CHINA_PRIVACY_ERROR_MSG')}
                                        </div>
                                    }
                                </div>
                            }

                            <p className='sub-header'>{t('ON_BOARDING.WELCOME.CFT_PERMISSION_MANAGEMENT')}</p>
                            <CpCheckbox
                                onCpChange={(ev: any) => toggleAllowCGUpdateStack(ev.detail.value)}
                                className='text-body'>
                                <span>
                                    {t(
                                        isOrgSelected ?
                                            'ON_BOARDING.WELCOME.ALLOW_CLOUDGUARD_TO_UPDATE_AND_DELETE_ITS_CLOUDFORMATION_STACK_RESOURCES_ORGANIZATIONAL' :
                                            'ON_BOARDING.WELCOME.ALLOW_CLOUDGUARD_TO_UPDATE_AND_DELETE_ITS_CLOUDFORMATION_STACK_RESOURCES',
                                    )}
                                </span>
                            </CpCheckbox>
                            <p className='text-body'>
                                {t('ON_BOARDING.WELCOME.IMPORTANT_MESSAGE_THIS_SETTING_CANNOT_BE_CHANGED')}
                            </p>
                            <WelcomeCards
                                onCardSelected={(val) => dispatchAndRerender(val)}
                                awsOnboardingType={awsOnboardingType}
                                awsOnboardingAccountType={awsOnboardingAccountType}
                            />
                        </>
                    }
                    {
                        isOrgSelected &&
                        <Stack>
                            <CustomCheckbox readOnly checked={true}
                                // @ts-ignore
                                label={<Trans components={{ b: <strong /> }}>{t('ON_BOARDING.WELCOME.ACCOUNT_DISCOVERY')}</Trans>}></CustomCheckbox>
                            <CustomCheckbox readOnly checked={true}
                                // @ts-ignore
                                label={<Trans components={{ b: <strong /> }}>{t('ON_BOARDING.WELCOME.CSPM')}</Trans>}></CustomCheckbox>
                            <CustomCheckbox readOnly checked={true}
                                // @ts-ignore
                                label={<Trans components={{ b: <strong /> }}>{t('ON_BOARDING.WELCOME.SERVERLESS')}</Trans>}></CustomCheckbox>
                            <CustomCheckbox readOnly checked={true}
                                // @ts-ignore
                                label={<Trans components={{ b: <strong /> }}>{t('ON_BOARDING.WELCOME.AGENTLESS_WORKLOAD_POSTURE')}</Trans>}></CustomCheckbox>

                            <CustomCheckbox readOnly checked={true}
                                // @ts-ignore
                                label={<Trans components={{ b: <strong /> }}>{t('ON_BOARDING.WELCOME.CDR')}</Trans>}></CustomCheckbox>
                            <InputWrapper>
                                <Stack direction="column" margin={[1,0,1,1]}>
                                    <Input
                                        data-aid='aws-welcome-management-account-id-input'
                                        label={t('ON_BOARDING.WELCOME.MANAGEMENT_ACCOUNT_ID')}
                                        value={accountId}
                                        onChange={onManagementAccountIdChange}
                                        isError={!!(isAccountAlreadyOnboarded || formValidations?.errors?.managementAccountId)}
                                        helperText={formValidations?.errors?.managementAccountId ? formValidations.errors.managementAccountId[0]?.message : undefined}
                                        required
                                        fullWidth
                                        clearable
                                    />
                                </Stack>
                            </InputWrapper>
                        </Stack>
                    }
                </div>
            </div>
        </div>
    );
};

export default AWSOnboardingWelcome;
