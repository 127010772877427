import React from 'react';
import { Icon } from 'common/design-system/components-v2';
import globalAddinContainer from 'common/extensibility/AddinContainer';
import {
    IEnvironmentsGetStarted,
    PATH_ENVIRONMENTS_GET_STARTED
} from 'common/module_interface/assets/GetStartedPage';
import { useTranslation } from 'react-i18next';
import GetStartedCard from './GetStartedCard';
import { Link } from 'react-router-dom';

const GetStarted: React.FunctionComponent = () => {

    const { t } = useTranslation();
    const cards = globalAddinContainer.get<IEnvironmentsGetStarted>(PATH_ENVIRONMENTS_GET_STARTED);

    return (
        <div className={'flex flex-col items-center h-full justify-center'}>
            <Icon className={'pb-7'} name={'cloudGuard'} size={96} />
            <div className={'pb-9 text-3xl text-[#29374E]'}><b>{t('GET_STARTED.WELCOME')}</b> {t('GET_STARTED.TO_CLOUDGUARD')}</div>
            <div className={'pb-9 text-md'}>{t('GET_STARTED.SUB_TITLE')}</div>
            <div className='mb-8 grid gap-2 sm:gap-7 xl:gap-9 lg:gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'>
                {cards.map((environment: IEnvironmentsGetStarted, cardIndex: number) => {
                    const { vendor, linkTo, isReact, title } = environment;
                    return (
                        isReact ? <Link to={linkTo}><GetStartedCard vendor={vendor} linkTo={linkTo} title={title} isReact={isReact} cardIndex={cardIndex} /></Link>
                            : <GetStartedCard vendor={vendor} linkTo={linkTo} title={title} isReact={isReact} cardIndex={cardIndex} />
                    );
                })}

            </div>
        </div>
    );
};


export default GetStarted;
