import { CpIcon } from '@dome9/components/react/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import FormValidations from './FormValidations';

export const PasswordValidationHelper: React.FC<passwordValidationProps> = (props) => {
    const useStyles = createUseStyles({
        passwordValidationBox: {
            '& .text-container': {
                padding: '0 10px',
                '& p': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                },
                '& .valid': {
                    color: '#56B40A',
                    fill: '#56B40A',
                },
                '& .invalid': {
                    color: '#F24E4E',
                    fill: '#F24E4E',
                },
            },
            '& .progressBarContainer': {
                '& div': {
                    height: '2px',
                    width: '0%',
                },
                '& .low': {
                    width: '30%',
                    backgroundColor: '#F24E4E',
                },
                '& .medium': {
                    width: '60%',
                    backgroundColor: '#FF9126',
                },
                '& .high': {
                    width: '100%',
                    backgroundColor: '#56B40A',
                },
            },
        },
    });

    const classes = useStyles();

    const passwordValidatoConditions = FormValidations.getPasswordValidationHelperValues(props.passwordString);

    const getStatusByNumberOfValid = (numberOfValid: number) => {
        switch (numberOfValid) {
            case 0:
                return 'none';
            case 1:
                return 'low';
            case 2:
                return 'medium';
            case 3:
                return 'high';
        }
    };

    const { t } = useTranslation();

    return (
        <div className={classes.passwordValidationBox}>
            <div className='text-container'>
                <p>Your password needs to:</p>

                {passwordValidatoConditions.map((condition) => {
                    return (
                        <p key={condition.value} className={condition.isValid ? 'valid' : 'invalid'}>
                            <CpIcon className='ml-xs ' icon={condition.isValid ? 'check' : 'close'} />
                            {t(condition.value)}
                        </p>
                    );
                })}
            </div>
            <div className='progressBarContainer'>
                <div
                    className={getStatusByNumberOfValid(
                        passwordValidatoConditions.filter((condition) => condition.isValid === true).length
                    )}></div>
            </div>
        </div>
    );
};

interface passwordValidationProps {
    passwordString: string;
}
export default PasswordValidationHelper;
