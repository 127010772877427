import { AxiosError } from 'axios';
import { navigateToLoginAndClearState } from 'platform/user/User.actions';
import i18n from 'common/services/translations/translations';
import { getReturnUrl, tryExtractResponseText } from '../../common/utils/http';
import { getNotificationsService, getStoreService } from '../../common/interface/services';
import { NotificationType } from '../../common/interface/notifications';

export const handleError = (error: AxiosError): void => {
    const errorCode = error?.response?.status || -1;

    switch (true) {
        case errorCode === 401:
            authError(error);
            break;
        case errorCode >= 400 && errorCode < 500:
            clientError(error);
            break;
        case errorCode >= 500 && errorCode < 600:
            serverError(error);
            break;
    }
};

const authError = (error: AxiosError<any>) => {
    const storeService = getStoreService();
    storeService.dispatch(navigateToLoginAndClearState(getReturnUrl(), error));
};

// Consider calling the logger service in order to report error

const serverError = (error: AxiosError<any>) => {
    const alertsService = getNotificationsService();
    alertsService.clearAllNotifications();
    alertsService.addNotification({
        type: NotificationType.ERROR,
        title: i18n.t('HTTP.SERVER_ERROR_TITLE'),
        text: tryExtractResponseText(error) || i18n.t('HTTP.SERVER_ERROR', { error }),
    });
};

const clientError = (error: AxiosError<any>) => {
    const alertsService = getNotificationsService();
    if (error.response?.data?.message) {
        error.message = error.response?.data?.message || error.message;
    }
    alertsService.addNotification({
        type: NotificationType.ERROR,
        title: i18n.t('HTTP.CLIENT_ERROR'),
        text: tryExtractResponseText(error) || i18n.t('HTTP.UNEXPECTED_ERROR'),
    });
};

export const apiMessageError = (error: AxiosError<any>) => {
    const alertsService = getNotificationsService();
    let message = i18n.t('HTTP.UNEXPECTED_ERROR');
    if (error.response?.data?.message) {
        message = error.response.data.message;
    }
    alertsService.addNotification({
        type: NotificationType.ERROR,
        title: i18n.t('HTTP.CLIENT_ERROR'),
        text: message,
    });
    throw error;
};

