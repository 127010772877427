import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 285px;
`;

const ContentDiv = styled.div`
  align-items: center;
  width: 100%;
`;

const AlertDiv = styled(Stack)`
    width: 100%;
    margin-bottom: 10px;
`;

const WidgetsWrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const FailureDiv = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.status.error};
`;

export const WAFAssetsByRiskStyled = {
    TopDiv,
    AlertDiv,
    ContentDiv,
    WidgetsWrapperDiv,
    FailureDiv
};
