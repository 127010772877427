import React from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { ISecurityControl } from '../SecurityControls.interface';
import { MultiChipCellContent } from 'common/erm-components/custom/MultiChipCellContent/MultiChipCellContent';

const SecurityControlSystemLabelsCellRenderer: React.FC<ICellRendererParams<ISecurityControl>> = (params) => {
    const systemLabels: string[] | undefined = params.data?.systemLabels;
    if (!systemLabels || (systemLabels.length === 0)) {
        return null;
    }
    return <MultiChipCellContent propsList={systemLabels.map(label => ({ label }))} />;
};

export default SecurityControlSystemLabelsCellRenderer;