import React from 'react';
import { Message } from 'common/design-system/components-v2';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { SelectedTab } from '../../AzureIntelligenceOnboarding.interface';

interface IConfirmModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    tab: SelectedTab;
}

const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
    const { isModalOpen, onClose, onConfirm, tab } = props;
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING.STORAGE.SWITCH_CONFIRMATION' });

    return (
        <Message
            isOpen={isModalOpen}
            onClose={onClose}
            title={t('TITLE')}
            text={t('DESCRIPTION')}
            submitBtnText={t(`CONFIRM_${tab === SelectedTab.List ? 'MANUAL' : 'LIST'}`)}
            cancelBtnText={t('CANCEL')}
            onConfirm={onConfirm}
            onCancel={onClose}
            width='lg'
        />
    );
};

export default ConfirmModal;