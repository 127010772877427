import { ISecurityControl } from './SecurityControls.interface';
import { IFilterConditionsContainer } from 'common/erm-components/custom/FilterTree/FilterCondition';
import { SECURITY_CONTROL_FIELD_NAMES } from './SecurityControls.consts';
import { ermTrans } from '../../RiskManagement.utils';
import {
    createFreeTextFilter,
    createSelectionFilter,
    getCommonFiltersMap
} from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.filters';
import { CommonFilterEnum } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPageTable.consts';
import { IClientFilterType } from 'common/erm-components/custom/ClientFilterPageTable/ClientFilterPage.interface';

export const getSecurityControlsFilters = (): IClientFilterType<ISecurityControl, IFilterConditionsContainer>[] => {
    const commonMap = getCommonFiltersMap<ISecurityControl>();
    return [
        commonMap[CommonFilterEnum.ADD_FILTER],
        commonMap[CommonFilterEnum.CLEAR_ALL],
        createFreeTextFilter([
            SECURITY_CONTROL_FIELD_NAMES.NAME
        ]),
        createSelectionFilter(
            SECURITY_CONTROL_FIELD_NAMES.SEVERITY, ermTrans('SECURITY_CONTROLS.TABLE.FILTERS.SEVERITY.TITLE')),
        createSelectionFilter(
            SECURITY_CONTROL_FIELD_NAMES.PLATFORMS, ermTrans('SECURITY_CONTROLS.TABLE.FILTERS.PLATFORMS.TITLE')),
        createSelectionFilter(
            SECURITY_CONTROL_FIELD_NAMES.SYSTEM_LABELS, ermTrans('SECURITY_CONTROLS.TABLE.FILTERS.SYSTEM_LABELS.TITLE')),
    ];
};