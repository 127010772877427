import { AlibabaEnvironmentData } from 'common/module_interface/assets/AlibabaEnvironment';

export interface AlibabaState {
    alibabaEvnironments: {
        [alibabaId: string]: {
            data?: AlibabaEnvironmentData;
            loading?: boolean;
            error?: boolean;
            deleted?: boolean;
        };
    };
}

export const AlibabaInitialState: AlibabaState = {
    alibabaEvnironments: {},
};