import { IDisplayMappingObject } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';
import { FILTERS_KEYS } from 'common/components/FilterPanel/FilterPanel.consts';
import {
    renderAddFilter,
    renderClearAll,
    renderMultiSelectFilter,
    renderTreeFilter
} from 'common/components/FilterPanel/FilterPanelDefaultFilters';
import { VENDORS, getVendor } from 'common/consts/vendors';
import { Addin } from 'common/extensibility/AddinRegistry';
import { ICloudAccount, IOrganizationalUnit } from 'common/interface/data_services';
import i18n from 'i18next';

export type RulesetDTO = {
    name: string;
    description: string;
    icon: string;
    selected?: boolean;
    id: number;
    isTemplate?: boolean;
    cloudVendor?: string;
    common: boolean;
    default: boolean;
};

export interface IFiltersInitialData {
    organizationalUnits: IOrganizationalUnit
    allCloudAccounts: ICloudAccount[],
    rulsets: RulesetDTO[],
}

export const complianceFilterDefsAddins: Addin[] =
    [
        {
            id: 'add-filter',
            content: {
                filterProps: { key: 'add-filter' },
                renderFunction: renderAddFilter,
            },
        },
        {
            id: 'organizationalUnitId',
            content: function (filtersInitialData: IFiltersInitialData) {
                return {
                    filterProps: {
                        initialData: filtersInitialData?.organizationalUnits,
                        key: 'organizationalUnitId',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ORGANIZATIONAL_UNITS.TITLE'),
                    },
                    renderFunction: renderTreeFilter,
                };
            },
        },
        {
            id: 'cloudAccountId',
            content: function (filtersInitialData: IFiltersInitialData) {
                const allCloudAccounts = filtersInitialData?.allCloudAccounts;
                const environmentList = allCloudAccounts?.map(account => ({ value: account.id, label: account.name }));

                const mapEnvironmentItem = (environmentList: any) => {
                    function getEnvironmentDisplayValueFromCloudAccount(cloudAccount: ICloudAccount, cloudAccountId: string) {
                        if (cloudAccount.name) {
                            return `${cloudAccount.name} (${cloudAccount.externalId})`;
                        }
                        return cloudAccount.externalId || cloudAccountId;
                    }
                    if (environmentList) {
                        return environmentList.reduce((prev: any, cloudAccountId: any) => {
                            const cloudAccount = allCloudAccounts?.find((cloudAccount: ICloudAccount) => cloudAccount.id === cloudAccountId.value) ?? null;
                            const icon = cloudAccount?.platform ? getVendor(cloudAccount?.platform)?.icon : '';
                            const displayValue = cloudAccount ? getEnvironmentDisplayValueFromCloudAccount(cloudAccount, cloudAccountId.value) : cloudAccountId.value;
                            prev[cloudAccountId.value] = { displayText: displayValue, icon };
                            return prev;
                        }, {});
                    } else {
                        return null;
                    }
                };

                return {
                    filterProps: {
                        initialData: environmentList,
                        key: 'cloudAccountId',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.ENVIRONMENT.TITLE'),

                        displayMapping: mapEnvironmentItem(environmentList),
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'platform',
            content: function () {
                const platforms = VENDORS.reduce((result: { value: string, label: string }[], vendor: any) => {
                    if (vendor.isMain) {
                        result.push({ value: vendor.name, label: vendor.displayName });
                    }
                    return result;
                }, []);
                const displayMapping: IDisplayMappingObject = {};
                platforms?.forEach((platform: { value: string }) => {
                    displayMapping[platform.value] = {
                        displayText: platform.value,
                        icon: getVendor(platform.value)?.icon,
                    };
                });
                return {
                    filterProps: {
                        initialData: platforms,
                        key: 'platform',
                        title: i18n.t('ASSETS.PROTECTED_ASSETS.FILTERS.PLATFORM.TITLE'),
                        displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            },
        },
        {
            id: 'bundleName',
            content: function (filtersInitialData: IFiltersInitialData) {
                const displayMapping: IDisplayMappingObject = {};
                const rulesets = filtersInitialData?.rulsets;
                rulesets?.forEach((ruleset) => {
                    displayMapping[ruleset.id] = {
                        displayText: ruleset.name,
                        icon: ruleset.cloudVendor,
                    };
                });
                return {
                    filterProps: {
                        initialData: rulesets?.map(rule => ({ value: rule.id, label: rule.name })),
                        key: 'bundleName',
                        title: i18n.t('EVENTS.FILTERS.RULESET.TITLE'),
                        displayMapping: displayMapping,
                    },
                    renderFunction: renderMultiSelectFilter,
                };
            }
        },
        {
            id: 'clearAll',
            content: {
                filterProps: {
                    key: FILTERS_KEYS.CLEAR_BUTTON,
                },
                renderFunction: renderClearAll,
            },
        },
    ];

