import React, { useEffect, useMemo, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { ConnectionStatus } from 'common/module_interface/intelligence/Intelligence.const';
import { ILogTypesModel, IStorageModel } from '../../AzureIntelligenceOnboarding.interface';
import { SelectV2, Stack } from 'common/design-system/components-v2';
import { SelectOption } from 'common/design-system/components-v2/SelectV2/Select.types';
import { useTranslation } from 'react-i18next';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useSelector } from 'react-redux';
import { getAllStorages, setAllStorages } from '../../AzureIntelligenceOnboarding.reducer';
import { getStoreService } from 'common/interface/services';

const LogTypesCellRenderer: React.FC<ICellRendererParams> = ({ data }) => {
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING.STORAGE' });
    const [logTypeOptions, setLogTypeOptions] = useState<SelectOption[]>([]);
    const [selectedLogTypes, setSelectedLogTypes] = useState<string[]>([]);
    const { dispatch } = getStoreService().getReduxTools();

    const allStorages = useSelector(getAllStorages);
    const selectedStorages = allStorages.filter(storage => storage.isSelected);

    const currentRow = selectedStorages?.find(row => row.id === data?.id);
    const allowSelection = !!currentRow && data?.status !== ConnectionStatus.connected;
 
    useEffect(() => {
        const logTypeOptions: SelectOption[] = [];

        data?.logTypesFound.forEach((item: ILogTypesModel) => {
            const isReadOnlyLogType = item.logTypeStatus === ConnectionStatus.connected;
            logTypeOptions.push({
                label: item.logType,
                value: item.logType,
                disabled: isReadOnlyLogType,
            });
        });
        setLogTypeOptions(logTypeOptions);

        if (data.status === ConnectionStatus.connected) {
            setSelectedLogTypes(logTypeOptions.map(type => type.value));
        } else {
            setSelectedLogTypes(currentRow?.logsType ?? []);
        }
    }, [data, currentRow?.logsType]);

    const handleOnChange = (value: string[]) => {
        setSelectedLogTypes(value);

        const getUpdatedRowsWithLogType = (rowData: IStorageModel[]) => {
            return rowData.map(row => row.id === data.id ?
                { ...row, logsType: [...value] } : row
            );
        };

        dispatch(setAllStorages(getUpdatedRowsWithLogType(allStorages)));
    };

    const isDisabled = useMemo(() => {
        return data?.status === ConnectionStatus.connected || !allowSelection;
    }, [data?.status, allowSelection]);

    return (
        <Stack direction={'row'} fullHeight fullWidth>
            <SelectV2
                isMulti
                options={logTypeOptions}
                onChange={handleOnChange}
                disabled={isDisabled}
                fullWidth
                value={selectedLogTypes}
                placeholder={t('SELECT')}
            />
        </Stack>
    );
};

export default LogTypesCellRenderer;
