import React from 'react';
import { Dropdown, List } from 'common/design-system/components-v2';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import { ToolBarProps } from './Toolbar.types';

const Toolbar: React.FunctionComponent<ToolBarProps> = ({ actionClick, options }) => {
    const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

    const dropdownListOptions = React.useMemo<IListItemProps[]>(() => {
        const listItems: IListItemProps[] = [];
        options.forEach((action, index) => {
            const { label, icon } = action;
            listItems.push({
                label,
                icon: icon || '',
                onClick: () => {
                    actionClick(action);
                    setIsDropdownOpen(false);
                },
                value: `${label}-${index}`
            });
        });
        return listItems;
    }, [options, actionClick]);

    if (!options.length) return null;
    
    return (
        <Dropdown
            open={isDropdownOpen}
            onStateChange={(state) => setIsDropdownOpen(state)}
            buttonProps={{
                iconButton: true,
                iconProps: { name: 'more' },
            }}
            maxHeight={500}
        >
            <List
                options={dropdownListOptions}
            />
        </Dropdown>
    );
};


export default Toolbar;
