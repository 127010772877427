import { AssetTypes } from 'common/assets/assets.const';
import { ICachingConfig } from 'common/interface/services';
import { AggregationConfig, Datasource, IDataSourceConfig } from 'common/components/ProtectedAssets/datasource';
import { Aggregations, IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { SortingModel } from 'common/components/Findings/Findings.interface';
import {
    BUSINESS_PRIORITY_TYPES,
    DATA_SENSITIVITY_VALUES,
    DEFAULT_PROTECTED_ASSETS_SORT_MODEL,
    FULL_ERM_AGGREGATION, I18nRiskNamespace,
} from '../../consts';
import {
    getErmSupportedAssets,
    RiskModifierSupportedTypes,
} from 'common/module_interface/RiskManagement/DataUtils';
import i18n from 'common/services/translations/translations';
import { ImportanceLevelMap } from 'common/consts/ImportanceLevel';
import { AllIconsName, IconComponentProps } from 'common/design-system/components-v2/Icon/Icon.types';
import { DataAssetCategoriesMap, DataAssetsCategory } from '../../DataAssetCategories';

export interface INetworkExposureLevelInfo {
    serverIds: string[];
    title: string;
    iconProps: IconComponentProps;
    bg: string;
}

export interface IIamSensitivityInfo {
    low: number;
    high: number;
    title: string;
    iconProps: IconComponentProps;
    bg: string;
    index: number;
}

export const iamSensitivityInfos: IIamSensitivityInfo[] = [
    {
        low: 0,
        high: 60,
        title: 'Low',
        iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.low.fg },
        bg: ImportanceLevelMap.low.bg,
        index: 0,
    },
    {
        low: 60,
        high: 75,
        title: 'Medium',
        iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.medium.fg },
        bg: ImportanceLevelMap.medium.bg,
        index: 1,
    },
    {
        low: 75,
        high: 90,
        title: 'High',
        iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.high.fg },
        bg: ImportanceLevelMap.high.bg,
        index: 2,
    },
    {
        low: 90,
        high: Infinity,
        title: 'Very High',
        iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.critical.fg },
        bg: ImportanceLevelMap.critical.bg,
        index: 3,
    },
];

const UNKNOWN_IAM_SENSITIVITY_INFO: IIamSensitivityInfo = {
    low: -1,
    high: -1,
    title: 'Undefined',
    iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.unknown.fg },
    bg: ImportanceLevelMap.unknown.bg,
    index: -1,
};

export const getIamSensitivityInfo = (iamSensitivity?: number): IIamSensitivityInfo => {
    if (iamSensitivity === undefined) {
        return UNKNOWN_IAM_SENSITIVITY_INFO;
    }
    return iamSensitivityInfos.find((item) => {
        return iamSensitivity >= item.low && iamSensitivity < item.high;
    }) || UNKNOWN_IAM_SENSITIVITY_INFO;
};

export interface IDataSensitivityInfo {
    key: string;
    value: string | null;
    iconProps: IconComponentProps;
    bg: string;
    index: number;
}

export const dataSensitivityInfos: IDataSensitivityInfo[] = [
    {
        key: 'False',
        value: DATA_SENSITIVITY_VALUES.NOT_SENSITIVE,
        iconProps: { name: 'dataSensitivity', customColor: ImportanceLevelMap.low.fg },
        bg: ImportanceLevelMap.low.bg,
        index: 0,
    },
    {
        key: 'True',
        value: DATA_SENSITIVITY_VALUES.SENSITIVE,
        iconProps: { name: 'dataSensitivity', customColor: ImportanceLevelMap.critical.fg },
        bg: ImportanceLevelMap.critical.bg,
        index: 1,
    },
];
const UNKNOWN_DATA_SENSITIVITY_INFO: IDataSensitivityInfo = {
    key: 'Unknown',
    value: null,
    iconProps: { name: 'iamImpact', customColor: ImportanceLevelMap.unknown.fg },
    bg: ImportanceLevelMap.unknown.bg,
    index: -1,
};

export const getDataSensitivityInfo = (dataSensitivityValue?: string): IDataSensitivityInfo => {
    if (dataSensitivityValue === undefined) {
        return UNKNOWN_DATA_SENSITIVITY_INFO;
    }
    return dataSensitivityInfos.find((item) => {
        return item.value === dataSensitivityValue;
    }) || UNKNOWN_DATA_SENSITIVITY_INFO;
};


const networkExposureLevelInfos: INetworkExposureLevelInfo[] = [
    {
        serverIds: ['Private'],
        title: 'Private',
        iconProps: { name: 'private', customColor: ImportanceLevelMap.low.fg },
        bg: ImportanceLevelMap.low.bg,
    },
    {
        serverIds: ['Partially Public', 'Partial'],
        title: 'Partially Public',
        iconProps: { name: 'semiPublic', customColor: ImportanceLevelMap.medium.fg },
        bg: ImportanceLevelMap.medium.bg,
    },
    {
        serverIds: ['Public'],
        title: 'Public',
        iconProps: { name: 'web', customColor: ImportanceLevelMap.critical.fg },
        bg: ImportanceLevelMap.critical.bg,
    },
];

interface IExtendedNetworkExposureLevelInfo extends INetworkExposureLevelInfo {
    total: number;
    position: number;
    isUnknown?: boolean;
}

export const UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY = 'Unknown';
const unknownNetworkExposureLevelInfo: IExtendedNetworkExposureLevelInfo = {
    serverIds: [UNKNOWN_NETWORK_EXPOSURE_LEVEL_KEY],
    title: 'Unknown',
    position: -1,
    total: networkExposureLevelInfos.length,
    isUnknown: true,
    iconProps: { name: 'web', customColor: ImportanceLevelMap.unknown.fg },
    bg: ImportanceLevelMap.unknown.bg,
};

export function getNetworkExposureLevelInfo(value: string | undefined): IExtendedNetworkExposureLevelInfo {
    if (value) {
        const resultIndex = networkExposureLevelInfos.findIndex((item) => item.serverIds.includes(value));
        if (resultIndex >= 0) {
            const networkExposureLevelInfo = networkExposureLevelInfos[resultIndex];
            return { ...networkExposureLevelInfo, position: resultIndex, total: networkExposureLevelInfos.length };
        }
    }

    return unknownNetworkExposureLevelInfo;
}

interface IIamExposureLevelInfo {
    serverIds: string[];
    title: string;
    iconProps: IconComponentProps;
    bg: string;
}

const iamExposureLevelInfos: IIamExposureLevelInfo[] = [
    {
        serverIds: ['Private'],
        title: 'Private',
        iconProps: { name: 'private', customColor: ImportanceLevelMap.low.fg },
        bg: ImportanceLevelMap.low.bg,
    },
    {
        serverIds: ['Partially Public', 'Partial'],
        title: 'Partially Public',
        iconProps: { name: 'semiPublic', customColor: ImportanceLevelMap.medium.fg },
        bg: ImportanceLevelMap.medium.bg,
    },
    {
        serverIds: ['Public'],
        title: 'Public',
        iconProps: { name: 'web', customColor: ImportanceLevelMap.critical.fg },
        bg: ImportanceLevelMap.critical.bg,
    },
];

interface IExtendedIamExposureLevelInfo extends IIamExposureLevelInfo {
    total: number;
    position: number;
    isUnknown?: boolean;
}

export const UNKNOWN_IAM_EXPOSURE_LEVEL_KEY = 'Unknown';
const unknownIamExposureLevelInfo: IExtendedIamExposureLevelInfo = {
    serverIds: [UNKNOWN_IAM_EXPOSURE_LEVEL_KEY],
    title: 'Unknown',
    position: -1,
    iconProps: { name: 'web', customColor: ImportanceLevelMap.unknown.fg },
    bg: ImportanceLevelMap.unknown.bg,
    total: iamExposureLevelInfos.length,
    isUnknown: true,
};

export function getIamExposureLevelInfo(value: string | undefined): IExtendedIamExposureLevelInfo {
    if (value) {
        const resultIndex = iamExposureLevelInfos.findIndex((item) => item.serverIds.includes(value));
        if (resultIndex >= 0) {
            const iamExposureLevelInfo = iamExposureLevelInfos[resultIndex];
            return { ...iamExposureLevelInfo, position: resultIndex, total: iamExposureLevelInfos.length };
        }
    }

    return unknownIamExposureLevelInfo;
}

interface IBusinessPriorityInfo {
    id: string;
    title: string;
    iconName: AllIconsName,
    bg: string,
    fg: string,
}

export interface IBusinessPriorityDisplayExtended extends IBusinessPriorityInfo {
    total: number;
    position: number;
}

const getBusinessPriorityInfos = (): IBusinessPriorityInfo[] => [
    {
        id: BUSINESS_PRIORITY_TYPES.MINOR_IMPORTANCE,
        title: i18n.t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.LOW', { ns: I18nRiskNamespace }),
        iconName: 'business',
        bg: ImportanceLevelMap.low.bg,
        fg: ImportanceLevelMap.low.fg,
    },
    {
        id: BUSINESS_PRIORITY_TYPES.IMPORTANT,
        title: i18n.t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.MEDIUM', { ns: I18nRiskNamespace }),
        iconName: 'business',
        bg: ImportanceLevelMap.medium.bg,
        fg: ImportanceLevelMap.medium.fg,
    },
    {
        id: BUSINESS_PRIORITY_TYPES.HIGH_IMPORTANCE,
        title: i18n.t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.HIGH', { ns: I18nRiskNamespace }),
        iconName: 'business',
        bg: ImportanceLevelMap.high.bg,
        fg: ImportanceLevelMap.high.fg,
    },
    {
        id: BUSINESS_PRIORITY_TYPES.CROWN_JEWEL,
        title: i18n.t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.CRITICAL', { ns: I18nRiskNamespace }),
        iconName: 'assetCrownJewel',
        bg: ImportanceLevelMap.critical.bg,
        fg: ImportanceLevelMap.critical.fg,
    },
];

export const UNDEFINED_BUSINESS_PRIORITY_ID = 'Undefined';

const getUnknownBusinessPriority = (): IBusinessPriorityDisplayExtended => {
    return (
        {
            id: UNDEFINED_BUSINESS_PRIORITY_ID,
            title: i18n.t('RISK_MANAGEMENT.BUSINESS_PRIORITY.TYPES.UNDEFINED', { ns: I18nRiskNamespace }),
            position: -1,
            iconName: 'business',
            bg: ImportanceLevelMap.unknown.bg,
            fg: ImportanceLevelMap.unknown.fg,
            total: getBusinessPriorityInfos().length,
        }
    );
};

export function getBusinessPriority(value: string | undefined): IBusinessPriorityDisplayExtended {
    if (value) {
        const businessPriorityInfos = getBusinessPriorityInfos();
        const resultIndex = businessPriorityInfos.findIndex(item => item.id === value);
        if (resultIndex >= 0) {
            const businessPriorityItem = businessPriorityInfos[resultIndex];
            return { ...businessPriorityItem, position: resultIndex, total: businessPriorityInfos.length };
        }
    }
    return getUnknownBusinessPriority();
}

const ALL_SUPPORTED_ASSET_TYPES: string[] = [];

export const getAllSupportedAssetTypes = () => {
    return [...ALL_SUPPORTED_ASSET_TYPES];
};

const ALL_DATA_ASSETS_TYPES: string[] = [];
export const getAllDataAssetsTypes = () => ALL_DATA_ASSETS_TYPES;

const RISK_MODIFIER_SUPPORTED_ASSET_TYPES: RiskModifierSupportedTypes = {
    networkExposure: [],
    iamExposure: [],
    iamSensitivity: [],
    dataSensitivity: [],
};
export async function setupRiskData() {
    const supportedAssetsData = await getErmSupportedAssets();

    ALL_SUPPORTED_ASSET_TYPES.splice(0, ALL_SUPPORTED_ASSET_TYPES.length, ...supportedAssetsData.allSupportedTypes);

    ALL_DATA_ASSETS_TYPES.length = 0;
    const categories = supportedAssetsData.dataAssetsCategories;
    for(const key in categories) {
        const categoryInfo = DataAssetCategoriesMap[key as DataAssetsCategory];
        categoryInfo.assetTypes = [];
        categories[key].forEach((type) => {
            categoryInfo.assetTypes.push(type);
            ALL_DATA_ASSETS_TYPES.push(type);
        });
    }

    const riskModifiers = Object.keys(RISK_MODIFIER_SUPPORTED_ASSET_TYPES).map(modifier => modifier as keyof RiskModifierSupportedTypes);
    riskModifiers.forEach(riskModifier => {
        RISK_MODIFIER_SUPPORTED_ASSET_TYPES[riskModifier] = supportedAssetsData.riskModifierSupportedTypes[riskModifier];
    });
}

export function isRiskSupportedForAsset(typeByPlatform: string): boolean {
    return ALL_SUPPORTED_ASSET_TYPES.includes(typeByPlatform);
}

export function isRunningStatusRelevant(typeByPlatform: string): boolean {
    const relevantAssetTypes = [AssetTypes.AWS_INSTANCE, AssetTypes.AZURE_VIRTUALMACHINE, AssetTypes.GCP_VMINSTANCE];
    return relevantAssetTypes.includes(typeByPlatform);
}

export function isDataSensitivityRelevant(typeByPlatform: string): boolean {
    return RISK_MODIFIER_SUPPORTED_ASSET_TYPES.dataSensitivity.includes(typeByPlatform);
}

export function isNetworkExposureRelevant(typeByPlatform: string): boolean {
    return RISK_MODIFIER_SUPPORTED_ASSET_TYPES.networkExposure.includes(typeByPlatform);
}

export function isIamExposureRelevant(typeByPlatform: string): boolean {
    return RISK_MODIFIER_SUPPORTED_ASSET_TYPES.iamExposure.includes(typeByPlatform);
}

export function isIamSensitivityRelevant(typeByPlatform: string): boolean {
    return RISK_MODIFIER_SUPPORTED_ASSET_TYPES.iamSensitivity.includes(typeByPlatform);
}

export const getErmAggregation = (datasource: Datasource, aggregationConfig: AggregationConfig = FULL_ERM_AGGREGATION): Promise<Aggregations> => {
    return datasource.getAdHokDataFromServer(aggregationConfig).then(response => {
        return response?.aggregations || {};
    });
};

export const createErmDataSourceConfig = (filters: IFiltersValues, pageSize: number, sortModel?: SortingModel, cachingConfig?: ICachingConfig): IDataSourceConfig => {
    return {
        pageSize,
        filters: {
            ...filters,
            includedEntityTypes: getAllSupportedAssetTypes(),
        },
        defaultSortModel: sortModel || DEFAULT_PROTECTED_ASSETS_SORT_MODEL,
        filterEntitiesWithoutRiskScore: true,
        cachingConfig,
    };
};
