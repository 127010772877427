import { IRangeFilterValue } from 'common/components/FilterPanel/DefaultFilters/DefaultFilters.interface';

export enum FilterValueType {
    STRING,
    STRING_LIST,
    NUMERIC_RANGE,
}

export type ICompactFilterValue = string | string[] | IRangeFilterValue;

export interface ICompactFilterItem {
    name: string;
    value: ICompactFilterValue;
    valueType: FilterValueType;
}
