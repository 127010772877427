import { Dropdown } from '@dome9/berries/react-components';
import React, { useState } from 'react';
import MiniMenu, { IMiniMenuItem } from '../MiniMenu';
import AddEditWidgetModal, { ActionTypes } from './AddEditWidgetModal';
import { IDashboardWidgetProps } from './GenericDashboardWidget';
import DeleteWidgetModal from './DeleteWidgetModal';
import MoveSectionWidgetModal from './MoveSectionWidgetModal';
import { useSelector } from 'react-redux';
import { getSelectedDashboard } from 'modules/overview/Overview.reducer';
import { i18nOverviewNamespace } from 'modules/overview/initialize.i18n';
import { useTranslation } from 'react-i18next';


const WidgetActions: React.FC<IDashboardWidgetProps> = ({ widget, section }) => {
    const { t } = useTranslation(i18nOverviewNamespace);
    const selectedDashboard = useSelector(getSelectedDashboard);
    const isMoveSectionOptionEnabled = selectedDashboard && selectedDashboard.sections && selectedDashboard.sections?.length > 1;
    const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isMoveSectionModalOpen, setIsMoveSectionModalOpen] = useState(false);
    const [action, setAction] = useState<ActionTypes>(ActionTypes.Edit);


    const menuItems: IMiniMenuItem[] = [
        {
            title: t('WIDGETS.ACTIONS.SETTINGS'),
            icon: 'settings',
            onClick: ()=> {
                setAction(ActionTypes.Edit);
                setIsAddEditModalOpen(true);
            },
        },
        {
            title: t('WIDGETS.ACTIONS.DUPLICATE'),
            icon: 'duplicate',
            onClick: ()=> {
                setAction(ActionTypes.Duplicate);
                setIsAddEditModalOpen(true);
            },
        },
        {
            title: t('WIDGETS.ACTIONS.MOVE'),
            icon: 'chevron-right',
            onClick: ()=> {
                setIsMoveSectionModalOpen(true);
            },
            disabled: !isMoveSectionOptionEnabled,
        },
        {
            title: t('WIDGETS.ACTIONS.DELETE'),
            icon: 'delete',
            onClick: ()=> {
                setIsDeleteModalOpen(true);
            },
        },

    ];


    return (
        <div className='absolute right-0'>
            <div className={'opacity-0 group-hover:opacity-100'}>
                <Dropdown
                    hasArrowIcon={false}
                    icon='more'
                    placement="bottom-end"
                    render={()=> <MiniMenu menuItems={menuItems}></MiniMenu>}
                    buttonVariant="quiet"
                />
            </div>
            {isAddEditModalOpen && <AddEditWidgetModal
                widget={widget}
                section={section}
                isOpen={isAddEditModalOpen}
                onRequestClose={() => {
                    setIsAddEditModalOpen(false);
                }}
                action={action}/>}
            <DeleteWidgetModal
                widget={widget}
                section={section}
                isOpen={isDeleteModalOpen}
                onRequestClose={()=> {
                    setIsDeleteModalOpen(false);
                }}
                action={action}/>
            <MoveSectionWidgetModal
                widget={widget}
                isOpen={isMoveSectionModalOpen}
                onRequestClose={()=> {
                    setIsMoveSectionModalOpen(false);
                }}
                section={section}
                action={action}/>
        </div>
    );
};

export default WidgetActions;
