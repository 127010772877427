import i18n, { validLanguages } from 'common/services/translations/translations';
import { insightNamespace } from './consts/i18n';

export function initializeInsightI18n() {
    return Promise.all(validLanguages.map(async (language) => {
        try {
            const contents = await require(`./languages/${language}/translation.json`);
            i18n.addResourceBundle(language, insightNamespace, contents);
        } catch {
            //Nothing to do with missing translation files
        }
    }));
}