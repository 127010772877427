import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TopIssuesStyled } from './TopIssues.styled';
import { LoadingState } from 'common/interface/general';
import { getTopIssues } from './TopIssues.datasource';
import { TopIssuesDashboardTable } from './TopIssuesDashboardTable';
import { ITopIssue } from '../../../../Issues/Issues.interface';
import { Card, Spinner } from 'common/design-system/components-v2';
import { RiskStyled } from '../../../../../RiskManagement.styled';
import { IServerInputFilterDetails } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { getFinalIssuesFilterDetails } from '../../../../Issues/Issues.utils';

export interface ITopIssuesInfo {
    items: ITopIssue[],
    loadingState: LoadingState;
}

const EMPTY_ITEMS_INFO = (): ITopIssuesInfo => {
    return {
        items: [],
        loadingState: LoadingState.IS_LOADING,
    };
};

export const TopIssues: React.FC<{ filterDetails?: IServerInputFilterDetails, title: string }> = ({ filterDetails, title }) => {
    const [itemsInfo, setItemsInfo] = useState<ITopIssuesInfo>(EMPTY_ITEMS_INFO());
    const finalFilter: IServerInputFilterDetails = useMemo(() => getFinalIssuesFilterDetails(filterDetails, true), [filterDetails]);
    const updateData = useCallback(() => {
        setItemsInfo(EMPTY_ITEMS_INFO());
        getTopIssues(finalFilter).then((items: ITopIssue[]) => {
            setItemsInfo({
                items,
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
        }).catch(() => {
            setItemsInfo({
                items: [],
                loadingState: LoadingState.LOADING_FAILED,
            });
        });
    }, [finalFilter]);

    useEffect(() => {
        if (filterDetails) {
            updateData();
        }
    }, [filterDetails, updateData]);

    if (itemsInfo.loadingState === LoadingState.IS_LOADING) {
        return <TopIssuesStyled.TopDiv><RiskStyled.SpinnerDiv><Spinner /></RiskStyled.SpinnerDiv></TopIssuesStyled.TopDiv>;
    }

    return (
        <Card title={title} fullWidth>
            <TopIssuesDashboardTable items={itemsInfo.items} filterDetails={finalFilter} />
        </Card>
    );
};
