export const boldSearchResult = (result: string, search: string) => {
    const indexOfSearchTerm = result.toLowerCase().indexOf(search.toLowerCase());
    if (indexOfSearchTerm === -1) {
        return (<div title={result} className='ellipsis-wrapped'>{result}</div>);
    }

    if (indexOfSearchTerm === 0) {
        return (<div title={result} className='ellipsis-wrapped'><b
            data-aid='bold-term'>{result.substring(0, search.length)}</b><span>{result.substring(search.length)}</span>
        </div>);
    }
    if (indexOfSearchTerm === result.length - search.length) {
        return (<div title={result} className='ellipsis-wrapped'>
            <span>{result.substring(0, result.length - search.length)}</span><b
                data-aid='bold-term'>{result.substring(result.length - search.length)}</b></div>);
    }
    return (<div title={result} className='ellipsis-wrapped'><span>{result.substring(0, indexOfSearchTerm)}</span><b
        data-aid='bold-term'>{result.substring(indexOfSearchTerm, indexOfSearchTerm + search.length)}</b><span>{result.substring(indexOfSearchTerm + search.length)}</span>
    </div>);
};