import React, { useEffect, useState } from 'react';
import { IEvidenceInfo, IIssue } from 'common/module_interface/RiskManagement/issues/Issues.interface';
import { IProtectedAssetViewModel } from 'common/components/ProtectedAssets/ProtectedAssetsTable.interface';
import { RiskLevelServerEnum } from 'common/module_interface/RiskManagement/RiskLevel';
import { MALWARE_LIST } from './dataMock';
import FindingSeveritySummary from '../FindingSeveritySummary/FindingSeveritySummary';

export const MalwareExtraInfo: React.FC<{ issue: IIssue, asset: IProtectedAssetViewModel, evidenceInfo?: IEvidenceInfo }>
    = () => {
        const [critical, setCritical] = useState(0);
        const [high, setHigh] = useState(0);

        useEffect(() => {
            // const res = await fetchMalwareInfo(issue, asset); //uncomment when API will be ready
            const res = MALWARE_LIST;
            setCritical(res.filter((item: { severity: RiskLevelServerEnum }) => item.severity === RiskLevelServerEnum.Critical).length);
            setHigh(res.filter((item: { severity: RiskLevelServerEnum }) => item.severity === RiskLevelServerEnum.High).length);
        }, []);

        return (
            <FindingSeveritySummary criticalCount={critical} highCount={high}/>
        );
    };

export default MalwareExtraInfo;