import { FindingToKustoMapping } from './FindingOverview/Models/FindingKustoModel';

export const ALERTS = 'alerts';
export const FINDINGS_OVERVIEW_DRAWER_ID = 'findings-overview-drawer';

export const FindingColumns = {
    ACTIONS: 'Actions'
};

export enum FindingsActions {
    FIX_IT = 'FixIt',
    REMEDIATE = 'Remediate',
    REPORT_ISSUE = 'ReportIssue',
    ACKNOWLEDGE = 'Acknowledge',
    COMMENT = 'Comment',
    CLOSE = 'Close',
    CHANGE_SEVERITY = 'ChangeSeverity',
    ASSIGN_USER = 'AssignUser',
    EXCLUDE = 'Exclude',
    ARCHIVE = 'Archive',
}

export const DEFAULT_MITRE_TACTIC_FILTER = `${FindingToKustoMapping.mitreTactic} like '%%'`;

export enum ModalType {
    FixIt = 'FixIt',
    BotStatusCheck = 'BotStatusCheck',
    Remediate = 'Remediate',
    ReportIssue = 'ReportIssue',
    Acknowledge = 'Acknowledge',
    Comment = 'Comment',
    Close = 'Close',
    ChangeSeverity = 'ChangeSeverity',
    AssignUser = 'AssignUser',
    Archive = 'Archive',
}

export const REMEDIATION = 'remediation';

export enum RemediationType {
    Feedback = 'feedback',
}

export enum RightPanelItemType {
    ExploreLogs = 'explore-logs',
}
