import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    getManagementAccountDetails,
} from './helpers/aws.reducer';
import { Typography, Link, Stack, Alert, Label } from 'common/design-system/components-v2';
import { StackWrapper, SuccessIcon } from './helpers/aws.styled';
const AwsOrgOnboardManagementAccount: FC = ()=> {
    const { t } = useTranslation();
    const managementAccountDetails = useSelector(getManagementAccountDetails);

    const generateCdrOnboardingLink = () => {
        if(managementAccountDetails && managementAccountDetails?.id) {
            return `/magellan/magellan-aws-cft-onboarding?account=${managementAccountDetails.externalAccountNumber}&type=cloudtrail&roleArn=${managementAccountDetails.credentials.arn}&accountName=${managementAccountDetails.name}`;
        }
        return '';
    };

    return <StackWrapper spacing={5}>
        <Stack spacing={3} direction="row" alignItems="center" justifyContent="flex-start">
            <SuccessIcon/>
            <Label size="xl" color="strong" text={t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.ORGANIZATION_ONBOARDED_SUCCESSFULLY')} />
        </Stack>
        <Stack spacing={2}>
            <Alert type={'info'} margin={[0,0,2,0]}>
                <Stack spacing={1}>
                    <Typography variant={'body500'}>{t('ON_BOARDING.ACCOUNT.ORG.IMPORTANT')}</Typography>
                    <Typography variant="bodyLg">{t('ON_BOARDING.ORG_ONBOARDING_CREATE_MEMBER_ACCOUNT.ONBOARDING_TAKES_TIME')}</Typography>
                </Stack>

            </Alert>
            <Typography variant='bodyLg'>{t('ON_BOARDING.ACCOUNT.ORG.WISH_TO_ONBOARD_CDR_TEXT')}</Typography>
            <Link internalUrl={generateCdrOnboardingLink()}>
                <Typography variant='bodyLg' color="brandPrimary">{t('ON_BOARDING.ACCOUNT.ORG.CONTINUE_TO_CDR_ONBOARDING')}</Typography>
            </Link>
        </Stack>
    </StackWrapper>;
};

export default AwsOrgOnboardManagementAccount;