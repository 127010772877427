import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wizard, { RenderViewParams } from 'common/components/Wizard/Wizard';
import { PlatformsSelectionComponent } from './PlatformsSelectionComponent';
import { PlatformRulesetSelectionComponent } from './PlatformRulesetSelectionComponent';
import {
    getNotificationsService,
    getRulesetService,
} from 'common/interface/services';
import { IPolicy } from 'common/interface/policy';
import { i18nPolicyNamespace } from '../initialize.i18n';
import { CSPM } from '../initialize';
import { CardItem } from 'common/components/policy/Card';
import { NotificationsSelectionComponent } from 'common/components/policy/NotificationsSelectionComponent';
import { ListItem } from 'common/components/policy/MultiSelectList/ListItemTypes/interfaces';
import { Vendors } from 'common/consts/vendors';
import { Done } from 'common/components/policy/Done/Done';
import { useLocation } from 'react-router-dom';
import { NotificationType } from 'common/interface/notifications';
import PreselectedPlatformRulesetComponent from 'common/components/policy/PreselectedPlatformRulesetComponent';
import { finishFlow, saveUpdatePolicies } from 'common/components/policy/utils';
import { useBreadcrumbsLastChildren } from 'common/hooks/useBreadcrumbsLastChildren';
import { useReturnUrl } from 'common/hooks/useReturnUrl';

const AddPolicyPage: React.FC = () => {
    const { t } = useTranslation(i18nPolicyNamespace);
    const [selectedPlatform,setSelectedPlatform] = useState<Vendors>();
    const [selectedRulesets,setSelectedRulesets] = useState<ListItem[]>();
    const [flowStatusMessage,setFlowStatusMessage] = useState<string>();
    const [selectedNotifications,setSelectedNotifications] = useState<ListItem[]>();
    const [flowStatusOK,setFlowStatusOK] = useState<boolean>();
    const returnUrl = useReturnUrl();

    useBreadcrumbsLastChildren([CSPM,t('GENERAL.ADD_VENDOR_POLICY')]);

    const saveChanges = useCallback( async () => {
        const allRulesets = await getRulesetService().getAllRulesets();

        if(selectedRulesets?.length && selectedNotifications?.length ){
            const policies:IPolicy[] = selectedRulesets.map((ruleset:ListItem) => {
                const rulesetId = +ruleset.id;
                const _ruleset = allRulesets.find((_ruleset) => _ruleset.id === rulesetId);
                const policy:IPolicy = {
                    rulesetPlatform: _ruleset?.cloudVendor,
                    // TODO: bug number 2, when we add imageassurance or k8s the targetType shouldn't be Vendor.
                    // TODO: need to check what is the right value or we can not add policy as cloud vendor
                    targetType: 'Vendor',
                    rulesetId: rulesetId,
                    notificationIds: selectedNotifications.map((notification:ListItem) => notification.id),
                };

                if(rulesetId < 0) {
                    policy.rulesetVersion = ruleset.selectedVersion?.version;
                }
                return policy;
            });

            if(policies?.length){
                setFlowStatusMessage(t('GENERAL.PROCESSING'));
                const result = await saveUpdatePolicies(policies);
                if(result) {
                    setFlowStatusOK(true);
                    setFlowStatusMessage(t('GENERAL.POLICY_ADDED_MESSAGE'));
                }else {
                    setFlowStatusOK(false);
                    setFlowStatusMessage(t('GENERAL.ERROR_OCCURRED'));

                }
            }
        }
    },[selectedNotifications, selectedRulesets, t]);
    const [predefinedRulesetAndPlatform,setPredefinedRulesetAndPlatform] = useState<{ rulesetName:string,platformName:string } | null>();
    const location = useLocation();
    const [steps, setSteps] = useState<any[]>();

    const startingSteps = useMemo(() => [{
        name: t('GENERAL.PLATFORM_SELECTION'),
        renderView: (renderViewParams:RenderViewParams) => {
            return <PlatformsSelectionComponent selectedPlatformId={selectedPlatform} {...renderViewParams } selectedItemChanged={selectedPlatformChanged} />;
        },
        onNext: async ()=>{
            return ;
        },
    },{
        name: t('GENERAL.RULESET_SELECTION'),
        renderView: (renderViewParams:RenderViewParams) => {
            return <PlatformRulesetSelectionComponent preSelectedItems={selectedRulesets} platform={selectedPlatform} {...renderViewParams } selectedRulesetsChanged={selectedRulesetsChanged}/>;
        },
        onNext: async ()=>{
            return ;
        },
    }],[selectedPlatform, selectedRulesets, t]);

    const finalSteps = useMemo(() => [
        {
            name: t('GENERAL.NOTIFICATION_SELECTION'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <NotificationsSelectionComponent preSelectedItems={selectedNotifications} {...renderViewParams } selectedNotificationsChanged={selectedNotificationsChanged}/>;
            },
            onNext: async ()=>{
                saveChanges();
            },
        },{
            name: t('GENERAL.DONE'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <Done message={flowStatusMessage} {...renderViewParams} hasError={!flowStatusOK}></Done>;
            },
            onNext: async ()=>{
                finishFlow(returnUrl);
            },
        }
    ],[t, selectedNotifications, saveChanges, flowStatusMessage, flowStatusOK]);
    const predefinedRulesetStep = useMemo(() => {
        return {
            name: t('GENERAL.PLATFORM_AND_RULESET'),
            renderView: (renderViewParams:RenderViewParams) => {
                return <PreselectedPlatformRulesetComponent onValidate={renderViewParams.onValidate} rulesetName={predefinedRulesetAndPlatform?.rulesetName} platformName={predefinedRulesetAndPlatform?.platformName} />;
            },
            onNext: async ()=>{
                return ;
            },
        };
    },[predefinedRulesetAndPlatform?.platformName, predefinedRulesetAndPlatform?.rulesetName, t]);

    const basicFlowSteps = useMemo(()=> [...startingSteps, ...finalSteps],[finalSteps, startingSteps]);
    const predefinedFlowSteps = useMemo(()=>[predefinedRulesetStep,...finalSteps],[finalSteps, predefinedRulesetStep]);

    const handlePredefinedRulesetParameterFromUrl = useCallback(async (rulesetId: string, environmentId:string) => {
        try {
            const allRulesets = await getRulesetService().getAllRulesets();
            const ruleset = allRulesets.find(ruleset => ruleset.id === +rulesetId);
            if(ruleset){
                if(ruleset.name !== predefinedRulesetAndPlatform?.rulesetName){
                    const rulesetItem : ListItem = {
                        id: ruleset.id.toString(),
                        name: ruleset.name
                    };
                    setSelectedRulesets([rulesetItem]);
                    setSelectedPlatform(environmentId as Vendors);
                    setPredefinedRulesetAndPlatform({ rulesetName: ruleset.name, platformName: environmentId });
                }
            }
            setSteps(predefinedFlowSteps);
        }catch {
            getNotificationsService().addNotification({
                type: NotificationType.ERROR,
                title: t('GENERAL.ERROR_OCCURRED'),
                text: t('GENERAL.ERROR_OCCURRED'),
            });
        }
    },[predefinedFlowSteps, predefinedRulesetAndPlatform?.rulesetName, t]);

    useEffect(() => {
        const querystring = new URLSearchParams(location.search);
        const rulesetId = querystring.get('rulesetId');
        const environmentId = querystring.get('environmentId');
        if(rulesetId && environmentId){
            handlePredefinedRulesetParameterFromUrl(rulesetId,environmentId);
        }else{
            setSteps(basicFlowSteps);
        }
    }, [basicFlowSteps, handlePredefinedRulesetParameterFromUrl, location, predefinedRulesetAndPlatform]);

    const selectedPlatformChanged = (selectedItem: CardItem) => {
        selectedItem && setSelectedPlatform(selectedItem.id);
        setSelectedRulesets([]);
        setSelectedNotifications([]);
    };

    const selectedRulesetsChanged = (selectedItems: ListItem[]) => {
        setSelectedRulesets(selectedItems);
    };

    const selectedNotificationsChanged = (selectedItems: ListItem[]) => {
        setSelectedNotifications(selectedItems);
    };

    return (
        <div>
            {steps?.length ? <Wizard
                steps={steps}
                title={t('GENERAL.ADD_POLICY')}
                doneButtonText={t('GENERAL.DONE')}
                onClose={()=>finishFlow(returnUrl)}
                ShowBackButtonInLastStep={false}
            /> : null}
        </div>
    );
};

export default AddPolicyPage;