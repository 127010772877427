import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Checkbox,
    Icon,
    Link,
    RadioButton,
    SelectV2,
    Stack,
    Tooltip,
    Typography
} from 'common/design-system/components-v2';
import { INotificationsCategoryProperties, ISelectedCategoryObject } from '../../Interfaces/NotificationPageInterfaces';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { IListItemProps } from 'common/design-system/components-v2/List/List.types';
import JsonPayloadEditorComponent from '../JsonPayload/JsonPayloadEditorComponent';
import { AWS_SECURITY_HUB_DESCRIPTION_LINK, I18nNotifications } from '../../NotificationsPage.consts';
import { OutputTypeSelectorComponent } from '../OutputTypeSelector/OutputTypeSelectorComponent';
import { IntegrationIssueAlertComponent } from '../IntegrationIssueAlert/IntegrationIssueAlertComponent';
import { NOTIFICATIONS_TARGET_IDS_DTO_MAPPER } from 'common/interface/notifications';
import { ADD_NEW_CONFIGURATION_ID } from '../utils';

export interface IntegrationCheckboxComponentProps{
    categorySelections: ISelectedCategoryObject,
    categoryMappingObject: INotificationsCategoryProperties,
    onCategoryClicked: (id: string) => void,
    configurationsList: any,
    onPayloadUpdated: (selectedCategory:ISelectedCategoryObject,payload: string) => void,
    onOutputTypeUpdated: (category:ISelectedCategoryObject,outputType: string | undefined) => void,
    onIntegrationSelected: Function,
    onConfigurationSelected: Function,
    onAddConfigurationsClicked: (integrationId: string) => void;
    immediateNotificationsFilter?: IFiltersValues;
    isReadOnly?: boolean;
    notificationId: string;
    integrationIssueCleared: (integrationType:string) => void;
}
export const IntegrationsCategoriesComponent : FC<IntegrationCheckboxComponentProps> = ({ integrationIssueCleared, notificationId , onOutputTypeUpdated, onPayloadUpdated, categorySelections, categoryMappingObject,onIntegrationSelected,onCategoryClicked, onConfigurationSelected, configurationsList, onAddConfigurationsClicked , isReadOnly=false, immediateNotificationsFilter }) => {
    const { id,title,description, descriptionLink, alert } = categoryMappingObject;

    const { t } = useTranslation( I18nNotifications );
    const getConfigurationsList = (): IListItemProps[] => {
        const configurations = [{ label: t('MODALS.ADD_EDIT_MODAL.ADD_NEW_CONFIGURATION'),
            value: ADD_NEW_CONFIGURATION_ID,
            onClick: () => onAddConfigurationsClicked(categorySelections.selectedIntegration) }];
        configurationsList?.forEach((configuration: { name: any; id: string; }) =>
        {configurations.push({ label: configuration.name,
            value: configuration.id!,
            onClick: () => {
                onConfigurationSelected(id, configuration);
            }
        });});

        return configurations;
    };

    const getIntegrationsChoices = () => {
        if (categoryMappingObject.integrationsIDs.length > 1) {
            return categoryMappingObject.integrationsIDs.map(integrationID => {
                return <RadioButton key={integrationID} id={integrationID} label={integrationID}
                    checked={categorySelections.selectedIntegration === integrationID}
                    onChange={() => onIntegrationSelected(id, integrationID)}
                    disabled={isReadOnly}></RadioButton>;
            });
        }
        return null;
    };

    const integrationIssueClearedEventHandler = () => {
        integrationIssueCleared && integrationIssueCleared(categorySelections.selectedIntegration);
    };

    const getIntegrationType = () => {
        return NOTIFICATIONS_TARGET_IDS_DTO_MAPPER[categoryMappingObject.id];
    };
    const configurationsClicked = (value : any) => {
        if(value === ADD_NEW_CONFIGURATION_ID) {
            onAddConfigurationsClicked(categorySelections.selectedIntegration);
        }else{
            onConfigurationSelected(id, configurationsList.find((config : any) => config.id === value));
        }
    };
    return <Stack key={id}>
        <Stack flexWrap={true} alignItems={'center'} spacing={2} direction={'row'}>
            <Checkbox label={t(title)} checked={categorySelections.isChecked} onChange={()=>onCategoryClicked(id)} disabled={isReadOnly}/>
            {description && <Tooltip interactive={true} placement="right" content={<>{t(description)}{descriptionLink && <Link externalUrl={AWS_SECURITY_HUB_DESCRIPTION_LINK}>{t('GENERAL.HERE')} </Link>}</>}><Icon name={'info'} size={12} /></Tooltip>}
        </Stack>
        { categorySelections.isChecked && categoryMappingObject.integrationsIDs.length > 0 && <Stack flexWrap={true} padding={[0,6]}>
            {immediateNotificationsFilter && alert?.condition?.(immediateNotificationsFilter) ? <Stack padding={[2,0,0,0]}>
                <Alert
                    type={'warning'}
                >
                    <Stack spacing={1}>
                        <Typography variant="body500">{t(alert.title)}</Typography>
                        <Typography variant="body">
                            {t(alert.message)}
                        </Typography>
                    </Stack>

                </Alert>
            </Stack> : null}
            {
                getIntegrationsChoices() ? <Stack flexWrap={true} direction={'row'} spacing={3}>
                    {getIntegrationsChoices()}
                </Stack> : null
            }
            {
                !!categorySelections.selectedIntegration && <Stack spacing={2} padding={[3,0,0,0]}>
                    <Typography>{t('MODALS.ADD_EDIT_MODAL.SELECT_CONFIGURATION',{ selectedIntegration: categorySelections.selectedIntegration })}</Typography>
                    <SelectV2
                        required={false}
                        clearable
                        value={categorySelections?.selectedConfiguration?.id || ''}
                        placeholder={t('MODALS.ADD_EDIT_MODAL.SELECT_CONFIGURATION_GENERAL')}
                        options={getConfigurationsList()}
                        onChange={(key : string | undefined) => configurationsClicked(key)}
                        isMulti={false}/>
                    { categorySelections.hasIntegrationPayload && <JsonPayloadEditorComponent jsonPayload={categorySelections.integrationPayload} payLoadUpdated={(payload)=>onPayloadUpdated(categorySelections,payload)} integrationConfiguration={categorySelections?.selectedConfiguration?.configurationObj} />}
                    { categorySelections.hasOutputTypeSelection && <OutputTypeSelectorComponent groupName={categorySelections.selectedConfiguration?.id?.toString() || ''} onTypeSelect={(outputType) => onOutputTypeUpdated(categorySelections,outputType)} selectedOutputType={categorySelections.outputType!} />}
                    { categorySelections.hasError && <IntegrationIssueAlertComponent integrationIssueCleared={integrationIssueClearedEventHandler} notificationId={notificationId} integrationId={categorySelections.selectedIntegration} integrationType={getIntegrationType()} />}
                </Stack>
            }
        </Stack>
        }
    </Stack>;
};