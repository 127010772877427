import React, { useCallback, useEffect, useState } from 'react';
import { EnvStyled } from './RiskiestEnvironments.styled';
import { useTranslation } from 'react-i18next';
import { IFieldInfo, LoadingState, SortOrder } from 'common/interface/general';
import { getEnvRiskLevelsMap, getEnvRouteFields } from './RiskiestEnvironments.datasource';
import {
    getRiskLevels,
    IRiskLevelInfo, RiskLevelEnum,
} from 'common/module_interface/RiskManagement/RiskLevel';
import { INumbersMap } from 'common/utils/helpFunctions';
import { Spinner } from 'common/design-system/components-v2';
import { IFiltersValues } from 'common/components/FilterPanel/FilterPanel.interface';
import { LevelBoxes } from 'common/erm-components/custom/LevelBoxes/LevelBoxes';
import { RiskStyled } from '../../../../../RiskManagement.styled';
import { I18nRiskNamespace } from '../../../../../consts';
import { ILevelBoxesItemsInfo, ILevelBoxItem } from 'common/erm-components/custom/LevelBoxes/LevelBox.interface';
import { getErmUrlsService } from 'common/module_interface/RiskManagement/Services';

const RISK_LEVEL_FIELD_NAME = 'customData|riskLevel';

const EMPTY_ITEMS_INFO = (): ILevelBoxesItemsInfo => {
    return {
        items: [],
        loadingState: LoadingState.IS_LOADING,
    };
};

const RiskiestEnvironments: React.FC<{ filterValues?: IFiltersValues }> = ({ filterValues, }) => {
    const [itemsInfo, setItemsInfo] = useState<ILevelBoxesItemsInfo>(EMPTY_ITEMS_INFO());
    const { t } = useTranslation();
    const updateData = useCallback(() => {
        setItemsInfo(EMPTY_ITEMS_INFO());
        getEnvRiskLevelsMap(filterValues?.fields).then((riskLevelsMap: INumbersMap) => {
            const items: ILevelBoxItem[] = [];
            getRiskLevels(SortOrder.descending, RiskLevelEnum.low).forEach((riskLevelInfo: IRiskLevelInfo) => {
                const count = riskLevelsMap[riskLevelInfo.key] ?? 0;
                const onClick = count > 0 ? () => {
                    const fields: IFieldInfo[] = [
                        ...(getEnvRouteFields(filterValues) || []),
                        {
                            name: RISK_LEVEL_FIELD_NAME,
                            value: [riskLevelInfo.serverKey],
                        }
                    ];
                    getErmUrlsService().gotoEnvironmentTable(fields);
                } : undefined;
                items.push({
                    onClick,
                    count,
                    color: riskLevelInfo.color,
                    text: riskLevelInfo.displayText,
                    level: riskLevelInfo.level,
                });
            });
            setItemsInfo({
                items,
                loadingState: LoadingState.LOADING_SUCCEEDED,
            });
        }).catch(() => {
            setItemsInfo({
                items: [],
                loadingState: LoadingState.LOADING_FAILED,
            });
        });
    }, [filterValues]);

    useEffect(() => {
        if (filterValues) {
            updateData();
        }
    }, [filterValues, updateData]);

    if (itemsInfo.loadingState === LoadingState.IS_LOADING) {
        return <EnvStyled.TopDiv><RiskStyled.SpinnerDiv><Spinner /></RiskStyled.SpinnerDiv></EnvStyled.TopDiv>;
    }

    return (
        <EnvStyled.TopDiv>
            <EnvStyled.HeaderDiv>{t('RISK_MANAGEMENT.DASHBOARD.ENVIRONMENTS_BY_RISK',{ ns: I18nRiskNamespace })}</EnvStyled.HeaderDiv>
            <EnvStyled.ContentDiv>
                <LevelBoxes items={itemsInfo.items} />
            </EnvStyled.ContentDiv>
        </EnvStyled.TopDiv>
    );
};

export default RiskiestEnvironments;
