import { IEvent } from 'common/module_interface/events/Events';
import { IFindingModelOriginString } from 'common/components/Findings/Findings.interface';
import { ExclusionRegistry } from 'common/module_interface/exclusion/exclusionRegistry';


export default function initializeExclusionEvents() {
    ExclusionRegistry.addExcludeEventEnablers([{
        id: IFindingModelOriginString.CIEM,
        content: {
            enabler: (event: IEvent) => {
                return event.origin === IFindingModelOriginString.CIEM;
            }
        }
    }]);
}
