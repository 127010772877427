import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupSelection, Input, Stack } from 'common/design-system/components-v2';
import { scannedFindingType, I18nExclusion, FindingTypes, scannedFindingTypeInputs } from '../../helpers/exclusions.consts';
import { IExclusionFindingTypeProps } from '../../helpers/exclusions.interfaces';

interface InputTypeField {
    name: string,
    propName: string,
    value: string
    gsl: (value: string) => string;
}

const ExclusionByFindingType: FC<IExclusionFindingTypeProps> = ({ onChange, initialSelectedOption }) => {
    const { t } = useTranslation(I18nExclusion);

    const parseInitialOptions = (options: string[] = []) => {
        const inputsConfig = scannedFindingTypeInputs(t);
        const stringExpressions = { category: ' and category = ', like: ' like ' };
        const extractedValues = options
            .filter(option => option.includes(stringExpressions.category))
            .map(option => {
                const [leftPart, category] = option.split(stringExpressions.category);
                const [prop, valuePart] = leftPart.split(stringExpressions.like);
                // Extracting value inside the single quotes
                const matches = valuePart.match(/'([^']+)'/);
                const value = matches ? matches[1] : '';
                return {
                    propName: prop.trim(),
                    value: value,
                    category: category.replace(/'/g, '').trim()
                };
            });
        const category = extractedValues[0]?.category;
        const findingType = category as keyof typeof FindingTypes;
        const inputs = findingType ? inputsConfig[findingType].map(input => ({
            ...input,
            value: extractedValues.find(val => val.propName.indexOf(input.propName) !== -1)?.value || ''
        })) : [];
        return { findingType, inputs };
    };
    const [findingType, setFindingType] = useState<keyof typeof FindingTypes | ''>(() => parseInitialOptions(initialSelectedOption as string[]).findingType);
    const [findingTypeInputs, setFindingTypeInputs] = useState<InputTypeField[]>(parseInitialOptions(initialSelectedOption as string[]).inputs);

    const onEntityTypeChange = (newValue: keyof typeof FindingTypes) => {
        setFindingTypeInputs(scannedFindingTypeInputs(t)[newValue || '']);
        setFindingType(newValue || '');
    };

    const handleInputOnChange = (inputIndex: number, value: string) => {
        setFindingTypeInputs(prevInputs =>
            prevInputs.map((input, index) =>
                index === inputIndex ? { ...input, value } : input
            )
        );
        const output = findingTypeInputs
            .filter(input => input.value !== '')
            .map(input => input.gsl(input.value));
        onChange(output);
    };
    
    return (
        <Stack>
            <Stack spacing={3}>
                <GroupSelection
                    label={t('MODAL.TOPICS.FINDING_TYPE.TITLE')}
                    direction="row"
                    onChange={(value) => onEntityTypeChange(value as keyof typeof FindingTypes)}
                    value={findingType}
                    options={scannedFindingType(t)}
                />
                { findingTypeInputs?.length ?
                    <Stack direction="column" justifyContent="flex-start" fullWidth spacing={3} padding={[0,0,0,6]}>
                        { findingTypeInputs.map((input: InputTypeField, index) => (
                            <Input
                                key={input.propName}
                                data-aid={`asset-input-${input.propName}`}
                                label={input.name}
                                value={input.value}
                                onChange={(e) => handleInputOnChange(index, e.target.value)}
                                fullWidth
                                clearable
                            />
                        ))}
                    </Stack>
                    : null }
            </Stack>
        </Stack>
    );
    
};

export default ExclusionByFindingType;