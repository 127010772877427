import {
    Typography,
    Alert, Button, Stack, Icon,
} from 'common/design-system/components-v2';
import { useCallback, useEffect, useState } from 'react';
import {
    ActiveFeaturesRegistry
} from 'common/module_interface/RiskManagement/activeFeatures/ActiveFeaturesRegistry';
import { getAccountService } from 'common/interface/services';
import {
    ActiveFeatureTopicEnum,
    ActiveFeatureTopicsMap,
    IActiveFeatureInfo,
    IActiveFeatureStatusInfo,
} from 'common/module_interface/RiskManagement/activeFeatures/ActiveFeatures';
import { ManagerStyled } from './ActiveFeaturesManagerPage.styled';
import ActiveFeaturesManagerTable from '../ActiveFeaturesManagerTable/ActiveFeaturesManagerTable';
import { isEmpty } from 'lodash';
import { GENERAL_BOOLEAN_VALUES } from 'common/consts/GeneralConsts';
import { DebugActiveFeaturesMap, isAfEnabled } from 'common/utils/debugUtils';

export const ActiveFeaturesManagerPage = () => {
    const [activeFeaturesStatusInfo, setActiveFeaturesStatusInfo] = useState<Record<string, IActiveFeatureStatusInfo[]>>();
    const [isChanged, setIsChanged] = useState<boolean>(false);
    
    const getActiveFeaturesInfos = useCallback(() => {
        let activeFeaturesInfos: IActiveFeatureInfo[] = ActiveFeaturesRegistry.getAllActiveFeaturesInfos();
        if (!isAfEnabled(DebugActiveFeaturesMap.SHOW_HIDDEN_ACTIVE_FEATURES.key)) {
            activeFeaturesInfos = activeFeaturesInfos.filter(feature => !feature.hidden);
        }
        return activeFeaturesInfos;
    }, []);
    
    const prepareActiveFeatures = useCallback((activeFeaturesInfo: IActiveFeatureInfo[]) => {
        const activeFeaturesStatusesByTopic: Record<string, IActiveFeatureStatusInfo[]> = {};
        activeFeaturesInfo.map((activeFeatureInfo: IActiveFeatureInfo) => {
            const isLocalStorageActive = localStorage.getItem(activeFeatureInfo.key) === GENERAL_BOOLEAN_VALUES.TRUE;
            const isServerActive = getAccountService().hasActiveFeature(activeFeatureInfo.key);
            const topicName = ActiveFeatureTopicEnum[activeFeatureInfo.topic];
            const activeFeatureStatus: IActiveFeatureStatusInfo = {
                ...activeFeatureInfo,
                isActive: isLocalStorageActive || isServerActive,
                localStorageActive: isLocalStorageActive, 
                serverActive: isServerActive 
            };

            if (!activeFeaturesStatusesByTopic[topicName]) {
                activeFeaturesStatusesByTopic[topicName] = [];
            }
            activeFeaturesStatusesByTopic[topicName].push(activeFeatureStatus);
        });
        return activeFeaturesStatusesByTopic;
    }, []);

    useEffect(() => {
        setActiveFeaturesStatusInfo(prepareActiveFeatures(getActiveFeaturesInfos()));
    }, [getActiveFeaturesInfos, prepareActiveFeatures]);
    
    return (
        <ManagerStyled.TopDiv>
            <ManagerStyled.Header direction={'column'} isChanged={isChanged}>
                <Alert type={'warning'} iconSize={24}>
                    <Stack direction={'column'} spacing={2}>
                        <Stack direction={'row'} spacing={8}>
                            <Typography variant={'h2'}>Attention: Refresh required</Typography>
                            <Button onClick={() => window.location.reload()} color={'brandPrimary'} disabled={!isChanged}>
                                <Icon name={'refresh'}/>
                                <Typography variant={'subtitleLg'} color={'inverse'}>Refresh</Typography>
                            </Button>
                        </Stack>
                        <Typography variant={'bodyLg'}>
                            In order to make your changes effective, please refresh the page after selecting the active features.
                        </Typography>
                    </Stack>
                </Alert>
                <ManagerStyled.TopSep />
            </ManagerStyled.Header>
            <Stack direction={'column'} overflow={'auto'} fullHeight>
                <Typography variant="h1">Active Features Manager page</Typography>
                <Typography variant="bodyLg">
                    This page includes all available active features from the modules: ERM and CDR(Intelligence)
                    <br/>
                    Select the active feature to be added to your browser session (other users in this account will not be affected)
                </Typography>
                <ManagerStyled.TablesDiv>
                    { !isEmpty(activeFeaturesStatusInfo) && Object.keys(activeFeaturesStatusInfo)
                        .sort((topic1, topic2) => ActiveFeatureTopicsMap[topic1 as ActiveFeatureTopicEnum].position - ActiveFeatureTopicsMap[topic2 as ActiveFeatureTopicEnum].position)
                        .map(topic => {
                            if (isEmpty(activeFeaturesStatusInfo[topic])) return;
                            const activeFeatureTopicElement = topic as ActiveFeatureTopicEnum;
                            return <ActiveFeaturesManagerTable key={`${topic}-active-features-manager-table`} activeFeaturesStatus={activeFeaturesStatusInfo[topic]} setIsChanged={setIsChanged} topic={activeFeatureTopicElement}/>;
                        })}
                </ManagerStyled.TablesDiv>
            </Stack>
        </ManagerStyled.TopDiv>
    );
};

export default ActiveFeaturesManagerPage;
