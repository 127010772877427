import styled, { css } from 'styled-components';
import { Stack, Typography } from 'common/design-system/components-v2';

export const TopDiv = styled(Stack)`
`;

const CounterDiv = styled(Typography)<{ clickable: boolean }>`
  font-size: 32px;
  ${({ clickable, theme }) => clickable && css`
    &:hover {
      cursor: pointer;
      color: ${theme.palette.onSurface.brandPrimary};
      text-decoration: underline;
    }
  `};
`;

export const DataAssetCardStyled = {
    TopDiv,
    CounterDiv
};