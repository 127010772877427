import styled from 'styled-components';
import { Stack } from 'common/design-system/components-v2';

const StackWrapper = styled(Stack)`
    border: ${({ theme }) => `${theme.border.width.standard}${theme.units} solid ${theme.palette.border.normal}`};
    border-radius: ${({ theme }) => `${theme.border.radius(2)}${theme.units}`};
    height: 100px;
    width: 300px;
    cursor: pointer;
    &:hover {
        p { 
            text-decoration: underline;
        }
    }
`;

const IntegrationIcon = styled.div`
    width:64px;
    height: 64px;
    background: ${({ theme }) => theme.palette.surface.secondary};
    border-radius: 50%;
    padding: 18px;
    display: flex;
`;
export {
    StackWrapper,IntegrationIcon
};