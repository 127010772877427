import React from 'react';
import { IEvent } from 'common/module_interface/events/Events';
import ComplianceFindingApi from 'modules/events/services/complianceFindingApi/complianceFindingApi.service';
import { ComplianceFindingArchive } from 'modules/events/services/complianceFindingApi/complianceFindingApi.interface';

export interface UseUnarchiveEventRequest {
    onSuccess?: (response: ComplianceFindingArchive.Response) => void;
    onError?: (error: string) => void;
}

export interface UseUnarchiveEventPayload {
    isLoading: boolean;
    isError: boolean;
    results?: ComplianceFindingArchive.Response;

    unarchiveEvents: (events: IEvent[]) => Promise<void>;
    reset?: () => void;
}

export type UseUnarchiveEventReset = (req?: UseUnarchiveEventRequest) => UseUnarchiveEventPayload;

export const useUnarchiveEvent: UseUnarchiveEventReset = (req) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<ComplianceFindingArchive.Response>();

    const unarchiveEvents = React.useCallback(async (events: IEvent[]) => {
        setIsError(false);
        setIsLoading(true);
        try {
            const response = await ComplianceFindingApi.unarchive({
                ids: events.map(event => event.id)
            });
            setResults(response.data);
            req?.onSuccess?.(response.data);
        } catch (error: any) {
            setIsError(true);
            req?.onError?.(error?.message || 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    }, [req]);

    const reset = React.useCallback(() => {
        setIsLoading(false);
        setIsError(false);
        setResults(undefined);
    }, []);

    return ({
        isLoading,
        isError,
        results,

        unarchiveEvents,
        reset
    });
};