import React, { ChangeEvent } from 'react';
import { Button, Icon, Input, Stack, Table } from 'common/design-system/components-v2';
import { i18nIntelligenceNamespace } from 'modules/Intelligence/initialize.i18n';
import { useTranslation } from 'react-i18next';
import { IStorageModel, SelectedTab } from '../../../AzureIntelligenceOnboarding.interface';
import Switch from 'common/design-system/components-v2/Switch';
import { SearchInputWrapper, StorageTable } from '../../../AzureIntelligenceOnboarding.styled';
import { GridOptions } from 'ag-grid-community';

interface IContentProps {
    selectedStorageTab: SelectedTab;
    isHideStorage: boolean;
    rowData: IStorageModel[] | null;
    gridOptions: GridOptions;
    onHideStorage: (event: ChangeEvent<HTMLInputElement>) => void;
    onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    setIsAddStorageModalOpen: (value: boolean) => void;
}

const Content: React.FC<IContentProps> = (props) => {
    const { selectedStorageTab, isHideStorage, rowData, gridOptions, onHideStorage, onSearchChange, setIsAddStorageModalOpen } = props;
    const { t } = useTranslation(i18nIntelligenceNamespace, { keyPrefix: 'AZURE_ONBOARDING' });

    return (
        <>
            <Stack padding={[4, 0]}>
                {t(`STORAGE.DESCRIPTION.${selectedStorageTab === SelectedTab.List ? 'SELECT' : 'ADD'}`)}
            </Stack>

            {selectedStorageTab === SelectedTab.List ? (
                <>
                    <Stack direction={'row'} spacing={6} alignItems='center' justifyContent='flex-end' margin={[0, 0, 2]}>
                        <Switch checked={isHideStorage} onChange={onHideStorage} label={t('STORAGE.HIDE_STORAGE')} disabled={rowData?.length === 0} />
                        <SearchInputWrapper>
                            <Input 
                                placeholder={t('STORAGE.SEARCH_PLACEHOLDER')} 
                                startAdornment={<Icon name='magnify' />} 
                                onChange={onSearchChange}
                                fullWidth
                                disabled={rowData?.length === 0}
                            />
                        </SearchInputWrapper>
                    </Stack>
                </>
            ) : (
                <Stack padding={[2, 0]}>
                    <Button
                        iconProps={{ name: 'plus' }}
                        variant='outlined'
                        onClick={() => setIsAddStorageModalOpen(true)}
                    >
                        {t('STORAGE.ADD_STORAGE.CTA')}
                    </Button>
                </Stack>
            )}

            {rowData && (selectedStorageTab === SelectedTab.List || (selectedStorageTab === SelectedTab.Manual && rowData.length)) ? (
                <StorageTable fullHeight fullWidth>
                    <Table 
                        gridOptions={gridOptions} 
                        disableColumnMenu={true} 
                        disableGrouping={true} 
                        disableRowsResetOnRowDataChange
                        disableRowsResetOnFilterChange
                    />
                </StorageTable>
            ) : null}
        </>
    );
};

export default Content;