import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'common/services/store/store';


interface IntegrationsState {
    shouldRefreshData: boolean
}

const initialState: IntegrationsState = {
    shouldRefreshData: false
};

export const IntegrationsSlice = createSlice({
    name: 'integrationsSlice',
    initialState,
    reducers: {
        setShouldRefreshData: (state: IntegrationsState, action: PayloadAction<any>) => {
            state.shouldRefreshData = action.payload;
        }
    },
});

export const {
    setShouldRefreshData
} = IntegrationsSlice.actions;

export const getShouldRefreshData = (state: RootState): boolean => {
    return state.integrationsSlice.shouldRefreshData;
};

export default IntegrationsSlice.reducer;