import { APPLICATION_REDUCERS } from 'common/extensibility/WellKnownPaths';
import { IApplicationReducer } from 'common/interface/redux';
import gcpReducer from './reducer';
import { globalAddinContainer } from 'common/extensibility/AddinContainer';
import { initialGcpEnvironmentData } from './GcpEnvironment.consts';


const initializeGcpEnvRedux = () => {
    const reducers: IApplicationReducer[] = [
        {
            name: 'env_gcp',
            reducer: gcpReducer,
            isBlackList: true,
        },
    ];
    globalAddinContainer.addMap(APPLICATION_REDUCERS, reducers, 'name');
};


export const initializeGcpEnv = () => {
    initializeGcpEnvRedux();
    initialGcpEnvironmentData();
};