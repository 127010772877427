export const COMPLIANCE_ICONS_MAPPING: { [code: string]: string } = {
    HIPAA: 'HIPAA',
    CIS: 'CIS',
    NIST: 'NIST',
    CloudGuard: 'cloudGuard',
    SOC: 'SOC',
    CCPA: 'CCPA',
    NZISM: 'NZISM',
    'PCI DSS': 'PCI_DSS',
    CSA: 'CSA',
    GDPR: 'GDPR',
};

export const AWS_INTERVALS: { [id: string]: number } = {
    ACCOUNT: 5 * 1000,
    SUMMARY: 5 * 1000,
    IS_TAKING_LONGER_THAN_EXPECTED_ACCOUNT: 2 * 60 * 1000,
    IS_TAKING_LONGER_THAN_EXPECTED_SUMMARY: 6 * 60 * 1000,
};

export enum AdminLink {
    CloudInfra = 'https://sc1.checkpoint.com/documents/Infinity_Portal/WebAdminGuides/EN/CloudGuard-PM-Admin-Guide/Default.htm#cshid=aws_org_s3',
    StandAlone = 'https://sc1.checkpoint.com/documents/CloudGuard_Dome9/Default.htm#cshid=aws_org_s3'
}

export const defaultOrgName = 'AWS Organization';