import styled from 'styled-components';

const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch; 
  border-radius: 8px;
  border: 1px solid #A5C2FD;
  background: rgba(87, 135, 251, 0.10);
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const HeaderIconDiv = styled.div`
  display: flex;
  width: 16px;
  flex-grow: 0;
`;

const HeaderTextDiv = styled.div`
  display: flex;
  flex-grow: 1;
  font-size: 13px;
  line-height: 24px; /* 184.615% */
`;


const BodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const BodyBarDiv = styled.div`
  display: flex;
  height: 100%;
  width: 4px;
  margin: 0 6px;
  flex-grow: 0;
  border-radius: 8px;
  background: ${({ theme }) => `${theme.palette.onSurface.brandPrimary}`};
`;

const BodyTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 4px;
  line-height: 20px;
`;

export const RemStyled = {
    TopDiv,
    HeaderDiv,
    HeaderIconDiv,
    HeaderTextDiv,
    BodyDiv,
    BodyBarDiv,
    BodyTextDiv,
};